import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { useSportlinePendingApi } from './useSportlinePendingApi';
export function useSportlineApiLoadComingEvents(props) {
    const { tryRequestWithCTag, defaultRestApiFlags, isLiveOutrightsEnabled } = props;
    const { loadEvents: loadComingEvents } = useSportlinePendingApi({
        tryRequestWithCTag,
        getEventsInput: (param)=>{
            let { silent, filters, regionId, sportFamily } = param;
            return {
                betline: BetlineType.Prematch,
                regionId,
                sportFamily,
                to: filters.to,
                silent,
                hideClosed: true,
                flags: [
                    ...defaultRestApiFlags.value
                ],
                LMCE: isLiveOutrightsEnabled.value
            };
        },
        getPendingParams: (param)=>{
            let { vTag, regionId, sportFamily, filters } = param;
            return {
                filter: filters,
                vTag,
                regionId,
                sportFamily,
                betlines: [
                    BetlineType.Prematch
                ]
            };
        }
    });
    return {
        loadComingEvents
    };
}
