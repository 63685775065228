import { ref, computed, watch } from 'vue';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
export function useLeaguePageActiveSection(props, emit) {
    const { canLoadStatistics, canDisplayExpectedEventsList, canDisplayExpectedStatistic, canDisplayExpectedPromotions, canDisplayPreviousEventsList, canDisplayPreviousStatistic, canDisplayPreviousPromotions } = props;
    const selectedSectionId = ref(null);
    const isEventsTabAvailable = computed(()=>canDisplayExpectedEventsList.value || canDisplayPreviousEventsList.value);
    const isStatisticTabAvailable = computed(()=>canLoadStatistics.value || canDisplayExpectedStatistic.value || canDisplayPreviousStatistic.value);
    const isPromotionsTabAvailable = computed(()=>canDisplayExpectedPromotions.value || canDisplayPreviousPromotions.value);
    const availableSection = computed(()=>{
        const result = [];
        if (isEventsTabAvailable.value) result.push(LeaguePageSectionId.EventsList);
        if (isStatisticTabAvailable.value) result.push(LeaguePageSectionId.Statistic);
        if (isPromotionsTabAvailable.value) result.push(LeaguePageSectionId.Promotions);
        return result;
    });
    const activeSectionId = computed(()=>{
        if (selectedSectionId.value && availableSection.value.includes(selectedSectionId.value)) return selectedSectionId.value;
        return availableSection.value[0] || LeaguePageSectionId.None;
    });
    function selectSection(param) {
        let { id } = param;
        selectedSectionId.value = id;
    }
    watch(activeSectionId, (id)=>{
        emit.onSectionActivated({
            id
        });
    }, {
        immediate: true
    });
    return {
        activeSectionId,
        isEventsTabAvailable,
        isStatisticTabAvailable,
        isPromotionsTabAvailable,
        selectSection
    };
}
