let counter = 0;
const registry = new WeakMap();
/**
 * Get function unique id.
 */ export function getFunctionId(callback) {
    let id = registry.get(callback);
    if (void 0 !== id) return id;
    counter += 1;
    id = counter;
    registry.set(callback, id);
    return id;
}
