import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let DoNotTrackComponent = class DoNotTrackComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result([
            window.navigator.doNotTrack || window.doNotTrack || ComponentStatus.NotAvailable
        ]);
    }
    constructor(){
        super(ComponentKey.DoNotTrack);
    }
};
export { DoNotTrackComponent as default };
