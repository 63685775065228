import { defineStore } from 'pinia';
import { ref } from 'vue';
import { doSelfExclusion as excludeSelf, doSelfExclusionFlex as excludeSelfFlex, getBetLimits, getDepositLimits, getSelfExclusion, PinCodePlace, PinCodeRequiredExceptionCode, responsibleGamingLimitTypeBetLimit, SelfExclusionTimeUnitType, setBetLimits as setSdkBetLimits, setDepositLimits as setSdkDepositLimits } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { isCustomerLimitsRange } from 'web/src/modules/responsible-gambling/guards/isCustomerLimitsRange';
import { canNotSelfExcludeCode } from 'web/src/modules/responsible-gambling/constants';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
const useResponsibleGamblingStore = defineStore('responsible-gambling', ()=>{
    const api = useGraphqlClient();
    const { convertToBaseError } = useErrorsConverter();
    const { setResponsibleGamingLimits } = useCustomerDataStore();
    const betLimits = ref(null);
    const betLimitsLoading = ref({
        dailyLimit: false,
        weeklyLimit: false,
        monthlyLimit: false,
        dailyPercent: false,
        dailyLeft: false,
        dailyEndAt: false,
        weeklyPercent: false,
        weeklyLeft: false,
        weeklyEndAt: false,
        monthlyPercent: false,
        monthlyLeft: false,
        monthlyEndAt: false
    });
    const betLimitsUpdatableAt = ref(null);
    const depositLimits = ref(null);
    const depositLimitsLoading = ref({
        dailyLimit: false,
        weeklyLimit: false,
        monthlyLimit: false,
        dailyPercent: false,
        dailyLeft: false,
        dailyEndAt: false,
        weeklyPercent: false,
        weeklyLeft: false,
        weeklyEndAt: false,
        monthlyPercent: false,
        monthlyLeft: false,
        monthlyEndAt: false
    });
    const depositLimitsUpdatableAt = ref(null);
    const selfExclusion = ref(null);
    const depositLimitsData = ref(null);
    const selfExclusionId = ref(null);
    const selfExclusionMonths = ref(null);
    const selfExclusionRouteBack = ref(null);
    // Mutations:
    function setBetLimits(payload) {
        betLimits.value = payload.limits;
        betLimitsUpdatableAt.value = payload.updatableAt;
    }
    // eslint-disable-next-line max-len
    function setDepositLimits(payload) {
        depositLimits.value = payload.limits;
        depositLimitsUpdatableAt.value = payload.updatableAt;
    }
    function setSelfExclusion(value) {
        selfExclusion.value = value;
    }
    function setDepositLimitsLoader(payload) {
        depositLimitsLoading.value[payload.limitPeriod] = payload.value;
    }
    function setBetLimitsLoader(payload) {
        betLimitsLoading.value[payload.limitPeriod] = payload.value;
    }
    function setDepositLimitsData(value) {
        depositLimitsData.value = value;
    }
    function setSelfExclusionId(value) {
        selfExclusionId.value = value;
    }
    function setSelfExclusionMonths(value) {
        selfExclusionMonths.value = value;
    }
    function setSelfExclusionRouteBack(value) {
        selfExclusionRouteBack.value = value;
    }
    // Actions:
    async function loadBetLimits() {
        const response = await getBetLimits(api, (node)=>node.queries.limits.getBetLimits);
        if (response?.limits && response.limitsUpdatableAt) setBetLimits({
            limits: response.limits,
            updatableAt: response.limitsUpdatableAt
        });
    }
    async function loadDepositLimits() {
        const response = await getDepositLimits(api, (node)=>node.queries.limits.getDepositLimits);
        if (response?.limits && response.limitsUpdatableAt) setDepositLimits({
            limits: response.limits,
            updatableAt: response.limitsUpdatableAt
        });
    }
    async function loadSelfExclusion() {
        const response = await getSelfExclusion(api, (node)=>node.queries.limits.getDepositLimits);
        if (response?.selfExclusion) setSelfExclusion(response.selfExclusion);
    }
    function pinCodeCheck(originalError, route) {
        const error = convertToBaseError(originalError);
        const errorCode = error.code.toString();
        if (errorCode === PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED) {
            usePinCodeStore().setRouteFrom({
                name: route
            });
            usePinCodeStore().setPinCodePlace(PinCodePlace.SELF_EXCLUSION);
            usePinCodeStore().setStepWithLS('VERIFY');
            throw new Error(errorCode);
        }
    }
    function canNotExcludeCodeCheck(originalError) {
        const error = convertToBaseError(originalError);
        if (error.message === canNotSelfExcludeCode) throw new Error(error.message);
    }
    // TODO: renamed setDepositLimits -> setDepositLimitsAction
    async function setDepositLimitsAction(payload) {
        setDepositLimitsLoader({
            limitPeriod: payload.limitPeriod,
            value: true
        });
        isCustomerLimitsRange(payload.type);
        const response = await setSdkDepositLimits(api, (node)=>node.mutations.limits.setDepositLimits, {
            options: {
                type: payload.type,
                limit: payload.limit
            }
        }).catch((originalError)=>{
            pinCodeCheck(originalError, RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS);
            throw originalError;
        }).finally(()=>{
            setDepositLimitsLoader({
                limitPeriod: payload.limitPeriod,
                value: false
            });
        });
        if (response && response.result && 'OK' === response.result) setDepositLimits({
            limits: response.limits,
            updatableAt: response.limitsUpdatableAt
        });
    }
    // TODO: renamed setBetLimits -> setBetLimitsAction
    async function setBetLimitsAction(payload) {
        setBetLimitsLoader({
            limitPeriod: payload.limitPeriod,
            value: true
        });
        isCustomerLimitsRange(payload.type);
        const response = await setSdkBetLimits(api, (node)=>node.mutations.limits.setBetLimits, {
            options: {
                type: payload.type,
                limit: payload.limit
            }
        }).catch((originalError)=>{
            pinCodeCheck(originalError, RouteName.RESPONSIBLE_GAMBLING_BETTING_LIMITS);
        }).finally(()=>{
            setBetLimitsLoader({
                limitPeriod: payload.limitPeriod,
                value: false
            });
        });
        if (response?.result === 'OK') {
            setBetLimits({
                limits: response.limits,
                updatableAt: response.limitsUpdatableAt
            });
            setResponsibleGamingLimits(responsibleGamingLimitTypeBetLimit, true);
        }
    }
    async function doSelfExclusion(months) {
        const response = await excludeSelf(api, (node)=>node.mutations.limits.doSelfExclusion, {
            options: {
                months
            }
        }).catch((originalError)=>{
            pinCodeCheck(originalError, selfExclusionRouteBack.value ?? RouteName.RESPONSIBLE_GAMBLING_EXCLUSION);
        });
        if (response && response.selfExclusion) setSelfExclusion(response.selfExclusion);
    }
    async function doSelfExclusionFlex(months) {
        const response = await excludeSelfFlex(api, (node)=>node.mutations.limits.doSelfExclusionFlex, {
            options: {
                timeUnitType: SelfExclusionTimeUnitType.MONTHS,
                timeUnitAmount: months
            }
        }).// eslint-disable-next-line sonarjs/no-identical-functions
        catch((originalError)=>{
            pinCodeCheck(originalError, selfExclusionRouteBack.value ?? RouteName.RESPONSIBLE_GAMBLING_EXCLUSION);
            canNotExcludeCodeCheck(originalError);
        });
        if (response?.selfExclusion) setSelfExclusion(response.selfExclusion);
    }
    return {
        // State:
        betLimits,
        betLimitsLoading,
        betLimitsUpdatableAt,
        depositLimits,
        depositLimitsLoading,
        depositLimitsUpdatableAt,
        selfExclusion,
        depositLimitsData,
        selfExclusionId,
        selfExclusionMonths,
        // Mutations:
        setBetLimits,
        setDepositLimits,
        setSelfExclusion,
        setDepositLimitsData,
        setSelfExclusionId,
        setSelfExclusionMonths,
        setSelfExclusionRouteBack,
        // Actions:
        loadBetLimits,
        loadDepositLimits,
        loadSelfExclusion,
        setDepositLimitsAction,
        setBetLimitsAction,
        doSelfExclusion,
        doSelfExclusionFlex
    };
});
export default useResponsibleGamblingStore;
