import { computed, reactive, toRef } from 'vue';
import { ExtendedEventBlock } from '../../enums';
function getUniqElements(ids) {
    return Array.from(new Set([
        ...ids
    ]));
}
export function useExtendedEventIdsStoreComposable(props) {
    const maxInType = toRef(props?.maxInType);
    const eventsIds = reactive({});
    const fullEventsIds = computed(()=>getUniqElements(Object.values(eventsIds).flatMap((ids)=>ids)));
    function addEventId(id) {
        let type = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : ExtendedEventBlock.Default;
        const currentList = eventsIds[type] ?? [];
        let newList = getUniqElements([
            ...currentList,
            id
        ]);
        if (maxInType.value && newList.length > maxInType.value) {
            const diff = newList.length - maxInType.value;
            newList = newList.slice(diff);
        }
        eventsIds[type] = newList;
    }
    function removeEventId(id) {
        let type = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : ExtendedEventBlock.Default;
        const currentList = eventsIds[type] ?? [];
        eventsIds[type] = currentList.filter((value)=>value !== id);
    }
    function createListRef(type) {
        return computed(()=>type.value ? getUniqElements(eventsIds[type.value] ?? []) : []);
    }
    function getIsEventInList(id, type) {
        return (eventsIds[type ?? ExtendedEventBlock.Default] ?? []).includes(id);
    }
    function createIsEventInListRef(id, type) {
        return computed(()=>(eventsIds[type.value ?? ExtendedEventBlock.Default] ?? []).includes(id.value));
    }
    return {
        fullEventsIds,
        addEventId,
        removeEventId,
        createListRef,
        createIsEventInListRef,
        getIsEventInList
    };
}
