import { Storage } from '@leon-hub/storage';
export class LexisNexisSessionStorage extends Storage {
    async getSessionId() {
        const record = await this.get();
        return record.exists ? record.value : void 0;
    }
    async setSessionId(value) {
        let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        return this.set(value, options);
    }
}
