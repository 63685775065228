export var ButtonKind;
var ButtonKind1;
(ButtonKind1 = ButtonKind || (ButtonKind = {}))["PRIMARY"] = "primary", ButtonKind1["PRIMARY_DARK_VERSION"] = "primary-dark-version", ButtonKind1["PRIMARY_OPACITY"] = "primary-opacity", ButtonKind1["SECONDARY"] = "secondary", ButtonKind1["SECONDARY_DARK"] = "secondary-dark", ButtonKind1["BASE"] = "base", ButtonKind1["BASE_OPACITY"] = "base-opacity", ButtonKind1["TRANSPARENT"] = "transparent", ButtonKind1["TRANSPARENT_BLUE"] = "transparent-blue", ButtonKind1["YELLOW"] = "yellow", ButtonKind1["RED"] = "red", ButtonKind1["CANCEL"] = "cancel", ButtonKind1["NAV"] = "nav", ButtonKind1["HEADER"] = "header", ButtonKind1["HEADER_DARK_VERSION"] = "header-dark-version", ButtonKind1["HEADER_ACTIVE"] = "header-active", ButtonKind1["HEADER_ACTIVE_DARK_VERSION"] = "header-active-dark-version", ButtonKind1["MONOCHROME"] = "monochrome", ButtonKind1["DARK_OPACITY"] = "dark-opacity", ButtonKind1["TRANSPARENT_DARK_VERSION"] = "transparent-dark-version"; // --BrandDefault
 // --DBrandDefault
 // --BrandDefault
 // --Layer2
 // --Layer1
 // --Button
 // ----OpacityLayer1
 // --Alert
 // --Red
 // --OpacityDefault
 // --Layer0
