import convertCommonClassesToCssModule from '../../../utils/convertCommonClassesToCssModule';
export default function getClassObject(props) {
    return convertCommonClassesToCssModule({
        'list-item': true,
        'list-item--no-hover': !!props.noHover,
        'list-item--have-switcher': !!props.haveSwitcher,
        'list-item--active': !!props.isActive,
        'list-item--big-margin': !!props.isBigMargin
    });
}
