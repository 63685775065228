import { getDetails } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default async function getDetailsData() {
    const apiClient = useGraphqlClient();
    const response = await getDetails(apiClient, (node)=>node.queries.refProgram.getDetails, {
        options: {}
    }, {
        silent: true
    });
    return response || {};
}
