// TODO:
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function isFile(data) {
    // TODO:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Constructor = 'undefined' != typeof File && File;
    return Constructor && data instanceof Constructor;
}
