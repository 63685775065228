import { computed, onBeforeMount, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { sleep } from '@leon-hub/utils';
import { CustomerFieldType, InvalidPhoneExceptionCode } from '@leon-hub/api-sdk';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { RegistrationApiErrorCode } from 'web/src/modules/registration/enums';
import { useRegistrationCompletionStore } from 'web/src/modules/registration-completion/store';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import { FormControlType, FormErrorKeyword } from 'web/src/components/Form/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useUserStore } from 'web/src/modules/user/store';
import updateFieldErrorByFormError from 'web/src/utils/forms/updateFieldErrorByFormError';
import extractCustomerFormErrors from 'web/src/utils/forms/extractCustomerFormErrors';
import { SimpleRegCompletionRouteParametersHandler } from 'web/src/modules/core/services/router/navigation-guards/registration-completion/utils';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { mapFormData } from 'web/src/components/Form/utils';
import { useCountryStore } from 'web/src/modules/country/store';
import { isUIFormSchema } from 'web/src/components/Form/guards';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
var UpdateIncompleteFieldsErrorCode;
var UpdateIncompleteFieldsErrorCode1;
(UpdateIncompleteFieldsErrorCode1 = UpdateIncompleteFieldsErrorCode || (UpdateIncompleteFieldsErrorCode = {}))["MODIFICATION_BLOCKED_EMAIL"] = "MODIFICATION_BLOCKED_EMAIL", UpdateIncompleteFieldsErrorCode1["MODIFICATION_BLOCKED_MOBILE"] = "MODIFICATION_BLOCKED_MOBILE";
export function useRegistrationCompletionRouteComponent(props, emit) {
    const { $translate } = useI18n();
    const router = useRouter();
    const analytics = useAnalytics();
    const { showDialog } = useDialogs();
    const { convertToBaseError } = useErrorsConverter();
    const isLoading = ref(false);
    const isPending = ref(false);
    const customErrors = ref();
    const remainingDelay = toRef(useSiteConfigStore(), 'millisToSleepAfterCountryChange');
    const registrationCompletionStore = useRegistrationCompletionStore();
    const geoIpCountryCode = toRef(useCountryStore(), 'geoIpCountryCode');
    const { fetchIncompleteRegistrationFields, updateIncompleteFields } = registrationCompletionStore;
    const preparedFormSchemas = toRef(registrationCompletionStore, 'preparedFormSchemas');
    const storeUiSchema = toRef(registrationCompletionStore, 'uiSchema');
    const { loadCustomerData } = useUserStore();
    const pinCodeStore = usePinCodeStore();
    const showCompleteRegistrationFields = toRef(pinCodeStore, 'showCompleteRegistrationFields');
    const pinCodeStep = toRef(pinCodeStore, 'step');
    const schema = computed(()=>preparedFormSchemas.value?.schema);
    const customerAction = computed(()=>props.manualCustomerAction ?? router.getParam('customerAction'));
    const submitButtonLabel = computed(()=>{
        if (customerAction.value === CustomerCompletionAction.ForbiddenCountry) return $translate('WEB2_REGISTRATION_COMPLETION_CONFIRM').value;
        return $translate('WEB2_REGISTRATION_COMPLETION_SAVE').value;
    });
    const uiSchema = computed(()=>{
        if (!preparedFormSchemas.value) return;
        const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
        return {
            ...preparedFormSchemas.value.uiSchema,
            submitButton: {
                label: submitButtonLabel.value
            },
            validatorErrorPatterns: {
                byWidget: {
                    ...phoneErrorPatterns.value,
                    [FormControlType.AsoTermsCheckbox]: {
                        [FormErrorKeyword.Required]: $translate('WEB2_REGISTRATION_FORM_ERROR_REQUIRED_DEFAULT').value
                    }
                }
            }
        };
    });
    const descriptionText = computed(()=>{
        switch(customerAction.value){
            case CustomerCompletionAction.ExAllowedCountry:
                return $translate('WEB2_REGISTRATION_COMPLETION_DESCRIPTION_EXCLUDED_COUNTRY').value;
            case CustomerCompletionAction.ForbiddenCountry:
                return $translate('WEB2_REGISTRATION_COMPLETION_DESCRIPTION_FORBIDDEN_COUNTRY').value;
            case CustomerCompletionAction.Withdrawal:
                return $translate('WEB2_REGISTRATION_DESCRIPTION_WITHDRAWAL').value;
            case CustomerCompletionAction.Deposit:
                return $translate('WEB2_REGISTRATION_COMPLETION_DESCRIPTION_DEPOSITS').value;
            default:
                return props.description || $translate('WEB2_REGISTRATION_COMPLETION_DESCRIPTION').value;
        }
    });
    const isAsoTerms = computed(()=>customerAction.value === CustomerCompletionAction.AsoTerms);
    const isExAllowedCountry = computed(()=>customerAction.value === CustomerCompletionAction.ExAllowedCountry);
    async function fetchFields() {
        if (customerAction.value) {
            isLoading.value = true;
            await fetchIncompleteRegistrationFields({
                customerAction: customerAction.value,
                paymentSystemId: router.getParam('paymentSystemId')
            });
            isLoading.value = false;
        }
    }
    function showError(error, errorCode) {
        if (!error) return;
        const modalProperties = {
            presetName: PresetName.ALERT_WARNING,
            options: {
                title: $translate('JS_CAPTION_ATTENTION').value,
                confirmMessage: error
            }
        };
        if ("MODIFICATION_BLOCKED_EMAIL" === errorCode || "MODIFICATION_BLOCKED_MOBILE" === errorCode) {
            modalProperties.presetName = PresetName.CONFIRM;
            modalProperties.options = {
                title: $translate('WEB2_MODIFICATION_BLOCKED_TITLE').value,
                confirmMessage: "MODIFICATION_BLOCKED_EMAIL" === errorCode ? $translate('WEB2_MODIFICATION_BLOCKED_EMAIL_TXT').value : $translate('WEB2_MODIFICATION_BLOCKED_MOBILE_TXT').value,
                buttons: [
                    {
                        label: $translate('WEB2_HELP_BUTTON_IN_MODAL').value
                    },
                    {
                        label: $translate('WEB2_MODIFICATION_BLOCKED_CLOSE_BTN').value
                    }
                ]
            };
        }
        showDialog(modalProperties).subscribe({
            [DialogAction.CONFIRM]: ()=>{
                router.push({
                    name: RouteName.SUPPORT
                });
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                if (pinCodeStep.value) pinCodeStore.setShowCompleteRegistrationFields(true);
            }
        });
    }
    function handleSubmitError(error, formData) {
        if (error instanceof GqlApiBatchedSubRequestError && storeUiSchema.value) {
            isUIFormSchema(storeUiSchema.value);
            if (error.code.equals(RegistrationApiErrorCode.DUPLICATE_EMAIL)) customErrors.value = updateFieldErrorByFormError(formData, CustomerFieldType.EMAIL, storeUiSchema.value, extractCustomerFormErrors(storeUiSchema.value, formData), error.message);
            if (error.code.equals(InvalidPhoneExceptionCode.INVALID_PHONE)) customErrors.value = updateFieldErrorByFormError(formData, CustomerFieldType.PHONE_INPUT, storeUiSchema.value, extractCustomerFormErrors(storeUiSchema.value, formData), error.message);
        }
        if (!customErrors.value) {
            analytics.push(AnalyticsEvent.MODAL_ERROR_MESSAGES, {
                registration: {
                    error: {
                        message: error.message,
                        code: error.code
                    }
                }
            });
            showError(error.message, `${error.code}`);
        }
    }
    async function makeSubmitRequest(formData) {
        isPending.value = true;
        customErrors.value = void 0;
        const { registrationForm, result } = await updateIncompleteFields(formData);
        const chosenCountryCode = mapFormData(formData)[0].value;
        const redirectLocation = SimpleRegCompletionRouteParametersHandler.getRedirectLocation();
        if ('OK' === result) {
            if (chosenCountryCode !== geoIpCountryCode.value && remainingDelay.value > 0) await sleep(remainingDelay.value);
            await loadCustomerData(true);
            if (registrationForm.hasFieldsErrors) {
                isUIFormSchema(registrationForm.uiFormSchema);
                customErrors.value = updateFieldErrorByFormError(formData, CustomerFieldType.PHONE_INPUT, registrationForm.uiFormSchema, extractCustomerFormErrors(registrationForm.uiFormSchema, formData));
                return;
            }
            if (redirectLocation && customerAction.value !== CustomerCompletionAction.CreatePinCode) {
                SimpleRegCompletionRouteParametersHandler.setRedirectLocation(void 0);
                router.replace(redirectLocation);
                return;
            }
            if (!showCompleteRegistrationFields.value) {
                SimpleRegCompletionRouteParametersHandler.setRedirectLocation(void 0);
                router.replace({
                    name: RouteName.HOME
                });
                return;
            }
            SimpleRegCompletionRouteParametersHandler.setRedirectLocation(void 0);
        }
        emit('after-submit');
    }
    async function onSubmit(data) {
        const { formData, customErrors: formCustomErrors, errors } = data;
        const hasAnyErrors = null !== errors && errors.size > 0 || null !== formCustomErrors && formCustomErrors.size > 0;
        if (!hasAnyErrors) try {
            await makeSubmitRequest(formData);
        } catch (error) {
            handleSubmitError(convertToBaseError(error), formData);
        } finally{
            if (customerAction.value === CustomerCompletionAction.ForbiddenCountry && remainingDelay.value > 0) setTimeout(()=>{
                isPending.value = false;
            }, remainingDelay.value);
            else isPending.value = false;
        }
    }
    const form = ref();
    function onFormMounted() {
        form.value?.focus();
    }
    function validateForm() {
        form.value?.showValidationErrors();
    }
    function onChange() {
        customErrors.value = void 0;
    }
    function goHome() {
        router.push({
            name: RouteName.HOME
        });
    }
    onBeforeMount(()=>{
        if (!schema.value) fetchFields();
    });
    watch(customerAction, fetchFields);
    return {
        schema,
        uiSchema,
        form,
        descriptionText,
        isAsoTerms,
        isExAllowedCountry,
        isLoading,
        isPending,
        customErrors,
        onFormMounted,
        validateForm,
        goHome,
        onSubmit,
        onChange
    };
}
