import { computed } from 'vue';
import getIconProps from './utils/getIconProps';
export default function useBonusLoyaltyProgramBalance(props, emit) {
    const iconProps = computed(()=>getIconProps(props));
    const handleClick = ()=>{
        emit('click');
    };
    return {
        iconProps,
        handleClick
    };
}
