import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { useSportlinePendingApi } from './useSportlinePendingApi';
function getBetlineType(param) {
    let { isExtraLiveEnabled, doAddUpcoming } = param;
    if (doAddUpcoming) return isExtraLiveEnabled ? BetlineType.UpcomingExtraLive : BetlineType.UpcomingLive;
    return isExtraLiveEnabled ? BetlineType.ExtraLive : BetlineType.Live;
}
export function useSportlineApiLoadLiveEvents(props) {
    const { tryRequestWithCTag, defaultRestApiFlags, isLiveOutrightsEnabled, isExtraLiveEnabled } = props;
    const { loadEvents: loadLiveEvents } = useSportlinePendingApi({
        tryRequestWithCTag,
        getEventsInput: (param)=>{
            let { silent, regionId, sportFamily, doAddUpcoming } = param;
            return {
                betline: getBetlineType({
                    isExtraLiveEnabled: isExtraLiveEnabled.value,
                    doAddUpcoming
                }),
                regionId,
                sportFamily,
                silent,
                hideClosed: true,
                flags: [
                    ...defaultRestApiFlags.value
                ],
                LMCE: isLiveOutrightsEnabled.value
            };
        },
        getPendingParams: (param)=>{
            let { vTag, regionId, sportFamily, doAddUpcoming } = param;
            return {
                vTag,
                regionId,
                sportFamily,
                betlines: [
                    getBetlineType({
                        isExtraLiveEnabled: isExtraLiveEnabled.value,
                        doAddUpcoming
                    })
                ]
            };
        }
    });
    return {
        loadLiveEvents
    };
}
