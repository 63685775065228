import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { AvatarSize } from './enums';
import { useVAvatar } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VAvatar',
    props: {
        size: {
            default: AvatarSize.SIZE_44
        },
        imageSrc: {},
        isLoyaltyEnabled: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        function onClick(event) {
            emit('click', event);
        }
        const { buttonProp, baseClasses, buttonTransparent } = useVAvatar(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_unref(baseClasses))
            }, [
                _ctx.isLoyaltyEnabled ? (_openBlock(), _createBlock(VButton, _mergeProps({
                    key: 1
                }, _unref(buttonTransparent), {
                    class: "avatar__button",
                    onClick: onClick
                }), {
                    default: _withCtx(()=>[
                            _ctx.imageSrc ? _renderSlot(_ctx.$slots, "egs-loyalty-program", {
                                key: 0
                            }) : (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                src: require('web/src/assets/images/beginner.svg'),
                                alt: ""
                            }, null, 8, _hoisted_1))
                        ]),
                    _: 3
                }, 16)) : (_openBlock(), _createBlock(VButton, _mergeProps({
                    key: 0
                }, _unref(buttonProp), {
                    class: "avatar__button",
                    onClick: onClick
                }), null, 16))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VAvatar'
                ]
            ]);
        };
    }
});
