import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { TextInput } from 'web/src/components/Input';
import PasswordValidator from 'web/src/components/PasswordValidator';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CardNameInput',
    props: {
        type: {},
        mask: {},
        list: {},
        isYmDisableKeys: {
            type: Boolean
        },
        clickableIcon: {
            type: Boolean
        },
        isHintHidden: {
            type: Boolean
        },
        isLabelHidden: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {},
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        autocomplete: {},
        placeholder: {},
        prefixIconName: {},
        suffixIconName: {},
        maxSymbols: {},
        regexp: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { emitChange, emitInput, emitFocus, emitBlur } = useBaseInputEvents(emit);
        const { $translate } = useI18n();
        const defaultGroups = computed(()=>[
                {
                    rules: [
                        {
                            message: $translate('WEB2_CARDHOLDER_VALIDATION').value,
                            regexp: '^((?:[A-Za-z-.]+ ?){1,})$'
                        }
                    ],
                    requiredValidRules: 1
                }
            ]);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(TextInput), _mergeProps(props, {
                autocomplete: "cc-name",
                "is-ym-disable-keys": "",
                onInput: _unref(emitInput),
                onChange: _unref(emitChange),
                onFocus: _unref(emitFocus),
                onBlur: _unref(emitBlur)
            }), {
                hint: _withCtx((param)=>{
                    let { isFocus, value: inputValue } = param;
                    return [
                        isFocus || inputValue ? (_openBlock(), _createBlock(_unref(PasswordValidator), {
                            key: 0,
                            groups: defaultGroups.value,
                            password: inputValue,
                            "is-focused": isFocus,
                            "success-message": _unref($translate)('WEB2_CARDHOLDER_VALIDATION').value
                        }, null, 8, [
                            "groups",
                            "password",
                            "is-focused",
                            "success-message"
                        ])) : _createCommentVNode("", true)
                    ];
                }),
                _: 1
            }, 16, [
                "onInput",
                "onChange",
                "onFocus",
                "onBlur"
            ])), [
                [
                    _directive_auto_id,
                    'CardNameInput'
                ]
            ]);
        };
    }
});
