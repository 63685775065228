import { isCreditCardTokenizationData } from './guards';
export default function createCreditCardTokenizationApi(client) {
    return {
        async tokenize (param) {
            let { pan } = param;
            return client.request({
                id: 'cards/tokenize',
                endpoint: '/v1/cards/tokenize',
                data: {
                    pan
                },
                silent: false,
                guard: isCreditCardTokenizationData
            });
        }
    };
}
