import { computed } from 'vue';
import { FormSubmitMode } from '../../../enums';
import { getSubmitMode, haveEmptyRequiredFields, isSubmitButtonDisabled } from './utils/isFormReadyUtils';
function useIsFormReady(param) {
    let { validationDisabled, schema, uiSchema, formDataMap, hasAnyErrors } = param;
    const submitMode = computed(()=>getSubmitMode(uiSchema.value));
    const isFormReady = computed(()=>{
        if (validationDisabled) return true;
        const isSubmitButtonEnabled = !isSubmitButtonDisabled(uiSchema.value);
        if (submitMode.value === FormSubmitMode.AlwaysEnabled) return isSubmitButtonEnabled;
        if (submitMode.value === FormSubmitMode.DisabledByErrors) {
            const hasErrors = hasAnyErrors.value || haveEmptyRequiredFields(schema.value, uiSchema.value, formDataMap.value);
            return hasErrors ? !hasErrors : isSubmitButtonEnabled;
        }
        throw new Error('incorrect submit mode');
    });
    return {
        isFormReady
    };
}
export default useIsFormReady;
