import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue";
import { onMounted } from 'vue';
import VStatus from 'web/src/components/Status/VStatus/VStatus.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import usePromotionButton from 'web/src/modules/promotions/components/PromotionButton/composables/usePromotionButton';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionButton',
    props: {
        buttonItem: {},
        isDisabled: {
            type: Boolean
        },
        buttonIsLoading: {
            type: Boolean
        }
    },
    emits: [
        "participate",
        "leaderboard",
        "exchange",
        "login",
        "on-bonus-wallet",
        "custom",
        "play",
        "identification-needed"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isStickied, promotionButton, onClick, showButtonStatus, buttonLabel, isStatusIcon, showButton, buttonProperties, onComponentMounted } = usePromotionButton(props, emit);
        onMounted(onComponentMounted);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "promotionButton",
                ref: promotionButton,
                class: _normalizeClass({
                    [_ctx.$style['promotion-details-button']]: true,
                    [_ctx.$style['promotion-details-button--is-stickied']]: _unref(isStickied)
                })
            }, [
                _ctx.buttonItem?.hintMessage ? _withDirectives((_openBlock(), _createBlock(VStatus, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['promotion-details-button__status']),
                    text: _ctx.buttonItem?.hintMessage,
                    "is-lower-case": ""
                }, null, 8, [
                    "class",
                    "text"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'promotion-button-status-hint'
                        }
                    ]
                ]) : _createCommentVNode("", true),
                _unref(showButtonStatus) ? _withDirectives((_openBlock(), _createBlock(VStatus, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['promotion-details-button__button']),
                    text: _unref(buttonLabel),
                    "is-icon": _unref(isStatusIcon),
                    "is-success": "",
                    onClick: _unref(onClick)
                }, null, 8, [
                    "class",
                    "text",
                    "is-icon",
                    "onClick"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'promotion-button-status-button'
                        }
                    ]
                ]) : _createCommentVNode("", true),
                _unref(showButton) ? _withDirectives((_openBlock(), _createBlock(VButton, _mergeProps({
                    key: 2
                }, _unref(buttonProperties), {
                    class: _ctx.$style['promotion-details-button__button'],
                    label: _unref(buttonLabel),
                    "is-loading": _ctx.buttonIsLoading,
                    onClick: _unref(onClick)
                }), null, 16, [
                    "class",
                    "label",
                    "is-loading",
                    "onClick"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'promotion-button'
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionButton'
                ]
            ]);
        };
    }
});
