import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VPhotoPlaceholderDirection } from '../enums';
export default function useVPhotoPlaceholder(props) {
    const cameraIconProps = {
        name: IconName.CAMERA,
        size: IconSize.SIZE_24
    };
    const rootClassName = computed(()=>({
            'v-photo-placeholder': true,
            'v-photo-placeholder--vertical': props.direction === VPhotoPlaceholderDirection.Vertical,
            'v-photo-placeholder--horizontal': props.direction === VPhotoPlaceholderDirection.Horizontal
        }));
    return {
        cameraIconProps,
        rootClassName
    };
}
