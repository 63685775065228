import { buildRegionNavigationParameters } from 'web/src/modules/sportline/utils/rest/navigation-parameters';
export default function parseRegionResponse(options) {
    const navigationParameters = buildRegionNavigationParameters(options);
    const id = String(options.regionResponse.id);
    const region = {
        id,
        name: options.regionResponse.name,
        // group by family or use id like single family group
        regionFamily: options.regionResponse.family || id,
        representation: {
            icon: options.regionResponse.family || null
        },
        navigationParameters
    };
    return {
        region
    };
}
