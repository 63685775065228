import { ref, nextTick } from 'vue';
import { FocusedInput, DateTimeFilterTabId } from 'web/src/modules/sportline/enums';
import DateTime from 'web/src/utils/DateTime';
import DateTimeInterval from 'web/src/utils/DateTimeInterval';
import { getFilterSelectFilterPayload } from 'web/src/modules/sportline/components/date-time-filter/utils';
import { useDateTimeFilterWatchProps } from './useDateTimeFilterWatchProps';
function createInitialDateRange() {
    const initialDateRange = DateTimeInterval.fromNowUntilTheEndOfTheDay();
    return {
        from: initialDateRange.from.toTimestamp(),
        to: initialDateRange.to.toTimestamp()
    };
}
export function useDateTimeFilter(props, emit) {
    const { activeKickoffPeriod, activeSportlineType, selectedRangeFrom, selectedRangeTo } = props;
    let backToTabAfterCustomRange = DateTimeFilterTabId.ALL;
    let lastSelectedCustomRange = createInitialDateRange();
    const tabsRef = ref();
    const showSelectCustomRangeForm = ref(false);
    const focusedLocal = ref(FocusedInput.NONE);
    const preparedForActivateTab = ref(DateTimeFilterTabId.ALL);
    function selectDateTimeRange(range) {
        lastSelectedCustomRange = range;
        emit('select-range', range);
    }
    function slideToActive() {
        tabsRef.value?.slideToActive();
    }
    function setFocused(state) {
        focusedLocal.value = state;
    }
    function emitSelectFilterForTabId(id) {
        emit('select-filter', getFilterSelectFilterPayload(id, {
            selectedRangeFrom: selectedRangeFrom.value,
            selectedRangeTo: selectedRangeTo.value,
            lastSelectedCustomRange
        }));
    }
    function setActiveTab(to, from) {
        if (to === from) // @TODO check extra setActiveTab
        return;
        if (to === DateTimeFilterTabId.DATE_RANGE) {
            showSelectCustomRangeForm.value = true;
            backToTabAfterCustomRange = from && from !== DateTimeFilterTabId.DATE_RANGE ? from : DateTimeFilterTabId.ALL;
        } else showSelectCustomRangeForm.value = false;
        preparedForActivateTab.value = to;
    }
    function onActivateTabClick(to) {
        setActiveTab(to, preparedForActivateTab.value);
        emitSelectFilterForTabId(to);
    }
    async function hideSelectCustomRange() {
        setActiveTab(backToTabAfterCustomRange, preparedForActivateTab.value);
        emitSelectFilterForTabId(backToTabAfterCustomRange);
        await nextTick();
        tabsRef.value?.scrollToSlide();
    }
    async function clearCustomRange() {
        lastSelectedCustomRange = createInitialDateRange();
        await hideSelectCustomRange();
    }
    function rangeFromChanged(param) {
        let { timestamp } = param;
        if (!timestamp) {
            clearCustomRange();
            return;
        }
        const selectedTo = selectedRangeTo.value ? Math.max(selectedRangeTo.value, timestamp) : timestamp;
        const to = DateTime.roundToDayEnd(selectedTo);
        selectDateTimeRange({
            from: timestamp,
            to
        });
        setFocused(FocusedInput.NONE);
    }
    function rangeToChanged(param) {
        let { timestamp } = param;
        if (!timestamp) {
            clearCustomRange();
            return;
        }
        // @TODO remove round to logic, it must be implemented in the datetime picker
        const selectedFrom = selectedRangeFrom.value ? Math.min(selectedRangeFrom.value, timestamp) : timestamp;
        const minFrom = DateTime.now().toTimestamp();
        const rounded = DateTime.roundToDayStart(selectedFrom);
        const from = Math.max(rounded, minFrom);
        selectDateTimeRange({
            from,
            to: timestamp
        });
        setFocused(FocusedInput.NONE);
    }
    useDateTimeFilterWatchProps({
        activeKickoffPeriod,
        activeSportlineType,
        preparedForActivateTab,
        setActiveTab
    });
    return {
        tabsRef,
        showSelectCustomRangeForm,
        focusedLocal,
        preparedForActivateTab,
        slideToActive,
        setFocused,
        onActivateTabClick,
        hideSelectCustomRange,
        rangeFromChanged,
        rangeToChanged
    };
}
