import { defineStore } from 'pinia';
import { ref } from 'vue';
import uuid from 'uuid';
import { bindIsArrayOf, isObject } from '@leon-hub/guards';
import { isDebugEnabled } from '@leon-hub/debug';
import { Json } from '@leon-hub/utils';
import { IconName } from '@leon-hub/icons';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import useI18n from 'web/src/modules/i18n/composables/useI18n';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';
const useSnackbarsStore = defineStore('snackbars', ()=>{
    const localStorageManager = useLocalStorageManager();
    const { $translate } = useI18n();
    const gqlClient = useGraphqlClient();
    const baseApiClient = useBaseApiClient();
    const snackbars = ref([]);
    let connectionLostRoute = '';
    let connectionErrorResolver = null;
    function setConnectionLostRoute(value) {
        connectionLostRoute = value;
    }
    function update(options) {
        const { id } = options;
        const snackbarItem = snackbars.value.findIndex((item)=>item.id === id);
        if (-1 !== snackbarItem) snackbars.value[snackbarItem] = {
            ...snackbars.value[snackbarItem],
            ...options
        };
    }
    function show(options) {
        const id = options.id || uuid.v4();
        let snackbar = snackbars.value.find((item)=>item.id === id);
        if (!snackbar) {
            snackbar = {
                ...options,
                id
            };
            snackbars.value.push(snackbar);
        }
        return snackbar;
    }
    async function hide(id) {
        const snackbar = snackbars.value.find((item)=>item.id === id);
        if (snackbar) {
            await snackbar.onHide?.();
            snackbars.value = snackbars.value.filter((item)=>item.id !== id);
        }
    }
    function logConnectionError(error) {
        try {
            if (isDebugEnabled()) {
                const debugData = localStorageManager.getItem('ApiConnectionErrors');
                let parsedDebugData = [];
                if (debugData) parsedDebugData = Json.parse(debugData, {
                    validator: bindIsArrayOf(isObject)
                }) ?? [];
                parsedDebugData.push(error ? {
                    date: new Date().toISOString(),
                    ...error.toJSON()
                } : {
                    date: new Date().toISOString(),
                    name: 'Unknown error'
                });
                if (parsedDebugData.length > 10) parsedDebugData.shift();
                localStorageManager.setItem('ApiConnectionErrors', Json.stringify(parsedDebugData) || '[]');
            }
        } catch  {}
    }
    // empty
    function reloadConnectionLostPage() {
        if (connectionLostRoute) window.location.href = connectionLostRoute;
        else window.location.reload();
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function showConnectionError() {
        let isCancelHidden = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false, error = arguments.length > 1 ? arguments[1] : void 0;
        logConnectionError(error);
        const id = 'connection-lost';
        if (!connectionErrorResolver) connectionErrorResolver = new Promise((resolve, reject)=>{
            show({
                id,
                title: $translate('WEB2_CONNECTION_ERROR_CAPTION').value,
                text: $translate('WEB2_CONNECTION_ERROR_DESCRIPTION').value,
                iconName: IconName.ATTENTION,
                image: void 0,
                type: 'error',
                accept: {
                    label: $translate('WEB2_CONNECTION_ERROR_BTN_RETRY').value,
                    action: ()=>{
                        "1";
                        reloadConnectionLostPage();
                        reject(new Error('Reload window from snackbar'));
                        return Promise.resolve();
                    }
                },
                cancel: {
                    label: $translate('JSP_PCL_FBOT_CLOSE').value,
                    action: ()=>{
                        reject(new Error('Snackbar canceled'));
                        return Promise.resolve();
                    }
                },
                onHide: ()=>{
                    connectionErrorResolver = null;
                    return Promise.resolve();
                }
            });
        });
        if (isCancelHidden) {
            const snackbar = snackbars.value.find((item)=>item.id === id);
            if (snackbar) snackbar.cancel = void 0;
        }
        return connectionErrorResolver;
    }
    gqlClient.setConnectionErrorResolver((error)=>showConnectionError(false, error));
    baseApiClient.setConnectionErrorResolver((error)=>showConnectionError(false, error));
    return {
        snackbars,
        setConnectionLostRoute,
        showConnectionError,
        show,
        hide,
        update
    };
});
export default useSnackbarsStore;
