import { GetEventSubscriptionStatus, GetEventSubscriptionAction } from './enums';
export function createGetEventRequestOnInitBehaviour(id, doAction) {
    function getAction(status, count) {
        switch(status){
            case GetEventSubscriptionStatus.OK:
                // we've got the event data
                return GetEventSubscriptionAction.Finish;
            case GetEventSubscriptionStatus.Missing:
                // The event is missing (maybe we have statistic), do redirects
                return GetEventSubscriptionAction.Redirect;
            case GetEventSubscriptionStatus.Error:
                // too many requests, show snackbar
                if (count >= 3) return GetEventSubscriptionAction.ShowError;
                // await next response
                return GetEventSubscriptionAction.AwaitNext;
            case GetEventSubscriptionStatus.Unsubscribe:
                return GetEventSubscriptionAction.Unsubscribe;
            case GetEventSubscriptionStatus.None:
            default:
                // first load
                return GetEventSubscriptionAction.AwaitNext;
        }
    }
    async function onStatus(status, count) {
        const action = getAction(status, count);
        await doAction(action);
        return action;
    }
    return {
        id,
        onStatus
    };
}
