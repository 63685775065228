import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "onClick"
];
import { IconSize, IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
import { VCover } from 'web/src/components/Cover';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { resolveRegionPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import VLinkPrevented from 'web/src/components/Link/VLinkPrevented/VLinkPrevented.vue';
import RegionLogo from 'web/src/modules/sportline/components/SportlineLogo/RegionLogo.vue';
import LeaguesList from '../leagues-list/LeaguesList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegionSpoiler',
    props: {
        sportElement: {},
        regionElement: {},
        dateTimeFilter: {},
        isCyberSportPage: {
            type: Boolean
        },
        isChildRoute: {
            type: Boolean
        },
        isOpen: {
            type: Boolean
        }
    },
    emits: [
        "change-open-state"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createVNode(_unref(VCover), {
                    opened: _ctx.isOpen,
                    onChange: _cache[0] || (_cache[0] = ($event)=>emit('change-open-state', $event))
                }, {
                    default: _withCtx((coverScope)=>[
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['sports-level-1-spoiler']]: true,
                                    [_ctx.$style['sports-level-1-spoiler__cover']]: true,
                                    [_ctx.$style['sports-level-1-spoiler--default']]: true,
                                    [_ctx.$style['sports-level-1-spoiler--open']]: coverScope.isOpen
                                })
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['sports-level-1-spoiler__header']]: true,
                                        [_ctx.$style['sports-level-1-spoiler__header--close']]: !coverScope.isOpen,
                                        [_ctx.$style['sports-level-1-spoiler__header--open']]: coverScope.isOpen
                                    })
                                }, [
                                    _createVNode(RegionLogo, {
                                        size: _unref(IconSize).SIZE_20,
                                        "flag-size": _unref(VCountryFlagSize).SIZE_16,
                                        "country-code": _ctx.regionElement.region.representation.icon,
                                        "sport-segment-id": _ctx.isCyberSportPage ? _unref(SportSegmentId).CyberSport : void 0,
                                        class: _normalizeClass(_ctx.$style['region-icon'])
                                    }, null, 8, [
                                        "size",
                                        "flag-size",
                                        "country-code",
                                        "sport-segment-id",
                                        "class"
                                    ]),
                                    _createVNode(VLinkPrevented, {
                                        class: _normalizeClass({
                                            [_ctx.$style['sports-level-1-spoiler__title']]: true,
                                            [_ctx.$style['sports-level-1-spoiler__title--open']]: coverScope.isOpen
                                        }),
                                        to: _unref(resolveRegionPageLink)(_ctx.regionElement.region.navigationParameters),
                                        onClick: coverScope.toggle
                                    }, {
                                        default: _withCtx(()=>[
                                                _createTextVNode(_toDisplayString(_ctx.regionElement.region.name), 1)
                                            ]),
                                        _: 2
                                    }, 1032, [
                                        "class",
                                        "to",
                                        "onClick"
                                    ]),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['sports-level-1-spoiler__indicator']),
                                        onClick: coverScope.toggle
                                    }, [
                                        _createVNode(_unref(VIcon), {
                                            size: _unref(IconSize).SIZE_16,
                                            name: coverScope.isOpen ? _unref(IconName).EXPAND_UP : _unref(IconName).EXPAND_DOWN
                                        }, null, 8, [
                                            "size",
                                            "name"
                                        ])
                                    ], 10, _hoisted_1)
                                ], 2),
                                coverScope.isLoaded ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['sports-level-1-spoiler__content'])
                                }, [
                                    _createVNode(LeaguesList, {
                                        sport: _ctx.sportElement.sport,
                                        leagues: _ctx.regionElement.leagues,
                                        "date-time-filter": _ctx.dateTimeFilter
                                    }, null, 8, [
                                        "sport",
                                        "leagues",
                                        "date-time-filter"
                                    ])
                                ], 2)), [
                                    [
                                        _vShow,
                                        coverScope.isOpen
                                    ]
                                ]) : _createCommentVNode("", true)
                            ], 2)
                        ]),
                    _: 1
                }, 8, [
                    "opened"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'RegionSpoiler'
                ]
            ]);
        };
    }
});
