import { DateTimeFilterTabId, SportFilterKickoffPeriod } from 'web/src/modules/sportline/enums';
export const defaultMapTabIdToKickoffPeriod = {
    [DateTimeFilterTabId.ALL]: SportFilterKickoffPeriod.ALL,
    [DateTimeFilterTabId.TODAY]: SportFilterKickoffPeriod.TODAY,
    [DateTimeFilterTabId.TOMORROW]: SportFilterKickoffPeriod.TOMORROW,
    [DateTimeFilterTabId.AFTERTOMORROW]: SportFilterKickoffPeriod.AFTERTOMORROW,
    [DateTimeFilterTabId.ONEHOUR]: SportFilterKickoffPeriod.ONEHOUR,
    [DateTimeFilterTabId.TWOHOURS]: SportFilterKickoffPeriod.TWOHOURS,
    [DateTimeFilterTabId.THREEHOURS]: SportFilterKickoffPeriod.THREEHOURS,
    [DateTimeFilterTabId.SIXHOURS]: SportFilterKickoffPeriod.SIXHOURS,
    [DateTimeFilterTabId.TWELVEHOURS]: SportFilterKickoffPeriod.TWELVEHOURS,
    [DateTimeFilterTabId.TWENTYFOURHOURS]: SportFilterKickoffPeriod.TWENTYFOURHOURS
};
export const defaultTabIds = Object.keys(defaultMapTabIdToKickoffPeriod);
