import { toRef, computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import { resolveRegionPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useSportlineBreadcrumbsRegionItem(props) {
    const defaultRegion = toRef(props.region);
    const { $translate } = useI18n();
    const sportlineBreadcrumbsStore = useSportlineBreadcrumbsStore();
    const regionElement = toRef(sportlineBreadcrumbsStore, 'regionElement');
    const relatedRegions = toRef(sportlineBreadcrumbsStore, 'relatedRegions');
    const region = computed(()=>defaultRegion.value ?? regionElement.value?.region ?? null);
    const isShown = computed(()=>!!region.value || relatedRegions.value.length > 0);
    const title = computed(()=>region.value?.name || $translate('JSP_SPORT_NAV_DEFAULT_REGION').value);
    const pageLocation = computed(()=>region.value ? resolveRegionPageLink(region.value.navigationParameters) : void 0);
    return {
        isShown,
        title,
        regionElement,
        relatedRegions,
        pageLocation
    };
}
