import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useDoSavePersistLocation } from './composables';
export const useSportlinePersistFilterStore = defineStore('sportline-persist-filter', ()=>{
    const doSavePersistLocation = useDoSavePersistLocation();
    const persistLocation = ref({});
    function setPersistLocation(key, value, permissionKey) {
        const current = persistLocation.value;
        const permission = doSavePersistLocation[permissionKey ?? key];
        if (null === permission.value) return;
        if (value && permission.value) current[key] = value;
        else delete current[key];
        persistLocation.value = current;
    }
    function resetPersistLocation(key) {
        if (key) {
            const current = persistLocation.value;
            delete current[key];
            persistLocation.value = current;
            return;
        }
        persistLocation.value = {};
    }
    return {
        persistLocation,
        setPersistLocation,
        resetPersistLocation
    };
});
