import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconSize } from '@leon-hub/icons';
import HighestOddsIcon from './HighestOddsIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HighestOddsBadge',
    props: {
        highestOddsIconColor: {},
        badgeBackground: {
            default: 'default'
        },
        badgeHeight: {
            default: '12'
        },
        doHideTextForSmallSize: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['highest-odds-badge']]: true,
                    [_ctx.$style[`highest-odds-badge--${_ctx.badgeBackground}`]]: true,
                    [_ctx.$style[`highest-odds-badge--paddings-for-${_ctx.badgeHeight}`]]: 'transparent' !== _ctx.badgeBackground,
                    [_ctx.$style[`highest-odds-badge--height-${_ctx.badgeHeight}`]]: true
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['highest-odds-badge__content']]: true,
                        [_ctx.$style['highest-odds-badge__content--multiline']]: _ctx.doHideTextForSmallSize
                    })
                }, [
                    _createVNode(HighestOddsIcon, {
                        class: _normalizeClass(_ctx.$style['highest-odds-badge__icon']),
                        "highest-odds-icon-color": _ctx.highestOddsIconColor,
                        "icon-size": '16' === _ctx.badgeHeight ? _unref(IconSize).SIZE_12 : _unref(IconSize).SIZE_10
                    }, null, 8, [
                        "class",
                        "highest-odds-icon-color",
                        "icon-size"
                    ]),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['highest-odds-badge__label'])
                    }, _toDisplayString(_ctx.$t('WEB2_SPORT_EVENTS_IMPROVED_ODDS')), 3)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'HighestOddsBadge'
                ]
            ]);
        };
    }
});
