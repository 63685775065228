import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaderBoardWrapper',
    props: {
        subheader: {
            default: ''
        }
    },
    setup (__props, param) {
        let { expose: __expose } = param;
        const wrapper = ref();
        function getOffsetTop() {
            return wrapper.value?.offsetTop || 0;
        }
        function scrollIntoView(options) {
            wrapper.value?.scrollIntoView(options);
        }
        __expose({
            getOffsetTop,
            scrollIntoView
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "wrapper",
                ref: wrapper,
                class: _normalizeClass(_ctx.$style['leader-board-wrapper'])
            }, [
                _createElementVNode("h2", {
                    class: _normalizeClass(_ctx.$style['leader-board-wrapper__header'])
                }, _toDisplayString(_ctx.$t('JSPNAV_LEADERBOARD_TTL')), 3),
                _ctx.subheader ? (_openBlock(), _createElementBlock("h3", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['leader-board-wrapper__sub-header'])
                }, _toDisplayString(_ctx.subheader), 3)) : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaderBoardWrapper'
                ]
            ]);
        };
    }
});
