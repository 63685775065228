import { computed } from 'vue';
import { toPattern } from 'vanilla-masker';
import { handleMaskedInput, handleMaskedKeydown, formatValueToPattern, getOnlyDigitsFromString } from './utils';
export default function useMaskedInput(param) {
    let { maskOptions, inputValue, isFocus } = param;
    const formattedValue = computed(()=>maskOptions.value ? formatValueToPattern(inputValue.value, maskOptions.value) : inputValue.value);
    const isMaskedPlaceholderHidden = computed(()=>!isFocus.value && !inputValue.value);
    const maskedValue = computed(()=>{
        if (!maskOptions.value) return inputValue.value;
        const placeholder = maskOptions.value?.placeholder;
        if (placeholder && placeholder.length > 1) return formattedValue.value + placeholder.slice(formattedValue.value.length);
        // if placeholder is one symbol for filling empty data: phone
        return toPattern(inputValue.value || '', maskOptions.value);
    });
    const getValueCutToPattern = ()=>{
        if (!maskOptions.value) return inputValue.value;
        const { pattern } = maskOptions.value;
        const patternLength = getOnlyDigitsFromString(pattern).length;
        return inputValue.value.slice(0, patternLength);
    };
    const getMaskedInputResult = (event)=>{
        if (!maskOptions.value) return null;
        return handleMaskedInput({
            event,
            maskOptions: maskOptions.value,
            currentValue: formattedValue.value
        });
    };
    const getMaskedKeydownResult = (event)=>{
        if (!maskOptions.value) return null;
        return handleMaskedKeydown({
            event,
            currentValue: formattedValue.value,
            maskOptions: maskOptions.value
        });
    };
    return {
        maskedValue,
        formattedValue,
        isMaskedPlaceholderHidden,
        getValueCutToPattern,
        getMaskedInputResult,
        getMaskedKeydownResult
    };
}
