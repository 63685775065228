import { computed, toRef } from 'vue';
import { defaultSportsMarketColumns, defaultMarketColumnsOptions } from 'web/src/modules/sportline/constants';
import { selectMarketTypeByColumn, placeRunnersToColumns } from 'web/src/modules/sportline/utils/markets';
export function useMarketColumns(props) {
    const { sport, markets, selectedColumnSize, selectedColumnId, columns } = props;
    const doUseFullAlternativeMarketsList = toRef(props.doUseFullAlternativeMarketsList ?? false);
    const actualMarketType = computed(()=>selectMarketTypeByColumn({
            columns: columns.value,
            selectedColumnId: selectedColumnId.value,
            allMarketsTypes: markets.value.map((market)=>market.type),
            doUseFullAlternativeMarketsList: doUseFullAlternativeMarketsList.value
        }));
    const actualMarketTypePriority = computed(()=>actualMarketType.value?.priority ?? 0);
    const actualMarket = computed(()=>{
        const marketType = actualMarketType.value;
        if (!marketType) return null;
        return markets.value.find((market)=>market.type.id === marketType.id) ?? null;
    });
    const marketColumnsCount = computed(()=>{
        if (selectedColumnSize.value) return selectedColumnSize.value;
        const defaultSportColumnInfo = sport.value ? defaultSportsMarketColumns[sport.value.representation.family] : void 0;
        return defaultSportColumnInfo?.size || 3;
    });
    const runnersPlaces = computed(()=>placeRunnersToColumns({
            market: actualMarket.value,
            marketType: actualMarketType.value,
            sportFamily: sport.value?.representation.family
        }, defaultMarketColumnsOptions));
    return {
        actualMarket,
        actualMarketType,
        actualMarketTypePriority,
        marketColumnsCount,
        runnersPlaces
    };
}
