import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = [
    "onClick"
];
import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { Theme } from '@leon-hub/api-sdk';
import { VIcon } from '@components/v-icon';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ThemeList',
    props: {
        theme: {}
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const filteredTheme = computed(()=>{
            if (props.theme) {
                const array = props.theme;
                return array.sort((a, b)=>{
                    const order = {
                        [Theme.AUTO]: 1,
                        [Theme.DARK]: 2,
                        [Theme.LIGHT]: 3,
                        [Theme.MIDNIGHT]: 4
                    };
                    return order[a.theme] - order[b.theme];
                });
            }
            return [];
        });
        function getIconName(themeName) {
            switch(themeName){
                case Theme.DARK:
                    return IconName.NIGHT;
                case Theme.LIGHT:
                    return IconName.DAY;
                case Theme.AUTO:
                    return IconName.BRIGHTNESS_AUTO;
                default:
                    return;
            }
        }
        return (_ctx, _cache)=>_ctx.theme?.length ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['theme-list'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredTheme.value, (item)=>(_openBlock(), _createElementBlock("div", {
                        key: item.theme,
                        class: _normalizeClass({
                            [_ctx.$style['theme-list__item']]: true,
                            [_ctx.$style['theme-list__item--active']]: item.isActive
                        }),
                        onClick: ($event)=>emit('change', item.theme)
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['theme-list__left'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['theme-list__icon'])
                            }, [
                                _createVNode(_unref(VIcon), {
                                    name: getIconName(item.theme)
                                }, null, 8, [
                                    "name"
                                ])
                            ], 2),
                            _createElementVNode("div", null, _toDisplayString(item.name), 1)
                        ], 2),
                        item.isActive ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['theme-list__right'])
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: _unref(IconName).CHECK_FILLED,
                                size: _unref(IconSize).SIZE_16
                            }, null, 8, [
                                "name",
                                "size"
                            ])
                        ], 2)) : _createCommentVNode("", true)
                    ], 10, _hoisted_1))), 128))
            ], 2)) : (_openBlock(), _createBlock(_unref(VEmpty), {
                key: 1
            }));
    }
});
