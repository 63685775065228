import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { IconName, IconSize, getAlertIcon } from '@leon-hub/icons';
import { VJumbotron, JumbotronFooterButton } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { AlertIconName } from 'web/src/components/Icon/AlertIcon/types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameIframeError',
    props: {
        type: {},
        text: {},
        title: {},
        button: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const icon = computed(()=>{
            switch(props.type){
                case 'unavailable':
                    return IconName.GAME_ERROR;
                case 'provider-block':
                case 'game-block':
                    return IconName.LOCATION_RESTRICTED;
                default:
                    return;
            }
        });
        const image = computed(()=>{});
        return (_ctx, _cache)=>{
            const _component_noindex = _resolveComponent("noindex");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-game-iframe-error']]: true
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-game-iframe-error__inner'])
                }, [
                    _createVNode(_component_noindex, null, {
                        default: _withCtx(()=>[
                                _createVNode(_unref(VJumbotron), {
                                    text: _ctx.text,
                                    heading: _ctx.title,
                                    icon: icon.value,
                                    "icon-size": _unref(IconSize).SIZE_80,
                                    image: image.value
                                }, _createSlots({
                                    _: 2
                                }, [
                                    _ctx.button ? {
                                        name: "footer",
                                        fn: _withCtx(()=>[
                                                _createVNode(_unref(JumbotronFooterButton), {
                                                    kind: _unref(ButtonKind).PRIMARY,
                                                    "full-width": "",
                                                    label: _ctx.button.text,
                                                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>emits('click', _ctx.button.action), [
                                                        "prevent"
                                                    ]))
                                                }, null, 8, [
                                                    "kind",
                                                    "label"
                                                ])
                                            ]),
                                        key: "0"
                                    } : void 0
                                ]), 1032, [
                                    "text",
                                    "heading",
                                    "icon",
                                    "icon-size",
                                    "image"
                                ])
                            ]),
                        _: 1
                    })
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameIframeError'
                ]
            ]);
        };
    }
});
