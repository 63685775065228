import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount } from 'vue';
import VFeedbackComposition from 'web/src/components/FeedbackComposition/VFeedbackComposition/VFeedbackComposition.vue';
import { useSupportFeedbackLegacyRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SupportFeedbackLegacyRoutePage',
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { feedbackFormMaxChars, haveValidEmailValue, submitting, isClearTextarea, isApiErrorEmailMessage, savedErrorDuplicateEmail, submit, closeSuccessDialog } = useSupportFeedbackLegacyRoutePage(emit);
        onBeforeUnmount(()=>{
            closeSuccessDialog();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['feedback-legacy-page'])
            }, [
                _createVNode(VFeedbackComposition, {
                    description: _ctx.$t('WEB2_YOUR_FEEDBACK_DESCRIPTION'),
                    "feedback-form-max-chars": _unref(feedbackFormMaxChars),
                    "have-valid-email-value": _unref(haveValidEmailValue),
                    submitting: _unref(submitting),
                    "is-clear-textarea": _unref(isClearTextarea),
                    "is-api-error-email-message": _unref(isApiErrorEmailMessage),
                    "saved-error-duplicate-email": _unref(savedErrorDuplicateEmail),
                    onSubmit: _unref(submit)
                }, null, 8, [
                    "description",
                    "feedback-form-max-chars",
                    "have-valid-email-value",
                    "submitting",
                    "is-clear-textarea",
                    "is-api-error-email-message",
                    "saved-error-duplicate-email",
                    "onSubmit"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'SupportFeedbackLegacyRoutePage'
                ]
            ]);
        };
    }
});
