import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useSportlineEventId, useSportlineEventTypeRef } from 'web/src/modules/sportline/composables/sportline-event';
import { useStreamAvailableIndicator } from 'web/src/modules/sportline/submodules/streams/composables';
import { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import { useSportlinePrematchMetaInfo } from './useSportlinePrematchMetaInfo';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventPrematchMetaInfo',
    props: {
        upcomingLabelType: {
            default: null
        },
        sportlineEvent: {}
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(()=>props.sportlineEvent);
        const upcomingLabelType = toRef(()=>props.upcomingLabelType);
        const sportlineEventId = useSportlineEventId(sportlineEvent);
        const sportlineType = useSportlineEventTypeRef(sportlineEvent);
        const kickoff = computed(()=>sportlineEvent.value.kickoff);
        const { willComeLabel, startsInLabel, date, time } = useSportlinePrematchMetaInfo({
            kickoff
        });
        const { isStreamIndicatorAvailable } = useStreamAvailableIndicator({
            sportEventId: sportlineEventId,
            sportlineType
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportline-event-meta-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-event-meta-info__left-column'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['kickoff-countdown'])
                    }, [
                        upcomingLabelType.value === _unref(UpcomingLabelType).StaticLabel ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['kickoff-countdown--filled'])
                        }, _toDisplayString(_ctx.$t('WEB2_EVENT_WILL_START_SOON')), 3)) : upcomingLabelType.value === _unref(UpcomingLabelType).Timer ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createTextVNode(_toDisplayString(_unref(willComeLabel)) + " ", 1),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['kickoff-countdown__value'])
                            }, _toDisplayString(_unref(startsInLabel)), 3)
                        ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 2
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['kickoff-countdown__date'])
                            }, _toDisplayString(_unref(date)), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['kickoff-countdown__time'])
                            }, _toDisplayString(_unref(time)), 3)
                        ], 64))
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-event-meta-info__right-column'])
                }, [
                    _unref(isStreamIndicatorAvailable) ? (_openBlock(), _createBlock(_unref(VIcon), {
                        key: 0,
                        name: _unref(IconName).STREAM,
                        size: _unref(IconSize).SIZE_12,
                        class: _normalizeClass(_ctx.$style['stream-indicator'])
                    }, null, 8, [
                        "name",
                        "size",
                        "class"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventPrematchMetaInfo'
                ]
            ]);
        };
    }
});
