import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getRules } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useRulesStore = defineStore('rules', ()=>{
    /** State */ const navigation = ref([]);
    /** Actions */ async function getRulesNavigation(silent) {
        const { rules } = await getRules(useGraphqlClient(), (node)=>node.queries.rules.getRules, {}, {
            silent
        });
        setRulesNavigation(convertRulesToNavigationItems(rules));
    }
    function setRulesNavigation() {
        let navItems = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
        navigation.value = navItems;
    }
    function convertRuleToNavigationItem(rule) {
        const { children } = rule;
        const subChildren = children.map((item)=>({
                id: item.id,
                caption: item.caption,
                isPrintEnabled: item.isPrintEnabled,
                children: item?.children ? item.children.map((ruleItem)=>({
                        id: ruleItem.id,
                        caption: ruleItem.caption,
                        isPrintEnabled: ruleItem.isPrintEnabled,
                        props: {
                            contentKey: ruleItem.contentPageKey
                        }
                    })) : []
            }));
        return {
            id: rule.id,
            caption: rule.caption,
            isPrintEnabled: rule.isPrintEnabled,
            children: subChildren
        };
    }
    function convertRulesToNavigationItems(rules) {
        return rules.map((item)=>convertRuleToNavigationItem(item));
    }
    return {
        navigation,
        getRulesNavigation
    };
});
export default useRulesStore;
