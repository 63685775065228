import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useCountSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import { SportsListAdapter } from 'web/src/modules/sportline/utils/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
const useSportsTreeStore = defineStore('sportline-sports-tree', ()=>{
    const fullSportsListResponse = ref(null);
    const countersResponse = ref(null);
    const parseSportlineSettings = useParseSportlineSettingsRef();
    const apiService = useSportlineApiService();
    const { timeout: countUpdateTimeout } = useCountSportlineUpdateTimeout();
    const fullSportsList = computed(()=>fullSportsListResponse.value ? new SportsListAdapter(fullSportsListResponse.value, parseSportlineSettings.value).getSports() : null);
    const isLoaded = computed(()=>null !== fullSportsListResponse.value);
    const isCountersLoaded = computed(()=>null !== countersResponse.value);
    const hasZeroMarginLeaguesInTheTree = computed(()=>!!fullSportsList.value?.some((listElement)=>!!listElement.regions?.some((regionElement)=>regionElement.leagues.some((leagueElement)=>leagueElement.league.hasZeroMargin))));
    const hasZeroMargin = computed(()=>!!countersResponse.value?.zeroMarginMarkets || hasZeroMarginLeaguesInTheTree.value);
    const lastUpdate = new BackgroundUpdateStopwatch({
        fullSportsList: async (param)=>{
            let { silent } = param;
            fullSportsListResponse.value = await apiService.loadFullSportsTree({
                silent
            });
            lastUpdate.update('fullSportsList');
        },
        counters: async (param)=>{
            let { silent } = param;
            countersResponse.value = await apiService.loadCounters({
                silent
            });
            lastUpdate.update('counters');
        }
    });
    /**
   * Will reload list in case when invalidateAfterInterval more the last update time
   */ async function reloadFullSportsList(param) {
        let { silent, invalidateAfterInterval } = param;
        await lastUpdate.callSyncAction('fullSportsList', invalidateAfterInterval, {
            silent
        });
    }
    async function reloadZeroMarginCounters(param) {
        let { silent } = param;
        await lastUpdate.callSyncAction('counters', countUpdateTimeout.value, {
            silent
        });
    }
    return {
        fullSportsList,
        hasZeroMargin,
        isLoaded,
        isCountersLoaded,
        zeroMarginUpdateInterval: countUpdateTimeout,
        reloadFullSportsList,
        reloadZeroMarginCounters
    };
});
export default useSportsTreeStore;
