import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createSlots as _createSlots, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
import { ref, useSlots } from 'vue';
import { IconName } from '@leon-hub/icons';
import { VJumbotron, JumbotronFooterButton } from 'web/src/components/Jumbotron';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { useModalWindow } from './composables';
import ModalContentWrapper from '../ModalContentWrapper/ModalContentWrapper.vue';
import ModalWindowHeader from '../ModalWindowHeader/ModalWindowHeader.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ModalWindow',
    props: {
        image: {},
        alertIconSize: {},
        icon: {},
        iconKind: {},
        iconSize: {},
        header: {},
        content: {},
        topBarText: {},
        buttons: {},
        isCloseAllowed: {
            type: Boolean,
            default: true
        },
        isCloseAsBack: {
            type: Boolean
        },
        isAlert: {
            type: Boolean
        },
        isScrollBarEnabled: {
            type: Boolean
        },
        isTopBarHidden: {
            type: Boolean
        },
        isFullHeight: {
            type: Boolean
        },
        hasInnerModal: {
            type: Boolean
        },
        isInnerModal: {
            type: Boolean
        },
        isFullHeightCentered: {
            type: Boolean,
            default: true
        },
        modalKey: {},
        useScrollListener: {
            type: Boolean
        },
        hasNoScroll: {
            type: Boolean
        },
        isFullScreen: {
            type: Boolean
        },
        isProfileLayout: {
            type: Boolean
        },
        isSmallWidthContent: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click",
        "scroll-reached-bottom"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const contentWrapper = ref();
        const { headerProperties, isHeaderHidden, jumbotronProperties, titleText } = useModalWindow(props, slots);
        function scrollTop() {
            contentWrapper.value?.scrollTop();
        }
        function scrollToElement(element) {
            contentWrapper.value?.scrollToElement(element);
        }
        function scrollUpdate() {
            contentWrapper.value?.scrollUpdate();
        }
        __expose({
            scrollTop,
            scrollToElement,
            scrollUpdate
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['modal-window']]: true,
                    [_ctx.$style['modal-window--alert']]: _ctx.isAlert,
                    [_ctx.$style['modal-window--with-inner']]: _ctx.hasInnerModal,
                    [_ctx.$style['modal-window--inner-modal']]: _ctx.isInnerModal,
                    [_ctx.$style['modal-window--full-height']]: _ctx.isFullHeight,
                    [_ctx.$style['modal-window--full-height-centered']]: _ctx.isFullHeight && _ctx.isFullHeightCentered,
                    [_ctx.$style['modal-window--slot']]: _ctx.$slots.default,
                    [_ctx.$style['modal-window--full-screen']]: _ctx.isFullScreen
                })
            }, [
                _renderSlot(_ctx.$slots, "header", {}, ()=>[
                        _unref(isHeaderHidden) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(ModalWindowHeader, _mergeProps({
                            key: 0
                        }, _unref(headerProperties), {
                            class: _ctx.$style['modal-window__header'],
                            onPrefixClick: _cache[0] || (_cache[0] = ($event)=>emit('close')),
                            onSuffixClick: _cache[1] || (_cache[1] = ($event)=>emit('close'))
                        }), null, 16, [
                            "class"
                        ]))
                    ]),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['modal-window__content']]: true,
                        [_ctx.$style['modal-window__content--has-native-scroll']]: !_ctx.isScrollBarEnabled,
                        [_ctx.$style['modal-window__content--no-scroll']]: _ctx.hasNoScroll
                    })
                }, [
                    _createVNode(ModalContentWrapper, {
                        ref_key: "contentWrapper",
                        ref: contentWrapper,
                        class: _normalizeClass(_ctx.$style['modal-window__wrapper']),
                        "is-scroll-bar-enabled": _ctx.isScrollBarEnabled,
                        "modal-key": _ctx.modalKey,
                        "use-scroll-listener": _ctx.useScrollListener,
                        "is-inner-modal": _ctx.isInnerModal,
                        onScrollReachedBottom: _cache[2] || (_cache[2] = ($event)=>emit('scroll-reached-bottom'))
                    }, {
                        default: _withCtx(()=>[
                                _renderSlot(_ctx.$slots, "default", {}, ()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass({
                                                [_ctx.$style['modal-window__inner']]: true,
                                                [_ctx.$style['modal-window__inner--alert']]: _ctx.isAlert,
                                                [_ctx.$style['modal-window__inner--alert-no-title']]: _ctx.isAlert && !_unref(titleText),
                                                [_ctx.$style['modal-window__inner--full-height']]: _ctx.isFullHeight,
                                                [_ctx.$style['modal-window__inner--full-height-centered']]: _ctx.isFullHeight && _ctx.isFullHeightCentered,
                                                [_ctx.$style['modal-window__inner--profile']]: _ctx.isProfileLayout,
                                                [_ctx.$style['modal-window__inner--small-width']]: _ctx.isSmallWidthContent,
                                                [_ctx.$style['modal-window__inner--no-header']]: _unref(isHeaderHidden) && !_ctx.$slots.header
                                            })
                                        }, [
                                            _renderSlot(_ctx.$slots, "inner-content", {}, ()=>[
                                                    _createVNode(_unref(VJumbotron), _mergeProps(_unref(jumbotronProperties), {
                                                        class: _ctx.$style['modal-window__jumbotron']
                                                    }), _createSlots({
                                                        _: 2
                                                    }, [
                                                        _ctx.$slots.content ? {
                                                            name: "default",
                                                            fn: _withCtx(()=>[
                                                                    _renderSlot(_ctx.$slots, "content")
                                                                ]),
                                                            key: "0"
                                                        } : void 0,
                                                        _ctx.$slots.icon ? {
                                                            name: "icon",
                                                            fn: _withCtx(()=>[
                                                                    _renderSlot(_ctx.$slots, "icon")
                                                                ]),
                                                            key: "1"
                                                        } : void 0,
                                                        _ctx.$slots.footer || _ctx.buttons?.length ? {
                                                            name: "footer",
                                                            fn: _withCtx(()=>[
                                                                    _renderSlot(_ctx.$slots, "footer", {}, ()=>[
                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index)=>_withDirectives((_openBlock(), _createBlock(_unref(JumbotronFooterButton), _mergeProps({
                                                                                    ref_for: true
                                                                                }, button, {
                                                                                    key: index,
                                                                                    "full-width": "",
                                                                                    onClick: ($event)=>emit('button-click', index)
                                                                                }), {
                                                                                    default: _withCtx(()=>[
                                                                                            _createVNode(VDynamicContent, {
                                                                                                content: button.label
                                                                                            }, null, 8, [
                                                                                                "content"
                                                                                            ])
                                                                                        ]),
                                                                                    _: 2
                                                                                }, 1040, [
                                                                                    "onClick"
                                                                                ])), [
                                                                                    [
                                                                                        _directive_data_test,
                                                                                        {
                                                                                            el: 'modal-button'
                                                                                        }
                                                                                    ]
                                                                                ])), 128))
                                                                        ])
                                                                ]),
                                                            key: "2"
                                                        } : void 0
                                                    ]), 1040, [
                                                        "class"
                                                    ])
                                                ])
                                        ], 2)
                                    ])
                            ]),
                        _: 3
                    }, 8, [
                        "class",
                        "is-scroll-bar-enabled",
                        "modal-key",
                        "use-scroll-listener",
                        "is-inner-modal"
                    ])
                ], 2),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ModalWindow'
                ]
            ]);
        };
    }
});
