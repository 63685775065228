import { computed, ref, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { baseButtonProps } from './utils';
export default function useVBalance(props) {
    const mainTag = ref();
    const formatMoney = useFormatMoney();
    const formattedCurrency = toRef(useCurrencyStore(), 'formattedCurrency');
    const balanceValue = computed(()=>formatMoney(props.value, {
            hideCurrency: props.isAddButtonAsCurrency
        }));
    const currencyIconProps = computed(()=>{
        const buttonBase = baseButtonProps(props);
        if (!props.isAddButtonAsCurrency || props.isBalanceHidden) buttonBase.iconName = props.isBigIcon || props.isBalanceHidden ? IconName.PLUS : IconName.PLUS_SMALL;
        else buttonBase.label = formattedCurrency.value;
        return buttonBase;
    });
    return {
        mainTag,
        balanceValue,
        currencyIconProps
    };
}
