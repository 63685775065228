import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HighestOddsIcon',
    props: {
        iconSize: {
            default: IconSize.SIZE_10
        },
        highestOddsIconColor: {
            default: 'default'
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VIcon), {
                class: _normalizeClass(_ctx.$style[`highest-odds-icon--${_ctx.highestOddsIconColor}`]),
                name: _unref(IconName).SPORTLINE_HIGHEST_ODDS,
                size: _ctx.iconSize
            }, null, 8, [
                "class",
                "name",
                "size"
            ])), [
                [
                    _directive_auto_id,
                    'HighestOddsIcon'
                ]
            ]);
        };
    }
});
