import { computed, shallowRef, toRef } from 'vue';
import { useModuleTimeout, useSyncState } from 'web/src/modules/core/store/composables';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useGetSportlineEntityFactory } from 'web/src/modules/sportline/composables/factories';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { getBetlineLeagueData, getBetlineLeagueStatisticStatus } from 'web/src/modules/sportline/utils/api';
import { parseBetlineLeagueDataResponse, parseBetlineLeagueRepresentationDataResponse } from 'web/src/modules/sportline/submodules/core-statistic/utils';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';
import { getSportFamilyBackground } from 'web/src/modules/sportline/utils';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
export function useLeagueHeaderDataStoreComposable(props) {
    const { expectedId } = props;
    const apiService = useSportlineApiService();
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isLeagueHeaderDataRequestEnabled = toRef(sportlineSettingsStore, 'isLeagueHeaderDataRequestEnabled');
    const isLeagueStatisticEnabled = toRef(sportlineSettingsStore, 'isLeagueStatisticEnabled');
    const { timeout: updateInterval } = useModuleTimeout('sportline-league-data', toRef(sportlineSettingsStore, 'sportLinePrematchUpdateInterval'));
    const rawLeagueResponse = shallowRef(null);
    const leagueData = shallowRef(null);
    const rawLeagueRepresentationData = shallowRef(null);
    const leagueStatisticBlockStatus = shallowRef([]);
    const { isLoading, lastLoadedId, isExpectedIdLoaded, setIsLoading, setLastLoadedId } = useExpectedIdLoading({
        expectedId
    });
    const { sportElement, regionElement, leagueElement } = useGetSportlineEntityFactory({
        rawLeagueResponse
    });
    const canLoadStatistics = computed(()=>leagueStatisticBlockStatus.value.length > 0 && isExpectedIdLoaded.value);
    const isReady = computed(()=>isExpectedIdLoaded.value);
    const isEmpty = computed(()=>!leagueData.value && !leagueElement.value);
    const leagueRepresentationData = computed(()=>{
        if (rawLeagueRepresentationData.value) return rawLeagueRepresentationData.value;
        const sport = sportElement.value?.sport;
        const league = leagueElement.value?.league;
        if (!sport || !league) return null;
        return {
            icon: sport.representation.icon,
            logoUrl: league.representation.logo ?? null,
            backgroundImage: league.representation.background ?? null,
            background: getSportFamilyBackground(sport.family)
        };
    });
    function clear() {
        leagueData.value = null;
        rawLeagueRepresentationData.value = null;
        leagueStatisticBlockStatus.value = [];
    }
    function fetchLeague(param) {
        let { leagueId, silent } = param;
        return apiService.loadLeague({
            id: String(leagueId),
            silent
        });
    }
    function fetchBetlineLeagueData(param) {
        let { leagueId, silent } = param;
        return isLeagueHeaderDataRequestEnabled.value ? getBetlineLeagueData({
            leagueId
        }, {
            silent
        }) : Promise.resolve(null);
    }
    function fetchBetlineLeagueStatisticStatus(param) {
        let { leagueId, silent } = param;
        return isLeagueStatisticEnabled.value ? getBetlineLeagueStatisticStatus({
            leagueId
        }, {
            silent
        }) : Promise.resolve(null);
    }
    async function fetchLeagueData(param) {
        let { silent } = param;
        const leagueId = expectedId.value;
        if (!leagueId) return;
        setIsLoading(true);
        try {
            const [leagueResponse, leagueDataResponse, leagueStatisticStatusResponse] = await Promise.all([
                fetchLeague({
                    leagueId,
                    silent
                }),
                fetchBetlineLeagueData({
                    leagueId,
                    silent
                }),
                fetchBetlineLeagueStatisticStatus({
                    leagueId,
                    silent
                })
            ]);
            rawLeagueResponse.value = leagueResponse;
            leagueStatisticBlockStatus.value = leagueStatisticStatusResponse?.enabledEntities ?? [];
            leagueData.value = parseBetlineLeagueDataResponse(leagueDataResponse);
            rawLeagueRepresentationData.value = parseBetlineLeagueRepresentationDataResponse(leagueDataResponse);
        } catch (error) {
            clear();
            rawLeagueResponse.value = false;
            if (!isStatisticNotFoundError(error)) throw error;
        } finally{
            setIsLoading(false);
            setLastLoadedId(leagueId);
        }
    }
    const lastUpdate = new BackgroundUpdateStopwatch({
        leagueData: fetchLeagueData
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled, isBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval
    });
    function onInit() {
        useSyncState(async (silent)=>{
            await syncState({
                silent
            });
        }, updateInterval);
    }
    return {
        leagueData,
        leagueRepresentationData,
        canLoadStatistics,
        isReady,
        isEmpty,
        isLoading,
        isBackgroundUpdateEnabled,
        lastLoadedId,
        sportElement,
        regionElement,
        leagueElement,
        onInit,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled,
        fetchLeagueData
    };
}
