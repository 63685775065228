import { computed } from 'vue';
import { getMarketColumnsFromMarketColumnsInfo, getMarketTypesFromMarketColumnsInfo } from './utils';
export function useSportlineElementMarketColumns(props) {
    const { sportlineElement } = props;
    const marketsColumns = computed(()=>getMarketColumnsFromMarketColumnsInfo(sportlineElement.value));
    return {
        marketsColumns
    };
}
export function useSportlineElementMarketTypes(props) {
    const { sportlineElement } = props;
    const marketsTypes = computed(()=>getMarketTypesFromMarketColumnsInfo(sportlineElement.value));
    return {
        marketsTypes
    };
}
