import { defineStore } from 'pinia';
import { watch } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { toggleFavoriteLeagueRequest } from 'web/src/modules/sportline/utils/api';
import { usePendingToFavoriteLeaguesStoreComposable } from './composables/usePendingToFavoriteLeaguesStoreComposable';
import { useFavoritesLeaguesEventsStoreComposable } from './composables/useFavoritesLeaguesEventsStoreComposable';
import { useFavoriteLeaguesBackgroundUpdates } from './composables/useFavoriteLeaguesBackgroundUpdates';
import { useOptimisticFavoritesLeagues } from './composables/useOptimisticFavoritesLeagues';
export const useCustomerLeaguesStore = defineStore('sportline-customer-leagues', ()=>{
    const { isLoggedIn } = useIsLoggedIn();
    const { customerLeaguesIds, rawFavoriteLeaguesEventsResponse, setRawFavoriteEventsResponse, fetchFavoriteLeaguesEvents, fetchCustomerLeaguesIds, clearCustomerLeaguesIds } = useFavoritesLeaguesEventsStoreComposable();
    const { preparedToAdd, preparedToRemove, pendingToFavoriteLeaguesList, addLeagueIdToTogglePrepared, togglePendingToFavoriteLeaguesList, setupPendingLeagues, clearPendingLeagues } = usePendingToFavoriteLeaguesStoreComposable({
        customerLeaguesIds
    });
    async function tryToAddPendingLeagues() {
        if (!isLoggedIn.value) return false;
        const responses = [];
        // @TODO optimize add pending leagues
        await fetchCustomerLeaguesIds({
            silent: true
        });
        const pendingList = setupPendingLeagues();
        // @TODO make single request to add many leagues
        for (const pending of pendingList){
            const { sportId, leagueId } = pending;
            responses.push(toggleFavoriteLeagueRequest({
                sportId,
                leagueId
            }, {
                silent: true
            }));
        }
        await Promise.all(responses);
        return true;
    }
    const { onInit: onBackgroundUpdatesInit, initialRequests: initialBackgroundUpdatesRequests, setIsBackgroundIdsUpdateAvailable, setIsEventsBackgroundUpdateAvailable, synchronizeLeaguesIds } = useFavoriteLeaguesBackgroundUpdates({
        customerLeaguesIds,
        pendingToFavoriteLeaguesList,
        tryToAddPendingLeagues,
        fetchFavoriteLeaguesEvents,
        fetchCustomerLeaguesIds,
        clearPendingLeagues
    });
    const { optimisticCustomerLeaguesIds } = useOptimisticFavoritesLeagues({
        preparedToAdd,
        preparedToRemove,
        pendingToFavoriteLeaguesList,
        customerLeaguesIds
    });
    async function toggleCustomerLeague(parameters) {
        if (!isLoggedIn.value) {
            togglePendingToFavoriteLeaguesList(parameters);
            return;
        }
        // add to optimistic
        const removeFromPrepared = addLeagueIdToTogglePrepared(parameters.leagueId);
        try {
            // toggle league state
            await toggleFavoriteLeagueRequest(parameters, {
                silent: false
            });
            // get actual favorite ids list
            await synchronizeLeaguesIds({
                silent: true,
                force: true
            });
        } finally{
            removeFromPrepared();
        }
    }
    async function initialRequests() {
        await initialBackgroundUpdatesRequests();
    }
    // init store
    watch(isLoggedIn, (value)=>{
        if (value) {
            initialBackgroundUpdatesRequests();
            return;
        }
        clearCustomerLeaguesIds();
        setRawFavoriteEventsResponse(null);
        clearPendingLeagues();
    }, {
        immediate: true
    });
    onBackgroundUpdatesInit();
    return {
        customerLeaguesIds,
        optimisticCustomerLeaguesIds,
        pendingToFavoriteLeaguesList,
        toggleCustomerLeague,
        setIsBackgroundIdsUpdateAvailable,
        setIsEventsBackgroundUpdateAvailable,
        initialRequests,
        // seems it must be internal field
        rawFavoriteLeaguesEventsResponse
    };
});
