import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
export default function useBalanceSwiperNavigation() {
    const { swiperState, slideToPreviousSlide, slideToNextSlide } = useSwiperNavigation();
    // eslint-disable-next-line max-len
    const isShowButtons = computed(()=>!!swiperState?.isPreviousButtonAllowed.value || !!swiperState?.isNextButtonAllowed.value);
    const previousButtonProperties = computed(()=>({
            kind: ButtonKind.BASE,
            height: ButtonHeight.MEDIUM,
            iconName: IconName.EXPAND_LEFT,
            disabled: !swiperState?.isPreviousButtonAllowed.value
        }));
    const nextButtonProperties = computed(()=>({
            kind: ButtonKind.BASE,
            height: ButtonHeight.MEDIUM,
            iconName: IconName.EXPAND_RIGHT,
            disabled: !swiperState?.isNextButtonAllowed.value
        }));
    return {
        slideToPreviousSlide,
        slideToNextSlide,
        isShowButtons,
        previousButtonProperties,
        nextButtonProperties
    };
}
