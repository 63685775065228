import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { BannerType, HomePageType, PathType } from '@leon-hub/api-sdk';
import { isSportlineRouteName } from '@leon-hub/routing-config-names/src/SportlineRouteName';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useBannerTexts } from 'web/src/modules/banners/components/composables';
export default function useMainBannerItem(props) {
    const { homePageType } = useSiteConfigStore();
    const route = useRoute();
    const item = ref(null);
    const isInternalLink = computed(()=>props.banner.pathType === PathType.INTERNAL_PATH);
    const isDivContainer = computed(()=>props.banner.pathType === PathType.MARKETING_LEONSHOP);
    const isMiddleSizeBanner = computed(()=>(homePageType === HomePageType.SLOTS && !isSportlineRouteName(route.name) || homePageType === HomePageType.SPORTS && (route.name === CasinoRouteName.CASINO_LOBBY || route.name === CasinoRouteName.CASINO_LIVE_LOBBY)) && props.banner.isNewBannerView);
    const bannerContent = computed(()=>props.banner.content);
    const { headline, secondaryText, buttonText } = useBannerTexts(bannerContent);
    const bannerProperties = computed(()=>{
        const { aspectRatio, linkTitle, isRedirectDisabled, bannerType, isActive, isVisibleImage, isBigHeight, groupId, isHalfWidth } = props;
        const mainImageComputed = isMiddleSizeBanner.value && props.banner.content.imageMiddle ? props.banner.content.imageMiddle : props.banner.content.image;
        const backgroundImageComputed = isMiddleSizeBanner.value && props.banner.content.bgImageMiddle ? props.banner.content.bgImageMiddle : props.banner.content.bgImage ?? void 0;
        return {
            bannerId: props.banner.bannerId ?? '',
            groupId,
            mainImage: mainImageComputed,
            backgroundImage: backgroundImageComputed,
            backgroundColor: props.banner.content.bgColor || void 0,
            forceHideImage: props.isSlideHidden,
            animation: props.banner.content.animation ?? void 0,
            url: props.banner.content.url,
            isInternalLink: isInternalLink.value,
            headline: headline.value,
            secondaryText: secondaryText.value,
            buttonText: buttonText.value,
            linkTitle,
            aspectRatio,
            isRedirectDisabled,
            bannerType,
            isActive,
            isDivContainer: isDivContainer.value,
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            isNT: props.isGroupBannersDev || props.banner.isNT,
            textColor: props.banner.textColor || void 0,
            isTextShadowHidden: props.banner.isTextShadowHidden || void 0,
            isVisibleImage,
            isBigHeight,
            isNewBannerView: props.banner.isNewBannerView || void 0,
            hasEventDetails: props.banner.bannerType === BannerType.SPORT && !!props.banner.event,
            isHalfWidth,
            bannerVersion: props.banner.bannerVersion
        };
    });
    return {
        item,
        bannerProperties
    };
}
