import { toRef } from 'vue';
import { useSportlineTopStore } from 'web/src/modules/sportline/submodules/top/store';
export function useMixedSportsList() {
    const sportlineTopStore = useSportlineTopStore();
    const sportlineElements = toRef(sportlineTopStore, 'sportlineElements');
    const totalCount = toRef(sportlineTopStore, 'totalCount');
    return {
        sportlineElements,
        totalCount
    };
}
export function useMixedHeadlineEventsList() {
    const sportlineTopStore = useSportlineTopStore();
    const headlineElements = toRef(sportlineTopStore, 'headlineElements');
    const totalCount = toRef(sportlineTopStore, 'totalCount');
    return {
        headlineElements,
        totalCount
    };
}
export function useSeparateHeadlineEventsLists() {
    const sportlineTopStore = useSportlineTopStore();
    const totalLiveCount = toRef(sportlineTopStore, 'totalLiveCount');
    const totalPrematchCount = toRef(sportlineTopStore, 'totalPrematchCount');
    const liveHeadlineElements = toRef(sportlineTopStore, 'liveHeadlineElements');
    const prematchHeadlineElements = toRef(sportlineTopStore, 'prematchHeadlineElements');
    return {
        totalLiveCount,
        totalPrematchCount,
        liveHeadlineElements,
        prematchHeadlineElements
    };
}
