export function useVTabsButton() {
    return {
        remapProps: (p)=>({
                type: p.type,
                flexible: p.flexible,
                active: p.active,
                single: p.single,
                disabled: p.disabled ? p.disabled : void 0,
                isNavigation: p.isNavigation,
                href: p.href,
                id: p.id,
                isBordered: p.isBordered,
                version: p.version
            })
    };
}
