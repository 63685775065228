import AccountHistoryRouteName from '@leon-hub/routing-config-names/src/AccountHistoryRouteName';
import ProfileRouteName from '@leon-hub/routing-config-names/src/ProfileRouteName';
import SportlineRouteName from './SportlineRouteName';
import PromoRouteName from './PromoRouteName';
import BaseRouteName from './BaseRouteName';
import RegistrationRouteName from './RegistrationRouteName';
import RegistrationCompletionRouteName from './RegistrationCompletionRouteName';
const RouteName = {
    ...BaseRouteName,
    ...SportlineRouteName,
    ...PromoRouteName,
    ...RegistrationRouteName,
    ...RegistrationCompletionRouteName,
    ...AccountHistoryRouteName,
    ...ProfileRouteName
};
export default RouteName;
