import { computed, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isOptionalString } from '@leon-hub/guards';
import RouteName, { CasinoRouteName } from '@leon-hub/routing-config-names';
import { isCasinoLobbyRouteName } from 'web/src/modules/casino/utils';
import { getCategoryByRoute, getGroupByUrl, isLiveEgsRoute } from 'web/src/modules/egs/utils';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
import { AllGamesGroupUrl } from 'web/src/modules/egs/constants';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useEgsCategoriesStore } from 'web/src/modules/egs/submodules/categories/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import { EgsRouterParametersHandler } from 'web/src/modules/egs/submodules/navigation/utils';
import useSupport from 'web/src/modules/support/composables/useSupport';
export function useCasinoLobby() {
    const { $translate } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const { isHelpMode } = useSupport();
    const siteConfigStore = useSiteConfigStore();
    const lobbyStore = useLobbyStore();
    const homePageType = toRef(siteConfigStore, 'homePageType');
    const lobbyType = toRef(lobbyStore, 'activeType');
    const groupId = toRef(lobbyStore, 'activeGroupId');
    const categoryId = toRef(lobbyStore, 'activeCategoryId');
    // eslint-disable-next-line max-len
    const isLoadingLobby = computed(()=>lobbyStore.getIsLoading(lobbyType.value, groupId.value, categoryId.value).value);
    const isEgsLoyaltyEnabled = toRef(siteConfigStore, 'isEgsLoyaltyEnabled');
    const { getGroupsByLobbyType } = useEgsGroupsStore();
    const currentGroups = computed(()=>getGroupsByLobbyType(lobbyType.value).value);
    const group = computed(()=>{
        if (!isCasinoLobbyRouteName(route.name, homePageType.value)) return;
        const { groupUrl } = route.params;
        isOptionalString(groupUrl);
        return getGroupByUrl(currentGroups.value, groupUrl);
    });
    const groupBlockType = computed(()=>group.value?.blockType);
    const filteredGroupId = computed(()=>groupBlockType.value ? void 0 : groupId.value);
    const hasGroup = computed(()=>!!group.value);
    const groupName = computed(()=>group.value?.name || $translate('WEB2_ALL_PROVIDERS').value);
    const groupUrl = computed(()=>group.value?.url);
    const categoriesStore = useEgsCategoriesStore();
    const { getCategoriesByGroupIdAndType } = categoriesStore;
    const categories = computed(()=>getCategoriesByGroupIdAndType(lobbyType.value, groupId.value).value);
    const isLoadingCategories = toRef(categoriesStore, 'isLoading');
    const { loadCategories } = categoriesStore;
    const category = computed(()=>{
        if (!isCasinoLobbyRouteName(route.name, homePageType.value)) return;
        return getCategoryByRoute(categories.value, route);
    });
    function onClickLoyaltyProgram() {
        router.push({
            name: CasinoRouteName.CASINO_LOYALTY_PROGRAM
        });
    }
    function onClickSearch() {
        router.push({
            name: CasinoRouteName.CASINO_SEARCH
        });
    }
    function onClickGroup() {
        router.push({
            name: isLiveEgsRoute(route) ? CasinoRouteName.CASINO_LIVE_GROUPS : CasinoRouteName.CASINO_GROUPS
        });
        EgsRouterParametersHandler.setSelectedGroup(route.params.groupUrl === AllGamesGroupUrl ? void 0 : groupUrl.value);
    }
    function onClickHelp() {
        router.push({
            name: RouteName.SUPPORT
        });
    }
    return {
        lobbyType,
        groupId,
        categoryId,
        hasGroup,
        groupName,
        group,
        category,
        groupBlockType,
        filteredGroupId,
        isLoadingCategories,
        isLoggedIn,
        isLoadingLobby,
        isHelpMode,
        onClickLoyaltyProgram,
        onClickSearch,
        onClickGroup,
        onClickHelp,
        loadCategories,
        isEgsLoyaltyEnabled,
        groupUrl,
        categories
    };
}
