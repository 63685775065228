import { FormErrorKeyword } from 'web/src/components/Form/enums';
import getWidget from 'web/src/components/Form/utils/getWidget';
import { getLabel } from 'web/src/components/Form/utils/uiSchema';
function hasRequiredParameter(error) {
    return 'required' === error.name;
}
function hasPatternParameter(error) {
    return 'pattern' === error.name;
}
function createErrorDataPath(parts) {
    return parts.length > 0 ? `/${parts.join('/')}` : '';
}
function normalizeDataPath(error) {
    const path = createErrorDataPath(error.path).slice(1);
    if (hasRequiredParameter(error)) {
        const { argument } = error;
        if (!path) return `${argument}`;
        return `${path}/${argument}`;
    }
    return path;
}
function hasErrorKeyword(keyword) {
    const key = keyword.charAt(0).toUpperCase() + keyword.slice(1);
    return !!FormErrorKeyword[key];
}
function hasLimitParameter(error) {
    return 'minLength' === error.name || 'maxLength' === error.name;
}
function getErrorMessageByPattern(errorPattern, keyword, widget) {
    const { byPattern = {} } = errorPattern;
    const byPatternObject = byPattern[widget] ?? {};
    const byPatternError = byPatternObject[keyword] || '';
    return byPatternError;
}
function getErrorPattern(errorPattern, keyword, widget) {
    const { common = {}, byWidget } = errorPattern;
    const commonPattern = common[keyword] || '';
    if (byWidget && widget) {
        const byWidgetKeyword = byWidget[widget] ?? {};
        const byWidgetError = byWidgetKeyword[keyword] || '';
        return byWidgetError || commonPattern;
    }
    return commonPattern;
}
const patternMinMaxLengthHandler = (field, template, error)=>{
    let result = template.replace(/%field%/g, field);
    if (hasLimitParameter(error)) result = result.replace(/%limit%/g, `${error.argument}`);
    return result;
};
const patternMessageHandlers = {
    [FormErrorKeyword.Required]: (field, template)=>template.replace(/%field%/g, field.toLowerCase()),
    [FormErrorKeyword.Pattern]: (field, template, error)=>{
        let result = template.replace(/%field%/g, field.toLowerCase());
        if (hasPatternParameter(error)) result = result.replace(/%pattern%/g, error.argument);
        return result;
    },
    [FormErrorKeyword.MinLength]: patternMinMaxLengthHandler,
    [FormErrorKeyword.MaxLength]: patternMinMaxLengthHandler,
    [FormErrorKeyword.MinItems]: (field, template)=>template.replace(/%field%/g, field.toLowerCase())
};
function getErrorMessage(keyword, field, patternMessage, error) {
    const patternMessageHandler = patternMessageHandlers[keyword];
    if (!patternMessageHandler) return patternMessage;
    return patternMessageHandler(field, patternMessage, error);
}
function getFieldFromDataPath(dataPath) {
    return dataPath.split('/')[0];
}
function createErrorMapper(uiSchema, errorPattern) {
    return (error)=>{
        const dataPath = normalizeDataPath(error);
        const field = dataPath.split('/')[0];
        const errorKeyword = error.name;
        const errorMessage = error.message.replace(error.property, 'field');
        // keyword is string but we need FormErrorKeyword
        // try to typecasting string -> FormErrorKeyword
        if (!hasErrorKeyword(errorKeyword)) return {
            keyword: errorKeyword,
            message: errorMessage,
            field,
            dataPath
        };
        const fieldForMessage = getFieldFromDataPath(dataPath);
        // it returns widget type for root widget, since complex fields consist from simple fields,
        // for returning correct error message we need nested widget type
        const widget = getWidget(uiSchema, fieldForMessage);
        const errorMessageByPattern = getErrorMessageByPattern(errorPattern, error.argument, widget) || null;
        const patternMessage = errorMessageByPattern || getErrorPattern(errorPattern, errorKeyword, widget) || errorMessage;
        const label = getLabel(uiSchema, getFieldFromDataPath(dataPath));
        const message = getErrorMessage(errorKeyword, label || fieldForMessage, patternMessage, error);
        return {
            keyword: errorKeyword,
            message,
            field,
            dataPath
        };
    };
}
export default createErrorMapper;
