import { _ as _define_property } from "@swc/helpers/_/_define_property";
var Steps;
var Steps1;
(Steps1 = Steps || (Steps = {}))[Steps1["Days"] = 0] = "Days", Steps1[Steps1["Hours"] = 1] = "Hours", Steps1[Steps1["Minutes"] = 2] = "Minutes", Steps1[Steps1["Seconds"] = 3] = "Seconds";
let TimeLeft = class TimeLeft {
    static days(value, now) {
        return new TimeLeft(value, now, 0);
    }
    static hours(value, now) {
        return new TimeLeft(value, now, 1);
    }
    static minutes(value, now) {
        return new TimeLeft(value, now, 2);
    }
    static seconds(value, now) {
        return new TimeLeft(value, now, 3);
    }
    get distance() {
        if (!this.now) return;
        return this.value > this.now ? this.value - this.now : 0;
    }
    /**
   * Value without formatting
   * @return {number | undefined}
   */ get timeLeft() {
        const { distance } = this;
        if (void 0 === distance) return 0;
        switch(this.step){
            case 0:
                return Math.floor(distance / 86400000);
            case 1:
                return Math.floor(distance / 3600000 % 24);
            case 2:
                return Math.floor(distance / 60000 % 60);
            case 3:
                return Math.floor(distance / 1000 % 60);
            default:
                return 0;
        }
    }
    /**
   * To pretty two-digit style
   * @return {string}
   */ toPretty() {
        const { timeLeft } = this;
        if (!timeLeft) return '00';
        const max = 99;
        if (max && timeLeft > max) return `${max}`;
        return timeLeft < 10 ? `0${timeLeft}` : `${timeLeft}`;
    }
    /**
   * used to convert in templates
   * @return {string}
   */ toString() {
        return this.toPretty();
    }
    /**
   * used to convert to number by +value
   * @return {number | undefined}
   */ valueOf() {
        return this.timeLeft;
    }
    constructor(value, now = Date.now(), step = 3){
        _define_property(this, "value", void 0);
        _define_property(this, "now", void 0);
        _define_property(this, "step", void 0);
        this.value = value;
        this.now = now;
        this.step = step;
    }
};
/** @deprecated @TODO use useTimeLeft instead */ export { TimeLeft as default };
