import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import CustomerHistoryListItemDetails from '../CustomerHistoryListItemDetails/CustomerHistoryListItemDetails.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListItemLayout',
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function emitClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(CustomerHistoryListItemDetails, {
                onClick: emitClick
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['history-list-item__container'])
                        }, [
                            _ctx.$slots.metaLeft || _ctx.$slots.metaRight ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['history-list-item__row'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['history-list-item__column']]: true,
                                        [_ctx.$style['history-list-item__column--left']]: true
                                    })
                                }, [
                                    _renderSlot(_ctx.$slots, "metaLeft")
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['history-list-item__column']]: true,
                                        [_ctx.$style['history-list-item__column--right']]: true
                                    })
                                }, [
                                    _renderSlot(_ctx.$slots, "metaRight")
                                ], 2)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'history-list-item-row--with-meta'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _ctx.$slots.descriptionLeft || _ctx.$slots.descriptionRight ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['history-list-item__row'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['history-list-item__column']]: true,
                                        [_ctx.$style['history-list-item__column--left']]: true
                                    })
                                }, [
                                    _renderSlot(_ctx.$slots, "descriptionLeft")
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['history-list-item__column']]: true,
                                        [_ctx.$style['history-list-item__column--right']]: true
                                    })
                                }, [
                                    _renderSlot(_ctx.$slots, "descriptionRight")
                                ], 2)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'history-list-item-row--with-description'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _ctx.$slots.date ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 2,
                                class: _normalizeClass(_ctx.$style['history-list-item__row'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['history-list-item__column'])
                                }, [
                                    _renderSlot(_ctx.$slots, "date")
                                ], 2)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'history-list-item-row--with-date'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _ctx.$slots.footerLeft || _ctx.$slots.footerRight ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 3,
                                class: _normalizeClass(_ctx.$style['history-list-item__row'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['history-list-item__column']]: true,
                                        [_ctx.$style['history-list-item__column--left']]: true
                                    })
                                }, [
                                    _renderSlot(_ctx.$slots, "footerLeft")
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['history-list-item__column']]: true,
                                        [_ctx.$style['history-list-item__column--right']]: true
                                    })
                                }, [
                                    _renderSlot(_ctx.$slots, "footerRight")
                                ], 2)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'history-list-item-row--with-footer'
                                    }
                                ]
                            ]) : _createCommentVNode("", true)
                        ], 2)
                    ]),
                _: 3
            })), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListItemLayout'
                ]
            ]);
        };
    }
});
