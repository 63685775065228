const MAX_FILE_NAME_LENGTH = 18;
const FILE_NAME_SHORT_LENGTH = 12;
const getShortenFileName = (fileName)=>{
    const dividerPosition = fileName.lastIndexOf('.', fileName.length);
    const extension = fileName.slice(dividerPosition, fileName.length);
    const name = fileName.slice(0, dividerPosition);
    if (fileName.length > MAX_FILE_NAME_LENGTH) return `${name.slice(0, FILE_NAME_SHORT_LENGTH)}...${extension}`;
    return fileName;
};
export default getShortenFileName;
