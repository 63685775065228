import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import DateTime from 'web/src/utils/DateTime';
import { getHighlightedText } from 'web/src/modules/search/submodules/sportline/components/SearchListItem/composables/utils';
export default function useSearchListItem(props, emits) {
    const streamIconProps = {
        name: IconName.STREAM,
        size: IconSize.SIZE_16
    };
    const date = computed(()=>DateTime.withTimeStamp(props.kickoff).toDate());
    const time = computed(()=>DateTime.withTimeStamp(props.kickoff).toTime());
    const formattedLeagueName = computed(()=>getHighlightedText(props.searchText, props.leagueName, false));
    const formattedRegionName = computed(()=>getHighlightedText(props.searchText, props.regionName));
    const formattedSportName = computed(()=>getHighlightedText(props.searchText, props.sportName));
    const formattedName = computed(()=>getHighlightedText(props.searchText, props.name, false));
    function onClick() {
        emits('click');
    }
    return {
        onClick,
        date,
        time,
        formattedName,
        formattedLeagueName,
        formattedRegionName,
        formattedSportName,
        streamIconProps
    };
}
