import { unref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18nStore } from 'web/src/modules/i18n/store';
const I18nPlugin = {
    install (app) {
        const i18nStore = useI18nStore();
        const { lang, locale } = storeToRefs(i18nStore);
        const { getTranslationFunction } = i18nStore;
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$t = getTranslationFunction();
        Object.defineProperty(app.config.globalProperties, '$lang', {
            enumerable: true,
            get: ()=>unref(lang)
        });
        Object.defineProperty(app.config.globalProperties, '$locale', {
            enumerable: true,
            get: ()=>unref(locale)
        });
    }
};
export default I18nPlugin;
