export function getFirstTeamIdForSeason(season) {
    for (const group of season.groups)for (const team of group.teams)if (team.team?.id) return team.team.id;
    return null;
}
export function getLastTeamIdForSeason(season) {
    let groupIndex = season.groups.length - 1;
    while(groupIndex >= 0){
        const group = season.groups[groupIndex];
        let teamIndex = group.teams.length - 1;
        while(teamIndex >= 0){
            const team = group.teams[teamIndex];
            if (team.team?.id) return team.team.id;
            teamIndex -= 1;
        }
        groupIndex -= 1;
    }
    return null;
}
