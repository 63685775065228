import { ref } from 'vue';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useParseSportlineSettingsRef, useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { extractBuildSportOptions } from 'web/src/modules/sportline/utils/rest/pre-build';
ref([]), ref(extractBuildSportOptions()), ()=>{};
export function useLobbySportlineEvents() {
    const { onInit: onGlobalSportlineEventsInit } = useGlobalSportlineEvents();
    const { flags: sportlineFlags } = useSportlineTopEventsSettings();
    return {
        sportlineFlags,
        parseSportlineSettings: useParseSportlineSettingsRef(),
        onGlobalSportlineEventsInit
    };
}
