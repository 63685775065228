import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { Timer } from '@leon-hub/utils';
import { isNumber } from '@leon-hub/guards';
import { BetType, CashOutReason, CashOutStatus } from '@leon-hub/api-sdk';
import { GqlApiAccessDeniedError } from '@leon-hub/api';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useCurrency } from 'web/src/modules/money/composables';
import { usePendingBetsStore } from 'web/src/modules/slip/submodules/pending-bets/store';
import { useSlipViewSettingsStore } from 'web/src/modules/slip/submodules/slip-view-settings/store';
import { TopLevelTabs } from 'web/src/modules/slip/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { BetCashoutMode } from '../enums';
import { requestCashoutOffer, doCashOutRequest, sendCashoutMetrika } from './utils';
const UPDATE_TIME = 3000;
const useCashoutStore = defineStore('cashout', ()=>{
    const graphqlClient = useGraphqlClient();
    const { currency } = useCurrency();
    const pendingBetsStore = usePendingBetsStore();
    const slipViewSettingsStore = useSlipViewSettingsStore();
    const slipActiveTopTabId = toRef(slipViewSettingsStore, 'activeTopTabId');
    // former state
    const isLoaded = ref(false);
    const isCompleted = ref(false);
    const isPending = ref(false);
    const price = ref(0);
    const oldPrice = ref(0);
    const haveError = ref(false);
    const errorMessage = ref('');
    const currentBetId = ref(null);
    const betType = ref(null);
    const slipCashoutInProgress = ref(false);
    const currentBetItem = ref(null);
    // former getters
    const mode = computed(()=>{
        if (isCompleted.value) return BetCashoutMode.SUCCESS;
        if (isPending.value) return BetCashoutMode.PENDING;
        if (haveError.value) return BetCashoutMode.ERROR;
        return BetCashoutMode.DEFAULT;
    });
    const cashoutAvailable = computed(()=>!haveError.value);
    const { $translate } = useI18n();
    const competitors = computed(()=>{
        const list = currentBetItem.value?.competitors;
        return list?.length === 2 ? `${list[0]} - ${list[1]}` : '';
    });
    const count = computed(()=>({
            COUNT: `${currentBetItem.value?.itemsQuantity ?? ''}`
        }));
    const betKind = computed(()=>{
        if (currentBetItem.value?.betType === BetType.EXPRESS) return $translate('WEB2_BET_EXPRESS_MULTIPLICITY', count).value;
        return currentBetItem.value?.runnerName ?? '';
    });
    const betName = computed(()=>{
        if (betType.value !== BetType.SINGLE) return `${betType.value === BetType.EXPRESS ? $translate('WEB2_TRANSACTION_HISTORY_EXPRESS_BET').value : $translate('WEB2_TRANSACTION_HISTORY_SYSTEM_BET').value} ${betKind.value}`;
        return competitors.value;
    });
    const coreComponentProps = computed(()=>({
            currency: currency.value,
            errorMessage: errorMessage.value,
            isLoaded: isLoaded.value,
            mode: mode.value,
            oldPrice: oldPrice.value,
            price: price.value,
            ...currentBetItem.value?.stakeAmount ? {
                betPrice: currentBetItem.value?.stakeAmount
            } : {},
            ...betName.value ? {
                betName: betName.value
            } : {}
        }));
    // formerActions
    let updateIntervalId = null;
    const setError = (isErrorState, message)=>{
        haveError.value = isErrorState;
        errorMessage.value = message || '';
    };
    const setPending = (value)=>{
        isPending.value = value;
    };
    const stopUpdate = ()=>{
        if (updateIntervalId) Timer.clearInterval(updateIntervalId);
        updateIntervalId = null;
    };
    const resetState = ()=>{
        stopUpdate();
        oldPrice.value = 0;
        price.value = 0;
        isLoaded.value = false;
        setError(false);
        isCompleted.value = false;
        setPending(false);
        currentBetId.value = null;
        currentBetItem.value = null;
    };
    const setSlipCashoutState = (inProgress)=>{
        slipCashoutInProgress.value = inProgress;
    };
    const applyUpdate = (data)=>{
        if (data.amount) {
            setError(false);
            price.value = data.amount;
        }
        if (data.status === CashOutStatus.ERROR) setError(true, data.msg);
    };
    const getCashoutOffer = ()=>{
        if (!currentBetId.value) throw new Error('Cashout: currentBetId in not defined');
        const options = {
            betId: currentBetId.value,
            cashoutAmount: price.value || null
        };
        return requestCashoutOffer({
            graphqlClient,
            options
        });
    };
    const doUpdate = async ()=>{
        try {
            if (currentBetId.value) {
                const response = await getCashoutOffer();
                if (response) applyUpdate(response);
            }
        } catch (rawError) {
            if (rawError instanceof GqlApiAccessDeniedError) {
                setSlipCashoutState(false);
                resetState();
            }
            throw rawError;
        }
    };
    const startUpdate = ()=>{
        updateIntervalId = Timer.setInterval(doUpdate, UPDATE_TIME);
    };
    const getOfferAmount = async ()=>{
        if (currentBetId.value) {
            const response = await getCashoutOffer();
            if (response) {
                isLoaded.value = true;
                applyUpdate(response);
                startUpdate();
            }
        }
    };
    const handleCashoutComplete = ()=>{
        isCompleted.value = true;
        isNumber(currentBetId.value);
        sendCashoutMetrika(currentBetId.value, betType.value);
        stopUpdate();
    };
    const handleCashoutError = (payload)=>{
        setError(true, payload.msg);
        if (payload.newAmount) price.value = payload.newAmount;
    };
    const handleCashoutChanged = (newAmount)=>{
        oldPrice.value = price.value;
        price.value = newAmount;
        startUpdate();
    };
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const doCashout = async ()=>{
        if (currentBetId.value) {
            stopUpdate();
            setPending(true);
            const options = {
                betId: currentBetId.value,
                cashoutAmount: price.value
            };
            const response = await doCashOutRequest({
                graphqlClient,
                options
            });
            setPending(false);
            if (response) {
                const { reason, remainingDelay, newAmount, msg } = response;
                if (reason === CashOutReason.WAITING && remainingDelay) {
                    setPending(true);
                    Timer.setTimeout(()=>{
                        // call itself
                        doCashout();
                    }, remainingDelay);
                    return;
                }
                if (response.status === CashOutStatus.OK && reason === CashOutReason.DONE) {
                    handleCashoutComplete();
                    return;
                }
                if (reason === CashOutReason.AMOUNT_CHANGED && newAmount) {
                    handleCashoutChanged(newAmount);
                    return;
                }
                if (response.status === CashOutStatus.ERROR) handleCashoutError({
                    newAmount,
                    msg
                });
            }
        }
    };
    const closeSlipOnCashout = ()=>{
        pendingBetsStore.setIsLoaded(false);
        setSlipCashoutState(false);
        if (slipActiveTopTabId.value === TopLevelTabs.MY_BETS) pendingBetsStore.loadPendingBets();
    };
    const initCashout = async (payload)=>{
        resetState();
        currentBetId.value = payload.betId;
        currentBetItem.value = payload?.item ?? null;
        if (payload.betType) betType.value = payload.betType;
        if (payload.data) {
            isLoaded.value = true;
            applyUpdate(payload.data);
        } else await getOfferAmount();
    };
    return {
        setSlipCashoutState,
        initCashout,
        doCashout,
        applyUpdate,
        resetState,
        closeSlipOnCashout,
        coreComponentProps,
        slipCashoutInProgress,
        cashoutAvailable,
        currentBetId,
        mode,
        errorMessage
    };
});
export default useCashoutStore;
