import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VForm } from 'web/src/components/Form';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';
import completionRegistrationWidgets from '../../constants/completionRegistrationWidgets';
import { useRegistrationCompletionRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegistrationCompletionRoutePage',
    props: {
        manualCustomerAction: {},
        description: {}
    },
    emits: [
        "after-submit"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const componentProps = __props;
        const emit = __emit;
        const { schema, uiSchema, form, descriptionText, isAsoTerms, isExAllowedCountry, isLoading, isPending, customErrors, onFormMounted, validateForm, goHome, onSubmit, onChange } = useRegistrationCompletionRouteComponent(componentProps, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['registration-completion']]: true,
                    [_ctx.$style['registration-completion-padding']]: !_ctx.manualCustomerAction && !_unref(isAsoTerms),
                    [_ctx.$style['registration-completion-aso']]: _unref(isAsoTerms)
                })
            }, [
                !_unref(isLoading) && _unref(schema) && _unref(uiSchema) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(isAsoTerms) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['registration-completion__description'])
                    }, _toDisplayString(_unref(descriptionText)), 3)),
                    _unref(isExAllowedCountry) ? (_openBlock(), _createBlock(VButton, {
                        key: 1,
                        label: _ctx.$t('WEB2_REGISTRATION_RESTRICTED_COUNTRY_CONTINUE_BUTTON'),
                        kind: _unref(ButtonKind).PRIMARY,
                        class: _normalizeClass(_ctx.$style['registration-completion__go-home']),
                        onClick: _unref(goHome)
                    }, null, 8, [
                        "label",
                        "kind",
                        "class",
                        "onClick"
                    ])) : (_openBlock(), _createBlock(_unref(VForm), {
                        key: 2,
                        ref_key: "form",
                        ref: form,
                        schema: _unref(schema),
                        "ui-schema": _unref(uiSchema),
                        "custom-errors": _unref(customErrors),
                        "is-pending": _unref(isPending),
                        onSubmit: _unref(onSubmit),
                        onChange: _unref(onChange),
                        onVnodeMounted: _unref(onFormMounted),
                        onFilled: _unref(validateForm)
                    }, _createSlots({
                        _: 2
                    }, [
                        _renderList(_unref(completionRegistrationWidgets), (component, slotName)=>({
                                name: slotName,
                                fn: _withCtx((param)=>{
                                    let { props, events, ref } = param;
                                    return [
                                        (_openBlock(), _createBlock(_resolveDynamicComponent(component), _mergeProps({
                                            ref: ref
                                        }, props, _toHandlers(events)), null, 16))
                                    ];
                                })
                            }))
                    ]), 1032, [
                        "schema",
                        "ui-schema",
                        "custom-errors",
                        "is-pending",
                        "onSubmit",
                        "onChange",
                        "onVnodeMounted",
                        "onFilled"
                    ]))
                ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'RegistrationCompletionRoutePage'
                ]
            ]);
        };
    }
});
