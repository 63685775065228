import { AdditionalInputEventType } from 'web/src/components/Input/enums';
export default function useAdditionalInputEvents(emit) {
    const emitMousedown = (event)=>{
        emit(AdditionalInputEventType.MOUSEDOWN, event);
    };
    const emitKeydown = (event)=>{
        emit(AdditionalInputEventType.KEYDOWN, event);
    };
    const emitMouseover = (event)=>{
        emit(AdditionalInputEventType.MOUSEOVER, event);
    };
    const emitMouseleave = (event)=>{
        emit(AdditionalInputEventType.MOUSELEAVE, event);
    };
    const emitPaste = (event)=>{
        emit(AdditionalInputEventType.PASTE, event);
    };
    return {
        emitMousedown,
        emitKeydown,
        emitMouseover,
        emitMouseleave,
        emitPaste
    };
}
