import { computed } from 'vue';
import { isLiveSportlineEvent } from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';
export function useLiveMatchProgress(props) {
    const { sportEvent } = props;
    const isLive = computed(()=>isLiveSportlineEvent(sportEvent.value));
    const matchProgress = computed(()=>isLiveSportlineEvent(sportEvent.value) ? sportEvent.value.matchProgress : null);
    return {
        isLive,
        matchProgress
    };
}
