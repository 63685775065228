import { computed } from 'vue';
function useTimeLeftPretty(timeLeft) {
    let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const max = 99;
    return computed(()=>{
        const value = timeLeft.value < 0 ? 0 : timeLeft.value;
        if (max && value > max) return `${max}`;
        if (!options.useLeadingZero?.value) return `${value}`;
        return value < 10 ? `0${value}` : `${value}`;
    });
}
export default function useTimeLeft(targetTime, now, options) {
    const distance = computed(()=>targetTime.value > now.value ? targetTime.value - now.value : 0);
    const daysLeft = computed(()=>Math.floor(distance.value / 86400000));
    const daysLeftPretty = useTimeLeftPretty(daysLeft, options ?? {});
    const hoursLeft = computed(()=>Math.floor(distance.value / 3600000 % 24));
    const hoursLeftPretty = useTimeLeftPretty(hoursLeft, options ?? {});
    const minutesLeft = computed(()=>Math.floor(distance.value / 60000 % 60));
    const minutesLeftPretty = useTimeLeftPretty(minutesLeft, options ?? {});
    const secondsLeft = computed(()=>Math.floor(distance.value / 1000 % 60));
    const secondsLeftPretty = useTimeLeftPretty(secondsLeft, options ?? {});
    return {
        distance,
        daysLeft,
        daysLeftPretty,
        hoursLeft,
        hoursLeftPretty,
        minutesLeft,
        minutesLeftPretty,
        secondsLeft,
        secondsLeftPretty
    };
}
