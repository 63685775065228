import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { Tag } from '@leon-hub/tags';
import { VIcon } from '@components/v-icon';
import { useVSubHeader } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSubHeader',
    props: {
        tag: {
            default: Tag.DIV
        },
        text: {},
        prefixIcon: {},
        suffixIcon: {},
        href: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { mainTag, mainProperties, prefixIconProperties, suffixIconProperties } = useVSubHeader(props);
        function emitClick() {
            emit('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(mainTag)), _mergeProps(_unref(mainProperties), {
                ref: "header",
                class: {
                    [_ctx.$style['sub-header']]: true,
                    [_ctx.$style['sub-header--link']]: !!_ctx.href
                },
                onClick: _withModifiers(emitClick, [
                    "prevent"
                ])
            }), {
                default: _withCtx(()=>[
                        _ctx.prefixIcon ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                            key: 0
                        }, _unref(prefixIconProperties), {
                            class: _ctx.$style['sub-header__icon sub-header__icon--prefix']
                        }), null, 16, [
                            "class"
                        ])) : _createCommentVNode("", true),
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
                            class: _normalizeClass(_ctx.$style['sub-header__text'])
                        }, {
                            default: _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_ctx.text), 1)
                                ]),
                            _: 1
                        }, 8, [
                            "class"
                        ])),
                        _ctx.suffixIcon ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                            key: 1
                        }, _unref(suffixIconProperties), {
                            class: _ctx.$style['sub-header__icon sub-header__icon--suffix']
                        }), null, 16, [
                            "class"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 16, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'VSubHeader'
                ]
            ]);
        };
    }
});
