// Disable forced style scoped attribute, because we use here modules.
/* eslint-disable vue-scoped-css/require-scoped */ import { defineComponent, h, useCssModule } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default defineComponent({
    name: 'DropdownSelectIcon',
    components: {
        VIcon
    },
    props: {
        isOpen: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        }
    },
    setup (props, context) {
        const $style = useCssModule();
        const rootClass = 'dropdown-select-icon';
        return ()=>{
            const cssClasses = [];
            if ($style) {
                cssClasses.push($style[rootClass]);
                if (props.isOpen) cssClasses.push($style[`${rootClass}--open`]);
                if (props.isLarge) cssClasses.push($style[`${rootClass}--large`]);
            }
            return h(VIcon, {
                name: IconName.TRIANGLE_DOWN,
                size: IconSize.SIZE_16,
                class: cssClasses,
                ...context.attrs
            });
        };
    }
});
