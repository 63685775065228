import { ComponentKey } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let TimezoneOffsetComponent = class TimezoneOffsetComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result([
            new Date().getTimezoneOffset()
        ]);
    }
    constructor(){
        super(ComponentKey.TimezoneOffset);
    }
};
export { TimezoneOffsetComponent as default };
