import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import useLeaderBoardParticipant from 'web/src/modules/promotions/components/LeaderBoardParticipant/composables/useLeaderBoardParticipant';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaderBoardParticipant',
    props: {
        participant: {},
        active: {
            type: Boolean
        },
        sticky: {
            type: Boolean
        }
    },
    emits: [
        "mounted"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { score } = useLeaderBoardParticipant(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.participant ? _withDirectives((_openBlock(), _createElementBlock("tr", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['leader-board__row']]: true,
                    [_ctx.$style['leader-board__row--active']]: _ctx.active,
                    [_ctx.$style['leader-board__row--sticky-bottom']]: _ctx.sticky,
                    [_ctx.$style['leader-board__row--first-place']]: _ctx.participant?.place === 1,
                    [_ctx.$style['leader-board__row--second-place']]: _ctx.participant?.place === 2,
                    [_ctx.$style['leader-board__row--third-place']]: _ctx.participant?.place === 3
                })
            }, [
                _createElementVNode("td", {
                    class: _normalizeClass({
                        [_ctx.$style['leader-board__cell']]: true,
                        [_ctx.$style['leader-board__cell--place']]: true
                    })
                }, _toDisplayString(_ctx.participant.place?.toString()), 3),
                _createElementVNode("td", {
                    class: _normalizeClass({
                        [_ctx.$style['leader-board__cell']]: true,
                        [_ctx.$style['leader-board__cell--name']]: true
                    })
                }, _toDisplayString(_ctx.participant.name), 3),
                _createElementVNode("td", {
                    class: _normalizeClass({
                        [_ctx.$style['leader-board__cell']]: true,
                        [_ctx.$style['leader-board__cell--prize']]: true
                    })
                }, _toDisplayString(_ctx.participant.prize), 3),
                _createElementVNode("td", {
                    class: _normalizeClass({
                        [_ctx.$style['leader-board__cell']]: true,
                        [_ctx.$style['leader-board__cell--score']]: true
                    })
                }, _toDisplayString(_unref(score)), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaderBoardParticipant'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
