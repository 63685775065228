import { computed, ref, toRef } from 'vue';
import { getSpintaxMetaData, RequestOptionsPriority } from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';
import { getSpintaxSeoMetaDataRequestOptionsForRoute } from 'web/src/modules/seo/utils/spintax';
import { isSpintaxSeoMetaData } from 'web/src/modules/seo/guards/isSpintaxSeoMetaData';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useSpintaxSeoMetaDataCache } from './useSpintaxSeoMetaDataCache';
export function useSpintaxSeoMetaInfo(props) {
    const { metaData } = props;
    return computed(()=>{
        if (!metaData.value) return;
        const titleConfig = metaData.value.title;
        const metaConfig = metaData.value.meta;
        return {
            title: titleConfig ? titleConfig.filter((value)=>'content' in value) : void 0,
            meta: metaConfig ? metaConfig.flatMap((config)=>Object.keys(config).map((name)=>({
                        name,
                        content: config[name]
                    }))) : void 0
        };
    });
}
export function useSpintaxSeoMetaData() {
    const siteConfigStore = useSiteConfigStore();
    const isMetaBetCmsSpintaxEnabled = toRef(siteConfigStore, 'isMetaBetCmsSpintaxEnabled');
    const api = useGraphqlClient();
    const cache = useSpintaxSeoMetaDataCache();
    const metaData = ref(null);
    const metaInfo = useSpintaxSeoMetaInfo({
        metaData
    });
    async function fetchMetaData(route) {
        const options = getSpintaxSeoMetaDataRequestOptionsForRoute(route);
        if (!options || !isMetaBetCmsSpintaxEnabled.value) return null;
        return getSpintaxMetaData(api, (node)=>node.queries.system.cms.getSpintaxMetadata, {
            options
        }, {
            silent: true,
            priority: RequestOptionsPriority.LOW,
            group: RequestGroupScore24
        });
    }
    async function updateActualMetaData(route) {
        let result = cache.getCacheForRoute(route);
        if (!result) {
            const response = await fetchMetaData(route);
            const metadata = response?.metadata;
            result = isSpintaxSeoMetaData(metadata) ? metadata : null;
            if (result) cache.setCacheForRoute(route, result);
        }
        metaData.value = result ?? null;
    }
    return {
        metaData,
        metaInfo,
        updateActualMetaData
    };
}
