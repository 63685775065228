import { computed, ref } from 'vue';
import { SessionExceptionCode } from '@leon-hub/api-sdk';
import { isString } from '@leon-hub/guards';
import getCreditCardTokenizationApi from 'web/src/modules/payments/store/utils/getCreditCardTokenizationApi';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
import isValidCreditCardForTokenize from 'web/src/modules/payments/utils/isValidCreditCardForTokenize';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import isTokenizedCardField from 'web/src/modules/payments/utils/isTokenizedCardField';
import { InvalidCreditCardTokenizeCode } from 'web/src/modules/payments/enums';
export default function useCreditCardTokenization() {
    const tokenizationApiClient = getCreditCardTokenizationApi();
    const { isCcTokensEnabled } = usePaymentsConfig();
    const { $translate } = useI18n();
    const { convertToBaseError } = useErrorsConverter();
    const paymentRequestPayload = ref({
        formParams: [],
        paymentSystemId: '',
        schemaId: ''
    });
    const token = ref('');
    function setPaymentRequestPayload(payload) {
        paymentRequestPayload.value = payload;
    }
    function resetToken() {
        token.value = '';
    }
    const pan = computed(()=>{
        let value;
        if (isCcTokensEnabled.value && null !== paymentRequestPayload.value) value = paymentRequestPayload.value.formParams.find(isTokenizedCardField)?.value;
        return isString(value) ? value : void 0;
    });
    async function getToken(bankcards) {
        try {
            if (isValidCreditCardForTokenize({
                pan: pan.value,
                bankcards
            }) && pan.value) {
                const tokenize = await tokenizationApiClient.tokenize({
                    pan: pan.value
                });
                token.value = tokenize.token;
            }
        } catch (rawError) {
            const error = convertToBaseError(rawError);
            if (error.code.equals(InvalidCreditCardTokenizeCode.TOKENIZE_ERROR_INVALID_PAN)) error.message = $translate('WEB2_TOKENIZE_ERROR_INVALID_PAN').value;
            else if (error.code.equals(SessionExceptionCode.SESSION)) error.message = $translate('WEB2_TOKENIZE_ERROR_SESSION').value;
            throw error;
        }
    }
    const tokenizePayload = computed(()=>{
        if (token.value) return {
            ...paymentRequestPayload.value,
            formParams: paymentRequestPayload.value.formParams.map((item)=>{
                if (isTokenizedCardField(item)) return {
                    ...item,
                    value: token.value
                };
                return item;
            })
        };
        return paymentRequestPayload.value;
    });
    const doTokenize = async (data, bankcards)=>{
        setPaymentRequestPayload(data);
        await getToken(bankcards);
    };
    return {
        doTokenize,
        tokenizePayload,
        resetToken
    };
}
