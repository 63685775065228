import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ErrorHeaderBar',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['error-header-bar'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['error-header-bar__inner'])
                }, [
                    _createVNode(VBrand)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ErrorHeaderBar'
                ]
            ]);
        };
    }
});
