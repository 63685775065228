import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
import DateTime from 'web/src/utils/DateTime';
import { resolveSportEventsDetailsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
/** @deprecated do not use */ export function getSportEventSeoMetaInfo(options) {
    if (!options) return {};
    const { sportEvent, sport, region, league } = options;
    const coreData = {
        name: sportEvent.name,
        kickoff: sportEvent.kickoff.toString(),
        time: DateTime.withTimeStamp(sportEvent.kickoff).toTime(),
        date: useDateLocalizers().toFullLocaleDate(sportEvent.kickoff),
        startDateISO: new DateTime(sportEvent.kickoff).toISOString(),
        sport: sport.name,
        sportFamily: sport.navigationParameters.urlName,
        sportEmoji: sport.representation.emoji,
        region: region.name,
        league: league.name,
        url: 'url' in options ? options.url : options.vm.$router.getHref(resolveSportEventsDetailsPageLink(sportEvent.navigationParameters)).absolute
    };
    if (sportEvent.isOutright) return coreData;
    return {
        ...coreData,
        home: sportEvent.competitors[0]?.name || '',
        away: sportEvent.competitors[1]?.name || '',
        logoHome: sportEvent.competitors[0]?.logo || '',
        logoAway: sportEvent.competitors[1]?.logo || ''
    };
}
export function extractSportEventSeoMetaInfoFromRegionElement(regionElement, options) {
    if (!regionElement) return [];
    return regionElement.leagues.flatMap((leagueElement)=>leagueElement.sportEvents.map((sportEventElement)=>getSportEventSeoMetaInfo({
                ...options,
                sportEvent: sportEventElement.sportEvent,
                region: regionElement.region,
                league: leagueElement.league
            })));
}
export function extractSportEventSeoMetaInfoFromSportElement(sportElement, options) {
    if (!sportElement) return [];
    return sportElement.regions.flatMap((regionElement)=>extractSportEventSeoMetaInfoFromRegionElement(regionElement, {
            ...options,
            sport: sportElement.sport
        }));
}
export function extractSportEventSeoMetaInfo(element, options) {
    if (!element) return [];
    if ('regions' in element) return extractSportEventSeoMetaInfoFromSportElement(element, options);
    const { sport } = options;
    if (sport) return extractSportEventSeoMetaInfoFromRegionElement(element, {
        ...options,
        sport
    });
    return [];
}
