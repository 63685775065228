import { computed } from 'vue';
import { useSportlineEventCompetitors } from 'web/src/modules/sportline/composables/sportline-event';
export function useSportlineEventInfo(props) {
    const { sportlineEvent } = props;
    const { host, guest } = useSportlineEventCompetitors({
        sportlineEvent
    });
    const sportlineEventId = computed(()=>sportlineEvent.value.id);
    const hostName = computed(()=>host.value?.name ?? '');
    const guestName = computed(()=>guest.value?.name ?? '');
    return {
        sportlineEventId,
        hostName,
        guestName
    };
}
