import { isValidInterface } from '@leon-hub/guards';
import { isCoreSportResponseFieldGuards } from './isCoreSportResponse';
import { isCoreRegionResponseFieldGuards } from './isCoreRegionResponse';
import { isCoreLeagueResponseFieldGuards } from './isCoreLeagueResponse';
const isGetSportResponseFieldGuards = {
    ...isCoreSportResponseFieldGuards
};
export function isGetSportResponse(value) {
    return isValidInterface(value, isGetSportResponseFieldGuards);
}
export const isGetRegionResponseFieldGuards = {
    ...isCoreRegionResponseFieldGuards,
    sport: isGetSportResponse
};
export function isGetRegionResponse(value) {
    return isValidInterface(value, isGetRegionResponseFieldGuards);
}
const isGetLeagueResponseFieldGuards = {
    ...isCoreLeagueResponseFieldGuards,
    sport: (value)=>!value,
    region: isGetRegionResponse
};
export function isGetLeagueResponse(value) {
    return isValidInterface(value, isGetLeagueResponseFieldGuards);
}
