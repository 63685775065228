import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import { useEgsInjectedGameIframeWidget } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsInjectedGameIframeWidget',
    props: {
        gameHtml: {},
        allow: {},
        flexLayout: {
            type: Boolean
        },
        allowfullscreen: {
            type: Boolean
        },
        timeout: {}
    },
    emits: [
        "load",
        "error"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { iframeName, gameUrl, onIframeMounted, onIframeLoaded } = useEgsInjectedGameIframeWidget(props, emit);
        const iframeElement = ref();
        function postMessage(message) {
            let target = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '*';
            iframeElement.value?.postMessage(message, target);
        }
        __expose({
            postMessage
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(VIframe, {
                ref_key: "iframeElement",
                ref: iframeElement,
                name: _unref(iframeName),
                src: _unref(gameUrl),
                allow: _ctx.allow,
                "flex-layout": _ctx.flexLayout,
                allowfullscreen: _ctx.allowfullscreen,
                timeout: _ctx.timeout,
                "full-width": "",
                "full-height": "",
                "hide-title": "",
                "is-dark": "",
                onVnodeMounted: _unref(onIframeMounted),
                onLoad: _unref(onIframeLoaded),
                onError: _cache[0] || (_cache[0] = ($event)=>emit('error', $event))
            }, null, 8, [
                "name",
                "src",
                "allow",
                "flex-layout",
                "allowfullscreen",
                "timeout",
                "onVnodeMounted",
                "onLoad"
            ])), [
                [
                    _directive_auto_id,
                    'EgsInjectedGameIframeWidget'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'started-game-iframe'
                    }
                ]
            ]);
        };
    }
});
