import { computed, ref, toRef } from 'vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { PaymentsIdentificationButtonName } from 'web/src/modules/payments/enums';
import useRouterStore from '../../core/store/useRouterStore';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function usePaymentsIdentification(paymentType) {
    const { $translate } = useI18n();
    const routerStore = useRouterStore();
    const previousRouteName = toRef(()=>routerStore.previousRouteName);
    return {
        isIdentifiedUser: ref(true),
        showIdentificationStatus: ref(false),
        identificationStatusProperties: computed(()=>({
                buttonsProperties: [
                    {
                        id: PaymentsIdentificationButtonName.RETRY,
                        label: $translate('JS_MOBILE_IDENT_HUB_ESIA_RETRY_BTN').value,
                        kind: ButtonKind.PRIMARY,
                        fullWidth: true
                    }
                ]
            })),
        onIdentificationStatusButtonClick: ()=>{},
        waitForGettingIdentificationState: ()=>Promise.resolve(),
        finishCustomerIdentification: ()=>Promise.resolve(),
        showIdentificationStatusModal: ()=>Promise.resolve(),
        onBeforeDetailsPageMounted: ()=>Promise.resolve()
    };
}
