import { computed, toRef } from 'vue';
import { lobbyTypeSearch } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
export default function useCasinoSearchLobby(props, emits) {
    const lobbyType = lobbyTypeSearch;
    const { isLoggedIn } = useIsLoggedIn();
    const siteConfigStore = useSiteConfigStore();
    const egsSkeletons = toRef(siteConfigStore, 'egsSkeletons');
    const skeletons = computed(()=>{
        const searchSkeletons = egsSkeletons.value?.search ?? [];
        if (!isLoggedIn.value) {
            if (props.isSport) return [];
            return searchSkeletons.slice(1);
        }
        return props.isSport ? searchSkeletons.slice(0, 1) : searchSkeletons;
    });
    function emitGameClick() {
        emits('game-click');
    }
    return {
        lobbyType,
        skeletons,
        emitGameClick
    };
}
