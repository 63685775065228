import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { AutoheightTextInput } from 'web/src/components/Input';
import { useBaseFormWidgetEvents, useCommonFormWidgetProps } from '../../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FormAutoheightTextInput',
    props: {
        title: {},
        hintMessage: {},
        disabled: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        name: {},
        extraProperties: {},
        value: {
            type: [
                String,
                Boolean,
                Number,
                Array,
                Object
            ]
        },
        options: {},
        error: {}
    },
    emits: [
        "change",
        "input",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { emitChange, emitInput, emitFocus, emitBlur } = useBaseFormWidgetEvents(emit);
        const { componentProps } = useCommonFormWidgetProps(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(AutoheightTextInput), _mergeProps({
                "max-length": props?.extraProperties?.maxLength
            }, _unref(componentProps), {
                onInput: _unref(emitInput),
                onChange: _unref(emitChange),
                onFocus: _unref(emitFocus),
                onBlur: _unref(emitBlur)
            }), null, 16, [
                "max-length",
                "onInput",
                "onChange",
                "onFocus",
                "onBlur"
            ])), [
                [
                    _directive_auto_id,
                    'FormAutoheightTextInput'
                ]
            ]);
        };
    }
});
