import { computed } from 'vue';
import createUniqueId from 'web/src/utils/createUniqueId';
export default function useSuggestEmailInput(props) {
    const listId = computed(()=>createUniqueId(`${props.name}-list`));
    const getEmailValue = function() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        return value.includes('@') ? value.split('@')[0] : '';
    };
    return {
        listId,
        getEmailValue
    };
}
