export function getNextSlideIndexOffset(activeIndex, slidesCounter) {
    let indexOffset = activeIndex === slidesCounter - 3 ? 2 : 1;
    const clientWidth = window.innerWidth || document.documentElement.clientWidth;
    if (clientWidth < 1280) indexOffset = activeIndex === slidesCounter - 2 ? 2 : 1;
    if (clientWidth < 1024) indexOffset = 1;
    return indexOffset;
}
export function getPreviousSlideIndexOffset(activeIndex, slidesCounter) {
    let indexOffset = activeIndex === slidesCounter - 1 ? 2 : 1;
    const clientWidth = window.innerWidth || document.documentElement.clientWidth;
    if (clientWidth < 1280) indexOffset = 0 === activeIndex ? 2 : 1;
    if (clientWidth < 1024) indexOffset = 1;
    return indexOffset;
}
