import { onActivated, onBeforeMount, onBeforeUnmount, onDeactivated } from 'vue';
import useTimeBus from './useTimeBus';
export default function useSafeTimeBus() {
    let updateEvery = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 1000;
    const { now, start, stop, subscribe, unsubscribe } = useTimeBus(updateEvery);
    let isActive = false;
    const activated = ()=>{
        if (isActive) return;
        start();
        isActive = true;
    };
    const deactivated = ()=>{
        if (!isActive) return;
        stop();
        isActive = false;
    };
    onBeforeMount(activated);
    onActivated(activated);
    onBeforeUnmount(deactivated);
    onDeactivated(deactivated);
    return {
        now,
        subscribe,
        unsubscribe
    };
}
