import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = [
    "alt",
    "height",
    "width",
    "src"
];
import { useVLogo } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLogo',
    props: {
        name: {},
        theme: {},
        productSpecificName: {},
        width: {},
        height: {},
        src: {},
        mascotName: {}
    },
    setup (__props) {
        const props = __props;
        const { src, alt } = useVLogo(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("img", {
                class: "logo",
                alt: _unref(alt),
                height: _ctx.height,
                width: _ctx.width,
                src: _unref(src)
            }, null, 8, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VLogo'
                ]
            ]);
        };
    }
});
