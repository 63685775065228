import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { resolveSportEventsDetailsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import routerLinkClickGuard from 'web/src/components/Link/utils/routerLinkClickGuard';
export function useSportlineEventLink(props, emits) {
    const { sportEvent, activeEventId } = props;
    const router = useRouter();
    const detailsPageTarget = computed(()=>{
        const location = resolveSportEventsDetailsPageLink(sportEvent.value.navigationParameters);
        return router.resolve(location);
    });
    const isActive = computed(()=>activeEventId?.value === sportEvent.value.id);
    const href = computed(()=>isActive.value ? void 0 : detailsPageTarget.value.href);
    function onLinkClick(mouseEvent) {
        let checkLinkGuard = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
        if (!checkLinkGuard || routerLinkClickGuard(mouseEvent)) {
            router.push(detailsPageTarget.value);
            emits.onClick?.();
            return true;
        }
        return false;
    }
    return {
        href,
        isActive,
        onLinkClick
    };
}
