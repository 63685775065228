import { ref } from 'vue';
export function useVFavoriteToggle(emit) {
    const isToggled = ref(false);
    function onClick(event) {
        isToggled.value = true;
        emit('click', event);
    }
    function onMouseLeave() {
        isToggled.value = false;
    }
    return {
        onClick,
        onMouseLeave,
        isToggled
    };
}
