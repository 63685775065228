export function isValidNumberInput(input) {
    let decimalPlacesCount = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
    const startsWithDot = input.startsWith('.');
    const extraZeros = input.startsWith('0') && !input.startsWith('0.') && input?.length > 1;
    const extraDecimalSeparatorsCount = decimalPlacesCount > 0 ? 2 : 1;
    const decimalSeparators = input.replace(/[^+.]/g, '')?.length;
    const wrongChars = input.replace('.', '').replace(/\d/g, '');
    const decimalsCount = decimalSeparators ? input.split('.')[1]?.length : 0;
    return decimalSeparators < extraDecimalSeparatorsCount && !startsWithDot && !extraZeros && !wrongChars?.length && decimalsCount <= decimalPlacesCount;
}
