import { isFunction } from '@leon-hub/guards';
import { isFormWidget } from 'web/src/components/Form/guards';
export default function getRelatedUiFieldCustomProperties(field, propertiesMapByWidget, propertiesMapById) {
    const { id, widget } = field;
    isFormWidget(widget);
    const propertiesByWidget = propertiesMapByWidget.get(widget) ?? {};
    const propertiesById = propertiesMapById.get(id) ?? {};
    const mergedProperties = {
        ...isFunction(propertiesByWidget) ? propertiesByWidget(field) : propertiesByWidget,
        ...isFunction(propertiesById) ? propertiesById(field) : propertiesById
    };
    const result = {};
    const allKeys = Object.keys(mergedProperties);
    for (const key of allKeys){
        const relatedValue = mergedProperties[key];
        result[key] = isFunction(relatedValue) ? relatedValue(field) : relatedValue;
    }
    return result;
}
