import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import { usePaymentsDepositsNavigation } from 'web/src/modules/core/composables/navigation';
export default function useMainBalance(props, emit) {
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const analytics = useAnalytics();
    const { pushToDepositsPage } = usePaymentsDepositsNavigation();
    const { currency } = useCurrency();
    const isSingleMode = computed(()=>props.mode === BalanceMode.Single);
    const buttonProperties = computed(()=>({
            label: isSingleMode.value ? $translate('WEB2_REPLENISH_SHORT').value : '',
            kind: ButtonKind.PRIMARY,
            iconName: isSingleMode.value ? IconName.ADD : IconName.PLUS_SMALL,
            iconSize: isSingleMode.value ? IconSize.SIZE_16 : IconSize.SIZE_24,
            rounded: !isSingleMode.value
        }));
    const formattedBalance = computed(()=>formatMoney(useCustomerFinanceStore().balance, {
            hideCurrency: false
        }));
    computed(()=>({
            currency: currency.value
        }));
    const balanceLabel = computed(()=>`${$translate('JSPACC_ACC_BALANCE').value} ${formattedBalance.value}`);
    function handleClick() {
        analytics.clickMap({
            deposit: 'userProfileDeposit'
        });
        pushToDepositsPage();
        emit('open-deposit');
    }
    return {
        buttonProperties,
        formattedBalance,
        balanceLabel,
        handleClick
    };
}
