export var CasinoLoyaltyProgramIconSizes;
var CasinoLoyaltyProgramIconSizes1;
(CasinoLoyaltyProgramIconSizes1 = CasinoLoyaltyProgramIconSizes || (CasinoLoyaltyProgramIconSizes = {}))["DEFAULT"] = "default", CasinoLoyaltyProgramIconSizes1["SIZE_24"] = "size-24", CasinoLoyaltyProgramIconSizes1["SIZE_32"] = "size-32", CasinoLoyaltyProgramIconSizes1["SIZE_36"] = "size-36", CasinoLoyaltyProgramIconSizes1["SIZE_42"] = "size-42", CasinoLoyaltyProgramIconSizes1["SIZE_68"] = "size-68", CasinoLoyaltyProgramIconSizes1["SIZE_80"] = "size-80", CasinoLoyaltyProgramIconSizes1["SIZE_86"] = "size-86", CasinoLoyaltyProgramIconSizes1["SIZE_92"] = "size-92", CasinoLoyaltyProgramIconSizes1["SIZE_94"] = "size-94", CasinoLoyaltyProgramIconSizes1["SIZE_96"] = "size-96", CasinoLoyaltyProgramIconSizes1["SIZE_112"] = "size-112", CasinoLoyaltyProgramIconSizes1["SIZE_120"] = "size-120", CasinoLoyaltyProgramIconSizes1["SIZE_132"] = "size-132", CasinoLoyaltyProgramIconSizes1["SIZE_136"] = "size-136", CasinoLoyaltyProgramIconSizes1["SIZE_160"] = "size-160", CasinoLoyaltyProgramIconSizes1["SIZE_180"] = "size-180", CasinoLoyaltyProgramIconSizes1["SIZE_200"] = "size-200", CasinoLoyaltyProgramIconSizes1["SIZE_230"] = "size-230";
export var CasinoLoyaltyProgramProgressSizes;
var CasinoLoyaltyProgramProgressSizes1;
(CasinoLoyaltyProgramProgressSizes1 = CasinoLoyaltyProgramProgressSizes || (CasinoLoyaltyProgramProgressSizes = {}))["SIZE_42"] = "size-42", CasinoLoyaltyProgramProgressSizes1["SIZE_92"] = "size-92", CasinoLoyaltyProgramProgressSizes1["SIZE_94"] = "size-94", CasinoLoyaltyProgramProgressSizes1["SIZE_112"] = "size-112";
export var CasinoLoyaltyProgramLevelStatus;
var CasinoLoyaltyProgramLevelStatus1;
(CasinoLoyaltyProgramLevelStatus1 = CasinoLoyaltyProgramLevelStatus || (CasinoLoyaltyProgramLevelStatus = {}))["SUCCESS"] = "success", CasinoLoyaltyProgramLevelStatus1["PROCESS"] = "process", CasinoLoyaltyProgramLevelStatus1["LOCK"] = "lock";
export var CasinoLoyaltyProgramCardSizes;
var CasinoLoyaltyProgramCardSizes1;
(CasinoLoyaltyProgramCardSizes1 = CasinoLoyaltyProgramCardSizes || (CasinoLoyaltyProgramCardSizes = {}))["SIZE_116"] = "size-116", CasinoLoyaltyProgramCardSizes1["SIZE_124"] = "size-124";
