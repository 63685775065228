import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "swiper-slide__overflow"
};
import { useVSwiperSlide } from './composables';
const __default__ = {
    name: 'VSwiperSlide'
};
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        originalSlidesIndex: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { slide, className, isOverflowVisible, setVisibility, getOffsetWidth, getOffsetLeft } = useVSwiperSlide();
        function onClick(event) {
            emit('click', event);
        }
        __expose({
            originalSlidesIndex: props.originalSlidesIndex,
            setVisibility,
            getOffsetWidth,
            getOffsetLeft
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "slide",
                ref: slide,
                class: _normalizeClass(_unref(className)),
                onClick: onClick
            }, [
                _unref(isOverflowVisible) ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'VSwiperSlide'
                ]
            ]);
        };
    }
});
