/* eslint-disable class-methods-use-this */ import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
function getBaseCasinoGroupsRoute(param) {
    let { name, path } = param;
    return {
        name,
        path,
        modalPreset: ModalDesktopPreset.ProviderSlotDesktopModal,
        topBar: ()=>import('web/src/modules/casino/submodules/groups/views/CasinoGroupsTopBarRouteComponent/CasinoGroupsTopBarRouteComponent.vue'),
        component: ()=>import('web/src/modules/casino/submodules/groups/pages/CasinoGroupsRoutePage/CasinoGroupsRoutePage.vue'),
        prefetch: ()=>import('web/src/modules/casino/submodules/groups/prefetch/CasinoGroupsPagePrefetch')
    };
}
export class CasinoGroupsModule extends AppModule {
    addRoutes(router) {
        router.addModalRoute({
            ...getBaseCasinoGroupsRoute({
                name: CasinoRouteName.CASINO_GROUPS,
                path: '/casino/groups'
            })
        });
        router.addModalRoute({
            ...getBaseCasinoGroupsRoute({
                name: CasinoRouteName.CASINO_LIVE_GROUPS,
                path: '/live-casino/groups'
            })
        });
    }
}
