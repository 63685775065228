import { defineStore } from 'pinia';
import { ref } from 'vue';
import { doSendFeedback, getFeedbackTypes } from '@leon-hub/api-sdk/src/sdk/sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useUserFeedbackResponse from 'web/src/modules/feedback/composables/useUserFeedbackResponse';
const useSupportFeedbackStore = defineStore('supportFeedback', ()=>{
    const apiClient = useGraphqlClient();
    const feedbackTypes = ref([]);
    const { sendUserResponse } = useUserFeedbackResponse();
    async function fetchTypes(silent) {
        const { feedbackTypes: fbTypes } = await getFeedbackTypes(apiClient, (node)=>node.queries.feedback.getDetails, {
            options: {}
        }, {
            silent
        });
        feedbackTypes.value = [
            ...fbTypes
        ];
    }
    async function sendFeedback(input) {
        const { result } = await doSendFeedback(apiClient, (node)=>node.mutations.feedback.send, {
            options: {
                doAction: '1',
                email: input.email,
                betId: input.betId,
                description: input.description,
                failedResponsesLogs: '',
                feedbackUniqKey: input.type
            }
        });
        return 'OK' === result;
    }
    return {
        feedbackTypes,
        fetchTypes,
        sendFeedback,
        sendUserResponse
    };
});
export default useSupportFeedbackStore;
