import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { VJumbotron } from 'web/src/components/Jumbotron';
import RestorePasswordContentBlock from 'web/src/modules/restore-password/components/RestorePasswordContentBlock/RestorePasswordContentBlock.vue';
import { VForm } from 'web/src/components/Form';
import VTooltip from 'web/src/modules/dialogs/components/VTooltip/VTooltip.vue';
import useRestorePasswordByEmailInfo from './composables/useRestorePasswordByEmailInfo';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestorePasswordByEmailInfo',
    props: {
        isPin: {
            type: Boolean
        }
    },
    emits: [
        "form-submit",
        "form-input",
        "success"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { resendForm, iconProperties, tooltipLabel, currentEmail, formSchema, uiSchema, emitSubmit, emitInput, resendEmail } = useRestorePasswordByEmailInfo(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps(_unref(iconProperties), {
                heading: _ctx.$t('RESTORE_PASSWORD_APPLICATION_SENT'),
                class: _ctx.$style['restore']
            }), {
                default: _withCtx(()=>[
                        _createVNode(RestorePasswordContentBlock, {
                            "restore-title": _ctx.isPin ? _ctx.$t('WEB2_RESTORE_PIN_LETTER_SENT') : _ctx.$t('RESTORE_PASSWORD_LETTER_SENT', {
                                email: _unref(currentEmail)
                            }),
                            onClick: _unref(resendEmail)
                        }, null, 8, [
                            "restore-title",
                            "onClick"
                        ]),
                        _createVNode(_unref(VForm), {
                            ref_key: "resendForm",
                            ref: resendForm,
                            class: _normalizeClass(_ctx.$style['restore__resend-form']),
                            schema: _unref(formSchema),
                            "ui-schema": _unref(uiSchema),
                            onSubmit: _unref(emitSubmit),
                            onInput: _unref(emitInput)
                        }, null, 8, [
                            "class",
                            "schema",
                            "ui-schema",
                            "onSubmit",
                            "onInput"
                        ]),
                        _unref(tooltipLabel) ? (_openBlock(), _createBlock(VTooltip, {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['restore__tooltip']),
                            text: _unref(tooltipLabel)
                        }, null, 8, [
                            "class",
                            "text"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 16, [
                "heading",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'RestorePasswordByEmailInfo'
                ]
            ]);
        };
    }
});
