export default function windowOpen(url) {
    let target = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '_blank';
    if (url) {
        const width = 880;
        const height = 900;
        const leftPos = (window.screen.width - width) / 2;
        const topPos = (window.screen.height - height) / 2 - 60;
        return window.open(url, target, `left=${leftPos},top=${topPos},location=1,scrollbars=0,resizable=1,width=${width},height=${height}`);
    }
    return null;
}
