import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { IconSize } from '@leon-hub/icons';
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ConfirmModal',
    props: {
        modal: {},
        isAlert: {
            type: Boolean
        },
        isScrollBarEnabled: {
            type: Boolean
        },
        customBackground: {}
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        function onButtonClick(index) {
            const button = props.modal.buttons?.[index];
            if (button?.action) emit('button-click', {
                action: button.action
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(LazyDefaultModal), {
                image: _ctx.modal.image,
                "alert-icon-size": _ctx.modal.alertIconSize,
                icon: _ctx.modal.iconName,
                "icon-size": _ctx.modal.iconSize ?? (_ctx.modal.fullHeight ? _unref(IconSize).SIZE_80 : _unref(IconSize).SIZE_60),
                header: _ctx.modal.title,
                content: _ctx.modal.confirmMessage,
                "top-bar-text": _ctx.modal.topBarText,
                height: _ctx.modal.height,
                width: _ctx.modal.width,
                buttons: _ctx.modal.buttons,
                "is-alert": _ctx.isAlert,
                "is-scroll-bar-enabled": _ctx.isScrollBarEnabled,
                "is-full-height": _ctx.modal.fullHeight,
                "is-close-allowed": _ctx.modal.isCloseAllowed,
                "is-full-height-centered": _ctx.modal.isFullHeightCentered,
                "icon-kind": _ctx.modal.iconKind,
                "is-inner-modal": _ctx.modal.isInnerModal,
                "is-top-bar-hidden": _ctx.isAlert && !_ctx.modal.topBarText,
                "is-centered": _ctx.modal.isCentered,
                "is-overlay-close-allowed": _ctx.modal.isOverlayCloseAllowed,
                "is-profile-layout": _ctx.modal.isProfileLayout,
                "custom-background": _ctx.modal.customBackground,
                "is-small-width-content": _ctx.modal.isSmallWidthContent,
                "data-test-id": _ctx.modal.dataTestId,
                "overlay-type": _ctx.modal.overlayType,
                onClose: _cache[0] || (_cache[0] = ($event)=>emit('close')),
                onButtonClick: onButtonClick
            }, _createSlots({
                _: 2
            }, [
                _ctx.$slots.default ? {
                    name: "content",
                    fn: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                    key: "0"
                } : void 0,
                _ctx.$slots.icon ? {
                    name: "icon",
                    fn: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "icon")
                        ]),
                    key: "1"
                } : void 0,
                _ctx.$slots.footer ? {
                    name: "footer",
                    fn: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "footer")
                        ]),
                    key: "2"
                } : void 0
            ]), 1032, [
                "image",
                "alert-icon-size",
                "icon",
                "icon-size",
                "header",
                "content",
                "top-bar-text",
                "height",
                "width",
                "buttons",
                "is-alert",
                "is-scroll-bar-enabled",
                "is-full-height",
                "is-close-allowed",
                "is-full-height-centered",
                "icon-kind",
                "is-inner-modal",
                "is-top-bar-hidden",
                "is-centered",
                "is-overlay-close-allowed",
                "is-profile-layout",
                "custom-background",
                "is-small-width-content",
                "data-test-id",
                "overlay-type"
            ])), [
                [
                    _directive_auto_id,
                    'ConfirmModal'
                ]
            ]);
        };
    }
});
