import { LimitName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
export function getSwitcherOptions($translate) {
    return [
        {
            id: LimitName.DAILY,
            label: $translate('WEB2_RG_SWITCHER_DAILY').value
        },
        {
            id: LimitName.WEEKLY,
            label: $translate('WEB2_RG_SWITCHER_WEEKLY').value
        },
        {
            id: LimitName.MONTHLY,
            label: $translate('WEB2_RG_SWITCHER_MONTHLY').value
        }
    ];
}
