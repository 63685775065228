import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { checkRestorePasswordUid, LoginType, PhoneCodeMethod } from '@leon-hub/api-sdk/src/sdk/sdk';
import { checkRestorePasswordSms, doRestorePasswordByEmail, doRestorePasswordByPhone, sendRestorePasswordLinkToEmail, sendRestorePasswordSmsOrCallToPhone, sendRestorePasswordSmsToPhone } from '@leon-hub/api-sdk';
import { AppError } from '@leon-hub/app-errors';
import { CaptchaRequesterStrategy } from '@leon-hub/module-captcha';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useFormsStore } from 'web/src/modules/forms/store';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import formPhoneValueToApiPhoneInput from 'web/src/components/Input/utils/formPhoneValueToApiPhoneInput';
import { isCaptchaSettingsForStrategy } from 'web/src/modules/captcha/store/constants';
// eslint-disable-next-line sonarjs/cognitive-complexity
const useRestorePasswordStore = defineStore('restore-password', ()=>{
    const apiClient = useGraphqlClient();
    const formsStore = useFormsStore();
    const captchaStore = useCaptchaStore();
    const siteConfigStore = useSiteConfigStore();
    const captchaSettingsForStrategy = toRef(captchaStore, 'captchaSettingsForStrategy');
    const smsResendCodeTimer = toRef(siteConfigStore, 'smsResendCodeTimer');
    const isPhoneTabEnabledOnPasswordRestorePage = toRef(siteConfigStore, 'isPhoneTabEnabledOnPasswordRestorePage');
    const isEmailTabEnabledOnPasswordRestorePage = toRef(siteConfigStore, 'isEmailTabEnabledOnPasswordRestorePage');
    const activeTab = ref(isPhoneTabEnabledOnPasswordRestorePage.value ? LoginType.PHONE : LoginType.EMAIL);
    const retrySmsCodeTimestamp = ref(0);
    /** User request restore link to this email */ const currentEmail = ref(null);
    /** User request restore link to this phone */ const currentPhone = ref(null);
    /** We got this uid from restore link */ const willVerifiedUid = ref(null);
    /** We got this email from restore link */ const willVerifiedEmail = ref(null);
    /** Set after we check uid and email are actual. false - an error occurred */ const verifiedUid = ref(null);
    /** Set after we check uid and email are actual. false - an error occurred */ const verifiedEmail = ref(null);
    /** Set after we check phone is actual */ const verifiedPhone = ref(null);
    const phoneCheckType = ref(null);
    const fetchInitErrorMessage = ref(null);
    const captchaSettings = computed(()=>{
        const settings = captchaSettingsForStrategy.value(CaptchaRequesterStrategy.RESTORE_PASSWORD);
        isCaptchaSettingsForStrategy(settings.value);
        return settings.value;
    });
    const captchaEnabled = computed(()=>Boolean(captchaSettings.value?.isEnabledToShow));
    const retrySmsCodeTime = computed(()=>{
        const currentTime = Date.now();
        if (currentTime < retrySmsCodeTimestamp.value) {
            const retrySmsCodeTimeInSeconds = (retrySmsCodeTimestamp.value - currentTime) / 1000;
            return Math.floor(retrySmsCodeTimeInSeconds);
        }
        return null;
    });
    const restorePasswordLastStepType = computed(()=>{
        // we get email and uid from url,
        // so we have to validate them before, but it is already the "Restore By Email" last step
        if (willVerifiedEmail.value?.length && willVerifiedUid.value?.length) return LoginType.EMAIL;
        // But we use the verified phone value to find the step,
        // because we check it in the second step (check sms form)
        if (verifiedPhone.value) return LoginType.PHONE;
        return null;
    });
    const isNewTel = computed(()=>phoneCheckType.value === PhoneCodeMethod.CALL_PASSWORD_CODE);
    const isPhoneTabEnabled = computed(()=>isPhoneTabEnabledOnPasswordRestorePage.value);
    const isEmailTabEnabled = computed(()=>isEmailTabEnabledOnPasswordRestorePage.value);
    const isMainFormReady = computed(()=>Boolean(formsStore.restoreBySendSms && formsStore.restoreBySendLinkToEmail));
    const setActiveTab = (value)=>{
        activeTab.value = value;
    };
    const setRetrySmsCodeTime = function() {
        let seconds = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
        retrySmsCodeTimestamp.value = Date.now() + 1000 * seconds;
    };
    const setEmail = (value)=>{
        currentEmail.value = value;
    };
    const setPhone = (value)=>{
        currentPhone.value = value;
    };
    const setWillVerifiedUid = (value)=>{
        willVerifiedUid.value = value;
    };
    const setWillVerifiedEmail = (value)=>{
        willVerifiedEmail.value = value;
    };
    const setVerifiedEmail = (value)=>{
        verifiedEmail.value = value;
    };
    const setVerifiedPhone = (value)=>{
        verifiedPhone.value = value ? formPhoneValueToApiPhoneInput(value) : null;
    };
    const setVerifiedUid = (value)=>{
        verifiedUid.value = value;
    };
    const setPhoneCodeMethod = (value)=>{
        phoneCheckType.value = value;
    };
    const setFetchInitErrorMessage = (value)=>{
        fetchInitErrorMessage.value = value;
    };
    /**
   * Clear user form data (Main route page)
   */ const clearCurrentRestoreData = ()=>{
        currentEmail.value = null;
        currentPhone.value = null;
        phoneCheckType.value = null;
    };
    /**
   * Clear data after complete flow (last step)
   */ const clearVerifiedData = ()=>{
        willVerifiedUid.value = null;
        willVerifiedEmail.value = null;
        verifiedUid.value = null;
        verifiedPhone.value = null;
        verifiedEmail.value = null;
    };
    const sendRestorePasswordLinkToEmailStore = async (param)=>{
        let { formData } = param;
        const options = {
            email: formData.email,
            captchaAnswer: formData.captchaAnswer
        };
        await sendRestorePasswordLinkToEmail(apiClient, (node)=>node.mutations.pwdRecoveryEmail.sendUid, {
            options
        });
        setEmail(options.email);
    };
    /**
   * Resend email with restore link to last email address
   */ const resendRestorePasswordLinkToEmail = async (param)=>{
        let { formData } = param;
        const email = currentEmail.value;
        if (!email) throw new AppError({
            originalError: new Error('Couldn\'t find email for resend restore link')
        });
        const options = {
            email,
            captchaAnswer: formData.captchaAnswer
        };
        // eslint-disable-next-line max-len
        await sendRestorePasswordLinkToEmail(apiClient, (node)=>node.mutations.pwdRecoveryEmail.sendUid, {
            options
        });
    };
    /**
   * Try to change password for uid
   */ const doRestorePasswordByEmailStore = async (param)=>{
        let { formData } = param;
        const email = verifiedEmail.value;
        const uid = verifiedUid.value;
        const { pwd } = formData;
        if (!email) throw new AppError({
            originalError: new Error('Couldn\'t find email for change password')
        });
        if (!uid) throw new AppError({
            originalError: new Error('Couldn\'t find uid for change password')
        });
        const options = {
            pwd,
            email,
            uid
        };
        await doRestorePasswordByEmail(apiClient, (node)=>node.mutations.pwdRecoveryEmail.changePwdByEmail, {
            options
        });
    };
    /**
   * Change password by last entered phone number
   */ const doRestorePasswordByPhoneStore = async (param)=>{
        let { formData } = param;
        const phone = verifiedPhone.value;
        const { pwd, captchaAnswer } = formData;
        if (!phone) throw new AppError({
            originalError: new Error('Couldn\'t find phone for change password')
        });
        const options = {
            phone,
            pwd,
            captchaAnswer
        };
        await doRestorePasswordByPhone(apiClient, (node)=>node.mutations.pwdRecoveryPhone.changePwdByPhone, {
            options
        });
    };
    // @TODO check PHONE_NOT_CHECKED error
    // @TODO move response validation from View to Actions
    /**
   * Check is current uid is still valid or customer must request new email
   */ const verifyRestorePasswordUid = async ()=>{
        const email = willVerifiedEmail.value;
        const uid = willVerifiedUid.value;
        if (!email) throw new AppError({
            originalError: new Error('Couldn\'t find email for verify uid')
        });
        if (!uid) throw new AppError({
            originalError: new Error('Couldn\'t find uid for verify uid')
        });
        const options = {
            email,
            uid
        };
        await checkRestorePasswordUid(apiClient, (node)=>node.mutations.pwdRecoveryEmail.isValidUid, {
            options
        }).then(()=>{
            setVerifiedUid(options.uid);
            setVerifiedEmail(options.email);
            setFetchInitErrorMessage(null);
        }).catch((param)=>{
            let { message } = param;
            setVerifiedUid(false);
            setVerifiedEmail(false);
            setFetchInitErrorMessage(message);
        });
    };
    const sendRestorePasswordSmsOrCallToPhoneStore = async (options)=>{
        const response = await sendRestorePasswordSmsOrCallToPhone(apiClient, (node)=>node.mutations.pwdRecoveryPhone.sendPasswordResetVerificationCode, {
            options
        });
        const { formStep } = response;
        setPhone(options.phone);
        setPhoneCodeMethod(formStep);
        setRetrySmsCodeTime(smsResendCodeTimer.value);
    };
    const sendRestorePasswordSmsToPhoneStore = async (options)=>{
        await sendRestorePasswordSmsToPhone(apiClient, (node)=>node.mutations.pwdRecoveryPhone.sendSms, {
            options
        });
        setPhoneCodeMethod(PhoneCodeMethod.SMS);
        setRetrySmsCodeTime(smsResendCodeTimer.value);
    };
    /**
   * Try to check phone number
   */ const checkRestorePasswordSmsStore = async (param)=>{
        let { formData } = param;
        const phone = currentPhone.value;
        if (!phone) throw new AppError({
            originalError: new Error('Couldn\'t find phone for check sms')
        });
        const { sms, captchaAnswer } = formData;
        const options = {
            phone,
            sms,
            captchaAnswer
        };
        await checkRestorePasswordSms(apiClient, (node)=>node.mutations.pwdRecoveryPhone.checkSms, {
            options
        });
        setVerifiedPhone(options.phone);
    };
    /**
   * The first one request when we haven't any info about check type
   */ const requestRestorePasswordSms = async (param)=>{
        let { formData } = param;
        await sendRestorePasswordSmsOrCallToPhoneStore({
            phone: formPhoneValueToApiPhoneInput(formData.phone),
            captchaAnswer: formData.captchaAnswer
        });
    };
    /**
   * Retry send sms for last entered phone number
   */ const resendRestorePasswordSms = async (param)=>{
        let { method } = param;
        if (!currentPhone.value) throw new AppError({
            originalError: new Error('Couldn\'t find phone for resend code')
        });
        const phone = formPhoneValueToApiPhoneInput(currentPhone.value);
        const useMethod = method ?? phoneCheckType.value;
        if (useMethod === PhoneCodeMethod.SMS) await sendRestorePasswordSmsToPhoneStore({
            phone,
            captchaAnswer: null
        });
        else await sendRestorePasswordSmsOrCallToPhoneStore({
            phone,
            captchaAnswer: null
        });
    };
    return {
        activeTab,
        captchaSettings,
        retrySmsCodeTime,
        restorePasswordLastStepType,
        phoneCheckType,
        isNewTel,
        smsResendCodeTimer,
        currentEmail,
        currentPhone,
        verifiedEmail,
        verifiedPhone,
        verifiedUid,
        willVerifiedUid,
        retrySmsCodeTimestamp,
        willVerifiedEmail,
        captchaEnabled,
        isMainFormReady,
        fetchInitErrorMessage,
        isPhoneTabEnabled,
        isEmailTabEnabled,
        setActiveTab,
        setEmail,
        setPhone,
        setPhoneCodeMethod,
        setRetrySmsCodeTime,
        setWillVerifiedEmail,
        setWillVerifiedUid,
        setVerifiedEmail,
        setVerifiedPhone,
        setVerifiedUid,
        clearVerifiedData,
        clearCurrentRestoreData,
        setFetchInitErrorMessage,
        doRestorePasswordByPhoneStore,
        doRestorePasswordByEmailStore,
        resendRestorePasswordLinkToEmail,
        sendRestorePasswordLinkToEmailStore,
        verifyRestorePasswordUid,
        requestRestorePasswordSms,
        resendRestorePasswordSms,
        checkRestorePasswordSmsStore
    };
});
export default useRestorePasswordStore;
