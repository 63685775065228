import { getAchievements } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function getAchievementsRequest(publicId) {
    const apiClient = useGraphqlClient();
    const options = {};
    if (publicId) options.publicId = publicId;
    return getAchievements(apiClient, (node)=>node.queries.achievements.getAchievements, {
        options
    });
}
