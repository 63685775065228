import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { logger } from '@leon-hub/logging';
import { isNotNull } from '@leon-hub/guards';
import { normalizeError } from '@leon-hub/errors';
import { createSportEventsCounters, reduceListWithSportEventsCounters } from 'web/src/modules/sportline/utils';
import { extractBuildSportOptions } from 'web/src/modules/sportline/utils/rest/pre-build';
import { parseSportResponse, parseRegionResponse, parseLeagueResponse } from 'web/src/modules/sportline/utils/rest/build';
/**
 * Used to prepare standard sport lists
 * like prematch page (sports) and sidebar, filters
 */ export class SportsListAdapter {
    // @TODO merge with parseLeagueResponse
    static createLeagueElement(options) {
        let leagueElement = null;
        const { rawSport, rawRegion, rawLeague } = options;
        try {
            const { region } = parseRegionResponse({
                ...options,
                sportResponse: rawSport,
                regionResponse: rawRegion
            });
            const { league } = parseLeagueResponse({
                ...options,
                sportResponse: rawSport,
                regionResponse: rawRegion,
                leagueResponse: rawLeague
            });
            // @TODO check counters generation
            leagueElement = {
                league,
                region,
                counters: createSportEventsCounters(rawLeague.inplay, rawLeague.prematch, 0, rawLeague.outright)
            };
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(error);
        }
        return leagueElement;
    }
    static createSport(response, buildSportOptions) {
        try {
            const rawSport = parseSportResponse(response, buildSportOptions);
            return rawSport?.sport ?? null;
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(error);
            return null;
        }
    }
    // @TODO fix complexity
    getSports() {
        const responseFilters = this.options?.responseFilters ?? {};
        // eslint-disable-next-line sonarjs/cognitive-complexity
        return this.response.map((rawSport)=>{
            const isCorrectSport = (responseFilters.sport ?? []).every((filter)=>filter(rawSport));
            if (!isCorrectSport) return null;
            let leaguesCount = 0;
            const regions = rawSport.regions.map((rawRegion)=>{
                const isCorrectRegion = (responseFilters.region ?? []).every((filter)=>filter(rawRegion));
                if (!isCorrectRegion) return null;
                // eslint-disable-next-line max-len
                const leagues = rawRegion.leagues.map((rawLeague)=>{
                    const isCorrectLeague = (responseFilters.league ?? []).every((filter)=>filter(rawLeague));
                    if (!isCorrectLeague) return null;
                    return SportsListAdapter.createLeagueElement({
                        ...this.options,
                        rawSport,
                        rawRegion,
                        rawLeague
                    });
                }).// eslint-disable-next-line unicorn/no-array-callback-reference
                filter(isNotNull);
                leaguesCount += leagues.length;
                const { region } = parseRegionResponse({
                    ...this.options,
                    sportResponse: rawSport,
                    regionResponse: rawRegion
                });
                return region ? {
                    region,
                    leagues,
                    counters: reduceListWithSportEventsCounters(leagues)
                } : null;
            }).// eslint-disable-next-line unicorn/no-array-callback-reference
            filter(isNotNull);
            const buildSportOptions = extractBuildSportOptions(this.options);
            const sport = SportsListAdapter.createSport(rawSport, buildSportOptions);
            return sport ? {
                sport,
                leaguesCount,
                regions,
                counters: reduceListWithSportEventsCounters(regions)
            } : null;
        }).// eslint-disable-next-line unicorn/no-array-callback-reference
        filter(isNotNull);
    }
    constructor(response, options = extractBuildSportOptions()){
        _define_property(this, "response", void 0);
        _define_property(this, "options", void 0);
        this.response = response;
        this.options = options;
    }
}
