import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { toRef, toValue } from 'vue';
import VList from 'web/src/components/List/VList/VList.vue';
import PaymentsListItem from 'web/src/modules/payments/components/PaymentsList/PaymentsListItem.vue';
import SPaymentsListItem from 'web/src/modules/payments/components/PaymentsList/components/SPaymentsListItem/SPaymentsListItem.vue';
import { useCurrency } from 'web/src/modules/money/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsList',
    props: {
        categories: {},
        enrollmentTimeInHint: {
            type: Boolean
        },
        isWithdrawals: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        toValue(()=>useCurrency().currency);
        const isEkycRequestable = toRef(()=>useCustomerDataStore().isEkycRequestable);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                class: _normalizeClass(_ctx.$style['payments-list'])
            }, {
                default: _withCtx(()=>[
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category)=>(_openBlock(), _createElementBlock(_Fragment, {
                                key: category.name
                            }, [
                                _createElementVNode("li", {
                                    class: _normalizeClass(_ctx.$style['payments-list__category-title'])
                                }, _toDisplayString(category.name), 3),
                                (_openBlock(true), _createElementBlock(_Fragment, {
                                    key: 1
                                }, _renderList(category.paymentSystems, (system, index)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                                        (_openBlock(), _createBlock(PaymentsListItem, {
                                            id: system.id,
                                            key: `${system.id}-${index}`,
                                            class: _normalizeClass(_ctx.$style['payments-list-item']),
                                            "payment-system": system,
                                            "enrollment-time-in-hint": _ctx.enrollmentTimeInHint,
                                            "is-withdrawals": _ctx.isWithdrawals,
                                            "hide-unavailable-message": isEkycRequestable.value,
                                            onClick: ($event)=>emits('click', system)
                                        }, null, 8, [
                                            "id",
                                            "class",
                                            "payment-system",
                                            "enrollment-time-in-hint",
                                            "is-withdrawals",
                                            "hide-unavailable-message",
                                            "onClick"
                                        ]))
                                    ], 64))), 256))
                            ], 64))), 128))
                    ]),
                _: 1
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsList'
                ]
            ]);
        };
    }
});
