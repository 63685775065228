import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef } from 'vue';
import { useSportHeadline } from './useSportHeadline';
import PrimaryHeadline from './PrimaryHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportHeadline',
    props: {
        sportId: {},
        customTitle: {},
        basisKey: {},
        expandKey: {},
        isExpanded: {
            type: Boolean
        },
        countEvents: {},
        hideEventsCounter: {
            type: Boolean
        },
        isComing: {
            type: Boolean
        },
        isMarketTypesSelectionEnabled: {
            type: Boolean
        }
    },
    emits: [
        "toggle-expand-state"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sportId = toRef(props, 'sportId');
        const customTitle = toRef(props, 'customTitle', null);
        const isComing = toRef(props, 'isComing', false);
        const expandKey = toRef(props, 'expandKey', null);
        const isExpanded = toRef(props, 'isExpanded', false);
        const countEvents = toRef(props, 'countEvents', 0);
        const hideEventsCounter = toRef(props, 'hideEventsCounter', false);
        const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);
        const { title, backgroundColor, onCatalogClick } = useSportHeadline({
            sportId,
            customTitle,
            isComing
        });
        function toggleExpandState(payload) {
            emit('toggle-expand-state', payload);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(PrimaryHeadline, {
                title: _unref(title),
                "background-color": _unref(backgroundColor),
                "expand-key": expandKey.value,
                "is-expanded": isExpanded.value,
                "count-events": countEvents.value,
                "hide-events-counter": hideEventsCounter.value,
                "is-coming": isComing.value,
                "is-market-types-selection-enabled": isMarketTypesSelectionEnabled.value,
                "basis-key": _ctx.basisKey,
                onToggleExpandState: toggleExpandState,
                onCatalogClick: _unref(onCatalogClick)
            }, null, 8, [
                "title",
                "background-color",
                "expand-key",
                "is-expanded",
                "count-events",
                "hide-events-counter",
                "is-coming",
                "is-market-types-selection-enabled",
                "basis-key",
                "onCatalogClick"
            ])), [
                [
                    _directive_auto_id,
                    'SportHeadline'
                ]
            ]);
        };
    }
});
