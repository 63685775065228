import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
const _hoisted_2 = [
    "src",
    "alt"
];
import { computed, toRef } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { SportIcon } from '@components/sport-icon';
import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';
import { useSportlineEventCompetitors } from 'web/src/modules/sportline/composables/sportline-event';
import { colorlessSportIconColor } from 'web/src/modules/sportline/constants';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DoubleEventLogo',
    props: {
        sportlineEvent: {},
        sport: {},
        type: {},
        isColorful: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const type = toRef(props, 'type', 'default');
        const sportlineEvent = toRef(props, 'sportlineEvent', null);
        const sport = toRef(props, 'sport', null);
        const isColorful = toRef(props, 'isColorful', false);
        const { backgroundColor } = useSportBackgroundColor({
            sport
        });
        const { host, guest } = useSportlineEventCompetitors({
            sportlineEvent
        });
        const sportBackgroundColor = computed(()=>isColorful.value ? backgroundColor.value ?? 'var(--Black)' : colorlessSportIconColor);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportline-event-logos-container'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-event-logos'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['sportline-event-logos__item']]: true,
                            [_ctx.$style[`sportline-event-logos__item--${type.value}-type`]]: true
                        })
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sportline-event-logos__logo-container'])
                        }, [
                            _unref(host) && _unref(host).logo ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _unref(host).logo,
                                alt: _unref(host).name,
                                class: _normalizeClass(_ctx.$style['sportline-event-logos__logo'])
                            }, null, 10, _hoisted_1)) : sport.value ? (_openBlock(), _createBlock(_unref(SportIcon), {
                                key: 1,
                                size: _unref(IconSize).SIZE_16,
                                style: _normalizeStyle({
                                    fill: sportBackgroundColor.value
                                }),
                                "sport-name": sport.value.representation.icon
                            }, null, 8, [
                                "size",
                                "style",
                                "sport-name"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['sportline-event-logos__item']]: true,
                            [_ctx.$style[`sportline-event-logos__item--${type.value}-type`]]: true,
                            [_ctx.$style['sportline-event-logos__item--second']]: true
                        })
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sportline-event-logos__logo-container'])
                        }, [
                            _unref(guest) && _unref(guest).logo ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _unref(guest).logo,
                                alt: _unref(guest).name,
                                class: _normalizeClass(_ctx.$style['sportline-event-logos__logo'])
                            }, null, 10, _hoisted_2)) : sport.value ? (_openBlock(), _createBlock(_unref(SportIcon), {
                                key: 1,
                                size: _unref(IconSize).SIZE_16,
                                style: _normalizeStyle({
                                    fill: sportBackgroundColor.value
                                }),
                                "sport-name": sport.value.representation.icon
                            }, null, 8, [
                                "size",
                                "style",
                                "sport-name"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'DoubleEventLogo'
                ]
            ]);
        };
    }
});
