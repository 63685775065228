export function voidify(callback) {
    const name = `${callback.name} wrapped`;
    const box = {
        [name] () {
            for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
                rest[_key] = arguments[_key];
            }
            callback.apply(this, rest);
        }
    };
    return box[name];
}
