import { computed, ref } from 'vue';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
const available = 'function' == typeof window.IntersectionObserver;
export default function useVLazyRender(props, emit) {
    const collision = ref(null);
    const isVisible = ref(false);
    let intersectionObserver = null;
    const displayContent = computed(()=>{
        const showByDefault = !props.isHideOnPrerender && !!process.env.VUE_APP_PRERENDER;
        return showByDefault || !!isVisible.value;
    });
    function detectObserverAvailability() {
        if (!available) handleIntersect();
    }
    function handleIntersect() {
        isVisible.value = true;
        stopObserving();
        emit('appear');
    }
    function observe() {
        if (!collision.value || !available) return;
        try {
            const options = {
                threshold: 0
            };
            intersectionObserver = new IntersectionObserver((entries, observerInstance)=>{
                if (entries[0].isIntersecting) {
                    handleIntersect();
                    observerInstance.disconnect();
                }
            }, options);
            intersectionObserver.observe(collision.value);
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(error);
        }
    }
    function mounted() {
        detectObserverAvailability();
        observe();
    }
    function stopObserving() {
        if (intersectionObserver) {
            intersectionObserver.disconnect();
            intersectionObserver = null;
        }
    }
    return {
        collision,
        mounted,
        stopObserving,
        displayContent
    };
}
