import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import HomeHeading from 'web/src/modules/home/components/heading/HomeHeading.vue';
import HomePromotionsSlide from './HomePromotionsSlide.vue';
import { useHomePromotionsBlock } from './useHomePromotionsBlock';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HomePromotionsBlock',
    setup (__props) {
        const { promotions, allPromotionsRoute, openAllPromotions, openPromotion, getPromotionRoute } = useHomePromotionsBlock();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(promotions).length > 0 ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['home-promotions'])
            }, [
                _createVNode(HomeHeading, {
                    title: _ctx.$t('WEB2_HOME_HEADING_PROMOTIONS'),
                    "button-text": _ctx.$t('WEB2_HOME_HEADING_PROMOTIONS_BTN'),
                    to: _unref(allPromotionsRoute),
                    "is-dark": "",
                    class: _normalizeClass(_ctx.$style['home-promotions__heading'])
                }, null, 8, [
                    "title",
                    "button-text",
                    "to",
                    "class"
                ]),
                _createVNode(_unref(VSwiper), {
                    "is-scroll-snap-enabled": ""
                }, _createSlots({
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(promotions), (promotion)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                    key: promotion.actionUrl ?? promotion.name ?? 'unknown',
                                    class: _normalizeClass(_ctx.$style['home-promotions__slide']),
                                    onClick: ($event)=>_unref(openPromotion)(promotion)
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(HomePromotionsSlide, {
                                                name: promotion.name,
                                                "banner-url": promotion.shortBannerUrl,
                                                "banner-image": promotion.shortBannerImage,
                                                description: promotion.shortDescription,
                                                "is-new": promotion.isNew,
                                                to: _unref(getPromotionRoute)(promotion)
                                            }, null, 8, [
                                                "name",
                                                "banner-url",
                                                "banner-image",
                                                "description",
                                                "is-new",
                                                "to"
                                            ])
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "class",
                                    "onClick"
                                ]))), 128)),
                            1 === _unref(promotions).length ? (_openBlock(), _createBlock(VSwiperSlide, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['home-promotions__slide']),
                                onClick: _unref(openAllPromotions)
                            }, {
                                default: _withCtx(()=>[
                                        _createVNode(HomePromotionsSlide, {
                                            name: _ctx.$t('WEB2_HOME_PROMOTIONS_SLIDE_ALL_TTL'),
                                            description: _ctx.$t('WEB2_HOME_PROMOTIONS_SLIDE_ALL_DESC'),
                                            "banner-url": require('web/src/assets/images/all-promotions.jpg'),
                                            to: _unref(allPromotionsRoute)
                                        }, null, 8, [
                                            "name",
                                            "description",
                                            "banner-url",
                                            "to"
                                        ])
                                    ]),
                                _: 1
                            }, 8, [
                                "class",
                                "onClick"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 2
                }, [
                    {
                        name: "pagination-footer",
                        fn: _withCtx(()=>[
                                _createVNode(SwiperNavigationButtons)
                            ]),
                        key: "0"
                    }
                ]), 1024)
            ], 2)), [
                [
                    _directive_auto_id,
                    'HomePromotionsBlock'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
