import { toRef, computed } from 'vue';
import { HomePageType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default function useHomePageType() {
    const siteConfigStore = useSiteConfigStore();
    const homePageType = toRef(siteConfigStore, 'homePageType');
    const isSportType = computed(()=>homePageType.value === HomePageType.SPORTS);
    const routeNameToSportLine = computed(()=>isSportType.value ? RouteName.HOME : RouteName.SPORTLINE_LIVE_EVENTS);
    return {
        homePageType,
        isSportType,
        routeNameToSportLine
    };
}
