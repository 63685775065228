import { toRef, computed } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { shouldOpenCatalog } from 'web/src/modules/sportline/utils';
import { buildOpenInCatalogParameters } from '../utils';
export function useOpenInCatalog() {
    const siteConfigStore = useSiteConfigStore();
    const { isDevIP } = useIsDevIP();
    const sportLineSettingsBlock = toRef(()=>siteConfigStore.sportLineBlock);
    const sportCatalogUrl = computed(()=>sportLineSettingsBlock.value?.sportlineCatalogUrl ?? null);
    function openInCatalog(parameters) {
        const url = sportCatalogUrl.value;
        if (!url) throw new Error(`web2.sportline.catalog.url is not configured!
      Set it at /siteconfig#/system/properties2?group=WEB2%20Sportline`);
        window.open(`${url}${parameters}`, '_blank');
    }
    function openInCatalogIfEnabled(event, params) {
        if (!isDevIP.value || !shouldOpenCatalog(event)) return false;
        event.preventDefault();
        event.stopPropagation();
        openInCatalog(buildOpenInCatalogParameters(params));
        return true;
    }
    return {
        openInCatalogIfEnabled
    };
}
