import { onActivated, onBeforeUnmount, onDeactivated, onMounted } from 'vue';
export function useCustomMessageListener(callback) {
    let isSubscribed = false;
    function subscribe() {
        if (!isSubscribed) {
            window.addEventListener('message', callback);
            isSubscribed = true;
        }
    }
    function unsubscribe() {
        window.removeEventListener('message', callback);
        isSubscribed = false;
    }
    if (!process.env.VUE_APP_PRERENDER) {
        onMounted(subscribe);
        onActivated(subscribe);
        onBeforeUnmount(unsubscribe);
        onDeactivated(unsubscribe);
    }
}
