import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { SportlineType } from 'web/src/modules/sportline/enums';
import { addCountersToCounters, getSportEventElementsListCounters, reduceListWithSportEventsCounters } from 'web/src/modules/sportline/utils';
import { createSportEvent, parseLeagueResponse, parseRegionResponse, parseSportResponse } from 'web/src/modules/sportline/utils/rest/build';
import { AbstractLineFactory } from './AbstractLineFactory';
/**
 * Used to prepare standard sportline with events
 * like live, cybersport, league and region pages
 */ export class SportlineFactory extends AbstractLineFactory {
    processEventResponse(param) {
        let { sportResponse, regionResponse, leagueResponse, eventResponse } = param;
        // sports only one will be grouped by id
        const sportId = String(sportResponse.id);
        let preparedSport = this.result.find((prepared)=>prepared.sport.sport.id === sportId);
        if (!preparedSport) {
            const sport = parseSportResponse(sportResponse, this.buildSportOptions);
            preparedSport = {
                sport,
                regions: []
            };
            this.result.push(preparedSport);
        }
        const regionId = String(regionResponse.id);
        let preparedRegion = this.factoryOptions?.groupByRegion ? preparedSport.regions.find((prepared)=>prepared.region.region.id === regionId) : preparedSport.regions[preparedSport.regions.length - 1];
        if (preparedRegion?.region.region.id !== regionId) {
            const number = preparedSport.regions.filter((prepared)=>prepared.region.region.id === regionId).length + 1;
            const region = parseRegionResponse({
                sportResponse,
                regionResponse,
                ...this.buildSportOptions
            });
            preparedRegion = {
                number,
                region,
                leagues: []
            };
            preparedSport.regions.push(preparedRegion);
        }
        const leagueId = String(leagueResponse.id);
        let preparedLeague = preparedRegion.leagues[preparedRegion.leagues.length - 1];
        if (preparedLeague?.league.league.id !== leagueId) {
            const number = preparedRegion.leagues.filter((prepared)=>prepared.league.league.id === leagueId).length + 1;
            const league = parseLeagueResponse({
                sportResponse,
                regionResponse,
                leagueResponse,
                ...this.buildSportOptions
            });
            preparedLeague = {
                number,
                league,
                sportEvents: [],
                outrightEvents: []
            };
            preparedRegion.leagues.push(preparedLeague);
        }
        const sportlineEvent = createSportEvent(eventResponse, {
            sportResponse,
            regionResponse,
            leagueResponse,
            ...this.buildSportOptions,
            useFullProgress: preparedSport.sport.sport.representation.useFullProgress
        });
        if (sportlineEvent.isOutright) {
            if (this.factoryOptions?.keepLiveOutrightsInList && sportlineEvent.type === SportlineType.Live) {
                preparedLeague.sportEvents.push(sportlineEvent);
                return;
            }
            preparedLeague.outrightEvents.push(sportlineEvent);
            return;
        }
        preparedLeague.sportEvents.push(sportlineEvent);
    }
    build() {
        return this.parseResponse().map((sportInfo)=>{
            const sportMarketTypes = new Map([]);
            const regions = sportInfo.regions.map((regionInfo)=>{
                const regionMarketTypes = new Map([]);
                const leagues = regionInfo.leagues.map((leagueInfo)=>{
                    const leagueMarketTypes = new Map([]);
                    // eslint-disable-next-line max-len
                    const processSportEvents = (sportEvents)=>sportEvents.map((sportEvent)=>{
                            for (const market of sportEvent.markets){
                                sportMarketTypes.set(market.type.id, market.type);
                                regionMarketTypes.set(market.type.id, market.type);
                                leagueMarketTypes.set(market.type.id, market.type);
                            }
                            return {
                                sportEvent
                            };
                        });
                    const sportEvents = processSportEvents(leagueInfo.sportEvents);
                    const outrightEvents = processSportEvents(leagueInfo.outrightEvents);
                    return {
                        key: `${leagueInfo.number}_${leagueInfo.league.league.id}`,
                        league: leagueInfo.league.league,
                        marketTypes: [
                            ...leagueMarketTypes.values()
                        ],
                        sportEvents,
                        outrightEvents,
                        counters: addCountersToCounters(getSportEventElementsListCounters(sportEvents), getSportEventElementsListCounters(outrightEvents))
                    };
                });
                return {
                    key: `${regionInfo.number}_${regionInfo.region.region.id}`,
                    region: regionInfo.region.region,
                    marketTypes: [
                        ...regionMarketTypes.values()
                    ],
                    leagues,
                    counters: reduceListWithSportEventsCounters(leagues)
                };
            });
            return {
                key: sportInfo.sport.sport.id,
                sport: sportInfo.sport.sport,
                regions,
                marketTypes: [
                    ...sportMarketTypes.values()
                ],
                marketsColumns: sportInfo.sport.marketsColumns,
                counters: reduceListWithSportEventsCounters(regions)
            };
        });
    }
    constructor(...args){
        super(...args);
        _define_property(this, "result", []);
    }
}
