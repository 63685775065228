import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import AccessOptionsComposition from '../../components/AccessOptionsComposition/AccessOptionsComposition.vue';
import { useAccessOptionsListRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccessOptionsListRoutePage',
    setup (__props) {
        const { filteredList, personalLink } = useAccessOptionsListRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(AccessOptionsComposition, {
                items: _unref(filteredList),
                "personal-link": _unref(personalLink)
            }, null, 8, [
                "items",
                "personal-link"
            ])), [
                [
                    _directive_auto_id,
                    'AccessOptionsListRoutePage'
                ]
            ]);
        };
    }
});
