import { BannerLocation } from 'web/src/modules/banners/enums';
export function isGroupAllowedByRoute(displayRoutes, route) {
    return displayRoutes.some((displayRoute)=>'VIRTUAL_ROUTE_ALL_PAGES' === displayRoute.name || displayRoute.name === route.name && displayRoute.parameters.every((parameter)=>parameter.availableValues.includes('*') || !route.params?.[parameter.name] && parameter.availableValues.includes('') || route.params?.[parameter.name] && parameter.availableValues.includes(String(route.params[parameter.name]))));
}
export function isGroupAllowed(group) {
    return group.banners.length > 0 && isGroupAllowedByRoute(group.displayRoutes, group.route);
}
export function filterGroupsByLocation(groups, param) {
    let { location, customLocationId } = param;
    return groups.map((group)=>({
            ...group,
            banners: group.banners.// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            filter((banner)=>banner.location === BannerLocation.CUSTOM ? banner.customLocationId === customLocationId : // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                banner.location === location)
        }));
}
export function filterGroupsByRoute(groups, route) {
    return groups.filter((group)=>isGroupAllowed({
            banners: group.banners,
            displayRoutes: group.displayRoutes,
            route
        }));
}
export function getFilteredBannerGroups(bannerGroups, param) {
    let { location, route, customLocationId } = param;
    const filteredByLocation = filterGroupsByLocation(bannerGroups, {
        location,
        customLocationId
    });
    const filteredByRoute = filterGroupsByRoute(filteredByLocation, route);
    return filteredByRoute.sort((a, b)=>b.weight - a.weight);
}
