import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { onComponentDeactivated, onComponentActivated } from '@leon-hub/vue-utils';
import MarketsList from 'web/src/modules/sportline/views/markets-list/MarketsList.vue';
import { useExtendedSportEventInfo } from './composables/useExtendedSportEventInfo';
import SportlineExtendedMarketsSkeleton from './SportlineExtendedMarketsSkeleton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportEventListItemExtendedMarkets',
    props: {
        sportEventId: {},
        extendedBlockType: {}
    },
    emits: [
        "before-open",
        "after-open",
        "after-close"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sportlineEventId = toRef(props, 'sportEventId');
        const extendedBlockType = toRef(props, 'extendedBlockType');
        const beforeOpen = ()=>emit('before-open');
        const afterOpen = ()=>emit('after-open');
        const afterClose = ()=>emit('after-close');
        const { isEventExtended, extendedSportEvent, representationSportFamily, isMarketGroupsTabsEnabled, extendedSportEventDetailsMarketLimit, openExtendedSportlineEvent, closeExtendedSportlineEvent, pauseBackgroundUpdated, resumeBackgroundUpdated } = useExtendedSportEventInfo({
            sportlineEventId,
            extendedBlockType
        }, {
            afterClose
        });
        onComponentActivated(resumeBackgroundUpdated);
        onComponentDeactivated(pauseBackgroundUpdated);
        __expose({
            open () {
                openExtendedSportlineEvent();
            },
            close () {
                closeExtendedSportlineEvent();
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_Transition, {
                name: "slide",
                onBeforeEnter: beforeOpen,
                onAfterEnter: afterOpen,
                onEnterCancelled: afterOpen
            }, {
                default: _withCtx(()=>[
                        _unref(isEventExtended) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass([
                                "sport-event-list-item-extended",
                                {
                                    'sport-event-list-item-extended--loaded': !!_unref(extendedSportEvent)
                                }
                            ])
                        }, [
                            _unref(extendedSportEvent) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(SportlineExtendedMarketsSkeleton, {
                                key: 0
                            })),
                            (_openBlock(), _createBlock(MarketsList, {
                                key: 1,
                                "sport-event": _unref(extendedSportEvent),
                                "representation-sport-family": _unref(representationSportFamily),
                                "is-loaded": !!_unref(extendedSportEvent),
                                "is-market-groups-tabs-enabled": _unref(isMarketGroupsTabsEnabled),
                                "default-markets-limit": _unref(extendedSportEventDetailsMarketLimit),
                                "is-list-item": ""
                            }, null, 8, [
                                "sport-event",
                                "representation-sport-family",
                                "is-loaded",
                                "is-market-groups-tabs-enabled",
                                "default-markets-limit"
                            ]))
                        ], 2)) : _createCommentVNode("", true)
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'SportEventListItemExtendedMarkets'
                ]
            ]);
        };
    }
});
