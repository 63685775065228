import { computed, ref } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getCustomerPositionIndex, getSecondPositionVisible, getShowMoreButtonProperties } from 'web/src/modules/promotions/components/LeaderBoard/composables/utils';
export default function useLeaderBoard(props, emit) {
    const { $translate } = useI18n();
    const stickyPositionVisibility = ref(true);
    const showMoreButtonProperties = computed(()=>getShowMoreButtonProperties(props, $translate));
    const customerPositionIndex = computed(()=>getCustomerPositionIndex(props));
    // eslint-disable-next-line max-len
    const secondPositionVisible = computed(()=>getSecondPositionVisible(props, stickyPositionVisibility.value));
    const participantMounted = ()=>{
        stickyPositionVisibility.value = false;
    };
    const handleLoadMore = ()=>{
        emit('load-more');
    };
    return {
        customerPositionIndex,
        participantMounted,
        secondPositionVisible,
        showMoreButtonProperties,
        handleLoadMore
    };
}
