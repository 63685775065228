import { defaultSportsMarketColumns } from 'web/src/modules/sportline/constants';
export function getMarketTypeColumnsCount(param, options) {
    let { marketType, sportFamily, runnersCount } = param;
    const preferSportCount = options?.preferSportCount ?? false;
    const defaultCount = 3;
    const defaultForSportCount = sportFamily ? defaultSportsMarketColumns[sportFamily]?.size : void 0;
    if (preferSportCount) {
        if (defaultForSportCount) {
            if (marketType?.columns) // sometimes we have more data than the default sport
            return defaultForSportCount > marketType?.columns ? defaultForSportCount : marketType?.columns;
            return defaultForSportCount;
        }
        return marketType?.columns || runnersCount || defaultCount;
    }
    return marketType?.columns || runnersCount || defaultForSportCount || defaultCount;
}
