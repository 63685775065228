import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { Timer } from '@leon-hub/utils';
import { checkPushTokenExists, doPostponeWebPushToken, doRegisterPushToken, RequestOptionsPriority } from '@leon-hub/api-sdk';
import { PushNotificationPermission } from '@leon-hub/app-types';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { DeviceFingerprintType } from 'web/src/modules/identity/enums';
import { useIdentityStore } from 'web/src/modules/identity/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { POSTPONE_PUSH_STORAGE_KEY, PUSH_TOKEN_STORAGE_KEY } from '../constants';
import { useFirebaseMessaging } from '../utils';
import usePushNotificationsStore from './usePushNotificationsStore';
const useWebPushNotificationsStore = defineStore('web-push-notifications', ()=>{
    const siteConfigStore = useSiteConfigStore();
    const pushNotificationsBlock = toRef(siteConfigStore, 'pushNotificationsBlock');
    const pushNotificationsWebBlock = toRef(siteConfigStore, 'pushNotificationsWebBlock');
    const { isLoggedIn } = useIsLoggedIn();
    const routerStore = useRouterStore();
    const isCurrentRouteHasTopBar = toRef(routerStore, 'isCurrentRouteHasTopBar');
    const firebaseMessaging = useFirebaseMessaging();
    const { getDeviceFingerprint } = useIdentityStore();
    const api = useGraphqlClient();
    const localStorage = useLocalStorageManager();
    const pushNotificationsStore = usePushNotificationsStore();
    const postponeHours = computed(()=>siteConfigStore.pushNotificationsWebBlock?.postponeHours || 0);
    const isSupported = toRef(pushNotificationsStore, 'isSupported');
    const isAllowed = toRef(pushNotificationsStore, 'isAllowed');
    function isModalPostponed() {
        const date = localStorage.getItem(POSTPONE_PUSH_STORAGE_KEY);
        return !!date && Number(date) + 3600000 * postponeHours.value > Date.now();
    }
    const isModalVisibilityVisible = ref(false);
    const isPostponed = ref(false);
    const isConfigSynchronized = ref(false);
    const isModalVisible = computed(()=>isModalVisibilityVisible.value && !isCurrentRouteHasTopBar.value && !isPostponed.value && isConfigSynchronized.value && isSupported.value && !isAllowed.value);
    watch(postponeHours, ()=>{
        isPostponed.value = isModalPostponed();
    }, {
        immediate: true
    });
    const isWebPushNotificationsDisabled = computed(()=>!pushNotificationsBlock.value?.enabled || !pushNotificationsBlock.value?.isAnonymousRegistrationEnabled && !isLoggedIn.value);
    let timeout = 0;
    function clearTimer() {
        if (timeout) {
            Timer.clearTimeout(timeout);
            timeout = 0;
        }
    }
    function hideModal() {
        isModalVisibilityVisible.value = false;
    }
    async function updateToken() {
        let forceUpdate = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        try {
            if (!await firebaseMessaging.isSupported()) return;
            const token = await firebaseMessaging.getToken();
            pushNotificationsStore.setToken(token);
            pushNotificationsStore.setIsAllowed(true);
            if (token && (forceUpdate || token !== localStorage.getItem(PUSH_TOKEN_STORAGE_KEY))) {
                const deviceId = await getDeviceFingerprint(DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER);
                pushNotificationsStore.setDeviceUUID(deviceId);
                if (!pushNotificationsBlock.value?.stopRequests) await doRegisterPushToken(api, (node)=>node.mutations.customer.notifications.registerV2, {
                    options: {
                        token,
                        deviceId,
                        deviceType: 'web'
                    }
                });
                localStorage.setItem(PUSH_TOKEN_STORAGE_KEY, token);
            }
        } catch (error) {
            pushNotificationsStore.setIsAllowed(false);
            pushNotificationsStore.setIsSupported(false);
            // eslint-disable-next-line no-console
            console.warn('[PushNotifications]: Unable to get push notifications token', error);
        }
    }
    async function subscribe() {
        let forceUpdate = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        hideModal();
        try {
            await updateToken(forceUpdate);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('[PushNotifications]: Unable to init push notification client', error);
        }
        return PushNotificationPermission.GRANTED;
    }
    async function isPushTokenRegistered() {
        try {
            if (pushNotificationsBlock.value?.stopRequests) return true;
            const deviceId = await getDeviceFingerprint(DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER);
            await checkPushTokenExists(api, (node)=>node.queries.customer.notifications.check, {
                options: {
                    deviceId,
                    deviceType: 'web'
                }
            }, {
                priority: RequestOptionsPriority.LOW
            });
        } catch (error) {
            if ('UNKNOWN_DEVICE' === String(error.code)) return false;
        }
        return true;
    }
    async function syncConfig() {
        if (!isConfigSynchronized.value) {
            isConfigSynchronized.value = true;
            await firebaseMessaging.init({
                apiKey: siteConfigStore.firebaseBlock?.webApiKey || '',
                projectId: siteConfigStore.firebaseBlock?.webProjectId || '',
                senderId: siteConfigStore.firebaseBlock?.webMessagingSenderId || '',
                appId: siteConfigStore.firebaseBlock?.webAppId || '',
                measurementId: siteConfigStore.firebaseBlock?.webMeasurementId || '',
                vapidKey: siteConfigStore.firebaseBlock?.webVapidKey || ''
            });
            const isFirebaseSupported = await firebaseMessaging.isSupported();
            pushNotificationsStore.setIsSupported(isFirebaseSupported);
            if (isFirebaseSupported) {
                const isTokenRegistered = await isPushTokenRegistered();
                pushNotificationsStore.setIsAllowed(isTokenRegistered);
            }
        }
    }
    function showModal() {
        let forceUpdate = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        return new Promise((resolve)=>{
            clearTimer();
            if (isWebPushNotificationsDisabled.value) {
                resolve();
                return;
            }
            timeout = Timer.setTimeout(async ()=>{
                await syncConfig();
                clearTimer();
                if (window.Notification?.permission !== 'granted') {
                    if (pushNotificationsWebBlock.value?.modalEnabled) isModalVisibilityVisible.value = true;
                    else await subscribe(forceUpdate);
                } else await subscribe(forceUpdate);
                resolve();
            }, 1000 * (pushNotificationsWebBlock.value?.openTimeout || 30));
        });
    }
    async function postpone() {
        hideModal();
        localStorage.setItem(POSTPONE_PUSH_STORAGE_KEY, Date.now().toString());
        if (isLoggedIn.value) await doPostponeWebPushToken(api, (node)=>node.mutations.webPushNotifications.postpone, {
            options: {}
        });
    }
    watch(isLoggedIn, async ()=>{
        syncConfig();
        localStorage.removeItem(PUSH_TOKEN_STORAGE_KEY);
        hideModal();
        await siteConfigStore.updateSiteConfigSettings({
            silent: true
        });
        showModal(true);
    });
    return {
        isModalVisible,
        syncConfig,
        showModal,
        subscribe,
        postpone
    };
});
export default useWebPushNotificationsStore;
