import { ref, watch, computed } from 'vue';
function useReadyStateComposable(props) {
    const { isDisplayPreviousDataAvailable, isReady, isEmpty } = props;
    const isPreviousDataExist = computed(()=>!isReady.value && !isEmpty.value);
    const canDisplayPreviousData = computed(()=>isDisplayPreviousDataAvailable.value && isPreviousDataExist.value);
    const canDisplayExpectedData = computed(()=>isReady.value && !isEmpty.value);
    return {
        canDisplayPreviousData,
        canDisplayExpectedData
    };
}
export function useLeagueReadyStateStoreComposable(props) {
    const { expectedId, loadedDataLeagueId, isLeagueDataReady, isLeagueDataEmpty, isEventsListReady, isEventsListEmpty, isStatisticReady, isStatisticEmpty, isPromotionsReady, isPromotionsEmpty } = props;
    const isDisplayPreviousDataAvailable = ref(false);
    const { canDisplayPreviousData: canDisplayPreviousLeagueData, canDisplayExpectedData: canDisplayExpectedLeagueData } = useReadyStateComposable({
        isDisplayPreviousDataAvailable,
        isReady: isLeagueDataReady,
        isEmpty: isLeagueDataEmpty
    });
    const { canDisplayPreviousData: canDisplayPreviousEventsList, canDisplayExpectedData: canDisplayExpectedEventsList } = useReadyStateComposable({
        isDisplayPreviousDataAvailable,
        isReady: isEventsListReady,
        isEmpty: isEventsListEmpty
    });
    const { canDisplayPreviousData: canDisplayPreviousStatistic, canDisplayExpectedData: canDisplayExpectedStatistic } = useReadyStateComposable({
        isDisplayPreviousDataAvailable,
        isReady: isStatisticReady,
        isEmpty: isStatisticEmpty
    });
    const { canDisplayPreviousData: canDisplayPreviousPromotions, canDisplayExpectedData: canDisplayExpectedPromotions } = useReadyStateComposable({
        isDisplayPreviousDataAvailable,
        isReady: isPromotionsReady,
        isEmpty: isPromotionsEmpty
    });
    const canDisplayPreviousData = computed(()=>canDisplayPreviousLeagueData.value || canDisplayPreviousEventsList.value || canDisplayPreviousStatistic.value || canDisplayPreviousPromotions.value);
    const canDisplayExpectedData = computed(()=>canDisplayExpectedLeagueData.value || canDisplayExpectedEventsList.value || canDisplayExpectedStatistic.value || canDisplayExpectedPromotions.value);
    const canDisplayAnything = computed(()=>canDisplayPreviousData.value || canDisplayExpectedData.value);
    "1";
    watch([
        expectedId,
        loadedDataLeagueId
    ], (param, param1)=>{
        let [id, loadedId] = param, [prevExpectedId] = param1;
        // display league data only if we navigate between league pages
        isDisplayPreviousDataAvailable.value = !!prevExpectedId && id !== loadedId;
    });
    return {
        canDisplayPreviousData,
        canDisplayExpectedData,
        canDisplayAnything,
        canDisplayExpectedLeagueData,
        canDisplayExpectedEventsList,
        canDisplayExpectedStatistic,
        canDisplayExpectedPromotions,
        canDisplayPreviousLeagueData,
        canDisplayPreviousEventsList,
        canDisplayPreviousStatistic,
        canDisplayPreviousPromotions
    };
}
