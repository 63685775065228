import { computed } from 'vue';
export function useSportsListDateFilter(props) {
    const { dateTimeFilter } = props;
    const dateTimeFilterCustomFrom = computed(()=>dateTimeFilter.value.customDateFrom ?? void 0);
    const dateTimeFilterCustomTo = computed(()=>dateTimeFilter.value.customDateTo ?? void 0);
    const dateTimeFilterActiveKickoffPeriod = computed(()=>dateTimeFilter.value.activeKickoffPeriod);
    const dateTimeFilterActiveSportlineType = computed(()=>dateTimeFilter.value.sportlineType);
    return {
        dateTimeFilterCustomFrom,
        dateTimeFilterCustomTo,
        dateTimeFilterActiveKickoffPeriod,
        dateTimeFilterActiveSportlineType
    };
}
