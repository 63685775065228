import { useRouter } from 'vue-router';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { logger } from '@leon-hub/logging';
import { isIframe } from 'web/src/utils/browser';
import PaymentsPostMessageEvent from 'web/src/modules/payments/utils/PaymentsPostMessageEvent';
// eslint-disable-next-line max-len
export default function usePaymentsDepositsOkFailController(props) {
    const router = useRouter();
    let postMessageBus = null;
    function beforeMount() {
        try {
            postMessageBus = new PostMessageBus({
                target: window.parent,
                targetOrigin: '*',
                initiator: 'PaymentsDepositsOkFailController'
            });
        } catch (error) {
            logger.error(`Couldn't create postmessage bus: ${error}`);
        }
    }
    function beforeUnmount() {
        if (postMessageBus) postMessageBus.dispose();
    }
    function onClick() {
        if (props.closeWindow && !process.env.VUE_APP_BROWSER_SAFARI) window.close();
        else if (isIframe() && postMessageBus) postMessageBus.emit(PaymentsPostMessageEvent.depositok, {
            status: true
        });
        else router.closeModal();
    }
    return {
        beforeMount,
        beforeUnmount,
        onClick
    };
}
