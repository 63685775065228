import RouteName from '@leon-hub/routing-config-names';
import { HomePageType, lobbyTypeLiveCasino, lobbyTypeSlots } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { getCasinoLobbyRoutePage } from 'web/src/modules/casino/submodules/lobby/utils';
import { getSportlineNavigationRouteComponent } from 'web/src/modules/sportline/submodules/navigation/SportlineNavigationSubmodule';
export class HomeModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
    addRoutes(router) {
        const { isBetSwipeTopEventsLayoutEnabled } = useSportlineLayoutSettings();
        // eslint-disable-next-line no-nested-ternary
        const sportlineNavigation = getSportlineNavigationRouteComponent();
        "1";
        {
            const { homePageType } = useSiteConfigStore();
            switch(homePageType){
                case HomePageType.SLOTS:
                case HomePageType.LIVE_GAMES:
                    router.addDefaultRoute({
                        ...getCasinoLobbyRoutePage({
                            name: RouteName.HOME,
                            path: '/',
                            meta: {
                                routeLobbyType: homePageType === HomePageType.LIVE_GAMES ? lobbyTypeLiveCasino : lobbyTypeSlots
                            }
                        }),
                        // eslint-disable-next-line max-len
                        topAuthorizedSlot: ()=>import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue')
                    });
                    break;
                case HomePageType.CUSTOM:
                    router.addDefaultRoute({
                        name: RouteName.HOME,
                        path: '/',
                        // eslint-disable-next-line max-len
                        component: ()=>import('web/src/modules/home/submodules/custom/pages/CustomHomeRoutePage/CustomHomeRoutePage.vue'),
                        prefetch: ()=>import('web/src/modules/home/submodules/custom/prefetch/CustomHomePagePrefetch'),
                        meta: {
                            isBannerHidden: true
                        }
                    });
                    break;
                default:
                    router.addDefaultRoute({
                        name: RouteName.HOME,
                        path: '/',
                        navigation: sportlineNavigation,
                        leftSideBar: ()=>import('web/src/modules/sportline/submodules/sidebar/views/SportsSidebarRouteComponent.vue'),
                        // eslint-disable-next-line max-len
                        contentLoader: ()=>import('web/src/modules/sportline/submodules/navigation/views/NavigationMainContentLoaderRouteComponent/NavigationMainContentLoaderRouteComponent.vue'),
                        prefetch: ()=>import('web/src/modules/home/submodules/casino/prefetch/CasinoHomePagePrefetch'),
                        component: ()=>import('web/src/modules/home/submodules/casino/views/CasinoHomeRoutePage.vue'),
                        // eslint-disable-next-line max-len
                        topAuthorizedSlot: ()=>import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue')
                    });
            }
        }
    }
} // eslint-disable-next-line max-len
 // eslint-disable-next-line max-len
