import { ref, computed } from 'vue';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { getBetlineLeagueTopPlayers } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError, isTopPlayersEmpty } from 'web/src/modules/sportline/utils/statistic';
export function useLeagueTopPlayersStoreComposable(props) {
    const { isEnabled, expectedId } = props;
    const { isLoading, isExpectedIdLoaded, setIsLoading, setLastLoadedId } = useExpectedIdLoading({
        expectedId
    });
    const topPlayers = ref(null);
    const isReady = computed(()=>!isEnabled.value || isExpectedIdLoaded.value);
    const isEmpty = computed(()=>null === topPlayers.value);
    function clear() {
        topPlayers.value = null;
    }
    async function fetchLeagueTopPlayers(param) {
        let { silent } = param;
        if (!isEnabled.value) return;
        const leagueId = expectedId.value;
        if (!leagueId) return;
        setIsLoading(true);
        try {
            const result = await getBetlineLeagueTopPlayers({
                leagueId
            }, {
                silent
            });
            topPlayers.value = isTopPlayersEmpty(result) ? null : {
                ...result
            };
        } catch (error) {
            clear();
            if (!isStatisticNotFoundError(error)) throw error;
        } finally{
            setIsLoading(false);
            setLastLoadedId(leagueId);
        }
    }
    return {
        isReady,
        isLoading,
        isEmpty,
        topPlayers,
        fetchLeagueTopPlayers,
        clear
    };
}
