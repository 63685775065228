import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Teleport as _Teleport, Fragment as _Fragment } from "vue";
import { defineAsyncComponent, onBeforeUnmount, onMounted, toRef } from 'vue';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import VOverlay from 'web/src/components/Modal/VOverlay/VOverlay.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { useDataTestUnit } from 'web/src/testing/directives';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useLiveChatStore } from 'web/src/modules/live-chat/store';
import TheLiveChatWidget from 'web/src/modules/support/submodules/widget/components/TheLiveChatWidget/TheLiveChatWidget.vue';
import { useSupportWidget, useChatDragDrop, useInlineChat, useChatBetId } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TheSupportWidget',
    setup (__props) {
        const LiveChat = defineAsyncComponent(()=>import('web/src/modules/live-chat/components/LiveChat/LiveChat.vue'));
        const liveChatStore = useLiveChatStore();
        const { vDataTestUnit } = useDataTestUnit();
        const { isChatIconVisible, closeButtonProps, isChatVisible, onChatCreated, openChat, hideChat, onChatDestroyed } = useSupportWidget();
        const { isLoggedIn } = useIsLoggedIn();
        const chatMessagesCounter = toRef(liveChatStore, 'newMessagesSize');
        const isChatActive = toRef(liveChatStore, 'isChatActive');
        const betId = useChatBetId();
        const { inlineChatText, isWidgetOpened, inlineChatDescription, defaultChatSubject, isWidgetAllowedForPage, showOverlay, overlayProps, hideWidget, sendInlineChatAnalytics } = useInlineChat();
        const { onDragStart, chatPositionStyles, chatRef } = useChatDragDrop();
        useBusSafeSubscribe(BusEvent.OPEN_CHAT, ()=>{
            openChat();
        });
        onMounted(()=>{
            onChatCreated();
        });
        onBeforeUnmount(()=>{
            onChatDestroyed();
        });
        function onInlineChatClicked() {
            sendInlineChatAnalytics();
            openChat();
        }
        return (_ctx, _cache)=>_unref(isLoggedIn) ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
            }, [
                _createCommentVNode("", true),
                isChatActive.value ? _withDirectives((_openBlock(), _createBlock(TheLiveChatWidget, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['support-widget__fixed']),
                    "new-messages-counter": chatMessagesCounter.value,
                    onClick: _unref(openChat)
                }, null, 8, [
                    "class",
                    "new-messages-counter",
                    "onClick"
                ])), [
                    [
                        _vShow,
                        _unref(isChatIconVisible)
                    ]
                ]) : _unref(isWidgetAllowedForPage) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass({
                        [_ctx.$style['support-widget']]: true,
                        [_ctx.$style['support-widget__fixed']]: true
                    })
                }, [
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass({
                            [_ctx.$style['support-widget__content']]: true,
                            [_ctx.$style['support-widget__content--hidden']]: !_unref(isWidgetOpened)
                        }),
                        onClick: onInlineChatClicked
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['support-widget__content-inner'])
                        }, [
                            _createVNode(VDynamicContent, {
                                content: _unref(inlineChatText),
                                class: _normalizeClass(_ctx.$style['support-widget__title'])
                            }, null, 8, [
                                "content",
                                "class"
                            ]),
                            _unref(inlineChatDescription) ? _withDirectives((_openBlock(), _createBlock(VDynamicContent, {
                                key: 0,
                                content: _unref(inlineChatDescription),
                                class: _normalizeClass(_ctx.$style['support-widget__desc'])
                            }, null, 8, [
                                "content",
                                "class"
                            ])), [
                                [
                                    _unref(vDataTestUnit),
                                    {
                                        el: 'inline-widget-description'
                                    }
                                ]
                            ]) : _createCommentVNode("", true)
                        ], 2)
                    ], 2)), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'inline-widget-content'
                            }
                        ]
                    ]),
                    _unref(isWidgetOpened) ? _withDirectives((_openBlock(), _createBlock(VButton, _mergeProps({
                        key: 0
                    }, _unref(closeButtonProps), {
                        class: _ctx.$style['support-widget__close'],
                        onClick: _unref(hideWidget)
                    }), null, 16, [
                        "class",
                        "onClick"
                    ])), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'close-btn'
                            }
                        ]
                    ]) : _createCommentVNode("", true)
                ], 2)), [
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'inline-widget'
                        }
                    ]
                ]) : _createCommentVNode("", true),
                (_openBlock(), _createBlock(_Teleport, {
                    to: "body"
                }, [
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        ref_key: "chatRef",
                        ref: chatRef,
                        class: _normalizeClass(_ctx.$style['live-chat-main']),
                        style: _normalizeStyle(_unref(chatPositionStyles))
                    }, [
                        _withDirectives(_createVNode(_unref(LiveChat), {
                            hidden: !_unref(isChatVisible),
                            "default-subject": _unref(defaultChatSubject),
                            "bet-id": _unref(betId),
                            class: _normalizeClass(_ctx.$style['live-chat-main__window']),
                            onHide: _unref(hideChat),
                            onDragStart: _unref(onDragStart)
                        }, null, 8, [
                            "hidden",
                            "default-subject",
                            "bet-id",
                            "class",
                            "onHide",
                            "onDragStart"
                        ]), [
                            [
                                _unref(vDataTestUnit),
                                {
                                    el: 'chat'
                                }
                            ]
                        ])
                    ], 6)), [
                        [
                            _vShow,
                            _unref(isChatVisible)
                        ],
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'chat-wrapper'
                            }
                        ]
                    ])
                ]))
            ], 64)) : _createCommentVNode("", true);
    }
});
