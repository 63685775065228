import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = [
    "for"
];
const _hoisted_2 = [
    "id",
    "checked",
    "disabled",
    "name",
    "tabindex",
    "autofocus"
];
import { useAutofocus } from 'web/src/components/Input/composables';
import { useVCheckbox } from './composables';
import VCheckboxIcon from '../VCheckboxIcon/components/VCheckboxIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCheckbox',
    props: {
        name: {
            default: ''
        },
        id: {},
        checked: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        error: {
            default: ''
        },
        tabIndex: {
            default: 0
        },
        label: {
            default: ''
        },
        inputDataTest: {},
        autofocus: {
            type: Boolean
        },
        noPadding: {
            type: Boolean
        }
    },
    emits: [
        "change",
        "input",
        "blur",
        "focus"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isHover, isFocus, isChecked, onMouseEnter, onMouseLeave, onInput, onChange, onFocus, onBlur } = useVCheckbox(props, emit);
        const { focus, focusable } = useAutofocus(props);
        __expose({
            focus
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['checkbox-container']]: true,
                    [_ctx.$style['checkbox-container--no-padding']]: _ctx.noPadding
                })
            }, [
                _createElementVNode("label", {
                    class: _normalizeClass({
                        [_ctx.$style['checkbox']]: true,
                        [_ctx.$style['checkbox--disabled']]: _ctx.disabled,
                        [_ctx.$style['checkbox--error']]: !!_ctx.error
                    }),
                    for: _ctx.id || void 0,
                    onMouseenter: _cache[5] || (_cache[5] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onMouseEnter) && _unref(onMouseEnter)(...args);
                    }),
                    onMouseleave: _cache[6] || (_cache[6] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onMouseLeave) && _unref(onMouseLeave)(...args);
                    })
                }, [
                    _createVNode(VCheckboxIcon, {
                        checked: _unref(isChecked),
                        "is-hover": !_ctx.disabled && (_unref(isHover) || _unref(isFocus)),
                        disabled: _ctx.disabled,
                        "is-error": !!_ctx.error
                    }, {
                        default: _withCtx(()=>[
                                _withDirectives(_createElementVNode("input", {
                                    id: _ctx.id || void 0,
                                    ref_key: "focusable",
                                    ref: focusable,
                                    checked: _ctx.checked,
                                    disabled: _ctx.disabled,
                                    name: _ctx.name,
                                    tabindex: _ctx.tabIndex,
                                    autofocus: process.env.VUE_APP_OS_IOS ? void 0 : _ctx.autofocus,
                                    class: _normalizeClass(_ctx.$style['checkbox__input']),
                                    type: "checkbox",
                                    onChange: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(onChange) && _unref(onChange)(...args);
                                    }, [
                                        "stop"
                                    ])),
                                    onFocus: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(onFocus) && _unref(onFocus)(...args);
                                    }, [
                                        "stop"
                                    ])),
                                    onBlur: _cache[2] || (_cache[2] = _withModifiers(//@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(onBlur) && _unref(onBlur)(...args);
                                    }, [
                                        "stop"
                                    ])),
                                    onInput: _cache[3] || (_cache[3] = _withModifiers(//@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(onInput) && _unref(onInput)(...args);
                                    }, [
                                        "stop"
                                    ])),
                                    onClick: _cache[4] || (_cache[4] = _withModifiers(()=>{}, [
                                        "stop"
                                    ]))
                                }, null, 42, _hoisted_2), [
                                    [
                                        _directive_data_test,
                                        _ctx.inputDataTest
                                    ]
                                ])
                            ]),
                        _: 1
                    }, 8, [
                        "checked",
                        "is-hover",
                        "disabled",
                        "is-error"
                    ]),
                    !!_ctx.label || _ctx.$slots.label ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['checkbox__text-container'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['checkbox__text']),
                            "data-test-id": "text"
                        }, [
                            _renderSlot(_ctx.$slots, "label", {}, ()=>[
                                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                                ])
                        ], 2)
                    ], 2)) : _createCommentVNode("", true)
                ], 42, _hoisted_1),
                !_ctx.error || _ctx.disabled || _ctx.checked ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    "data-test-id": "hint-error",
                    class: _normalizeClass(_ctx.$style['checkbox__hint-error'])
                }, _toDisplayString(_ctx.error), 3))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VCheckbox'
                ]
            ]);
        };
    }
});
