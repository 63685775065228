import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import { bannerVersionV3 } from '@leon-hub/api-sdk';
import { getReadySlides } from 'web/src/modules/banners/components/FadeCarouselBanner/utils/getReadySlides';
import { useAbstractBanner } from 'web/src/modules/banners/composables/useAbstractBanner';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { PaginationLocation } from 'web/src/components/FadeCarousel/enums';
export default function useFadeCarouselBanner(props, emit) {
    const route = useRoute();
    const { banners, linkTitle, autoplayTimeout, isGroupBannersDev, emitBannerItemClick } = useAbstractBanner(props, emit);
    const { isLoggedIn } = useIsLoggedIn();
    const fadeCarousel = ref();
    const activeSlideIndex = ref(0);
    const nextVisibleIndex = ref(0);
    let visibilityTimeout = 0;
    const readySlides = ref([]);
    const bannersLength = computed(()=>banners.value.length);
    const paginationLocation = computed(()=>banners.value.find((banner)=>banner.bannerVersion === bannerVersionV3) ? PaginationLocation.RIGHT : PaginationLocation.CENTER);
    function goToFirstBanner() {
        fadeCarousel.value?.moveToSlide(0);
    }
    function showNextImage() {
        if (visibilityTimeout) {
            Timer.clearTimeout(visibilityTimeout);
            visibilityTimeout = 0;
        }
        if (autoplayTimeout) {
            const timeout = autoplayTimeout.value > 3000 ? autoplayTimeout.value - 3000 : 1;
            visibilityTimeout = Timer.setTimeout(()=>{
                if (banners.value?.[activeSlideIndex.value + 1]) nextVisibleIndex.value = activeSlideIndex.value + 1;
            }, timeout);
        }
        readySlides.value = getReadySlides(banners.value.length, activeSlideIndex.value);
    }
    function beforeMount() {
        showNextImage();
    }
    function emitSlideChanged() {
        emit('slide-changed', activeSlideIndex.value);
    }
    function onSlideChanged(index) {
        activeSlideIndex.value = index;
        showNextImage();
        emitSlideChanged();
    }
    watch(isLoggedIn, goToFirstBanner);
    watch(linkTitle, goToFirstBanner);
    watch(bannersLength, goToFirstBanner);
    watch(()=>route.path, goToFirstBanner);
    return {
        fadeCarousel,
        autoplayTimeout,
        banners,
        readySlides,
        nextVisibleIndex,
        isGroupBannersDev,
        activeSlideIndex,
        onSlideChanged,
        emitBannerItemClick,
        emitSlideChanged,
        beforeMount,
        paginationLocation
    };
}
