import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import { Tag } from '@leon-hub/tags';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useCasinoSearchItem } from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/composables';
import { getHighlightedText } from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/composables/utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSearchItem',
    props: {
        name: {
            default: ''
        },
        group: {
            default: ''
        },
        categories: {
            default: ()=>[]
        },
        image: {},
        searchText: {
            default: ''
        },
        href: {
            default: ''
        },
        ntBadge: {
            default: ''
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { onClick, imageProps, ntBadgeProperties, formattedName, formattedGroup, formattedCategories } = useCasinoSearchItem(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(_ctx.$style['slots-search-item'])
            }, [
                _createElementVNode("a", {
                    class: _normalizeClass(_ctx.$style['slots-search-item__link']),
                    href: _ctx.href,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onClick) && _unref(onClick)(...args);
                    }, [
                        "prevent"
                    ]))
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['slots-search-item__image-holder'])
                    }, [
                        _createVNode(VImage, _mergeProps(_unref(imageProps), {
                            class: _ctx.$style['slots-search-item__image']
                        }), null, 16, [
                            "class"
                        ]),
                        _ctx.ntBadge ? (_openBlock(), _createBlock(VBadge, _mergeProps({
                            key: 0
                        }, _unref(ntBadgeProperties), {
                            class: _ctx.$style['slots-search-item__nt-badge']
                        }), null, 16, [
                            "class"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['slots-search-item__info'])
                    }, [
                        _createVNode(VDynamicContent, {
                            content: _unref(formattedName),
                            tag: _unref(Tag).DIV,
                            class: _normalizeClass(_ctx.$style['slots-search-item__name'])
                        }, null, 8, [
                            "content",
                            "tag",
                            "class"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['slots-search-item__categories'])
                        }, [
                            _createVNode(VDynamicContent, {
                                content: _unref(formattedGroup),
                                tag: _unref(Tag).SPAN,
                                class: _normalizeClass(_ctx.$style['slots-search-item__categories-item--group'])
                            }, null, 8, [
                                "content",
                                "tag",
                                "class"
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formattedCategories), (category)=>(_openBlock(), _createBlock(VDynamicContent, {
                                    key: category,
                                    content: _unref(getHighlightedText)(` • ${category}`, _ctx.searchText),
                                    tag: _unref(Tag).SPAN
                                }, null, 8, [
                                    "content",
                                    "tag"
                                ]))), 128))
                        ], 2)
                    ], 2)
                ], 10, _hoisted_1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoSearchItem'
                ]
            ]);
        };
    }
});
