import { computed, nextTick, ref } from 'vue';
import { emailPattern } from 'web/src/components/Form/constants';
import { getEmailFieldError, getIsSubmitButtonDisabled, getSubmitButtonProps } from 'web/src/components/FeedbackComposition/VFeedbackComposition/composables/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { FeedbackTextareaRowsSize } from 'web/src/components/FeedbackComposition/VFeedbackComposition/enums';
export default function useVFeedBackComposition(props) {
    const { $translate } = useI18n();
    const emailValue = ref('');
    const isEmailFieldError = ref(false);
    const comment = ref('');
    const commentTextarea = ref(null);
    const emailFieldError = computed(()=>getEmailFieldError(props, emailValue.value, isEmailFieldError.value, $translate));
    // eslint-disable-next-line max-len
    const isSubmitButtonDisabled = computed(()=>getIsSubmitButtonDisabled(props, emailValue.value, comment.value, isEmailFieldError.value));
    const submitButtonProperties = computed(()=>getSubmitButtonProps(props, isSubmitButtonDisabled, $translate));
    const rowsMaxValue = FeedbackTextareaRowsSize.MAX;
    const rowsMinValue = FeedbackTextareaRowsSize.MIN;
    async function onComponentMounted() {
        await nextTick();
        focusTextArea();
    }
    function onComponentUpdated() {
        if (props.isClearTextarea) comment.value = '';
    }
    function onTextareaChange(e) {
        comment.value = e.target.value;
    }
    function isEmailValid(e) {
        return !!e.target.value && !new RegExp(emailPattern).test(e.target.value);
    }
    function onEmailBlur(e) {
        isEmailFieldError.value = isEmailValid(e);
    }
    function onEmailChange(e) {
        isEmailFieldError.value = isEmailValid(e);
        emailValue.value = e.target.value;
    }
    function focusTextArea() {
        if (commentTextarea.value && 'focus' in commentTextarea.value) commentTextarea.value.focus();
    }
    function getSubmitFormData() {
        return {
            comment: comment.value,
            email: emailValue.value
        };
    }
    return {
        comment,
        commentTextarea,
        emailValue,
        emailFieldError,
        submitButtonProperties,
        rowsMaxValue,
        rowsMinValue,
        onComponentMounted,
        onComponentUpdated,
        onEmailBlur,
        onEmailChange,
        onTextareaChange,
        getSubmitFormData
    };
}
