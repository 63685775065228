import { isNumber } from '@leon-hub/guards';
export default function handleRegexpLimitedPaste(param) {
    let { event, currentValue, regexp } = param;
    event.preventDefault();
    const { target, clipboardData } = event;
    HTMLInputElement;
    const { selectionStart, selectionEnd } = target;
    isNumber(selectionStart) && isNumber(selectionEnd);
    const data = clipboardData?.getData('text') ?? '';
    if (regexp.test(data)) {
        const valueBefore = currentValue.slice(0, selectionStart);
        const valueAfter = currentValue.slice(selectionEnd);
        const head = `${valueBefore}${data}`;
        const nextValue = `${head}${valueAfter}`;
        const newPosition = head.length;
        target.value = nextValue;
        target.selectionStart = newPosition;
        target.selectionEnd = newPosition;
        return nextValue;
    }
    target.value = currentValue;
    target.selectionStart = selectionStart;
    target.selectionEnd = selectionEnd;
    return currentValue;
}
