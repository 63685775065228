import { isArray, isObject, isNumber, isString, isBoolean, isUndefined } from '@leon-hub/guards';
import { BetlineSportEventStatus, BetlineType } from 'web/src/modules/sportline/enums/rest';
import { SportEventMatchPhase } from 'web/src/modules/sportline/enums';
function isCoreCompetitorResponse(value) {
    if (!isArray(value)) return false;
    return value.every((item)=>isObject(item) && isNumber(item.id) && isString(item.name));
}
function isCoreMarketResponse(value) {
    if (!isArray(value)) return false;
    return value.every((item)=>isObject(item) && isNumber(item.id) && isNumber(item.marketTypeId) && isString(item.name) && isArray(item.runners) && isBoolean(item.open));
}
function isBetlineType(value) {
    const expectedValues = [
        BetlineType.Live,
        BetlineType.ExtraLive,
        BetlineType.Prematch,
        BetlineType.Planed
    ];
    return isString(value) && expectedValues.includes(value);
}
function isSportEventMatchPhase(value) {
    return isString(value) && Object.values(SportEventMatchPhase).map((item)=>`${item}`).includes(value);
}
function isBetlineSportEventStatus(value) {
    return isString(value) && Object.values(BetlineSportEventStatus).map((item)=>`${item}`).includes(value);
}
function isLiveStatusResponse(value) {
    return isObject(value);
}
export function mapBannerEvent(bannerEvent) {
    const { competitors, betline, matchPhase, status, markets } = bannerEvent;
    const liveStatus = bannerEvent.liveStatus ?? void 0;
    isCoreCompetitorResponse(competitors);
    isCoreMarketResponse(markets);
    isBetlineType(betline);
    isSportEventMatchPhase(matchPhase);
    isBetlineSportEventStatus(status);
    isUndefined(liveStatus) || isLiveStatusResponse(liveStatus);
    const result = {
        id: bannerEvent.id,
        name: bannerEvent.name,
        nameDefault: bannerEvent.name,
        hasMarketWithZeroMargin: markets[0].hasZeroMargin,
        competitors,
        kickoff: bannerEvent.kickoff,
        lastUpdated: 0,
        league: {
            id: 0,
            name: '',
            nameDefault: '',
            sport: {
                id: 0,
                name: '',
                family: ''
            },
            region: {
                id: 0,
                name: '',
                nameDefault: '',
                url: ''
            }
        },
        betline,
        open: bannerEvent.open,
        matchPhase,
        markets,
        url: bannerEvent.url,
        status,
        liveStatus
    };
    return result;
}
