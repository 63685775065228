import { computed } from 'vue';
import { isString } from '@leon-hub/guards';
import { defaultMarketColumnsOptions } from 'web/src/modules/sportline/constants';
import { placeRunnersToColumns } from 'web/src/modules/sportline/utils/markets';
export function useMarketRunners(props) {
    const { market, marketType, sport } = props;
    const runnersPlaces = computed(()=>placeRunnersToColumns({
            market: market.value,
            marketType: marketType.value,
            sportFamily: sport.value?.representation.family
        }, defaultMarketColumnsOptions).map((runner)=>runner && !isString(runner) ? runner : null));
    return {
        runnersPlaces
    };
}
