import { inject, ref, watch } from 'vue';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { ScrollbarProvidableContext } from '@components/v-scrollbar';
export function useExternalScrollWatcher(scrollbarContextHandler, layoutScrollHandler) {
    const onBusEvent = (busEvent)=>layoutScrollHandler(busEvent);
    "1";
    {
        const scrollbarContext = inject(ScrollbarProvidableContext.ScrollbarContext, ref(void 0));
        if (scrollbarContext.value) watch(scrollbarContext, (value)=>{
            if (value) scrollbarContextHandler(value.scrollTop);
        });
        else useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL, onBusEvent);
    }
}
