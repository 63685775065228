import useCustomerFavoritesService from 'web/src/modules/sportline/submodules/favorites/composables/useCustomerFavoritesService';
import { ShowcaseNavigationItemId } from 'web/src/modules/home/submodules/showcase/enums';
export default function useShowcaseNavigationFavoriteEventsComposable() {
    const { hasFavoritesOptimistic } = useCustomerFavoritesService();
    function isFavoriteEventsEnabledReducer() {
        return hasFavoritesOptimistic.value;
    }
    function addFavoriteEventsReducer(itemReducers) {
        // eslint-disable-next-line max-len
        const reducers = itemReducers[ShowcaseNavigationItemId.SPORTLINE_FAVORITE_EVENTS] || [];
        return {
            ...itemReducers,
            [ShowcaseNavigationItemId.SPORTLINE_FAVORITE_EVENTS]: [
                ...reducers,
                isFavoriteEventsEnabledReducer
            ]
        };
    }
    return {
        addFavoriteEventsReducer
    };
}
