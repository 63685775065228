import { isString } from '@leon-hub/guards';
import { CustomFilter } from 'web/src/modules/sportline/enums';
const customFilterValues = Object.values(CustomFilter);
const customFilterValuesCaseless = customFilterValues.map((value)=>value.toLowerCase());
export function isCustomFilter(value) {
    return isString(value) && customFilterValues.includes(value);
}
export function isCustomFilterCaseless(value) {
    return isString(value) && customFilterValuesCaseless.includes(value.toLowerCase());
}
