import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import getInputIconSize from '../../utils/getInputIconSize';
export default /*@__PURE__*/ _defineComponent({
    __name: 'InputButton',
    props: {
        iconName: {
            default: IconName.CANCEL
        },
        title: {},
        isLarge: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props) {
        const props = __props;
        // ok not to be reactive
        const iconSize = getInputIconSize(props.isLarge);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                type: "button",
                tabindex: "-1",
                title: _ctx.title,
                class: _normalizeClass({
                    [_ctx.$style['input-button']]: true,
                    [_ctx.$style['input-button--large']]: _ctx.isLarge
                }),
                onMousedown: _cache[0] || (_cache[0] = _withModifiers(($event)=>_ctx.$emit('click'), [
                    "prevent"
                ]))
            }, [
                _createVNode(_unref(VIcon), {
                    size: _unref(iconSize),
                    name: _ctx.iconName
                }, null, 8, [
                    "size",
                    "name"
                ])
            ], 42, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'InputButton'
                ]
            ]);
        };
    }
});
