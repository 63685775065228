import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = [
    "disabled"
];
import { useCssModule } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useIsSportlineType } from 'web/src/modules/sportline/composables/core';
import createDisplayValueChangeDirective from 'web/src/utils/directives/createDisplayValueChangeDirective';
import { runnerComponentPropsToRefs, useRunner } from './composables';
import { runnerDataTestElement } from './constants';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PrimaryMarketRunner',
    props: {
        runner: {},
        market: {},
        slipCaption: {},
        sportEventId: {},
        isOutrightEvent: {
            type: Boolean
        },
        sportlineType: {},
        doDisplayName: {
            type: Boolean
        },
        isPermanentBetUp: {
            type: Boolean
        },
        isPermanentBetDown: {
            type: Boolean
        },
        isRunnerV2: {
            type: Boolean
        }
    },
    emits: [
        "click-runner"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const cssModule = useCssModule();
        const vDirection = {
            updated: createDisplayValueChangeDirective({
                moveUp: cssModule['sportline-primary-market-runner--bet-up'],
                moveDown: cssModule['sportline-primary-market-runner--bet-down'],
                autoDirection: true
            })
        };
        const runnerProps = runnerComponentPropsToRefs(props);
        const { value, price, name, isEmpty, isLocked, isSelected, addToSlip } = useRunner(runnerProps);
        const { isLive, isPrematch } = useIsSportlineType(runnerProps.sportlineType);
        function onRunnerClick(event) {
            addToSlip({
                event
            });
            emit('click-runner', event);
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                type: "button",
                class: _normalizeClass({
                    [_ctx.$style['sportline-primary-market-runner']]: true,
                    [_ctx.$style['sportline-primary-market-runner--selected']]: _unref(isSelected),
                    [_ctx.$style['sportline-primary-market-runner--bet-up']]: _ctx.isPermanentBetUp,
                    [_ctx.$style['sportline-primary-market-runner--bet-down']]: _ctx.isPermanentBetDown,
                    [_ctx.$style['sportline-primary-market-runner--v2']]: _ctx.isRunnerV2,
                    [_ctx.$style['sportline-primary-market-runner--locked']]: _unref(isLocked)
                }),
                disabled: _unref(isLocked) && !_unref(isSelected),
                onClick: _withModifiers(onRunnerClick, [
                    "stop",
                    "prevent"
                ])
            }, [
                _ctx.isRunnerV2 ? _createCommentVNode("", true) : _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass([
                        _ctx.$style['sportline-primary-market-runner__coefficient'],
                        _ctx.$style['sportline-primary-market-runner__coefficient--left']
                    ])
                }, [
                    _createTextVNode(_toDisplayString(_unref(name)), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: _unref(runnerDataTestElement).name
                        }
                    ]
                ]),
                _withDirectives((_openBlock(), _createElementBlock("span", {
                    class: _normalizeClass([
                        _ctx.$style['sportline-primary-market-runner__coefficient'],
                        _ctx.$style['sportline-primary-market-runner__coefficient--right']
                    ])
                }, [
                    _createTextVNode(_toDisplayString(_unref(value)), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: _unref(runnerDataTestElement).price
                        }
                    ]
                ]),
                _unref(isLocked) && !_unref(isEmpty) ? _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['sportline-primary-market-runner__locked-mask'])
                }, [
                    _withDirectives(_createVNode(_unref(VIcon), {
                        name: _unref(IconName).LOCK,
                        size: _unref(IconSize).SIZE_12,
                        class: _normalizeClass(_ctx.$style['sportline-primary-market-runner__locked-icon'])
                    }, null, 8, [
                        "name",
                        "size",
                        "class"
                    ]), [
                        [
                            _directive_data_test,
                            {
                                el: _unref(runnerDataTestElement).lockIcon
                            }
                        ]
                    ])
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: _unref(runnerDataTestElement).mask
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'PrimaryMarketRunner'
                ],
                [
                    _directive_data_test,
                    {
                        el: _unref(runnerDataTestElement).runner,
                        locked: _unref(isLocked),
                        selected: _unref(isSelected),
                        live: _unref(isLive),
                        prematch: _unref(isPrematch)
                    }
                ],
                [
                    vDirection,
                    {
                        value: _unref(price)
                    }
                ]
            ]);
        };
    }
});
