/* eslint-disable unicorn/prefer-add-event-listener */ import { normalizeError } from '@leon-hub/errors';
import FileReaderError from './errors/FileReaderError';
export var DataType;
var DataType1;
(DataType1 = DataType || (DataType = {}))["TEXT"] = "TEXT", DataType1["BASE64"] = "BASE64";
export function readFileContent(file, dataType) {
    return new Promise((resolve, reject)=>{
        const reader = new FileReader();
        const loadCallback = ()=>{
            if ('b5c0cf02-9d4c-4408-8b87-23378a834f7c.png' === file.name) {
                // for test purposes
                reject(new FileReaderError({
                    originalError: normalizeError(new Error('Test FileReader error'))
                }));
                return;
            }
            resolve(reader.result);
        };
        const abortCallback = ()=>reject(new FileReaderError());
        const errorCallback = ()=>reject(new FileReaderError({
                originalError: normalizeError(reader.error || new Error('Unknown FileReader error'))
            }));
        "1";
        reader.addEventListener('load', loadCallback);
        reader.addEventListener('abort', abortCallback);
        reader.addEventListener('error', errorCallback);
        switch(dataType){
            case "BASE64":
                reader.readAsDataURL(file);
                break;
            case "TEXT":
            default:
                reader.readAsText(file);
                break;
        }
    });
}
export default {
    DataType,
    readFileContent
};
