import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NotFoundMessage',
    props: {
        title: {},
        text: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VJumbotron, {
                heading: _ctx.title || _ctx.$t('WEB2_NOTHING_FOUND'),
                class: _normalizeClass(_ctx.$style['not-found-message__jumbotron'])
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
                    ]),
                _: 1
            }, 8, [
                "heading",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'NotFoundMessage'
                ]
            ]);
        };
    }
});
