import { watch } from 'vue';
import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
export function useLogErrorOnceForValue(valueRef) {
    let canLog = true;
    function logError(error) {
        if (!canLog) return;
        logger.error(normalizeError(error));
        canLog = false;
    }
    watch(valueRef, ()=>{
        canLog = true;
    }, {
        immediate: true
    });
    return {
        logError
    };
}
