import { computed } from 'vue';
import { getSafePercentage } from 'web/src/modules/core/utils/math';
function toFixedPercentage(value, toFixed) {
    return `${toFixed.value ? value.value.toFixed(toFixed.value) : value.value}%`;
}
export function useChartBarWidth(props) {
    const { toFixed, homePercentage, drawPercentage, awayPercentage } = props;
    const isEmpty = computed(()=>!homePercentage.value && !drawPercentage.value && !awayPercentage.value);
    // Do use relative width in case of the total sum less than 100%
    const totalPercentage = computed(()=>homePercentage.value + drawPercentage.value + awayPercentage.value);
    const homeChartWidth = computed(()=>isEmpty.value ? 50 : getSafePercentage(homePercentage.value, totalPercentage.value));
    const drawsChartWidth = computed(()=>getSafePercentage(drawPercentage.value, totalPercentage.value));
    const awayChartWidth = computed(()=>isEmpty.value ? 50 : getSafePercentage(awayPercentage.value, totalPercentage.value));
    const homePercentageLabel = computed(()=>homePercentage.value && homePercentage.value > 0 ? toFixedPercentage(homePercentage, toFixed) : null);
    const drawPercentageLabel = computed(()=>drawPercentage.value && drawPercentage.value > 0 ? toFixedPercentage(drawPercentage, toFixed) : null);
    const awayPercentageLabel = computed(()=>awayPercentage.value && awayPercentage.value > 0 ? toFixedPercentage(awayPercentage, toFixed) : null);
    return {
        isEmpty,
        homeChartWidth,
        drawsChartWidth,
        awayChartWidth,
        homePercentageLabel,
        drawPercentageLabel,
        awayPercentageLabel
    };
}
