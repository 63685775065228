import { isString } from '@leon-hub/guards';
function getChacheKeyForRoute(route) {
    const { name } = route;
    const params = route.params || {};
    if (!isString(name)) return null;
    return [
        name,
        ...Object.keys(params || {}).map((key)=>`${key}=${params[key]}`)
    ].join('&');
}
/**
 * @see LEONWEB-10420
 * This logic is not used yet.
 * Will be fully implemented or removed after clear requirements
 */ export function useSpintaxSeoMetaDataCache() {
    const cache = {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function isCacheAllowedForRoute(route) {
        // for now any cache is disabled
        return false;
    }
    function getCacheForRoute(route) {
        if (!isCacheAllowedForRoute(route)) return null;
        const key = getChacheKeyForRoute(route);
        if (!key) return null;
        return cache[key];
    }
    function clearCacheForKey(key) {
        if (cache[key]) delete cache[key];
    }
    function setCacheForRoute(route, value) {
        const key = getChacheKeyForRoute(route);
        if (!key) return;
        if (!isCacheAllowedForRoute(route) || !value) {
            clearCacheForKey(key);
            return;
        }
        cache[key] = value;
    }
    return {
        getCacheForRoute,
        setCacheForRoute
    };
}
