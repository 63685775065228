/* eslint-disable max-len */ import { CasinoRouteName } from '@leon-hub/routing-config-names';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class CasinoLoyaltyProgramModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addProfileRoute({
            name: CasinoRouteName.CASINO_LOYALTY_PROGRAM,
            path: '/profile/casino-loyalty-program',
            component: ()=>import('web/src/modules/casino-loyalty-program/pages/CasinoLoyaltyProgramRoutePage/CasinoLoyaltyProgramRoutePage.vue'),
            props: true,
            topBar: ()=>import('web/src/modules/casino-loyalty-program/components/LoyaltyProgramTopBarRouteComponent/LoyaltyProgramTopBarRouteComponent.vue'),
            title: '{{$t(\'WEB2_LOYALTY_PROGRAM_CASINO_LEVEL\')}}',
            prefetch: ()=>import('web/src/modules/casino-loyalty-program/prefetch/CasinoLoyaltyProgramPagePrefetch')
        });
        router.addProfileRoute({
            name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_ID,
            path: '/profile/casino-loyalty-program/:id',
            component: ()=>import('web/src/modules/casino-loyalty-program/pages/CasinoLoyaltyProgramRoutePage/CasinoLoyaltyProgramRoutePage.vue'),
            props: true,
            topBar: ()=>import('web/src/modules/casino-loyalty-program/components/LoyaltyProgramTopBarRouteComponent/LoyaltyProgramTopBarRouteComponent.vue'),
            title: '{{$t(\'WEB2_LOYALTY_PROGRAM_CASINO_LEVEL\')}}'
        });
        router.addProfileRoute({
            name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_INFO,
            path: '/profile/casino-loyalty-program-info',
            component: ()=>import('web/src/modules/casino-loyalty-program/pages/CasinoLoyaltyProgramInfoRoutePage/CasinoLoyaltyProgramInfoRoutePage.vue'),
            title: '{{$t(\'WEB2_LOYALTY_PROGRAM_CASINO_INFO\')}}'
        });
    }
}
