import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, mergeProps as _mergeProps } from "vue";
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { ConfirmEmailStage } from '../../enums';
import useConfirmEmail from './composables/useConfirmEmail';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ConfirmEmail',
    props: {
        stage: {
            default: ConfirmEmailStage.CONFIRM_SUCCESS
        },
        isPending: {
            type: Boolean
        },
        isLoggedIn: {
            type: Boolean
        },
        email: {
            default: ''
        },
        confirmDisabled: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "confirm",
        "send-again",
        "get-support"
    ],
    setup (__props) {
        const props = __props;
        const { isSuccess, jumbotronProps, isErrorViewVisible, withSupportTranslation, isLoaderVisible, confirmButtonsVisible, closeButtonVisible, confirmButtonLabel } = useConfirmEmail(props);
        const closeButtonKind = ButtonKind.TRANSPARENT;
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _unref(isLoaderVisible) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                key: 0,
                hint: _ctx.$t('WEB2_PLEASE_WAIT_TEXT')
            }, null, 8, [
                "hint"
            ])) : (_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps({
                key: 1
            }, _unref(jumbotronProps), {
                class: {
                    [_ctx.$style['confirm-email']]: true,
                    [_ctx.$style['confirm-email--success']]: _unref(isSuccess)
                }
            }), {
                default: _withCtx(()=>[
                        _ctx.stage === _unref(ConfirmEmailStage).EMAIL_UNKNOWN ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_CONFIRM_EMAIL_ENTER_MAIL')), 1),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['confirm-email__form'])
                            }, [
                                _renderSlot(_ctx.$slots, "email-form")
                            ], 2)
                        ], 64)) : _ctx.stage === _unref(ConfirmEmailStage).EMAIL_IS_NOT_CONFIRMED ? (_openBlock(), _createBlock(_unref(TextSlotPlaceholder), {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['confirm-email__label']),
                            text: _ctx.$t('WEB2_CONFIRM_EMAIL_FOLLOW_LINK')
                        }, {
                            email: _withCtx(()=>[
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['confirm-email__bright'])
                                    }, _toDisplayString(_ctx.email), 3)
                                ]),
                            _: 1
                        }, 8, [
                            "class",
                            "text"
                        ])) : _ctx.stage === _unref(ConfirmEmailStage).WAIT_FOR_CONFIRMATION ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 2
                        }, [
                            (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _createVNode(_unref(TextSlotPlaceholder), {
                                    class: _normalizeClass(_ctx.$style['confirm-email__label']),
                                    text: _ctx.$t('WEB2_CONFIRM_EMAIL_SENT')
                                }, {
                                    email: _withCtx(()=>[
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['confirm-email__bright'])
                                            }, _toDisplayString(_ctx.email), 3)
                                        ]),
                                    _: 1
                                }, 8, [
                                    "class",
                                    "text"
                                ]),
                                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                                _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                                _createVNode(_unref(TextSlotPlaceholder), {
                                    class: _normalizeClass(_ctx.$style['confirm-email__label']),
                                    text: _ctx.$t('WEB2_CONFIRM_EMAIL_SPAM')
                                }, {
                                    retry: _withCtx(()=>[
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['confirm-email__button-container'])
                                            }, [
                                                _createElementVNode("button", {
                                                    type: "button",
                                                    class: _normalizeClass([
                                                        _ctx.$style['confirm-email__inline-button'],
                                                        _ctx.$style['confirm-email__bright']
                                                    ]),
                                                    onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('send-again'))
                                                }, _toDisplayString(_ctx.$t('WEB2_CONFIRM_EMAIL_RETRY')), 3),
                                                _cache[6] || (_cache[6] = _createTextVNode(". "))
                                            ], 2)
                                        ]),
                                    _: 1
                                }, 8, [
                                    "class",
                                    "text"
                                ])
                            ], 64))
                        ], 64)) : _ctx.stage === _unref(ConfirmEmailStage).CONFIRM_SUCCESS ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 3
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_CONFIRM_EMAIL_SUCCESS_TEXT')), 1)
                        ], 64)) : _unref(isErrorViewVisible) ? (_openBlock(), _createBlock(_unref(TextSlotPlaceholder), {
                            key: 4,
                            class: _normalizeClass(_ctx.$style['confirm-email__label']),
                            text: _unref(withSupportTranslation)
                        }, {
                            support: _withCtx(()=>[
                                    _createElementVNode("button", {
                                        type: "button",
                                        class: _normalizeClass(_ctx.$style['confirm-email__inline-button']),
                                        onClick: _cache[1] || (_cache[1] = ($event)=>_ctx.$emit('get-support'))
                                    }, _toDisplayString(_ctx.$t('WEB2_CONFIRM_EMAIL_MANY_REQUESTS_SUPPORT')), 3)
                                ]),
                            _: 1
                        }, 8, [
                            "class",
                            "text"
                        ])) : _createCommentVNode("", true)
                    ]),
                footer: _withCtx(()=>[
                        _unref(closeButtonVisible) ? _withDirectives((_openBlock(), _createBlock(_unref(JumbotronFooterButton), {
                            key: 0,
                            "full-width": "",
                            size: "large",
                            kind: _unref(ButtonKind).BASE,
                            label: _ctx.$t('WEB2_CLOSE'),
                            onClick: _cache[2] || (_cache[2] = ($event)=>_ctx.$emit('close'))
                        }, null, 8, [
                            "kind",
                            "label"
                        ])), [
                            [
                                _directive_data_test,
                                {
                                    el: 'button-action-close'
                                }
                            ]
                        ]) : _createCommentVNode("", true),
                        _unref(confirmButtonsVisible) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _withDirectives(_createVNode(_unref(JumbotronFooterButton), {
                                label: _unref(confirmButtonLabel),
                                kind: _unref(ButtonKind).PRIMARY,
                                "full-width": "",
                                "is-loading": _ctx.isPending,
                                disabled: _ctx.confirmDisabled,
                                onClick: _cache[3] || (_cache[3] = ($event)=>_ctx.$emit('confirm'))
                            }, null, 8, [
                                "label",
                                "kind",
                                "is-loading",
                                "disabled"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'button-action-confirm'
                                    }
                                ]
                            ]),
                            _withDirectives(_createVNode(_unref(JumbotronFooterButton), {
                                kind: _unref(closeButtonKind),
                                "full-width": "",
                                disabled: _ctx.isPending,
                                label: _ctx.$t('WEB2_MOBILE_CANCEL'),
                                onClick: _cache[4] || (_cache[4] = ($event)=>_ctx.$emit('close'))
                            }, null, 8, [
                                "kind",
                                "disabled",
                                "label"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'button-action-cancel'
                                    }
                                ]
                            ])
                        ], 64)) : _createCommentVNode("", true),
                        _createCommentVNode("", true)
                    ]),
                _: 3
            }, 16, [
                "class"
            ]));
        };
    }
});
