import { useRouter } from 'vue-router';
import { computed, toRef, watch } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { HistoryFilter, TransactionGroup } from '@leon-hub/api-sdk';
import { ActiveOrderFilterEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';
export default function useRequestedAmount(emit) {
    const $formatMoney = useFormatMoney();
    const userStore = useUserStore();
    const { currency } = useCurrency();
    const customerHistoryStore = useCustomerHistoryStore();
    const router = useRouter();
    const profileData = toRef(userStore, 'profileData');
    const orderFilter = toRef(customerHistoryStore, 'orderFilter');
    const requestedValue = computed(()=>profileData.value?.requestedWithdrawals.requestedAmount ?? 0);
    const singlePaymentId = computed(()=>profileData.value?.requestedWithdrawals.singlePaymentId ?? 0);
    const requestedCount = computed(()=>profileData.value?.requestedWithdrawals.requestedCount ?? 0);
    function onClickRequest() {
        if (singlePaymentId.value && 1 === requestedCount.value) router.push({
            name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
            params: {
                id: `${singlePaymentId.value}`,
                group: TransactionGroup.PAYMENT
            }
        });
        else router.push({
            name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
            query: {
                filter: HistoryFilter.ALL_WITHDRAWALS,
                activeOrderFilter: ActiveOrderFilterEnum.DEFAULT
            }
        });
        if (emit) emit('close');
    }
    watch(requestedValue, (newValue, oldValue)=>{
        if (newValue < oldValue) customerHistoryStore.updateCustomerHistoryAction(orderFilter.value);
    });
    return {
        requestedValue,
        currency,
        $formatMoney,
        onClickRequest
    };
}
