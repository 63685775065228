const getCoreDropdownProps = function(input) {
    let { matchParentWidth } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const { x, y, width, height } = input;
    let maxPossibleWidth = 0;
    if ('undefined' != typeof window) maxPossibleWidth = window.innerWidth - x - (width || 0);
    if (matchParentWidth && width) maxPossibleWidth = width;
    return {
        appearZonePositionX: x,
        appearZonePositionY: y,
        appearZoneMinWidth: width,
        appearZoneMaxWidth: maxPossibleWidth,
        appearZoneOffsetY: height || 0
    };
};
export default getCoreDropdownProps;
