import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { noop } from 'lodash';
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DropListSelectModal',
    props: {
        label: {},
        searchEnabled: {
            type: Boolean
        },
        isFooterShown: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "preselect",
        "return-focus",
        "update-position"
    ],
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY
            }, {
                default: _withCtx(()=>[
                        _createVNode(_unref(LazyDefaultModal), {
                            ref: "modal",
                            "is-close-as-back": false,
                            "is-scroll-bar-enabled": false,
                            "is-centered": false,
                            "is-full-height": "",
                            "is-full-height-centered": false,
                            "is-alert": false,
                            "top-bar-text": _ctx.label || '',
                            "use-scroll-listener": false,
                            onClose: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('close'))
                        }, {
                            default: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['modal-select'])
                                    }, [
                                        _ctx.searchEnabled ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: _normalizeClass(_ctx.$style['modal-select__search'])
                                        }, [
                                            _renderSlot(_ctx.$slots, "search")
                                        ], 2)) : _createCommentVNode("", true),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['modal-select__content'])
                                        }, [
                                            _renderSlot(_ctx.$slots, "content", {
                                                mounted: _unref(noop),
                                                onContentRedraw: _unref(noop)
                                            })
                                        ], 2),
                                        _ctx.isFooterShown ? (_openBlock(), _createElementBlock("div", {
                                            key: 1,
                                            class: _normalizeClass(_ctx.$style['modal-select__footer'])
                                        }, [
                                            _renderSlot(_ctx.$slots, "footer")
                                        ], 2)) : _createCommentVNode("", true)
                                    ], 2)
                                ]),
                            _: 3
                        }, 8, [
                            "is-close-as-back",
                            "top-bar-text"
                        ])
                    ]),
                _: 3
            }, 8, [
                "selector"
            ])), [
                [
                    _directive_auto_id,
                    'DropListSelectModal'
                ]
            ]);
        };
    }
});
