import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names/src';
import VSnackBar from 'web/src/components/SnackBar/VSnackBar/VSnackBar.vue';
import { useVideoVerificationAlert } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VideoVerificationAlert',
    setup (__props) {
        const { snackbarProperties, markVideoVerificationAlert } = useVideoVerificationAlert();
        const router = useRouter();
        function onClick() {
            router.push({
                name: RouteName.CUSTOMER_VERIFICATION
            });
            markVideoVerificationAlert();
        }
        function onCloseClick() {
            markVideoVerificationAlert();
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VSnackBar, _mergeProps(_unref(snackbarProperties), {
                onClick: onClick,
                onClickClose: onCloseClick
            }), null, 16)), [
                [
                    _directive_auto_id,
                    'VideoVerificationAlert'
                ]
            ]);
        };
    }
});
