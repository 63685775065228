import { _ as _define_property } from "@swc/helpers/_/_define_property";
import round from 'lodash/round';
import RequestAnimationFrame from 'web/src/utils/RequestAnimationFrame';
import RandomIntervals from 'web/src/utils/RandomIntervals';
let AmountAnimator = class AmountAnimator {
    start() {
        const { callback, values } = this;
        const { from, to } = values;
        this.animationFrame = new RequestAnimationFrame({
            ...this.options,
            draw: (progress)=>{
                const currentValue = progress ? round((to - from) * progress + from, 2) : from;
                callback(currentValue, this.isFinished);
                this.currentValue = currentValue;
            }
        });
        this.animationFrame.start();
    }
    stop() {
        const { animationFrame } = this;
        if (animationFrame) {
            animationFrame.stop();
            this.values.from = this.currentValue;
        }
    }
    constructor(options){
        _define_property(this, "animationFrame", null);
        _define_property(this, "randomIntervals", void 0);
        _define_property(this, "values", void 0);
        _define_property(this, "options", void 0);
        _define_property(this, "callback", void 0);
        _define_property(this, "currentValue", void 0);
        _define_property(this, "isFinished", false);
        const { timeRange, duration, callback, values } = options;
        this.randomIntervals = new RandomIntervals(timeRange.min, timeRange.max, duration);
        this.values = values;
        this.currentValue = values.from;
        this.options = {
            timing: (timeFraction)=>{
                if (1 === timeFraction) this.isFinished = true;
                return this.randomIntervals.query(timeFraction);
            },
            duration
        };
        this.callback = callback;
    }
};
export default AmountAnimator;
