import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import useFastTrackApiClient from 'web/src/modules/fast-track/services/api/useFastTrackApiClient';
import { isFastTrackBaseEndpointsResponse, isFastTrackDeleteMessageResponse, isFastTrackDoLoginResponse, isFastTrackMarkMessageAsReadResponse, isFastTrackMessagesResponse } from './guards';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createFastTrackApi(requestTimeout) {
    const client = useFastTrackApiClient();
    return {
        async getFastTrackBaseEndpoints (options) {
            return client.request({
                id: 'fast-track-api/get-saved-logins',
                endpoint: options.endpoint,
                method: 'GET',
                silent: true,
                priority: RequestOptionsPriority.LOW,
                notAddBaseHeaders: true,
                requestTimeout: requestTimeout.value,
                guard: isFastTrackBaseEndpointsResponse
            });
        },
        async doFastTrackLogin (options) {
            return client.request({
                id: 'fast-track-api/do-fast-track-login',
                endpoint: `${options.fusionUrl}/Platform/LoginAuthToken`,
                method: 'POST',
                headers: {
                    authtoken: options.sessionId
                },
                requestTimeout: requestTimeout.value,
                silent: true,
                priority: RequestOptionsPriority.LOW,
                notAddBaseHeaders: true,
                guard: isFastTrackDoLoginResponse
            });
        },
        async getFastTrackMessages (options) {
            return client.request({
                id: 'fast-track-api/get-fast-track-messages',
                endpoint: `${options.fusionUrl}/Notifications/v2/user-notifications`,
                method: 'GET',
                headers: {
                    authtoken: options.authToken
                },
                query: {
                    'unread-only': options.unreadOnly,
                    'inbox-only': options.inboxOnly
                },
                requestTimeout: requestTimeout.value,
                silent: true,
                priority: RequestOptionsPriority.LOW,
                notAddBaseHeaders: true,
                guard: isFastTrackMessagesResponse
            });
        },
        async markFastTrackMessageAsRead (options) {
            return client.request({
                id: 'fast-track-api/mark-fast-track-message-as-read',
                endpoint: `${options.fusionUrl}/Notifications/MarkNotificationAsRead`,
                method: 'POST',
                headers: {
                    authtoken: options.authToken
                },
                data: {
                    MessageId: options.messageId
                },
                requestTimeout: requestTimeout.value,
                silent: true,
                priority: RequestOptionsPriority.LOW,
                notAddBaseHeaders: true,
                guard: isFastTrackMarkMessageAsReadResponse
            });
        },
        async deleteFastTrackMessage (options) {
            return client.request({
                id: 'fast-track-api/delete-fast-track-message',
                endpoint: `${options.fusionUrl}/Notifications/v2/user-notification/${options.messageId}`,
                method: 'DELETE',
                headers: {
                    authtoken: options.authToken
                },
                requestTimeout: requestTimeout.value,
                silent: true,
                priority: RequestOptionsPriority.LOW,
                notAddBaseHeaders: true,
                guard: isFastTrackDeleteMessageResponse
            });
        }
    };
}
