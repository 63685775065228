import { computed, nextTick, onBeforeUnmount, ref } from 'vue';
import Scrollbar from 'smooth-scrollbar';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import getTestDataAttrs from '../../utils/getTestDataAttrs';
export default function useVScrollbarDesktop(props, emit) {
    const element = ref();
    let $scrollbar = null;
    let postScrollElement;
    let postScrollOptions;
    const isReady = ref(false);
    const topOffset = ref(0);
    useBusSafeSubscribe(BusEvent.QA_SCROLLBAR_SCROLL_TO, onScrollTo);
    useBusSafeSubscribe(BusEvent.MODAL_CONTENT_SCROLL_TOP, scrollTop);
    function destroyScrollbar() {
        if ($scrollbar) {
            $scrollbar.destroy();
            $scrollbar = null;
        }
    }
    function clearPostScrollElement() {
        postScrollElement = void 0;
        postScrollOptions = void 0;
    }
    async function initScrollBar() {
        destroyScrollbar();
        await nextTick();
        const el = element.value;
        if (el) {
            $scrollbar = Scrollbar.init(el, {
                damping: 1,
                renderByPixels: true
            });
            $scrollbar.addListener((status)=>{
                const limit = status.limit.y;
                const offset = status.offset.y;
                topOffset.value = offset;
                if (props.useScrollListener) {
                    emit('scroll', {
                        limit,
                        offset
                    });
                    if (limit <= offset) emit('reached-bottom');
                }
            });
            if (postScrollElement) {
                await nextAnimationFrame();
                scrollToElement(postScrollElement, postScrollOptions);
                clearPostScrollElement();
            }
            await nextTick();
            isReady.value = true;
        }
    }
    useIntersectionObserver(element, (isIntersecting)=>{
        if (isIntersecting) initScrollBar();
    }, void 0, true);
    onBeforeUnmount(()=>{
        destroyScrollbar();
        clearPostScrollElement();
    });
    const testAttrs = computed(()=>getTestDataAttrs('scrollbar', props));
    function isElementFullyVisible(el) {
        if (!$scrollbar) return false;
        const { bounding } = $scrollbar;
        const targetBounding = el.getBoundingClientRect();
        return targetBounding.bottom <= bounding.bottom && targetBounding.top >= bounding.top;
    }
    function onScrollTo(param) {
        let { scrollbar, el, options } = param;
        if (scrollbar === props.testEl) scrollToElement(el, options);
    }
    function onScroll(event) {
        if (props.disabled) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
    // exposed function
    function scrollTo(top) {
        if ($scrollbar) $scrollbar.scrollTo(0, top, 0);
    }
    function scrollTop() {
        scrollTo(0);
    }
    function scrollDown() {
        scrollTo($scrollbar?.contentEl.scrollHeight || 0);
    }
    function scrollRight() {
        if ($scrollbar) $scrollbar.scrollTo($scrollbar.contentEl.scrollWidth, 0, 0);
    }
    function scrollUpdate() {
        $scrollbar?.update();
    }
    function scrollToElement(el, options) {
        if (el) {
            if ($scrollbar) {
                let onlyScrollIfNeeded = !!options?.onlyIfNeeded;
                if (!isElementFullyVisible(el)) onlyScrollIfNeeded = false;
                $scrollbar.scrollIntoView(el, {
                    onlyScrollIfNeeded,
                    alignToTop: options?.alignToTop,
                    offsetTop: options?.offsetTop
                });
            } else {
                postScrollElement = el;
                postScrollOptions = options;
            }
        }
    }
    const providableContext = computed(()=>({
            isReady: isReady.value,
            scrollTop: topOffset.value
        }));
    return {
        testAttrs,
        element,
        providableContext,
        onScroll,
        scrollTo,
        scrollTop,
        scrollDown,
        scrollRight,
        scrollUpdate,
        scrollToElement
    };
}
