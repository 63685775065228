import { toRef } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { captchaChallengeWasShownKey, CaptchaRequesterStrategy } from '@leon-hub/module-captcha';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import registrationTypeFormat from 'web/src/modules/registration/utils/registrationTypeFormat';
import useRegistrationIdleRedirectStore from 'web/src/modules/registration/store/useRegistrationIdleRedirectStore';
import { LoginMethod } from 'web/src/modules/user/store/enums';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function useRegistrationAnalytics() {
    const analytics = useAnalytics();
    const idleStore = useRegistrationIdleRedirectStore();
    const customerData = useCustomerDataStore();
    const countryName = toRef(customerData, 'countryName');
    const vipStatus = toRef(customerData, 'vipStatus');
    const { captchaSettingsForStrategy } = useCaptchaStore();
    const captchaSettings = captchaSettingsForStrategy(CaptchaRequesterStrategy.REGISTRATION);
    function registrationError(error, registrationType) {
        analytics.push(AnalyticsEvent.Z_LI_REGISTRATION_ERROR, {
            registration: {
                [registrationType]: {
                    error: {
                        message: error.message,
                        code: error.code.toString()
                    }
                }
            }
        });
        analytics.push(AnalyticsEvent.Z_LOGGED_IN, {
            login: {
                error: {
                    message: error.message,
                    code: `${error.code}`
                }
            }
        });
    }
    function registrationSuccess(registrationMetrics) {
        analytics.registrationSuccess(registrationMetrics);
        "1";
        analytics.push(AnalyticsEvent.Z_DURATION_REGISTRATION, {
            registration: {
                durationRegistration: {
                    [idleStore.getDurationRegistrationInSeconds()]: registrationMetrics.userId
                }
            }
        });
        const loginTypeKey = registrationTypeFormat(registrationMetrics?.registrationType ?? null);
        analytics.push(AnalyticsEvent.Z_LOGGED_IN, {
            login: {
                ok: {
                    [loginTypeKey]: {
                        captchaChallengeWasShown: window.sessionStorage.getItem(captchaChallengeWasShownKey),
                        captcha: captchaSettings.value.captchaType,
                        loginType: LoginMethod.DEFAULT
                    }
                }
            }
        });
        analytics.yandexZInit({
            userId: registrationMetrics.userId || '',
            customerType: vipStatus.value,
            country: countryName.value,
            currentTheme: registrationMetrics.theme
        });
    }
    function setMetrikaBonusCode(param) {
        let { registrationType, bonusCode } = param;
        analytics.push(AnalyticsEvent.Z_BONUS_CODE, {
            registration: {
                [registrationTypeFormat(registrationType)]: {
                    bonusCodeDetails: {
                        bonusCode: bonusCode || null
                    }
                }
            }
        });
    }
    function registrationSubmit() {
        analytics.clickMap({
            registration: 'registrationPage'
        });
    }
    return {
        registrationError,
        registrationSubmit,
        registrationSuccess,
        setMetrikaBonusCode
    };
}
