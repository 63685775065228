import { _ as _define_property } from "@swc/helpers/_/_define_property";
import DateTime from 'web/src/utils/DateTime';
let DateTimeInterval = class DateTimeInterval {
    static getNow() {
        return Date.now();
    }
    static fromNowPlusHours() {
        let count = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 1;
        const now = DateTimeInterval.getNow();
        const oneHour = 3600000;
        return new DateTimeInterval(now, now + oneHour * count);
    }
    static fromNowUntilTheEndOfTheDay() {
        const now = DateTimeInterval.getNow();
        const from = new Date(now);
        const end = new Date(now);
        end.setHours(23, 59, 59, 999);
        return new DateTimeInterval(from.getTime(), end.getTime());
    }
    static today() {
        const now = DateTimeInterval.getNow();
        const from = new Date(now);
        const end = new Date(now);
        from.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        return new DateTimeInterval(from.getTime(), end.getTime());
    }
    static tomorrow() {
        const todayInterval = DateTimeInterval.today();
        const oneDay = 86400000;
        return new DateTimeInterval(todayInterval.from.toTimestamp() + oneDay, todayInterval.to.toTimestamp() + oneDay);
    }
    static afterTomorrow() {
        const todayInterval = DateTimeInterval.today();
        const oneDay = 86400000;
        return new DateTimeInterval(todayInterval.from.toTimestamp() + 2 * oneDay, todayInterval.to.toTimestamp() + 2 * oneDay);
    }
    toObject() {
        return {
            from: this.from.toTimestamp(),
            to: this.to.toTimestamp()
        };
    }
    constructor(from, to){
        _define_property(this, "from", void 0);
        _define_property(this, "to", void 0);
        this.from = 'number' == typeof from || 'string' == typeof from ? DateTime.withTimeStamp(from) : from;
        this.to = 'number' == typeof to || 'string' == typeof to ? DateTime.withTimeStamp(to) : to;
    }
};
export { DateTimeInterval as default };
