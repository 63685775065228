/**
 * Returns optional either an image or an icon, depending on the environment and arguments.
 */ export function getImageOrIcon(param) {
    let { alertIcon, image, icon } = param;
    if (image) return {
        image
    };
    if (icon) return {
        iconName: icon
    };
    return {};
}
