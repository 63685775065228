import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { AlertIconName } from '@leon-hub/icons';
import { doDeactivateTimer, doPlayTimeControl, playTimeControl } from '@leon-hub/api-sdk';
import { getJumbotronProps } from 'web/src/components/Jumbotron';
import { InGameStepEnum } from 'web/src/modules/profile/submodules/responsible-gambling/store/enums';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useUserStore } from 'web/src/modules/user/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import useLocalizedTimeString from 'web/src/modules/i18n/composables/useLocalizedTimeString';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import useAppFullscreenStore from 'web/src/modules/core/store/useAppFullscreenStore';
import normalizeTime from 'web/src/modules/profile/utils/normalizeTime';
import { StepName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
const useResponsibleGamblingStoreV2 = defineStore('responsible-gambling-v2', ()=>{
    const api = useGraphqlClient();
    const frontNotificationsStore = useFrontNotificationsStore();
    const playTimeEndNotification = toRef(frontNotificationsStore, 'playTimeEndNotification');
    const userStore = useUserStore();
    const { $translate } = useI18n();
    const { showDialog, closeDialog } = useDialogs();
    const { getTimeString } = useLocalizedTimeString();
    const formatMoney = useFormatMoney();
    const { currency } = useCurrency();
    const { setFullscreenMode } = useAppFullscreenStore();
    /** State */ const isOnboardingIconShown = ref(true);
    const hideResponsibleGamblingOnBoardingPage = ref(false);
    const timeInGameStep = ref(InGameStepEnum.INITIAL);
    const timeInGameTimerSetupData = ref(null);
    const timeInGameTimerData = ref(null);
    const timeInGameLoadingState = ref(false);
    const timeInGameNotificationIsVisible = ref(false);
    const depositStep = ref(StepName.INFO);
    const betStep = ref(StepName.INFO);
    /** Mutations */ function setOnboardingIconState(value) {
        isOnboardingIconShown.value = value;
    }
    function timeInGameSetNotificationIsVisible(value) {
        timeInGameNotificationIsVisible.value = value;
    }
    function timeInGameSetStep(value) {
        timeInGameStep.value = value;
    }
    function timeInGameSetData(value) {
        timeInGameTimerSetupData.value = value;
    }
    function setDepositStep(value) {
        depositStep.value = value;
    }
    function setBetStep(value) {
        betStep.value = value;
    }
    function setResponsibleGamblingHideOnBoardingPageState(value) {
        hideResponsibleGamblingOnBoardingPage.value = value;
    }
    async function timeInGameTimerActivate(options) {
        timeInGameLoadingState.value = true;
        return doPlayTimeControl(api, (node)=>node.mutations.playTimeControl.setTimer, {
            options
        }, {
            silent: true
        }).then((response)=>{
            timeInGameTimerData.value = response;
        }).finally(()=>{
            timeInGameLoadingState.value = false;
        });
    }
    async function timeInGameTimerDeactivate() {
        timeInGameLoadingState.value = true;
        return doDeactivateTimer(api, (node)=>node.mutations.playTimeControl.deactivateTimer, {
            options: {}
        }, {
            silent: true
        }).then(()=>{
            timeInGameTimerData.value = null;
        }).finally(()=>{
            timeInGameLoadingState.value = false;
        });
    }
    async function timeInGameTimerContinue() {
        timeInGameLoadingState.value = true;
        return playTimeControl(api, (node)=>node.queries.playTimeControl.getState, {
            options: {}
        }, {
            silent: true
        }).then((response)=>timeInGameTimerActivate({
                hours: response.state?.hours || 0,
                minutes: response.state?.minutes || 0
            })).finally(()=>{
            timeInGameLoadingState.value = false;
        });
    }
    async function timeInGameGetState() {
        timeInGameLoadingState.value = true;
        return playTimeControl(api, (node)=>node.queries.playTimeControl.getState, {
            options: {}
        }, {
            silent: true
        }).then((response)=>{
            timeInGameTimerData.value = response;
        }).finally(()=>{
            timeInGameLoadingState.value = false;
        });
    }
    const showGameTimeEnd = (data)=>{
        if (!timeInGameNotificationIsVisible.value) {
            timeInGameSetNotificationIsVisible(true);
            const hours = computed(()=>{
                const [h] = normalizeTime(data.hours, data.minutes);
                return {
                    count: `${h}`
                };
            });
            const minutes = computed(()=>{
                const [, m] = normalizeTime(data.hours, data.minutes);
                return {
                    count: `${m}`
                };
            });
            const timeName = getTimeString(hours, minutes);
            const params = computed(()=>({
                    time: `${timeName?.hours.toLowerCase()} ${timeName?.minutes.toLowerCase()}`,
                    sum: formatMoney(Math.abs(data.overallProfit || 0), {
                        currency: data.currency || currency.value
                    })
                }));
            const resultMessage = computed(()=>{
                if (!Number(data.overallProfit) || Number(data.overallProfit) >= 0) return $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_WIN', params).value;
                return $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_LOSS', params).value;
            });
            const config = {
                presetName: PresetName.CONFIRM,
                options: {
                    ...getJumbotronProps({
                        alertIcon: AlertIconName.Rest
                    }),
                    width: ModalWidth.SMALL,
                    title: $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_TITLE').value,
                    confirmMessage: `${$translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_DESCRIPTION', params).value} ${resultMessage.value}`,
                    buttons: [
                        {
                            label: $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_BUTTONS_CONTINUE').value,
                            kind: ButtonKind.PRIMARY,
                            action: DialogAction.MODAL_CLOSE
                        },
                        {
                            label: $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_BUTTON_EXIT').value,
                            action: DialogAction.CONFIRM,
                            kind: ButtonKind.TRANSPARENT
                        }
                    ]
                }
            };
            config.options.image = {
                // eslint-disable-next-line import/no-dynamic-require
                src: require("web/src/assets/images/li/default/alarm.svg")
            };
            setFullscreenMode(false);
            const { id, subscribe } = showDialog(config);
            subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    timeInGameSetNotificationIsVisible(false);
                    userStore.logout();
                },
                [DialogAction.MODAL_CLOSE]: ()=>{
                    timeInGameSetNotificationIsVisible(false);
                    timeInGameTimerContinue();
                    closeDialog(id);
                }
            });
        }
    };
    function init() {
        return Promise.resolve();
    }
    watch(playTimeEndNotification, (newValue)=>{
        if (newValue) showGameTimeEnd(newValue);
    }, {
        immediate: true
    });
    return {
        init,
        hideResponsibleGamblingOnBoardingPage,
        setOnboardingIconState,
        timeInGameStep,
        timeInGameTimerSetupData,
        timeInGameTimerData,
        timeInGameLoadingState,
        timeInGameNotificationIsVisible,
        depositStep,
        betStep,
        timeInGameSetStep,
        timeInGameSetData,
        timeInGameTimerActivate,
        timeInGameTimerDeactivate,
        timeInGameTimerContinue,
        timeInGameGetState,
        timeInGameSetNotificationIsVisible,
        setResponsibleGamblingHideOnBoardingPageState,
        setDepositStep,
        setBetStep
    };
});
export default useResponsibleGamblingStoreV2;
