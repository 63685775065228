import { computed } from 'vue';
import { isOptionalString, isString } from '@leon-hub/guards';
import { getOptionsRelatedToSiblingFields, getWrapperHintProperties } from '../../utils';
import { isAutocompleteAddressProps } from '../../guards';
export default function useFormAutocompleteAddress(props) {
    const componentProps = computed(()=>{
        const { name, value, options, title, error, disabled, hintMessage, extraProperties, autofocus } = props;
        isOptionalString(value);
        const computedOptions = options ?? {};
        const { relatedCountryFieldId, relatedCityFieldId, ...restOptions } = computedOptions;
        let combinedOptions = {
            ...restOptions
        };
        combinedOptions = getOptionsRelatedToSiblingFields('countryCode', relatedCountryFieldId, extraProperties?.formData, combinedOptions);
        combinedOptions = getOptionsRelatedToSiblingFields('selectedCity', relatedCityFieldId, extraProperties?.formData, combinedOptions);
        isString(computedOptions.countryCode);
        const allProps = {
            name,
            value,
            label: title,
            disabled,
            ...combinedOptions,
            ...getWrapperHintProperties({
                options,
                error,
                hintMessage
            }),
            autofocus
        };
        isAutocompleteAddressProps(allProps);
        return allProps;
    });
    return {
        componentProps
    };
}
