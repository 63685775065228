export function getSlipEntryInput(item) {
    return {
        ts: item.ts,
        event: item.event,
        market: item.market,
        runner: item.runner,
        odds: item.odds,
        banker: item.banker,
        marketStatus: item.marketStatus,
        betline: item.betline,
        zeroMargin: item.zeroMargin
    };
}
export function getMakeBetEntryItems(input) {
    return input.map(getSlipEntryInput);
}
