import { LimitName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
export function getInputPlaceholder($translate, limit) {
    switch(limit){
        case LimitName.DAILY:
            return $translate('WEB2_DAILY_LIMIT').value;
        case LimitName.WEEKLY:
            return $translate('WEB2_WEEKLY_LIMIT').value;
        default:
            return $translate('WEB2_MONTHLY_LIMIT').value;
    }
}
