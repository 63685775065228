import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, mergeProps as _mergeProps, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "informer__label"
};
const _hoisted_2 = {
    class: "informer__money"
};
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useVInformer } from 'web/src/components/Informer/VInformer/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VInformer',
    props: {
        label: {
            default: ''
        },
        value: {
            default: 0
        },
        number: {
            default: 0
        },
        currency: {},
        showCancelButton: {
            type: Boolean
        },
        wrap: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { cancelButtonProperties, formattedValue, badgeKind } = useVInformer(props);
        function emitClick() {
            emits('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: "informer",
                onClick: emitClick
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "label", {}, ()=>[
                            _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
                        ])
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass([
                        "informer__value",
                        {
                            'informer__value--wrap': _ctx.wrap
                        }
                    ])
                }, [
                    _renderSlot(_ctx.$slots, "value", {
                        formattedValue: _unref(formattedValue)
                    }, ()=>[
                            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(formattedValue)), 1),
                            _ctx.number > 0 ? (_openBlock(), _createBlock(VBadge, {
                                key: 0,
                                label: `${_ctx.number}`,
                                kind: _unref(badgeKind),
                                class: "informer__counter-badge"
                            }, null, 8, [
                                "label",
                                "kind"
                            ])) : _createCommentVNode("", true),
                            _ctx.showCancelButton ? (_openBlock(), _createBlock(VButton, _normalizeProps(_mergeProps({
                                key: 1
                            }, _unref(cancelButtonProperties))), null, 16)) : _createCommentVNode("", true)
                        ])
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'VInformer'
                ]
            ]);
        };
    }
});
