import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import EgsJackpotAnimatedAmount from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAnimatedAmount/EgsJackpotAnimatedAmount.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsGameJackpotListItem',
    props: {
        item: {},
        href: {},
        animationDuration: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: _ctx.href,
                class: _normalizeClass(_ctx.$style['egs-game-jackpot-list-item']),
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>emit('click'), [
                    "prevent"
                ]))
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['egs-game-jackpot-list-item__container'])
                }, [
                    _ctx.item.images.jackpot ? (_openBlock(), _createBlock(VImage, _mergeProps({
                        key: 0
                    }, _ctx.item.images.jackpot, {
                        "show-loader": "",
                        "is-lazy": "",
                        "object-fit": _unref(ObjectFitOption).COVER,
                        class: _ctx.$style['egs-game-jackpot-list-item__img']
                    }), null, 16, [
                        "object-fit",
                        "class"
                    ])) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['egs-game-jackpot-list-item__counter-container'])
                    }, [
                        _ctx.item.jackpot ? (_openBlock(), _createBlock(EgsJackpotAnimatedAmount, _mergeProps({
                            key: 0
                        }, _ctx.item.jackpot.amount, {
                            "has-background": "",
                            "jackpot-animation-duration": _ctx.animationDuration
                        }), null, 16, [
                            "jackpot-animation-duration"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['egs-game-jackpot-list-item__label'])
                }, _toDisplayString(_ctx.item.name), 3)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'EgsGameJackpotListItem'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'egs-game-jackpot-list-item'
                    }
                ]
            ]);
        };
    }
});
