import { ref, computed, toRef } from 'vue';
import { logger } from '@leon-hub/logging';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { isCustomFilter } from 'web/src/modules/sportline/guards';
import { isLiveCountersEmpty, isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { SportsListAdapter } from 'web/src/modules/sportline/utils/rest';
import { chooseFilterValue } from 'web/src/modules/sportline/submodules/segment/store/utils';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
export default function useSegmentStoreFilterComposable(props) {
    const { sportSegmentsSettings, allLiveTabUpdateInterval, selectedLiveTabUpdateInterval } = props;
    const isUpdateOnlyActiveEntityEnabled = toRef(()=>sportSegmentsSettings.value.isUpdateOnlyActiveEntityEnabled);
    const apiService = useSportlineApiService();
    const selectedFilter = ref(CustomFilter.None);
    const rawFullSportsListResponse = ref(null);
    const lastUpdate = new BackgroundUpdateStopwatch({
        filterOptions: async (param)=>{
            let { silent } = param;
            const response = await apiService.loadLiveFiltersSportsTree({
                silent,
                dateTime: {
                    to: props.comingEventsKickoffPeriod.value.to,
                    from: void 0
                }
            });
            rawFullSportsListResponse.value = response || null;
            lastUpdate.update('filterOptions');
        }
    });
    /** Update only one sport/region for active tab */ const filterUpdateInterval = computed(()=>isUpdateOnlyActiveEntityEnabled.value ? selectedLiveTabUpdateInterval.value : allLiveTabUpdateInterval.value);
    const isReady = computed(()=>null !== rawFullSportsListResponse.value);
    const segmentSportFamily = computed(()=>props.sportSegmentsSettings.value.sportFamily);
    const fullSportsList = computed(()=>{
        const rawResponse = rawFullSportsListResponse.value;
        if (!rawResponse) return [];
        const list = new SportsListAdapter(rawResponse, {
            ...props.parseSportlineSettings.value,
            responseFilters: {
                league: [
                    (response)=>(response.inplay || 0) + (response.prematch || 0) > 0
                ]
            }
        }).getSports();
        return list.filter((listElement)=>listElement.leaguesCount > 0);
    });
    // full sport elements list
    const sportElementsList = computed(()=>fullSportsList.value.map((sportListElement)=>({
                key: sportListElement.sport.id,
                sport: sportListElement.sport,
                regions: [],
                marketTypes: [],
                marketsColumns: [],
                counters: sportListElement.counters
            })));
    const activeSportElement = computed(()=>{
        // find sport for segment
        if (segmentSportFamily.value) {
            const sportFamily = segmentSportFamily.value;
            const list = fullSportsList.value.// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            filter((element)=>element.sport.representation.family === sportFamily).map((sportListElement)=>({
                    key: sportListElement.sport.id,
                    sport: sportListElement.sport,
                    regions: (sportListElement.regions ?? []).map((regionListElement)=>({
                            key: regionListElement.region.id,
                            region: regionListElement.region,
                            marketTypes: [],
                            leagues: [],
                            counters: regionListElement.counters
                        })),
                    marketTypes: [],
                    marketsColumns: [],
                    counters: sportListElement.counters
                }));
            if (list.length > 1) logger.warn(`More then one "${sportFamily}" sport presented`, list.map((sportElement)=>sportElement.sport.id));
            return list?.[0] || null;
        }
        // ex. sportElementSelectedInFilter - find selected sport for filter
        const selectedName = selectedFilter.value;
        const isCustomFilterSelected = isCustomFilter(selectedName);
        if (isCustomFilterSelected) return null;
        const selectedSportItem = sportElementsList.value.find((sportElement)=>isSportFamilyEquals(sportElement.sport, selectedName));
        return selectedSportItem ?? null;
    });
    const fullDisciplinesList = computed(()=>{
        // sports pages don't have disciplines list
        if (!segmentSportFamily.value) return null;
        if (!activeSportElement.value) return null;
        return activeSportElement.value.regions.filter((regionElement)=>!isLiveCountersEmpty(regionElement) || regionElement.counters.prematch > 0);
    });
    const activeRegionElement = computed(()=>{
        if (!segmentSportFamily.value) return null;
        const selectedName = selectedFilter.value;
        const isCustomFilterSelected = isCustomFilter(selectedName);
        if (isCustomFilterSelected) return null;
        return (fullDisciplinesList.value ?? []).find((regionElement)=>regionElement.region.navigationParameters.urlName.toLowerCase() === selectedName) ?? null;
    });
    const filterOptionsIds = computed(()=>{
        if (!segmentSportFamily.value) // return all sports family
        return sportElementsList.value.map((sportElement)=>sportElement.sport.representation.family) || [];
        // return regions ids
        return fullDisciplinesList.value?.map((regionElement)=>regionElement.region.id) ?? [];
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: filterUpdateInterval
    });
    function setSelectedFilter(value) {
        const filter = value || CustomFilter.AllEvents;
        // do not update the same filter
        if (filter.toLowerCase() === selectedFilter.value.toLowerCase()) return;
        selectedFilter.value = chooseFilterValue(filter);
    }
    function onInit() {
        useSyncState(async (silent)=>{
            await syncState({
                silent
            });
        }, filterUpdateInterval);
    }
    return {
        isReady,
        filterUpdateInterval,
        selectedFilter,
        rawFullSportsListResponse,
        fullSportsList,
        fullDisciplinesList,
        activeSportElement,
        activeRegionElement,
        filterOptionsIds,
        setSelectedFilter,
        initialRequests,
        onInit,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
}
