import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, onMounted, onActivated, onUnmounted, onDeactivated } from 'vue';
import { useNeedRedirectFromTab } from 'web/src/modules/sportline/components/SportlineTab/composables';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';
import { useSportlineFavoritesTabList } from '../useSportlineFavoritesTabList';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineFavoritesTabList',
    props: {
        favoriteEventsList: {},
        defaultExpandSportKey: {},
        defaultExpandLeagueKey: {},
        isSomeFilterSelected: {
            type: Boolean
        },
        selectedRegionFamily: {},
        isActive: {
            type: Boolean
        }
    },
    emits: [
        "redirect-from-tab",
        "reset-filter"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const isActive = toRef(props, 'isActive', false);
        const isSomeFilterSelected = toRef(props, 'isSomeFilterSelected', false);
        const selectedRegionFamily = toRef(props, 'selectedRegionFamily', null);
        const defaultExpandSportKey = toRef(props, 'defaultExpandSportKey');
        const defaultExpandLeagueKey = toRef(props, 'defaultExpandLeagueKey');
        function emitResetFilter() {
            emit('reset-filter');
        }
        function emitRedirectFromTab() {
            emit('redirect-from-tab');
        }
        const { isLoaded, isNeedRedirectFromTab, isMarketTypesSelectionEnabled, onTabActivated, onTabDeactivated } = useSportlineFavoritesTabList({
            isActive,
            selectedRegionFamily
        });
        useNeedRedirectFromTab({
            isNeedRedirectFromTab
        }, {
            emitRedirectFromTab
        });
        onMounted(onTabActivated);
        onActivated(onTabActivated);
        onUnmounted(onTabDeactivated);
        onDeactivated(onTabDeactivated);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(isLoaded) ? _ctx.favoriteEventsList.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 1
                }, _renderList(_ctx.favoriteEventsList, (sportElement)=>(_openBlock(), _createBlock(SportlineSportBlock, {
                        key: sportElement.sport.id,
                        "sport-element": sportElement,
                        "is-market-types-selection-enabled": _unref(isMarketTypesSelectionEnabled),
                        "collapse-league-key": defaultExpandLeagueKey.value,
                        "expand-key": `${defaultExpandSportKey.value}-${sportElement.sport.representation.family}`,
                        "extended-block-type": _unref(ExtendedEventBlock).Favorites,
                        "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                            sportId: sportElement.sport.id
                        }),
                        "is-default-expanded": "",
                        "is-show-add-league-to-favorite": "",
                        "is-display-outright-events-enabled": "",
                        "is-league-default-expanded": "",
                        "can-add-to-favorite": "",
                        "can-add-outrights-to-favorite": ""
                    }, null, 8, [
                        "sport-element",
                        "is-market-types-selection-enabled",
                        "collapse-league-key",
                        "expand-key",
                        "extended-block-type",
                        "basis-key"
                    ]))), 128)) : isActive.value ? (_openBlock(), _createBlock(SportNoResults, {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['no-results']),
                    "sportline-is-empty": !isSomeFilterSelected.value,
                    "can-reset-filter": "",
                    onReset: emitResetFilter
                }, null, 8, [
                    "class",
                    "sportline-is-empty"
                ])) : _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VLoaderMaskedDelayed), {
                    key: 0
                }))
            ])), [
                [
                    _directive_auto_id,
                    'SportlineFavoritesTabList'
                ]
            ]);
        };
    }
});
