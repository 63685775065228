import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "loader-masked"
};
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { VLoader } from '@components/loader';
import { useVLoaderDelayed } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLoaderMaskedDelayed',
    props: {
        delay: {},
        size: {},
        color: {},
        title: {},
        hint: {},
        margin: {}
    },
    setup (__props) {
        const props = __props;
        const { loaderIsVisible, onAppear, onDisappear } = useVLoaderDelayed(props);
        onBeforeMount(onAppear);
        onBeforeUnmount(onDisappear);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(loaderIsVisible) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(VLoader), {
                    title: _ctx.title,
                    hint: _ctx.hint,
                    size: _ctx.size,
                    color: _ctx.color,
                    margin: _ctx.margin
                }, null, 8, [
                    "title",
                    "hint",
                    "size",
                    "color",
                    "margin"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'VLoaderMaskedDelayed'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
