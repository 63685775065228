import { defineStore } from 'pinia';
import { computed, watch } from 'vue';
import { getInitArrayStatesByLobbyType } from 'web/src/modules/egs/utils';
import { useEgsApi } from 'web/src/modules/egs/composables';
import useLobbyType from 'web/src/modules/lobby/composables/useLobbyType';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
const useEgsGroupsStore = defineStore('egs-groups', ()=>{
    const { lobbyType: currentLobbyType } = useLobbyType();
    const { getGroups } = useEgsApi();
    const { isLoggedIn } = useIsLoggedIn();
    const groupsByType = getInitArrayStatesByLobbyType();
    function getGroupsByLobbyType(lobbyType) {
        const group = groupsByType[lobbyType];
        if (group) return group;
        throw new Error(`No groups by lobby type: ${lobbyType}`);
    }
    const currentGroups = computed(()=>getGroupsByLobbyType(currentLobbyType.value).value);
    function setGroups(type, groups) {
        const group = groupsByType[type];
        if (!group) throw new Error(`No groups by lobby type: ${type}`);
        group.value = groups;
    }
    async function loadGroups(lobbyType) {
        let silent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        const type = lobbyType ?? currentLobbyType.value;
        const groups = await getGroups({
            type
        }, silent);
        setGroups(type, groups);
    }
    watch(isLoggedIn, ()=>{
        for (const group of Object.values(groupsByType))group.value = [];
    });
    return {
        getGroupsByLobbyType,
        loadGroups,
        currentGroups
    };
});
export default useEgsGroupsStore;
