import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { safeParseInt, sleep } from '@leon-hub/utils';
import { useIsDebugEnabled, useSaveToLocalStorage, useDebugIFrameUrl } from 'web/src/modules/sportline/submodules/debug/store/composables';
const LS_BET_DELAY_KEY = 'VIRTUAL_SPORT_BET_DELAY';
const LS_IFRAME_URL_KEY = 'VIRTUAL_SPORT_IFRAME_URL';
export const useVirtualSportDebugStore = defineStore('sportline-virtual-sport-debug', ()=>{
    const { isDebugEnabled } = useIsDebugEnabled();
    const { saveValueToLocalStorage, getValueFromLocalStorage } = useSaveToLocalStorage();
    const { iframeUrl, saveIFrameUrl, setIFrameUrl, loadIFrameUrl } = useDebugIFrameUrl({
        localStorageKey: LS_IFRAME_URL_KEY
    });
    const delayInProgress = ref(false);
    const betDelayInternal = ref(null);
    const betDelay = computed(()=>isDebugEnabled.value ? betDelayInternal.value : null);
    async function runDebugDelay() {
        if (!betDelay.value) return;
        delayInProgress.value = true;
        await sleep(betDelay.value);
        delayInProgress.value = false;
    }
    if (isDebugEnabled.value) {
        const rawBetDelay = getValueFromLocalStorage(LS_BET_DELAY_KEY);
        if (rawBetDelay) betDelayInternal.value = safeParseInt(rawBetDelay);
    }
    loadIFrameUrl();
    return {
        isDebugEnabled,
        delayInProgress,
        betDelay,
        // delay
        setBetDelay (value) {
            betDelayInternal.value = value ? safeParseInt(value) : null;
        },
        saveBetDelay () {
            saveValueToLocalStorage(LS_BET_DELAY_KEY, betDelayInternal.value);
        },
        runDebugDelay,
        // url
        iframeUrl,
        setIFrameUrl,
        saveIFrameUrl
    };
});
