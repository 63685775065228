import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { MarketTypeSign } from 'web/src/modules/sportline/enums';
import { defaultMarketColumnsNames } from 'web/src/modules/sportline/constants';
export function useColumnsNames(props) {
    const { isColumnNamesDisplayDisabled, runnersPlaces, actualMarketType, defaultColumnSign, defaultColumnSize } = props;
    const { $translate } = useI18n();
    const mainMarketColumnNames = computed(()=>{
        if (isColumnNamesDisplayDisabled.value) return [];
        const sign = actualMarketType.value?.sign ?? defaultColumnSign.value;
        if (!sign) // No info about selected column sign
        return [];
        switch(sign){
            case MarketTypeSign.HomeAway:
                return [
                    '1',
                    '2'
                ];
            case MarketTypeSign.HomeDrawAway:
                return [
                    '1',
                    'X',
                    '2'
                ];
            case MarketTypeSign.YesNo:
                return [
                    $translate('WEB2_MAIN_MARKET_YES').value,
                    $translate('WEB2_MAIN_MARKET_NO').value
                ];
            case MarketTypeSign.UnderOver:
                return [
                    $translate('WEB2_MAIN_MARKET_OVER').value,
                    $translate('WEB2_MAIN_MARKET_UNDER').value
                ];
            case MarketTypeSign.OddEven:
                return [
                    $translate('WEB2_MAIN_MARKET_EVEN').value,
                    $translate('WEB2_MAIN_MARKET_ODD').value
                ];
            default:
                return defaultMarketColumnsNames[runnersPlaces.value.length || defaultColumnSize.value];
        }
    });
    return {
        mainMarketColumnNames
    };
}
