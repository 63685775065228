import { useCmsStore } from 'web/src/modules/cms/store';
export default function useAccessAndroidAppRoutePage() {
    async function created() {
        await useCmsStore().fetchCmsContentTranslation({
            key: 'WEB2_ANDROID_APP_INSTALL'
        });
    }
    return {
        created
    };
}
