import { normalizeError } from '@leon-hub/errors';
import { applyEventsRunnersOddsChanges, isSportEventResponseIncludesChanges } from 'web/src/modules/sportline/utils/rest/claimToUpdate';
import { ApplyEventRunnersOddsChangesError } from 'web/src/modules/sportline/errors/core';
export default function createApplyRunnersOddsChanges(getResponse, setResponse) {
    return function applyOddsChanges(changes) {
        try {
            const rawResponse = getResponse();
            if (!rawResponse) return;
            const hasChanges = isSportEventResponseIncludesChanges(rawResponse, changes);
            if (!hasChanges) return;
            setResponse(applyEventsRunnersOddsChanges(rawResponse, changes));
        } catch (rawError) {
            throw new ApplyEventRunnersOddsChangesError({
                originalError: normalizeError(rawError)
            });
        }
    };
}
