export default function getHours(timeLeft, displayedTimeLeft, $t, includeDaysLeft) {
    if (!timeLeft || !displayedTimeLeft) return '';
    const { days, hours } = timeLeft;
    if (days <= 0 && hours <= 0) return '';
    if (includeDaysLeft) {
        const hoursAndDays = 24 * days + hours;
        return `${String(hoursAndDays)} ${$t('WEB2_HOURS_EXTRA_SHORT').value}.`;
    }
    return `${String(hours)} ${$t('WEB2_HOURS_EXTRA_SHORT').value}.`;
}
