import { DateInputCustomDate } from 'web/src/components/Input/enums';
import DateTime from 'web/src/utils/DateTime';
export function getMaxDate(max) {
    if ('string' == typeof max) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (max === DateInputCustomDate.Now) return DateTime.now().toTimestamp();
        return;
    }
    return max;
}
