import { computed } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function usePaymentsListItem(props) {
    const theme = useTheme();
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const logoLocation = computed(()=>{
        const themeName = theme.isDark.value ? 'dark' : 'light';
        return `${(props.paymentSystem?.logoSrcThemed || '').replace(/^\//, '').replace('{theme}', themeName)}`;
    });
    const hintMessage = computed(()=>{
        if (props.enrollmentTimeInHint) return props.paymentSystem.enrollmentTime || '';
        if (props.paymentSystem.minAmount?.amount) {
            if (props.paymentSystem.maxAmount?.amount) // eslint-disable-next-line max-len
            return `${formatMoney(props.paymentSystem.minAmount.amount)} - ${formatMoney(props.paymentSystem.maxAmount.amount)}`;
            return `${$translate('WEB2_MIN_STAKE').value}: ${formatMoney(props.paymentSystem.minAmount.amount)}`;
        }
        return '';
    });
    return {
        logoLocation,
        hintMessage
    };
}
