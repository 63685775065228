export var FormSchemaTypes;
var FormSchemaTypes1;
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ (FormSchemaTypes1 = FormSchemaTypes || (FormSchemaTypes = {}))["String"] = "string", FormSchemaTypes1["Number"] = "number", FormSchemaTypes1["Integer"] = "integer", FormSchemaTypes1["Object"] = "object", FormSchemaTypes1["Array"] = "array", FormSchemaTypes1["Boolean"] = "boolean", FormSchemaTypes1["Null"] = "null";
export const isFormTypeString = (property)=>"string" === `${property?.type}`.toLowerCase();
export const isFormTypeNumber = (property)=>"number" === `${property?.type}`.toLowerCase();
export const isFormTypeBoolean = (property)=>"boolean" === `${property?.type}`.toLowerCase();
export const isFormTypeArray = (property)=>"array" === `${property?.type}`.toLowerCase();
export const isFormTypeObject = (property)=>"object" === `${property?.type}`.toLowerCase();
export const isFormPrimitiveType = (property)=>{
    const propertyType = `${property.type}`.toLowerCase();
    switch(propertyType){
        case "array":
        case "object":
            return false;
        default:
            return true;
    }
};
