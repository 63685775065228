import { isString } from '@leon-hub/guards';
export function isSportIdEquals(sport, id) {
    if (!sport || !id) return false;
    if (isString(sport)) return sport === id;
    return id === sport.id;
}
export function isRegionIdEquals(region, id) {
    if (!region || !id) return false;
    if (isString(region)) return region === id;
    return id === region.id || id === region.name;
}
export function isLeagueIdEquals(league, id) {
    if (!league || !id) return false;
    if (isString(league)) return league === id;
    return id === league.id;
}
