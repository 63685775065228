import { computed, toRef } from 'vue';
import usePaymentsMaxValueFastChoiceDeposit from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoiceDeposit';
import { useBalance } from 'web/src/modules/customer/store/composables';
export default function usePaymentsMaxValueFastChoice(props) {
    const isDeposit = toRef(props, 'isDeposit');
    const maxAmount = toRef(props, 'maxAmount');
    const { balance } = useBalance();
    const { maxValueFastChoiceDeposit } = usePaymentsMaxValueFastChoiceDeposit(props);
    const maxValueFastChoice = computed(()=>{
        if (isDeposit.value) return maxValueFastChoiceDeposit.value;
        if (maxAmount.value) return balance.value < maxAmount.value ? balance.value : maxAmount.value;
        return balance.value;
    });
    return {
        maxValueFastChoice
    };
}
