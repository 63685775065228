import { computed } from 'vue';
import { useRouter } from 'vue-router';
export default function useVRouterLink(props) {
    const router = useRouter();
    const target = computed(()=>router.resolve(props.to));
    const href = computed(()=>target.value.href);
    return {
        href,
        target
    };
}
