import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { isArray } from '@leon-hub/guards';
let BackgroundUpdateStopwatch = class BackgroundUpdateStopwatch {
    setLastUpdate(timers) {
        let time = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : Date.now();
        // eslint-disable-next-line no-nested-ternary
        const keys = timers ? isArray(timers) ? timers : [
            timers
        ] : Object.keys(this.lastUpdate);
        for (const timer of keys)this.lastUpdate[timer] = time;
    }
    get syncActionsNames() {
        return Object.keys(this.lastUpdate);
    }
    /**
   * Update last update
   * @param {T} timers
   */ update(timers) {
        this.setLastUpdate(timers, Date.now());
    }
    invalidate(timers) {
        this.setLastUpdate(timers, 0);
    }
    updateGroup(timer, group) {
        const lastUpdates = this.lastGroupUpdate[timer] || {};
        const groups = isArray(group) ? group : [
            group
        ];
        const now = Date.now();
        for (const name of groups)lastUpdates[name] = now;
        this.lastGroupUpdate[timer] = lastUpdates;
    }
    async forceCallSyncAction(timer) {
        for(var _len = arguments.length, payload = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            payload[_key - 1] = arguments[_key];
        }
        await this.syncActions[timer]?.(...payload);
    }
    async forceCallGroupSyncAction(timer, group) {
        for(var _len = arguments.length, payload = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++){
            payload[_key - 2] = arguments[_key];
        }
        await this.syncActions[timer]?.(...payload, group);
    }
    /**
   * Call sync action once after the interval
   * @param {T[number]} timer
   * @param {number} interval
   * @param payload
   * @return {boolean}
   */ async callSyncAction(timer, interval) {
        for(var _len = arguments.length, payload = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++){
            payload[_key - 2] = arguments[_key];
        }
        if (this.isIntervalElapsed(timer, interval)) {
            await this.syncActions[timer]?.(...payload);
            return true;
        }
        return false;
    }
    async callGroupSyncAction(timer, group, interval) {
        for(var _len = arguments.length, payload = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++){
            payload[_key - 3] = arguments[_key];
        }
        if (this.isGroupTimerMoreThen(timer, group, interval)) {
            await this.syncActions[timer]?.(...payload, group);
            return true;
        }
        return false;
    }
    /**
   * true if interval elapsed
   * @param {T[number]} timer
   * @param {number} interval
   * @return {boolean}
   */ isIntervalElapsed(timer, interval) {
        const now = Date.now();
        return now - this.lastUpdate[timer] > interval;
    }
    isGroupTimerMoreThen(timer, group, interval) {
        const lastUpdate = this.lastGroupUpdate[timer][group];
        if (!lastUpdate) return true;
        const now = Date.now();
        return now - lastUpdate > interval;
    }
    constructor(syncActions = {}){
        _define_property(this, "syncActions", void 0);
        _define_property(this, "lastUpdate", void 0);
        _define_property(this, "lastGroupUpdate", void 0);
        this.syncActions = syncActions;
        this.lastUpdate = {};
        this.lastGroupUpdate = {};
        this.setLastUpdate(Object.keys(this.syncActions), 0);
    }
};
export { BackgroundUpdateStopwatch as default };
