import { computed, ref } from 'vue';
import { isNumber } from '@leon-hub/guards';
import { useI18n } from 'web/src/modules/i18n/composables';
import useCountdown from 'web/src/modules/core/utils/countdown/useCountdown';
import useSafeTimeBus from 'web/src/modules/core/composables/time-bus/useSafeTimeBus';
import createUniqueId from 'web/src/utils/createUniqueId';
import copyTextToClipboard from 'web/src/utils/browser/copyTextToClipboard';
import { TabId } from '../enums/TabId';
import { isTabId } from '../guards/isTabId';
export function useCryptoPaymentData(props) {
    const { $translate } = useI18n();
    const { now } = useSafeTimeBus(1000);
    const uniqueId = computed(()=>createUniqueId(props.name ?? ''));
    const switcherOptions = computed(()=>[
            {
                id: TabId.Details,
                label: $translate('WEB2_PAYMENTS_DETAILS').value
            },
            {
                id: TabId.QrCode,
                label: $translate('WEB2_PAYMENTS_QR_CODE').value
            }
        ]);
    const selectedTab = ref(TabId.Details);
    const isTooltipShown = ref(false);
    async function handleCopyToClipboard(value) {
        await copyTextToClipboard(value);
        if (!isTooltipShown.value) {
            isTooltipShown.value = true;
            setTimeout(()=>{
                isTooltipShown.value = false;
            }, 1500);
        }
    }
    const selectTab = (value)=>{
        if (value) {
            isTabId(value);
            selectedTab.value = value;
        }
    };
    const activeTab = computed(()=>{
        if (!props.qrCodeSrc) return TabId.Details;
        return selectedTab.value;
    });
    const showExpires = computed(()=>isNumber(props.expires));
    const safeExpires = computed(()=>{
        const safeValue = showExpires.value ? props.expires : 0;
        isNumber(safeValue);
        return 1000 * safeValue;
    });
    const { displayedTimeLeft, timeLeftValue } = useCountdown(safeExpires, now, {
        displaySecondsOnMaxMinutes: ref(false),
        useLeadingZero: ref(true)
    });
    const timeLeft = computed(()=>{
        const { hours, minutes, seconds } = displayedTimeLeft.value;
        const days = timeLeftValue.value.days > 0 ? `${displayedTimeLeft.value.days}:` : '';
        return `${days}${hours}:${minutes}:${seconds}`;
    });
    const timerIsEnded = computed(()=>{
        if (!showExpires.value) return false;
        const { days, hours, minutes, seconds } = timeLeftValue.value;
        return !days && !hours && !minutes && !seconds;
    });
    return {
        uniqueId,
        switcherOptions,
        activeTab,
        selectTab,
        showExpires,
        timeLeft,
        timerIsEnded,
        isTooltipShown,
        handleCopyToClipboard
    };
}
