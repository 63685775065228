import { roundToDivisible } from '../../math';
/**
 * Get customer's timezone offset for system time rounded to 0.5
 * (For any customer's timezone we must get same offset for same time)
 * For example:
 * Actual time is 12.00 at GMT+3
 * User can select any timezone in system settings but
 * If he has time set to 19.00 we got +9 because right now 19.00 in Tokyo (GMT+9)
 * Same for 01.00 we got -9 because right now in Anchorage 01.00 (GMT-9)
 * And we got +10.30 for time 20.30 same to Adelaide (GTM+10.30)
 *
 * Examples
 * offset +3 |day 0| shift 0 = 3
 * offset +3 |day+1| shift -31.5 = 10.5
 * offset +3 |day 0| shift -7.5  = 10.5
 * offset +3 |day-1| shift 16.5 = 10.5
 * offset +3 |day+1| shift -17 = -4
 * offset +3 |day 0| shift 7  = -4
 * offset +3 |day-1| shift 31 = -4
 */ export function getCustomerTimezoneOffset(timeShift, timezoneOffset) {
    const offset = -timezoneOffset / 60;
    const shift = timeShift / 60 / 60 / 1000;
    // round to 0.5
    const roundedShift = roundToDivisible(shift, 0.5);
    // shift is not changed every 24 hour
    const oneDayShift = Math.abs(roundedShift % 24);
    // size of shift for current day (abs of +-12)
    const todayShift = oneDayShift > 12 ? 24 - oneDayShift : oneDayShift;
    // sign changed between 12 hours lines
    const shiftSign = oneDayShift > 12 ? Math.sign(roundedShift) : -Math.sign(roundedShift);
    const result = shiftSign * todayShift + offset;
    return Math.abs(result) > 12 ? -12 * Math.sign(result) + result % 12 : result;
}
