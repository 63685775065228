import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import PinButton from '../PinButton/PinButton.vue';
import { usePinButtons } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PinButtons',
    props: {
        pinButtonsListInitial: {},
        visible: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "keyup",
        "keydown"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { activeButton, pinButtonsList, onKeyDown, onKeyUp } = usePinButtons(props, emits);
        function onClick(value) {
            emits('click', value);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['pin-buttons'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['pin-buttons__wrapper'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pinButtonsList), (button)=>(_openBlock(), _createBlock(PinButton, {
                            key: button.value,
                            value: button.value,
                            "is-filled": button.isFilled,
                            "is-active": button.value === _unref(activeButton) || button.isActive,
                            onClick: ($event)=>onClick(button.value),
                            onTouchstart: _unref(onKeyUp),
                            onTouchend: _unref(onKeyDown)
                        }, {
                            default: _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(button.title) + " ", 1),
                                    button.iconName ? (_openBlock(), _createBlock(_unref(VIcon), {
                                        key: 0,
                                        name: button.iconName,
                                        size: _unref(IconSize).SIZE_24
                                    }, null, 8, [
                                        "name",
                                        "size"
                                    ])) : _createCommentVNode("", true)
                                ]),
                            _: 2
                        }, 1032, [
                            "value",
                            "is-filled",
                            "is-active",
                            "onClick",
                            "onTouchstart",
                            "onTouchend"
                        ]))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PinButtons'
                ]
            ]);
        };
    }
});
