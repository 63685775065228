import { ref } from 'vue';
import { RegistrationStage } from 'web/src/modules/registration/enums';
export function useRegistrationStage() {
    const stage = ref(RegistrationStage.Main);
    const previousStages = ref([]);
    function goToStage(target) {
        const currentStage = stage.value;
        if (target.clearHistory) previousStages.value = [];
        else previousStages.value = [
            ...previousStages.value,
            currentStage
        ];
        stage.value = target.stage;
    }
    function goToPreviousStage() {
        const stages = [
            ...previousStages.value
        ];
        const lastStage = stages.pop();
        previousStages.value = stages;
        if (lastStage) stage.value = lastStage;
    }
    return {
        stage,
        previousStages,
        goToStage,
        goToPreviousStage,
        resetStages () {
            stage.value = RegistrationStage.Main;
            previousStages.value = [];
        }
    };
}
