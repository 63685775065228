import { computed, ref, toRef, customRef, watch } from 'vue';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useTimeBus from 'web/src/modules/core/composables/time-bus/useTimeBus';
/** How long is the preview in seconds. default is 15 */ /** Kill old data after seconds. Default is a week (604800 = 60 * 60 * 24 * 7) */ export const localStorageKey = 'sportline-stream-preview';
function createVisitTimestampRef(sportlineEventId, now, clearAfter) {
    const localStorageManager = useLocalStorageManager();
    function getPreviewTimestampMapFromStorage() {
        const storedState = localStorageManager.getItem(localStorageKey);
        return storedState ? JSON.parse(storedState) : {};
    }
    const previewTimestampMap = ref(getPreviewTimestampMapFromStorage());
    function setValueToMap(key, value, map) {
        const newMap = {
            ...map
        };
        if (void 0 !== value) newMap[key] = value;
        else delete newMap[key];
        return newMap;
    }
    function clearOldValuesInMap(map) {
        const newMap = {
            ...map
        };
        for (const key of Object.keys(newMap)){
            const value = newMap[key];
            if (now.value - value >= 1000 * clearAfter.value) delete newMap[key];
        }
        return newMap;
    }
    return customRef((track, trigger)=>({
            get () {
                track();
                const id = sportlineEventId.value;
                if (!id) return;
                return previewTimestampMap.value[id];
            },
            set (value) {
                const id = sportlineEventId.value;
                if (!id) return;
                // get value from the local storage again to keep synchronized several composables at once
                previewTimestampMap.value = clearOldValuesInMap(setValueToMap(id, value, getPreviewTimestampMapFromStorage()));
                localStorageManager.setItem(localStorageKey, JSON.stringify(previewTimestampMap.value));
                trigger();
            }
        }));
}
export function useStreamPreviewStoreComposable(props) {
    const sportlineEventId = toRef(props.sportlineEventId);
    const previewDuration = toRef(props.previewDuration ?? 15);
    const clearAfter = toRef(props.clearAfter ?? 604800);
    const { isLoggedIn } = useIsLoggedIn();
    const { now, isRunning, start, stop, forceUpdateNow } = useTimeBus();
    const visitTime = createVisitTimestampRef(sportlineEventId, now, clearAfter);
    const doShowStreamPreviewInfo = computed(()=>!isLoggedIn.value && void 0 !== visitTime.value && now.value - visitTime.value >= 1000 * previewDuration.value);
    let stopWatch;
    function stopWatchPreviewShown() {
        stop();
        stopWatch?.();
        stopWatch = void 0;
    }
    function startWatchPreviewShown() {
        if (!isRunning.value) start();
        stopWatch = stopWatch ?? watch([
            doShowStreamPreviewInfo,
            sportlineEventId
        ], (param, param1)=>{
            let [isShown, id] = param, [, oldId] = param1;
            if (oldId && id !== oldId) {
                stopWatchPreviewShown();
                return;
            }
            if (isShown) stopWatchPreviewShown();
        }, {
            immediate: true
        });
    }
    return {
        visitTime,
        isRunningPreviewTimeout: isRunning,
        doShowStreamPreviewInfo,
        startPreview () {
            forceUpdateNow();
            // trigger clearing old data
            visitTime.value = visitTime.value ?? now.value;
            if (!doShowStreamPreviewInfo.value) startWatchPreviewShown();
        },
        stopPreview () {
            stop();
        }
    };
}
