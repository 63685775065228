import { toRef, computed, watch } from 'vue';
import { defineStore } from 'pinia';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useSportlinePageIdentifier } from 'web/src/modules/sportline/store/composables';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { useEventDetailsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useAwaitConditionQueue from 'web/src/utils/store/composables/useAwaitConditionQueue';
import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { useSportEventWidgetConfig, useActiveSlideStoreComposable, useRelatedEventsStoreComposable, useMarketsLayoutTypeStoreComposable, useSportlineEventDetailsDataStoreComposable, useSportlineEventDetailsMiniBoardStoreComposable, useSportlineEventDetailsRequestStatusBehaviours } from './composables';
import { createOnInitBehaviourFactory, createStayingOnPageBehaviourFactory } from './utils';
export const useSportlineEventDetailsStore = defineStore('sportline-event-details', ()=>{
    const { pageIdentifier, setPageIdentifier } = useSportlinePageIdentifier();
    const { onInit: onGlobalSportlineEventsInit } = useGlobalSportlineEvents();
    const { timeout: eventDetailsUpdateInterval } = useEventDetailsSportlineUpdateTimeout();
    const sportlineEventStatisticStore = useSportlineEventStatisticStore();
    const isSportEventStatisticEnabled = toRef(sportlineEventStatisticStore, 'isSportEventStatisticEnabled');
    const isPostMatchStatisticLoaded = toRef(sportlineEventStatisticStore, 'isPostMatchStatisticLoaded');
    const hasPostMatchStatistics = toRef(sportlineEventStatisticStore, 'hasPostMatchStatistics');
    const { startLoadingPostMatchStatistics } = sportlineEventStatisticStore;
    const customerFavoritesService = useCustomerFavoritesService();
    const requestStatusBehaviours = useSportlineEventDetailsRequestStatusBehaviours();
    const { isLoaded, isShowingLoadingIndicator, rawSportEventDetails, sportEventDetailsPreview, sportEventDetails, sportEventDetailsId, sportEventDetailsLoadedId, sportElement, sport, region, league, setDetailsStatus, clearDetailsMarketsList, setRawSportEventDetails, fetchDetails, onInit: onEventDetailsDataInit, streamInfo } = useSportlineEventDetailsDataStoreComposable({
        pageIdentifier,
        fireRequestStatus: requestStatusBehaviours.fireRequestStatus
    }, {
        sportEventDetailsUpdated (value) {
            sportlineEventStatisticStore.setCurrentLoadedMatchIdentifier(value);
        }
    });
    const { clearStreamInfo, reloadStream, ...publicStreamInterface } = streamInfo;
    const { relatedSportListElement, leagueEventsList, setBackgroundUpdateEnabled: setRelatedEventsBackgroundUpdateEnabled, onInit: onRelatedEventsInit } = useRelatedEventsStoreComposable({
        pageIdentifier,
        sportEventDetails,
        sportEventDetailsId
    });
    const { isMiniBoardShown, setIsMiniBoardShown } = useSportlineEventDetailsMiniBoardStoreComposable();
    const { sportEventWidgetConfig, liveWidgetType } = useSportEventWidgetConfig({
        sportEventDetails
    });
    const { defaultLayoutType, setDefaultLayoutType } = useMarketsLayoutTypeStoreComposable();
    const { defaultSwiperSlideId, restoreSwiperSlideId, saveSwiperSlideId } = useActiveSlideStoreComposable();
    const { awaitCondition: awaitStateIsLoaded } = useAwaitConditionQueue({
        source: 'Sportline event details is loaded',
        value: isLoaded,
        condition: (value)=>value
    });
    const { awaitCondition: awaitLeavePage } = useAwaitConditionQueue({
        source: 'Leave sportline event details page',
        value: pageIdentifier,
        condition: (value)=>!value.sportEventId
    });
    const { awaitCondition: awaitPostMatchStatisticIsLoaded } = useAwaitConditionQueue({
        source: 'Sportline event details statistic is loaded',
        value: isPostMatchStatisticLoaded,
        condition: (value)=>value
    });
    /** Return true if we have some content to display e.g. loaded event, preview or post statistic */ const isReadyToDisplayContent = computed(()=>{
        // check rawSportEventDetails === false before isLoaded
        // because false event details means load missing event
        if (false === rawSportEventDetails.value) return !isSportEventStatisticEnabled.value || isPostMatchStatisticLoaded.value;
        if (isLoaded.value) return true;
        const preview = sportEventDetailsPreview.value;
        const targetId = pageIdentifier.value.sportEventId;
        return !!preview?.sportEvent && preview.sportEvent.id === targetId;
    });
    const lastUpdate = new BackgroundUpdateStopwatch({
        eventDetails: async (param)=>{
            let { silent, skip } = param;
            // skip updates for set background update (reloaded by initial requests)
            if (skip) return;
            if (false !== rawSportEventDetails.value) // do not update event data after Missing status
            await fetchDetails({
                silent
            });
            lastUpdate.update('eventDetails');
        }
    });
    const { initialRequests: initialLifeCycleRequests, syncState, setBackgroundUpdateEnabled: setLifeCycleBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: eventDetailsUpdateInterval,
        onEnableChanged (value) {
            setRelatedEventsBackgroundUpdateEnabled(value);
            sportlineEventStatisticStore.setBackgroundUpdateEnabled(value);
            customerFavoritesService.setIsBackgroundIdsUpdateAvailable(CustomerFavoritesIdsBackgroundUpdateKey.DETAILS, value);
        }
    });
    function clearDetails() {
        setRawSportEventDetails(null);
        sportlineEventStatisticStore.clearStatistic();
        clearStreamInfo();
    }
    async function initialRequests() {
        if (sportEventDetailsLoadedId.value === pageIdentifier.value.sportEventId) // this event already loaded, will be used sync background
        return;
        await Promise.all([
            initialLifeCycleRequests(),
            sportlineEventStatisticStore.initialRequests()
        ]);
    }
    function setBackgroundUpdateEnabled(value) {
        // we will skip reloading for the initial set background requests true
        const skipUpdate = sportEventDetailsLoadedId.value !== pageIdentifier.value.sportEventId;
        const payload = {
            eventDetails: {
                skip: skipUpdate
            }
        };
        return setLifeCycleBackgroundUpdateEnabled(value, payload);
    }
    // on init
    onGlobalSportlineEventsInit();
    restoreSwiperSlideId();
    onEventDetailsDataInit();
    onRelatedEventsInit();
    useSyncState((silent)=>syncState({
            silent
        }), eventDetailsUpdateInterval);
    const createStayingOnPageBehaviour = createStayingOnPageBehaviourFactory(requestStatusBehaviours, {
        setDetailsStatus,
        clearDetailsMarketsList,
        loadSportMatchStatistics: startLoadingPostMatchStatistics
    });
    const createOnInitBehaviour = createOnInitBehaviourFactory(requestStatusBehaviours, {
        createStayingOnPageBehaviour,
        awaitPostMatchStatisticIsLoaded,
        setRawSportEventDetails,
        hasPostMatchStatistics
    });
    watch(pageIdentifier, (value, oldValue)=>{
        setBackgroundUpdateEnabled(!!value.sportEventId);
        if (!value.sportEventId) setRawSportEventDetails(null);
        if (oldValue?.sportEventId) requestStatusBehaviours.removeBehaviours(oldValue.sportEventId);
    });
    return {
        ...publicStreamInterface,
        relatedSportListElement,
        leagueEventsList,
        pageIdentifier,
        setPageIdentifier,
        isLoaded,
        isShowingLoadingIndicator,
        sportEventDetailsPreview,
        sportEventDetails,
        sportElement,
        sport,
        region,
        league,
        isMiniBoardShown,
        setIsMiniBoardShown,
        sportEventWidgetConfig,
        reloadStream,
        defaultLayoutType,
        setDefaultLayoutType,
        defaultSwiperSlideId,
        saveSwiperSlideId,
        awaitStateIsLoaded,
        awaitLeavePage,
        awaitPostMatchStatisticIsLoaded,
        clearDetails,
        isReadyToDisplayContent,
        liveWidgetType,
        setBackgroundUpdateEnabled,
        initialRequests,
        createOnInitBehaviour
    };
});
