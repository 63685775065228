import { computed } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
import { getLogoUrl } from './utils';
export default function useVBrand(props) {
    const { isLight } = useTheme();
    const isConstColor = computed(()=>props.isHeader);
    const logoUrl = computed(()=>getLogoUrl({
            isSimplified: props.isSimplifiedLogo,
            // eslint-disable-next-line no-nested-ternary
            isLight: isConstColor.value ? props.isDarkIcon : !!props.isDarkIcon || !props.isDark && isLight.value
        }));
    return {
        logoUrl
    };
}
