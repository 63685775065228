import { isNull, isNumber, isString, isUndefined, isValidInterface } from '@leon-hub/guards';
export const isCoreRegionResponseFieldGuards = {
    id: isNumber,
    name: isString,
    nameDefault: isString,
    url: isString,
    family: [
        isUndefined,
        isNull,
        isString
    ]
};
export function isCoreRegionResponse(value) {
    return isValidInterface(value, isCoreRegionResponseFieldGuards);
}
