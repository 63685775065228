import { SportlineType } from 'web/src/modules/sportline/enums';
export function createSportEventsCounters() {
    let live = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0, prematch = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0, liveOutright = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0, outright = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : 0;
    return {
        live,
        prematch,
        liveOutright,
        outright,
        total: live + prematch + liveOutright + outright
    };
}
export function addSportlineEventToCounters(counters, sportEvent) {
    return createSportEventsCounters(counters.live + Number(sportEvent.type === SportlineType.Live && !sportEvent.isOutright), counters.prematch + Number(sportEvent.type === SportlineType.Prematch && !sportEvent.isOutright), counters.liveOutright + Number(sportEvent.type === SportlineType.Live && sportEvent.isOutright), counters.outright + Number(sportEvent.type === SportlineType.Prematch && sportEvent.isOutright));
}
export function addCountersToCounters(first, second) {
    return createSportEventsCounters(first.live + second.live, first.prematch + second.prematch, first.liveOutright + second.liveOutright, first.outright + second.outright);
}
export function getSportEventElementsListCounters(list) {
    return list.reduce((counters, sportEventElement)=>addSportlineEventToCounters(counters, sportEventElement.sportEvent), createSportEventsCounters());
}
export function reduceListWithSportEventsCounters(elements) {
    return elements.reduce((counters, element)=>addCountersToCounters(counters, element.counters), createSportEventsCounters());
}
export function getLeagueElementEventsCounters(leagueElement) {
    return getSportEventElementsListCounters([
        ...leagueElement.sportEvents,
        ...leagueElement.outrightEvents
    ]);
}
export function getRegionElementEventsCounters(regionElement) {
    return regionElement.leagues.reduce((counters, leagueElement)=>{
        const result = getLeagueElementEventsCounters(leagueElement);
        return addCountersToCounters(counters, result);
    }, createSportEventsCounters());
}
export function getSportElementEventsCounters(sportElement) {
    return sportElement.regions.reduce((counters, regionElement)=>{
        const result = getRegionElementEventsCounters(regionElement);
        return addCountersToCounters(counters, result);
    }, createSportEventsCounters());
}
