import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import { useCasinoSearchLobby } from 'web/src/modules/search/submodules/casino/views/CasinoSearchLobby/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSearchLobby',
    props: {
        isSport: {
            type: Boolean
        },
        shouldReplaceGameRoutes: {
            type: Boolean
        }
    },
    emits: [
        "game-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { lobbyType, skeletons, emitGameClick } = useCasinoSearchLobby(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(LobbyBlock), {
                "test-el": "casino-search-lobby",
                "lobby-type": _unref(lobbyType),
                "is-sport": _ctx.isSport,
                "should-replace-game-routes": _ctx.shouldReplaceGameRoutes,
                skeletons: _unref(skeletons),
                "is-modal": "",
                "no-background": "",
                onGameClick: _unref(emitGameClick)
            }, null, 8, [
                "lobby-type",
                "is-sport",
                "should-replace-game-routes",
                "skeletons",
                "onGameClick"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoSearchLobby'
                ]
            ]);
        };
    }
});
