import { computed, ref } from 'vue';
import { createEmptyMarketGroupForMarket, getGroupIdForMarket, getOpenStateKeyForGroup } from 'web/src/modules/sportline/views/markets-list/utils';
export function useMarketsListGroups(props) {
    const { isOutright, allMarketsList, isAllTabSelected, activeMarketTypesIds, closedMarketGroups, defaultMarketsLimit } = props;
    const isMoreMarketsClicked = ref(false);
    const groups = computed(()=>{
        const result = [];
        let lastMarketGroup = null;
        // eslint-disable-next-line unicorn/no-array-for-each
        allMarketsList.value.forEach((market, index)=>{
            if (!isAllTabSelected.value && !activeMarketTypesIds.value.includes(market.type.id)) return;
            const id = getGroupIdForMarket(market);
            if (!lastMarketGroup || lastMarketGroup.id !== id) {
                if (lastMarketGroup) result.push(lastMarketGroup);
                lastMarketGroup = createEmptyMarketGroupForMarket(market);
                const groupKey = getOpenStateKeyForGroup(lastMarketGroup);
                lastMarketGroup.displayTitle = !isOutright.value || index > 0;
                lastMarketGroup.isOpened = !closedMarketGroups.value.includes(groupKey);
            }
            lastMarketGroup.hasZeroMargin = lastMarketGroup.hasZeroMargin || market.hasZeroMargin;
            lastMarketGroup.markets.push(market);
        });
        if (lastMarketGroup) result.push(lastMarketGroup);
        return result;
    });
    const isMoreMarketButtonVisible = computed(()=>defaultMarketsLimit.value > 0 && !isMoreMarketsClicked.value && defaultMarketsLimit.value < groups.value.length);
    const filteredGroups = computed(()=>{
        if (!isMoreMarketButtonVisible.value) return groups.value;
        return groups.value.slice(0, defaultMarketsLimit.value);
    });
    const oddGroups = computed(()=>filteredGroups.value.filter((g, index)=>!(index % 2)));
    const evenGroups = computed(()=>filteredGroups.value.filter((g, index)=>index % 2));
    const hasAnyGroups = computed(()=>groups.value.length > 0);
    return {
        isMoreMarketsClicked,
        isMoreMarketButtonVisible,
        filteredGroups,
        oddGroups,
        evenGroups,
        hasAnyGroups
    };
}
