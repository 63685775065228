import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, mergeProps as _mergeProps, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { Tag } from 'web/src/components/_shared';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { useVBalance } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VBalance',
    props: {
        value: {},
        isLowBalance: {
            type: Boolean
        },
        tag: {
            default: Tag.DIV
        },
        isAddButtonAsCurrency: {
            type: Boolean
        },
        isBalanceHidden: {
            type: Boolean
        },
        isBigIcon: {
            type: Boolean
        },
        isHeader: {
            type: Boolean
        },
        isEgsGamePage: {
            type: Boolean
        },
        isSumOfBalancesEnabled: {
            type: Boolean
        }
    },
    emits: [
        "top-up",
        "click-button-gift"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { balanceValue, currencyIconProps, mainTag } = useVBalance(props);
        function emitTopUp() {
            emit('top-up');
        }
        function emitClickButtonGift() {
            emit('click-button-gift');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
                ref_key: "mainTag",
                ref: mainTag,
                class: _normalizeClass({
                    [_ctx.$style['balance']]: true,
                    [_ctx.$style['balance--low']]: _ctx.isLowBalance,
                    [_ctx.$style['balance--is-deposit-hidden']]: _ctx.isBalanceHidden,
                    [_ctx.$style['balance--is-header']]: _ctx.isHeader && "1",
                    [_ctx.$style['balance--game']]: _ctx.isEgsGamePage
                }),
                onClick: emitTopUp
            }, {
                default: _withCtx(()=>[
                        _ctx.isSumOfBalancesEnabled && !_ctx.isBalanceHidden ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['balance__gift']),
                            onClick: _withModifiers(emitClickButtonGift, [
                                "stop"
                            ])
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: _unref(IconName).PROMOS,
                                size: _unref(IconSize).SIZE_16,
                                class: _normalizeClass(_ctx.$style['balance__gift-icon'])
                            }, null, 8, [
                                "name",
                                "size",
                                "class"
                            ])
                        ], 2)) : _createCommentVNode("", true),
                        _ctx.isBalanceHidden ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['balance__text'])
                        }, _toDisplayString(_unref(balanceValue)), 3)),
                        (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 3
                        }, _unref(currencyIconProps), {
                            class: _ctx.$style['balance__button'],
                            onClick: _withModifiers(emitTopUp, [
                                "stop"
                            ])
                        }), null, 16, [
                            "class"
                        ]))
                    ]),
                _: 1
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'VBalance'
                ]
            ]);
        };
    }
});
