import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { useSportlinePendingApi } from './useSportlinePendingApi';
export default function useSportlineApiLoadLeagueEvents(props) {
    const { tryRequestWithCTag, defaultRestApiFlags, isLiveOutrightsEnabled } = props;
    const { loadEvents: loadLeagueEvents } = useSportlinePendingApi({
        tryRequestWithCTag,
        getEventsInput: (param)=>{
            let { leagueId, silent } = param;
            return {
                betline: BetlineType.All,
                leagueId,
                hideClosed: true,
                silent,
                flags: [
                    ...defaultRestApiFlags.value
                ],
                LMCE: isLiveOutrightsEnabled.value
            };
        },
        getPendingParams: (param)=>{
            let { vTag, leagueId } = param;
            return {
                vTag,
                leagueId,
                betlines: [
                    BetlineType.All
                ]
            };
        }
    });
    return {
        loadLeagueEvents
    };
}
