import { isString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config-names';
import { HomePageType } from '@leon-hub/api-sdk';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useRootNavigationStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useSportlineHome } from 'web/src/modules/home/submodules/sportline/useSportlineHome';
function getDefaultRouteName() {
    "1";
    {
        const { homePageType } = useSiteConfigStore();
        return homePageType === HomePageType.SPORTS ? RouteName.HOME : RouteName.SPORTLINE_TOP_EVENTS;
    }
}
let HomePagePrefetch = class HomePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const { isReady, initialRequests, syncBackgroundRequests } = useSportlineHome();
        const navigationStore = useRootNavigationStore();
        const targetRouteName = isString(to.name) ? to.name : getDefaultRouteName();
        if (isReady.value) {
            syncBackgroundRequests();
            navigationStore.setRouteContentLoaded(targetRouteName);
        } else {
            navigationStore.setOnlyFirstRouteContentLoading(targetRouteName);
            initialRequests().then(()=>{
                navigationStore.setRouteContentLoaded(targetRouteName);
            });
        }
        next();
        return Promise.resolve();
    }
};
export { HomePagePrefetch as default };
