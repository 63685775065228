import formatExpirationDate from './formatExpirationDate';
export const inputEventToFormDataEvent = (event)=>{
    const { name, value } = event.target;
    return {
        name,
        value
    };
};
export const inputExpirationDateToFormDataEvent = (event)=>{
    const { name, value } = event.target;
    return {
        name,
        value: formatExpirationDate(value)
    };
};
export const inputFileEventToFormDataEvent = (event)=>{
    const { files, name } = event.target;
    const value = files ? files[0] : void 0;
    return {
        name,
        value
    };
};
export const fileMultipleEventToFormDataEvent = function(event) {
    let returnOriginalName = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    const { selectedIndex, name, files } = event.target;
    const output = {
        name: `${name}/${selectedIndex}`,
        value: files[selectedIndex]
    };
    if (returnOriginalName) output.fieldOriginalName = name;
    return output;
};
export const checkboxEventToDataEvent = (event, name)=>{
    const { target } = event;
    HTMLInputElement;
    return {
        name,
        value: target.checked
    };
};
