import { onMounted, ref, watch } from 'vue';
import { AmountAnimator, createFakeValue } from 'web/src/components/AmountAnimator/VAmountAnimator/utils';
export default function useVAmountAnimator(props) {
    const animatedValue = ref(0);
    let amountAnimator = null;
    function onValueChange() {
        animatedValue.value = props.amount;
        runAnimation(animatedValue.value, animatedValue.value);
    }
    function watchAnimationBehaviour(to) {
        if (amountAnimator) {
            if (to) amountAnimator.stop();
            else amountAnimator.start();
        }
    }
    function runAnimation(newAmount, amount) {
        const { duration, timeRange, stepRange, isInfinite } = props;
        if (0 === newAmount) return;
        if (amountAnimator) amountAnimator.stop();
        const isFakeAnimation = newAmount <= amount;
        amountAnimator = new AmountAnimator({
            duration,
            timeRange,
            callback: (value, isFinished)=>{
                animatedValue.value = value;
                if (isFinished && isInfinite) runAnimation(newAmount, createFakeValue({
                    duration,
                    stepRange,
                    amount: newAmount
                }));
            },
            values: {
                from: isFakeAnimation ? newAmount : amount,
                to: isFakeAnimation ? createFakeValue({
                    duration,
                    stepRange,
                    amount: newAmount
                }) : newAmount
            }
        });
        if (!props.isHidden) amountAnimator.start();
    }
    watch(()=>props.amount, onValueChange);
    watch(()=>props.isHidden, watchAnimationBehaviour);
    onMounted(onValueChange);
    return {
        animatedValue
    };
}
