import { isGetHeadlineMatchesChangesResponseCheck, isGetMergedHeadlineMatchesChangesResponseCheck, isGetMergedHeadlineMatchesResponseCheck } from 'web/src/modules/sportline/guards/rest';
export default function mapHeadlineMatchesToSportEventsResponse(response) {
    if (!response) return null;
    if (isGetHeadlineMatchesChangesResponseCheck(response)) {
        if (isGetMergedHeadlineMatchesChangesResponseCheck(response)) return response.events;
        const liveEvents = response.live.data || [];
        const prematchEvents = response.prematch.data || [];
        const liveTotal = response.liveTotal || 0;
        const prematchTotal = response.prematchTotal || 0;
        return {
            events: [
                ...liveEvents,
                ...prematchEvents
            ],
            totalCount: liveTotal + prematchTotal
        };
    }
    if (isGetMergedHeadlineMatchesResponseCheck(response)) return response.events;
    const liveEvents = response.live.events;
    const prematchEvents = response.prematch.events;
    const liveTotal = response.liveTotal || 0;
    const prematchTotal = response.prematchTotal || 0;
    return {
        events: [
            ...liveEvents,
            ...prematchEvents
        ],
        totalCount: liveTotal + prematchTotal
    };
}
