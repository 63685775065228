export const ThemePlugin = {
    install (app, param) {
        let { theme, isDark, isLight, isAuto } = param;
        Object.defineProperty(app.config.globalProperties, '$theme', {
            enumerable: true,
            get: ()=>({
                    theme: theme.value,
                    isDark: isDark.value,
                    isLight: isLight.value,
                    isAuto: isAuto.value
                })
        });
    }
};
