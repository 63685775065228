import { ref } from 'vue';
export function useSportlineEventDetailsMiniBoardStoreComposable() {
    const isMiniBoardShown = ref(false);
    function setIsMiniBoardShown(value) {
        isMiniBoardShown.value = value;
    }
    return {
        isMiniBoardShown,
        setIsMiniBoardShown
    };
}
