import { toRef } from 'vue';
import { isString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config';
import { ApiError } from '@leon-hub/api';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useQuickBetsStore } from 'web/src/modules/quick-bets/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
let QuickBetsDetailsPrefetch = class QuickBetsDetailsPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const siteConfigStore = useSiteConfigStore();
        if (!siteConfigStore.fastGamesBlock?.isEnabled) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        const gamesStore = useQuickBetsStore();
        const activeGame = toRef(gamesStore, 'activeGame');
        const { groupUrl, gameUrl } = to.params;
        isString(groupUrl);
        isString(gameUrl);
        try {
            await gamesStore.setActiveUrl(groupUrl, gameUrl);
        } catch (error) {
            if (!(error instanceof ApiError) || 'EGS_GAME_UNAVAILABLE' !== error.code.toString()) throw error;
        }
        if (!activeGame.value) {
            next(router.resolve301location({
                name: RouteName.QUICK_BETS
            }));
            return;
        }
        next();
    }
};
export { QuickBetsDetailsPrefetch as default };
