import RouteName from '@leon-hub/routing-config';
import { isOptionalString, isUndefined } from '@leon-hub/guards';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import { useRegistrationCompletionStore } from 'web/src/modules/registration-completion/store';
let RegistrationCompletionPrefetch = class RegistrationCompletionPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        if ((to.params.customerAction === CustomerCompletionAction.Withdrawal || to.params.customerAction === CustomerCompletionAction.Deposit) && isUndefined(to.params.paymentSystemId)) {
            next({
                name: RouteName.HOME
            });
            return;
        }
        const { customerAction, paymentSystemId } = to.params;
        isOptionalString(customerAction);
        isOptionalString(paymentSystemId);
        await useRegistrationCompletionStore().fetchIncompleteRegistrationFields({
            customerAction,
            paymentSystemId
        });
        next();
    }
};
export { RegistrationCompletionPrefetch as default };
