import { watch, computed, ref } from 'vue';
import { IconName } from '@leon-hub/icons';
import getCircleStrokeWidth from './utils/getCircleStrokeWidth';
import getCirclePositionProps from './utils/getCirclePositionProps';
import getRelativePercentage from './utils/getRelativePercentage';
import getRelativeSafePercentage from './utils/getRelativeSafePercentage';
import getCircleLength from './utils/getCircleLength';
import getMatchedSizeValue from './utils/getMatchedSizeValue';
import getSafePercentage from './utils/getSafePercentage';
import getViewBox from './utils/getViewBox';
import getStrokeDasharray from './utils/getStrokeDasharray';
import getFilterId from './utils/getFilterId';
import getFilterShadowColorMatrix from './utils/getFilterShadowColorMatrix';
export default function useVCircularProgress(props) {
    const progress = ref();
    const checkIcon = IconName.CHECK_ROUNDED;
    const matchedSizeValue = computed(()=>getMatchedSizeValue(props));
    const circleStrokeWidth = computed(()=>getCircleStrokeWidth(props));
    const circlePositionProps = computed(()=>getCirclePositionProps(props));
    const circleLength = computed(()=>getCircleLength(props));
    const viewBox = computed(()=>getViewBox(props));
    const safePercentage = computed(()=>getSafePercentage(props));
    const relativeSafePercentage = computed(()=>getRelativeSafePercentage(props));
    const relativePercentage = computed(()=>getRelativePercentage(props));
    const strokeDasharray = computed(()=>getStrokeDasharray(props));
    const filterId = getFilterId();
    const filterShadowColorMatrix = getFilterShadowColorMatrix(props);
    function setCircleLengthVariable() {
        progress?.value?.style.setProperty('--circleLength', `${circleLength.value || 0}`);
    }
    function onCircularProgressMounted() {
        setCircleLengthVariable();
    }
    watch(()=>props.circleSize, setCircleLengthVariable);
    return {
        progress,
        checkIcon,
        matchedSizeValue,
        circleStrokeWidth,
        circlePositionProps,
        circleLength,
        viewBox,
        safePercentage,
        relativeSafePercentage,
        relativePercentage,
        strokeDasharray,
        filterId,
        filterShadowColorMatrix,
        onCircularProgressMounted
    };
}
