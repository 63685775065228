const knownBooleanProps = [
    'disabled',
    'hidden',
    'isIgnoredByDisableCounter',
    'shortWidth',
    'autofocus',
    'isBirthday',
    'emitFilled',
    'readonly',
    'isYmDisableKeys',
    'clickableIcon',
    'open',
    'isCityType',
    'isCountrySelector',
    // slott props
    'isLarge',
    'isHintHidden',
    'isLabelHidden',
    'hideValidation',
    'showSelectedIcon',
    'searchEnabled',
    'disableHoverFilter',
    'isMultiselectMode'
];
const knownNumberProps = [
    'legalAge',
    'size',
    'maxSymbols',
    'maxLength',
    'rowsMax',
    'rowsMin',
    'maximumFractionDigits',
    'expires'
];
const knownStringArrayProps = [
    'forbiddenSymbols'
];
export { knownNumberProps, knownBooleanProps, knownStringArrayProps };
