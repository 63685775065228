import { isBoolean, isFunction } from '@leon-hub/guards';
export function matchTagHref(substring) {
    const href = substring.match(/href="(.+)"/gm);
    if (!href || !href[0]) return {
        hasHref: false,
        isAnchor: false
    };
    if (href[0].match(/^href="#/gm)) return {
        hasHref: true,
        isAnchor: true
    };
    return {
        hasHref: true,
        isAnchor: false
    };
}
function getBooleanPropValue(key, value) {
    return value ? key : '';
}
function getStringPropValue(key, value) {
    return `${key}="${value}"`;
}
function tagPropsStringify(tagProps) {
    return Object.keys(tagProps).map((key)=>{
        const value = tagProps[key];
        if (void 0 === value) return '';
        return isBoolean(value) ? getBooleanPropValue(key, value) : getStringPropValue(key, value);
    }).filter((prop)=>!!prop).join(' ');
}
function fixFirstSpace(value) {
    return value ? ` ${value}` : '';
}
export function replaceContentTags(content, oldTag, newTag, tagProps) {
    let getProps;
    if (isFunction(tagProps)) getProps = (substring)=>fixFirstSpace(tagPropsStringify(tagProps(substring)));
    else {
        const constProps = fixFirstSpace(tagPropsStringify(tagProps));
        getProps = ()=>constProps;
    }
    return (content || '').replace(new RegExp(`<(${oldTag})((\\s+((?!>).)*)|\\s*)>`, 'gm'), function(substring) {
        for(var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            args[_key - 1] = arguments[_key];
        }
        return `<${newTag}${args[1]}${getProps(substring)}>`;
    }).replace(new RegExp(`<\\/((((?!>).)*)\\s+|\\s*)(${oldTag})>`, 'gm'), function(substring) {
        for(var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            args[_key - 1] = arguments[_key];
        }
        return `</${args[0]}${newTag}>`;
    });
}
