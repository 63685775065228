import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import LeaderBoardParticipant from '../LeaderBoardParticipant/LeaderBoardParticipant.vue';
import { useLeaderBoard } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaderBoard',
    props: {
        list: {},
        customerPosition: {},
        isLoading: {
            type: Boolean
        },
        endOfList: {
            type: Boolean
        },
        description: {
            default: ''
        },
        title: {
            default: ''
        }
    },
    emits: [
        "load-more"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { customerPositionIndex, participantMounted, secondPositionVisible, showMoreButtonProperties, handleLoadMore } = useLeaderBoard(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['leader-board'])
            }, [
                _createElementVNode("table", {
                    class: _normalizeClass(_ctx.$style['leader-board__table'])
                }, [
                    _createElementVNode("thead", {
                        class: _normalizeClass(_ctx.$style['leader-board__header'])
                    }, [
                        _createElementVNode("tr", {
                            class: _normalizeClass(_ctx.$style['leader-board__row'])
                        }, [
                            _createElementVNode("th", {
                                scope: "col",
                                class: _normalizeClass({
                                    [_ctx.$style['leader-board__cell']]: true,
                                    [_ctx.$style['leader-board__cell--place']]: true
                                })
                            }, _toDisplayString(_ctx.$t('JS_LEADERBOARD_ARCHIVE_PLACE')), 3),
                            _createElementVNode("th", {
                                scope: "col",
                                class: _normalizeClass({
                                    [_ctx.$style['leader-board__cell']]: true,
                                    [_ctx.$style['leader-board__cell--name']]: true
                                })
                            }, _toDisplayString(_ctx.$t('JS_PROMOTIONS_TITLE_WINNERNAME')), 3),
                            _createElementVNode("th", {
                                scope: "col",
                                class: _normalizeClass({
                                    [_ctx.$style['leader-board__cell']]: true,
                                    [_ctx.$style['leader-board__cell--prize']]: true
                                })
                            }, _toDisplayString(_ctx.$t('JS_PROMOTIONS_TITLE_PRIZE')), 3),
                            _createElementVNode("th", {
                                scope: "col",
                                class: _normalizeClass({
                                    [_ctx.$style['leader-board__cell']]: true,
                                    [_ctx.$style['leader-board__cell--score']]: true
                                })
                            }, _toDisplayString(_ctx.$t('JS_PROMOTIONS_TITLE_SCORE')), 3)
                        ], 2)
                    ], 2),
                    _ctx.list.length > 0 ? (_openBlock(), _createElementBlock("tbody", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['leader-board__tbody'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (participant, index)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                                index !== _unref(customerPositionIndex) ? (_openBlock(), _createBlock(LeaderBoardParticipant, {
                                    key: index,
                                    participant: participant,
                                    active: false
                                }, null, 8, [
                                    "participant"
                                ])) : _createCommentVNode("", true),
                                index === _unref(customerPositionIndex) ? (_openBlock(), _createBlock(LeaderBoardParticipant, {
                                    key: index,
                                    participant: participant,
                                    sticky: "",
                                    active: "",
                                    onMounted: _unref(participantMounted)
                                }, null, 8, [
                                    "participant",
                                    "onMounted"
                                ])) : _createCommentVNode("", true)
                            ], 64))), 256)),
                        _unref(secondPositionVisible) && _ctx.customerPosition ? (_openBlock(), _createBlock(LeaderBoardParticipant, {
                            key: 0,
                            participant: _ctx.customerPosition,
                            sticky: "",
                            active: ""
                        }, null, 8, [
                            "participant"
                        ])) : _createCommentVNode("", true)
                    ], 2)) : _createCommentVNode("", true),
                    _ctx.endOfList ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("tfoot", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['leader-board__tfoot'])
                    }, [
                        _createVNode(VButton, _mergeProps(_unref(showMoreButtonProperties), {
                            onClick: _unref(handleLoadMore)
                        }), null, 16, [
                            "onClick"
                        ])
                    ], 2))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaderBoard'
                ]
            ]);
        };
    }
});
