import { computed } from 'vue';
import { isOptionalString } from '@leon-hub/guards';
import { TextInputTypes } from 'web/src/components/Input/enums';
import getWrapperHintProperties from 'web/src/components/Form/utils/getWrapperHintProperties';
export default function useFormCPFNumber(props) {
    const componentProps = computed(()=>{
        const { name, value, options, title, error, disabled, hintMessage, autofocus } = props;
        isOptionalString(value);
        const parsedValue = value ? value.replace(/\D/g, '') : '';
        return {
            name,
            label: title,
            disabled,
            value: parsedValue,
            ...options ?? {},
            ...getWrapperHintProperties({
                options,
                error,
                hintMessage
            }),
            mask: {
                pattern: '999.999.999-99',
                placeholder: '0'
            },
            placeholder: '000.000.000-00',
            type: TextInputTypes.NUMBER,
            autofocus
        };
    });
    return {
        componentProps
    };
}
