import { computed, toRef, watch, ref, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import isGeneralApiError from '@leon-hub/api/src/client/errors/isGeneralApiError';
import { useCustomMessageListener } from '@leon-hub/browser-composables';
import { BusEvent, useEventsBus, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { useQuickBetsStore } from 'web/src/modules/quick-bets/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { AnalyticsModalType, DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { messageEventGameLocation, messageEventGameNavigate, messageEventGetViewPort, messageEventIframeTop, messageEventNewHeight, messageEventScrollTo, messageEventSubscribeScroll, messageEventUnsubscribeScroll, twainSportProvider } from 'web/src/modules/quick-bets/constants';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
export default function useQuickBetsDetailsPage() {
    const route = useRoute();
    const router = useRouter();
    const bus = useEventsBus();
    const quickBetsStore = useQuickBetsStore();
    const { isLoggedIn } = useIsLoggedIn();
    const minHeight = ref(0);
    const mainContentRef = ref();
    const { startGame, clearStartGameData, getGameById, reloadActiveGame } = useQuickBetsStore();
    const { showDialog } = useDialogs();
    let shouldReloadGame = true;
    const activeGame = toRef(quickBetsStore, 'activeGame');
    const startGameData = toRef(quickBetsStore, 'startGameData');
    const gameUrl = computed(()=>startGameData.value?.gameUrl);
    const startCodeType = computed(()=>startGameData.value?.startCode);
    const showLoader = computed(()=>!gameUrl.value);
    function showError(message) {
        showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                title: '',
                confirmMessage: message,
                analyticsModalType: AnalyticsModalType.WARNING_EGS,
                dataTestId: 'quick-bets-details-error'
            }
        }).subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                router.replace({
                    name: RouteName.QUICK_BETS
                });
            }
        });
    }
    const styles = computed(()=>{
        const stylesData = {};
        if (minHeight.value) stylesData.minHeight = `${minHeight.value}px`;
        return stylesData;
    });
    async function doNavigation(id) {
        if ('lobby' !== id) try {
            const game = await getGameById(id);
            if (game && game.id !== activeGame.value?.id) {
                shouldReloadGame = false;
                await router.replace({
                    name: RouteName.QUICK_BETS_DETAILS,
                    params: {
                        gameUrl: game.url,
                        groupUrl: game.group.url
                    }
                });
            }
            return;
        } catch  {}
        await router.push({
            name: RouteName.QUICK_BETS
        });
    }
    function onQuickBetsMessage(param) {
        let { data } = param;
        const { action, value, payload } = data || {};
        const actionValue = value || payload;
        switch(action){
            case messageEventGameLocation:
                /** only one possible use case = home button clicked.
         its dangerous to redirect to third party given URL, so redirect to lobby
         until we will not found more valid cases to handle */ router.push({
                    name: RouteName.QUICK_BETS
                });
                break;
            case messageEventGameNavigate:
                doNavigation(actionValue);
                break;
            default:
                break;
        }
    }
    const iframeElement = ref();
    const scrollTop = ref(0);
    let scrollTopWatcher;
    function postMessage(message) {
        iframeElement.value?.postMessage(message);
    }
    function getScrollTop() {
        return scrollTop.value;
    }
    function scrollSubscribe() {
        postMessage(JSON.stringify({
            type: 'onScroll',
            payload: {
                height: mainContentRef.value?.offsetHeight,
                scrollY: getScrollTop(),
                iFrameTopOffset: mainContentRef.value?.offsetTop
            }
        }));
    }
    function onScrollSubscribe() {
        scrollTopWatcher = watch(scrollTop, scrollSubscribe);
    }
    function onScrollUnsubscribe() {
        scrollTopWatcher?.();
        scrollTopWatcher = void 0;
    }
    function scrollTo(top) {
        bus.emit(BusEvent.LAYOUT_CONTENT_SET_SCROLL, {
            scrollTop: top
        });
    }
    function onTwainSportMessage(param) {
        let { data } = param;
        try {
            const parsedData = JSON.parse(data);
            switch(parsedData.type){
                case messageEventSubscribeScroll:
                    onScrollSubscribe();
                    break;
                case messageEventUnsubscribeScroll:
                    onScrollUnsubscribe();
                    break;
                case messageEventNewHeight:
                    minHeight.value = parsedData.payload.height;
                    break;
                case messageEventGetViewPort:
                    postMessage(JSON.stringify({
                        type: 'getViewPort',
                        payload: {
                            width: mainContentRef.value?.offsetWidth,
                            height: mainContentRef.value?.offsetHeight,
                            iFrameTopOffset: mainContentRef.value?.offsetTop,
                            scrollY: getScrollTop()
                        }
                    }));
                    break;
                case messageEventScrollTo:
                    scrollTo(Number(parsedData.payload.scrollTo));
                    break;
                case messageEventIframeTop:
                    scrollTo(Number(parsedData.payload.topOffset));
                    break;
                default:
                    break;
            }
        } catch  {}
    }
    useCustomMessageListener((event)=>{
        if (activeGame.value?.group.id === twainSportProvider) onTwainSportMessage(event);
        else onQuickBetsMessage(event);
    });
    async function restartGame() {
        clearStartGameData();
        try {
            await startGame();
        } catch (error) {
            if (isGeneralApiError(error)) throw error;
            showError(error.message);
        }
    }
    const metaParameters = computed(()=>({
            gameName: activeGame.value?.name || '',
            gameId: activeGame.value?.id || ''
        }));
    onBeforeUnmount(()=>{
        onScrollUnsubscribe();
    });
    useSeoMetaParameters(metaParameters);
    function init() {
        "1";
        useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL, (param)=>{
            let { scrollTop: scrollTopInner } = param;
            scrollTop.value = scrollTopInner;
        });
        watch(()=>route.fullPath, ()=>{
            if (route.name !== RouteName.QUICK_BETS_DETAILS || !shouldReloadGame) {
                shouldReloadGame = true;
                return;
            }
            restartGame();
        }, {
            immediate: true
        });
        watch(isLoggedIn, ()=>{
            reloadActiveGame();
            restartGame();
        });
    }
    init();
    return {
        showLoader,
        gameUrl,
        startCodeType,
        iframeElement,
        styles,
        mainContentRef
    };
}
