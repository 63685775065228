import { computed } from 'vue';
import { SearchTab } from 'web/src/modules/search/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useCasinoSearchTabsSwitcher(emits) {
    const { $translate } = useI18n();
    const switcherOptions = computed(()=>[
            {
                id: SearchTab.SPORTS,
                label: $translate('JSPBET_SPORT_TYPES').value
            },
            {
                id: SearchTab.SLOTS,
                label: $translate('TABS_CASINO').value
            }
        ]);
    function emitChange(index) {
        emits('change', index);
    }
    return {
        switcherOptions,
        emitChange
    };
}
