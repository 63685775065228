import { computed } from 'vue';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line max-len
export default function usePromotionDetailsHeader(props) {
    const { $translate } = useI18n();
    const { locale } = useI18nLocale();
    const formattedStartDate = computed(()=>{
        const format = TimeFormats.dayMonthShortYear;
        if (!props.promotion?.startDateNumber) return '';
        return DateTime.formatTimeStamp(props.promotion.startDateNumber, format, locale.value);
    });
    const formattedEndDate = computed(()=>{
        const format = TimeFormats.dayMonthShortYear;
        if (!props.promotion?.endDateNumber) return '';
        return DateTime.formatTimeStamp(props.promotion.endDateNumber, format, locale.value);
    });
    const periodProperties = computed(()=>`${formattedStartDate.value} - ${formattedEndDate.value}`);
    return {
        periodProperties
    };
}
