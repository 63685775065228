import { toRef, computed } from 'vue';
import { useSportlineTopStore } from 'web/src/modules/sportline/submodules/top/store';
import { usePromotionsTopStore } from 'web/src/modules/promotions/submodules/top/usePromotionsTopStore';
import { useSportlineCardsData } from 'web/src/modules/home/submodules/sportline/composables';
export function useSportlineHome() {
    const sportlineTopStore = useSportlineTopStore();
    const isSportlineTopReady = toRef(sportlineTopStore, 'isReady');
    const promotionsTopStore = usePromotionsTopStore();
    const isPromotionsTopReady = toRef(promotionsTopStore, 'isReady');
    const sportlineCardsData = useSportlineCardsData();
    const { isReady: isCardsDataReady } = sportlineCardsData;
    const isReady = computed(()=>isSportlineTopReady.value && isPromotionsTopReady.value && isCardsDataReady.value);
    async function initialRequests() {
        await Promise.allSettled([
            sportlineTopStore.initialRequests(),
            promotionsTopStore.initialRequests(),
            sportlineCardsData.initialRequests()
        ]);
    }
    async function syncBackgroundRequests() {
        await Promise.allSettled([
            sportlineTopStore.syncBackgroundRequests({
                silent: true
            }),
            promotionsTopStore.syncBackgroundRequests(),
            sportlineCardsData.syncBackgroundRequests()
        ]);
    }
    async function setBackgroundUpdateEnabled(value) {
        await Promise.all([
            sportlineTopStore.setBackgroundUpdateEnabled(value),
            sportlineCardsData.setBackgroundUpdateEnabled(value)
        ]);
    }
    return {
        isReady,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
}
