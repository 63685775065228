/**
 * https://w3c.github.io/requestidlecallback/
 * The maximum deadline of 50ms is derived from studies [RESPONSETIME] which show that that a response to user input
 * within 100ms is generally perceived as instantaneous to humans.
 * Capping idle deadlines to 50ms means that even if the user input occurs immediately after the idle task has begun,
 * the user agent still has a remaining 50ms in which to respond
 * to the user input without producing user perceptible lag.
 */ const responseTimeDeadlineMs = 50;
let requestIdleCallbackFn = (callback)=>{
    const start = Date.now();
    return +setTimeout(()=>{
        callback({
            didTimeout: false,
            timeRemaining () {
                return Math.max(0, responseTimeDeadlineMs - (Date.now() - start));
            }
        });
    }, 1);
};
// TODO: use !process.env.VUE_APP_RENDERING_SSR instead of process.browser
if (process.browser && 'requestIdleCallback' in window) requestIdleCallbackFn = window.requestIdleCallback;
const requestIdleCallback = requestIdleCallbackFn.bind(globalThis);
export default process.server ? requestIdleCallbackFn : requestIdleCallback;
