import { ref } from 'vue';
export default function useVSightObserver() {
    const isVisible = ref(false);
    function onVisibilityChange(value) {
        isVisible.value = value;
    }
    const observeVisibilityOptions = {
        callback: (value)=>onVisibilityChange(value),
        throttle: 300
    };
    return {
        observeVisibilityOptions,
        isVisible
    };
}
