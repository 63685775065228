import { watch, ref } from 'vue';
import useCheckboxEvents from './useCheckboxEvents';
export default function useVCheckbox(props, emit) {
    const isHover = ref(false);
    const isChecked = ref(!!props.checked);
    const isFocus = ref(false);
    const onMouseEnter = ()=>{
        isHover.value = true;
    };
    const onMouseLeave = ()=>{
        isHover.value = false;
    };
    const { onInput, onChange, onFocus, onBlur } = useCheckboxEvents(emit);
    const handleFocus = (event)=>{
        isFocus.value = true;
        onFocus(event);
    };
    const handleBlur = (event)=>{
        isFocus.value = false;
        onBlur(event);
    };
    watch(()=>props.checked, (value)=>{
        isChecked.value = !!value;
    });
    return {
        isHover,
        isFocus,
        isChecked,
        onMouseEnter,
        onMouseLeave,
        onInput,
        onChange,
        onFocus: handleFocus,
        onBlur: handleBlur
    };
}
