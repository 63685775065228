import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'OddsList',
    props: {
        odds: {},
        odd: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("ul", {
                class: _normalizeClass(_ctx.$style['odds-selector'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.odds, (item)=>(_openBlock(), _createElementBlock("li", {
                        key: item.id,
                        class: _normalizeClass({
                            [_ctx.$style['odds-selector__item']]: true,
                            [_ctx.$style['odds-selector__item-active']]: item.oddType === _ctx.odd
                        }),
                        onClick: ($event)=>emit('click', item.oddType)
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['odds-selector__item-text'])
                        }, _toDisplayString(item.label), 3),
                        item.oddType === _ctx.odd ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['odds-selector__icon-container'])
                        }, [
                            _createVNode(_unref(VIcon), {
                                class: "odds-selector__icon-selected",
                                name: _unref(IconName).CHECK_FILLED,
                                size: _unref(IconSize).SIZE_16
                            }, null, 8, [
                                "name",
                                "size"
                            ])
                        ], 2)) : _createCommentVNode("", true)
                    ], 10, _hoisted_1))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'OddsList'
                ]
            ]);
        };
    }
});
