import { PAYMENTS_UNDEFINED_CREDIT_CARD } from 'web/src/modules/payments/constants';
export default function isValidCreditCardForTokenize(param) {
    let { pan, bankcards } = param;
    // pan is not exist or empty
    if (!pan || pan === PAYMENTS_UNDEFINED_CREDIT_CARD) return false;
    if (bankcards?.length) {
        const isPanFromBankCards = !!bankcards.find((bankcard)=>bankcard.token === pan || bankcard.pan === pan);
        // pan is token or hidden pan
        if (isPanFromBankCards) return false;
    }
    return true;
}
