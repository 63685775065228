import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useVCaptchaVerificationWatcher } from 'web/src/modules/captcha/components/VCaptchaVerificationWatcher/composables';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'VCaptchaVerificationWatcher',
    props: {
        captchaRequesterStrategy: {}
    },
    emits: [
        "verify"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        useVCaptchaVerificationWatcher(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, null, 512)), [
                [
                    _directive_auto_id,
                    'VCaptchaVerificationWatcher'
                ]
            ]);
        };
    }
});
