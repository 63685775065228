import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import DepositBonusSelectorModal from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/DepositBonusSelectorModal.vue';
import useDepositBonusSelector from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/composables/useDepositBonusSelector';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ModalSelector } from 'web/src/modules/core/enums';
import { DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DepositBonusSelector',
    props: {
        isStandAloneMode: {
            type: Boolean
        },
        hidden: {
            type: Boolean
        },
        isChoosingBonusBeforeDeposit: {
            type: Boolean
        },
        isNoneSelected: {
            type: Boolean
        },
        depositBonusList: {},
        selectedBonus: {},
        selectedBonusCode: {}
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { selectorListItemProperties, isPaymentBonuses, isDetailsAvailable, openedBonusDetails, openListModal, openDetailsModal, onCloseModal, onCloseAllModal, emitChange } = useDepositBonusSelector(props, emit);
        const buttonInfoProperties = {
            iconName: IconName.INFO_OUTLINE,
            iconSize: IconSize.SIZE_24,
            kind: ButtonKind.BASE
        };
        const modalSelector = ModalSelector.DESKTOP_INNER_MODAL;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.hidden ? _createCommentVNode("", true) : _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['deposit-bonus-selector'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['deposit-bonus-selector__selector'])
                }, [
                    _createVNode(VList, {
                        class: _normalizeClass(_ctx.$style['deposit-bonus-selector__selector-button'])
                    }, {
                        default: _withCtx(()=>[
                                _createVNode(VListItem, _mergeProps(_unref(selectorListItemProperties), {
                                    class: {
                                        [_ctx.$style['deposit-bonus-selector__selector-button-inner']]: true
                                    },
                                    onClick: _unref(openListModal)
                                }), null, 16, [
                                    "class",
                                    "onClick"
                                ])
                            ]),
                        _: 1
                    }, 8, [
                        "class"
                    ]),
                    _ctx.selectedBonus !== _unref(DEPOSIT_BONUS_NONE_SELECTED) && _unref(isDetailsAvailable) ? (_openBlock(), _createBlock(LButton, _mergeProps({
                        key: 1
                    }, buttonInfoProperties, {
                        class: _ctx.$style['deposit-bonus-selector__button-info'],
                        onClick: _unref(openDetailsModal)
                    }), null, 16, [
                        "class",
                        "onClick"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _createVNode(ModalPortal, {
                    selector: _unref(modalSelector)
                }, {
                    default: _withCtx(()=>[
                            _unref(isPaymentBonuses) ? (_openBlock(), _createBlock(DepositBonusSelectorModal, {
                                key: 0,
                                "bonus-list": _ctx.depositBonusList,
                                "selected-option-id": _ctx.selectedBonus,
                                "opened-bonus-details": _unref(openedBonusDetails),
                                onBack: _unref(onCloseModal),
                                onClose: _unref(onCloseAllModal),
                                onChange: _unref(emitChange)
                            }, null, 8, [
                                "bonus-list",
                                "selected-option-id",
                                "opened-bonus-details",
                                "onBack",
                                "onClose",
                                "onChange"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "selector"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'DepositBonusSelector'
                ],
                [
                    _unref(vDataTestUnit),
                    {
                        el: 'deposit-bonus'
                    }
                ]
            ]);
        };
    }
});
