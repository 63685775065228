import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { useSportlineRegionLeagueElements } from 'web/src/modules/sportline/composables/list-components/region';
import SportlineLeagueBlock from 'web/src/modules/sportline/views/LeagueBlock/SportlineLeagueBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineRegionBlock',
    props: {
        sport: {},
        regionElement: {},
        upcomingLabelType: {},
        isHideLeagueInfo: {
            type: Boolean
        },
        isColumnNamesDisplayDisabled: {
            type: Boolean
        },
        isShowAddLeagueToFavorite: {
            type: Boolean
        },
        isDisplayMainEventsDisabled: {
            type: Boolean
        },
        isDisplayOutrightEventsEnabled: {
            type: Boolean
        },
        isDisplayOutrightHeadlineDisabled: {
            type: Boolean
        },
        canAddToFavorite: {
            type: Boolean
        },
        canAddOutrightsToFavorite: {
            type: Boolean
        },
        isExtendedEventViewDisabled: {
            type: Boolean
        },
        collapseLeagueKey: {},
        isLeagueDefaultExpanded: {
            type: Boolean
        },
        isFilterActive: {
            type: Boolean
        },
        activeEventId: {},
        extendedBlockType: {},
        basisKey: {}
    },
    emits: [
        "click-event"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        /**
 * The main region component with leagues components.
 * Seems it could be removed.
 */ const props = __props;
        const emit = __emit;
        const isDisplayOutrightEventsEnabled = toRef(props, 'isDisplayOutrightEventsEnabled');
        const regionElement = toRef(props, 'regionElement');
        const { leagueElements } = useSportlineRegionLeagueElements({
            isDisplayOutrightEventsEnabled,
            regionElement
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(leagueElements), (leagueElement)=>(_openBlock(), _createBlock(SportlineLeagueBlock, {
                        key: leagueElement.key,
                        sport: _ctx.sport,
                        region: regionElement.value.region,
                        "league-element": leagueElement,
                        "upcoming-label-type": _ctx.upcomingLabelType,
                        "is-hide-league-info": _ctx.isHideLeagueInfo,
                        "is-column-names-display-disabled": _ctx.isColumnNamesDisplayDisabled,
                        "is-show-add-league-to-favorite": _ctx.isShowAddLeagueToFavorite,
                        "is-display-main-events-disabled": _ctx.isDisplayMainEventsDisabled,
                        "is-display-outright-events-enabled": isDisplayOutrightEventsEnabled.value,
                        "is-display-outright-headline-disabled": _ctx.isDisplayOutrightHeadlineDisabled,
                        "can-add-to-favorite": _ctx.canAddToFavorite,
                        "can-add-outrights-to-favorite": _ctx.canAddOutrightsToFavorite,
                        "is-extended-event-view-disabled": _ctx.isExtendedEventViewDisabled,
                        "active-event-id": _ctx.activeEventId,
                        "expand-key": _ctx.collapseLeagueKey ? `${_ctx.collapseLeagueKey}-${leagueElement.league.id}` : void 0,
                        "is-default-expanded": _ctx.isLeagueDefaultExpanded,
                        "is-filter-active": _ctx.isFilterActive,
                        "extended-block-type": _ctx.extendedBlockType,
                        "basis-key": _ctx.basisKey,
                        onClickEvent: _cache[0] || (_cache[0] = ($event)=>emit('click-event'))
                    }, null, 8, [
                        "sport",
                        "region",
                        "league-element",
                        "upcoming-label-type",
                        "is-hide-league-info",
                        "is-column-names-display-disabled",
                        "is-show-add-league-to-favorite",
                        "is-display-main-events-disabled",
                        "is-display-outright-events-enabled",
                        "is-display-outright-headline-disabled",
                        "can-add-to-favorite",
                        "can-add-outrights-to-favorite",
                        "is-extended-event-view-disabled",
                        "active-event-id",
                        "expand-key",
                        "is-default-expanded",
                        "is-filter-active",
                        "extended-block-type",
                        "basis-key"
                    ]))), 128))
            ])), [
                [
                    _directive_auto_id,
                    'SportlineRegionBlock'
                ]
            ]);
        };
    }
});
