import normalizeImagePath from 'web/src/utils/normalizeImagePath';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
const errorPrefix = 'Unable to load image:';
export default function loadImage(appUrl, imageRawSource) {
    try {
        const imageSource = normalizeImagePath(appUrl, imageRawSource);
        return new Promise((resolve, reject)=>{
            const img = new Image();
            // eslint-disable-next-line unicorn/prefer-add-event-listener
            img.addEventListener('load', ()=>resolve(img));
            img.addEventListener('error', ()=>reject(new Error(`${errorPrefix} ${imageSource}`)));
            img.src = imageSource;
        });
    } catch (error) {
        return Promise.reject(useErrorsConverter().convertToBaseError(error));
    }
}
