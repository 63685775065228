import { toRef } from 'vue';
import memoize from 'lodash/memoize';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { debug } from 'web/src/utils/debug';
import { getSessionID, getStorage } from '../utils';
import { useShieldIntegrationStore } from '../store';
const log = debug.extend('shield-integration');
async function syncStorage() {
    log('syncing with storage..');
    const store = useShieldIntegrationStore();
    const { isEnabled, sdkUrl } = store;
    if (isEnabled && sdkUrl) {
        const record = await getStorage().get();
        if (record.exists) {
            const { value } = record;
            store.setSessionId(value.session_id);
            log('synced with storage: %o', value);
        } else {
            log('storage is empty');
            try {
                const sessionId = await getSessionID(sdkUrl);
                log(`received new session id: ${sessionId}`);
                store.setSessionId(sessionId);
            } catch (err) {
                logger.error(normalizeError(err, 'Unable to get shield session id'));
            }
        }
    } else log('storage sync is disabled');
}
// Requirement to sync external api once.
const syncStorageOnce = memoize(syncStorage);
const undefRef = toRef(()=>void 0);
/**
 * Retrieves the session ID for shield integration.
 *
 * If shield integration is enabled (sdkUrl is defined), the following steps are taken:
 * 1. If the session ID is defined within the runtime, it is returned.
 * 2. If the session ID is stored within the cache, it is synced to the runtime and then returned.
 * 3. If no session ID is available, a new one will be fetched, synced and returned.
 */ export const useShieldIntegration = ()=>{
    // eslint-disable-next-line no-console
    const sessionId = toRef(()=>useShieldIntegrationStore().sessionId);
    const startShieldIntegrationMainRoutine = async ()=>{
        const store = useShieldIntegrationStore();
        if (!store.isEnabled) return;
        if (!store.sessionId) await syncStorageOnce();
    };
    let isShieldIntegrationCalled = false;
    async function load() {
        if (!isShieldIntegrationCalled) {
            isShieldIntegrationCalled = true;
            await startShieldIntegrationMainRoutine();
        }
    }
    return {
        sessionId,
        load
    };
};
