import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective } from "vue";
import { ref, toRef } from 'vue';
import { useExpandableSportlineBlock } from 'web/src/modules/sportline/composables/list';
import { useSportlineSportRegionElements } from 'web/src/modules/sportline/composables/list-components/sport';
import SportHeadline from 'web/src/modules/sportline/views/headline/SportHeadline.vue';
import SportlineRegionBlock from 'web/src/modules/sportline/views/RegionBlock/SportlineRegionBlock.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import { useSportlineBlockHeadline } from './composables';
// expandable
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineSportBlock',
    props: {
        sportElement: {},
        withoutHeadline: {
            type: Boolean
        },
        isShowAddLeagueToFavorite: {
            type: Boolean
        },
        isHideLeagueInfo: {
            type: Boolean
        },
        isColumnNamesDisplayDisabled: {
            type: Boolean
        },
        isDisplayMainEventsDisabled: {
            type: Boolean
        },
        isDisplayOutrightHeadlineDisabled: {
            type: Boolean
        },
        isDisplayOutrightEventsEnabled: {
            type: Boolean
        },
        isLeagueDefaultExpanded: {
            type: Boolean
        },
        canAddToFavorite: {
            type: Boolean
        },
        canAddOutrightsToFavorite: {
            type: Boolean
        },
        upcomingLabelType: {},
        isComingHeadline: {
            type: Boolean
        },
        isMarketTypesSelectionEnabled: {
            type: Boolean
        },
        collapseLeagueKey: {
            default: null
        },
        customTitle: {},
        activeEventId: {},
        basisKey: {},
        expandKey: {
            default: null
        },
        isDefaultExpanded: {
            type: Boolean
        },
        isFilterActive: {
            type: Boolean
        },
        extendedBlockType: {},
        isComing: {
            type: Boolean
        }
    },
    emits: [
        "click-event"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        /**
 * The main sportline component. It holds sport header and region blocks.
 */ const props = __props;
        const emit = __emit;
        const content = ref();
        const sportElement = toRef(props, 'sportElement');
        const basisKey = toRef(props, 'basisKey');
        const { sport, sportId, regionElements } = useSportlineSportRegionElements({
            sportElement
        });
        const { headlineKey, eventsCount } = useSportlineBlockHeadline({
            withoutHeadline: toRef(props, 'withoutHeadline', false),
            isMarketTypesSelectionEnabled: toRef(props, 'isMarketTypesSelectionEnabled', false),
            sportElement: toRef(props, 'sportElement'),
            sportId,
            basisKey
        });
        const { isExpanded, isExpandable, expandAnimationActive, expandAnimationDuration, toggleExpandState } = useExpandableSportlineBlock({
            content,
            expandKey: toRef(props, 'expandKey'),
            isDefaultExpanded: toRef(props, 'isDefaultExpanded')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(regionElements).length > 0 ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: `group-${_unref(sport).id}`
            }, [
                _ctx.withoutHeadline ? _createCommentVNode("", true) : _withDirectives((_openBlock(), _createBlock(SportHeadline, {
                    key: _unref(headlineKey),
                    "sport-id": _unref(sport).id,
                    "basis-key": basisKey.value,
                    "count-events": _unref(eventsCount),
                    "is-coming": _ctx.isComingHeadline,
                    "expand-key": _ctx.expandKey,
                    "is-expanded": _unref(expandAnimationActive) ? !_unref(isExpanded) : _unref(isExpanded),
                    "is-market-types-selection-enabled": _ctx.isMarketTypesSelectionEnabled,
                    "custom-title": _ctx.customTitle,
                    class: _normalizeClass(_ctx.$style['sport-events-list-sport__headline']),
                    onToggleExpandState: _unref(toggleExpandState)
                }, null, 8, [
                    "sport-id",
                    "basis-key",
                    "count-events",
                    "is-coming",
                    "expand-key",
                    "is-expanded",
                    "is-market-types-selection-enabled",
                    "custom-title",
                    "class",
                    "onToggleExpandState"
                ])), [
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'sportline-sport-block-headline',
                            'is-expanded': _unref(isExpanded)
                        }
                    ]
                ]),
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    ref_key: "content",
                    ref: content,
                    class: _normalizeClass({
                        [_ctx.$style['expandable-element-content']]: true,
                        [_ctx.$style['expandable-element-content--hidden']]: _unref(isExpandable) && !_unref(isExpanded)
                    }),
                    style: _normalizeStyle({
                        'transition-duration': `${_unref(expandAnimationDuration)}ms`
                    })
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(regionElements), (regionElement)=>(_openBlock(), _createBlock(SportlineRegionBlock, {
                            key: regionElement.key,
                            sport: _unref(sport),
                            "region-element": regionElement,
                            "upcoming-label-type": _ctx.upcomingLabelType,
                            "active-event-id": _ctx.activeEventId,
                            "is-hide-league-info": _ctx.isHideLeagueInfo,
                            "is-show-add-league-to-favorite": _ctx.isShowAddLeagueToFavorite,
                            "is-column-names-display-disabled": _ctx.isColumnNamesDisplayDisabled,
                            "is-display-main-events-disabled": _ctx.isDisplayMainEventsDisabled,
                            "is-display-outright-headline-disabled": _ctx.isDisplayOutrightHeadlineDisabled,
                            "is-display-outright-events-enabled": _ctx.isDisplayOutrightEventsEnabled,
                            "can-add-to-favorite": _ctx.canAddToFavorite,
                            "can-add-outrights-to-favorite": _ctx.canAddOutrightsToFavorite,
                            "collapse-league-key": _ctx.collapseLeagueKey,
                            "is-league-default-expanded": _ctx.isLeagueDefaultExpanded,
                            "is-filter-active": _ctx.isFilterActive,
                            "extended-block-type": _ctx.extendedBlockType,
                            "basis-key": basisKey.value,
                            onClickEvent: _cache[0] || (_cache[0] = ($event)=>emit('click-event'))
                        }, null, 8, [
                            "sport",
                            "region-element",
                            "upcoming-label-type",
                            "active-event-id",
                            "is-hide-league-info",
                            "is-show-add-league-to-favorite",
                            "is-column-names-display-disabled",
                            "is-display-main-events-disabled",
                            "is-display-outright-headline-disabled",
                            "is-display-outright-events-enabled",
                            "can-add-to-favorite",
                            "can-add-outrights-to-favorite",
                            "collapse-league-key",
                            "is-league-default-expanded",
                            "is-filter-active",
                            "extended-block-type",
                            "basis-key"
                        ]))), 128))
                ], 6)), [
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'sportline-sport-block-content'
                        }
                    ]
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SportlineSportBlock'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
