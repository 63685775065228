import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { BonusCampaignType } from '@leon-hub/api-sdk';
import { toLowerCase } from '@leon-hub/utils';
import { logger } from '@leon-hub/logging';
import { ProfileButtonsTab } from 'web/src/modules/profile/submodules/bonuses/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { fetchSdkBonusMatrix, doRefuseBonusApi, doWithdrawBonusCBC, doWithdrawSportBonusApi, fetchCustomerBonuses } from '../utils';
const useProfileBonusesStore = defineStore('profile-bonuses', ()=>{
    const { $translate } = useI18n();
    /** state */ const loading = ref(true);
    const sportBonuses = ref([]);
    const egsBonuses = ref([]);
    const bonusCampaignType = ref();
    const isBonusPageVisited = ref(false);
    const isOnboardingIconShown = ref(true);
    const hideOnBoardingPage = ref(false);
    const complexData = ref(null);
    const activeTab = ref();
    const waitingRefreshDelay = ref(false);
    /** getters */ const initialTab = computed(()=>{
        if (!sportBonuses.value.length || egsBonuses.value.length) return ProfileButtonsTab.EGS;
        return ProfileButtonsTab.SPORTS;
    });
    /** Mutations */ function setLoading(value) {
        loading.value = value;
    }
    function setSportBonuses(bonuses) {
        sportBonuses.value = bonuses;
    }
    function setEgsBonuses(bonuses) {
        egsBonuses.value = bonuses;
    }
    function setBonusCampaignType(value) {
        bonusCampaignType.value = value;
    }
    function setIsBonusesPageVisited(value) {
        isBonusPageVisited.value = value;
    }
    function setOnboardingIconState(value) {
        isOnboardingIconShown.value = value;
    }
    function setHideOnBoardingPageState(value) {
        hideOnBoardingPage.value = value;
    }
    function setComplexData(data) {
        complexData.value = data;
    }
    function setActiveTab(value) {
        activeTab.value = value;
    }
    function setWaitingRefreshDelay(value) {
        waitingRefreshDelay.value = value;
    }
    /** Actions */ async function fetchBonuses(bonusCampaignTypeValue) {
        try {
            const payload = await fetchCustomerBonuses(bonusCampaignTypeValue);
            if (payload) {
                const sportKey = toLowerCase(BonusCampaignType.SPORT);
                const egsKey = toLowerCase(BonusCampaignType.EGS);
                switch(bonusCampaignTypeValue){
                    case BonusCampaignType.SPORT:
                        setSportBonuses(payload[sportKey]);
                        break;
                    case BonusCampaignType.EGS:
                        setEgsBonuses(payload[egsKey]);
                        break;
                    default:
                        setSportBonuses(payload[sportKey]);
                        setEgsBonuses(payload[egsKey]);
                        break;
                }
                if (bonusCampaignType.value !== bonusCampaignTypeValue) setBonusCampaignType(bonusCampaignTypeValue);
                if (payload.hideOnBoardingPage) setHideOnBoardingPageState(true);
            }
        } finally{
            if (loading.value) setLoading(false);
        }
    }
    async function doWithdrawSportBonus(campaignId) {
        const response = await doWithdrawSportBonusApi(campaignId);
        if (!response.withdrawnAmount || 0 === response.withdrawnAmount) throw new Error($translate('WEB2_SPORT_BONUS_EXCHANGE_ERROR_MESSAGE').value);
        return response;
    }
    async function collectBonus(options) {
        const { bonus } = options;
        switch(bonus.campaignType){
            case BonusCampaignType.SPORT:
                await doWithdrawSportBonus(bonus.campaignId);
                setSportBonuses(sportBonuses.value.filter((param)=>{
                    let { bonusId } = param;
                    return bonus.bonusId !== bonusId;
                }));
                break;
            case BonusCampaignType.EGS:
                await doWithdrawBonusCBC();
                setEgsBonuses(egsBonuses.value.filter((param)=>{
                    let { bonusId } = param;
                    return bonus.bonusId !== bonusId;
                }));
                break;
            default:
                logger.error('Failed to collect bonus: BonusCampaignType is missing');
                break;
        }
    }
    async function refuseBonus(options) {
        return doRefuseBonusApi(options);
    }
    function getBonusMatrix(options) {
        return fetchSdkBonusMatrix(options);
    }
    function switchOnboardingTopBarIcon(value) {
        setOnboardingIconState(value);
    }
    return {
        loading,
        sportBonuses,
        egsBonuses,
        bonusCampaignType,
        isBonusPageVisited,
        isOnboardingIconShown,
        hideOnBoardingPage,
        complexData,
        initialTab,
        activeTab,
        waitingRefreshDelay,
        setIsBonusesPageVisited,
        setHideOnBoardingPageState,
        setComplexData,
        fetchBonuses,
        collectBonus,
        refuseBonus,
        getBonusMatrix,
        switchOnboardingTopBarIcon,
        setActiveTab,
        setWaitingRefreshDelay
    };
});
export default useProfileBonusesStore;
