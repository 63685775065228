import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { toRef, useSlots } from 'vue';
import { Tag } from '@leon-hub/tags';
import { IconSize } from '@leon-hub/icons';
import { VSpinnerColor } from '@components/spinner';
import { useAdventButton } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventButton',
    props: {
        disabled: {
            type: Boolean
        },
        fullWidth: {
            type: Boolean
        },
        href: {},
        iconName: {},
        iconRight: {
            type: Boolean
        },
        id: {},
        isLoading: {
            type: Boolean
        },
        kind: {
            default: 'primary'
        },
        label: {
            default: ''
        },
        role: {
            default: 'button'
        },
        size: {
            default: 'large'
        },
        tag: {
            default: Tag.BUTTON
        },
        target: {},
        type: {}
    },
    emits: [
        "click",
        "keydown",
        "mousedown",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const isDisabledState = toRef(()=>props.disabled || void 0);
        const iconSize = toRef(()=>{
            switch(props.size){
                case 'large':
                    return IconSize.SIZE_28;
                case 'medium':
                    return IconSize.SIZE_20;
                case 'small':
                default:
                    return IconSize.SIZE_16;
            }
        });
        const slots = useSlots();
        const { isIconOnly, isIconRight, mainTag, computedAttributes, iconComponent } = useAdventButton(props, slots);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), _mergeProps(_unref(computedAttributes), {
                id: _ctx.id,
                ref_key: "mainTag",
                ref: mainTag,
                class: {
                    [_ctx.$style['advent-button']]: true,
                    [_ctx.$style[`advent-button--size-${_ctx.size}`]]: _ctx.size,
                    [_ctx.$style[`advent-button--kind-${_ctx.kind}`]]: _ctx.kind,
                    [_ctx.$style['advent-button--icon-only']]: _unref(isIconOnly),
                    [_ctx.$style['advent-button--icon-right']]: _unref(isIconRight),
                    [_ctx.$style['advent-button--full-width']]: _ctx.fullWidth,
                    [_ctx.$style['advent-button--is-loading']]: _ctx.isLoading
                },
                disabled: isDisabledState.value,
                role: _ctx.role,
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('click', $event)),
                onKeydown: _cache[1] || (_cache[1] = ($event)=>emit('keydown', $event)),
                onMousedown: _cache[2] || (_cache[2] = ($event)=>emit('mousedown', $event))
            }), {
                default: _withCtx(()=>[
                        _unref(iconComponent) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(iconComponent)), {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['advent-button__icon']),
                            size: iconSize.value,
                            name: _ctx.iconName,
                            color: _unref(VSpinnerColor).PARENT,
                            kind: "custom"
                        }, null, 8, [
                            "class",
                            "size",
                            "name",
                            "color"
                        ])) : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "default", {}, ()=>[
                                _unref(isIconOnly) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['advent-button__label'])
                                }, _toDisplayString(_ctx.label), 3))
                            ])
                    ]),
                _: 3
            }, 16, [
                "id",
                "class",
                "disabled",
                "role"
            ])), [
                [
                    _directive_auto_id,
                    'AdventButton'
                ]
            ]);
        };
    }
});
