import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { isNumber, isObject } from '@leon-hub/guards';
import { localStorageManager } from '@leon-hub/local-storage';
import { KEY_VALUE, KEY_CHANGED, KEY_EXPIRES } from './constants';
import { log } from './utils';
function isParsedStorageItem(item) {
    isObject(item);
    isNumber(item[KEY_CHANGED]);
    KEY_EXPIRES in item && isNumber(item[KEY_EXPIRES]);
    return true;
}
export class Storage {
    getItem() {
        if (this.cache) return {
            exists: true,
            value: this.cache
        };
        const { id } = this.options;
        const rawValue = localStorageManager.getItem(id);
        if ('string' == typeof rawValue) try {
            const storageItem = JSON.parse(rawValue);
            if (isParsedStorageItem(storageItem)) {
                const { [KEY_VALUE]: value } = storageItem;
                if (this.options.guard(value)) return {
                    value,
                    exists: true
                };
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(`Unable to parse "${id}" storage value: ${rawValue}`, err);
        }
        return {
            exists: false
        };
    }
    get() {
        return Promise.resolve(this.getItem());
    }
    set(value) {
        let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        const { expires } = options;
        const { id } = this.options;
        log(`writing "${id}" storage value: %o using options: %o`, value, options);
        this.options.guard(value);
        localStorageManager.setItem(id, JSON.stringify({
            [KEY_VALUE]: value,
            [KEY_CHANGED]: Date.now(),
            [KEY_EXPIRES]: expires
        }));
        this.setCache(value);
        return Promise.resolve();
    }
    clear() {
        this.cache = void 0;
        localStorageManager.removeItem(this.options.id);
        return Promise.resolve();
    }
    setCache(value) {
        this.cache = value;
    }
    constructor(options){
        _define_property(this, "options", void 0);
        _define_property(this, "cache", void 0);
        this.options = options;
        this.cache = void 0;
        try {
            const result = this.getItem();
            if (result.exists) this.setCache(result.value);
        } catch  {}
    }
}
