import { AbstractError } from '@leon-hub/errors';
import FileReaderErrorCode from './FileReaderErrorCode';
const fileReaderErrorCode = new FileReaderErrorCode();
let FileReaderError = class FileReaderError extends AbstractError {
    constructor(options){
        super({
            ...options,
            code: fileReaderErrorCode,
            message: 'BTR_WEB2_FILE_READER_ERROR'
        });
    }
} // TODO: move translation service into leon-hub
;
export { FileReaderError as default };
