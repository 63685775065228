import { computed } from 'vue';
import isVPhotoInputProps from 'web/src/components/PhotoInput/VPhotoInput/guards/isVPhotoInputProps';
export default function useFormPhotoInput(props) {
    const componentProps = computed(()=>{
        const { name, options } = props;
        const result = {
            ...options || {},
            name
        };
        isVPhotoInputProps(result);
        return result;
    });
    return {
        componentProps
    };
}
