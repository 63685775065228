import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import SList from 'web/src/components/List/SList/SList.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';
import { ListType } from 'web/src/components/List/enums';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import useProfileNavList from './composables/useProfileNavList';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavList',
    props: {
        items: {},
        oneLevelList: {
            type: Boolean
        }
    },
    setup (__props) {
        const { getIconSettings, getBadgeLabel, checkHasNewNotifications, getCounterLabel, onClick, getBonusesValue } = useProfileNavList();
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _openBlock(), _createBlock(VList, {
                key: 1,
                "list-type": _unref(ListType).GROUP
            }, {
                default: _withCtx(()=>[
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item)=>_withDirectives((_openBlock(), _createBlock(VListItem, {
                                key: item.id,
                                "icon-name": _unref(getIconSettings)(item),
                                "is-new": _unref(checkHasNewNotifications)(item),
                                title: item.caption,
                                "have-expand": !item.done,
                                "badge-label": _unref(getBadgeLabel)(item),
                                counter: _unref(getCounterLabel)(item),
                                "suffix-text": _unref(getBonusesValue)(item),
                                "icon-colored": !_ctx.oneLevelList && true,
                                "icon-custom-styles": item.props && item.props?.iconStyle ? `${item.props.iconStyle}` : '',
                                onClick: ($event)=>_unref(onClick)(item)
                            }, null, 8, [
                                "icon-name",
                                "is-new",
                                "title",
                                "have-expand",
                                "badge-label",
                                "counter",
                                "suffix-text",
                                "icon-colored",
                                "icon-custom-styles",
                                "onClick"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'navigation-item',
                                        navigationId: item.id
                                    }
                                ]
                            ])), 128))
                    ]),
                _: 1
            }, 8, [
                "list-type"
            ]);
        };
    }
});
