import { computed } from 'vue';
import isFileMultipleDragAndDropInputProperties from 'web/src/components/Input/guards/isFileMultipleDragAndDropInputProperties';
import getWrapperHintProperties from '../../utils/getWrapperHintProperties';
export default function useFormFileDragAndDropInput(props) {
    const componentProps = computed(()=>{
        const { name, options, error, disabled, hintMessage } = props;
        isFileMultipleDragAndDropInputProperties(options);
        let errorString = error;
        let errorsArray = [];
        if (Array.isArray(error)) {
            errorsArray = error;
            errorString = error.map(String).filter((item)=>!!item).join(', ');
        }
        return {
            ...options,
            ...getWrapperHintProperties({
                options,
                error: errorString,
                hintMessage
            }),
            name,
            formErrors: errorsArray,
            disabled
        };
    });
    return {
        componentProps
    };
}
