import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import StatisticLeagueStandings from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandings.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticLeagueStandingBlock',
    props: {
        teams: {},
        leagueStandings: {},
        alwaysExpanded: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const teams = toRef(props, 'teams', null);
        const leagueStandings = toRef(props, 'leagueStandings', []);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        leagueStandings.value.length > 0 ? (_openBlock(), _createBlock(VListItemAccordion, {
                            key: 0,
                            id: "league-standings",
                            title: _ctx.$t('WEB2_MATCH_STATISTIC_TOURNAMENT_TABLE'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", null, [
                                        _createVNode(StatisticLeagueStandings, {
                                            "league-standings": leagueStandings.value,
                                            teams: teams.value,
                                            "always-expanded": _ctx.alwaysExpanded
                                        }, null, 8, [
                                            "league-standings",
                                            "teams",
                                            "always-expanded"
                                        ])
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'StatisticLeagueStandingBlock'
                ]
            ]);
        };
    }
});
