import { logger } from '@leon-hub/logging';
import { isString, isUndefined } from '@leon-hub/guards';
import { getMarketTypeColumnsCount } from './getMarketTypeColumnsCount';
// first level is columns count in server data
// second level is local result
const runnerPlaceMap = {
    2: {
        2: [
            0,
            1
        ],
        3: [
            0,
            2
        ]
    },
    3: {
        2: [
            0,
            1,
            2
        ],
        // prefer sport actualRunnersCount > defaultSportCount
        3: [
            0,
            1,
            2
        ]
    }
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export function placeRunnersToColumns(param, options) {
    let { marketType, market, sportFamily } = param;
    const preferSportCount = options?.preferSportCount ?? false;
    const actualRunners = market?.runners ?? Array.from({
        length: marketType?.columns || 3
    }).fill(null);
    const actualMarketType = market?.type ?? marketType ?? null;
    const resultSize = getMarketTypeColumnsCount({
        marketType: actualMarketType,
        sportFamily: sportFamily ?? void 0,
        runnersCount: actualRunners.length
    }, {
        preferSportCount
    });
    const result = Array.from({
        length: resultSize
    }).fill(null);
    const typeSize = actualMarketType?.columns || result.length;
    if (typeSize) {
        runnerPlaceMap[typeSize], market?.eventId;
        runnerPlaceMap[typeSize][resultSize], market?.eventId;
    }
    for (const [index, runner] of Object.entries(actualRunners)){
        const column = isString(runner) || !runner || isUndefined(runner?.column) ? Number(index) : runner?.column;
        let place;
        if (typeSize) place = runnerPlaceMap[typeSize][resultSize][column];
        else {
            logger.warn(`Empty market type size for event ${market?.eventId}`);
            result[column] = runner;
        }
        if (!result[place]) result[place] = runner;
    }
    return result;
}
