import RouteName from './RouteName';
export default RouteName;
const baseRouteNameNames = Object.values(RouteName);
export function isBaseRouteName(value) {
    return baseRouteNameNames.includes(value);
}
export { default as isEGSRoute } from './isEGSRoute';
export { default as CasinoRouteName } from './CasinoRouteName';
export { isPromoRouteName } from './PromoRouteName';
export { default as isSportlineRoute } from './isSportlineRoute';
