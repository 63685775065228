import { computed, ref } from 'vue';
import { useLocalStorageManager } from '@leon-hub/local-storage';
function useMarketsListLocalStorageMethods(props) {
    const closedMarketsGroupsStorageKeyPrefix = 'sport-event-group-state';
    const localStorageManager = useLocalStorageManager();
    const closedMarketsGroupsStorageKey = computed(// eslint-disable-next-line max-len
    ()=>`${closedMarketsGroupsStorageKeyPrefix}-${props.representationSportFamily?.value?.toLowerCase() || 'unknown'}`);
    function saveClosedMarketGroupsToStorage(closedMarketGroups) {
        localStorageManager.setItem(closedMarketsGroupsStorageKey.value, JSON.stringify(closedMarketGroups));
    }
    function loadClosedMarketGroupsFromStorage() {
        try {
            const items = localStorageManager.getItem(closedMarketsGroupsStorageKey.value);
            return items ? JSON.parse(items) : [];
        } catch  {
            return [];
        }
    }
    return {
        closedMarketsGroupsStorageKey,
        saveClosedMarketGroupsToStorage,
        loadClosedMarketGroupsFromStorage
    };
}
export default function useMarketsListOpenStateStorage(props) {
    const { closedMarketsGroupsStorageKey, saveClosedMarketGroupsToStorage, loadClosedMarketGroupsFromStorage } = useMarketsListLocalStorageMethods(props);
    const closedMarketGroups = ref(loadClosedMarketGroupsFromStorage());
    function toggleMarketGroupClosedStateInTheStorage(groupKey) {
        const closedMarketGroupsUpdated = [
            ...closedMarketGroups.value
        ];
        const existingIndex = closedMarketGroupsUpdated.indexOf(groupKey);
        if (existingIndex > -1) closedMarketGroupsUpdated.splice(existingIndex, 1);
        else closedMarketGroupsUpdated.push(groupKey);
        closedMarketGroups.value = closedMarketGroupsUpdated;
        saveClosedMarketGroupsToStorage(closedMarketGroupsUpdated);
    }
    return {
        closedMarketGroups,
        closedMarketsGroupsStorageKey,
        toggleMarketGroupClosedStateInTheStorage,
        saveClosedMarketGroupsToStorage,
        loadClosedMarketGroupsFromStorage
    };
}
