import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { defineAsyncComponent, onBeforeMount } from 'vue';
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import MainBalance from 'web/src/modules/profile/components/MainBalance/MainBalance.vue';
import PaymentsModalsController from 'web/src/modules/payments/components/PaymnetsModalsController/PaymentsModalsController.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import BalanceSwiperNavigation from 'web/src/modules/profile/components/BalanceSwiperNavigation/BalanceSwiperNavigation.vue';
import { VEmpty } from 'web/src/components/Empty';
import { PaymentsModalType } from 'web/src/modules/payments/enums';
import { useBalanceUser } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BalanceUser',
    props: {
        mode: {
            default: BalanceMode.Single
        },
        isInProfile: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const CBCBalance = defineAsyncComponent(()=>import('web/src/modules/profile/components/CBCBalance/CBCBalance.vue'));
        const CashbackBalance = defineAsyncComponent(()=>import('web/src/modules/profile/components/CashbackBalance/CashbackBalance.vue'));
        const { isSuccessModal, balanceSnapshot, isSingleMode, isMultipleMode, emitClose, handleCloseSuccessModal, onWithdrawBonus, beforeMount } = useBalanceUser(props, emit);
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['balance']]: true,
                    [_ctx.$style['balance--modal']]: !_ctx.isInProfile
                })
            }, [
                _unref(isSingleMode) ? (_openBlock(), _createBlock(MainBalance, {
                    key: 0,
                    mode: _ctx.mode,
                    onOpenDeposit: _unref(emitClose)
                }, null, 8, [
                    "mode",
                    "onOpenDeposit"
                ])) : _unref(isMultipleMode) ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['balance__multiple-wrapper'])
                }, [
                    _createVNode(MainBalance, {
                        mode: _ctx.mode,
                        onOpenDeposit: _unref(emitClose)
                    }, null, 8, [
                        "mode",
                        "onOpenDeposit"
                    ]),
                    _createVNode(_unref(CBCBalance), {
                        mode: _ctx.mode,
                        "is-in-profile": _ctx.isInProfile,
                        onWithdrawBonus: _unref(onWithdrawBonus),
                        onClickCard: _unref(emitClose)
                    }, null, 8, [
                        "mode",
                        "is-in-profile",
                        "onWithdrawBonus",
                        "onClickCard"
                    ]),
                    (_openBlock(), _createBlock(_unref(CashbackBalance), {
                        key: 0
                    }))
                ], 2)) : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['balance__swiper'])
                }, [
                    _createVNode(_unref(VSwiper), {
                        ref: "swiper",
                        class: _normalizeClass(_ctx.$style['balance__swiper-list'])
                    }, _createSlots({
                        default: _withCtx(()=>[
                                _createVNode(VSwiperSlide, {
                                    class: _normalizeClass(_ctx.$style['balance__swiper-item'])
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(MainBalance, {
                                                mode: _unref(BalanceMode).Slider,
                                                onOpenDeposit: _unref(emitClose)
                                            }, null, 8, [
                                                "mode",
                                                "onOpenDeposit"
                                            ])
                                        ]),
                                    _: 1
                                }, 8, [
                                    "class"
                                ]),
                                _createVNode(VSwiperSlide, {
                                    class: _normalizeClass(_ctx.$style['balance__swiper-item'])
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(_unref(CBCBalance), {
                                                mode: "slider",
                                                "is-in-profile": _ctx.isInProfile,
                                                onWithdrawBonus: _unref(onWithdrawBonus),
                                                onClickCard: _unref(emitClose)
                                            }, null, 8, [
                                                "is-in-profile",
                                                "onWithdrawBonus",
                                                "onClickCard"
                                            ])
                                        ]),
                                    _: 1
                                }, 8, [
                                    "class"
                                ]),
                                _createVNode(VSwiperSlide, {
                                    class: _normalizeClass(_ctx.$style['balance__swiper-item'])
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(_unref(CashbackBalance))
                                        ]),
                                    _: 1
                                }, 8, [
                                    "class"
                                ])
                            ]),
                        _: 2
                    }, [
                        {
                            name: "pagination-header",
                            fn: _withCtx(()=>[
                                    _createVNode(BalanceSwiperNavigation)
                                ]),
                            key: "0"
                        }
                    ]), 1032, [
                        "class"
                    ])
                ], 2)),
                _unref(isSuccessModal) ? (_openBlock(), _createBlock(PaymentsModalsController, {
                    key: 3,
                    "modal-type": _unref(PaymentsModalType).DEPOSITS_OTHER_SUCCESS_MODAL,
                    "modal-text": _unref(balanceSnapshot),
                    "is-in-profile": _ctx.isInProfile,
                    onClose: _unref(handleCloseSuccessModal)
                }, null, 8, [
                    "modal-type",
                    "modal-text",
                    "is-in-profile",
                    "onClose"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BalanceUser'
                ]
            ]);
        };
    }
});
