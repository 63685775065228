import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { HistoryFilter } from '@leon-hub/api-sdk';
import { TargetParamWithdrawal } from '@leon-hub/yandex-metrika';
import RouteName from '@leon-hub/routing-config';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useHomePageType from 'web/src/modules/core/store/composables/useHomePageType';
import { useI18n } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line max-len
export default function useCustomerHistoryListEmpty(props) {
    const router = useRouter();
    const { $translate } = useI18n();
    const analytics = useAnalytics();
    const homePageType = useHomePageType();
    const emptyText = computed(()=>{
        switch(props.filter){
            case HistoryFilter.ALL:
                return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL').value;
            case HistoryFilter.ALL_BETS:
                return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_BETS').value;
            case HistoryFilter.ALL_DEPOSITS:
                return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_DEPOSITS').value;
            case HistoryFilter.ALL_WITHDRAWALS:
                return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_WITHDRAWALS').value;
            case HistoryFilter.ALL_BONUS:
                return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_BONUSES').value;
            case HistoryFilter.ALL_EGS:
                return $translate('WEB2_CUSTOMER_HISTORY_NOT_FOUND_ALL_EGS').value;
            case HistoryFilter.LEONSHOP:
                return $translate('WEB2_SHOP_NO_HISTORY').value;
            default:
                return '';
        }
    });
    const buttonLabel = computed(()=>{
        switch(props.filter){
            case HistoryFilter.ALL_BETS:
                return $translate('WEB2_CUSTOMER_HISTORY_MAKE_BET').value;
            case HistoryFilter.ALL_DEPOSITS:
                return $translate('WEB2_CUSTOMER_HISTORY_REPLENISH').value;
            case HistoryFilter.ALL_WITHDRAWALS:
                return $translate('WEB2_CUSTOMER_HISTORY_PAYMENTS').value;
            case HistoryFilter.ALL:
                return $translate('WEB2_CUSTOMER_HISTORY_MAKE_BET').value;
            case HistoryFilter.ALL_BONUS:
                return $translate('WEB2_CUSTOMER_HISTORY_TAKE_BONUS').value;
            case HistoryFilter.ALL_EGS:
                return $translate('WEB2_CUSTOMER_HISTORY_TO_SLOTS').value;
            case HistoryFilter.LEONSHOP:
                return $translate('WEB2_GO_TO_SHOP').value;
            default:
                return '';
        }
    });
    function buttonClick() {
        let name;
        let params = null;
        switch(props.filter){
            case HistoryFilter.ALL:
            case HistoryFilter.ALL_BETS:
                name = homePageType.routeNameToSportLine.value;
                break;
            case HistoryFilter.ALL_WITHDRAWALS:
                analytics.clickMap({
                    withdrawal: TargetParamWithdrawal.USER_PROFILE_HISTORY
                });
                name = RouteName.WITHDRAWALS;
                break;
            case HistoryFilter.ALL_DEPOSITS:
                analytics.clickMap({
                    deposit: 'userProfileHistoryDeposits'
                });
                name = RouteName.DEPOSITS;
                break;
            case HistoryFilter.ALL_BONUS:
                name = RouteName.PROMOTIONS;
                params = {
                    categoryId: 'bonuses'
                };
                break;
            case HistoryFilter.ALL_EGS:
                name = CasinoRouteName.CASINO_LOBBY;
                break;
            case HistoryFilter.LEONSHOP:
                name = RouteName.LOYALTY_PROGRAM;
                break;
            default:
                name = null;
                break;
        }
        if (name) {
            const routerParams = params ? {
                name,
                params: {
                    ...params
                }
            } : {
                name
            };
            router.push(routerParams);
        }
    }
    const isHideButtonForSlott = computed(()=>false);
    return {
        buttonLabel,
        emptyText,
        isHideButtonForSlott,
        buttonClick
    };
}
