import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { VJumbotron, JumbotronFooterButton } from 'web/src/components/Jumbotron';
import ErrorHeaderBar from '../ErrorHeaderBar/ErrorHeaderBar.vue';
import { useBaseErrorContent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BaseErrorContent',
    props: {
        iconName: {},
        title: {},
        text: {},
        isEmailAsLabel: {
            type: Boolean
        },
        isSupportHidden: {
            type: Boolean
        },
        isMainButtonHidden: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { mainJumbotronProps, mainButtonProps, supportJumbotronProps, supportButtonProps, onMainClick, onSupportClick } = useBaseErrorContent(props);
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(ErrorHeaderBar),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['error-app'])
                }, [
                    _createVNode(_unref(VJumbotron), _mergeProps(_unref(mainJumbotronProps), {
                        class: _ctx.$style['error-app__info']
                    }), _createSlots({
                        _: 2
                    }, [
                        _ctx.isMainButtonHidden ? void 0 : {
                            name: "footer",
                            fn: _withCtx(()=>[
                                    _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(mainButtonProps), {
                                        onClick: _unref(onMainClick)
                                    }), null, 16, [
                                        "onClick"
                                    ])
                                ]),
                            key: "0"
                        }
                    ]), 1040, [
                        "class"
                    ]),
                    _ctx.isSupportHidden ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps({
                        key: 0
                    }, _unref(supportJumbotronProps), {
                        class: _ctx.$style['error-app__support']
                    }), {
                        footer: _withCtx(()=>[
                                _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(supportButtonProps), {
                                    onClick: _unref(onSupportClick)
                                }), null, 16, [
                                    "onClick"
                                ])
                            ]),
                        _: 1
                    }, 16, [
                        "class"
                    ]))
                ], 2)
            ], 64));
    }
});
