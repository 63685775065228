import { ref, computed } from 'vue';
import { isNumber } from '@leon-hub/guards';
export default function useFilterTransition(props, emits) {
    const activeTransitionId = ref(null);
    const nextTransitionId = ref(null);
    const isFadeOutTransition = ref(false);
    const isTransitionEnabled = ref(false);
    const isTransitionActive = ref(false);
    const transitionName = computed(()=>props.disabled ? 'none' : 'fade');
    const transitionDuration = computed(()=>props.disabled ? 0 : {
            enter: props.fadeInDuration,
            leave: props.fadeOutDuration
        });
    const transitionStyles = computed(()=>{
        let duration = transitionDuration.value;
        if (!isNumber(duration)) duration = isFadeOutTransition.value ? duration.leave : duration.enter;
        return {
            'transition-duration': `${duration / 1000}s`
        };
    });
    function setActiveTransitionId(id) {
        activeTransitionId.value = id;
        emits.setActiveTransitionId(id);
    }
    function transitionStart(id) {
        if (props.disabled) return;
        if (!isTransitionEnabled.value) return;
        isTransitionActive.value = true;
        setActiveTransitionId(id);
        isFadeOutTransition.value = true;
    }
    function transitionComplete() {
        if (props.disabled) return;
        isTransitionActive.value = false;
        setActiveTransitionId(null);
        nextTransitionId.value = null;
    }
    function beforeFadeIn(id) {
        nextTransitionId.value = id;
    }
    function fadeOutComplete() {
        if (props.disabled) return;
        isFadeOutTransition.value = false;
        setActiveTransitionId(nextTransitionId.value);
        nextTransitionId.value = null;
    }
    function getActiveTransitionId() {
        return activeTransitionId.value;
    }
    function isActive() {
        return isTransitionActive.value;
    }
    function enable() {
        isTransitionEnabled.value = true;
    }
    function disable() {
        isTransitionEnabled.value = false;
    }
    return {
        transitionName,
        transitionDuration,
        transitionStyles,
        activeTransitionId,
        nextTransitionId,
        isFadeOutTransition,
        isTransitionEnabled,
        isTransitionActive,
        transitionStart,
        transitionComplete,
        beforeFadeIn,
        fadeOutComplete,
        getActiveTransitionId,
        isActive,
        enable,
        disable
    };
}
