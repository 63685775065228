import { toRef, computed } from 'vue';
import { isLiveSportlineEvent, isPrematchSportlineEvent, isOutrightSportlineEvent } from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';
export function useSportlineEventTypeRef(sportlineEvent) {
    const sportlineEventRef = toRef(sportlineEvent);
    return computed(()=>sportlineEventRef.value?.type ?? null);
}
export function useSportlineEventType(props) {
    const { sportEvent } = props;
    const sportlineEventId = computed(()=>sportEvent.value?.id);
    const sportlineType = computed(()=>sportEvent.value?.type);
    const isLive = computed(()=>isLiveSportlineEvent(sportEvent.value));
    const isPrematch = computed(()=>isPrematchSportlineEvent(sportEvent.value));
    const isOutright = computed(()=>isOutrightSportlineEvent(sportEvent.value));
    return {
        sportlineEventId,
        sportlineType,
        isLive,
        isPrematch,
        isOutright
    };
}
