import { InputEventType } from 'web/src/components/Input/enums';
const useFormSwitchEvents = (emit, name)=>{
    const emitInput = (value)=>{
        emit(InputEventType.INPUT, {
            value,
            name
        });
    };
    const emitChange = (value)=>{
        emit(InputEventType.CHANGE, {
            value,
            name
        });
    };
    const emitFocus = (value)=>{
        emit(InputEventType.FOCUS, {
            value,
            name
        });
    };
    const emitBlur = (value)=>{
        emit(InputEventType.BLUR, {
            value,
            name
        });
    };
    return {
        emitChange,
        emitInput,
        emitFocus,
        emitBlur
    };
};
export default useFormSwitchEvents;
