import { logger as loggerInstance } from '@leon-hub/logging';
import { CustomerFieldType } from '@leon-hub/api-sdk';
import { CustomCustomerFieldType } from 'web/src/modules/registration/enums';
import { isFormPhoneValue } from 'web/src/components/Form/guards';
const logger = loggerInstance.createNamespace('updateFieldErrorByFormError');
export default function updateFieldErrorByFormError(formData, fieldType, uiFormSchema) {
    let customerErrors = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {}, message = arguments.length > 4 ? arguments[4] : void 0;
    let validationMessage;
    if (message) validationMessage = message;
    else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- keep it for now
        const fieldInput = uiFormSchema?.fields.find((field)=>field.id === fieldType);
        validationMessage = fieldInput?.error;
        if (!validationMessage) return customerErrors;
    }
    const value = formData[fieldType];
    if (fieldType === CustomerFieldType.PHONE_INPUT && !isFormPhoneValue(value)) {
        logger.warn('Unexpected phone value', value);
        return customerErrors;
    }
    const field = fieldType === CustomerFieldType.PHONE_INPUT ? CustomCustomerFieldType.PHONE_INPUT_SUFFIX : fieldType;
    const valueReturn = isFormPhoneValue(value) ? value?.suffix : formData[fieldType];
    return {
        ...customerErrors,
        [field]: [
            {
                message: validationMessage,
                value: valueReturn
            }
        ]
    };
}
