import { defineStore } from 'pinia';
import { ref } from 'vue';
import { lobbyTypeSlots } from '@leon-hub/api-sdk';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { EgsGamesCategoryId } from 'web/src/modules/egs/enums';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
const useEgsHistoryGamesStore = defineStore('egs-history-games', ()=>{
    const { getGames } = useEgsApi();
    const games = ref([]);
    const { updateGames } = useEgsGamesStore();
    async function loadHistoryGames() {
        const data = await getGames({
            type: lobbyTypeSlots,
            category: EgsGamesCategoryId.HISTORY_GAMES
        }, true);
        games.value = data.games;
        updateGames(data.games);
    }
    function updateHistoryGame(game) {
        games.value = [
            game,
            ...games.value.filter((filterGame)=>game.id !== filterGame.id)
        ];
    }
    return {
        games,
        loadHistoryGames,
        updateHistoryGame
    };
});
export default useEgsHistoryGamesStore;
