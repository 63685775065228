import { nextTick, computed, ref } from 'vue';
import { getLocationQueryParams } from '@leon-hub/service-locator-env';
function isNativeLazyMethod() {
    const value = getLocationQueryParams()['feature-lazy-img-type'];
    if ('observer' === value) return false;
    if ('native' === value) return true;
    return 'loading' in HTMLImageElement.prototype;
}
export default function useVLazyImage(props, emit) {
    let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const initial = ref(!!options.initial);
    const imageLoadCompleted = ref(false);
    function visibilityChanged(isVisible) {
        if (isVisible) initial.value = true;
    }
    function forceShowImage() {
        initial.value = true;
    }
    async function onImageLoad() {
        if (isNativeLazyMethod()) visibilityChanged(true);
        await nextTick();
        // await this.$nextAnimationFrame();
        imageLoadCompleted.value = true;
        emit('image-loaded');
    }
    const showImage = computed(()=>isNativeLazyMethod() || initial.value && !props.forceHideImage);
    return {
        isNativeLazyMethod,
        imageLoadCompleted,
        visibilityChanged,
        forceShowImage,
        onImageLoad,
        showImage
    };
}
