function getStars(number) {
    return number ? '*'.repeat(number) : '';
}
// eslint-disable-next-line import/prefer-default-export
export function phoneNumberStarModifier(param) {
    let { prefix = '', suffix = '' } = param;
    let phoneCode = '';
    let phoneStart = getStars(3);
    let phoneMiddle = getStars(2);
    let phoneEnd = suffix.slice(5);
    if (suffix.length >= 9) {
        phoneCode = `(${suffix.slice(0, 3)})`;
        phoneStart = getStars(3);
        phoneMiddle = getStars(suffix.length - 8);
        phoneEnd = suffix.slice(-2);
    }
    const dash = suffix.length > 5 ? '-' : '';
    return `+${prefix} ${phoneCode} ${phoneStart}-${phoneMiddle}${dash}${phoneEnd}`;
}
