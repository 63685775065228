import { computed, ref, toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { defaultListFilter } from 'web/src/modules/sportline/constants';
import { getFilterDateTimeInterval, getSportDateTimeFilterByValue } from 'web/src/modules/sportline/utils';
export function useSportsListDateFilterStoreComposable() {
    const sportlineSettingsStore = useSportlineSettingsStore();
    const doUseRelativeTimeForSportline = toRef(sportlineSettingsStore, 'doUseRelativeTimeForSportline');
    const dateTimeFilter = ref(defaultListFilter);
    /**
   * Get actual filters for requests
   * Used function because getter will cache return value
   * https://jira.leoncorp.net/browse/LEONWEB-1921
   */ const dateTimeFilterInterval = computed(()=>getFilterDateTimeInterval({
            activeKickoffPeriod: dateTimeFilter.value.activeKickoffPeriod,
            sportsListFilter: dateTimeFilter.value,
            doUseRelativeTimeForSportline: doUseRelativeTimeForSportline.value
        }));
    const dateTimeFilterAbsoluteInterval = computed(()=>getFilterDateTimeInterval({
            activeKickoffPeriod: dateTimeFilter.value.activeKickoffPeriod,
            sportsListFilter: dateTimeFilter.value,
            doUseRelativeTimeForSportline: false
        }));
    function setDateTimeFilter(value) {
        dateTimeFilter.value = getSportDateTimeFilterByValue(value) ?? defaultListFilter;
    }
    return {
        dateTimeFilter,
        dateTimeFilterInterval,
        dateTimeFilterAbsoluteInterval,
        setDateTimeFilter
    };
}
