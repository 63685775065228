import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateCustomerCard',
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-customer-card']),
                onClick: _cache[0] || (_cache[0] = ($event)=>emits('click'))
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-customer-card__inner'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['general-info'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['bonus-code__top-text']]: true,
                                [_ctx.$style['affiliate-customer-card__top-left']]: true
                            })
                        }, [
                            _renderSlot(_ctx.$slots, "top-left")
                        ], 2),
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['bonus-code__bottom-text']]: true,
                                [_ctx.$style['affiliate-customer-card__bottom-left']]: true
                            })
                        }, [
                            _renderSlot(_ctx.$slots, "bottom-left")
                        ], 2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bonus-code'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['bonus-code__top-text']]: true,
                                [_ctx.$style['affiliate-customer-card__top-right']]: true
                            })
                        }, [
                            _renderSlot(_ctx.$slots, "top-right")
                        ], 2),
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['bonus-code__bottom-text']]: true,
                                [_ctx.$style['affiliate-customer-card__bottom-right']]: true
                            })
                        }, [
                            _renderSlot(_ctx.$slots, "bottom-right")
                        ], 2)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateCustomerCard'
                ]
            ]);
        };
    }
});
