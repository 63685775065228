/* eslint-disable no-param-reassign */ import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { createPopper } from '@popperjs/core';
import { PopperPosition } from '../VPopper/enums';
let PopperHint = class PopperHint {
    updateTitle(title) {
        this.title = title;
    }
    destroy() {
        this.destroyPopper();
        this.element.title = '';
        this.element.removeEventListener('click', this.createPopper.bind(this));
    }
    createPopper() {
        if (this.instance) return;
        if (this.content) return;
        this.content = document.createElement('div');
        this.content.innerHTML = this.title;
        this.content.classList.add('app-hint-popup');
        document.body.appendChild(this.content);
        this.instance = createPopper(this.element, this.content, this.popperOptions);
        window.setTimeout(()=>{
            document.addEventListener('click', this.destroyPopper.bind(this), {
                once: true
            });
        }, 0);
    }
    destroyPopper() {
        document.removeEventListener('click', this.destroyPopper.bind(this));
        this.instance?.destroy();
        this.instance = void 0;
        if (this.content) {
            document.body.removeChild(this.content);
            this.content = void 0;
        }
    }
    constructor(element, title){
        _define_property(this, "element", void 0);
        _define_property(this, "title", void 0);
        _define_property(this, "content", void 0);
        _define_property(this, "instance", void 0);
        _define_property(this, "popperOptions", void 0);
        this.element = element;
        this.title = title;
        this.popperOptions = {
            placement: PopperPosition.AUTO,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [
                            0,
                            4
                        ]
                    }
                }
            ]
        };
        this.element.title = title;
        this.element.addEventListener('click', this.createPopper.bind(this));
    }
};
function addPopperHint(element, binding) {
    if (binding.oldValue === binding.value) return;
    if (!binding.value) {
        element.popperHint?.destroy();
        return;
    }
    if (element.popperHint) {
        element.popperHint.updateTitle(binding.value);
        return;
    }
    element.popperHint = new PopperHint(element, binding.value);
}
function removePopperHint(element) {
    element.popperHint?.destroy();
    element.popperHint = void 0;
}
const popperHint = {
    beforeMount: addPopperHint,
    updated: addPopperHint,
    beforeUnmount: removePopperHint
};
export default popperHint;
