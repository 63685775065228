import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useVReCaptchaBadge(props) {
    useI18n();
    const termsLink = computed(()=>props.termsLinkTemplate?.replace('{lang}', props.lang));
    const privacyLink = computed(()=>props.privacyLinkTemplate?.replace('{lang}', props.lang));
    return {
        termsLink,
        privacyLink
    };
}
