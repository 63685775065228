import { computed, onBeforeUnmount, ref } from 'vue';
import { isNullOrUndefined } from '@leon-hub/guards';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { CaptchaType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import ReCaptchaPostMessageEvent from 'web/src/modules/captcha/components/types/ReCaptchaPostMessageEvent';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import { getContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import { useI18nLocale } from 'web/src/modules/i18n/composables';
import { getAdditionalQueryStringParameters, getIframeWidgetSrc } from 'web/src/modules/captcha/components/VCaptcha/composables/utils';
import { ReCaptchaFramedWrapperState } from 'web/src/modules/captcha/components/VReCaptcha/enums';
import { CaptchaRequesterStrategy, ReCaptchaType } from 'web/src/modules/captcha/store/enums';
import { captchaChallengeWasShownKey } from 'web/src/modules/captcha/store/constants';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
export default function useVCaptcha(props, emit) {
    let postMessageBus = null;
    const { lang } = useI18nLocale();
    const expanded = ref(false);
    const isDefaultStrategy = computed(()=>props.captchaRequesterStrategy === CaptchaRequesterStrategy.DEFAULT);
    const vCaptchaElement = ref();
    const iframeElement = ref();
    const iframeName = IframeWidget.Captcha;
    const iframeWidgetSrc = computed(()=>getIframeWidgetSrc(props, iframeName));
    const additionalQueryStringParameters = computed(()=>getAdditionalQueryStringParameters(props, lang.value));
    function iframeMounted() {
        createPostMessage();
    }
    function mapCaptchaType(captchaType) {
        switch(captchaType){
            case CaptchaType.INVISIBLE_RECAPTCHA:
            case CaptchaType.ANDROID_RECAPTCHA:
                return ReCaptchaType.CHALLENGE_INVISIBLE;
            case CaptchaType.RECAPTCHA:
                return ReCaptchaType.CHALLENGE_CHECKBOX;
            default:
                throw new Error(`Unexpected state captchaType:${captchaType}`);
        }
    }
    function listenPostMessage() {
        if (props.useIframeForCaptcha) {
            postMessageBus?.on(ReCaptchaPostMessageEvent.verify, emitVerify);
            postMessageBus?.on(ReCaptchaPostMessageEvent.expired, emitExpired);
            postMessageBus?.on(ReCaptchaPostMessageEvent.error, emitFramedError);
            postMessageBus?.on(ReCaptchaPostMessageEvent.wrapperState, emitWrapperState);
        }
    }
    function emitVerify(reCaptchaResponse) {
        emit('verify', reCaptchaResponse);
    }
    function emitExpired() {
        emit('expired');
    }
    function emitError(error) {
        emit('error', useErrorsConverter().convertToBaseError(error));
    }
    function emitFramedError(param) {
        let { errorJSON } = param;
        const { name, ...errorData } = JSON.parse(errorJSON);
        emitError(Object.assign(new CaptchaServiceError(), errorData));
    }
    function emitChallengeClosed() {
        emit('challenge-is-closed');
    }
    function emitChallengeOpened() {
        emit('challenge-is-opened');
        window.sessionStorage.setItem(captchaChallengeWasShownKey, String(true));
    }
    function emitWrapperState(param) {
        let { state } = param;
        expanded.value = state === ReCaptchaFramedWrapperState.Expand;
        emit('wrapper-state', state);
    }
    function createPostMessage() {
        const iframeEl = getContentWindowByIFrameName(iframeName);
        isNullOrUndefined(iframeEl);
        postMessageBus = Object.freeze(new PostMessageBus({
            target: iframeEl,
            targetOrigin: '*',
            initiator: 'VCaptcha'
        }));
    }
    onBeforeUnmount(()=>{
        if (props.useIframeForCaptcha) postMessageBus?.dispose();
    });
    return {
        vCaptchaElement,
        iframeElement,
        iframeWidgetSrc,
        expanded,
        isDefaultStrategy,
        iframeName,
        additionalQueryStringParameters,
        iframeMounted,
        listenPostMessage,
        emitVerify,
        emitExpired,
        emitError,
        emitChallengeClosed,
        emitChallengeOpened,
        mapCaptchaType
    };
}
