import { toRef } from 'vue';
import { useSportlineGlobalEventsStore } from 'web/src/modules/sportline/store/useSportlineGlobalEventsStore';
export function useGlobalSportlineEvents() {
    const sportlineGlobalEventsStore = useSportlineGlobalEventsStore();
    const broadcastEventInfoSubscriptions = toRef(sportlineGlobalEventsStore, 'broadcastEventInfoSubscriptions');
    function onInit() {
        sportlineGlobalEventsStore.initSportlineEvents();
    }
    return {
        broadcastEventInfoSubscriptions,
        onInit
    };
}
