import { computed, nextTick, ref, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CustomerHistoryFilterType, HistoryFilter } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { ModalSelector } from 'web/src/modules/core/enums';
import { ActiveOrderFilterEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { useCurrency } from 'web/src/modules/money/composables';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { DEFAULT_ORDER_FILTER } from 'web/src/modules/profile/submodules/customer-history/store/constants';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useLoyaltyShopStore from 'web/src/modules/bonuses/store/useLoyaltyShopStore';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ModalHeight, ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCashoutStore } from 'web/src/modules/cashout/store';
import { usePendingBetsStore } from 'web/src/modules/slip/submodules/pending-bets/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
// eslint-disable-next-line max-len,sonarjs/cognitive-complexity
export default function useCustomerHistoryListRouteComponent(props) {
    const { $translate } = useI18n();
    const { showDialog, closeDialog } = useDialogs();
    const router = useRouter();
    useRoute();
    const { currency: currencyBase } = useCurrency();
    const scgStore = useSiteConfigStore();
    const cashoutStore = useCashoutStore();
    const pendingBetsStore = usePendingBetsStore();
    const customerHistoryStore = useCustomerHistoryStore();
    const customerDataStore = useCustomerDataStore();
    const loyaltyShopStore = useLoyaltyShopStore();
    const offers = toRef(loyaltyShopStore, 'offers');
    const historyItems = toRef(customerHistoryStore, 'historyItems');
    const isInitialLoader = toRef(customerHistoryStore, 'isInitialLoader');
    const isLastPage = toRef(customerHistoryStore, 'isLastPage');
    const isLazyLoader = toRef(customerHistoryStore, 'isLazyLoader');
    const selectedHistoryFilter = toRef(customerHistoryStore, 'selectedHistoryFilter');
    const isLeonShopHistoryExists = toRef(customerHistoryStore, 'isLeonShopHistoryExists');
    const historyFilterOptions = toRef(customerDataStore, 'historyFilterOptions');
    const defaultHistoryFilterOption = toRef(customerDataStore, 'defaultHistoryFilterOption');
    const isLeonShopEnabledStore = toRef(scgStore, 'isLeonShopEnabled');
    const portalSelector = ModalSelector.DESKTOP_INNER_MODAL;
    const isCashOutModal = ref(false);
    const currentCashOutItem = ref(null);
    const modalId = ref(null);
    const selectedFilter = computed(()=>{
        const filter = router.getQuery('filter');
        return Object.values(HistoryFilter).find((value)=>value === filter) ?? DEFAULT_ORDER_FILTER.filter;
    });
    const isLeonShopFilter = computed(()=>selectedFilter.value === HistoryFilter.LEONSHOP);
    const currency = computed(()=>{
        if (isLeonShopFilter.value) return 'L';
        return currencyBase.value;
    });
    // better extend array on server
    const pendingBetOption = computed(()=>({
            dateRange: 365,
            dateType: CustomerHistoryFilterType.DAY,
            isDefault: false,
            order: 0.1,
            title: $translate('WEB2_PENDING_BETS').value
        }));
    const fullHistoryFilterOptions = computed(()=>{
        if (0 === historyFilterOptions.value.length) return [];
        return [
            pendingBetOption.value,
            ...historyFilterOptions.value
        ];
    });
    const selectedHistoryFilterOption = computed(()=>{
        const activeOrderFilter = router.getQuery('activeOrderFilter');
        return fullHistoryFilterOptions.value.find((value)=>value.order === Number(activeOrderFilter));
    });
    const isPendingBets = computed(()=>{
        const filter = router.getQuery('filter');
        const activeOrderFilter = router.getQuery('activeOrderFilter');
        return (filter === HistoryFilter.ALL_BETS || filter === HistoryFilter.PENDING_BETS) && activeOrderFilter === ActiveOrderFilterEnum.PENDING_BETS;
    });
    const selectedOrderFilter = computed(()=>{
        const defaultOption = {
            dateRange: defaultHistoryFilterOption.value?.dateRange ?? DEFAULT_ORDER_FILTER.dateRange,
            dateType: defaultHistoryFilterOption.value?.dateType ?? DEFAULT_ORDER_FILTER.dateType
        };
        if (isPendingBets.value) return defaultOption;
        return selectedHistoryFilterOption.value ?? defaultOption;
    });
    const queryParameters = computed(()=>{
        const defaultFilter = {
            filter: selectedFilter.value,
            activeOrderFilter: ActiveOrderFilterEnum.DEFAULT
        };
        if (!selectedHistoryFilterOption.value) return defaultFilter;
        if (selectedHistoryFilterOption.value.order === Number(ActiveOrderFilterEnum.PENDING_BETS) && !isPendingBets.value) return defaultFilter;
        return {
            filter: selectedFilter.value,
            activeOrderFilter: String(selectedHistoryFilterOption.value.order)
        };
    });
    const requestParameters = computed(()=>{
        const parameters = {
            filter: selectedFilter.value,
            dateRange: selectedOrderFilter.value.dateRange,
            dateType: selectedOrderFilter.value.dateType,
            page: DEFAULT_ORDER_FILTER.page,
            rows: DEFAULT_ORDER_FILTER.rows
        };
        if (isPendingBets.value) return {
            ...parameters,
            filter: HistoryFilter.PENDING_BETS,
            dateRange: pendingBetOption.value.dateRange,
            dateType: pendingBetOption.value.dateType
        };
        return parameters;
    });
    function updateCustomerHistory(options) {
        return customerHistoryStore.updateCustomerHistoryAction(options);
    }
    async function updateHistory() {
        if (props.filterParamsFromProps) await updateCustomerHistory(props.filterParamsFromProps);
        else {
            router.replace({
                name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
                query: queryParameters.value
            });
            await nextTick();
            await updateCustomerHistory(requestParameters.value);
        }
    }
    async function onLoadMore() {
        if (!isLastPage.value) await updateCustomerHistory({
            ...requestParameters.value,
            page: customerHistoryStore.currentFilterState.page + 1
        });
    }
    function updateQuery(tabId) {
        const { activeOrderFilter } = queryParameters.value;
        const isPendingBetsValue = activeOrderFilter === ActiveOrderFilterEnum.PENDING_BETS;
        router.replace({
            name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
            query: {
                filter: tabId,
                activeOrderFilter: isPendingBetsValue ? ActiveOrderFilterEnum.DEFAULT : activeOrderFilter
            }
        });
    }
    async function onTabClick(tabId) {
        if (tabId !== selectedHistoryFilter.value) {
            updateQuery(tabId);
            await nextTick();
            await updateCustomerHistory({
                ...requestParameters.value,
                filter: tabId
            });
        }
    }
    async function onFilterChange(option) {
        if (!option) return;
        const id = option.order;
        const stringId = `${id}`;
        const filter = router.getQuery('filter');
        router.replace({
            name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
            query: {
                filter,
                activeOrderFilter: stringId
            }
        });
        const isPendingBetsValue = selectedHistoryFilter.value === HistoryFilter.ALL_BETS && stringId === ActiveOrderFilterEnum.PENDING_BETS;
        await updateCustomerHistory({
            ...requestParameters.value,
            filter: isPendingBetsValue ? HistoryFilter.PENDING_BETS : selectedHistoryFilter.value,
            dateType: option.dateType,
            dateRange: option.dateRange
        });
    }
    function onWithdrawalModalCancel(item) {
        customerHistoryStore.doWithdrawalCancel({
            paymentId: Number(item.objectId)
        }).then(updateHistory).then(()=>{
            const { id } = showDialog({
                presetName: PresetName.ALERT_SUCCESS,
                options: {
                    height: ModalHeight.DEFAULT,
                    fullHeight: true,
                    isFullHeightCentered: false,
                    title: $translate('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT').value,
                    buttons: [
                        {
                            kind: ButtonKind.BASE,
                            label: $translate('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT_CLOSE').value
                        }
                    ]
                }
            });
            modalId.value = id;
        }).catch((error)=>{
            const { id } = showDialog({
                presetName: PresetName.ALERT_ERROR,
                options: {
                    confirmMessage: error.message,
                    height: ModalHeight.SMALL_MINUS,
                    fullHeight: true
                }
            });
            modalId.value = id;
        });
    }
    function handleHistoryItemClick(item) {
        const { objectId, objectType, accountType, cardOrder } = item;
        if (objectId && objectType && !cardOrder) router.push({
            name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
            params: {
                id: `${objectId}`,
                group: `${objectType.toLowerCase()}`,
                accountType: `${accountType.toLowerCase()}`
            }
        });
    }
    function handleCancelWithdrawal(item) {
        const { subscribe, id } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT').value,
                confirmMessage: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_QUESTION').value,
                width: ModalWidth.SMALL,
                isCentered: false,
                buttons: [
                    {
                        label: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_YES').value
                    },
                    {
                        label: $translate('WEB2_TRANSACTION_HISTORY_CANCEL_PAYMENT_NO').value
                    }
                ]
            }
        });
        modalId.value = id;
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                onWithdrawalModalCancel(item);
            }
        });
    }
    function closeCashOutModal() {
        isCashOutModal.value = false;
        currentCashOutItem.value = null;
        cashoutStore.resetState();
    }
    async function onCashOutConfirm() {
        await onCashOutSuccess();
        closeCashOutModal();
    }
    async function onCashOutSuccess() {
        if (currentCashOutItem.value) await Promise.all([
            customerHistoryStore.updateHistoryListItem({
                id: currentCashOutItem.value.objectId,
                group: currentCashOutItem.value.objectType,
                accountType: currentCashOutItem.value.accountType
            }),
            pendingBetsStore.loadPendingBets()
        ]);
    }
    async function handleBetCashOut(item) {
        isCashOutModal.value = true;
        currentCashOutItem.value = item;
        if (item.objectId) await cashoutStore.initCashout({
            betId: Number(item.objectId),
            betType: 'betType' in item ? item.betType : null
        });
    }
    const isLeonShopEnabled = computed(()=>isLeonShopEnabledStore.value && offers.value.length > 0 || isLeonShopHistoryExists.value);
    async function beforeMount() {
        await updateHistory();
        await customerHistoryStore.checkIfLeonShopHistoryExists();
        if (isLeonShopEnabledStore.value || offers.value.length > 0) await loyaltyShopStore.fetchCustomerOffers();
    }
    function beforeUnmount() {
        if (modalId.value) closeDialog(modalId.value);
        customerHistoryStore.onDestroyCustomerHistory();
    }
    return {
        selectedHistoryFilter,
        isCashOutModal,
        isLeonShopEnabled,
        isInitialLoader,
        portalSelector,
        historyFilterOptions,
        historyItems,
        isLastPage,
        currency,
        isLazyLoader,
        currentCashOutItem,
        onCashOutConfirm,
        handleBetCashOut,
        handleHistoryItemClick,
        handleCancelWithdrawal,
        onLoadMore,
        onTabClick,
        closeCashOutModal,
        onFilterChange,
        beforeMount,
        beforeUnmount
    };
}
