export function isWindowLocationHistoryAvailable() {
    return 'undefined' != typeof window && window.history && 'undefined' != typeof document;
}
/** function to change hash without trigger vue router handlers */ export function setHashWithoutRouter() {
    let hash = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
    if (!isWindowLocationHistoryAvailable()) return;
    const clearedUrl = document.location.href.split('#')[0];
    const desiredHash = hash ? `#${hash}` : '';
    window.history.pushState('', '', `${clearedUrl}${desiredHash}`);
}
export function getHash() {
    return document.location.href.split('#')[1];
}
