import { computed, toRef } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import { StepDescriptionKind, StepDescriptionSize } from 'web/src/components/StepDescription/VStepDescription/enums';
import getPlayerReward from '../../../utils/referralProgramPopup/getPlayerReward';
export default function useReferralProgramRegistrationBody(props, emit) {
    const { $translate } = useI18n();
    const i18nStore = useI18nStore();
    const formatMoney = useFormatMoney();
    const currency = toRef(useCurrency(), 'currency');
    const depositKeys = computed(()=>({
            count: String(formatMoney(Number(props.rewards?.[0].depositAmount) || 0, {
                currency: props.currency || currency.value
            }))
        }));
    const referralProgramPopupRewardProps = computed(()=>({
            referralReward: true,
            table: getPlayerReward(props.rewards ?? null, $translate, formatMoney, i18nStore.locale, props.currency || currency.value)
        }));
    const stepSize = toRef(()=>StepDescriptionSize.SIZE_24);
    const stepDescriptionProps = computed(()=>[
            {
                step: '1',
                label: $translate('WEB2_REFERRAL_REGISTRATION_STEP_1').value,
                kind: StepDescriptionKind.HIGHLIGHT,
                size: stepSize.value
            },
            {
                step: '2',
                label: $translate('WEB2_REFERRAL_REGISTRATION_STEP_2', depositKeys).value,
                kind: StepDescriptionKind.HIGHLIGHT,
                size: stepSize.value
            },
            {
                step: '3',
                label: $translate('WEB2_REFERRAL_REGISTRATION_STEP_3').value,
                kind: StepDescriptionKind.HIGHLIGHT,
                size: stepSize.value
            }
        ]);
    const buttonProps = computed(()=>({
            label: $translate('WEB2_REFERRAL_REGISTRATION_CONTINUE').value,
            fullWidth: true
        }));
    const registrationDescription = $translate('WEB2_REFERRAL_REGISTRATION_DESCRIPTION');
    const registrationRule = $translate('WEB2_REFERRAL_REGISTRATION_RULE');
    const registrationTitle = $translate('WEB2_REFERRAL_REGISTRATION_STEP_TITLE');
    const handleClick = ()=>{
        emit('click');
    };
    return {
        stepDescriptionProps,
        buttonProps,
        referralProgramPopupRewardProps,
        registrationDescription,
        registrationRule,
        registrationTitle,
        handleClick
    };
}
