import { computed } from 'vue';
import useTimeLeft from './useTimeLeft';
export default function useCountdown(targetTime, now) {
    let props = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const { daysLeft, daysLeftPretty, hoursLeft, hoursLeftPretty, minutesLeft, minutesLeftPretty, secondsLeft, secondsLeftPretty } = useTimeLeft(targetTime, now, {
        useLeadingZero: props.useLeadingZero
    });
    const timeLeftValue = computed(()=>({
            days: daysLeft.value,
            hours: hoursLeft.value,
            minutes: minutesLeft.value,
            seconds: secondsLeft.value
        }));
    const displayedTimeLeft = computed(()=>{
        const displaySecondsOnMaxMinutes = props.displaySecondsOnMaxMinutes?.value ?? 5;
        const showSeconds = false === displaySecondsOnMaxMinutes || 0 === daysLeft.value && 0 === hoursLeft.value && minutesLeft.value < displaySecondsOnMaxMinutes;
        return {
            days: daysLeftPretty.value,
            hours: hoursLeftPretty.value,
            minutes: minutesLeftPretty.value,
            seconds: !!showSeconds && secondsLeftPretty.value
        };
    });
    return {
        timeLeftValue,
        displayedTimeLeft
    };
}
