import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "linear-progress__bg"
};
const _hoisted_2 = {
    key: 0,
    class: "linear-progress__check"
};
import { VIcon } from '@components/v-icon';
import { ProgressHeight, ProgressKind } from './enums';
import { useVLinearProgress } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLinearProgress',
    props: {
        value: {
            default: 0
        },
        of: {
            default: 100
        },
        height: {
            default: ProgressHeight.DEFAULT
        },
        kind: {
            default: ProgressKind.DEFAULT
        },
        noShadow: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { iconProperties, percentage, barStyleObject, classObject } = useVLinearProgress(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_unref(classObject))
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: "linear-progress__bar",
                        style: _normalizeStyle(_unref(barStyleObject))
                    }, null, 4),
                    100 === _unref(percentage) && _ctx.height === _unref(ProgressHeight).DEFAULT ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconProperties))), null, 16)
                    ])) : _createCommentVNode("", true)
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VLinearProgress'
                ]
            ]);
        };
    }
});
