import { ref, toRef } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
import { getFavoritesLeaguesIdsRequest } from 'web/src/modules/sportline/utils/api';
export function useFavoritesLeaguesEventsStoreComposable() {
    const { isLoggedIn } = useIsLoggedIn();
    const customerDataStore = useCustomerDataStore();
    const customerLogin = toRef(customerDataStore, 'login');
    const broadcastSelected = useBroadcastSelected();
    const apiService = useSportlineApiService();
    const customerLeaguesIds = ref(null);
    const rawFavoriteLeaguesEventsResponse = ref(null);
    function setRawFavoriteEventsResponse(response) {
        rawFavoriteLeaguesEventsResponse.value = response ? normalizeEventsChangesResponseToDefaultResponse(response) : response;
    }
    async function fetchFavoriteLeaguesEvents(param) {
        let { silent } = param;
        if (!isLoggedIn.value) {
            rawFavoriteLeaguesEventsResponse.value = null;
            return;
        }
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const vTag = (rawFavoriteLeaguesEventsResponse.value || null)?.vtag;
        const isUpdateRequest = !!vTag;
        try {
            const response = await apiService.loadFavoriteLeaguesEvents({
                login: `${customerLogin.value}-${Date.now()}`,
                vTag: isUpdateRequest ? vTag : void 0,
                silent
            });
            rawFavoriteLeaguesEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
            broadcastSelected.updateDataInStorageByResponse({
                response
            });
        } catch (error) {
            rawFavoriteLeaguesEventsResponse.value = false;
            throw error;
        }
    }
    function clearCustomerLeaguesIds() {
        customerLeaguesIds.value = [];
    }
    async function fetchCustomerLeaguesIds(param) {
        let { silent } = param;
        if (!isLoggedIn.value) {
            clearCustomerLeaguesIds();
            return;
        }
        const response = await getFavoritesLeaguesIdsRequest({
            dateFrom: null,
            dateTo: null
        }, {
            silent
        });
        customerLeaguesIds.value = (response?.myLeagues?.leagues || []).map((league)=>String(league.leaguesId));
    }
    return {
        customerLeaguesIds,
        rawFavoriteLeaguesEventsResponse,
        setRawFavoriteEventsResponse,
        fetchFavoriteLeaguesEvents,
        fetchCustomerLeaguesIds,
        clearCustomerLeaguesIds
    };
}
