import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSpinner',
    props: {
        size: {},
        color: {},
        customSize: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const SpinnerComponent = require('../LSpinner/LSpinner.vue').default;
        const remapProps = ()=>props;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(SpinnerComponent), _normalizeProps(_guardReactiveProps(_unref(remapProps)(props))), null, 16)), [
                [
                    _directive_auto_id,
                    'VSpinner'
                ]
            ]);
        };
    }
});
