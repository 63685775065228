import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { logger } from '@leon-hub/logging';
import { localStorageManager } from '@leon-hub/local-storage';
import ServerDate from 'web/src/utils/ServerDate';
let VirtualSportBetsMappingStorage = class VirtualSportBetsMappingStorage {
    static isKeysEqual(key1, key2) {
        return key1.selectionKey === key2.selectionKey && key1.sport === key2.sport && key1.marketSpecifier === key2.marketSpecifier && key1.marketId === key2.marketId && key1.outcomeId === key2.outcomeId && key1.matchId === key2.matchId;
    }
    static isMappingsEqual(value1, value2) {
        return value1.runnerId === value2.runnerId && value1.marketId === value2.marketId && value1.eventId === value2.eventId;
    }
    getKeysByMappings(mappings) {
        return this.slipMappings.filter((param)=>{
            let [, value] = param;
            return mappings.find((mapping)=>VirtualSportBetsMappingStorage.isMappingsEqual(mapping, value));
        }).map((param)=>{
            let [key] = param;
            return key;
        });
    }
    getSlipMapping(bet) {
        return this.slipMappings.find((param)=>{
            let [key] = param;
            return VirtualSportBetsMappingStorage.isKeysEqual(key, bet);
        })?.[1];
    }
    setSlipMapping(bet, slipMapping) {
        if (!slipMapping) return;
        const key = {
            selectionKey: bet.selectionKey,
            sport: bet.sport,
            marketSpecifier: bet.marketSpecifier,
            marketId: bet.marketId,
            outcomeId: bet.outcomeId,
            matchId: bet.matchId,
            validity: bet.validity
        };
        this.slipMappings.push([
            key,
            slipMapping,
            ServerDate.now()
        ]);
        this.saveToLocalStorage();
        return slipMapping;
    }
    releaseOldMappings() {
        const releaseDate = ServerDate.now() - VirtualSportBetsMappingStorage.releaseMappingAfter;
        this.slipMappings = this.slipMappings.filter((mapping)=>mapping[2] > releaseDate);
    }
    loadFromLocalStorage() {
        try {
            const asString = localStorageManager.getItem(VirtualSportBetsMappingStorage.localStorageKey);
            if (!asString) {
                this.slipMappings = [];
                return;
            }
            this.slipMappings = JSON.parse(asString);
        } catch (error) {
            logger.error(`Virtual sport bets mapping loadFromLocalStorage error: ${error}`);
        }
    }
    saveToLocalStorage() {
        try {
            const asString = JSON.stringify(this.slipMappings);
            if (asString) localStorageManager.setItem(VirtualSportBetsMappingStorage.localStorageKey, asString);
        } catch (error) {
            logger.error(`Virtual sport bets mapping saveToLocalStorage error: ${error}`);
        }
    }
    constructor(){
        _define_property(this, "slipMappings", []);
        this.loadFromLocalStorage();
        this.releaseOldMappings();
    }
};
_define_property(VirtualSportBetsMappingStorage, "releaseMappingAfter", 86400000);
_define_property(VirtualSportBetsMappingStorage, "localStorageKey", 'web2_virtual_sport_slip_mappings');
export { VirtualSportBetsMappingStorage as default };
