import { localStorageManager } from '@leon-hub/local-storage';
const deviceCustomerLoginKey = 'deviceCustomerLogin';
// TODO: create unified mechanism for sync storage data
const DeviceCustomerLoginStorage = {
    setLogin (value) {
        localStorageManager.setItem(deviceCustomerLoginKey, value);
    },
    remove () {
        localStorageManager.removeItem(deviceCustomerLoginKey);
    },
    getLogin () {
        return localStorageManager.getItem(deviceCustomerLoginKey);
    }
};
export default DeviceCustomerLoginStorage;
