import { computed, ref, watch } from 'vue';
import { logger } from '@leon-hub/logging';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
import { getCurrenciesFromApi, mapApiCurrencyResponseToSelectOptions } from '../utils';
export default function useCurrencySelect(props, emits) {
    const { $translate } = useI18n();
    const { emitChange, emitFocus, emitBlur } = useBaseInputEvents(emits);
    const isLoading = ref(false);
    const emitChangeValue = (value)=>emitChange({
            target: {
                value,
                name: props.name || ''
            }
        });
    const options = ref([]);
    const getCurrencyOptions = async (countryCode)=>{
        options.value = [];
        isLoading.value = true;
        const response = await getCurrenciesFromApi(countryCode);
        isLoading.value = false;
        if (response) {
            const allOptions = mapApiCurrencyResponseToSelectOptions(response.currencies);
            const onlyRealOptions = allOptions.filter((option)=>'-' !== option.value);
            if (!onlyRealOptions.length && allOptions.length) {
                logger.error(`No valid currency provided via getCurrenciesByCountry for ${countryCode.toUpperCase()}, edit regcountries!`);
                return;
            }
            options.value = 1 === onlyRealOptions.length ? onlyRealOptions : allOptions;
            emitChangeValue(options.value[0].value);
        }
    };
    const selectProps = computed(()=>{
        const { countryCode, loaderDelay, ...singleSelectProps } = props;
        const disabledProps = {
            disabled: true,
            placeholder: $translate('WEB2_CURRENCY_SELECTOR_HINT').value
        };
        return {
            options: options.value,
            ...singleSelectProps,
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            disabled: singleSelectProps.disabled || options.value.length <= 1,
            ...props.countryCode ? {} : disabledProps
        };
    });
    watch(()=>props.countryCode, (value, oldValue)=>{
        if (value) getCurrencyOptions(value);
        if (oldValue && !value) // clear
        emitChangeValue('');
    }, {
        immediate: true
    });
    return {
        selectProps,
        isLoading,
        emitChange,
        emitFocus,
        emitBlur
    };
}
