import RouteName from '@leon-hub/routing-config';
import { PaymentType } from '@leon-hub/api-sdk';
import { isInFrame } from '@leon-hub/html-utils';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { PaymentsPage } from 'web/src/modules/core/services/router/enums';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
const depositTitle = '{{$t(\'WEB2_DEPOSIT_FUNDS\')}}';
const depositOkPath = '/depositok';
const depositProcessPath = '/depositprocess';
const depositFailPath = '/depositfail';
const withdrawalOkPath = '/withdrawalok';
export class PaymentsModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
    addRoutes(router) {
        router.addProfileRoute({
            name: RouteName.DEPOSITS,
            path: '/deposits',
            component: ()=>import('web/src/modules/payments/submodules/deposits/pages/PaymentsDepositsRoutePage.vue'),
            topBar: ()=>import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
            navigation: void 0,
            topBarProps: {
                page: PaymentsPage.DESKTOP_DEPOSITS,
                paymentType: PaymentType.DEPOSIT
            }
        });
        router.addProfileRoute({
            name: RouteName.DEPOSITS_OVERVIEW,
            path: '/topup',
            component: ()=>import('web/src/modules/payments/submodules/deposits/pages/PaymentsDepositsRoutePage.vue'),
            topBar: ()=>import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
            topBarProps: {
                page: PaymentsPage.DESKTOP_DEPOSITS
            }
        });
        router.addProfileRoute({
            name: RouteName.DEPOSITS_DETAILS,
            path: '/deposits/:name',
            component: ()=>import('web/src/modules/payments/submodules/deposits/pages/PaymentsDepositsDetailsRoutePage.vue'),
            prefetch: ()=>import('web/src/modules/payments/submodules/deposits/prefetch/PaymentsDepositsDetailsPrefetch'),
            topBar: ()=>import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
            topBarProps: {
                page: PaymentsPage.DEPOSIT_DETAILS,
                paymentType: PaymentType.DEPOSIT
            }
        });
        router.addProfileRoute({
            name: RouteName.WITHDRAWALS,
            path: '/withdrawals',
            component: ()=>import('web/src/modules/payments/submodules/withdrawals/pages/PaymentsWithdrawalsRoutePage.vue'),
            topBar: ()=>import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
            navigation: void 0,
            topBarProps: {
                // eslint-disable-next-line max-len
                page: PaymentsPage.DESKTOP_WITHDRAWALS,
                paymentType: PaymentType.WITHDRAWAL
            }
        });
        router.addProfileRoute({
            name: RouteName.WITHDRAWALS_DETAILS,
            path: '/withdrawals/:name',
            // eslint-disable-next-line max-len
            component: ()=>import('web/src/modules/payments/submodules/withdrawals/pages/PaymentsWithdrawalsDetailsRoutePage.vue'),
            // eslint-disable-next-line max-len
            prefetch: ()=>import('web/src/modules/payments/submodules/withdrawals/prefetch/PaymentsWithdrawalsDetailsPrefetch'),
            topBar: ()=>import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
            topBarProps: {
                page: PaymentsPage.WITHDRAWAL_DETAILS,
                paymentType: PaymentType.WITHDRAWAL
            }
        });
        "1";
        if (isInFrame()) router.addBlankRoute({
            name: RouteName.DEPOSITSOK,
            path: depositOkPath,
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsokRoutePage/PaymentsDepositsokRoutePage.vue')
        });
        else router.addModalRoute({
            name: RouteName.DEPOSITSOK,
            path: depositOkPath,
            modalPreset: ModalDesktopPreset.DepositDetailsDesktopModal,
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsokRoutePage/PaymentsDepositsokRoutePage.vue'),
            title: depositTitle
        });
        router.addBlankRoute({
            name: RouteName.DEPOSITSOK2,
            path: '/depositok2',
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsokRoutePage/PaymentsDepositsokRoutePage.vue'),
            props: {
                closeWindow: true
            }
        });
        if (isInFrame()) router.addBlankRoute({
            name: RouteName.DEPOSITSPROCESS,
            path: depositProcessPath,
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue')
        });
        else router.addModalRoute({
            name: RouteName.DEPOSITSPROCESS,
            path: depositProcessPath,
            modalPreset: ModalDesktopPreset.DepositDetailsDesktopModal,
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue'),
            title: depositTitle
        });
        router.addBlankRoute({
            name: RouteName.DEPOSITSPROCESS2,
            path: '/depositprocess2',
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue'),
            props: {
                closeWindow: true
            }
        });
        if (isInFrame()) router.addBlankRoute({
            name: RouteName.DEPOSITSFAIL,
            path: depositFailPath,
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsfailRoutePage/PaymentsDepositsfailRoutePage.vue')
        });
        else router.addModalRoute({
            name: RouteName.DEPOSITSFAIL,
            path: depositFailPath,
            modalPreset: ModalDesktopPreset.DepositDetailsDesktopModal,
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsfailRoutePage/PaymentsDepositsfailRoutePage.vue'),
            title: depositTitle
        });
        router.addBlankRoute({
            name: RouteName.DEPOSITSFAIL2,
            path: '/depositfail2',
            component: ()=>import('web/src/modules/payments/pages/PaymentsDepositsfailRoutePage/PaymentsDepositsfailRoutePage.vue'),
            props: {
                closeWindow: true
            }
        });
        if (isInFrame()) router.addBlankRoute({
            name: RouteName.WITHDRAWALOK,
            path: withdrawalOkPath,
            component: ()=>import('web/src/modules/payments/components/PaymentsWithdrawalsokRoutePage/PaymentsWithdrawalsokRoutePage.vue')
        });
        else router.addModalRoute({
            name: RouteName.WITHDRAWALOK,
            path: withdrawalOkPath,
            modalPreset: ModalDesktopPreset.DepositDetailsDesktopModal,
            component: ()=>import('web/src/modules/payments/components/PaymentsWithdrawalsokRoutePage/PaymentsWithdrawalsokRoutePage.vue'),
            title: '{{$t(\'WEB2_WITHDRAWAL_FROM_ACCOUNT_OK\')}}'
        });
        router.addBlankRoute({
            name: RouteName.WITHDRAWALOK2,
            path: '/withdrawalok2',
            component: ()=>import('web/src/modules/payments/components/PaymentsWithdrawalsokRoutePage/PaymentsWithdrawalsokRoutePage.vue'),
            props: {
                closeWindow: true
            }
        });
    }
}
