import { computed, toRef } from 'vue';
import useSiteConfigStore from 'web/src/modules/core/store/useSiteConfigStore';
export default function useWebSocketsConfig() {
    const siteConfigStore = useSiteConfigStore();
    const webSockets = toRef(siteConfigStore, 'webSockets');
    return {
        isEnabled: computed(()=>!!webSockets.value?.isEnabled),
        isBalanceEnabled: computed(()=>!!webSockets.value?.isBalanceEnabled),
        isCashbackEnabled: computed(()=>!!webSockets.value?.isCashbackEnabled),
        isLoyaltyUpdateEnabled: computed(()=>!!webSockets.value?.isLoyaltyUpdateEnabled),
        isCbcBonusWithdrawalAmountEnabled: computed(()=>!!webSockets.value?.isCbcBonusWithdrawalAmountEnabled),
        isOnDepositEnabled: computed(()=>!!webSockets.value?.isOnDepositEnabled),
        isSocketStickyBonusStatus: computed(()=>!!webSockets.value?.isSocketStickyBonusStatus),
        isLoyaltyProgramEnabled: computed(()=>!!webSockets.value?.isLoyaltyProgramEnabled),
        isWheelNotificationEnabled: computed(()=>!!webSockets.value?.isWheelNotificationEnabled),
        // eslint-disable-next-line max-len
        isFlexibleFreeSpinBetLimitNotificationEnabled: computed(()=>!!webSockets.value?.isFlexibleFreeSpinBetLimitNotificationEnabled)
    };
}
