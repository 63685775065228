import { CustomerLimitsRange } from '@leon-hub/api-sdk/src/sdk/sdk';
export var ResponsibleGamblingLimitsToRange;
var ResponsibleGamblingLimitsToRange1;
(ResponsibleGamblingLimitsToRange1 = ResponsibleGamblingLimitsToRange || (ResponsibleGamblingLimitsToRange = {}))[ResponsibleGamblingLimitsToRange1["dailyLimit"] = CustomerLimitsRange.DAILY] = "dailyLimit", ResponsibleGamblingLimitsToRange1[ResponsibleGamblingLimitsToRange1["monthlyLimit"] = CustomerLimitsRange.MONTHLY] = "monthlyLimit", ResponsibleGamblingLimitsToRange1[ResponsibleGamblingLimitsToRange1["weeklyLimit"] = CustomerLimitsRange.WEEKLY] = "weeklyLimit";
export var ResponsibleGamblingLimitsType;
var ResponsibleGamblingLimitsType1;
(ResponsibleGamblingLimitsType1 = ResponsibleGamblingLimitsType || (ResponsibleGamblingLimitsType = {}))["DEPOSIT"] = "deposit", ResponsibleGamblingLimitsType1["BET"] = "bet", ResponsibleGamblingLimitsType1["SELF_EXCLUSION"] = "self-exclusion";
export function ResponsibleGamblingLimitsMapper(limitName) {
    let result = '';
    switch(limitName){
        case 'dailyLimit':
            result = 'dailyUpdatableAt';
            break;
        case 'monthlyLimit':
            result = 'monthlyUpdatableAt';
            break;
        case 'weeklyLimit':
            result = 'weeklyUpdatableAt';
            break;
        default:
            break;
    }
    return result || null;
}
