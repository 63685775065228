import { isNullOrUndefined, isNumber } from '@leon-hub/guards';
import handleMaskedValueChange from './handleMaskedValueChange';
export default function handleMaskedInput(param) {
    let { event, maskOptions, currentValue } = param;
    const { target, data } = event;
    HTMLInputElement;
    const isChromeAutofill = isNullOrUndefined(data);
    let beforeSelectionStart = target.selectionStart;
    isNumber(beforeSelectionStart);
    if (isChromeAutofill) // also cut event. Need to pass empty string as empty value
    beforeSelectionStart = 0;
    else // if we add value then cursor positionStart is always after added value,
    // but we need the start cursor position, that is why we remove length of adding data (always 1)
    if (null !== data) beforeSelectionStart -= 1;
    return handleMaskedValueChange({
        target,
        selectionStart: beforeSelectionStart,
        selectionEnd: beforeSelectionStart,
        data: isChromeAutofill ? target.value : data,
        currentValue: isChromeAutofill ? '' : currentValue,
        maskOptions
    });
}
