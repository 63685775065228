import { computed } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useExtendedEventSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
export function useExtendedEventBackgroundRequestsStoreComposable(props, request) {
    const { eventsIds } = props;
    const { timeout: extendedEventUpdateInterval } = useExtendedEventSportlineUpdateTimeout();
    const isAvailable = computed(()=>eventsIds.value.length > 0);
    const { resumeSyncState: resume, pauseSyncState: pause } = useSyncState(request, extendedEventUpdateInterval, {
        condition: isAvailable
    });
    return {
        resume,
        pause
    };
}
