import { isObject } from '@leon-hub/guards';
import { InputEventType } from 'web/src/components/Input/enums';
export default function useVPhotoInput(props, emit) {
    const isVInputFileEvent = (value)=>isObject(value) && 'target' in value;
    function takeFiles(event) {
        isVInputFileEvent(event);
        return event.target?.files || null;
    }
    function onChange(event) {
        const { target } = event;
        HTMLInputElement;
        const files = takeFiles(event);
        emit(InputEventType.CHANGE, {
            target: {
                name: props.name,
                files
            }
        });
    }
    return {
        onChange
    };
}
