import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
export default function useIdentificationNeededModal() {
    const router = useRouter();
    function goToIdentification() {
        router.push({
            name: RouteName.IDENTIFICATION
        });
    }
    function showIdentificationNeededModal() {
        // eslint-disable-next-line import/no-dynamic-require
        goToIdentification();
    }
    return {
        showIdentificationNeededModal
    };
}
