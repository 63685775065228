import { computed } from 'vue';
import { Tag } from '@leon-hub/tags';
export function useBaseTabsButton(props) {
    const isDisabled = computed(()=>props.disabled ? props.disabled : void 0);
    const tag = computed(()=>props.isNavigation ? Tag.A : Tag.BUTTON);
    const htmlTypeAttr = computed(()=>props.isNavigation ? void 0 : Tag.BUTTON);
    const role = computed(()=>props.isNavigation ? void 0 : 'tab');
    return {
        isDisabled,
        tag,
        htmlTypeAttr,
        role
    };
}
