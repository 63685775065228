import { getBetlineLeagueTopPlayers as getBetlineLeagueTopPlayersByLeagueId } from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export function getBetlineLeagueTopPlayers(options, param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return getBetlineLeagueTopPlayersByLeagueId(apiClient, (node)=>node.queries.betLine.getLeagueTopPlayersByLeagueId, {
        options
    }, {
        silent,
        group: RequestGroupScore24
    });
}
