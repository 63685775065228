import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount, onMounted } from 'vue';
import { useVLazyRender } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLazyRender',
    props: {
        isHideOnPrerender: {
            type: Boolean
        }
    },
    emits: [
        "appear"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { collision, mounted, stopObserving, displayContent } = useVLazyRender(props, emit);
        onMounted(mounted);
        onBeforeUnmount(stopObserving);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "collision",
                ref: collision
            }, [
                _unref(displayContent) ? _renderSlot(_ctx.$slots, "default", {
                    key: 0
                }) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'VLazyRender'
                ]
            ]);
        };
    }
});
