import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useFavoriteSportEventData } from 'web/src/modules/sportline/submodules/favorites/composables';
import { useVFavoriteToggle } from 'web/src/components/FavoriteToggle/VFavoriteToggle/composables';
import { FavoriteIconColor } from 'web/src/components/FavoriteToggle/VFavoriteToggle/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventFavoriteToggle',
    props: {
        sportlineEventId: {},
        sportlineEventType: {},
        isColored: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const sportlineEventId = toRef(props, 'sportlineEventId');
        const sportlineEventType = toRef(props, 'sportlineEventType');
        const { isFavorite, canFavorite, emitChangeFavoriteState } = useFavoriteSportEventData({
            sportlineEventId,
            sportlineEventType
        });
        const { isToggled, onClick, onMouseLeave } = useVFavoriteToggle(()=>{
            emitChangeFavoriteState();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(canFavorite) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['favorite-toggle']]: true,
                    [_ctx.$style['favorite-toggle--colored']]: _ctx.isColored,
                    [_ctx.$style['favorite-toggle--no-default-color']]: true,
                    [_ctx.$style['favorite-toggle--favorite']]: !!_unref(isFavorite),
                    [_ctx.$style['favorite-toggle--toggled']]: _unref(isToggled)
                }),
                onClick: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onClick) && _unref(onClick)(...args);
                }, [
                    "stop",
                    "prevent"
                ])),
                onMouseleave: _cache[1] || (_cache[1] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onMouseLeave) && _unref(onMouseLeave)(...args);
                })
            }, [
                _createVNode(_unref(VIcon), {
                    name: _unref(IconName).STAR_OUTLINE,
                    class: _normalizeClass(_ctx.$style['favorite-toggle__inactive-icon'])
                }, null, 8, [
                    "name",
                    "class"
                ]),
                _createVNode(_unref(VIcon), {
                    name: _unref(IconName).STAR,
                    class: _normalizeClass([
                        _ctx.$style[`favorite-toggle__active-icon-name-${_unref(IconName).STAR}-${_unref(FavoriteIconColor).BRAND_DEFAULT}`],
                        _ctx.$style['favorite-toggle__active-icon']
                    ])
                }, null, 8, [
                    "name",
                    "class"
                ])
            ], 34)), [
                [
                    _directive_auto_id,
                    'SportlineEventFavoriteToggle'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
