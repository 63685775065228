import { computed, toRef } from 'vue';
import usePaymentsSuggestedAmounts from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsSuggestedAmounts';
export default function usePaymentsMaxValueFastChoiceDeposit(props) {
    const maxAmount = toRef(props, 'maxAmount');
    const allowInputSuggestedAmountsOnly = toRef(props, 'allowInputSuggestedAmountsOnly');
    const { filteredDefaultSuggestedAmounts, filteredCurrentPaymentSystemSuggestedAmounts } = usePaymentsSuggestedAmounts(props);
    const maxValueFastChoiceDeposit = computed(()=>{
        if (allowInputSuggestedAmountsOnly.value) return 0;
        if (filteredCurrentPaymentSystemSuggestedAmounts.value) {
            const amountsList = filteredCurrentPaymentSystemSuggestedAmounts.value;
            if (maxAmount.value && amountsList[amountsList.length - 1] >= maxAmount.value) return 0;
        }
        if (filteredDefaultSuggestedAmounts.value) {
            const defaultAmountsList = filteredDefaultSuggestedAmounts.value;
            if (maxAmount.value && defaultAmountsList[defaultAmountsList.length - 1] >= maxAmount.value) return 0;
        }
        return maxAmount.value || 0;
    });
    return {
        maxValueFastChoiceDeposit
    };
}
