import { onBeforeUnmount } from 'vue';
import useSeoMetaProperties from 'web/src/modules/seo/composables/useSeoMetaProperties';
export default function usePreventTitleChange() {
    const { metaTitle } = useSeoMetaProperties();
    let observer = new MutationObserver((mutations)=>{
        if (metaTitle.value !== mutations[0].target.textContent) document.title = metaTitle.value;
    });
    const target = document.querySelector('title');
    if (target) observer.observe(target, {
        childList: true
    });
    onBeforeUnmount(()=>{
        observer?.disconnect();
        observer = void 0;
    });
}
