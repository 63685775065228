import { defineStore } from 'pinia';
import { ref } from 'vue';
import { fromMillisecondsToSeconds } from 'web/src/modules/registration/utils/fromMillisecondsToSeconds';
const useRegistrationIdleRedirectStore = defineStore('registration-idle-redirect', ()=>{
    const startTimeRegistration = ref(0);
    function setStartTimeRegistration(value) {
        startTimeRegistration.value = value;
    }
    function getDurationRegistrationInSeconds() {
        return fromMillisecondsToSeconds(Date.now() - startTimeRegistration.value);
    }
    return {
        startTimeRegistration,
        getDurationRegistrationInSeconds,
        setStartTimeRegistration
    };
});
export default useRegistrationIdleRedirectStore;
