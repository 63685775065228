import { isNumber, isString, isValidInterface } from '@leon-hub/guards';
import { isMinimalWorkingSportResponse } from './isMinimalWorkingSportResponse';
import { isCoreSportReferenceResponse } from './isCoreSportReferenceResponse';
import { isCoreRegionResponse } from './isCoreRegionResponse';
import { isCoreRegionReferenceResponse } from './isCoreRegionReferenceResponse';
export const isMinimalWorkingLeagueResponseFieldGuards = {
    id: isNumber,
    region: [
        isCoreRegionReferenceResponse,
        isCoreRegionResponse
    ],
    name: isString,
    nameDefault: isString,
    sport: [
        isCoreSportReferenceResponse,
        isMinimalWorkingSportResponse
    ],
    url: isString
};
export function isMinimalWorkingLeagueResponse(value) {
    return isValidInterface(value, isMinimalWorkingLeagueResponseFieldGuards);
}
