import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import MainBannerItem from 'web/src/modules/banners/components/MainBannerItem/MainBannerItem.vue';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import VSwiperBannerNavigationButtons from 'web/src/modules/banners/components/VSwiperBannerNavigation/VSwiperBannerNavigationButtons.vue';
import { useSwiperBanner } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SwiperBanner',
    props: {
        bannerGroup: {},
        isLoggedIn: {
            type: Boolean
        },
        bannerType: {
            default: BannerType.MAIN_BANNER
        },
        aspectRatio: {
            default: 1.75
        },
        isBigHeight: {
            type: Boolean
        }
    },
    emits: [
        "slide-changed",
        "banner-item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { swiper, banners, emitBannerItemClick, isGroupBannersDev, activeIndex, bannersKey, isMouseOver, emitSlideChanged, onMouseEnter, onMouseLeave, onActivatedAction, onDeactivatedAction } = useSwiperBanner(props, emit);
        onComponentActivated(onActivatedAction);
        onComponentDeactivated(onDeactivatedAction);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VSwiper), {
                ref_key: "swiper",
                ref: swiper,
                key: _unref(bannersKey),
                "is-infinite-loop": "",
                "is-scroll-snap-enabled": "",
                class: _normalizeClass(_ctx.$style['swiper-banner']),
                onScroll: _unref(emitSlideChanged),
                onMouseenter: _unref(onMouseEnter),
                onMouseleave: _unref(onMouseLeave)
            }, {
                "pagination-footer": _withCtx(()=>[
                        _createVNode(VSwiperBannerNavigationButtons)
                    ]),
                default: _withCtx(()=>[
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(banners), (banner, index)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                key: banner.id,
                                class: _normalizeClass(_ctx.$style['swiper-banner__slide'])
                            }, {
                                default: _withCtx(()=>[
                                        _createVNode(MainBannerItem, {
                                            banner: banner,
                                            "is-group-banners-dev": _unref(isGroupBannersDev),
                                            "group-id": _ctx.bannerGroup?.id,
                                            "is-active": !_unref(isMouseOver) && index === _unref(activeIndex),
                                            "banner-type": _ctx.bannerType,
                                            "is-visible-image": "",
                                            "is-half-width": "",
                                            onClick: ($event)=>_unref(emitBannerItemClick)($event, banner)
                                        }, null, 8, [
                                            "banner",
                                            "is-group-banners-dev",
                                            "group-id",
                                            "is-active",
                                            "banner-type",
                                            "onClick"
                                        ])
                                    ]),
                                _: 2
                            }, 1032, [
                                "class"
                            ]))), 128))
                    ]),
                _: 1
            }, 8, [
                "class",
                "onScroll",
                "onMouseenter",
                "onMouseleave"
            ])), [
                [
                    _directive_auto_id,
                    'SwiperBanner'
                ]
            ]);
        };
    }
});
