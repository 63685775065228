import AbstractFingerprintComponent from '../../AbstractFingerprintComponent';
import { ComponentKey, ComponentStatus } from '../../types';
import { getWebglCanvas, loseWebglContext } from './utils';
let WebGlVendorAndRendererComponent = class WebGlVendorAndRendererComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        try {
            const glContext = getWebglCanvas();
            if (glContext) {
                const rendererInfo = glContext.getExtension('WEBGL_debug_renderer_info');
                if (rendererInfo) {
                    const result = [];
                    result.push(// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    glContext.getParameter(rendererInfo.UNMASKED_VENDOR_WEBGL), // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    glContext.getParameter(rendererInfo.UNMASKED_RENDERER_WEBGL));
                    loseWebglContext(glContext);
                    return this.result(result);
                }
            }
        } catch  {
            return this.result([
                ComponentStatus.Error
            ]);
        }
        return this.result([
            ComponentStatus.NotAvailable
        ]);
    }
    constructor(){
        super(ComponentKey.WebglVendorAndRenderer);
    }
};
// Component source: https://github.com/fingerprintjs/fingerprintjs2/blob/master/fingerprint2.js#L1082
export { WebGlVendorAndRendererComponent as default };
