import { EgsGameType } from '@leon-hub/api-sdk';
import { EgsRouterParametersHandler, getEgsGameRouteLocation, isDemoMethod } from 'web/src/modules/egs/submodules/navigation/utils';
import navigateTo from 'web/src/utils/router/navigateTo';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
export default function useEgsNavigation(router) {
    const { isLoggedIn } = useIsLoggedIn();
    const { getGameById, loadGameById } = useEgsGamesStore();
    function getEgsGameHref(game) {
        return router.resolve(getEgsGameRouteLocation(game)).href;
    }
    async function navigateToGamePage(game, selectedMethod) {
        let replace = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
        if (game.typeId !== EgsGameType.FASTGAMES) EgsRouterParametersHandler.setSelectedGameMethod(selectedMethod);
        "1";
        await router.closeModal();
        navigateTo(router, getEgsGameRouteLocation(game), replace);
    }
    function isDemo(game, selectedMethod) {
        return isDemoMethod(game, isLoggedIn.value, selectedMethod);
    }
    // eslint-disable-next-line max-len
    async function navigateToGamePageById(gameId, selectedMethod) {
        let replace = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
        let game = getGameById(gameId);
        if (!game) game = await loadGameById(gameId);
        if (game) navigateToGamePage(game, selectedMethod, replace);
    }
    return {
        getEgsGameHref,
        navigateToGamePage,
        navigateToGamePageById,
        isDemo
    };
}
