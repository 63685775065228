export var ReCaptchaType;
var ReCaptchaType1;
(ReCaptchaType1 = ReCaptchaType || (ReCaptchaType = {}))["CHALLENGE_CHECKBOX"] = "CHALLENGE_CHECKBOX", ReCaptchaType1["CHALLENGE_INVISIBLE"] = "CHALLENGE_INVISIBLE", ReCaptchaType1["SCORE"] = "SCORE";
export var CaptchaRequesterStrategy;
var CaptchaRequesterStrategy1;
(CaptchaRequesterStrategy1 = CaptchaRequesterStrategy || (CaptchaRequesterStrategy = {}))["RESTORE_PASSWORD"] = "RESTORE_PASSWORD", CaptchaRequesterStrategy1["REGISTRATION"] = "REGISTRATION", CaptchaRequesterStrategy1["LOGIN"] = "LOGIN", CaptchaRequesterStrategy1["DEFAULT"] = "DEFAULT";
export var ReCaptchaTheme;
var ReCaptchaTheme1;
(ReCaptchaTheme1 = ReCaptchaTheme || (ReCaptchaTheme = {}))["DARK"] = "dark", ReCaptchaTheme1["LIGHT"] = "light";
export var ReCaptchaSize;
var ReCaptchaSize1;
// NB! Do not change case of enum values
(ReCaptchaSize1 = ReCaptchaSize || (ReCaptchaSize = {}))["COMPACT"] = "compact", ReCaptchaSize1["NORMAL"] = "normal", ReCaptchaSize1["INVISIBLE"] = "invisible";
export var ChallengeState;
var ChallengeState1;
(ChallengeState1 = ChallengeState || (ChallengeState = {}))["CHALLENGE_IS_CLOSED"] = "challenge-is-closed", ChallengeState1["CHALLENGE_IS_OPENED"] = "challenge-is-opened";
