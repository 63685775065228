import { toRef, computed } from 'vue';
import { SportlineType } from 'web/src/modules/sportline/enums';
export function useIsSportlineType(sportlineType) {
    const sportlineTypeRef = toRef(sportlineType);
    const isLive = computed(()=>sportlineTypeRef.value === SportlineType.Live);
    const isPrematch = computed(()=>sportlineTypeRef.value === SportlineType.Prematch);
    return {
        isLive,
        isPrematch
    };
}
