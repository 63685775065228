import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import useMainAppMetaInfo from './useMainAppMetaInfo';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TheMetaInfo',
    setup (__props) {
        useMainAppMetaInfo();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VEmpty), null, null, 512)), [
                [
                    _directive_auto_id,
                    'TheMetaInfo'
                ]
            ]);
        };
    }
});
