import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { AlertIconName, IconName } from '@leon-hub/icons';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsWithdrawalCancelSuccessModal',
    props: {
        text: {},
        secondaryText: {},
        isInProfile: {
            type: Boolean
        }
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { $translate } = useI18n();
        function onButtonClick(param) {
            let { action } = param;
            if (action === DialogAction.MODAL_CLOSE) emit('close');
        }
        const modalProperties = computed(()=>({
                isInnerModal: true,
                ...getImageOrIcon({
                    icon: IconName.ATTENTION_OUTLINE,
                    alertIcon: AlertIconName.Success
                }),
                iconKind: JumbotronIconKind.SUCCESS,
                fullHeight: false,
                isProfileLayout: props.isInProfile,
                title: $translate('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT').value,
                buttons: [
                    {
                        action: DialogAction.MODAL_CLOSE,
                        kind: ButtonKind.PRIMARY,
                        label: $translate('WEB2_CLOSE').value
                    }
                ]
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                modal: modalProperties.value,
                onButtonClick: onButtonClick,
                onClose: _cache[0] || (_cache[0] = ($event)=>emit('close'))
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['deposits-success'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['deposits-success__text'])
                            }, _toDisplayString(_ctx.text), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['deposits-success__value'])
                            }, " +" + _toDisplayString(_ctx.secondaryText), 3)
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "modal"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsWithdrawalCancelSuccessModal'
                ]
            ]);
        };
    }
});
