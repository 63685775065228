export var ComponentKey;
var ComponentKey1;
(ComponentKey1 = ComponentKey || (ComponentKey = {}))["Plugins"] = "plugins", ComponentKey1["Fonts"] = "fonts", ComponentKey1["UserAgent"] = "userAgent", ComponentKey1["ScreenResolution"] = "screenResolution", ComponentKey1["Timezone"] = "timezone", ComponentKey1["TimezoneOffset"] = "timezoneOffset", ComponentKey1["Language"] = "language", ComponentKey1["ColorDepth"] = "colorDepth", ComponentKey1["DeviceMemory"] = "deviceMemory", ComponentKey1["PixelRatio"] = "pixelRatio", ComponentKey1["HardwareConcurrency"] = "hardwareConcurrency", ComponentKey1["Platform"] = "platform", ComponentKey1["DoNotTrack"] = "doNotTrack", ComponentKey1["WebglVendorAndRenderer"] = "webglVendorAndRenderer", ComponentKey1["TouchSupport"] = "touchSupport", ComponentKey1["Canvas"] = "canvas", ComponentKey1["Audio"] = "audio", ComponentKey1["EnumerateDevices"] = "enumerateDevices";
export var ComponentStatus;
var ComponentStatus1;
// https://wiki.mozilla.org/Fingerprinting
// Other
// TODO:
// HttpAccept = 'httpAccept',
// SuperCookies = 'superCookies',
// CookiesEnabled = 'cookiesEnabled',
// ServiceWorker = 'serviceWorker',
// AdBlock = 'adBlock',
// Detect lied signatures
(ComponentStatus1 = ComponentStatus || (ComponentStatus = {}))["NotAvailable"] = "NotAvailable", ComponentStatus1["NoData"] = "NoData", ComponentStatus1["Error"] = "Error", ComponentStatus1["Timeout"] = "TimeoutDFB", ComponentStatus1["Applicable"] = "Applicable", ComponentStatus1["NotApplicable"] = "NotApplicable";
