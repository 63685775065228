import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1
};
import { toRef } from 'vue';
import { IconDirection, IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useExpandableHeadline } from 'web/src/modules/sportline/composables/list';
import { defaultHeadlineBackgrounColor } from './constants';
import MarketsDropDown from './MarketsDropDown.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PrimaryHeadline',
    props: {
        title: {},
        backgroundColor: {},
        basisKey: {},
        expandKey: {},
        isExpanded: {
            type: Boolean
        },
        countEvents: {},
        hideEventsCounter: {
            type: Boolean
        },
        isComing: {
            type: Boolean
        },
        isMarketTypesSelectionEnabled: {
            type: Boolean
        }
    },
    emits: [
        "catalog-click",
        "toggle-expand-state"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const title = toRef(props, 'title', null);
        const backgroundColor = toRef(props, 'backgroundColor', defaultHeadlineBackgrounColor);
        const hideEventsCounter = toRef(props, 'hideEventsCounter', false);
        const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);
        const countEvents = toRef(props, 'countEvents', 0);
        const expandKey = toRef(props, 'expandKey', null);
        const isExpanded = toRef(props, 'isExpanded', false);
        const { isExpandable, eventsCountLabel, isShowEventsCounters, toggleExpandState } = useExpandableHeadline({
            expandKey,
            isExpanded,
            countEvents
        }, emit);
        function onCatalogClick(event) {
            emit('catalog-click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-list-sport-headline'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['sport-event-list-sport-headline__left']]: true,
                        [_ctx.$style['sport-event-list-sport-headline__left--expandable']]: _unref(isExpandable)
                    }),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(toggleExpandState) && _unref(toggleExpandState)(...args);
                    })
                }, [
                    title.value ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _unref(isExpandable) ? (_openBlock(), _createBlock(_unref(VIcon), {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['sport-event-list-sport-headline__expand-icon']),
                            style: _normalizeStyle({
                                fill: backgroundColor.value
                            }),
                            direction: isExpanded.value ? _unref(IconDirection).NORTH : _unref(IconDirection).SOUTH,
                            size: _unref(IconSize).SIZE_24,
                            name: _unref(IconName).CHEVRON
                        }, null, 8, [
                            "class",
                            "style",
                            "direction",
                            "size",
                            "name"
                        ])) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sport-event-list-sport-headline__title']),
                            onClick: onCatalogClick
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['sport-event-list-sport-headline__label'])
                            }, _toDisplayString(title.value), 3)
                        ], 2)
                    ], 64)) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sport-event-list-sport-headline__right'])
                }, [
                    isMarketTypesSelectionEnabled.value && (!_unref(isExpandable) || isExpanded.value) ? (_openBlock(), _createBlock(MarketsDropDown, {
                        key: 0,
                        "basis-key": _ctx.basisKey
                    }, null, 8, [
                        "basis-key"
                    ])) : !hideEventsCounter.value && (!_unref(isExpandable) || _unref(isShowEventsCounters)) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(countEvents.value) + " " + _toDisplayString(_unref(eventsCountLabel)), 1)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PrimaryHeadline'
                ]
            ]);
        };
    }
});
