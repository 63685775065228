import { ButtonHeight, ButtonKind, ButtonType } from 'web/src/components/Button/VButton/enums';
import { FormControlType } from '../enums';
// don't show button if we set submitButton to null
export function hasSubmitButton(uiSchema) {
    const { submitButton } = uiSchema;
    return null !== submitButton;
}
export function getSubmitButtonProperties(uiSchema) {
    const buttonSchema = uiSchema.submitButton || {};
    const defaultSchema = {
        type: ButtonType.SUBMIT,
        kind: ButtonKind.PRIMARY,
        label: 'Submit',
        disabled: false,
        fullWidth: true,
        height: ButtonHeight.LARGE
    };
    return {
        ...defaultSchema,
        ...buttonSchema
    };
}
export function getUiSchemaField(uiSchema, field) {
    const { fields } = uiSchema;
    const defaultSchema = {
        widget: FormControlType.Text
    };
    if (!fields) return defaultSchema;
    const schema = fields[field] || {};
    if (!schema.widget) return {
        ...schema,
        widget: FormControlType.Text
    };
    return schema;
}
export function getLabel(uiSchema, field) {
    return getUiSchemaField(uiSchema, field)?.title || '';
}
