import { ref, computed, watch } from 'vue';
import { HistoryFilter } from '@leon-hub/api-sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line max-len
export default function useCustomerHistoryFilterTabs(props) {
    const { $translate } = useI18n();
    const swiper = ref(null);
    const tabs = computed(()=>{
        // TODO better fetch list from backend
        const tabsArray = [
            {
                id: HistoryFilter.ALL,
                label: $translate('WEB2_ALL').value
            },
            {
                id: HistoryFilter.ALL_BONUS,
                label: $translate('WEB2_BONUSES').value
            },
            {
                id: HistoryFilter.ALL_DEPOSITS,
                label: $translate('WEB2_DEPOSITS').value
            },
            {
                id: HistoryFilter.ALL_WITHDRAWALS,
                label: $translate('WEB2_PAYMENTS').value
            }
        ];
        "1";
        tabsArray.splice(1, 0, {
            id: HistoryFilter.ALL_BETS,
            label: $translate('WEB2_BETS').value
        });
        "1";
        tabsArray.splice(2, 0, {
            id: HistoryFilter.ALL_EGS,
            label: $translate('WEB2_ALL_EGS').value
        });
        if (props.isLeonShopEnabled) tabsArray.splice(2, 0, {
            id: HistoryFilter.LEONSHOP,
            label: $translate('WEB2_SHOP').value
        });
        return tabsArray;
    });
    const activeTabIndex = computed(()=>tabs.value.findIndex((item)=>item.id === props.selectedHistoryFilter));
    function slideToActive() {
        let smooth = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (swiper.value) swiper.value?.slideToSlide(activeTabIndex.value, {
            smooth,
            speed: 300,
            isCentered: true
        });
    }
    watch(()=>props.selectedHistoryFilter, ()=>{
        slideToActive(true);
    });
    return {
        swiper,
        tabs,
        slideToActive
    };
}
