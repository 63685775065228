import { BetType } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
function transformBetTypeForMetrika(betType) {
    if (!betType) return null;
    return betType === BetType.SINGLE ? 'single' : 'multi';
}
export default function sendCashoutMetrika(currentBetId, betType) {
    const analyticsService = useAnalytics();
    analyticsService.push(AnalyticsEvent.Z_CASH_OUT, {
        submitSlipData: {
            ok: {
                [currentBetId]: {
                    cashOut: true
                }
            },
            cashOutByBetType: transformBetTypeForMetrika(betType)
        }
    });
}
