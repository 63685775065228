import { logger } from '@leon-hub/logging';
export default function logPlaceBetResultError(param) {
    let { errorCode, errorMessage, slipStatus, slipMessage } = param;
    const payload = {
        errorMessage,
        errorCode,
        slipStatus,
        slipMessage
    };
    if (!errorCode && slipStatus) {
        logger.info('Place bet slipStatus', payload);
        return;
    }
    if (errorCode && errorCode.includes('freebet')) {
        logger.error('Place bet freebet', payload);
        return;
    }
    logger.error('Place bet error', payload);
}
