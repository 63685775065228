import { ref, computed } from 'vue';
import { useDocumentDimensionSafeSubscribe } from '@leon-hub/browser-composables';
import { LayoutSizes } from 'web/src/modules/core/enums';
import { DetailsLayoutType } from 'web/src/modules/sportline/views/markets-list/enums';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useMarketsListResizableLayout(props) {
    const { isListItem, isOutright, defaultLayoutType, isEmptyMarketsList, isOnlyOneMarket, marketHolder, columnOne, columnTwo } = props;
    const maxOneColumnLayoutWidth = 800;
    const marketHolderClientWidth = ref(0);
    const totalColumnsWidth = ref(0);
    function recalculateColumnsLayout() {
        marketHolderClientWidth.value = marketHolder.value?.clientWidth || 0;
        totalColumnsWidth.value = (columnOne.value?.clientWidth || 0) + (columnTwo.value?.clientWidth || 0);
    }
    const { clientDimension, isPortraitOrientation } = useDocumentDimensionSafeSubscribe({
        debounce: 100,
        onWindowResize: recalculateColumnsLayout
    });
    const isSmallScreen = computed(()=>(clientDimension.value?.clientWidth ?? 0) <= +LayoutSizes.ShowOnColumnGrid);
    const isOutrightLayout = computed(()=>isOutright.value && isOnlyOneMarket.value);
    const allowedForScreenSizeLayoutTypes = computed(()=>{
        if (isSmallScreen.value || isPortraitOrientation.value || isOutrightLayout.value) // always show one column for small screens and outright events
        return [
            DetailsLayoutType.OneColumn
        ];
        // @see LEONWEB-8107 and LEONWEB-7671
        // the two columns layout may not fit to an element less than 800 width
        // in case of too large markets names, and we can notice it only after render columns
        // 820 is the max width for holder on list pages
        // for details page an average wide size is 1072 and 656 is minimal
        if (marketHolderClientWidth.value < totalColumnsWidth.value || marketHolderClientWidth.value < maxOneColumnLayoutWidth) return [
            DetailsLayoutType.OneColumn
        ];
        return [
            DetailsLayoutType.OneColumn,
            DetailsLayoutType.TwoColumn
        ];
    });
    const layoutTypesPriority = computed(()=>{
        const defaultValue = defaultLayoutType.value ?? (isListItem.value ? DetailsLayoutType.OneColumn : DetailsLayoutType.TwoColumn);
        return [
            defaultValue,
            DetailsLayoutType.TwoColumn,
            DetailsLayoutType.OneColumn
        ].filter((type)=>!!type);
    });
    const layoutType = computed(()=>{
        const allowedLayoutType = layoutTypesPriority.value.find((type)=>allowedForScreenSizeLayoutTypes.value.includes(type));
        return allowedLayoutType ?? DetailsLayoutType.OneColumn;
    });
    const allowedToSelectLayoutTypes = computed(()=>isListItem?.value ? // do not allow customer to select layout type on list pages
        [] : allowedForScreenSizeLayoutTypes.value);
    const isDisplayOneColumnLayout = computed(()=>layoutType.value === DetailsLayoutType.OneColumn || isOutright.value || isEmptyMarketsList.value);
    recalculateColumnsLayout();
    return {
        layoutType,
        allowedToSelectLayoutTypes,
        isDisplayOneColumnLayout,
        isSmallScreen,
        layoutTypesPriority,
        allowedForScreenSizeLayoutTypes,
        recalculateColumnsLayout
    };
}
