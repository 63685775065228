import { ref, watch, computed } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useFavoriteLeaguesSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import arrayEquals from 'web/src/utils/array/arrayEquals';
export function useFavoriteLeaguesBackgroundUpdates(props) {
    const { customerLeaguesIds, pendingToFavoriteLeaguesList, tryToAddPendingLeagues, fetchFavoriteLeaguesEvents, fetchCustomerLeaguesIds, clearPendingLeagues } = props;
    const { timeout: favoriteLeaguesUpdateInterval } = useFavoriteLeaguesSportlineUpdateTimeout();
    const isLeaguesEventsBackgroundUpdateAvailable = ref(false);
    const isBackgroundIdsUpdateAvailableSet = ref({});
    const isSyncIdsAvailable = computed(()=>Object.values(isBackgroundIdsUpdateAvailableSet.value).some((enable)=>enable));
    const lastUpdate = new BackgroundUpdateStopwatch({
        favoriteLeaguesEvents: async (param)=>{
            let { silent } = param;
            await fetchFavoriteLeaguesEvents({
                silent
            });
            lastUpdate.update('favoriteLeaguesEvents');
        },
        favoriteLeaguesIds: async (param)=>{
            let { silent } = param;
            await fetchCustomerLeaguesIds({
                silent
            });
            lastUpdate.update('favoriteLeaguesIds');
        }
    });
    async function synchronizeLeaguesIds(param) {
        let { force, silent } = param;
        await (force ? lastUpdate.forceCallSyncAction('favoriteLeaguesIds', {
            silent
        }) : lastUpdate.callSyncAction('favoriteLeaguesIds', favoriteLeaguesUpdateInterval.value, {
            silent
        }));
    }
    async function synchronizeLeaguesEvents(param) {
        let { force, silent } = param;
        await (force ? lastUpdate.forceCallSyncAction('favoriteLeaguesEvents', {
            silent
        }) : lastUpdate.callSyncAction('favoriteLeaguesEvents', favoriteLeaguesUpdateInterval.value, {
            silent
        }));
    }
    async function syncState(param) {
        let { silent } = param;
        const isSyncListAvailable = isLeaguesEventsBackgroundUpdateAvailable.value;
        if (pendingToFavoriteLeaguesList.value.length > 0) {
            // after login with pending list
            // ids list will be sync in add action
            const isAddedPending = await tryToAddPendingLeagues();
            if (isSyncListAvailable) await synchronizeLeaguesEvents({
                silent
            });
            const isSimilarEvents = compareSimilarEvents();
            if (isAddedPending && isSimilarEvents) clearPendingLeagues();
        } else // just sync state
        await Promise.allSettled([
            isSyncIdsAvailable.value ? synchronizeLeaguesIds({
                silent
            }) : Promise.resolve(),
            isSyncListAvailable ? synchronizeLeaguesEvents({
                silent
            }) : Promise.resolve()
        ]);
    }
    function compareSimilarEvents() {
        return pendingToFavoriteLeaguesList.value.every((event)=>customerLeaguesIds.value?.includes(event.leagueId));
    }
    function setIsBackgroundIdsUpdateAvailable(value) {
        isBackgroundIdsUpdateAvailableSet.value = {
            ...isBackgroundIdsUpdateAvailableSet.value,
            ...value
        };
        if (value) synchronizeLeaguesIds({
            silent: true
        });
    }
    function setIsEventsBackgroundUpdateAvailable(value) {
        if (value === isLeaguesEventsBackgroundUpdateAvailable.value) return;
        isLeaguesEventsBackgroundUpdateAvailable.value = value;
        if (value) synchronizeLeaguesEvents({
            silent: true
        });
    }
    // @TODO check inits and watchers, it could make init requests twice
    async function initialRequests() {
        await synchronizeLeaguesIds({
            silent: true,
            force: true
        });
    }
    function onInit() {
        watch(customerLeaguesIds, (list, oldList)=>{
            const isFavoritesChanged = !arrayEquals(oldList ?? [], list ?? []);
            if (!isFavoritesChanged) return;
            if (isLeaguesEventsBackgroundUpdateAvailable.value) synchronizeLeaguesEvents({
                silent: true,
                force: true
            });
            else lastUpdate.invalidate('favoriteLeaguesEvents');
        });
        useSyncState(async (silent)=>{
            await syncState({
                silent
            });
        }, favoriteLeaguesUpdateInterval);
    }
    return {
        onInit,
        initialRequests,
        setIsBackgroundIdsUpdateAvailable,
        setIsEventsBackgroundUpdateAvailable,
        synchronizeLeaguesIds
    };
}
