import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "content"
];
const _hoisted_2 = [
    "content"
];
const _hoisted_3 = {
    key: 1
};
import { onMounted, useSlots } from 'vue';
import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';
import { PopperPosition } from 'web/src/components/Popper/VPopper/enums';
import { BreadcrumbType } from './enums';
import { useVBreadcrumb } from './composables';
import VCrumb from './components/VCrumb.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VBreadcrumb',
    props: {
        type: {
            default: BreadcrumbType.DEFAULT
        },
        applyTarget: {},
        subMenu: {
            type: Boolean
        },
        icon: {},
        title: {},
        open: {
            type: Boolean
        },
        controlled: {
            type: Boolean
        },
        canExpand: {
            type: Boolean
        },
        position: {
            default: 0
        },
        to: {
            default: null
        }
    },
    emits: [
        "toggle"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const { popper, popperModifiers, isOpen, toggleOpen, forceHide } = useVBreadcrumb(props);
        /**
 * @TODO Popper created for Breadcrumb without dropdown
 * @TODO Popper content does not recreated without key on Breadcrumb element
 */ function toggle() {
            if (props.controlled) {
                emit('toggle');
                return;
            }
            toggleOpen();
        }
        function onPopperHide() {
            if (isOpen.value) toggle();
        }
        onMounted(()=>{
            if (props.open) toggle();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass({
                    [_ctx.$style['breadcrumb']]: true,
                    [_ctx.$style['breadcrumb--open']]: _unref(isOpen),
                    [_ctx.$style[`breadcrumb--type-${_ctx.type}`]]: _ctx.type !== _unref(BreadcrumbType).DEFAULT
                })
            }, [
                _ctx.canExpand ? (_openBlock(), _createBlock(VPopper, {
                    key: 1,
                    ref_key: "popper",
                    ref: popper,
                    position: _unref(PopperPosition).BOTTOM,
                    "is-full-width": "",
                    "is-full-height": "",
                    "apply-target": _ctx.applyTarget,
                    modifiers: _unref(popperModifiers),
                    "has-offset": false,
                    onHide: onPopperHide
                }, {
                    reference: _withCtx(()=>[
                            _createVNode(VCrumb, {
                                to: _ctx.to,
                                title: _ctx.title,
                                "is-prevented": !!_unref(slots).default,
                                "is-open": _unref(isOpen),
                                "can-expand": _ctx.canExpand,
                                "icon-name": _ctx.icon,
                                type: _ctx.type,
                                onToggle: toggle
                            }, _createSlots({
                                _: 2
                            }, [
                                _unref(slots).icon ? {
                                    name: "icon",
                                    fn: _withCtx(()=>[
                                            _renderSlot(_ctx.$slots, "icon")
                                        ]),
                                    key: "0"
                                } : void 0
                            ]), 1032, [
                                "to",
                                "title",
                                "is-prevented",
                                "is-open",
                                "can-expand",
                                "icon-name",
                                "type"
                            ]),
                            _ctx.position ? (_openBlock(), _createElementBlock("meta", {
                                key: 0,
                                itemprop: "position",
                                content: String(_ctx.position)
                            }, null, 8, _hoisted_2)) : _createCommentVNode("", true)
                        ]),
                    default: _withCtx(()=>[
                            _unref(slots).default ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass({
                                    [_ctx.$style['breadcrumb__content']]: true,
                                    [_ctx.$style['breadcrumb__content--sub-menu']]: _ctx.subMenu
                                })
                            }, [
                                _renderSlot(_ctx.$slots, "default", {
                                    isOpen: _unref(isOpen),
                                    toggle: toggle,
                                    forceHide: _unref(forceHide)
                                })
                            ], 2)), [
                                [
                                    _vShow,
                                    _unref(isOpen)
                                ]
                            ]) : (_openBlock(), _createElementBlock("div", _hoisted_3))
                        ]),
                    _: 3
                }, 8, [
                    "position",
                    "apply-target",
                    "modifiers"
                ])) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createVNode(VCrumb, {
                        to: _ctx.to,
                        title: _ctx.title,
                        "is-prevented": !!_unref(slots).default,
                        "icon-name": _ctx.icon,
                        type: _ctx.type
                    }, _createSlots({
                        _: 2
                    }, [
                        _unref(slots).icon ? {
                            name: "icon",
                            fn: _withCtx(()=>[
                                    _renderSlot(_ctx.$slots, "icon")
                                ]),
                            key: "0"
                        } : void 0
                    ]), 1032, [
                        "to",
                        "title",
                        "is-prevented",
                        "icon-name",
                        "type"
                    ]),
                    _ctx.position ? (_openBlock(), _createElementBlock("meta", {
                        key: 0,
                        itemprop: "position",
                        content: String(_ctx.position)
                    }, null, 8, _hoisted_1)) : _createCommentVNode("", true)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VBreadcrumb'
                ]
            ]);
        };
    }
});
