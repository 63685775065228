import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class RulesModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        "1";
        router.addDefaultRoute({
            name: RouteName.RULES,
            path: '/rules/:levelOneId?/:levelTwoId?/:levelThreeId?',
            prefetch: ()=>import('web/src/modules/rules/prefetch/RulesPrefetch'),
            component: ()=>import('web/src/modules/rules/components/RulesRouteComponent.vue'),
            leftSideBar: ()=>import('web/src/modules/rules/components/RulesSidebarRouteComponent.vue'),
            navigation: void 0
        });
        router.addDefaultRoute({
            name: RouteName.BETTING_TERMS_WEB2,
            path: '/terms',
            props: {
                cmsKey: 'BETTING_TERMS_WEB2',
                title: '{{$t(\'WEB2_PROMO_TERMS_TITLE\')}}',
                isDownloadButtonEnabled: true,
                termsTitle: 'WEB2_PROMO_TERMS_TITLE'
            },
            leftSideBar: ()=>import('web/src/modules/sidebar/views/SidebarMenuKnowHowRouteComponent/SidebarMenuKnowHowRouteComponent.vue'),
            component: ()=>import('web/src/modules/rules/components/RulesContentDefault.vue')
        });
    }
}
