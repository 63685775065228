export var HeadlineSlideName;
var HeadlineSlideName1;
(HeadlineSlideName1 = HeadlineSlideName || (HeadlineSlideName = {}))["Info"] = "info", HeadlineSlideName1["LiveWidget"] = "live-widget", HeadlineSlideName1["LiveStream"] = "live-stream";
export var HeadlineSlideType;
var HeadlineSlideType1;
(HeadlineSlideType1 = HeadlineSlideType || (HeadlineSlideType = {}))["OutrightInfo"] = "OutrightInfo", HeadlineSlideType1["LiveInfo"] = "LiveInfo", HeadlineSlideType1["PreMatchInfo"] = "PreMatchInfo", HeadlineSlideType1["LiveWidget"] = "LiveWidget", HeadlineSlideType1["LiveStream"] = "LiveStream";
export var MetrikaCategory;
var MetrikaCategory1;
(MetrikaCategory1 = MetrikaCategory || (MetrikaCategory = {}))["SWIPER_NAVIGATION"] = "SportEventDetailsHeadlineMobile", MetrikaCategory1["BUTTONS_NAVIGATION"] = "SportEventDetailsHeadline";
export var MetrikaEvents;
var MetrikaEvents1;
/** @see LEONWEB-13161 */ (MetrikaEvents1 = MetrikaEvents || (MetrikaEvents = {}))["EVENT_BUTTONS"] = "button", MetrikaEvents1["EVENT_KNOBS"] = "knobs", MetrikaEvents1["EVENT_SWIPE"] = "swipe", MetrikaEvents1["EVENTS_CAROUSEL"] = "carousel", MetrikaEvents1["EVENTS_CHANGE_SLIDE"] = "changeSlide", MetrikaEvents1["EVENTS_CLICKS"] = "clicks", MetrikaEvents1["EVENTS_IS_AVAILABLE"] = "isAvailable";
export var MetrikaHeadlineNavigationType;
var MetrikaHeadlineNavigationType1;
(MetrikaHeadlineNavigationType1 = MetrikaHeadlineNavigationType || (MetrikaHeadlineNavigationType = {}))["All"] = "all", MetrikaHeadlineNavigationType1["Stream"] = "stream", MetrikaHeadlineNavigationType1["Widget"] = "widget";
