import applyRunnerOddsChange from './applyRunnerOddsChange';
export function applyEventRunnersOddsChanges(eventData, changes) {
    return {
        ...eventData,
        lastUpdated: Date.now(),
        markets: eventData.markets?.map((market)=>({
                ...market,
                runners: market.runners.map((runner)=>applyRunnerOddsChange({
                        eventId: eventData.id,
                        marketId: market.id,
                        runner
                    }, changes))
            }))
    };
}
export function applyEventsRunnersOddsChanges(currentResponse, changes) {
    if ('events' in currentResponse) return {
        ...currentResponse,
        events: currentResponse.events.map((eventData)=>applyEventRunnersOddsChanges(eventData, changes))
    };
    return applyEventRunnersOddsChanges(currentResponse, changes);
}
