import { defineStore } from 'pinia';
import { computed, ref, shallowRef } from 'vue';
import { useRouter } from 'vue-router';
import { doSubmitWithdrawal, getWithdrawalSystemsList, PinCodePlace, PinCodeRequiredExceptionCode, getCompanyFee as fetchCompanyFee, getWithdrawalsSystemInfo, doCancelRequestedWithdrawals } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { usePaymentsCategories } from 'web/src/modules/payments/composables/usePaymentsCategories';
import { usePaymentSystemRequestTime } from 'web/src/modules/payments/composables/usePaymentSystemRequestTime';
import { useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import addCustomerFormPaymentsErrors from 'web/src/utils/forms/addCustomerFormPaymentsErrors';
import CustomerFormError from 'web/src/utils/forms/CustomerFormError';
import { usePaymentSystemData } from 'web/src/modules/payments/composables/usePaymentSystemData';
import useCreditCardTokenization from 'web/src/modules/payments/composables/useCreditCardTokenization';
import { FormFieldTouchedStrategy } from 'web/src/components/Form/enums';
import FormControlType from 'web/src/components/Form/enums/FormControlType';
import { PaymentFormFieldName, PaymentsFormStep, PaymentsStatus, PaymentsSubmitAction } from 'web/src/modules/payments/enums';
import { isErrorWithdrawalCode } from 'web/src/modules/payments/guards/isErrorWithdrawalCode';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';
import { useCardNumberError } from 'web/src/modules/payments/composables/useCardNumberError';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { getUiFieldRawOptions } from 'web/src/utils/jsonSchemaAdapter';
const useWithdrawalStore = defineStore('withdrawal', ()=>{
    const gqlClient = useGraphqlClient();
    const analytics = useAnalytics();
    const router = useRouter();
    const { convertToBaseError } = useErrorsConverter();
    const formatMoney = useFormatMoney();
    const customerFinanceStore = useCustomerFinanceStore();
    const pinCodeStore = usePinCodeStore();
    const paymentStore = usePaymentsStore();
    const { setBalance } = customerFinanceStore;
    const hideCommissionHint = ref(false);
    const submitData = shallowRef(null);
    const companyFee = shallowRef(null);
    const { doTokenize, resetToken, tokenizePayload } = useCreditCardTokenization();
    const { paymentSystems, setCategories, categories } = usePaymentsCategories();
    const { isReadyToRequest, setLastPaymentSystemRequestTime } = usePaymentSystemRequestTime();
    const { currentPaymentSystem, submitPaymentResponse, setSubmitResponse, paymentSystemData, name, isAdditionalRegistrationNeeded, creditCards, nextSteps, paymentsProcedureStatus, paymentMessage, redirectUrl, htmlContent, htmlContentProperties, redirectCharset, redirectFormParams, redirectMethod, uiFormSchema, formStep, uiFormSchamAdapted, initialModalProperties, submitButton, amount, amountAsNumber, amountAsMoney, clearCurrentStep, cancel, creditCardToken, validatorErrorPatterns, isFormPending, paymentSystemId, submittedAmountAsMoney, clearNDFLLocalStorageData, getPaymentSubmitCancelPayload, clearSubmitResponse, setSubmittedAmount, handlePaymentError, customErrors, isCurrentValueFromSuggestedAmounts, cardHolder } = usePaymentSystemData();
    const availableForWithdraw = computed(()=>{
        if (!currentPaymentSystem.value?.availableForWithdraw) return '';
        return formatMoney(currentPaymentSystem.value.availableForWithdraw);
    });
    const { cardNumberErrors } = useCardNumberError([
        PaymentFormFieldName.CARDNUMBER_WITHDRAWAL,
        PaymentFormFieldName.CARDNUMBER
    ], creditCardToken);
    const errorCode = ref(null);
    const errorMessage = ref(null);
    async function getCompanyFee(data) {
        const result = await fetchCompanyFee(gqlClient, (node)=>node.queries.finance.getCompanyFee, {
            options: {
                amount: data.amount,
                paymentSystemId: data.paymentSystemId
            }
        });
        companyFee.value = result;
    }
    async function loadWithdrawalSystem(systemId) {
        const data = await getWithdrawalsSystemInfo(useGraphqlClient(), (node)=>node.queries.finance.withdrawal.getWithdrawalSystemInfo, {
            options: {
                paymentSystemId: systemId
            }
        });
        currentPaymentSystem.value = data;
        hideCommissionHint.value = !!data.hideCommissionHint;
    }
    async function loadWithdrawalSystems() {
        if (isReadyToRequest()) {
            const data = await getWithdrawalSystemsList(gqlClient, (node)=>node.queries.finance.withdrawal.getWithdrawalSystemsList, {
                options: {}
            });
            hideCommissionHint.value = !!data.hideCommissionHint;
            setCategories(data.paymentCategories);
            if (data.errorCode && data.message) {
                errorCode.value = isErrorWithdrawalCode(data.errorCode) ? data.errorCode : null;
                errorMessage.value = data.message;
            } else {
                errorCode.value = null;
                errorMessage.value = null;
            }
            setLastPaymentSystemRequestTime();
        }
    }
    async function cancelRequestedWithdrawals() {
        return doCancelRequestedWithdrawals(useGraphqlClient(), (node)=>node.mutations.finance.withdrawal.cancelRequestedWithdrawals, {
            options: {}
        }).catch((error)=>{
            paymentStore.errorHandler(error);
            throw error;
        });
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function submitWithdrawal(data) {
        isFormPending.value = true;
        paymentsProcedureStatus.value = {
            status: PaymentsStatus.LOADING,
            action: data.action
        };
        try {
            await doTokenize(data.payload, creditCards.value);
            const response = await doSubmitWithdrawal(gqlClient, (node)=>node.mutations.finance.withdrawal.submit, {
                options: tokenizePayload.value
            }, {
                timeout: 60000,
                retry: 0
            });
            const { balance, balanceTimestamp } = response;
            if (balance && balanceTimestamp) setBalance(balance, balanceTimestamp);
            setSubmittedAmount();
            setSubmitResponse(response);
            if (response?.msg && null === response.uiFormSchema && !response.redirectUrl) analytics.push(AnalyticsEvent.Z_WITHDRAWAL_REQUEST, {
                withdrawals: {
                    ok: {
                        paymentSystemId: data.payload.paymentSystemId
                    }
                }
            });
        } catch (rawError) {
            const error = convertToBaseError(rawError);
            let formErrors = null;
            if (error.code.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)) {
                pinCodeStore.setPinCodePlace(PinCodePlace.WITHDRAWAL);
                pinCodeStore.setStepWithLS('VERIFY');
                throw rawError;
            }
            if (uiFormSchema.value) formErrors = addCustomerFormPaymentsErrors(error, uiFormSchema.value, data.payload);
            // to remove - data.action === PaymentsSubmitAction.NEXT_STEP_SUBMIT
            if (data.action === PaymentsSubmitAction.NEXT_STEP_SUBMIT || data.action === PaymentsSubmitAction.SUBMIT) useAnalytics().push(AnalyticsEvent.Z_WITHDRAWAL_REQUEST, {
                withdrawals: {
                    error: {
                        paymentSystemId: data.payload.paymentSystemId,
                        message: error.message,
                        code: `${error.code}`
                    }
                }
            });
            if (formErrors) throw new CustomerFormError(formErrors);
            if (paymentsProcedureStatus.value.action) paymentsProcedureStatus.value.status = PaymentsStatus.ERROR;
            throw rawError;
        } finally{
            resetToken();
            isFormPending.value = false;
        }
    }
    const formProps = computed(()=>{
        if (uiFormSchamAdapted.value) {
            uiFormSchamAdapted.value.updateOptionsByWidget(FormControlType.FastSum, {
                availableForWithdraw: availableForWithdraw.value,
                isCryptoWithdrawal: paymentSystemData.value?.isCrypto
            }).addPropertiesByWidget(FormControlType.CardExpirationDate, {
                touchedStrategy: FormFieldTouchedStrategy.Change
            }).addPropertiesByWidget(FormControlType.FastSum, (field)=>({
                    options: {
                        ...field.options ? getUiFieldRawOptions(field.options) : {},
                        isCurrentValueFromSuggestedAmounts: isCurrentValueFromSuggestedAmounts.value
                    }
                }));
            return {
                schema: uiFormSchamAdapted.value?.getFormSchema(),
                uiSchema: {
                    ...uiFormSchamAdapted.value?.getFormUiSchema(),
                    submitButton: submitButton.value,
                    validatorErrorPatterns: validatorErrorPatterns.value
                }
            };
        }
        return null;
    });
    function pushOnSuccess() {
        router.closeModal();
    }
    const nettoAmount = computed(()=>submitPaymentResponse.value?.nettoAmount);
    const bruttoAmount = computed(()=>submitPaymentResponse.value?.bruttoAmount);
    const taxAmount = computed(()=>submitPaymentResponse.value?.taxAmount);
    const balanceAfterWithdrawal = computed(()=>submitPaymentResponse.value?.balance);
    function setSubmitData(data) {
        submitData.value = data;
    }
    const withdrawalCustomErrors = computed(()=>({
            ...customErrors.value,
            ...formStep.value === PaymentsFormStep.NEW_CARD || formStep.value === PaymentsFormStep.SECOND ? cardNumberErrors.value : {}
        }));
    function setCardHolder(value) {
        cardHolder.value = value;
    }
    return {
        paymentSystemData,
        errorCode,
        errorMessage,
        loadWithdrawalSystem,
        loadWithdrawalSystems,
        withdrawalCategories: categories,
        submitWithdrawal,
        getCompanyFee,
        uiFormSchamAdapted,
        paymentSystems,
        submitData,
        setSubmitData,
        cancel,
        formProps,
        formStep,
        paymentMessage,
        initialModalProperties,
        redirectUrl,
        htmlContent,
        htmlContentProperties,
        redirectCharset,
        redirectFormParams,
        redirectMethod,
        amount,
        amountAsMoney,
        amountAsNumber,
        companyFee,
        hideCommissionHint,
        isAdditionalRegistrationNeeded,
        nextSteps,
        name,
        clearCurrentStep,
        pushOnSuccess,
        taxAmount,
        bruttoAmount,
        nettoAmount,
        creditCardToken,
        isFormPending,
        paymentSystemId,
        submittedAmountAsMoney,
        clearNDFLLocalStorageData,
        getPaymentSubmitCancelPayload,
        cancelRequestedWithdrawals,
        clearSubmitResponse,
        balanceAfterWithdrawal,
        handlePaymentError,
        customErrors: withdrawalCustomErrors,
        setCardHolder
    };
});
export default useWithdrawalStore;
