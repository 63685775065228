import { DateInputRoundDateTo } from 'web/src/components/Input/enums';
import DateTime from 'web/src/utils/DateTime';
export function applyLimitationsTo(timestamp, minDate, maxDate, roundTo) {
    let result = timestamp;
    if (minDate) result = Math.max(result, minDate);
    if (maxDate) result = Math.min(result, maxDate);
    if (roundTo === DateInputRoundDateTo.DayStart) {
        const rounded = DateTime.roundToDayStart(result);
        result = minDate ? Math.max(rounded, minDate) : rounded;
    }
    if (roundTo === DateInputRoundDateTo.DayEnd) {
        const rounded = DateTime.roundToDayEnd(result);
        result = maxDate ? Math.min(rounded, maxDate) : rounded;
    }
    return result;
}
