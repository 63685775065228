import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { themeDark, Theme } from '@leon-hub/api-sdk';
import { isTheme } from '@leon-hub/api-guards';
import { themeHeaderName } from '@leon-hub/api/src/client/getBaseHeaders';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useInitAppConfigStore, useSiteConfigStore, useStorageManagerStore } from 'web/src/modules/core/store';
import setApiHeaders from 'web/src/modules/core/utils/setApiHeaders';
const allThemes = [
    Theme.DARK,
    Theme.LIGHT
];
const useThemeStore = defineStore('theme', ()=>{
    const storageManager = useStorageManagerStore();
    const initAppConfigStore = useInitAppConfigStore();
    const analytics = useAnalytics();
    function getInitTheme(key) {
        const { default: defaultTheme } = initAppConfigStore.themeConfig;
        const initTheme = initAppConfigStore.themeConfig[key];
        if (initTheme && isTheme(initTheme)) return initTheme;
        return defaultTheme;
    }
    const siteConfigStore = useSiteConfigStore();
    const currentTheme = ref(getInitTheme('current'));
    const customTheme = ref();
    const firstTheme = ref(getInitTheme('first'));
    const isAuto = ref(false);
    const customStatusBarTheme = ref();
    const settingsBlock = toRef(siteConfigStore, 'settingsBlock');
    const theme = computed(()=>customTheme.value ?? currentTheme.value);
    const isDark = computed(()=>theme.value === Theme.DARK);
    const isLight = computed(()=>theme.value === Theme.LIGHT);
    const isThemeFooterSwitcherEnabled = computed(()=>!!settingsBlock.value?.themeFooterSwitcherEnabled);
    const hasCustomTheme = computed(()=>!!customTheme.value);
    let systemTheme;
    async function getDefaultSystemTheme() {
        if (!systemTheme) systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.DARK : Theme.LIGHT;
        return systemTheme;
    }
    function setCustomStatusbarTheme(value) {
        customStatusBarTheme.value = value;
    }
    function setCustomStatusbarColor() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        setCustomStatusbarTheme(value ? themeDark : void 0);
    }
    async function setTheme(value) {
        // eslint-disable-next-line no-param-reassign
        currentTheme.value = value;
        setThemeHeaderAndChangeNavbarColor();
        await storageManager.setTheme(value);
    }
    function setThemeHeaderAndChangeNavbarColor() {
        setApiHeaders({
            [themeHeaderName]: theme.value
        });
    }
    async function setFirstTheme(value) {
        await storageManager.setFirstTheme(value);
        firstTheme.value = value;
    }
    async function setIsAutoTheme(value) {
        isAuto.value = value;
        await storageManager.setIsAutoTheme(value);
    }
    async function syncState() {
        const [storedTheme, firstThemeResponse, isAutoResponse] = await Promise.all([
            storageManager.getTheme(),
            storageManager.getFirstTheme(),
            storageManager.getIsAutoTheme()
        ]);
        let firstThemeValue;
        if (isAutoResponse) {
            const defaultSystemTheme = await getDefaultSystemTheme();
            await setIsAutoTheme(true);
            await setTheme(defaultSystemTheme);
            if (!firstThemeResponse) firstThemeValue = defaultSystemTheme;
        } else if (isTheme(storedTheme) && allThemes.includes(storedTheme)) {
            await setTheme(storedTheme);
            if (!firstThemeResponse) firstThemeValue = storedTheme;
        }
        if (firstThemeValue) {
            await setFirstTheme(firstThemeValue);
            const formattedTheme = firstThemeValue.toLowerCase();
            analytics.push(AnalyticsEvent.Z_AB_TEST_NEW_USERS, {
                ab_test_light_mode: {
                    [formattedTheme]: {
                        visit: formattedTheme
                    }
                }
            });
        }
    }
    async function recalculateTheme() {
        systemTheme = void 0;
        return syncState();
    }
    function toggleTheme() {
        const newTheme = currentTheme.value === Theme.DARK ? Theme.LIGHT : Theme.DARK;
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                changeMode: newTheme === Theme.LIGHT ? 'light-dark' : 'dark-light'
            }
        });
        setIsAutoTheme(false);
        setTheme(newTheme);
    }
    async function changeTheme(value) {
        let newTheme = value;
        if (value === Theme.AUTO) {
            newTheme = await getDefaultSystemTheme();
            setIsAutoTheme(true);
        } else setIsAutoTheme(false);
        return setTheme(newTheme);
    }
    function setCustomTheme(value) {
        customTheme.value = value;
        setThemeHeaderAndChangeNavbarColor();
    }
    syncState();
    return {
        theme,
        firstTheme,
        isAuto,
        isDark,
        isLight,
        customStatusBarTheme,
        hasCustomTheme,
        recalculateTheme,
        setCustomStatusbarTheme,
        setCustomStatusbarColor,
        toggleTheme,
        changeTheme,
        setCustomTheme,
        isThemeFooterSwitcherEnabled
    };
});
export default useThemeStore;
