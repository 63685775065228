import { BetSlipMainButtonMode, BetSlipResultState } from '../../../enums';
export default function getMainButtonMode(param) {
    let { resultState, isPendingChanges, isLoggedIn, notEnoughBalance, mustAcceptChanges, stakeIsOutOfRange, isBetModeMatchesSlipTypes, identificationRequired, isFreeBet } = param;
    if (resultState === BetSlipResultState.ERROR) return BetSlipMainButtonMode.PLACE_BET_ERROR;
    if (resultState === BetSlipResultState.PENDING || resultState === BetSlipResultState.WAIT_FOR_RETRY) return BetSlipMainButtonMode.PENDING_PLACE_BET;
    if (resultState === BetSlipResultState.SUCCESS) return BetSlipMainButtonMode.BET_SUCCESSFULLY_PLACED;
    if (!isBetModeMatchesSlipTypes) return BetSlipMainButtonMode.NOT_ENOUGH_EVENTS;
    if (isPendingChanges) return BetSlipMainButtonMode.PENDING_CHANGES;
    if (mustAcceptChanges) return BetSlipMainButtonMode.MUST_ACCEPT_CHANGES;
    if (notEnoughBalance) return BetSlipMainButtonMode.NOT_ENOUGH_BALANCE;
    if (stakeIsOutOfRange) return isFreeBet ? BetSlipMainButtonMode.UNABLE_TO_APPLY_FREEBET : BetSlipMainButtonMode.INCORRECT_RANGE;
    if (!isLoggedIn) return BetSlipMainButtonMode.NOT_LOGGED_IN;
    if (identificationRequired) return BetSlipMainButtonMode.IDENTIFICATION_REQUIRED;
    return BetSlipMainButtonMode.READY_TO_PLACE_BET;
}
