import RouteName from '@leon-hub/routing-config';
import { EgsGameType } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
// eslint-disable-next-line max-len
export default function getEgsGameRouteLocation(game) {
    if (game.typeId === EgsGameType.FASTGAMES) return {
        name: RouteName.QUICK_BETS_DETAILS,
        params: {
            gameUrl: game.url,
            groupUrl: game.group.url
        }
    };
    const parameters = {
        gameUrl: game.url,
        groupUrl: game.group.url
    };
    return {
        name: game.typeId === EgsGameType.LIVE ? CasinoRouteName.CASINO_LIVE_GAME : CasinoRouteName.CASINO_GAME,
        params: parameters
    };
}
