import { computed, toRef } from 'vue';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { getDefaultWidgetUrl } from 'web/src/modules/framed-app/utils';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import EgsGameWidgetPostMessageEvent, { EgsGameWidgetPostMessageBusInitiator } from 'web/src/modules/framed-app/components/EGSGameFramedWidget/utils/EgsGameWidgetPostMessageEvent';
import { usePreventTitleChange } from 'web/src/modules/egs/components/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default function useEgsInjectedGameIframeWidget(props, emit) {
    const siteConfigStore = useSiteConfigStore();
    toRef(siteConfigStore, 'widgetIframeUrl');
    const iframeName = IframeWidget.EgsGame;
    const gameUrl = computed(()=>getDefaultWidgetUrl(iframeName, void 0));
    let postMessageBus;
    function onIframeMounted() {
        const iframeContentWindow = requireContentWindowByIFrameName(iframeName);
        postMessageBus = new PostMessageBus({
            target: iframeContentWindow,
            targetOrigin: '*',
            initiator: EgsGameWidgetPostMessageBusInitiator
        });
        postMessageBus.emit(EgsGameWidgetPostMessageEvent.init, {
            gameHtml: props.gameHtml
        });
        postMessageBus.on(EgsGameWidgetPostMessageEvent.stateChanged, ()=>{
            postMessageBus?.emit(EgsGameWidgetPostMessageEvent.init, {
                gameHtml: props.gameHtml
            });
        });
    }
    function onIframeLoaded() {
        emit('load');
    }
    usePreventTitleChange();
    return {
        iframeName,
        gameUrl,
        onIframeMounted,
        onIframeLoaded
    };
}
