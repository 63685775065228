import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { onBeforeUnmount, onBeforeMount } from 'vue';
import { VLoader } from '@components/loader';
import { useVLoaderDelayed } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLoaderDelayed',
    props: {
        delay: {},
        size: {},
        color: {},
        title: {},
        hint: {},
        margin: {}
    },
    setup (__props) {
        const props = __props;
        const { loaderIsVisible, onDisappear, onAppear } = useVLoaderDelayed(props);
        onBeforeMount(onAppear);
        onBeforeUnmount(onDisappear);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(loaderIsVisible) ? _withDirectives((_openBlock(), _createBlock(_unref(VLoader), {
                key: 0,
                title: _ctx.title,
                hint: _ctx.hint,
                size: _ctx.size,
                color: _ctx.color,
                margin: _ctx.margin
            }, null, 8, [
                "title",
                "hint",
                "size",
                "color",
                "margin"
            ])), [
                [
                    _directive_auto_id,
                    'VLoaderDelayed'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
