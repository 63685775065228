import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1
};
import { computed, useCssModule } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { BadgeKind, BadgePosition } from 'web/src/components/Badge/VBadge/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LBadge',
    props: {
        label: {
            default: ''
        },
        kind: {
            default: BadgeKind.DEFAULT
        },
        isActive: {
            type: Boolean
        },
        position: {
            default: BadgePosition.DEFAULT
        },
        iconName: {
            default: ''
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        /* eslint-disable vue-scoped-css/require-scoped */ const props = __props;
        const emit = __emit;
        const $style = useCssModule();
        const classNames = computed(()=>{
            const { kind, position, isActive } = props;
            const classes = [
                $style.badge
            ];
            if (kind && kind !== BadgeKind.DEFAULT) classes.push($style[`badge--kind-${kind}`]);
            if (position && position !== BadgePosition.DEFAULT) classes.push($style[`badge--position-${position}`]);
            if (isActive) classes.push($style['badge--active']);
            return classes;
        });
        const iconProperties = computed(()=>props.iconName ? {
                name: props.iconName,
                size: IconSize.SIZE_12
            } : void 0);
        function onClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(classNames.value),
                onClick: onClick
            }, [
                props.iconName ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                    key: 0
                }, iconProperties.value, {
                    class: _unref($style).badge__icon
                }), null, 16, [
                    "class"
                ])) : _createCommentVNode("", true),
                _ctx.label ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'Badge'
                ]
            ]);
        };
    }
});
