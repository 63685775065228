import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
import { IconName } from '@leon-hub/icons';
import VSnackBar from 'web/src/components/SnackBar/VSnackBar/VSnackBar.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VTooltip',
    props: {
        text: {},
        isProfileModalTooltip: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass({
                    [_ctx.$style['tooltip']]: true,
                    [_ctx.$style['tooltip--profile']]: _ctx.isProfileModalTooltip
                })
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['tooltip__text'])
                }, _toDisplayString(_ctx.text), 3)
            ], 2));
    }
});
