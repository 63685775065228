import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { VideoVerificationStatus } from '@leon-hub/api-sdk';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { doRefuseMeeting, doScheduleMeeting, fetchAvailableMeetingSlots, fetchUpcomingMeeting, mapTimesArrayByDay } from './utils';
const useVideoVerificationStore = defineStore('video-verification', ()=>{
    // State
    const availableMeetingSlots = ref(new Map());
    const upcomingMeeting = ref();
    const isTimeChangeAvailable = ref();
    const isVideoVerificationEnabled = toRef(useSiteConfigStore(), 'isVideoVerificationFeatureEnabled');
    const videoVerificationStatus = toRef(useCustomerDataStore(), 'videoVerificationStatus');
    // Getters
    const availableSlots = computed(()=>availableMeetingSlots.value);
    const getUpcomingMeeting = computed(()=>upcomingMeeting.value);
    const getUpcomingMeetingTime = computed(()=>{
        if (!upcomingMeeting.value) return '';
        return DateTime.formatTimeStamp(upcomingMeeting.value.startTime, TimeFormats.hoursMinutes);
    });
    const getUpcomingMeetingDate = computed(()=>{
        if (!upcomingMeeting.value) return '';
        return DateTime.formatTimeStamp(upcomingMeeting.value.startTime, TimeFormats.dayMonthYear);
    });
    const getUpcomingMeetingRoomUrl = computed(()=>upcomingMeeting.value?.roomUrl);
    const isTimeToMeetingLessThirtyMinutes = computed(()=>{
        if (!upcomingMeeting.value) return false;
        return (upcomingMeeting.value.startTime - DateTime.now().toTimestamp()) / 60000 <= 30;
    });
    const isMeetingTimeStillAvailable = computed(()=>{
        if (!upcomingMeeting.value) return false;
        return upcomingMeeting.value.endTime - DateTime.now().toTimestamp() > 0;
    });
    const isShowHintVideoVerificationProfile = computed(()=>{
        if (videoVerificationStatus.value && isVideoVerificationEnabled.value) return videoVerificationStatus.value !== VideoVerificationStatus.NONE && videoVerificationStatus.value !== VideoVerificationStatus.PENDING && videoVerificationStatus.value !== VideoVerificationStatus.PASSED && videoVerificationStatus.value !== VideoVerificationStatus.UNKNOWN;
        return false;
    });
    // Actions
    async function syncSlots() {
        const { availableMeetingSlots: data } = await fetchAvailableMeetingSlots(!!isTimeChangeAvailable.value);
        availableMeetingSlots.value = mapTimesArrayByDay(data);
    }
    async function syncUpcomingMeeting() {
        const response = await fetchUpcomingMeeting();
        upcomingMeeting.value = response?.meetingSlot;
        isTimeChangeAvailable.value = response?.isTimeChangeAvailable;
    }
    async function saveScheduleMeeting(meetingSlotId) {
        await doScheduleMeeting({
            meetingSlotId,
            rescheduledMeetingSlotId: upcomingMeeting.value?.id
        });
    }
    async function refuseMeeting() {
        await doRefuseMeeting();
    }
    // On init
    if (isVideoVerificationEnabled.value) {
        syncSlots();
        syncUpcomingMeeting();
    }
    return {
        availableSlots,
        getUpcomingMeeting,
        isTimeChangeAvailable,
        getUpcomingMeetingTime,
        getUpcomingMeetingDate,
        getUpcomingMeetingRoomUrl,
        isTimeToMeetingLessThirtyMinutes,
        isMeetingTimeStillAvailable,
        isShowHintVideoVerificationProfile,
        syncSlots,
        syncUpcomingMeeting,
        saveScheduleMeeting,
        refuseMeeting
    };
});
export default useVideoVerificationStore;
