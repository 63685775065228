import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { LoginType } from '@leon-hub/api-sdk/src/sdk/sdk';
import BaseRouteName from '@leon-hub/routing-config-names/src/BaseRouteName';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { FormControlType, FormErrorKeyword, FormFocusType } from 'web/src/components/Form/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCasAuthStore } from 'web/src/modules/cas/store';
import { CaptchaRequesterStrategy } from 'web/src/modules/captcha/store/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { TextInputTypes } from 'web/src/components/Input/enums';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import { LoginFieldName } from './enums';
import { accountSchema, emailSchema, phoneSchema } from './schemas';
function getDefaultLoginValue() {
    return {
        [LoginType.PHONE]: {
            prefix: "356",
            suffix: ''
        },
        [LoginType.EMAIL]: '',
        [LoginType.ACCOUNT]: ''
    };
}
const useLoginStore = defineStore('login', ()=>{
    const { $translate } = useI18n();
    const { parsePhone, getDefaultPhoneValueForForm } = useFormDefaultFormPhoneValue();
    const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
    const casAuthStore = useCasAuthStore();
    const captchaSettings = useCaptchaStore().captchaSettingsForStrategy(CaptchaRequesterStrategy.LOGIN);
    const isCookiesOk = toRef(casAuthStore, 'isCookiesOk');
    useLocalStorageManager();
    const loginType = ref(LoginType.PHONE);
    const phoneNumber = ref(null);
    const defaultFieldCommon = ref({
        password: '',
        checkbox: true,
        captchaType: void 0,
        captchaAnswer: void 0,
        fingerprint: void 0
    });
    const defaultFieldLogin = ref(getDefaultLoginValue());
    const isCaptchaEnabled = computed(()=>captchaSettings.value.isEnabledToShow);
    const captchaType = computed(()=>captchaSettings.value.captchaType);
    const loginField = computed(()=>{
        switch(loginType.value){
            case LoginType.PHONE:
                return {
                    title: $translate('WEB2_LOGIN_PHONE').value,
                    widget: FormControlType.Phone,
                    default: getDefaultPhoneValueForForm(phoneNumber.value ?? {}),
                    options: {
                        autocomplete: 'off',
                        label: $translate('WEB2_PHONE_NUMBER').value,
                        placeholder: $translate('WEB2_PHONE_INPUT_PLACEHOLDER').value
                    }
                };
            case LoginType.EMAIL:
                return {
                    title: $translate('WEB2_LOGIN_EMAIL').value,
                    widget: FormControlType.SuggestEmail,
                    default: defaultFieldLogin.value.EMAIL,
                    options: {
                        autocomplete: 'off',
                        placeholder: $translate('WEB2_EMAIL_INPUT_PLACEHOLDER').value,
                        suggestion: useSiteConfigStore().formsEmailHints
                    }
                };
            case LoginType.ACCOUNT:
                return {
                    title: $translate('WEB2_LOGIN_ACCOUNT').value,
                    widget: FormControlType.Text,
                    default: defaultFieldLogin.value.ACCOUNT,
                    options: {
                        autocomplete: 'off',
                        type: TextInputTypes.TEL,
                        placeholder: $translate('WEB2_LOGIN_ACCOUNT').value
                    }
                };
            default:
                return {
                    hidden: true
                };
        }
    });
    const schema = computed(()=>{
        switch(loginType.value){
            case LoginType.PHONE:
                return phoneSchema;
            case LoginType.EMAIL:
                return emailSchema;
            case LoginType.ACCOUNT:
                return accountSchema;
            default:
                return accountSchema;
        }
    });
    const uiSchema = computed(()=>({
            order: [
                LoginFieldName.LOGIN,
                LoginFieldName.PASSWORD,
                LoginFieldName.CHECKBOX,
                LoginFieldName.CAPTCHA_TOKEN,
                LoginFieldName.CAPTCHA_TYPE
            ],
            submitButton: null,
            focus: {
                type: FormFocusType.OnMount,
                field: LoginFieldName.LOGIN
            },
            fields: {
                [LoginFieldName.LOGIN]: loginField.value,
                [LoginFieldName.PASSWORD]: {
                    widget: FormControlType.Password,
                    title: $translate('JSPNAV_USTAT_PWD').value,
                    default: defaultFieldCommon.value.password,
                    options: {
                        autocomplete: 'off',
                        placeholder: $translate('WEB2_PASSWORD_INPUT_PLACEHOLDER').value,
                        hideValidation: true,
                        hintLinkRight: {
                            href: {
                                name: BaseRouteName.RESTORE_PASSWORD
                            },
                            text: $translate('JSPACC_LOGIN_FORGPWD').value
                        }
                    }
                },
                [LoginFieldName.CHECKBOX]: {
                    hidden: !useSiteConfigStore().isCasAuthEnabled,
                    title: $translate('JSP_LOGIN_SAVE_PASSWORD').value,
                    widget: FormControlType.Checkbox,
                    default: isCookiesOk.value || defaultFieldCommon.value.checkbox,
                    options: {
                        id: 'cas-save-password-checkbox'
                    }
                },
                [LoginFieldName.CAPTCHA_TYPE]: {
                    hidden: true,
                    default: captchaSettings.value.captchaType
                },
                [LoginFieldName.CAPTCHA_TOKEN]: {
                    hidden: !captchaSettings.value.isEnforced,
                    widget: FormControlType.Captcha,
                    options: {
                        captchaRequesterStrategy: CaptchaRequesterStrategy.LOGIN,
                        captchaEnabled: captchaSettings.value.isEnabledToShow,
                        reCaptchaTheme: captchaSettings.value.theme,
                        isEnforced: captchaSettings.value.isEnforced
                    }
                },
                [LoginFieldName.FINGERPRINT]: {
                    hidden: true,
                    default: void 0
                }
            },
            validatorErrorPatterns: {
                byWidget: {
                    [FormControlType.Text]: {
                        [FormErrorKeyword.Pattern]: $translate('WEB2_LOGIN_FORM_ERROR_PATTERN_ACCOUNT_NUMBER').value
                    },
                    ...phoneErrorPatterns.value
                }
            }
        }));
    function setLoginType(type) {
        loginType.value = type;
    }
    function setPhoneNumber(value) {
        phoneNumber.value = value;
    }
    function setUiSchemaDefault(param) {
        let { field, value } = param;
        defaultFieldCommon.value[field] = value;
    }
    function setUiSchemaLoginDefault(param) {
        let { activeTab, value } = param;
        defaultFieldLogin.value[activeTab] = value;
    }
    function resetUiSchemaLoginDefault() {
        defaultFieldLogin.value = getDefaultLoginValue();
    }
    function prefillLoginForm(type, value, fakePassword) {
        setLoginType(type);
        if (loginType.value === LoginType.PHONE) {
            phoneNumber.value = parsePhone(value);
            setUiSchemaLoginDefault({
                activeTab: LoginType.PHONE,
                value: phoneNumber.value
            });
        } else setUiSchemaLoginDefault({
            activeTab: loginType.value,
            value
        });
        setUiSchemaDefault({
            field: LoginFieldName.PASSWORD,
            value: fakePassword
        });
    }
    return {
        loginType,
        phoneNumber,
        schema,
        uiSchema,
        isCaptchaEnabled,
        captchaType,
        defaultFieldLogin,
        setLoginType,
        setPhoneNumber,
        setUiSchemaDefault,
        setUiSchemaLoginDefault,
        resetUiSchemaLoginDefault,
        prefillLoginForm
    };
});
export default useLoginStore;
