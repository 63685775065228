import { ref, computed, watch } from 'vue';
import { useBaseInputEvents, useHintParentProps } from 'web/src/components/Input/composables';
import createUniqueId from 'web/src/utils/createUniqueId';
export default function useSingleSelect(props, emits) {
    const isFocused = ref(false);
    const isHovered = ref(false);
    const selectSelectedValue = ref(props.selectedValue || '');
    const { emitChange, emitFocus, emitBlur } = useBaseInputEvents(emits);
    const uniqueId = computed(()=>createUniqueId(props.name || 'select'));
    watch(()=>props.selectedValue, ()=>{
        selectSelectedValue.value = props.selectedValue || '';
    });
    const eventToEmit = computed(()=>({
            target: {
                name: props.name || '',
                value: selectSelectedValue.value
            }
        }));
    const selectedLabel = computed(()=>{
        if (!selectSelectedValue.value || !props.options) return '';
        const selectedItem = props.options.find((item)=>item.value === selectSelectedValue.value);
        return selectedItem ? selectedItem.label : '';
    });
    const { hasError, hintProperties } = useHintParentProps(props);
    const borderWrapperProps = computed(()=>({
            isFocus: isFocused.value,
            isHover: isHovered.value,
            isDisabled: props.disabled,
            hasError: hasError.value
        }));
    const wrapperInputProps = computed(()=>({
            ...hintProperties.value,
            inputId: uniqueId.value,
            label: props.label,
            disabled: props.disabled,
            isFocus: isFocused.value,
            isEmpty: !selectSelectedValue.value,
            isLarge: props.isLarge
        }));
    const selectProperties = computed(()=>({
            autofocus: props.autofocus,
            id: uniqueId.value,
            disabled: props.disabled,
            dropdownMinWidth: props.dropdownMinWidth,
            hasError: hasError.value,
            isEmpty: !selectSelectedValue.value,
            name: props.name,
            options: props.options,
            placeholder: props.placeholder,
            selectedLabel: selectedLabel.value,
            selectedValue: selectSelectedValue.value,
            isCountrySelector: props.isCountrySelector,
            isLarge: props.isLarge
        }));
    const mouseover = ()=>{
        isHovered.value = true;
    };
    const mouseleave = ()=>{
        isHovered.value = false;
    };
    const onChange = (event)=>{
        const { value } = event.target;
        if (value === selectSelectedValue.value) return;
        selectSelectedValue.value = value;
        emitChange(eventToEmit.value);
    };
    const onFocus = ()=>{
        isFocused.value = true;
        emitFocus(eventToEmit.value);
    };
    const onBlur = ()=>{
        isFocused.value = false;
        emitBlur(eventToEmit.value);
    };
    return {
        mouseleave,
        mouseover,
        onChange,
        onFocus,
        onBlur,
        selectedLabel,
        selectSelectedValue,
        borderWrapperProps,
        wrapperInputProps,
        selectProperties
    };
}
