import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize, SlottIconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { convertCommonClassToCssModule as toModule } from 'web/src/components/List/utils/convertCommonClassesToCssModule';
import PaymentsLogo from 'web/src/modules/payments/components/PaymentsLogo/PaymentsLogo.vue';
import { VCheckbox } from 'web/src/components/Checkbox';
import { useVListItemExtended } from 'web/src/components/List/VListItemExtended/composables';
import { ListItemExtendedKind } from './enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VListItemExtended',
    props: {
        haveExpand: {
            type: Boolean,
            default: true
        },
        iconName: {
            default: IconName.STAR
        },
        iconBackground: {},
        isChecked: {
            type: Boolean
        },
        isDisabled: {
            type: Boolean
        },
        kind: {
            default: ListItemExtendedKind.ICON
        },
        logoName: {},
        logoSrc: {},
        subTitleFirst: {},
        subTitleFirstColor: {},
        subTitleSecond: {},
        title: {},
        hideUnavailableMessage: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "click-checkbox"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        function emitClickCheckbox() {
            emit('click-checkbox');
        }
        function emitClick() {
            emit('click');
        }
        const { classObject, computedIconProperties, hasIcon, hasLogo, hasCheckbox, hasSubTitleContent, isLogoDisabled, haveSuffix } = useVListItemExtended(props);
        const expandLeftIconProperties = {
            name: IconName.EXPAND_RIGHT,
            size: IconSize.SIZE_16
        };
        const lockIconProperties = {
            name: IconName.LOCK,
            size: IconSize.SIZE_24
        };
        function onClick() {
            if (hasCheckbox.value) {
                emitClickCheckbox();
                return;
            }
            emitClick();
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(_unref(classObject)),
                onClick: _withModifiers(onClick, [
                    "prevent"
                ])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_unref(toModule)('list-item-extended__inner'))
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_unref(toModule)('list-item-extended__prefix'))
                    }, [
                        _unref(hasIcon) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_unref(toModule)('list-item-extended__prefix-icon'))
                        }, [
                            _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(computedIconProperties))), null, 16)
                        ], 2)) : _unref(hasLogo) ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_unref(toModule)('list-item-extended__prefix-logo'))
                        }, [
                            _ctx.isDisabled ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                                key: 0
                            }, lockIconProperties, {
                                class: _unref(toModule)('list-item-extended__prefix-lock')
                            }), null, 16, [
                                "class"
                            ])) : _createCommentVNode("", true),
                            _createVNode(PaymentsLogo, {
                                alt: _ctx.logoName || '',
                                src: _ctx.logoSrc,
                                class: _normalizeClass(_unref(toModule)('list-item-extended__prefix-logo-img'))
                            }, null, 8, [
                                "alt",
                                "src",
                                "class"
                            ])
                        ], 2)) : _unref(hasCheckbox) ? (_openBlock(), _createBlock(_unref(VCheckbox), {
                            key: 2,
                            id: `${_ctx.title}_checkbox`,
                            checked: _ctx.isChecked,
                            class: _normalizeClass(_unref(toModule)('list-item-extended__prefix-checkbox'))
                        }, null, 8, [
                            "id",
                            "checked",
                            "class"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_unref(toModule)('list-item-extended__default'))
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_unref(toModule)('list-item-extended__title'))
                        }, _toDisplayString(_ctx.title), 3),
                        _unref(hasSubTitleContent) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_unref(toModule)('list-item-extended__subtitle-content'))
                        }, [
                            !_ctx.subTitleFirst || _unref(isLogoDisabled) && !_ctx.hideUnavailableMessage ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass(_unref(toModule)('list-item-extended__subtitle'))
                            }, _toDisplayString(_ctx.subTitleFirst), 3)),
                            !_ctx.subTitleSecond || _unref(isLogoDisabled) ? _unref(isLogoDisabled) && !_ctx.hideUnavailableMessage ? (_openBlock(), _createElementBlock("span", {
                                key: 2,
                                class: _normalizeClass(_unref(toModule)('list-item-extended__subtitle--locked'))
                            }, _toDisplayString(_ctx.$t('WEB2_SERVICE_UNAVAILABLE')), 3)) : _createCommentVNode("", true) : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass(_unref(toModule)('list-item-extended__subtitle'))
                            }, _toDisplayString(_ctx.subTitleSecond), 3))
                        ], 2)) : _createCommentVNode("", true)
                    ], 2),
                    _unref(haveSuffix) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_unref(toModule)('list-item-extended__suffix'))
                    }, [
                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(expandLeftIconProperties)), null, 16)
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VListItemExtended'
                ]
            ]);
        };
    }
});
