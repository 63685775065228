/* eslint-disable class-methods-use-this */ import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class QuickBetsModule extends AppModule {
    addRoutes(router) {
        router.addPrefetchRoute({
            name: RouteName.FAST_GAMES,
            path: '/fastgames',
            prefetch: ()=>import('./prefetch/FastGamesRedirectPrefetch')
        });
        router.addPrefetchRoute({
            name: RouteName.FAST_GAMES_ITEM,
            path: '/fastgames/:gameId',
            prefetch: ()=>import('./prefetch/FastGamesDetailsRedirectLobbyPrefetch')
        });
        router.addDefaultRoute({
            name: RouteName.QUICK_BETS,
            path: '/quick-bets/:groupUrl?/:categoryUrl?',
            prefetch: ()=>import('./prefetch/QuickBetsLobbyPrefetch'),
            component: ()=>import('./pages/QuickBetsLobbyRoutePage/QuickBetsLobbyRoutePage.vue'),
            seoController: ()=>import('web/src/modules/egs/submodules/seo/EgsPageSeoController')
        });
        "1";
        router.addDefaultRoute({
            name: RouteName.QUICK_BETS_DETAILS,
            path: '/quick-bets/:groupUrl/play/:gameUrl',
            prefetch: ()=>import('./prefetch/QuickBetsDetailsPrefetch'),
            component: ()=>import('./pages/QuickBetDetailsRoutePage/QuickBetsDetailsRoutePage.vue'),
            seoController: ()=>import('web/src/modules/egs/submodules/seo/EgsPageSeoController')
        });
    }
}
