import { computed } from 'vue';
import { isOptionalString } from '@leon-hub/guards';
export default function useFormPaymentsDisabledInput(props, inputType) {
    const componentProps = computed(()=>{
        const { value } = props;
        isOptionalString(value);
        return {
            value: value || '',
            inputType
        };
    });
    return {
        componentProps
    };
}
