import { defineStore } from 'pinia';
import { ref } from 'vue';
const useAppFullscreenStore = defineStore('app-fullscreen', ()=>{
    const isAppInFullscreenMode = ref(!!document.fullscreenElement);
    function setFullscreenMode(value) {
        isAppInFullscreenMode.value = value;
        if (value && !document.fullscreenElement) document.documentElement.requestFullscreen();
        else if (!value && document.fullscreenElement) document.exitFullscreen();
    }
    document.addEventListener('fullscreenchange', ()=>{
        setFullscreenMode(!!document.fullscreenElement);
    });
    return {
        isAppInFullscreenMode,
        setFullscreenMode
    };
});
export default useAppFullscreenStore;
