import { assertFullCoreLeagueResponse, assertFullCoreRegionResponse, assertFullCoreSportResponse } from 'web/src/modules/sportline/utils/rest/assert';
import { buildSportlineEventNavigationParameters } from 'web/src/modules/sportline/utils/rest/navigation-parameters';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { checkMatchStreamIndicator } from 'web/src/modules/sportline/submodules/streams';
export function parseSportlineSearchResult(response, options, streamEvents) {
    const liveEvents = [];
    const prematchEvents = [];
    for (const item of response){
        const id = String(item.id);
        const leagueResponse = assertFullCoreLeagueResponse(item.league);
        const regionResponse = assertFullCoreRegionResponse(leagueResponse.region);
        const sportResponse = assertFullCoreSportResponse(leagueResponse.sport);
        // eslint-disable-next-line max-len
        const navigationParameters = buildSportlineEventNavigationParameters({
            ...options,
            sportEventResponse: item,
            leagueResponse,
            regionResponse,
            sportResponse
        });
        const sportlineType = item.betline === BetlineType.Live ? SportlineType.Live : SportlineType.Prematch;
        const event = {
            id,
            name: item.name,
            kickoff: item.kickoff,
            regionName: regionResponse.name,
            leagueName: leagueResponse.name,
            sportName: sportResponse.name,
            liveScore: item.liveStatus?.score,
            liveProgress: item.liveStatus?.progress,
            navigationParameters,
            streamAvailable: checkMatchStreamIndicator(streamEvents, {
                id,
                type: sportlineType
            })
        };
        if (sportlineType === SportlineType.Live) liveEvents.push(event);
        else prematchEvents.push(event);
    }
    return {
        liveEvents,
        prematchEvents
    };
}
