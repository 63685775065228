export function useCanShowSegmentGroup(props) {
    const { selectedFilter, activeTransitionId } = props;
    function canShowLiveForGroup(group) {
        return activeTransitionId.value ? group.allowedForTransitionIds.includes(activeTransitionId.value) : !!selectedFilter.value && group.allowedForTransitionIds.includes(selectedFilter.value);
    }
    function canShowPrematchForGroup(group) {
        return activeTransitionId.value ? activeTransitionId.value === group.transitionId : !!selectedFilter.value && group.transitionId === selectedFilter.value;
    }
    return {
        canShowLiveForGroup,
        canShowPrematchForGroup
    };
}
