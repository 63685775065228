import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { useHead } from '@unhead/vue';
import { AlertIconName } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import ErrorContent from '../../components/ErrorContent/ErrorContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ForbiddenErrorRoutePage',
    setup (__props) {
        useHead({
            meta: [
                {
                    content: '403',
                    name: 'prerender-status-code'
                }
            ]
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ErrorContent, {
                "error-number": '403',
                "error-title": _ctx.$t('WEB2_ERROR_403_TITLE'),
                "error-message": _ctx.$t('WEB2_ERROR_403_MSG'),
                "has-support": true,
                "alert-icon": void 0,
                "alert-icon-size": "large"
            }, {
                footer: _withCtx(()=>[
                        _createVNode(VButton, {
                            tag: _unref(Tag).ROUTER_LINK,
                            href: "/",
                            "full-width": "",
                            label: _ctx.$t('WEB2_ERROR_BTN_GO_HOME')
                        }, null, 8, [
                            "tag",
                            "label"
                        ])
                    ]),
                _: 1
            }, 8, [
                "error-number",
                "error-title",
                "error-message",
                "has-support",
                "alert-icon"
            ])), [
                [
                    _directive_auto_id,
                    'ForbiddenErrorRoutePage'
                ]
            ]);
        };
    }
});
