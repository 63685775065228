import { computed, toRef, getCurrentInstance, watch } from 'vue';
import { useRouter } from 'vue-router';
import { CustomerConfig, PinCodePlace, PinCodeState as PinCodeStateEnum, Theme } from '@leon-hub/api-sdk/src/sdk/sdk';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useUserNavigationStore } from 'web/src/modules/user/submodules/navigation/store';
import { useRootStore, useSiteConfigStore, useStorageManagerStore } from 'web/src/modules/core/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCopyToClipboard, useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useTheme } from 'web/src/modules/theme/composables';
import { useThemeStore } from 'web/src/modules/theme/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { LanguageSelectorPlace } from 'web/src/modules/i18n/components/LanguageSelector/enums';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';
import useBiometricAuthSettings from './useBiometricAuthSettings';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useUserSettingsPage() {
    const { $translate, setLanguageSelectorPlace } = useI18n();
    const analytics = useAnalytics();
    const router = useRouter();
    const { showDialog } = useDialogs();
    const { isAuto, theme } = useTheme();
    const rootStore = useRootStore();
    const userStore = useUserStore();
    const customerDataStore = useCustomerDataStore();
    const siteConfigStore = useSiteConfigStore();
    const { copy } = useCopyToClipboard();
    const { isOddsFormatSwitcherEnabled } = useOddsSettings();
    const userSettingsNavigation = toRef(useUserNavigationStore(), 'userProfileSettingsNavigation');
    const isLanguageSwitcherAvailable = toRef(rootStore, 'isLanguageSwitcherAvailable');
    const currentLanguageName = toRef(rootStore, 'currentLanguageName');
    const currentLanguageFlag = toRef(rootStore, 'currentLanguageFlag');
    const isHiddenBalance = toRef(customerDataStore, 'isHiddenBalance');
    const isPinCodeRequestedOnLogin = toRef(customerDataStore, 'isPinCodeRequestedOnLogin');
    const pinCodeRequestedPlaces = toRef(()=>siteConfigStore.pinCodeRequestedPlaces);
    const storageManager = useStorageManagerStore();
    const pinCodeModule = usePinCodeStore();
    const { isFingerprintSupported, isFingerprintAuthenticationEnabled, changeFingerSettings } = useBiometricAuthSettings();
    function openLanguage() {
        "1";
        setLanguageSelectorPlace(LanguageSelectorPlace.SETTINGS);
        router.push({
            name: RouteName.LANGUAGE
        });
    }
    function getIconSettings(item) {
        return item.icon?.name;
    }
    const themeLabel = computed(()=>{
        const label = isAuto.value ? Theme.AUTO : theme.value;
        return $translate(`WEB2_CORDOVA_THEME_${label}`).value;
    });
    function handleChangeTheme(themeValue) {
        return useThemeStore().changeTheme(themeValue);
    }
    async function changeHiddenBalance(value) {
        const hideBalance = value ? 'on' : 'off';
        analytics.push(AnalyticsEvent.Z_HIDE_BALANCE, {
            hideBalance
        });
        await userStore.setAdditionalPropsUser({
            value: {
                value
            },
            config: CustomerConfig.HIDE_BALANCE
        });
        await userStore.loadUserData(true);
    }
    async function onClickChangeHiddenBalance() {
        await changeHiddenBalance(!isHiddenBalance.value);
    }
    const showLoginPinCode = computed(()=>!!pinCodeRequestedPlaces.value?.includes(PinCodePlace.PIN_CODE_AT_LOGIN_SETTING));
    const hasPinCode = computed(()=>!!pinCodeModule.status && pinCodeModule.status !== PinCodeStateEnum.RESET);
    const hasLoginPinCode = computed(()=>!!pinCodeModule.pinCodeRequestedAtLogin);
    const pinCodeLoading = computed(()=>pinCodeModule.loading);
    const isShowPinCode = computed(()=>{
        if (!pinCodeRequestedPlaces.value?.length) return false;
        return !(pinCodeRequestedPlaces.value?.length === 1 && pinCodeRequestedPlaces.value[0] === PinCodePlace.UNKNOWN);
    });
    function clearLocalStorage() {
        showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_CLEAR_CACHE').value,
                confirmMessage: $translate('WEB2_CLEAR_CACHE_MESSAGE').value,
                buttons: [
                    {
                        label: $translate('WEB2_CLEAR').value
                    },
                    {
                        label: $translate('WEB2_MOBILE_CANCEL').value
                    }
                ],
                dataTestId: 'clear-cache'
            }
        }).subscribe({
            [DialogAction.CONFIRM]: ()=>{
                storageManager.clearCache();
                const instance = getCurrentInstance();
                instance?.proxy?.$forceUpdate();
                showDialog({
                    presetName: PresetName.ALERT_SUCCESS_NO_BUTTONS,
                    options: {
                        title: $translate('WEB2_CLEAR_CACHE_SUCCESS').value,
                        dataTestId: 'clear-cache-success'
                    }
                });
            }
        });
    }
    function onItemClick(item) {
        if (item.props && item.props.isCacheCleaner) clearLocalStorage();
        else router.push({
            name: item.routeName
        });
    }
    function switchPinCode() {
        if (!pinCodeLoading.value) {
            pinCodeModule.setRouteFrom({
                name: RouteName.CUSTOMER_SETTINGS
            });
            let step = 'CREATE';
            if (hasPinCode.value) step = 'DELETE';
            else if (!pinCodeModule.wasPinCodeSetOnce) step = 'MODAL_CREATE';
            pinCodeModule.setStepWithLS(step);
            router.replace({
                name: RouteName.PIN_CODE_PROFILE
            });
        }
    }
    function changePinCode() {
        pinCodeModule.setRouteFrom({
            name: RouteName.CUSTOMER_SETTINGS
        });
        pinCodeModule.setStepWithLS('UPDATE_PREV');
        router.replace({
            name: RouteName.PIN_CODE_PROFILE
        });
    }
    function loginPinCode() {
        pinCodeModule.setPinCodePlace(PinCodePlace.PIN_CODE_AT_LOGIN_SETTING);
        pinCodeModule.setRouteFrom({
            name: RouteName.CUSTOMER_SETTINGS
        });
        pinCodeModule.setStepWithLS('VERIFY');
        router.replace({
            name: RouteName.PIN_CODE_PROFILE
        });
    }
    watch(isPinCodeRequestedOnLogin, ()=>{
        pinCodeModule.setPinCodeRequestedAtLogin();
    });
    return {
        isOddsFormatSwitcherEnabled,
        userSettingsNavigation,
        isLanguageSwitcherAvailable,
        currentLanguageName,
        currentLanguageFlag,
        themeLabel,
        isHiddenBalance,
        isShowPinCode,
        pinCodeRequestedPlaces,
        showLoginPinCode,
        hasPinCode,
        hasLoginPinCode,
        isFingerprintSupported,
        isFingerprintAuthenticationEnabled,
        openLanguage,
        getIconSettings,
        handleChangeTheme,
        changeHiddenBalance,
        onItemClick,
        switchPinCode,
        changePinCode,
        loginPinCode,
        changeFingerSettings,
        onClickChangeHiddenBalance
    };
}
