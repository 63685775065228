import { _ as _define_property } from "@swc/helpers/_/_define_property";
let PaymentsDepositsNavigationParametersHolder = class PaymentsDepositsNavigationParametersHolder {
    static setLastDepositedId(method) {
        PaymentsDepositsNavigationParametersHolder.lastDepositedId = method;
    }
    static getLastDepositedId() {
        return PaymentsDepositsNavigationParametersHolder.lastDepositedId;
    }
};
_define_property(PaymentsDepositsNavigationParametersHolder, "lastDepositedId", void 0);
export { PaymentsDepositsNavigationParametersHolder as default };
