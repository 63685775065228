import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VListItemExtended from 'web/src/components/List/VListItemExtended/VListItemExtended.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccessOptionsListItem',
    props: {
        icon: {},
        title: {
            default: 'Title is not set'
        },
        description: {
            default: 'Description is not set'
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function onClick() {
            emit('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VListItemExtended, {
                title: _ctx.title,
                "icon-name": _ctx.icon,
                "sub-title-first": _ctx.description,
                class: _normalizeClass(_ctx.$style['access-options-list__item']),
                onClick: onClick
            }, null, 8, [
                "title",
                "icon-name",
                "sub-title-first",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'AccessOptionsListItem'
                ]
            ]);
        };
    }
});
