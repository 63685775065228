import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useRootStore } from 'web/src/modules/core/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { defaultCurrency, defaultCurrencyLocale } from '../consts';
import { getCurrencySymbol, isSupportedLocale } from '../utils';
const useCurrencyStore = defineStore('currency', ()=>{
    const rootStore = useRootStore();
    const formatMoneyLocale = toRef(rootStore, 'formatMoneyLocale');
    const defaultUICurrency = toRef(rootStore, 'defaultUICurrency');
    const i18nStore = useI18nStore();
    const { isLoggedIn } = useIsLoggedIn();
    const lang = toRef(i18nStore, 'lang');
    const stateCurrency = ref(defaultCurrency);
    const currency = computed(()=>(isLoggedIn.value ? stateCurrency.value : defaultUICurrency.value) || defaultCurrency);
    const formattedCurrency = computed(()=>getCurrencySymbol(currency.value));
    const currencyLocale = computed(()=>{
        const currentLang = lang.value.toLowerCase();
        const fallbackLocale = currentLang && isSupportedLocale(currentLang) ? currentLang : defaultCurrencyLocale;
        return formatMoneyLocale.value || fallbackLocale;
    });
    function setCurrency(value) {
        stateCurrency.value = value;
    }
    return {
        currency,
        formattedCurrency,
        currencyLocale,
        setCurrency
    };
});
export default useCurrencyStore;
