import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineComingHeader',
    props: {
        caption: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['coming'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['coming__title'])
                }, _toDisplayString(_ctx.caption || _ctx.$t('WEB2_COMING_EVENTS')), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineComingHeader'
                ]
            ]);
        };
    }
});
