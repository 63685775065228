import { getOnboarding } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function fetchOnboarding(param) {
    let { onboardingId, withSlides = false } = param;
    const apiClient = useGraphqlClient();
    return getOnboarding(apiClient, (node)=>node.queries.onboarding.getItems, {
        options: {
            ...onboardingId ? {
                id: onboardingId
            } : {},
            withSlides
        }
    });
}
