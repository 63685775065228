import { getAchievementsProfile } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function getAchievementsProfileRequest(publicId) {
    const apiClient = useGraphqlClient();
    const options = {};
    if (publicId) options.publicId = publicId;
    return getAchievementsProfile(apiClient, (node)=>node.queries.achievements.getProfile, {
        options
    });
}
