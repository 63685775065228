import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useCasinoLoyaltyProgramLevel(props) {
    const { $translate } = useI18n();
    const label = computed(()=>{
        switch(props.status){
            case CasinoLoyaltyProgramLevelStatus.SUCCESS:
                return $translate('WEB2_LOYALTY_PROGRAM_LEVEL_STATUS_SUCCESS').value;
            case CasinoLoyaltyProgramLevelStatus.PROCESS:
                return $translate('WEB2_LOYALTY_PROGRAM_LEVEL_STATUS_PROCESS').value;
            case CasinoLoyaltyProgramLevelStatus.LOCK:
            default:
                return $translate('WEB2_LOYALTY_PROGRAM_LEVEL_STATUS_LOCK').value;
        }
    });
    const iconName = computed(()=>{
        if (props.status === CasinoLoyaltyProgramLevelStatus.PROCESS) return IconName.TIMER_SAND;
        if (props.status === CasinoLoyaltyProgramLevelStatus.LOCK) return IconName.LOCK;
        return IconName.CHECK_FILLED;
    });
    return {
        iconName,
        label
    };
}
