import { getLexGuardSession } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { getLexisNexisSessionStorage } from '../utils/getLexisNexisSessionStorage';
export const getLexisNexisSessionId = async (options)=>{
    const { cache = true, login } = options;
    const storage = getLexisNexisSessionStorage(login);
    if (cache) {
        const session = await storage.getSessionId();
        if (session) return session;
    }
    const data = await getLexGuardSession(useGraphqlClient(), (node)=>node.queries.system.getLexGuardSession);
    await storage.setSessionId(data.id, {
        expires: data.expiresAt
    });
    return data.id;
};
