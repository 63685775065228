import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ConfirmEmailStage } from '../../../enums';
export default function useConfirmEmail(props) {
    const { $translate } = useI18n();
    const isSuccess = computed(()=>props.stage === ConfirmEmailStage.CONFIRM_SUCCESS || props.stage === ConfirmEmailStage.WAIT_FOR_CONFIRMATION);
    const headingText = computed(()=>{
        switch(props.stage){
            case ConfirmEmailStage.EMAIL_UNKNOWN:
            case ConfirmEmailStage.EMAIL_IS_NOT_CONFIRMED:
                return $translate('WEB2_CONFIRM_EMAIL_CAPTION_NOT_CONFIRMED').value;
            case ConfirmEmailStage.CONFIRM_SUCCESS:
                return $translate('WEB2_CONFIRM_EMAIL_CAPTION_SUCCESS').value;
            case ConfirmEmailStage.WAIT_FOR_CONFIRMATION:
                return $translate('WEB2_CONFIRM_EMAIL_CAPTION_CHECK').value;
            case ConfirmEmailStage.TOO_MANY_REQUESTS:
                return $translate('WEB2_CONFIRM_EMAIL_CAPTION_TOO_MANY_REQUESTS').value;
            case ConfirmEmailStage.TECH_ERROR:
                return $translate('WEB2_CONFIRM_EMAIL_CAPTION_TECH_ERROR').value;
            case ConfirmEmailStage.WRONG_TOKEN:
                return $translate('WEB2_CONFIRM_SUBSCRIPTION_CAPTION_WRONG_TOKEN').value;
            case ConfirmEmailStage.ALREADY_CONFIRMED:
                return $translate('WEB2_CONFIRM_EMAIL_CAPTION_WRONG_TOKEN').value;
            default:
                return '';
        }
    });
    const jumbotronProps = computed(()=>{
        const properties = {
            heading: headingText.value
        };
        const iconProperties = {
            iconSize: isSuccess.value ? IconSize.SIZE_80 : IconSize.SIZE_60,
            icon: isSuccess.value ? IconName.CHECK_OUTLINE : IconName.ATTENTION_OUTLINE
        };
        // eslint-disable-next-line default-case
        return {
            ...properties,
            ...iconProperties
        };
    });
    const isErrorViewVisible = computed(()=>{
        switch(props.stage){
            case ConfirmEmailStage.TECH_ERROR:
            case ConfirmEmailStage.TOO_MANY_REQUESTS:
            case ConfirmEmailStage.WRONG_TOKEN:
            case ConfirmEmailStage.ALREADY_CONFIRMED:
                return true;
            default:
                return false;
        }
    });
    const withSupportTranslation = computed(()=>{
        switch(props.stage){
            case ConfirmEmailStage.TOO_MANY_REQUESTS:
                return $translate('WEB2_CONFIRM_EMAIL_MANY_REQUESTS').value;
            case ConfirmEmailStage.TECH_ERROR:
                return $translate('WEB2_CONFIRM_EMAIL_TECH_ERROR').value;
            case ConfirmEmailStage.WRONG_TOKEN:
                return $translate('WEB2_CONFIRM_SUBSCRIPTION_WRONG_TOKEN').value;
            case ConfirmEmailStage.ALREADY_CONFIRMED:
                return $translate('WEB2_CONFIRM_EMAIL_WRONG_TOKEN').value;
            default:
                return '';
        }
    });
    const isLoaderVisible = computed(()=>props.stage === ConfirmEmailStage.PENDING_CHECK || props.stage === ConfirmEmailStage.EMAIL_UNKNOWN && !props.isLoggedIn);
    const confirmButtonsVisible = computed(()=>{
        switch(props.stage){
            case ConfirmEmailStage.TECH_ERROR:
            case ConfirmEmailStage.EMAIL_UNKNOWN:
            case ConfirmEmailStage.EMAIL_IS_NOT_CONFIRMED:
                return true;
            case ConfirmEmailStage.WRONG_TOKEN:
                return props.isLoggedIn ?? false;
            case ConfirmEmailStage.ALREADY_CONFIRMED:
                return false;
            default:
                return false;
        }
    });
    const closeButtonVisible = computed(()=>{
        switch(props.stage){
            case ConfirmEmailStage.CONFIRM_SUCCESS:
            case ConfirmEmailStage.TOO_MANY_REQUESTS:
                return true;
            case ConfirmEmailStage.WRONG_TOKEN:
                return !props.isLoggedIn;
            case ConfirmEmailStage.ALREADY_CONFIRMED:
                return true;
            default:
                return false;
        }
    });
    const confirmButtonLabel = computed(()=>{
        const isRetryLabel = props.stage === ConfirmEmailStage.TECH_ERROR || props.stage === ConfirmEmailStage.WRONG_TOKEN;
        return isRetryLabel ? $translate('WEB2_TRY_AGAIN').value : $translate('WEB2_MODAL_CONFIRM').value;
    });
    return {
        isSuccess,
        jumbotronProps,
        isErrorViewVisible,
        withSupportTranslation,
        isLoaderVisible,
        confirmButtonsVisible,
        closeButtonVisible,
        confirmButtonLabel
    };
}
