import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
import { IconName } from '@leon-hub/icons';
import VList from 'web/src/components/List/VList/VList.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import SHintBlockExpanded from 'web/src/components/HintBlock/SHintBlockExpanded/SHintBlockExpanded.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsHintBlock',
    props: {
        showNoSpoiler: {
            type: Boolean
        },
        message: {
            default: ''
        },
        open: {
            type: Boolean
        },
        isTerminal: {
            type: Boolean
        },
        showOtherMethodButton: {
            type: Boolean
        },
        isDeptsupisstreetterminal: {
            type: Boolean
        },
        showAdditionalInformation: {
            type: Boolean
        }
    },
    emits: [
        "click-other"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    'payments-hint-block': true,
                    'payments-hint-block--terminal': _ctx.isTerminal
                })
            }, [
                _ctx.isTerminal ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _ctx.isDeptsupisstreetterminal ? _createCommentVNode("", true) : (_openBlock(), _createBlock(VDynamicContent, {
                        key: 0,
                        class: "payments-hint-block__text",
                        content: _ctx.message
                    }, null, 8, [
                        "content"
                    ])),
                    _createVNode(VButton, {
                        kind: _unref(ButtonKind).CANCEL,
                        label: _ctx.$t('WEB2_CHOOSE_ANOTHER_METHOD'),
                        "full-width": "",
                        class: "payments-hint-block__button",
                        onClick: _cache[0] || (_cache[0] = ($event)=>emit('click-other'))
                    }, null, 8, [
                        "kind",
                        "label"
                    ])
                ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    (_openBlock(), _createElementBlock(_Fragment, {
                        key: 2
                    }, [
                        _ctx.showOtherMethodButton ? (_openBlock(), _createBlock(VButton, {
                            key: 0,
                            kind: _unref(ButtonKind).CANCEL,
                            label: _ctx.$t('WEB2_CHOOSE_ANOTHER_METHOD'),
                            "full-width": "",
                            class: "payments-hint-block__button",
                            onClick: _cache[1] || (_cache[1] = ($event)=>emit('click-other'))
                        }, null, 8, [
                            "kind",
                            "label"
                        ])) : _createCommentVNode("", true),
                        _ctx.showNoSpoiler && _ctx.showAdditionalInformation && _ctx.message ? _withDirectives((_openBlock(), _createBlock(VDynamicContent, {
                            key: 1,
                            class: _normalizeClass([
                                "payments-hint-block__text",
                                {
                                    'payments-hint-block__text-without-spoiler': true
                                }
                            ]),
                            content: _ctx.message
                        }, null, 8, [
                            "content"
                        ])), [
                            [
                                _directive_data_test,
                                {
                                    el: 'hint-without-spoiler'
                                }
                            ]
                        ]) : _ctx.showAdditionalInformation && _ctx.message ? _withDirectives((_openBlock(), _createBlock(VList, {
                            key: 2,
                            class: "payments-hint-block__accordion"
                        }, {
                            default: _withCtx(()=>[
                                    _createVNode(VListItemAccordion, {
                                        id: "hint",
                                        title: _ctx.$t('WEB2_IMPORTANT_INFORMATION'),
                                        "no-background": "",
                                        class: "payments-hint-block__content",
                                        open: _ctx.open
                                    }, {
                                        content: _withCtx(()=>[
                                                _createVNode(VDynamicContent, {
                                                    class: "payments-hint-block__text",
                                                    content: _ctx.message
                                                }, null, 8, [
                                                    "content"
                                                ])
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "title",
                                        "open"
                                    ])
                                ]),
                            _: 1
                        })), [
                            [
                                _directive_data_test,
                                {
                                    el: 'hint-spoiler'
                                }
                            ]
                        ]) : _createCommentVNode("", true)
                    ], 64))
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsHintBlock'
                ]
            ]);
        };
    }
});
