import { computed, ref, watch } from 'vue';
import { InputEventType } from 'web/src/components/Input/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';
export default function useVRadio(props, emit, slots) {
    const isChecked = ref(false);
    const hasLabel = computed(()=>hasSlot(slots, 'default') || !!props.label);
    const hasSubTitle = computed(()=>hasSlot(slots, 'content') || !!props.subTitle);
    const wrapperClass = computed(()=>{
        const base = 'radio';
        return {
            [base]: true,
            [`${base}--disabled`]: !!props.disabled,
            [`${base}--checked`]: !!props.checked,
            [`${base}--error`]: !!props.error,
            [`${base}--has-icon`]: !!props.hasIcon,
            [`${base}--has-no-label`]: !!props.hasNoLabel
        };
    });
    function onChange(event) {
        const { checked } = event.target;
        isChecked.value = checked;
        emit(InputEventType.CHANGE, event);
    }
    function beforeMount() {
        isChecked.value = !!props.checked;
    }
    watch(()=>props.checked, (value)=>{
        isChecked.value = value;
    });
    return {
        isChecked,
        hasLabel,
        hasSubTitle,
        wrapperClass,
        onChange,
        beforeMount
    };
}
