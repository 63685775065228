import { computed, onMounted, ref, shallowRef } from 'vue';
import { OpenIn, PaymentType } from '@leon-hub/api-sdk';
import { isFormSchema } from '@leon-hub/form-utils';
import { IconName, IconSize } from '@leon-hub/icons';
import { localStorageManager } from '@leon-hub/local-storage';
import { useIsAppInstalled } from 'web/src/modules/payments/submodules/deposits/composables/useIsAppInstalled';
import { useI18n } from 'web/src/modules/i18n/composables';
import { FormControlType, FormFieldTouchedStrategy } from 'web/src/components/Form/enums';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { JsonSchemaAdapter, getUiFieldRawOptions } from 'web/src/utils/jsonSchemaAdapter';
import isValueInRange from 'web/src/modules/payments/utils/isValueInRange';
import { useBalance } from 'web/src/modules/customer/store/composables';
import getSubmitPaymentPayload from 'web/src/modules/payments/utils/getSubmitPaymentPayload';
import { PaymentFormFieldName, PaymentsCardField, PaymentsFormStep, PaymentsNDFLType, PaymentsStatus, PaymentsSubmitAction } from 'web/src/modules/payments/enums';
import { isPaymentsFormStep } from 'web/src/modules/payments/guards/isPaymentsFormStep';
import { isPaymentsCardTypes } from 'web/src/modules/payments/guards/isPaymentsCardTypes';
import { PAYMENTS_UNDEFINED_CREDIT_CARD } from 'web/src/modules/payments/constants';
import usePaymentError from 'web/src/modules/payments/composables/usePaymentError';
import { useCardExpirationError } from 'web/src/modules/payments/composables/useCardExpirationError';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import isValueInSuggestedAmounts from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/helpers/isValueInSuggestedAmounts';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
export function usePaymentSystemData() {
    const { $translate } = useI18n();
    const { currency } = useCurrency();
    const formatMoney = useFormatMoney();
    const { balance } = useBalance();
    const { parsePhoneNumberByUIField } = useFormDefaultFormPhoneValue();
    const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
    const currentPaymentSystem = shallowRef(null);
    const submitPaymentResponse = shallowRef(null);
    const amount = ref('');
    const cardHolder = ref('');
    const creditCardToken = ref('');
    const isFormPending = ref(false);
    const cardExpirationDate = ref('');
    const submittedAmount = ref(0);
    const { isExpirationValidationDisabled, isPaymentsFastSumEnabled } = usePaymentsConfig();
    const paymentsProcedureStatus = ref({
        action: null,
        status: null
    });
    const nextSteps = ref(null);
    const { isInstalled, checkIfAppInstalled } = useIsAppInstalled();
    const { customErrors: paymentErrors, handlePaymentError } = usePaymentError();
    const cardExpirationErrors = computed(()=>{
        if (isExpirationValidationDisabled.value) return {};
        return useCardExpirationError(PaymentFormFieldName.EXPIRATION, cardExpirationDate).cardExpirationErrors.value;
    });
    onMounted(()=>{
        checkIfAppInstalled('ru.sberbankmobile');
    });
    const paymentSystemData = computed(()=>currentPaymentSystem.value?.paymentSystemData ?? null);
    const isDeposit = computed(()=>paymentSystemData.value?.paymentType === PaymentType.DEPOSIT);
    const name = computed(()=>paymentSystemData.value?.name ?? '');
    const paymentId = computed(()=>paymentSystemData.value?.id);
    const creditCards = computed(()=>paymentSystemData.value?.bankcards ?? []);
    const allowSuggestedAmountsOnly = computed(()=>paymentSystemData.value?.allowInputSuggestedAmountsOnly ?? false);
    const minAmount = computed(()=>paymentSystemData.value?.minAmount?.amount ?? 1);
    const maxAmount = computed(()=>paymentSystemData.value?.maxAmount?.amount ?? (isDeposit.value ? 0 : balance.value));
    const amountAsNumber = computed(()=>parseFloat(amount.value));
    const amountAsMoney = computed(()=>formatMoney(amountAsNumber.value));
    const amountSubmitButton = computed(()=>formatMoney(Number(amount.value)));
    const isAmountInRange = computed(()=>isValueInRange(amountAsNumber.value, minAmount.value, maxAmount.value));
    const isAdditionalRegistrationNeeded = computed(()=>currentPaymentSystem.value?.additionalRegistrationNeeded ?? false);
    const paymentSystemId = computed(()=>paymentSystemData.value?.paymentSystemId);
    const submittedAmountAsMoney = computed(()=>formatMoney(submittedAmount.value));
    const isInalidCardHolder = computed(()=>{
        if (cardHolder.value) return !/^((?:[A-Za-z-.]+ ?){1,})$/.test(cardHolder.value);
        return false;
    });
    const setSubmittedAmount = ()=>{
        submittedAmount.value = amountAsNumber.value;
    };
    function setSelectedBonusModalStatus(value) {
        currentPaymentSystem.value = {
            ...currentPaymentSystem.value,
            hideNoSelectedBonusModal: value
        };
    }
    function setOpenIn(value) {
        let openIn = null;
        switch(value){
            case 'same_window':
                openIn = OpenIn.SAME_WINDOW;
                break;
            case 'new_window':
                openIn = OpenIn.NEW_WINDOW;
                break;
            case 'iframe':
                openIn = OpenIn.IFRAME;
                break;
            default:
                break;
        }
        if (openIn) currentPaymentSystem.value = {
            ...currentPaymentSystem.value,
            paymentSystemData: {
                ...currentPaymentSystem?.value?.paymentSystemData ?? {},
                openIn
            }
        };
    }
    function clearLocalStorageItem(key) {
        const storageValue = localStorageManager.getItem(key);
        if (storageValue) localStorageManager.removeItem(key);
    }
    function clearNDFLLocalStorageData() {
        clearLocalStorageItem(PaymentsNDFLType.NETTO_AMOUNT);
        clearLocalStorageItem(PaymentsNDFLType.BRUTTO_AMOUNT);
        clearLocalStorageItem(PaymentsNDFLType.TAX_AMOUNT);
    }
    function setSubmitResponse(response) {
        let keepLocalStorageData = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        submitPaymentResponse.value = response;
        if (response?.nettoAmount) localStorageManager.setItem(PaymentsNDFLType.NETTO_AMOUNT, `${response.nettoAmount}`);
        else if (!keepLocalStorageData) clearLocalStorageItem(PaymentsNDFLType.NETTO_AMOUNT);
        if (response?.bruttoAmount) localStorageManager.setItem(PaymentsNDFLType.BRUTTO_AMOUNT, `${response.bruttoAmount}`);
        else if (!keepLocalStorageData) clearLocalStorageItem(PaymentsNDFLType.BRUTTO_AMOUNT);
        if (response?.taxAmount) localStorageManager.setItem(PaymentsNDFLType.TAX_AMOUNT, `${response.taxAmount}`);
        else if (!keepLocalStorageData) clearLocalStorageItem(PaymentsNDFLType.TAX_AMOUNT);
        setNextSteps(response);
    }
    const redirectUrl = computed(()=>submitPaymentResponse.value?.redirectUrl ?? '');
    const htmlContent = computed(()=>submitPaymentResponse.value?.htmlContent ?? '');
    const htmlContentProperties = computed(()=>submitPaymentResponse.value?.htmlContentProperties);
    const redirectCharset = computed(()=>submitPaymentResponse.value?.redirectCharset ?? '');
    const redirectFormParams = computed(()=>submitPaymentResponse.value?.redirectFormParams);
    const redirectMethod = computed(()=>submitPaymentResponse.value?.redirectMethod);
    const cancel = computed(()=>submitPaymentResponse.value?.cancel);
    const creditCardType = computed(()=>{
        let cardType;
        if (creditCardToken.value && creditCardToken.value !== PAYMENTS_UNDEFINED_CREDIT_CARD) cardType = creditCards.value.find((creditCard)=>creditCard.token === creditCardToken.value)?.type;
        return isPaymentsCardTypes(cardType) ? cardType : null;
    });
    function getPaymentSubmitCancelPayload() {
        if (cancel.value?.formParams?.length && cancel.value?.schemaId) return getSubmitPaymentPayload({
            paymentSystemId: paymentId.value || '',
            schemaId: paymentSystemData.value?.schemaId || '',
            formParams: [
                ...cancel.value.formParams
            ],
            action: PaymentsSubmitAction.CANCEL
        });
        return null;
    }
    function setNextSteps(response) {
        const depositFormStep = isPaymentsFormStep(response?.formStep) ? response?.formStep : null;
        if (depositFormStep && response?.formValidationSchema && response.uiFormSchema && !response.redirectFormParams) {
            if (null === nextSteps.value) nextSteps.value = [
                {
                    formStep: depositFormStep,
                    uiFormSchema: response.uiFormSchema,
                    formValidationSchema: response.formValidationSchema
                }
            ];
            else nextSteps.value.push({
                formStep: depositFormStep,
                uiFormSchema: response.uiFormSchema,
                formValidationSchema: response.formValidationSchema
            });
        } else nextSteps.value = null;
    }
    function clearCurrentStep() {
        if (null === nextSteps.value) return;
        nextSteps.value.pop();
        if (0 === nextSteps.value.length) nextSteps.value = null;
    }
    const lastStep = computed(()=>{
        if (null === nextSteps.value) return null;
        return nextSteps.value[nextSteps.value.length - 1];
    });
    const formStep = computed(()=>{
        const step = null !== lastStep.value ? lastStep.value.formStep : paymentSystemData.value?.formStep;
        return isPaymentsFormStep(step) ? step : null;
    });
    const jsonSchema = computed(()=>{
        const schema = JSON.parse(null !== lastStep.value ? lastStep.value.formValidationSchema ?? '' : paymentSystemData.value?.formValidationSchema ?? '');
        isFormSchema(schema);
        return schema;
    });
    const currentPaymentSystemSuggestedAmounts = computed(()=>{
        if (paymentSystemData.value?.suggestedAmounts?.length) {
            const suggestedAmounts = paymentSystemData.value?.suggestedAmounts.find((customAmounts)=>customAmounts.currency === currency.value);
            if (suggestedAmounts?.amounts.length) return [
                ...suggestedAmounts.amounts
            ];
        }
        return [];
    });
    const isCurrentValueFromSuggestedAmounts = computed(()=>!allowSuggestedAmountsOnly.value || !isPaymentsFastSumEnabled.value || isValueInSuggestedAmounts(currentPaymentSystemSuggestedAmounts.value, amountAsNumber.value));
    const isFormButtonDisabled = computed(()=>{
        const isInitialStepAndAmountNotInRange = formStep.value === PaymentsFormStep.INITIAL && !isAmountInRange.value;
        const isWithdrawalAndAmountExceedsBalance = amountAsNumber.value > balance.value && !isDeposit.value;
        return isInitialStepAndAmountNotInRange || isWithdrawalAndAmountExceedsBalance || !isCurrentValueFromSuggestedAmounts.value || isInalidCardHolder.value;
    });
    const currentPaymentSystemAllowedIncrement = computed(()=>{
        if (paymentSystemData.value?.allowedIncrements?.length) {
            const allowedIncrements = (currentPaymentSystem.value?.paymentSystemData?.allowedIncrements ?? []).find((customAmount)=>customAmount.currency === currency.value);
            if (allowedIncrements?.amount) return allowedIncrements.amount;
        }
        return 0;
    });
    const uiFormSchema = computed(()=>{
        // eslint-disable-next-line max-len
        const uiSchema = null !== lastStep.value ? lastStep.value.uiFormSchema : paymentSystemData.value?.uiFormSchema ?? null;
        // eslint-disable-next-line sonarjs/no-collapsible-if
        // eslint-disable-next-line max-len
        return uiSchema;
    });
    const initialModalProperties = computed(()=>{
        function isKeyExist(array, keys) {
            return keys.every((key)=>array.some((item)=>item.key === key && !!item.value));
        }
        const uiSchemaFields = uiFormSchema.value?.fields ?? null;
        if (uiSchemaFields) {
            const modalProperties = uiSchemaFields.find((item)=>{
                if (item.options.length && item.options) return isKeyExist(item.options, [
                    'hintEnterIdButton',
                    'hintMessage',
                    'hintTitle'
                ]);
                return false;
            });
            if (modalProperties) {
                const options = getUiFieldRawOptions(modalProperties.options);
                return {
                    confirmMessage: `${options?.hintMessage ?? ''}`,
                    title: `${options?.hintTitle ?? ''}`,
                    buttons: [
                        {
                            label: `${options?.hintEnterIdButton ?? ''}`,
                            action: DialogAction.CONFIRM
                        }
                    ]
                };
            }
        }
        return null;
    });
    const fastSumOptions = computed(()=>({
            minAmount: minAmount.value ?? 1,
            suggestedAmounts: currentPaymentSystemSuggestedAmounts.value,
            allowedIncrement: currentPaymentSystemAllowedIncrement.value,
            allowInputSuggestedAmountsOnly: allowSuggestedAmountsOnly.value
        }));
    const uiFormSchamAdapted = computed(()=>{
        if (uiFormSchema.value) return new JsonSchemaAdapter(uiFormSchema.value, jsonSchema.value).addPropertiesByWidget(FormControlType.FastSum, {
            default: amount.value
        }).addPropertiesById(PaymentsCardField.CARD_HOLDER, {
            widget: FormControlType.CardName
        }).addOptionsByWidget(FormControlType.Text, {
            ...null !== initialModalProperties.value ? {
                suffixIconName: IconName.QUESTION,
                clickableIcon: true
            } : {}
        }).addOptionsByWidget(FormControlType.FastSum, {
            amount: amount.value,
            minAmount: minAmount.value ?? null,
            maxAmount: maxAmount.value ?? null,
            defaultInputValue: paymentSystemData.value?.defaultInputValue,
            suggestedAmounts: currentPaymentSystemSuggestedAmounts.value,
            allowedIncrement: currentPaymentSystemAllowedIncrement.value,
            allowInputSuggestedAmountsOnly: allowSuggestedAmountsOnly.value
        }).addPropertiesByWidget(FormControlType.CardExpirationDate, {
            touchedStrategy: FormFieldTouchedStrategy.Change,
            shortWidth: true
        }).addPropertiesByWidget(FormControlType.CardCvv, {
            shortWidth: true
        }).addPropertiesByWidget(FormControlType.CreditCardSelector, {
            default: creditCardToken.value
        }).addPropertiesByWidgetGroup([
            FormControlType.Phone,
            FormControlType.PhoneInput,
            FormControlType.PhoneCountriesSelectorInput
        ], (field)=>({
                default: parsePhoneNumberByUIField(field)
            }));
        return null;
    });
    const paymentMessage = computed(()=>{
        const { msg, uiFormSchema: uiSchema } = submitPaymentResponse.value ?? {};
        if (msg && null === uiSchema && !redirectUrl.value) return msg;
        if (isDeposit.value && paymentsProcedureStatus.value.status === PaymentsStatus.OK && submitPaymentResponse.value?.formStep === null && submitPaymentResponse.value?.redirectUrl === null && null === redirectMethod.value && submitPaymentResponse.value?.redirectCharset === null && submitPaymentResponse.value?.redirectFormParams === null) return $translate('JSP_CCGATEWAY_PAYMENT_SUCCESS_DESC').value;
        return '';
    });
    const buttonLabelText = computed(()=>isDeposit.value ? $translate('JS_ADD_FUNDS').value.toUpperCase() : $translate('WEB2_WITHDRAW').value.toUpperCase());
    const submitPendingButtonLabel = computed(()=>$translate('WEB2_PROCESSING').value.toUpperCase());
    const submitButtonLabel = computed(()=>{
        if (isFormPending.value) return submitPendingButtonLabel.value;
        if ((amount.value || formStep.value !== PaymentsFormStep.INITIAL && amount.value) && isAmountInRange.value) {
            if (isDeposit.value) return `${$translate('WEB2_REPLENISH').value.toUpperCase()} ${amountSubmitButton.value}`;
            if (amountAsNumber.value <= balance.value) return `${$translate('WEB2_WITHDRAW').value.toUpperCase()} ${amountSubmitButton.value}`;
        }
        return buttonLabelText.value;
    });
    const submitButton = computed(()=>({
            label: submitButtonLabel.value,
            iconName: IconName.SHIELD,
            iconSize: IconSize.SIZE_16,
            fullWidth: true,
            isUppercase: false,
            disabled: isFormButtonDisabled.value
        }));
    const validatorErrorPatterns = computed(()=>({
            byPattern: {
                [FormControlType.Text]: {
                    '^([a-zA-Z]+[-\\s\\.]*)+$': $translate('WEB2_PAYMIFINITYBANKACCOUNT_PATTERN_ERROR').value,
                    '^[a-zA-Z-\'& ]+$': $translate('WEB2_PAYINPLAY_PATTERN_ERROR').value,
                    '^[0-9]{12}$': $translate('WEB2_PAYMENTS_DOCUMENT_NUMBER_PATTERN_ERROR').value,
                    '^\\d+$': $translate('WEB2_PAYMENTS_ACCOUNT_PATTERN_ERROR').value,
                    '^[a-zA-Z]{4}\\d{7}$': $translate('WEB2_PAYMENTS_CODE_PATTERN_ERROR').value
                }
            },
            byWidget: phoneErrorPatterns.value
        }));
    function clearSubmitResponse() {
        let keepLocalStorageData = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        setSubmitResponse(null, keepLocalStorageData);
    }
    const customErrors = computed(()=>({
            ...paymentErrors.value,
            ...cardExpirationErrors.value
        }));
    return {
        setSubmitResponse,
        submitPaymentResponse,
        currentPaymentSystem,
        paymentSystemData,
        isAdditionalRegistrationNeeded,
        uiFormSchema,
        creditCards,
        name,
        nextSteps,
        paymentsProcedureStatus,
        paymentMessage,
        redirectUrl,
        redirectMethod,
        formStep,
        jsonSchema,
        fastSumOptions,
        uiFormSchamAdapted,
        initialModalProperties,
        submitButton,
        amount,
        amountAsNumber,
        amountAsMoney,
        minAmount,
        htmlContent,
        htmlContentProperties,
        redirectCharset,
        redirectFormParams,
        cancel,
        creditCardToken,
        creditCardType,
        validatorErrorPatterns,
        isFormPending,
        isFormButtonDisabled,
        cardExpirationDate,
        paymentSystemId,
        submittedAmount,
        submittedAmountAsMoney,
        customErrors,
        isCurrentValueFromSuggestedAmounts,
        cardHolder,
        setSelectedBonusModalStatus,
        setNextSteps,
        clearCurrentStep,
        clearNDFLLocalStorageData,
        getPaymentSubmitCancelPayload,
        clearSubmitResponse,
        setSubmittedAmount,
        handlePaymentError,
        setOpenIn
    };
}
