import { isOptionalString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
let FastGamesDetailsRedirectLobbyPrefetch = class FastGamesDetailsRedirectLobbyPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        isOptionalString(to.params.gameId);
        let location = {
            name: RouteName.QUICK_BETS
        };
        if ([
            'nard',
            'boxing',
            'shootout',
            'bridge',
            'darts'
        ].includes(to.params.gameId)) location = {
            name: RouteName.QUICK_BETS_DETAILS,
            params: {
                groupUrl: 'fastgames',
                gameUrl: to.params.gameId
            }
        };
        next(router.resolve301location(location));
        return Promise.resolve();
    }
};
export { FastGamesDetailsRedirectLobbyPrefetch as default };
