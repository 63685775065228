import { useRoute, useRouter } from 'vue-router';
export default function useTheSidebarMenu(props, emits, options) {
    const router = useRouter();
    const route = useRoute();
    function beforeLevelOneClick(event, fun, location, payload) {
        const isModalOpening = route.name !== location.name;
        if (!isModalOpening) fun();
        if (payload) emitMenuLevelClick(event, payload);
    }
    function emitBeforeNavigateClick(event, to, payload) {
        emits('before-navigate', {
            event,
            to,
            payload
        });
    }
    function emitMenuLevelClick(event, payload) {
        if (event.target.closest(`.${options.defaultL1Class}`) || event.target.className === options.defaultL1InnerClass) {
            const element = props.menu.find((item)=>item.id === payload.id);
            if (element?.location) {
                if (props.replaceRoutes) router.replace(element.location);
                else router.push(element.location);
            }
        }
        emits('level-click', {
            event,
            payload
        });
    }
    function doLevelClick(event, fun, payload) {
        if (fun) fun();
        if (payload) emitMenuLevelClick(event, payload);
    }
    return {
        emitBeforeNavigateClick,
        emitMenuLevelClick,
        beforeLevelOneClick,
        doLevelClick
    };
}
