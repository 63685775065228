import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { LoginType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { PhoneCodeMethod } from '@leon-hub/api-sdk';
import { IconSize, IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config';
import { phoneNumberStarModifier } from 'web/src/utils/phoneNumberUtils/phoneNumberStarModifier';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { FormControlType, FormFocusType } from 'web/src/components/Form/enums';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
// eslint-disable-next-line max-len
export default function useRestorePasswordCheckPhoneForm(props, emits) {
    const { $translate } = useI18n();
    const restorePasswordStore = useRestorePasswordStore();
    const siteConfigStore = useSiteConfigStore();
    const pinCodeStore = usePinCodeStore();
    const routerStore = useRouterStore();
    const phoneCheckConfirmationSettings = toRef(siteConfigStore, 'phoneCodeLengthSettings');
    const phoneAttemptsLimit = toRef(siteConfigStore, 'phoneWrongAttemptsLimit');
    const isEmailRegEnabled = toRef(siteConfigStore, 'isSuggestedEmailRegistrationEnabled');
    const emailRegTimeout = toRef(siteConfigStore, 'suggestedEmailRegistrationTimeout');
    const isNewTel = toRef(restorePasswordStore, 'isNewTel');
    const attemptsCounter = ref(1);
    const showHintBlock = ref(false);
    const isLoading = ref(false);
    const isError = ref(false);
    const errorMessage = ref('');
    const router = useRouter();
    const iconProperties = computed(()=>({
            icon: IconName.INFO_OUTLINE,
            iconSize: IconSize.SIZE_60
        }));
    const closeButtonProperties = computed(()=>({
            kind: ButtonKind.PRIMARY,
            fullWidth: true
        }));
    const cmsKey = computed(()=>{
        if (isNewTel.value) return props.isPin ? 'WEB2_RESTORE_PIN_PHONE_CHECK_BY_CALL_HINT_BLOCK_AFTER_TIMEOUT' : 'WEB2_RESTORE_PASSWORD_PHONE_CHECK_BY_CALL_HINT_BLOCK_AFTER_TIMEOUT';
        return props.isPin ? 'WEB2_RESTORE_PIN_PHONE_CHECK_HINT_BLOCK_TIMEOUT' : 'WEB2_RESTORE_PASSWORD_PHONE_CHECK_HINT_BLOCK_TIMEOUT';
    });
    const phoneCheckConfirmationCodeLength = computed(()=>{
        const formType = restorePasswordStore.phoneCheckType;
        const settingsForType = formType ? phoneCheckConfirmationSettings.value[formType] : null;
        return settingsForType || 6;
    });
    const uiSchema = computed(()=>({
            order: [
                'sms'
            ],
            submitButton: {
                label: $translate('WEB2_NEXT').value,
                disabled: isError.value
            },
            focus: {
                type: FormFocusType.OnMount,
                field: 'sms'
            },
            fields: {
                sms: {
                    widget: FormControlType.SmsCode,
                    options: {
                        size: phoneCheckConfirmationCodeLength.value,
                        error: errorMessage.value
                    }
                }
            }
        }));
    const schema = computed(()=>({
            $schema: 'http://json-schema.org/draft-07/schema#',
            type: 'object',
            required: [
                'sms'
            ],
            properties: {
                sms: {
                    type: 'string',
                    pattern: `^[0-9]{0,${phoneCheckConfirmationCodeLength.value}}$`,
                    minLength: phoneCheckConfirmationCodeLength.value,
                    maxLength: phoneCheckConfirmationCodeLength.value
                }
            }
        }));
    const isMaxAttempts = computed(()=>attemptsCounter.value > phoneAttemptsLimit.value);
    const isShowHintBlock = computed(()=>isEmailRegEnabled.value && showHintBlock.value);
    const smsNumberCheckProperties = computed(()=>{
        const currentPhone = {
            prefix: restorePasswordStore.currentPhone?.prefix || '',
            suffix: restorePasswordStore.currentPhone?.suffix || ''
        };
        return {
            schema: schema.value,
            uiSchema: uiSchema.value,
            isPending: isLoading.value,
            phone: phoneNumberStarModifier(currentPhone),
            isNewTel: isNewTel.value,
            showButtonTime: restorePasswordStore.retrySmsCodeTime,
            hintTimeout: emailRegTimeout.value,
            isPin: props.isPin
        };
    });
    async function sendSmsOrCallToPhone(method) {
        if (props.isPin) await pinCodeStore.handleSendRestorePinTokenPhone(PhoneCodeMethod.SMS);
        else await restorePasswordStore.resendRestorePasswordSms({
            method
        });
    }
    function changeShowHintBlock(value) {
        showHintBlock.value = value;
    }
    function goToRestorePasswordMainPage() {
        if (props.isPin) {
            restorePasswordStore.setActiveTab(LoginType.EMAIL);
            emits('close');
        }
        restorePasswordStore.clearCurrentRestoreData();
        restorePasswordStore.setActiveTab(LoginType.EMAIL);
    }
    async function goToPhoneCheckBySmsStage() {
        await sendSmsOrCallToPhone(PhoneCodeMethod.SMS);
        attemptsCounter.value = 1;
    }
    function setRetrySmsCodeTime(time) {
        if (props.isPin) pinCodeStore.handleSendRestorePinTokenPhone(PhoneCodeMethod.SMS);
        else restorePasswordStore.setRetrySmsCodeTime(time);
    }
    function checkCode(formOutput) {
        if (formOutput.errors) return;
        isLoading.value = true;
        if (props.isPin) pinCodeStore.resetPinCodeByPhone(formOutput.formData.sms).then(()=>{
            emits('close');
            pinCodeStore.setStepWithLS('CREATE');
            if (routerStore.currentRouteName === RouteName.RESTORE_PIN_PROFILE) router.push({
                name: RouteName.PIN_CODE_PROFILE
            });
            else router.push({
                name: RouteName.PIN_CODE
            });
        }).catch(()=>{
            isLoading.value = false;
            if (attemptsCounter.value <= phoneAttemptsLimit.value) attemptsCounter.value += 1;
            isError.value = true;
            setExternalError();
        });
        else restorePasswordStore.checkRestorePasswordSmsStore(formOutput).then(()=>{
            isError.value = false;
            router.replace({
                name: RouteName.RESTORE_PASSWORD_ENTER_NEW_ONE
            });
        }).catch(()=>{
            if (attemptsCounter.value <= phoneAttemptsLimit.value) attemptsCounter.value += 1;
            isError.value = true;
            setExternalError();
        }).finally(()=>{
            isLoading.value = false;
        });
    }
    function setExternalError() {
        errorMessage.value = $translate('WEB2_INVALID_CODE').value;
    }
    function onInput(formOutput) {
        if (formOutput.errors) setExternalError();
        else errorMessage.value = '';
        isError.value = false;
    }
    function onBlur(formOutput) {
        if (formOutput.errors) setExternalError();
    }
    function clear() {
        restorePasswordStore.clearCurrentRestoreData();
    }
    function handleRestoreButton() {
        if (props.isPin) emits('close');
        else clear();
    }
    return {
        phoneAttemptsLimit,
        isMaxAttempts,
        isShowHintBlock,
        iconProperties,
        closeButtonProperties,
        cmsKey,
        handleRestoreButton,
        smsNumberCheckProperties,
        checkCode,
        sendSmsOrCallToPhone,
        setRetrySmsCodeTime,
        changeShowHintBlock,
        onInput,
        onBlur,
        goToRestorePasswordMainPage,
        goToPhoneCheckBySmsStage
    };
}
