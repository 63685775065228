import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { v4 as uuidV4 } from 'uuid';
import { isObject, isString, isUndefined, isFunction } from '@leon-hub/guards';
import { normalizeError } from '@leon-hub/errors';
import { DefaultDebugLogger } from '@leon-hub/debug-logger';
import { isDebugEnabled, DEBUG_KEY } from '@leon-hub/debug';
function isRegularPostMessageData(value) {
    return isObject(value) && isString(value.eventName) && isString(value.clientId) && isString(value.initiator) && (isUndefined(value.isSendWithPostMessageBus) || true === value.isSendWithPostMessageBus);
}
function isCasAuthPostMessageData(value) {
    return isObject(value) && isString(value.id) && isString(value.type) && true === value.isSendWithPostMessageBus;
}
function isPostMessageData(value) {
    return isRegularPostMessageData(value) || isCasAuthPostMessageData(value);
}
function isWindowProxy(window1) {
    return isFunction(window1.postMessage);
}
const currentClientId = uuidV4();
function makeMessage(event, payload, from) {
    return {
        isSendWithPostMessageBus: true,
        eventName: event,
        initiator: from,
        clientId: currentClientId,
        payload
    };
}
/**
 * From the following set WindowProxy | MessagePort | ServiceWorker
 * only WindowProxy is currently implemented.
 */ let PostMessageBus = class PostMessageBus {
    get logPrefix() {
        return `[${this.isParent ? 'parent' : 'child'} in target origin: ${this.targetOrigin}]`;
    }
    dispose() {
        window.removeEventListener('message', this.boundEventCallback);
        this.log(`post message bus for ${this.options.initiator} initiator disposed`);
    }
    on(event, callback) {
        const eventName = event.toString();
        this.originEvents[eventName] = this.originEvents[eventName] || [];
        this.originEvents[eventName].push(callback);
        this.log(`
       starting event listener
       for event "${eventName}"
       at ${new Date(Date.now()).toISOString()}`);
    }
    get originEvents() {
        if (!this.events[this.targetOrigin]) this.events[this.targetOrigin] = {};
        return this.events[this.targetOrigin];
    }
    emit(event, payload) {
        if (isWindowProxy(this.target)) try {
            if (this.isParent) {
                this.log(`
             emit event "${event.toString()}"
             with payload: ${payload ? JSON.stringify(payload) : 'none'}
             at ${new Date(Date.now()).toISOString()}`);
                this.target.postMessage(makeMessage(event.toString(), payload, this.options.initiator), this.targetOrigin);
                return true;
            }
            this.log(`
           emit event "${event.toString()}"
           with payload: ${payload ? JSON.stringify(payload) : 'none'}
           at ${new Date(Date.now()).toISOString()}`);
            this.target.postMessage(makeMessage(event.toString(), payload, this.options.initiator), '*');
            return true;
        } catch (rawError) {
            const error = normalizeError(rawError);
            const reassignedError = new Error(`Error while emitting postMessage. eventName: ${event.toString()} ${error.message}`);
            reassignedError.stack = error.stack;
            // eslint-disable-next-line no-console
            console.error(reassignedError);
        }
        return false;
    }
    checkEvent(event) {
        const { data } = event;
        const { isSendWithPostMessageBus } = data;
        if (!isSendWithPostMessageBus) return false;
        if (null !== this.allowMessagesFromDomain) return new URL(event.origin).hostname === new URL(this.allowMessagesFromDomain).hostname;
        return this.checkEventOrigin(event.origin, event.source);
    }
    checkEventOrigin(eventOrigin, eventSource) {
        // sandbox and cordova
        if (this.skipSourceCheck && ('null' === eventOrigin || 'file://' === eventOrigin || 'file:' === window.location.protocol)) return true;
        if (this.skipSourceCheck) return true;
        if (this.isParent) {
            if ('*' === this.targetOrigin) // return eventSource === this.target; //TODO: bug: window is empty on desktop mode
            return true;
            return new URL(eventOrigin).hostname === new URL(this.targetOrigin).hostname && // check if window references matches
            eventSource === this.target;
        }
        return eventSource === this.target;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    off(event, callback) {
        const listeners = this.originEvents[event.toString()];
        if (!listeners) return false;
        for(let index = 0; index < listeners.length; index += 1)if (listeners[index] === callback) {
            listeners.splice(index, 1);
            return true;
        }
        return false;
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    handleMessage(event) {
        MessageEvent;
        if (isPostMessageData(event.data)) {
            const eventName = isRegularPostMessageData(event.data) ? event.data.eventName : event.data.type;
            if (this.checkEvent(event)) {
                const { payload } = event.data;
                const originEvent = this.originEvents[eventName];
                if (isUndefined(originEvent)) return;
                if (isRegularPostMessageData(event.data)) {
                    const { clientId } = event.data;
                    const sameContext = clientId === currentClientId;
                    if (!this.options.isP2P && sameContext) return;
                }
                for(let index = 0; index < originEvent.length; index += 1){
                    const originEventCallback = this.originEvents[eventName][index];
                    try {
                        originEventCallback(payload);
                    } catch (rawError) {
                        const error = normalizeError(rawError);
                        const reassignedError = new Error(`Error happened inside originEventCallback. ${error.message}`);
                        reassignedError.stack = error.stack;
                        // eslint-disable-next-line no-console
                        console.warn(reassignedError);
                    }
                }
            }
        }
    }
    log() {
        for(var _len = arguments.length, messages = new Array(_len), _key = 0; _key < _len; _key++){
            messages[_key] = arguments[_key];
        }
        if (this.debug) this.logger.log(this.logPrefix, ...messages);
    }
    constructor(options){
        _define_property(this, "logger", DefaultDebugLogger.create({
            namespace: 'post-message-bus',
            debugEnabledKey: DEBUG_KEY.POST_MESSAGE
        }));
        _define_property(this, "target", void 0);
        _define_property(this, "targetOrigin", void 0);
        _define_property(this, "boundEventCallback", void 0);
        _define_property(this, "isParent", window.parent === window);
        _define_property(this, "allowMessagesFromDomain", void 0);
        _define_property(this, "options", void 0);
        _define_property(this, "events", {});
        _define_property(this, "skipSourceCheck", false);
        _define_property(this, "debug", isDebugEnabled(DEBUG_KEY.POST_MESSAGE));
        options.initiator.length;
        const parentOptions = this.isParent ? {
            target: window.parent,
            targetOrigin: '*'
        } : {};
        this.options = {
            ...parentOptions,
            ...options
        };
        this.allowMessagesFromDomain = options.allowMessagesFromDomain || null;
        this.skipSourceCheck = options.skipSourceCheck || false;
        this.targetOrigin = this.options.targetOrigin;
        isObject(this.options.target);
        this.target = this.options.target;
        this.boundEventCallback = this.handleMessage.bind(this);
        window.addEventListener('message', this.boundEventCallback, false);
        this.log(`new post message bus created by ${this.options.initiator} initiator`);
    }
};
export default PostMessageBus;
