import { _ as _define_property } from "@swc/helpers/_/_define_property";
let PaymentsCreditCardBeautifier = class PaymentsCreditCardBeautifier {
    typeToString() {
        return this.getCreditCardType();
    }
    panToString() {
        return this.getCreditCardToken();
    }
    getName() {
        return `${this.typeToString()} ${this.panToString()}`;
    }
    getCreditCardToken() {
        const lastStarIndex = this.pan.lastIndexOf('*');
        let lastNumbers = this.pan.slice(lastStarIndex + 1);
        if (lastNumbers.length > this.options.maxNumbersLength) lastNumbers = lastNumbers.slice(lastNumbers.length - this.options.maxNumbersLength);
        return `${this.options.tokenStar.repeat(this.options.maxNumberOfTokenStars)} ${lastNumbers}`;
    }
    getCreditCardType() {
        let newType = '';
        switch(this.type){
            case 'MASTER_CARD_NEW':
            case 'MASTER_CARD':
                newType = 'MasterCard';
                break;
            case 'JCB':
                newType = 'JCB';
                break;
            case 'MAESTRO':
            case 'DISCOVER':
            case 'VISA_ELECTRON':
            case 'VISA':
            case 'AMERICAN_EXPRESS':
            case 'CHINA_UNIONPAY':
            case 'DINERS_CLUB_CARTE_BLANCHE':
            case 'DINERS_CLUB_ENROUTE':
            case 'DINERS_CLUB_INTERNATIONAL':
            case 'INTER_PAYMENT_TM':
            case 'INSTA_PAYMENT':
            case 'LASER':
            case 'DANKORT':
            case 'SOLO':
            case 'SWITCH':
            case 'UATP':
                newType = this.toLowerNoDash();
                break;
            default:
                break;
        }
        return newType;
    }
    toLowerNoDash() {
        const arrayOfStrings = this.type.split(PaymentsCreditCardBeautifier.SEPARATOR);
        const uppercased = arrayOfStrings.map((item)=>item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
        return uppercased.join(' ');
    }
    constructor(type, pan, options){
        _define_property(this, "type", void 0);
        _define_property(this, "pan", void 0);
        _define_property(this, "options", void 0);
        this.type = type;
        this.pan = pan;
        this.options = {
            tokenStar: String.fromCodePoint(8226),
            // •
            maxNumberOfTokenStars: 4,
            maxNumbersLength: 4
        };
        if (options) this.options = {
            ...this.options,
            ...options
        };
    }
};
_define_property(PaymentsCreditCardBeautifier, "SEPARATOR", '_');
export { PaymentsCreditCardBeautifier as default };
