import { ref, computed } from 'vue';
import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors/src/internal';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { useRelatedEventsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { flatMapSportElementsList } from 'web/src/modules/sportline/utils';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
export function useRelatedEventsStoreComposable(props) {
    const { pageIdentifier, sportEventDetails, sportEventDetailsId } = props;
    const { timeout: relatedEventsUpdateInterval } = useRelatedEventsSportlineUpdateTimeout();
    const parseSportlineSettings = useParseSportlineSettingsRef();
    const apiService = useSportlineApiService();
    const broadcastSelected = useBroadcastSelected();
    const rawRelatedEventsResponse = ref(null);
    const rawLeagueEventsResponse = ref(null);
    const leagueId = computed(()=>sportEventDetails.value?.league.id);
    function setRawRelatedEventsResponse(response) {
        rawRelatedEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
    }
    async function fetchRelatedEvents(param) {
        let { silent } = param;
        try {
            const eventId = sportEventDetailsId.value ?? pageIdentifier.value.sportEventId;
            if (!eventId) {
                setRawRelatedEventsResponse(null);
                return;
            }
            const response = await apiService.loadRelatedEvents({
                eventId,
                vTag: rawRelatedEventsResponse.value?.vtag,
                silent
            });
            if (response) {
                setRawRelatedEventsResponse(response);
                broadcastSelected.updateDataInStorageByResponse({
                    response
                });
            } else setRawRelatedEventsResponse(null);
        } catch (rawError) {
            setRawRelatedEventsResponse(null);
            logger.error(normalizeError(rawError));
        }
    }
    async function fetchLeagueEvents(param) {
        let { silent } = param;
        try {
            if (!leagueId.value) return;
            const response = await apiService.loadLeagueEvents({
                leagueId: leagueId.value,
                // here could be "false" value in rawEventsResponse, nullish-coalescing is not working here
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                vTag: (rawLeagueEventsResponse.value || null)?.vtag,
                silent
            });
            rawLeagueEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
            broadcastSelected.updateDataInStorageByResponse({
                response
            });
        } catch (error) {
            rawLeagueEventsResponse.value = false;
            throw error;
        }
    }
    /** Related list element for related list block */ const relatedSportListElement = computed(()=>{
        const relatedSportsList = rawRelatedEventsResponse.value ? new SportlineFactory(rawRelatedEventsResponse.value, parseSportlineSettings.value).build() : null;
        return relatedSportsList?.[0] ?? null;
    });
    /**
   * League events for breadcrumbs
   * @hint Maybe it must be calculated in the view composable
   */ const leagueEventsList = computed(()=>{
        const rawList = rawLeagueEventsResponse.value;
        const sportElements = rawList ? new SportlineFactory(rawList, parseSportlineSettings.value).build() : null;
        return flatMapSportElementsList(sportElements ?? []).map((param)=>{
            let { eventElement } = param;
            return eventElement;
        });
    });
    const { onInit: onSportlineEventsChangesListenersInit } = useSportlineEventsChangesListeners({
        setResponse (response) {
            rawRelatedEventsResponse.value = response;
        },
        getResponse () {
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            return rawRelatedEventsResponse.value || null;
        }
    });
    async function loadRelatedEvents(options) {
        await Promise.all([
            fetchRelatedEvents(options),
            fetchLeagueEvents(options)
        ]);
    }
    const lastUpdate = new BackgroundUpdateStopwatch({
        relatedEvents: async (param)=>{
            let { silent, skip } = param;
            // skip updates for set background update (reloaded by initial requests)
            if (skip) return;
            await loadRelatedEvents({
                silent
            });
            lastUpdate.update('relatedEvents');
        }
    });
    const { isBackgroundUpdateEnabled, syncState, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: relatedEventsUpdateInterval
    });
    useSyncState((silent)=>syncState({
            silent
        }), relatedEventsUpdateInterval, {
        condition: isBackgroundUpdateEnabled
    });
    function onInit() {
        onSportlineEventsChangesListenersInit();
    }
    return {
        relatedSportListElement,
        leagueEventsList,
        setBackgroundUpdateEnabled (value) {
            setBackgroundUpdateEnabled(value);
        },
        onInit
    };
}
