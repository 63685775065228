import { computed } from 'vue';
import { isOptionalString } from '@leon-hub/guards';
import getWrapperHintProperties from '../utils/getWrapperHintProperties';
export default function useCommonFormWidgetProps(props, inputType) {
    const componentProps = computed(()=>{
        const { name, value, options, title, error, disabled, hintMessage, autofocus } = props;
        isOptionalString(value);
        return {
            name,
            value,
            label: title,
            disabled,
            ...inputType ? {
                type: inputType
            } : {},
            ...options ?? {},
            ...getWrapperHintProperties({
                options,
                error,
                hintMessage
            }),
            autofocus
        };
    });
    return {
        componentProps
    };
}
