import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import TextInput from '../TextInput';
import { useBaseInputEvents } from '../../composables';
import useBonusCodeInput from './useBonusCodeInput';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'BonusCodeInput',
    props: {
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'bonus-code'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isBonusCodeVisible, makeVisible } = useBonusCodeInput(props);
        const { emitInput, emitBlur, emitFocus, emitChange } = useBaseInputEvents(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bonus-code-input'])
            }, [
                _unref(isBonusCodeVisible) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    role: "button",
                    class: _normalizeClass(_ctx.$style['bonus-code-input__wrapper']),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(makeVisible) && _unref(makeVisible)(...args);
                    })
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['bonus-code-input__link'])
                    }, _toDisplayString(_ctx.$t('WEB2_REG_BONUS_CODE_LINK_LABEL')), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['bonus-code-input__link-comment'])
                    }, "(" + _toDisplayString(_ctx.$t('JSPACC_REG_BCODE_IFHAVE')) + ")", 3)
                ], 2)),
                _unref(isBonusCodeVisible) ? (_openBlock(), _createBlock(_unref(TextInput), {
                    key: 1,
                    autofocus: _ctx.autofocus,
                    name: _ctx.name,
                    value: _ctx.value,
                    disabled: _ctx.disabled,
                    placeholder: _ctx.placeholder,
                    label: _ctx.label,
                    error: _ctx.error,
                    "error-right": _ctx.errorRight,
                    hint: _ctx.hint,
                    "hint-right": _ctx.hintRight,
                    "hint-link": _ctx.hintLink,
                    "hint-link-right": _ctx.hintLinkRight,
                    "is-large": _ctx.isLarge,
                    onInput: _unref(emitInput),
                    onChange: _unref(emitChange),
                    onFocus: _unref(emitFocus),
                    onBlur: _unref(emitBlur)
                }, null, 8, [
                    "autofocus",
                    "name",
                    "value",
                    "disabled",
                    "placeholder",
                    "label",
                    "error",
                    "error-right",
                    "hint",
                    "hint-right",
                    "hint-link",
                    "hint-link-right",
                    "is-large",
                    "onInput",
                    "onChange",
                    "onFocus",
                    "onBlur"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusCodeInput'
                ]
            ]);
        };
    }
});
