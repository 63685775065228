import { toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useSlipViewSettingsStore } from '../../slip-view-settings/store';
import { useSlipRootStore } from '../../slip-root/store';
import { useGetSharedSlipStore } from '../store';
export function useGetSharedSlip() {
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const getSharedSlipStore = useGetSharedSlipStore();
    const isReceiveFromCodeEnabled = toRef(getSharedSlipStore, 'isReceiveFromCodeEnabled');
    const isWaitingToSlipFromCode = toRef(getSharedSlipStore, 'isWaitingToSlipFromCode');
    const bookingCodeError = toRef(getSharedSlipStore, 'bookingCodeError');
    const isAllSharedEventsExpired = toRef(getSharedSlipStore, 'isAllSharedEventsExpired');
    const sharedBookingCode = toRef(getSharedSlipStore, 'sharedBookingCode');
    const isBookingCodeFromLink = toRef(getSharedSlipStore, 'isBookingCodeFromLink');
    const { getSlipFromBookingCode, clearBonusCodeError, setSharedBookingCode, resetBookingCodeForm } = getSharedSlipStore;
    const slipViewSettingsStore = useSlipViewSettingsStore();
    const isSlipFromBookingCodeOverlayVisible = toRef(slipViewSettingsStore, 'isSlipFromBookingCodeOverlayVisible');
    const { handleSlipClose } = useSlipRootStore();
    const { hideSlipFromBookingCodeOverlay } = slipViewSettingsStore;
    const onOverlayButtonClick = ()=>{
        if (isAllSharedEventsExpired.value) {
            handleSlipClose();
            router.push({
                name: isLoggedIn.value ? RouteName.HOME : RouteName.LOGIN
            });
        } else hideSlipFromBookingCodeOverlay();
    };
    const onBookingCodeInput = (value)=>{
        clearBonusCodeError();
        setSharedBookingCode(value);
    };
    return {
        isReceiveFromCodeEnabled,
        isWaitingToSlipFromCode,
        bookingCodeError,
        isSlipFromBookingCodeOverlayVisible,
        isAllSharedEventsExpired,
        isLoggedIn,
        sharedBookingCode,
        isBookingCodeFromLink,
        getSlipFromBookingCode,
        onBookingCodeInput,
        onOverlayButtonClick,
        setSharedBookingCode,
        resetBookingCodeForm
    };
}
