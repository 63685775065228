import { isIconName, isVirtualSportIconName } from '@leon-hub/icons';
import { isString } from '@leon-hub/guards';
const ICON_PREFIX = 'sport-';
export default function getIconNameBySportName(value) {
    if (isString(value)) {
        const val = value.toLowerCase();
        const iconName = val.startsWith(ICON_PREFIX) ? val : `${ICON_PREFIX}${val}`;
        if (isVirtualSportIconName(iconName)) return iconName;
        return isIconName(iconName) ? iconName : void 0;
    }
}
