import { emailPattern } from 'web/src/components/Form/constants';
export const defaultSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    title: 'Login',
    required: [
        'login',
        'password',
        'captchaAnswer'
    ],
    properties: {
        login: {
            type: 'string',
            pattern: `${emailPattern}|^\\d{6,9}$|^\\+?\\d{11}$`
        },
        password: {
            type: 'string',
            pattern: '^(?!.*(.)\\\\1{2,}).+$'
        },
        checkbox: {
            type: 'boolean'
        },
        captchaAnswer: {
            type: 'string'
        },
        captchaType: {
            type: 'string'
        }
    }
};
function phoneSchemaLoginProperties() {
    "1";
    return {
        suffix: {
            type: 'string'
        },
        prefix: {
            type: 'string'
        },
        countryCode: {
            type: 'string'
        }
    };
}
function phoneSchemaLoginConditions() {
    "1";
    return {
        allOf: [
            {
                if: {
                    properties: {
                        prefix: {
                            const: '66'
                        }
                    },
                    required: [
                        'prefix'
                    ]
                },
                then: {
                    properties: {
                        suffix: {
                            type: 'string',
                            pattern: '^[0-9]{5,15}$'
                        }
                    }
                }
            },
            {
                if: {
                    properties: {
                        prefix: {
                            not: {
                                const: '66'
                            }
                        }
                    }
                },
                then: {
                    properties: {
                        suffix: {
                            type: 'string',
                            pattern: '^[1-9][0-9]{4,14}$'
                        }
                    }
                }
            }
        ]
    };
}
export const phoneSchema = {
    ...defaultSchema,
    properties: {
        ...defaultSchema.properties,
        login: {
            required: [
                'suffix',
                'prefix'
            ],
            type: 'object',
            properties: phoneSchemaLoginProperties(),
            ...phoneSchemaLoginConditions()
        },
        loginType: {
            type: 'string'
        }
    }
};
export const emailSchema = {
    ...defaultSchema,
    properties: {
        ...defaultSchema.properties,
        login: {
            type: 'string',
            pattern: emailPattern
        },
        loginType: {
            type: 'string'
        }
    }
};
export const accountSchema = {
    ...defaultSchema,
    properties: {
        ...defaultSchema.properties,
        login: {
            type: 'string',
            pattern: '^[0-9]+$'
        },
        loginType: {
            type: 'string'
        }
    }
};
