import { toRef } from 'vue';
import { useFastBetsStore } from 'web/src/modules/fast-bets/store';
export default function useFastBetsValues() {
    const fastBetsStore = useFastBetsStore();
    const isFastBetsEnabled = toRef(fastBetsStore, 'isFastBetsEnabled');
    const fastBetsShowMax = toRef(fastBetsStore, 'fastBetsShowMax');
    const fastBetsOptions = toRef(fastBetsStore, 'fastBetsOptions');
    return {
        isFastBetsEnabled,
        fastBetsShowMax,
        fastBetsOptions
    };
}
