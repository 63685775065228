import { watch, computed, nextTick, ref } from 'vue';
import { createPopper as createPopperHelper } from '@popperjs/core';
import { PopperApplyTarget } from 'web/src/components/Popper/VPopper/enums';
import { isElementContains } from 'web/src/utils/html';
export function useVPopper(props, emit) {
    const { modifiers, position, applyTarget, hasOffset, isFullWidth } = props;
    const popper = ref();
    const reference = ref();
    const content = ref();
    const showContent = ref(false);
    const isBodyContentAvailable = ref(false);
    let popperInstance = null;
    const sameWidth = {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: [
            'computeStyles'
        ],
        fn: (param)=>{
            let { state } = param;
            const result = {
                ...state
            };
            result.styles.popper.width = `${state.rects.reference.width}px`;
            return result;
        }
    };
    const isAppendToBody = computed(()=>applyTarget.value === PopperApplyTarget.Body);
    const popperOptions = computed(()=>({
            placement: position.value,
            modifiers: [
                ...modifiers.value,
                ...isFullWidth.value && isAppendToBody.value ? [
                    sameWidth
                ] : [],
                ...hasOffset.value ? [
                    {
                        name: 'offset',
                        options: {
                            offset: [
                                0,
                                4
                            ]
                        }
                    }
                ] : []
            ]
        }));
    async function createPopper() {
        document.addEventListener('click', handleDocumentClick);
        isBodyContentAvailable.value = true;
        await nextTick();
        if (reference.value && content.value) {
            popperInstance = createPopperHelper(reference.value, content.value, popperOptions.value);
            // LEONWEB-11692 force redraw popper after components ready to fix position
            await nextTick();
            popperInstance?.forceUpdate();
        }
    }
    function destroyPopper() {
        document.removeEventListener('click', handleDocumentClick);
        popperInstance?.destroy();
        popperInstance = null;
        isBodyContentAvailable.value = false;
    }
    function toggle() {
        showContent.value = !showContent.value;
    }
    function hide() {
        showContent.value = false;
    }
    function show() {
        showContent.value = true;
    }
    function focusReferenceElm() {
        if (reference.value) {
            const element = reference.value.firstElementChild;
            HTMLElement;
            element.focus();
        }
    }
    function handleDocumentClick(event) {
        const { target } = event;
        Element;
        if (isElementContains(target, popper.value) || isElementContains(target, content.value)) return;
        showContent.value = false;
    }
    function emitShow() {
        emit('show');
    }
    function emitHide() {
        emit('hide');
    }
    watch(()=>popperOptions.value, (value)=>{
        popperInstance?.setOptions(value);
    }, {
        deep: true
    });
    watch(showContent, (value)=>{
        if (value) {
            emitShow();
            createPopper();
        } else {
            emitHide();
            destroyPopper();
        }
    });
    return {
        popper,
        reference,
        content,
        showContent,
        isBodyContentAvailable,
        createPopper,
        destroyPopper,
        toggle,
        show,
        hide,
        focusReferenceElm
    };
}
