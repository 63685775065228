import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, createBlock as _createBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import { Tag } from '@leon-hub/tags';
import { VIcon } from '@components/v-icon';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { useSearchListItem } from 'web/src/modules/search/submodules/sportline/components/SearchListItem/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SearchListItem',
    props: {
        href: {
            default: ''
        },
        searchText: {
            default: ''
        },
        name: {},
        kickoff: {},
        regionName: {},
        leagueName: {},
        sportName: {},
        liveScore: {},
        liveProgress: {},
        streamAvailable: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { onClick, date, time, formattedName, formattedLeagueName, formattedRegionName, formattedSportName, streamIconProps } = useSearchListItem(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(_ctx.$style['search-item'])
            }, [
                _createElementVNode("a", {
                    class: _normalizeClass(_ctx.$style['search-item__link']),
                    href: _ctx.href,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onClick) && _unref(onClick)(...args);
                    }, [
                        "prevent"
                    ]))
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['search-item__status'])
                    }, [
                        _ctx.liveScore ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['search-item__text--medium']]: true,
                                    [_ctx.$style['search-item__score']]: true
                                })
                            }, _toDisplayString(_ctx.liveScore), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['search-item__progress'])
                            }, _toDisplayString(_ctx.liveProgress), 3)
                        ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['search-item__text--medium']]: true,
                                    [_ctx.$style['search-item__date']]: true
                                })
                            }, _toDisplayString(_unref(date)), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['search-item__time'])
                            }, _toDisplayString(_unref(time)), 3)
                        ], 64))
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['search-item__main'])
                    }, [
                        _createVNode(VDynamicContent, {
                            content: _unref(formattedName),
                            tag: _unref(Tag).DIV,
                            class: _normalizeClass({
                                [_ctx.$style['search-item__text--medium']]: true,
                                [_ctx.$style['search-item__name']]: true
                            })
                        }, null, 8, [
                            "content",
                            "tag",
                            "class"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['search-item__info'])
                        }, [
                            _createElementVNode("div", null, [
                                _createVNode(VDynamicContent, {
                                    content: _unref(formattedSportName),
                                    tag: _unref(Tag).SPAN,
                                    class: _normalizeClass(_ctx.$style['search-item__sport'])
                                }, null, 8, [
                                    "content",
                                    "tag",
                                    "class"
                                ]),
                                _createVNode(VDynamicContent, {
                                    content: _unref(formattedRegionName),
                                    tag: _unref(Tag).SPAN,
                                    class: _normalizeClass(_ctx.$style['search-item__region'])
                                }, null, 8, [
                                    "content",
                                    "tag",
                                    "class"
                                ]),
                                _createVNode(VDynamicContent, {
                                    content: _unref(formattedLeagueName),
                                    tag: _unref(Tag).SPAN,
                                    class: _normalizeClass(_ctx.$style['search-item__league'])
                                }, null, 8, [
                                    "content",
                                    "tag",
                                    "class"
                                ])
                            ]),
                            _ctx.streamAvailable ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                                key: 0
                            }, _unref(streamIconProps), {
                                class: _ctx.$style['search-item__stream']
                            }), null, 16, [
                                "class"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ], 2)
                ], 10, _hoisted_1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SearchListItem'
                ]
            ]);
        };
    }
});
