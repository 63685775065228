import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createSlots as _createSlots } from "vue";
const _hoisted_1 = [
    "src"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { ModalSelector } from 'web/src/modules/core/enums';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
import { VEmpty } from 'web/src/components/Empty';
import InputButton from '../InputButton/InputButton.vue';
import { InputMode, TextInputTypes } from '../../enums';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import usePhoneInput from './usePhoneInput';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'PhoneInput',
    props: {
        prefix: {},
        countryCode: {
            default: "MT"
        },
        mask: {},
        disablePlaceholder: {},
        prefixes: {},
        logo: {},
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'phone'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {},
        autocomplete: {},
        emitFilled: {
            type: Boolean
        },
        maxSymbols: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur",
        "filled"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const VCountriesSelector = require('web/src/components/CountriesSelector/VCountriesSelector/VCountriesSelector.vue').default;
        const ModalPortal = require('web/src/components/Modal/ModalPortal/ModalPortal.vue').default;
        // eslint-disable-next-line vue/define-macros-order
        const props = __props;
        const emits = __emit;
        const { uniqueId, isEmpty, isFocus, isHover, formattedValue, showMask, maskedValue, selectedPrefix, selectedCountryCode, onPrefixClick, toggleOpenPrefixModal, isPrefixModalShown, isMaskedPlaceholderHidden, showClearButton, onCountrySelect, currentPlaceholder, hasError, onBlur, onChange, onFocus, onPaste, onInput, onMouseLeave, onMouseOver, onKeyDown, onClear } = usePhoneInput(props, emits);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), {
                class: _normalizeClass(_ctx.$style['phone-input']),
                "input-id": _unref(uniqueId),
                disabled: _ctx.disabled,
                "is-focus": _unref(isFocus),
                "is-empty": _unref(isEmpty),
                label: _ctx.label,
                hint: _ctx.hint,
                "hint-right": _ctx.hintRight,
                "hint-link": _ctx.hintLink,
                "hint-link-right": _ctx.hintLinkRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                "is-large": _ctx.isLarge,
                "clickable-suffix": _unref(showClearButton)
            }, _createSlots({
                input: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['phone-input__wrapper'])
                        }, [
                            _createVNode(_unref(InputBorderWrapper), {
                                "is-disabled": _ctx.disabled,
                                class: _normalizeClass(_ctx.$style['phone-input__prefix'])
                            }, {
                                default: _withCtx(()=>[
                                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.disabled ? 'span' : 'button'), {
                                            type: 'button',
                                            class: _normalizeClass({
                                                [_ctx.$style['phone-input__prefix-inner']]: true,
                                                [_ctx.$style['phone-input__prefix-inner--large']]: _ctx.isLarge,
                                                [_ctx.$style['phone-input__prefix-inner--with-icon']]: !_ctx.disabled
                                            }),
                                            onClick: _unref(onPrefixClick)
                                        }, {
                                            default: _withCtx(()=>[
                                                    (_openBlock(), _createBlock(_unref(VCountryFlag), {
                                                        key: 1,
                                                        name: _unref(selectedCountryCode),
                                                        class: _normalizeClass(_ctx.$style['phone-input__flag'])
                                                    }, null, 8, [
                                                        "name",
                                                        "class"
                                                    ])),
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass(_ctx.$style['phone-input__prefix-text'])
                                                    }, " +" + _toDisplayString(_unref(selectedPrefix)), 3),
                                                    (_openBlock(), _createElementBlock(_Fragment, {
                                                        key: 2
                                                    }, [
                                                        _ctx.disabled ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VIcon), {
                                                            key: 0,
                                                            name: _unref(IconName).TRIANGLE_DOWN,
                                                            size: _unref(IconSize).SIZE_16,
                                                            class: _normalizeClass(_ctx.$style['phone-input__arrow'])
                                                        }, null, 8, [
                                                            "name",
                                                            "size",
                                                            "class"
                                                        ]))
                                                    ], 64))
                                                ]),
                                            _: 1
                                        }, 8, [
                                            "type",
                                            "class",
                                            "onClick"
                                        ]))
                                    ]),
                                _: 1
                            }, 8, [
                                "is-disabled",
                                "class"
                            ]),
                            _createVNode(_unref(InputBorderWrapper), {
                                "is-focus": _unref(isFocus),
                                "is-disabled": _ctx.disabled,
                                "is-hover": _unref(isHover),
                                "has-error": _unref(hasError),
                                class: _normalizeClass(_ctx.$style['phone-input__suffix'])
                            }, {
                                default: _withCtx(()=>[
                                        _createVNode(_unref(TextFlatInput), {
                                            "input-id": _unref(uniqueId),
                                            autofocus: _ctx.autofocus,
                                            name: _ctx.name,
                                            value: _unref(formattedValue),
                                            autocomplete: _ctx.autocomplete,
                                            disabled: _ctx.disabled,
                                            "has-error": _unref(hasError),
                                            "has-vanilla-mask": _unref(showMask),
                                            placeholder: _unref(currentPlaceholder),
                                            type: _unref(TextInputTypes).TEL,
                                            "input-mode": _unref(InputMode).TEL,
                                            "has-icon-suffix": _unref(showClearButton),
                                            "is-large": _ctx.isLarge,
                                            onMouseover: _unref(onMouseOver),
                                            onMouseleave: _unref(onMouseLeave),
                                            onFocus: _unref(onFocus),
                                            onBlur: _unref(onBlur),
                                            onKeydown: _unref(onKeyDown),
                                            onChange: _unref(onChange),
                                            onInput: _unref(onInput),
                                            onPaste: _unref(onPaste)
                                        }, null, 8, [
                                            "input-id",
                                            "autofocus",
                                            "name",
                                            "value",
                                            "autocomplete",
                                            "disabled",
                                            "has-error",
                                            "has-vanilla-mask",
                                            "placeholder",
                                            "type",
                                            "input-mode",
                                            "has-icon-suffix",
                                            "is-large",
                                            "onMouseover",
                                            "onMouseleave",
                                            "onFocus",
                                            "onBlur",
                                            "onKeydown",
                                            "onChange",
                                            "onInput",
                                            "onPaste"
                                        ]),
                                        _unref(showMask) ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            class: _normalizeClass({
                                                [_ctx.$style['phone-input__masked-placeholder']]: true,
                                                [_ctx.$style['phone-input__masked-placeholder--hidden']]: _unref(isMaskedPlaceholderHidden),
                                                [_ctx.$style['phone-input__masked-placeholder--large']]: _ctx.isLarge
                                            })
                                        }, _toDisplayString(_unref(maskedValue)), 3)) : _createCommentVNode("", true)
                                    ]),
                                _: 1
                            }, 8, [
                                "is-focus",
                                "is-disabled",
                                "is-hover",
                                "has-error",
                                "class"
                            ]),
                            _ctx.logo ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['phone-input__logo']),
                                src: _ctx.logo,
                                alt: ""
                            }, null, 10, _hoisted_1)) : _createCommentVNode("", true)
                        ], 2)
                    ]),
                iconSuffix: _withCtx(()=>[
                        _withDirectives(_createVNode(InputButton, {
                            class: _normalizeClass({
                                [_ctx.$style['phone-input__clear-button']]: _ctx.logo
                            }),
                            title: _ctx.$t('WEB2_CLEAR'),
                            "is-large": _ctx.isLarge,
                            onClick: _unref(onClear)
                        }, null, 8, [
                            "class",
                            "title",
                            "is-large",
                            "onClick"
                        ]), [
                            [
                                _vShow,
                                _unref(showClearButton)
                            ],
                            [
                                _directive_data_test,
                                {
                                    el: 'clear-icon-input-button',
                                    visible: !_unref(showClearButton)
                                }
                            ]
                        ])
                    ]),
                _: 2
            }, [
                {
                    name: "dropdown",
                    fn: _withCtx(()=>[
                            _createVNode(_unref(ModalPortal), {
                                selector: _unref(ModalSelector).BODY
                            }, {
                                default: _withCtx(()=>[
                                        _unref(isPrefixModalShown) ? (_openBlock(), _createBlock(_unref(VCountriesSelector), {
                                            key: 0,
                                            "countries-list": _ctx.prefixes,
                                            "default-prefix": _unref(selectedPrefix),
                                            "selected-country-code": _unref(selectedCountryCode),
                                            onCloseFilter: _unref(toggleOpenPrefixModal),
                                            onOnCountrySelect: _unref(onCountrySelect)
                                        }, null, 8, [
                                            "countries-list",
                                            "default-prefix",
                                            "selected-country-code",
                                            "onCloseFilter",
                                            "onOnCountrySelect"
                                        ])) : _createCommentVNode("", true)
                                    ]),
                                _: 1
                            }, 8, [
                                "selector"
                            ])
                        ]),
                    key: "0"
                }
            ]), 1032, [
                "class",
                "input-id",
                "disabled",
                "is-focus",
                "is-empty",
                "label",
                "hint",
                "hint-right",
                "hint-link",
                "hint-link-right",
                "error",
                "error-right",
                "is-large",
                "clickable-suffix"
            ])), [
                [
                    _directive_auto_id,
                    'PhoneInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'phone-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
