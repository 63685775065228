import { useSyncState } from 'web/src/modules/core/store/composables';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import useCustomerFavoritesService from 'web/src/modules/sportline/submodules/favorites/composables/useCustomerFavoritesService';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
export default function useSportlineSidebarSyncBackgroundRequestsComposable(props) {
    const customerFavoritesService = useCustomerFavoritesService();
    const lastUpdate = new BackgroundUpdateStopwatch({
        sportsList: async (param)=>{
            let { silent } = param;
            await props.reloadList(silent);
            lastUpdate.update('sportsList');
        }
    });
    const { initialRequests, syncState, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: props.updateInterval,
        onEnableChanged (value) {
            customerFavoritesService.setIsBackgroundIdsUpdateAvailable(CustomerFavoritesIdsBackgroundUpdateKey.SIDEBAR, value);
        }
    });
    function onInit() {
        initialRequests();
        useSyncState((silent)=>syncState({
                silent
            }), props.updateInterval);
    }
    return {
        onInit,
        setBackgroundUpdateEnabled
    };
}
