import { getWindowHeight, getWindowWidth } from 'web/src/utils/html';
export default function getFixedChatPosition(element, currentBottom, currentRight) {
    const windowWidth = getWindowWidth();
    const windowHeight = getWindowHeight();
    const elementHeight = element.offsetHeight;
    const elementWidth = element.offsetWidth;
    let bottom = currentBottom;
    let right = currentRight;
    if (elementHeight + bottom > windowHeight) bottom = windowHeight - elementHeight;
    if (bottom < 0) bottom = 0;
    if (elementWidth + right > windowWidth) right = windowWidth - elementWidth;
    if (right < 0) right = 0;
    return {
        bottom,
        right
    };
}
