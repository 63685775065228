import { MenuLevel } from 'web/src/components/SidebarMenu/types';
export default function getSidebarMenuCatalogClickPayload(payload) {
    const { level, id } = payload;
    switch(level){
        case MenuLevel.ONE:
            return {
                sportId: id
            };
        case MenuLevel.FREE:
            return {
                leagueId: id
            };
        default:
            return {};
    }
}
