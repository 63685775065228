import { computed } from 'vue';
import { isSportlineEventSuspended } from 'web/src/modules/sportline/utils/sportlineEvent';
import { getOpenStateKeyForGroup } from 'web/src/modules/sportline/views/markets-list/utils';
export default function useMarketsListMarketGroup(props, emit) {
    const isSportEventSuspended = computed(()=>isSportlineEventSuspended(props.sportEvent.value));
    function onMarketGroupToggle(group) {
        const groupKey = getOpenStateKeyForGroup(group);
        emit('market-group-toggle', groupKey);
    }
    return {
        isSportEventSuspended,
        onMarketGroupToggle
    };
}
