import { toRef } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants/index';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useTheme } from 'web/src/modules/theme/composables';
export default function useCasinoGameAnalytics() {
    const analytics = useAnalytics();
    const gamesStore = useCasinoGameStore();
    const activeGame = toRef(gamesStore, 'activeGame');
    const { theme } = useTheme();
    function sendOpenGameMetrika(isDemo) {
        const event = isDemo ? AnalyticsEvent.Z_OPEN_DEMO_GAME : AnalyticsEvent.Z_OPEN_GAME;
        const vendorType = isDemo ? 'demoGameVendor' : 'gameVendor';
        const lobbyType = activeGame.value?.typeId || '';
        const providerName = activeGame.value?.group?.name || '';
        const gameName = activeGame.value?.name || '';
        analytics.push(event, {
            [vendorType]: {
                [lobbyType]: {
                    [providerName]: gameName
                },
                mode: theme.value.toLowerCase()
            }
        });
    }
    return {
        sendOpenGameMetrika
    };
}
