import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
import CasinoLoyaltyProgramSwiperNavigationButtons from // eslint-disable-next-line max-len
'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramSwiper/CasinoLoyaltyProgramSwiperNavigationButtons.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramSwiperNavigation',
    setup (__props) {
        const { swiperState, slideToPreviousSlide, slideToNextSlide } = useSwiperNavigation();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(CasinoLoyaltyProgramSwiperNavigationButtons, {
                "show-previous-button": _unref(swiperState)?.isPreviousButtonAllowed.value,
                "show-next-button": _unref(swiperState)?.isNextButtonAllowed.value,
                onClickPrevious: _unref(slideToPreviousSlide),
                onClickNext: _unref(slideToNextSlide)
            }, null, 8, [
                "show-previous-button",
                "show-next-button",
                "onClickPrevious",
                "onClickNext"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramSwiperNavigation'
                ]
            ]);
        };
    }
});
