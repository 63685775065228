// eslint-disable-next-line unicorn/no-static-only-class
import { _ as _define_property } from "@swc/helpers/_/_define_property";
export class CommonRegistrationState {
}
// TODO: replace by enum
// user is anonym (not passed login stage)
_define_property(CommonRegistrationState, "ANONYM", 'ANONYM');
// user is authorized (passed login stage)
_define_property(CommonRegistrationState, "AUTHORIZED", 'AUTHORIZED');
// form loaded
_define_property(CommonRegistrationState, "FORM_READY", 'FORM_READY');
// server error - nothing to do
_define_property(CommonRegistrationState, "ERROR_SERVER", 'ERROR_SERVER');
// user can resend form
_define_property(CommonRegistrationState, "FORM_ERROR", 'FORM_ERROR');
// form updated with error, user can resend form
_define_property(CommonRegistrationState, "ERROR_FORM_VALIDATION", 'ERROR_FORM_VALIDATION');
