/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ import { computed, ref } from 'vue';
import { MarketCustomTabs } from 'web/src/modules/sportline/views/markets-list/enums';
export function useMarketsListTabsControl(props) {
    const { hasStatistic, marketGroups, allowedToSelectLayoutTypes } = props;
    const isStatisticSelected = ref(false);
    const internalMarketGroupTabId = ref(MarketCustomTabs.All);
    const activeMarketTypesIds = computed(()=>{
        const activeGroup = marketGroups.value.find((group)=>group.id === internalMarketGroupTabId.value);
        return activeGroup?.marketTypeIds ?? [];
    });
    const doShowInlineStatisticSwitch = computed(()=>allowedToSelectLayoutTypes.value.length > 1);
    const isAllTabSelected = computed(()=>internalMarketGroupTabId.value === MarketCustomTabs.All);
    // displayed market group
    const displayedMarketGroupTabId = computed(()=>{
        if (doShowInlineStatisticSwitch.value) // show real active tab for exist separate switch
        return internalMarketGroupTabId.value;
        // fake selected tab without real switch
        return isStatisticSelected.value && hasStatistic.value ? MarketCustomTabs.Statistic : internalMarketGroupTabId.value;
    });
    const isSelectedTabExistInMarketGroups = computed(()=>{
        if (internalMarketGroupTabId.value === MarketCustomTabs.All) return true;
        if (internalMarketGroupTabId.value === MarketCustomTabs.Statistic) // uniq case for empty markets groups
        // used for do not change isStatisticSelected after user turned off it
        return true;
        return marketGroups.value.some((group)=>group.id === internalMarketGroupTabId.value);
    });
    function recalculateActiveTabId() {
        if (internalMarketGroupTabId.value === MarketCustomTabs.All) internalMarketGroupTabId.value = marketGroups.value.length > 0 && !process.env.VUE_APP_PRERENDER ? marketGroups.value[0].id : MarketCustomTabs.All;
    }
    function resetSelectedTabId() {
        internalMarketGroupTabId.value = MarketCustomTabs.All;
    }
    function setSelectedTabId(value) {
        if (value === MarketCustomTabs.Statistic) isStatisticSelected.value = true;
        else {
            internalMarketGroupTabId.value = value;
            if (!doShowInlineStatisticSwitch.value) isStatisticSelected.value = false;
        }
    }
    return {
        isAllTabSelected,
        activeMarketTypesIds,
        isStatisticSelected,
        isSelectedTabExistInMarketGroups,
        doShowInlineStatisticSwitch,
        displayedMarketGroupTabId,
        recalculateActiveTabId,
        resetSelectedTabId,
        setSelectedTabId
    };
}
