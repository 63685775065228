import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { getStorage, normalizeUrl } from '../utils';
export const useShieldIntegrationStore = defineStore('shield-integration', ()=>{
    const sessionId = ref(void 0);
    const sdkUrl = computed(()=>{
        const { helmetAPIUrl } = useSiteConfigStore();
        return helmetAPIUrl ? normalizeUrl(helmetAPIUrl) : void 0;
    });
    function setSessionId(id) {
        getStorage().set({
            session_id: id
        }).// eslint-disable-next-line no-console
        catch((err)=>console.error(err));
        sessionId.value = id;
    }
    return {
        isEnabled: toRef(()=>useSiteConfigStore().isShieldEnabled),
        sdkUrl,
        setSessionId,
        sessionId: computed({
            get: ()=>sessionId.value,
            set: setSessionId
        })
    };
});
