import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import RedirectSeoRouteComponent from './pages/RedirectSeoRouteComponent/RedirectSeoRouteComponent.vue';
export class ErrorsModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addDefaultRoute({
            name: RouteName.ERROR_NOT_FOUND_404,
            path: '/404',
            component: ()=>import('./pages/NotFoundErrorRoutePage/NotFoundErrorRoutePage.vue'),
            isFooterHidden: true
        });
        router.addDefaultRoute({
            name: RouteName.ERROR_FORBIDDEN_403,
            path: '/403',
            component: ()=>import('./pages/ForbiddenErrorRoutePage/ForbiddenErrorRoutePage.vue')
        });
        router.addBlankRoute({
            name: RouteName.ERROR_SERVICE_UNAVAILABLE_503,
            path: '/503',
            prefetch: ()=>import('./prefetch/RedirectToHomeIfDirectLinkPrefetch'),
            component: ()=>import('./pages/TechnicalErrorRoutePage/TechnicalErrorRoutePage.vue')
        });
        router.addBlankRoute({
            name: RouteName.REDIRECT_SEO,
            path: '/301',
            component: ()=>Promise.resolve({
                    default: RedirectSeoRouteComponent
                })
        });
    }
}
