import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
import { SlottIconName } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VInformer from 'web/src/components/Informer/VInformer/VInformer.vue';
import WithdrawalWidget from 'web/src/modules/profile/components/WithdrawalWidget/WithdrawalWidget.vue';
import useRequestedAmount from 'web/src/modules/profile/components/RequestedAmount/composables/useRequestedAmount';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'RequestedAmount',
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { currency, requestedValue, $formatMoney, onClickRequest } = useRequestedAmount(emit);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _unref(requestedValue) > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
            }, [
                _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
                    key: 1
                }, _ctx.$attrs, {
                    class: _ctx.$style['withdrawal-request'],
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onClickRequest) && _unref(onClickRequest)(...args);
                    })
                }), [
                    _createVNode(VInformer, {
                        label: _ctx.$t('JSPACC_ACC_REQUESTED'),
                        value: _unref(requestedValue),
                        currency: _unref(currency)
                    }, null, 8, [
                        "label",
                        "value",
                        "currency"
                    ]),
                    _createVNode(VButton, {
                        label: _ctx.$t('WEB2_CUSTOMER_HISTORY_CANCEL'),
                        kind: _unref(ButtonKind).CANCEL,
                        height: _unref(ButtonHeight).SMALL,
                        onClick: _withModifiers(_unref(onClickRequest), [
                            "stop"
                        ])
                    }, null, 8, [
                        "label",
                        "kind",
                        "height",
                        "onClick"
                    ])
                ], 16)), [
                    [
                        _directive_data_test,
                        {
                            el: 'withdrawal-request'
                        }
                    ]
                ])
            ], 64)) : _createCommentVNode("", true);
        };
    }
});
