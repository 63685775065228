import { ref } from 'vue';
import useAnalytics from 'web/src/modules/analytics/composables/useAnalytics';
export default function useMarketsListAnalytics(props) {
    const analytics = useAnalytics();
    let isDisplayStatisticWatcher = null;
    const timeStartedWatchStatistics = ref(null);
    function startAnalyticsWatchers() {}
    // should start immediate if display turned on by default
    function stopAnalyticsWatchers() {
        isDisplayStatisticWatcher?.();
        isDisplayStatisticWatcher = null;
    }
    return {
        timeStartedWatchStatistics,
        startAnalyticsWatchers,
        stopAnalyticsWatchers
    };
}
