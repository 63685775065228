import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "v-photo-placeholder__frame-wrapper"
};
const _hoisted_2 = [
    "src"
];
const _hoisted_3 = {
    class: "v-photo-placeholder__icon"
};
import { VIcon } from '@components/v-icon';
import { VPhotoPlaceholderDirection } from './enums';
import { useVPhotoPlaceholder } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VPhotoPlaceholder',
    props: {
        direction: {
            default: VPhotoPlaceholderDirection.Vertical
        },
        imageSrc: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { cameraIconProps, rootClassName } = useVPhotoPlaceholder(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_unref(rootClassName))
            }, [
                _cache[0] || (_cache[0] = _createElementVNode("div", {
                    class: "v-photo-placeholder__corner v-photo-placeholder__corner--top"
                }, null, -1)),
                _cache[1] || (_cache[1] = _createElementVNode("div", {
                    class: "v-photo-placeholder__corner v-photo-placeholder__corner--top-right"
                }, null, -1)),
                _cache[2] || (_cache[2] = _createElementVNode("div", {
                    class: "v-photo-placeholder__corner v-photo-placeholder__corner--bottom"
                }, null, -1)),
                _cache[3] || (_cache[3] = _createElementVNode("div", {
                    class: "v-photo-placeholder__corner v-photo-placeholder__corner--bottom-right"
                }, null, -1)),
                _createElementVNode("div", _hoisted_1, [
                    _ctx.imageSrc ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "v-photo-placeholder__frame",
                        alt: "photo",
                        src: _ctx.imageSrc
                    }, null, 8, _hoisted_2)) : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(cameraIconProps))), null, 16)
                    ])
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VPhotoPlaceholder'
                ]
            ]);
        };
    }
});
