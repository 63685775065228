import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ref, nextTick, computed, useSlots, watch } from 'vue';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { ModalSelector } from 'web/src/modules/core/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ModalPortal',
    props: {
        selector: {},
        transition: {}
    },
    setup (__props) {
        const props = __props;
        const bus = useEventsBus();
        const isAnimationAvailable = ref(false);
        const slots = useSlots();
        let wasMounted = false;
        function afterLeave() {
            isAnimationAvailable.value = false;
        }
        async function onComponentMounted() {
            if (props.transition) {
                await nextTick();
                isAnimationAvailable.value = true;
            }
            if (props.selector === ModalSelector.BODY) bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_DISABLE, {
                reason: 'modal'
            });
            wasMounted = false;
        }
        function onComponentUnmounted() {
            isAnimationAvailable.value = false;
            if (props.selector === ModalSelector.BODY) bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_ENABLE, {
                reason: 'modal'
            });
        }
        const hasSlots = computed(()=>hasSlot(slots, 'default'));
        watch(hasSlots, (newValue)=>{
            if (newValue && !wasMounted) {
                wasMounted = true;
                onComponentMounted();
            }
        }, {
            immediate: true
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return hasSlots.value || isAnimationAvailable.value ? _withDirectives((_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: _ctx.selector,
                disabled: !hasSlots.value && !isAnimationAvailable.value,
                onVnodeBeforeUnmount: onComponentUnmounted
            }, [
                _ctx.transition ? (_openBlock(), _createBlock(_Transition, {
                    key: 0,
                    name: _ctx.transition,
                    onAfterLeave: afterLeave,
                    onLeaveCancelled: afterLeave
                }, {
                    default: _withCtx(()=>[
                            isAnimationAvailable.value ? _renderSlot(_ctx.$slots, "default", {
                                key: 0
                            }) : _createCommentVNode("", true)
                        ]),
                    _: 3
                }, 8, [
                    "name"
                ])) : _renderSlot(_ctx.$slots, "default", {
                    key: 1
                })
            ], 8, [
                "to",
                "disabled"
            ])), [
                [
                    _directive_auto_id,
                    'ModalPortal'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
