import { defineStore } from 'pinia';
import { ref } from 'vue';
const useRootNavigationStore = defineStore('root-navigation', ()=>{
    const isRouteContentLoading = ref({});
    function setOnlyFirstRouteContentLoading(name) {
        if (void 0 !== isRouteContentLoading.value[name]) return;
        isRouteContentLoading.value[name] = true;
    }
    function setRouteContentLoaded(name) {
        isRouteContentLoading.value[name] = false;
    }
    return {
        isRouteContentLoading,
        setOnlyFirstRouteContentLoading,
        setRouteContentLoaded
    };
});
export default useRootNavigationStore;
