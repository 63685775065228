import { toRef } from 'vue';
import { setNavigationBreadcrumbs } from '@leon-hub/navigation-config';
import useRouterStore from '../useRouterStore';
import useNavigationStore from '../useNavigationStore';
export default function useFilterNavigationItems() {
    const navigationStore = useNavigationStore();
    const navigationConfig = toRef(navigationStore, 'navigationConfig');
    const routerStore = useRouterStore();
    const realCurrentRouteName = toRef(routerStore, 'realCurrentRouteName');
    function getNavigationItems(group) {
        const items = navigationConfig.value[group] ?? [];
        return realCurrentRouteName.value ? setNavigationBreadcrumbs(items, realCurrentRouteName.value) : items;
    }
    return {
        getNavigationItems
    };
}
