import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useNumberStringInput } from '../../composables';
import { InputMode, TextInputTypes } from '../../enums';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import InputButton from '../InputButton/InputButton.vue';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'NumberStringInput',
    props: {
        showHintOnFocus: {
            type: Boolean
        },
        isSaved: {
            type: Boolean
        },
        isSlipStyle: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'number-input'
        },
        value: {
            default: ''
        },
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        hint: {},
        error: {},
        placeholder: {},
        maximumFractionDigits: {
            default: 0
        }
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { uniqueId, isFocus, isEmpty, isHover, showClearButton, inputValue, onBlur, onChange, onInput, onFocus, onClear, onPaste } = useNumberStringInput(props, emits);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), {
                hint: !_ctx.showHintOnFocus || _unref(isFocus) ? _ctx.hint : '',
                "input-id": _unref(uniqueId),
                label: _ctx.label,
                disabled: _ctx.disabled,
                "is-focus": _unref(isFocus),
                "is-empty": _unref(isEmpty),
                error: _ctx.error,
                "clickable-suffix": _unref(showClearButton)
            }, {
                input: _withCtx(()=>[
                        _createVNode(_unref(InputBorderWrapper), {
                            "is-focus": _unref(isFocus),
                            "is-disabled": _ctx.disabled,
                            "is-hover": _unref(isHover),
                            "has-error": !!_ctx.error,
                            "is-from-modal": _ctx.isSlipStyle
                        }, {
                            default: _withCtx(()=>[
                                    _createVNode(_unref(TextFlatInput), {
                                        autofocus: _ctx.autofocus,
                                        name: _ctx.name,
                                        "input-id": _unref(uniqueId),
                                        type: _unref(TextInputTypes).TEL,
                                        "input-mode": _unref(InputMode).NUMERIC,
                                        value: _unref(inputValue),
                                        "has-error": !!_ctx.error,
                                        "has-icon-suffix": _unref(showClearButton) || _ctx.isSaved,
                                        disabled: _ctx.disabled,
                                        placeholder: _ctx.placeholder,
                                        onFocus: _unref(onFocus),
                                        onBlur: _unref(onBlur),
                                        onChange: _unref(onChange),
                                        onInput: _unref(onInput),
                                        onPaste: _unref(onPaste)
                                    }, null, 8, [
                                        "autofocus",
                                        "name",
                                        "input-id",
                                        "type",
                                        "input-mode",
                                        "value",
                                        "has-error",
                                        "has-icon-suffix",
                                        "disabled",
                                        "placeholder",
                                        "onFocus",
                                        "onBlur",
                                        "onChange",
                                        "onInput",
                                        "onPaste"
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "is-focus",
                            "is-disabled",
                            "is-hover",
                            "has-error",
                            "is-from-modal"
                        ])
                    ]),
                iconSuffix: _withCtx(()=>[
                        _withDirectives(_createVNode(InputButton, {
                            title: _ctx.$t('WEB2_CLEAR'),
                            onClick: _unref(onClear)
                        }, null, 8, [
                            "title",
                            "onClick"
                        ]), [
                            [
                                _vShow,
                                _unref(showClearButton)
                            ],
                            [
                                _directive_data_test,
                                {
                                    el: 'clear-icon-input-button',
                                    visible: !_unref(showClearButton)
                                }
                            ]
                        ]),
                        _ctx.isSaved && !_unref(showClearButton) ? (_openBlock(), _createBlock(_unref(VIcon), {
                            key: 0,
                            name: _unref(IconName).CHECK,
                            size: _unref(IconSize).SIZE_16
                        }, null, 8, [
                            "name",
                            "size"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "hint",
                "input-id",
                "label",
                "disabled",
                "is-focus",
                "is-empty",
                "error",
                "clickable-suffix"
            ])), [
                [
                    _directive_auto_id,
                    'NumberStringInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'number-string-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
