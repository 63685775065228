import { keys } from '@leon-hub/utils';
function mergeErrors(customErrors, schemaErrors) {
    const errors = customErrors ? {
        ...customErrors
    } : {};
    if (!schemaErrors) return errors;
    for (const key of keys(schemaErrors)){
        const value = schemaErrors[key];
        if (Array.isArray(value)) errors[key] = value.map((item, index)=>{
            const errorValue = customErrors?.[key];
            const errorItem = Array.isArray(errorValue) ? errorValue[index] : errorValue;
            return item || errorItem;
        });
        else errors[key] = value;
    }
    return errors;
}
export default mergeErrors;
