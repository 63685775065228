import { DropdownPreferredDirection } from '../enums/DropdownPreferredDirection';
export default function getSettingsForOpenDirection(props, dropdownContainer) {
    const preferredDirection = props.dropDirection ?? DropdownPreferredDirection.AUTO;
    const result = {
        betterOpenToTop: false,
        maxHeight: null
    };
    if ('undefined' == typeof window) return result;
    const { appearZonePositionY, appearZoneOffsetY } = props;
    const winHeight = window.innerHeight;
    const contentSize = dropdownContainer.value?.offsetHeight || 0;
    const verticalOffset = props.verticalOffset ?? 0;
    const downAppearPoint = appearZonePositionY + appearZoneOffsetY;
    const spaceBelow = winHeight - downAppearPoint;
    const maxContentSize = props.heightLimit ? Math.min(props.heightLimit, contentSize) : contentSize;
    if (spaceBelow >= maxContentSize && preferredDirection !== DropdownPreferredDirection.TOP) {
        if (props.heightLimit) result.maxHeight = props.heightLimit;
        else result.maxHeight = spaceBelow - verticalOffset;
        return result;
    }
    switch(preferredDirection){
        case DropdownPreferredDirection.TOP:
            result.betterOpenToTop = true;
            break;
        case DropdownPreferredDirection.DOWN:
            result.betterOpenToTop = false;
            break;
        case DropdownPreferredDirection.AUTO:
        // is default
        default:
            result.betterOpenToTop = 0.7 * appearZonePositionY > spaceBelow;
            break;
    }
    let maxHeightByWindowSize;
    maxHeightByWindowSize = result.betterOpenToTop ? appearZonePositionY - verticalOffset : winHeight - appearZonePositionY - appearZoneOffsetY - verticalOffset;
    result.maxHeight = props.heightLimit ? Math.min(maxHeightByWindowSize, props.heightLimit) : maxHeightByWindowSize;
    return result;
}
