import { computed, ref } from 'vue';
import { isOptionalString } from '@leon-hub/guards';
import isPasswordInputProps from 'web/src/components/Input/guards/isPasswordInputProps';
import { useI18n } from 'web/src/modules/i18n/composables';
import getWrapperHintProperties from '../../utils/getWrapperHintProperties';
export default function useFormPassword(props) {
    const componentProps = computed(()=>{
        const { name, value, disabled, title, error, hintMessage, options, autofocus, extraProperties } = props;
        isOptionalString(value);
        isPasswordInputProps(options);
        const wrapperHintProperties = getWrapperHintProperties({
            options,
            hintMessage,
            error
        });
        const { $translate } = useI18n();
        const validationGroups = computed(()=>{
            const groups = [];
            if (extraProperties?.minLength) groups.push({
                rules: [
                    {
                        message: $translate('WEB2_PASSWORD_LENGTH_DYNAMIC_LENGTH', ref({
                            number: extraProperties?.minLength.toString()
                        })).value,
                        regexp: `.{${extraProperties?.minLength},}$`
                    }
                ],
                requiredValidRules: 1
            });
            if (extraProperties?.pattern) groups.push({
                rules: [
                    {
                        message: $translate('WEB2_PASSWORD_NUMBER').value,
                        regexp: extraProperties.pattern.toString()
                    }
                ],
                requiredValidRules: 2
            });
            return groups;
        });
        return {
            value,
            label: title,
            disabled,
            ...options || {},
            autofocus,
            name,
            error: wrapperHintProperties.error,
            hint: wrapperHintProperties.hint,
            hintLink: wrapperHintProperties.hintLink,
            groups: validationGroups.value
        };
    });
    return {
        componentProps
    };
}
