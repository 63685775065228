import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { CustomerFieldType, getIncompleteRegistrationFields, updateIncompleteRegistrationFields } from '@leon-hub/api-sdk';
import { isFormSchema } from '@leon-hub/form-utils';
import { useCountry } from 'web/src/modules/country/composables';
import StringUtils from 'web/src/utils/StringUtils';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useCountryStore } from 'web/src/modules/country/store';
import { getUiFieldRawOptions, JsonSchemaAdapter } from 'web/src/utils/jsonSchemaAdapter';
import { FormControlType } from 'web/src/components/Form/enums';
import getFormSelectorCountryCode from 'web/src/modules/registration/utils/getFormSelectorCountryCode';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { isUIFormSchema } from 'web/src/components/Form/guards';
import { mapFormData } from 'web/src/components/Form/utils';
export const useRegistrationCompletionStore = defineStore('registration-completion-completion', ()=>{
    const apiClient = useGraphqlClient();
    const { country } = useCountry();
    const geoIpCountryCode = toRef(useCountryStore(), 'geoIpCountryCode');
    const schema = ref();
    const schemaId = ref('');
    const uiSchema = ref();
    const preparedFormSchemas = computed(()=>{
        if (!schema.value || !uiSchema.value) return;
        const jsonSchema = JSON.parse(schema.value);
        isFormSchema(jsonSchema);
        isUIFormSchema(uiSchema.value);
        const jsonSchemaAdapter = new JsonSchemaAdapter(uiSchema.value, jsonSchema, {
            /*
      * compared to default mapping keys - there is no mapping for "hidden"
      * Don't sure it have a sense
      *  */ default: 'defaultValue',
            disabled: 'disabled',
            title: 'title',
            widget: 'widget',
            options: 'options',
            labels: 'labels'
        }).// TODO: can leave this only for FormControlType.SelectWithSearch after backend changes (LB-14443)
        addPropertiesByWidgetGroup([
            FormControlType.Select,
            FormControlType.SelectWithSearch
        ], {
            default: getFormSelectorCountryCode(geoIpCountryCode.value, uiSchema.value)
        }).addPropertiesById(CustomerFieldType.COUNTRY_SELECT, ()=>({
                default: getFormSelectorCountryCode(geoIpCountryCode.value, uiSchema.value)
            })).addOptionsByWidget(FormControlType.SelectWithSearch, {
            isCountrySelector: true,
            modalViewHeader: useI18n().$translate('WEB2_REGISTRATION_COMPLETION_COUNTRY_TITLE').value
        }).addPropertiesByWidget(FormControlType.Date, (field)=>({
                default: field?.defaultValue ?? '',
                options: {
                    emitFilled: true,
                    isBirthday: true
                }
            })).addPropertiesByWidget(FormControlType.AutocompleteAddress, (field)=>{
            const optionsData = getUiFieldRawOptions(field.options) ?? {};
            return {
                options: {
                    isCityType: optionsData?.isCityType,
                    countryCode: country.value
                }
            };
        }).addPropertiesByWidgetGroup([
            FormControlType.Phone,
            FormControlType.PhoneInput,
            FormControlType.PhoneCountriesSelectorInput
        ], (field)=>{
            const { parsePhoneNumberByUIField } = useFormDefaultFormPhoneValue();
            return {
                default: parsePhoneNumberByUIField(field)
            };
        });
        return {
            schema: jsonSchemaAdapter.getFormSchema(),
            uiSchema: jsonSchemaAdapter.getFormUiSchema()
        };
    });
    async function fetchIncompleteRegistrationFields(param) {
        let { customerAction, paymentSystemId } = param;
        const response = await getIncompleteRegistrationFields(apiClient, (node)=>node.queries.registration.getIncompleteRegistrationFields, {
            options: {
                customerAction: customerAction ? StringUtils.kebabCaseToCamelCase(customerAction) : null,
                paymentSystemId
            }
        });
        if (!response.uiFormSchema.fields.length) return false;
        schema.value = response.formValidationSchema;
        uiSchema.value = response.uiFormSchema;
        schemaId.value = response.schemaId;
        return true;
    }
    function updateIncompleteFields(formData) {
        return updateIncompleteRegistrationFields(apiClient, (node)=>node.mutations.registration.updateIncompleteRegistrationFields, {
            options: {
                schemaId: schemaId.value,
                formParams: mapFormData(formData)
            }
        });
    }
    return {
        preparedFormSchemas,
        uiSchema,
        fetchIncompleteRegistrationFields,
        updateIncompleteFields
    };
});
