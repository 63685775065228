import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { TextInputTypes } from 'web/src/components/Input/enums';
import InputButton from '../InputButton/InputButton.vue';
import TextInput from '../TextInput';
import { useBaseInputEvents } from '../../composables';
import useCardCvvInput from './useCardCvvInput';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'CardCvvInput',
    props: {
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'cvv'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { maskOptions, showHelp } = useCardCvvInput();
        const { emitChange, emitBlur, emitInput, emitFocus } = useBaseInputEvents(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(_unref(TextInput), {
                autofocus: _ctx.autofocus,
                value: _ctx.value,
                "is-ym-disable-keys": "",
                name: _ctx.name,
                label: _ctx.label,
                placeholder: _ctx.$t('WEB2_3_DIGITS'),
                disabled: _ctx.disabled,
                hint: _ctx.hint,
                "hint-link": _ctx.hintLink,
                "hint-right": _ctx.hintRight,
                "hint-link-right": _ctx.hintLinkRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                mask: _unref(maskOptions),
                type: _unref(TextInputTypes).TEL,
                "is-large": _ctx.isLarge,
                "clickable-icon": "",
                onInput: _unref(emitInput),
                onChange: _unref(emitChange),
                onFocus: _unref(emitFocus),
                onBlur: _unref(emitBlur)
            }, {
                iconSuffix: _withCtx(()=>[
                        _createVNode(InputButton, {
                            class: _normalizeClass(_ctx.$style['card-cvv-input-button']),
                            title: _ctx.$t('WEB2_WHAT_IS_CVV_CVC'),
                            "icon-name": _unref(IconName).QUESTION_OUTLINE,
                            onClick: _unref(showHelp)
                        }, null, 8, [
                            "class",
                            "title",
                            "icon-name",
                            "onClick"
                        ])
                    ]),
                _: 1
            }, 8, [
                "autofocus",
                "value",
                "name",
                "label",
                "placeholder",
                "disabled",
                "hint",
                "hint-link",
                "hint-right",
                "hint-link-right",
                "error",
                "error-right",
                "mask",
                "type",
                "is-large",
                "onInput",
                "onChange",
                "onFocus",
                "onBlur"
            ])), [
                [
                    _directive_auto_id,
                    'CardCvvInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'card-cvv-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
