import { isOptionalString, isString } from '@leon-hub/guards';
export default function getCmsParams(routeName, routeParams, seoConfigs) {
    if (!routeName || !seoConfigs) return {};
    isString(routeName);
    const seoConfig = seoConfigs[routeName];
    const seoData = seoConfig?.seoData;
    if (!seoData) return {};
    const cmsParams = {};
    for (const key of Object.keys(seoData.params || {}))if (seoData.params[key].startsWith(':')) {
        const routeKey = seoData.params[key].substring(1);
        const value = routeParams[routeKey];
        isOptionalString(value);
        if (value) cmsParams[key] = value;
    } else cmsParams[key] = seoData.params[key];
    if (seoData.place) cmsParams.place = seoData.place;
    return cmsParams;
}
