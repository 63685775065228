import { ref, computed, watch } from 'vue';
import { useScrollIntoView } from 'web/src/modules/core/composables/scroll';
import doAfterScrollVisibilityCheck from 'web/src/utils/doAfterScrollVisibilityCheck';
export default function useFocusOnError(fields, externalErrors, focusOnError, focusByName) {
    const { scrollIntoView } = useScrollIntoView();
    const isFocusedOnError = ref(false);
    const resetFocusOnError = ()=>{
        isFocusedOnError.value = false;
    };
    const lines = ref({});
    const setLinesRef = (templateRef, id)=>{
        if (templateRef) {
            HTMLDivElement;
            lines.value[id] = templateRef;
        }
    };
    const firstFieldWithErrorId = computed(()=>{
        const result = null;
        if (!focusOnError.value) return result;
        for (const field of fields.value)if (field.widgetProps.error) return field.id;
        return result;
    });
    const scrollToFirstError = ()=>{
        if (firstFieldWithErrorId.value) {
            const element = lines.value[firstFieldWithErrorId.value];
            const baseCallback = ()=>{
                focusByName(firstFieldWithErrorId.value ?? '');
                isFocusedOnError.value = true;
            };
            doAfterScrollVisibilityCheck(element, {
                callbackIfHidden: ()=>{
                    scrollIntoView(element);
                    baseCallback();
                },
                callbackIfVisible: ()=>{
                    baseCallback();
                },
                observerOptions: {
                    threshold: 1.0
                }
            });
        }
    };
    watch(externalErrors, ()=>{
        if (!focusOnError.value || isFocusedOnError.value) return;
        scrollToFirstError();
    });
    return {
        setLinesRef,
        scrollToFirstError,
        resetFocusOnError
    };
}
