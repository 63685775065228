import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = [
    "disabled"
];
import { useCssModule } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useIsSportlineType } from 'web/src/modules/sportline/composables/core';
import createDisplayValueChangeDirective from 'web/src/utils/directives/createDisplayValueChangeDirective';
import { runnerComponentPropsToRefs, useRunner } from './composables';
import { runnerDataTestElement } from './constants';
export default /*@__PURE__*/ _defineComponent({
    __name: 'InListRunner',
    props: {
        runner: {},
        market: {},
        slipCaption: {},
        sportEventId: {},
        isOutrightEvent: {
            type: Boolean
        },
        sportlineType: {},
        doDisplayName: {
            type: Boolean
        },
        isPermanentBetUp: {
            type: Boolean
        },
        isPermanentBetDown: {
            type: Boolean
        },
        isRunnerV2: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const cssModule = useCssModule();
        const vDirection = {
            // @TODO review add class logic
            updated: createDisplayValueChangeDirective({
                moveUp: cssModule['sportline-runner-value--bet-up'],
                moveDown: cssModule['sportline-runner-value--bet-down'],
                autoDirection: true
            })
        };
        const runnerProps = runnerComponentPropsToRefs(props);
        const { value, price, label, isEmpty, isLocked, isSelected, addToSlip } = useRunner(runnerProps);
        const { isLive, isPrematch } = useIsSportlineType(runnerProps.sportlineType);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                type: "button",
                class: _normalizeClass({
                    [_ctx.$style['sportline-runner']]: true,
                    [_ctx.$style['sportline-runner--locked']]: _unref(isLocked) && !_unref(isSelected),
                    [_ctx.$style['sportline-runner--selected']]: _unref(isSelected)
                }),
                disabled: _unref(isLocked) && !_unref(isSelected),
                onClick: _cache[0] || (_cache[0] = ($event)=>_unref(addToSlip)({
                        event: $event
                    }))
            }, [
                _unref(label) ? _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['sportline-runner__handicap']]: true,
                        [_ctx.$style['sportline-runner__handicap--locked']]: _unref(isLocked) && !_unref(isSelected)
                    })
                }, [
                    _createTextVNode(_toDisplayString(_unref(label)), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: _unref(runnerDataTestElement).handicap
                        }
                    ]
                ]) : _createCommentVNode("", true),
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                    _createTextVNode(_toDisplayString(_unref(value)), 1)
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: _unref(runnerDataTestElement).price
                        }
                    ]
                ]),
                _unref(isLocked) && !_unref(isEmpty) ? _withDirectives((_openBlock(), _createBlock(_unref(VIcon), {
                    key: 1,
                    name: _unref(IconName).LOCK,
                    size: _unref(IconSize).SIZE_12,
                    class: _normalizeClass(_ctx.$style['sportline-runner__icon'])
                }, null, 8, [
                    "name",
                    "size",
                    "class"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: _unref(runnerDataTestElement).lockIcon
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'InListRunner'
                ],
                [
                    _directive_data_test,
                    {
                        el: _unref(runnerDataTestElement).runner,
                        locked: _unref(isLocked),
                        selected: _unref(isSelected),
                        live: _unref(isLive),
                        prematch: _unref(isPrematch)
                    }
                ],
                [
                    vDirection,
                    {
                        value: _unref(price)
                    }
                ]
            ]);
        };
    }
});
