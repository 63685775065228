import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import { useWindowResize, useWindowVisibilityChanged } from '@leon-hub/browser-composables';
import { getNextSlideIndexOffset } from 'web/src/modules/banners/components/VSwiperBannerNavigation/utils/utils';
import { useAbstractBanner } from 'web/src/modules/banners/composables/useAbstractBanner';
export default function useSwiperBanner(props, emit) {
    const route = useRoute();
    const { banners, autoplayTimeout, isGroupBannersDev, emitBannerItemClick } = useAbstractBanner(props, emit);
    const swiper = ref(null);
    const activeIndex = ref(0);
    const isMouseOver = ref(false);
    let timeout = 0;
    const bannersKey = computed(()=>`${banners.value.length}${String(route.name)}`);
    function onResize(param) {
        let { deltaX } = param;
        if (Math.abs(deltaX) > 0) swiper.value?.slideToSlide(swiper.value.getActiveSlideIndex());
    }
    function emitSlideChanged(event) {
        activeIndex.value = event.activeSlide;
        emit('slide-changed', event.activeSlide);
    }
    function stopTimer() {
        if (timeout) {
            Timer.clearTimeout(timeout);
            timeout = 0;
        }
    }
    function startTimer() {
        stopTimer();
        if (autoplayTimeout.value && !isMouseOver.value) timeout = Timer.setTimeout(()=>{
            slideToNextSlide();
            startTimer();
        }, autoplayTimeout.value);
    }
    function onWindowVisibilityChanged(isVisible) {
        if (isVisible) startTimer();
        else stopTimer();
    }
    function onMouseEnter() {
        isMouseOver.value = true;
        stopTimer();
    }
    function onMouseLeave() {
        isMouseOver.value = false;
        startTimer();
    }
    function slideToNextSlide() {
        if (swiper.value) {
            const activeIndexCurrent = swiper.value.getActiveSlideIndex();
            swiper.value.slideToNextSlide(getNextSlideIndexOffset(activeIndexCurrent, banners.value.length));
        }
    }
    function onActivatedAction() {
        startTimer();
    }
    function onDeactivatedAction() {
        stopTimer();
    }
    useWindowResize(onResize);
    useWindowVisibilityChanged(onWindowVisibilityChanged);
    return {
        swiper,
        banners,
        emitBannerItemClick,
        isGroupBannersDev,
        activeIndex,
        bannersKey,
        isMouseOver,
        emitSlideChanged,
        onMouseEnter,
        onMouseLeave,
        onActivatedAction,
        onDeactivatedAction
    };
}
