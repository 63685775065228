import { buildSportNavigationParameters } from 'web/src/modules/sportline/utils/rest/navigation-parameters';
import { sportSegments, SportBackgroundColorDark, SportBackgroundColorLight } from 'web/src/modules/sportline/constants';
import { getSportFamilyEmoji, isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { findSportRepresentationSetting } from '../pre-build';
function getIsLiveTimerEnabled(sportRepresentationSettings, sport) {
    if (!sportRepresentationSettings || !sport) return false;
    const item = findSportRepresentationSetting(sportRepresentationSettings, sport);
    return !!item?.useFullProgress;
}
export default function createSport(options) {
    const { sport, sportFamilyMapping, sportRepresentationSettings } = options;
    const id = String(sport.id);
    const { name, background } = sport;
    const family = sport.family.toLowerCase();
    const navigationParameters = buildSportNavigationParameters({
        sportResponse: sport,
        sportFamilyMapping
    });
    const weight = sport.weight || 0;
    const segmentKey = Object.keys(options.sportSegmentMapping ?? {}).find((key)=>isSportFamilyEquals(String(key), family));
    const segment = segmentKey ? options.sportSegmentMapping?.[segmentKey] ?? sportSegments.default : sportSegments.default;
    return {
        id,
        name,
        family: navigationParameters.urlName,
        segment,
        representation: {
            icon: family,
            family,
            emoji: getSportFamilyEmoji(family),
            useFullProgress: getIsLiveTimerEnabled(sportRepresentationSettings, sport),
            weight,
            background: {
                dark: SportBackgroundColorDark[family],
                light: SportBackgroundColorLight[family]
            },
            backgroundImage: background
        },
        navigationParameters
    };
}
