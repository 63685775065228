import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { assertFullCoreLeagueResponse, assertFullCoreRegionResponse, assertFullCoreSportResponse } from 'web/src/modules/sportline/utils/rest/assert';
let ResponseMappings = class ResponseMappings {
    getSportResponseOrSetNew(response) {
        if (this.sportsResponses[response.id]) return this.sportsResponses[response.id];
        this.sportsResponses[response.id] = assertFullCoreSportResponse(response);
        return this.sportsResponses[response.id];
    }
    getRegionResponseOrSetNew(response) {
        if (this.regionResponses[response.id]) return this.regionResponses[response.id];
        this.regionResponses[response.id] = assertFullCoreRegionResponse(response);
        return this.regionResponses[response.id];
    }
    getLeagueResponseOrSetNew(response) {
        if (this.leaguesResponses[response.id]) return this.leaguesResponses[response.id];
        this.leaguesResponses[response.id] = assertFullCoreLeagueResponse(response);
        return this.leaguesResponses[response.id];
    }
    constructor(){
        _define_property(this, "sportsResponses", {});
        _define_property(this, "regionResponses", {});
        _define_property(this, "leaguesResponses", {});
    }
};
/**
 * Use to fill responses by references
 * A reference has structure { id: string }
 */ export { ResponseMappings as default };
