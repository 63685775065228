import { computed, ref } from 'vue';
import { isString } from '@leon-hub/guards';
import { getFileType } from 'web/src/components/Input/utils';
import { InputFileTypes } from '../../enums';
import useMultipleFileInput from '../../composables/useMultipleFileInput';
export default function useFileMultipleDragAndDropInput(props, emits) {
    const { uniqueId, filesRecords, acceptedFileTypes, removeByIndex, onChange, onReplace, onBlur, onFocus, addMoreAvailable, handleFileList } = useMultipleFileInput(props, emits);
    const errorList = computed(()=>(props.formErrors || []).filter((item)=>!!item && isString(item)));
    const placeholderPropsList = computed(()=>filesRecords.value.map((param, index)=>{
            let { name, base64String } = param;
            const fileType = getFileType(name);
            const isImageFile = fileType === InputFileTypes.Image;
            return {
                imageSrc: isImageFile ? base64String : '',
                hasError: !!(props.formErrors && props.formErrors[index]),
                name,
                isImageFile
            };
        }));
    const onLabelKeydown = (event)=>{
        const { target } = event;
        HTMLElement;
        target.click();
    };
    const isInDropZone = ref(false);
    const onDragenter = (event)=>{
        event.preventDefault();
        isInDropZone.value = true;
    };
    const onDragleave = (event)=>{
        event.preventDefault();
        isInDropZone.value = false;
    };
    const onDragover = (event)=>{
        event.preventDefault();
    };
    const onDrop = (event)=>{
        event.preventDefault();
        isInDropZone.value = false;
        const dataTransferFiles = event.dataTransfer?.files;
        if (!dataTransferFiles) return;
        handleFileList(dataTransferFiles);
    };
    return {
        errorList,
        placeholderPropsList,
        uniqueId,
        acceptedFileTypes,
        onLabelKeydown,
        filesRecords,
        isInDropZone,
        removeByIndex,
        onChange,
        onReplace,
        onBlur,
        onFocus,
        onDragenter,
        onDragleave,
        onDragover,
        onDrop,
        addMoreAvailable
    };
}
