import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { useVBrand } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VBrand',
    props: {
        isSimplifiedLogo: {
            type: Boolean
        },
        isDark: {
            type: Boolean
        },
        isDarkIcon: {
            type: Boolean
        },
        alt: {
            default: ''
        },
        isLanding: {
            type: Boolean
        },
        isHeader: {
            type: Boolean
        },
        isFixSize: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { logoUrl } = useVBrand(props);
        function onClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("img", {
                src: _unref(logoUrl),
                alt: _ctx.alt,
                class: _normalizeClass({
                    [_ctx.$style['brand']]: true,
                    [_ctx.$style['brand--short']]: _ctx.isSimplifiedLogo,
                    [_ctx.$style['brand--fix-size']]: _ctx.isFixSize
                }),
                onClick: onClick
            }, null, 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VBrand'
                ]
            ]);
        };
    }
});
