import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
export function useFavoriteRegionFilterTabs(props, emits, favoriteEventsList) {
    const { selectedRegionFamily } = props;
    const { selectRegionFamily } = emits;
    const { $translate } = useI18n();
    const favoriteRegionsTabs = computed(()=>{
        const selectedFamily = selectedRegionFamily.value;
        const regionMap = new Map();
        const favoritesList = favoriteEventsList.value;
        if (favoritesList && favoritesList.length > 0) {
            for (const sportElement of favoritesList)for (const regionElement of sportElement.regions)if (!regionMap.get(regionElement.region.regionFamily)) regionMap.set(regionElement.region.regionFamily, {
                key: regionElement.region.regionFamily,
                title: regionElement.region.name,
                isActive: regionElement.region.regionFamily === selectedFamily,
                isCyberSport: sportElement.sport.segment.id === SportSegmentId.CyberSport,
                region: regionElement.region,
                onClick: ()=>{
                    selectRegionFamily(regionElement.region.regionFamily);
                }
            });
        }
        if (regionMap.size < 2) return [];
        const allRegionsTab = {
            key: 'all-favorites-regions-tab',
            title: $translate('JSP_SPORT_NAV_DEFAULT_REGION').value,
            isActive: null === selectedFamily,
            isCyberSport: false,
            onClick: ()=>{
                selectRegionFamily(null);
            }
        };
        return [
            allRegionsTab,
            ...regionMap.values()
        ];
    });
    const activeTabIndex = computed(()=>favoriteRegionsTabs.value.findIndex((tab)=>tab.isActive));
    return {
        activeTabIndex,
        favoriteRegionsTabs
    };
}
