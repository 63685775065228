import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { doConfirmations } from '@leon-hub/api-sdk';
import { doRewarding } from '@leon-hub/api-sdk/src/sdk/sdk';
import getReferralData from 'web/src/modules/referral-program/utils/api/getReferral';
import getRewardsData from 'web/src/modules/referral-program/utils/api/getRewards';
import getAvailabilityData from 'web/src/modules/referral-program/utils/api/getAvailability';
import getDetailsData from 'web/src/modules/referral-program/utils/api/getDetails';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { ReferralProgramViewMode } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import sortByRegistrationDate from 'web/src/modules/referral-program/utils/sortByRegistrationDate';
import { useSiteConfigStore } from 'web/src/modules/core/store';
const useReferralProgramStore = defineStore('referral-program', ()=>{
    // State
    const programReferral = ref(null);
    const programDetails = ref(null);
    const rewards = ref(null);
    const availability = ref(null);
    const isAccessGranted = ref(false);
    const isConfirmed = ref(false);
    const apiClient = useGraphqlClient();
    const viewMode = ref(ReferralProgramViewMode.MAIN);
    const formatMoney = useFormatMoney();
    const isProfileReferralProgramEnabled = toRef(useSiteConfigStore(), 'isProfileReferralProgramEnabled');
    // Actions
    async function setProgramReferral() {
        try {
            if (isProfileReferralProgramEnabled.value) programReferral.value = await getReferralData();
        } catch  {
            clearProgramReferral();
        }
    }
    function clearProgramReferral() {
        programReferral.value = null;
    }
    async function setRewards(referralCode) {
        try {
            if (isProfileReferralProgramEnabled.value) rewards.value = await getRewardsData(referralCode);
        } catch (error) {
            clearRewards();
            throw error;
        }
    }
    function clearRewards() {
        rewards.value = null;
    }
    async function getRefDetails() {
        if (isProfileReferralProgramEnabled.value) {
            let response = await getDetailsData();
            if (response?.referrals) response = sortByRegistrationDate(response);
            programDetails.value = response;
            isAccessGranted.value = !programDetails.value?.playerRequirements.some((item)=>!item.satisfied);
        }
    }
    async function getAvailability() {
        availability.value = await getAvailabilityData();
    }
    async function confirmation(silent) {
        let confirmed = false;
        if (isProfileReferralProgramEnabled.value) {
            const response = await doConfirmations(apiClient, (node)=>node.mutations.refProgram.confirmations, {
                options: {}
            }, {
                silent
            });
            confirmed = response.confirmed;
            await getRefDetails();
            if (confirmed) isConfirmed.value = true;
        }
        return confirmed;
    }
    async function rewarding(rewardOptions, silent) {
        if (isProfileReferralProgramEnabled.value) {
            let response = await doRewarding(apiClient, (node)=>node.mutations.refProgram.reward, {
                options: rewardOptions
            }, {
                silent
            });
            if (response?.referrals) response = sortByRegistrationDate(response);
            programDetails.value = response;
        }
    }
    const totalReward = computed(()=>formatMoney(programDetails.value?.totalRewardAmount || 0));
    function setViewMode(value) {
        viewMode.value = value;
    }
    return {
        programReferral,
        programDetails,
        isAccessGranted,
        rewards,
        availability,
        setProgramReferral,
        clearProgramReferral,
        setRewards,
        clearRewards,
        getAvailability,
        getRefDetails,
        confirmation,
        totalReward,
        viewMode,
        setViewMode,
        rewarding,
        isConfirmed
    };
});
export default useReferralProgramStore;
