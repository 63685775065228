import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode, createSlots as _createSlots } from "vue";
import { toRef } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { SportlineNavigationItemCustomStyle } from 'web/src/modules/sportline/enums/navigation';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import VLinkPrevented from 'web/src/components/Link/VLinkPrevented/VLinkPrevented.vue';
import SportlineNavigationIndicator from 'web/src/modules/sportline/components/navigation-indicator/SportlineNavigationIndicator.vue';
import { useSportlineFilter } from '../composables/useSportlineFilter';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineFilter',
    props: {
        items: {}
    },
    setup (__props, param) {
        let { expose: __expose } = param;
        const props = __props;
        const items = toRef(props, 'items');
        const { swiper, slideToActive, itemsWithIdentifier } = useSportlineFilter({
            items
        });
        __expose({
            slideToActive
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['sportline-filter']]: true
                })
            }, [
                _createVNode(_unref(VSwiper), {
                    ref_key: "swiper",
                    ref: swiper,
                    "is-scroll-snap-enabled": "",
                    onVnodeMounted: _cache[0] || (_cache[0] = ($event)=>_unref(slideToActive)(false))
                }, _createSlots({
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(itemsWithIdentifier), (item, index)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                    key: item.key,
                                    class: _normalizeClass({
                                        [_ctx.$style['sportline-filter__slide']]: true
                                    }),
                                    onClick: ($event)=>item.onClick(item, index)
                                }, {
                                    default: _withCtx(()=>[
                                            _withDirectives((_openBlock(), _createBlock(VLinkPrevented, {
                                                to: item.to,
                                                class: _normalizeClass({
                                                    [_ctx.$style['sportline-filter__item']]: true,
                                                    [_ctx.$style['sportline-filter__item--active']]: item.isActive,
                                                    [_ctx.$style['sportline-filter__item--default']]: !item.customStyle,
                                                    [_ctx.$style['sportline-filter__item--bundesliga']]: item.customStyle === _unref(SportlineNavigationItemCustomStyle).Bundesliga,
                                                    [_ctx.$style['sportline-filter__item--highest-odds']]: item.customStyle === _unref(SportlineNavigationItemCustomStyle).HighestOdds
                                                }),
                                                title: item.title
                                            }, {
                                                default: _withCtx(()=>[
                                                        item.icon ? (_openBlock(), _createBlock(SportlineNavigationIndicator, {
                                                            key: 0,
                                                            icon: item.icon,
                                                            size: item.customStyle === _unref(SportlineNavigationItemCustomStyle).Bundesliga ? _unref(IconSize).SIZE_28 : _unref(IconSize).SIZE_20,
                                                            "custom-filter": item.customFilter,
                                                            class: _normalizeClass(_ctx.$style['sportline-filter__item-icon'])
                                                        }, null, 8, [
                                                            "icon",
                                                            "size",
                                                            "custom-filter",
                                                            "class"
                                                        ])) : _createCommentVNode("", true),
                                                        _createElementVNode("span", {
                                                            class: _normalizeClass({
                                                                [_ctx.$style['sportline-filter__item-title']]: true,
                                                                [_ctx.$style['sportline-filter__item-title--default']]: !item.customStyle,
                                                                [_ctx.$style['sportline-filter__item-title--bundesliga']]: item.customStyle === _unref(SportlineNavigationItemCustomStyle).Bundesliga,
                                                                [_ctx.$style['sportline-filter__item-title--highest-odds']]: item.customStyle === _unref(SportlineNavigationItemCustomStyle).HighestOdds
                                                            })
                                                        }, _toDisplayString(item.title), 3),
                                                        _renderSlot(_ctx.$slots, "afterItem", {
                                                            item: item
                                                        })
                                                    ]),
                                                _: 2
                                            }, 1032, [
                                                "to",
                                                "class",
                                                "title"
                                            ])), [
                                                [
                                                    _directive_data_test,
                                                    {
                                                        el: item.dataTest,
                                                        ['is-active']: item.isActive
                                                    }
                                                ]
                                            ])
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "class",
                                    "onClick"
                                ]))), 128))
                        ]),
                    _: 2
                }, [
                    {
                        name: "pagination-footer",
                        fn: _withCtx(()=>[
                                _createVNode(SwiperNavigationButtons)
                            ]),
                        key: "0"
                    }
                ]), 1536)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineFilter'
                ]
            ]);
        };
    }
});
