import useSupport from 'web/src/modules/support/composables/useSupport';
import { getIntercom } from 'web/src/modules/intercom/composables/utils';
export default function useIntercomEvents() {
    const { isIntercomOptionsListFlow } = useSupport();
    function hideIntercomWidget() {
        onUpdate({
            hide_default_launcher: true
        });
    }
    function openIntercom() {
        openIntercomMenu();
    }
    function showIntercomWidget(isOpenMenu) {
        onUpdate({
            hide_default_launcher: false
        });
        if (isOpenMenu) openIntercomMenu();
    }
    function openIntercomMenu() {
        getIntercom()?.('show');
    }
    function bootIntercomUser(settings) {
        getIntercom()?.('boot', settings);
    }
    function resetIntercom() {
        getIntercom()?.('shutdown');
    }
    function onUpdate(options) {
        getIntercom()?.('update', options);
    }
    function onHide(callback) {
        getIntercom()?.('onHide', callback);
    }
    function onShow(callback) {
        getIntercom()?.('onShow', callback);
    }
    function showSpace(spaceName) {
        getIntercom()?.('showSpace', spaceName);
    }
    return {
        hideIntercomWidget,
        showIntercomWidget,
        openIntercomMenu,
        openIntercom,
        resetIntercom,
        bootIntercomUser,
        onHide,
        onShow,
        showSpace,
        onUpdate
    };
}
