import { SportFamily, SportlineEventPeriodType } from 'web/src/modules/sportline/enums';
/**
 * @deprecated @TODO remove. This logic is used for statistics
 */ const WholeMatchTime = {
    [SportFamily.Soccer]: 90
};
export function getWholeMatchTimeForSportFamily(family) {
    return WholeMatchTime[family] || null;
}
export function getMatchPercentageForTime(family, time) {
    const wholeMatchTime = WholeMatchTime[family];
    if (!wholeMatchTime) return null;
    const percentage = 100 / wholeMatchTime * time;
    return Math.round(10 * percentage) / 10;
}
export function filterMainPeriods(periods) {
    return periods.filter((period)=>period.type === SportlineEventPeriodType.Main);
}
