import { toRef } from 'vue';
import { useUserNavigationStore } from 'web/src/modules/user/submodules/navigation/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function useAccessOptionsListRoutePage() {
    const customerStore = useCustomerDataStore();
    const userPhoneProfileNavigation = toRef(useUserNavigationStore(), 'userPhoneProfileNavigation');
    const personalLink = toRef(customerStore, 'personalLink');
    const PROFILE_ACCESS_OPTIONS = 'profile-access-options';
    const findObjectById = (data)=>data.find((child)=>child.id === PROFILE_ACCESS_OPTIONS) ?? null;
    const result = findObjectById(userPhoneProfileNavigation.value)?.children ?? void 0;
    const filteredList = result?.filter((link)=>{
        if (link.props) {
            if (link.props.isPersonalLink) return true === link.props.isPersonalLink && !!personalLink.value;
            if (link.props.isAndroidAppLink) return true === link.props.isAndroidAppLink && !process.env.VUE_APP_OS_IOS && !process.env.VUE_APP_OS_MACOS;
        }
        return true;
    });
    return {
        filteredList,
        personalLink
    };
}
