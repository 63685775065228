import { computed } from 'vue';
import useSafeTimeBus from 'web/src/modules/core/composables/time-bus/useSafeTimeBus';
import DateTime from 'web/src/utils/DateTime';
export function useVDatepickerDisabledDates(props) {
    const { now } = useSafeTimeBus(60000);
    const { doDisablePastDates } = props;
    const todayStart = computed(()=>DateTime.roundToDayStart(now.value));
    function checkDisabledDate(date) {
        return doDisablePastDates && date.getTime() < todayStart.value;
    }
    return {
        checkDisabledDate
    };
}
