import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { doReadCustomerNotification, getCustomerNotifications, getCustomerNotificationsCount, removeCustomerMessage, RequestOptionsPriority } from '@leon-hub/api-sdk';
import { Timer } from '@leon-hub/utils';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { RefProgramNotificationType } from '@leon-hub/websocket/src/sdk-ws';
import { useFastTrackStore } from 'web/src/modules/fast-track/store';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import { useDesktopModalStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import promisesInBatch from 'web/src/utils/promisesInBatch';
import DateTime from 'web/src/utils/DateTime';
import { useI18n } from 'web/src/modules/i18n/composables';
import { CUSTOMER_NOTIFICATIONS_POSTPONED_STORAGE_KEY } from './constants';
import { createMessageFromCms } from './utils';
const useCustomerNotificationsStore = defineStore('customer-notifications', ()=>{
    const localStorageManager = useLocalStorageManager();
    const apiClient = useGraphqlClient();
    const { isLoggedIn } = useIsLoggedIn();
    const { $translate } = useI18n();
    const postponedModalsShowTimeout = toRef(useSiteConfigStore(), 'postponedModalsShowTimeout');
    const fastTrackStore = useFastTrackStore();
    const fastTrackPreparedMessages = toRef(fastTrackStore, 'preparedMessages');
    const fastTrackMessages = toRef(fastTrackStore, 'messages');
    const fastTrackUnreadCounter = toRef(fastTrackStore, 'unreadCounter');
    const frontNotificationsStore = useFrontNotificationsStore();
    const frontPreparedMessages = toRef(frontNotificationsStore, 'preparedCustomerMessages');
    const frontMessages = toRef(frontNotificationsStore, 'onSiteNotifications');
    const frontUnreadCounter = toRef(frontNotificationsStore, 'unreadCustomerMessagesCounter');
    const refUnreadCounter = toRef(frontNotificationsStore, 'unreadRefMessagesCounter');
    const refFrontNotification = toRef(frontNotificationsStore, 'refFrontNotification');
    const desktopModal = toRef(useDesktopModalStore(), 'desktopModal');
    const unreadCounter = ref(0);
    const messages = ref();
    const isPostponedModalsAllowed = ref(false);
    const isEditing = ref(false);
    const isLoading = ref(false);
    const checkedMessages = ref([]);
    const preparedMessages = computed(()=>messages.value ? messages.value.map((message)=>createMessageFromCms(message)) : void 0);
    const refFrontNotificationMessage = computed(()=>{
        const result = [];
        refFrontNotification.value?.forEach((item)=>{
            const itemType = item?.payload.type;
            // eslint-disable-next-line default-case
            switch(itemType){
                case RefProgramNotificationType.BONUS_ELIGIBILITY_RULES:
                    result.push({
                        key: 'someKey',
                        message: 'someMessage',
                        isModal: false,
                        ...item,
                        title: $translate('WEB2_REFERRAL_PROGRAM_NOTIFICATION_TITLE').value,
                        createdAt: DateTime.toTimestamp(item.timestamp || 0),
                        source: CustomerNotificationMessageSource.REF
                    });
                    break;
                case RefProgramNotificationType.REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION:
                    result.push({
                        key: item.id,
                        message: $translate('WEB2_REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION_MESSAGE').value,
                        isModal: false,
                        ...item,
                        title: $translate('WEB2_REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION_TITLE').value,
                        createdAt: DateTime.toTimestamp(item.timestamp || 0),
                        source: CustomerNotificationMessageSource.TEXT,
                        button: {
                            text: $translate('WEB2_REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION_BUTTON').value,
                            link: '/referral-program',
                            fullWidth: true
                        }
                    });
                    break;
            }
        });
        return result;
    });
    const allMessages = computed(()=>preparedMessages.value && fastTrackPreparedMessages.value && frontPreparedMessages.value ? [
            ...preparedMessages.value,
            ...frontPreparedMessages.value,
            ...fastTrackPreparedMessages.value,
            ...refFrontNotificationMessage.value
        ].sort((a, b)=>b.createdAt - a.createdAt) : void 0);
    const modalMessages = computed(()=>[
            ...fastTrackPreparedMessages.value ?? [],
            ...frontPreparedMessages.value ?? [],
            ...refFrontNotificationMessage.value ?? []
        ].filter((item)=>item.isModal && !item.isRead).sort((a, b)=>b.createdAt - a.createdAt));
    const checkedCmsMessages = computed(()=>messages.value?.filter((param)=>{
            let { id } = param;
            return checkedMessages.value.some((item)=>item.id === id && item.source === CustomerNotificationMessageSource.CMS);
        }) ?? []);
    const unreadTotalCounter = computed(()=>unreadCounter.value + fastTrackUnreadCounter.value + frontUnreadCounter.value + refUnreadCounter.value);
    const isCustomerModalsAllowed = computed(()=>!desktopModal.value && isPostponedModalsAllowed.value);
    const latestUnreadMessage = computed(()=>allMessages.value?.find((item)=>!item.isRead && !item.isModal));
    function calculateUnreadCount() {
        unreadCounter.value = messages.value?.filter((item)=>0 === item.status).length || 0;
    }
    function toggleCheck(message) {
        const checkedMessage = checkedMessages.value.find((item)=>item.id === message.id && item.source === message.source);
        if (checkedMessage) checkedMessages.value = checkedMessages.value.filter((item)=>item.id !== message.id || item.source !== message.source);
        else checkedMessages.value.push(message);
    }
    async function loadMessages() {
        if (isLoggedIn.value) {
            isLoading.value = true;
            const { allOperatorMessages } = await getCustomerNotifications(apiClient, (node)=>node.queries.customer.listOperatorMessages, {
                options: {}
            });
            messages.value = allOperatorMessages;
            calculateUnreadCount();
            if (!allOperatorMessages.length) stopEditing();
            await fastTrackStore.fetchMessages();
            await frontNotificationsStore.getAllCustomerNotifications();
            isLoading.value = false;
        }
    }
    async function loadUnreadCount(silent) {
        if (isLoggedIn.value) {
            const response = await getCustomerNotificationsCount(apiClient, (node)=>node.queries.customer.countUnreadMessages, {
                options: {}
            }, {
                silent,
                priority: RequestOptionsPriority.LOW
            });
            if (response) unreadCounter.value = response.unreadMessagesCount;
        }
    }
    function setStatusRead(id) {
        const message = messages.value?.find((item)=>item.id === id);
        if (message) message.status = 1;
    }
    function hideModal() {
        // eslint-disable-next-line max-len
        localStorageManager.setItem(CUSTOMER_NOTIFICATIONS_POSTPONED_STORAGE_KEY, unreadCounter.value.toString());
    }
    function resetModal() {
        localStorageManager.removeItem(CUSTOMER_NOTIFICATIONS_POSTPONED_STORAGE_KEY);
    }
    function recalculateUnreadCounter() {
        calculateUnreadCount();
        if (unreadCounter.value > 0) hideModal();
        else resetModal();
    }
    async function setRead(message) {
        if (message.source === CustomerNotificationMessageSource.CMS) {
            await doReadCustomerNotification(apiClient, (node)=>node.mutations.customer.updateMessageStatus, {
                options: {
                    messageId: message.id.toString()
                }
            });
            setStatusRead(message.id);
        } else if (message.source === CustomerNotificationMessageSource.FAST_TRACK) await fastTrackStore.setMessageAsRead(message);
        else if (message.source === CustomerNotificationMessageSource.ALCR || message.source === CustomerNotificationMessageSource.REF || message.source === CustomerNotificationMessageSource.TEXT) await frontNotificationsStore.markNotification({
            id: message.id
        });
        recalculateUnreadCounter();
    }
    function setClicked(message) {
        if (message.source === CustomerNotificationMessageSource.ALCR) frontNotificationsStore.markAsClicked({
            id: message.id
        });
    }
    async function remove(id) {
        return removeCustomerMessage(apiClient, (node)=>node.mutations.customer.messages.deleteMessage, {
            options: {
                messageId: id
            }
        });
    }
    async function deleteMultiple(options) {
        const failedMessages = [];
        if (options.messages?.length) {
            let result = [];
            if (!options.isAllDeleting) {
                const currentMessages = messages.value;
                if (currentMessages?.length) {
                    const removableIds = options.messages.map((param)=>{
                        let { id } = param;
                        return id;
                    });
                    result = currentMessages.filter((param)=>{
                        let { id } = param;
                        return !removableIds.includes(id);
                    });
                }
            }
            messages.value = result;
            recalculateUnreadCounter();
            await promisesInBatch(options.messages, (message)=>remove(message.id).catch(()=>{
                    failedMessages.push(message);
                }));
            if (failedMessages.length > 0) {
                messages.value = options.isAllDeleting ? failedMessages : [
                    ...messages.value || [],
                    ...failedMessages
                ];
                recalculateUnreadCounter();
            }
        }
        return failedMessages.map((item)=>({
                id: item.id,
                source: CustomerNotificationMessageSource.CMS
            }));
    }
    async function deleteChecked() {
        const fastTrackCheckedMessages = fastTrackMessages.value?.filter((param)=>{
            let { MessageId } = param;
            return checkedMessages.value.some((item)=>item.id === MessageId && item.source === CustomerNotificationMessageSource.FAST_TRACK);
        }) ?? [];
        const alcrCheckedMessages = frontMessages.value?.filter((param)=>{
            let { id } = param;
            return checkedMessages.value.some((item)=>item.id === id && item.source === CustomerNotificationMessageSource.ALCR);
        }) ?? [];
        const refCheckedMessages = refFrontNotification.value?.filter((param)=>{
            let { id } = param;
            return checkedMessages.value.some((item)=>item.id === id);
        }) ?? [];
        const innerMessages = [
            ...checkedCmsMessages.value
        ];
        checkedMessages.value = [];
        const results = await Promise.allSettled([
            fastTrackStore.deleteMultiple({
                messagesArray: fastTrackCheckedMessages
            }),
            frontNotificationsStore.deleteMultiple({
                messages: alcrCheckedMessages
            }),
            frontNotificationsStore.deleteMultiple({
                messages: refCheckedMessages
            }),
            deleteMultiple({
                messages: innerMessages
            })
        ]);
        return results.flatMap((v)=>'fulfilled' === v.status ? v.value : []);
    }
    async function deleteAll() {
        const results = await Promise.allSettled([
            fastTrackStore.deleteMultiple({
                messagesArray: fastTrackMessages.value,
                isAllDeleting: true
            }),
            frontNotificationsStore.deleteMultiple({
                messages: frontMessages.value
            }),
            frontNotificationsStore.deleteMultiple({
                messages: refFrontNotification.value
            }),
            deleteMultiple({
                messages: messages.value,
                isAllDeleting: true
            })
        ]);
        // eslint-disable-next-line sonarjs/no-identical-functions
        return results.flatMap((v)=>'fulfilled' === v.status ? v.value : []);
    }
    function stopEditing() {
        isEditing.value = false;
    }
    function getPostponedUnreadCounter() {
        return Number(localStorageManager.getItem(CUSTOMER_NOTIFICATIONS_POSTPONED_STORAGE_KEY) || 0);
    }
    let postponedModalsTimeout = 0;
    function initPostponedModalsShow() {
        if (postponedModalsTimeout) {
            Timer.clearTimeout(postponedModalsTimeout);
            postponedModalsTimeout = 0;
        }
        if (isLoggedIn.value) postponedModalsTimeout = Timer.setTimeout(()=>{
            isPostponedModalsAllowed.value = true;
            postponedModalsTimeout = 0;
        }, postponedModalsShowTimeout.value);
        else isPostponedModalsAllowed.value = false;
    }
    function setEditing(value) {
        isEditing.value = value;
    }
    function setChecked(message) {
        checkedMessages.value = [
            message
        ];
    }
    function clearChecked() {
        checkedMessages.value = [];
    }
    watch(isLoggedIn, (newValue)=>{
        if (newValue) {
            loadUnreadCount();
            resetModal();
            initPostponedModalsShow();
        } else {
            resetModal();
            initPostponedModalsShow();
            unreadCounter.value = 0;
        }
    }, {
        immediate: true
    });
    return {
        isEditing,
        allMessages,
        modalMessages,
        unreadTotalCounter,
        unreadCounter,
        isCustomerModalsAllowed,
        latestUnreadMessage,
        checkedMessages,
        refFrontNotificationMessage,
        isLoading,
        setClicked,
        setRead,
        getPostponedUnreadCounter,
        hideModal,
        loadMessages,
        setEditing,
        stopEditing,
        deleteAll,
        deleteChecked,
        toggleCheck,
        setChecked,
        clearChecked
    };
});
export default useCustomerNotificationsStore;
