import { getBonusMatrix as getSdkBonusMatrix } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function fetchSdkBonusMatrix(param) {
    let { bonusId, bonusCampaignType } = param;
    const apiClient = useGraphqlClient();
    return getSdkBonusMatrix(apiClient, (node)=>node.queries.bonuses.getBonusMatrix, {
        options: {
            bonusId,
            bonusCampaignType
        }
    });
}
