import { InputEventType } from '../../enums';
export default function useCardExpirationDateInput(emit) {
    const maskOptions = {
        pattern: '99/99',
        placeholder: '0'
    };
    const formatEventValue = (originalEvent)=>{
        const { name, value } = originalEvent.target;
        const formattedValue = value.replace(/^(\d{2})/, '$1/');
        return {
            target: {
                name,
                value: formattedValue
            }
        };
    };
    const onFocus = (event)=>{
        emit(InputEventType.FOCUS, formatEventValue(event));
    };
    const onBlur = (event)=>{
        emit(InputEventType.BLUR, formatEventValue(event));
    };
    const onChange = (event)=>{
        emit(InputEventType.CHANGE, formatEventValue(event));
    };
    const onInput = (event)=>{
        emit(InputEventType.INPUT, formatEventValue(event));
    };
    return {
        maskOptions,
        onFocus,
        onBlur,
        onInput,
        onChange
    };
}
