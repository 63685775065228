import { useI18n } from 'web/src/modules/i18n/composables';
export default function getPageDescription(props) {
    const { $translate } = useI18n();
    if (props.isNewTel) {
        if (props.isPin) return $translate('WEB2_PIN_INPUT_NEW_TEL').value;
        return $translate('WEB2_INPUT_NEW_TEL').value;
    }
    if (props.isPin) return $translate('WEB2_PIN_INPUT_SMS_CODE').value;
    return $translate('WEB2_INPUT_SMS_CODE').value;
}
