import { computed } from 'vue';
import { getHintLinkComponent, getHintLinkProps, isNativeAnchor } from './utils';
export default function useInputHint(props) {
    const hintErrorText = computed(()=>props.error || props.hint || '');
    const hintErrorTextRight = computed(()=>props.errorRight || props.hintRight || '');
    const isLeftLinkNative = computed(()=>isNativeAnchor(props.hintLink));
    const isRightLinkNative = computed(()=>isNativeAnchor(props.hintLinkRight));
    const hintLinkComponent = computed(()=>getHintLinkComponent(isLeftLinkNative.value));
    const hintLinkRightComponent = computed(()=>getHintLinkComponent(isRightLinkNative.value));
    const hintLinkLeftProperties = computed(()=>getHintLinkProps(isLeftLinkNative.value, props.hintLink));
    const hintLinkRightProperties = computed(()=>getHintLinkProps(isRightLinkNative.value, props.hintLinkRight));
    return {
        hintErrorText,
        hintErrorTextRight,
        hintLinkComponent,
        hintLinkRightComponent,
        hintLinkLeftProperties,
        hintLinkRightProperties
    };
}
