import isSeoMetaParametersConfig from 'web/src/modules/seo/guards/isSeoMetaParametersConfig';
import isEqualSeoMetaParameterValue from 'web/src/modules/seo/utils/isEqualSeoMetaParameterValue';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function mergeSeoMetaParameters(metaParameters, dataFromComponent, duplicatedValuesReaction) {
    const computedComponentMeta = {};
    for (const key of Object.keys(dataFromComponent)){
        const config = dataFromComponent[key];
        let value;
        let unique = true;
        let mergeMethod;
        if (isSeoMetaParametersConfig(config)) {
            value = config.value;
            unique = config.unique ?? true;
            if (config.merge) mergeMethod = config.merge;
        } else value = config;
        if (void 0 !== value) {
            if (unique && key in metaParameters && !isEqualSeoMetaParameterValue(metaParameters[key], value)) {
                if (mergeMethod) value = mergeMethod(metaParameters[key], value);
                else duplicatedValuesReaction(key, metaParameters[key], value);
            }
            computedComponentMeta[key] = value;
        }
    }
    return {
        ...metaParameters,
        ...computedComponentMeta
    };
}
