import { computed, ref } from 'vue';
import { Timer } from '@leon-hub/utils';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { isDropListOptionElementRef } from '../guards';
import { getNextPreselectedIndex } from '../utils';
import { Keys } from '../constants/keys';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ export function useDropListEventHandlers(props, param) {
    let { setPreselected, preselectedId, allFlatOptions, focusOnSearch, focusOnDropdownButton, onOpen, onClose, onPreselect, isDropdownShown, lastRenderedCount, increasePaginationCount } = param;
    const optionRefs = ref([
        []
    ]);
    const setOptionRefs = (option, groupIndex, index)=>{
        if (option) {
            isDropListOptionElementRef(option);
            if (!optionRefs.value[groupIndex]) optionRefs.value[groupIndex] = [];
            optionRefs.value[groupIndex][index] = option;
        }
    };
    const flatOptionRefs = computed(()=>optionRefs.value.flat());
    const scrollbarRef = ref();
    const primaryButtonRef = ref();
    const secondaryButtonRef = ref();
    const isPrimaryButtonFocused = ref(false);
    const isSecondaryButtonFocused = ref(false);
    const onPrimaryButtonFocus = ()=>{
        setPreselected('');
        isPrimaryButtonFocused.value = true;
    };
    const onSecondaryButtonFocus = ()=>{
        setPreselected('');
        isSecondaryButtonFocused.value = true;
    };
    const onPrimaryButtonBlur = ()=>{
        isPrimaryButtonFocused.value = true;
    };
    const onSecondaryButtonBlur = ()=>{
        isSecondaryButtonFocused.value = false;
    };
    let isScrollingIntoView = false;
    let scrollTimer = 0;
    const onOptionHover = (value)=>{
        if (!isScrollingIntoView) setPreselected(value);
    };
    const scrollToPreselected = (index, oldIndex)=>{
        if (index < 0) return;
        Timer.clearTimeout(scrollTimer);
        const safeIndex = flatOptionRefs.value[index] ? index : 0;
        const button = flatOptionRefs.value[safeIndex];
        isScrollingIntoView = true;
        scrollTimer = Timer.setTimeout(async ()=>{
            scrollbarRef.value?.scrollToElement(button, {
                onlyIfNeeded: true,
                alignToTop: oldIndex > safeIndex
            });
            await nextAnimationFrame();
            isScrollingIntoView = false;
        }, 0);
    };
    const focusOnFirstButton = ()=>{
        if (primaryButtonRef.value) primaryButtonRef.value.focus();
        else if (secondaryButtonRef.value) secondaryButtonRef.value.focus();
    };
    const setNextPreselected = function() {
        let isMovingForward = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
        const currentIndex = preselectedId.value ? allFlatOptions.value.findIndex((item)=>item.value === preselectedId.value) : -1;
        if (-1 === currentIndex && !isMovingForward) return;
        const lastIndex = allFlatOptions.value.length - 1;
        const nextIndex = getNextPreselectedIndex(isMovingForward, currentIndex, lastIndex);
        if (nextIndex + 1 === lastRenderedCount.value) increasePaginationCount();
        if (-1 === nextIndex) {
            if (props.searchEnabled) focusOnSearch();
            else scrollbarRef.value?.scrollTop();
            return;
        }
        if (nextIndex > lastIndex) {
            if (props.primaryButtonLabel || props.secondaryButtonLabel) focusOnFirstButton();
            else scrollbarRef.value?.scrollDown();
            return;
        }
        const nextPreselectedId = allFlatOptions.value[nextIndex]?.value ?? '';
        setPreselected(nextPreselectedId);
        scrollToPreselected(nextIndex, currentIndex);
    };
    const onInputKeydown = (event)=>{
        if (event.code === Keys.ArrowDown || event.code === Keys.Tab) {
            event.preventDefault();
            setNextPreselected(true);
            focusOnDropdownButton();
            return;
        }
        if (event.code === Keys.Enter) {
            event.preventDefault();
            return;
        }
        if (event.code === Keys.Escape) onClose();
    };
    const onDropdownOptionKeydown = (event)=>{
        const { code } = event;
        if (isDropdownShown.value) {
            event.preventDefault();
            if (code === Keys.Enter || code === Keys.Space) onPreselect();
            if (code === Keys.ArrowUp || code === Keys.ArrowDown || code === Keys.Tab) {
                const isMovingForward = code !== Keys.ArrowUp;
                setNextPreselected(isMovingForward);
            }
            if (code === Keys.Escape) onClose();
        } else if (code === Keys.ArrowUp || code === Keys.ArrowDown) {
            event.preventDefault();
            onOpen();
        }
    };
    const onActionButtonKeydown = (event)=>{
        const { code } = event;
        if (code !== Keys.Tab && code !== Keys.ArrowUp && code !== Keys.ArrowLeft && code !== Keys.ArrowRight && code !== Keys.ArrowDown) return;
        event.preventDefault();
        if (isPrimaryButtonFocused.value && secondaryButtonRef.value && (code === Keys.Tab || code === Keys.ArrowDown || code === Keys.ArrowRight)) {
            secondaryButtonRef.value.focus();
            return;
        }
        if (isSecondaryButtonFocused.value && primaryButtonRef.value && (code === Keys.ArrowLeft || code === Keys.ArrowUp)) {
            primaryButtonRef.value.focus();
            return;
        }
        if (code === Keys.ArrowUp) {
            focusOnDropdownButton();
            setPreselected(allFlatOptions.value[allFlatOptions.value.length - 1].value);
        }
        if (code === Keys.Tab) focusOnDropdownButton();
    };
    return {
        setOptionRefs,
        scrollbarRef,
        onOptionHover,
        onInputKeydown,
        onDropdownOptionKeydown,
        primaryButtonRef,
        secondaryButtonRef,
        onActionButtonKeydown,
        onPrimaryButtonFocus,
        onPrimaryButtonBlur,
        onSecondaryButtonFocus,
        onSecondaryButtonBlur
    };
}
