import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import useWebPushNotificationsStore from 'web/src/modules/push-notifications/store/useWebPushNotificationsStore';
export default function useWebPushModal() {
    const { $translate } = useI18n();
    const pushNotificationsStore = useWebPushNotificationsStore();
    const modalProps = computed(()=>({
            title: $translate('WEB2_PUSH_NOTIFICATIONS_MODAL_TITLE').value,
            buttons: [
                {
                    label: $translate('WEB2_PUSH_NOTIFICATIONS_BTN_SUBSCRIBE').value,
                    fullWidth: true
                },
                {
                    kind: ButtonKind.TRANSPARENT,
                    label: $translate('WEB2_PUSH_NOTIFICATIONS_BTN_POSTPONE').value,
                    fullWidth: true
                }
            ]
        }));
    function onClose() {
        pushNotificationsStore.postpone();
    }
    function onButtonClick(index) {
        if (0 === index) pushNotificationsStore.subscribe();
        if (1 === index) onClose();
    }
    return {
        modalProps,
        onButtonClick,
        onClose
    };
}
