import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let ColorDepthComponent = class ColorDepthComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result([
            window.screen.colorDepth || ComponentStatus.NotAvailable
        ]);
    }
    constructor(){
        super(ComponentKey.ColorDepth);
    }
};
export { ColorDepthComponent as default };
