import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useTheme } from 'web/src/modules/theme/composables';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { getLobbyLocation } from 'web/src/modules/egs/utils';
export default function useLobbyEgsGroupItem(props) {
    const route = useRoute();
    const router = useRouter();
    const src = computed(()=>{
        if (!props.item) return '';
        const { isLight } = useTheme();
        const link = isLight.value ? props.item.iconUrls.light : props.item.iconUrls.dark;
        return link || '';
    });
    const ntBadgeProperties = computed(()=>({
            kind: BadgeKind.SQUARE_ERROR,
            label: props.item?.ntBadge || ''
        }));
    const href = computed(()=>props.item ? router.resolve(getLobbyLocation(route, props.item.url)).href : void 0);
    return {
        src,
        ntBadgeProperties,
        href
    };
}
