import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let DeviceMemoryComponent = class DeviceMemoryComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result([
            window.navigator.deviceMemory || ComponentStatus.NotAvailable
        ]);
    }
    constructor(){
        super(ComponentKey.DeviceMemory);
    }
};
export { DeviceMemoryComponent as default };
