export function deleteSingleBannerInGroups(param) {
    let { bannerGroups, groupId, bannerId } = param;
    const matchedGroupIndex = bannerGroups.findIndex((group)=>group.id === groupId);
    if (matchedGroupIndex < 0) return bannerGroups;
    let matchedBannersGroup = [
        ...bannerGroups[matchedGroupIndex].banners
    ];
    const matchedBannersIndex = matchedBannersGroup.findIndex((banner)=>banner.bannerId === bannerId);
    if (matchedBannersIndex < 0) return bannerGroups;
    matchedBannersGroup = [
        ...matchedBannersGroup.slice(0, matchedBannersIndex),
        ...matchedBannersGroup.slice(matchedBannersIndex + 1)
    ];
    const result = [
        ...bannerGroups
    ];
    result[matchedGroupIndex].banners = matchedBannersGroup;
    return result;
}
