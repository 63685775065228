import { computed, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { BetLine, BetType, OutcomeState } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { currentScore, defaultDateTimeFormat, formatInnings } from 'web/src/modules/profile/submodules/customer-history/utils';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { createSportEventLiveStatusInfo } from 'web/src/modules/sportline/utils/rest/build';
// eslint-disable-next-line max-len
export default function useCustomerHistoryDetailsScoreBoard(props) {
    const { $translate } = useI18n();
    const router = useRouter();
    const isTimeReversedIndicatorAvailable = toRef(useSportlineSettingsStore(), 'isTimeReversedIndicatorAvailable');
    const matchProgress = ref();
    const { gameScoreProgress, isStaticLiveProgress, isTimeReversed, staticDirectTimeProgressLabel, staticReversedTimeProgressLabel, stage } = useLiveStatus(matchProgress);
    const isSingle = computed(()=>props.transaction?.betType === BetType.SINGLE);
    const isExpress = computed(()=>props.transaction?.betType === BetType.EXPRESS);
    const isSystem = computed(()=>props.transaction?.betType === BetType.SYSTEM);
    const isLive = computed(()=>props.transaction.outcomeState === OutcomeState.IN_PLAY);
    const isVirtual = computed(()=>props.transaction.betLine === BetLine.VSPORTS);
    const competitors = computed(()=>{
        const { betEventParts } = props.transaction;
        return betEventParts?.length === 2 ? `${betEventParts[0]} - ${betEventParts[1]}` : '';
    });
    const betKindBtrParams = computed(()=>({
            COUNT: `${props.transaction.betItems?.length || props.transaction?.countItems}`
        }));
    const betKind = computed(()=>{
        let text = '';
        if (isExpress.value) text = $translate('WEB2_BET_EXPRESS_MULTIPLICITY', betKindBtrParams).value;
        if (isSystem.value) text = props.transaction.combiStatusText || '';
        return text;
    });
    const header = computed(()=>props.transaction.betLeague || '');
    const mainScore = computed(()=>{
        const { liveStatus, matchResult } = props.transaction;
        if (matchResult?.score) return matchResult.score;
        if (liveStatus?.score) return currentScore(liveStatus.score);
        return null;
    });
    const showScoreDetails = computed(()=>{
        if (!mainScore.value) return false;
        const { home, away } = mainScore.value;
        return !!home && !!away;
    });
    const homeMainScore = computed(()=>mainScore.value?.home ? formatInnings(mainScore.value.home) : '');
    const awayMainScore = computed(()=>mainScore.value?.away ? formatInnings(mainScore.value.away) : '');
    const additionalScore = computed(()=>{
        const { matchResult } = props.transaction;
        if (matchResult?.penaltyScore) return matchResult.penaltyScore;
        if (matchResult?.extraTimeMatchScore) return matchResult.extraTimeMatchScore;
        return null;
    });
    const additionalScoreBadgeProperties = computed(()=>{
        const { matchResult } = props.transaction;
        let label = '';
        if (matchResult?.penaltyScore) label = $translate('WEB2_BET_PENALTY').value;
        if (matchResult?.extraTimeMatchScore) label = $translate('WEB2_BET_ET_MATCH').value;
        return {
            label,
            kind: BadgeKind.SQUARE_HIGHLIGHT
        };
    });
    const currentPhaseScore = computed(()=>{
        const { liveStatus, matchResult } = props.transaction;
        if (matchResult?.phaseScores) return matchResult.phaseScores.map((item)=>`${item.score.home || ''}:${item.score.away || ''}`).join('; ');
        return liveStatus?.setScores || '';
    });
    const statusBadgeProperties = computed(()=>{
        let label;
        switch(props.transaction.outcomeState){
            case OutcomeState.IN_PLAY:
                label = 'LIVE';
                break;
            case OutcomeState.PRE_GAME:
                label = $translate('WEB2_BET_START').value;
                break;
            default:
                label = $translate('WEB2_BET_ENDED').value;
                break;
        }
        return {
            label,
            kind: BadgeKind.SQUARE
        };
    });
    const kickoff = computed(()=>defaultDateTimeFormat(props.transaction.kickoff) || '');
    const eventUrl = computed(()=>{
        const { eventUrl: urlTransaction } = props.transaction;
        if (!urlTransaction) return null;
        return {
            id: `${urlTransaction.eventId}`,
            leagueUrlName: urlTransaction?.league || '',
            regionUrlName: urlTransaction?.region || '',
            sportFamily: urlTransaction?.sport || '',
            urlName: urlTransaction?.event || ''
        };
    });
    function goToEvent() {
        if (eventUrl.value && !isVirtual.value) router.push({
            name: RouteName.SPORT_EVENT_DETAILS,
            params: {
                ...eventUrl.value,
                // LEONWEB-5650 seems this logic is deprecated,
                // because the back button always returns to the previous page if it is possible
                isForcedBackLink: '1'
            }
        });
    }
    watch(()=>props.transaction.liveStatus, (liveStatus)=>{
        if (liveStatus) matchProgress.value = createSportEventLiveStatusInfo(liveStatus).matchProgress;
    }, {
        deep: true,
        immediate: true
    });
    return {
        isSingle,
        isExpress,
        isLive,
        competitors,
        betKind,
        header,
        showScoreDetails,
        homeMainScore,
        awayMainScore,
        additionalScore,
        additionalScoreBadgeProperties,
        currentPhaseScore,
        statusBadgeProperties,
        kickoff,
        goToEvent,
        gameScoreProgress,
        isStaticLiveProgress,
        isTimeReversed,
        staticDirectTimeProgressLabel,
        staticReversedTimeProgressLabel,
        stage,
        isTimeReversedIndicatorAvailable
    };
}
