import { computed } from 'vue';
import hasSlot from 'web/src/utils/vue/hasSlot';
import { getBaseClassNames, getPropsForIcon } from './utils';
export default function useVHintBlock(props, slots) {
    const iconProperties = computed(()=>getPropsForIcon(props));
    const baseClassNames = computed(()=>getBaseClassNames(props, hasSlot(slots, 'title')));
    return {
        iconProperties,
        baseClassNames
    };
}
