import { isUndefined } from '@leon-hub/guards';
import { SportlineEventPeriodType } from 'web/src/modules/sportline/enums';
import LiveStatusParseStrategy from './LiveStatusParseStrategy';
import FullProgressLiveStatusParseStrategy from './FullProgressLiveStatusParseStrategy';
export function createSportEventLiveStatusInfo(liveStatus, options) {
    const strategy = options?.useFullProgress ? new FullProgressLiveStatusParseStrategy(liveStatus) : new LiveStatusParseStrategy(liveStatus);
    const matchProgress = {
        stage: strategy.getStage(),
        totalScore: strategy.getTotalScore(),
        totalScoreDetails: strategy.getTotalScoreDetails(),
        innings: strategy.getInnings(),
        penaltyScore: strategy.getPenaltyScore(),
        currentSetScore: strategy.getCurrentSetScore(),
        mainTime: strategy.getMainTime(),
        additionalTime: strategy.getAdditionalTime(),
        timeDirection: strategy.getTimeDirection()
    };
    return {
        matchProgress
    };
}
export function createSportlineEventPeriodsInfo(liveStatus) {
    const { periodDurationMin, numberOfPeriods, additionalPeriodDurationMin, numberOfAdditionalPeriods } = liveStatus;
    const mainPeriods = periodDurationMin ? Array.from({
        length: numberOfPeriods ?? 0
    }, (_, index)=>({
            type: SportlineEventPeriodType.Main,
            number: index + 1,
            duration: periodDurationMin
        })) : [];
    const additionalPeriods = additionalPeriodDurationMin ? Array.from({
        length: numberOfAdditionalPeriods ?? 0
    }, (_, index)=>({
            type: SportlineEventPeriodType.Additional,
            number: index + 1,
            duration: additionalPeriodDurationMin
        })) : [];
    if (!mainPeriods.length && !additionalPeriods.length) return {};
    return {
        periods: [
            ...mainPeriods,
            ...additionalPeriods
        ]
    };
}
function createSportlineEventIncidentStatistics(statistics) {
    if (!statistics) return {};
    return {
        corners: statistics.corners,
        redCards: statistics.redCards,
        yellowCards: statistics.yellowCards,
        yellowRedCards: statistics.yellowRedCards,
        currentSuspensionCount: statistics.currentSuspensionCount
    };
}
export function createSportlineEventIncidentStatisticsInfo(liveStatus) {
    const { homeStatistics, awayStatistics } = liveStatus;
    const host = createSportlineEventIncidentStatistics(homeStatistics);
    const guest = createSportlineEventIncidentStatistics(awayStatistics);
    if (!isUndefined(host.currentSuspensionCount) || !isUndefined(guest.currentSuspensionCount)) {
        const hostSuspensions = host.currentSuspensionCount ?? 0;
        const guestSuspensions = guest.currentSuspensionCount ?? 0;
        if (guestSuspensions > hostSuspensions) host.powerPlay = guestSuspensions - hostSuspensions;
        else if (hostSuspensions > guestSuspensions) guest.powerPlay = hostSuspensions - guestSuspensions;
    }
    return {
        incidentStatistics: {
            host,
            guest
        }
    };
}
