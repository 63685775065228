import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
export default function useScrollIntoView() {
    const bus = useEventsBus();
    const scrollIntoView = (element)=>{
        bus.emit(BusEvent.MODAL_SCROLL_TO_ELEMENT, {
            element,
            onlyIfNeeded: true
        });
    };
    return {
        scrollIntoView
    };
}
