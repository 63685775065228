import { _ as _define_property } from "@swc/helpers/_/_define_property";
import TimeLeft from './TimeLeft';
let Countdown = class Countdown {
    getTimeLeft() {
        const daysLeft = TimeLeft.days(this.value, this.now);
        const hoursLeft = TimeLeft.hours(this.value, this.now);
        const minutesLeft = TimeLeft.minutes(this.value, this.now);
        const secondsLeft = TimeLeft.seconds(this.value, this.now);
        return {
            days: +daysLeft,
            hours: +hoursLeft,
            minutes: +minutesLeft,
            seconds: +secondsLeft
        };
    }
    getDisplayedTimeLeft() {
        let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
            displaySecondsOn: {
                maxMinutes: 5
            }
        };
        const daysLeft = TimeLeft.days(this.value, this.now);
        const hoursLeft = TimeLeft.hours(this.value, this.now);
        const minutesLeft = TimeLeft.minutes(this.value, this.now);
        const secondsLeft = TimeLeft.seconds(this.value, this.now);
        const displaySecondsOnMaxMinutes = options.displaySecondsOn?.maxMinutes ?? false;
        const showSeconds = false === displaySecondsOnMaxMinutes || 0 === +daysLeft && 0 === +hoursLeft && +minutesLeft < displaySecondsOnMaxMinutes;
        return {
            days: daysLeft.toString(),
            hours: hoursLeft.toString(),
            minutes: minutesLeft.toString(),
            seconds: !!showSeconds && secondsLeft.toString()
        };
    }
    constructor(value, now = Date.now()){
        _define_property(this, "value", void 0);
        _define_property(this, "now", void 0);
        this.value = value;
        this.now = now;
    }
};
/** @deprecated use useCountdown instead */ export { Countdown as default };
