import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
export default function createMessageFromFastTrack(notification) {
    return {
        id: notification.MessageId,
        key: `${notification.MessageId}-${CustomerNotificationMessageSource.FAST_TRACK}`,
        title: notification.Title,
        message: notification.Message,
        createdAt: Date.parse(`${notification.Date.replace(/-/g, '/')} GMT`),
        isRead: notification.IsRead,
        isModal: 'silent' !== notification.DisplayType,
        source: CustomerNotificationMessageSource.FAST_TRACK,
        image: notification.ImageUrl,
        button: notification.CTAButtonLink && notification.CTAButtonText ? {
            text: notification.CTAButtonText,
            link: notification.CTAButtonLink
        } : void 0,
        button2: notification.CTAButton2Text ? {
            text: notification.CTAButton2Text,
            link: notification.CTAButton2Link || ''
        } : void 0
    };
}
