import { computed } from 'vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import { getHighlightedText } from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/composables/utils';
// eslint-disable-next-line import/no-dynamic-require
const noimageSrc = require("web/src/assets/images/li/default/noimage-square.png");
export default function useCasinoSearchItem(props, emits) {
    const formattedName = computed(()=>getHighlightedText(props.name, props.searchText));
    const formattedGroup = computed(()=>getHighlightedText(props.group, props.searchText));
    const ntBadgeProperties = computed(()=>({
            kind: BadgeKind.SQUARE_ERROR,
            label: props.ntBadge
        }));
    const formattedCategories = computed(()=>props.categories?.filter((item)=>item !== props.group && !!item));
    const imageProps = computed(()=>({
            isLazy: true,
            showLoader: true,
            objectFit: ObjectFitOption.COVER,
            ...props.image ?? {
                src: noimageSrc
            }
        }));
    function onClick() {
        emits('click');
    }
    return {
        onClick,
        imageProps,
        ntBadgeProperties,
        formattedName,
        formattedGroup,
        formattedCategories
    };
}
