import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getActiveOrAvailableCampaigns, getRounds, RequestOptionsPriority } from '@leon-hub/api-sdk';
import { doCalculateRound } from '@leon-hub/api-sdk/src/sdk/sdk';
import { logger } from '@leon-hub/logging';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { GqlApiAccessDeniedError } from '@leon-hub/api';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useWebSockets } from 'web/src/modules/core/composables';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
const useFortuneWheelStore = defineStore('fortune-wheel', ()=>{
    const apiClient = useGraphqlClient();
    const { subscribeAuthorized } = useWebSockets();
    const { isWheelNotificationEnabled } = useWebSocketsConfig();
    const { isLoggedIn } = useIsLoggedIn();
    const salt = '-20503cfe';
    // state
    const roundState = ref(null);
    const roundWin = ref(0);
    const roundWinIndex = ref(0);
    const roundWinDetails = ref(null);
    const campaign = ref(null);
    const fortuneWheelId = ref(0);
    const campaignState = ref('');
    const categoryId = ref('');
    const actionUrl = ref('');
    const showNotification = ref(false);
    const isAdditionalRoundActive = ref(false);
    const showSpinButton = ref(false);
    const isFortuneWheelAvailable = ref(true);
    const socketPollingTimeout = ref(5000);
    // getter
    const roundKey = computed(()=>`${roundState.value?.key}${salt}` || '');
    // actions
    async function fetchActiveOrAvailableCampaigns() {
        if (!isLoggedIn.value) return;
        try {
            const response = await getActiveOrAvailableCampaigns(apiClient, (node)=>node.queries.promotions.getActiveOrAvailableCampaigns, void 0, {
                silent: true,
                priority: RequestOptionsPriority.LOW
            });
            const { campaigns } = response;
            campaign.value = [
                ...campaigns
            ];
            const campaignComputed = campaign.value.at(0);
            if (campaignComputed) {
                setFortuneWheelId(campaignComputed.campaignId);
                setCampaignState(campaignComputed.state);
                setActionUrl(campaignComputed.actionUrl);
                setCategoryId(campaignComputed.categoryId);
                setShowNotificationStatus(campaignComputed.showNotification);
            }
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError && !(error instanceof GqlApiAccessDeniedError)) logger.error('Something goes wrong with active or available campaigns', error);
        }
    }
    async function fetchRounds(campaignId) {
        try {
            const response = await getRounds(apiClient, (node)=>node.queries.bonusGame.getRounds, {
                options: {
                    campaignId
                }
            }, {
                silent: true
            });
            roundState.value = {
                ...response
            };
            setFortuneWheelAvailable(roundState.value.round.active);
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) logger.error('Something goes wrong with fetch rounds', error);
        }
    }
    async function calculateRound(campaignId, key) {
        try {
            const response = await doCalculateRound(apiClient, (node)=>node.mutations.bonusGame.calculateRound, {
                options: {
                    campaignId,
                    key
                }
            });
            if (response.rewardId) {
                roundWin.value = response.rewardId;
                const rewards = roundState.value?.round.rewards ?? [];
                roundWinIndex.value = rewards.findIndex((reward)=>reward.rewardId === roundWin.value);
                roundWinDetails.value = response.details;
            }
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) logger.error('Something goes wrong with calculate round', error);
        }
    }
    function setFortuneWheelId(id) {
        fortuneWheelId.value = id;
    }
    function setCampaignState(state) {
        campaignState.value = state;
    }
    function setActionUrl(url) {
        actionUrl.value = url;
    }
    function setCategoryId(id) {
        categoryId.value = id;
    }
    function setShowNotificationStatus(status) {
        showNotification.value = status;
    }
    function setFortuneWheelAvailable(value) {
        isFortuneWheelAvailable.value = value;
    }
    function setShowSpinButton(value) {
        showSpinButton.value = value;
    }
    function subscribeOnBonusGameRoundEvent() {
        subscribeAuthorized({
            method: 'onBonusGameRoundEvent',
            onMessage: (data)=>{
                const { additional } = data.onBonusGameRoundEvent;
                if (additional) {
                    setShowSpinButton(true);
                    isAdditionalRoundActive.value = true;
                }
            },
            isEnabled: isWheelNotificationEnabled,
            polling: {
                timeout: socketPollingTimeout,
                callback: ()=>fetchActiveOrAvailableCampaigns()
            }
        });
    }
    function init() {
        subscribeOnBonusGameRoundEvent();
    }
    init();
    return {
        roundState,
        roundKey,
        roundWinIndex,
        roundWinDetails,
        campaign,
        campaignState,
        fortuneWheelId,
        categoryId,
        actionUrl,
        showNotification,
        isAdditionalRoundActive,
        showSpinButton,
        isFortuneWheelAvailable,
        fetchRounds,
        calculateRound,
        fetchActiveOrAvailableCampaigns,
        setFortuneWheelId,
        setCampaignState,
        setActionUrl,
        setCategoryId,
        setShowNotificationStatus,
        setShowSpinButton
    };
});
export default useFortuneWheelStore;
