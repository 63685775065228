import { isObject, isString } from '@leon-hub/guards';
import { CardTypesValidationPatterns, PaymentsCardTypes } from 'web/src/modules/payments/enums';
import { isPaymentsCardTypes } from 'web/src/modules/payments/guards/isPaymentsCardTypes';
export default function getCardType(cardNumber) {
    if (!cardNumber) return null;
    const source = CardTypesValidationPatterns;
    isObject(source);
    const cardTypesKeys = Object.keys(source);
    let currentCard = null;
    for (const cardTypesKey of cardTypesKeys)if (isPaymentsCardTypes(cardTypesKey) && cardTypesKey in source) {
        const re = source[cardTypesKey];
        if (isString(re) && new RegExp(re).test(cardNumber)) currentCard = PaymentsCardTypes[cardTypesKey];
    }
    return currentCard;
}
