import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportEventsOutrightHeadline from 'web/src/modules/sportline/components/SportlineOutrightHeadline/SportlineOutrightHeadline.vue';
import { useRelatedEventsTable } from './useRelatedEventsTable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RelatedEventsTable',
    props: {
        withoutHeadline: {
            type: Boolean
        },
        activeId: {},
        sportElement: {},
        isOnlyOutrightShown: {
            type: Boolean
        }
    },
    emits: [
        "click-event"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const activeId = toRef(props, 'activeId', null);
        const sportElement = toRef(props, 'sportElement', null);
        const isOnlyOutrightShown = toRef(props, 'isOnlyOutrightShown', false);
        const withoutHeadline = toRef(props, 'withoutHeadline', false);
        const { outrightEvents, doShowOutrightEvents } = useRelatedEventsTable({
            sportElement
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return sportElement.value ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['related-events'])
            }, [
                isOnlyOutrightShown.value ? _createCommentVNode("", true) : (_openBlock(), _createBlock(SportlineSportBlock, {
                    key: 0,
                    "sport-element": sportElement.value,
                    "without-headline": withoutHeadline.value,
                    "active-event-id": activeId.value,
                    "can-add-to-favorite": true,
                    "extended-block-type": _unref(ExtendedEventBlock).RelatedEvents,
                    "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                        sportId: sportElement.value.sport.id
                    }),
                    onClickEvent: _cache[0] || (_cache[0] = ($event)=>emit('click-event'))
                }, null, 8, [
                    "sport-element",
                    "without-headline",
                    "active-event-id",
                    "can-add-to-favorite",
                    "extended-block-type",
                    "basis-key"
                ])),
                _unref(doShowOutrightEvents) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    isOnlyOutrightShown.value ? _createCommentVNode("", true) : (_openBlock(), _createBlock(SportEventsOutrightHeadline, {
                        key: 0
                    })),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(outrightEvents), (outrightSportElement)=>(_openBlock(), _createBlock(SportlineSportBlock, {
                            key: `outright_${outrightSportElement.sport.id}`,
                            "sport-element": outrightSportElement,
                            "is-market-types-selection-enabled": false,
                            "is-hide-league-info": "",
                            "is-column-names-display-disabled": "",
                            "without-headline": "",
                            "is-display-main-events-disabled": "",
                            "is-display-outright-headline-disabled": "",
                            "is-display-outright-events-enabled": "",
                            "can-add-to-favorite": true,
                            "can-add-outrights-to-favorite": true,
                            "extended-block-type": _unref(ExtendedEventBlock).RelatedEvents,
                            "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                                sportId: outrightSportElement.sport.id,
                                salt: 'outrights'
                            })
                        }, null, 8, [
                            "sport-element",
                            "can-add-to-favorite",
                            "can-add-outrights-to-favorite",
                            "extended-block-type",
                            "basis-key"
                        ]))), 128))
                ], 64)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'RelatedEventsTable'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
