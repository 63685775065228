import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id"
];
const _hoisted_2 = [
    "value"
];
import { TextInputTypes } from '../../enums';
import TextInput from '../TextInput';
import useSuggestEmailInput from './useSuggestEmailInput';
import { useBaseInputEvents } from '../../composables';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'SuggestEmailInput',
    props: {
        suggestion: {
            default: ()=>[]
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'email'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        autocomplete: {},
        placeholder: {},
        prefixIconName: {},
        suffixIconName: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { emitInput, emitBlur, emitFocus, emitChange } = useBaseInputEvents(emits);
        const { getEmailValue, listId } = useSuggestEmailInput(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(TextInput), {
                autofocus: _ctx.autofocus,
                name: _ctx.name,
                list: _unref(listId),
                type: _unref(TextInputTypes).EMAIL,
                placeholder: _ctx.placeholder,
                label: _ctx.label,
                autocomplete: _ctx.autocomplete,
                disabled: _ctx.disabled,
                value: _ctx.value,
                hint: _ctx.hint,
                "hint-right": _ctx.hintRight,
                "hint-link": _ctx.hintLink,
                "hint-link-right": _ctx.hintLinkRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                "suffix-icon-name": _ctx.suffixIconName,
                "prefix-icon-name": _ctx.prefixIconName,
                "is-large": _ctx.isLarge,
                onInput: _unref(emitInput),
                onChange: _unref(emitChange),
                onFocus: _unref(emitFocus),
                onBlur: _unref(emitBlur)
            }, {
                dropdown: _withCtx((param)=>{
                    let { value: inputValue } = param;
                    return [
                        _createElementVNode("datalist", {
                            id: _unref(listId)
                        }, [
                            _unref(getEmailValue)(inputValue) ? (_openBlock(true), _createElementBlock(_Fragment, {
                                key: 0
                            }, _renderList(_ctx.suggestion, (suggest)=>(_openBlock(), _createElementBlock("option", {
                                    key: suggest,
                                    value: _unref(getEmailValue)(inputValue) + '@' + suggest
                                }, null, 8, _hoisted_2))), 128)) : _createCommentVNode("", true)
                        ], 8, _hoisted_1)
                    ];
                }),
                _: 1
            }, 8, [
                "autofocus",
                "name",
                "list",
                "type",
                "placeholder",
                "label",
                "autocomplete",
                "disabled",
                "value",
                "hint",
                "hint-right",
                "hint-link",
                "hint-link-right",
                "error",
                "error-right",
                "suffix-icon-name",
                "prefix-icon-name",
                "is-large",
                "onInput",
                "onChange",
                "onFocus",
                "onBlur"
            ])), [
                [
                    _directive_auto_id,
                    'SuggestEmailInput'
                ]
            ]);
        };
    }
});
