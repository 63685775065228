import { defineAsyncComponent } from 'vue';
import { FormControlType } from 'web/src/components/Form/enums';
import { FormText, FormPassword, FormEmail, FormSingleSelect, FormCheckbox, FormDate, FormPhoneInput, FormSwitch, FormHidden, FormCaptcha, FormMultilineTextInput, FormSuggestEmail, FormSmsCode, FormAutocompleteAddress, FormCurrencyInput, FormSelectWithSearch } from '../components';
/* eslint-disable unicorn/prefer-module */ const FormCPFNumber = require('../components/li/FormCPFNumber/FormCPFNumber.vue').default;
const FormCurrencySelect = require('../components/li/FormCurrencySelect/FormCurrencySelect.vue').default;
const FormDropListSelect = process.env.VUE_APP_STORYBOOK ? require('../components/FormDropListSelect').default : ()=>false;
const FormAutoheightTextInput = process.env.VUE_APP_STORYBOOK ? require('../components/FormAutoheightTextInput').default : ()=>false;
/* eslint-enable unicorn/prefer-module */ /** common & instantly loaded */ const baseWidgets = {
    // most common | registration | login
    [FormControlType.Text]: FormText,
    [FormControlType.Email]: FormEmail,
    [FormControlType.Password]: FormPassword,
    [FormControlType.PasswordValidator]: FormPassword,
    [FormControlType.Select]: FormSingleSelect,
    [FormControlType.Phone]: FormPhoneInput,
    [FormControlType.PhoneInput]: FormPhoneInput,
    [FormControlType.PhoneCountriesSelectorInput]: FormPhoneInput,
    [FormControlType.Checkbox]: FormCheckbox,
    [FormControlType.Date]: FormDate,
    [FormControlType.Switch]: FormSwitch,
    [FormControlType.Hidden]: FormHidden,
    [FormControlType.SuggestEmail]: FormSuggestEmail,
    [FormControlType.Captcha]: FormCaptcha,
    [FormControlType.MultilineTextInput]: FormMultilineTextInput,
    [FormControlType.SmsCode]: FormSmsCode,
    [FormControlType.AutocompleteAddress]: FormAutocompleteAddress,
    [FormControlType.CurrencyInput]: FormCurrencyInput,
    [FormControlType.SelectWithSearch]: FormSelectWithSearch
};
/** rarely used */ const asyncWidgets = {
    /* eslint-disable max-len */ [FormControlType.FileSingle]: defineAsyncComponent(()=>import('../components/FormFileSingleInput/FormFileSingleInput.vue')),
    [FormControlType.FileMultiple]: defineAsyncComponent(()=>import('../components/FormFileMultipleInput/FormFileMultipleInput.vue')),
    [FormControlType.FileDragAndDrop]: defineAsyncComponent(()=>import('../components/FormFileDragAndDropInput/FormFileDragAndDropInput.vue')),
    [FormControlType.PhotoInput]: defineAsyncComponent(()=>import('../components/FormPhotoInput/FormPhotoInput.vue')),
    [FormControlType.PieTimerInput]: defineAsyncComponent(()=>import('../components/FormPieTimerInput/FormPieTimerInput.vue'))
};
/* eslint-enable max-len */ let domainWidgets = {};
"1";
domainWidgets = {
    ...domainWidgets,
    [FormControlType.CPFNumber]: FormCPFNumber,
    [FormControlType.CurrencySelect]: FormCurrencySelect
};
if (process.env.VUE_APP_STORYBOOK) domainWidgets = {
    ...domainWidgets,
    [FormControlType.Fio]: FormText
};
/** Slott */ if (process.env.VUE_APP_STORYBOOK) domainWidgets = {
    ...domainWidgets,
    [FormControlType.DropListSelect]: FormDropListSelect,
    [FormControlType.AutoheightTextInput]: FormAutoheightTextInput
};
const widgetList = {
    ...baseWidgets,
    ...asyncWidgets,
    ...domainWidgets
};
/** for payments inputs
   * @see web/src/views/Payments/components/PaymentsSystems/PaymentsSystem/customPaymentsFormWidgets.ts
   */ export default Object.freeze(widgetList);
