import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { extractSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';
import { createGetHref } from 'web/src/modules/core/utils';
export function useSegmentPageSeoMeta(props) {
    const router = useRouter();
    const getHref = createGetHref(router);
    const { isReady, activeSportElement, activeRegionElement, groupsForDisplay, canShowLiveForGroup, canShowPrematchForGroup } = props;
    const seoMetaInfo = computed(()=>{
        if (!isReady.value) return {};
        const sport = activeSportElement.value?.sport;
        const region = activeRegionElement.value?.region;
        return {
            sport: sport?.name || '',
            sportFamily: sport?.navigationParameters.urlName || '',
            sportEmoji: sport?.representation.emoji || '',
            region: region?.name || '',
            regionUrlName: region?.navigationParameters.urlName || '',
            regionFamily: region?.representation.icon || '',
            sportEvents: {
                value: groupsForDisplay.value.flatMap((group)=>[
                        ...canShowLiveForGroup(group) ? extractSportEventSeoMetaInfo(group.liveElement, {
                            sport,
                            vm: {
                                $router: {
                                    getHref
                                }
                            }
                        }) : [],
                        ...canShowPrematchForGroup(group) ? extractSportEventSeoMetaInfo(group.prematchElement, {
                            sport,
                            vm: {
                                $router: {
                                    getHref
                                }
                            }
                        }) : []
                    ]).filter((sportEventMeta)=>!!sportEventMeta),
                merge (oldValue, newValue) {
                    return [
                        ...oldValue,
                        ...newValue
                    ];
                }
            }
        };
    });
    return {
        seoMetaInfo
    };
}
