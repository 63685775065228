import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { toRef } from 'vue';
import { SportlineHomeBlockPriority } from 'web/src/modules/home/submodules/sportline/enums';
import HomeHeading from 'web/src/modules/home/components/heading/HomeHeading.vue';
import SportlineHomeSportElement from 'web/src/modules/home/submodules/sportline/views/SportlineHomeSportElement.vue';
import { useSportlineEventsBlock, useHomeSportlineElementSeoMeta } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventsBlock',
    props: {
        priority: {}
    },
    setup (__props) {
        const props = __props;
        const priority = toRef(props, 'priority', SportlineHomeBlockPriority.Live);
        const { isLiveBlock, isPrematchBlock, title, buttonTitle, sportElements, secondaryButton, pageLocation } = useSportlineEventsBlock({
            priority
        });
        useHomeSportlineElementSeoMeta({
            sportElements
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isLiveBlock) || _unref(isPrematchBlock) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['sport-events-block'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sport-events-block__heading'])
                }, [
                    _createVNode(HomeHeading, {
                        key: "heading",
                        title: _unref(title),
                        "button-text": _unref(buttonTitle),
                        to: _unref(pageLocation),
                        "secondary-button": _unref(secondaryButton)
                    }, null, 8, [
                        "title",
                        "button-text",
                        "to",
                        "secondary-button"
                    ])
                ], 2),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sportElements), (sportElement)=>(_openBlock(), _createBlock(SportlineHomeSportElement, {
                        key: sportElement.sport.id,
                        "sport-element": sportElement,
                        "is-market-types-selection-enabled": false
                    }, null, 8, [
                        "sport-element"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventsBlock'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
