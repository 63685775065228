import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import { useBalanceCard } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BalanceCard',
    props: {
        mode: {
            default: BalanceMode.Single
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isSingleMode } = useBalanceCard(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['balance-card']]: true,
                    [_ctx.$style['balance-card--multi']]: !_unref(isSingleMode)
                }),
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('click'))
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'BalanceCard'
                ]
            ]);
        };
    }
});
