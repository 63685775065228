import { defineStore } from 'pinia';
import { computed, toRef } from 'vue';
import { NavigationGroup } from '@leon-hub/navigation-config';
import RouteName from '@leon-hub/routing-config';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useFilterNavigationItems } from 'web/src/modules/core/store/composables';
import useVerification from 'web/src/modules/profile/submodules/verification/composables/useVerification';
const useUserNavigationStore = defineStore('user-navigation', ()=>{
    const siteConfigStore = useSiteConfigStore();
    const isSubscriptionBlockEnabled = toRef(siteConfigStore, 'isSubscriptionBlockEnabled');
    const isAchievementEnabled = toRef(()=>siteConfigStore.isAchievementEnabled);
    const customerDataStore = useCustomerDataStore();
    const { isVerificationNavigationItemEnabled } = useVerification();
    const g2svFeatureStatus = toRef(customerDataStore, 'g2svFeatureStatus');
    const userStore = useUserStore();
    const isBonusTermsAccepted = toRef(userStore, 'isBonusTermsAccepted');
    const { getNavigationItems } = useFilterNavigationItems();
    function filterProfileItems(items) {
        return items.filter((item)=>{
            if (item.props?.checkAchievementsAvailability && !isAchievementEnabled.value) return false;
            if (item.props?.isLeonShop && !isBonusTermsAccepted.value) return false;
            switch(item.routeName){
                case RouteName.CUSTOMER_VERIFICATION:
                    return isVerificationNavigationItemEnabled.value;
                case RouteName.TWO_FACTOR_AUTHENTICATION:
                    return g2svFeatureStatus.value;
                case RouteName.CUSTOMER_SUBSCRIPTIONS:
                    return isSubscriptionBlockEnabled.value;
                default:
                    return true;
            }
        });
    }
    function getFilteredUserProfileNavigationWithChildren(group) {
        return getNavigationItems(group).filter((item)=>item.children && item.children.length > 0).map((item)=>({
                ...item,
                children: filterProfileItems(item?.children ?? [])
            }));
    }
    const profileSidebarNavigation = computed(()=>filterProfileItems(getNavigationItems(NavigationGroup.PROFILE_NAVIGATION)));
    const userPhoneProfileNavigation = computed(()=>getFilteredUserProfileNavigationWithChildren(NavigationGroup.PROFILE_NAVIGATION));
    const userProfileSettingsNavigation = computed(()=>getFilteredUserProfileNavigationWithChildren(NavigationGroup.PROFILE_SETTINGS_NAVIGATION));
    return {
        profileSidebarNavigation,
        userPhoneProfileNavigation,
        userProfileSettingsNavigation
    };
});
export default useUserNavigationStore;
