import { isEnumOfType, isObject, isString, isUndefined, isValidInterface } from '@leon-hub/guards';
import { isRegistrationForm } from 'web/src/modules/registration/guards';
import { RegistrationFormStep } from 'web/src/modules/registration//enums';
export const isProfileForm = (value)=>isValidInterface(value, {
        uiFormSchema: isObject,
        formValidationSchema: isString,
        schemaId: isString,
        formStep: [
            isEnumOfType(RegistrationFormStep),
            isUndefined
        ]
    });
export const isUpdateIncompleteProfileFieldsResponse = (value)=>isValidInterface(value, {
        result: isString,
        registrationForm: isRegistrationForm
    });
