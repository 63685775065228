import { watch, toRef } from 'vue';
import useRootStore from '../../store/useRootStore';
export default function useOnAppContentLoadedCallback() {
    const isAppMainContentLoaded = toRef(useRootStore(), 'isAppMainContentLoaded');
    function performOnLoad(callback) {
        let { onlyIfNotLoaded } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        if (isAppMainContentLoaded.value) {
            if (!onlyIfNotLoaded) callback();
            return;
        }
        const watcher = watch(isAppMainContentLoaded, (isLoaded)=>{
            if (isLoaded) {
                callback();
                watcher();
            }
        });
    }
    return {
        performOnLoad
    };
}
