import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import SearchNoResultsPlaceholder from 'web/src/modules/search/components/SearchNoResultsPlaceholder/SearchNoResultsPlaceholder.vue';
import SearchSportResults from 'web/src/modules/search/submodules/sportline/components/SearchSportResults/SearchSportResults.vue';
import SearchTags from 'web/src/modules/search/components/SearchTags/SearchTags.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useSearchSportlineContent } from 'web/src/modules/search/submodules/sportline/views/SearchSportlineContent/composables';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SearchSportlineContent',
    emits: [
        "focus",
        "search-text",
        "item-click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const emits = __emit;
        const LobbyContent = require('web/src/modules/search/submodules/casino/views/CasinoSearchLobby/CasinoSearchLobby.vue').default;
        const { isNoResultsBlockVisible, noResultsText, isTagsVisible, searchTags, onTagClick, isLoading, hasSearchResults, liveEvents, prematchEvents, searchText, onEventClick, onInputClear, onInput } = useSearchSportlineContent(emits);
        __expose({
            onInputClear,
            onInput
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(isNoResultsBlockVisible) ? (_openBlock(), _createBlock(SearchNoResultsPlaceholder, {
                    key: 0,
                    text: _unref(noResultsText)
                }, null, 8, [
                    "text"
                ])) : _createCommentVNode("", true),
                _unref(isTagsVisible) ? (_openBlock(), _createBlock(SearchTags, {
                    key: 1,
                    tags: _unref(searchTags),
                    onClick: _unref(onTagClick)
                }, null, 8, [
                    "tags",
                    "onClick"
                ])) : _createCommentVNode("", true),
                _unref(isNoResultsBlockVisible) ? (_openBlock(), _createBlock(_unref(LobbyContent), {
                    key: 2,
                    "is-sport": ""
                })) : _createCommentVNode("", true),
                _unref(isLoading) ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: _normalizeClass(_ctx.$style['search__loader'])
                }, [
                    _createVNode(_unref(VLoaderDelayed))
                ], 2)) : _createCommentVNode("", true),
                _unref(hasSearchResults) ? (_openBlock(), _createBlock(SearchSportResults, {
                    key: 4,
                    "live-search-results": _unref(liveEvents),
                    "prematch-search-results": _unref(prematchEvents),
                    "search-text": _unref(searchText),
                    onClickEvent: _unref(onEventClick)
                }, null, 8, [
                    "live-search-results",
                    "prematch-search-results",
                    "search-text",
                    "onClickEvent"
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'SearchSportlineContent'
                ]
            ]);
        };
    }
});
