import { isError, isUndefined } from '@leon-hub/guards';
import { Json } from '@leon-hub/utils';
import { BusEvent, useEventsBus, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { isReCaptchaResponse } from 'web/src/modules/captcha/store/utils';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
export default function useVCaptchaVerificationWatcher(props, emit) {
    const bus = useEventsBus();
    const { getSiteKeyByCaptchaType } = useCaptchaStore();
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    useBusSafeSubscribe(BusEvent.CAPTCHA_VERIFICATION, (param)=>{
        let { captchaType } = param;
        verifyCaptchaCallback(captchaType);
    });
    function verifyCaptchaCallback(captchaType) {
        const { subscribe } = showDialog({
            presetName: PresetName.CAPTCHA,
            options: {
                title: $translate('WEB2_CAPTCHA_CHECK_MODAL_CAPTION').value,
                fullHeight: false,
                captchaRequesterStrategy: props.captchaRequesterStrategy,
                captchaType,
                dataTestId: 'verify-captcha-callback'
            }
        });
        subscribe({
            [DialogAction.MODAL_CLOSE]: (payload)=>{
                if (isReCaptchaResponse(payload.value)) {
                    if (isUndefined(payload.value)) bus.emit(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, {});
                    else if (isError(payload.value.captchaResponse)) bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, {
                        error: payload.value.captchaResponse
                    });
                    else emitVerify(payload.value.captchaResponse);
                } else bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, {
                    error: useErrorsConverter().convertToBaseError(new CaptchaServiceError({
                        log: `[TheCaptchaVerificationWatcher]: unknown response=${Json.stringify(payload.value)}`,
                        payload: {
                            captchaType
                        }
                    }))
                });
            }
        });
    }
    function emitVerify(captchaToken) {
        emit('verify', captchaToken);
    }
}
