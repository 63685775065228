import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
/**
 * we have 4 possible sizes (2 used in actual design)
 * It is cheaper to just predifine values then calculate it on client side
 * */ export const circleSizeMap = Object.freeze({
    [CircleSize.MINI]: 36,
    [CircleSize.DEFAULT]: 54,
    [CircleSize.MEDIUM]: 64,
    [CircleSize.BIG]: 96,
    [CircleSize.SIZE_32]: 32,
    [CircleSize.SIZE_40]: 40,
    [CircleSize.SIZE_42]: 42,
    [CircleSize.SIZE_44]: 42,
    [CircleSize.SIZE_80]: 80,
    [CircleSize.SIZE_92]: 92,
    [CircleSize.SIZE_94]: 94,
    [CircleSize.SIZE_112]: 112,
    [CircleSize.SIZE_152]: 152,
    [CircleSize.SIZE_180]: 180,
    [CircleSize.SIZE_218]: 218,
    [CircleSize.SIZE_S]: 44,
    [CircleSize.SIZE_M]: 52,
    [CircleSize.SIZE_L]: 94
});
export const circleStrokeMap = Object.freeze({
    [CircleSize.MINI]: 4,
    [CircleSize.DEFAULT]: 5,
    [CircleSize.MEDIUM]: 5,
    [CircleSize.BIG]: 6,
    [CircleSize.SIZE_32]: 2,
    [CircleSize.SIZE_40]: 3,
    [CircleSize.SIZE_42]: 2,
    [CircleSize.SIZE_44]: 5,
    [CircleSize.SIZE_80]: 3,
    [CircleSize.SIZE_92]: 4,
    [CircleSize.SIZE_94]: 3,
    [CircleSize.SIZE_112]: 3,
    [CircleSize.SIZE_152]: 7,
    [CircleSize.SIZE_180]: 6,
    [CircleSize.SIZE_218]: 9,
    [CircleSize.SIZE_S]: 3,
    [CircleSize.SIZE_M]: 3,
    [CircleSize.SIZE_L]: 3
});
/**
 * SVG circle props
 * cx, cy = circleSizeMap value / 2
 * r (radius) = (circleSizeMap value - Math.round(circleStrokeMap / 2)) / 2
 * */ export const circlePositionMap = Object.freeze({
    [CircleSize.MINI]: {
        cx: 18,
        cy: 18,
        r: 16
    },
    [CircleSize.DEFAULT]: {
        cx: 27,
        cy: 27,
        r: 24
    },
    [CircleSize.MEDIUM]: {
        cx: 32,
        cy: 32,
        r: 29
    },
    [CircleSize.BIG]: {
        cx: 48,
        cy: 48,
        r: 45
    },
    [CircleSize.SIZE_32]: {
        cx: 16,
        cy: 16,
        r: 15
    },
    [CircleSize.SIZE_40]: {
        cx: 20,
        cy: 20,
        r: 19
    },
    [CircleSize.SIZE_42]: {
        cx: 21,
        cy: 21,
        r: 20
    },
    [CircleSize.SIZE_44]: {
        cx: 21,
        cy: 21,
        r: 20
    },
    [CircleSize.SIZE_80]: {
        cx: 40,
        cy: 40,
        r: 39
    },
    [CircleSize.SIZE_92]: {
        cx: 46,
        cy: 46,
        r: 45
    },
    [CircleSize.SIZE_94]: {
        cx: 47,
        cy: 47,
        r: 45.5
    },
    [CircleSize.SIZE_112]: {
        cx: 56,
        cy: 56,
        r: 55
    },
    [CircleSize.SIZE_152]: {
        cx: 76,
        cy: 76,
        r: 74
    },
    [CircleSize.SIZE_180]: {
        cx: 90,
        cy: 90,
        r: 88
    },
    [CircleSize.SIZE_218]: {
        cx: 109,
        cy: 109,
        r: 106.5
    },
    [CircleSize.SIZE_S]: {
        cx: 22,
        cy: 22,
        r: 21
    },
    [CircleSize.SIZE_M]: {
        cx: 26,
        cy: 26,
        r: 25
    },
    [CircleSize.SIZE_L]: {
        cx: 47,
        cy: 47,
        r: 46
    }
});
/**
 * From geometry:
 * C=2πr
 * For this case:
 * Math.round(2 * Math.PI * circlePositionMap[size].r)
 * */ export const circleLengthMap = Object.freeze({
    [CircleSize.MINI]: 97,
    [CircleSize.DEFAULT]: 148,
    [CircleSize.MEDIUM]: 178,
    [CircleSize.BIG]: 280,
    [CircleSize.SIZE_32]: 98,
    [CircleSize.SIZE_40]: 120,
    [CircleSize.SIZE_42]: 126,
    [CircleSize.SIZE_44]: 126,
    [CircleSize.SIZE_80]: 245,
    [CircleSize.SIZE_92]: 283,
    [CircleSize.SIZE_94]: 290,
    [CircleSize.SIZE_112]: 346,
    [CircleSize.SIZE_152]: 465,
    [CircleSize.SIZE_180]: 553,
    [CircleSize.SIZE_218]: 669,
    [CircleSize.SIZE_S]: 132,
    [CircleSize.SIZE_M]: 157,
    [CircleSize.SIZE_L]: 289
});
