/* eslint-disable class-methods-use-this */ import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class CasinoBetgamesModule extends AppModule {
    addRoutes(router) {
        const navigation = void 0;
        router.addDefaultRoute({
            name: CasinoRouteName.CASINO_BETGAMES,
            path: '/betgames',
            prefetch: ()=>import('./prefetch/CasinoBetgamesPagePrefetch'),
            // eslint-disable-next-line max-len
            component: ()=>import('./pages/CasinoBetgamesRoutePage/CasinoBetgamesRoutePage.vue'),
            navigation
        });
    }
}
