import { storeToRefs } from 'pinia';
import { usePendingBetsStore } from 'web/src/modules/slip/submodules/pending-bets/store';
import useSlipViewSettingsStore from 'web/src/modules/slip/submodules/slip-view-settings/store/useSlipViewSettingsStore';
import { useCashoutStore } from '../store';
import { BetCashoutMode } from '../enums';
export default function useCashoutView() {
    const { updatePendingBetsOnCashout } = usePendingBetsStore();
    const cashoutStore = useCashoutStore();
    const { setSlipFullHeight } = useSlipViewSettingsStore();
    const { coreComponentProps, currentBetId, mode } = storeToRefs(cashoutStore);
    const { resetState, doCashout, setSlipCashoutState } = cashoutStore;
    const resolveCashout = ()=>{
        updatePendingBetsOnCashout({
            betId: currentBetId.value,
            result: mode.value
        });
    };
    const cancelClick = ()=>{
        if (mode.value === BetCashoutMode.ERROR) resolveCashout();
        setSlipCashoutState(false);
        setSlipFullHeight(false);
        resetState();
    };
    const mainButtonClick = ()=>{
        if (mode.value === BetCashoutMode.SUCCESS) {
            resolveCashout();
            cancelClick();
        } else doCashout();
    };
    return {
        coreComponentProps,
        mainButtonClick,
        cancelClick
    };
}
