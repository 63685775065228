import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "credit-card-radio-selector"
};
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import usePaymentsCreditCardRadioSelector from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardRadioSelector/composables/usePaymentsCreditCardRadioSelector';
import PaymentsCreditCardRadioSelectorItem from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardRadioSelectorItem/PaymentsCreditCardRadioSelectorItem.vue';
import { PAYMENTS_UNDEFINED_CREDIT_CARD } from 'web/src/modules/payments/constants';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCreditCardRadioSelector',
    props: {
        creditCards: {},
        selectedCardToken: {},
        hasNewCard: {
            type: Boolean
        },
        isDeletable: {
            type: Boolean
        }
    },
    emits: [
        "delete",
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { cardsList, hasCollapsedView, showNewCardButton, buttonProperties, getName, switchCollapsed, onDelete } = usePaymentsCreditCardRadioSelector(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cardsList), (creditCard)=>(_openBlock(), _createBlock(PaymentsCreditCardRadioSelectorItem, {
                        key: `${creditCard.token}`,
                        pan: creditCard.pan,
                        token: creditCard.token ?? _unref(PAYMENTS_UNDEFINED_CREDIT_CARD),
                        type: creditCard.type,
                        label: _unref(getName)(creditCard.type, `${creditCard.pan}`),
                        "is-selected": _ctx.selectedCardToken === creditCard.token,
                        "is-deletable": _ctx.isDeletable,
                        name: "creditCardSelector",
                        onChange: _cache[0] || (_cache[0] = ($event)=>emit('change', $event)),
                        onDelete: ($event)=>_unref(onDelete)(creditCard)
                    }, null, 8, [
                        "pan",
                        "token",
                        "type",
                        "label",
                        "is-selected",
                        "is-deletable",
                        "onDelete"
                    ]))), 128)),
                _unref(showNewCardButton) ? (_openBlock(), _createBlock(PaymentsCreditCardRadioSelectorItem, {
                    key: 0,
                    label: _ctx.$t('WEB2_ANOTHER_CARD'),
                    token: _unref(PAYMENTS_UNDEFINED_CREDIT_CARD),
                    "is-selected": _ctx.selectedCardToken === _unref(PAYMENTS_UNDEFINED_CREDIT_CARD),
                    name: "creditCardSelector",
                    onChange: _cache[1] || (_cache[1] = ($event)=>emit('change', $event))
                }, null, 8, [
                    "label",
                    "token",
                    "is-selected"
                ])) : _createCommentVNode("", true),
                _unref(hasCollapsedView) ? (_openBlock(), _createBlock(VButton, _mergeProps({
                    key: 1
                }, _unref(buttonProperties), {
                    onClick: _unref(switchCollapsed)
                }), null, 16, [
                    "onClick"
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsCreditCardRadioSelector'
                ]
            ]);
        };
    }
});
