import { toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import PaymentsDepositsNavigationParametersHolder from 'web/src/modules/core/composables/navigation/PaymentsDepositsNavigationParametersHolder';
export default function usePaymentsDepositsNavigation() {
    const router = useRouter();
    const customerDataStore = useCustomerDataStore();
    const lastDepositedSystemId = toRef(customerDataStore, 'lastDepositedSystemId');
    function pushToDepositsPage(options) {
        const isIdentOrIdentFeatureNotEnabled = true;
        const query = options?.query ?? {};
        let routeParams = {
            name: RouteName.DEPOSITS,
            query
        };
        if (lastDepositedSystemId.value && isIdentOrIdentFeatureNotEnabled) PaymentsDepositsNavigationParametersHolder.setLastDepositedId(lastDepositedSystemId.value);
        if (options?.needToReplace) router.replace(routeParams);
        else router.push(routeParams);
    }
    return {
        pushToDepositsPage
    };
}
