import { computed } from 'vue';
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
import { getNavigationProperties, getNextSlideIndexOffset, getPreviousSlideIndexOffset } from '../utils';
export default function useVSwiperBannerNavigationButtons() {
    const { swiperState, slideToNextSlide, slideToPreviousSlide } = useSwiperNavigation();
    const navigationProperties = computed(()=>getNavigationProperties(swiperState));
    function onNextClick() {
        if (swiperState) // eslint-disable-next-line max-len
        slideToNextSlide(getNextSlideIndexOffset(swiperState.activeSlideIndex.value, swiperState.slidesCounter.value));
    }
    function onPreviousClick() {
        if (swiperState) // eslint-disable-next-line max-len
        slideToPreviousSlide(getPreviousSlideIndexOffset(swiperState.activeSlideIndex.value, swiperState.slidesCounter.value));
    }
    return {
        navigationProperties,
        onNextClick,
        onPreviousClick
    };
}
