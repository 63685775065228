import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = [
    "alt",
    "src"
];
import { ProductSpecificName } from 'web/src/components/Logo/VLogo/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsLogo',
    props: {
        alt: {
            default: ''
        },
        src: {
            default: ''
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("img", {
                class: _normalizeClass(_ctx.$style['payment-system-logo']),
                alt: _ctx.alt,
                src: _ctx.src ? _ctx.src : // eslint-disable-next-line max-len
                require(`web/src/assets/logotypes/li/default/${_unref(ProductSpecificName).LOGO}.svg`)
            }, null, 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'PaymentsLogo'
                ]
            ]);
        };
    }
});
