import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import useBaseIntercom from 'web/src/modules/intercom/composables/useBaseIntercom';
let IntercomPrefetch = class IntercomPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(_router, _to, from, next) {
        const input = useBaseIntercom().prefetch(from, next);
        if (!input) return;
        next();
    }
};
export { IntercomPrefetch as default };
