import { ref } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useFooterCopyright() {
    const { $translate } = useI18n();
    const translationProps = ref({
        currentYear: String(new Date().getFullYear())
    });
    const copyright1 = $translate('WEB2_COPYRIGHT_1_DYNAMIC_YEAR_V2', translationProps);
    const copyright2 = $translate('WEB2_COPYRIGHT_2', translationProps);
    return {
        copyright1,
        copyright2
    };
}
