import { computed, ref } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';
import useSportlineCacheStorage from 'web/src/modules/sportline/store/useSportlineCacheStorage';
import { defaultHeadlineBackgrounColor } from './constants';
export function useSportHeadline(props) {
    const { sportId, isComing } = props;
    const customTitle = props.customTitle ?? ref(null);
    const { $translate } = useI18n();
    const cacheStore = useSportlineCacheStorage();
    const sportlineCatalogClick = useSportlineCatalogClick({
        sportId
    });
    const sportCache = computed(()=>cacheStore.getSportCache(sportId.value).value);
    const sport = computed(()=>sportCache.value?.sport ?? null);
    const { backgroundColor: defaultBackgroundColor } = useSportBackgroundColor({
        sport
    });
    const backgroundColor = computed(()=>defaultBackgroundColor.value ?? defaultHeadlineBackgrounColor);
    const title = computed(()=>{
        if (customTitle.value) return customTitle.value;
        if (isComing.value) return $translate('WEB2_COMING_EVENTS').value;
        if (sport.value) return sport.value.name;
        return null;
    });
    return {
        ...sportlineCatalogClick,
        backgroundColor,
        title
    };
}
