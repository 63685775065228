export default function getContainerStyleProps(props, betterOpenToTop, maxHeight, dropdownContainer) {
    const result = {
        width: 'auto',
        translateTop: 0,
        translateLeft: props.appearZonePositionX
    };
    if ('undefined' == typeof window) return result;
    if (props.matchParentWidth && props.appearZoneMaxWidth) result.width = `${props.appearZoneMaxWidth}px`;
    if (betterOpenToTop.value) {
        const contentSize = dropdownContainer.value?.offsetHeight || 0;
        const dropdownHeight = maxHeight.value ? Math.min(maxHeight.value, contentSize) : contentSize;
        result.translateTop = props.appearZonePositionY - dropdownHeight;
    } else result.translateTop = props.appearZonePositionY + props.appearZoneOffsetY;
    if (props.appearZoneMinWidth) result.minWidth = `${props.appearZoneMinWidth}px`;
    if (props.appearZoneMaxWidth) result.maxWidth = `${props.appearZoneMaxWidth}px`;
    return result;
}
