import { LoginType } from '@leon-hub/api-sdk';
export function phoneToString(phone) {
    if (phone) {
        const { prefix, suffix } = phone;
        return [
            '+',
            prefix,
            suffix
        ].join('');
    }
    return '';
}
export function getLoginType(value) {
    if (value.includes('@')) return LoginType.EMAIL;
    // fix it after refactoring login module on leonbets
    // optional +, prefix, suffix
    if (value.length >= 10) return LoginType.PHONE;
    return LoginType.ACCOUNT;
}
