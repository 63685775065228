import { computed } from 'vue';
import { defaultDateTimeFormat } from 'web/src/modules/profile/submodules/customer-history/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';
// eslint-disable-next-line max-len
export default function useCustomerHistoryListItemPayment(props, emit) {
    const $formatMoney = useFormatMoney();
    const dateProperties = computed(()=>defaultDateTimeFormat(props.item.date));
    const isStatus = computed(()=>!!props.item?.status);
    const isMoneyPositive = computed(()=>props.item.credit > 0);
    const money = computed(()=>{
        const { debit, credit } = props.item;
        let text = '';
        if (debit) text = `-${$formatMoney(debit)}`;
        if (credit) text = `+${$formatMoney(credit)}`;
        return text;
    });
    const isWithdrawCancellable = computed(()=>props.item.withdrawCancellable);
    function cancelWithdrawal() {
        if (isWithdrawCancellable.value) emit('cancel-withdrawal', props.item);
    }
    return {
        dateProperties,
        isStatus,
        isMoneyPositive,
        money,
        isWithdrawCancellable,
        cancelWithdrawal
    };
}
