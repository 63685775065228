import { inject } from 'vue';
import { SwiperProvidableKeys } from 'web/src/components/Swiper/Navigation/enums';
export default function useSwiperNavigation() {
    const slideToNextVisibleSlide = inject(SwiperProvidableKeys.SlideToNextVisibleSlide, ()=>{});
    const slideToPreviousVisibleSlide = inject(SwiperProvidableKeys.SlideToPreviousVisibleSlide, ()=>{});
    const slideToNextSlide = inject(SwiperProvidableKeys.SlideToNextSlide, ()=>{});
    const slideToPreviousSlide = inject(SwiperProvidableKeys.SlideToPreviousSlide, ()=>{});
    const swiperState = inject(SwiperProvidableKeys.State);
    const slideToSlide = inject(SwiperProvidableKeys.SlideToSlide, ()=>{});
    return {
        slideToNextVisibleSlide,
        slideToPreviousVisibleSlide,
        slideToNextSlide,
        slideToPreviousSlide,
        swiperState,
        slideToSlide
    };
}
