import { computed } from 'vue';
import { isOptionalString } from '@leon-hub/guards';
import { InputEventType } from 'web/src/components/Input/enums';
import getWrapperHintProperties from '../../utils/getWrapperHintProperties';
export function useFormDropListSelect(props, emit) {
    const isMultiple = computed(()=>!!props.options?.isMultiselectMode);
    const selectedAsArray = computed(()=>{
        isOptionalString(props.value), props.value;
        if (!isMultiple.value) return [];
        return (props.value ?? '').split(',').map((value)=>value.trim());
    });
    /**
   * TODO: to real usage of multiple selection in form, need to add real string[] as form data
   * or not to relay on schema enum
   * */ const selected = computed(()=>{
        isOptionalString(props.value), props.value;
        if (!isMultiple.value) return props.value ?? '';
        return selectedAsArray.value;
    });
    const getValue = (eventValue)=>{
        if (!isMultiple.value) return eventValue;
        if (selectedAsArray.value.includes(eventValue)) return selectedAsArray.value.filter((value)=>value !== eventValue).join(', ');
        selectedAsArray.value.push(eventValue);
        return selectedAsArray.value.join(', ');
    };
    const componentProps = computed(()=>{
        const { name, title, extraProperties, value, error, hintMessage, disabled, options, autofocus } = props;
        const values = extraProperties?.enum ?? [];
        isOptionalString(value);
        const uiLabels = extraProperties?.labels;
        const selectOptions = values.map((currentValue)=>({
                label: uiLabels ? uiLabels[currentValue] : currentValue,
                value: currentValue
            }));
        return {
            options: selectOptions,
            selected: selected.value,
            placeholder: title,
            disabled,
            ...options ?? {},
            ...getWrapperHintProperties({
                options,
                error,
                hintMessage
            }),
            name,
            autofocus
        };
    });
    const emitChange = (value)=>{
        emit(InputEventType.CHANGE, {
            name: props.name,
            value: getValue(value)
        });
    };
    const emitFocus = ()=>{
        emit(InputEventType.FOCUS, {
            name: props.name,
            value: props.value
        });
    };
    const emitBlur = ()=>{
        emit(InputEventType.BLUR, {
            name: props.name,
            value: props.value
        });
    };
    return {
        componentProps,
        emitChange,
        emitFocus,
        emitBlur
    };
}
