import { computed, ref, watch } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineAnalytics } from 'web/src/modules/sportline/composables/analytics';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { extractTopLeagues, extractZeroMarginTopLeagues, sortTopLeagueElementsASC } from 'web/src/modules/sportline/utils';
import { resolveLeaguePageLink, resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
function prepareTopLeagueListElementsForFilterTab(leagues) {
    return leagues.flatMap((param)=>{
        let { league, region } = param;
        return {
            league,
            region,
            pageLocation: resolveLeaguePageLink(league.navigationParameters)
        };
    });
}
export function useSportsSidebarTopLeagues(props) {
    const { sports, favoritesSports, maxTabsCount, maxInList, defaultActiveSportId } = props;
    const { $translate } = useI18n();
    const analytics = useSportlineAnalytics();
    const innerActiveTabId = ref(null);
    const favoritesLeaguesTab = computed(()=>{
        const favoritesLeagues = (favoritesSports.value ?? []).flatMap((param)=>{
            let { leagues } = param;
            return prepareTopLeagueListElementsForFilterTab(leagues);
        });
        return favoritesLeagues.length ? {
            key: CustomFilter.MyFavoriteLeagues,
            filterKey: CustomFilter.MyFavoriteLeagues,
            isActive: innerActiveTabId.value === CustomFilter.MyFavoriteLeagues,
            leagues: favoritesLeagues.sort(sortTopLeagueElementsASC),
            totalCount: favoritesLeagues.length,
            allLeaguesLocation: resolveSportsPageLink({
                urlName: CustomFilter.Favorites
            }),
            tabItem: {
                id: CustomFilter.MyFavoriteLeagues,
                icon: {
                    name: IconName.STAR_ALT,
                    size: IconSize.SIZE_16
                }
            }
        } : null;
    });
    const zeroMarginLeaguesTab = computed(()=>{
        const zeroMarginLeagues = extractZeroMarginTopLeagues(sports.value);
        const leagues = prepareTopLeagueListElementsForFilterTab(zeroMarginLeagues);
        return leagues.length ? {
            key: CustomFilter.ZeroMargin,
            filterKey: CustomFilter.ZeroMargin,
            isActive: innerActiveTabId.value === CustomFilter.ZeroMargin,
            leagues: leagues.sort(sortTopLeagueElementsASC),
            totalCount: zeroMarginLeagues.length,
            allLeaguesLocation: resolveSportsPageLink({
                urlName: CustomFilter.ZeroMargin
            }),
            tabItem: {
                id: CustomFilter.ZeroMargin,
                icon: {
                    name: IconName.SPORTLINE_HIGHEST_ODDS,
                    size: IconSize.SIZE_16
                }
            }
        } : null;
    });
    const allFilterTabs = computed(()=>{
        const result = [];
        if (favoritesLeaguesTab.value) result.push(favoritesLeaguesTab.value);
        if (zeroMarginLeaguesTab.value) result.push(zeroMarginLeaguesTab.value);
        for (const [index, sportElement] of (sports.value ?? []).entries()){
            const sportTopLeagues = extractTopLeagues(sportElement);
            if (sportTopLeagues.length > 0) {
                const leagues = prepareTopLeagueListElementsForFilterTab(sportTopLeagues);
                result.push({
                    key: sportElement.sport.id,
                    sport: sportElement.sport,
                    isActive: innerActiveTabId.value ? sportElement.sport.id === innerActiveTabId.value : 0 === index,
                    leagues: leagues.sort(sortTopLeagueElementsASC),
                    totalCount: sportElement.leaguesCount,
                    allLeaguesLocation: resolveSportsPageLink(sportElement.sport.navigationParameters),
                    tabItem: {
                        id: sportElement.sport.id,
                        icon: {
                            name: `sport-${sportElement.sport.representation.family}`,
                            size: IconSize.SIZE_16
                        }
                    }
                });
            }
        }
        return result;
    });
    const tabs = computed(()=>allFilterTabs.value.map((filterTab)=>filterTab.tabItem).slice(0, maxTabsCount.value));
    const isAvailable = computed(()=>!!tabs.value.length);
    const activeFilterTab = computed(()=>allFilterTabs.value.find((tab)=>tab.isActive) ?? allFilterTabs.value[0] ?? null);
    const activeTabId = computed(()=>activeFilterTab.value?.key ?? void 0);
    const activeFilterTabTotalCount = computed(()=>activeFilterTab.value?.totalCount ?? 0);
    const allLeaguesTitle = computed(()=>activeTabId.value === CustomFilter.MyFavoriteLeagues ? $translate('JSP_MY_LEAGUES').value : $translate('WEB2_ALL_LEAGUES').value);
    const activeFilterTabLeaguesList = computed(()=>(activeFilterTab.value?.leagues ?? []).slice(0, maxInList.value));
    const activeFilterTabAllLeaguesPageLocation = computed(()=>activeFilterTab.value?.allLeaguesLocation ?? null);
    const activeFilterTabLeaguesListSize = computed(()=>(activeFilterTab.value?.leagues ?? []).length);
    function onActivateTabClick(to) {
        innerActiveTabId.value = to;
        if (to === CustomFilter.ZeroMargin) analytics.zeroMarginSidebarTabClick();
    }
    watch(defaultActiveSportId, (value)=>{
        innerActiveTabId.value = value;
    }, {
        immediate: true
    });
    return {
        tabs,
        isAvailable,
        activeTabId,
        activeFilterTabLeaguesList,
        activeFilterTabLeaguesListSize,
        activeFilterTabAllLeaguesPageLocation,
        allLeaguesTitle,
        activeFilterTabTotalCount,
        onActivateTabClick
    };
}
