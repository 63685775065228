import { IntercomWidgetPadding } from 'web/src/modules/intercom/enums';
export default function getPadding(position) {
    switch(position){
        case IntercomWidgetPadding.HORIZONTAL:
            return 16;
        case IntercomWidgetPadding.VERTICAL:
            return 16;
        default:
            return 0;
    }
}
