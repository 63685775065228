export function splitGetLeagueResponse(response) {
    if (!response) return null;
    const { region, ...leagueFields } = response;
    const { sport, ...regionFields } = region;
    const sportResponse = sport;
    const regionResponse = regionFields;
    const leagueResponse = {
        ...leagueFields,
        region: regionResponse,
        sport: sportResponse
    };
    return {
        leagueResponse,
        regionResponse,
        sportResponse
    };
}
