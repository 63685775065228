import { DecodeSymbol, DecodeValue } from 'web/src/utils/DecodeValue';
export default function decodeDefaultValue(currentProperties) {
    const defaultValue = currentProperties.default ? String(currentProperties.default) : '';
    const symbol = defaultValue.includes('+') ? DecodeSymbol.DEFAULT : DecodeSymbol.BULL;
    const decodedDefaultValue = new DecodeValue(defaultValue, {
        symbol
    }).toDecodedString();
    return {
        ...currentProperties,
        default: decodedDefaultValue
    };
}
