import { onBeforeUnmount, toRef, watch } from 'vue';
import { useSportBannersStore } from '../../../store';
export function useSportEventInfo(props) {
    const sportBannersStore = useSportBannersStore();
    const { activateSlide, deactivateSlide } = sportBannersStore;
    const sportlineEvent = toRef(sportBannersStore, 'currentEventDetails');
    const market = toRef(sportBannersStore, 'currentMarket');
    watch(()=>props.isActive, (value)=>{
        if (value) activateSlide(props.bannerId, props.groupId);
        else deactivateSlide(props.bannerId);
    }, {
        immediate: true
    });
    onBeforeUnmount(()=>{
        if (props.isActive) deactivateSlide(props.bannerId);
    });
    return {
        sportlineEvent,
        market
    };
}
