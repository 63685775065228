import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DateTimeFilterTabId, DateTimeFilterTabSet } from 'web/src/modules/sportline/enums';
export function useDateTimeFilterTabsSet(props) {
    const { tabSet } = props;
    const { $translate } = useI18n();
    const allLabel = $translate('JS_SPORTS_QUICKFILTERS_ALL');
    const liveLabel = $translate('WEB2_LIVE_SPORTLINE');
    const todayLabel = $translate('JS_SPORTS_QUICKFILTERS_TODAY');
    const tomorrowLabel = $translate('JS_SPORTS_QUICKFILTERS_TOMORROW');
    const oneHourLabel = $translate('WEB2_SPORTS_QUICKFILTERS_1H');
    const threeHoursLabel = $translate('WEB2_SPORTS_QUICKFILTERS_3H');
    const sixHoursLabel = $translate('WEB2_SPORTS_QUICKFILTERS_6H');
    const twelveHoursLabel = $translate('WEB2_SPORTS_QUICKFILTERS_12H');
    const twentyFourHoursLabel = $translate('WEB2_SPORTS_QUICKFILTERS_24H');
    const dateRangeLabel = $translate('WEB2_SPORTS_QUICKFILTERS_CALENDER');
    const tabs = computed(()=>{
        switch(tabSet.value){
            case DateTimeFilterTabSet.Full:
                return [
                    {
                        id: DateTimeFilterTabId.ALL,
                        label: allLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.LIVE,
                        label: liveLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.TODAY,
                        label: todayLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.TOMORROW,
                        label: tomorrowLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.ONEHOUR,
                        label: oneHourLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.THREEHOURS,
                        label: threeHoursLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.SIXHOURS,
                        label: sixHoursLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.TWELVEHOURS,
                        label: twelveHoursLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.TWENTYFOURHOURS,
                        label: twentyFourHoursLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.DATE_RANGE,
                        label: dateRangeLabel.value
                    }
                ];
            case DateTimeFilterTabSet.Default:
                return [
                    {
                        id: DateTimeFilterTabId.ALL,
                        label: allLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.ONEHOUR,
                        label: oneHourLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.THREEHOURS,
                        label: threeHoursLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.SIXHOURS,
                        label: sixHoursLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.TWELVEHOURS,
                        label: twelveHoursLabel.value
                    },
                    {
                        id: DateTimeFilterTabId.TWENTYFOURHOURS,
                        label: twentyFourHoursLabel.value
                    }
                ];
            default:
                return [];
        }
    });
    return {
        tabs
    };
}
