import { computed, ref, watch } from 'vue';
import { InputEventType } from 'web/src/components/Input/enums';
import createUniqueId from 'web/src/utils/createUniqueId';
export default function useInputCore(props, emit) {
    let watchValueProp = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    const uniqueId = computed(()=>createUniqueId(props.name ?? 'input'));
    const inputValue = ref(props.value ?? '');
    const isEmpty = computed(()=>'' === inputValue.value);
    const isFocus = ref(false);
    const isHover = ref(false);
    const showClearButton = computed(()=>isFocus.value && !isEmpty.value && !props.readonly);
    const setInputValue = (value)=>{
        inputValue.value = value ?? '';
    };
    watch(()=>props.value, (value)=>{
        if (!watchValueProp) return;
        if (value !== inputValue.value) setInputValue(value);
    });
    const eventToEmit = computed(()=>({
            target: {
                value: inputValue.value,
                name: props.name ?? ''
            }
        }));
    const onFocus = ()=>{
        isFocus.value = true;
        emit(InputEventType.FOCUS, eventToEmit.value);
    };
    const onBlur = ()=>{
        isFocus.value = false;
        emit(InputEventType.BLUR, eventToEmit.value);
    };
    const onMouseOver = ()=>{
        isHover.value = true;
    };
    const onMouseLeave = ()=>{
        isHover.value = false;
    };
    const emitChange = ()=>{
        emit(InputEventType.CHANGE, eventToEmit.value);
    };
    const emitInput = ()=>{
        emit(InputEventType.INPUT, eventToEmit.value);
    };
    const clearValue = ()=>{
        setInputValue('');
        emitChange();
    };
    return {
        uniqueId,
        isEmpty,
        isFocus,
        isHover,
        inputValue,
        showClearButton,
        clearValue,
        onMouseOver,
        onMouseLeave,
        onFocus,
        onBlur,
        emitChange,
        emitInput,
        setInputValue
    };
}
