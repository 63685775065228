import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue";
import { IconName } from '@leon-hub/icons';
import { VForm } from 'web/src/components/Form';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import PaymentsCancelWithdrawal from 'web/src/modules/payments/components/PaymentsCancelWithdrawal/PaymentsCancelWithdrawal.vue';
import PaymentsLogo from 'web/src/modules/payments/components/PaymentsLogo/PaymentsLogo.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { usePaymentSystem } from 'web/src/modules/payments/components/PaymentSystem/composables/usePaymentSystem';
import PaymentsIframe from 'web/src/modules/payments/components/PaymentsIframe/PaymentsIframe.vue';
import PaymentsHintBlock from 'web/src/modules/payments/components/PaymentsHintBlock/PaymentsHintBlock.vue';
import PaymentsContent from 'web/src/modules/payments/components/PaymentsContent/PaymentsContent.vue';
import PaymentsModalsController from 'web/src/modules/payments/components/PaymnetsModalsController/PaymentsModalsController.vue';
import { PaymentsModalType } from 'web/src/modules/payments/enums';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import customPaymentsFormWidgets from '../../form-widgets/customPaymentsFormWidgets';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentSystem',
    props: {
        paymentSystem: {},
        uiFormSchema: {},
        formStep: {},
        schema: {},
        withdrawalStatsData: {
            default: null
        },
        paymentError: {},
        initialModalProperties: {},
        redirectUrl: {},
        htmlContent: {},
        previousRouteName: {},
        htmlContentProperties: {},
        redirectCharset: {},
        redirectFormParams: {},
        redirectMethod: {},
        mobileRedirectUrl: {},
        showPaymentsModal: {
            type: Boolean
        },
        isFormPending: {
            type: Boolean
        },
        companyFeeHintDescription: {},
        amountAsMoney: {},
        paymentMessage: {},
        modalText: {},
        submitButton: {}
    },
    emits: [
        "submit",
        "input",
        "change",
        "close",
        "disabled-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const componentProps = __props;
        const emits = __emit;
        const modalSelector = ModalSelector.DESKTOP_INNER_MODAL;
        const { showPaymentsLogo, paymentsLogoProperties, isDeposit, labelPropertiesEnrollmentTime, isDeptsupisstreetterminal, paymentsHintBlockProperties, hasInitiateModal, onSubmit, onChange, onInput, pushToMainPaymentPage, openIn, onDisabledClick, onIconSuffixClick, manualSubmit, isYMHide, paymentForm, isCryptoWithdrawal, minAMount } = usePaymentSystem(componentProps, emits);
        return (_ctx, _cache)=>{
            _resolveDirective("data-test");
            return _openBlock(), _createElementBlock(_Fragment, null, [
                _ctx.htmlContent ? (_openBlock(), _createBlock(PaymentsContent, {
                    key: 0,
                    "html-content": _ctx.htmlContent,
                    "html-content-properties": _ctx.htmlContentProperties,
                    onClose: _unref(pushToMainPaymentPage)
                }, null, 8, [
                    "html-content",
                    "html-content-properties",
                    "onClose"
                ])) : _ctx.redirectUrl ? (_openBlock(), _createBlock(PaymentsIframe, {
                    key: 2,
                    "is-deposit": _unref(isDeposit),
                    "redirect-url": _ctx.redirectUrl,
                    "redirect-charset": _ctx.redirectCharset,
                    "redirect-form-params": _ctx.redirectFormParams,
                    "redirect-method": _ctx.redirectMethod,
                    "open-in": _unref(openIn),
                    "mobile-redirect-url": _ctx.mobileRedirectUrl,
                    "is-deep-links-allowed": _ctx.paymentSystem?.isDeepLinkAllowed ?? false
                }, null, 8, [
                    "is-deposit",
                    "redirect-url",
                    "redirect-charset",
                    "redirect-form-params",
                    "redirect-method",
                    "open-in",
                    "mobile-redirect-url",
                    "is-deep-links-allowed"
                ])) : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([
                        _ctx.$style['payments-system']
                    ])
                }, [
                    _unref(showPaymentsLogo) ? (_openBlock(), _createBlock(PaymentsLogo, _mergeProps({
                        key: 0
                    }, _unref(paymentsLogoProperties), {
                        class: [
                            _ctx.$style['payments-system__logo']
                        ]
                    }), null, 16, [
                        "class"
                    ])) : _createCommentVNode("", true),
                    _ctx.withdrawalStatsData?.requestedCount ? (_openBlock(), _createBlock(PaymentsCancelWithdrawal, {
                        key: 1,
                        value: _ctx.withdrawalStatsData?.requestedAmount ?? 0
                    }, null, 8, [
                        "value"
                    ])) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['payments-system__form']]: true
                        })
                    }, [
                        _ctx.schema && _ctx.uiFormSchema && !(_ctx.paymentSystem?.isTerminal ?? false) ? (_openBlock(), _createBlock(_unref(VForm), {
                            key: 0,
                            ref_key: "paymentForm",
                            ref: paymentForm,
                            "ui-schema": _ctx.uiFormSchema,
                            schema: _ctx.schema,
                            "custom-errors": _ctx.paymentError ?? {},
                            class: _normalizeClass({
                                'ym-hide-content': _unref(isYMHide)
                            }),
                            "is-pending": _ctx.isFormPending,
                            onSubmit: _unref(onSubmit),
                            onInput: _unref(onInput),
                            onChange: _unref(onChange),
                            onDisabledButtonClick: _unref(onDisabledClick),
                            onIconSuffixClick: _unref(onIconSuffixClick)
                        }, _createSlots({
                            _: 2
                        }, [
                            _renderList(_unref(customPaymentsFormWidgets), (component, slotName)=>({
                                    name: slotName,
                                    fn: _withCtx((param)=>{
                                        let { props, events, ref } = param;
                                        return [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(component), _mergeProps({
                                                ref: ref
                                            }, props, _toHandlers(events)), null, 16))
                                        ];
                                    })
                                }))
                        ]), 1032, [
                            "ui-schema",
                            "schema",
                            "custom-errors",
                            "class",
                            "is-pending",
                            "onSubmit",
                            "onInput",
                            "onChange",
                            "onDisabledButtonClick",
                            "onIconSuffixClick"
                        ])) : _createCommentVNode("", true),
                        _createCommentVNode("", true)
                    ], 2),
                    _unref(isDeposit) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        class: _normalizeClass([
                            _ctx.$style['payments-system__label']
                        ])
                    }, _toDisplayString(_unref(labelPropertiesEnrollmentTime)), 3)),
                    _unref(isCryptoWithdrawal) ? (_openBlock(), _createElementBlock("span", {
                        key: 3,
                        class: _normalizeClass([
                            _ctx.$style['payments-system__label']
                        ])
                    }, _toDisplayString(_unref(minAMount)), 3)) : _createCommentVNode("", true),
                    _unref(isDeptsupisstreetterminal) ? (_openBlock(), _createBlock(VCmsContent, {
                        key: 4,
                        "cms-key": "DEPTSUPISSTREETTERMINAL_WEB2",
                        "no-padding": ""
                    })) : _createCommentVNode("", true),
                    (_unref(isDeposit) || _ctx.companyFeeHintDescription, !_unref(isDeposit) && _ctx.companyFeeHintDescription ? (_openBlock(), _createBlock(VHintBlock, {
                        key: 6,
                        class: _normalizeClass([
                            _ctx.$style['payments-system__hint-block']
                        ])
                    }, {
                        label: _withCtx(()=>[
                                _createTextVNode(_toDisplayString(_ctx.companyFeeHintDescription), 1)
                            ]),
                        _: 1
                    }, 8, [
                        "class"
                    ])) : _createCommentVNode("", true)),
                    _createVNode(PaymentsHintBlock, _mergeProps(_unref(paymentsHintBlockProperties), {
                        onClickOther: _unref(pushToMainPaymentPage)
                    }), null, 16, [
                        "onClickOther"
                    ]),
                    _createVNode(ModalPortal, {
                        selector: _unref(modalSelector)
                    }, {
                        default: _withCtx(()=>[
                                _unref(hasInitiateModal) && _ctx.initialModalProperties ? (_openBlock(), _createBlock(ConfirmModal, {
                                    key: 0,
                                    modal: _ctx.initialModalProperties,
                                    onClose: _cache[0] || (_cache[0] = ($event)=>hasInitiateModal.value = false),
                                    onButtonClick: _cache[1] || (_cache[1] = ($event)=>hasInitiateModal.value = false)
                                }, null, 8, [
                                    "modal"
                                ])) : _createCommentVNode("", true)
                            ]),
                        _: 1
                    }, 8, [
                        "selector"
                    ])
                ], 2)),
                _ctx.showPaymentsModal ? (_openBlock(), _createBlock(PaymentsModalsController, {
                    key: 3,
                    "modal-text": _ctx.modalText,
                    "modal-type": _unref(PaymentsModalType).DEPOSITS_OTHER_SUCCESS_MODAL,
                    "is-in-profile": "",
                    onClose: _cache[2] || (_cache[2] = ($event)=>emits('close'))
                }, null, 8, [
                    "modal-text",
                    "modal-type"
                ])) : _createCommentVNode("", true)
            ], 64);
        };
    }
});
