import { computed, toRef } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
export function useExpandableHeadline(props, emit) {
    const { expandKey, isExpanded, countEvents } = props;
    const { $translate } = useI18n();
    const locale = toRef(useI18nStore(), 'locale');
    const eventsCountOne = $translate('WEB2_SPORT_EVENTS_COUNT_ONE');
    const eventsCountTwo = $translate('WEB2_SPORT_EVENTS_COUNT_TWO');
    const eventsCountFew = $translate('WEB2_SPORT_EVENTS_COUNT_FEW');
    const isExpandable = computed(()=>null !== expandKey.value);
    const eventsCountLabel = computed(()=>LanguageMicroSettings.plural([
            eventsCountOne.value,
            eventsCountTwo.value,
            eventsCountFew.value
        ], countEvents.value, locale.value));
    const isShowEventsCounters = computed(()=>isExpandable.value && !isExpanded.value && !!countEvents.value);
    function toggleExpandState() {
        if (!isExpandable.value) return;
        emit('toggle-expand-state', {
            value: !isExpanded.value
        });
    }
    return {
        isExpandable,
        eventsCountLabel,
        isShowEventsCounters,
        toggleExpandState
    };
}
