/* eslint-disable no-console */ import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { IdleTimeQueueManager, Json, promiseTimeout } from '@leon-hub/utils';
import { logger } from '@leon-hub/logging';
import { isArray, isBoolean, isFunction, isNumber, isObject, isString, isUndefined } from '@leon-hub/guards';
import { localStorageManager } from '@leon-hub/local-storage';
import { ComponentStatus } from './types';
import { millisecondsToPrettyTime } from './utils';
import PluginsComponent from './components/pluginsComponent';
import UserAgentComponent from './components/userAgentComponent';
import FontsComponent from './components/fontsComponent';
import TimezoneComponent from './components/timezoneComponent';
import TimezoneOffsetComponent from './components/timezoneOffsetComponent';
import ScreenResolutionComponent from './components/screenResolutionComponent';
import CanvasComponent from './components/canvasApi/canvasComponent';
import WebGlVendorAndRendererComponent from './components/canvasApi/webGlVendorAndRendererComponent';
import HardwareConcurrencyComponent from './components/hardwareConcurrencyComponent';
import LanguageComponent from './components/languageComponent';
import ColorDepthComponent from './components/colorDepthComponent';
import DeviceMemoryComponent from './components/deviceMemoryComponent';
import PixelRatioComponent from './components/pixelRatioComponent';
import DoNotTrackComponent from './components/doNotTrackComponent';
import TouchSupportComponent from './components/touchSupportComponent';
import AudioComponent from './components/audioComponent';
import PlatformComponent from './components/platformComponent';
const FBR_STORAGE_KEY = 'fbrVisitorId';
const idleTimeQueueManager = IdleTimeQueueManager.getInstance();
const compoundStringJoiner = ':';
const componentsSet = new Set([
    PluginsComponent,
    UserAgentComponent,
    FontsComponent,
    TimezoneComponent,
    TimezoneOffsetComponent,
    ScreenResolutionComponent,
    CanvasComponent,
    WebGlVendorAndRendererComponent,
    HardwareConcurrencyComponent,
    LanguageComponent,
    ColorDepthComponent,
    DeviceMemoryComponent,
    PixelRatioComponent,
    DoNotTrackComponent,
    TouchSupportComponent,
    AudioComponent,
    PlatformComponent
]);
function isFingerprintBrowserConfig(value) {
    return isObject(value) && isFunction(value.hasher) && (isUndefined(value.doNotAllowComponentsList) || isArray(value.doNotAllowComponentsList)) && (isUndefined(value.components) || isObject(value.components)) && isNumber(value.componentExecutingTimeoutMs) && isBoolean(value.debug) && (isUndefined(value.runInIdleTimeQueue) || isBoolean(value.runInIdleTimeQueue)) && isNumber(value.visitorIdTtl);
}
function isStoredFingerprintBrowser(value) {
    return isObject(value) && isString(value.visitorId) && isNumber(value.timestamp);
}
let DeviceFingerprintBrowserService = class DeviceFingerprintBrowserService {
    init(config) {
        this.config = config;
    }
    async generateFingerprint() {
        if (!isFingerprintBrowserConfig(this.config)) {
            logger.error(`FingerprintBrowser config is invalid: config=${Json.stringify(this.config)}`);
            return null;
        }
        const startTime = Date.now();
        if (this.config.debug) console.info('[FPB] has started executing components.');
        if (this.hasNotApplicableComponents(// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.config.doNotAllowComponentsList ? this.config.doNotAllowComponentsList : [])) {
            if (this.config.debug) console.info('[FPB] doesn\'t apply to your device.');
            return null;
        }
        const runInIdleTimeQueue = void 0 === this.config.runInIdleTimeQueue || this.config.runInIdleTimeQueue;
        const computedComponentsResult = await this.computeComponents({
            runInIdleTimeQueue,
            components: this.config.components || componentsSet,
            componentExecutingTimeoutMs: this.config.componentExecutingTimeoutMs,
            debug: this.config.debug
        });
        const compoundArrayResult = [
            ...computedComponentsResult
        ].map((param)=>{
            let [key, componentResult] = param;
            return `${key}:${componentResult.value}`;
        });
        const compoundStringResult = compoundArrayResult.join(compoundStringJoiner);
        if (this.config.debug) // eslint-disable-next-line max-len
        console.info(`[FPB] components executing took ${millisecondsToPrettyTime(Math.floor(Date.now() - startTime))}.`);
        const result = this.config.hasher(compoundStringResult);
        if (this.config.debug) console.info(`[FPB] computing result is ${result}.`);
        localStorageManager.setItem(FBR_STORAGE_KEY, Json.stringify({
            visitorId: result,
            timestamp: Date.now()
        }) || '{}');
        return result;
    }
    getFingerprintFromStorage() {
        if (!isFingerprintBrowserConfig(this.config)) {
            logger.error(`FingerprintBrowser config is invalid: config=${Json.stringify(this.config)}`);
            return null;
        }
        const storedFbr = Json.parse(localStorageManager.getItem(FBR_STORAGE_KEY) || '{}', {
            defaultValue: {}
        });
        if (isStoredFingerprintBrowser(storedFbr)) {
            if (this.config.visitorIdTtl > 0 && (Date.now() - storedFbr.timestamp) / 1000 / 60 < this.config.visitorIdTtl) return storedFbr.visitorId;
            localStorageManager.removeItem(FBR_STORAGE_KEY);
        }
        return null;
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity,class-methods-use-this
    async computeComponents(options) {
        const componentsResult = new Map();
        const awaitingTasks = [];
        for (const ComponentClass of options.components){
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const task = (done, ComponentClass)=>{
                const component = new ComponentClass();
                promiseTimeout({
                    timeout: options.componentExecutingTimeoutMs,
                    promise: new Promise((resolve)=>{
                        try {
                            component.getComponentResult().then(resolve);
                        } catch (error) {
                            if (options.debug) // eslint-disable-next-line no-console
                            console.info(`[FPB] error occurred at component: ${component.key}.`, error);
                            componentsResult.set(component.key, {
                                key: component.key,
                                value: ComponentStatus.Error
                            });
                            done();
                        }
                    })
                }).then((result)=>{
                    componentsResult.set(component.key, result);
                    done();
                }).catch(()=>{
                    if (options.debug) // eslint-disable-next-line no-console,max-len
                    console.info(`[FPB] ${options.componentExecutingTimeoutMs}ms timeout exceeded at component: ${component.key}.`);
                    componentsResult.set(component.key, {
                        key: component.key,
                        value: ComponentStatus.Timeout
                    });
                    done();
                });
            };
            if (options.runInIdleTimeQueue) awaitingTasks.push(new Promise((resolve)=>{
                idleTimeQueueManager.enqueueTask(task.bind(void 0, resolve, ComponentClass));
            }));
            else awaitingTasks.push(new Promise((resolve)=>{
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                task(resolve, ComponentClass);
            }));
        }
        await Promise.allSettled(awaitingTasks);
        return componentsResult;
    }
    // eslint-disable-next-line class-methods-use-this
    hasNotApplicableComponents(components) {
        return components.some((component)=>component() === ComponentStatus.NotApplicable);
    }
    constructor(){
        _define_property(this, "config", void 0);
    }
};
export { DeviceFingerprintBrowserService as default };
