import { toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useRouterStore } from 'web/src/modules/core/store';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';
export default function useDepositsNavigation() {
    useRouter();
    usePaymentsStore();
    const routerStore = useRouterStore();
    toRef(()=>routerStore.previousRouteUrl);
    function setPreviousPageUrl() {}
    return {
        setPreviousPageUrl
    };
}
