import { inject, toRef } from 'vue';
import { ProvideSelectColumnMethodKey } from '../constants';
export function useInjectSelectColumnMethod(props) {
    // do not inject settings for blocks without sport info
    const basisKey = toRef(props.basisKey);
    const injectedSelectColumn = inject(ProvideSelectColumnMethodKey);
    function selectColumn(columnId) {
        if (!basisKey.value) return;
        injectedSelectColumn?.({
            basisKey: basisKey.value,
            columnId
        });
    }
    return {
        selectColumn
    };
}
