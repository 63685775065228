import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import { useVLinkPrevented } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLinkPrevented',
    props: {
        to: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { href } = useVLinkPrevented(props);
        function clickHandler(mouseEvent) {
            if (mouseEvent && !mouseEvent.defaultPrevented && mouseEvent.preventDefault) mouseEvent.preventDefault();
            emit('click', mouseEvent);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: _unref(href),
                onClick: _withModifiers(clickHandler, [
                    "prevent"
                ])
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 8, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VLinkPrevented'
                ]
            ]);
        };
    }
});
