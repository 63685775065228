import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Teleport as _Teleport, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import CasinoLoyaltyProgramProgress from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramProgress/CasinoLoyaltyProgramProgress.vue';
import useCasinoLoyaltyProgramLevelDoneModal from // eslint-disable-next-line max-len
'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramLevelDoneModal/composables/useCasinoLoyaltyProgramLevelDoneModal';
import { ModalSelector } from 'web/src/modules/core/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramLevelDoneModal',
    setup (__props) {
        const { title, counterLevels, progressProperties, isShowLevelDoneModal, close } = useCasinoLoyaltyProgramLevelDoneModal();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isShowLevelDoneModal) ? _withDirectives((_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: _unref(ModalSelector).BODY
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-level-done-modal']),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(close) && _unref(close)(...args);
                    })
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['casino-loyalty-program-level-done-modal__icon'])
                    }, [
                        _createVNode(CasinoLoyaltyProgramProgress, _normalizeProps(_guardReactiveProps(_unref(progressProperties))), null, 16)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['casino-loyalty-program-level-done-modal__content'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['casino-loyalty-program-level-done-modal__title'])
                        }, _toDisplayString(_unref(title)), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['casino-loyalty-program-level-done-modal__descr'])
                        }, _toDisplayString(_unref(counterLevels)) + " " + _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_LEVELS_UNLOCKED')), 3)
                    ], 2)
                ], 2)
            ], 8, [
                "to"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramLevelDoneModal'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
