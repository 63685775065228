import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
const defaultLinkTag = 'a';
export const isNativeAnchor = (hintLink)=>!!(hintLink && 'string' == typeof hintLink.href);
export const getHintLinkComponent = (isNative)=>isNative ? defaultLinkTag : VRouterLink;
export const getHintLinkProps = (isNative, hintLink)=>{
    if (isNative) return {
        href: hintLink?.href,
        target: hintLink?.target
    };
    return {
        to: hintLink?.href,
        target: hintLink?.target
    };
};
