import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { LandingChevronDecor, LandingChevronKind } from './types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingChevron',
    props: {
        label: {},
        kind: {
            default: LandingChevronKind.PRIMARY
        },
        decoration: {
            default: LandingChevronDecor.NONE
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['landing-chevron']]: true,
                    [_ctx.$style[`landing-chevron--${_ctx.kind}`]]: _ctx.kind !== _unref(LandingChevronKind).PRIMARY,
                    [_ctx.$style[`landing-chevron--${_ctx.decoration}`]]: _ctx.decoration !== _unref(LandingChevronDecor).NONE
                })
            }, [
                _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
                _ctx.decoration === _unref(LandingChevronDecor).STAR ? (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['landing-chevron__icon']),
                    name: _unref(IconName).STAR
                }, null, 8, [
                    "class",
                    "name"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingChevron'
                ]
            ]);
        };
    }
});
