import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function getSubmitButtonProps(props, isSubmitButtonDisabled, $t) {
    const processValue = $t('WEB2_PROCESSING').value;
    const sendValue = $t('WEB2_BUTTON_SEND').value;
    return {
        label: props.submitting ? processValue : sendValue,
        kind: ButtonKind.PRIMARY,
        isLoading: props.submitting,
        disabled: isSubmitButtonDisabled.value,
        fullWidth: true
    };
}
