import { ref } from 'vue';
import { lobbyTypeSearch, lobbyTypeHome, lobbyTypeSlotsGamePage, lobbyTypeFastgames, lobbyTypeLoyaltyProgram, lobbyTypeLiveCasino, lobbyTypeSlots, lobbyTypeAll, lobbyTypeLiveGamePage } from '@leon-hub/api-sdk';
export default function getInitArrayStatesByLobbyType() {
    return {
        [lobbyTypeSlots]: ref([]),
        [lobbyTypeLiveCasino]: ref([]),
        [lobbyTypeLoyaltyProgram]: ref([]),
        [lobbyTypeFastgames]: ref([]),
        [lobbyTypeSearch]: ref([]),
        [lobbyTypeLiveGamePage]: ref([]),
        [lobbyTypeSlotsGamePage]: ref([]),
        [lobbyTypeHome]: ref([]),
        [lobbyTypeAll]: ref([])
    };
}
