import { computed, ref } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import getShortenFileName from './utils/getShortenFileName';
export default function useVFileInputPlaceholder(props) {
    const hasPreviewImage = ref(false);
    const iconProperties = computed(()=>({
            name: props.hasError ? IconName.ATTENTION_OUTLINE : IconName.CHECK_OUTLINE,
            size: IconSize.SIZE_40
        }));
    const previewButtonProps = {
        iconName: IconName.ZOOM_IN,
        height: ButtonHeight.MEDIUM,
        kind: ButtonKind.BASE,
        rounded: true
    };
    const fileNameText = computed(()=>getShortenFileName(props.name));
    const isImageFileLayout = computed(()=>!!(props.isImageFile && !props.hasError));
    function onImagePreview() {
        hasPreviewImage.value = true;
    }
    function onImagePreviewHide() {
        hasPreviewImage.value = false;
    }
    return {
        hasPreviewImage,
        iconProperties,
        previewButtonProps,
        fileNameText,
        isImageFileLayout,
        onImagePreview,
        onImagePreviewHide
    };
}
