export function isIframe() {
    try {
        return window.self !== window.top;
    } catch  {
        return false;
    }
}
export function isCookiesDisabled() {
    return navigator && !navigator.cookieEnabled;
}
