import { toRef } from 'vue';
import { getEgsGames, getEgsGroups, getEgsCategories, getEgsGameById, getEgsGameByUrl, getLobby as getLobbyRequest, getEgsTopWinners, startEgsGame, lobbyTypeAll, lobbyTypeSlots, RequestOptionsPriority } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { parseLobbyItems } from 'web/src/modules/lobby/utils';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default function useEgsApi() {
    const apiClient = useGraphqlClient();
    const externalGamesBlock = toRef(useSiteConfigStore(), 'externalGames');
    async function getGroups(options, silent) {
        const result = await getEgsGroups(apiClient, (node)=>node.queries.externalGames.getGroups, {
            options: {
                ...options,
                type: options.type === lobbyTypeAll ? lobbyTypeSlots : options.type
            }
        }, {
            silent
        });
        return result.groups;
    }
    async function getCategories(options, silent) {
        const result = await getEgsCategories(apiClient, (node)=>node.queries.externalGames.getCategories, {
            options: {
                ...options,
                type: options.type === lobbyTypeAll ? lobbyTypeSlots : options.type
            }
        }, {
            silent
        });
        return result.categories;
    }
    async function getGameById(id) {
        const result = await getEgsGameById(apiClient, (node)=>node.queries.externalGames.getGame, {
            options: {
                gameId: id
            }
        });
        return result.game;
    }
    async function getGameByUrl(options) {
        const result = await getEgsGameByUrl(apiClient, (node)=>node.queries.externalGames.getGameByUrl, {
            options
        });
        return result.game;
    }
    function getGames(options, silent) {
        return getEgsGames(apiClient, (node)=>node.queries.externalGames.getGames, {
            options
        }, {
            silent,
            priority: silent ? RequestOptionsPriority.LOW : void 0
        });
    }
    // eslint-disable-next-line max-len
    async function getLobby(options, parseOptions, silent) {
        const items = await getLobbyRequest(apiClient, (node)=>node.queries.externalGames.getLobby, {
            options
        }, {
            silent
        });
        return parseLobbyItems(items, parseOptions);
    }
    function getTopWinners(options) {
        return getEgsTopWinners(apiClient, (node)=>node.queries.externalGames.getTopWinners, {
            options
        });
    }
    function startGame(options) {
        let isVpnGame = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        const requestOptions = isVpnGame ? {
            timeout: externalGamesBlock.value?.vpnEgsStartGameRetryTimeout ?? 30000,
            retry: externalGamesBlock.value?.vpnEgsStartGameRetryCounter ?? 5
        } : {
            // By default client uses 15 sec timeout.
            timeout: externalGamesBlock.value?.egsStartGameRetryTimeout,
            // Disable retry by default.
            // @see https://jira.leoncorp.net/browse/LB-15070?focusedCommentId=360654&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-360654
            retry: externalGamesBlock.value?.egsStartGameRetryCounter ?? 0
        };
        return startEgsGame(apiClient, (node)=>node.mutations.externalGames.startGame, {
            options
        }, requestOptions);
    }
    return {
        getGroups,
        getCategories,
        getGames,
        getGameById,
        getGameByUrl,
        getLobby,
        getTopWinners,
        startGame
    };
}
