import { computed, ref, watch } from 'vue';
import { InputEventType } from '../../enums';
import { handleNumberPaste, handleNumberInput } from '../../composables/utils';
export default function useStakeInput(props, emit) {
    const inputValue = ref(props.value || '');
    const inputElement = ref();
    watch(()=>props.value, (value)=>{
        inputValue.value = value || '';
    });
    "1";
    watch(()=>props.isFocused, (value)=>{
        if (value) inputElement.value?.focus();
    }, {
        immediate: true
    });
    const onFocus = ()=>{
        emit(InputEventType.FOCUS);
    };
    const onBlur = ()=>{
        "1";
        emit(InputEventType.BLUR, inputValue.value);
    };
    const emitInput = ()=>{
        emit(InputEventType.INPUT, inputValue.value);
    };
    const onClear = ()=>{
        inputValue.value = '';
        emitInput();
    };
    const onInput = (event)=>{
        inputValue.value = handleNumberInput(event, inputValue.value, props.maximumFractionDigits);
        emitInput();
    };
    const onPaste = (event)=>{
        event.preventDefault();
        inputValue.value = handleNumberPaste(event, inputValue.value, props.maximumFractionDigits);
        emitInput();
    };
    const onEnterKeydown = ()=>{
        "1";
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (props.isFocused) inputElement.value?.blur();
    };
    const isClearButtonVisible = computed(()=>!!props.isFocused && !!inputValue.value);
    const isCurrencySymbolVisible = computed(()=>!inputValue.value || !props.isFocused);
    return {
        onFocus,
        onBlur,
        onClear,
        onPaste,
        onInput,
        onEnterKeydown,
        inputElement,
        isClearButtonVisible,
        isCurrencySymbolVisible,
        inputValue
    };
}
