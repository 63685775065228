import { defineComponent as _defineComponent } from 'vue';
import { mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, useSlots } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { getAlertIconWeb2Image } from 'web/src/modules/icons';
import hasSlot from 'web/src/utils/vue/hasSlot';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { AlertIconName } from 'web/src/components/Icon/AlertIcon/types';
import { JumbotronIconKind } from './enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VJumbotron',
    props: {
        icon: {},
        iconSize: {
            default: IconSize.SIZE_60
        },
        image: {},
        heading: {
            default: ''
        },
        text: {
            default: ''
        },
        isModal: {
            type: Boolean
        },
        iconKind: {},
        alertIconSize: {
            default: 'default'
        },
        alertIcon: {}
    },
    setup (__props) {
        const props = __props;
        const slots = useSlots();
        const hasIcon = computed(()=>!!props.icon || hasSlot(slots, 'icon'));
        const hasContent = computed(()=>hasSlot(slots, 'default'));
        const hasFooter = computed(()=>hasSlot(slots, 'footer'));
        /* test fails on component mount on read process.env if use in template directly */ const isPhoneModal = computed(()=>false);
        const imageSrc = computed(()=>{
            // eslint-disable-next-line no-console
            if (props.image) return props.image;
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['jumbotron'])
            }, [
                imageSrc.value ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['jumbotron__image']]: true,
                        [_ctx.$style[`jumbotron__image--size__${_ctx.alertIconSize}`]]: true
                    }),
                    "data-test-id": "custom-icon"
                }, [
                    _createVNode(VImage, _mergeProps(imageSrc.value, {
                        class: _ctx.$style['jumbotron__image-inner']
                    }), null, 16, [
                        "class"
                    ])
                ], 2)) : hasIcon.value ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['jumbotron__icon-container'])
                }, [
                    _renderSlot(_ctx.$slots, "icon", {}, ()=>[
                            _createVNode(_unref(VIcon), {
                                "data-test-id": "icon",
                                class: _normalizeClass({
                                    [_ctx.$style['jumbotron__icon']]: true,
                                    [_ctx.$style["jumbotron__icon--success"]]: _ctx.iconKind === _unref(JumbotronIconKind).SUCCESS
                                }),
                                name: props.icon,
                                size: props.iconSize
                            }, null, 8, [
                                "class",
                                "name",
                                "size"
                            ])
                        ])
                ], 2)) : _createCommentVNode("", true),
                _ctx.heading ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    "data-test-id": "heading",
                    class: _normalizeClass(_ctx.$style['jumbotron__heading'])
                }, _toDisplayString(_ctx.heading), 3)) : _createCommentVNode("", true),
                _ctx.text ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    "data-test-id": "text",
                    class: _normalizeClass(_ctx.$style['jumbotron__text'])
                }, [
                    _createVNode(VDynamicContent, {
                        content: _ctx.text
                    }, null, 8, [
                        "content"
                    ])
                ], 2)) : _createCommentVNode("", true),
                hasContent.value ? (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    "data-test-id": "content",
                    class: _normalizeClass(_ctx.$style['jumbotron__content'])
                }, [
                    _renderSlot(_ctx.$slots, "default")
                ], 2)) : _createCommentVNode("", true),
                hasFooter.value ? (_openBlock(), _createElementBlock("div", {
                    key: 5,
                    "data-test-id": "footer",
                    class: _normalizeClass({
                        [_ctx.$style['jumbotron__footer']]: true,
                        [_ctx.$style['jumbotron__footer--phone-modal']]: isPhoneModal.value
                    })
                }, [
                    _renderSlot(_ctx.$slots, "footer")
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VJumbotron'
                ]
            ]);
        };
    }
});
