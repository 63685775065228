import { computed, ref, watch } from 'vue';
import { isVCaptchaProperties } from 'web/src/components/Input/guards/isVCaptchaProperties';
import { isFormWrapperWidgetPropertiesByType } from '../../../guards/isFormWrapperWidgetProperties';
import { FormControlType } from '../../../enums';
import getWidgetTypeList from './utils/getWidgetTypeList';
import { getSubmitButtonProperties, getUiSchemaField, hasSubmitButton } from '../../../utils/uiSchema';
import mergeErrors from '../../../utils/mergeErrors';
import getFormWidgetSchemaProperties from './utils/getFormWidgetSchemaProperties';
import getFormWrapperWidgetProperties from './utils/getFormWrapperWidgetProperties';
export default function useFormFields(param) {
    let { schema, uiSchema, isFormReady, isPending, externalErrors, schemaErrors, formData } = param;
    const regularWidgetList = ref([]);
    const captchaRelatedField = ref(null);
    const updateWidgetLists = ()=>{
        const { widgetTypeList, captchaWidget } = getWidgetTypeList(uiSchema.value);
        regularWidgetList.value = widgetTypeList;
        captchaRelatedField.value = captchaWidget;
    };
    watch(uiSchema, updateWidgetLists, {
        immediate: true
    });
    const fields = computed(()=>{
        const errors = mergeErrors(externalErrors.value, schemaErrors.value);
        return regularWidgetList.value.map((param)=>{
            let { type, field } = param;
            const fieldUiSchema = getUiSchemaField(uiSchema.value, field);
            const widgetProps = getFormWrapperWidgetProperties({
                name: field,
                fieldUiSchema,
                value: formData.value[field],
                error: errors[field],
                extraProperties: getFormWidgetSchemaProperties(schema.value.properties[field], fieldUiSchema, formData.value)
            });
            const fieldProps = {
                hidden: fieldUiSchema.hidden ?? fieldUiSchema.widget === FormControlType.Hidden,
                shortWidth: fieldUiSchema.shortWidth
            };
            return {
                widget: type,
                widgetProps,
                id: field,
                fieldProps
            };
        });
    });
    const captchaField = computed(()=>{
        if (!captchaRelatedField.value) return null;
        const { field } = captchaRelatedField.value;
        const fieldUiSchema = getUiSchemaField(uiSchema.value, field);
        const widgetProps = getFormWrapperWidgetProperties({
            name: field,
            fieldUiSchema,
            value: formData.value[field],
            error: '',
            extraProperties: {}
        });
        isFormWrapperWidgetPropertiesByType(widgetProps, isVCaptchaProperties);
        return widgetProps;
    });
    const buttonProps = computed(()=>{
        if (!hasSubmitButton(uiSchema.value)) return null;
        const properties = getSubmitButtonProperties(uiSchema.value);
        return {
            ...properties,
            isLoading: isPending.value,
            disabled: !isFormReady.value
        };
    });
    return {
        fields,
        captchaField,
        buttonProps
    };
}
