import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { useHead } from '@unhead/vue';
import { AlertIconName } from '@leon-hub/icons';
import { useNavigationItemsStore } from 'web/src/modules/core/store';
import { getImageOrIcon } from 'web/src/modules/icons';
import ErrorContent from '../../components/ErrorContent/ErrorContent.vue';
import ErrorPageNavigation from '../../components/ErrorPageNavigation/ErrorPageNavigation.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NotFoundErrorRoutePage',
    setup (__props) {
        const navigationItems = toRef(useNavigationItemsStore(), 'error404NavigationItems');
        const hasItems = computed(()=>navigationItems.value.length > 0);
        useHead({
            meta: [
                {
                    content: '404',
                    name: 'prerender-status-code'
                }
            ]
        });
        const { image } = getImageOrIcon({
            alertIcon: AlertIconName.Space
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ErrorContent, {
                "error-type": "not-found",
                "error-number": '404',
                image: _unref(image),
                "error-title": _ctx.$t('WEB2_ERROR_404_TITLE'),
                "error-message": _ctx.$t('WEB2_ERROR_404_MSG'),
                "has-support": "",
                "alert-icon-size": "large",
                class: "error-page--not-found"
            }, _createSlots({
                _: 2
            }, [
                hasItems.value ? {
                    name: "description",
                    fn: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_ERROR_404_WITH_MENU')), 1)
                        ]),
                    key: "0"
                } : void 0,
                hasItems.value ? {
                    name: "footer",
                    fn: _withCtx(()=>[
                            _createVNode(ErrorPageNavigation, {
                                items: navigationItems.value
                            }, null, 8, [
                                "items"
                            ])
                        ]),
                    key: "1"
                } : void 0
            ]), 1032, [
                "error-number",
                "image",
                "error-title",
                "error-message"
            ])), [
                [
                    _directive_auto_id,
                    'NotFoundErrorRoutePage'
                ]
            ]);
        };
    }
});
