import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 2
};
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { SportIcon } from '@components/sport-icon';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineNavigationIndicator',
    props: {
        icon: {},
        size: {},
        customFilter: {}
    },
    setup (__props) {
        // @TODO text size based on icon size
        // @TODO implement SportlineNavigationItemType.Image
        return (_ctx, _cache)=>_ctx.icon && _ctx.icon.type === _unref(SportlineNavigationItemType).Icon ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                key: 0
            }, _ctx.icon.props, {
                size: _ctx.size
            }), null, 16, [
                "size"
            ])) : _ctx.icon && _ctx.icon.type === _unref(SportlineNavigationItemType).SportIcon ? (_openBlock(), _createBlock(_unref(SportIcon), _mergeProps({
                key: 1
            }, _ctx.icon.props, {
                size: _ctx.size
            }), null, 16, [
                "size"
            ])) : _ctx.icon && _ctx.icon.type === _unref(SportlineNavigationItemType).Text ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.icon.text), 1)) : (_openBlock(), _createBlock(_unref(VIcon), {
                key: 3,
                size: _ctx.size,
                name: _unref(IconName).CROSS
            }, null, 8, [
                "size",
                "name"
            ]));
    }
});
