import { getAllBatchedEntries, getSinglesAvailableForBetting, getSlipEntryFromCombinedInfo } from '../../slip-info/utils';
import { getMakeBetEntryItems } from './getMakeBetEntryItems';
export default function getBatchMakeBetEntries(param) {
    let { combinedSlipEntriesForSingles, stakeValue, batchedSlipInfo, priceChangePolicy, isMultiSinglesMode, sameStakeForSingles, freeBetId } = param;
    if (isMultiSinglesMode) {
        const availableSlipEntries = getSinglesAvailableForBetting({
            input: combinedSlipEntriesForSingles,
            priceChangePolicy,
            sameStake: sameStakeForSingles ?? false
        });
        return availableSlipEntries.map((entry)=>{
            const stake = sameStakeForSingles ? stakeValue : Number(entry.metaInfo?.stakeValue || 0);
            return {
                stake,
                slipEntries: getMakeBetEntryItems([
                    getSlipEntryFromCombinedInfo(entry)
                ])
            };
        });
    }
    return [
        {
            stake: stakeValue,
            slipEntries: getMakeBetEntryItems(getAllBatchedEntries(batchedSlipInfo.slipEntries)),
            freeBetId
        }
    ];
}
