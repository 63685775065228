import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed } from 'vue';
import { InputEventType } from 'web/src/components/Input/enums';
import VCaptchaVerificationWatcher from 'web/src/modules/captcha/components/VCaptchaVerificationWatcher/VCaptchaVerificationWatcher.vue';
import useFormCaptcha from './useFormCaptcha';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FormCaptcha',
    props: {
        title: {},
        hintMessage: {},
        disabled: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        name: {},
        extraProperties: {},
        value: {
            type: [
                String,
                Boolean,
                Number,
                Array,
                Object
            ]
        },
        options: {},
        error: {}
    },
    emits: [
        "input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { componentProps } = useFormCaptcha(props);
        const shouldRenderComponent = computed(()=>!!componentProps.value.captchaEnabled);
        const onVerify = (captchaToken)=>{
            emit(InputEventType.INPUT, {
                value: captchaToken,
                name: props.name
            });
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return shouldRenderComponent.value ? _withDirectives((_openBlock(), _createBlock(VCaptchaVerificationWatcher, _mergeProps({
                key: 0
            }, _unref(componentProps), {
                onVerify: onVerify
            }), null, 16)), [
                [
                    _directive_auto_id,
                    'FormCaptcha'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
