import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { isArrayOfStrings } from '@leon-hub/guards';
import { isFormSchema } from '@leon-hub/form-utils';
import { defaultMappingKeys } from './constants/defaultMappingKeys';
import { addToPropertiesMap, updatePropertiesMap, getUiFieldRawOptions, getRelatedUiFieldCustomProperties, mapCommonFieldProperties, getUiSchemaField } from './utils';
let JsonSchemaAdapter = class JsonSchemaAdapter {
    /** will set properties for FormUiSchemaField object to all widgets related to first argument */ addPropertiesByWidget(widget, properties) {
        this.customPropertiesByWidget = addToPropertiesMap(this.customPropertiesByWidget, widget, properties);
        return this;
    }
    /** will set properties for FormUiSchemaField object to all widgets in passed array
   * useful to pass same props to similar components what can be passed by different enum values and can't relay on ID
   * like Phone/PhoneInput/PhoneCountriesSelectorInput or Password/PasswordValidator
   * */ addPropertiesByWidgetGroup(widgetGroup, properties) {
        for (const widget of widgetGroup)this.customPropertiesByWidget = addToPropertiesMap(this.customPropertiesByWidget, widget, properties);
        return this;
    }
    /** will update properties for FormUiSchemaField object to all widgets related to first argument */ updatePropertiesByWidget(widget, properties) {
        this.customPropertiesByWidget = updatePropertiesMap(this.customPropertiesByWidget, widget, properties);
        return this;
    }
    /** will set properties for FormUiSchemaField[options] object to all widgets related to first argument */ addOptionsByWidget(widget, options) {
        this.customOptionsByWidget = addToPropertiesMap(this.customOptionsByWidget, widget, options);
        return this;
    }
    /** will update properties for FormUiSchemaField[options] object to all widgets related to first argument */ updateOptionsByWidget(widget, options) {
        this.customOptionsByWidget = updatePropertiesMap(this.customOptionsByWidget, widget, options);
        return this;
    }
    /** will set properties for FormUiSchemaField object to field with related ID */ addPropertiesById(id, properties) {
        this.customPropertiesById = addToPropertiesMap(this.customPropertiesById, id, properties);
        return this;
    }
    /** will set properties for FormUiSchemaField[options] object to field with related ID */ addOptionsById(id, options) {
        this.customOptionsById = addToPropertiesMap(this.customOptionsById, id, options);
        return this;
    }
    /** @deprecated use getUiFieldRawOptions directly instead */ static mapToObject() {
        let list = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
        return getUiFieldRawOptions(list);
    }
    getRelatedCustomProperties(field) {
        return getRelatedUiFieldCustomProperties(field, this.customPropertiesByWidget, this.customPropertiesById);
    }
    getRelatedCustomFieldOptions(field) {
        return getRelatedUiFieldCustomProperties(field, this.customOptionsByWidget, this.customOptionsById);
    }
    getCommonFieldProperties(field) {
        return mapCommonFieldProperties(field, this.mappingKeys);
    }
    mapToFormUiSchemaField(field) {
        const commonProperties = this.getCommonFieldProperties(field);
        const customProperties = this.getRelatedCustomProperties(field);
        const customOptions = this.getRelatedCustomFieldOptions(field);
        return getUiSchemaField(commonProperties, customProperties, customOptions);
    }
    getFormUiSchema() {
        const fields = {};
        for (const field of this.apiUiSchema.fields)fields[field.id] = this.mapToFormUiSchemaField(field);
        let order = null;
        if (this.apiUiSchema.order) {
            isArrayOfStrings(this.apiUiSchema.order);
            order = this.apiUiSchema.order;
        }
        return {
            fields,
            ...order ? {
                order
            } : {}
        };
    }
    getFormSchema() {
        isFormSchema(this.JSONSchema);
        return this.JSONSchema;
    }
    constructor(apiUiSchema, JSONSchema, mappingKeys = defaultMappingKeys){
        _define_property(this, "apiUiSchema", void 0);
        _define_property(this, "JSONSchema", void 0);
        _define_property(this, "mappingKeys", void 0);
        /** props to create FormUiSchemaField object related on FormWidget(FormControlType) */ _define_property(this, "customPropertiesByWidget", void 0);
        /** props to create FormUiSchemaField[options] object related on FormWidget(FormControlType) */ _define_property(this, "customOptionsByWidget", void 0);
        /** props to create FormUiSchemaField object related on field id */ _define_property(this, "customPropertiesById", void 0);
        /** props to create FormUiSchemaField[options] object related on field id */ _define_property(this, "customOptionsById", void 0);
        this.apiUiSchema = apiUiSchema;
        this.JSONSchema = JSONSchema;
        this.mappingKeys = mappingKeys;
        this.customPropertiesByWidget = new Map();
        this.customOptionsByWidget = new Map();
        this.customPropertiesById = new Map();
        this.customOptionsById = new Map();
    }
};
export { JsonSchemaAdapter as default };
