import isNavigationItemActive from './isNavigationItemActive';
const setNavigationBreadcrumbs = (navigationItems, routeName)=>navigationItems.map((navigationItem)=>{
        const isActive = isNavigationItemActive(navigationItem, routeName);
        if (isActive) {
            let children;
            if (navigationItem.children?.length) children = setNavigationBreadcrumbs(navigationItem.children, routeName);
            return {
                ...navigationItem,
                isActive,
                children
            };
        }
        return {
            ...navigationItem
        };
    });
export default setNavigationBreadcrumbs;
