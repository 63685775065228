import { LimitName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
export function getCircularProgressData(limit, limits) {
    // TODO NEXT CHANGES
    let data = {};
    switch(limit){
        case LimitName.DAILY:
            limits?.dailyLimit, limits?.dailyPercent, limits?.dailyLeft;
            break;
        case LimitName.WEEKLY:
            limits?.weeklyLimit, limits?.weeklyPercent, limits?.weeklyLeft;
            break;
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,autofix/no-unused-vars
            limits?.monthlyLimit, limits?.monthlyPercent, limits?.monthlyLeft;
            break;
    }
    return {
        limit: '0',
        percent: 0,
        left: '0'
    };
}
