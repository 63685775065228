import { onMounted, onUnmounted, unref, watch } from 'vue';
import { isEmptyElementRef } from '../template-refs';
function getHtmlElement(element) {
    if (!element) return;
    if (element instanceof Element) return element;
    return element.$el;
}
export default function useIntersectionObserver(element, callback, options, onceOnIntersecting) {
    if ('undefined' == typeof IntersectionObserver) return;
    let observer = null;
    function disconnectObserver() {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
    }
    function connectObserver() {
        const unrefElement = getHtmlElement(unref(element));
        if (!unrefElement) return;
        observer = new IntersectionObserver((entries)=>{
            const isIntersecting = entries[0]?.isIntersecting;
            if (onceOnIntersecting && isIntersecting) {
                callback(isIntersecting);
                disconnectObserver();
            } else callback(isIntersecting);
        }, options);
        observer.observe(unrefElement);
    }
    if (isEmptyElementRef(element)) {
        const unwatchElement = watch(element, (newValue)=>{
            if (newValue) {
                connectObserver();
                unwatchElement();
            }
        });
    } else onMounted(connectObserver);
    onUnmounted(disconnectObserver);
}
