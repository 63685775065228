import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { IconName } from '@leon-hub/icons';
import TextInput from 'web/src/components/Input/components/TextInput/TextInput.vue';
import { useFocusableElement } from 'web/src/components/Input/composables';
import { useVSearchInput } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSearchInput',
    props: {
        placeholder: {
            default: ''
        },
        value: {
            default: ''
        },
        autofocus: {
            type: Boolean
        },
        isIconRight: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        }
    },
    emits: [
        "input",
        "clear"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const emit = __emit;
        const { onInput, onClear } = useVSearchInput(emit);
        const { focus, focusable } = useFocusableElement();
        __expose({
            focusInputElement: focus
        });
        const iconSearch = IconName.SEARCH;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(TextInput, _mergeProps({
                ref_key: "focusable",
                ref: focusable,
                name: "search",
                autofocus: _ctx.autofocus
            }, _ctx.isIconRight ? {
                suffixIconName: _unref(iconSearch)
            } : {
                prefixIconName: _unref(iconSearch)
            }, {
                value: _ctx.value,
                placeholder: _ctx.placeholder,
                "is-large": _ctx.isLarge,
                autocomplete: "off",
                onInput: _unref(onInput),
                onClear: _unref(onClear)
            }), null, 16, [
                "autofocus",
                "value",
                "placeholder",
                "is-large",
                "onInput",
                "onClear"
            ])), [
                [
                    _directive_auto_id,
                    'VSearchInput'
                ]
            ]);
        };
    }
});
