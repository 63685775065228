const pad = function(time) {
    let digits = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 2;
    return `00${time}`.slice(-digits);
};
// eslint-disable-next-line import/prefer-default-export
export const millisecondsToPrettyTime = (milliseconds)=>{
    const ms = milliseconds % 1000;
    const seconds = (milliseconds - ms) / 1000 % 60;
    const minutes = (milliseconds - seconds) / 60 % 60;
    return `${pad(minutes)}:${pad(seconds)}.${pad(ms, 3)}`;
};
