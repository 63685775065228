import { computed } from 'vue';
import { useScrollElementIntoView } from './useScrollElementIntoView';
export function useExpandableSportlineEventBlock(props) {
    const { container, isExtendedEventViewDisabled } = props;
    const { stopScrollIntoView, startScrollIntoView, scrollIntoViewAndStop } = useScrollElementIntoView({
        container
    });
    const isExtendedSportEventAllowed = computed(()=>!isExtendedEventViewDisabled.value);
    function beforeExtendedOpen() {
        startScrollIntoView();
    }
    function afterExtendedOpen() {
        scrollIntoViewAndStop();
    }
    function afterExtendedClose() {
        stopScrollIntoView();
    }
    return {
        isExtendedSportEventAllowed,
        beforeExtendedOpen,
        afterExtendedOpen,
        afterExtendedClose
    };
}
