import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, createVNode as _createVNode } from "vue";
import { onBeforeMount } from 'vue';
import VFadeCarouselNavigationButtons from 'web/src/components/FadeCarousel/VFadeCarouselNavigationButtons/VFadeCarouselNavigationButtons.vue';
import VFadeCarousel from 'web/src/components/FadeCarousel/VFadeCarousel/VFadeCarousel.vue';
import MainBannerItem from 'web/src/modules/banners/components/MainBannerItem/MainBannerItem.vue';
import VFadeCarouselSlide from 'web/src/components/FadeCarousel/VFadeCarouselSlide/VFadeCarouselSlide.vue';
import VFadeCarouselPointsPagination from 'web/src/components/FadeCarousel/VFadeCarouselPointsPagination/VFadeCarouselPointsPagination.vue';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import { useFadeCarouselBanner } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FadeCarouselBanner',
    props: {
        bannerGroup: {},
        isLoggedIn: {
            type: Boolean
        },
        bannerType: {
            default: BannerType.MAIN_BANNER
        },
        aspectRatio: {
            default: 1.75
        },
        isBigHeight: {
            type: Boolean
        }
    },
    emits: [
        "slide-changed",
        "mounted",
        "banner-item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { fadeCarousel, autoplayTimeout, banners, readySlides, nextVisibleIndex, isGroupBannersDev, activeSlideIndex, onSlideChanged, beforeMount, paginationLocation } = useFadeCarouselBanner(props, emit);
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VFadeCarousel, {
                ref_key: "fadeCarousel",
                ref: fadeCarousel,
                "autoplay-timeout": _unref(autoplayTimeout),
                "pagination-location": _unref(paginationLocation),
                onSlideChanged: _unref(onSlideChanged),
                onMounted: _cache[0] || (_cache[0] = ($event)=>emit('mounted'))
            }, {
                default: _withCtx((param)=>{
                    let { options } = param;
                    return [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(banners), (banner, index)=>(_openBlock(), _createBlock(VFadeCarouselSlide, {
                                key: banner.content.image.src,
                                "current-slide-index": index
                            }, {
                                default: _withCtx((param)=>{
                                    let { isSlideShown } = param;
                                    return [
                                        _unref(readySlides).includes(index) ? _withDirectives((_openBlock(), _createBlock(MainBannerItem, {
                                            key: 0,
                                            ref_for: true,
                                            ref: "items",
                                            banner: banner,
                                            "group-id": _ctx.bannerGroup?.id,
                                            "is-redirect-disabled": options.isDragInProgress,
                                            "is-slide-hidden": !process.env.VUE_APP_PRERENDER && !isSlideShown && index !== _unref(nextVisibleIndex),
                                            "is-group-banners-dev": _unref(isGroupBannersDev),
                                            "is-big-height": _ctx.isBigHeight,
                                            "is-active": _unref(activeSlideIndex) === index,
                                            class: _normalizeClass(_ctx.$style['fade-carousel-banner__item--link-drag-prevent']),
                                            "banner-type": _ctx.bannerType,
                                            onClick: ($event)=>emit('banner-item-click', {
                                                    path: $event,
                                                    banner
                                                })
                                        }, null, 8, [
                                            "banner",
                                            "group-id",
                                            "is-redirect-disabled",
                                            "is-slide-hidden",
                                            "is-group-banners-dev",
                                            "is-big-height",
                                            "is-active",
                                            "class",
                                            "banner-type",
                                            "onClick"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'banner-item'
                                                }
                                            ]
                                        ]) : _createCommentVNode("", true)
                                    ];
                                }),
                                _: 2
                            }, 1032, [
                                "current-slide-index"
                            ]))), 128))
                    ];
                }),
                pagination: _withCtx(()=>[
                        _ctx.isBigHeight ? (_openBlock(), _createBlock(VFadeCarouselNavigationButtons, {
                            key: 0
                        })) : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "pagination", {}, ()=>[
                                _createVNode(VFadeCarouselPointsPagination, {
                                    "pagination-location": _unref(paginationLocation)
                                }, null, 8, [
                                    "pagination-location"
                                ])
                            ])
                    ]),
                _: 3
            }, 8, [
                "autoplay-timeout",
                "pagination-location",
                "onSlideChanged"
            ])), [
                [
                    _directive_auto_id,
                    'FadeCarouselBanner'
                ]
            ]);
        };
    }
});
