import { computed, ref } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
// eslint-disable-next-line max-len,sonarjs/cognitive-complexity
export default function useVAccordionItem(props, slots, accordionSharedState) {
    const isIndependentlyOpen = ref(false);
    const noIconProperties = {
        size: IconSize.SIZE_16
    };
    const isOpen = computed(()=>{
        if (accordionSharedState.controlled) return !!props.open;
        if (accordionSharedState.independentItems) return isIndependentlyOpen.value;
        return accordionSharedState.openItem === props.id;
    });
    const computedIconProperties = computed(()=>{
        if (props.icon) return {
            name: props.icon,
            size: IconSize.SIZE_16
        };
    });
    // Condition helpers
    const hasSuffixSlot = computed(()=>!!slots.suffix);
    const hasPrefixSlot = computed(()=>!!slots.prefix);
    const hasHeaderSlot = computed(()=>!!slots.header);
    const hasComputedIcon = computed(()=>!hasPrefixSlot.value && !!computedIconProperties.value);
    // eslint-disable-next-line max-len
    const hasEmptyIcon = computed(()=>!hasPrefixSlot.value && !computedIconProperties.value && accordionSharedState.hasIcon);
    // eslint-disable-next-line max-len
    const hasPrefix = computed(()=>hasPrefixSlot.value || hasComputedIcon.value || hasEmptyIcon.value);
    const rootTag = computed(()=>{
        switch(accordionSharedState.parentTag){
            case Tag.OL:
            case Tag.UL:
                return Tag.LI;
            case Tag.SECTION:
                return Tag.ARTICLE;
            case Tag.DL:
                return Tag.DIV;
            default:
                return props.tag ?? Tag.DIV;
        }
    });
    const headerTag = computed(()=>accordionSharedState.parentTag === Tag.DL ? Tag.DT : Tag.DIV);
    const contentTag = computed(()=>accordionSharedState.parentTag === Tag.DL ? Tag.DD : Tag.DIV);
    const iconOpen = IconName.EXPAND_DOWN;
    const iconClose = IconName.EXPAND_UP;
    const iconSize = IconSize.SIZE_16;
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const indicatorConfig = computed(()=>{
        const indicatorClosed = {
            name: iconOpen,
            size: iconSize
        };
        const indicatorOpen = {
            name: iconClose,
            size: iconSize
        };
        const defaults = [
            indicatorClosed,
            indicatorOpen
        ];
        if (Array.isArray(props.indicator) && 2 === props.indicator.length) return props.indicator;
        if (!Array.isArray(props.indicator) && 'object' == typeof props.indicator) return [
            {
                ...props.indicator,
                name: iconOpen
            },
            {
                ...props.indicator,
                name: iconClose
            }
        ];
        if (Array.isArray(accordionSharedState.indicators) && 2 === accordionSharedState.indicators.length) return accordionSharedState.indicators;
        if (!Array.isArray(accordionSharedState.indicators) && 'object' == typeof accordionSharedState.indicators) return [
            {
                ...accordionSharedState.indicators,
                name: iconOpen
            },
            {
                ...accordionSharedState.indicators,
                name: iconClose
            }
        ];
        return defaults;
    });
    const computedIndicatorProperties = computed(()=>{
        if (!props.indicator || !accordionSharedState.indicators) return;
        return isOpen.value ? indicatorConfig.value[1] : indicatorConfig.value[0];
    });
    function independentlyOpenToggle() {
        isIndependentlyOpen.value = !isIndependentlyOpen.value;
    }
    return {
        hasHeaderSlot,
        indicatorConfig,
        isIndependentlyOpen,
        noIconProperties,
        isOpen,
        hasSuffixSlot,
        hasPrefixSlot,
        hasComputedIcon,
        hasEmptyIcon,
        hasPrefix,
        rootTag,
        headerTag,
        contentTag,
        computedIconProperties,
        computedIndicatorProperties,
        independentlyOpenToggle
    };
}
