import { isString, isArray, isObject } from '@leon-hub/guards';
export function isValueList(value) {
    if (!isArray(value)) return false;
    return value.length > 0 && value.every(isObject);
}
export function isStringEntry(value) {
    if (!isObject(value)) return false;
    return isString(value.key) && (isString(value.value) || isValueList(value.valueList));
}
export default function isStringEntryArray(value) {
    if (!isArray(value)) return false;
    return value.every(isStringEntry);
}
