import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
import { VIcon } from '@components/v-icon';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';
import { useVListItemCountries } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VListItemCountries',
    props: {
        selectedItemCode: {},
        searchText: {},
        name: {},
        code: {
            default: "MT"
        },
        prefix: {
            default: "356"
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isSelected, getHighlightedText } = useVListItemCountries(props);
        function emitClick() {
            emit('click', {
                prefix: props.prefix,
                code: props.code
            });
        }
        return (_ctx, _cache)=>(_openBlock(), _createBlock(VListItem, {
                key: 1,
                title: _ctx.name,
                "icon-suffix-name": _unref(isSelected) ? _unref(IconName).CHECK_FILLED : void 0,
                "is-active": _unref(isSelected),
                "have-hover": "",
                onClick: emitClick
            }, {
                prefix: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['list-item__prefix-flag'])
                        }, [
                            _createCommentVNode("", true),
                            _createVNode(_unref(VCountryFlag), {
                                name: _ctx.code,
                                class: _normalizeClass(_ctx.$style['list-item__prefix-flag-img'])
                            }, null, 8, [
                                "name",
                                "class"
                            ])
                        ], 2)
                    ]),
                inner: _withCtx(()=>[
                        _createElementVNode("div", null, [
                            _createVNode(VDynamicContent, {
                                tag: _unref(Tag).SPAN,
                                class: _normalizeClass(_ctx.$style['countries-selector-item__name']),
                                content: _unref(getHighlightedText)(_ctx.name || '')
                            }, null, 8, [
                                "tag",
                                "class",
                                "content"
                            ]),
                            _cache[1] || (_cache[1] = _createElementVNode("span", null, " ", -1)),
                            _createVNode(VDynamicContent, {
                                tag: _unref(Tag).SPAN,
                                class: _normalizeClass(_ctx.$style['countries-selector-item__code']),
                                content: _unref(getHighlightedText)(_ctx.prefix)
                            }, null, 8, [
                                "tag",
                                "class",
                                "content"
                            ])
                        ])
                    ]),
                _: 1
            }, 8, [
                "title",
                "icon-suffix-name",
                "is-active"
            ]));
    }
});
