import RouteName from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { useCasinoHome } from 'web/src/modules/home/submodules/casino/useCasinoHome';
let CasinoHomePagePrefetch = class CasinoHomePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const { isReady, initialRequests, syncBackgroundRequests } = useCasinoHome();
        const navigationStore = useRootNavigationStore();
        if (isReady.value) {
            syncBackgroundRequests();
            navigationStore.setRouteContentLoaded(RouteName.HOME);
        } else {
            navigationStore.setOnlyFirstRouteContentLoading(RouteName.HOME);
            initialRequests().then(()=>{
                navigationStore.setRouteContentLoaded(RouteName.HOME);
            }).catch((error)=>{
                throw error;
            });
        }
        next();
        return Promise.resolve();
    }
};
export { CasinoHomePagePrefetch as default };
