import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 1
};
const _hoisted_2 = [
    "onClick"
];
import { onBeforeMount } from 'vue';
import { VIcon } from '@components/v-icon';
import { useVSwitcher } from './composables';
import { SwitcherKind } from './enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSwitcher',
    props: {
        options: {
            default: ()=>[]
        },
        activeId: {},
        kind: {
            default: SwitcherKind.DEFAULT
        },
        isSmall: {
            type: Boolean
        },
        isCapitalize: {
            type: Boolean
        },
        isWideClickZone: {
            type: Boolean
        },
        isRounded: {
            type: Boolean
        },
        showDivider: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { grid, innerActiveIndex, positionCalculated, beforeMount, selectionStyle, calculateActiveOffsetWidth } = useVSwitcher(props);
        onBeforeMount(beforeMount);
        function emitChange(index) {
            innerActiveIndex.value = index;
            calculateActiveOffsetWidth();
            emit('change', props.options[index]?.id);
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['switcher']]: true,
                    [_ctx.$style['switcher--small']]: _ctx.isSmall,
                    [_ctx.$style['switcher--capitalize']]: _ctx.isCapitalize,
                    [_ctx.$style['switcher--rounded']]: _ctx.isRounded,
                    [_ctx.$style['switcher--wide-clickzone']]: _ctx.isWideClickZone,
                    [_ctx.$style[`switcher--kind-${_ctx.kind}`]]: _ctx.kind !== _unref(SwitcherKind).DEFAULT
                })
            }, [
                _withDirectives(_createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['switcher__selection']),
                    style: _normalizeStyle(_unref(selectionStyle))
                }, null, 6), [
                    [
                        _vShow,
                        _unref(positionCalculated)
                    ]
                ]),
                _createElementVNode("div", {
                    ref_key: "grid",
                    ref: grid,
                    class: _normalizeClass(_ctx.$style['switcher__grid'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index)=>_withDirectives((_openBlock(), _createElementBlock("div", {
                            key: option.id,
                            class: _normalizeClass(_ctx.$style['switcher__option'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['switcher__label']]: true,
                                    [_ctx.$style['switcher__label--active']]: index === _unref(innerActiveIndex),
                                    [_ctx.$style['switcher__label--show-divider']]: _ctx.showDivider && index > 0 && ![
                                        index,
                                        index - 1
                                    ].includes(_unref(innerActiveIndex)),
                                    [_ctx.$style['switcher__label--only-icons']]: !option.label
                                })
                            }, [
                                option.iconName ? (_openBlock(), _createBlock(_unref(VIcon), {
                                    key: 0,
                                    name: option.iconName,
                                    size: option.iconSize
                                }, null, 8, [
                                    "name",
                                    "size"
                                ])) : _createCommentVNode("", true),
                                option.label ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(option.label), 1)) : _createCommentVNode("", true)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['switcher__clickzone']),
                                onClick: ($event)=>emitChange(index)
                            }, null, 10, _hoisted_2)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'switcher-option',
                                    id: option.id,
                                    active: _ctx.activeId === option.id
                                }
                            ]
                        ])), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VSwitcher'
                ]
            ]);
        };
    }
});
