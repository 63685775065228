import { computed } from 'vue';
import { getExternalErrorsRelatedToCurrentData } from './utils/formExternalErrorsUtils';
export default function useExternalErrors(param) {
    let { customErrors, uiSchema, validationDisabled, formDataMap, schema } = param;
    // LEONWEB-6322 do not validate hidden/disabled fields
    const ignoredFields = computed(()=>{
        const allFields = uiSchema.value.fields ?? {};
        const result = new Set();
        for (const fieldName of Object.keys(allFields)){
            const currentField = allFields[fieldName];
            if (currentField.hidden || currentField.disabled) result.add(fieldName);
        }
        return result;
    });
    const externalErrors = computed(()=>{
        if (validationDisabled) return {};
        return getExternalErrorsRelatedToCurrentData(formDataMap.value, schema.value, customErrors.value, ignoredFields.value);
    });
    return {
        externalErrors
    };
}
