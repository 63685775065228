import { watch } from 'vue';
import { isSubscriptionAllowed, WebSocketAccessRole, WebSocketSubscription } from '@leon-hub/websocket';
import useWebsocketSubscriptionsStore from 'web/src/modules/core/store/useWebsocketSubscriptionsStore';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
// eslint-disable-next-line max-len
export default function useWebSockets() {
    const socketService = useWebsocketSubscriptionsStore();
    function subscribe(param) {
        let { method, onMessage, isEnabled, variables, polling, access } = param;
        const { isLoggedIn } = useIsLoggedIn();
        const subscription = new WebSocketSubscription({
            method,
            onMessage,
            variables,
            polling: polling ? {
                ...polling,
                timeout: polling.timeout.value
            } : void 0,
            access,
            isWsEnabled: isEnabled.value
        });
        function handlePolling() {
            if (isSubscriptionAllowed(isLoggedIn.value, access)) subscription.startPollingRequest();
            else subscription.stopPollingRequest();
        }
        let unwatchPollingTimeout;
        if (polling?.timeout) unwatchPollingTimeout = watch(polling.timeout, (newValue)=>{
            subscription.setPollingTimeout(newValue);
        }, {
            immediate: true
        });
        let unwatchLoggedIn = watch(isLoggedIn, handlePolling, {
            immediate: true
        });
        let unwatchEnabled = watch(isEnabled, (newValue)=>{
            subscription.setIsWsEnabled(newValue);
            socketService.unsubscribe(subscription);
            socketService.subscribe(subscription);
            if (!newValue) handlePolling();
        }, {
            immediate: true
        });
        function unsubscribe() {
            subscription.stopPollingRequest();
            socketService.unsubscribe(subscription);
            unwatchPollingTimeout?.();
            unwatchLoggedIn?.();
            unwatchEnabled?.();
            unwatchPollingTimeout = void 0;
            unwatchLoggedIn = void 0;
            unwatchEnabled = void 0;
        }
        return {
            unsubscribe
        };
    }
    // eslint-disable-next-line max-len
    function subscribeAuthorized(options) {
        return subscribe({
            ...options,
            access: WebSocketAccessRole.AUTHORIZED
        });
    }
    // eslint-disable-next-line max-len
    function subscribeAnonymous(options) {
        return subscribe({
            ...options,
            access: WebSocketAccessRole.ANONYMOUS
        });
    }
    return {
        subscribe,
        subscribeAuthorized,
        subscribeAnonymous
    };
}
