import searchSelectOptions from 'web/src/utils/search/searchSelectOptions';
export function searchInGroups(input, searchQuery) {
    const result = [];
    for (const group of input){
        const { options } = group;
        const matchedItems = searchSelectOptions(options, searchQuery);
        if (matchedItems.length) result.push({
            ...group,
            options: matchedItems
        });
    }
    return result;
}
