import { ref } from 'vue';
import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors/src/internal';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
const LocalStorageWidgetPositionKey = 'sport-events-details-widget-position';
export function useActiveSlideStoreComposable() {
    const localStorageManager = useLocalStorageManager();
    const defaultSwiperSlideId = ref(HeadlineSlideName.LiveStream);
    function setSwiperSlideId(value) {
        defaultSwiperSlideId.value = value;
    }
    function restoreSwiperSlideId() {
        try {
            const positionName = localStorageManager.getItem(LocalStorageWidgetPositionKey);
            if (positionName) setSwiperSlideId(positionName);
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(error);
        }
    }
    function saveSwiperSlideId(slideId) {
        setSwiperSlideId(slideId);
        try {
            if (slideId) localStorageManager.setItem(LocalStorageWidgetPositionKey, slideId);
            else localStorageManager.removeItem(LocalStorageWidgetPositionKey);
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(error);
        }
    }
    return {
        defaultSwiperSlideId,
        restoreSwiperSlideId,
        saveSwiperSlideId
    };
}
