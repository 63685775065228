import { computed, ref } from 'vue';
export function useDropListSearchQuery(props, emit, resetPagination) {
    const searchQuery = ref('');
    const searchInputRef = ref();
    const focusOnSearch = ()=>{
        if (props.searchEnabled && searchInputRef.value) searchInputRef.value.focus();
    };
    const emitQueryForApi = ()=>{
        if (props.isSearchByApiQuery) emit('search-input', searchQuery.value);
    };
    const onSearchInput = (event)=>{
        searchQuery.value = event.target.value;
        resetPagination();
        emitQueryForApi();
    };
    const clearSearchQuery = ()=>{
        searchQuery.value = '';
        resetPagination();
        emitQueryForApi();
    };
    const isFilterSearch = computed(()=>!props.isSearchByApiQuery && !!searchQuery.value.length);
    return {
        searchQuery,
        searchInputRef,
        isFilterSearch,
        onSearchInput,
        focusOnSearch,
        clearSearchQuery
    };
}
