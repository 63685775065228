import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getServiceWorkerConfiguration } from '@leon-hub/api-sdk/src/sdk/sdk';
import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useServiceWorkerStore = defineStore('service-worker', ()=>{
    const api = useGraphqlClient();
    const isEnabled = ref(false);
    async function loadConfig() {
        const data = await getServiceWorkerConfiguration(api, (node)=>node.queries.serviceWorker.getConfiguration, {}, {
            silent: true,
            priority: RequestOptionsPriority.LOW
        });
        isEnabled.value = data.app.enabled;
    }
    return {
        loadConfig,
        isEnabled
    };
});
export default useServiceWorkerStore;
