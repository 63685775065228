import { LogoSource } from 'web/src/modules/sportline/enums';
import { CompetitorType } from 'web/src/modules/sportline/enums/rest';
function parseCompetitorResponse(response) {
    const result = {
        id: String(response.id || ''),
        name: response.name
    };
    if (response.logo) {
        result.logo = response.logo;
        result.logoMeta = {
            source: 'SCORES' === response.logoSource ? LogoSource.Scores : LogoSource.Manual
        };
    }
    return result;
}
// @TODO Rename. The name is confusing. It can create competitors list
export function createCompetitorsPair(competitors) {
    if (2 !== competitors.length) // Return full list for outright events
    return competitors.map((competitor)=>parseCompetitorResponse(competitor));
    const host = (competitors || []).find((c)=>c.homeAway === CompetitorType.Host);
    const guest = (competitors || []).find((c)=>c.homeAway === CompetitorType.Guest);
    if (!host || !guest) return competitors.map((competitor)=>parseCompetitorResponse(competitor));
    return [
        parseCompetitorResponse(host),
        parseCompetitorResponse(guest)
    ];
}
