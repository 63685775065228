import { CustomerRegistrationType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { isArray, isNull, isNumber, isObject, isString, isUndefined, isEnumOfType, isValidInterface } from '@leon-hub/guards';
import { RegistrationFormStep } from 'web/src/modules/registration//enums';
export const isRegistrationForm = (value)=>isValidInterface(value, {
        registrationType: [
            isEnumOfType(CustomerRegistrationType)
        ],
        uiFormSchema: isObject,
        // TODO: complete guard
        formValidationSchema: isString,
        schemaId: isString,
        formStep: [
            isEnumOfType(RegistrationFormStep),
            isUndefined
        ],
        formName: [
            isString,
            isUndefined,
            isNull
        ],
        priority: [
            isNumber,
            isUndefined
        ],
        formProgress: [
            isNumber,
            isNull,
            isUndefined
        ],
        sendSms: [
            isObject,
            isNull,
            isUndefined
        ],
        goBack: [
            isObject,
            isNull,
            isUndefined
        ]
    });
export const isRegistrationForms = (value)=>isArray(value) && value.every(()=>isRegistrationForm);
