import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import KickoffPeriodUtils from 'web/src/modules/sportline/utils/core/KickoffPeriodUtils';
function buildDateTimeRage(from, to) {
    return from && to ? {
        from,
        to
    } : {
        from: void 0,
        to: void 0
    };
}
export function getDateTimeRangeForFilter(filter) {
    if (!filter) return null;
    return filter.activeKickoffPeriod === SportFilterKickoffPeriod.CUSTOM ? buildDateTimeRage(filter.customDateFrom, filter.customDateTo) : KickoffPeriodUtils.dateTimeRangeFromKickoffPeriod(filter.activeKickoffPeriod);
}
