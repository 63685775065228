import { ref } from 'vue';
export function useActiveBreadcrumb() {
    const activeId = ref(null);
    function toggleId(id) {
        activeId.value = activeId.value !== id ? id : null;
    }
    return {
        activeId,
        toggleId
    };
}
