import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { watchCustomEffect } from '../utils/watchCustomEffect';
import { getLexisNexisProfiledStorage } from '../utils/getLexisNexisProfiledStorage';
export const useLexisNexisIntegrationStore = defineStore('lexis-nexis-integration', ()=>{
    const siteConfigStore = useSiteConfigStore();
    const customerDataStore = useCustomerDataStore();
    const profiledCache = reactive({});
    const customerLogin = computed(()=>customerDataStore.login || void 0);
    const isProfiled = computed(()=>!!customerDataStore.login && !!profiledCache[customerDataStore.login]);
    const lexGuardAPIUrl = computed(()=>siteConfigStore.lexGuardAPIUrl);
    const lexGuardOrgId = computed(()=>siteConfigStore.lexGuardOrgId);
    const lexGuardProfilingSubdomain = computed(()=>siteConfigStore.lexGuardProfilingSubdomain);
    const isEnabled = computed(()=>!!(siteConfigStore.isLexGuardEnabled && siteConfigStore.lexGuardAPIUrl && siteConfigStore.lexGuardOrgId && siteConfigStore.lexGuardProfilingSubdomain));
    startSyncStorageToRefs(customerLogin, profiledCache, isEnabled);
    return {
        customerLogin,
        setProfiled (login) {
            if ('' !== login) profiledCache[login] = true;
        },
        isProfiled,
        isEnabled,
        lexGuardOrgId,
        lexGuardAPIUrl,
        lexGuardProfilingSubdomain
    };
});
function startSyncStorageToRefs(login, profiledCache, isEnabled) {
    watchCustomEffect(async ()=>{
        if (!login.value) return;
        Object.assign(profiledCache, {
            [login.value]: await getLexisNexisProfiledStorage(login.value).isProfiled()
        });
    }, {
        id: 'persist-storage',
        condition: isEnabled
    });
}
