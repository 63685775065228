import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeProps as _normalizeProps, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    class: "step-description"
};
const _hoisted_2 = {
    class: "step-description__wrapper"
};
const _hoisted_3 = {
    key: 0
};
import { VIcon } from '@components/v-icon';
import { useVStepDescription } from 'web/src/components/StepDescription/VStepDescription/composables';
import { StepDescriptionSize, StepDescriptionKind } from './enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VStepDescription',
    props: {
        kind: {
            default: StepDescriptionKind.BRAND
        },
        step: {},
        label: {
            default: ''
        },
        iconName: {},
        size: {
            default: StepDescriptionSize.SIZE_32
        }
    },
    setup (__props) {
        const props = __props;
        const { iconProperties } = useVStepDescription(props);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass([
                            "step-description__circle",
                            [
                                `step-description__circle-${_ctx.kind}`,
                                `step-description__circle-${_ctx.size}`
                            ]
                        ])
                    }, [
                        _ctx.step ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.step), 1)) : _ctx.iconName ? (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                            key: 1
                        }, _unref(iconProperties))), null, 16)) : _createCommentVNode("", true)
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: `step-description__circle ${_ctx.step ? `step-description__circle-${_ctx.step}` : ''}`
                            }
                        ]
                    ]),
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass([
                            "step-description__label",
                            {
                                'step-description__label-step': _ctx.step,
                                'step-description__label-icon': _ctx.iconName
                            }
                        ])
                    }, [
                        _renderSlot(_ctx.$slots, "label", {}, ()=>[
                                _createTextVNode(_toDisplayString(_ctx.label), 1)
                            ])
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: `step-description__label ${_ctx.step ? `step-description__label-${_ctx.step}` : ''}`
                            }
                        ]
                    ])
                ]),
                _renderSlot(_ctx.$slots, "default")
            ])), [
                [
                    _directive_auto_id,
                    'VStepDescription'
                ]
            ]);
        };
    }
});
