import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'VAnimatedTrack',
    setup (__props) {
        // empty setup
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['animated-track'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['animated-track__background'])
                }, null, 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['animated-track__foreground'])
                }, null, 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VAnimatedTrack'
                ]
            ]);
        };
    }
});
