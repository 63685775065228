import { computed, ref } from 'vue';
export function useVBreadcrumb(props) {
    const popper = ref();
    const isLocallyOpen = ref(false);
    const popperModifiers = [
        {
            name: 'computeStyles',
            fn (options) {
                // eslint-disable-next-line no-param-reassign,max-len
                options.state.styles.popper.transform = `translate3d(0, ${options.state.modifiersData.popperOffsets?.y || 0}px, 0)`;
                return options.state;
            }
        }
    ];
    const isOpen = computed(()=>!!(props.controlled ? props.open : isLocallyOpen.value));
    function toggleOpen() {
        isLocallyOpen.value = !isLocallyOpen.value;
    }
    function forceHide() {
        popper.value?.hide();
    }
    return {
        popper,
        isLocallyOpen,
        popperModifiers,
        isOpen,
        toggleOpen,
        forceHide
    };
}
