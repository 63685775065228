import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { LazyDefaultModal as DefaultModal } from 'web/src/components/Modal/DefaultModal';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { ModalSelector } from 'web/src/modules/core/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VCheckbox } from 'web/src/components/Checkbox';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { useCasinoGameBonusReminderModal } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameBonusReminderModal',
    props: {
        bonusBalance: {},
        hideLimitModalOnGamePage: {
            type: Boolean
        },
        maxBetLimit: {}
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isVisible, isDontShowAgain, maxBetLimitFormatted, onCloseReminderModal, onChangeConsent, onBonusLinkClick } = useCasinoGameBonusReminderModal(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isVisible) ? _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                key: 0,
                selector: _unref(ModalSelector).BODY
            }, {
                default: _withCtx(()=>[
                        _createVNode(_unref(DefaultModal), {
                            "is-alert": true,
                            width: _unref(ModalWidth).SMALL,
                            "top-bar-text": _ctx.$t('WEB2_BONUS_REMINDER_MODAL_TITLE_FROM_BONUS_WALLET'),
                            "is-centered": "",
                            "is-full-height": false,
                            "is-full-height-centered": false,
                            onClose: _unref(onCloseReminderModal)
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['bonus-reminder-modal'])
                                    }, [
                                        _createCommentVNode("", true),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['bonus-reminder-modal__details-text'])
                                        }, _toDisplayString(_ctx.$t('WEB2_BONUS_REMINDER_MODAL_MAX_BET_TEXT', {
                                            maxBetLimitFormatted: _unref(maxBetLimitFormatted)
                                        })), 3),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['bonus-reminder-modal__details-text'])
                                        }, [
                                            _createVNode(_unref(TextSlotPlaceholder), {
                                                text: _ctx.$t('WEB2_BONUS_REMINDER_MODAL_BONUS_RULES')
                                            }, {
                                                bonuses: _withCtx(()=>[
                                                        _createElementVNode("span", {
                                                            class: _normalizeClass(_ctx.$style['bonus-reminder-modal__details-link']),
                                                            onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                                            function() {
                                                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                                    args[_key] = arguments[_key];
                                                                }
                                                                return _unref(onBonusLinkClick) && _unref(onBonusLinkClick)(...args);
                                                            })
                                                        }, _toDisplayString(_ctx.$t('WEB2_BONUS_REMINDER_MODAL_BONUS_RULES_LINK')), 3)
                                                    ]),
                                                _: 1
                                            }, 8, [
                                                "text"
                                            ])
                                        ], 2),
                                        _createVNode(VButton, {
                                            kind: _unref(ButtonKind).PRIMARY,
                                            height: _unref(ButtonHeight).LARGE,
                                            label: _ctx.$t('WEB2_BONUS_REMINDER_MODAL_BUTTON_TEXT_CONTINUE'),
                                            "full-width": "",
                                            class: _normalizeClass(_ctx.$style['bonus-reminder-modal__button']),
                                            onClick: _unref(onCloseReminderModal)
                                        }, null, 8, [
                                            "kind",
                                            "height",
                                            "label",
                                            "class",
                                            "onClick"
                                        ]),
                                        _createVNode(_unref(VCheckbox), {
                                            id: "bonus-reminder-checkbox",
                                            checked: _unref(isDontShowAgain),
                                            class: _normalizeClass(_ctx.$style['bonus-reminder-modal__checkbox']),
                                            label: _ctx.$t('WEB2_BONUS_REMINDER_MODAL_DONT_SHOW_AGAIN'),
                                            onChange: _unref(onChangeConsent)
                                        }, null, 8, [
                                            "checked",
                                            "class",
                                            "label",
                                            "onChange"
                                        ])
                                    ], 2)
                                ]),
                            _: 1
                        }, 8, [
                            "is-alert",
                            "width",
                            "top-bar-text",
                            "onClose"
                        ])
                    ]),
                _: 1
            }, 8, [
                "selector"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoGameBonusReminderModal'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
