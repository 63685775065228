import { InputFileTypes } from 'web/src/components/Input/enums';
import { getFileExtension } from './getFileExtension';
export const isFileImage = (allowedTypes, file)=>{
    if (0 === Object.keys(allowedTypes).length) return false;
    const extension = getFileExtension(file);
    if (!extension) return false;
    const fileType = allowedTypes[extension];
    if (!fileType) return false;
    return fileType.type === InputFileTypes.Image;
};
