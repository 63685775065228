import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusHowDoesItWork',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bonus-how-does-it-work'])
            }, [
                _createElementVNode("h2", {
                    class: _normalizeClass(_ctx.$style['bonus-how-does-it-work__title'])
                }, _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_HOW_IT_WORKS_TITLE')), 3),
                _createVNode(VCmsContent, {
                    class: _normalizeClass(_ctx.$style['bonus-how-does-it-work__content']),
                    "cms-key": "WEB2_LOYALTY_PROGRAM_HOW_IT_WORKS"
                }, null, 8, [
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusHowDoesItWork'
                ]
            ]);
        };
    }
});
