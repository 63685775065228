import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { BetlineReplaceResponse, SportEventsResponseChangeUtils } from 'web/src/modules/sportline/utils/rest';
function filterEventsResponseByTypes(response, filter) {
    const events = response.events.filter((event)=>filter.includes(event.betline));
    return {
        ...response,
        totalCount: events.length,
        events
    };
}
export function normalizeComingResponse(param) {
    let { response } = param;
    const updated = response ? BetlineReplaceResponse.unknownResponseToSportEventsResponse(response) : response;
    const normalized = SportEventsResponseChangeUtils.normalizeDuplicatesResponse(updated);
    // filter outright events
    const coming = normalized ? filterEventsResponseByTypes(normalized, [
        BetlineType.Prematch
    ]) : null;
    return coming ? Object.freeze(coming) : null;
}
