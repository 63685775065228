import { toRef, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { logger } from '@leon-hub/logging';
import { useTooltipStore } from 'web/src/modules/dialogs/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ConfirmEmailStage } from '../enums';
export default function useVerifyEmailRoutePage() {
    const { $translate } = useI18n();
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const { showTooltip } = useTooltipStore();
    const { convertToBaseError } = useErrorsConverter();
    const customerDataStore = useCustomerDataStore();
    const haveValidEmailValue = toRef(customerDataStore, 'haveValidEmailValue');
    const customerEmail = toRef(customerDataStore, 'email');
    const isEmailConfirmed = toRef(customerDataStore, 'isEmailConfirmed');
    const userStore = useUserStore();
    const confirmationLinkWasSent = toRef(userStore, 'confirmationLinkWasSent');
    const tooManyConfirmationLinkRequests = toRef(userStore, 'tooManyConfirmationLinkRequests');
    const { resendVerificationEmail, loadUserData, confirmEmailByToken, setConfirmationLinkWasSent, setTooManyConfirmationLinkRequests } = userStore;
    const emailWasConfirmed = ref(false);
    const isTechError = ref(false);
    const isWrongToken = ref(false);
    const isPending = ref(false);
    const email = computed(()=>haveValidEmailValue.value ? customerEmail.value : '');
    const token = computed(()=>{
        const tokenParam = router.getParam('token');
        return tokenParam ? String(tokenParam) : null;
    });
    const confirmEmailStage = computed(()=>{
        if (emailWasConfirmed.value) return ConfirmEmailStage.CONFIRM_SUCCESS;
        if (isEmailConfirmed.value) return ConfirmEmailStage.ALREADY_CONFIRMED;
        if (tooManyConfirmationLinkRequests.value) return ConfirmEmailStage.TOO_MANY_REQUESTS;
        if (isTechError.value) return ConfirmEmailStage.TECH_ERROR;
        if (isWrongToken.value) return ConfirmEmailStage.WRONG_TOKEN;
        if (token.value) return ConfirmEmailStage.PENDING_CHECK;
        if (confirmationLinkWasSent.value) return ConfirmEmailStage.WAIT_FOR_CONFIRMATION;
        if (haveValidEmailValue.value) return ConfirmEmailStage.EMAIL_IS_NOT_CONFIRMED;
        return ConfirmEmailStage.EMAIL_UNKNOWN;
    });
    const leaveView = ()=>{
        router.closeModal();
    };
    const onAppear = ()=>{
        if (!token.value && !isLoggedIn.value) leaveView();
    };
    const onDisappear = ()=>{
        setConfirmationLinkWasSent(false);
        setTooManyConfirmationLinkRequests(false);
    };
    const sendAgain = async ()=>{
        isPending.value = true;
        await resendVerificationEmail();
        showTooltip({
            notificationText: $translate('WEB2_REQUEST_SUBMITTED').value
        });
    };
    const goToSupport = ()=>{
        router.replace({
            name: RouteName.SUPPORT
        });
    };
    const makeVerificationRequest = async ()=>{
        if (!token.value) {
            logger.error('Verification request token not found');
            return;
        }
        isTechError.value = false;
        try {
            const result = await confirmEmailByToken(token.value);
            if (result) {
                emailWasConfirmed.value = true;
                if (isLoggedIn.value) loadUserData().catch((err)=>logger.error(err));
            }
        } catch (rawError) {
            const error = convertToBaseError(rawError);
            if ('INVALID_VERIFICATION_TOKEN' === `${error.code}`) isWrongToken.value = true;
            else isTechError.value = true;
        }
    };
    const onConfirmClick = ()=>{
        if (isWrongToken.value) sendAgain();
        if (isTechError.value) {
            isTechError.value = false;
            makeVerificationRequest();
        }
    };
    const init = ()=>{
        if (token.value && !isEmailConfirmed.value) makeVerificationRequest();
    };
    init();
    return {
        email,
        isLoggedIn,
        confirmEmailStage,
        isPending,
        onAppear,
        onDisappear,
        onConfirmClick,
        goToSupport,
        sendAgain,
        onClose: leaveView
    };
}
