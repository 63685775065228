import { bindIsArrayOf } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { isCoreSportMarketColumnResponse } from 'web/src/modules/sportline/guards/rest';
export function createMarketColumns(options) {
    const columns = options.sport.mainMarkets ?? [];
    if (!bindIsArrayOf(isCoreSportMarketColumnResponse)(columns)) {
        logger.warn('CSL CMS sport main markets config corrupt', options.sport);
        return [];
    }
    return columns.map((column, index)=>({
            id: String(index),
            name: column.name,
            marketsPriority: [
                String(column.id),
                ...column.altMarketTypeIds.map(String)
            ]
        }));
}
