/* eslint-disable class-methods-use-this */ import { CasinoRouteName } from '@leon-hub/routing-config-names';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
function getCasinoGameDefaultRoute(param) {
    let { name, path } = param;
    return {
        name,
        path,
        prefetch: ()=>import('./prefetch/CasinoGamePagePrefetch'),
        component: ()=>import('./pages/CasinoGameRoutePage/CasinoGameRoutePage.vue')
    };
}
function addCasinoGameRoute(router, options) {
    "1";
    router.addDefaultRoute(getCasinoGameDefaultRoute(options));
}
export class CasinoGameModule extends AppModule {
    addRoutes(router) {
        addCasinoGameRoute(router, {
            name: CasinoRouteName.CASINO_GAME,
            path: '/casino/:groupUrl/play/:gameUrl'
        });
        addCasinoGameRoute(router, {
            name: CasinoRouteName.CASINO_LIVE_GAME,
            path: '/live-casino/:groupUrl/play/:gameUrl'
        });
        router.addPrefetchRoute({
            name: 'old games',
            path: '/slots/game/:id',
            prefetch: ()=>import('./prefetch/CasinoSlotsGamesPrefetchOld')
        });
        router.addPrefetchRoute({
            name: 'old games v2',
            path: '/slots/:groupUrl/play/:gameUrl',
            prefetch: ()=>import('./prefetch/CasinoSlotsGamePrefetchOldV2')
        });
        router.addBlankRoute({
            name: CasinoRouteName.GAME_EXIT,
            path: '/game-exit',
            component: ()=>import('./pages/CasinoExitRoutePage/CasinoExitRoutePage.vue')
        });
    }
}
