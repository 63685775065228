import { computed, onBeforeMount, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { PushNotificationPermission } from '@leon-hub/app-types';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { usePushNotificationsConfigSyncState, usePushNotificationsSubscribe } from 'web/src/modules/push-notifications/composables';
import { usePushNotificationSettingsStore } from 'web/src/modules/push-notifications/submodules/push-settings/store';
import { useUserStore } from 'web/src/modules/user/store';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { PushRadioInputId } from 'web/src/modules/push-notifications/pages/PushNotificationsBetsOnboardingRoutePage/enums';
export default function usePushNotificationsBetsOnboardingRoutePage() {
    const isImageLoaded = ref(false);
    const isSaved = ref(false);
    const selectedInput = ref(PushRadioInputId.WIN_ONLY);
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const pushNotificationSettingsStore = usePushNotificationSettingsStore();
    const userStore = useUserStore();
    const router = useRouter();
    const isBetOutcomePushEnabled = toRef(pushNotificationSettingsStore, 'isBetOutcomePushEnabled');
    const isWinOnlyBetOutcomePushEnabled = toRef(pushNotificationSettingsStore, 'isWinOnlyBetOutcomePushEnabled');
    const pushInputs = computed(()=>[
            getPushRadioInput(PushRadioInputId.WIN_ONLY, $translate('WEB2_PUSH_NOTIFICATION_ONLY_WIN').value),
            getPushRadioInput(PushRadioInputId.ALL, $translate('WEB2_PUSH_NOTIFICATION_ALL_BETS').value),
            getPushRadioInput(PushRadioInputId.POSTPONE, $translate('WEB2_ONBOARDING_PUSH_ITEM_3').value)
        ]);
    onBeforeMount(beforeMount);
    async function beforeMount() {
        await usePushNotificationsConfigSyncState();
        await pushNotificationSettingsStore.fetchSettings();
        if (pushNotificationSettingsStore.wasSaved()) selectedInput.value = pushNotificationSettingsStore.isWinOnlyBetOutcomePushEnabled ? PushRadioInputId.WIN_ONLY : PushRadioInputId.ALL;
    }
    async function checkPermissions() {
        return await usePushNotificationsSubscribe() === PushNotificationPermission.GRANTED;
    }
    async function saveSettings() {
        let input = {
            isBetOutcomePushEnabled: isBetOutcomePushEnabled.value,
            isWinOnlyBetOutcomePushEnabled: isWinOnlyBetOutcomePushEnabled.value
        };
        switch(selectedInput.value){
            case PushRadioInputId.ALL:
                input = {
                    isBetOutcomePushEnabled: true,
                    isWinOnlyBetOutcomePushEnabled: false
                };
                break;
            case PushRadioInputId.WIN_ONLY:
                input = {
                    isBetOutcomePushEnabled: true,
                    isWinOnlyBetOutcomePushEnabled: true
                };
                break;
            case PushRadioInputId.POSTPONE:
                return;
            default:
                throw new Error('Unknown push type');
        }
        await pushNotificationSettingsStore.saveSettings(input);
    }
    async function onSubmit() {
        if (!await checkPermissions()) return;
        if (selectedInput.value !== PushRadioInputId.POSTPONE) await userStore.enablePushConsent();
        await saveSettings();
        showSaveModal();
    }
    function showSaveModal() {
        isSaved.value = true;
        const isDisabled = selectedInput.value === PushRadioInputId.POSTPONE;
        showDialog({
            presetName: isDisabled ? PresetName.ALERT_ERROR : PresetName.ALERT_SUCCESS,
            options: {
                fullHeight: true,
                iconName: isDisabled ? IconName.BELL_OFF : IconName.CHECK_OUTLINE,
                iconSize: IconSize.SIZE_80,
                title: isDisabled ? $translate('WEB2_PUSH_BETS_MODAL_DISABLED_TTL').value : $translate('WEB2_PUSH_BETS_MODAL_ENABLED_TTL').value,
                confirmMessage: isDisabled ? $translate('WEB2_PUSH_BETS_MODAL_DISABLED_MSG').value : $translate('WEB2_PUSH_BETS_MODAL_ENABLED_MSG').value,
                buttons: [
                    {
                        action: DialogAction.MODAL_CLOSE,
                        label: $translate('JSP_PCL_FBOT_CLOSE').value,
                        kind: ButtonKind.BASE
                    }
                ],
                dataTestId: 'bets-notification-onboarding'
            }
        }).subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                router.back();
            }
        });
    }
    function setImageLoaded() {
        isImageLoaded.value = true;
    }
    function getPushRadioInput(id, name) {
        return {
            id,
            name,
            isEnabled: selectedInput.value === id
        };
    }
    function onPushSettingChanged(event, id) {
        const { checked } = event.target;
        if (checked) selectedInput.value = id;
    }
    return {
        isSaved,
        setImageLoaded,
        isImageLoaded,
        pushInputs,
        onPushSettingChanged,
        onSubmit
    };
}
