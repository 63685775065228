import { AllGamesGroupUrl } from 'web/src/modules/egs/constants';
import isLiveEgsRoute from './isLiveEgsRoute';
import getLobbyRouteName from './getLobbyRouteName';
export default function getLobbyLocation(route, groupUrl, categoryUrl, lobbyType) {
    const baseName = getLobbyRouteName(lobbyType, isLiveEgsRoute(route));
    const urlGroupParam = groupUrl || AllGamesGroupUrl;
    const parameters = {};
    parameters.groupUrl = urlGroupParam;
    if (!categoryUrl && !groupUrl) delete parameters.groupUrl;
    if (categoryUrl) parameters.categoryUrl = categoryUrl;
    return {
        name: baseName,
        params: parameters
    };
}
