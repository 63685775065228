import { getAchievement } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function getAchievementRequest(id, publicId) {
    const apiClient = useGraphqlClient();
    const options = {
        id
    };
    if (publicId) options.publicId = publicId;
    return getAchievement(apiClient, (node)=>node.queries.achievements.getAchievement, {
        options
    });
}
