const INCORRECT_LENGTH = 'incorrect pathFragments length';
export function mergeString(dataPathFragments, result, value) {
    if (1 !== dataPathFragments.length) throw new Error(INCORRECT_LENGTH);
    const field = dataPathFragments[0];
    if (result.has(field)) return result;
    result.set(field, value);
    return result;
}
export function mergeObjectArray(dataPathFragments, result, value) {
    if (3 !== dataPathFragments.length) throw new Error(INCORRECT_LENGTH);
    const field = dataPathFragments[0];
    const errorList = result.get(field) || [];
    const newErrorList = errorList.map((errorObject)=>{
        if (errorObject) return {
            ...errorObject
        };
        return null;
    });
    const pathIndex = parseInt(dataPathFragments[1], 10);
    const errorObject = newErrorList[pathIndex] || {};
    if (pathIndex > 0) {
        for(let index = 0, range = pathIndex; index < range; index += 1)if (void 0 === newErrorList[index]) newErrorList[index] = null;
    }
    newErrorList[pathIndex] = {
        ...errorObject,
        [dataPathFragments[2]]: void 0 !== value ? value : null
    };
    result.set(field, newErrorList);
    return result;
}
export function mergeObject(dataPathFragments, result, value) {
    if (2 !== dataPathFragments.length) throw new Error(INCORRECT_LENGTH);
    const field = dataPathFragments[0];
    const currentValue = result.get(field) || {};
    const newValue = {
        [dataPathFragments[1]]: value
    };
    result.set(field, {
        ...currentValue,
        ...newValue
    });
    return result;
}
export function mergeArray(dataPathFragments, result, value) {
    if (2 !== dataPathFragments.length) throw new Error(INCORRECT_LENGTH);
    const field = dataPathFragments[0];
    const currentValue = result.get(field);
    const newValue = currentValue ? [
        ...currentValue
    ] : [];
    const pathIndex = parseInt(dataPathFragments[1], 10);
    if (pathIndex > 0) {
        for(let index = 0, range = pathIndex; index < range; index += 1)if (void 0 === newValue[index]) newValue[index] = null;
    }
    newValue[pathIndex] = void 0 !== value ? value : null;
    result.set(field, newValue);
    return result;
}
