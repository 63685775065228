import { Currency } from '../enums';
export default function getCurrencyAbbrForIcon(currency) {
    switch(currency){
        case Currency.SEK:
        case Currency.NOK:
        case Currency.DKK:
            return Currency.DKK;
        case Currency.ARS:
        case Currency.MXN:
        case Currency.CLP:
        case Currency.UYU:
        case Currency.COP:
        case Currency.HKD:
        case Currency.CAD:
            return Currency.USD;
        default:
            return currency;
    }
}
