import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onBeforeUnmount, onMounted } from 'vue';
import { VLazyIframe } from 'web/src/components/Iframe';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { usePaymentsIframe } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsIframe',
    props: {
        redirectUrl: {
            default: ''
        },
        openIn: {
            default: null
        },
        redirectMethod: {
            default: null
        },
        redirectCharset: {
            default: ''
        },
        redirectFormParams: {
            default: null
        },
        isDeposit: {
            type: Boolean,
            default: true
        },
        isDeepLinksAllowed: {
            type: Boolean
        },
        mobileRedirectUrl: {
            default: '/'
        }
    },
    setup (__props) {
        const props = __props;
        const { showIframe, iframeName, iframeMounted, mounted, beforeUnmount } = usePaymentsIframe(props);
        onMounted(mounted);
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>_unref(showIframe) ? (_openBlock(), _createBlock(_unref(VLazyIframe), {
                key: 0,
                src: _ctx.redirectUrl,
                name: _unref(iframeName),
                class: _normalizeClass(_ctx.$style['payments-iframe']),
                "full-size": "",
                "hide-full-height-in-url": "",
                onVnodeMounted: _unref(iframeMounted)
            }, null, 8, [
                "src",
                "name",
                "class",
                "onVnodeMounted"
            ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                key: 1,
                class: _normalizeClass(_ctx.$style['payments-iframe-loader']),
                hint: _ctx.$t('WEB2_PLEASE_WAIT_TEXT')
            }, null, 8, [
                "class",
                "hint"
            ]));
    }
});
