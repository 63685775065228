import { computed, toRef } from 'vue';
import { useSportlineTopLeaguesStore, useSportlineTopZeroMarginStore } from 'web/src/modules/sportline/submodules/top/store';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
export function useSportlineCardsData() {
    const { isBetSwipeTopEventsLayoutEnabled } = useSportlineLayoutSettings();
    const sportlineTopLeaguesStore = useSportlineTopLeaguesStore();
    const isTopLeaguesReady = toRef(sportlineTopLeaguesStore, 'isReady');
    const topLeagues = toRef(sportlineTopLeaguesStore, 'topLeagues');
    const sportlineTopZeroMarginStore = useSportlineTopZeroMarginStore();
    const isZeroMarginReady = toRef(sportlineTopZeroMarginStore, 'isReady');
    const zeroMarginEventsList = toRef(sportlineTopZeroMarginStore, 'zeroMarginEventsList');
    // @TODO check this logic. Maybe we should not await this data to display page
    const isReady = computed(()=>!isBetSwipeTopEventsLayoutEnabled.value || isTopLeaguesReady.value && isZeroMarginReady.value);
    return {
        isReady,
        topLeagues,
        zeroMarginEventsList,
        async initialRequests () {
            if (!isBetSwipeTopEventsLayoutEnabled.value) return;
            await Promise.all([
                sportlineTopLeaguesStore.initialRequests(),
                sportlineTopZeroMarginStore.initialRequests()
            ]);
        },
        async syncBackgroundRequests () {
            if (!isBetSwipeTopEventsLayoutEnabled.value) return;
            await Promise.all([
                sportlineTopLeaguesStore.syncBackgroundRequests(),
                sportlineTopZeroMarginStore.syncBackgroundRequests()
            ]);
        },
        async setBackgroundUpdateEnabled (value) {
            if (!isBetSwipeTopEventsLayoutEnabled.value) return;
            await Promise.all([
                sportlineTopLeaguesStore.setBackgroundUpdateEnabled(value),
                sportlineTopZeroMarginStore.setBackgroundUpdateEnabled(value)
            ]);
        }
    };
}
