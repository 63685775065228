import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = [
    "src"
];
import { IconDirection, IconSize } from '@leon-hub/icons';
import { ArrowIcon } from '@components/arrow-icon';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionDetailsHowToReceiveBonus',
    props: {
        title: {
            default: ''
        },
        items: {}
    },
    setup (__props) {
        const iconProperties = {
            size: IconSize.SIZE_16,
            direction: IconDirection.SOUTH
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.title && _ctx.items.length ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("h2", {
                    class: _normalizeClass({
                        [_ctx.$style['promotions-shared__title']]: true,
                        [_ctx.$style['how-to-receive-bonus__title']]: true
                    })
                }, _toDisplayString(_ctx.title), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['steps-hint'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index)=>(_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: _normalizeClass(_ctx.$style['steps-hint__panel'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['steps-hint__image-holder'])
                            }, [
                                _createElementVNode("img", {
                                    class: _normalizeClass(_ctx.$style['steps-hint__image']),
                                    src: 'https://' + item.bonusItemImage,
                                    alt: ""
                                }, null, 10, _hoisted_2)
                            ], 2),
                            _createElementVNode("h2", {
                                class: _normalizeClass(_ctx.$style['steps-hint__heading'])
                            }, _toDisplayString(item.bonusItemTitle), 3),
                            _createVNode(VDynamicContent, {
                                class: _normalizeClass(_ctx.$style['steps-hint__text']),
                                content: item.bonusItemDescription || void 0
                            }, null, 8, [
                                "class",
                                "content"
                            ]),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['steps-hint__arrow'])
                            }, [
                                _createVNode(_unref(ArrowIcon), _mergeProps({
                                    ref_for: true
                                }, iconProperties, {
                                    class: _ctx.$style['steps-hint__arrow-icon']
                                }), null, 16, [
                                    "class"
                                ])
                            ], 2)
                        ], 2))), 128))
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'PromotionDetailsHowToReceiveBonus'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
