import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { HomePageType } from '@leon-hub/api-sdk/src/sdk/sdk';
import RouteName from '@leon-hub/routing-config-names';
export default function isCasinoLobbyRouteName(routeName, homePageType) {
    return !!routeName && ([
        CasinoRouteName.CASINO_LIVE_LOBBY,
        CasinoRouteName.CASINO_LOBBY,
        CasinoRouteName.CASINO_MY_GAMES,
        RouteName.QUICK_BETS
    ].includes(String(routeName)) || !!homePageType && [
        HomePageType.SLOTS,
        HomePageType.LIVE_GAMES
    ].includes(homePageType) && routeName === RouteName.HOME);
}
