import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { SingleSelect } from 'web/src/components/Select';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useCurrencySelect } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CurrencySelect',
    props: {
        countryCode: {},
        loaderDelay: {
            default: 400
        },
        autofocus: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        dropdownMinWidth: {},
        hidden: {
            type: Boolean
        },
        label: {},
        placeholder: {},
        isCountrySelector: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        name: {},
        selectedValue: {},
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { selectProps, isLoading, emitFocus, emitChange, emitBlur } = useCurrencySelect(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(SingleSelect), _mergeProps(_unref(selectProps), {
                onChange: _unref(emitChange),
                onFocus: _unref(emitFocus),
                onBlur: _unref(emitBlur)
            }), {
                iconPrefix: _withCtx(()=>[
                        _unref(isLoading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                            key: 0,
                            delay: _ctx.loaderDelay,
                            class: _normalizeClass(_ctx.$style['currency-select-loader'])
                        }, null, 8, [
                            "delay",
                            "class"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 16, [
                "onChange",
                "onFocus",
                "onBlur"
            ])), [
                [
                    _directive_auto_id,
                    'CurrencySelect'
                ]
            ]);
        };
    }
});
