import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import { startLoginDeviceRoutineEventType, useAppEmitter } from 'web/src/modules/emitter';
import { getDeferredDeviceIDStorage } from './getDeferredDeviceIDStorage';
import { useIsLoggedIn } from '../composables';
export async function onAppMounted() {
    const { login } = useCustomerDataStore();
    const storage = getDeferredDeviceIDStorage(login);
    const record = await storage.get();
    if (record.exists && record.value && useIsLoggedIn().isLoggedIn.value) {
        await storage.clear();
        useAppEmitter().emit(startLoginDeviceRoutineEventType);
    }
}
