import { computed } from 'vue';
import { isOptionalString } from '@leon-hub/guards';
import isSingleSelectProperties from 'web/src/components/Select/guards/isSingleSelectProperties';
import getWrapperHintProperties from '../utils/getWrapperHintProperties';
export default function useFormSelect(props) {
    const componentProps = computed(()=>{
        const { name, title, extraProperties, value, error, hintMessage, disabled, options, autofocus } = props;
        const values = extraProperties?.enum ?? [];
        isOptionalString(value);
        isSingleSelectProperties(options);
        const selectedValue = value || '';
        const isSingleValuePreselected = selectedValue && 1 === values.length && values[0] === selectedValue;
        const uiLabels = extraProperties?.labels;
        const selectOptions = values.map((currentValue)=>({
                label: uiLabels ? uiLabels[currentValue] : currentValue,
                value: currentValue
            }));
        return {
            options: selectOptions,
            selectedValue,
            placeholder: title,
            disabled: isSingleValuePreselected || disabled,
            ...options,
            ...getWrapperHintProperties({
                options,
                error,
                hintMessage
            }),
            name,
            autofocus
        };
    });
    return {
        componentProps
    };
}
