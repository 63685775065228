import { doAchievementReward } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function doAchievementRewardRequest(id) {
    const apiClient = useGraphqlClient();
    return doAchievementReward(apiClient, (node)=>node.mutations.achievements.reward, {
        options: {
            id
        }
    });
}
