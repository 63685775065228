import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick"
];
import { toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';
import { popoverProperties, useMarketsDropDown } from './useMarketsDropDown';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketsDropDown',
    props: {
        basisKey: {}
    },
    setup (__props) {
        const props = __props;
        const basisKey = toRef(props, 'basisKey');
        const { popperRef, selectedColumnId, dropdownOptions, title, onPopperShow, onActiveMarketTypeChange } = useMarketsDropDown({
            basisKey
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-list-markets-dropdown'])
            }, [
                _createVNode(VPopper, _mergeProps(_unref(popoverProperties), {
                    ref_key: "popperRef",
                    ref: popperRef,
                    onShow: _unref(onPopperShow)
                }), {
                    reference: _withCtx(()=>[
                            _createVNode(VButton, {
                                height: _unref(ButtonHeight).MEDIUM,
                                kind: _unref(ButtonKind).BASE,
                                "icon-name": _unref(IconName).DROPDOWN,
                                "is-uppercase": false,
                                "icon-right": "",
                                label: _unref(title)
                            }, null, 8, [
                                "height",
                                "kind",
                                "icon-name",
                                "label"
                            ])
                        ]),
                    default: _withCtx(()=>[
                            _createElementVNode("ul", {
                                class: _normalizeClass(_ctx.$style['sport-event-list-markets-dropdown__list'])
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dropdownOptions), (item)=>(_openBlock(), _createElementBlock("li", {
                                        key: item.value,
                                        class: _normalizeClass({
                                            [_ctx.$style['sport-event-list-markets-dropdown__list-item']]: true,
                                            [_ctx.$style['sport-event-list-markets-dropdown__list-item--selected']]: item.value === _unref(selectedColumnId)
                                        }),
                                        onClick: ($event)=>_unref(onActiveMarketTypeChange)(item.value)
                                    }, _toDisplayString(item.label), 11, _hoisted_1))), 128))
                            ], 2)
                        ]),
                    _: 1
                }, 16, [
                    "onShow"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'MarketsDropDown'
                ]
            ]);
        };
    }
});
