import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useTheLiveChatWidget } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TheLiveChatWidget',
    props: {
        newMessagesCounter: {
            default: 0
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { showCounter, badgeProps, buttonProps } = useTheLiveChatWidget(props);
        function emitClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['chat-widget'])
            }, [
                _createVNode(VButton, _mergeProps(_unref(buttonProps), {
                    "data-test-id": "chat-widget-btn",
                    onClick: emitClick
                }), null, 16),
                _unref(showCounter) ? (_openBlock(), _createBlock(VBadge, _mergeProps({
                    key: 0
                }, _unref(badgeProps), {
                    class: _ctx.$style['chat-widget__counter'],
                    "data-test-id": "chat-widget-badge",
                    onClick: emitClick
                }), null, 16, [
                    "class"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'TheLiveChatWidget'
                ]
            ]);
        };
    }
});
