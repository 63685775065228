import { defineStore } from 'pinia';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
const useCasinoAnalyticsStore = defineStore('casino-analytics', ()=>{
    const analytics = useAnalytics();
    let lobbyMetricsData;
    function sendOpenGameLobbyMetrika() {
        if (!lobbyMetricsData) return;
        const { lobbyType, groupFilterName, categoryFilterName, section, vendorName, gameName } = lobbyMetricsData;
        analytics.push(AnalyticsEvent.Z_OPEN_GAME_LOBBY, {
            gameVendorLobby: {
                [lobbyType]: {
                    [groupFilterName]: {
                        [categoryFilterName]: {
                            [section]: {
                                [vendorName]: gameName
                            }
                        }
                    }
                }
            }
        });
        lobbyMetricsData = void 0;
    }
    function setMetricsData(data) {
        lobbyMetricsData = data;
    }
    return {
        setMetricsData,
        sendOpenGameLobbyMetrika
    };
});
export default useCasinoAnalyticsStore;
