import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let PixelRatioComponent = class PixelRatioComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result([
            window.devicePixelRatio || ComponentStatus.NotAvailable
        ]);
    }
    constructor(){
        super(ComponentKey.PixelRatio);
    }
};
export { PixelRatioComponent as default };
