import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
// hack to avoid navigation issues
export function useGoToRouteName() {
    const router = useRouter();
    const goToRouteName = (routeName)=>{
        router.push({
            name: RouteName.HOME
        }).then(()=>{
            router.push({
                name: routeName
            });
        });
    };
    return {
        goToRouteName
    };
}
