import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { BetCashOut } from 'web/src/components/BetCashOut/components';
import { useCashoutView } from '../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CashoutView',
    setup (__props) {
        const { coreComponentProps, mainButtonClick, cancelClick } = useCashoutView();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(BetCashOut), _mergeProps(_unref(coreComponentProps), {
                onMainButtonClick: _unref(mainButtonClick),
                onCancel: _unref(cancelClick)
            }), null, 16, [
                "onMainButtonClick",
                "onCancel"
            ])), [
                [
                    _directive_auto_id,
                    'CashoutView'
                ]
            ]);
        };
    }
});
