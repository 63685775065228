import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { defaultCurrency } from 'web/src/modules/money/consts';
import { useCustomerHistoryListItem } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListItem',
    props: {
        currency: {
            default: defaultCurrency
        },
        item: {}
    },
    emits: [
        "item-click",
        "cash-out",
        "cancel-withdrawal"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { component, emitItemClick, emitCashOut, emitCancelWithdrawal } = useCustomerHistoryListItem(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component)), {
                currency: _ctx.currency,
                item: _ctx.item,
                onItemClick: _unref(emitItemClick),
                onCashOut: _unref(emitCashOut),
                onCancelWithdrawal: _unref(emitCancelWithdrawal)
            }, null, 40, [
                "currency",
                "item",
                "onItemClick",
                "onCashOut",
                "onCancelWithdrawal"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListItem'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'transaction-history-item',
                        type: _ctx.item.objectType
                    }
                ]
            ]);
        };
    }
});
