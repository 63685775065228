import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { useReferralProgramPopupReward } from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramPopupReward',
    props: {
        referralReward: {
            type: Boolean
        },
        table: {},
        rewardLabel: {},
        showTitle: {
            type: Boolean
        }
    },
    setup (__props) {
        const { iconProps } = useReferralProgramPopupReward();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['referral-program-popup-reward'])
            }, [
                _ctx.showTitle ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['referral-program-popup-reward__title'])
                }, _toDisplayString(_ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_REWARD')), 3)) : _createCommentVNode("", true),
                _ctx.referralReward ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['referral-program-popup-reward__list'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-popup-reward__list-header'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['referral-program-popup-reward__list-cell']]: true,
                                [_ctx.$style['referral-program-popup-reward__list-cell--left']]: true
                            })
                        }, _toDisplayString(_ctx.$t('WEB2_REFFERAL_PROGRAM_TABLE_BONUS')), 3),
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['referral-program-popup-reward__list-cell']]: true,
                                [_ctx.$style['referral-program-popup-reward__list-cell--right']]: true
                            })
                        }, _toDisplayString(_ctx.$t('WEB2_REFFERAL_PROGRAM_TABLE_DEPOSIT')), 3)
                    ], 2),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table, (item, index)=>(_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: _normalizeClass(_ctx.$style['referral-program-popup-reward__list-item'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass({
                                    [_ctx.$style['referral-program-popup-reward__list-cell']]: true,
                                    [_ctx.$style['referral-program-popup-reward__list-cell--left']]: true
                                })
                            }, _toDisplayString(item.deposit), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass({
                                    [_ctx.$style['referral-program-popup-reward__list-cell']]: true,
                                    [_ctx.$style['referral-program-popup-reward__list-cell--right']]: true
                                })
                            }, _toDisplayString(item.reward), 3)
                        ], 2))), 128))
                ], 2)) : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['referral-program-popup-reward__block'])
                }, [
                    _createVNode(_unref(VIcon), _mergeProps(_unref(iconProps), {
                        class: _ctx.$style['referral-program-popup-reward__block-icon']
                    }), null, 16, [
                        "class"
                    ]),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['referral-program-popup-reward__block-label'])
                    }, _toDisplayString(_ctx.rewardLabel), 3)
                ], 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramPopupReward'
                ]
            ]);
        };
    }
});
