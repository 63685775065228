import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useVReferralProgramStep } from 'web/src/modules/referral-program/components/ReferralProgramStep/VReferralProgramStep/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VReferralProgramStep',
    props: {
        label: {},
        to: {},
        satisfied: {
            type: Boolean
        },
        isInfo: {
            type: Boolean
        },
        green: {
            type: Boolean
        },
        isRounded: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { handleClick } = useVReferralProgramStep(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['referral-program-step']]: true,
                    [_ctx.$style['referral-program-step--satisfied']]: _ctx.satisfied,
                    [_ctx.$style['referral-program-step--link']]: !!_ctx.to,
                    [_ctx.$style['referral-program-step--info']]: !!_ctx.isInfo,
                    [_ctx.$style['referral-program-step--green']]: _ctx.green,
                    [_ctx.$style['referral-program-step--satisfied-green']]: _ctx.green && _ctx.satisfied,
                    [_ctx.$style['referral-program-step--is-rounded']]: _ctx.isRounded
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(handleClick) && _unref(handleClick)(...args);
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-step__left'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-step__icon'])
                    }, [
                        _createVNode(_unref(VIcon), {
                            name: _ctx.satisfied ? _unref(IconName).CHECK_FILLED : _unref(IconName).FAKE_CHECKBOX
                        }, null, 8, [
                            "name"
                        ])
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-step__label'])
                    }, _toDisplayString(_ctx.label), 3)
                ], 2),
                _ctx.satisfied ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['referral-program-step__button'])
                }, _toDisplayString(_ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_READY')), 3)) : (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['referral-program-step__arrow']),
                    name: _unref(IconName).EXPAND_RIGHT,
                    size: _unref(IconSize).SIZE_16
                }, null, 8, [
                    "class",
                    "name",
                    "size"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VReferralProgramStep'
                ]
            ]);
        };
    }
});
