import { toRef, onBeforeMount, onActivated, onDeactivated, onBeforeUnmount, getCurrentInstance } from 'vue';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';
/**
 * Use to pass meta parameters defined in component
 */ export default function useSeoMetaParameters(params) {
    let removeParameters;
    const seoMetaStorage = useSeoMetaStorage();
    const metaParameters = toRef(seoMetaStorage, 'metaParameters');
    function addMetaParameters() {
        const uid = getCurrentInstance()?.uid;
        if (!uid) throw new Error('Component not initialized');
        if (removeParameters) // already set ref
        return;
        seoMetaStorage.setMetaParameter(uid, params);
        removeParameters = ()=>{
            seoMetaStorage.setMetaParameter(uid, null);
        };
    }
    function unwatchMetaParameters() {
        removeParameters?.();
        removeParameters = void 0;
    }
    onBeforeMount(addMetaParameters);
    onActivated(addMetaParameters);
    onBeforeUnmount(unwatchMetaParameters);
    onDeactivated(unwatchMetaParameters);
    return {
        metaParameters
    };
}
