import { isNumber, isString, isUndefined, isValidInterface } from '@leon-hub/guards';
import { isCompetitorType } from './isCompetitorType';
export const isCoreCompetitorResponseFieldGuards = {
    id: isNumber,
    name: isString,
    homeAway: isCompetitorType,
    type: [
        isUndefined,
        isString
    ],
    logo: [
        isUndefined,
        isString
    ]
};
export function isCoreCompetitorResponse(value) {
    return isValidInterface(value, isCoreCompetitorResponseFieldGuards);
}
