export var ErrorCodeWithdrawal;
var ErrorCodeWithdrawal1;
(ErrorCodeWithdrawal1 = ErrorCodeWithdrawal || (ErrorCodeWithdrawal = {}))["VERIFICATION_NEEDED"] = "VERIFICATION_NEEDED", ErrorCodeWithdrawal1["PAYOUT_BLOCKED"] = "PAYOUT_BLOCKED", ErrorCodeWithdrawal1["IDENT_NEEDED"] = "IDENT_NEEDED";
export var ErrorCodeDeposit;
(ErrorCodeDeposit || (ErrorCodeDeposit = {}))["EKYC_NEEDED"] = "EKYC_NEEDED";
export var PaymentsFormStep;
var PaymentsFormStep1;
(PaymentsFormStep1 = PaymentsFormStep || (PaymentsFormStep = {}))["SMS_CODE"] = "SMS_CODE", PaymentsFormStep1["INITIAL"] = "INITIAL", PaymentsFormStep1["NEW_CARD"] = "NEW_CARD", PaymentsFormStep1["CVC"] = "CVC", PaymentsFormStep1["SECOND"] = "SECOND";
export var PaymentsCardField;
var PaymentsCardField1;
// no longer used step LEONWEB-7405
(PaymentsCardField1 = PaymentsCardField || (PaymentsCardField = {}))["CARD_NUMBER"] = "cardnumber", PaymentsCardField1["CARD_HOLDER"] = "cardholder", PaymentsCardField1["CARD_EXPIRATION"] = "expiration", PaymentsCardField1["CARD_CVC"] = "cvc", PaymentsCardField1["CARD_CVV"] = "cvv", PaymentsCardField1["CARD"] = "card";
export var PaymentsCardTypes;
var PaymentsCardTypes1;
// withdrawal cardnumber field
(PaymentsCardTypes1 = PaymentsCardTypes || (PaymentsCardTypes = {}))["MASTER_CARD_NEW"] = "MASTER_CARD_NEW", PaymentsCardTypes1["MASTER_CARD"] = "MASTER_CARD", PaymentsCardTypes1["VISA_ELECTRON"] = "VISA_ELECTRON", PaymentsCardTypes1["VISA"] = "VISA", PaymentsCardTypes1["MAESTRO"] = "MAESTRO", PaymentsCardTypes1["AMEX"] = "AMEX", PaymentsCardTypes1["DINERS"] = "DINERS", PaymentsCardTypes1["DISCOVER"] = "DISCOVER", PaymentsCardTypes1["JCB"] = "JCB", PaymentsCardTypes1["MIR"] = "MIR", PaymentsCardTypes1["HUMO"] = "HUMO", PaymentsCardTypes1["UZCARD"] = "UZCARD";
export var CardTypesValidationPatterns;
var CardTypesValidationPatterns1;
(CardTypesValidationPatterns1 = CardTypesValidationPatterns || (CardTypesValidationPatterns = {}))["AMEX"] = "^(3[47])", CardTypesValidationPatterns1["DINERS"] = "^((30[0-59]{1})|(3[689]{1}[0-9]{1}))", CardTypesValidationPatterns1["DISCOVER"] = "^(6((5[0-9][4])|(4[4-9][0-9]{3})|(22(12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5]))|(011[0-9]{2})))", CardTypesValidationPatterns1["JCB"] = "^(35(2[8-9]|[3-8][0-9]))", CardTypesValidationPatterns1["MAESTRO"] = "^((5018)|(5020)|(5038)|(5612)|(5893)|(6304)|(67[0-9]{2})|(0604)|(6390))", CardTypesValidationPatterns1["VISA_ELECTRON"] = "^((417500)|((4026)|(4405)|(4508)|(4844)|(4913)|(4917)))", CardTypesValidationPatterns1["VISA"] = "^(4(?!(17500)|(026)|(405)|(508)|(844)|(913)|(917)))", CardTypesValidationPatterns1["MIR"] = "^(220([0-4]))", CardTypesValidationPatterns1["MASTER_CARD"] = "^((5([1-5]))|(22[2-9])|(2[3-7]))", CardTypesValidationPatterns1["HUMO"] = "^9860", CardTypesValidationPatterns1["UZCARD"] = "^(8600|5614)";
export var PaymentsStatus;
var PaymentsStatus1;
(PaymentsStatus1 = PaymentsStatus || (PaymentsStatus = {}))["OK"] = "OK", PaymentsStatus1["LOADING"] = "LOADING", PaymentsStatus1["ERROR"] = "ERROR", PaymentsStatus1["SUCCESS"] = "SUCCESS";
export var PaymentsSubmitAction;
var PaymentsSubmitAction1;
(PaymentsSubmitAction1 = PaymentsSubmitAction || (PaymentsSubmitAction = {}))["SUBMIT"] = "submit", PaymentsSubmitAction1["CANCEL"] = "cancel", PaymentsSubmitAction1["RETRY"] = "retry", PaymentsSubmitAction1["NEXT_STEP_SUBMIT"] = "next_step_submit";
export var PaymentsDepositStatus;
var PaymentsDepositStatus1;
(PaymentsDepositStatus1 = PaymentsDepositStatus || (PaymentsDepositStatus = {}))["SUCCESS"] = "success", PaymentsDepositStatus1["PROCESS"] = "process", PaymentsDepositStatus1["FAIL"] = "fail";
export var InvalidPaymentsExceptionCode;
var InvalidPaymentsExceptionCode1;
(InvalidPaymentsExceptionCode1 = InvalidPaymentsExceptionCode || (InvalidPaymentsExceptionCode = {}))["PAYMENT_ERROR"] = "PAYMENT_ERROR", InvalidPaymentsExceptionCode1["PAYMENT_ERROR_WRONG_PAN"] = "PAYMENT_ERROR_WRONG_PAN", InvalidPaymentsExceptionCode1["PAYMENT_ERROR_DATE_EXPIRED"] = "PAYMENT_ERROR_DATE_EXPIRED", InvalidPaymentsExceptionCode1["PAYMENT_ERROR_WRONG_EXP_DATE"] = "PAYMENT_ERROR_WRONG_EXP_DATE", InvalidPaymentsExceptionCode1["PAYMENT_ERROR_WRONG_NAME"] = "PAYMENT_ERROR_WRONG_NAME";
export var InvalidCreditCardTokenizeCode;
(InvalidCreditCardTokenizeCode || (InvalidCreditCardTokenizeCode = {}))["TOKENIZE_ERROR_INVALID_PAN"] = "INVALID_PAN";
export var PaymentsModalType;
var PaymentsModalType1;
(PaymentsModalType1 = PaymentsModalType || (PaymentsModalType = {}))["DEPOSITS_OTHER_SUCCESS_MODAL"] = "DEPOSITS_OTHER_SUCCESS_MODAL", PaymentsModalType1["WITHDRAWAL_CANCEL_SUCCESS_MODAL"] = "WITHDRAWAL_CANCEL_SUCCESS_MODAL", PaymentsModalType1["WITHDRAWAL_CANCEL_MODAL"] = "WITHDRAWAL_CANCEL_MODAL";
export var PaymentsIdentificationButtonName;
var PaymentsIdentificationButtonName1;
(PaymentsIdentificationButtonName1 = PaymentsIdentificationButtonName || (PaymentsIdentificationButtonName = {}))["CHECK_STATUS"] = "checkStatus", PaymentsIdentificationButtonName1["RETRY"] = "retry", PaymentsIdentificationButtonName1["CLOSE"] = "close", PaymentsIdentificationButtonName1["TO_SUPPORT"] = "toSupport";
export var PaymentFormFieldName;
var PaymentFormFieldName1;
(PaymentFormFieldName1 = PaymentFormFieldName || (PaymentFormFieldName = {}))["AMOUNT"] = "amount", PaymentFormFieldName1["CURRENCY"] = "currency", PaymentFormFieldName1["CUST_BCODES"] = "cust_bcodes", PaymentFormFieldName1["DOACTION"] = "doaction", PaymentFormFieldName1["PAYMENT_SYSTEM_ID"] = "paymentSystemId", PaymentFormFieldName1["PMTMETHOD"] = "pmtmethod", PaymentFormFieldName1["CARDHOLDER"] = "cardholder", PaymentFormFieldName1["CARDNUMBER"] = "cardnumber", PaymentFormFieldName1["CARDNUMBER_WITHDRAWAL"] = "card", PaymentFormFieldName1["CARDNUMBER_SHOW"] = "cardnumber_show", PaymentFormFieldName1["CVC"] = "cvc", PaymentFormFieldName1["EXPIRATION"] = "expiration", PaymentFormFieldName1["SAVE_CARD"] = "saveCard", PaymentFormFieldName1["STEP"] = "step", PaymentFormFieldName1["PHONE"] = "phone";
export var PaymentsNDFLType;
var PaymentsNDFLType1;
(PaymentsNDFLType1 = PaymentsNDFLType || (PaymentsNDFLType = {}))["NETTO_AMOUNT"] = "nettoAmount", PaymentsNDFLType1["BRUTTO_AMOUNT"] = "bruttoAmount", PaymentsNDFLType1["TAX_AMOUNT"] = "taxAmount";
