import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsWithdrawalCancelModal',
    props: {
        text: {},
        secondaryText: {},
        isInProfile: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "confirm-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { $translate } = useI18n();
        function onButtonClick(param) {
            let { action } = param;
            if (action === DialogAction.CONFIRM) emit('confirm-click');
            if (action === DialogAction.MODAL_CLOSE) emit('close');
        }
        const modalProperties = computed(()=>({
                isInnerModal: true,
                ...getImageOrIcon({
                    icon: IconName.ATTENTION_OUTLINE,
                    alertIcon: AlertIconName.QuestionMark
                }),
                fullHeight: false,
                title: $translate('WEB2_CANCEL_PAYMENT_WITHDRAWAL_TITLE').value,
                isProfileLayout: props.isInProfile,
                buttons: [
                    {
                        action: DialogAction.CONFIRM,
                        kind: ButtonKind.PRIMARY,
                        label: $translate('WEB2_YES_CANCEL').value
                    },
                    {
                        action: DialogAction.MODAL_CLOSE,
                        kind: ButtonKind.TRANSPARENT,
                        label: $translate('WEB2_DO_NOT_CANCEL').value
                    }
                ]
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                modal: modalProperties.value,
                onButtonClick: onButtonClick,
                onClose: _cache[0] || (_cache[0] = ($event)=>emit('close'))
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['deposits-success__text'])
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.secondaryText), 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "modal"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsWithdrawalCancelModal'
                ]
            ]);
        };
    }
});
