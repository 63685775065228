import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';
import { isHttps, getLocationHost } from '@leon-hub/service-locator-env';
import { isWebSocketData, isWSSupported } from '../helpers';
import { WebSocketMessageType } from '../enums';
let WebSocketHolder = class WebSocketHolder {
    connect() {
        if (!isWSSupported()) return;
        this.initConnection();
    }
    initConnection() {
        if (!this.socket) try {
            const wsUrl = `ws${this.isHttps ? 's' : ''}://${this.host}/subscriptions`;
            this.socket = new WebSocket(wsUrl);
            this.checkOpenedConnectionTimer = Timer.setTimeout(()=>{
                this.socket;
                if (this.socket.readyState === WebSocket.CONNECTING) this.reconnect();
                this.checkOpenedConnectionTimer = 0;
            }, this.openSocketTimeout);
            const { socket } = this;
            socket.addEventListener('open', ()=>{
                this.sendEvent({
                    type: WebSocketMessageType.GQL_CONNECTION_INIT,
                    payload: {}
                });
            });
            socket.addEventListener('message', this.onSocketMessage.bind(this));
            socket.addEventListener('close', (event)=>{
                if (1000 !== event.code) {
                    logger.warn(`[WebSocket] connection closed: ${JSON.stringify(event)}`);
                    this.reconnect();
                }
            });
            socket.addEventListener('error', (event)=>{
                logger.warn(`[WebSocket] connection error: ${JSON.stringify(event)}`);
                this.isReconnected = false;
                this.isConnectionAck = false;
                this.reconnect();
            });
        } catch (error) {
            logger.error('[WebSocket] connection error', error);
            this.reconnect();
        }
    }
    subscribe(param) {
        let { id, query, variables, onMessage, onError, onComplete } = param;
        if (!isWSSupported()) return;
        if (this.subscriptions[id]) return;
        const subscription = {
            id,
            isSubscribed: false,
            query,
            variables,
            onMessage,
            onError,
            onComplete
        };
        this.subscriptions[id] = subscription;
        this.applySubscription(subscription);
    }
    unsubscribe(param) {
        let { id } = param;
        if (this.subscriptions[id]) {
            if (this.subscriptions[id].isSubscribed) this.sendEvent({
                id,
                type: WebSocketMessageType.GQL_STOP
            });
            delete this.subscriptions[id];
        }
    }
    async disconnect() {
        if (this.isDisconnected) return;
        if (this.socket) {
            if (this.isConnectionAck) {
                for (const subscription of Object.values(this.subscriptions))if (subscription.isSubscribed) {
                    this.sendEvent({
                        id: subscription.id,
                        type: WebSocketMessageType.GQL_STOP
                    });
                    // eslint-disable-next-line no-param-reassign
                    subscription.isSubscribed = false;
                }
                this.sendEvent({
                    type: WebSocketMessageType.GQL_CONNECTION_TERMINATE
                });
            }
            this.socket?.close();
            await this.waitSocketClose();
            this.socket = null;
        }
        this.clearCheckOpenedConnectionTimer();
        this.stopKeepAliveCheck();
        this.keepAliveDate = 0;
        this.sessionExpiresAt = 0;
        this.options.onDisconnect();
        this.isDisconnected = true;
    }
    clearCheckOpenedConnectionTimer() {
        if (this.checkOpenedConnectionTimer) {
            Timer.clearTimeout(this.checkOpenedConnectionTimer);
            this.checkOpenedConnectionTimer = 0;
        }
    }
    sendEvent(data) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(data));
            if (data.type === WebSocketMessageType.GQL_CONNECTION_TERMINATE) {
                this.socket.close();
                this.socket = null;
            }
        }
    }
    waitSocketClose() {
        return new Promise((resolve)=>{
            // eslint-disable-next-line unicorn/consistent-function-scoping
            const isConnectionClosed = ()=>!this.socket || this.socket.readyState === WebSocket.CLOSED;
            if (isConnectionClosed()) {
                resolve();
                return;
            }
            const waitIsConnectionClosed = ()=>{
                Timer.setTimeout(()=>{
                    if (isConnectionClosed()) resolve();
                    else waitIsConnectionClosed();
                }, 5);
            };
            waitIsConnectionClosed();
        });
    }
    onSocketMessage(event) {
        const data = JSON.parse(event.data);
        isWebSocketData(data);
        switch(data.type){
            case WebSocketMessageType.GQL_CONNECTION_ACK:
                this.onConnectionAck(data);
                break;
            case WebSocketMessageType.GQL_CONNECTION_KEEP_ALIVE:
                this.onKeepAlive();
                break;
            case WebSocketMessageType.GQL_DATA:
                this.onMessageData(data);
                break;
            case WebSocketMessageType.GQL_ERROR:
                this.onMessageError(data);
                break;
            case WebSocketMessageType.GQL_COMPLETE:
                this.onMessageComplete(data);
                break;
            case WebSocketMessageType.GQL_CONNECTION_ERROR:
                this.onServerConnectionError(data);
                break;
            case WebSocketMessageType.GQL_RECONNECT:
                this.reconnect();
                break;
            default:
                break;
        }
    }
    startKeepAliveCheck() {
        this.stopKeepAliveCheck();
        this.keepAliveInterval = Timer.setInterval(this.pingKeepAlive.bind(this), 1000);
    }
    stopKeepAliveCheck() {
        if (this.keepAliveInterval) {
            Timer.clearInterval(this.keepAliveInterval);
            this.keepAliveInterval = 0;
        }
    }
    pingKeepAlive() {
        const serverKANotReceived = this.keepAliveDate > 0 && this.keepAliveDate < Date.now() - this.serverKeepAliveTimeout;
        const clientKAExpired = Date.now() > this.sessionExpiresAt;
        if (serverKANotReceived || clientKAExpired) this.reconnect();
    }
    onKeepAlive() {
        this.keepAliveDate = Date.now();
    }
    onConnectionAck(data) {
        this.sessionExpiresAt = new Date(data.payload.expiresAt).getTime();
        this.isConnectionAck = true;
        this.clearCheckOpenedConnectionTimer();
        for (const subscription of Object.values(this.subscriptions))this.applySubscription(subscription);
        this.startKeepAliveCheck();
        this.options.onConnectionAck();
    }
    onMessageData(data) {
        if (this.subscriptions[data.id]) {
            const subscription = this.subscriptions[data.id];
            if (subscription.onMessage) subscription.onMessage(data.payload.data);
        }
    }
    onMessageError(data) {
        if (this.subscriptions[data.id]) {
            const subscription = this.subscriptions[data.id];
            if (subscription.onError) subscription.onError(new Error(data.payload.message));
        }
    }
    onMessageComplete(data) {
        if (this.subscriptions[data.id]) {
            const subscription = this.subscriptions[data.id];
            if (subscription.onComplete) subscription.onComplete();
        }
    }
    onServerConnectionError(data) {
        logger.error('[WebSocket] server connection error', data.payload);
        this.reconnect();
    }
    applySubscription(subscription) {
        if (this.isConnectionAck) {
            this.socket;
            // eslint-disable-next-line no-param-reassign
            subscription.isSubscribed = true;
            this.sendEvent({
                id: subscription.id,
                type: WebSocketMessageType.GQL_START,
                payload: {
                    query: subscription.query,
                    variables: subscription.variables
                }
            });
        }
    }
    async reconnect() {
        if (this.isReconnected) return;
        if (!this.isConnectionAck) await this.disconnect();
        this.options.onReconnect();
        this.isReconnected = true;
    }
    isAckReceived() {
        return this.isConnectionAck;
    }
    constructor(options){
        _define_property(this, "socket", null);
        _define_property(this, "subscriptions", {});
        _define_property(this, "options", void 0);
        _define_property(this, "isConnectionAck", false);
        _define_property(this, "keepAliveDate", 0);
        _define_property(this, "keepAliveInterval", 0);
        _define_property(this, "serverKeepAliveTimeout", 20000);
        _define_property(this, "sessionExpiresAt", 0);
        _define_property(this, "isReconnected", false);
        _define_property(this, "isDisconnected", false);
        _define_property(this, "openSocketTimeout", 10000);
        _define_property(this, "checkOpenedConnectionTimer", 0);
        _define_property(this, "host", void 0);
        _define_property(this, "isHttps", void 0);
        this.host = getLocationHost();
        this.isHttps = isHttps();
        this.options = options;
        this.connect();
    }
};
export { WebSocketHolder as default };
