import { lobbyGameTypeSlots, lobbyTypeSlots, lobbyGameTypeLive, lobbyTypeFastgames, lobbyTypeLiveCasino } from '@leon-hub/api-sdk';
export default function getLobbyTypeByCategoryGamesType(type) {
    switch(type){
        case lobbyGameTypeLive:
            return lobbyTypeLiveCasino;
        case lobbyTypeFastgames:
            return lobbyTypeFastgames;
        case lobbyGameTypeSlots:
        default:
            return lobbyTypeSlots;
    }
}
