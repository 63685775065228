export default function getRulesFromNotPassedGroups(groups, password) {
    return groups.reduce((result, group)=>{
        let countValidRules = 0;
        const currentRules = group.rules.map((rule)=>{
            const isValid = new RegExp(rule.regexp).test(password);
            if (isValid) countValidRules += 1;
            return {
                message: rule.message,
                isValid
            };
        });
        const isNeedToFilter = countValidRules >= group.requiredValidRules;
        return [
            ...result,
            ...currentRules.filter((rule)=>!isNeedToFilter || rule.isValid)
        ];
    }, []);
}
