import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot } from "vue";
import { useVAmountAnimator } from 'web/src/components/AmountAnimator/VAmountAnimator/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VAmountAnimator',
    props: {
        amount: {},
        duration: {},
        timeRange: {},
        stepRange: {
            default: ()=>({
                    min: 0,
                    max: 0
                })
        },
        isInfinite: {
            type: Boolean
        },
        isHidden: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { animatedValue } = useVAmountAnimator(props);
        return (_ctx, _cache)=>_renderSlot(_ctx.$slots, "default", {
                animatedValue: _unref(animatedValue)
            });
    }
});
