import { computed, toRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { SportlineNavigationSection } from 'web/src/modules/sportline/submodules/navigation/enums';
import useSportlineSidebarStore from 'web/src/modules/sportline/submodules/sidebar/store/useSportlineSidebarStore';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { useSportsListDateFilter } from 'web/src/modules/sportline/submodules/sports-list/components/composables';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
import { getSportPageNavigationParameters, isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { resolveLeaguePageLink, resolveRegionPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import resolveSportPageLink from 'web/src/modules/sportline/submodules/sidebar/utils/resolveSportPageLink';
import getSidebarMenuCatalogClickPayload from 'web/src/modules/sportline/submodules/sidebar/utils/getSidebarMenuCatalogClickPayload';
import { LayoutSizes } from 'web/src/modules/core/enums';
import { prepareNavigationBetweenPagesSportsFilter } from 'web/src/modules/sportline/submodules/navigation/utils';
export default function useSidebarComponent() {
    const router = useRouter();
    const bus = useEventsBus();
    const sidebarStore = useSportlineSidebarStore();
    const sportlineNavigationStore = useSportlineNavigationStore();
    const sportlineNavigationSection = toRef(sportlineNavigationStore, 'sportlineNavigationSection');
    const { setSportlineNavigationBetweenPagesFilter, setSportlineReloadFiltersLocation } = sportlineNavigationStore;
    const { isSportFiltersEnabled, maxTopLeaguesInTab, maxTopLeaguesTabsInSidebar, fullSportsList, actualSportsList, sportsListFilter, fullFavoriteLeagues } = storeToRefs(sidebarStore);
    const { setSportsListFilter, setBackgroundUpdateEnabled } = sidebarStore;
    const { dateTimeFilterCustomFrom: datetimeCustomFrom, dateTimeFilterCustomTo: datetimeCustomTo, dateTimeFilterActiveKickoffPeriod: datetimeActiveKickoffPeriod, dateTimeFilterActiveSportlineType: datetimeActiveSportlineType } = useSportsListDateFilter({
        dateTimeFilter: sportsListFilter
    });
    const defaultState = computed(()=>{
        const { sportFamily, leagueId, regionId } = getSportPageNavigationParameters(router.currentRoute.value);
        return Object.freeze({
            sportFamily,
            regionId,
            leagueId
        });
    });
    function isActiveLeagueElement(element) {
        const league = element?.league;
        const id = league?.id;
        return !!id && defaultState.value.leagueId === id;
    }
    function isActiveRegionElement(element) {
        const region = element?.region;
        const id = region?.id;
        const isRegionSelected = !!id && defaultState.value.regionId === id;
        return isRegionSelected || (element.leagues || []).some((league)=>isActiveLeagueElement(league));
    }
    function isActiveSportElement(element) {
        const isSportSelected = isSportFamilyEquals(element?.sport, defaultState.value.sportFamily);
        if (isSportSelected) return true;
        return (element.regions ?? []).some((region)=>isActiveRegionElement(region));
    }
    function isActiveCyberSportRegionElement(element) {
        const currentRouteValue = router.currentRoute.value;
        if (currentRouteValue.name !== RouteName.SPORTLINE_CYBERSPORT) return false;
        const { region } = currentRouteValue.params;
        return region === element.region.navigationParameters.urlName;
    }
    function isActiveCyberSportElement() {
        return router.currentRoute.value.name === RouteName.SPORTLINE_CYBERSPORT;
    }
    function isCyberSportElement(element) {
        return element.sport.segment.id === SportSegmentId.CyberSport;
    }
    function onDateTimeFilterSelect(filter) {
        setSportsListFilter(filter);
    }
    function setDateTimeFilterCustomRange(range) {
        setSportsListFilter(range);
    }
    function catalogClick(param) {
        let { event, payload } = param;
        if (payload) {
            const catalogClickPayload = getSidebarMenuCatalogClickPayload(payload);
            bus.emit(BusEvent.CATALOG_CLICK, {
                event,
                payload: catalogClickPayload
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (document.documentElement.clientWidth < LayoutSizes.ShowLeftSidebarBreakpoint) bus.emit(BusEvent.SIDE_MENU_TOGGLE, {});
    }
    function beforeSidebarNavigate(param) {
        let { event, to, payload } = param;
        const selectedDateTimeFilter = prepareNavigationBetweenPagesSportsFilter(sportsListFilter.value);
        if (router.canNavigateTo(to)) setSportlineNavigationBetweenPagesFilter(selectedDateTimeFilter);
        else {
            setSportlineNavigationBetweenPagesFilter(selectedDateTimeFilter);
            setSportlineReloadFiltersLocation(to.name);
        }
        catalogClick({
            event,
            payload
        });
    }
    const menu = computed(()=>{
        // https://jira.leoncorp.net/browse/LEONWEB-2140
        const isOpenByDefault = !!process.env.VUE_APP_PRERENDER;
        return actualSportsList.value?.map((sportElement)=>{
            const L1Key = `level1-spoiler-${sportElement.sport.id}-${defaultState.value.sportFamily || 'default'}`;
            const isCyberSport = isCyberSportElement(sportElement);
            return {
                key: L1Key,
                id: sportElement.sport.id,
                location: resolveSportPageLink(sportElement.sport),
                name: sportElement.sport.name,
                canOpen: true,
                active: isOpenByDefault || isActiveSportElement(sportElement) || isCyberSport && isActiveCyberSportElement(),
                menu: sportElement.regions?.map((regionElement)=>({
                        active: isOpenByDefault || isActiveRegionElement(regionElement) || isCyberSport && isActiveCyberSportRegionElement(regionElement),
                        key: `level2-spoiler-${regionElement.region.id}-${defaultState.value.regionId || 'default'}`,
                        location: resolveRegionPageLink(regionElement.region.navigationParameters),
                        name: regionElement.region.name,
                        menu: regionElement.leagues?.map((leagueElement)=>({
                                active: isActiveLeagueElement(leagueElement),
                                id: leagueElement.league.id,
                                key: `level3-${leagueElement.league.id}`,
                                location: resolveLeaguePageLink(leagueElement.league.navigationParameters),
                                name: leagueElement.league.name
                            })) ?? []
                    })) ?? []
            };
        }) ?? [];
    });
    const topLeaguesDefaultSportId = computed(()=>{
        if (sportlineNavigationSection.value === SportlineNavigationSection.CyberSport) {
            const cyberSport = fullSportsList.value.find((sportElement)=>sportElement.sport.segment.id === SportSegmentId.CyberSport);
            return cyberSport?.sport.id ?? null;
        }
        return null;
    });
    return {
        isSportFiltersEnabled,
        maxTopLeaguesInTab,
        maxTopLeaguesTabsInSidebar,
        fullSportsList,
        fullFavoriteLeagues,
        topLeaguesDefaultSportId,
        datetimeActiveKickoffPeriod,
        datetimeActiveSportlineType,
        datetimeCustomFrom,
        datetimeCustomTo,
        menu,
        onDateTimeFilterSelect,
        setDateTimeFilterCustomRange,
        setBackgroundUpdateEnabled,
        beforeSidebarNavigate,
        catalogClick
    };
}
