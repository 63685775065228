import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';
import { onWindowVisibilityChanged } from '@leon-hub/browser-composables';
import { isWSSupported } from '../helpers';
import WebSocketHolder from './WebSocketHolder';
let GraphQLWebSocketService = class GraphQLWebSocketService {
    isSupported() {
        return this.isEnabled;
    }
    async initConnection() {
        let reconnect = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false, force = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        if (!this.hasWsEnabledSubscription()) return;
        if (this.connectionTimer) {
            Timer.clearTimeout(this.connectionTimer);
            this.connectionTimer = 0;
            return;
        }
        const diffDate = Date.now() - this.connectionDate;
        if (force || diffDate > this.reconnectTimeout) await this.initSocket(reconnect);
        else this.connectionTimer = Timer.setTimeout(()=>{
            this.initSocket(reconnect);
            this.connectionTimer = 0;
        }, this.reconnectTimeout - diffDate);
    }
    initSocket() {
        let reconnect = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        return new Promise((resolve)=>{
            if (!this.socket || reconnect) {
                for (const socket of this.sockets)if (!socket.isAckReceived()) socket.disconnect();
                this.connectionDate = Date.now();
                const newSocket = new WebSocketHolder({
                    onError: ()=>{
                        this.checkConnections();
                    },
                    onReconnect: ()=>{
                        this.reconnectInternal(false);
                    },
                    onConnectionAck: async ()=>{
                        await this.socket?.disconnect();
                        this.socket = newSocket;
                        for (const subscription of Object.values(this.subscriptions))if (subscription.isWebSocketsEnabled()) newSocket.subscribe(subscription.getWsSubscriptionInput());
                        this.checkConnections();
                        resolve();
                    },
                    onDisconnect: ()=>{
                        this.sockets = this.sockets.filter((socket)=>socket !== newSocket);
                        this.checkConnections();
                        resolve();
                    }
                });
                this.sockets.push(newSocket);
            } else resolve();
        });
    }
    checkConnections() {
        if (this.sockets.some((socket)=>socket.isAckReceived())) this.stopAllPollingRequests();
        else this.startAllPollingRequests();
    }
    connect() {
        return this.initConnection(true, true);
    }
    setEnabled(isEnabled) {
        this.isEnabled = isEnabled;
        if (this.isEnabled) for (const subscription of Object.values(this.subscriptions))this.initSubscription(subscription);
        else {
            this.disconnect();
            for (const subscription of Object.values(this.subscriptions))subscription.startPollingRequest();
        }
    }
    subscribe(subscription) {
        if (this.isSubscribed(subscription)) {
            logger.warn(`[WebSocket] Already subscribed to event ${subscription.method}. Please unsubscribe first.`);
            return;
        }
        this.subscriptions[subscription.method] = subscription;
        this.initSubscription(subscription);
        if (this.socket?.isAckReceived()) subscription.stopPollingRequest();
    }
    initSubscription(subscription) {
        if (this.isSupported() && subscription.isWebSocketsEnabled()) {
            if (!this.sockets.length) this.connect();
            for (const socket of this.sockets)socket.subscribe(subscription.getWsSubscriptionInput());
        } else subscription.startPollingRequest();
    }
    hasWsEnabledSubscription() {
        return !!Object.values(this.subscriptions).some((subscription)=>subscription.isWebSocketsEnabled());
    }
    unsubscribe(subscription) {
        for (const socket of this.sockets)socket.unsubscribe({
            id: subscription.method
        });
        subscription.stopPollingRequest();
        if (this.subscriptions[subscription.method]) delete this.subscriptions[subscription.method];
        if (!this.hasWsEnabledSubscription()) this.disconnect();
    }
    async disconnect() {
        if (this.connectionTimer) {
            Timer.clearTimeout(this.connectionTimer);
            this.connectionTimer = 0;
        }
        await Promise.all(this.sockets.map((socket)=>socket.disconnect()));
    }
    reconnectInternal(force) {
        return this.initConnection(true, force);
    }
    reconnect() {
        return this.reconnectInternal(true);
    }
    isSubscribed(subscription) {
        return !!this.subscriptions[subscription.method];
    }
    stopAllPollingRequests() {
        for (const subscription of Object.values(this.subscriptions))if (subscription.isWebSocketsEnabled()) subscription.stopPollingRequest();
    }
    startAllPollingRequests() {
        for (const subscription of Object.values(this.subscriptions))subscription.startPollingRequest();
    }
    callPollingRequests() {
        for (const subscription of Object.values(this.subscriptions))subscription.callPollingRequest();
    }
    constructor(){
        _define_property(this, "isEnabled", isWSSupported());
        _define_property(this, "socket", null);
        _define_property(this, "subscriptions", {});
        _define_property(this, "sockets", []);
        _define_property(this, "connectionDate", 0);
        _define_property(this, "reconnectTimeout", 5000);
        _define_property(this, "connectionTimer", 0);
        window.addEventListener('beforeunload', ()=>{
            this.disconnect();
            this.checkConnections();
        });
        onWindowVisibilityChanged((isVisible)=>{
            if (isVisible) {
                this.callPollingRequests();
                this.reconnectInternal(false);
            }
        }).addVisibilityChangeEventListener();
    }
};
export { GraphQLWebSocketService as default };
