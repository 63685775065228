import { computed, toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { BetlineFlag } from 'web/src/modules/sportline/enums/rest';
export function useSportlineTopEventsSettings() {
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isTopEventsEnabled = toRef(()=>sportlineSettingsStore.isTopEventsEnabled);
    const isLiveOutrightsEnabled = toRef(()=>sportlineSettingsStore.isLiveOutrightsEnabled);
    const isTopEventsLogosEnabled = toRef(()=>sportlineSettingsStore.isTopEventsLogosEnabled);
    const isMarketTypesSelectionEnabled = toRef(()=>sportlineSettingsStore.isMarketTypesSelectionEnabled);
    const parseSportlineSettings = toRef(()=>sportlineSettingsStore.parseSportlineSettings);
    const defaultRestApiFlags = toRef(()=>sportlineSettingsStore.defaultRestApiFlags);
    const flags = computed(()=>defaultRestApiFlags.value.filter((flag)=>flag !== BetlineFlag.NoDuplicate));
    return {
        isTopEventsEnabled,
        isLiveOutrightsEnabled,
        isTopEventsLogosEnabled,
        isMarketTypesSelectionEnabled,
        parseSportlineSettings,
        defaultRestApiFlags,
        flags
    };
}
