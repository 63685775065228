function escape(text) {
    return text.replace(/[\s#$()*+,-.?[\\\]^{|}]/g, '\\$&');
}
function wrapPlaceholder(value) {
    return `\\\${${String(value)}}`;
}
function unwrapPlaceholder(value) {
    return value.slice(2, -1);
}
const createReplacer = (map)=>(match)=>String(map[unwrapPlaceholder(match)]);
function replace(content, replacements) {
    const rule = Object.keys(replacements).filter((key)=>void 0 !== replacements[key]).map(escape).map(wrapPlaceholder).join('|');
    if (rule) {
        const regExp = new RegExp(rule, 'g');
        return content.replace(regExp, createReplacer(replacements));
    }
    return content;
}
export default replace;
