import { CasinoIconName } from '@leon-hub/icons';
export function useGetIcon() {
    function getIcon(item) {
        if ('LOBBY' === item) return CasinoIconName.CASINO_LOBBY;
        if ('ALL' === item) return CasinoIconName.CASINO_ALL;
        return CasinoIconName.CASINO_FAVORITES;
    }
    return {
        getIcon
    };
}
