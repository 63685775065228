import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
export default function createMessageFromCms(notification) {
    return {
        id: notification.id,
        key: `${notification.id}-${CustomerNotificationMessageSource.CMS}`,
        title: notification.title,
        message: notification.message,
        createdAt: notification.timestampCreation,
        isRead: 1 === notification.status,
        isModal: false,
        source: CustomerNotificationMessageSource.CMS
    };
}
