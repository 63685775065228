import { computed, ref } from 'vue';
import { InputEventType } from 'web/src/components/Input/enums';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';
import { PAYMENTS_UNDEFINED_CREDIT_CARD } from 'web/src/modules/payments/constants';
export default function usePaymentsCreditCardSelector(props, emit) {
    const isVisible = ref(true);
    const { deleteCreditCard } = useDepositStore();
    function setSelectedCreditCardToken(id) {
        emitInput(id);
    }
    const showCreditCardSelector = computed(()=>isVisible.value && !!props.creditCards.length);
    function onCreditCardSelectedMounted() {
        setSelectedCreditCardToken(props.creditCards[0]?.token ? props.creditCards[0].token : PAYMENTS_UNDEFINED_CREDIT_CARD);
    }
    function getPaymentsCreditCardByToken(token) {
        return props.creditCards.find((item)=>item.token === token) ?? void 0;
    }
    function handleDeleteClick(token) {
        const bankCard = getPaymentsCreditCardByToken(token);
        if (!bankCard) return;
        deleteCreditCard(bankCard);
    }
    function emitInput(id) {
        emit(InputEventType.INPUT, id);
    }
    return {
        onCreditCardSelectedMounted,
        setSelectedCreditCardToken,
        handleDeleteClick,
        showCreditCardSelector
    };
}
