import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLocalStorage } from '@leon-hub/cordova/src/modules/local-storage';
import isValidCustomerBetType from 'web/src/modules/customer/utils/isValidCustomerBetType';
const LOCAL_STORAGE_BET_TYPE_KEY = 'customer:betType';
const useCustomerBetTypeStore = defineStore('customer-bet-type', ()=>{
    const localStorage = useLocalStorage();
    const betType = ref();
    function setBetType(type) {
        betType.value = type;
        localStorage.setItem(LOCAL_STORAGE_BET_TYPE_KEY, type);
    }
    function onInit() {
        const type = localStorage.getItem(LOCAL_STORAGE_BET_TYPE_KEY);
        if (isValidCustomerBetType(type)) setBetType(type);
    }
    onInit();
    return {
        betType,
        setBetType
    };
});
export default useCustomerBetTypeStore;
