import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "value"
];
export default /*@__PURE__*/ _defineComponent({
    __name: 'FormPaymentsInput',
    props: {
        value: {},
        inputType: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['form-payments-input'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['form-payments-input__icon']]: true,
                        [`form-payments-input__icon--${_ctx.inputType}`]: true
                    })
                }, null, 2),
                _createElementVNode("input", {
                    class: _normalizeClass(_ctx.$style['form-payments-input__input']),
                    value: _ctx.value,
                    disabled: ""
                }, null, 10, _hoisted_1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'FormPaymentsInput'
                ]
            ]);
        };
    }
});
