import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import SearchListSection from 'web/src/modules/search/submodules/sportline/components/SearchListSection/SearchListSection.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SearchSportResults',
    props: {
        searchText: {
            default: ''
        },
        liveSearchResults: {
            default: ()=>[]
        },
        prematchSearchResults: {
            default: ()=>[]
        }
    },
    emits: [
        "click-event"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        function emitClickEvent(event) {
            emits('click-event', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _ctx.liveSearchResults.length ? (_openBlock(), _createBlock(SearchListSection, {
                    key: 0,
                    caption: _ctx.$t('WEB2_SPORTLINE_LIVE_EVENTS'),
                    "search-text": _ctx.searchText,
                    list: _ctx.liveSearchResults,
                    onItemClick: emitClickEvent
                }, null, 8, [
                    "caption",
                    "search-text",
                    "list"
                ])) : _createCommentVNode("", true),
                _ctx.prematchSearchResults.length ? (_openBlock(), _createBlock(SearchListSection, {
                    key: 1,
                    caption: _ctx.$t('WEB2_SPORTLINE_PREMATCH_EVENTS'),
                    "search-text": _ctx.searchText,
                    list: _ctx.prematchSearchResults,
                    onItemClick: emitClickEvent
                }, null, 8, [
                    "caption",
                    "search-text",
                    "list"
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'SearchSportResults'
                ]
            ]);
        };
    }
});
