import { ref } from 'vue';
// do not use this composable if you need to check several packages in one place, use useIsOneOfPakageInstalled instead
export function useIsAppInstalled() {
    const isInstalled = ref(false);
    return {
        isInstalled,
        checkIfAppInstalled: ()=>Promise.resolve()
    };
}
/*
Use if you need to check more then one package
*/ // eslint-disable-next-line max-len
export function useIsOneOfPackageInstalled() {
    return {
        checkIfOneOfAppInstalled: (packageName)=>{
            const { isInstalled, checkIfAppInstalled } = useIsAppInstalled();
            checkIfAppInstalled(packageName);
            return {
                isInstalled
            };
        }
    };
}
