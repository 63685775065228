import { toRef, computed } from 'vue';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import { leagueElementNavigationEqualsTo, regionElementNavigationEqualsTo, sportElementEqualsTo } from 'web/src/modules/sportline/utils/rest/compare';
// LEONWEB-2631 for details page used urlNames from page link
export function useSportlineTreeElementForPageIdentifier(props) {
    const { pageIdentifier } = props;
    const sportsTreeStore = useSportsTreeStore();
    const isLoaded = toRef(sportsTreeStore, 'isLoaded');
    const fullSportsList = toRef(sportsTreeStore, 'fullSportsList');
    const { reloadFullSportsList } = sportsTreeStore;
    const sportElement = computed(()=>{
        const relatedSports = fullSportsList.value || [];
        const { sportFamily } = pageIdentifier.value;
        if (!sportFamily) return null;
        return relatedSports.find(sportElementEqualsTo(sportFamily, (sport)=>sportFamily.toLowerCase() === sport?.sport.representation.family.toLowerCase())) || null;
    });
    const regionElement = computed(()=>{
        const relatedRegions = sportElement.value?.regions || [];
        const { regionId, regionUrlName } = pageIdentifier.value;
        if (!regionId && !regionUrlName) return null;
        return relatedRegions.find(regionElementNavigationEqualsTo(regionId || regionUrlName)) || null;
    });
    const leagueElement = computed(()=>{
        const relatedLeagues = regionElement.value?.leagues || [];
        const { leagueId, leagueUrlName } = pageIdentifier.value;
        if (!leagueId && !leagueUrlName) return null;
        return relatedLeagues.find(leagueElementNavigationEqualsTo(leagueId || leagueUrlName)) || null;
    });
    async function initialRequests() {
        if (isLoaded.value) return;
        await reloadFullSportsList({
            silent: true,
            invalidateAfterInterval: 10000
        });
    }
    return {
        isLoaded,
        sportElement,
        regionElement,
        leagueElement,
        initialRequests
    };
}
