import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createBlock as _createBlock, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0
};
import { VIcon } from '@components/v-icon';
import { convertCommonClassToCssModule as toModule } from 'web/src/components/List/utils/convertCommonClassesToCssModule';
import { SwitchKind } from 'web/src/components/Switch/VSwitch/enums';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ListItemSuffixAlign } from 'web/src/components/List/VListItem/enums';
import useVListItemInner from './composables/useVListItemInner';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VListItemInner',
    props: {
        badgeLabel: {},
        buttonLabel: {},
        counter: {},
        haveExpand: {
            type: Boolean
        },
        haveProgress: {
            type: Boolean
        },
        haveSwitcher: {
            type: Boolean
        },
        href: {},
        iconColored: {
            type: Boolean
        },
        iconCustomStyles: {},
        iconName: {},
        iconSuffixName: {},
        isNew: {
            type: Boolean
        },
        isSwitchChecked: {
            type: Boolean
        },
        isSwitchDisabled: {
            type: Boolean
        },
        isTitleCentered: {
            type: Boolean
        },
        noWrap: {
            type: Boolean
        },
        progressValue: {
            default: 0
        },
        suffixAlign: {
            default: ListItemSuffixAlign.CENTER
        },
        suffixText: {},
        switchKind: {
            default: SwitchKind.DEFAULT
        },
        title: {}
    },
    emits: [
        "click",
        "change-switcher",
        "click-switcher",
        "click-prefix",
        "click-default",
        "click-suffix",
        "click-button"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        function emitClick(event) {
            emit('click', event);
        }
        function emitChangeSwitcher(value) {
            emit('change-switcher', value);
        }
        function emitClickSwitcher(event) {
            emit('click-switcher', event);
        }
        function emitClickPrefix() {
            emit('click-prefix');
        }
        function emitClickDefault() {
            emit('click-default');
        }
        function emitClickSuffix() {
            emit('click-suffix');
        }
        function emitClickButton() {
            emit('click-button');
        }
        const { tag, badgeProperties, switchProperties, computedIconProperties, progressProperties, haveSuffix, havePrefix, classObject, iconSuffixProperties, buttonProperties, listItemInnerRef, scrollIntoView } = useVListItemInner(props);
        __expose({
            scrollIntoView
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(tag)), {
                ref_key: "listItemInnerRef",
                ref: listItemInnerRef,
                class: _normalizeClass(_unref(classObject)),
                href: 'a' === _unref(tag) ? _ctx.href : null,
                onClick: emitClick
            }, {
                default: _withCtx(()=>[
                        _unref(havePrefix) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass({
                                [_unref(toModule)('list-item__prefix')]: true,
                                [_unref(toModule)('list-item__prefix--colored')]: _ctx.iconColored
                            }),
                            onClick: emitClickPrefix
                        }, [
                            _renderSlot(_ctx.$slots, "prefix", {}, ()=>[
                                    _ctx.iconName ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass({
                                            [_unref(toModule)('list-item__prefix-icon')]: true,
                                            [_unref(toModule)('list-item__prefix-icon--new')]: _ctx.isNew,
                                            [_unref(toModule)('list-item__prefix-icon--colored')]: _ctx.iconColored,
                                            [_unref(toModule)(`list-item__prefix-icon--${_ctx.iconName}`)]: _ctx.iconColored && _ctx.iconName
                                        }),
                                        style: _normalizeStyle(_ctx.iconCustomStyles)
                                    }, [
                                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(computedIconProperties))), null, 16)
                                    ], 6)) : _createCommentVNode("", true)
                                ])
                        ], 2)) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                _unref(toModule)('list-item__default')
                            ]),
                            onClick: emitClickDefault
                        }, [
                            !_ctx.title || _ctx.$slots.inner ? _renderSlot(_ctx.$slots, "inner", {
                                key: 1
                            }) : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
                                _createTextVNode(_toDisplayString(_ctx.title), 1)
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'list-item-title'
                                    }
                                ]
                            ])
                        ], 2),
                        _unref(haveSuffix) ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass([
                                _unref(toModule)("list-item__suffix"),
                                _unref(toModule)(`list-item__suffix--${_ctx.suffixAlign}`)
                            ]),
                            onClick: emitClickSuffix
                        }, [
                            _ctx.haveSwitcher ? (_openBlock(), _createBlock(VSwitch, _mergeProps({
                                key: 0
                            }, _unref(switchProperties), {
                                onClick: emitClickSwitcher,
                                onChange: emitChangeSwitcher
                            }), null, 16)) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _ctx.counter || _ctx.suffixText ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_unref(toModule)('list-item__suffix-text'))
                                }, _toDisplayString(_ctx.counter || _ctx.suffixText), 3)) : _createCommentVNode("", true),
                                _ctx.badgeLabel ? (_openBlock(), _createBlock(VBadge, _mergeProps({
                                    key: 1
                                }, _unref(badgeProperties), {
                                    class: _unref(toModule)('list-item__suffix-badge')
                                }), null, 16, [
                                    "class"
                                ])) : _ctx.haveProgress || _ctx.progressValue > 0 ? (_openBlock(), _createBlock(VCircularProgress, _mergeProps({
                                    key: 2
                                }, _unref(progressProperties), {
                                    class: _unref(toModule)('list-item__suffix-progress')
                                }), null, 16, [
                                    "class"
                                ])) : _createCommentVNode("", true),
                                _ctx.haveExpand || !!_ctx.iconSuffixName || _ctx.haveProgress || _ctx.progressValue > 0 ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                                    key: 3
                                }, _unref(iconSuffixProperties), {
                                    class: _unref(toModule)('list-item__suffix-icon')
                                }), null, 16, [
                                    "class"
                                ])) : _createCommentVNode("", true)
                            ], 64)),
                            _ctx.buttonLabel ? (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 2
                            }, _unref(buttonProperties), {
                                class: _unref(toModule)('list-item__suffix-button'),
                                onClick: emitClickButton
                            }), null, 16, [
                                "class"
                            ])) : _createCommentVNode("", true)
                        ], 2)) : _createCommentVNode("", true)
                    ]),
                _: 3
            }, 8, [
                "class",
                "href"
            ])), [
                [
                    _directive_auto_id,
                    'VListItemInner'
                ]
            ]);
        };
    }
});
