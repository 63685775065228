import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick",
    "onMouseover"
];
import { computed, onMounted, ref } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FeedbackRating',
    props: {
        infoIcon: {},
        title: {},
        allowedToChangeRatingText: {},
        disabledToChangeRatingText: {},
        surveyRating: {},
        isRatingChangeAvailable: {
            type: Boolean
        },
        isRounded: {
            type: Boolean
        }
    },
    emits: [
        "submit-rating"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const isRatingChangeEnabled = computed(()=>props.isRatingChangeAvailable);
        const rating = ref(0);
        const hoverRating = ref(0);
        const isOnlyActiveStartsAvailable = computed(()=>!!props.surveyRating && !props.isRatingChangeAvailable && props.isRounded);
        const ratings = computed(()=>isOnlyActiveStartsAvailable.value ? Array.from({
                length: props.surveyRating ? props.surveyRating : 5
            }, (v, i)=>i + 1) : [
                1,
                2,
                3,
                4,
                5
            ]);
        const starProperties = {
            name: IconName.STAR_ALT,
            size: IconSize.SIZE_24
        };
        function isRatingActive(value) {
            return isRatingChangeEnabled.value && hoverRating.value >= value || rating.value >= value;
        }
        function isRatingNotHovered(value) {
            return !!isRatingChangeEnabled.value && rating.value < value;
        }
        function onRatingChange(value) {
            if (!isRatingChangeEnabled.value) return;
            setRating(value);
            emits('submit-rating', rating.value);
        }
        function setRating(value) {
            rating.value = value;
        }
        function onRatingHover(value) {
            hoverRating.value = value;
        }
        function resetRatingHover() {
            hoverRating.value = 0;
        }
        onMounted(()=>{
            if (props.surveyRating) setRating(props.surveyRating);
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['feedback-rating']]: true,
                    [_ctx.$style['feedback-rating--inline']]: isOnlyActiveStartsAvailable.value,
                    [_ctx.$style['feedback-rating--rounded']]: _ctx.isRounded,
                    [_ctx.$style['feedback-rating--confirm']]: !isRatingChangeEnabled.value
                })
            }, [
                isRatingChangeEnabled.value ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['feedback-rating__title-box'])
                }, [
                    _ctx.infoIcon && _ctx.title ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createVNode(_unref(VIcon), _mergeProps(_ctx.infoIcon, {
                            class: _ctx.$style['feedback-rating__info']
                        }), null, 16, [
                            "class"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['feedback-rating__title-wrapper'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['feedback-rating__title'])
                            }, _toDisplayString(_ctx.title), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['feedback-rating__text'])
                            }, _toDisplayString(_ctx.allowedToChangeRatingText), 3)
                        ], 2)
                    ], 64)) : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['feedback-rating__text'])
                    }, _toDisplayString(_ctx.allowedToChangeRatingText), 3))
                ], 2)) : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass({
                        [_ctx.$style['feedback-rating__title']]: !isOnlyActiveStartsAvailable.value,
                        [_ctx.$style['feedback-rating__text']]: isOnlyActiveStartsAvailable.value
                    })
                }, _toDisplayString(_ctx.disabledToChangeRatingText), 3)),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['feedback-rating__stars']]: true,
                        [_ctx.$style['feedback-rating__stars--flexible']]: isOnlyActiveStartsAvailable.value
                    })
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ratings.value, (ratingIndex)=>(_openBlock(), _createElementBlock("div", {
                            key: ratingIndex,
                            class: _normalizeClass({
                                [_ctx.$style['feedback-rating__star']]: true,
                                [_ctx.$style['feedback-rating__star--flexible']]: isOnlyActiveStartsAvailable.value,
                                [_ctx.$style['feedback-rating__star--active']]: isRatingActive(ratingIndex),
                                [_ctx.$style['feedback-rating__star--not-hovered']]: isRatingNotHovered(ratingIndex)
                            }),
                            onClick: ($event)=>onRatingChange(ratingIndex),
                            onMouseover: ($event)=>onRatingHover(ratingIndex),
                            onMouseleave: resetRatingHover
                        }, [
                            _createVNode(_unref(VIcon), _mergeProps({
                                ref_for: true
                            }, starProperties), null, 16)
                        ], 42, _hoisted_1))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'FeedbackRating'
                ]
            ]);
        };
    }
});
