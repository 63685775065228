import { logger } from '@leon-hub/logging';
export default function logPlaceBetResultSuccess(param) {
    let { slipEntries, liveEventsIds, priceChangePolicy, slipType } = param;
    for (const { result, isMaxStake, fastBets } of slipEntries){
        const payload = {
            response: result,
            liveEventsIds,
            slipType,
            isMaxStake,
            fastBets,
            priceChangePolicy
        };
        logger.info('Place bet success', payload);
    }
}
