import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { getClassNameForStatus } from 'web/src/components/Status/VStatus/composables/utils';
export default function useVStatus(props) {
    const className = computed(()=>getClassNameForStatus(props));
    const iconProp = {
        name: IconName.CHECK_FILLED,
        size: IconSize.SIZE_16
    };
    return {
        className,
        iconProp
    };
}
