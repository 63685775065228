import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0
};
import useMainBannerSectionRouteComponent from 'web/src/modules/banners/components/MainBannerSectionRouteComponent/composables/useMainBannerSectionRouteComponent';
import { BannerLocation } from 'web/src/modules/banners/enums';
import CustomHomepageBanner from 'web/src/modules/banners/components/CustomHomepageBanner/CustomHomepageBanner.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomHomepageBanners',
    setup (__props) {
        const { currentGroup } = useMainBannerSectionRouteComponent({
            location: BannerLocation.TOP_CUSTOM_HOME_PAGE
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(currentGroup)?.banners.length ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(CustomHomepageBanner, {
                    banner: _unref(currentGroup).banners[0].content
                }, null, 8, [
                    "banner"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CustomHomepageBanners'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
