import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import getDefaultInitValue from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/helpers/getDefaultInitValue';
import getParsedValue from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/helpers/getParsedValue';
import { useI18n } from 'web/src/modules/i18n/composables';
import getFormatMoneyOptions from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/helpers/getFormatMoneyOptions';
import isValueInRange from 'web/src/modules/payments/utils/isValueInRange';
import getInputLabel from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/helpers/getInputLabel';
import getPlaceholderText from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/helpers/getPlaceholderText';
import { useFormatMoney } from 'web/src/modules/money/composables';
import isValueFineWithAllowedIncrement from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/helpers/isValueFineWithAllowedIncrement';
import { PAYMENTS_DEFAULT_INPUT_VALUE } from 'web/src/modules/payments/constants';
export default function usePaymentsInput(props, emit) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const router = useRouter();
    const currentValue = ref(PAYMENTS_DEFAULT_INPUT_VALUE);
    const isCurrentValueInRange = ref(true);
    const isCurrentValueFineWithAllowedIncrement = ref(true);
    const isDirty = ref(false);
    const isDefaultValue = ref(false);
    const defaultInitValue = computed(()=>{
        const sum = router.getQuery('sum');
        if (sum) return getDefaultInitValue(parseInt(String(sum), 10), props.minAmount, props.maxAmount);
        return getDefaultInitValue(props.defaultInitInputValue, props.minAmount, props.maxAmount);
    });
    const eventToEmit = computed(()=>({
            target: {
                value: currentValue.value || '',
                name: props.name || ''
            }
        }));
    const parseValue = (val)=>getParsedValue(val, props);
    const valueToNumber = computed(()=>parseInt(currentValue.value, 10) || 0);
    const isAmountExceedsBalance = computed(()=>!props.deposit && valueToNumber.value > props.balance);
    const formattedBalance = computed(()=>$formatMoney(props.balance ?? 0, {
            currency: props.currency
        }));
    const checkRange = ()=>{
        isCurrentValueInRange.value = isValueInRange(valueToNumber.value, props.minAmount || 0, props.maxAmount || 0);
    };
    const checkIfValueIsFineWithAllowedIncrement = ()=>{
        if (props.allowedIncrement && props.allowedIncrement > 0) isCurrentValueFineWithAllowedIncrement.value = isValueFineWithAllowedIncrement(props.allowedIncrement, valueToNumber.value);
    };
    const checkErrors = ()=>{
        checkRange();
        checkIfValueIsFineWithAllowedIncrement();
    };
    const onChange = (value)=>{
        currentValue.value = value ? parseValue(value) : PAYMENTS_DEFAULT_INPUT_VALUE;
        isDefaultValue.value = false;
        checkErrors();
        emit('input', eventToEmit.value);
        emit('change', eventToEmit.value);
    };
    const onFocus = ()=>{
        if (currentValue.value && !isDirty.value && currentValue.value === `${defaultInitValue.value}`) {
            isDefaultValue.value = true;
            currentValue.value = '';
        }
        if (currentValue.value === PAYMENTS_DEFAULT_INPUT_VALUE) currentValue.value = '';
        emit('focus', eventToEmit.value);
    };
    const onInput = (value)=>{
        isDirty.value = true;
        onChange(value);
    };
    const onBlur = ()=>{
        if (!valueToNumber.value) {
            currentValue.value = isDefaultValue.value ? `${defaultInitValue.value}` : PAYMENTS_DEFAULT_INPUT_VALUE;
            checkErrors();
        }
        emit('blur', eventToEmit.value);
    };
    const errorLeftMessage = computed(()=>{
        if (props.bonus) return;
        const isWithdrawalExceeds = !props.deposit && isAmountExceedsBalance.value;
        if (isWithdrawalExceeds && (isCurrentValueInRange.value || !isCurrentValueInRange.value)) return $translate('WEB2_WITHDRAWAL_AMOUNT_EXEEDS').value;
        if (!props.isCurrentValueFromSuggestedAmounts) return $translate('WEB2_SUGGESTED_AMOUNTS_ONLY').value;
        if (!isCurrentValueFineWithAllowedIncrement.value && props.allowedIncrement) return $translate('WEB2_ALLOWED_INCREMENT_ONLY', ref({
            amount: `${props.allowedIncrement}`,
            amount1: `${props.allowedIncrement}`,
            amount2: `${2 * props.allowedIncrement}`,
            amount3: `${3 * props.allowedIncrement}`
        })).value;
    });
    const formatMoneyOptions = computed(()=>getFormatMoneyOptions(props));
    const inputLabel = computed(()=>getInputLabel(props, $translate, $formatMoney));
    const hintRightMessage = computed(()=>{
        if (errorLeftMessage.value) return;
        if (props.hintRight) return props.hintRight;
        if (0 === props.maxAmount) return `${$translate('WEB2_MIN_STAKE').value}: ${$formatMoney(props.minAmount || 0, formatMoneyOptions.value)}`;
        // eslint-disable-next-line max-len
        return `${$formatMoney(props.minAmount || 0, formatMoneyOptions.value)} - ${$formatMoney(props.maxAmount || 0, formatMoneyOptions.value)}`;
    });
    const errorRightMessage = computed(()=>{
        if (0 === valueToNumber.value && currentValue.value.length) return $translate('WEB2_ENTER_PRICE').value;
        if (!isCurrentValueInRange.value) return hintRightMessage.value;
    });
    const placeholderText = computed(()=>getPlaceholderText(props, $translate));
    watch(()=>props.inputValue, (inputValue)=>{
        if (inputValue && Number(inputValue) !== Number(defaultInitValue.value)) isDirty.value = true;
        onChange(inputValue || PAYMENTS_DEFAULT_INPUT_VALUE);
    });
    watch(()=>props.minAmount, ()=>{
        onChange(`${defaultInitValue.value}`);
        checkRange();
    });
    function paymentsInputMounted() {
        onChange(`${defaultInitValue.value}`);
    }
    return {
        onFocus,
        onChange,
        onInput,
        onBlur,
        inputLabel,
        hintRightMessage,
        errorLeftMessage,
        errorRightMessage,
        placeholderText,
        formatMoneyOptions,
        currentValue,
        formattedBalance,
        paymentsInputMounted
    };
}
