export function getSportEventDetailsFromPreviewData(preview, options) {
    const { sport, league, region, sportEvent } = preview || {};
    if (!sport || !league || !region || !sportEvent) return null;
    if (sportEvent.id !== options?.actualSportEventId) return null;
    return {
        ...sportEvent,
        sport,
        league,
        region
    };
}
