import isMatchedChange from './isMatchedChange';
export default function applyRunnerStatusChanges(param, changes) {
    let { eventId, marketId, runner } = param;
    const actualChange = changes.find((change)=>isMatchedChange(change, {
            eventId,
            marketId,
            runnerId: runner.id
        }));
    if (!actualChange) return runner;
    return {
        ...runner,
        open: null === actualChange.marketStatus
    };
}
