import memoize from 'lodash/memoize';
import { ruPatternLocaleDateTime as defaultPattern, createDateLocalizers } from '@leon-hub/utils';
import { getMonthFullNames, getMonthShortNames } from './utils';
const cache = new Map();
export const getDateLocalizers = memoize((locale, $t)=>{
    let localizers = cache.get(locale);
    if (!localizers) {
        const shortMonthNames = getMonthShortNames($t);
        const fullMonthNames = getMonthFullNames($t);
        const getMonth = (param)=>{
            let { id, format } = param;
            const list = 'short' === format ? shortMonthNames : fullMonthNames;
            if (id in list) return list[id].value;
            throw new RangeError(`Missing month number: ${id}`);
        };
        localizers = createDateLocalizers({
            pattern: defaultPattern,
            getMonth
        });
        cache.set(locale, localizers);
    }
    return localizers;
}, (locale)=>locale);
