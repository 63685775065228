import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id"
];
const _hoisted_2 = [
    "for"
];
import VPhotoPlaceholder from 'web/src/components/PhotoPlaceholder/VPhotoPlaceholder/VPhotoPlaceholder.vue';
import { useVPhotoInput } from './composables';
const bemClassName = 'photo-input';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VPhotoInput',
    props: {
        id: {},
        name: {},
        placeholder: {
            default: ()=>({})
        },
        onPlaceholderClick: {}
    },
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onChange } = useVPhotoInput(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style[bemClassName])
            }, [
                _createElementVNode("input", {
                    id: _ctx.id,
                    class: _normalizeClass(_ctx.$style[`${bemClassName}__file`]),
                    type: "file",
                    name: "name",
                    accept: "image/*",
                    onChange: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onChange) && _unref(onChange)(...args);
                    }, [
                        "stop"
                    ])),
                    onInput: _cache[1] || (_cache[1] = _withModifiers(()=>{}, [
                        "stop"
                    ]))
                }, null, 42, _hoisted_1),
                _ctx.onPlaceholderClick ? (_openBlock(), _createElementBlock("label", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style[`${bemClassName}__label`]),
                    onClick: _cache[2] || (_cache[2] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _ctx.onPlaceholderClick && _ctx.onPlaceholderClick(...args);
                    })
                }, [
                    _createVNode(VPhotoPlaceholder, _normalizeProps(_guardReactiveProps(_ctx.placeholder)), null, 16)
                ], 2)) : (_openBlock(), _createElementBlock("label", {
                    key: 1,
                    for: _ctx.id,
                    class: _normalizeClass(_ctx.$style[`${bemClassName}__label`])
                }, [
                    _createVNode(VPhotoPlaceholder, _normalizeProps(_guardReactiveProps(_ctx.placeholder)), null, 16)
                ], 10, _hoisted_2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VPhotoInput'
                ]
            ]);
        };
    }
});
