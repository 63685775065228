import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
const _hoisted_2 = [
    "alt",
    "src"
];
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { VLogoLoader } from 'web/src/components/Loader';
import BonusLoyaltyProgramBalance from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';
import { useBonusLoyaltyProgramCard } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusLoyaltyProgramCard',
    props: {
        name: {
            default: ''
        },
        balance: {
            default: 0
        },
        isLocked: {
            type: Boolean
        },
        imageSrc: {
            default: ''
        },
        image: {
            default: null
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { iconName, onBonusLoyaltyProgramCardClick, hasImageObject } = useBonusLoyaltyProgramCard(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['bonus-loyalty-program-card']]: true,
                    [_ctx.$style['bonus-loyalty-program-card--locked']]: _ctx.isLocked
                }),
                onClick: _cache[1] || (_cache[1] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onBonusLoyaltyProgramCardClick) && _unref(onBonusLoyaltyProgramCardClick)(...args);
                })
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['bonus-loyalty-program-card__title']),
                    title: _ctx.name
                }, _toDisplayString(_ctx.name), 11, _hoisted_1),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bonus-loyalty-program-card__img-wrapper'])
                }, [
                    _unref(hasImageObject) && _ctx.image ? (_openBlock(), _createBlock(VImage, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['bonus-loyalty-program-card__img']),
                        src: _ctx.image.src,
                        x1: _ctx.image.x1,
                        x2: _ctx.image.x2,
                        x3: _ctx.image.x3,
                        x1webp: _ctx.image.x1webp,
                        x2webp: _ctx.image.x2webp,
                        x3webp: _ctx.image.x3webp,
                        alt: _ctx.name
                    }, null, 8, [
                        "class",
                        "src",
                        "x1",
                        "x2",
                        "x3",
                        "x1webp",
                        "x2webp",
                        "x3webp",
                        "alt"
                    ])) : _ctx.imageSrc ? (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['bonus-loyalty-program-card__img']),
                        alt: _ctx.name,
                        src: _ctx.imageSrc
                    }, null, 10, _hoisted_2)) : (_openBlock(), _createBlock(_unref(VLogoLoader), {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['bonus-loyalty-program-card__placeholder']),
                        "has-animation": false
                    }, null, 8, [
                        "class"
                    ]))
                ], 2),
                _createVNode(BonusLoyaltyProgramBalance, {
                    text: String(_ctx.balance),
                    "icon-name": _unref(iconName),
                    "is-inactive": _ctx.isLocked,
                    "has-no-hover": "",
                    onClick: _cache[0] || (_cache[0] = ($event)=>_unref(onBonusLoyaltyProgramCardClick)())
                }, null, 8, [
                    "text",
                    "icon-name",
                    "is-inactive"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusLoyaltyProgramCard'
                ]
            ]);
        };
    }
});
