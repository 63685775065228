export default function getFormWrapperWidgetProperties(param) {
    let { fieldUiSchema, error, extraProperties, value, name } = param;
    return {
        name,
        title: fieldUiSchema?.title || '',
        disabled: fieldUiSchema?.disabled,
        hintMessage: fieldUiSchema?.hintMessage || '',
        value,
        error,
        extraProperties,
        options: fieldUiSchema.options ?? {}
    };
}
