import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed, ref } from 'vue';
import { StartCodeType } from '@leon-hub/api-sdk';
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import EgsInjectedGameIframeWidget from '../EgsInjectedGameIframeWidget/EgsInjectedGameIframeWidget.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsGameIframe',
    props: {
        gameUrl: {},
        startCodeType: {},
        allow: {},
        flexLayout: {
            type: Boolean
        },
        allowfullscreen: {
            type: Boolean
        },
        timeout: {}
    },
    emits: [
        "load",
        "error"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const iframeName = IframeWidget.EgsGame;
        const isHtmlInjection = computed(()=>props.startCodeType === StartCodeType.HTML_INJECTION);
        function onFrameLoaded() {
            emit('load');
        }
        const iframeElement = ref();
        const iframeInjectedElement = ref();
        function postMessage(message) {
            let target = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '*';
            iframeElement.value?.postMessage(message, target);
            iframeInjectedElement.value?.postMessage(message, target);
        }
        __expose({
            postMessage
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return !isHtmlInjection.value && _ctx.gameUrl ? _withDirectives((_openBlock(), _createBlock(VIframe, {
                key: 0,
                ref_key: "iframeElement",
                ref: iframeElement,
                name: _unref(iframeName),
                src: _ctx.gameUrl,
                "is-dark": "",
                "full-width": "",
                "full-height": "",
                "hide-title": "",
                allow: _ctx.allow,
                timeout: _ctx.timeout,
                allowfullscreen: _ctx.allowfullscreen,
                "flex-layout": _ctx.flexLayout,
                onLoad: onFrameLoaded,
                onError: _cache[0] || (_cache[0] = ($event)=>emit('error', $event))
            }, null, 8, [
                "name",
                "src",
                "allow",
                "timeout",
                "allowfullscreen",
                "flex-layout"
            ])), [
                [
                    _directive_data_test,
                    {
                        el: 'started-game-iframe'
                    }
                ]
            ]) : isHtmlInjection.value && _ctx.gameUrl ? (_openBlock(), _createBlock(EgsInjectedGameIframeWidget, {
                key: 1,
                ref_key: "iframeInjectedElement",
                ref: iframeInjectedElement,
                "game-html": _ctx.gameUrl,
                allow: _ctx.allow,
                allowfullscreen: _ctx.allowfullscreen,
                "flex-layout": _ctx.flexLayout,
                timeout: _ctx.timeout,
                onLoad: onFrameLoaded,
                onError: _cache[1] || (_cache[1] = ($event)=>emit('error', $event))
            }, null, 8, [
                "game-html",
                "allow",
                "allowfullscreen",
                "flex-layout",
                "timeout"
            ])) : _createCommentVNode("", true);
        };
    }
});
