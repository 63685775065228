export function getBroadcastListElementFromCache(key, eventCache, eventMarketsCache) {
    if (!eventCache?.sportEvent) return null;
    if (!eventMarketsCache?.markets) return null;
    const markets = eventMarketsCache.markets?.markets || [];
    const market = markets.find((marketInList)=>marketInList.id === String(key.market));
    const runner = market?.runners.find((runnerInList)=>runnerInList.id === String(key.runner));
    if (!market || !runner) return null;
    return {
        key,
        market,
        runner,
        event: eventCache.sportEvent
    };
}
