import { ref, toRef, watch, computed } from 'vue';
import { defineStore } from 'pinia';
import useRootStore from 'web/src/modules/core/store/useRootStore';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useTopSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { getHeadlineMatches, getHeadlineMatchesChanges } from 'web/src/modules/sportline/utils/api';
import { mapHeadlineMatchesToSportEventsResponse } from 'web/src/modules/sportline/utils/rest/pre-build';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { useMixedHeadlineListsStore, useSeparateHeadlineListsStore } from './composables';
import { handleRawSportEventsResponse } from '../utils';
export const useSportlineTopStore = defineStore('sportline-top', ()=>{
    const { isTopEventsEnabled, flags, isLiveOutrightsEnabled } = useSportlineTopEventsSettings();
    const rootStore = useRootStore();
    const cTag = toRef(rootStore, 'cTag');
    const customerFavoritesService = useCustomerFavoritesService();
    const broadcastSelected = useBroadcastSelected();
    const { onInit: onGlobalSportlineEventsInit } = useGlobalSportlineEvents();
    const { timeout: topSportlineUpdateInterval } = useTopSportlineUpdateTimeout();
    const rawSplitSportEventsResponse = ref(null);
    const rawMergedSportEventsResponse = ref(null);
    const { isReady: isSeparateReady, totalLiveCount, totalPrematchCount, liveHeadlineElements, prematchHeadlineElements } = useSeparateHeadlineListsStore({
        rawResponse: rawSplitSportEventsResponse
    });
    const { isReady: isMixedReady, totalCount, headlineElements, sportlineElements } = useMixedHeadlineListsStore({
        rawResponse: rawMergedSportEventsResponse
    });
    const isReady = computed(()=>isTopEventsEnabled.value ? isMixedReady.value : isSeparateReady.value);
    function setRawSportEventsResponse(response) {
        const parsed = handleRawSportEventsResponse(response);
        rawMergedSportEventsResponse.value = parsed.rawMergedSportEventsResponse;
        rawSplitSportEventsResponse.value = parsed.rawSplitSportEventsResponse;
    }
    const lastUpdate = new BackgroundUpdateStopwatch({
        sportEvents: async (param)=>{
            let { silent } = param;
            const inplayVTag = rawSplitSportEventsResponse.value?.live?.vtag;
            const prematchVTag = rawSplitSportEventsResponse.value?.prematch?.vtag;
            const mergedVTag = rawMergedSportEventsResponse.value?.events?.vtag;
            // @TODO move requests to createSportlineApiService
            const isMakeUpdateRequest = !!inplayVTag && !!prematchVTag || !!mergedVTag;
            let result;
            if (isMakeUpdateRequest) result = await getHeadlineMatchesChanges({
                ctag: cTag.value,
                inplayHeadlineVtag: inplayVTag,
                prematchHeadlineVtag: prematchVTag,
                allVtag: mergedVTag,
                silent,
                flags: [
                    ...flags.value
                ],
                LMCE: isLiveOutrightsEnabled.value
            });
            const isInvalidRequest = result && 'events' in result && !!result.events ? result?.events?.vtag === 'invalid' : result?.live?.vtag === 'invalid' || result?.prematch?.vtag === 'invalid';
            if (!isMakeUpdateRequest || isInvalidRequest) result = await getHeadlineMatches({
                ctag: cTag.value,
                silent,
                flags: [
                    ...flags.value
                ],
                merged: isTopEventsEnabled.value,
                LMCE: isLiveOutrightsEnabled.value
            });
            setRawSportEventsResponse(result ?? null);
            lastUpdate.update('sportEvents');
            broadcastSelected.updateDataInStorageByResponse({
                response: mapHeadlineMatchesToSportEventsResponse(result)
            });
        }
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled, isBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: topSportlineUpdateInterval,
        onEnableChanged (value) {
            customerFavoritesService.setIsBackgroundIdsUpdateAvailable(CustomerFavoritesIdsBackgroundUpdateKey.HOME, value);
        }
    });
    // init
    // LEONWEB-8926 cTag can be unchanged on first request after login
    watch(cTag, ()=>{
        if (isBackgroundUpdateEnabled.value) lastUpdate.forceCallSyncAction('sportEvents', {
            silent: true
        });
    });
    useSyncState((silent)=>syncState({
            silent
        }), topSportlineUpdateInterval);
    onGlobalSportlineEventsInit();
    return {
        isReady,
        isTopEventsEnabled,
        totalLiveCount,
        totalPrematchCount,
        totalCount,
        liveHeadlineElements,
        prematchHeadlineElements,
        headlineElements,
        sportlineElements,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
});
