import RouteName from '@leon-hub/routing-config';
import { isString } from '@leon-hub/guards';
export function resolveSportsPageLink(target) {
    return target.urlName ? {
        name: RouteName.SPORTLINE_PREMATCH_EVENTS,
        params: {
            family: target.urlName.toLowerCase()
        }
    } : {
        name: RouteName.SPORTLINE_PREMATCH_EVENTS,
        params: {}
    };
}
export function resolveRegionPageLink(target) {
    return {
        name: RouteName.SPORT_REGION,
        params: {
            ...target
        }
    };
}
export function resolveLeaguePageLink(target) {
    return {
        name: RouteName.SPORT_LEAGUE,
        params: {
            ...target
        }
    };
}
export function resolveTopLeaguePageLink(target) {
    return {
        name: RouteName.SPORTLINE_TOP_LEAGUE,
        params: {
            ...target
        }
    };
}
export function resolveLiveLeaguePageLink(target) {
    return {
        name: RouteName.SPORTLINE_LIVE_LEAGUE,
        params: {
            ...target
        }
    };
}
export function resolveSportEventsDetailsPageLink(target) {
    return {
        name: RouteName.SPORT_EVENT_DETAILS,
        params: {
            ...target
        }
    };
}
export function resolveLivePageLink(target) {
    return {
        name: RouteName.SPORTLINE_LIVE_EVENTS,
        params: target.urlName ? {
            family: target.urlName.toLowerCase()
        } : {}
    };
}
export function resolveCybersportPageLink(target) {
    const regionParameters = target.urlName ? {
        region: target.urlName.toLowerCase()
    } : {};
    return {
        name: RouteName.SPORTLINE_CYBERSPORT,
        params: {
            ...regionParameters
        }
    };
}
function isStringishLocation(value) {
    return !value || isString(value);
}
/**
 * Used to resolve links in main header and mobile sportline navigation element
 */ export function resolveHeaderBarsSportlinePageLink(to, persistLocation) {
    const { live, sports, cybersport } = persistLocation;
    switch(to){
        case RouteName.SPORTLINE_LIVE_EVENTS:
            return isStringishLocation(live) ? resolveLivePageLink({
                urlName: live
            }) : resolveLivePageLink({
                urlName: live.sportFamily
            });
        case RouteName.SPORTLINE_PREMATCH_EVENTS:
            if (isStringishLocation(sports)) return resolveSportsPageLink({
                urlName: sports
            });
            if (sports.leagueId && sports.leagueUrlName && sports.regionUrlName && sports.sportFamily) return resolveLeaguePageLink({
                id: sports.leagueId,
                urlName: sports.leagueUrlName,
                regionUrlName: sports.regionUrlName,
                sportFamily: sports.sportFamily
            });
            if (sports.regionId && sports.regionUrlName && sports.sportFamily) return resolveRegionPageLink({
                id: sports.regionId,
                urlName: sports.regionUrlName,
                sportFamily: sports.sportFamily
            });
            return resolveSportsPageLink({
                urlName: sports.sportFamily
            });
        case RouteName.SPORTLINE_CYBERSPORT:
            return isStringishLocation(cybersport) ? resolveCybersportPageLink({
                urlName: cybersport
            }) : resolveCybersportPageLink({
                urlName: cybersport.regionUrlName
            });
        default:
            return {
                name: to ?? RouteName.HOME
            };
    }
}
