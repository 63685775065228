import { computed, watch } from 'vue';
import { getLocationQueryParams } from '@leon-hub/service-locator-env';
import { logger } from '@leon-hub/logging';
import { getLexisNexisProfiledStorage } from 'web/src/modules/lexis-nexis-integration/utils/getLexisNexisProfiledStorage';
import { isCustomerProfiled } from './isCustomerProfiled';
import { getLexisNexisSessionId } from '../api';
import { useLexisNexisIntegrationStore } from '../store/useLexisNexisIntegrationStore';
import { isConfiguredOptions } from './isConfiguredOptions';
import { log } from './log';
import { runLexisNexisApiProfile } from './runLexisNexisApiProfile';
import { useIsDevIP } from '../../core/composables/root';
export const createActivationEffect = (getControls)=>async function activationEffect() {
        const { stop, continueEffect } = getControls();
        try {
            const store = useLexisNexisIntegrationStore();
            const login = computed(()=>store.customerLogin);
            log('waiting for authorization');
            const uid = login.value ?? await new Promise((resolve)=>{
                if (login.value) {
                    resolve(login.value);
                    return;
                }
                const unwatch = watch(login, (newLogin)=>{
                    if (newLogin) {
                        unwatch();
                        resolve(newLogin);
                    }
                });
            });
            log('uid: %j', uid);
            if (!uid) return;
            // TODO: Remove delay before continueEffect usage (getControls issue).
            await Promise.resolve();
            const storageEffect = await continueEffect(()=>isCustomerProfiled(uid));
            log('storageEffect: %j', storageEffect);
            if (storageEffect.dead) return;
            const { value } = storageEffect;
            log('storage: %j', value);
            if (value) {
                log('already profiled');
                return;
            }
            const session = await continueEffect(()=>{
                log('retrieving session..');
                return getLexisNexisSessionId({
                    login: uid
                });
            });
            if (session.dead) return;
            await continueEffect(async ()=>{
                const { lexGuardOrgId, lexGuardAPIUrl, lexGuardProfilingSubdomain } = store;
                const timeout = useDevTimeout();
                const options = {
                    lexGuardOrgId,
                    lexGuardAPIUrl,
                    lexGuardProfilingSubdomain,
                    sessionId: session.value,
                    ...timeout ? {
                        timeout
                    } : {}
                };
                isConfiguredOptions(options), JSON.stringify(options);
                const profiledStorage = getLexisNexisProfiledStorage(uid);
                try {
                    await runLexisNexisApiProfile(options, {
                        log: log.extend('profile')
                    });
                    // TODO: clean when LEONWEB-14303 resolved.
                    logger.warn(`lex-nex profiled: ${JSON.stringify({
                        ...options,
                        uid
                    })}`);
                    await profiledStorage.setProfiledStatus(true);
                    log('profiled');
                } catch (err) {
                    await profiledStorage.setProfiledStatus(false);
                    throw err;
                } finally{
                    store.setProfiled(uid);
                    log('stored');
                }
            });
        } catch (err) {
            logger.error('lexis-nexis integration activation error:', err);
        } finally{
            stop();
        }
    };
function useDevTimeout() {
    const { isDevIP } = useIsDevIP();
    if (!isDevIP.value) return null;
    const rawValue = getLocationQueryParams()['lexis-timeout'];
    if (rawValue) {
        const timeout = Number(rawValue);
        if (Number.isFinite(timeout)) return timeout;
    }
    return null;
}
