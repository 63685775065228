import { toRef, ref, computed } from 'vue';
export function createSportlineFragmentBasisKeyValue(param) {
    let { sportId, salt } = param;
    return [
        sportId,
        salt
    ].filter((value)=>!!value).join('_') ?? '';
}
export function createSportlineFragmentBasisKey(props) {
    const sportId = props.sportId ? toRef(props.sportId) : ref(null);
    const salt = props.salt ? toRef(props.salt) : ref(null);
    return computed(()=>createSportlineFragmentBasisKeyValue({
            salt: salt.value,
            sportId: sportId.value
        }));
}
