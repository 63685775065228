import { InputEventType } from 'web/src/components/Input/enums';
import { inputFileEventToFormDataEvent } from 'web/src/components/Form/utils';
const useFormFileSingleEvents = (emit)=>{
    const emitChange = (event)=>{
        emit(InputEventType.CHANGE, inputFileEventToFormDataEvent(event));
    };
    const emitFocus = (event)=>{
        emit(InputEventType.FOCUS, inputFileEventToFormDataEvent(event));
    };
    const emitBlur = (event)=>{
        emit(InputEventType.BLUR, inputFileEventToFormDataEvent(event));
    };
    return {
        emitChange,
        emitFocus,
        emitBlur
    };
};
export default useFormFileSingleEvents;
