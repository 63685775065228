import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed, toRef } from 'vue';
import { useCasinoTopStore } from 'web/src/modules/casino/submodules/top/useCasinoTopStore';
import { CasinoHomeBlockPriority } from 'web/src/modules/home/submodules/casino/enums';
import CasinoHomeJackpotsBlock from 'web/src/modules/home/submodules/casino/components/CasinoHomeJackpotsBlock/CasinoHomeJackpotsBlock.vue';
import HomePromotionsBlock from 'web/src/modules/home/submodules/promotions/views/HomePromotionsBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoHomePromoBlock',
    props: {
        priority: {
            default: CasinoHomeBlockPriority.Jackpots
        }
    },
    setup (__props) {
        const props = __props;
        const hasJackpots = toRef(useCasinoTopStore(), 'hasJackpots');
        const isPromotionsBlock = computed(()=>props.priority === CasinoHomeBlockPriority.Promotions && hasJackpots.value || props.priority === CasinoHomeBlockPriority.Jackpots && !hasJackpots.value);
        const isJackpotsBlock = computed(()=>props.priority === CasinoHomeBlockPriority.Jackpots && hasJackpots.value);
        return (_ctx, _cache)=>isPromotionsBlock.value ? (_openBlock(), _createBlock(HomePromotionsBlock, {
                key: 0
            })) : isJackpotsBlock.value ? (_openBlock(), _createBlock(CasinoHomeJackpotsBlock, {
                key: 1
            })) : _createCommentVNode("", true);
    }
});
