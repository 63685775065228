import { toRef, computed } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export function useResetPersistLocationSettings() {
    const siteConfigStore = useSiteConfigStore();
    const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');
    const isResetLocationEnabled = computed(()=>!!sportLineBlock.value?.familyFilterResetEnabled);
    return {
        isResetLocationEnabled
    };
}
