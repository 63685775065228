import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { AlertIconName, IconName } from '@leon-hub/icons';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsDepositsOtherSuccessModal',
    props: {
        text: {},
        isInProfile: {
            type: Boolean
        }
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { $translate } = useI18n();
        function onButtonClick(param) {
            let { action } = param;
            if (action === DialogAction.MODAL_CLOSE) emit('close');
        }
        const modalProperties = computed(()=>{
            const modalProps = {
                iconName: IconName.CHECK_OUTLINE,
                iconKind: JumbotronIconKind.SUCCESS,
                iconName: IconName.CHECK_OUTLINE,
                title: $translate('WEB2_YOUR_DEPOSIT_IS').value,
                isProfileLayout: props.isInProfile,
                buttons: [
                    {
                        action: DialogAction.MODAL_CLOSE,
                        kind: ButtonKind.BASE,
                        label: $translate('JSP_PCL_FBOT_CLOSE').value
                    }
                ]
            };
            if (!props.isInProfile) return {
                isAlert: true,
                modal: {
                    ...modalProps,
                    isCentered: false,
                    width: ModalWidth.SMALL
                }
            };
            return {
                modal: {
                    ...modalProps,
                    isInnerModal: true,
                    fullHeight: true
                }
            };
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, _mergeProps(modalProperties.value, {
                onButtonClick: onButtonClick,
                onClose: _cache[0] || (_cache[0] = ($event)=>emit('close'))
            }), {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['deposits-success__text'])
                        }, _toDisplayString(_ctx.text), 3)
                    ]),
                _: 1
            }, 16)), [
                [
                    _directive_auto_id,
                    'PaymentsDepositsOtherSuccessModal'
                ]
            ]);
        };
    }
});
