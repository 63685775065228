import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VFadeCarouselPointsPagination from 'web/src/components/FadeCarousel/VFadeCarouselPointsPagination/VFadeCarouselPointsPagination.vue';
import { PaginationLocation } from 'web/src/components/FadeCarousel/enums';
import { useVFadeCarousel } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VFadeCarousel',
    props: {
        initSlideIndex: {
            default: 0
        },
        autoplayTimeout: {
            default: 0
        },
        pagination: {},
        paginationLocation: {
            default: PaginationLocation.CENTER
        }
    },
    emits: [
        "slide-changed",
        "mounted"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { holder, onClick, onMouseEnter, onMouseLeave, carouselOptions, isPointsPagination, moveToSlide } = useVFadeCarousel(props, emits);
        __expose({
            moveToSlide
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "holder",
                ref: holder,
                class: _normalizeClass([
                    "swiper",
                    _ctx.$style['fade-carousel']
                ]),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onClick) && _unref(onClick)(...args);
                }),
                onMouseenter: _cache[1] || (_cache[1] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onMouseEnter) && _unref(onMouseEnter)(...args);
                }),
                onMouseleave: _cache[2] || (_cache[2] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onMouseLeave) && _unref(onMouseLeave)(...args);
                })
            }, [
                _renderSlot(_ctx.$slots, "default", {
                    options: _unref(carouselOptions)
                }),
                _unref(carouselOptions).slidesCount > 1 ? _renderSlot(_ctx.$slots, "pagination", {
                    key: 0
                }, ()=>[
                        _unref(isPointsPagination) ? (_openBlock(), _createBlock(VFadeCarouselPointsPagination, {
                            key: 0,
                            "pagination-location": _ctx.paginationLocation
                        }, null, 8, [
                            "pagination-location"
                        ])) : _createCommentVNode("", true)
                    ]) : _createCommentVNode("", true)
            ], 34)), [
                [
                    _directive_auto_id,
                    'VFadeCarousel'
                ]
            ]);
        };
    }
});
