import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "data-test-error"
];
const _hoisted_2 = [
    "data-test-error"
];
import useInputHint from './useInputHint';
import { dataTestHint as vDataTestHint, dataTestHintRight as vDataTestHintRight, dataTestHintLink as vDataTestHintLink } from '../../directives';
export default /*@__PURE__*/ _defineComponent({
    __name: 'InputHint',
    props: {
        error: {
            default: ''
        },
        errorRight: {
            default: ''
        },
        hint: {
            default: ''
        },
        hintRight: {
            default: ''
        },
        hintLink: {
            default: null
        },
        hintLinkRight: {
            default: null
        }
    },
    setup (__props) {
        const props = __props;
        const { hintErrorText, hintErrorTextRight, hintLinkComponent, hintLinkRightComponent, hintLinkLeftProperties, hintLinkRightProperties } = useInputHint(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['wrapper-hint'])
            }, [
                _renderSlot(_ctx.$slots, "default", {}, ()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['wrapper-hint__wrapper'])
                        }, [
                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                "data-test-error": !!_ctx.error || !!_ctx.$slots.errorLeft,
                                class: _normalizeClass({
                                    [_ctx.$style['wrapper-hint__text']]: true,
                                    [_ctx.$style['wrapper-hint__text--left']]: true,
                                    [_ctx.$style['wrapper-hint__text--error']]: !!_ctx.error || !!_ctx.$slots.errorLeft
                                })
                            }, [
                                _renderSlot(_ctx.$slots, "hintLeft", {}, ()=>[
                                        _ctx.hintLink && !_ctx.error ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(hintLinkComponent)), _mergeProps({
                                            key: 0,
                                            class: _ctx.$style['wrapper-hint__link']
                                        }, _unref(hintLinkLeftProperties)), {
                                            default: _withCtx(()=>[
                                                    _createTextVNode(_toDisplayString(_ctx.hintLink.text), 1)
                                                ]),
                                            _: 1
                                        }, 16, [
                                            "class"
                                        ])), [
                                            [
                                                _unref(vDataTestHintLink)
                                            ]
                                        ]) : _renderSlot(_ctx.$slots, "errorLeft", {
                                            key: 1
                                        }, ()=>[
                                                _createTextVNode(_toDisplayString(_unref(hintErrorText)), 1)
                                            ])
                                    ])
                            ], 10, _hoisted_1)), [
                                [
                                    _unref(vDataTestHint)
                                ]
                            ]),
                            _ctx.hintLinkRight || _unref(hintErrorTextRight) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                "data-test-error": !!_ctx.errorRight,
                                class: _normalizeClass({
                                    [_ctx.$style['wrapper-hint__text']]: true,
                                    [_ctx.$style['wrapper-hint__text--right']]: true,
                                    [_ctx.$style['wrapper-hint__text--error']]: !!_ctx.errorRight
                                })
                            }, [
                                _renderSlot(_ctx.$slots, "hintRight", {}, ()=>[
                                        _ctx.hintLinkRight && !_ctx.errorRight ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(hintLinkRightComponent)), _mergeProps({
                                            key: 0,
                                            class: _ctx.$style['wrapper-hint__link']
                                        }, _unref(hintLinkRightProperties)), {
                                            default: _withCtx(()=>[
                                                    _createTextVNode(_toDisplayString(_ctx.hintLinkRight.text), 1)
                                                ]),
                                            _: 1
                                        }, 16, [
                                            "class"
                                        ])), [
                                            [
                                                _unref(vDataTestHintLink)
                                            ]
                                        ]) : (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 1
                                        }, [
                                            _createTextVNode(_toDisplayString(_unref(hintErrorTextRight)), 1)
                                        ], 64))
                                    ])
                            ], 10, _hoisted_2)), [
                                [
                                    _unref(vDataTestHintRight)
                                ]
                            ]) : _createCommentVNode("", true)
                        ], 2)
                    ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'InputHint'
                ]
            ]);
        };
    }
});
