import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import ReferralProgramPopupReward from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/ReferralProgramPopupReward.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VStepDescription from 'web/src/components/StepDescription/VStepDescription/VStepDescription.vue';
import { useReferralProgramRegistrationBody } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramRegistrationBody',
    props: {
        currency: {},
        rewards: {},
        referralRequirements: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { stepDescriptionProps, buttonProps, referralProgramPopupRewardProps, registrationDescription, registrationRule, registrationTitle, handleClick } = useReferralProgramRegistrationBody(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['referral-program-reg-body'])
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['referral-program-reg-body__title'])
                }, _toDisplayString(_unref(registrationDescription)), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-reg-body__column'])
                }, [
                    _createVNode(ReferralProgramPopupReward, _normalizeProps(_guardReactiveProps(_unref(referralProgramPopupRewardProps))), null, 16)
                ], 2),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['referral-program-reg-body__rule'])
                }, _toDisplayString(_unref(registrationRule)), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-reg-body__how-to'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-reg-body__title'])
                    }, _toDisplayString(_unref(registrationTitle)), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-reg-body__steps'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(stepDescriptionProps), (step, index)=>(_openBlock(), _createBlock(VStepDescription, _mergeProps({
                                key: index,
                                ref_for: true
                            }, step), null, 16))), 128))
                    ], 2)
                ], 2),
                _createVNode(VButton, _mergeProps({
                    class: _ctx.$style['referral-program-reg-body__button']
                }, _unref(buttonProps), {
                    onClick: _unref(handleClick)
                }), null, 16, [
                    "class",
                    "onClick"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramRegistrationBody'
                ]
            ]);
        };
    }
});
