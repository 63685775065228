import isMatchedChange from './isMatchedChange';
// eslint-disable-next-line import/prefer-default-export
export function isSportEventResponseIncludesChanges(currentResponse, changes) {
    if (!currentResponse || !changes) return false;
    const events = 'events' in currentResponse ? currentResponse.events : [
        currentResponse
    ];
    return events.some((event)=>(event.markets ?? []).some((market)=>(market.runners || []).some((runner)=>(changes || []).some((change)=>isMatchedChange(change, {
                        eventId: event.id,
                        marketId: market.id,
                        runnerId: runner.id
                    })))));
}
