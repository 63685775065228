import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { Tag } from '@leon-hub/tags';
import { IconSize } from '@leon-hub/icons';
import BadgeKind from 'web/src/components/Badge/VBadge/enums/BadgeKind';
import ButtonKind from 'web/src/components/Button/VButton/enums/ButtonKind';
import { useModalWindowHeader } from 'web/src/components/Modal/ModalWindowHeader/composables';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import ModalWindowHeaderPrefixAbstract from 'web/src/components/Modal/ModalWindowHeader/components/ModalWindowHeaderPrefixAbstract/ModalWindowHeaderPrefixAbstract.vue';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ModalWindowHeader',
    props: {
        prefixIconName: {},
        suffixIconName: {},
        title: {
            default: ''
        },
        titleTag: {
            default: Tag.DIV
        },
        badge: {},
        badgeKind: {
            default: BadgeKind.DEFAULT
        },
        isDragAllowed: {
            type: Boolean
        },
        isAvatar: {
            type: Boolean
        },
        type: {
            default: 'default'
        },
        isBadgeCounter: {
            type: Boolean
        },
        isCloseButtonOutside: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        "drag-start",
        "prefix-click",
        "suffix-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { pageHeader, isSticky } = useModalWindowHeader();
        function onMouseDown(event) {
            if (props.isDragAllowed) emit('drag-start', event);
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "pageHeader",
                ref: pageHeader,
                class: _normalizeClass({
                    [_ctx.$style['modal-header']]: true,
                    [_ctx.$style['modal-header--draggable']]: _ctx.isDragAllowed,
                    [_ctx.$style['modal-header--no-title']]: !_ctx.title && !_ctx.$slots.title,
                    [_ctx.$style['modal-header--avatar']]: _ctx.isAvatar,
                    [_ctx.$style['modal-header--empty']]: !_ctx.title && !_ctx.suffixIconName && !props.prefixIconName && !_ctx.$slots.title && !_ctx.$slots.suffix,
                    [_ctx.$style['modal-header--stickied']]: _unref(isSticky),
                    [_ctx.$style[`modal-header--type__${_ctx.type}`]]: _ctx.type
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['modal-header__prefix'])
                }, [
                    _renderSlot(_ctx.$slots, "prefix", {}, ()=>[
                            _createVNode(ModalWindowHeaderPrefixAbstract, {
                                "prefix-icon-name": _ctx.prefixIconName,
                                class: _normalizeClass(_ctx.$style['modal-header__prefix-abstract']),
                                onPrefixClick: _cache[0] || (_cache[0] = ($event)=>emit('prefix-click'))
                            }, null, 8, [
                                "prefix-icon-name",
                                "class"
                            ])
                        ])
                ], 2),
                _renderSlot(_ctx.$slots, "title", {}, ()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['modal-header__content']),
                            onMousedown: onMouseDown
                        }, [
                            _withDirectives(_createVNode(VDynamicContent, {
                                tag: _ctx.titleTag,
                                content: _ctx.title,
                                class: _normalizeClass(_ctx.$style['modal-header__title'])
                            }, null, 8, [
                                "tag",
                                "content",
                                "class"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'modal-header__title'
                                    }
                                ]
                            ]),
                            _ctx.badge ? (_openBlock(), _createBlock(VBadge, {
                                key: 0,
                                label: _ctx.badge,
                                kind: _ctx.badgeKind,
                                "is-counter": _ctx.isBadgeCounter,
                                class: _normalizeClass(_ctx.$style['modal-header__badge'])
                            }, null, 8, [
                                "label",
                                "kind",
                                "is-counter",
                                "class"
                            ])) : _createCommentVNode("", true)
                        ], 34)
                    ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['modal-header__suffix'])
                }, [
                    _renderSlot(_ctx.$slots, "suffix", {}, ()=>[
                            _ctx.suffixIconName ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _withDirectives((_openBlock(), _createBlock(VButton, {
                                    key: 1,
                                    kind: _unref(ButtonKind).NAV,
                                    "icon-size": _unref(IconSize).SIZE_24,
                                    "icon-name": _ctx.suffixIconName,
                                    onClick: _cache[3] || (_cache[3] = ($event)=>emit('suffix-click'))
                                }, null, 8, [
                                    "kind",
                                    "icon-size",
                                    "icon-name"
                                ])), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'modal-suffix-button'
                                        }
                                    ]
                                ])
                            ], 64)) : _createCommentVNode("", true)
                        ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ModalWindowHeader'
                ]
            ]);
        };
    }
});
