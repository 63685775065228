import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';
import { BreadcrumbType } from 'web/src/components/Breadcrumbs/VBreadcrumb/enums';
import VBreadcrumb from 'web/src/components/Breadcrumbs/VBreadcrumb/VBreadcrumb.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import BreadcrumbsRelatedListItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/BreadcrumbsRelatedListItem.vue';
import { resolveLeaguePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { ListType } from 'web/src/components/List/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueBreadcrumbsItem',
    props: {
        leagueElement: {},
        relatedLeagues: {},
        title: {},
        position: {},
        open: {
            type: Boolean
        },
        to: {}
    },
    emits: [
        "toggle"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VBreadcrumb, {
                type: _unref(BreadcrumbType).MENU,
                title: _ctx.title,
                position: _ctx.position,
                open: _ctx.open,
                to: _ctx.to,
                "can-expand": _ctx.relatedLeagues.length > 0,
                controlled: "",
                onToggle: _cache[0] || (_cache[0] = ($event)=>emit('toggle', _unref(BreadcrumbId).Leagues))
            }, {
                default: _withCtx((param)=>{
                    let { forceHide } = param;
                    return [
                        _createVNode(VList, {
                            "list-type": _unref(ListType).GROUP
                        }, {
                            default: _withCtx(()=>[
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedLeagues, (relatedLeague)=>(_openBlock(), _createBlock(BreadcrumbsRelatedListItem, {
                                            key: `league-breadcrumb-${relatedLeague.league.id}`,
                                            "is-active": !!_ctx.leagueElement && _ctx.leagueElement.league.id === relatedLeague.league.id,
                                            title: relatedLeague.league.name,
                                            to: _unref(resolveLeaguePageLink)(relatedLeague.league.navigationParameters),
                                            onLinkClick: forceHide
                                        }, null, 8, [
                                            "is-active",
                                            "title",
                                            "to",
                                            "onLinkClick"
                                        ]))), 128))
                                ]),
                            _: 2
                        }, 1032, [
                            "list-type"
                        ])
                    ];
                }),
                _: 1
            }, 8, [
                "type",
                "title",
                "position",
                "open",
                "to",
                "can-expand"
            ])), [
                [
                    _directive_auto_id,
                    'LeagueBreadcrumbsItem'
                ]
            ]);
        };
    }
});
