import { ComponentKey } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let UserAgentComponent = class UserAgentComponent extends AbstractFingerprintComponent {
    async getComponentResult() {
        return this.result([
            window.navigator.userAgent
        ]);
    }
    constructor(){
        super(ComponentKey.UserAgent);
    }
};
export { UserAgentComponent as default };
