import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { useLobbyEgsNoGames } from 'web/src/modules/lobby/components/LobbyEgsNoGames/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyEgsNoGames',
    props: {
        heading: {},
        text: {},
        buttonLabel: {},
        routeName: {},
        isButtonHidden: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { noGamesButtonProperties, onClick } = useLobbyEgsNoGames(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VJumbotron), {
                heading: _ctx.heading || _ctx.$t('WEB2_GAMES_NO_RESULT_TITLE'),
                text: _ctx.text || _ctx.$t('WEB2_GAMES_NO_RESULT_TEXT'),
                class: _normalizeClass(_ctx.$style['lobby-egs-no-games'])
            }, _createSlots({
                _: 2
            }, [
                _ctx.isButtonHidden ? void 0 : {
                    name: "footer",
                    fn: _withCtx(()=>[
                            _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(noGamesButtonProperties), {
                                label: _ctx.buttonLabel || _ctx.$t('WEB2_GOHOME'),
                                onClick: _unref(onClick)
                            }), null, 16, [
                                "label",
                                "onClick"
                            ])
                        ]),
                    key: "0"
                }
            ]), 1032, [
                "heading",
                "text",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'LobbyEgsNoGames'
                ]
            ]);
        };
    }
});
