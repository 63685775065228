import { InputEventType } from 'web/src/components/Input/enums';
import getFormDataEventWithFormattedCPFValue from './getFormDataEventWithFormattedCPFValue';
const useCPFNumberEvents = (emit)=>{
    const emitChange = (event)=>{
        emit(InputEventType.CHANGE, getFormDataEventWithFormattedCPFValue(event));
    };
    const emitInput = (event)=>{
        emit(InputEventType.INPUT, getFormDataEventWithFormattedCPFValue(event));
    };
    const emitFocus = (event)=>{
        emit(InputEventType.FOCUS, getFormDataEventWithFormattedCPFValue(event));
    };
    const emitBlur = (event)=>{
        emit(InputEventType.BLUR, getFormDataEventWithFormattedCPFValue(event));
    };
    return {
        emitChange,
        emitInput,
        emitFocus,
        emitBlur
    };
};
export default useCPFNumberEvents;
