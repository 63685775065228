import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { onBeforeUnmount } from 'vue';
import { Tag } from '@leon-hub/tags';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VForm } from 'web/src/components/Form';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { useResponsibleGamblingForm } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingForm',
    props: {
        schema: {},
        uiSchema: {},
        timestamp: {},
        limit: {},
        limitName: {},
        formType: {},
        loading: {
            type: Boolean
        }
    },
    emits: [
        "form-output",
        "delete-limit"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { form, timeName, dynamicUiSchema, buttonProperties, handleSubmit, handleInput, handleButtonClick, beforeUnmount } = useResponsibleGamblingForm(props, emit);
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['responsible-gambling-form__wrapper'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-form__form'])
                }, [
                    _withDirectives(_createVNode(_unref(VForm), {
                        ref_key: "form",
                        ref: form,
                        schema: _ctx.schema,
                        "ui-schema": _unref(dynamicUiSchema),
                        "is-pending": _ctx.loading,
                        onChange: _unref(handleInput),
                        onSubmit: _unref(handleSubmit),
                        onInput: _unref(handleInput)
                    }, null, 8, [
                        "schema",
                        "ui-schema",
                        "is-pending",
                        "onChange",
                        "onSubmit",
                        "onInput"
                    ]), [
                        [
                            _directive_data_test,
                            {
                                el: 'core-custom-input',
                                name: _ctx.limitName
                            }
                        ]
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['responsible-gambling-form__button'])
                }, [
                    _unref(timeName) ? (_openBlock(), _createBlock(_unref(TextSlotPlaceholder), {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['responsible-gambling-form__time']),
                        text: _ctx.$t('WEB2_YOU_CAN_CHANGE'),
                        tag: _unref(Tag).DIV
                    }, {
                        hours: _withCtx(()=>[
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['responsible-gambling-form__time-accent'])
                                }, _toDisplayString(_unref(timeName).hours || ''), 3)
                            ]),
                        minutes: _withCtx(()=>[
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['responsible-gambling-form__time-accent'])
                                }, _toDisplayString(_unref(timeName).minutes || ''), 3)
                            ]),
                        _: 1
                    }, 8, [
                        "class",
                        "text",
                        "tag"
                    ])) : (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 1
                        }, _unref(buttonProperties), {
                            onClick: _unref(handleButtonClick)
                        }), null, 16, [
                            "onClick"
                        ]))
                    ], 64))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingForm'
                ]
            ]);
        };
    }
});
