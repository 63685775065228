import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock } from "vue";
const _hoisted_1 = [
    "id",
    "autofocus",
    "type",
    "disabled",
    "autocomplete",
    "name",
    "value",
    "placeholder"
];
import InputButton from 'web/src/components/Input/components/InputButton/InputButton.vue';
import { useAutofocus, useNumberStringInput } from '../../composables';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextInputTypes from '../../enums/TextInputTypes';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'CurrencyInput',
    props: {
        currency: {},
        currencyRight: {},
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'currency'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {},
        autocomplete: {},
        maximumFractionDigits: {
            default: 2
        }
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { uniqueId, isEmpty, isFocus, isHover, inputValue, showClearButton, onClear, onBlur, onFocus, onMouseOver, onMouseLeave, onChange, onInput, onPaste } = useNumberStringInput(props, emits);
        const { focus, focusable } = useAutofocus(props);
        __expose({
            focus
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), {
                class: _normalizeClass({
                    [_ctx.$style['currency-input']]: true,
                    [_ctx.$style['currency-input--large']]: _ctx.isLarge
                }),
                "input-id": _unref(uniqueId),
                label: _ctx.label,
                disabled: _ctx.disabled,
                "is-focus": _unref(isFocus),
                "is-empty": _unref(isEmpty),
                hint: _ctx.hint,
                "hint-right": _ctx.hintRight,
                "hint-link": _ctx.hintLink,
                "hint-link-right": _ctx.hintLinkRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                "is-large": _ctx.isLarge,
                "clickable-suffix": _unref(showClearButton)
            }, {
                input: _withCtx(()=>[
                        _createVNode(_unref(InputBorderWrapper), {
                            "is-focus": _unref(isFocus),
                            "is-disabled": _ctx.disabled,
                            "is-hover": _unref(isHover),
                            "has-error": !!(_ctx.error || _ctx.errorRight)
                        }, {
                            default: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass({
                                            [_ctx.$style['currency-input__container']]: true,
                                            [_ctx.$style['currency-input__container--error']]: !!(_ctx.error || _ctx.errorRight),
                                            [_ctx.$style['currency-input__container--disabled']]: _ctx.disabled,
                                            [_ctx.$style['currency-input__container--icon-suffix']]: _unref(showClearButton)
                                        }),
                                        onClick: _cache[5] || (_cache[5] = ($event)=>_unref(focus)()),
                                        onMouseover: _cache[6] || (_cache[6] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onMouseOver) && _unref(onMouseOver)(...args);
                                        }),
                                        onMouseleave: _cache[7] || (_cache[7] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onMouseLeave) && _unref(onMouseLeave)(...args);
                                        })
                                    }, [
                                        _ctx.currency ? _withDirectives((_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            class: _normalizeClass([
                                                _ctx.$style['currency-input__currency'],
                                                _ctx.$style['currency-input__currency--left']
                                            ])
                                        }, _toDisplayString(_ctx.currency), 3)), [
                                            [
                                                _vShow,
                                                _unref(inputValue)
                                            ]
                                        ]) : _createCommentVNode("", true),
                                        _createElementVNode("div", {
                                            class: _normalizeClass({
                                                [_ctx.$style['currency-input__content']]: true,
                                                [_ctx.$style['currency-input__content--empty']]: !_ctx.value
                                            })
                                        }, [
                                            _createElementVNode("input", {
                                                id: _unref(uniqueId),
                                                ref_key: "focusable",
                                                ref: focusable,
                                                autofocus: process.env.VUE_APP_OS_IOS ? void 0 : _ctx.autofocus,
                                                type: _unref(TextInputTypes).TEL,
                                                disabled: _ctx.disabled,
                                                autocomplete: _ctx.autocomplete,
                                                name: _ctx.name,
                                                value: _unref(inputValue),
                                                placeholder: _ctx.placeholder,
                                                class: _normalizeClass(_ctx.$style['currency-input__input']),
                                                onInput: _cache[0] || (_cache[0] = //@ts-ignore
                                                function() {
                                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                        args[_key] = arguments[_key];
                                                    }
                                                    return _unref(onInput) && _unref(onInput)(...args);
                                                }),
                                                onChange: _cache[1] || (_cache[1] = //@ts-ignore
                                                function() {
                                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                        args[_key] = arguments[_key];
                                                    }
                                                    return _unref(onChange) && _unref(onChange)(...args);
                                                }),
                                                onFocus: _cache[2] || (_cache[2] = //@ts-ignore
                                                function() {
                                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                        args[_key] = arguments[_key];
                                                    }
                                                    return _unref(onFocus) && _unref(onFocus)(...args);
                                                }),
                                                onBlur: _cache[3] || (_cache[3] = //@ts-ignore
                                                function() {
                                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                        args[_key] = arguments[_key];
                                                    }
                                                    return _unref(onBlur) && _unref(onBlur)(...args);
                                                }),
                                                onPaste: _cache[4] || (_cache[4] = //@ts-ignore
                                                function() {
                                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                        args[_key] = arguments[_key];
                                                    }
                                                    return _unref(onPaste) && _unref(onPaste)(...args);
                                                })
                                            }, null, 42, _hoisted_1),
                                            _withDirectives(_createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['currency-input__width-holder']),
                                                "aria-hidden": "true"
                                            }, _toDisplayString(_ctx.value), 3), [
                                                [
                                                    _vShow,
                                                    !!_ctx.value
                                                ]
                                            ])
                                        ], 2),
                                        _ctx.currencyRight ? _withDirectives((_openBlock(), _createElementBlock("span", {
                                            key: 1,
                                            class: _normalizeClass([
                                                _ctx.$style['currency-input__currency'],
                                                _ctx.$style['currency-input__currency--right']
                                            ])
                                        }, _toDisplayString(_ctx.currencyRight), 3)), [
                                            [
                                                _vShow,
                                                _unref(inputValue)
                                            ]
                                        ]) : _createCommentVNode("", true)
                                    ], 34)
                                ]),
                            _: 1
                        }, 8, [
                            "is-focus",
                            "is-disabled",
                            "is-hover",
                            "has-error"
                        ])
                    ]),
                iconSuffix: _withCtx(()=>[
                        _withDirectives(_createVNode(InputButton, {
                            title: _ctx.$t('WEB2_CLEAR'),
                            "is-large": _ctx.isLarge,
                            onClick: _unref(onClear)
                        }, null, 8, [
                            "title",
                            "is-large",
                            "onClick"
                        ]), [
                            [
                                _vShow,
                                _unref(showClearButton)
                            ],
                            [
                                _directive_data_test,
                                {
                                    el: 'clear-icon-input-button',
                                    visible: !_unref(showClearButton)
                                }
                            ]
                        ])
                    ]),
                _: 1
            }, 8, [
                "class",
                "input-id",
                "label",
                "disabled",
                "is-focus",
                "is-empty",
                "hint",
                "hint-right",
                "hint-link",
                "hint-link-right",
                "error",
                "error-right",
                "is-large",
                "clickable-suffix"
            ])), [
                [
                    _directive_auto_id,
                    'CurrencyInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'currency-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
