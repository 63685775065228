import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = [
    "src"
];
import { computed } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PushNotificationsOnboardingImage',
    emits: [
        "load",
        "error"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { isLight } = useTheme();
        const imageSrc = computed(()=>isLight.value ? // eslint-disable-next-line import/no-dynamic-require
            require("web/src/assets/images/li/default/push-onboarding-light.svg") : // eslint-disable-next-line import/no-dynamic-require
            require("web/src/assets/images/li/default/push-onboarding.svg"));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("img", {
                src: imageSrc.value,
                alt: "main",
                onLoad: _cache[0] || (_cache[0] = ($event)=>emit('load')),
                onError: _cache[1] || (_cache[1] = ($event)=>emit('error'))
            }, null, 40, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'PushNotificationsOnboardingImage'
                ]
            ]);
        };
    }
});
