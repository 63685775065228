import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { useInfoCountdown } from './useInfoCountdown';
export default /*@__PURE__*/ _defineComponent({
    __name: 'InfoCountdown',
    props: {
        kickoff: {},
        isNarrow: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const kickoff = toRef(props, 'kickoff');
        const { displayedTimeLeft, daysLabel, hoursLabel, minutesLabel, secondsLabel } = useInfoCountdown({
            kickoff
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(displayedTimeLeft) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['prematch-info-countdown']]: true,
                    [_ctx.$style['prematch-info-countdown--narrow']]: _ctx.isNarrow
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['prematch-info-countdown__item'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['prematch-info-countdown__counter'])
                    }, _toDisplayString(_unref(displayedTimeLeft).days), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['prematch-info-countdown__label'])
                    }, _toDisplayString(_unref(daysLabel)), 3)
                ], 2),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['prematch-info-countdown__delimiter'])
                }, ":", 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['prematch-info-countdown__item'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['prematch-info-countdown__counter'])
                    }, _toDisplayString(_unref(displayedTimeLeft).hours), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['prematch-info-countdown__label'])
                    }, _toDisplayString(_unref(hoursLabel)), 3)
                ], 2),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['prematch-info-countdown__delimiter'])
                }, ":", 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['prematch-info-countdown__item'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['prematch-info-countdown__counter'])
                    }, _toDisplayString(_unref(displayedTimeLeft).minutes), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['prematch-info-countdown__label'])
                    }, _toDisplayString(_unref(minutesLabel)), 3)
                ], 2),
                false !== _unref(displayedTimeLeft).seconds ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['prematch-info-countdown__delimiter'])
                    }, ":", 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['prematch-info-countdown__item'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['prematch-info-countdown__counter'])
                        }, _toDisplayString(_unref(displayedTimeLeft).seconds), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['prematch-info-countdown__label'])
                        }, _toDisplayString(_unref(secondsLabel)), 3)
                    ], 2)
                ], 64)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'InfoCountdown'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
