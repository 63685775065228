import { ref } from 'vue';
import { getClientDimension, getIsPortraitOrientation } from '@leon-hub/utils';
import { useWindowResize } from './useWindowResize';
export function useDocumentDimensionSafeSubscribe() {
    let { debounce, onWindowResize } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        debounce: 100
    };
    const clientDimension = ref({
        clientHeight: 0,
        clientWidth: 0
    });
    const isPortraitOrientation = ref(false);
    function recalculate() {
        clientDimension.value = getClientDimension();
        isPortraitOrientation.value = getIsPortraitOrientation();
        onWindowResize?.();
    }
    useWindowResize(recalculate, {
        debounce
    });
    recalculate();
    return {
        clientDimension,
        isPortraitOrientation
    };
}
