import { logger } from '@leon-hub/logging';
import { assertUnknownGetEventsResponse } from 'web/src/modules/sportline/asserts/rest';
import { filterCorruptedEventsInResponse, logCorruptedEventsIds } from 'web/src/modules/sportline/utils/response';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
export default async function getEvents(input) {
    const apiClient = useSportlineApi();
    const response = await apiClient.getEvents(input);
    try {
        assertUnknownGetEventsResponse(response);
        return filterCorruptedEventsInResponse(response, logCorruptedEventsIds);
    } catch (error) {
        logger.error('apiClient getEvents response corrupted', error);
        // always return response for user
        return {
            totalCount: 0,
            events: []
        };
    }
}
