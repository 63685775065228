import { computed } from 'vue';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line max-len
export default function usePromotionDetailsDescription(props) {
    const { showDialog } = useDialogs();
    const { $translate } = useI18n();
    const parsedDescription = computed(()=>{
        const descriptionString = props.description || '';
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(descriptionString, 'text/html');
        const body = parsedDocument.querySelectorAll('body')[0];
        return body ? body.innerHTML : descriptionString;
    });
    function openSharedInfo(message) {
        showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                confirmMessage: message,
                buttons: [
                    {
                        kind: ButtonKind.SECONDARY_DARK,
                        label: $translate('WEB2_CLOSE').value
                    }
                ],
                dataTestId: 'open-shared-info'
            }
        });
    }
    return {
        parsedDescription,
        openSharedInfo
    };
}
