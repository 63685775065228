import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineOutrightHeadline',
    setup (__props) {
        // seems it could be replaced by importing styles
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportline-outright-headline'])
            }, [
                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_LEAGUE_OUTRIGHT_WINNERS')), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineOutrightHeadline'
                ]
            ]);
        };
    }
});
