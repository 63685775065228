import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import RestorePasswordByEmailInfo from 'web/src/modules/restore-password/components/RestorePasswordByEmailInfo/RestorePasswordByEmailInfo.vue';
import RestorePasswordCheckPhoneForm from 'web/src/modules/restore-password/components/RestorePasswordCheckPhoneForm/RestorePasswordCheckPhoneForm.vue';
import useRestoreContainer from './composables/useRestoreContainer';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestoreContainer',
    props: {
        isPin: {
            type: Boolean
        },
        isRestoreByEmailSent: {
            type: Boolean
        },
        isRestoreByPhoneSent: {
            type: Boolean
        },
        isInProfile: {
            type: Boolean
        }
    },
    emits: [
        "resend-restore-password-by-email",
        "resend-email-input",
        "resend-email-success",
        "check-pin-success"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { isCaptchaEnabled, tabsProperties, switchProperties, onTabClick } = useRestoreContainer();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _ctx.isRestoreByEmailSent ? (_openBlock(), _createBlock(RestorePasswordByEmailInfo, {
                    key: 0,
                    "is-pin": _ctx.isPin,
                    onFormSubmit: _cache[0] || (_cache[0] = ($event)=>emit('resend-restore-password-by-email', $event)),
                    onFormInput: _cache[1] || (_cache[1] = ($event)=>emit('resend-email-input', $event)),
                    onSuccess: _cache[2] || (_cache[2] = ($event)=>emit('resend-email-success', $event))
                }, null, 8, [
                    "is-pin"
                ])) : _ctx.isRestoreByPhoneSent ? (_openBlock(), _createBlock(RestorePasswordCheckPhoneForm, {
                    key: 1,
                    "is-in-profile": _ctx.isInProfile,
                    "is-pin": _ctx.isPin,
                    onClose: _cache[3] || (_cache[3] = ($event)=>emit('check-pin-success'))
                }, null, 8, [
                    "is-in-profile",
                    "is-pin"
                ])) : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['restore__page'])
                }, [
                    (_unref(tabsProperties).items?.length ?? 0) > 1 ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['restore-password__tabs-container'])
                    }, [
                        (_openBlock(), _createBlock(VTabs, _mergeProps({
                            key: 1
                        }, _unref(tabsProperties), {
                            "with-gap": "",
                            onTabClick: _unref(onTabClick)
                        }), null, 16, [
                            "onTabClick"
                        ]))
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['restore'])
                    }, [
                        _renderSlot(_ctx.$slots, "default")
                    ], 2)
                ], 2)),
                _unref(isCaptchaEnabled) ? (_openBlock(), _createBlock(VReCaptchaBadge, {
                    key: 3,
                    class: _normalizeClass(_ctx.$style['restore-captcha-badge']),
                    lang: _ctx.$lang
                }, null, 8, [
                    "class",
                    "lang"
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'RestoreContainer'
                ]
            ]);
        };
    }
});
