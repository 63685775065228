import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { isString, isFunction } from '@leon-hub/guards';
import { Deferred, Json } from '@leon-hub/utils';
import { logger as loggerInstance } from '@leon-hub/logging';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import RECAPTCHA_CHALLENGE_SELECTOR from 'web/src/modules/captcha/components/VReCaptcha/constants/reCaptchaChallengeSelector';
import { reCaptchaApiLoadedCallbackName } from 'web/src/modules/captcha/components/VReCaptcha/constants';
import isGreCaptcha from './utils/isGreCaptcha';
function assertExecution(instance, method) {
    if (!isFunction(instance[method])) throw new Error(`ReCAPTCHA instance doesn't have ${method} method`);
}
const getChallengeParentElement = ()=>document?.querySelector(RECAPTCHA_CHALLENGE_SELECTOR)?.parentElement?.parentElement ?? null;
let DefaultReCaptchaService = class DefaultReCaptchaService {
    static getInstance() {
        if (DefaultReCaptchaService.$instance) return DefaultReCaptchaService.$instance;
        DefaultReCaptchaService.$instance = new DefaultReCaptchaService();
        return DefaultReCaptchaService.$instance;
    }
    get scriptId() {
        return this.options?.scriptId ?? 'ReCaptchaService';
    }
    notifyApiLoaded() {
        this.deferredLoad.resolve(null);
    }
    notifyExecuting(response) {
        this.deferredExecute.resolve(response);
    }
    deferredExecutingReset() {
        this.deferredExecute = new Deferred();
    }
    loadRecaptchaScript(apiUrl) {
        let scriptElement = document.querySelector(`#${this.scriptId}`);
        if (scriptElement) this.logger.info('ReCAPTCHA script has already been loaded');
        else {
            scriptElement = document.createElement('script');
            scriptElement.id = this.scriptId;
            scriptElement.src = apiUrl;
            scriptElement.async = true;
            scriptElement.defer = true;
            scriptElement.addEventListener('error', (event)=>{
                scriptElement?.parentElement?.removeChild(scriptElement);
                this.deferredLoad.reject(new Error(`ScriptElementError, ErrorEvent=${Json.stringify(event, {
                    defaultValue: 'Unknown error event'
                })}`));
            });
            document.head.append(scriptElement);
        }
        return this.deferredLoad.promise;
    }
    async tryLoadRecaptchaScript(apiUrl) {
        let retryCount = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 3, attempt = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 1;
        return this.loadRecaptchaScript(apiUrl).catch((error)=>{
            this.deferredLoad = new Deferred();
            if (attempt >= retryCount) throw new CaptchaServiceError({
                log: `Unable to load captcha script at ${apiUrl} after ${retryCount} tries`,
                originalError: error
            });
            return this.tryLoadRecaptchaScript(apiUrl, retryCount, attempt + 1);
        });
    }
    wait() {
        return this.deferredLoad.promise;
    }
    render(element, options, callback) {
        this.wait().then(()=>{
            if (this.grecaptcha && isGreCaptcha(this.grecaptcha)) {
                assertExecution(this.grecaptcha, 'render');
                callback(this.grecaptcha?.render(element, options));
            }
        }).catch(this.executionErrorHandler.bind(this));
    }
    reset() {
        this.deferredLoad.resolved;
        this.wait().then(()=>{
            if (this.grecaptcha && isGreCaptcha(this.grecaptcha)) {
                assertExecution(this.grecaptcha, 'reset');
                this.grecaptcha?.reset();
            }
        }).catch(this.executionErrorHandler.bind(this));
    }
    // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
    get grecaptcha() {
        const { grecaptcha } = window;
        return isGreCaptcha(grecaptcha) ? grecaptcha : null;
    }
    async execute() {
        this.deferredLoad.resolved;
        return this.wait().then(()=>{
            if (this.grecaptcha && isGreCaptcha(this.grecaptcha)) {
                assertExecution(this.grecaptcha, 'execute');
                return this.grecaptcha?.execute();
            }
        }).catch(this.executionErrorHandler.bind(this));
    }
    checkRecaptchaLoad() {
        if (isGreCaptcha(this.grecaptcha)) {
            this.notifyApiLoaded();
            return true;
        }
        this.logger.warn('ReCAPTCHA has not been loaded');
        return false;
    }
    executionErrorHandler(error) {
        this.logger.error(error, 'ReCaptcha execution error occurred');
        return null;
    }
    async getResponse() {
        this.deferredLoad.resolved;
        return this.wait().then(()=>{
            if (this.grecaptcha && isGreCaptcha(this.grecaptcha)) {
                assertExecution(this.grecaptcha, 'getResponse');
                return this.grecaptcha?.getResponse();
            }
            return null;
        }).catch(this.executionErrorHandler.bind(this));
    }
    async invalidate() {
        const captchaResponse = await this.getResponse();
        if (isString(captchaResponse) && captchaResponse.length > 0) return Promise.resolve({
            captchaResponse
        });
        await this.execute();
        const result = await this.deferredExecute.promise;
        this.deferredExecutingReset();
        return result;
    }
    // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
    isChallengeVisible() {
        const element = getChallengeParentElement();
        if (element) return 'hidden' !== window.getComputedStyle(element).visibility;
        return false;
    }
    constructor(options, logger = loggerInstance){
        _define_property(this, "options", void 0);
        _define_property(this, "logger", void 0);
        _define_property(this, "deferredLoad", void 0);
        _define_property(this, "deferredExecute", void 0);
        this.options = options;
        this.logger = logger;
        this.deferredLoad = new Deferred();
        this.deferredExecute = new Deferred();
        "1";
        window[reCaptchaApiLoadedCallbackName] = this.notifyApiLoaded.bind(this);
    }
};
_define_property(DefaultReCaptchaService, "$instance", null);
/**
 * ReCaptcha Manifestation
 * https://github.com/google/recaptcha/issues/231
 * https://github.com/google/recaptcha/issues/286
 */ export { DefaultReCaptchaService as default };
