/**
 * @example:
 *
 * interface MyType {
 *   instanceMethod();
 * }
 *
 * interface MyTypeStatic {
 *   new(): MyType;
 *   staticMethod();
 * }
 *
 * @ImplementsStatic<MyTypeStatic>()
 * class Testable {
 *   instanceMethod() {}      // required by typescript
 *   static staticMethod() {} // required by typescript
 * }
 */ export default function ImplementsStatic() {
    return (constructor)=>constructor;
}
