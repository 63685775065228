import { defineStore } from 'pinia';
import { computed } from 'vue';
import { logger } from '@leon-hub/logging';
import RouteName from '@leon-hub/routing-config';
import useSegmentStoreComposable from 'web/src/modules/sportline/submodules/segment/store/composables/useSegmentStoreComposable';
import { useAllLiveCybersportPageUpdateInterval, useSelectedCybersportLivePageUpdateInterval, useComingLiveCybersportPageUpdateInterval } from 'web/src/modules/sportline/submodules/update-timeout';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { filterRegionsListEvents } from 'web/src/modules/sportline/utils';
import { checkMatchStreamIndicator, useMatchStreamAccess } from 'web/src/modules/sportline/submodules/streams';
import { useCybersportAllRegionsStoreComposable } from './composables';
export const useSportlineCybersportStore = defineStore('sportline-cybersport-store', ()=>{
    const matchStreamAccess = useMatchStreamAccess();
    const { isPageEnabled, isReady: isSegmentReady, filterIsReady, isMarketTypesSelectionEnabled, isFilterTransitionFadeEffectEnabled, filterTransitionDuration, selectedFilter, activeRegionElement, rawLiveEventsResponse, rawComingEventsResponse, rawFullSportsListResponse, fullSportsList, fullEventsResponse, sportSegmentsSettings, hasFavoritesOptimistic, favoriteEventsFullList, favoriteEventsListForDisplay, favoriteSelectedRegionFamily, comingResponseWithoutLive, liveDisciplinesMap, comingDisciplinesMap, compiledComingDisciplinesList, customerOptimisticFavoriteSportEventsIds, customerOptimisticFavoriteLeaguesIds, setSelectedFilter, initialRequests: initialSegmentRequests, syncBackgroundRequests, setBackgroundUpdateEnabled: setSegmentBackgroundUpdateEnabled, setFavoriteSelectedRegionFamily } = useSegmentStoreComposable({
        sportSegmentsSettingsId: RouteName.SPORTLINE_CYBERSPORT,
        backgroundFavoritesId: CustomerFavoritesIdsBackgroundUpdateKey.CYBERSPORT,
        filterFavoritesEventsListBySegmentId: SportSegmentId.CyberSport,
        allLiveTabUpdateInterval: useAllLiveCybersportPageUpdateInterval().timeout,
        selectedLiveTabUpdateInterval: useSelectedCybersportLivePageUpdateInterval().timeout,
        comingUpdateInterval: useComingLiveCybersportPageUpdateInterval().timeout
    });
    const sportElement = computed(()=>{
        const list = fullSportsList.value.// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        filter((element)=>element.sport.representation.family === sportSegmentsSettings.value.sportFamily).map((sportListElement)=>({
                key: sportListElement.sport.id,
                sport: sportListElement.sport,
                regions: (sportListElement.regions ?? []).map((regionListElement)=>({
                        key: regionListElement.region.id,
                        region: regionListElement.region,
                        marketTypes: [],
                        leagues: [],
                        counters: regionListElement.counters
                    })),
                marketTypes: [],
                marketsColumns: [],
                counters: sportListElement.counters
            }));
        if (list.length > 1) logger.warn('More then one Cybersport presented', list.map((element)=>element.sport.id));
        return list?.[0] || null;
    });
    const disciplinesList = computed(()=>{
        const cybersport = sportElement.value;
        return cybersport ? Object.freeze(cybersport.regions.filter((regionElement)=>regionElement.counters.live > 0 || regionElement.counters.prematch > 0)) : null;
    });
    const customerStreamsDisciplinesList = computed(()=>matchStreamAccess.value.length > 0 ? filterRegionsListEvents(disciplinesList.value ?? [], (sportEventElement)=>checkMatchStreamIndicator(matchStreamAccess.value, sportEventElement.sportEvent)) : []);
    const { isReady: isSportsTreeReady, sportsTreeElement, sportsTreeDateTimeFilter, isRegionOpen, setOpenRegionState, setSportsTreeDateTimeFilter, initialRequests: initialSportsTreeRequests, setBackgroundUpdateEnabled: setSportsTreeBackgroundUpdateEnabled, reloadSportsTreeForFilter } = useCybersportAllRegionsStoreComposable({
        sportElement
    });
    const isReady = computed(()=>isSportsTreeReady.value && isSegmentReady.value);
    const totalCount = computed(()=>sportsTreeElement.value?.counters.total ?? 0);
    async function initialRequests() {
        await Promise.allSettled([
            initialSegmentRequests(),
            initialSportsTreeRequests()
        ]);
    }
    function setBackgroundUpdateEnabled(value) {
        setSegmentBackgroundUpdateEnabled(value);
        setSportsTreeBackgroundUpdateEnabled(value);
    }
    return {
        isPageEnabled,
        isReady,
        filterIsReady,
        totalCount,
        isMarketTypesSelectionEnabled,
        isFilterTransitionFadeEffectEnabled,
        filterTransitionDuration,
        selectedFilter,
        activeRegionElement,
        rawLiveEventsResponse,
        rawComingEventsResponse,
        rawFullSportsListResponse,
        sportElement,
        disciplinesList,
        fullEventsResponse,
        hasFavoritesOptimistic,
        favoriteEventsFullList,
        favoriteEventsListForDisplay,
        favoriteSelectedRegionFamily,
        comingResponseWithoutLive,
        liveDisciplinesMap,
        comingDisciplinesMap,
        compiledComingDisciplinesList,
        customerStreamsDisciplinesList,
        customerOptimisticFavoriteSportEventsIds,
        customerOptimisticFavoriteLeaguesIds,
        sportsTreeElement,
        sportsTreeDateTimeFilter,
        isRegionOpen,
        setOpenRegionState,
        setSportsTreeDateTimeFilter,
        reloadSportsTreeForFilter,
        setSelectedFilter,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled,
        setFavoriteSelectedRegionFamily
    };
});
