import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import VAmountAnimator from 'web/src/components/AmountAnimator/VAmountAnimator/VAmountAnimator.vue';
import VSightObserver from 'web/src/components/SightObserver/VSightObserver/VSightObserver.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSightAmountAnimator',
    props: {
        amount: {},
        duration: {},
        timeRange: {},
        stepRange: {
            default: ()=>({
                    min: 0,
                    max: 0
                })
        },
        isInfinite: {
            type: Boolean
        },
        isHidden: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VSightObserver, null, {
                default: _withCtx((param)=>{
                    let { isVisible } = param;
                    return [
                        _createVNode(VAmountAnimator, {
                            amount: _ctx.amount,
                            "is-hidden": !isVisible,
                            duration: _ctx.duration,
                            "time-range": _ctx.timeRange,
                            "step-range": _ctx.stepRange,
                            "is-infinite": _ctx.isInfinite
                        }, {
                            default: _withCtx((param)=>{
                                let { animatedValue } = param;
                                return [
                                    _renderSlot(_ctx.$slots, "default", {
                                        animatedValue: animatedValue
                                    })
                                ];
                            }),
                            _: 2
                        }, 1032, [
                            "amount",
                            "is-hidden",
                            "duration",
                            "time-range",
                            "step-range",
                            "is-infinite"
                        ])
                    ];
                }),
                _: 3
            })), [
                [
                    _directive_auto_id,
                    'VSightAmountAnimator'
                ]
            ]);
        };
    }
});
