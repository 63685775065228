import arrayEquals from 'web/src/utils/array/arrayEquals';
function isApiPendingParamsEquals(apiPending1, apiPending2) {
    return apiPending1.filter?.to === apiPending2.filter?.to && apiPending1.filter?.from === apiPending2.filter?.from && apiPending1.sportId === apiPending2.sportId && apiPending1.leagueId === apiPending2.leagueId && apiPending1.regionId === apiPending2.regionId && apiPending1.sportFamily === apiPending2.sportFamily && apiPending1.login === apiPending2.login && apiPending1.vTag === apiPending2.vTag && arrayEquals(apiPending1.betlines, apiPending2.betlines);
}
export default function useSportlineApiPending() {
    const apiPendings = [];
    function getApiPendingByIndex(index) {
        if (!apiPendings[index]) throw new Error('Api pending is not exist');
        return apiPendings[index];
    }
    function getApiPendingIndexForFilter(filter) {
        return apiPendings.findIndex((apiPending)=>isApiPendingParamsEquals(apiPending, filter));
    }
    function addApiPending(apiPending) {
        apiPendings.push(apiPending);
    }
    function removeApiPendingForFilter(filter) {
        const apiPendingIndex = getApiPendingIndexForFilter(filter);
        if (-1 !== apiPendingIndex) {
            delete apiPendings[apiPendingIndex];
            apiPendings.splice(apiPendingIndex, 1);
        }
    }
    return {
        getApiPendingByIndex,
        getApiPendingIndexForFilter,
        removeApiPendingForFilter,
        addApiPending
    };
}
