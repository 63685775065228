import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let TimezoneComponent = class TimezoneComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        if (window.Intl && window.Intl.DateTimeFormat) return this.result([
            new window.Intl.DateTimeFormat().resolvedOptions().timeZone
        ]);
        return this.result([
            ComponentStatus.NotAvailable
        ]);
    }
    constructor(){
        super(ComponentKey.Timezone);
    }
};
export { TimezoneComponent as default };
