import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useBonusCodesOverview } from 'web/src/modules/affiliates/components/BonusCodesOverview/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusCodesOverview',
    props: {
        addButtonText: {
            default: ''
        },
        currentCount: {
            default: 0
        },
        maxCount: {
            default: 100
        },
        buttonIsLink: {
            type: Boolean
        },
        showHelp: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "button-click",
        "help"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { counterText, buttonDisabled, buttonKind, buttonLabel, emitButtonClick, emitHelp } = useBonusCodesOverview(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bonus-codes-overview'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bonus-codes-overview__row'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bonus-codes-overview__counter'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bonus-codes-overview__counter-label'])
                        }, _toDisplayString(_ctx.$t('WEB2_BONUS_CODES')), 3),
                        _createElementVNode("strong", {
                            class: _normalizeClass(_ctx.$style['bonus-codes-overview__counter-value'])
                        }, _toDisplayString(_unref(counterText)), 3)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bonus-codes-overview__add-button'])
                    }, [
                        _createVNode(VButton, {
                            label: _unref(buttonLabel),
                            kind: _unref(buttonKind),
                            disabled: _unref(buttonDisabled),
                            onClick: _unref(emitButtonClick)
                        }, null, 8, [
                            "label",
                            "kind",
                            "disabled",
                            "onClick"
                        ])
                    ], 2)
                ], 2),
                _ctx.showHelp ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['bonus-codes-overview__footer'])
                }, [
                    _createElementVNode("button", {
                        type: "button",
                        class: _normalizeClass(_ctx.$style['bonus-codes-overview__help']),
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(emitHelp) && _unref(emitHelp)(...args);
                        })
                    }, [
                        _createVNode(_unref(VIcon), {
                            name: _unref(IconName).QUESTION_OUTLINE,
                            size: _unref(IconSize).SIZE_16,
                            class: _normalizeClass(_ctx.$style['bonus-codes-overview__help-icon'])
                        }, null, 8, [
                            "name",
                            "size",
                            "class"
                        ]),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bonus-codes-overview__help-text'])
                        }, _toDisplayString(_ctx.$t('WEB2_BONUS_CODE_USAGE')), 3)
                    ], 2)
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusCodesOverview'
                ]
            ]);
        };
    }
});
