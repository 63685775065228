import { defineStore } from 'pinia';
import { computed, toRef } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import { useTopLeaguesSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { extractTopLeagues } from 'web/src/modules/sportline/utils';
export const useSportlineTopLeaguesStore = defineStore('sportline-top-leagues', ()=>{
    const { timeout: updateInterval } = useTopLeaguesSportlineUpdateTimeout();
    const sportsTreeStore = useSportsTreeStore();
    const isReady = toRef(sportsTreeStore, 'isLoaded');
    const rawFullSportsList = toRef(sportsTreeStore, 'fullSportsList');
    const fullSportsList = computed(()=>rawFullSportsList.value ?? []);
    const topLeagues = computed(()=>fullSportsList.value.flatMap(extractTopLeagues));
    async function reloadFullSportsList(silent) {
        await sportsTreeStore.reloadFullSportsList({
            silent,
            invalidateAfterInterval: updateInterval.value
        });
    }
    const lastUpdate = new BackgroundUpdateStopwatch({
        sportsList: async (param)=>{
            let { silent } = param;
            await reloadFullSportsList(silent);
            lastUpdate.update('sportsList');
        }
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval
    });
    useSyncState((silent)=>syncState({
            silent
        }), updateInterval);
    return {
        isReady,
        topLeagues,
        initialRequests,
        setBackgroundUpdateEnabled,
        async syncBackgroundRequests () {
            await syncBackgroundRequests({
                silent: true
            });
        }
    };
});
