import { isBoolean, isObject } from '@leon-hub/guards';
/* eslint-disable no-param-reassign */ function isAutoDirectionOptions(value) {
    return isObject(value) && isBoolean(value.autoDirection) && value.autoDirection;
}
function isEqualsValues(binding) {
    return binding.value?.value === binding.oldValue?.value;
}
function isMovedUpAuto(binding) {
    const to = binding.value?.value || 0;
    const from = binding.oldValue?.value || 0;
    return to > from;
}
function isMovedDownAuto(binding) {
    const to = binding.value?.value || 0;
    const from = binding.oldValue?.value || 0;
    return to < from;
}
function createDisplayValueChangeDirective(options) {
    const { moveUp, moveDown, timeout } = options;
    const isMovedUp = isAutoDirectionOptions(options) ? isMovedUpAuto : function isMovedUpTarget(binding) {
        return binding.value?.moveTo === options.upDirection;
    };
    const isMovedDown = isAutoDirectionOptions(options) ? isMovedDownAuto : function isMovedDownTarget(binding) {
        return binding.value?.moveTo === options.downDirection;
    };
    return function componentUpdated(element, binding, vnode) {
        let changed = false;
        if (isEqualsValues(binding)) return;
        if (isMovedUp(binding)) {
            element.classList.add(moveUp);
            changed = true;
        } else if (isMovedDown(binding)) {
            element.classList.add(moveDown);
            changed = true;
        }
        if (changed) {
            if (vnode.directionUpdateTimeout) {
                clearTimeout(vnode.directionUpdateTimeout);
                delete vnode.directionUpdateTimeout;
            }
            vnode.directionUpdateTimeout = +setTimeout(()=>{
                element.classList.remove(moveUp);
                element.classList.remove(moveDown);
            }, timeout ?? 3000);
        }
    };
}
export default createDisplayValueChangeDirective;
