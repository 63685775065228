import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href",
    "rel",
    "target"
];
import { useVDynamicContentLink } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VDynamicContentLink',
    props: {
        href: {
            default: ''
        },
        target: {},
        rel: {},
        isCmsContent: {
            type: Boolean
        },
        useEmitter: {
            type: Boolean
        }
    },
    emits: [
        "click-link"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { handleClick, computedRel } = useVDynamicContentLink(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: _ctx.href,
                rel: _unref(computedRel),
                target: _ctx.target,
                onClick: _cache[0] || (_cache[0] = ($event)=>_unref(handleClick)($event))
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 8, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VDynamicContentLink'
                ]
            ]);
        };
    }
});
