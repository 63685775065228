import { CircleColor, CircleFilterShadows } from '../../enums';
export default function getFilterShadowColorMatrix(props) {
    switch(props.circleColor){
        case CircleColor.YELLOW:
            return CircleFilterShadows.YELLOW;
        case CircleColor.PURPLE:
            return CircleFilterShadows.PURPLE;
        default:
            return CircleFilterShadows.DEFAULT;
    }
}
