/* eslint-disable sonarjs/prefer-single-boolean-return */ import { IgnoredErrorType } from '@leon-hub/api-sdk';
import { isString } from '@leon-hub/guards';
import { isSentryLoggableError } from '@leon-hub/errors-whitelist';
export default function isLoggableError(error, ignoredErrors) {
    return isSentryLoggableError(error) && !isIgnoredErrorMessage(error, ignoredErrors);
}
function isIgnoredErrorMessage(error, ignoredErrors) {
    if (!ignoredErrors) return false;
    const errorCombined = getErrorMessage(error);
    if (!errorCombined) return false;
    for (const ignoredError of ignoredErrors){
        const ignoredErrorMessage = ignoredError.error;
        if (ignoredError.type === IgnoredErrorType.TEXT && errorCombined.includes(ignoredErrorMessage)) return true;
        if (ignoredError.type === IgnoredErrorType.REGEXP) {
            const regexp = new RegExp(ignoredErrorMessage);
            if (regexp.test(errorCombined)) return true;
        }
    }
    return false;
}
function getErrorMessage(error) {
    let errorMessage = '';
    let errorStack = '';
    if (error instanceof Error) {
        if (error.message) errorMessage = error.message;
        if (error.stack) errorStack = error.stack;
    } else if (isString(error)) errorMessage = String(error);
    if (!errorMessage && !errorStack) return '';
    return `${errorMessage} ${errorStack}`;
}
