import { computed } from 'vue';
import { useSportlineElementMarketColumns, useSportlineElementMarketTypes } from 'web/src/modules/sportline/composables/markets';
import { useProvideMarketColumn } from 'web/src/modules/sportline/composables/list';
import useSportlineCacheStorage from 'web/src/modules/sportline/store/useSportlineCacheStorage';
import { getSportElementEventsCounters } from 'web/src/modules/sportline/utils';
// @TODO remove passing full sportElement and get  data from cache
export function useSportlineBlockHeadline(props) {
    const { isMarketTypesSelectionEnabled, sportId, sportElement, basisKey } = props;
    const { getSportCache } = useSportlineCacheStorage();
    const sportElementCache = computed(()=>getSportCache(sportId.value).value);
    const headlineKey = computed(()=>sportElementCache.value ? `${sportElementCache.value.sport.id}_${sportElementCache.value.updated}` : sportId.value);
    const eventsCount = computed(()=>{
        const counters = getSportElementEventsCounters(sportElement.value);
        return counters.total;
    });
    const { marketsColumns } = useSportlineElementMarketColumns({
        sportlineElement: sportElement
    });
    const { marketsTypes } = useSportlineElementMarketTypes({
        sportlineElement: sportElement
    });
    useProvideMarketColumn({
        basisKey,
        isMarketTypesSelectionEnabled,
        marketsColumns,
        marketsTypes
    });
    return {
        headlineKey,
        eventsCount
    };
}
