import { PaymentsCardTypes } from 'web/src/modules/payments/enums';
export default function getCardNumberLength(cardType) {
    switch(cardType){
        case PaymentsCardTypes.AMEX:
            return {
                minLength: 15,
                maxLength: 15
            };
        case PaymentsCardTypes.DINERS:
            return {
                minLength: 14,
                maxLength: 19
            };
        case PaymentsCardTypes.DISCOVER:
        case PaymentsCardTypes.JCB:
        case PaymentsCardTypes.MIR:
            return {
                minLength: 16,
                maxLength: 19
            };
        case PaymentsCardTypes.VISA_ELECTRON:
        case PaymentsCardTypes.MASTER_CARD:
            return {
                minLength: 16,
                maxLength: 16
            };
        case PaymentsCardTypes.VISA:
            return {
                minLength: 13,
                maxLength: 16
            };
        // case PaymentsCardTypes.MAESTRO: // same as default
        default:
            return {
                minLength: 12,
                maxLength: 19
            };
    }
}
