import { AppError, AppErrorCode } from '@leon-hub/app-errors';
import { useI18n } from 'web/src/modules/i18n/composables';
let CaptchaServiceError = class CaptchaServiceError extends AppError {
    constructor(options = {}){
        super({
            ...options,
            code: AppErrorCode.APP_UNEXPECTED_ERROR,
            silent: false,
            message: useI18n().$translate('WEB2_CAPTCHA_SERVICE_ERROR').value
        });
    }
};
export { CaptchaServiceError as default };
