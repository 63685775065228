import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useHead } from '@unhead/vue';
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
import { getJumbotronProps } from 'web/src/components/Jumbotron';
import ErrorContent from 'web/src/modules/errors/components/ErrorContent/ErrorContent.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import ErrorHeaderBarSimpleRouteComponent from 'web/src/modules/errors/views/ErrorHeaderBarSimpleRouteComponent/ErrorHeaderBarSimpleRouteComponent.vue';
import { useTechnicalErrorRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TechnicalErrorRoutePage',
    setup (__props) {
        const { refresh, onSupportClick } = useTechnicalErrorRoutePage();
        useHead({
            meta: [
                {
                    content: '503',
                    name: 'prerender-status-code'
                }
            ]
        });
        const { image, icon, iconSize } = getJumbotronProps({
            iconSize: IconSize.SIZE_80,
            alertIcon: AlertIconName.TechnicalWork,
            icon: IconName.GEARS
        });
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(ErrorHeaderBarSimpleRouteComponent),
                _createVNode(ErrorContent, {
                    "error-type": "technical",
                    "error-icon": {
                        icon: _unref(icon),
                        image: _unref(image),
                        iconSize: _unref(iconSize)
                    },
                    "alert-icon-size": "large",
                    "error-title": _ctx.$t('WEB2_ERROR_503_TITLE_V2'),
                    "error-message": `
      ${_ctx.$t('WEB2_ERROR_503_TEXT1_V2')}
      ${_ctx.$t('WEB2_ERROR_503_TEXT2_V2')}
      ${_ctx.$t('WEB2_ERROR_503_TEXT3_V2')}
    `.trim(),
                    "has-support": true,
                    class: _normalizeClass(_ctx.$style['error-page--technical']),
                    "alert-icon": void 0
                }, {
                    footer: _withCtx(()=>[
                            _createVNode(VButton, {
                                kind: _unref(ButtonKind).PRIMARY,
                                "full-width": "",
                                label: _ctx.$t('WEB2_ERROR_503_BTN_UPDATE_V2'),
                                onClick: _unref(refresh)
                            }, null, 8, [
                                "kind",
                                "label",
                                "onClick"
                            ])
                        ]),
                    "contacts-button": _withCtx(()=>[
                            _createVNode(VButton, {
                                tag: _unref(Tag).A,
                                kind: _unref(ButtonKind).BASE,
                                rounded: "",
                                "is-uppercase": false,
                                label: _ctx.$t('WEB2_ERROR_BTN_SUPPORT'),
                                onClick: _unref(onSupportClick)
                            }, null, 8, [
                                "tag",
                                "kind",
                                "label",
                                "onClick"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "error-icon",
                    "error-title",
                    "error-message",
                    "has-support",
                    "class",
                    "alert-icon"
                ])
            ], 64));
    }
});
