import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import hasSlot from 'web/src/utils/vue/hasSlot';
export default function useModalWindow(props, slots) {
    const isShowCloseButton = computed(()=>!!props.isFullHeight && !!props.isProfileLayout);
    const titleText = computed(()=>{
        const title = props.topBarText || (!props.isTopBarHidden && (props.isAlert || props.isFullHeight) ? '&nbsp;' : void 0);
        return title;
    });
    const prefixIconName = computed(()=>{
        if (props.isCloseAsBack) return IconName.ARROW_LEFT;
    });
    const isCloseIconAllowed = computed(()=>(!!props.isAlert || !!props.isFullHeight) && !!props.isCloseAllowed && !hasSlot(slots, 'default'));
    const suffixIconName = computed(()=>{
        if (props.isCloseAsBack) return;
        if (isCloseIconAllowed.value && !prefixIconName.value || isShowCloseButton.value || props.isCloseAllowed) return IconName.CROSS;
    });
    const jumbotronProperties = computed(()=>({
            image: props.image,
            icon: props.icon,
            iconSize: props.iconSize,
            heading: props.header,
            text: props.content,
            isModal: true,
            iconKind: props.iconKind,
            alertIconSize: props.alertIconSize
        }));
    const headerProperties = computed(()=>({
            prefixIconName: prefixIconName.value,
            suffixIconName: prefixIconName.value === suffixIconName.value ? void 0 : suffixIconName.value,
            title: titleText.value,
            type: props.isAlert || props.isFullHeight ? 'default' : 'tertiary'
        }));
    const isHeaderHidden = computed(()=>!prefixIconName.value && !suffixIconName.value && !titleText.value);
    return {
        titleText,
        isShowCloseButton,
        headerProperties,
        isHeaderHidden,
        jumbotronProperties
    };
}
