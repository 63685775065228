import { logger } from '@leon-hub/logging';
import { assertUnknownGetHeadlineChangesEventsResponse } from 'web/src/modules/sportline/asserts/rest';
import { filterCorruptedEventsInChangesResponse, logCorruptedEventsIds } from 'web/src/modules/sportline/utils/response';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
export default async function getHeadlineMatchesChanges(input) {
    const apiClient = useSportlineApi();
    const response = await apiClient.headlineMatchesChanges(input);
    try {
        assertUnknownGetHeadlineChangesEventsResponse(response);
        // it is really GetHeadlineMatchesChangesResponse after filtration
        return {
            ...response,
            events: response.events ? filterCorruptedEventsInChangesResponse(response.events, logCorruptedEventsIds) : response.events,
            live: response.live ? filterCorruptedEventsInChangesResponse(response.live, logCorruptedEventsIds) : response.live,
            prematch: response.prematch ? filterCorruptedEventsInChangesResponse(response.prematch, logCorruptedEventsIds) : response.prematch
        };
    } catch (error) {
        logger.error('apiClient getHeadlineMatchesChanges response corrupted', error);
        // always return response for user
        return {
            total: 0,
            events: {
                totalCount: 0,
                data: []
            }
        };
    }
}
