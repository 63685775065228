import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { useWindowResize } from '@leon-hub/browser-composables';
import useRootStore from 'web/src/modules/core/store/useRootStore';
import { useLiveChatStore } from 'web/src/modules/live-chat/store';
import { getFixedChatPosition } from './utils';
const storageKeyPositionRight = 'CHAT_POS_R';
const storageKeyPositionBottom = 'CHAT_POS_B';
const useChatDragDropDesktop = ()=>{
    const { setIsContentSelectionDisabled } = useRootStore();
    const chatRef = ref();
    const liveChatStore = useLiveChatStore();
    const isChatOpened = toRef(liveChatStore, 'isOpened');
    let posX = 0;
    let posY = 0;
    let bottomInitial = 0;
    let rightInitial = 0;
    const chatBottom = ref(32);
    const chatRight = ref(32);
    const localStorageManager = useLocalStorageManager();
    const chatPositionStyles = computed(()=>({
            right: `${chatRight.value}px`,
            bottom: `${chatBottom.value}px`
        }));
    onMounted(()=>{
        const chatRightStorage = localStorageManager.getItem(storageKeyPositionRight);
        const chatBottomStorage = localStorageManager.getItem(storageKeyPositionBottom);
        if (chatRightStorage) chatRight.value = Number(chatRightStorage);
        if (chatBottomStorage) chatBottom.value = Number(chatBottomStorage);
    });
    useWindowResize(fixChatPosition);
    function saveChatPositions(right, bottom) {
        chatRight.value = right;
        chatBottom.value = bottom;
        localStorageManager.setItem('CHAT_POS_R', String(right));
        localStorageManager.setItem('CHAT_POS_B', String(bottom));
    }
    function fixChatPosition() {
        if (chatRef.value && isChatOpened.value) {
            const { bottom, right } = getFixedChatPosition(chatRef.value, chatBottom.value, chatRight.value);
            chatBottom.value = bottom;
            chatRight.value = right;
            saveChatPositions(chatRight.value, chatBottom.value);
        }
    }
    function onDragStart(event) {
        posX = event.clientX;
        posY = event.clientY;
        bottomInitial = chatBottom.value;
        rightInitial = chatRight.value;
        window.addEventListener('mouseup', onDragEnd);
        window.addEventListener('mousemove', onMouseMove);
        setIsContentSelectionDisabled(true);
    }
    function onDragEnd() {
        window.removeEventListener('mouseup', onDragEnd);
        window.removeEventListener('mousemove', onMouseMove);
        fixChatPosition();
        setIsContentSelectionDisabled(false);
    }
    function onMouseMove(event) {
        saveChatPositions(rightInitial - event.clientX + posX, bottomInitial - event.clientY + posY);
    }
    watch(isChatOpened, async (newValue)=>{
        if (newValue) {
            await nextTick();
            fixChatPosition();
        }
    });
    return {
        onDragStart,
        chatRef,
        chatPositionStyles
    };
};
export default useChatDragDropDesktop;
