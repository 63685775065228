export const isCanvasSupported = ()=>{
    const element = document.createElement('canvas');
    return !!(element.getContext && element.getContext('2d'));
};
export const getWebglCanvas = ()=>{
    const canvas = document.createElement('canvas');
    try {
        return canvas.getContext('webgl');
    } catch  {
        return null;
    }
};
export const loseWebglContext = (context)=>{
    if (context) {
        const loseContextExtension = context.getExtension('WEBGL_lose_context');
        if (loseContextExtension) loseContextExtension.loseContext();
    }
};
