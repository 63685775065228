import { CustomerFieldType } from '@leon-hub/api-sdk/src/sdk/sdk';
const countryDefaultNotAvailable = '-';
function getFormSelectorCountryCode(geoIpCountryCode, uiSchema) {
    if (!uiSchema?.fields) return geoIpCountryCode;
    let geoIpCountry = geoIpCountryCode;
    const countrySelectField = uiSchema.fields.find(// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    (i)=>i.id === CustomerFieldType.COUNTRY_SELECT);
    if (countrySelectField && !countrySelectField.disabled) {
        const isCountryInList = countrySelectField.labels.some((item)=>item.key === geoIpCountry);
        if (!isCountryInList) geoIpCountry = countryDefaultNotAvailable;
    } else if (countrySelectField && countrySelectField.disabled) geoIpCountry = countrySelectField.labels[0].key || countryDefaultNotAvailable;
    return geoIpCountry;
}
export default getFormSelectorCountryCode;
