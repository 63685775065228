import { logger } from '@leon-hub/logging';
import { SportFamily, SportlineType } from 'web/src/modules/sportline/enums';
export function getActualScoreDetails(sportEvent, sport, isAllScores) {
    if (sportEvent.type !== SportlineType.Live) return null;
    const hostScore = [
        {
            value: sportEvent.matchProgress.totalScore.host,
            highlighted: true
        }
    ];
    const guestScore = [
        {
            value: sportEvent.matchProgress.totalScore.guest,
            highlighted: true
        }
    ];
    const { host, guest } = sportEvent.matchProgress.totalScoreDetails;
    const currentSetHostScore = host[host.length - 1] ? [
        {
            value: host[host.length - 1]
        }
    ] : [];
    const currentSetGuestScore = guest[guest.length - 1] ? [
        {
            value: guest[guest.length - 1]
        }
    ] : [];
    const allHostScores = host.map((score)=>({
            value: score
        }));
    const allGuestScores = guest.map((score)=>({
            value: score
        }));
    const { penaltyScore, currentSetScore } = sportEvent.matchProgress;
    const currentGameScoreHostScore = currentSetScore ? [
        {
            value: `${currentSetScore.host}`
        }
    ] : [];
    const currentGameScoreGuestScore = currentSetScore ? [
        {
            value: `${currentSetScore.guest}`
        }
    ] : [];
    const currentPenaltyScoreHostScore = penaltyScore ? [
        {
            value: `${penaltyScore.host}`
        }
    ] : [];
    const currentPenaltyScoreGuestScore = penaltyScore ? [
        {
            value: `${penaltyScore.guest}`
        }
    ] : [];
    const sportFamily = sport?.representation.family;
    if (!sport) logger.warn(`Live Score board for ${sportEvent.id} unknown sport`);
    const hostScores = isAllScores ? allHostScores : currentSetHostScore;
    const guestScores = isAllScores ? allGuestScores : currentSetGuestScore;
    switch(sportFamily){
        case SportFamily.Tennis:
            return {
                host: [
                    ...hostScore,
                    ...hostScores,
                    ...currentGameScoreHostScore
                ],
                guest: [
                    ...guestScore,
                    ...guestScores,
                    ...currentGameScoreGuestScore
                ]
            };
        case SportFamily.TableTennis:
        case SportFamily.Volleyball:
        case SportFamily.Badminton:
        case SportFamily.BeachVolleyball:
            return {
                host: [
                    ...hostScore,
                    ...hostScores
                ],
                guest: [
                    ...guestScore,
                    ...guestScores
                ]
            };
        case SportFamily.Soccer:
        case SportFamily.IceHockey:
            return {
                host: [
                    ...hostScore,
                    ...currentPenaltyScoreHostScore
                ],
                guest: [
                    ...guestScore,
                    ...currentPenaltyScoreGuestScore
                ]
            };
        default:
            return {
                host: [
                    ...hostScore
                ],
                guest: [
                    ...guestScore
                ]
            };
    }
}
