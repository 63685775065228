import RouteName from '@leon-hub/routing-config';
/**
     * @deprecated use RouteName instead
     */ const RouterNamePlugin = {
    install (app) {
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$routeName = RouteName;
        // eslint-disable-next-line max-len,no-param-reassign
        app.config.globalProperties.$to = (name, options)=>({
                ...options || {},
                name
            });
    }
};
export default RouterNamePlugin;
