import { _ as _define_property } from "@swc/helpers/_/_define_property";
let SocketEmitter = class SocketEmitter {
    async emit(type, payload) {
        const data = {
            type,
            payload
        };
        await this.socketWrapper.send(data);
    }
    constructor(socketWrapper){
        _define_property(this, "socketWrapper", void 0);
        this.socketWrapper = socketWrapper;
    }
};
export { SocketEmitter as default };
