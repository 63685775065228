import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "credit-card-selector"
};
import { onMounted } from 'vue';
import PaymentsCreditCardRadioSelector from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardRadioSelector/PaymentsCreditCardRadioSelector.vue';
import usePaymentsCreditCardSelector from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelector/composables/usePaymentsCreditCardSelector';
import PaymentsCreditCardSelectSelector from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelectSelector/PaymentsCreditCardSelectSelector.vue';
// todo: InputEventType.CHANGE in some brilliant new vue version (3.3 enums is unsupported with defineEmits
export default /*@__PURE__*/ _defineComponent({
    __name: 'ThePaymentsCreditCardSelector',
    props: {
        creditCards: {},
        allowNewCard: {
            type: Boolean
        },
        selectedCreditCardToken: {
            type: [
                String,
                Boolean,
                Number,
                Array,
                Object
            ]
        }
    },
    emits: [
        "input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onCreditCardSelectedMounted, showCreditCardSelector, setSelectedCreditCardToken, handleDeleteClick } = usePaymentsCreditCardSelector(props, emit);
        onMounted(onCreditCardSelectedMounted);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(showCreditCardSelector) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createBlock(PaymentsCreditCardRadioSelector, {
                    key: 1,
                    "credit-cards": _ctx.creditCards,
                    "has-new-card": _ctx.allowNewCard,
                    "is-deletable": false,
                    "selected-card-token": String(_ctx.selectedCreditCardToken),
                    onChange: _unref(setSelectedCreditCardToken),
                    onDelete: _unref(handleDeleteClick)
                }, null, 8, [
                    "credit-cards",
                    "has-new-card",
                    "is-deletable",
                    "selected-card-token",
                    "onChange",
                    "onDelete"
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'ThePaymentsCreditCardSelector'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
