import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
    class: "access-options-wrapper"
};
import { useRouter } from 'vue-router';
import AccessOptionsList from '../AccessOptionsList/AccessOptionsList.vue';
import AccessOptionsListItem from '../AccessOptionsListItem/AccessOptionsListItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccessOptionsComposition',
    props: {
        items: {},
        personalLink: {}
    },
    setup (__props) {
        const router = useRouter();
        function onClick(item) {
            router.push({
                name: item.routeName
            });
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(AccessOptionsList, null, {
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item)=>_withDirectives((_openBlock(), _createBlock(AccessOptionsListItem, {
                                    key: item.id,
                                    title: item.caption,
                                    description: item.label,
                                    icon: item.icon?.name,
                                    onClick: ($event)=>onClick(item)
                                }, null, 8, [
                                    "title",
                                    "description",
                                    "icon",
                                    "onClick"
                                ])), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: item.id
                                        }
                                    ]
                                ])), 128))
                        ]),
                    _: 1
                })
            ])), [
                [
                    _directive_auto_id,
                    'AccessOptionsComposition'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'access-options-wrapper'
                    }
                ]
            ]);
        };
    }
});
