import { ref, computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCountdownDateTimeLabels } from 'web/src/modules/sportline/composables/countdown';
import useSafeTimeBus from 'web/src/modules/core/composables/time-bus/useSafeTimeBus';
import { CountdownLabelType } from 'web/src/modules/sportline/composables/countdown/enums';
import DateTime from 'web/src/utils/DateTime';
export function useSportlinePrematchMetaInfo(props) {
    const { kickoff } = props;
    const { $translate } = useI18n();
    const { now } = useSafeTimeBus(1000);
    const timestamp = computed(()=>kickoff.value ? DateTime.withTimeStamp(kickoff.value) : void 0);
    const date = computed(()=>timestamp.value?.toDate() ?? '');
    const time = computed(()=>timestamp.value?.toTime() ?? '');
    const { startsInLabel } = useCountdownDateTimeLabels({
        fromTimestamp: now,
        toTimestamp: kickoff,
        displaySecondsOnMaxMinutes: ref(1),
        daysLabelType: ref(CountdownLabelType.Full),
        hoursLabelType: ref(CountdownLabelType.Full),
        minutesLabelType: ref(CountdownLabelType.Short),
        secondsLabelType: ref(CountdownLabelType.Short)
    });
    const emptyTimeRef = ref({
        time: ''
    });
    const willComeLabel = $translate('WEB2_EVENT_WILL_COME_IN', emptyTimeRef);
    return {
        willComeLabel,
        startsInLabel,
        date,
        time
    };
}
