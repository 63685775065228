import { isFileImage } from 'web/src/components/Input/utils';
import readFileContent from './readFileContent';
export default async function getFileRecord(param) {
    let { file, fileTypes, errorCallback } = param;
    const record = {
        record: file,
        name: file.name,
        base64String: ''
    };
    if (isFileImage(fileTypes, file)) try {
        const base64string = await readFileContent(file);
        if (base64string) record.base64String = base64string;
    } catch (error) {
        errorCallback();
        throw error;
    }
    return record;
}
