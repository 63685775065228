import { isEnumOfType } from '@leon-hub/guards';
import { CountryCode } from 'web/src/components/CountryFlag/VCountryFlag/enums';
/**
 * It just forces set the representation icon's type to CountryCode
 */ export function getRegionCountryCode(region) {
    const icon = region?.representation.icon;
    if (!icon) return null;
    isEnumOfType(CountryCode)(icon);
    return icon;
}
