import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { JumbotronFooterButton, JumbotronIconKind, VJumbotron } from 'web/src/components/Jumbotron';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { PaymentsDepositStatus } from 'web/src/modules/payments/enums';
import { usePaymentsDepositsOkFailController } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsDepositsOkFailController',
    props: {
        closeWindow: {
            type: Boolean
        },
        iconName: {},
        status: {
            default: PaymentsDepositStatus.SUCCESS
        },
        margin: {
            type: Boolean
        },
        title: {},
        message: {}
    },
    setup (__props) {
        const props = __props;
        const { beforeMount, beforeUnmount, onClick } = usePaymentsDepositsOkFailController(props);
        onBeforeMount(beforeMount);
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['payments-depositok']]: true,
                    [_ctx.$style['payments-depositok--margin-top']]: _ctx.margin,
                    [_ctx.$style['payments-depositok--separate-page']]: _ctx.closeWindow
                })
            }, [
                _createVNode(_unref(VJumbotron), {
                    class: _normalizeClass(_ctx.$style['payments-depositok--content']),
                    "icon-size": _ctx.status === _unref(PaymentsDepositStatus).FAIL ? _unref(IconSize).SIZE_60 : _unref(IconSize).SIZE_80,
                    icon: _ctx.iconName ?? '',
                    heading: _ctx.title,
                    text: _ctx.message,
                    "icon-kind": _ctx.status === _unref(PaymentsDepositStatus).FAIL ? _unref(JumbotronIconKind).DEFAULT : _unref(JumbotronIconKind).SUCCESS
                }, {
                    footer: _withCtx(()=>[
                            _createVNode(_unref(JumbotronFooterButton), {
                                label: _ctx.$t('JS_BETSLIP_CONFIRMATION_CLOSE'),
                                kind: _unref(ButtonKind).BASE,
                                height: _unref(ButtonHeight).LARGE,
                                "full-width": "",
                                onClick: _unref(onClick)
                            }, null, 8, [
                                "label",
                                "kind",
                                "height",
                                "onClick"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "icon-size",
                    "icon",
                    "heading",
                    "text",
                    "icon-kind"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsDepositsOkFailController'
                ]
            ]);
        };
    }
});
