import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useEgsApi } from 'web/src/modules/egs/composables';
const useEgsGamesStore = defineStore('egs-games', ()=>{
    const api = useEgsApi();
    const games = {};
    function createKey(groupUrl, gameUrl) {
        return `${groupUrl}-${gameUrl}`;
    }
    function updateGames(items) {
        for (const game of items){
            const key = createKey(game.group.url, game.url);
            if (games[key]) games[key].value = game;
            else games[key] = ref(game);
        }
    }
    function getGameByUrl(groupUrl, gameUrl) {
        const key = createKey(groupUrl, gameUrl);
        if (!games[key]) games[key] = ref(void 0);
        return games[key];
    }
    function getGameById(id) {
        return Object.values(games).find((game)=>game.value?.id === id)?.value;
    }
    function setFavorite(ids, value) {
        for (const id of ids){
            const game = getGameById(id);
            if (game) game.isFavorite = value;
        }
    }
    async function loadGameById(id) {
        const game = await api.getGameById(id);
        if (game) updateGames([
            game
        ]);
        return game;
    }
    return {
        updateGames,
        getGameByUrl,
        setFavorite,
        getGameById,
        loadGameById
    };
});
export default useEgsGamesStore;
