import { computed, ref, toRef, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { useBannersStore } from 'web/src/modules/banners/store';
import { BannerCategory, BannerDisableClickArea, BannerLocation } from 'web/src/modules/banners/enums';
import { getFilteredBannerGroups } from 'web/src/modules/banners/utils';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import StaticBanner from 'web/src/modules/banners/components/StaticBanner/StaticBanner.vue';
import SwiperBanner from 'web/src/modules/banners/components/SwiperBanner/SwiperBanner.vue';
import FadeCarouselBanner from 'web/src/modules/banners/components/FadeCarouselBanner/FadeCarouselBanner.vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
// eslint-disable-next-line max-len
export default function useMainBannerSectionRouteComponent(props) {
    const route = useRoute();
    const analytics = useAnalytics();
    const { isLoggedIn } = useIsLoggedIn();
    const bannerGroupsStore = toRef(useBannersStore(), 'bannerGroups');
    const currentGroup = ref(null);
    let timeout = 0;
    let active = false;
    const banners = computed(()=>currentGroup.value?.banners ?? []);
    const showBannerSection = computed(()=>banners.value.length > 0);
    const disableClickAreas = computed(()=>props.isStatic ? [] : [
            BannerDisableClickArea.LEFT,
            BannerDisableClickArea.TOP
        ]);
    const bannerGroupComponent = computed(()=>{
        if (props.isStatic) return StaticBanner;
        if (props.isStoryBanner) return SwiperBanner;
        return FadeCarouselBanner;
    });
    function onActivate() {
        setupCurrentBanner(route);
        active = true;
    }
    function onDeactivate() {
        active = false;
    }
    function onRouteChanged(newRoute) {
        if (!active) return;
        timeout = 0;
        // TODO: Refactor - receive route path as prop after transition end
        if ('overlay-in' === newRoute.meta.transition) timeout = 310;
        if (timeout) Timer.clearTimeout(timeout);
        if (timeout > 0) timeout = Timer.setTimeout(()=>{
            setupCurrentBanner(newRoute);
        }, timeout);
        else setupCurrentBanner(newRoute);
    }
    function setupCurrentBanner(value) {
        const { location, customLocationId } = props;
        const bannerGroups = getFilteredBannerGroups(bannerGroupsStore.value, {
            route: value,
            location: location ?? BannerLocation.TOP,
            customLocationId
        });
        if (0 === bannerGroups.length) {
            currentGroup.value = null;
            return;
        }
        const totalWeight = bannerGroups.reduce((accumulator, currentValue)=>accumulator + currentValue.weight, 0);
        const randNumber = Math.random() * totalWeight;
        let weightSum = 0;
        currentGroup.value = bannerGroups.find((campaign)=>{
            weightSum += campaign.weight;
            return randNumber <= weightSum;
        }) ?? null;
    }
    function onRouterLinkClick(param) {
        let { path } = param;
        analytics.clickMap({
            promo: 'banners'
        });
        if (props.bannerCategory === BannerCategory.SUPER_LIVE) analytics.push(AnalyticsEvent.Z_VIRTUAL_SPORT_BANNER, {
            virtualSportBanner: 'click'
        });
        analytics.push(AnalyticsEvent.Z_OPEN_PROMO_PAGE, {
            promoPage: {
                banners: path
            }
        });
    }
    watch(()=>route, onRouteChanged, {
        deep: true
    });
    watch(bannerGroupsStore, ()=>{
        setupCurrentBanner(route);
    }, {
        deep: true
    });
    onComponentActivated(onActivate);
    onComponentDeactivated(onDeactivate);
    return {
        showBannerSection,
        bannerGroupComponent,
        currentGroup,
        isLoggedIn,
        disableClickAreas,
        onRouterLinkClick
    };
}
