import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { DisplayType, FrontNotificationType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { doDeleteNotification, doMarkAsClicked, doMarkAsDelivered, doMarkNotification, getNotifications as getNotif, RequestOptionsPriority } from '@leon-hub/api-sdk';
import { RefProgramNotificationType } from '@leon-hub/websocket/src/sdk-ws';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import promisesInBatch from 'web/src/utils/promisesInBatch';
import { useWebSockets } from 'web/src/modules/core/composables';
import createCustomerMessageFromFrontNotification from 'web/src/modules/front-notifications/helpers/createCustomerMessageFromFrontNotification';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
const onSiteNotificationTypes = [
    FrontNotificationType.ALCR_NOTIFICATION,
    FrontNotificationType.ON_SITE
];
const useFrontNotificationsStore = defineStore('front-notifications', ()=>{
    const { isLoggedIn } = useIsLoggedIn();
    const api = useGraphqlClient();
    const { subscribeAuthorized } = useWebSockets();
    const { isSocketStickyBonusStatus } = useWebSocketsConfig();
    const siteConfig = useSiteConfigStore();
    const isVideoVerificationEnabled = toRef(siteConfig, 'isVideoVerificationFeatureEnabled');
    const frontNotifications = ref();
    const pollingInterval = ref(30000);
    const cbcFrontNotification = computed(()=>frontNotifications.value?.find((item)=>item.type === FrontNotificationType.CBC_BONUS_ACTIVATED) ?? null);
    const refFrontNotification = computed(()=>{
        const ar = [];
        frontNotifications.value?.forEach((item)=>{
            if (item.type === FrontNotificationType.REFPRG && isRefFrontNotification(item)) ar.push(item);
        });
        return ar ?? null;
    });
    const playTimeEndNotification = computed(()=>frontNotifications.value?.find((item)=>item.type === FrontNotificationType.PLAY_TIME_ENDED));
    const onSiteNotifications = computed(()=>(frontNotifications.value ?? []).filter((item)=>onSiteNotificationTypes.includes(item.type)).filter((n)=>n.displayType !== DisplayType.SNACKBAR));
    const videoVerificationNotifications = computed(()=>frontNotifications.value?.filter((item)=>item.type === FrontNotificationType.VIDEO_VERIFICATION) || []);
    const isVideoVerificationAlert = computed(()=>!!videoVerificationNotifications.value.length && isVideoVerificationEnabled.value);
    const preparedCustomerMessages = computed(()=>onSiteNotifications.value ? onSiteNotifications.value.map((message)=>createCustomerMessageFromFrontNotification(message)) : void 0);
    const unreadCustomerMessagesCounter = computed(()=>onSiteNotifications.value?.filter((message)=>!message.isRead).length || 0);
    const unreadRefMessagesCounter = computed(// eslint-disable-next-line max-len
    ()=>refFrontNotification.value?.filter((message)=>{
            const type = message.payload.type;
            return type !== RefProgramNotificationType.BONUS_ELIGIBILITY_RULES && type !== RefProgramNotificationType.REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION || message.isRead ? 0 : 1;
        }).length || 0);
    function setFrontNotifications() {
        if (!frontNotifications.value) frontNotifications.value = [];
    }
    function removeFrontNotification(itemId) {
        if (frontNotifications.value) frontNotifications.value = frontNotifications.value.filter((item)=>item.id !== itemId);
    }
    function resetFrontNotification() {
        frontNotifications.value = void 0;
    }
    function updateFrontNotification(item) {
        const notifications = frontNotifications.value ? [
            ...frontNotifications.value
        ] : [];
        const idx = notifications.findIndex((i)=>i.id === item.id);
        if (idx > -1) notifications[idx] = {
            ...notifications[idx],
            ...item
        };
        else notifications.unshift(item);
        frontNotifications.value = notifications;
    }
    function markAsRead(itemId) {
        const message = (frontNotifications.value ?? []).find((item)=>item.id === itemId);
        if (message) Object.assign(message, {
            isRead: true
        });
    }
    async function getUnreadNotifications() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        await getNotifications({
            unread: true,
            silent
        });
    }
    async function getAllCustomerNotifications() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        await getNotifications({
            unread: false,
            silent
        });
    }
    async function markAsDelivered(options) {
        await doMarkAsDelivered(api, (node)=>node.mutations.frontNotifications.markAsDelivered, {
            options
        });
    }
    async function markNotification(options) {
        await doMarkNotification(api, (node)=>node.mutations.frontNotifications.markAsRead, {
            options
        });
        const stateMessage = (frontNotifications.value ?? []).find((param)=>{
            let { id } = param;
            return id === options.id;
        });
        const removeAfterMarkList = [
            FrontNotificationType.CBC_BONUS_ACTIVATED,
            FrontNotificationType.VIDEO_VERIFICATION,
            FrontNotificationType.BS_DEPOSIT,
            FrontNotificationType.CRYPTO_DEPOSIT_STATUS
        ];
        if (removeAfterMarkList.includes(stateMessage?.type)) removeFrontNotification(options.id);
        else markAsRead(options.id);
    }
    async function markAsClicked(options) {
        const stateMessage = (frontNotifications.value ?? []).find((param)=>{
            let { id } = param;
            return id === options.id;
        });
        if (!stateMessage?.isClicked) await doMarkAsClicked(api, (node)=>node.mutations.frontNotifications.markAsClicked, {
            options
        });
        if (stateMessage?.type === FrontNotificationType.CBC_BONUS_ACTIVATED) removeFrontNotification(options.id);
    }
    async function deleteNotification(id) {
        await doDeleteNotification(api, (node)=>node.mutations.frontNotifications.delete, {
            options: {
                id
            }
        });
    }
    async function deleteMessage(message) {
        if (message.source === CustomerNotificationMessageSource.ALCR && frontNotifications.value) {
            const stateMessage = frontNotifications.value.find((param)=>{
                let { id } = param;
                return id === message.id;
            });
            if (stateMessage) {
                removeFrontNotification(message.id);
                try {
                    await deleteNotification(message.id);
                } catch  {
                    updateFrontNotification(stateMessage);
                }
            }
        } else throw new Error('You cannot call delete message on not ALCR message type');
    }
    function isRefFrontNotification(notification) {
        return 'payload' in notification;
    }
    async function deleteMultiple(param) {
        let { messages } = param;
        const failedMessages = [];
        if (messages && messages.length > 0) {
            for (const message of messages)removeFrontNotification(message.id);
            await promisesInBatch(messages, (message)=>deleteNotification(message.id).catch(()=>{
                    failedMessages.push(message);
                }));
            if (failedMessages.length > 0) for (const message of failedMessages)updateFrontNotification(message);
        }
        return failedMessages.map((item)=>({
                id: item.id,
                source: CustomerNotificationMessageSource.ALCR
            }));
    }
    async function fetchNotifications(input) {
        const { silent, ...options } = input;
        const response = await getNotif(api, (node)=>node.queries.frontNotifications.getNotifications, {
            options
        }, {
            silent,
            priority: silent ? RequestOptionsPriority.LOW : void 0
        });
        setFrontNotifications();
        for (const item of response.items){
            if (item.type !== FrontNotificationType.CBC_BONUS_ACTIVATED || !item.isRead) updateFrontNotification(item);
            if (!item.isDelivered) markAsDelivered({
                id: item.id
            });
        }
        return response.next;
    }
    async function getNotifications(param) {
        let { unread, silent } = param;
        let next = {
            limit: 100,
            offset: 0
        };
        do // eslint-disable-next-line no-await-in-loop
        next = await fetchNotifications({
            unread,
            types: [
                ...onSiteNotificationTypes,
                FrontNotificationType.CBC_BONUS_ACTIVATED,
                FrontNotificationType.VIDEO_VERIFICATION,
                FrontNotificationType.REFPRG
            ],
            limit: next.limit,
            offset: next.offset,
            silent
        });
        while (next);
    }
    function subscribeOnFrontNotification() {
        subscribeAuthorized({
            method: 'onFrontNotification',
            onMessage: (data)=>{
                updateFrontNotification({
                    ...data.onFrontNotification,
                    isClicked: false,
                    isRead: false
                });
                // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                if (!data.onFrontNotification.isDelivered && data.onFrontNotification.type !== FrontNotificationType.PLAY_TIME_ENDED) markAsDelivered({
                    id: data.onFrontNotification.id
                });
            },
            isEnabled: isSocketStickyBonusStatus,
            polling: {
                timeout: pollingInterval,
                callback: ()=>getUnreadNotifications(true),
                callOnLogin: true
            }
        });
    }
    subscribeOnFrontNotification();
    watch(isLoggedIn, (newValue)=>{
        if (!newValue) resetFrontNotification();
    });
    return {
        playTimeEndNotification,
        frontNotifications,
        cbcFrontNotification,
        refFrontNotification,
        videoVerificationNotifications,
        isVideoVerificationAlert,
        preparedCustomerMessages,
        unreadCustomerMessagesCounter,
        unreadRefMessagesCounter,
        onSiteNotifications,
        markNotification,
        deleteMessage,
        getNotifications,
        deleteMultiple,
        markAsClicked,
        getUnreadNotifications,
        getAllCustomerNotifications,
        removeFrontNotification
    };
});
export default useFrontNotificationsStore;
