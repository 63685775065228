import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "preloader"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'VPreloader',
    props: {
        hint: {
            default: ''
        }
    },
    setup (__props) {
        const VLoader = require('web/src/components/Loader').VLoaderDelayed;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(VLoader), {
                    hint: _ctx.hint
                }, null, 8, [
                    "hint"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'VPreloader'
                ]
            ]);
        };
    }
});
