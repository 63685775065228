import { computed, toRef } from 'vue';
import { useSlipRunnerClick } from 'web/src/modules/slip/composable';
import { useSlipSelectedEntriesStore } from 'web/src/modules/slip/submodules/slipSelectedEntries/store';
import { useOpenInCatalog } from 'web/src/modules/sportline/submodules/catalog';
import { SportEventStatus } from 'web/src/modules/sportline/enums';
import isMatchedSlipInfo from 'web/src/modules/slip/utils/isMatchedSlipInfo';
import { createSlipInfoRef } from 'web/src/modules/sportline/views/runner/utils';
const DASHES = '--';
export function runnerComponentPropsToRefs(props) {
    return {
        runner: toRef(props, 'runner'),
        market: toRef(props, 'market'),
        sportEventId: toRef(props, 'sportEventId'),
        isOutrightEvent: toRef(props, 'isOutrightEvent'),
        sportlineType: toRef(props, 'sportlineType', null),
        slipCaption: toRef(props, 'slipCaption'),
        doDisplayName: toRef(props, 'doDisplayName', false)
    };
}
export function useRunner(props) {
    const { doDisplayName, runner } = props;
    const { onSlipRunnerClick } = useSlipRunnerClick();
    const { openInCatalogIfEnabled } = useOpenInCatalog();
    const slipSelectedEntriesStore = useSlipSelectedEntriesStore();
    const selectedEventsInfo = toRef(()=>slipSelectedEntriesStore.selectedEventsInfo);
    const id = computed(()=>runner.value?.id);
    const value = computed(()=>runner.value?.value || DASHES);
    const price = computed(()=>runner.value?.price ?? 0);
    const name = computed(()=>runner.value?.name || DASHES);
    const isEmpty = computed(()=>!runner.value);
    const isLocked = computed(()=>runner.value?.status !== SportEventStatus.Open);
    const handicap = computed(()=>runner.value?.handicap);
    const slipInfo = createSlipInfoRef(props);
    const isSelected = computed(()=>selectedEventsInfo.value.some((item)=>!!slipInfo.value && isMatchedSlipInfo(item, slipInfo.value)));
    const label = computed(()=>{
        if (isEmpty.value) return null;
        if (doDisplayName.value) return name.value ?? null;
        return handicap.value ?? null;
    });
    function addToSlip(param) {
        let { event } = param;
        // handle click if runner is not locked or if it is selected to remove it from the slip
        const canAddToSlip = !isLocked.value || isSelected.value;
        if (!canAddToSlip) return;
        const item = slipInfo.value;
        if (!item) return;
        if (openInCatalogIfEnabled(event, {
            eventId: item.event,
            marketId: item.market
        })) return;
        onSlipRunnerClick(item);
    }
    return {
        id,
        value,
        price,
        name,
        isEmpty,
        isLocked,
        handicap,
        isSelected,
        label,
        addToSlip
    };
}
