import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, unref as _unref } from "vue";
import DepositsBonusSelectorComponent from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/DepositBonusSelector.vue';
import { DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
import SPaymentBonuses from 'web/src/modules/payments/components/PaymentBonuses/components/SPaymentBonuses/SPaymentBonuses.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FormDepositBonusSelector',
    props: {
        title: {},
        hintMessage: {},
        disabled: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        name: {},
        extraProperties: {},
        value: {
            type: [
                String,
                Boolean,
                Number,
                Array,
                Object
            ]
        },
        options: {},
        error: {}
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const emitChange = (id)=>{
            emit('change', {
                name: props.name,
                value: props.options?.selectedBonusCode ?? id
            });
        };
        return (_ctx, _cache)=>(_openBlock(), _createBlock(DepositsBonusSelectorComponent, {
                key: 1,
                "deposit-bonus-list": _ctx.options?.depositBonusList ?? [],
                "selected-bonus": _ctx.options?.selectedBonus ?? _unref(DEPOSIT_BONUS_NONE_SELECTED),
                "is-none-selected": _ctx.options?.isNoneSelected ?? false,
                "is-choosing-bonus-before-deposit": _ctx.options?.isChoosingBonusBeforeDeposit ?? false,
                onChange: emitChange
            }, null, 8, [
                "deposit-bonus-list",
                "selected-bonus",
                "is-none-selected",
                "is-choosing-bonus-before-deposit"
            ]));
    }
});
