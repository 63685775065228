import { computed } from 'vue';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { useResetPersistLocationSettings } from './useResetPersistLocationSettings';
/** Return null for the situation when page shouldn't affect the state */ export function useDoSavePersistLocation() {
    const { isResetLocationEnabled } = useResetPersistLocationSettings();
    const { isBetSwipeNavigationLayoutEnabled } = useSportlineLayoutSettings();
    const isEnabled = computed(()=>!isResetLocationEnabled.value);
    const isBetSwipeEnabled = computed(()=>isBetSwipeNavigationLayoutEnabled.value ? !isResetLocationEnabled.value : null);
    return {
        live: isEnabled,
        cybersport: isEnabled,
        sports: isEnabled,
        region: isBetSwipeEnabled,
        league: isBetSwipeEnabled
    };
}
