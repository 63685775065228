import { MatchProgressTimeDirection } from 'web/src/modules/sportline/enums';
import { useLiveProgressTimerController } from 'web/src/modules/sportline/composables/live-progress';
import { isStaticMatchProgressTime } from 'web/src/modules/sportline/utils';
import { totalSecondsToPretty } from 'web/src/modules/sportline/utils/live-progress';
/**
 * Create brand-new timer directive
 * It is used for tests
 */ export function createTimerDirective() {
    const { beforeElementMounted, elementUpdated, elementUnmounted } = useLiveProgressTimerController({
        updateEvery: 1000,
        isBindingChanged (binding) {
            return binding.value !== binding.oldValue;
        },
        updated (element, binding, passedTime) {
            if (isStaticMatchProgressTime(binding.arg)) return;
            if (void 0 === binding.value) return;
            const isReversed = binding.arg === MatchProgressTimeDirection.Reversed;
            const passedSeconds = Math.trunc(passedTime / 1000);
            const totalSeconds = isReversed ? binding.value - passedSeconds : binding.value + passedSeconds;
            // eslint-disable-next-line no-param-reassign
            element.innerText = totalSecondsToPretty(totalSeconds);
        }
    });
    return {
        beforeMount: beforeElementMounted,
        updated: elementUpdated,
        unmounted: elementUnmounted
    };
}
export const vTimer = createTimerDirective();
