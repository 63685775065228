import { getFirstTeamIdForSeason, getLastTeamIdForSeason } from './getTeamsForSeason';
function getTournamentDataFromStanding() {
    return {
        name: null,
        categoryName: null,
        seasons: []
    };
}
function getSeasonDataFromStanding(standing) {
    const { seasonName, seasonYear } = standing;
    return {
        key: seasonName || 'default',
        name: seasonName || null,
        year: seasonYear || null,
        groups: []
    };
}
function getTournamentGroupDataFromStanding(standing) {
    const { leagueTableId, leagueTableName, leagueTableType, leagueTableRound } = standing;
    return {
        id: leagueTableId || '0',
        name: leagueTableName || null,
        round: leagueTableRound || null,
        type: leagueTableType || null,
        teams: []
    };
}
function getTeamStandingDataFromStanding(standing) {
    try {
        const { team } = standing;
        const goals = (standing.goalsTotal || '0:0').split(':');
        const totals = {
            win: parseInt(standing.winTotal || '0', 10),
            draw: parseInt(standing.drawTotal || '0', 10),
            loss: parseInt(standing.lossTotal || '0', 10),
            matches: parseInt(standing.matchesTotal || '0', 10),
            points: parseInt(standing.pointsTotal || '0', 10),
            goals: [
                parseInt(goals[0], 10),
                parseInt(goals[1], 10)
            ]
        };
        const promotion = {
            id: standing.promotionId || null,
            name: standing.promotionName || null
        };
        const sortPositionTotal = standing.sortPositionTotal ? parseInt(standing.sortPositionTotal, 10) : void 0;
        return {
            position: sortPositionTotal ?? 0,
            team: team ?? null,
            totals,
            promotion
        };
    } catch  {
        return null;
    }
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function groupLeagueStandings(standings) {
    let tournament = null;
    const seasons = new Map([]);
    const seasonsGroups = {};
    for (const standing of standings){
        if (!tournament) tournament = getTournamentDataFromStanding();
        if (!tournament) continue;
        const season = getSeasonDataFromStanding(standing);
        if (!season) continue;
        const seasonKey = season.key;
        if (!seasons.get(seasonKey)) seasons.set(seasonKey, season);
        const groupKey = standing.leagueTableId || '0';
        if (!groupKey) continue;
        let seasonGroups = seasonsGroups[seasonKey];
        if (!seasonGroups) {
            seasonGroups = new Map([]);
            seasonsGroups[seasonKey] = seasonGroups;
        }
        let group = seasonGroups.get(groupKey) ?? null;
        if (!group) {
            group = getTournamentGroupDataFromStanding(standing);
            if (!group) continue;
            seasonGroups.set(groupKey, group);
        }
        const teamStanding = getTeamStandingDataFromStanding(standing);
        if (!!teamStanding) group.teams.push(teamStanding);
    }
    if (!tournament) return null;
    for (const seasonKey of seasons.keys()){
        const season = seasons.get(seasonKey);
        const groups = seasonsGroups[seasonKey];
        if (!!season && !!groups) {
            for (const group of groups.values())season.groups.push(group);
            tournament.seasons.push(season);
        }
    }
    return tournament;
}
function filterStandingsTeamsInLargeGroup(group, teamIds) {
    const willDisplayMap = group.teams.map((team)=>({
            id: team.team?.id,
            team,
            willDisplay: false
        }));
    for (const [index, team] of willDisplayMap.entries()){
        if (!!team.id && !!teamIds.has(team.id)) {
            // for large groups we will display team element and +-1 index elements
            // for first and last elements we will show 2 next/prev elements
            if (willDisplayMap[index - 1]) willDisplayMap[index - 1].willDisplay = true;
            else if (willDisplayMap[index + 2]) willDisplayMap[index + 2].willDisplay = true;
            if (willDisplayMap[index]) willDisplayMap[index].willDisplay = true;
            if (willDisplayMap[index + 1]) willDisplayMap[index + 1].willDisplay = true;
            else if (willDisplayMap[index - 2]) willDisplayMap[index - 2].willDisplay = true;
        }
    }
    return {
        ...group,
        teams: willDisplayMap.filter((team)=>team.willDisplay).map((team)=>team.team)
    };
}
function filterStandingsInSeasonByTeams(season, teamIds) {
    const firstGroupWithTeams = season.groups.find((group)=>group.teams.some((team)=>!!team.team?.id && teamIds.has(team.team.id))) ?? season.groups[0];
    if (!firstGroupWithTeams) return {
        ...season,
        groups: []
    };
    // keep only closest teams for large group and full group for small groups
    const filterGroup = firstGroupWithTeams.teams.length > 6 ? filterStandingsTeamsInLargeGroup(firstGroupWithTeams, teamIds) : firstGroupWithTeams;
    return {
        ...season,
        groups: filterGroup && filterGroup.teams.length > 0 ? [
            filterGroup
        ] : []
    };
}
export function filterGroupsInTournamentByTeams(tournament, teamIds) {
    if (!tournament) return null;
    const seasons = tournament.seasons.map((season)=>filterStandingsInSeasonByTeams(season, teamIds)).filter((season)=>season.groups.length > 0);
    return seasons.length > 0 ? {
        ...tournament,
        seasons
    } : null;
}
function filterStandingsInSeasonByFirstAndLastPosition(season) {
    const teamIds = new Set([]);
    const firstTeamId = getFirstTeamIdForSeason(season);
    const lastTeamId = getLastTeamIdForSeason(season);
    if (firstTeamId) teamIds.add(firstTeamId);
    if (lastTeamId) teamIds.add(lastTeamId);
    return filterStandingsInSeasonByTeams(season, teamIds);
}
export function filterGroupsInTournamentByFirstAndLastPosition(tournament) {
    if (!tournament) return null;
    const seasons = tournament.seasons.map((season)=>filterStandingsInSeasonByFirstAndLastPosition(season)).filter((season)=>season.groups.length > 0);
    return seasons.length > 0 ? {
        ...tournament,
        seasons
    } : null;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getPromotionsFromTournament(tournament) {
    const result = new Map([]);
    for (const season of tournament?.seasons ?? [])for (const group of season.groups)for (const team of group.teams){
        if (!team.promotion.id || !team.promotion.name) continue;
        if (!result.get(team.promotion.id)) result.set(team.promotion.id, team.promotion);
    }
    return result;
}
