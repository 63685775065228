import { toRef } from 'vue';
import { useRouter } from 'vue-router';
import { logger } from '@leon-hub/logging';
import { IconName, IconSize } from '@leon-hub/icons';
import { useRootStore } from 'web/src/modules/core/store';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useLanguageSelector() {
    const availableLanguages = toRef(useRootStore(), 'availableLanguages');
    const router = useRouter();
    const iconGlobeProps = {
        size: IconSize.SIZE_20,
        name: IconName.GLOBE
    };
    const iconCheckProps = {
        size: IconSize.SIZE_16,
        name: IconName.CHECK_FILLED
    };
    function onLanguageItemClick(code, text) {
        useI18n().setLanguageTag({
            languageTag: code,
            textMetrics: text,
            reload: true
        }).catch((err)=>logger.error(err));
    }
    function onChange(language) {
        const { code, text } = language;
        router.back();
        onLanguageItemClick(code, text);
    }
    return {
        availableLanguages,
        iconGlobeProps,
        iconCheckProps,
        onChange
    };
}
