import { computed, ref } from 'vue';
import useRecalculateImageSource from 'web/src/components/Image/composables/useRecalculateImageSource';
import { ObjectFitOption } from '../enums';
export default function useVImage(props) {
    const { src, x1, x2, x3, x1webp, x2webp, x3webp, objectFit } = props;
    const lazyImage = ref();
    const imageSource = computed(()=>({
            src: src.value,
            x1: x1.value,
            x2: x2.value,
            x3: x3.value,
            x1webp: x1webp.value,
            x2webp: x2webp.value,
            x3webp: x3webp.value
        }));
    const prepareImageStyles = computed(()=>({
            ...objectFit.value === ObjectFitOption.FILL ? {} : {
                objectFit: objectFit.value
            }
        }));
    const { normalizedSrc, normalizeSrc } = useRecalculateImageSource({
        imageSource
    });
    const generatedSrc = computed(()=>normalizedSrc.value || '');
    function forceShowImage() {
        lazyImage.value?.forceShowImage();
    }
    return {
        lazyImage,
        generatedSrc,
        prepareImageStyles,
        forceShowImage,
        created: normalizeSrc
    };
}
