import { computed, toRef } from 'vue';
import { useCustomerLeaguesStore } from 'web/src/modules/sportline/submodules/favorites/store/leagues';
import { getFavoriteLeaguesFromSportList } from 'web/src/modules/sportline/submodules/favorites/utils';
export default function useSportlineSidebarFavoriteLeaguesComposable(props) {
    const customerLeaguesStore = useCustomerLeaguesStore();
    const customerLeaguesIds = toRef(customerLeaguesStore, 'customerLeaguesIds');
    const fullFavoriteLeagues = computed(()=>{
        const favoriteLeaguesIds = customerLeaguesIds.value ?? Object.freeze(new Array());
        const result = props.fullSportsList.value ? getFavoriteLeaguesFromSportList(props.fullSportsList.value, favoriteLeaguesIds) : [];
        return Object.freeze(result);
    });
    return {
        fullFavoriteLeagues
    };
}
