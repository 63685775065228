import { computed } from 'vue';
import { Theme } from '@leon-hub/api-sdk';
import { useTheme } from 'web/src/modules/theme/composables';
export default function useSportlineBackgroundColor(props) {
    const { theme } = useTheme();
    const { background } = props;
    const backgroundColor = computed(()=>{
        if (theme.value === Theme.LIGHT && background.value?.light) return background.value.light || null;
        return background.value?.dark || null;
    });
    return {
        backgroundColor
    };
}
