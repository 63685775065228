import { isObject } from '@leon-hub/guards';
// Currently, all members in LeaderBoardWinnersResponse are nullable
export function isLeaderBoardWinnersResponse(value) {
    return isObject(value);
}
export function getLeaderBoardList(id, list) {
    return list[id] || [];
}
export function getLeaderBoardOptions(actionUrl, id, options) {
    return options[actionUrl]?.[id];
}
export default {
    isLeaderBoardWinnersResponse
};
