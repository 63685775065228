import { BetType } from '@leon-hub/api-sdk';
import formatOdd from 'web/src/utils/formatOdd';
import DateTime from 'web/src/utils/DateTime';
import isCompetitorsTuple from '../../../guards/isCompetitorsTuple';
const getPendingBetMatchedType = (oddsType)=>{
    const status = oddsType.toLowerCase();
    if ('system' === status) return BetType.SYSTEM;
    if ('combi' === status) return BetType.EXPRESS;
    return BetType.SINGLE;
};
export default function mapPendingBetToSlipListHistoryItemData(param) {
    let { pendingBet, expressTitle, systemTitle, formatMoney, isOddsFormatSwitcherEnabled = false } = param;
    const { betId, itemsQuantity, outcomeSr, eventNameParts, combiStatus, isEnabledForCashout, odds, oddsStr, oddsType, stake, acceptDate, isLive, cashoutAmount } = pendingBet;
    const betType = getPendingBetMatchedType(oddsType || '');
    const competitors = isCompetitorsTuple(eventNameParts) ? eventNameParts : void 0;
    const namesMap = {
        [BetType.SINGLE]: `${eventNameParts.join(' ').trim()}`,
        [BetType.EXPRESS]: expressTitle,
        [BetType.SYSTEM]: systemTitle
    };
    return {
        id: betId || 0,
        eventName: competitors ? void 0 : namesMap[betType],
        competitors,
        odd: isOddsFormatSwitcherEnabled ? oddsStr ?? '' : formatOdd(odds || 0),
        marketName: betType === BetType.SINGLE ? `${oddsType}` : '',
        runnerName: betType === BetType.SYSTEM ? `${combiStatus}` : `${outcomeSr}`,
        itemsQuantity: itemsQuantity ?? void 0,
        stake: formatMoney(stake || 0),
        cashOutAvailable: !!isEnabledForCashout,
        cashoutAmount,
        stakeAmount: stake,
        isLive,
        betType,
        eventDate: acceptDate ? `${DateTime.withTimeStamp(acceptDate).toDateTime()}` : void 0
    };
}
