import { computed } from 'vue';
import usePaymentsSuggestedAmounts from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsSuggestedAmounts';
import suggestedAmountsToFastChoices from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/utils/suggestedAmountsToFastChoices';
import { useFormatMoney } from 'web/src/modules/money/composables';
import usePaymentsMaxValueFastChoice from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoice';
export default function usePaymentsFastChoices(props) {
    const { filteredDefaultSuggestedAmounts, filteredCurrentPaymentSystemSuggestedAmounts } = usePaymentsSuggestedAmounts(props);
    const formatMoney = useFormatMoney();
    const { maxValueFastChoice } = usePaymentsMaxValueFastChoice(props);
    const defaultFastChoices = computed(()=>suggestedAmountsToFastChoices(filteredDefaultSuggestedAmounts.value, formatMoney));
    const currentPaymentSystemFastChoices = computed(()=>suggestedAmountsToFastChoices(filteredCurrentPaymentSystemSuggestedAmounts.value, formatMoney));
    function removeMaxFastChoiceValue(choices) {
        return choices.filter((item)=>item.value !== maxValueFastChoice.value);
    }
    const fastChoices = computed(()=>{
        if (currentPaymentSystemFastChoices.value) return removeMaxFastChoiceValue(currentPaymentSystemFastChoices.value);
        return defaultFastChoices.value ? removeMaxFastChoiceValue(defaultFastChoices.value) : null;
    });
    return {
        fastChoices
    };
}
