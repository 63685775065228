import { isError, isObject, isString, isUndefined } from '@leon-hub/guards';
import { CaptchaType, RecaptchaTheme as ReCaptchaThemeSiteConfig, Theme } from '@leon-hub/api-sdk/src/sdk/sdk';
import { ReCaptchaTheme } from 'web/src/modules/captcha/store/enums';
export function isReCaptchaTheme(value) {
    return value === ReCaptchaTheme.DARK || value === ReCaptchaTheme.LIGHT;
}
export function isReCaptchaResponse(value) {
    return isUndefined(value) || isObject(value) && (isCaptchaToken(value.captchaResponse) || isError(value.captchaResponse));
}
export function isCaptchaToken(value) {
    return isString(value) && value.length > 0;
}
export function isCaptchaType(value) {
    return value === CaptchaType.RECAPTCHA || value === CaptchaType.INVISIBLE_RECAPTCHA || value === CaptchaType.ANDROID_RECAPTCHA;
}
function mapTheme(theme) {
    switch(theme){
        case Theme.DARK:
        case Theme.LIGHT:
            return ReCaptchaTheme.LIGHT;
        default:
            throw new Error(`Unknown theme ${theme}`);
    }
}
export function reCaptchaThemeConfigMapper(configTheme) {
    let currentTheme = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : Theme.DARK;
    switch(configTheme){
        case ReCaptchaThemeSiteConfig.DARK:
            return ReCaptchaTheme.DARK;
        case ReCaptchaThemeSiteConfig.LIGHT:
            return ReCaptchaTheme.LIGHT;
        case ReCaptchaThemeSiteConfig.UI_INVERT:
            return currentTheme === Theme.DARK ? ReCaptchaTheme.LIGHT : ReCaptchaTheme.DARK;
        case ReCaptchaThemeSiteConfig.UI_MATCH:
            return mapTheme(currentTheme);
        default:
            throw new Error(`ReCaptcha theme:${configTheme} is not implemented`);
    }
}
