import { computed, ref } from 'vue';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
export default function useModalContentWrapper(props) {
    const scrollbar = ref();
    const content = ref();
    const innerScrollElement = computed(()=>{
        if (!props.isScrollBarEnabled) return null;
        return scrollbar.value;
    });
    function scrollToElementId(id) {
        const matchedElement = document.getElementById(id);
        if (matchedElement && innerScrollElement.value) innerScrollElement.value?.scrollTo(matchedElement.offsetTop);
    }
    useBusSafeSubscribe(BusEvent.SCROLL_TO_ELEMENT_ID, (param)=>{
        let { id, inModal } = param;
        if (!inModal) return;
        scrollToElementId(id);
    });
    useBusSafeSubscribe(BusEvent.MODAL_CONTENT_SCROLL_TOP, ()=>{
        innerScrollElement.value?.scrollTo(0);
    });
    return {
        scrollbar,
        content
    };
}
