import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useVThemeSwitcher } from 'web/src/components/ThemeSwitcher/VThemeSwitcher/composables';
import ThemeList from 'web/src/modules/theme/components/ThemeList/ThemeList.vue';
import useUserSettingsPage from 'web/src/modules/profile/pages/UserSettingsRoutePage/composables/useUserSettingsPage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ThemeRoutePage',
    setup (__props) {
        const { themeList } = useVThemeSwitcher();
        const { handleChangeTheme } = useUserSettingsPage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['theme-wrapper'])
            }, [
                _createVNode(ThemeList, {
                    theme: _unref(themeList),
                    onChange: _unref(handleChangeTheme)
                }, null, 8, [
                    "theme",
                    "onChange"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'ThemeRoutePage'
                ]
            ]);
        };
    }
});
