import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { defineAsyncComponent } from 'vue';
import { IconName, SlottIconName } from '@leon-hub/icons';
import { AnchorTarget, ButtonKind } from '@leon-hub/module-buttons';
import { Tag } from '@leon-hub/tags';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { useCmsContentToPrint, useRuleDownload } from 'web/src/modules/rules/composables';
import { ButtonHeight } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RulesContentDefault',
    props: {
        cmsKey: {},
        title: {},
        isDownloadButtonEnabled: {
            type: Boolean
        },
        termsTitle: {}
    },
    setup (__props) {
        const props = __props;
        const { fileHref } = useRuleDownload();
        const { getBtrKeyToPrint } = useCmsContentToPrint();
        const { $translate } = useI18n();
        function onPrint() {
            if (props.termsTitle && props.cmsKey) {
                const captionValue = $translate(props.termsTitle).value;
                getBtrKeyToPrint(captionValue, props.cmsKey);
            }
        }
        const DesktopTitle = defineAsyncComponent(()=>import('web/src/modules/core/components/DesktopTitle/DesktopTitle.vue'));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['rules-content-default'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['rules-content-default__header'])
                }, [
                    (_openBlock(), _createBlock(_unref(DesktopTitle), {
                        key: 0,
                        title: _ctx.title
                    }, null, 8, [
                        "title"
                    ])),
                    (_openBlock(), _createBlock(VButton, {
                        key: 2,
                        "is-uppercase": false,
                        kind: _unref(ButtonKind).BASE,
                        height: _unref(ButtonHeight).MEDIUM,
                        rounded: "",
                        "icon-name": _unref(IconName).DOCUMENT,
                        label: _ctx.$t('WEB2_PRINT'),
                        onClick: _cache[0] || (_cache[0] = ($event)=>onPrint())
                    }, null, 8, [
                        "kind",
                        "height",
                        "icon-name",
                        "label"
                    ]))
                ], 2),
                _createVNode(VCmsContent, {
                    "cms-key": _ctx.cmsKey
                }, null, 8, [
                    "cms-key"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'RulesContentDefault'
                ]
            ]);
        };
    }
});
