import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { computed, onMounted, ref, toRef, watch } from 'vue';
import { useRoute } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import SidebarCaption from 'web/src/components/SidebarMenu/SidebarCaption/SidebarCaption.vue';
import TheSidebarMenu from 'web/src/components/SidebarMenu/TheSidebarMenu/TheSidebarMenu.vue';
import { useRulesStore } from 'web/src/modules/rules/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { convertNavigationToSidebarMenu } from 'web/src/components/SidebarMenu/utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RulesSidebarList',
    setup (__props) {
        const route = useRoute();
        const rulesStore = useRulesStore();
        const siteConfigStore = useSiteConfigStore();
        const isRulesTitleEnabled = toRef(siteConfigStore, 'isRulesTitleEnabled');
        const isRulesNavigationOpen = toRef(siteConfigStore, 'isRulesNavigationOpen');
        const navigation = toRef(rulesStore, 'navigation');
        const openedLevelOne = ref({});
        const openedLevelTwo = ref({});
        const openedLevelOneById = (id)=>openedLevelOne.value[id];
        const openedLevelTwoById = (id)=>openedLevelTwo.value[id];
        const isActiveRuleElement = (element)=>element.id === route.params.levelThreeId;
        const menu = computed(()=>convertNavigationToSidebarMenu(navigation.value, {
                active: (navItem1)=>openedLevelOneById(navItem1.id)
            }, {
                active: (item, parent)=>openedLevelTwoById(`${parent.id}_${item.id}`)
            }, {
                active: isActiveRuleElement,
                location: resolveRuleLocation
            }));
        function toggleLevelOneById(isOpen, id) {
            openedLevelOne.value[id] = isOpen;
        }
        function toggleLevelTwoById(isOpen, id) {
            openedLevelTwo.value[id] = isOpen;
        }
        function resolveRuleLocation(itemL3, itemL2, itemL1) {
            return {
                name: RouteName.RULES,
                params: {
                    levelOneId: itemL1.id,
                    levelTwoId: itemL2.id,
                    levelThreeId: itemL3.id
                }
            };
        }
        onMounted(()=>{
            if (isRulesNavigationOpen.value) for (const levelOne of navigation.value){
                toggleLevelOneById(true, levelOne.id);
                if (levelOne.children) for (const levelTwo of levelOne.children)toggleLevelTwoById(true, `${levelOne.id}_${levelTwo.id}`);
            }
        });
        watch(()=>route.params, (to)=>{
            toggleLevelOneById(true, to.levelOneId);
            toggleLevelTwoById(true, `${to.levelOneId}_${to.levelTwoId}`);
        }, {
            immediate: true
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(TheSidebarMenu, {
                menu: menu.value
            }, {
                top: _withCtx(()=>[
                        isRulesTitleEnabled.value ? (_openBlock(), _createBlock(SidebarCaption, {
                            key: 0,
                            title: _ctx.$t('WEB2_RULES')
                        }, null, 8, [
                            "title"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "menu"
            ])), [
                [
                    _directive_auto_id,
                    'RulesSidebarList'
                ]
            ]);
        };
    }
});
