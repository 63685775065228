import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
export function getSport(input) {
    const apiClient = useSportlineApi();
    return apiClient.getSport(input);
}
export function getRegion(input) {
    const apiClient = useSportlineApi();
    return apiClient.getRegion(input);
}
export function getLeague(input) {
    const apiClient = useSportlineApi();
    return apiClient.getLeague(input);
}
