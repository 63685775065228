import { isNumber, isString, isBoolean, isUndefined, bindIsArrayOf, isValidInterface } from '@leon-hub/guards';
import { isCoreMarketRunnerResponse } from './isCoreMarketRunnerResponse';
import { isBetlineSelectionTag } from './isBetlineSelectionTag';
export const isCoreMarketResponseFieldGuards = {
    id: isNumber,
    marketTypeId: isNumber,
    name: isString,
    runners: bindIsArrayOf(isCoreMarketRunnerResponse),
    open: isBoolean,
    primary: isBoolean,
    cols: isNumber,
    selectionTypes: [
        isUndefined,
        bindIsArrayOf(isBetlineSelectionTag)
    ],
    handicap: [
        isUndefined,
        isString
    ],
    hasZeroMargin: [
        isUndefined,
        isBoolean
    ]
};
export function isCoreMarketResponse(value) {
    return isValidInterface(value, isCoreMarketResponseFieldGuards);
}
