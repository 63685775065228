import { EgsAvailabilityStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
export default function getSeatsText(status, seats, $t) {
    switch(status){
        case EgsAvailabilityStatus.NOT_AVAILABLE:
            return seats || $t('WEB2_EGS_GAME_NOT_AVAILABLE').value;
        case EgsAvailabilityStatus.BET_BEHIND:
            return $t('WEB2_EGS_GAME_BET_BEHIND').value;
        case EgsAvailabilityStatus.AVAILABLE:
            return seats;
        default:
            return '';
    }
}
