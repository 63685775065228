import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { getFastBetInputError, requestFastBetValuesSave, requestSaveShowMaxFastBet } from '../utils';
import { BetInputErrorKind } from '../enums';
const emptyFastBetsSettings = {
    enabled: false,
    showMax: false,
    values: []
};
const useFastBetsStore = defineStore('fastBets', ()=>{
    const siteConfigStore = useSiteConfigStore();
    const customerDataStore = useCustomerDataStore();
    const api = useGraphqlClient();
    const { updateLocalCustomerData } = customerDataStore;
    const { currency } = useCurrency();
    const formatMoney = useFormatMoney();
    const slipSiteConfig = toRef(siteConfigStore, 'slipBlock');
    const customerFastBetsSettings = toRef(customerDataStore, 'fastBets');
    const fastBetInputItems = ref([]);
    const fastBetsSaved = ref(false);
    const defaultFastBetsSettings = computed(()=>{
        const defaultSettings = slipSiteConfig.value?.fastBets;
        if (!defaultSettings) return emptyFastBetsSettings;
        const defaultCurrencySettings = defaultSettings.values.find((item)=>item.currency === currency.value);
        const defaultFastBets = defaultCurrencySettings?.values ?? [];
        return {
            enabled: defaultFastBets.length > 0,
            values: defaultFastBets
        };
    });
    const fastBetsSettings = computed(()=>customerFastBetsSettings.value ?? defaultFastBetsSettings.value);
    const fastBetsValues = computed(()=>fastBetsSettings.value.values);
    const fastBetsOptions = computed(()=>fastBetsValues.value.map((value)=>({
                label: formatMoney(value, {
                    useGrouping: false,
                    currency: currency.value
                }),
                value
            })));
    const isFastBetsEnabled = computed(()=>fastBetsSettings.value.enabled ?? false);
    const fastBetsShowMax = computed(()=>fastBetsSettings.value.showMax ?? false);
    const maxAvailableFastBet = computed(()=>fastBetsSettings.value.maxValue || null);
    const minAvailableFastBet = computed(()=>fastBetsSettings.value.minValue || 1);
    const setFastBetInputItems = (items)=>{
        fastBetInputItems.value = items;
    };
    const setFastBetsSaved = (isSaved)=>{
        fastBetsSaved.value = isSaved;
    };
    const initFastBetInputValues = ()=>{
        const items = fastBetsValues.value.map((value)=>({
                value: `${value}`
            }));
        setFastBetInputItems(items);
    };
    const checkFastBetInputValues = ()=>{
        const result = fastBetInputItems.value.map((item, index)=>{
            const error = getFastBetInputError({
                input: item.value,
                max: maxAvailableFastBet.value,
                min: minAvailableFastBet.value,
                isRequired: true
            });
            const changed = error === BetInputErrorKind.NONE && item.value !== `${fastBetsValues.value[index]}`;
            return {
                error,
                value: item.value,
                changed
            };
        });
        setFastBetInputItems(result);
    };
    const handleFastBetValuesChange = (values)=>{
        setFastBetsSaved(false);
        const result = fastBetInputItems.value.map((item, index)=>({
                ...item,
                value: values[index]
            }));
        setFastBetInputItems(result);
        checkFastBetInputValues();
    };
    const saveFastBetValues = async (settings)=>{
        updateLocalCustomerData({
            fastBets: settings
        });
        const response = await requestFastBetValuesSave(api, fastBetsSettings.value);
        if (response?.result === 'OK') setFastBetsSaved(true);
    };
    const handleFastBetValuesBlur = ()=>{
        checkFastBetInputValues();
        const validValues = fastBetInputItems.value.map((item, index)=>{
            if (item.error === BetInputErrorKind.NONE) return Number(item.value);
            return fastBetsValues.value[index];
        });
        if (JSON.stringify(validValues) === JSON.stringify(fastBetsValues.value)) return;
        const settingsToSave = {
            ...fastBetsSettings.value,
            values: validValues
        };
        saveFastBetValues(settingsToSave);
    };
    const handleFastBetMaxSwitch = async ()=>{
        const currentSettings = fastBetsSettings.value;
        const nextSettings = {
            ...currentSettings,
            showMax: !currentSettings.showMax
        };
        updateLocalCustomerData({
            fastBets: nextSettings
        });
        try {
            await requestSaveShowMaxFastBet(api, nextSettings.showMax);
        } catch (error) {
            updateLocalCustomerData({
                fastBets: currentSettings
            });
            throw error;
        }
    };
    const clearFastBetInputValues = ()=>{
        setFastBetInputItems([]);
    };
    return {
        fastBetsOptions,
        isFastBetsEnabled,
        fastBetsShowMax,
        maxAvailableFastBet,
        minAvailableFastBet,
        fastBetInputItems,
        fastBetsSaved,
        handleFastBetValuesChange,
        handleFastBetValuesBlur,
        handleFastBetMaxSwitch,
        initFastBetInputValues,
        clearFastBetInputValues
    };
});
export default useFastBetsStore;
