import { delay } from '@leon-hub/utils';
import { SandboxApiError } from '../errors';
import { sandboxScriptErrorMessage } from '../constants';
function printLoadableScript(script) {
    return `<script defer onerror="postMessage('${sandboxScriptErrorMessage}')" crossorigin="anonymous" src="${script}"></script>`;
}
export async function printScript(param) {
    let { script, retry = 2 } = param;
    if ('string' == typeof script) return Promise.resolve(printLoadableScript(script));
    if (!script.inline) return Promise.resolve(printLoadableScript(script.src));
    let counter = retry;
    try {
        do {
            // eslint-disable-next-line no-await-in-loop
            const response = await fetch(script.src);
            if (response.ok) {
                // eslint-disable-next-line no-await-in-loop
                const content = await response.text();
                return `<script onerror="postMessage('${sandboxScriptErrorMessage}', '*')">try{${content}}catch(e){postMessage('${sandboxScriptErrorMessage}', '*')}</script>`;
            }
            // eslint-disable-next-line no-await-in-loop
            await delay(100);
            counter -= 1;
        }while (counter > 0);
    } catch (cause) {
        throw new SandboxApiError({
            cause
        });
    }
    throw new SandboxApiError({
        message: 'Unable to fetch sandbox resource'
    });
}
