import { computed } from 'vue';
import isPhoneInputProps from 'web/src/components/Input/guards/isPhoneInputProps';
import isPartialFormPhoneValue from 'web/src/components/Form/guards/isPartialFormPhoneValue';
import getHintFields from '../../utils/getHintFields';
export default function useFormPhone(props) {
    const componentProps = computed(()=>{
        const { name, value, options, title, disabled, hintMessage, autofocus } = props;
        const fieldData = value || {};
        isPartialFormPhoneValue(fieldData);
        const { prefix, suffix, countryCode } = fieldData;
        // errors come for that field as {prefix: error, suffix: error}, but component need a string
        const errorList = Object.values(props.error || {});
        isPhoneInputProps(options);
        return {
            name,
            label: title,
            disabled,
            value: suffix,
            // for phone it is fine to show first error
            error: errorList.length ? errorList[0] : '',
            ...options,
            // prefix and countryCode is valid options, override from formData if it exists
            ...prefix ? {
                prefix
            } : {},
            ...countryCode ? {
                countryCode
            } : {},
            ...getHintFields(options, hintMessage),
            autofocus
        };
    });
    return {
        componentProps
    };
}
