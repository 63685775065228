import { toRef } from 'vue';
import { prepareSiteConfigHtml } from '@leon-hub/html-utils';
import HighLighter from 'web/src/utils/HighLighter';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';
export default function useSearchText() {
    const searchText = toRef(useSupportFaqStore(), 'searchText');
    const highlightedText = (text)=>HighLighter.highlight(prepareSiteConfigHtml(text), searchText.value.trim(), 'highlighted-text');
    return {
        highlightedText,
        searchText
    };
}
