import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VHeadlineBanner from 'web/src/components/HeadlineBanner/VHeadlineBanner/VHeadlineBanner.vue';
import { useMainBannerItem } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainBannerItem',
    props: {
        isRedirectDisabled: {
            type: Boolean
        },
        isSlideHidden: {
            type: Boolean
        },
        groupId: {},
        banner: {},
        linkTitle: {},
        aspectRatio: {
            default: 3
        },
        bannerType: {},
        isActive: {
            type: Boolean,
            default: true
        },
        isGroupBannersDev: {
            type: Boolean
        },
        isVisibleImage: {
            type: Boolean
        },
        isBigHeight: {
            type: Boolean
        },
        isHalfWidth: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { item, bannerProperties } = useMainBannerItem(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VHeadlineBanner, _mergeProps({
                ref_key: "item",
                ref: item
            }, _unref(bannerProperties), {
                class: {
                    [_ctx.$style['bn-item']]: true,
                    [_ctx.$style[`bn-item--location-${_ctx.banner.location}`]]: !!_ctx.banner.location
                },
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('click', $event))
            }), null, 16, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'MainBannerItem'
                ]
            ]);
        };
    }
});
