import { computed, ref } from 'vue';
import { IconName } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import PaymentsCreditCardBeautifier from 'web/src/modules/payments/form-widgets/CreditCardSelector/utils/PaymentsCreditCardBeautifier';
import { useI18n } from 'web/src/modules/i18n/composables';
import { PAYMENTS_VISIBLE_CARDS_COUNT } from 'web/src/modules/payments/constants';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
export default function usePaymentsCreditCardRadioSelector(props, emit) {
    const isCollapsed = ref(true);
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const showNewCardButton = computed(()=>props.hasNewCard && props.creditCards.length <= PAYMENTS_VISIBLE_CARDS_COUNT || !isCollapsed.value);
    const hasCollapsedView = computed(()=>props.creditCards.length > PAYMENTS_VISIBLE_CARDS_COUNT);
    const cardsList = computed(()=>{
        if (props.creditCards.length > PAYMENTS_VISIBLE_CARDS_COUNT && isCollapsed.value) return props.creditCards.slice(0, PAYMENTS_VISIBLE_CARDS_COUNT);
        return props.creditCards;
    });
    const buttonProperties = computed(()=>{
        let properties = {
            label: $translate('WEB2_PAYMENTS_CARDS_COLLAPSE_SHOW').value,
            iconName: IconName.EXPAND_DOWN,
            kind: ButtonKind.BASE_OPACITY,
            height: ButtonHeight.SMALL,
            fullWidth: true,
            iconRight: true
        };
        if (!isCollapsed.value) properties = {
            ...properties,
            label: $translate('WEB2_PAYMENTS_CARDS_COLLAPSE_HIDE').value,
            iconName: IconName.EXPAND_UP
        };
        return properties;
    });
    const switchCollapsed = ()=>{
        isCollapsed.value = !isCollapsed.value;
    };
    const getName = (type, pan)=>new PaymentsCreditCardBeautifier(`${type}`, pan).getName();
    function onDelete(bankCard) {
        const beautifier = new PaymentsCreditCardBeautifier(bankCard.type ?? '', bankCard.pan ?? '');
        const { subscribe } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_CARD_DELETE_TITLE').value,
                confirmMessage: `${$translate('WEB2_CARD_DELETE_DESCR_START').value}
            <span class="modal__text-mark">${beautifier.getName()}</span>
            ${$translate('WEB2_CARD_DELETE_DESCR_END').value}`,
                buttons: [
                    {
                        label: $translate('WEB2_CARD_DELETE').value
                    }
                ],
                dataTestId: 'card-delete'
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                emit('delete', bankCard.token);
            }
        });
    }
    return {
        cardsList,
        hasCollapsedView,
        showNewCardButton,
        buttonProperties,
        getName,
        switchCollapsed,
        onDelete
    };
}
