import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "data-test-key"
];
const _hoisted_2 = [
    "data-test-key"
];
const _hoisted_3 = [
    "data-test-key"
];
const _hoisted_4 = [
    "data-test-key"
];
const _hoisted_5 = [
    "data-test-key"
];
const _hoisted_6 = [
    "data-test-key"
];
const _hoisted_7 = [
    "data-test-key"
];
const _hoisted_8 = [
    "data-test-key"
];
const _hoisted_9 = {
    key: 0
};
const _hoisted_10 = [
    "data-test-key"
];
const _hoisted_11 = [
    "data-test-key"
];
const _hoisted_12 = [
    "data-test-key"
];
import { VIcon } from '@components/v-icon';
import CustomerHistoryListItemLayout from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemLayout/CustomerHistoryListItemLayout.vue';
import { defaultCurrency } from 'web/src/modules/money/consts';
import { TestKeysEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { useCustomerHistoryListItemBet } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListItemBet',
    props: {
        item: {},
        currency: {
            default: defaultCurrency
        }
    },
    emits: [
        "cash-out",
        "item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isLive, isCombi, isCalculated, iconProperties, predictionLabel, statusProperties, dateProperties, eventDescription, betPrice, debitProperties, creditValue, predictionChoice } = useCustomerHistoryListItemBet(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(CustomerHistoryListItemLayout, {
                onClick: _cache[1] || (_cache[1] = ($event)=>emit('item-click', _ctx.item))
            }, _createSlots({
                metaLeft: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['bet-list-item__status']]: true,
                                [_ctx.$style['bet-list-item__status--positive']]: _unref(isLive)
                            }),
                            "data-test-key": _unref(TestKeysEnum).STATUS
                        }, _toDisplayString(_unref(statusProperties)), 11, _hoisted_1)
                    ]),
                metaRight: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bet-list-item__date']),
                            "data-test-key": _unref(TestKeysEnum).DATE
                        }, _toDisplayString(_unref(dateProperties)), 11, _hoisted_2)
                    ]),
                descriptionLeft: _withCtx(()=>[
                        _unref(eventDescription)[0] ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['bet-list-item__event-description']),
                            "data-test-key": _unref(TestKeysEnum).EVENT_DESCRIPTION
                        }, _toDisplayString(_unref(eventDescription)[0]), 11, _hoisted_3)) : _createCommentVNode("", true),
                        _unref(eventDescription)[1] ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['bet-list-item__event-description']),
                            "data-test-key": _unref(TestKeysEnum).EVENT_DESCRIPTION
                        }, _toDisplayString(_unref(eventDescription)[1]), 11, _hoisted_4)) : _createCommentVNode("", true),
                        _unref(isCombi) ? (_openBlock(), _createElementBlock("span", {
                            key: 2,
                            class: _normalizeClass(_ctx.$style['bet-list-item__prediction-label']),
                            "data-test-key": _unref(TestKeysEnum).MARKET_NAME
                        }, _toDisplayString(_unref(predictionLabel)), 11, _hoisted_5)) : _createCommentVNode("", true)
                    ]),
                descriptionRight: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bet-list-item__coefficient']),
                            "data-test-key": _unref(TestKeysEnum).ODDS
                        }, _toDisplayString(_unref(betPrice)), 11, _hoisted_6),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bet-list-item__debit']),
                            "data-test-key": _unref(TestKeysEnum).DEBIT
                        }, _toDisplayString(_unref(debitProperties)), 11, _hoisted_7)
                    ]),
                footerRight: _withCtx(()=>[
                        _ctx.item.cashoutAmount ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['bet-list-item__cash-out']),
                            "data-test-key": _unref(TestKeysEnum).CASH_OUT_AVAILABLE,
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>emit('cash-out', _ctx.item), [
                                "stop"
                            ]))
                        }, [
                            _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconProperties))), null, 16)
                        ], 10, _hoisted_11)) : _unref(isCalculated) ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass({
                                [_ctx.$style['bet-list-item__credit']]: true,
                                [_ctx.$style['bet-list-item__credit--positive']]: _ctx.item.credit > 0
                            }),
                            "data-test-key": _unref(TestKeysEnum).CREDIT
                        }, _toDisplayString(_unref(creditValue)), 11, _hoisted_12)) : _createCommentVNode("", true)
                    ]),
                _: 2
            }, [
                _unref(isCombi) ? void 0 : {
                    name: "footerLeft",
                    fn: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['bet-list-item__prediction'])
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['bet-list-item__prediction-label']),
                                    "data-test-key": _unref(TestKeysEnum).MARKET_NAME
                                }, _toDisplayString(_unref(predictionLabel)), 11, _hoisted_8),
                                _unref(predictionChoice) ? (_openBlock(), _createElementBlock("span", _hoisted_9, " - ")) : _createCommentVNode("", true),
                                _unref(predictionChoice) ? (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['bet-list-item__prediction-choice']),
                                    "data-test-key": _unref(TestKeysEnum).RUNNER_NAME
                                }, _toDisplayString(_unref(predictionChoice)), 11, _hoisted_10)) : _createCommentVNode("", true)
                            ], 2)
                        ]),
                    key: "0"
                }
            ]), 1024)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListItemBet'
                ]
            ]);
        };
    }
});
