import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import useSyncState from 'web/src/modules/core/store/composables/useSyncState';
export function useComponentSyncState(callback, timerName, options) {
    const { timeout, pauseSyncState, resumeSyncState } = useSyncState(callback, timerName, options);
    onComponentActivated(resumeSyncState);
    onComponentDeactivated(pauseSyncState);
    return {
        timeout
    };
}
