import { computed } from 'vue';
import { SportlineType } from 'web/src/modules/sportline/enums';
export function createMatchStreamAccessRef(isStreamsEnabled, rawCustomerMatchStreamEvents) {
    return computed(()=>isStreamsEnabled.value ? rawCustomerMatchStreamEvents.value.map((param)=>{
            let { eventId, isPreview, isAvailableForPrematch } = param;
            return {
                eventId: String(eventId),
                sportlineTypes: [
                    SportlineType.Live
                ],
                // @see LEONWEB-14247 Previews are available only for guests and only for RU version (The env flag is used for this purpose)
                isPreview: false,
                isUpcoming: !!isAvailableForPrematch
            };
        }) : []);
}
