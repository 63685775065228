import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
import { VLoaderDelayed } from 'web/src/components/Loader';
import CasinoSearchSection from 'web/src/modules/search/submodules/casino/components/CasinoSearchSection/CasinoSearchSection.vue';
import CasinoSearchLobby from 'web/src/modules/search/submodules/casino/views/CasinoSearchLobby/CasinoSearchLobby.vue';
import { useCasinoSearchContent } from 'web/src/modules/search/submodules/casino/views/CasinoSearchContent/composables';
import SearchNoResultsPlaceholder from 'web/src/modules/search/components/SearchNoResultsPlaceholder/SearchNoResultsPlaceholder.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSearchContent',
    props: {
        shouldReplaceItemRoutes: {
            type: Boolean
        }
    },
    emits: [
        "focus",
        "search-text",
        "item-click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isNoSearchResults, noResultsText, isLoading, hasSearchResults, searchText, games, groups, isMoreLoadingAvailable, replaceGameRoute, onGameClick, onLoadMore, onLobbyGameClicked, onInputClear, onInput } = useCasinoSearchContent(props, emits);
        __expose({
            onInputClear,
            onInput
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(isNoSearchResults) ? (_openBlock(), _createBlock(SearchNoResultsPlaceholder, {
                    key: 0,
                    text: _unref(noResultsText)
                }, null, 8, [
                    "text"
                ])) : _createCommentVNode("", true),
                _unref(isLoading) ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['search__loader'])
                }, [
                    _createVNode(_unref(VLoaderDelayed))
                ], 2)) : _createCommentVNode("", true),
                _unref(hasSearchResults) ? (_openBlock(), _createBlock(CasinoSearchSection, {
                    key: 2,
                    "search-text": _unref(searchText),
                    games: _unref(games),
                    groups: _unref(groups),
                    "is-load-more-available": _unref(isMoreLoadingAvailable),
                    onItemClick: _unref(onGameClick),
                    onLoadMore: _unref(onLoadMore)
                }, null, 8, [
                    "search-text",
                    "games",
                    "groups",
                    "is-load-more-available",
                    "onItemClick",
                    "onLoadMore"
                ])) : _createCommentVNode("", true),
                _withDirectives(_createVNode(CasinoSearchLobby, {
                    "should-replace-game-routes": _unref(replaceGameRoute),
                    onGameClick: _unref(onLobbyGameClicked)
                }, null, 8, [
                    "should-replace-game-routes",
                    "onGameClick"
                ]), [
                    [
                        _vShow,
                        _unref(isNoSearchResults)
                    ]
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CasinoSearchContent'
                ]
            ]);
        };
    }
});
