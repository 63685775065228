import { computed, ref } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useWindowEvent } from '@leon-hub/browser-composables';
import { PinButtonEvent } from 'web/src/modules/pin-code/enums';
import { pinButtonsProps, availableKeys } from '../../../utils/utils';
export default function usePinButtons(props, emits) {
    const activeButton = ref('');
    const pinButtonsList = computed(()=>{
        if (props.pinButtonsListInitial?.length) return props.pinButtonsListInitial;
        if (!props.visible) return pinButtonsProps;
        return pinButtonsProps.map((button)=>{
            if (button.value === PinButtonEvent.VISIBILITY) return {
                ...button,
                iconName: IconName.VISIBILITY_ON,
                isActive: false
            };
            return button;
        });
    });
    function onKeyUp(e) {
        const { key } = e;
        if (availableKeys.includes(key)) activeButton.value = '';
    }
    function onKeyDown(e) {
        const { key } = e;
        if (availableKeys.includes(key)) {
            activeButton.value = key.toUpperCase();
            emits('click', key.toUpperCase());
        }
    }
    "1";
    useWindowEvent('keyup', onKeyUp);
    useWindowEvent('keydown', onKeyDown);
    return {
        activeButton,
        pinButtonsList,
        onKeyDown,
        onKeyUp
    };
}
