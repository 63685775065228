import { isNumber } from '@leon-hub/guards';
export default function handleNumberPaste(event) {
    let currentValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '', fractionDigits = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0, symbolLimitation = arguments.length > 3 ? arguments[3] : void 0;
    const { target, clipboardData } = event;
    HTMLInputElement;
    const { selectionStart, selectionEnd } = target;
    isNumber(selectionStart);
    isNumber(selectionEnd);
    const valueBefore = currentValue.slice(0, selectionStart);
    const valueAfter = currentValue.slice(selectionEnd);
    const data = clipboardData?.getData('text') || '';
    const safeSymbolsFromBuffer = data.replaceAll(',', '.').replaceAll(/[^\d.]/gm, '');
    let combined = [
        valueBefore,
        safeSymbolsFromBuffer,
        valueAfter
    ].join('').trim().replace(/^[0.]+/, (match)=>match.endsWith('.') ? '.' : '');
    if (0 === fractionDigits) return combined.replaceAll('.', '').slice(0, symbolLimitation);
    if (combined.startsWith('.')) combined = `0${combined}`;
    const [whole, ...fraction] = combined.split('.');
    const formattedFraction = fraction.join('').replaceAll('.', '').slice(0, fractionDigits);
    const head = !whole && formattedFraction ? '0' : whole;
    const tail = formattedFraction ? `.${formattedFraction}` : '';
    return [
        head,
        tail
    ].join('').slice(0, symbolLimitation);
}
