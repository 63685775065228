import { InputEventType } from 'web/src/components/Input/enums';
export default function useCheckboxEvents(emit) {
    const onChange = (event)=>{
        emit(InputEventType.CHANGE, event);
    };
    const onInput = (event)=>{
        emit(InputEventType.INPUT, event);
    };
    const onFocus = (event)=>{
        emit(InputEventType.FOCUS, event);
    };
    const onBlur = (event)=>{
        emit(InputEventType.BLUR, event);
    };
    return {
        onInput,
        onChange,
        onFocus,
        onBlur
    };
}
