import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
import { ButtonKind } from '@leon-hub/module-buttons';
import PaymentsInput from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/PaymentsInput.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { StepName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingEditStep',
    props: {
        description: {},
        balance: {},
        minAmount: {},
        loading: {
            type: Boolean
        },
        placeholder: {}
    },
    emits: [
        "submit",
        "step-change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const formatMoney = useFormatMoney();
        const { currency } = useCurrency();
        const result = ref(String(props.minAmount));
        const handleSubmit = ()=>{
            emits('submit', result.value);
        };
        const handleBack = ()=>{
            emits('step-change', StepName.INFO);
        };
        const handleChange = (e)=>{
            result.value = e.target.value;
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(_ctx.$style['rg-bet-limit-edit'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['rg-bet-limit-edit__info'])
                }, _toDisplayString(_ctx.description), 3),
                _createElementVNode("form", {
                    onSubmit: _withModifiers(handleSubmit, [
                        "stop",
                        "prevent"
                    ])
                }, [
                    _createVNode(PaymentsInput, {
                        currency: _unref(currency),
                        balance: _ctx.balance,
                        bonus: false,
                        "allow-input-suggested-amounts-only": false,
                        "suggested-amounts": [],
                        "min-amount": _ctx.minAmount,
                        disabled: _ctx.loading,
                        placeholder: _ctx.placeholder,
                        "hint-right": _ctx.$t('WEB2_RG_MIN_AMOUNT', {
                            count: _unref(formatMoney)(_ctx.minAmount || 0)
                        }),
                        deposit: "",
                        onChange: handleChange
                    }, null, 8, [
                        "currency",
                        "balance",
                        "min-amount",
                        "disabled",
                        "placeholder",
                        "hint-right"
                    ])
                ], 32),
                (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['rg-bet-limit-edit__buttons'])
                }, [
                    _createVNode(VButton, {
                        "full-width": "",
                        disabled: Number(result.value) < _ctx.minAmount,
                        "is-loading": _ctx.loading,
                        onClick: handleSubmit
                    }, {
                        default: _withCtx(()=>[
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RG_BUTTON_SET')), 1)
                            ]),
                        _: 1
                    }, 8, [
                        "disabled",
                        "is-loading"
                    ]),
                    _createVNode(VButton, {
                        "full-width": "",
                        kind: _unref(ButtonKind).TRANSPARENT,
                        disabled: _ctx.loading,
                        onClick: handleBack
                    }, {
                        default: _withCtx(()=>[
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RG_BUTTON_CANCEL')), 1)
                            ]),
                        _: 1
                    }, 8, [
                        "kind",
                        "disabled"
                    ])
                ], 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingEditStep'
                ]
            ]);
        };
    }
});
