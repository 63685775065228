import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onMounted } from 'vue';
import { useVReCaptcha } from 'web/src/modules/captcha/components/VReCaptcha/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VReCaptcha',
    props: {
        siteKey: {
            default: ''
        },
        theme: {},
        badge: {},
        type: {},
        tabindex: {},
        apiUrl: {},
        language: {
            default: ''
        },
        autoExecute: {
            type: Boolean
        }
    },
    emits: [
        "verify",
        "expired",
        "error",
        "render",
        "challenge-is-closed",
        "challenge-is-opened"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { root, execute, reset, onComponentCreated, onComponentMounted } = useVReCaptcha(props, emits);
        onMounted(()=>{
            onComponentCreated();
            onComponentMounted();
        });
        __expose({
            reset,
            execute
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "root",
                ref: root,
                class: _normalizeClass(_ctx.$style['the-re-captcha'])
            }, null, 2)), [
                [
                    _directive_auto_id,
                    'VReCaptcha'
                ]
            ]);
        };
    }
});
