import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useEgsJackpotAmount } from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAmount/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsJackpotAmount',
    props: {
        currency: {},
        value: {},
        hasBackground: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { currencySymbol, priceItems } = useEgsJackpotAmount(props);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['egs-jackpot-amount']]: true,
                    [_ctx.$style['egs-jackpot-amount--background']]: _ctx.hasBackground
                })
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['egs-jackpot-amount__currency'])
                }, _toDisplayString(_unref(currencySymbol)), 3),
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(_ctx.$style['egs-jackpot-amount__value-list'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(priceItems), (item, index)=>(_openBlock(), _createElementBlock("span", {
                            key: index,
                            class: _normalizeClass('.' === item ? _ctx.$style['egs-jackpot-amount__dot'] : _ctx.$style['egs-jackpot-amount__item-value'])
                        }, _toDisplayString(item), 3))), 128))
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'egs-jackpot-amount-value-list'
                        }
                    ]
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'EgsJackpotAmount'
                ]
            ]);
        };
    }
});
