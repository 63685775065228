import { defineComponent, h, computed, inject, watch } from 'vue';
import { Tag } from '@leon-hub/tags';
import { IconName, IconSize } from '@leon-hub/icons';
import { Color } from '@leon-hub/colors';
import { VIcon } from '@components/v-icon';
import VAccordion from 'web/src/components/Accordion/VAccordion/VAccordion.vue';
import VAccordionItem from 'web/src/components/Accordion/VAccordionItem/VAccordionItem.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VDynamicContentLink from 'web/src/components/DynamicContent/VDynamicContentLink/VDynamicContentLink.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VListItemExtended from 'web/src/components/List/VListItemExtended/VListItemExtended.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import TextSlotPlaceholder from 'web/src/components/TextSlotPlaceholder/components/TextSlotPlaceholder.vue';
import SubHeader from 'web/src/components/SubHeader/VSubHeader/VSubHeader.vue';
import VCard from 'web/src/components/Card/VCard/VCard.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import { replaceContentTags } from 'web/src/utils/html';
import BonusLoyaltyProgramBalance from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';
// eslint-disable-next-line vue/one-component-per-file
export default defineComponent({
    name: 'VDynamicContent',
    props: {
        tag: {
            type: String,
            default: Tag.DIV
        },
        content: {
            type: String,
            default: ''
        },
        isCmsContent: {
            type: Boolean
        },
        noMargins: {
            type: Boolean
        },
        skipReplaceLinks: {
            type: Boolean
        }
    },
    emits: [
        'open-info',
        'open-faq',
        'click-link',
        'close',
        'loyalty-block-click',
        'click',
        'restore-password-phone-check-go-to-email',
        'registration-phone-check-go-to-email-registration',
        'registration-phone-check-go-to-phone-check-by-sms',
        'restore-password-phone-check-go-to-phone-check-by-sms'
    ],
    setup (props, param) {
        let { attrs, emit } = param;
        const cmsData = inject('cmsData', {});
        const getMetaData = inject('getMetaData', ()=>{});
        const replacedLinksContent = computed(()=>props.skipReplaceLinks ? props.content : replaceContentTags(props.content || '', 'a', 'VDynamicContentLink', {
                'is-cms-content': props.isCmsContent
            }));
        const baseComponentOptions = {
            components: {
                // eslint-disable-next-line vue/no-reserved-component-names
                Button: VButton,
                VButton,
                VIcon,
                VAccordion,
                VAccordionItem,
                VImage,
                VDynamicContentLink,
                List: VList,
                ListItem: VListItem,
                ListItemExtended: VListItemExtended,
                ListItemAccordion: VListItemAccordion,
                VList,
                VListItem,
                VListItemExtended,
                VListItemAccordion,
                TextSlotPlaceholder,
                SubHeader,
                VCard,
                BonusLoyaltyProgramBalance,
                Brand: VBrand,
                VBrand,
                VHintBlock,
                VAnimationStarter
            },
            data: ()=>({
                    cmsData: Object.freeze(cmsData),
                    IconName: Object.freeze(IconName),
                    Color: Object.freeze(Color),
                    IconSize: Object.freeze(IconSize),
                    ButtonKind: Object.freeze(ButtonKind),
                    Tag: Object.freeze(Tag)
                }),
            computed: {
                metaData: ()=>getMetaData()
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        let newComponent = 'div';
        watch(replacedLinksContent, (newValue)=>{
            // eslint-disable-next-line vue/one-component-per-file
            newComponent = defineComponent({
                ...baseComponentOptions,
                template: `<${props.tag} :class="{'no-margins': ${props.noMargins}}">${newValue}</${props.tag}>`
            });
        }, {
            immediate: true
        });
        return ()=>h(newComponent, {
                ...attrs,
                onOpenInfo (val) {
                    emit('open-info', val);
                },
                onOpenFaq () {
                    emit('open-faq');
                },
                onClickLink ($event) {
                    emit('click-link', $event);
                },
                onClose () {
                    emit('close');
                },
                onLoyaltyBlockClick ($event) {
                    emit('loyalty-block-click', $event);
                },
                onClick () {
                    emit('click');
                },
                onRestorePasswordPhoneCheckGoToEmail () {
                    emit('restore-password-phone-check-go-to-email');
                },
                onRegistrationPhoneCheckGoToEmailRegistration () {
                    emit('registration-phone-check-go-to-email-registration');
                },
                onRegistrationPhoneCheckGoToPhoneCheckBySms () {
                    emit('registration-phone-check-go-to-phone-check-by-sms');
                },
                onRestorePasswordPhoneCheckGoToPhoneCheckBySms () {
                    emit('restore-password-phone-check-go-to-phone-check-by-sms');
                }
            });
    }
});
