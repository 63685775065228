import { getDateTimeRangeForFilter } from './getDateTimeRangeForFilter';
import { getLeagueElementKeyForFilter } from './getLeagueElementKeyForFilter';
function isSportEventElementFitsTheFilter(sportEventElement, filter) {
    const { sportlineType } = filter;
    const { type, kickoff } = sportEventElement.sportEvent;
    if (type === sportlineType) return true;
    const dateTimeRange = getDateTimeRangeForFilter(filter);
    if (!dateTimeRange?.from || !dateTimeRange?.to) return false;
    return kickoff >= dateTimeRange.from && kickoff <= dateTimeRange.to;
}
function findFilterKeyForSportEventElement(sportEventElement, filtersMap) {
    return Object.keys(filtersMap).find((key)=>isSportEventElementFitsTheFilter(sportEventElement, filtersMap[key]));
}
export function getFiltersMap(baseKey, filters) {
    return filters.reduce((result, filter)=>({
            ...result,
            [getLeagueElementKeyForFilter(baseKey, filter)]: filter
        }), {});
}
export function splitSportEventsListByFilters(list, baseKey, filters) {
    const filtersMap = getFiltersMap(baseKey, filters);
    const elementsMap = Object.keys(filtersMap).reduce((result, key)=>({
            ...result,
            [key]: []
        }), {
        [baseKey]: []
    });
    return list.reduce((result, sportEventElement)=>{
        const actualFilter = findFilterKeyForSportEventElement(sportEventElement, filtersMap);
        result[actualFilter ?? baseKey].push(sportEventElement);
        return result;
    }, elementsMap);
}
