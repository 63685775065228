import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import ThePaymentsCreditCardSelector from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelector/ThePaymentsCreditCardSelector.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CreditCardSelector',
    props: {
        title: {},
        hintMessage: {},
        disabled: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        name: {},
        extraProperties: {},
        value: {
            type: [
                String,
                Boolean,
                Number,
                Array,
                Object
            ]
        },
        options: {},
        error: {}
    },
    emits: [
        "input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const emitChange = (id)=>{
            emit('input', {
                name: props.name,
                value: id
            });
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ThePaymentsCreditCardSelector, {
                "selected-credit-card-token": _ctx.value,
                "credit-cards": _ctx.options?.creditCards ?? [],
                "allow-new-card": _ctx.options?.allowNewCard ?? false,
                onInput: emitChange
            }, null, 8, [
                "selected-credit-card-token",
                "credit-cards",
                "allow-new-card"
            ])), [
                [
                    _directive_auto_id,
                    'CreditCardSelector'
                ]
            ]);
        };
    }
});
