import { toNumber } from 'vanilla-masker';
function getCounterForRemove(positionStart, positionEnd, hasData) {
    const selectionDiff = positionEnd - positionStart;
    // if we have selection then remove that selection
    if (selectionDiff) return selectionDiff;
    // remove nothing, we are going only add elements
    if (hasData) return 0;
    // if we have no data than remove 1
    return 1;
}
function patternPositionToRawPosition(pattern, position) {
    const placeholderCount = pattern.reduce((result, char, index)=>{
        if (index >= position) return result;
        return '9' !== char ? result + 1 : result;
    }, 0);
    return position - placeholderCount;
}
function getMaxRawLength(pattern) {
    return pattern.filter((char)=>'9' === char).length;
}
function createPatternIndexes(pattern) {
    let currentIndex = 0;
    return pattern.reduce((result, char, index)=>{
        if ('9' !== char) return result;
        // eslint-disable-next-line no-param-reassign
        result[currentIndex] = index + 1;
        currentIndex += 1;
        return result;
    }, {});
}
export default function createNewMaskedValue(input) {
    const { oldValue, positionStart, positionEnd, pattern, data } = input;
    const rawValue = toNumber(oldValue);
    const patternList = [
        ...pattern
    ];
    const newRawValue = [
        ...rawValue
    ];
    const hasSelection = positionStart !== positionEnd;
    const rawIndexPositionStart = patternPositionToRawPosition(patternList, positionStart);
    const rawIndexPositionEnd = hasSelection ? patternPositionToRawPosition(patternList, positionEnd) : rawIndexPositionStart;
    const counterRemove = getCounterForRemove(rawIndexPositionStart, rawIndexPositionEnd, null !== data);
    if (counterRemove) newRawValue.splice(rawIndexPositionStart, counterRemove);
    if (null === data) return {
        position: positionStart,
        value: newRawValue.join('')
    };
    const filteredData = toNumber(data);
    // no value
    if (!filteredData) return {
        position: positionStart,
        value: newRawValue.join('')
    };
    const maxRawLength = getMaxRawLength(patternList);
    const maxIndexPosition = maxRawLength - 1;
    const patternIndexes = createPatternIndexes(patternList);
    if (rawIndexPositionStart > maxIndexPosition) return {
        position: patternIndexes[newRawValue.length - 1],
        value: newRawValue.join('')
    };
    newRawValue.splice(rawIndexPositionStart, 0, filteredData);
    if (newRawValue.length > maxRawLength) newRawValue.splice(maxRawLength);
    // rawIndexPositionStart includes position after value added (offset 1)
    // that is why when we add offset which is equal length of adding value we need to minus 1 symbol
    const positionIndex = rawIndexPositionStart + filteredData.length - 1;
    return {
        position: patternIndexes[positionIndex],
        value: newRawValue.join('')
    };
}
