import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { MenuLevel } from 'web/src/components/SidebarMenu/types';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import SidebarCaption from 'web/src/components/SidebarMenu/SidebarCaption/SidebarCaption.vue';
import { useSportsSidebarTopLeagues } from './useSportsSidebarTopLeagues';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportsSidebarTopLeagues',
    props: {
        defaultActiveSportId: {},
        sports: {},
        favoritesSports: {},
        maxTabsCount: {},
        maxInList: {}
    },
    emits: [
        "level-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sports = toRef(props, 'sports', []);
        const favoritesSports = toRef(props, 'favoritesSports', []);
        const maxTabsCount = toRef(props, 'maxTabsCount', 6);
        const maxInList = toRef(props, 'maxInList', 5);
        const defaultActiveSportId = toRef(props, 'defaultActiveSportId', null);
        const { tabs, isAvailable, activeTabId, activeFilterTabLeaguesList, activeFilterTabLeaguesListSize, activeFilterTabAllLeaguesPageLocation, allLeaguesTitle, activeFilterTabTotalCount, onActivateTabClick } = useSportsSidebarTopLeagues({
            sports,
            favoritesSports,
            maxTabsCount,
            maxInList,
            defaultActiveSportId
        });
        function emitMenuLevelClick(event, payload) {
            emit('level-click', {
                event,
                payload
            });
        }
        function emitMenuLevelZeroClick(event) {
            emit('level-click', {
                event
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isAvailable) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['sports-sidebar-top-leagues'])
            }, [
                _createVNode(SidebarCaption, {
                    title: _ctx.$t('WEB2_TOP_LEAGUES')
                }, null, 8, [
                    "title"
                ]),
                _createVNode(VTabs, {
                    class: _normalizeClass(_ctx.$style['sports-sidebar-top-leagues__tabs']),
                    type: _unref(TabsType).SMALL,
                    items: _unref(tabs),
                    "active-id": _unref(activeTabId),
                    "is-bordered": "",
                    onTabClick: _unref(onActivateTabClick)
                }, null, 8, [
                    "class",
                    "type",
                    "items",
                    "active-id",
                    "onTabClick"
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(activeFilterTabLeaguesList), (topLeagueElement)=>(_openBlock(), _createElementBlock("div", {
                        key: topLeagueElement.league.id,
                        class: _normalizeClass(_ctx.$style['sports-sidebar-top-leagues__league-list'])
                    }, [
                        _createVNode(VRouterLink, {
                            to: topLeagueElement.pageLocation,
                            class: _normalizeClass(_ctx.$style['sports-sidebar-top-leagues__league']),
                            onClick: ($event)=>emitMenuLevelClick($event, {
                                    level: _unref(MenuLevel).FREE,
                                    id: topLeagueElement.league.id
                                })
                        }, {
                            default: _withCtx(()=>[
                                    topLeagueElement.region ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['sports-sidebar-top-leagues__league-region'])
                                    }, _toDisplayString(topLeagueElement.region.name), 3)) : _createCommentVNode("", true),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['sports-sidebar-top-leagues__league-title'])
                                    }, _toDisplayString(topLeagueElement.league.name), 3)
                                ]),
                            _: 2
                        }, 1032, [
                            "to",
                            "class",
                            "onClick"
                        ])
                    ], 2))), 128)),
                _unref(activeFilterTabLeaguesListSize) > maxInList.value ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([
                        _ctx.$style['sports-sidebar-top-leagues__all'],
                        _ctx.$style['sports-level-1-spoiler']
                    ])
                }, [
                    _createVNode(VRouterLink, {
                        class: _normalizeClass([
                            _ctx.$style['sports-sidebar-top-leagues__all-header'],
                            _ctx.$style['sports-level-1-spoiler__header']
                        ]),
                        to: _unref(activeFilterTabAllLeaguesPageLocation) ?? '',
                        onClick: _cache[0] || (_cache[0] = ($event)=>emitMenuLevelZeroClick($event))
                    }, {
                        default: _withCtx(()=>[
                                _createElementVNode("span", {
                                    class: _normalizeClass([
                                        _ctx.$style['sports-sidebar-top-leagues__all-title'],
                                        _ctx.$style['sports-level-1-spoiler__title']
                                    ])
                                }, _toDisplayString(_unref(allLeaguesTitle)), 3),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['sports-level-1-spoiler__indicator'])
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['sports-sidebar-top-leagues__all-counter'])
                                    }, _toDisplayString(_unref(activeFilterTabTotalCount)), 3),
                                    _createVNode(_unref(VIcon), {
                                        size: _unref(IconSize).SIZE_16,
                                        name: _unref(IconName).EXPAND_RIGHT
                                    }, null, 8, [
                                        "size",
                                        "name"
                                    ])
                                ], 2)
                            ]),
                        _: 1
                    }, 8, [
                        "class",
                        "to"
                    ])
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportsSidebarTopLeagues'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
