import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 1
};
import { computed, ref, toRef } from 'vue';
import LeagueHeadline from 'web/src/modules/sportline/views/headline/LeagueHeadline.vue';
import SportlineEventBlock from 'web/src/modules/sportline/views/EventBlock/SportlineEventBlock.vue';
import { useExpandableSportlineBlock } from 'web/src/modules/sportline/composables/list';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineLeagueBlock',
    props: {
        sport: {},
        region: {},
        leagueElement: {},
        upcomingLabelType: {},
        isHideLeagueInfo: {
            type: Boolean
        },
        isColumnNamesDisplayDisabled: {
            type: Boolean
        },
        isShowAddLeagueToFavorite: {
            type: Boolean
        },
        isDisplayMainEventsDisabled: {
            type: Boolean
        },
        isDisplayOutrightEventsEnabled: {
            type: Boolean
        },
        isDisplayOutrightHeadlineDisabled: {
            type: Boolean
        },
        canAddToFavorite: {
            type: Boolean
        },
        canAddOutrightsToFavorite: {
            type: Boolean
        },
        isExtendedEventViewDisabled: {
            type: Boolean
        },
        activeEventId: {},
        isFilterActive: {
            type: Boolean
        },
        expandKey: {},
        isDefaultExpanded: {
            type: Boolean
        },
        extendedBlockType: {},
        basisKey: {}
    },
    emits: [
        "click-event"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const content = ref();
        const leagueElement = toRef(props, 'leagueElement');
        const isDisplayMainEventsDisabled = toRef(props, 'isDisplayMainEventsDisabled');
        const isColumnNamesDisplayDisabled = toRef(props, 'isColumnNamesDisplayDisabled');
        const isDisplayOutrightEventsEnabled = toRef(props, 'isDisplayOutrightEventsEnabled');
        const basisKey = toRef(props, 'basisKey');
        const leagueEventsCount = computed(()=>{
            if (isDisplayMainEventsDisabled.value) return leagueElement.value.counters.outright;
            if (!isDisplayOutrightEventsEnabled.value) return leagueElement.value.counters.live + leagueElement.value.counters.prematch;
            return leagueElement.value.counters.total;
        });
        const isHeadlineColumnNamesDisplayDisabled = computed(()=>isDisplayMainEventsDisabled.value || isColumnNamesDisplayDisabled.value || 0 === leagueElement.value.sportEvents.length);
        const { isExpanded, isExpandable, expandAnimationActive, expandAnimationDuration, toggleExpandState } = useExpandableSportlineBlock({
            content,
            expandKey: toRef(props, 'expandKey', null),
            isDefaultExpanded: toRef(props, 'isDefaultExpanded')
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['league-element-inner__holder'])
            }, [
                _ctx.isHideLeagueInfo ? _createCommentVNode("", true) : (_openBlock(), _createBlock(LeagueHeadline, {
                    key: leagueElement.value.key,
                    class: _normalizeClass(_ctx.$style['league-headline']),
                    sport: _ctx.sport,
                    region: _ctx.region,
                    "league-element": leagueElement.value,
                    "is-show-add-league-to-favorite": _ctx.isShowAddLeagueToFavorite,
                    "is-column-names-display-disabled": isHeadlineColumnNamesDisplayDisabled.value,
                    "expand-key": _ctx.expandKey,
                    "is-expanded": _unref(expandAnimationActive) ? !_unref(isExpanded) : _unref(isExpanded),
                    "is-filter-active": _ctx.isFilterActive,
                    "count-events": leagueEventsCount.value,
                    "basis-key": basisKey.value,
                    onToggleExpandState: _unref(toggleExpandState)
                }, null, 8, [
                    "class",
                    "sport",
                    "region",
                    "league-element",
                    "is-show-add-league-to-favorite",
                    "is-column-names-display-disabled",
                    "expand-key",
                    "is-expanded",
                    "is-filter-active",
                    "count-events",
                    "basis-key",
                    "onToggleExpandState"
                ])),
                _createElementVNode("div", {
                    ref_key: "content",
                    ref: content,
                    class: _normalizeClass({
                        [_ctx.$style['expandable-element-content']]: true,
                        [_ctx.$style['expandable-element-content--hidden']]: _unref(isExpandable) && !_unref(isExpanded)
                    }),
                    style: _normalizeStyle({
                        'transition-duration': `${_unref(expandAnimationDuration)}ms`
                    })
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sportline-events-list'])
                    }, [
                        isDisplayMainEventsDisabled.value ? _ctx.isHideLeagueInfo ? _createCommentVNode("", true) : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'league-block-outright-headline-holder'
                                }
                            ]
                        ]) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _ctx.isHideLeagueInfo ? _createCommentVNode("", true) : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'league-block-headline-holder'
                                    }
                                ]
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(leagueElement.value.sportEvents, (sportEventElement)=>(_openBlock(), _createBlock(SportlineEventBlock, {
                                    key: sportEventElement.sportEvent.id,
                                    sport: _ctx.sport,
                                    "sport-event-element": sportEventElement,
                                    "upcoming-label-type": _ctx.upcomingLabelType,
                                    "can-add-to-favorite": _ctx.canAddToFavorite,
                                    "is-extended-event-view-disabled": _ctx.isExtendedEventViewDisabled,
                                    "active-event-id": _ctx.activeEventId,
                                    "extended-block-type": _ctx.extendedBlockType,
                                    "basis-key": basisKey.value,
                                    onClick: _cache[0] || (_cache[0] = ($event)=>emit('click-event'))
                                }, null, 8, [
                                    "sport",
                                    "sport-event-element",
                                    "upcoming-label-type",
                                    "can-add-to-favorite",
                                    "is-extended-event-view-disabled",
                                    "active-event-id",
                                    "extended-block-type",
                                    "basis-key"
                                ]))), 128))
                        ], 64)),
                        isDisplayOutrightEventsEnabled.value && leagueElement.value.outrightEvents.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 2
                        }, [
                            _ctx.isDisplayOutrightHeadlineDisabled ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['league-element-inner__outrights-title'])
                            }, _toDisplayString(_ctx.$t('WEB2_LEAGUE_OUTRIGHT_WINNERS')), 3)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(leagueElement.value.outrightEvents, (outrightEvent)=>(_openBlock(), _createBlock(SportlineEventBlock, {
                                    key: outrightEvent.sportEvent.id,
                                    sport: _ctx.sport,
                                    "sport-event-element": outrightEvent,
                                    "upcoming-label-type": _ctx.upcomingLabelType,
                                    "can-add-to-favorite": _ctx.canAddOutrightsToFavorite,
                                    "active-event-id": _ctx.activeEventId,
                                    "extended-block-type": _ctx.extendedBlockType,
                                    "basis-key": basisKey.value,
                                    onClick: _cache[1] || (_cache[1] = ($event)=>emit('click-event'))
                                }, null, 8, [
                                    "sport",
                                    "sport-event-element",
                                    "upcoming-label-type",
                                    "can-add-to-favorite",
                                    "active-event-id",
                                    "extended-block-type",
                                    "basis-key"
                                ]))), 128))
                        ], 64)) : _createCommentVNode("", true)
                    ], 2)
                ], 6)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineLeagueBlock'
                ]
            ]);
        };
    }
});
