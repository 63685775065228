import RouteName from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useRootStore } from 'web/src/modules/core/store';
let RedirectToHomeIfDirectLinkPrefetch = class RedirectToHomeIfDirectLinkPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        if (useRootStore().isDirectLink) {
            next({
                name: RouteName.HOME,
                replace: true
            });
            return Promise.resolve();
        }
        next();
        return Promise.resolve();
    }
};
export { RedirectToHomeIfDirectLinkPrefetch as default };
