import { ref, watch, nextTick, onMounted } from 'vue';
export default function useStakeInputLabel(currentValue, props) {
    const isLabelHidden = ref(false);
    const widthMeter = ref();
    const checkIsPlaceEnoughForLabel = ()=>{
        if (widthMeter.value) {
            const { offsetWidth, scrollWidth } = widthMeter.value;
            isLabelHidden.value = scrollWidth > offsetWidth;
        }
    };
    watch(()=>currentValue.value, ()=>{
        nextTick().then(checkIsPlaceEnoughForLabel);
    });
    onMounted(checkIsPlaceEnoughForLabel);
    watch(()=>props.betMode, (value)=>{
        if (value) checkIsPlaceEnoughForLabel();
    });
    return {
        widthMeter,
        isLabelHidden
    };
}
