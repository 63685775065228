import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useRulesStore } from 'web/src/modules/rules/store';
let RulesPrefetch = class RulesPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const rulesStore = useRulesStore();
        const navigation = toRef(rulesStore, 'navigation');
        if (0 === navigation.value.length) {
            await rulesStore.getRulesNavigation();
            if (0 === navigation.value.length) {
                next();
                return;
            }
        }
        const { levelOneId, levelTwoId, levelThreeId } = to.params;
        const validLevelOne = navigation.value.find((item)=>item.id === levelOneId);
        const validLevelTwo = validLevelOne?.children?.find((item)=>item.id === levelTwoId);
        const validLevelThree = validLevelTwo?.children?.find((item)=>item.id === levelThreeId);
        const isNotFound = levelOneId && !validLevelOne || levelTwoId && !validLevelTwo || levelThreeId && !validLevelThree;
        if (isNotFound) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        if (!levelOneId) {
            const defaultLevelOne = navigation.value[0];
            next({
                name: RouteName.RULES,
                params: {
                    levelOneId: defaultLevelOne.id
                },
                replace: true
            });
            return;
        }
        if (!levelTwoId) {
            const defaultLevelTwo = validLevelOne?.children?.[0];
            const defaultLevelThree = defaultLevelTwo?.children?.[0];
            next({
                name: RouteName.RULES,
                params: {
                    levelOneId,
                    levelTwoId: defaultLevelTwo.id,
                    levelThreeId: defaultLevelThree.id
                },
                replace: true
            });
            return;
        }
        if (!levelThreeId) {
            const defaultLevelThree = validLevelTwo?.children?.[0];
            next({
                name: RouteName.RULES,
                params: {
                    levelOneId,
                    levelTwoId,
                    levelThreeId: defaultLevelThree.id
                },
                replace: true
            });
            return;
        }
        next();
    }
};
export { RulesPrefetch as default };
