import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, isRef as _isRef, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 0,
    ref: "standings"
};
const _hoisted_2 = {
    class: "standings-team-promotion-item standings-team-promotion-item--header"
};
const _hoisted_3 = {
    class: "standings-team-promotion-item-cell"
};
const _hoisted_4 = {
    class: "standings-team-promotion-item__group-name"
};
const _hoisted_5 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-matches-total"
};
const _hoisted_6 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-win-total"
};
const _hoisted_7 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-draw-total"
};
const _hoisted_8 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-loss-total"
};
const _hoisted_9 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-goals-total"
};
const _hoisted_10 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-points-total"
};
const _hoisted_11 = {
    class: "standings-team-promotion-item-cell"
};
const _hoisted_12 = {
    class: "standings-team-promotion-team-info"
};
const _hoisted_13 = {
    class: "standings-team-promotion-team-info__number"
};
const _hoisted_14 = {
    class: "standings-team-promotion-team-info__name"
};
const _hoisted_15 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-matches-total"
};
const _hoisted_16 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-win-total"
};
const _hoisted_17 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-draw-total"
};
const _hoisted_18 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-loss-total"
};
const _hoisted_19 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-goals-total"
};
const _hoisted_20 = {
    class: "standings-team-promotion-item-cell standings-team-promotion-team-points-total"
};
const _hoisted_21 = {
    colspan: "7",
    class: "statistic-league-standings-group-divider"
};
const _hoisted_22 = {
    key: 0,
    class: "standings-team-promotion-legend"
};
const _hoisted_23 = {
    class: "standings-team-promotion-legend-item__label"
};
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import vPopperHint from 'web/src/components/Popper/directives/popperHint';
import StatisticLeagueStandingsPromotion from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingsPromotion.vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import useStatisticLeagueStandings from './useStatisticLeagueStandings';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticLeagueStandings',
    props: {
        leagueStandings: {},
        teams: {},
        alwaysExpanded: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const leagueStandings = toRef(props, 'leagueStandings');
        const teams = toRef(props, 'teams', null);
        const alwaysExpanded = toRef(props, 'alwaysExpanded', false);
        const { isExpanded, isDisplayFullTable, teamsIds, tournament, promotions } = useStatisticLeagueStandings({
            leagueStandings,
            teams,
            alwaysExpanded
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(tournament) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tournament).seasons, (season)=>(_openBlock(), _createElementBlock("div", {
                        key: season.name ?? void 0
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(season.groups, (group)=>(_openBlock(), _createElementBlock("table", {
                                key: `${season.name}_${group.id}_group`,
                                class: "standings-team-promotions-group"
                            }, [
                                _createElementVNode("thead", null, [
                                    _createElementVNode("tr", _hoisted_2, [
                                        _createElementVNode("td", _hoisted_3, [
                                            _createElementVNode("div", _hoisted_4, _toDisplayString(group.name), 1)
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", _hoisted_5, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_GAMES_SHORT')), 1)
                                        ])), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_GAMES')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", _hoisted_6, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN_SHORT')), 1)
                                        ])), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", _hoisted_7, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW_SHORT')), 1)
                                        ])), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", _hoisted_8, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE_SHORT')), 1)
                                        ])), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", _hoisted_9, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_BALLS_DIFF_SHORT')), 1)
                                        ])), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_BALLS_DIFF')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", _hoisted_10, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_POINTS_SHORT')), 1)
                                        ])), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_POINTS')
                                            ]
                                        ])
                                    ])
                                ]),
                                _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.teams, (team, index)=>(_openBlock(), _createElementBlock(_Fragment, {
                                            key: `${season.name}_${group.id}_${team.position}_item`
                                        }, [
                                            _createElementVNode("tr", {
                                                class: _normalizeClass([
                                                    "standings-team-promotion-item",
                                                    {
                                                        'standings-team-promotion-item--highlighted': !!team.team?.id && _unref(teamsIds).has(team.team.id)
                                                    }
                                                ])
                                            }, [
                                                _createElementVNode("td", _hoisted_11, [
                                                    _createElementVNode("div", _hoisted_12, [
                                                        _createVNode(StatisticLeagueStandingsPromotion, {
                                                            class: "standings-team-promotion-team-info__indicator",
                                                            promotion: team.promotion
                                                        }, null, 8, [
                                                            "promotion"
                                                        ]),
                                                        _createElementVNode("div", _hoisted_13, _toDisplayString(team.position), 1),
                                                        team.team ? (_openBlock(), _createElementBlock(_Fragment, {
                                                            key: 0
                                                        }, [
                                                            _createVNode(StatisticTeamLogo, {
                                                                class: "standings-team-promotion-team-info__icon",
                                                                team: team.team
                                                            }, null, 8, [
                                                                "team"
                                                            ]),
                                                            _createElementVNode("div", _hoisted_14, _toDisplayString(team.team.name), 1)
                                                        ], 64)) : _createCommentVNode("", true)
                                                    ])
                                                ]),
                                                _createElementVNode("td", _hoisted_15, _toDisplayString(team.totals.matches), 1),
                                                _createElementVNode("td", _hoisted_16, _toDisplayString(team.totals.win), 1),
                                                _createElementVNode("td", _hoisted_17, _toDisplayString(team.totals.draw), 1),
                                                _createElementVNode("td", _hoisted_18, _toDisplayString(team.totals.loss), 1),
                                                _createElementVNode("td", _hoisted_19, _toDisplayString(team.totals.goals[0]) + "-" + _toDisplayString(team.totals.goals[1]), 1),
                                                _createElementVNode("td", _hoisted_20, _toDisplayString(team.totals.points), 1)
                                            ], 2),
                                            !_unref(isDisplayFullTable) && index + 1 < group.teams.length && group.teams[index + 1].position - team.position > 1 ? (_openBlock(), _createElementBlock("tr", {
                                                key: `${season.name}_${group.id}_${team.position}_divider`,
                                                class: "standings-team-promotion-item standings-team-promotion-item--divider"
                                            }, [
                                                _createElementVNode("td", _hoisted_21, [
                                                    _createVNode(_unref(VIcon), {
                                                        class: "statistic-league-standings-group-divider__icon",
                                                        name: _unref(IconName).MORE,
                                                        size: _unref(IconSize).SIZE_16
                                                    }, null, 8, [
                                                        "name",
                                                        "size"
                                                    ])
                                                ])
                                            ])) : _createCommentVNode("", true)
                                        ], 64))), 128))
                                ])
                            ]))), 128))
                    ]))), 128)),
                _unref(isDisplayFullTable) ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(promotions), (promotion)=>(_openBlock(), _createElementBlock("div", {
                            key: promotion.id ?? void 0,
                            class: "standings-team-promotion-legend-item"
                        }, [
                            _createVNode(StatisticLeagueStandingsPromotion, {
                                class: "statistic-league-standings-promotion-indicator",
                                promotion: promotion
                            }, null, 8, [
                                "promotion"
                            ]),
                            _createElementVNode("div", _hoisted_23, _toDisplayString(promotion.name), 1)
                        ]))), 128))
                ])) : _createCommentVNode("", true),
                alwaysExpanded.value ? _createCommentVNode("", true) : (_openBlock(), _createBlock(ExpandButton, {
                    key: 1,
                    "is-expanded": _unref(isExpanded),
                    "onUpdate:isExpanded": _cache[0] || (_cache[0] = ($event)=>_isRef(isExpanded) ? isExpanded.value = $event : null),
                    "opened-title": _ctx.$t('WEB2_CLOSE'),
                    "closed-title": _ctx.$t('WEB2_MATCH_STATISTIC_MORE_TOURNAMENT_TABLE'),
                    class: "standings-team-promotion-list-toggle"
                }, null, 8, [
                    "is-expanded",
                    "opened-title",
                    "closed-title"
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'StatisticLeagueStandings'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
