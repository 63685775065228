import { isString, isOptionalString, isNull, isUndefined, isObject } from '@leon-hub/guards';
function isRawLocation(value) {
    return isString(value) || isObject(value) && (isString(value.path) || isString(value.name));
}
export default function isHintLink(value) {
    if (!isObject(value)) return false;
    return isRawLocation(value.href) && isOptionalString(value.target) && isString(value.text);
}
export function isOptionalHintLinkOrNull(value) {
    return isNull(value) || isUndefined(value) || isHintLink(value);
}
