import { computed, ref } from 'vue';
import { isNumber } from '@leon-hub/guards';
import { AlertIconName } from '@leon-hub/icons';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
import createUniqueId from 'web/src/utils/createUniqueId';
import { InputEventType } from '../enums';
import isVInputFileEvent from '../guards/isVInputFileEvent';
import { getFileRecord } from './utils';
export default function useMultipleFileInput(props, emit) {
    const { showDialog } = useDialogs();
    const { $translate } = useI18n();
    const uniqueId = computed(()=>createUniqueId(props.name || ''));
    const filesRecords = ref([]);
    const currentFiles = computed(()=>filesRecords.value.map((item)=>item.record));
    const acceptedFileTypes = computed(()=>{
        const accepted = Object.keys(props.fileTypes ?? {}).map((type)=>`.${type}`).join(', ');
        return accepted.length ? accepted : void 0;
    });
    const updateFileRecords = (records)=>{
        filesRecords.value = records;
    };
    const getEventToEmit = (selectedIndex)=>({
            target: {
                files: currentFiles.value,
                selectedIndex,
                name: props.name || ''
            }
        });
    const emitChangeEvents = (selectedIndexes)=>{
        for (const selectedIndex of selectedIndexes)emit(InputEventType.CHANGE, getEventToEmit(selectedIndex));
    };
    const deleteFile = (index)=>{
        const file = filesRecords.value[index];
        if (!file) return;
        const changedIndexes = [];
        for(let fileIndex = filesRecords.value.length - 1; fileIndex >= index; fileIndex -= 1)changedIndexes.push(fileIndex);
        const filesRecordsNew = [
            ...filesRecords.value
        ];
        filesRecordsNew.splice(index, 1);
        updateFileRecords(filesRecordsNew);
        emitChangeEvents(changedIndexes);
    };
    const getOrDeleteFileRecord = (file, index)=>getFileRecord({
            file,
            fileTypes: props.fileTypes ?? {},
            errorCallback: ()=>deleteFile(index)
        });
    const onReplace = async (event, index)=>{
        isVInputFileEvent(event);
        const file = event.target.files ? event.target.files[0] : null;
        if (file && filesRecords.value[index]) {
            const fileRecordsNew = [
                ...filesRecords.value
            ];
            fileRecordsNew[index] = await getOrDeleteFileRecord(file, index);
            updateFileRecords(fileRecordsNew);
            emitChangeEvents([
                index
            ]);
        }
    };
    const max = computed(()=>isNumber(props.max) ? props.max : Number.POSITIVE_INFINITY);
    const addMoreAvailable = computed(()=>filesRecords.value.length < max.value);
    const handleFileList = async (files)=>{
        const fileList = [
            ...files
        ];
        const filesRecordsNew = [
            ...filesRecords.value
        ];
        const selectedIndexes = [];
        let limit = max.value - filesRecords.value.length;
        if (0 === limit) return;
        for await (const file of fileList){
            if (0 === limit) return;
            const index = filesRecordsNew.length;
            const newRecord = await getOrDeleteFileRecord(file, index);
            filesRecordsNew.push(newRecord);
            selectedIndexes.push(index);
            limit -= 1;
        }
        updateFileRecords(filesRecordsNew);
        emitChangeEvents(selectedIndexes);
    };
    const removeByIndex = (index)=>{
        const confirmMessage = $translate('WEB2_SIDENT_DELETE_FILE_CONFIRMATION').value;
        const buttonLabel = $translate('WEB2_IMAGE_DELETE').value;
        const { subscribe } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                confirmMessage,
                image: getImageOrIcon({
                    alertIcon: AlertIconName.QuestionMark
                }).image,
                buttons: [
                    {
                        label: buttonLabel
                    }
                ],
                width: ModalWidth.SMALL,
                dataTestId: 'file-input-remove-confirmation'
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                deleteFile(index);
            }
        });
    };
    const onChange = async (event)=>{
        const { target } = event;
        HTMLInputElement;
        const fileList = target.files ? [
            ...target.files
        ] : [];
        target.value = '';
        await handleFileList(fileList);
    };
    const onBlur = (selectedIndex)=>{
        emit(InputEventType.BLUR, getEventToEmit(selectedIndex));
    };
    const onFocus = (selectedIndex)=>{
        emit(InputEventType.FOCUS, getEventToEmit(selectedIndex));
    };
    return {
        uniqueId,
        filesRecords,
        addMoreAvailable,
        acceptedFileTypes,
        removeByIndex,
        onChange,
        onReplace,
        onBlur,
        onFocus,
        handleFileList
    };
}
