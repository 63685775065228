import { logger } from '@leon-hub/logging';
import { isArray } from '@leon-hub/guards';
import { filterCorruptedEvents, logCorruptedEventsIds } from 'web/src/modules/sportline/utils/response';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
export default async function searchEvents(input) {
    const apiClient = useSportlineApi();
    const response = await apiClient.searchEvents(input);
    try {
        if (!isArray(response)) {
            logger.error('searchEvents response assertion failed');
            return [];
        }
        return filterCorruptedEvents(response, logCorruptedEventsIds);
    } catch (error) {
        logger.error('apiClient getEvents response corrupted', error);
        // always return response for user
        return [];
    }
}
