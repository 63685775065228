import { useRouter } from 'vue-router';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { getLobbyLocationByGroup } from 'web/src/modules/casino/utils';
export default function useCasinoGroupsList(emit) {
    const router = useRouter();
    const bus = useEventsBus();
    function onClick(group) {
        emit('click-group', group);
        bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
        router.push(getLobbyLocationByGroup(group));
    }
    function onSelectedLoaded(el) {
        emit('selected-loaded', el);
    }
    return {
        onClick,
        onSelectedLoaded
    };
}
