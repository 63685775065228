import { computed, toRef } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';
export default function useCasinoLoyaltyProgramLevelDoneModal() {
    const { $translate } = useI18n();
    const loyaltyStatusStore = useCasinoLoyaltyStore();
    const siteConfigStore = useSiteConfigStore();
    const isEgsLoyaltyProgramLvlUpModelEnabled = toRef(siteConfigStore, 'isEgsLoyaltyProgramLvlUpModelEnabled');
    const currentLevelData = toRef(loyaltyStatusStore, 'currentLevelData');
    const name = computed(()=>currentLevelData.value?.name);
    const currentLevelId = toRef(loyaltyStatusStore, 'currentLevelId');
    const levelSettingsWithStatus = toRef(loyaltyStatusStore, 'levelSettingsWithStatus');
    const imageSrc = computed(()=>currentLevelData.value?.imageUrl);
    const isShowLevel = toRef(loyaltyStatusStore, 'isShowLevelDoneModal');
    const currentCompletedLevel = computed(()=>({
            currentCompletedLevel: name.value || ''
        }));
    const title = computed(()=>name.value ? $translate('WEB2_LOYALTY_PROGRAM_LEVEL_DONE_TITLE', currentCompletedLevel).value : '');
    const counterLevels = computed(()=>{
        const currentIndex = levelSettingsWithStatus.value.findIndex((item)=>item.id === currentLevelId.value) + 1;
        return `${currentIndex}/${levelSettingsWithStatus.value.length}`;
    });
    const progressProperties = computed(()=>({
            isHideProgress: true,
            imageSrc: imageSrc.value
        }));
    const isShowLevelDoneModal = computed(()=>isEgsLoyaltyProgramLvlUpModelEnabled.value && isShowLevel.value);
    const close = ()=>{
        loyaltyStatusStore.toggleShowLevelDoneModal();
    };
    return {
        title,
        counterLevels,
        progressProperties,
        isShowLevelDoneModal,
        close
    };
}
