import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { watch } from 'vue';
import { convertCommonClassToCssModule as toModule } from 'web/src/components/List/utils/convertCommonClassesToCssModule';
import { useVListItemAccordion } from 'web/src/components/List/VListItemAccordion/composables';
import VListItem from '../VListItem/VListItem.vue';
import VListItemInner from '../VListItemInner/VListItemInner.vue';
import { ListItemSuffixAlign } from '../VListItem/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VListItemAccordion',
    props: {
        id: {},
        title: {},
        badgeLabel: {},
        counter: {
            default: ''
        },
        countryCode: {
            default: ''
        },
        haveExpand: {
            type: Boolean
        },
        haveProgress: {
            type: Boolean
        },
        haveSwitcher: {
            type: Boolean
        },
        href: {},
        iconCustomStyles: {},
        iconName: {
            default: void 0
        },
        iconSuffixName: {},
        isActive: {
            type: Boolean
        },
        isBigMargin: {
            type: Boolean
        },
        isDone: {
            type: Boolean
        },
        isNew: {
            type: Boolean
        },
        isSwitchChecked: {
            type: Boolean
        },
        isSwitchDisabled: {
            type: Boolean
        },
        isTitleCentered: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        noHover: {
            type: Boolean
        },
        noWrap: {
            type: Boolean
        },
        open: {
            type: Boolean
        },
        progressValue: {},
        suffixAlign: {},
        suffixText: {
            default: ''
        },
        switchKind: {},
        type: {},
        isDynamicOpen: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "click-disabled"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isOpen, classObject, iconSuffixProperties, iconNameAccordion, isIndependentlyOpen, $accordionSharedState, toggle, listItemRef, scrollIntoView } = useVListItemAccordion(props, emit);
        watch(()=>props.open, (newValue)=>{
            if (props.isDynamicOpen) {
                toggle(newValue);
                return;
            }
            if (newValue && !props.haveSwitcher) {
                toggle(true);
                return;
            }
            if (props.haveSwitcher) {
                isIndependentlyOpen.value = Boolean(newValue);
                if (!$accordionSharedState.independentItems) $accordionSharedState.updateOpenItem(isOpen.value ? null : props.id);
            }
        }, {
            immediate: !props.isDynamicOpen
        });
        __expose({
            scrollIntoView
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VListItem, {
                id: _ctx.id,
                class: _normalizeClass(_unref(classObject))
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemInner, {
                            ref_key: "listItemRef",
                            ref: listItemRef,
                            title: _ctx.title,
                            "have-switcher": _ctx.haveSwitcher,
                            "icon-name": _unref(iconNameAccordion),
                            "country-code": _ctx.countryCode,
                            "icon-suffix-name": _unref(iconSuffixProperties),
                            "is-switch-disabled": _ctx.isSwitchDisabled,
                            "is-switch-checked": _unref(isOpen),
                            "is-title-centered": _ctx.isTitleCentered,
                            "suffix-text": _ctx.suffixText,
                            counter: _ctx.counter,
                            "suffix-align": _unref(ListItemSuffixAlign).TOP,
                            onClick: _cache[0] || (_cache[0] = ($event)=>_unref(toggle)())
                        }, _createSlots({
                            _: 2
                        }, [
                            _ctx.$slots.inner ? {
                                name: "inner",
                                fn: _withCtx(()=>[
                                        _renderSlot(_ctx.$slots, "inner")
                                    ]),
                                key: "0"
                            } : void 0
                        ]), 1032, [
                            "title",
                            "have-switcher",
                            "icon-name",
                            "country-code",
                            "icon-suffix-name",
                            "is-switch-disabled",
                            "is-switch-checked",
                            "is-title-centered",
                            "suffix-text",
                            "counter",
                            "suffix-align"
                        ]),
                        _withDirectives(_createElementVNode("div", {
                            class: _normalizeClass(_unref(toModule)('list-item-accordion__content'))
                        }, [
                            _renderSlot(_ctx.$slots, "content", {
                                isOpen: _unref(isOpen),
                                toggle: _unref(toggle)
                            }, ()=>[
                                    _createTextVNode(" No content provided for " + _toDisplayString(_ctx.id), 1)
                                ])
                        ], 2), [
                            [
                                _vShow,
                                _unref(isOpen)
                            ]
                        ])
                    ]),
                _: 3
            }, 8, [
                "id",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'VListItemAccordion'
                ]
            ]);
        };
    }
});
