import { ref, computed } from 'vue';
import { PromotionActionButtonState, PromotionActionButtonType } from '@leon-hub/api-sdk';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function usePromotionButton(props, emit) {
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const isStickied = ref(true);
    const promotionButton = ref();
    function onClick() {
        switch(props.buttonItem.buttonType){
            case PromotionActionButtonType.PARTICIPATE:
                emit('participate');
                break;
            case PromotionActionButtonType.LEADERBOARD:
                emit('leaderboard');
                break;
            case PromotionActionButtonType.EXCHANGE:
                emit('exchange');
                break;
            case PromotionActionButtonType.LOGIN:
                emit('login');
                break;
            case PromotionActionButtonType.ON_BONUS_WALLET:
                emit('on-bonus-wallet');
                break;
            case PromotionActionButtonType.CUSTOM:
                emit('custom');
                break;
            case PromotionActionButtonType.PLAY:
                emit('play');
                break;
            case PromotionActionButtonType.IDENTIFICATION_NEEDED:
                emit('identification-needed');
                break;
            default:
                break;
        }
    }
    const buttonSharedProperties = computed(()=>({
            fullWidth: true,
            disabled: props.isDisabled,
            isUppercase: false
        }));
    const buttonActive = computed(()=>({
            ...buttonSharedProperties.value,
            kind: ButtonKind.PRIMARY
        }));
    const buttonLoading = computed(()=>({
            ...buttonSharedProperties.value,
            isLoading: true
        }));
    const buttonProperties = computed(()=>{
        if (props.buttonItem?.buttonType === PromotionActionButtonType.PARTICIPATION_PROCESSING) return buttonLoading.value;
        return buttonActive.value;
    });
    const showButton = computed(()=>{
        const { buttonType, state } = props.buttonItem;
        return buttonType !== PromotionActionButtonType.NONE && state !== PromotionActionButtonState.INFO;
    });
    const showButtonStatus = computed(()=>{
        const { buttonType, state } = props.buttonItem;
        return buttonType !== PromotionActionButtonType.NONE && state === PromotionActionButtonState.INFO;
    });
    const isStatusIcon = computed(()=>{
        const { buttonType } = props.buttonItem;
        return buttonType === PromotionActionButtonType.PARTICIPATING || buttonType === PromotionActionButtonType.LEADERBOARD || buttonType === PromotionActionButtonType.EXCHANGED;
    });
    const buttonLabel = computed(()=>{
        const { buttonType, title, value } = props.buttonItem;
        switch(buttonType){
            case PromotionActionButtonType.ON_BONUS_WALLET:
                return `${formatMoney(value)} ${title.toUpperCase()}`;
            case PromotionActionButtonType.EXCHANGE:
            case PromotionActionButtonType.EXCHANGED:
                if (props.buttonIsLoading) return $translate('WEB2_BONUS_EXCHANGE_LOADING').value.toUpperCase();
                return `${title.toUpperCase()} ${formatMoney(value)}`;
            default:
                return title.toUpperCase();
        }
    });
    function scrollHandler() {
        if (promotionButton.value) isStickied.value = promotionButton.value.getBoundingClientRect().bottom === window.innerHeight;
    }
    useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL, scrollHandler);
    function onComponentMounted() {
        scrollHandler();
        isStickied.value = true;
    }
    return {
        onClick,
        showButtonStatus,
        buttonLabel,
        isStatusIcon,
        showButton,
        buttonProperties,
        isStickied,
        promotionButton,
        onComponentMounted
    };
}
