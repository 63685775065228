import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export class RegistrationCompletionModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addModalRoute({
            name: RouteName.REGISTRATION_COMPLETION,
            path: '/registration/completion/:customerAction/:paymentSystemId?',
            access: CustomerRouteAccessRole.AUTHORIZED,
            prefetch: ()=>import('./prefetch/RegistrationCompletionPrefetch'),
            component: ()=>import('web/src/modules/registration-completion/pages/RegistrationCompletionRoutePage/RegistrationCompletionRoutePage.vue'),
            modalPreset: ModalDesktopPreset.SmallDesktopModal,
            topBar: ()=>import('web/src/modules/registration-completion/views/RegistrationCompletionTopBarRouteComponent/RegistrationCompletionTopBarRouteComponent.vue')
        });
    }
}
