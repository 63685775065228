import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { PresetName } from 'web/src/modules/dialogs/enums';
export default function useCardCvvInput() {
    const { showDialog } = useDialogs();
    const { $translate } = useI18n();
    const maskOptions = {
        pattern: '999',
        placeholder: '0'
    };
    const showHelp = ()=>{
        showDialog({
            presetName: PresetName.CMS_MODAL,
            options: {
                iconName: void 0,
                fullHeight: false,
                title: $translate('WEB2_WHAT_IS_CVV_CVC').value,
                cmsKey: 'WEB2_WHAT_IS_CVV',
                buttons: [
                    {
                        label: $translate('JSP_OK').value
                    }
                ],
                dataTestId: 'what-is-cvv'
            }
        });
    };
    return {
        maskOptions,
        showHelp
    };
}
