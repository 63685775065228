import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { useAccessCallbackRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccessCallbackRoutePage',
    setup (__props) {
        const { created } = useAccessCallbackRoutePage();
        created();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VCmsContent, {
                class: _normalizeClass(_ctx.$style['access-option-content']),
                "cms-key": "WEB2_ACCESS_CALLBACK_OPTION",
                silent: ""
            }, null, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'AccessCallbackRoutePage'
                ]
            ]);
        };
    }
});
