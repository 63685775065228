import { computed } from 'vue';
import { logger } from '@leon-hub/logging';
import { SportEventsResponseChangeUtils, SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { sportLineToSportMap } from 'web/src/modules/sportline/utils';
const disciplinesMapFactoryOptions = {
    groupByRegion: true,
    keepLiveOutrightsInList: true
};
export function useSegmentStoreResponseMapping(props) {
    const { sportSegmentsSettings, parseSportlineSettings, rawLiveEventsResponse, rawComingEventsResponse } = props;
    const comingResponseWithoutLive = computed(()=>SportEventsResponseChangeUtils.excludeResponses(rawComingEventsResponse.value, rawLiveEventsResponse.value));
    const fullEventsResponse = computed(()=>SportEventsResponseChangeUtils.concatResponses([
            rawLiveEventsResponse.value,
            comingResponseWithoutLive.value
        ]));
    const liveEventsSportsMap = computed(()=>{
        // empty sports for disciplines based pages
        if (sportSegmentsSettings.value.sportFamily) return new Map();
        const rawEventsResponse = rawLiveEventsResponse.value;
        if (!rawEventsResponse) return new Map();
        return sportLineToSportMap(new SportlineFactory(rawEventsResponse, parseSportlineSettings.value, {
            keepLiveOutrightsInList: true
        }).build());
    });
    const comingEventsSportsMap = computed(()=>{
        // empty sports for disciplines based pages
        if (sportSegmentsSettings.value.sportFamily) return new Map();
        const rawEventsResponse = comingResponseWithoutLive.value;
        if (!rawEventsResponse) return new Map();
        const prematchEventsResponse = SportEventsResponseChangeUtils.sliceEvents(rawEventsResponse, {
            eventsInSport: sportSegmentsSettings.value.maximumComingEventsInEntityTab
        });
        const list = new SportlineFactory(prematchEventsResponse, parseSportlineSettings.value).build();
        return sportLineToSportMap(list);
    });
    const liveDisciplinesMap = computed(()=>{
        // empty disciplines for sports based pages
        if (!sportSegmentsSettings.value.sportFamily) return {};
        const rawResponse = rawLiveEventsResponse.value;
        const list = rawResponse ? new SportlineFactory(rawResponse, parseSportlineSettings.value, disciplinesMapFactoryOptions).build() : null;
        const regions = list?.[0]?.regions ?? [];
        return regions.reduce((result, regionElement)=>({
                ...result,
                [regionElement.key]: regionElement
            }), {});
    });
    const comingDisciplinesMap = computed(()=>{
        // empty disciplines for sports based pages
        if (!sportSegmentsSettings.value.sportFamily) return {};
        const rawResponse = comingResponseWithoutLive.value;
        if (!rawResponse) return Object.freeze({});
        const slicedResponse = SportEventsResponseChangeUtils.sliceEvents(rawResponse, {
            eventsInRegion: sportSegmentsSettings.value.maximumComingEventsInEntityTab
        });
        const list = new SportlineFactory(slicedResponse, parseSportlineSettings.value, disciplinesMapFactoryOptions).build();
        const regions = list[0]?.regions || [];
        return regions.reduce((result, regionElement)=>({
                ...result,
                [regionElement.key]: regionElement
            }), {});
    });
    const totalCount = computed(()=>{
        if (sportSegmentsSettings.value.sportFamily) return Object.values(liveDisciplinesMap.value).reduce((result, regionElement)=>result + regionElement.counters.live, 0);
        return rawLiveEventsResponse.value?.totalCount || 0;
    });
    const compiledComingSports = computed(()=>{
        // empty sports for disciplines based pages
        if (sportSegmentsSettings.value.sportFamily) return null;
        const rawEventsResponse = comingResponseWithoutLive.value;
        if (!rawEventsResponse) return [];
        const comingEventsMaximumLiveForShow = sportSegmentsSettings.value.maximumLiveToShowCompiledComingBlock;
        if (null !== comingEventsMaximumLiveForShow && totalCount.value > comingEventsMaximumLiveForShow) return [];
        const prematchEventsResponse = SportEventsResponseChangeUtils.sliceEvents(rawEventsResponse, {
            sports: sportSegmentsSettings.value.maximumEntitiesInCompiledComingBlock,
            eventsInSport: sportSegmentsSettings.value.maximumComingEventsInEntityInCompiledBlock
        });
        return new SportlineFactory(prematchEventsResponse, parseSportlineSettings.value).build();
    });
    /** Compiled coming regions for "all" tab */ const compiledComingDisciplinesList = computed(()=>{
        // empty disciplines for sports based pages
        if (!sportSegmentsSettings.value.sportFamily) return null;
        const rawResponse = comingResponseWithoutLive.value;
        if (!rawResponse) return [];
        const maxLiveForShow = sportSegmentsSettings.value.maximumLiveToShowCompiledComingBlock;
        if (null !== maxLiveForShow && totalCount.value > maxLiveForShow) return [];
        const slicedResponse = SportEventsResponseChangeUtils.sliceEvents(rawResponse, {
            regions: sportSegmentsSettings.value.maximumEntitiesInCompiledComingBlock,
            eventsInRegion: sportSegmentsSettings.value.maximumComingEventsInEntityInCompiledBlock
        });
        const list = new SportlineFactory(slicedResponse, parseSportlineSettings.value, disciplinesMapFactoryOptions).build();
        const regions = list?.[0]?.regions;
        if (list.length > 1) logger.warn('More then one Cybersport presented', list.map((sportElement)=>sportElement.sport.id));
        return regions || null;
    });
    return {
        comingResponseWithoutLive,
        fullEventsResponse,
        totalCount,
        liveEventsSportsMap,
        comingEventsSportsMap,
        compiledComingSports,
        liveDisciplinesMap,
        comingDisciplinesMap,
        compiledComingDisciplinesList
    };
}
