import { ref, computed } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { onComponentDeactivated } from '@leon-hub/vue-utils';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useInjectSelectedColumn, useInjectSelectColumnMethod } from 'web/src/modules/sportline/composables/select-main-market';
import { PopperPosition } from 'web/src/components/Popper/VPopper/enums';
export const popoverProperties = {
    position: PopperPosition.TOP_END,
    hasOffset: false,
    modifiers: [
        {
            name: 'minWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: [
                'computeStyles'
            ],
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            fn: (param)=>{
                let { state } = param;
                const result = {
                    ...state
                };
                const referenceWidth = state.rects.reference.width;
                result.styles.popper.minWidth = `${referenceWidth}px`;
                return result;
            }
        },
        {
            name: 'offset',
            options: {
                offset: (param)=>{
                    let { popper } = param;
                    return [
                        0,
                        -popper.height
                    ];
                }
            }
        }
    ]
};
export function useMarketsDropDown(param) {
    let { basisKey } = param;
    const analytics = useAnalytics();
    const bus = useEventsBus();
    const { selectColumn } = useInjectSelectColumnMethod({
        basisKey
    });
    const { selectedColumnId, columns } = useInjectSelectedColumn({
        basisKey
    });
    // eslint-disable-next-line unicorn/prevent-abbreviations
    const popperRef = ref();
    const selectedColumnIdFinal = computed(()=>selectedColumnId.value || columns.value[0]?.id || null);
    const dropdownOptions = computed(()=>columns.value.map((column)=>({
                label: column.name,
                value: column.id
            })));
    const activeOption = computed(()=>{
        const selectedId = selectedColumnIdFinal.value;
        return dropdownOptions.value.find((item)=>item.value === selectedId);
    });
    const title = computed(()=>activeOption.value?.label ?? '');
    let clearListeners;
    function closeDropdown() {
        clearListeners?.();
        popperRef.value?.hide();
    }
    function onPopperShow() {
        bus.once(BusEvent.LAYOUT_CONTENT_SCROLL, closeDropdown);
        window.addEventListener('resize', closeDropdown);
        clearListeners = ()=>{
            bus.off(BusEvent.LAYOUT_CONTENT_SCROLL, closeDropdown);
            window.removeEventListener('resize', closeDropdown);
            clearListeners = void 0;
        };
    }
    function onActiveMarketTypeChange(value) {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: 'lineViewDisplay'
        });
        selectColumn(value);
        closeDropdown();
    }
    onComponentDeactivated(closeDropdown);
    return {
        popperRef,
        selectedColumnId: selectedColumnIdFinal,
        dropdownOptions,
        activeOption,
        title,
        onPopperShow,
        onActiveMarketTypeChange
    };
}
