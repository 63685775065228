import betResultHasLimitExceedError from './betResultHasLimitExceedError';
import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function parseBetResults(results, selectedEntries) {
    const acceptedIds = new Set();
    const acceptedBets = [];
    const slipEntries = [];
    let haveItemWithCorruptedInfo = false;
    let haveLimitsError = false;
    for (const result of results)if (result.slipInfo) {
        slipEntries.push(result.slipInfo);
        if (result.accepted) {
            acceptedBets.push(result);
            const entry = result.slipInfo.entries[0];
            acceptedIds.add(getSlipEntryId(entry));
        } else if (betResultHasLimitExceedError(result)) haveLimitsError = true;
    } else haveItemWithCorruptedInfo = true;
    const updatedSlipEntries = haveItemWithCorruptedInfo ? null : slipEntries;
    if (!acceptedBets.length) return {
        acceptedBets: [],
        unsettledBetsIds: selectedEntries,
        updatedSlipEntries,
        haveLimitsError
    };
    const unsettledBetsIds = selectedEntries.filter((id)=>!acceptedIds.has(id));
    return {
        acceptedBets,
        unsettledBetsIds,
        updatedSlipEntries: unsettledBetsIds.length ? null : updatedSlipEntries,
        haveLimitsError
    };
}
