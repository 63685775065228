import { DeviceFingerprintType } from 'web/src/modules/identity/enums';
import { useIdentityStore } from 'web/src/modules/identity/store';
export default function useDeviceFingerprints() {
    const { getDeviceFingerprint, getFingerPrintFromStorage } = useIdentityStore();
    async function getDeviceFingerprints() {
        let deviceFingerprint = '';
        let deviceFingerprintJs = '';
        deviceFingerprintJs = await getFingerPrintFromStorage(DeviceFingerprintType.DEVICE_FINGERPRINT_JS);
        if (!deviceFingerprintJs) deviceFingerprint = await getDeviceFingerprint(DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER);
        return {
            deviceFingerprint,
            deviceFingerprintJs
        };
    }
    return {
        getDeviceFingerprints
    };
}
