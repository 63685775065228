import { isGetHeadlineMatchesChangesResponseCheck, isGetMergedHeadlineMatchesChangesResponseCheck, isGetMergedHeadlineMatchesResponseCheck } from 'web/src/modules/sportline/guards/rest';
import { BetlineReplaceResponse } from 'web/src/modules/sportline/utils/rest';
// eslint-disable-next-line max-len
export const handleRawSportEventsResponse = (response)=>{
    let result = {
        rawMergedSportEventsResponse: null,
        rawSplitSportEventsResponse: null
    };
    if (!response) return result;
    if (isGetHeadlineMatchesChangesResponseCheck(response)) {
        result = isGetMergedHeadlineMatchesChangesResponseCheck(response) ? {
            ...result,
            rawMergedSportEventsResponse: Object.freeze({
                events: BetlineReplaceResponse.unknownResponseToSportEventsResponse(response.events),
                total: response.total
            })
        } : {
            ...result,
            rawSplitSportEventsResponse: Object.freeze({
                live: BetlineReplaceResponse.unknownResponseToSportEventsResponse(response.live),
                prematch: BetlineReplaceResponse.unknownResponseToSportEventsResponse(response.prematch),
                liveTotal: response.liveTotal,
                prematchTotal: response.prematchTotal
            })
        };
        return result;
    }
    result = isGetMergedHeadlineMatchesResponseCheck(response) ? {
        ...result,
        rawMergedSportEventsResponse: Object.freeze(response)
    } : {
        ...result,
        rawSplitSportEventsResponse: Object.freeze(response)
    };
    return result;
};
