import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    class: "payment-bonus-item"
};
const _hoisted_2 = {
    class: "payment-bonus-item__wrapper"
};
const _hoisted_3 = {
    class: "payment-bonus-item__buttons"
};
import { onMounted } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { usePaymentBonusItem } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentBonusItem',
    props: {
        bonusItem: {},
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        "select",
        "select-complete"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { animatedMask, isExpanded, isStatic, isImageLoaded, isDetailsAvailable, bonusImage, description, emitSelectComplete, emitSelectedBonus, onMount, onToggleExpand, onDetailClick, chooseButtonHandle, setImageLoaded } = usePaymentBonusItem(props, emit);
        onMounted(onMount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VImage, _mergeProps({
                    class: "payment-bonus-item__img"
                }, _unref(bonusImage), {
                    onClick: _unref(emitSelectedBonus),
                    onImageLoaded: _unref(setImageLoaded)
                }), null, 16, [
                    "onClick",
                    "onImageLoaded"
                ]),
                _withDirectives(_createElementVNode("div", {
                    ref_key: "animatedMask",
                    ref: animatedMask,
                    class: _normalizeClass([
                        "payment-bonus-item__mask",
                        {
                            'payment-bonus-item__mask--static': _unref(isStatic)
                        }
                    ]),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(emitSelectComplete) && _unref(emitSelectComplete)(...args);
                    })
                }, null, 2), [
                    [
                        _vShow,
                        _ctx.isSelected
                    ]
                ]),
                _withDirectives(_createVNode(_unref(VIcon), {
                    class: _normalizeClass([
                        "payment-bonus-item__mask-icon",
                        {
                            'payment-bonus-item__mask-icon--clicked': _ctx.isSelected
                        }
                    ]),
                    size: _unref(IconSize).SIZE_44,
                    name: _unref(IconName).CHECK_FILLED,
                    onClick: _unref(emitSelectComplete)
                }, null, 8, [
                    "class",
                    "size",
                    "name",
                    "onClick"
                ]), [
                    [
                        _vShow,
                        _ctx.isSelected
                    ]
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                        class: "payment-bonus-item__text-box",
                        onClick: _cache[1] || (_cache[1] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onToggleExpand) && _unref(onToggleExpand)(...args);
                        })
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                "payment-bonus-item__name",
                                {
                                    'payment-bonus-item__name--skeleton': !_unref(isImageLoaded)
                                }
                            ])
                        }, [
                            _unref(isImageLoaded) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.bonusItem.campaignName), 1)
                            ], 64)) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                'payment-bonus-item__desc--skeleton': !_unref(isImageLoaded)
                            })
                        }, [
                            _unref(isImageLoaded) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createVNode(VDynamicContent, {
                                    class: _normalizeClass([
                                        "payment-bonus-item__desc",
                                        {
                                            'payment-bonus-item__desc--expand': _unref(isExpanded)
                                        }
                                    ]),
                                    "no-margins": "",
                                    content: _unref(description)
                                }, null, 8, [
                                    "class",
                                    "content"
                                ]),
                                _withDirectives(_createElementVNode("div", _hoisted_3, [
                                    _unref(isDetailsAvailable) ? (_openBlock(), _createBlock(VButton, {
                                        key: 0,
                                        kind: _unref(ButtonKind).SECONDARY,
                                        "icon-name": _unref(IconName).INFO_OUTLINE,
                                        height: _unref(ButtonHeight).MEDIUM,
                                        "is-uppercase": false,
                                        label: _ctx.$t('WEB2_CBC_PROMO_MORE'),
                                        onClick: _withModifiers(_unref(onDetailClick), [
                                            "stop"
                                        ])
                                    }, null, 8, [
                                        "kind",
                                        "icon-name",
                                        "height",
                                        "label",
                                        "onClick"
                                    ])) : _createCommentVNode("", true),
                                    _createVNode(VButton, {
                                        class: _normalizeClass({
                                            single: !_unref(isDetailsAvailable)
                                        }),
                                        "icon-name": _unref(IconName).PROMOS,
                                        height: _unref(ButtonHeight).MEDIUM,
                                        "is-uppercase": false,
                                        label: _ctx.$t('WEB2_FEEDBACK_FORM_THEME_PLACEHOLDER'),
                                        onClick: _withModifiers(_unref(chooseButtonHandle), [
                                            "stop"
                                        ])
                                    }, null, 8, [
                                        "class",
                                        "icon-name",
                                        "height",
                                        "label",
                                        "onClick"
                                    ])
                                ], 512), [
                                    [
                                        _vShow,
                                        _unref(isExpanded)
                                    ]
                                ])
                            ], 64)) : _createCommentVNode("", true)
                        ], 2)
                    ]),
                    _unref(isImageLoaded) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass([
                            "payment-bonus-item__button",
                            {
                                'payment-bonus-item__button--expand': _unref(isExpanded)
                            }
                        ])
                    }, [
                        _createVNode(VButton, {
                            height: _unref(ButtonHeight).TINY,
                            kind: _unref(isExpanded) ? _unref(ButtonKind).SECONDARY : _unref(ButtonKind).TRANSPARENT,
                            "icon-name": _unref(isExpanded) ? _unref(IconName).EXPAND_UP : _unref(IconName).EXPAND_DOWN,
                            onClick: _unref(onToggleExpand)
                        }, null, 8, [
                            "height",
                            "kind",
                            "icon-name",
                            "onClick"
                        ])
                    ], 2)) : _createCommentVNode("", true)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'PaymentBonusItem'
                ]
            ]);
        };
    }
});
