import { isBoolean, isNull, isNumber, isString, isValidObject, isEnumOfType } from '@leon-hub/guards';
import { BonusCategory, BonusState } from '@leon-hub/api-sdk/src/sdk/sdk';
export var BonusLoadingKey;
var BonusLoadingKey1;
(BonusLoadingKey1 = BonusLoadingKey || (BonusLoadingKey = {}))["BONUS_LIST"] = "bonus-list", BonusLoadingKey1["BONUS_CBC"] = "bonus-cbc";
export function isBonusLoadingKey(value) {
    return isEnumOfType(BonusLoadingKey)(value);
}
export var BonusPanels;
var BonusPanels1;
(BonusPanels1 = BonusPanels || (BonusPanels = {}))["CBC"] = "cbc", BonusPanels1["BALANCE"] = "balance", BonusPanels1["PROGRAM"] = "program", BonusPanels1["NONE"] = "none";
const BASE_BONUS_RULES = {
    walletBalanceNumber: [
        isNumber
    ],
    category: [
        isEnumOfType(BonusCategory)
    ],
    widgetName: [
        isString
    ],
    state: [
        isEnumOfType(BonusState)
    ],
    currency: [
        isString
    ],
    progress: [
        isNumber
    ]
};
export function isBonusAccumulative(input) {
    let silent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    return isValidObject(input, {
        ...BASE_BONUS_RULES,
        isLeonshopShown: [
            isBoolean
        ],
        bonusCurrency: [
            isString,
            isNull
        ],
        remainingAmountNumber: [
            isNumber
        ],
        bonusAmountNumber: [
            isNumber
        ]
    }, silent);
}
export function isBonusFirstDeposit(input) {
    let silent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    return isValidObject(input, {
        ...BASE_BONUS_RULES,
        isLeonshopShown: [
            isBoolean
        ],
        bonusCurrency: [
            isString,
            isNull
        ],
        requiredAmountNumber: [
            isNumber
        ],
        bonusAmountNumber: [
            isNumber
        ]
    }, silent);
}
export function isBonus(input) {
    let silent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    return isValidObject(input, {
        ...BASE_BONUS_RULES,
        amountNumber: [
            isNumber
        ],
        availableAmountNumber: [
            isNumber
        ],
        requiredAmountNumber: [
            isNumber
        ],
        campaignName: [
            isString,
            isNull
        ],
        expiredAt: [
            isString,
            isNull
        ],
        expiringThrough: [
            isString,
            isNull
        ],
        expiredTimestamp: [
            isNumber
        ],
        isExpired: [
            isBoolean
        ],
        bonusId: [
            isNumber
        ],
        actionUrl: [
            isString,
            isNull
        ],
        categoryId: [
            isString,
            isNull
        ]
    }, silent);
}
