import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import addLoginDevice from 'web/src/modules/identity/utils/addLoginDevice';
import { useLexisNexisIntegration } from 'web/src/modules/lexis-nexis-integration';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
export async function runDeviceIdRoutine() {
    const skipLexis = !useRegistrationComplete().value;
    try {
        if (!skipLexis) useLexisNexisIntegration().runApiProfile();
    } finally{
        try {
            await addLoginDevice({
                skipLexis
            });
        } catch (err) {
            logger.error('Unable to add login device', normalizeError(err));
        }
    }
}
