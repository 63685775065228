import { ref } from 'vue';
import { ProgramRequirementType } from '@leon-hub/api-sdk/src/sdk/sdk';
export default function getRequirementsTitle(requirement, $t, formatMoney) {
    const requirementKeys = ref({
        count: String(formatMoney(Number(requirement.requiredValue || 0) || 0))
    });
    switch(requirement.type){
        case ProgramRequirementType.REGISTRATION:
            return $t('WEB2_REFERRAL_REQUIREMENT_REGISTRATION').value;
        case ProgramRequirementType.DEPOSIT:
            return $t('WEB2_REFERRAL_REQUIREMENT_DEPOSIT', requirementKeys).value;
        case ProgramRequirementType.BET:
            return $t('WEB2_REFERRAL_REQUIREMENT_BET').value;
        case ProgramRequirementType.IDENTIFICATION:
            return $t('WEB2_REFERRAL_REQUIREMENT_IDENTIFICATION').value;
        case ProgramRequirementType.CONFIRMATION:
            return $t('WEB2_REFERRAL_REQUIREMENT_CONFIRMATION').value;
        default:
            return '';
    }
}
