import { isArray, isNumber, isValidInterface } from '@leon-hub/guards';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { OperationTypeMappingType } from 'web/src/modules/affiliates/store/enums';
export const isAffiliateOperation = (value)=>isValidInterface(value, {
        date: isNumber,
        debit: isNumber,
        credit: isNumber,
        pendingDebit: isNumber,
        pendingCredit: isNumber,
        balance: isNumber,
        pendingBalance: isNumber,
        typeId: isNumber
    });
export const isAffiliateOperations = (value)=>isArray(value) && value.every((item)=>isAffiliateOperation(item));
export function mapOperationDateTime(value) {
    isNumber(value);
    return DateTime.formatTimeStamp(DateTime.timestampSecondsToDate(value, true).getTime(), TimeFormats.dayMonthYearHoursMinutes);
}
export function mapOperationDate(value) {
    isNumber(value);
    return DateTime.formatTimeStamp(DateTime.timestampSecondsToDate(value, true).getTime(), TimeFormats.dayMonthYear);
}
export function mapOperationTime(value) {
    isNumber(value);
    return DateTime.formatTimeStamp(DateTime.timestampSecondsToDate(value, true).getTime(), TimeFormats.hoursMinutes);
}
export function mapOperationTypeId($t, value) {
    isNumber(value);
    switch(value){
        case OperationTypeMappingType.BetId:
            return $t('WEB2_AFFILIATE_OPERATION_TYPE_BET').value;
        case OperationTypeMappingType.PartnerId:
            return $t('WEB2_AFFILIATE_OPERATION_TYPE_PARTNER').value;
        case OperationTypeMappingType.SportBonusWithdrawalId:
            return $t('JAVA_AHI_AFFPBONUSCHARGE').value;
        default:
            return 'Unexpected operation type';
    }
}
export function mapZeroToEmptyString(value) {
    isNumber(value);
    return 0 === value ? '' : String(value);
}
export function mapOperationBalance(value) {
    isNumber(value);
    return Number(value).toFixed(2);
}
