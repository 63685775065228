export default function updatePreparedToActionList(param) {
    let { id, state, currentList } = param;
    const alreadyIncludes = currentList.includes(id);
    const result = {
        isChanged: false,
        list: currentList
    };
    if (state) {
        if (alreadyIncludes) return result;
        result.isChanged = true;
        result.list = [
            ...currentList,
            id
        ];
        return result;
    }
    if (!alreadyIncludes) return result;
    result.isChanged = true;
    result.list = currentList.filter((preparedId)=>preparedId !== id);
    return result;
}
