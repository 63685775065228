import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 1
};
import { toRef, computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useColumnsNames, useExpandableHeadline } from 'web/src/modules/sportline/composables/list';
import { useInjectSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
import { defaultSportsMarketColumns, defaultMarketColumnsOptions } from 'web/src/modules/sportline/constants';
import FavoriteLeagueIndicator from 'web/src/modules/sportline/submodules/favorites/views/FavoriteLeagueIndicator.vue';
import { useLeagueHeadlineTitle } from 'web/src/modules/sportline/composables/list/useLeagueHeadlineTitle';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import { selectMarketTypeByColumn, placeRunnersToColumns } from 'web/src/modules/sportline/utils/markets';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueHeadline',
    props: {
        sport: {},
        region: {},
        leagueElement: {},
        isFilterActive: {
            type: Boolean
        },
        isShowAddLeagueToFavorite: {
            type: Boolean
        },
        isColumnNamesDisplayDisabled: {
            type: Boolean
        },
        expandKey: {},
        isExpanded: {
            type: Boolean
        },
        countEvents: {},
        basisKey: {}
    },
    emits: [
        "toggle-expand-state"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sport = toRef(props, 'sport', null);
        const region = toRef(props, 'region', null);
        const leagueElement = toRef(props, 'leagueElement', null);
        const isColumnNamesDisplayDisabled = toRef(props, 'isColumnNamesDisplayDisabled', false);
        const expandKey = toRef(props, 'expandKey', null);
        const isExpanded = toRef(props, 'isExpanded', false);
        const isFilterActive = toRef(props, 'isFilterActive', false);
        const countEvents = toRef(props, 'countEvents', 0);
        const basisKey = toRef(props, 'basisKey');
        const leagueId = computed(()=>leagueElement.value?.league.id);
        const { selectedColumnId, selectedColumnSize, selectedColumnSign, columns } = useInjectSelectedColumn({
            basisKey
        });
        const actualMarketType = computed(()=>selectMarketTypeByColumn({
                columns: columns.value,
                selectedColumnId: selectedColumnId.value,
                allMarketsTypes: leagueElement.value?.marketTypes ?? []
            }));
        const runnersPlaces = computed(()=>placeRunnersToColumns({
                market: null,
                marketType: actualMarketType.value,
                sportFamily: sport.value?.representation.family
            }, defaultMarketColumnsOptions));
        const { isExpandable, isShowEventsCounters, toggleExpandState } = useExpandableHeadline({
            expandKey,
            isExpanded,
            countEvents
        }, emit);
        const { leagueTitle, regionTitle } = useLeagueHeadlineTitle({
            doUseFilter: isFilterActive,
            region,
            league: computed(()=>leagueElement.value?.league ?? null),
            filter: computed(()=>leagueElement.value?.usedFilter ?? null)
        });
        const defaultSportColumnInfo = computed(()=>sport.value ? defaultSportsMarketColumns[sport.value.representation.family] : void 0);
        const defaultColumnSize = computed(()=>selectedColumnSize.value ?? defaultSportColumnInfo.value?.size ?? 3);
        const { mainMarketColumnNames } = useColumnsNames({
            isColumnNamesDisplayDisabled,
            runnersPlaces,
            actualMarketType,
            defaultColumnSign: selectedColumnSign,
            defaultColumnSize
        });
        const { onCatalogClick } = useSportlineCatalogClick({
            leagueId
        });
        function onHeadlineClick(event) {
            // click on favorites icon
            if (event.defaultPrevented) return;
            toggleExpandState();
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['sport-event-list-league-headline']]: true,
                    [_ctx.$style['sport-event-list-league-headline--expandable']]: _unref(isExpandable),
                    [_ctx.$style['sport-event-list-league-headline--collapsed']]: _unref(isExpandable) && !isExpanded.value
                }),
                onClick: onHeadlineClick
            }, [
                _ctx.isShowAddLeagueToFavorite && leagueElement.value && sport.value ? (_openBlock(), _createBlock(FavoriteLeagueIndicator, {
                    key: 0,
                    "sport-id": sport.value.id,
                    "league-id": leagueElement.value.league.id,
                    class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__favorite-indicator'])
                }, null, 8, [
                    "sport-id",
                    "league-id",
                    "class"
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['sport-event-list-league-headline__label']]: true,
                        [_ctx.$style['sport-event-list-league-headline__label--can-favorite']]: _ctx.isShowAddLeagueToFavorite
                    }),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onCatalogClick) && _unref(onCatalogClick)(...args);
                    })
                }, [
                    _unref(regionTitle) && _unref(leagueTitle) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__region'])
                        }, _toDisplayString(_unref(regionTitle)), 3),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__league-name'])
                        }, _toDisplayString(_unref(leagueTitle)), 3)
                    ], 64)) : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__region'])
                    }, _toDisplayString(_unref(leagueTitle)), 3))
                ], 2),
                _unref(isShowEventsCounters) ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__events-counter'])
                }, [
                    _createTextVNode(_toDisplayString(countEvents.value) + " ", 1),
                    _createVNode(_unref(VIcon), {
                        class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__expand-icon']),
                        size: _unref(IconSize).SIZE_20,
                        name: isExpanded.value ? _unref(IconName).EXPAND_UP : _unref(IconName).EXPAND_DOWN
                    }, null, 8, [
                        "class",
                        "size",
                        "name"
                    ])
                ], 2)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__market-labels'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mainMarketColumnNames), (runner, idx)=>(_openBlock(), _createElementBlock("div", {
                                key: idx,
                                class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__market-label'])
                            }, [
                                runner ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(runner), 1)) : _createCommentVNode("", true)
                            ], 2))), 128))
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['sport-event-list-league-headline__market-label'],
                            _ctx.$style['sport-event-list-league-headline__market-label--placeholder']
                        ])
                    }, [
                        _unref(isExpandable) ? (_openBlock(), _createBlock(_unref(VIcon), {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['sport-event-list-league-headline__expand-icon']),
                            size: _unref(IconSize).SIZE_20,
                            name: isExpanded.value ? _unref(IconName).EXPAND_UP : _unref(IconName).EXPAND_DOWN
                        }, null, 8, [
                            "class",
                            "size",
                            "name"
                        ])) : (_openBlock(), _createElementBlock("span", _hoisted_2, " "))
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeagueHeadline'
                ]
            ]);
        };
    }
});
