import { CustomerBetType, lobbyTypeHome } from '@leon-hub/api-sdk';
import RouteName, { CasinoRouteName } from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import { useCustomerBetTypeStore } from 'web/src/modules/customer/store';
let CustomHomePagePrefetch = class CustomHomePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const betTypeStore = useCustomerBetTypeStore();
        if (betTypeStore.betType) {
            next({
                name: betTypeStore.betType === CustomerBetType.SPORT ? RouteName.SPORTLINE_TOP_EVENTS : CasinoRouteName.CASINO_LOBBY
            });
            return Promise.resolve();
        }
        const { getLobbyItems, loadLobbyItems } = useLobbyStore();
        const items = getLobbyItems(lobbyTypeHome);
        if (!items.value.length) loadLobbyItems({
            type: lobbyTypeHome
        });
        next();
        return Promise.resolve();
    }
};
export { CustomHomePagePrefetch as default };
