import { toRef, ref, computed } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { BetlineSportEventStatus } from 'web/src/modules/sportline/enums/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { getBetlineMatchStatistics } from 'web/src/modules/sportline/utils/api';
import parsePostMatchStatisticConfrontationBlocks from 'web/src/modules/sportline/submodules/event-statistic/utils/parsePostMatchStatisticConfrontationBlocks';
import { isStatisticNotFoundError, convertBetlineToSportlinePostMatchStatistics } from 'web/src/modules/sportline/utils/statistic';
/** @deprecated use postMatchStatistics instead */ const TWENTY_FOUR_HOURS = 86400000;
export default function useSportlinePostMatchStatisticsStoreComposable(props) {
    const { expectedStatisticId, isSportEventStatisticEnabled, currentLoadedMatchIdentifier, sportlineEventStatisticUpdateInterval, setIsBackgroundRequestPriorityIncreased } = props;
    const isBackgroundRequestPriorityIncreased = toRef(props.isBackgroundRequestPriorityIncreased ?? null);
    /** @deprecated use postMatchStatistics instead */ const betlinePostMatchStatistics = ref(null);
    const postMatchStatistics = ref(null);
    const setBetlinePostMatchStatistics = function(statistic) {
        postMatchStatistics.value = convertBetlineToSportlinePostMatchStatistics(statistic);
        betlinePostMatchStatistics.value = statistic;
    };
    const hasPostMatchStatistics = computed(()=>{
        const targetId = expectedStatisticId.value;
        const statistic = postMatchStatistics.value ? postMatchStatistics.value : null;
        const loadedId = statistic?.id;
        return !!loadedId && String(targetId) === loadedId;
    });
    const isPostMatchStatisticLoaded = computed(()=>{
        if (!isSportEventStatisticEnabled.value) return true;
        if (false === postMatchStatistics.value) return true;
        const targetId = expectedStatisticId.value;
        if (!targetId) return false;
        return hasPostMatchStatistics.value;
    });
    const confrontationBlocks = computed(()=>{
        const statistic = postMatchStatistics.value ? postMatchStatistics.value : null;
        const periods = statistic?.periods;
        return parsePostMatchStatisticConfrontationBlocks(periods);
    });
    const lastUpdate = new BackgroundUpdateStopwatch({
        postMatchStatistics: async (param)=>{
            let { silent } = param;
            if (!isSportEventStatisticEnabled.value) return;
            try {
                const eventId = expectedStatisticId.value;
                if (!eventId) return;
                setBetlinePostMatchStatistics(await getBetlineMatchStatistics({
                    eventId
                }, {
                    silent
                }));
                setIsBackgroundRequestPriorityIncreased(false);
            } catch (error) {
                if (!isStatisticNotFoundError(error)) throw error;
                // we tried but got empty statistic for this ID
                setBetlinePostMatchStatistics(false);
            } finally{
                lastUpdate.update('postMatchStatistics');
            }
        }
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: sportlineEventStatisticUpdateInterval
    });
    function isSyncStateWillBeSkipped() {
        if (!isSportEventStatisticEnabled.value) return true;
        // load post match data only for closed events
        const loadedSportEvent = currentLoadedMatchIdentifier.value;
        if (false !== loadedSportEvent && loadedSportEvent?.status !== BetlineSportEventStatus.CLOSED) return true;
        if (isBackgroundRequestPriorityIncreased.value) // Always do background requests after event moved to CLOSED
        return false;
        // sync only if we have post data
        if (!postMatchStatistics.value) return true;
        // skip syncs after 24 hours
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const timestamp = (postMatchStatistics.value || null)?.matchDate;
        const diff = timestamp ? Date.now() - timestamp : null;
        return null === diff || diff > TWENTY_FOUR_HOURS;
    }
    function onInit() {
        useSyncState(async (silent)=>{
            if (isSyncStateWillBeSkipped()) return;
            await syncState({
                silent
            });
        }, sportlineEventStatisticUpdateInterval);
    }
    return {
        initialRequests,
        onInit,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled,
        betlinePostMatchStatistics,
        postMatchStatistics,
        hasPostMatchStatistics,
        isPostMatchStatisticLoaded,
        confrontationBlocks,
        isSyncStateWillBeSkipped,
        setBetlinePostMatchStatistics
    };
}
