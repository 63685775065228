import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { useVCountryFlag } from './useVCountryFlag';
/** @deprecated use code prop instead */ export default /*@__PURE__*/ _defineComponent({
    __name: 'VCountryFlag',
    props: {
        sprite: {
            type: Boolean
        },
        name: {},
        code: {},
        size: {},
        rounded: {
            type: Boolean
        }
    },
    setup (__props) {
        /**
 * @TODO check VIcon usage. Seems we can use default.svg flag
 */ const props = __props;
        const name = toRef(props, 'name');
        const isSprite = toRef(props, 'sprite', false);
        const countryCode = toRef(props, 'code', "MT");
        const size = toRef(props, 'size', VCountryFlagSize.SIZE_12);
        const countryCodeFallback = computed(()=>// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            (name.value ?? countryCode.value)?.toUpperCase() || null);
        const { position, imageUrl } = useVCountryFlag({
            countryCode: countryCodeFallback
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("span", {
                class: _normalizeClass({
                    [_ctx.$style['country-flag']]: true,
                    [_ctx.$style[`country-flag-${size.value}`]]: !_ctx.rounded,
                    [_ctx.$style['country-flag__rounded']]: _ctx.rounded
                })
            }, [
                _unref(imageUrl) ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['country-flag__img']),
                    style: _normalizeStyle(isSprite.value ? {
                        background: `url(${require('web/src/assets/flags/flags.png')}) ${_unref(position)} / 255px no-repeat`
                    } : {
                        backgroundImage: `url(${_unref(imageUrl)})`
                    })
                }, null, 6)) : (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 1,
                    size: _unref(IconSize).SIZE_20,
                    name: _unref(IconName).GLOBE
                }, null, 8, [
                    "size",
                    "name"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VCountryFlag'
                ]
            ]);
        };
    }
});
