import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { ShowcaseNavigationItemName } from 'web/src/modules/home/submodules/showcase/enums';
export function showCaseNavigationName(name) {
    switch(name){
        case ShowcaseNavigationItemName.LIVE:
            return 'LiveBets';
        case ShowcaseNavigationItemName.CHERRY:
            return 'SLOTS';
        case ShowcaseNavigationItemName.ROULETTE:
            return 'LIVE';
        case ShowcaseNavigationItemName.BETGAMES:
            return 'BetGames';
        case ShowcaseNavigationItemName.TV:
            return 'virtualSports';
        case ShowcaseNavigationItemName.CYBERSPORT:
            return 'eSports';
        case ShowcaseNavigationItemName.FASTGAMES:
            return 'quickBets';
        case ShowcaseNavigationItemName.ACTIONS:
            return 'promo';
        case ShowcaseNavigationItemName.CRICKET:
            return 'cricket';
        case ShowcaseNavigationItemName.FOOTBALL:
            return 'football';
        case ShowcaseNavigationItemName.TENNIS:
            return 'tennis';
        default:
            return name;
    }
}
export default function useShowcaseNavigationAnalyticsComposable() {
    const analytics = useAnalytics();
    function handleNavigationItemClickAnalytics(name) {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                [showCaseNavigationName(name)]: 'shortcuts'
            }
        });
        if (name === ShowcaseNavigationItemName.ZERO_MARGIN) analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                margin_0: 'shortcuts'
            }
        });
    }
    return {
        handleNavigationItemClickAnalytics
    };
}
