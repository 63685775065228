import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { getLiveChatSettings, HelpButtonMode } from '@leon-hub/api-sdk/src/sdk/sdk';
import { logger } from '@leon-hub/logging';
import { hashString } from '@leon-hub/utils/src/hash/murmurHash';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import DefaultLiveChatObject from 'web/src/modules/live-chat/classes/DefaultLiveChatObject';
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
import MediaUtils from 'web/src/utils/MediaUtils';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import { LiveChatMode, LiveChatStatus } from 'web/src/modules/live-chat/enums';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useLiveChatApi from 'web/src/modules/live-chat/services/api/useLiveChatApi';
import { useModuleTimeout } from 'web/src/modules/core/store/composables';
import useSupport from 'web/src/modules/support/composables/useSupport';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useWebSockets } from 'web/src/modules/core/composables';
const STARTED_CHAT_STORAGE_KEY = 'startedChat';
const CHAT_MESSAGES_READ_LENGTH = 'chatMessagesReadLength';
const platform = "web";
const useLiveChatStore = defineStore('live-chat', ()=>{
    const localStorageManager = useLocalStorageManager();
    const api = useLiveChatApi();
    const gqlApi = useGraphqlClient();
    const analytics = useAnalytics();
    const { isLoggedIn } = useIsLoggedIn();
    const customerDataStore = useCustomerDataStore();
    const login = toRef(customerDataStore, 'login');
    const supportBlock = toRef(useSiteConfigStore(), 'supportBlock');
    const { isIntercomOptionsListFlow } = useSupport();
    const { subscribeAuthorized } = useWebSockets();
    const { timeout } = useModuleTimeout('live-chat');
    /** State */ const startText = ref('');
    const loaded = ref(false);
    const enabled = ref(false);
    const muted = ref(false);
    const isOpened = ref(false);
    const code = ref('');
    const session = ref('');
    const endpoint = ref('');
    const mode = ref(LiveChatMode.opened);
    const currentChat = ref(new DefaultLiveChatObject());
    const lastChat = ref(null);
    const userTypingDate = ref(Date.now());
    const tempFiles = ref([]);
    const messagesSize = ref(0);
    const hidden = ref(true);
    const chatDataReceived = ref(false);
    const openOnCreated = ref(false);
    const loadingFilesCounter = ref(0);
    const chatWasStarted = ref(false);
    /** Getters */ const staffTyping = computed(()=>currentChat.value.typing && currentChat.value.status < LiveChatStatus.ENDED);
    const hasActiveChat = computed(()=>currentChat.value.status === LiveChatStatus.IN_CHAT || currentChat.value.status === LiveChatStatus.INCOMING);
    const isChatActive = computed(()=>mode.value === LiveChatMode.started || messagesSize.value > 0 || chatWasStarted.value);
    const newMessagesSize = computed(()=>{
        if (messagesSize.value > 0) return messagesSize.value;
        return chatWasStarted.value ? 1 : 0;
    });
    const isOpenSyncStateEnabled = computed(()=>!isOpened.value && isLoggedIn.value && !isChatActive.value && supportBlock.value?.helpButtonMode !== HelpButtonMode.EXT_CHAT);
    /** Mutations */ function updateChatSettings(input) {
        endpoint.value = input.endpoint;
        code.value = input.code;
        session.value = input.session;
        if (!input.endpoint) enabled.value = false;
    }
    function setEnabled(isEnabled) {
        enabled.value = isEnabled;
    }
    function setCurrentChat(chat) {
        currentChat.value = chat;
    }
    function setQuestion(question) {
        currentChat.value.question = question;
    }
    function setLoaded(isLoaded) {
        loaded.value = isLoaded;
    }
    function setMode(liveChatMode) {
        mode.value = liveChatMode;
    }
    function setStatus(status) {
        currentChat.value.status = status;
    }
    function setLastChat(chat) {
        if (chat) {
            lastChat.value = chat;
            if (chat.status === LiveChatStatus.ENDED) checkNewMessages(chat);
        }
    }
    function setMuted(isMuted) {
        muted.value = isMuted;
    }
    function setUserTypingDate(date) {
        userTypingDate.value = date;
    }
    function addUploadedFiles(files) {
        for (const file of files)tempFiles.value.push(file);
    }
    function removeFile(id) {
        const index = tempFiles.value.findIndex((item)=>item.id === id);
        if (index > -1) tempFiles.value.splice(index, 1);
    }
    function clearFiles() {
        tempFiles.value = [];
    }
    function setReadMessagesStorage(response) {
        if (response.status !== LiveChatStatus.ENDED) localStorageManager.setItem(CHAT_MESSAGES_READ_LENGTH, String(response.conversation.length));
        else localStorageManager.removeItem(CHAT_MESSAGES_READ_LENGTH);
    }
    function setHidden(isHidden) {
        hidden.value = isHidden;
        if (!isHidden && lastChat.value) setReadMessagesStorage(lastChat.value);
    }
    function setNewMessagesSize(size) {
        messagesSize.value = size;
    }
    function clearNewMessagesSize() {
        messagesSize.value = 0;
    }
    function setChatDataReceived(value) {
        chatDataReceived.value = value;
    }
    function setOpenOnCreated(value) {
        openOnCreated.value = value;
    }
    function resetState() {
        session.value = '';
        mode.value = LiveChatMode.opened;
        currentChat.value = new DefaultLiveChatObject();
        tempFiles.value = [];
        messagesSize.value = 0;
        chatWasStarted.value = false;
    }
    function setLoadingFilesCounter(value) {
        loadingFilesCounter.value = value;
    }
    function setChatWasStarted(value) {
        chatWasStarted.value = value;
    }
    function setIsOpened(value) {
        isOpened.value = value;
    }
    /** Actions */ async function loadSettings() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (isLoggedIn.value) await Promise.all([
            fetchSettings(silent)
        ]);
    }
    async function fetchSettings() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (!silent) setLoaded(false);
        let response = null;
        try {
            response = await getLiveChatSettings(gqlApi, (node)=>node.queries.liveChat.getDetails, {
                options: {}
            }, {
                silent
            });
        } catch (err) {
            logger.error('Unable to get live chat settings:', err);
            setEnabled(false);
            return;
        }
        if (response) {
            setEnabled(response.chatEnabled || false);
            updateChatSettings(response.chatData);
        }
        if (!silent) setLoaded(true);
    }
    async function openChat(silent) {
        if (isIntercomOptionsListFlow.value) return;
        setLoaded(false);
        await loadSettings(true);
        const isPreopened = mode.value === LiveChatMode.preopened;
        try {
            setMode(isPreopened ? LiveChatMode.busy : LiveChatMode.preopened);
            startText.value = '';
            if (endpoint.value) {
                const response = await api.openLiveChat({
                    code: code.value,
                    session: session.value,
                    login: login.value,
                    platform,
                    endpoint: endpoint.value,
                    silent
                });
                checkOpenedResponse(response, isPreopened);
            }
        } catch (err) {
            logger.error('Unable to open chat', err);
            setMode(LiveChatMode.busy);
        } finally{
            setLoaded(true);
        }
    }
    function checkOpenedResponse(response, isPreopened) {
        if ('OK' === response.result) {
            if (isPreopened || !response.lastChat || response.chatActive) {
                if (response.supportActive) response.chatActive ? setMode(LiveChatMode.started) : setMode(LiveChatMode.opened);
                else setMode(LiveChatMode.busy);
            }
            setLastChat(response.lastChat);
            if (response.chatActive) setStatus(response.chatStatus);
            startText.value = response.startText || '';
        } else setMode(LiveChatMode.busy);
    }
    async function startChat(param) {
        let { question, betId } = param;
        yandexMetrikaStartOnlineChat();
        if (endpoint.value) {
            const response = await api.startLiveChat({
                code: code.value,
                session: session.value,
                login: login.value,
                question,
                platform,
                endpoint: endpoint.value,
                version: 'WEB2',
                betId
            });
            if ('OK' === response.result) {
                clearFiles();
                setStatus(LiveChatStatus.INCOMING);
                setCurrentChat(new DefaultLiveChatObject(LiveChatStatus.INCOMING));
                setQuestion(response.question);
                setMode(LiveChatMode.started);
                setChatDataReceived(false);
                localStorageManager.setItem(STARTED_CHAT_STORAGE_KEY, hashString(login.value));
                return true;
            }
            if ('LIVECHAT_BUSY' === response.errorStatus) setMode(LiveChatMode.busy);
        }
        return false;
    }
    async function handleGetChatResponse(response) {
        checkNewMessages(response);
        await playSound(response);
        if (response.conversation.length > currentChat.value.conversation.length) // eslint-disable-next-line no-param-reassign
        response.typing = false;
        setCurrentChat(response);
        setChatDataReceived(true);
        if (response.status === LiveChatStatus.INCOMING || response.status === LiveChatStatus.IN_CHAT) setMode(LiveChatMode.started);
        if (response.status === LiveChatStatus.ENDED) {
            setLastChat(response);
            setMode(LiveChatMode.preopened);
        }
    }
    async function getChat() {
        if (endpoint.value) {
            const typing = userTypingDate.value >= Date.now() - 1000;
            const response = await api.getLiveChat({
                code: code.value,
                session: session.value,
                login: login.value,
                typing,
                endpoint: endpoint.value,
                modalOpened: !hidden.value,
                silent: true
            });
            if ('OK' === response.result) {
                await handleGetChatResponse(response);
                return true;
            }
        }
        return false;
    }
    function yandexMetrikaStartOnlineChat() {
        analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                onlineChat: {
                    startOnlineChat: true
                }
            }
        });
    }
    function checkNewMessages(response) {
        let newMessagesLength = 0;
        const readLength = Number(localStorageManager.getItem(CHAT_MESSAGES_READ_LENGTH));
        if (readLength > 0) newMessagesLength = response.conversation.length - readLength;
        if (hidden.value) {
            if ((currentChat.value.status < LiveChatStatus.ENDED || readLength > 0) && response.status >= LiveChatStatus.ENDED) newMessagesLength += 1;
            if (currentChat.value.isCreatedByStaff) {
                const staffMessagesCount = response.conversation.filter((msg)=>msg.messageType === LiveChatMessageType.staff).length;
                if (staffMessagesCount <= 1) newMessagesLength = response.conversation.length - readLength;
            }
            setNewMessagesSize(newMessagesLength);
        } else setReadMessagesStorage(response);
        if (!hidden.value && response.status === LiveChatStatus.ENDED) localStorageManager.removeItem(STARTED_CHAT_STORAGE_KEY);
    }
    async function playSound(response) {
        let play = false;
        for (const chat of response.conversation){
            let found = false;
            for (const currChat of currentChat.value.conversation)if (chat.uid === currChat.uid) found = true;
            if (!found && chat.messageType !== LiveChatMessageType.client) play = true;
        }
        if (play && !muted.value) await MediaUtils.playSound('message.mp3');
    }
    async function closeChat() {
        if (endpoint.value) {
            const response = await api.closeLiveChat({
                code: code.value,
                session: session.value,
                login: login.value,
                endpoint: endpoint.value
            });
            if ('OK' === response.result) {
                setStatus(LiveChatStatus.ENDED);
                clearFiles();
                return true;
            }
        }
        return false;
    }
    async function message(msg) {
        if (endpoint.value) {
            const files = [];
            for (const file of tempFiles.value)files.push(file.id);
            const response = await api.sendLiveChatMessage({
                code: code.value,
                session: session.value,
                login: login.value,
                message: msg,
                files,
                endpoint: endpoint.value
            });
            if ('OK' === response.result) {
                for (const id of files)removeFile(id);
                await handleGetChatResponse(response);
                return true;
            }
        }
        return false;
    }
    async function doAction(actionPayload) {
        const currentActions = currentChat.value.actions;
        currentChat.value.actions = [];
        try {
            const response = await api.sendLiveChatAction({
                code: code.value,
                session: session.value,
                login: login.value,
                bot_payload: actionPayload,
                endpoint: endpoint.value
            });
            if ('OK' === response.result) {
                await handleGetChatResponse(response);
                return true;
            }
        } catch (error) {
            currentChat.value.actions = currentActions;
            throw error;
        }
        return false;
    }
    async function surveySubmit(data) {
        if (endpoint.value) {
            const response = await api.surveyLiveChat({
                code: code.value,
                session: session.value,
                login: login.value,
                rating: data.rating,
                comment: data.comment,
                endpoint: endpoint.value
            });
            if ('OK' === response.result) {
                await handleGetChatResponse(response);
                return true;
            }
        }
        return false;
    }
    async function upload(files) {
        if (endpoint.value) {
            const counter = files.length;
            setLoadingFilesCounter(loadingFilesCounter.value + counter);
            try {
                const response = await api.uploadLiveChat({
                    code: code.value,
                    session: session.value,
                    login: login.value,
                    files,
                    endpoint: endpoint.value
                });
                if ('OK' === response.result) addUploadedFiles(response.files);
            } catch  {}
            //
            setLoadingFilesCounter(loadingFilesCounter.value - counter);
        }
    }
    async function removeFileCallback(id) {
        if (endpoint.value) {
            const response = await api.removeFileLiveChat({
                code: code.value,
                session: session.value,
                login: login.value,
                id,
                endpoint: endpoint.value
            });
            if ('OK' === response.result) {
                removeFile(id);
                return true;
            }
        }
        return false;
    }
    function setHiddenCallback(isHidden) {
        setHidden(isHidden);
        if (!isHidden) {
            clearNewMessagesSize();
            setChatWasStarted(false);
            if (!hasActiveChat.value) localStorageManager.removeItem(STARTED_CHAT_STORAGE_KEY);
        }
    }
    function subscribeOnChatCreate() {
        subscribeAuthorized({
            method: 'onTicketChatEvent',
            onMessage: (data)=>{
                if (data) openChat(true);
            },
            isEnabled: isChatActive,
            polling: {
                timeout,
                callback: async ()=>{
                    if (isOpenSyncStateEnabled.value) await openChat(true);
                }
            }
        });
    }
    subscribeOnChatCreate();
    watch(login, async (newValue)=>{
        if (newValue) await openChat(true);
        else resetState();
    }, {
        immediate: true
    });
    return {
        /** State */ loaded,
        enabled,
        muted,
        isOpened,
        code,
        session,
        endpoint,
        mode,
        currentChat,
        lastChat,
        userTypingDate,
        tempFiles,
        messagesSize,
        hidden,
        chatDataReceived,
        openOnCreated,
        loadingFilesCounter,
        chatWasStarted,
        /** Getters */ newMessagesSize,
        staffTyping,
        isChatActive,
        /** Mutations */ updateChatSettings,
        setEnabled,
        setCurrentChat,
        setQuestion,
        setLoaded,
        setMode,
        setStatus,
        addUploadedFiles,
        removeFile,
        clearFiles,
        setHidden,
        setNewMessagesSize,
        clearNewMessagesSize,
        setChatDataReceived,
        resetState,
        setLoadingFilesCounter,
        setChatWasStarted,
        startText,
        /** Actions */ loadSettings,
        fetchSettings,
        setIsOpened,
        setUserTypingDate,
        setOpenOnCreated,
        setMuted,
        openChat,
        startChat,
        getChat,
        closeChat,
        message,
        surveySubmit,
        upload,
        removeFileCallback,
        setHiddenCallback,
        doAction
    };
});
export default useLiveChatStore;
