import { getBetlineLeagueStandings as getBetlineLeagueStandingsByEventId, getLeagueStandingsByLeagueId as getBetlineLeagueStandingsByLeagueId } from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function getBetlineLeagueStandings(options, param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    if ('leagueId' in options) return getBetlineLeagueStandingsByLeagueId(apiClient, (node)=>node.queries.betLine.getLeagueStandingsByLeagueId, {
        options
    }, {
        silent,
        group: RequestGroupScore24
    });
    return getBetlineLeagueStandingsByEventId(apiClient, (node)=>node.queries.betLine.getLeagueStandings, {
        options
    }, {
        silent,
        group: RequestGroupScore24
    });
}
