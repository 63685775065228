import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { TextInput } from 'web/src/components/Input';
import VLogo from 'web/src/components/Logo/VLogo/VLogo.vue';
import useCardNumberInput from 'web/src/modules/payments/form-widgets/FormCardNumber/components/CardNumberInput/composables/useCardNumberInput';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
import { TextInputTypes } from 'web/src/components/Input/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CardNumberInput',
    props: {
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {},
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        selectedCardTypeLogo: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { emitChange, emitInput, emitFocus, emitBlur } = useBaseInputEvents(emit);
        const { logoName, onInput, maskOptions } = useCardNumberInput(props, emitInput);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['card-number-input'])
            }, [
                _createVNode(_unref(TextInput), _mergeProps(props, {
                    ref: "focusable",
                    autofocus: _ctx.autofocus,
                    error: _ctx.error,
                    mask: _unref(maskOptions),
                    "is-ym-disable-keys": "",
                    type: _unref(TextInputTypes).TEL,
                    autocomplete: "cc-number",
                    onInput: _unref(onInput),
                    onChange: _unref(emitChange),
                    onFocus: _unref(emitFocus),
                    onBlur: _unref(emitBlur)
                }), {
                    iconSuffix: _withCtx(()=>[
                            _unref(logoName) ? (_openBlock(), _createBlock(VLogo, {
                                key: 0,
                                name: _unref(logoName),
                                class: _normalizeClass(_ctx.$style['card-number-input__logo'])
                            }, null, 8, [
                                "name",
                                "class"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 16, [
                    "autofocus",
                    "error",
                    "mask",
                    "type",
                    "onInput",
                    "onChange",
                    "onFocus",
                    "onBlur"
                ]),
                _ctx.selectedCardTypeLogo ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['card-number-input__logo-wrapper'])
                }, [
                    _createVNode(VLogo, {
                        name: _ctx.selectedCardTypeLogo,
                        class: _normalizeClass(_ctx.$style['card-number-input__logo-image'])
                    }, null, 8, [
                        "name",
                        "class"
                    ])
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CardNumberInput'
                ]
            ]);
        };
    }
});
