import { getCurrentInstance, nextTick, onMounted } from 'vue';
function getLifeCycleTarget(target) {
    return target ?? getCurrentInstance();
}
/**
 * Call onMounted() if it's inside a component lifecycle, if not, just call the function
 *
 * @param fn
 * @param sync if set to false, it will run in the nextTick() of Vue
 * @param target
 */ export function tryOnMounted(fn) {
    let sync = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, target = arguments.length > 2 ? arguments[2] : void 0;
    const instance = getLifeCycleTarget();
    if (instance) onMounted(fn, target);
    else if (sync) fn();
    else nextTick(fn);
}
