import { computed, ref, toRef } from 'vue';
import { defineStore } from 'pinia';
import { getEgsGames, lobbyTypeSlots, RequestOptionsPriority } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const DEFAULT_LIMIT = 50;
export const useCasinoTopStore = defineStore('casino-top', ()=>{
    const apiClient = useGraphqlClient();
    const siteConfigStore = useSiteConfigStore();
    const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');
    const jackpots = ref(null);
    const isReady = computed(()=>null !== jackpots.value);
    const topEventsJackpotsSectionEnabled = computed(()=>!!sportLineBlock.value?.topEventsJackpotsSectionEnabled);
    const hasJackpots = computed(()=>!!jackpots.value && jackpots.value.length > 0 && topEventsJackpotsSectionEnabled.value);
    function setJackpots(lobbyItems) {
        jackpots.value = lobbyItems.map((item)=>({
                ...item
            }));
    }
    async function updateJackpots() {
        try {
            const result = await getEgsGames(apiClient, (node)=>node.queries.externalGames.getGames, {
                options: {
                    type: lobbyTypeSlots,
                    limit: DEFAULT_LIMIT,
                    isJackpot: true
                }
            }, {
                silent: true,
                priority: RequestOptionsPriority.LOW
            });
            setJackpots(result.games);
        } catch (error) {
            if (null === jackpots.value) setJackpots([]);
            throw error;
        }
    }
    return {
        isReady,
        jackpots,
        hasJackpots,
        updateJackpots
    };
});
