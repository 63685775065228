import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useVFadeCarouselSlide } from 'web/src/components/FadeCarousel/VFadeCarouselSlide/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VFadeCarouselSlide',
    props: {
        currentSlideIndex: {}
    },
    setup (__props) {
        const props = __props;
        const { isSlideShown, isSlideVisible } = useVFadeCarouselSlide(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "fade-carousel-slide",
                    {
                        [_ctx.$style['fade-carousel-slide']]: true,
                        [_ctx.$style['fade-carousel-slide--active']]: _unref(isSlideVisible)
                    }
                ])
            }, [
                _renderSlot(_ctx.$slots, "default", {
                    isSlideShown: _unref(isSlideShown)
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'VFadeCarouselSlide'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'carousel-slide',
                        visible: _unref(isSlideVisible)
                    }
                ]
            ]);
        };
    }
});
