import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { logger } from '@leon-hub/logging';
import { getBetgeniusWidgetConfiguration, getLSportsWidgetConfiguration, getSportradarLiveMatchTrackerConfiguration, getSportradarLiveScoreConfiguration, getSportradarStatisticsConfiguration } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export const useSportlineWidgetsStore = defineStore('sportline-widgets', ()=>{
    const api = useGraphqlClient();
    const siteConfigStore = useSiteConfigStore();
    const widgetsBlock = toRef(siteConfigStore, 'widgetsBlock');
    const sportradarLiveMatchTrackerConfig = ref(null);
    const sportradarStatisticsConfig = ref(null);
    const sportradarLiveScoreConfig = ref(null);
    const lsportsConfig = ref(null);
    const betgeniusConfig = ref(null);
    const productionUrl = computed(()=>lsportsConfig.value?.productionUrl || void 0);
    const sportradarLiveScoreFrameConfigFinal = computed(()=>sportradarLiveScoreConfig.value ? {
            ...sportradarLiveScoreConfig.value,
            iframeUrl: widgetsBlock.value?.iframeUrl
        } : null);
    const sportradarStatisticsConfigFinal = computed(()=>sportradarStatisticsConfig.value ? {
            ...sportradarStatisticsConfig.value,
            iframeUrl: widgetsBlock.value?.iframeUrl
        } : null);
    const sportradarLiveMatchTrackerConfigFinal = computed(()=>sportradarLiveMatchTrackerConfig.value ? {
            ...sportradarLiveMatchTrackerConfig.value,
            iframeUrl: widgetsBlock.value?.iframeUrl
        } : null);
    const lsportsConfigFinal = computed(()=>lsportsConfig.value ? {
            ...lsportsConfig.value,
            iframeUrl: widgetsBlock.value?.iframeUrl
        } : null);
    const betgeniusConfigFinal = computed(()=>betgeniusConfig.value ? {
            ...betgeniusConfig.value,
            productionUrl: productionUrl.value,
            widgetLocales: [
                {
                    locale: 'en-gb',
                    default: true
                },
                {
                    locale: 'ru-ru'
                },
                {
                    locale: 'it-it'
                }
            ],
            iframeUrl: widgetsBlock.value?.iframeUrl
        } : null);
    const isActiveSportsCache = {};
    async function fetchSportradarLiveMatchTracker() {
        try {
            sportradarLiveMatchTrackerConfig.value = await getSportradarLiveMatchTrackerConfiguration(api, (node)=>node.queries.configContent.getSportRadarWidgetConfiguration, {
                options: {}
            });
        } catch (error) {
            logger.error('An error occurred while updating Sportradar Live Match Tracker data', error);
        }
    }
    async function fetchSportradarStatisticsConfig() {
        try {
            if (!sportradarStatisticsConfig.value) sportradarStatisticsConfig.value = await getSportradarStatisticsConfiguration(api, (node)=>node.queries.configContent.getSportRadarStatisticsConfiguration, {
                options: {}
            });
        } catch (error) {
            logger.error('An error occurred while updating Sportradar Statistics data', error);
        }
    }
    async function fetchSportradarLiveScoreConfig() {
        try {
            if (!sportradarLiveScoreConfig.value) sportradarLiveScoreConfig.value = await getSportradarLiveScoreConfiguration(api, (node)=>node.queries.configContent.getSportRadarLiveScoreConfiguration, {
                options: {}
            });
        } catch (error) {
            logger.error('An error occurred while updating Sportradar Live Score data', error);
        }
    }
    async function fetchLSportsWidgetConfig() {
        try {
            lsportsConfig.value = await getLSportsWidgetConfiguration(api, (node)=>node.queries.configContent.getLSportsWidgetConfiguration, {
                options: {}
            });
        } catch (error) {
            logger.error('An error occurred while updating LSportsConfig data', error);
        }
    }
    async function fetchBetgeniusWidgetConfig() {
        try {
            betgeniusConfig.value = await getBetgeniusWidgetConfiguration(api, (node)=>node.queries.configContent.getBetgeniusWidgetConfiguration, {
                options: {}
            });
        } catch (error) {
            logger.error('An error occurred while updating Betgenius config', error);
        }
    }
    async function fetchSportlineConfigs() {
        const promises = [];
        if (!sportradarLiveMatchTrackerConfig.value) promises.push(fetchSportradarLiveMatchTracker());
        if (!lsportsConfig.value) promises.push(fetchLSportsWidgetConfig());
        if (!betgeniusConfig.value) promises.push(fetchBetgeniusWidgetConfig());
        await Promise.all(promises);
    }
    function isActiveLSportWidgetSport(sport) {
        if (isActiveSportsCache[sport]) return isActiveSportsCache[sport];
        isActiveSportsCache[sport] = computed(()=>!!lsportsConfig.value?.activeSports?.map((item)=>item.toLowerCase()).includes(sport));
        return isActiveSportsCache[sport];
    }
    return {
        fetchSportlineConfigs,
        fetchSportradarStatisticsConfig,
        fetchSportradarLiveScoreConfig,
        isActiveLSportWidgetSport,
        sportradarLiveScoreFrameConfigFinal,
        sportradarStatisticsConfigFinal,
        betgeniusConfigFinal,
        sportradarLiveMatchTrackerConfigFinal,
        lsportsConfigFinal
    };
});
