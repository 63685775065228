import { defineStore } from 'pinia';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useSportsListStoreComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import { useSidebarSportsUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useSportlineSidebarSettingsComposable from './composables/useSportlineSidebarSettingsComposable';
import useSportlineSidebarSyncBackgroundRequestsComposable from './composables/useSportlineSidebarSyncBackgroundRequestsComposable';
import useSportlineSidebarFavoriteLeaguesComposable from './composables/useSportlineSidebarFavoriteLeaguesComposable';
const useSportlineSidebarStore = defineStore('sportline-sidebar', ()=>{
    const { isSportFiltersEnabled, maxTopLeaguesInTab, maxTopLeaguesTabsInSidebar } = useSportlineSidebarSettingsComposable();
    const { timeout: sidebarSportsUpdateTimeout } = useSidebarSportsUpdateTimeout();
    const { sportsListFilter, fullSportsList, actualSportsList, isReady, reloadAllSportsLists, setSportsListFilter } = useSportsListStoreComposable({
        updateInterval: sidebarSportsUpdateTimeout
    });
    const { onInit: globalSportlineEventsOnInit } = useGlobalSportlineEvents();
    const { onInit: backgroundRequestsOnInit, setBackgroundUpdateEnabled } = useSportlineSidebarSyncBackgroundRequestsComposable({
        updateInterval: sidebarSportsUpdateTimeout,
        reloadList: reloadAllSportsLists
    });
    const { fullFavoriteLeagues } = useSportlineSidebarFavoriteLeaguesComposable({
        fullSportsList
    });
    // On init
    globalSportlineEventsOnInit();
    backgroundRequestsOnInit();
    return {
        isReady,
        isSportFiltersEnabled,
        maxTopLeaguesInTab,
        maxTopLeaguesTabsInSidebar,
        fullSportsList,
        actualSportsList,
        sportsListFilter,
        fullFavoriteLeagues,
        setSportsListFilter,
        setBackgroundUpdateEnabled
    };
});
export default useSportlineSidebarStore;
