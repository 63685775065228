export const defaultMarketColumnsNames = {
    2: [
        '1',
        '2'
    ],
    3: [
        '1',
        'X',
        '2'
    ]
};
