import { CasinoIconName, VirtualSportIconName } from '../enums';
import { isIconNameKey } from './isIconNameKey';
import { IconName } from '../constants';
export function getIconName(param) {
    let { name, prefix } = param;
    let key = name;
    if (prefix) key = `${prefix}_${name}`.toUpperCase();
    if (key in CasinoIconName) // @ts-ignore
    return CasinoIconName[key];
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    if (key in VirtualSportIconName) // @ts-ignore
    return VirtualSportIconName[key];
    isIconNameKey(key);
    return IconName[key];
}
