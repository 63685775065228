import { computed, onBeforeUnmount, onMounted, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSearchCasinoStore } from 'web/src/modules/search/submodules/casino/store';
import { filterBlockedGroups } from 'web/src/modules/egs/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
import { isCasinoGameRouteName } from 'web/src/modules/casino/utils';
export default function useCasinoSearchContent(props, emits) {
    let isGameClicked = false;
    let isInputTouched = false;
    const router = useRouter();
    const route = useRoute();
    const { $translate } = useI18n();
    const searchCasinoStore = useSearchCasinoStore();
    const egsNavigation = useEgsNavigation(router);
    const gamesRef = toRef(searchCasinoStore, 'games');
    const groupsRef = toRef(searchCasinoStore, 'groups');
    const isLoading = toRef(searchCasinoStore, 'isSearchInProgress');
    const searchText = toRef(searchCasinoStore, 'searchText');
    const isNoSearchResultsRef = toRef(searchCasinoStore, 'isNoSearchResults');
    const isSearchAvailable = toRef(searchCasinoStore, 'isSearchAvailable');
    const isMoreLoadingAvailable = toRef(searchCasinoStore, 'isMoreLoadingAvailable');
    const games = computed(()=>gamesRef.value ?? []);
    const groups = computed(()=>filterBlockedGroups(groupsRef.value));
    const isNoSearchResults = computed(()=>!isSearchAvailable.value || isNoSearchResultsRef.value);
    const hasSearchResults = computed(()=>!isLoading.value && !isNoSearchResultsRef.value);
    const noResultsText = computed(()=>isSearchAvailable.value ? $translate('JAVA_SEARCH_NORESULTS').value : $translate('WEB2_SLOTS_SEARCH_BY').value);
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const replaceGameRoute = computed(()=>props.shouldReplaceItemRoutes || isCasinoGameRouteName(route.name));
    onMounted(()=>{
        emitSearchText(searchText.value);
        emitFocus();
    });
    onBeforeUnmount(()=>{
        if (!isGameClicked) {
            if (isInputTouched) searchCasinoStore.sendAnalytics(false);
            searchCasinoStore.setSearchText('');
        }
    });
    function onGameClick(game) {
        isGameClicked = true;
        emitItemClick();
        searchCasinoStore.sendAnalytics(true);
        egsNavigation.navigateToGamePage(game, void 0, replaceGameRoute.value);
    }
    function onLobbyGameClicked() {
        isGameClicked = true;
        emitItemClick();
    }
    async function onLoadMore() {
        await searchCasinoStore.fetchMoreGames();
    }
    function onInput(value) {
        emitSearchText(value);
        isInputTouched = true;
        searchCasinoStore.setSearchText(value);
    }
    function onInputClear() {
        isInputTouched = false;
        searchCasinoStore.sendAnalytics(false);
        onInput('');
    }
    function emitFocus() {
        emits('focus');
    }
    function emitSearchText(value) {
        emits('search-text', value);
    }
    function emitItemClick() {
        emits('item-click');
    }
    return {
        isNoSearchResults,
        noResultsText,
        isLoading,
        hasSearchResults,
        searchText,
        games,
        groups,
        isMoreLoadingAvailable,
        replaceGameRoute,
        onGameClick,
        onLoadMore,
        onLobbyGameClicked,
        onInputClear,
        onInput
    };
}
