/* eslint-disable class-methods-use-this */ import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { getCasinoLobbyRoutePage } from './utils';
function getLobbyRoute(name, path, access) {
    return getCasinoLobbyRoutePage({
        name,
        path,
        access,
        seoController: ()=>import('web/src/modules/egs/submodules/seo/EgsPageSeoController')
    });
}
function getCasinoLobbyRoute() {
    return getLobbyRoute(CasinoRouteName.CASINO_LOBBY, '/casino/:groupUrl?/:categoryUrl?');
}
function getCasinoLiveLobbyRoute() {
    return getLobbyRoute(CasinoRouteName.CASINO_LIVE_LOBBY, '/live-casino/:groupUrl?/:categoryUrl?');
}
export class CasinoLobbyModule extends AppModule {
    addRoutes(router) {
        router.addDefaultRoute(getCasinoLobbyRoute()).addDefaultRoute(getCasinoLiveLobbyRoute());
        router.addPrefetchRoute({
            name: 'old lobby',
            path: '/slots/:groupUrl?/:categoryUrl?',
            prefetch: ()=>import('./prefetch/CasinoSlotsPrefetchOld')
        });
    }
}
