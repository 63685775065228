import { computed, ref } from 'vue';
import { isVisibleInDOM } from '@leon-hub/utils/src/browser/domUtils';
import { normalizeError } from '@leon-hub/errors';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import { getConfiguredApiUrl, getSettledReCaptchaOptions, getVRecaptchaWidgetSize } from 'web/src/modules/captcha/components/VReCaptcha/composables/utils';
import DefaultReCaptchaService from 'web/src/modules/captcha/services/DefaultReCaptchaService';
import RECAPTCHA_CHALLENGE_SELECTOR from 'web/src/modules/captcha/components/VReCaptcha/constants/reCaptchaChallengeSelector';
export default function useVReCaptcha(props, emit) {
    const root = ref();
    const size = computed(()=>getVRecaptchaWidgetSize(props));
    const settledReCaptchaOptions = computed(()=>getSettledReCaptchaOptions(props, size));
    const configuredApiUrl = computed(()=>getConfiguredApiUrl(props));
    const CaptchaInstance = DefaultReCaptchaService.getInstance();
    const DOMObserverConfig = {
        childList: true,
        attributes: true,
        attributeOldValue: true,
        characterData: false,
        characterDataOldValue: false,
        subtree: true
    };
    let observer;
    let isChallengeOpened = false;
    function onComponentCreated() {
        window?.document?.body;
        observer = new MutationObserver(domChangeCallback);
        observer.observe(window?.document?.body, DOMObserverConfig);
    }
    function domChangeCallback() {
        let mutationRecords = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
        for (const mutationRecord of mutationRecords)if ('attributes' === mutationRecord.type) {
            const challengeIframe = mutationRecord.target.querySelectorAll(RECAPTCHA_CHALLENGE_SELECTOR);
            const target = challengeIframe[0];
            challengeEmitToggle(challengeIframe.length, target);
        }
    }
    function challengeEmitToggle(length, target) {
        if (length) {
            if (isVisibleInDOM(target)) {
                if (!isChallengeOpened) emitChallengeOpened();
            } else if (target && isChallengeOpened) emitChallengeClosed();
        }
    }
    function execute() {
        return CaptchaInstance.execute();
    }
    function emitVerify(captchaToken) {
        CaptchaInstance.notifyExecuting({
            captchaResponse: captchaToken
        });
        emit('verify', {
            captchaResponse: captchaToken
        });
        return {
            captchaResponse: captchaToken
        };
    }
    function reset() {
        CaptchaInstance.reset();
    }
    function emitExpired() {
        reset();
        emitError();
        emit('expired');
    }
    function emitError(error) {
        const payload = {
            reCaptchaSize: size.value,
            siteKey: props.siteKey
        };
        let captchaError;
        if (error instanceof CaptchaServiceError) {
            captchaError = error;
            captchaError.payload = payload;
        } else captchaError = new CaptchaServiceError({
            originalError: normalizeError(error),
            payload
        });
        emit('error', captchaError);
    }
    function emitRender(id) {
        emit('render', id);
        return id;
    }
    function emitChallengeClosed() {
        isChallengeOpened = false;
        emit('challenge-is-closed');
    }
    function emitChallengeOpened() {
        isChallengeOpened = true;
        emit('challenge-is-opened');
    }
    async function onComponentMounted() {
        try {
            await CaptchaInstance.tryLoadRecaptchaScript(configuredApiUrl.value);
            CaptchaInstance.checkRecaptchaLoad();
            const options = {
                ...settledReCaptchaOptions.value,
                callback: emitVerify,
                'expired-callback': emitExpired,
                'error-callback': emitError
            };
            if (root.value instanceof HTMLElement) CaptchaInstance.render(root.value, options, emitRender);
            if (props.autoExecute) await execute();
        } catch (rawError) {
            emitError(rawError);
        }
    }
    return {
        root,
        execute,
        reset,
        onComponentCreated,
        onComponentMounted
    };
}
