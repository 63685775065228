import { ref, computed, watch } from 'vue';
import { useDebounce } from '@leon-hub/debounce';
import { InputEventType } from 'web/src/components/Input/enums';
import useAddressSearch from './useAddressSearch';
export default function useAutocompleteAddress(props, emit) {
    const { findAddressByQuery } = useAddressSearch();
    const inputValue = ref(props.value || '');
    const predictions = ref([]);
    const isFocused = ref(false);
    const preselectedIndex = ref(-1);
    const eventToEmit = computed(()=>({
            target: {
                value: inputValue.value,
                name: props.name || ''
            }
        }));
    // eslint-disable-next-line arrow-body-style
    const placeTypes = computed(()=>/**
     * @example ['(cities)'] - search only cities
     * @example ['street_address'] - street address
     * @see https://developers.google.com/maps/documentation/javascript/supported_types
     * LEONWEB-9819 - only cities or address is allowed
     * address is default, (cities) is enabled by isCityType prop
     * */ props.isCityType ? [
            '(cities)'
        ] : [
            'address'
        ]);
    const setPreselectedIndex = function() {
        let index = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : -1;
        preselectedIndex.value = predictions.value[index] ? index : -1;
    };
    watch(()=>props.value, (value)=>{
        inputValue.value = value || '';
    });
    const resetPredictionsOnRelatedDataChange = (newValue, oldValue)=>{
        if (newValue !== oldValue) {
            setPreselectedIndex(-1);
            predictions.value = [];
        }
    };
    watch(()=>props.countryCode, resetPredictionsOnRelatedDataChange);
    watch(()=>props.selectedCity, resetPredictionsOnRelatedDataChange);
    const emitFocus = ()=>{
        emit(InputEventType.FOCUS, eventToEmit.value);
    };
    const emitBlur = ()=>{
        emit(InputEventType.BLUR, eventToEmit.value);
    };
    const onChange = ()=>{
        emit(InputEventType.CHANGE, eventToEmit.value);
    };
    const emitInput = ()=>{
        emit(InputEventType.INPUT, eventToEmit.value);
    };
    const showPredictions = computed(()=>!!(isFocused.value && predictions.value.length));
    const handleAddressSearch = useDebounce(async (query)=>{
        const computedQuery = query && props.selectedCity ? `${props.selectedCity}, ${query}` : query;
        predictions.value = await findAddressByQuery({
            address: computedQuery,
            countryCode: props.countryCode,
            types: placeTypes.value
        });
    }, 500);
    const onFocus = ()=>{
        isFocused.value = true;
        emitFocus();
        if (inputValue.value && !predictions.value.length) handleAddressSearch(inputValue.value);
    };
    const onBlur = ()=>{
        isFocused.value = false;
        setPreselectedIndex(-1);
        emitBlur();
    };
    const onInput = (event)=>{
        const { value } = event.target;
        inputValue.value = value;
        emitInput();
        handleAddressSearch(value);
    };
    const onClear = ()=>{
        predictions.value = [];
        inputValue.value = '';
        setPreselectedIndex(-1);
        emitInput();
    };
    const selectPrediction = (prediction)=>{
        predictions.value = [];
        setPreselectedIndex(-1);
        inputValue.value = prediction.structured_formatting.main_text;
        emitInput();
    };
    const preselectByIndex = function() {
        let index = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : -1;
        const prediction = predictions.value[index];
        if (prediction) selectPrediction(prediction);
    };
    const movePreselectedIndex = function() {
        let moveDown = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        let nextIndex = moveDown ? preselectedIndex.value + 1 : preselectedIndex.value - 1;
        if (nextIndex >= predictions.value.length) nextIndex = 0;
        if (nextIndex < 0) nextIndex = predictions.value.length - 1;
        setPreselectedIndex(nextIndex);
    };
    const onKeyDown = (event)=>{
        const { key } = event;
        if (predictions.value.length) {
            if ('Enter' === key && preselectedIndex.value > -1) {
                event.preventDefault();
                preselectByIndex(preselectedIndex.value);
                return;
            }
            if ('ArrowDown' === key || 'ArrowUp' === key) {
                event.preventDefault();
                movePreselectedIndex('ArrowDown' === key);
            }
        }
    };
    return {
        inputValue,
        predictions,
        onInput,
        onChange,
        onClear,
        onBlur,
        onFocus,
        showPredictions,
        selectPrediction,
        preselectedIndex,
        onKeyDown,
        setPreselectedIndex
    };
}
