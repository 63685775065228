import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { ApiErrorCode } from '@leon-hub/api';
import { AppError } from '@leon-hub/app-errors';
export class ParseSportEventError extends AppError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_UNEXPECTED_ERROR,
            message: `Sportline: ${options?.message || 'Unexpected parse sport event response error'}`
        });
        _define_property(this, "response", void 0);
        _define_property(this, "buildOptions", void 0);
        this.response = options.response;
        this.buildOptions = options.buildOptions;
    }
}
