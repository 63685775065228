import { toRef, getCurrentInstance, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRouterStore, useKeepAliveStore } from 'web/src/modules/core/store';
export function useKeepAlive(routeNames) {
    const currentRouteName = toRef(useRouterStore(), 'currentRouteName');
    const { addKeepAliveComponent, removeKeepAliveComponent } = useKeepAliveStore();
    let componentName;
    let mountedRouteName;
    onMounted(()=>{
        // eslint-disable-next-line no-underscore-dangle
        componentName = getCurrentInstance()?.type?.__name;
        mountedRouteName = currentRouteName.value;
        if (componentName) addKeepAliveComponent(componentName);
    });
    function removeComponentFromKeepAlive() {
        if (componentName) removeKeepAliveComponent(componentName);
    }
    onBeforeUnmount(removeComponentFromKeepAlive);
    // Add watcher only if routeNames provided
    if (routeNames) watch(currentRouteName, (newValue)=>{
        if (newValue && ![
            ...routeNames,
            mountedRouteName
        ].includes(newValue)) removeComponentFromKeepAlive();
    });
}
