import { Deferred } from '@leon-hub/utils';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
export default function useCaptchaResolver() {
    let captchaProcess;
    const eventBus = useEventsBus();
    async function resolveCaptcha(captchaType) {
        eventBus.emit(BusEvent.CAPTCHA_VERIFICATION, {
            captchaType
        });
        captchaProcess = new Deferred();
        return captchaProcess.promise;
    }
    function setCaptchaToken(token) {
        captchaProcess.resolve(token);
    }
    return {
        resolveCaptcha,
        setCaptchaToken
    };
}
