import { toRef, computed, ref } from 'vue';
import hasSlot from 'web/src/utils/vue/hasSlot';
import { useBaseButton } from 'web/src/components/Button/VButton/composables';
export default function useAdventButton(props, slots) {
    const { computedAttributes, iconComponent } = useBaseButton(props, slots);
    const mainTag = ref();
    const hasDefaultSlot = computed(()=>hasSlot(slots, 'default'));
    const isIconOnly = computed(()=>!props.label && !hasDefaultSlot.value && !!props.iconName);
    const isIconRight = toRef(()=>!!props.iconName && !!props.iconRight);
    return {
        mainTag,
        isIconOnly,
        isIconRight,
        computedAttributes,
        iconComponent
    };
}
