import { reduceListWithSportEventsCounters } from '../getCounters';
export default function filterSportListRegions(sportElements, filter) {
    return sportElements.map((sportElement)=>{
        const regions = sportElement.regions.filter((regionElement)=>filter(regionElement));
        return {
            ...sportElement,
            regions,
            counters: reduceListWithSportEventsCounters(regions)
        };
    }).filter((sportElement)=>sportElement.regions.length > 0);
}
