import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, computed } from 'vue';
import { StatisticChartBarType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import { useChartBarWidth } from './composables/useChartBarWidth';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticChartBar',
    props: {
        type: {
            default: StatisticChartBarType.NARROW
        },
        toFixed: {},
        homePercentage: {},
        drawPercentage: {},
        awayPercentage: {}
    },
    setup (__props) {
        const props = __props;
        const isLabeledType = computed(()=>props.type === StatisticChartBarType.LABELED);
        const isNarrowType = computed(()=>props.type === StatisticChartBarType.NARROW);
        const { homeChartWidth, drawsChartWidth, awayChartWidth, homePercentageLabel, drawPercentageLabel, awayPercentageLabel } = useChartBarWidth({
            toFixed: toRef(props, 'toFixed', null),
            homePercentage: toRef(props, 'homePercentage', 0),
            drawPercentage: toRef(props, 'drawPercentage', 0),
            awayPercentage: toRef(props, 'awayPercentage', 0)
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "statistic-chart-bar__container",
                    {
                        'statistic-chart-bar__container--labeled': isLabeledType.value,
                        'statistic-chart-bar__container--narrow': isNarrowType.value
                    }
                ])
            }, [
                _unref(homeChartWidth) > 0 ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "statistic-chart-bar statistic-chart-bar--host",
                    style: _normalizeStyle({
                        width: `${_unref(homeChartWidth)}%`
                    })
                }, [
                    isLabeledType.value && _unref(homePercentageLabel) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createTextVNode(_toDisplayString(_unref(homePercentageLabel)), 1)
                    ], 64)) : _createCommentVNode("", true)
                ], 4)) : _createCommentVNode("", true),
                _unref(drawsChartWidth) > 0 ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "statistic-chart-bar statistic-chart-bar--draws",
                    style: _normalizeStyle({
                        width: `${_unref(drawsChartWidth)}%`
                    })
                }, [
                    isLabeledType.value && _unref(drawPercentageLabel) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createTextVNode(_toDisplayString(_unref(drawPercentageLabel)), 1)
                    ], 64)) : _createCommentVNode("", true)
                ], 4)) : _createCommentVNode("", true),
                _unref(awayChartWidth) > 0 ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "statistic-chart-bar statistic-chart-bar--guest",
                    style: _normalizeStyle({
                        width: `${_unref(awayChartWidth)}%`
                    })
                }, [
                    isLabeledType.value && _unref(awayPercentageLabel) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createTextVNode(_toDisplayString(_unref(awayPercentageLabel)), 1)
                    ], 64)) : _createCommentVNode("", true)
                ], 4)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'StatisticChartBar'
                ]
            ]);
        };
    }
});
