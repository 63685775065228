export default function getLobbyLocation(baseRouteName) {
    let categoryUrl = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '', urlGroup = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : '';
    const params = {};
    if (categoryUrl) params.categoryUrl = categoryUrl;
    if (urlGroup) params.groupUrl = urlGroup;
    return {
        name: baseRouteName,
        params
    };
}
