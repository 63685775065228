import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
    key: 0,
    "data-test-id": "captcha-container"
};
const _hoisted_2 = {
    key: 3,
    type: "submit",
    hidden: ""
};
import { inject, provide, ref, toRef, onMounted } from 'vue';
import { ScrollbarProvidableContext } from '@components/v-scrollbar';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { useOnAppContentLoadedCallback } from 'web/src/modules/core/composables/content';
import { FormProvidable } from '../../enums';
import widgetList from '../../utils/widgetList';
import FormCaptcha from '../FormCaptcha';
import { isFormValidationDisabled } from './utils';
import { useErrorPatterns, useForm, useFormAutofocus, useFormEmits } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VForm',
    props: {
        schema: {},
        uiSchema: {},
        customErrors: {
            default: ()=>({})
        },
        isFromModal: {
            type: Boolean
        },
        isPending: {
            type: Boolean
        },
        focusOnError: {
            type: Boolean
        }
    },
    emits: [
        "submit",
        "input",
        "change",
        "focus",
        "blur",
        "filled",
        "disabled-button-click",
        "icon-prefix-click",
        "icon-suffix-click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const customFormContext = ref({
            isFromModal: props.isFromModal ?? false
        });
        provide(FormProvidable.FormContext, customFormContext);
        const customScrollbarContext = inject(ScrollbarProvidableContext.ScrollbarContext, ref({
            isReady: true,
            scrollTop: 0
        }));
        const { isDevIP } = useIsDevIP();
        const validationDisabled = !!isDevIP.value && isFormValidationDisabled();
        const { errorPatterns } = useErrorPatterns();
        const uiSchemaRef = toRef(props, 'uiSchema');
        const { focusedElementId, focusStart, focus, focusByIndex, focusByName } = useFormAutofocus(uiSchemaRef);
        const { handleBlur, handleInput, handleFocus, handleSubmit, reset, restoreFromSnapShot, getSnapshot, getCurrentOutput, showValidationErrors, setLinesRef, fields, captchaField, buttonProps } = useForm({
            schema: toRef(props, 'schema'),
            uiSchema: uiSchemaRef,
            customErrors: toRef(props, 'customErrors'),
            isPending: toRef(props, 'isPending'),
            focusOnError: toRef(props, 'focusOnError'),
            validationDisabled,
            errorPatterns,
            focusByName
        });
        const { performOnLoad } = useOnAppContentLoadedCallback();
        onMounted(()=>{
            /** on app start, internal autofocus on field can be triggered before
   * content is visible over skeleton, so this call to focus again after content is loaded */ performOnLoad(focusStart, {
                onlyIfNotLoaded: true
            });
        });
        const { onChange, onInput, onFocus, onBlur, onSubmit, onFilled, onIconPrefixClick, onIconSuffixClick, onDisabledButtonClick } = useFormEmits(emit, {
            handleInput,
            handleFocus,
            handleBlur,
            handleSubmit,
            getCurrentOutput
        });
        __expose({
            submit: onSubmit,
            reset,
            focus,
            getSnapshot,
            restoreFromSnapShot,
            focusByName,
            focusByIndex,
            getCurrentOutput,
            showValidationErrors
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("form", {
                class: _normalizeClass(_ctx.$style['form']),
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onSubmit) && _unref(onSubmit)(...args);
                }, [
                    "stop",
                    "prevent"
                ]))
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['form__fields'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fields), (param)=>{
                        let { widget, id, widgetProps, fieldProps } = param;
                        return _openBlock(), _createElementBlock(_Fragment, {
                            key: id
                        }, [
                            fieldProps.hidden ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [], 64)) : (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                ref_for: true,
                                ref: (el)=>_unref(setLinesRef)(el, id),
                                class: _normalizeClass({
                                    [_ctx.$style['form__control']]: true,
                                    [_ctx.$style['form__control-short']]: fieldProps.shortWidth
                                })
                            }, [
                                _unref(widgetList)[widget] ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(widgetList)[widget]), _mergeProps({
                                    key: 0,
                                    ref_for: true
                                }, widgetProps, {
                                    autofocus: _unref(customScrollbarContext).isReady && _unref(focusedElementId) === id,
                                    onInput: _unref(onInput),
                                    onFocus: _unref(onFocus),
                                    onChange: _unref(onChange),
                                    onBlur: _unref(onBlur),
                                    onFilled: _unref(onFilled),
                                    onIconPrefixClick: _unref(onIconPrefixClick),
                                    onIconSuffixClick: _unref(onIconSuffixClick)
                                }), null, 16, [
                                    "autofocus",
                                    "onInput",
                                    "onFocus",
                                    "onChange",
                                    "onBlur",
                                    "onFilled",
                                    "onIconPrefixClick",
                                    "onIconSuffixClick"
                                ])) : _renderSlot(_ctx.$slots, widget, {
                                    key: 1,
                                    props: {
                                        ...widgetProps,
                                        autofocus: _unref(customScrollbarContext).isReady && _unref(focusedElementId) === id
                                    },
                                    events: {
                                        input: _unref(onInput),
                                        focus: _unref(onFocus),
                                        change: _unref(onChange),
                                        blur: _unref(onBlur),
                                        filled: _unref(onFilled),
                                        'icon-prefix-click': _unref(onIconPrefixClick),
                                        'icon-suffix-click': _unref(onIconSuffixClick)
                                    }
                                })
                            ], 2))
                        ], 64);
                    }), 128))
                ], 2),
                _unref(captchaField) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_unref(FormCaptcha), _mergeProps(_unref(captchaField), {
                        onInput: _unref(onInput)
                    }), null, 16, [
                        "onInput"
                    ])
                ])) : _createCommentVNode("", true),
                _ctx.isPending ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['form__mask'])
                }, null, 2)) : _createCommentVNode("", true),
                _unref(buttonProps) ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['form__footer'])
                }, [
                    _withDirectives(_createVNode(VButton, _mergeProps(_unref(buttonProps), {
                        onClick: _unref(onSubmit)
                    }), null, 16, [
                        "onClick"
                    ]), [
                        [
                            _directive_data_test,
                            {
                                el: _unref(buttonProps)?.id
                            }
                        ]
                    ]),
                    _unref(buttonProps).disabled ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['form__button-mask']),
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onDisabledButtonClick) && _unref(onDisabledButtonClick)(...args);
                        })
                    }, null, 2)) : _createCommentVNode("", true)
                ], 2)) : (_openBlock(), _createElementBlock("input", _hoisted_2))
            ], 34)), [
                [
                    _directive_auto_id,
                    'VForm'
                ]
            ]);
        };
    }
});
