import { defineStore } from 'pinia';
import { ref } from 'vue';
import { SportlineNavigationSection } from 'web/src/modules/sportline/submodules/navigation/enums';
import { normalizeSportlineNavigationBetweenPagesFilter } from './utils';
export const useSportlineNavigationStore = defineStore('sportline-navigation', ()=>{
    const sportlineReloadFiltersLocation = ref(null);
    const sportlineNavigationBetweenPagesFilter = ref(null);
    const sportlineNavigationSection = ref(SportlineNavigationSection.Default);
    function setSportlineReloadFiltersLocation(value) {
        sportlineReloadFiltersLocation.value = value;
    }
    function setSportlineNavigationBetweenPagesFilter(filter) {
        const normalizedFilter = normalizeSportlineNavigationBetweenPagesFilter({
            period: filter?.activeKickoffPeriod,
            from: filter?.customDateFrom ? String(filter.customDateFrom) : void 0,
            to: filter?.customDateTo ? String(filter.customDateTo) : void 0
        });
        sportlineNavigationBetweenPagesFilter.value = normalizedFilter ?? null;
    }
    function setSportlineNavigationSection(section) {
        sportlineNavigationSection.value = section ?? SportlineNavigationSection.Default;
    }
    return {
        sportlineReloadFiltersLocation,
        sportlineNavigationBetweenPagesFilter,
        setSportlineReloadFiltersLocation,
        setSportlineNavigationBetweenPagesFilter,
        sportlineNavigationSection,
        setSportlineNavigationSection
    };
});
