import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { PhoneInput } from 'web/src/components/Input';
import useInternationalPhoneInput from './useInternationalPhoneInput';
export default /*@__PURE__*/ _defineComponent({
    __name: 'InternationalPhoneInput',
    props: {
        prefix: {},
        countryCode: {},
        mask: {},
        disablePlaceholder: {},
        prefixes: {},
        logo: {},
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'phone'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {},
        autocomplete: {},
        emitFilled: {
            type: Boolean
        },
        maxSymbols: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur",
        "filled"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { onInput, onChange, onBlur, onFocus, onFilled, computedPrefixesData, computedCountryCode } = useInternationalPhoneInput(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(PhoneInput), {
                autofocus: _ctx.autofocus,
                name: _ctx.name,
                disabled: _ctx.disabled,
                label: _ctx.label,
                prefix: _ctx.prefix,
                value: _ctx.value,
                "country-code": _unref(computedCountryCode),
                placeholder: _ctx.placeholder,
                autocomplete: _ctx.autocomplete,
                mask: _ctx.mask,
                "disable-placeholder": _ctx.disablePlaceholder,
                "emit-filled": _ctx.emitFilled,
                "max-symbols": _ctx.maxSymbols,
                prefixes: _unref(computedPrefixesData),
                hint: _ctx.hint,
                "hint-right": _ctx.hintRight,
                "hint-link": _ctx.hintLink,
                "hint-link-right": _ctx.hintLinkRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                logo: _ctx.logo,
                onInput: _unref(onInput),
                onChange: _unref(onChange),
                onFocus: _unref(onFocus),
                onBlur: _unref(onBlur),
                onFilled: _unref(onFilled)
            }, null, 8, [
                "autofocus",
                "name",
                "disabled",
                "label",
                "prefix",
                "value",
                "country-code",
                "placeholder",
                "autocomplete",
                "mask",
                "disable-placeholder",
                "emit-filled",
                "max-symbols",
                "prefixes",
                "hint",
                "hint-right",
                "hint-link",
                "hint-link-right",
                "error",
                "error-right",
                "logo",
                "onInput",
                "onChange",
                "onFocus",
                "onBlur",
                "onFilled"
            ])), [
                [
                    _directive_auto_id,
                    'InternationalPhoneInput'
                ]
            ]);
        };
    }
});
