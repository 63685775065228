import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { toRefs } from 'vue';
import { IconName } from '@leon-hub/icons';
import { FavoriteIconColor } from 'web/src/components/FavoriteToggle/VFavoriteToggle/enums';
import VFavoriteToggle from 'web/src/components/FavoriteToggle/VFavoriteToggle/VFavoriteToggle.vue';
import { useLeagueFavoriteIndicator } from 'web/src/modules/sportline/submodules/favorites/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FavoriteLeagueIndicator',
    props: {
        sportId: {},
        leagueId: {}
    },
    setup (__props) {
        const props = __props;
        const inactiveFavoriteIcon = {
            name: IconName.STAR_OUTLINE
        };
        const activeFavoriteIcon = {
            name: IconName.STAR
        };
        const { isFavorite, isOnlyPending, toggleLeagueFavoriteState } = useLeagueFavoriteIndicator(toRefs(props));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VFavoriteToggle, {
                key: `${_ctx.sportId}_${_ctx.leagueId}_${String(_unref(isFavorite))}`,
                "active-icon": activeFavoriteIcon,
                "active-icon-color": _unref(FavoriteIconColor).TEXT_DEFAULT,
                "inactive-icon": inactiveFavoriteIcon,
                "is-favorite": _unref(isFavorite),
                "is-only-pending": _unref(isOnlyPending),
                class: "favorite-league-indicator",
                onClick: _withModifiers(_unref(toggleLeagueFavoriteState), [
                    "prevent"
                ])
            }, null, 8, [
                "active-icon-color",
                "is-favorite",
                "is-only-pending",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'FavoriteLeagueIndicator'
                ]
            ]);
        };
    }
});
