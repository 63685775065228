import RouteName from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
let FastGamesRedirectPrefetch = class FastGamesRedirectPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        next(router.resolve301location({
            name: RouteName.QUICK_BETS
        }));
        return Promise.resolve();
    }
};
export { FastGamesRedirectPrefetch as default };
