import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function useBonusCodesOverview(props, emits) {
    const { $translate } = useI18n();
    const counterText = computed(()=>`${props.currentCount}/${props.maxCount}`);
    const codesLimitReached = computed(()=>{
        if (props.currentCount && props.maxCount) return props.currentCount >= props.maxCount;
        return false;
    });
    const buttonDisabled = computed(()=>{
        if (props.buttonIsLink) return false;
        return codesLimitReached.value;
    });
    const buttonKind = computed(()=>{
        if (!props.buttonIsLink) return ButtonKind.PRIMARY;
        return codesLimitReached.value ? ButtonKind.SECONDARY : ButtonKind.PRIMARY;
    });
    const buttonLabel = computed(()=>{
        const add = props.addButtonText || $translate('WEB2_ADD_BONUS_CODE').value;
        const list = $translate('WEB2_BONUS_CODES_LIST').value;
        if (!props.buttonIsLink) return add;
        return codesLimitReached.value ? list : add;
    });
    function emitButtonClick() {
        emits('button-click');
    }
    function emitHelp() {
        emits('help');
    }
    return {
        counterText,
        buttonDisabled,
        buttonKind,
        buttonLabel,
        emitButtonClick,
        emitHelp
    };
}
