import { mergeDefaults as _mergeDefaults, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { useSlots } from 'vue';
import { getVButtonDefaultProps } from '../VButton/utils/getVButtonDefaultProps';
import { useBaseButton, useButtonEmits, useVButton } from '../VButton/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LButton',
    props: /*@__PURE__*/ _mergeDefaults({
        rounded: {
            type: Boolean
        },
        kind: {},
        height: {},
        textColor: {},
        isUppercase: {
            type: Boolean
        },
        iconSize: {},
        hasAnimation: {
            type: Boolean
        },
        isAbsolutePosition: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        for: {},
        fullWidth: {
            type: Boolean
        },
        href: {},
        iconName: {},
        iconRight: {
            type: Boolean
        },
        isAutofocus: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        },
        isNoPaddings: {
            type: Boolean
        },
        label: {},
        role: {},
        tag: {},
        target: {},
        type: {},
        id: {}
    }, getVButtonDefaultProps()),
    emits: [
        "click",
        "keydown",
        "mousedown",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const slots = useSlots();
        const { mainTag, iconComponent, isIconOnly, isIconRight, isAnimated, computedAttributes, onTouchStart, onTouchEnd, focusOnElement } = useBaseButton(props, slots);
        const { isDisabledState, iconProps, iconClassNames, isIconLeft, isAnimation, isSpecialHeight, isSpecialKind } = useVButton(props, slots, isAnimated);
        const { click, keydown, mousedown, focus, blur } = useButtonEmits(emits);
        __expose({
            focus: focusOnElement
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), _mergeProps(_unref(computedAttributes), {
                id: _ctx.id,
                ref_key: "mainTag",
                ref: mainTag,
                class: {
                    [_ctx.$style['button']]: true,
                    [_ctx.$style['button--rounded']]: props.rounded,
                    [_ctx.$style['button--icon-only']]: _unref(isIconOnly),
                    [_ctx.$style['button--icon-left']]: _unref(isIconLeft),
                    [_ctx.$style['button--icon-right']]: _unref(isIconRight),
                    [_ctx.$style['button--full-width']]: props.fullWidth,
                    [_ctx.$style[`button--kind-${props.kind}`]]: _unref(isSpecialKind),
                    [_ctx.$style[`button--height-${props.height}`]]: _unref(isSpecialHeight),
                    [_ctx.$style['button--is-loading']]: _ctx.isLoading,
                    [_ctx.$style['button--uppercase']]: _ctx.isUppercase,
                    [_ctx.$style['button--is-absolute']]: _ctx.isAbsolutePosition,
                    [_ctx.$style['button--animation']]: _unref(isAnimation),
                    [_ctx.$style[`button--text-color-${_ctx.textColor}`]]: !!_ctx.textColor,
                    [_ctx.$style['button--no-paddings']]: props.isNoPaddings
                },
                disabled: _unref(isDisabledState),
                role: _ctx.role,
                onTouchstart: _unref(onTouchStart),
                onTouchend: _unref(onTouchEnd),
                onClick: _unref(click),
                onKeydown: _unref(keydown),
                onMousedown: _unref(mousedown),
                onFocus: _unref(focus),
                onBlur: _unref(blur)
            }), {
                default: _withCtx(()=>[
                        _unref(iconComponent) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(iconComponent)), _mergeProps({
                            key: 0
                        }, _unref(iconProps), {
                            class: _unref(iconClassNames).map((name)=>_ctx.$style[name])
                        }), null, 16, [
                            "class"
                        ])) : _createCommentVNode("", true),
                        _ctx.$slots.default || _ctx.label ? _renderSlot(_ctx.$slots, "default", {
                            key: 1
                        }, ()=>[
                                _createTextVNode(_toDisplayString(_ctx.label), 1)
                            ]) : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "icon")
                    ]),
                _: 3
            }, 16, [
                "id",
                "class",
                "disabled",
                "role",
                "onTouchstart",
                "onTouchend",
                "onClick",
                "onKeydown",
                "onMousedown",
                "onFocus",
                "onBlur"
            ])), [
                [
                    _directive_auto_id,
                    'Button'
                ]
            ]);
        };
    }
});
