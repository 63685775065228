import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { PasswordValidateTemplateItemStatus } from 'web/src/components/PasswordValidator/enums';
import useVPasswordValidator from './composables/useVPasswordValidator';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PasswordValidator',
    props: {
        groups: {
            default: ()=>[]
        },
        password: {
            default: ''
        },
        isFocused: {
            type: Boolean
        },
        successMessage: {
            default: ''
        },
        error: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { templates } = useVPasswordValidator(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("ul", {
                class: _normalizeClass(_ctx.$style['password-validator'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(templates), (template)=>(_openBlock(), _createElementBlock("li", {
                        key: template.message,
                        class: _normalizeClass({
                            [_ctx.$style['password-validator__line']]: true,
                            [_ctx.$style['password-validator__line--success']]: template.status === _unref(PasswordValidateTemplateItemStatus).Success,
                            [_ctx.$style['password-validator__line--error']]: template.status === _unref(PasswordValidateTemplateItemStatus).Error
                        })
                    }, [
                        _createVNode(_unref(VIcon), {
                            class: _normalizeClass(_ctx.$style['password-validator__icon']),
                            size: _unref(IconSize).SIZE_16,
                            name: template.status === _unref(PasswordValidateTemplateItemStatus).Success ? _unref(IconName).CHECK : _unref(IconName).DOT
                        }, null, 8, [
                            "class",
                            "size",
                            "name"
                        ]),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['password-validator__message'])
                        }, _toDisplayString(template.message), 3)
                    ], 2))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PasswordValidator'
                ]
            ]);
        };
    }
});
