import { computed, onBeforeMount, onBeforeUnmount, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { PromoActionType } from '@leon-hub/api-sdk';
import RouteName, { CasinoRouteName } from '@leon-hub/routing-config-names';
import { logger } from '@leon-hub/logging';
import { useEventsBus } from '@leon-hub/event-bus';
import { getLeaderBoardList, getLeaderBoardOptions } from 'web/src/modules/promotions/store/utils';
import useFortuneWheelStore from 'web/src/modules/fortune-wheel/store/useFortuneWheelStore';
import { useLeaderBoardStore, usePromotionsStore } from 'web/src/modules/promotions/store';
import { useAppDownloadStore } from 'web/src/modules/app-download/store';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
export default function usePromotionDetailsCore() {
    const router = useRouter();
    const { $translate } = useI18n();
    const { locale } = useI18nLocale();
    useEventsBus();
    const board = ref();
    const terms = ref();
    const promotionsStore = usePromotionsStore();
    const leaderBoardStore = useLeaderBoardStore();
    const fortuneWheelStore = useFortuneWheelStore();
    const currentPromotion = toRef(promotionsStore, 'promotion');
    const promotionsLoaded = toRef(promotionsStore, 'promotionsLoaded');
    const customerCategory = toRef(promotionsStore, 'customerCategory');
    const { getLeaderBoard } = leaderBoardStore;
    const leaderBoardListMap = toRef(leaderBoardStore, 'leaderBoardListMap');
    const leaderBoardOptionsMapRecord = toRef(leaderBoardStore, 'leaderBoardOptionsMapRecord');
    const promotionButton = computed(()=>currentPromotion.value?.actionButton);
    const fortuneWheelId = toRef(fortuneWheelStore, 'fortuneWheelId');
    const { loadPromotionDetails, setOpenedActiveUrl } = promotionsStore;
    const actionUrl = ref('');
    const isDoingActionButton = ref(false);
    const currentPromoLeaderboardDate = computed(()=>{
        if (currentPromotion.value?.activeTournamentStartDate && currentPromotion.value.activeTournamentEndDate) return `
      ${$translate('WEB2_TOURNAMENT').value}:
      ${DateTime.formatTimeStamp(Date.parse(currentPromotion.value.activeTournamentStartDate), TimeFormats.dayMonthShortYear, locale.value)} -
      ${DateTime.formatTimeStamp(Date.parse(currentPromotion.value.activeTournamentEndDate), TimeFormats.dayMonthShortYear, locale.value)}`;
        return '';
    });
    const leaderBoard = computed(()=>{
        const url = currentPromotion.value?.actionUrl || actionUrl.value;
        if (!url) return [];
        return getLeaderBoardList(url, leaderBoardListMap.value);
    });
    const haveLeaderBoard = computed(()=>leaderBoard.value.length > 0);
    const isLeaderBoardPromo = computed(()=>currentPromotion.value?.promotionType === PromoActionType.LEADERBOARD_EG || currentPromotion.value?.promotionType === PromoActionType.LEADERBOARD_SPORTS);
    const leaderBoardProperties = computed(()=>{
        const url = currentPromotion.value?.actionUrl || actionUrl.value;
        if (!url) return;
        const options = getLeaderBoardOptions(url, url, leaderBoardOptionsMapRecord.value);
        return {
            list: leaderBoard.value,
            endOfList: options?.nextPage === 0,
            isLoading: options?.isLoading,
            customerPosition: options?.customerPosition ?? void 0
        };
    });
    function goToCustomLink() {
        const customLink = promotionButton.value?.customLink;
        if (customLink) {
            if (useAppDownloadStore().isAppDownloadUrl(customLink)) window.location.href = customLink;
            else router.push(customLink);
        }
    }
    function goToPlay() {
        if (currentPromotion.value?.promotionType === PromoActionType.FORTUNE_WHEEL) {
            fortuneWheelStore.setFortuneWheelId(fortuneWheelId.value || 0);
            router.push({
                name: RouteName.FORTUNE_WHEEL
            });
        } else router.push({
            name: CasinoRouteName.CASINO_LOBBY
        });
    }
    function goToIdentification() {
        router.push({
            name: RouteName.IDENTIFICATION
        });
    }
    async function doButtonAction() {
        const { actionButton, actionUrl: url } = currentPromotion.value || {};
        if (actionButton && url) {
            isDoingActionButton.value = true;
            await promotionsStore.doButtonMutationAction({
                actionUrl: url,
                buttonType: actionButton.buttonType
            });
            isDoingActionButton.value = false;
        } else logger.error('No actionButton');
    }
    async function loadMore(id) {
        const url = currentPromotion.value?.actionUrl || actionUrl.value || '';
        await leaderBoardStore.getLeaderBoard({
            actionUrl: url,
            id: id ? String(id) : url
        });
    }
    function scrollToPosition(element, scrollTop) {
        "1";
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }
    function scrollToLeaderBoard() {
        if (!board.value) return;
        const headerHeight = 60;
        scrollToPosition(board.value, board.value.getOffsetTop() - headerHeight);
    }
    function scrollToTerms() {
        if (!terms.value) return;
        const headerHeight = 60;
        scrollToPosition(terms.value, terms.value.getOffsetTop() - headerHeight);
    }
    onBeforeMount(()=>{
        if (!promotionsLoaded.value && null !== customerCategory.value) promotionsStore.fetchPromotions(customerCategory.value);
    });
    onBeforeUnmount(()=>{
        leaderBoardStore.resetLeaderBoard();
    });
    return {
        board,
        isLeaderBoardPromo,
        actionUrl,
        currentPromotion,
        currentPromoLeaderboardDate,
        haveLeaderBoard,
        leaderBoardProperties,
        doButtonAction,
        scrollToLeaderBoard,
        goToCustomLink,
        loadMore,
        goToPlay,
        goToIdentification,
        getLeaderBoard,
        leaderBoard,
        loadPromotionDetails,
        setOpenedActiveUrl,
        promotionButton,
        isDoingActionButton,
        terms,
        scrollToTerms
    };
}
