import { BetlineSportEventStatus } from 'web/src/modules/sportline/enums/rest';
import { GetEventSubscriptionStatus, GetEventSubscriptionAction } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
/**
 * This behaviour works after the first load is completed
 * @see createGetEventRequestOnPageBehaviour for the logic clarification
 */ export function createStayingOnPageBehaviourFactory(requestStatusBehaviours, props) {
    const { setDetailsStatus, clearDetailsMarketsList, loadSportMatchStatistics } = props;
    return function createStayingOnPageBehaviour(id, actions) {
        requestStatusBehaviours.removeBehavioursExcept(id);
        requestStatusBehaviours.addOnPageBehaviour(id, {
            [GetEventSubscriptionAction.ShowError] () {
                setDetailsStatus(BetlineSportEventStatus.SUSPENDED);
                actions[GetEventSubscriptionAction.ShowError]?.();
            },
            [GetEventSubscriptionAction.Redirect] () {
                setDetailsStatus(BetlineSportEventStatus.CLOSED);
                clearDetailsMarketsList();
                actions[GetEventSubscriptionAction.Redirect]?.();
            },
            [GetEventSubscriptionAction.Finish] () {
                requestStatusBehaviours.removeBehaviours(id);
                // try to reload post match statistic after the end of the game
                loadSportMatchStatistics();
                actions[GetEventSubscriptionAction.Finish]?.();
            }
        });
    };
}
/**
 * This behaviour works on the page loading from the prefetch.
 * @see createGetEventRequestOnInitBehaviour for the logic clarification
 */ export function createOnInitBehaviourFactory(requestStatusBehaviours, props) {
    const { createStayingOnPageBehaviour, awaitPostMatchStatisticIsLoaded, setRawSportEventDetails, hasPostMatchStatistics } = props;
    return function createOnInitBehaviour(id, actions) {
        // clear last status on init
        requestStatusBehaviours.fireRequestStatus(id, GetEventSubscriptionStatus.None);
        requestStatusBehaviours.removeBehavioursExcept(id);
        // extend behaviour from navigation
        requestStatusBehaviours.addOnInitBehaviour(id, {
            ...actions,
            [GetEventSubscriptionAction.ShowError] () {
                requestStatusBehaviours.removeBehaviours(id);
                actions[GetEventSubscriptionAction.ShowError]?.();
            },
            async [GetEventSubscriptionAction.Redirect] () {
                requestStatusBehaviours.removeBehaviours(id);
                await awaitPostMatchStatisticIsLoaded().catch(()=>{
                    actions[GetEventSubscriptionAction.Redirect]?.();
                });
                if (hasPostMatchStatistics.value) {
                    setRawSportEventDetails(false);
                    // do call the Finish action
                    // but do not subscribe to on page behaviour, we won't do event requests anyway
                    actions[GetEventSubscriptionAction.Finish]?.();
                    return;
                }
                actions[GetEventSubscriptionAction.Redirect]?.();
            },
            [GetEventSubscriptionAction.Finish] () {
                requestStatusBehaviours.removeBehaviours(id);
                createStayingOnPageBehaviour(id, {});
                actions[GetEventSubscriptionAction.Finish]?.();
            }
        });
    };
}
