import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VLinkPrevented from 'web/src/components/Link/VLinkPrevented/VLinkPrevented.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HomePromotionsSlide',
    props: {
        bannerUrl: {},
        name: {},
        to: {},
        description: {},
        bannerImage: {},
        isNew: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VLinkPrevented, {
                to: _ctx.to,
                class: _normalizeClass(_ctx.$style['home-promotions-slide'])
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("figure", {
                            class: _normalizeClass(_ctx.$style['home-promotions-slide__image-wrapper'])
                        }, [
                            _ctx.bannerImage ? (_openBlock(), _createBlock(VImage, _mergeProps({
                                key: 0,
                                "object-fit": _unref(ObjectFitOption).COVER,
                                "is-lazy": "",
                                "show-loader": ""
                            }, _ctx.bannerImage, {
                                class: _ctx.$style['home-promotions-slide__image']
                            }), null, 16, [
                                "object-fit",
                                "class"
                            ])) : (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['home-promotions-slide__image']),
                                src: _ctx.bannerUrl ?? void 0,
                                alt: _ctx.name ?? void 0,
                                loading: "lazy"
                            }, null, 10, _hoisted_1)),
                            _ctx.isNew ? (_openBlock(), _createBlock(VBadge, {
                                key: 2,
                                class: _normalizeClass(_ctx.$style['home-promotions-slide__image-badge']),
                                label: "New",
                                kind: _unref(BadgeKind).SQUARE
                            }, null, 8, [
                                "class",
                                "kind"
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['home-promotions-slide__title'])
                        }, _toDisplayString(_ctx.name || ''), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['home-promotions-slide__desc'])
                        }, _toDisplayString(_ctx.description || ''), 3)
                    ]),
                _: 1
            }, 8, [
                "to",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'HomePromotionsSlide'
                ]
            ]);
        };
    }
});
