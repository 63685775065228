import { useRouter } from 'vue-router';
import { computed } from 'vue';
export default function useBannerLocation(banner) {
    const router = useRouter();
    const href = computed(()=>banner.value ? router.resolve({
            path: banner.value.url
        }).href : void 0);
    function onClick() {
        if (banner.value) router.push({
            path: banner.value.url
        });
    }
    return {
        href,
        onClick
    };
}
