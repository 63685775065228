import { onActivated, onBeforeUnmount, onDeactivated, onMounted, watch } from 'vue';
import { useDebounce } from '@leon-hub/debounce';
import { getViewportDimension } from '@leon-hub/utils';
export function useWindowResize(callback) {
    let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    let hasActiveListener = false;
    let dimensions = getViewportDimension();
    function resizeCallbackInner() {
        const newDimensions = getViewportDimension();
        callback({
            deltaX: dimensions.viewportWidth - newDimensions.viewportWidth,
            deltaY: dimensions.viewportHeight - newDimensions.viewportHeight,
            viewportHeight: newDimensions.viewportHeight,
            viewportWidth: newDimensions.viewportWidth
        });
        dimensions = newDimensions;
    }
    const resizeCallback = options.debounce ? useDebounce(resizeCallbackInner) : resizeCallbackInner;
    function addEventListener() {
        if (!hasActiveListener) {
            dimensions = getViewportDimension();
            window.addEventListener('resize', resizeCallback);
            hasActiveListener = true;
        }
    }
    function removeEventListener() {
        if (hasActiveListener) {
            window.removeEventListener('resize', resizeCallback);
            hasActiveListener = false;
        }
    }
    if (options.condition) watch(options.condition, (newValue)=>{
        if (newValue) addEventListener();
        else removeEventListener();
    }, {
        immediate: true
    });
    else {
        onMounted(addEventListener);
        onActivated(addEventListener);
    }
    onBeforeUnmount(removeEventListener);
    onDeactivated(removeEventListener);
}
