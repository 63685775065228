import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import debounce from 'lodash/debounce';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
const useSearchCasinoStore = defineStore('search-casino', ()=>{
    const analytics = useAnalytics();
    const { getGroups, getGames } = useEgsApi();
    const searchMinCharsSize = toRef(useSiteConfigStore(), 'searchMinCharsSize');
    const searchText = ref('');
    const games = ref(null);
    const groups = ref(null);
    const gamesCursor = ref(null);
    const isMoreItemsLoading = ref(false);
    const isSearchAvailable = computed(()=>searchText.value.length >= searchMinCharsSize.value);
    const isSearchInProgress = computed(()=>isSearchAvailable.value && (!games.value || !groups.value));
    const isNoSearchResults = computed(()=>isSearchAvailable.value && !!games.value && 0 === games.value.length && !!groups.value && 0 === groups.value.length);
    const isMoreLoadingAvailable = computed(()=>!isMoreItemsLoading.value && !!gamesCursor.value);
    async function fetchGames() {
        let isMore = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (!isMore) games.value = null;
        const result = await getGames({
            gameName: searchText.value,
            limit: isMore ? gamesCursor.value?.limit : null,
            offset: isMore ? gamesCursor.value?.offset : null
        });
        games.value = isMore ? (games.value ?? []).concat(result.games) : result.games;
        gamesCursor.value = result.next;
    }
    async function fetchGroups() {
        groups.value = null;
        groups.value = await getGroups({
            groupName: searchText.value
        });
    }
    async function fetchMoreGames() {
        if (!gamesCursor.value) return;
        isMoreItemsLoading.value = true;
        await fetchGames(true);
        isMoreItemsLoading.value = false;
    }
    const doSearch = debounce(()=>{
        Promise.all([
            fetchGames(),
            fetchGroups()
        ]);
    }, 500);
    function setSearchText(value) {
        searchText.value = value;
        games.value = null;
        groups.value = null;
        gamesCursor.value = null;
        isMoreItemsLoading.value = false;
        if (!isSearchAvailable.value) return;
        doSearch();
    }
    function sendAnalytics() {
        let isClicked = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        const results = isSearchAvailable.value && !isNoSearchResults.value;
        analytics.push(AnalyticsEvent.Z_SEARCH_GAME, {
            searchDetails: {
                games: {
                    [searchText.value]: {
                        [results ? 'true' : 'false']: isClicked ? 'clicked' : 'nonClicked'
                    }
                }
            }
        });
    }
    return {
        searchText,
        games,
        groups,
        isSearchInProgress,
        isNoSearchResults,
        isMoreLoadingAvailable,
        isSearchAvailable,
        setSearchText,
        fetchMoreGames,
        sendAnalytics
    };
});
export default useSearchCasinoStore;
