import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { BetCashoutMode } from 'web/src/modules/cashout/enums';
import { getCurrencyIcon } from 'web/src/modules/money/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useBetCashOut(props, emit) {
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const isPending = computed(()=>props.mode === BetCashoutMode.PENDING);
    const isCompleted = computed(()=>props.mode === BetCashoutMode.SUCCESS);
    const isError = computed(()=>props.mode === BetCashoutMode.ERROR);
    const showOldValue = computed(()=>!!props.oldPrice && !isCompleted.value);
    const iconName = computed(()=>{
        if (isCompleted.value) return IconName.CHECK_OUTLINE;
        if (isError.value) return IconName.INFO_OUTLINE;
        if (showOldValue.value) return IconName.ATTENTION_OUTLINE;
        return getCurrencyIcon(props.currency);
    });
    const mainText = computed(()=>{
        if (isCompleted.value) return $translate('WEB2_BETDET_CASHOUT_SUCCESS').value;
        if (isError.value) return props.errorMessage ? props.errorMessage : $translate('JSPBET_BETDET_CASHOUT_IMPOSSIBLE').value;
        if (showOldValue.value) return $translate('WEB2_STAKE_CASHOUT_CHANGE').value;
        return $translate('WEB2_BET_DETAILS_SELL_BET_FOR').value;
    });
    const formattedPriceValue = computed(()=>formatMoney(props.price ?? 0));
    const formattedBetPriceValue = computed(()=>formatMoney(Number(props.betPrice) ?? 0));
    const primaryButtonLabel = computed(()=>{
        if (isPending.value) return $translate('WEB2_CASHOUT_IN_PROGRESS').value;
        if (isCompleted.value) return $translate('WEB2_CLOSE').value;
        return $translate('WEB2_BET_DO_CAHSOUT').value;
    });
    const showCancel = computed(()=>!isCompleted.value);
    const showValue = computed(()=>!!props.price && !showOldValue.value && !props.betPrice && !isError.value);
    const isGreenPrice = computed(()=>Number(props?.price) > Number(props?.betPrice));
    const emitMainButtonClick = ()=>{
        emit('main-button-click');
    };
    const emitCancel = ()=>{
        emit('cancel');
    };
    return {
        isPending,
        isCompleted,
        isError,
        iconName,
        mainText,
        formattedPriceValue,
        formattedBetPriceValue,
        primaryButtonLabel,
        showCancel,
        showValue,
        isGreenPrice,
        emitMainButtonClick,
        emitCancel
    };
}
