import { computed, nextTick, toRef } from 'vue';
import { useEventsBus } from '@leon-hub/event-bus';
import { IconName, IconSize } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useLiveChatStore } from 'web/src/modules/live-chat/store';
const closeButtonProps = {
    iconName: IconName.CROSS,
    iconSize: IconSize.SIZE_24,
    kind: ButtonKind.PRIMARY,
    rounded: true
};
function sendOpenChatAnalytics(analytics) {
    analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
        supportDetails: {
            onlineChat: {
                openOnlineChat: true
            }
        }
    });
}
export default function useSupportWidget() {
    useEventsBus();
    const analytics = useAnalytics();
    const liveChatStore = useLiveChatStore();
    const chatMessagesCounter = toRef(liveChatStore, 'newMessagesSize');
    const openOnCreated = toRef(liveChatStore, 'openOnCreated');
    const isChatOpened = toRef(liveChatStore, 'isOpened');
    const { isLoggedIn } = useIsLoggedIn();
    const isChatVisible = computed(()=>isChatOpened.value && isLoggedIn.value);
    const isChatIconVisible = computed(()=>!isChatOpened.value && chatMessagesCounter.value > 0);
    function hideChat() {
        if (isChatOpened.value) liveChatStore.setIsOpened(false);
    }
    function openChat() {
        if (!isChatOpened.value) {
            sendOpenChatAnalytics(analytics);
            liveChatStore.setOpenOnCreated(false);
            liveChatStore.setIsOpened(true);
        }
    }
    function onChatDestroyed() {
        if (isChatOpened.value) liveChatStore.setOpenOnCreated(true);
    }
    async function onChatCreated() {
        if (openOnCreated.value) {
            await nextTick();
            openChat();
        }
    }
    return {
        isChatIconVisible,
        closeButtonProps,
        isChatVisible,
        onChatDestroyed,
        openChat,
        hideChat,
        onChatCreated
    };
}
