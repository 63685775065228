import { computed, toRef } from 'vue';
import { LoginType } from '@leon-hub/api-sdk';
import { SwitcherKind } from 'web/src/components/Switcher/VSwitcher/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
export default function useRestoreContainer() {
    const { $translate } = useI18n();
    const restorePasswordStore = useRestorePasswordStore();
    const isCaptchaEnabled = toRef(restorePasswordStore, 'captchaEnabled');
    const isPhoneTabEnabled = toRef(restorePasswordStore, 'isPhoneTabEnabled');
    const isEmailTabEnabled = toRef(restorePasswordStore, 'isEmailTabEnabled');
    const activeTab = toRef(restorePasswordStore, 'activeTab');
    const tabsProperties = computed(()=>{
        const items = [];
        if (isEmailTabEnabled.value) items.push({
            id: LoginType.EMAIL,
            label: $translate('RESTORE_PASSWORD_TAB_EMAIL').value
        });
        if (isPhoneTabEnabled.value) items.push({
            id: LoginType.PHONE,
            label: $translate('RESTORE_PASSWORD_TAB_PHONE').value
        });
        return {
            items,
            activeId: activeTab.value,
            fullWidth: true
        };
    });
    const switchProperties = computed(()=>{
        const options = [];
        if (isEmailTabEnabled.value) options.push({
            id: LoginType.EMAIL,
            label: $translate('RESTORE_PASSWORD_TAB_EMAIL').value
        });
        if (isPhoneTabEnabled.value) options.push({
            id: LoginType.PHONE,
            label: $translate('RESTORE_PASSWORD_TAB_PHONE').value
        });
        return {
            options,
            kind: SwitcherKind.PRIMARY,
            activeId: activeTab.value
        };
    });
    function onTabClick(tabName) {
        restorePasswordStore.setActiveTab(tabName);
    }
    return {
        isCaptchaEnabled,
        tabsProperties,
        switchProperties,
        onTabClick
    };
}
