import { computed } from 'vue';
import isFileMultipleInputProps from 'web/src/components/Input/guards/isFileMultipleInputProps';
import getErrorArrayFields from '../../utils/getErrorArrayFields';
import getHintFields from '../../utils/getHintFields';
import getHintLinkFields from '../../utils/getHintLinkFields';
export default function useFormFileMultipleInput(props) {
    const componentProps = computed(()=>{
        const { name, error, title, hintMessage, disabled, options, autofocus } = props;
        isFileMultipleInputProps(options);
        return {
            label: title,
            disabled,
            ...options,
            ...getErrorArrayFields({
                ...options
            }, error),
            ...getHintFields(options, hintMessage),
            ...getHintLinkFields(options),
            autofocus,
            name
        };
    });
    return {
        componentProps
    };
}
