import { computed } from 'vue';
import { prepareSiteConfigHtml } from '@leon-hub/html-utils';
import HighLighter from 'web/src/utils/HighLighter';
export default function useVListItemCountries(props) {
    const isSelected = computed(()=>props.code === props.selectedItemCode);
    function getHighlightedText(text) {
        return HighLighter.highlight(prepareSiteConfigHtml(text), props.searchText || '', 'highlighted-text');
    }
    return {
        isSelected,
        getHighlightedText
    };
}
