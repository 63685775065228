import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { Deferred, Timer } from '@leon-hub/utils';
import { createDebugCallback } from '../utils/debug';
const whileDebug = createDebugCallback('SocketWrapper');
function isSocketOpen(socket) {
    return socket.readyState === WebSocket.OPEN;
}
let SocketWrapper = class SocketWrapper {
    createSocket() {
        return new WebSocket(this.options.url);
    }
    getSocketData(socket) {
        let socketData = this.perSocketMap.get(socket);
        if (!socketData) {
            this.socketCounter += 1;
            socketData = {
                id: this.socketCounter,
                deferred: new Deferred(),
                timeout: 0
            };
            this.perSocketMap.set(socket, socketData);
        }
        return socketData;
    }
    async connect() {
        if (this.socket) return this.getSocketData(this.socket).deferred.promise;
        const socket = this.createSocket();
        const { deferred, id } = this.getSocketData(socket);
        whileDebug((log)=>log(`[socket#${id}] initializing new connection with state ${socket.readyState}`));
        deferred.promise.then(()=>{
            whileDebug((log)=>log(`[socket#${id}] deferred connection promise has been resolved`));
        });
        socket.addEventListener('open', ()=>{
            whileDebug((log)=>log(`[socket#${id}] received open event`));
            deferred.resolve(socket);
        });
        socket.addEventListener('error', (event)=>{
            // eslint-disable-next-line no-console
            whileDebug((log)=>log(`[socket#${id}] received error event %O`, event));
            // eslint-disable-next-line no-console
            console.warn('Socket error event occurred', event);
            deferred.reject(new Error('Unexpected socket error'));
            this.detach('WebSocket received an error event');
        });
        socket.addEventListener('close', (event)=>{
            whileDebug((log)=>log(`[socket#${id}] received close event: %s %s`, event.code, event.reason));
            if (this.socket !== socket) {
                whileDebug((log)=>{
                    log(`[socket#${id}] redundant, skipping detach`);
                });
                return;
            }
            this.detach();
        });
        this.socket = socket;
        deferred.promise.then(()=>{
            this.refreshTimeout();
        });
        return deferred.promise;
    }
    refreshTimeout() {
        const { socket } = this;
        if (socket) this.resetTimeout(socket, ()=>{
            whileDebug((log)=>{
                const { id } = this.getSocketData(socket);
                log(`[socket#${id}] timeout for socket`);
            });
            this.detach();
        });
    }
    resetTimeout(socket, callback) {
        const socketDate = this.getSocketData(socket);
        whileDebug((log)=>{
            log(`[socket#${socketDate.id}] resetting socket timeout`);
        });
        Timer.clearTimeout(socketDate.timeout);
        if (callback) socketDate.timeout = Timer.setTimeout(callback, this.options.openTimeout);
    }
    async send(data) {
        this.sendCounter += 1;
        const { sendCounter } = this;
        whileDebug((log)=>log(`[send#${sendCounter}] about to send data: ${JSON.stringify(data)}`));
        const socket = await this.connect();
        whileDebug((log)=>log(`[send#${sendCounter}] connection is ready`));
        socket.send(JSON.stringify(data));
        whileDebug((log)=>log(`[send#${sendCounter}] data has been sent`));
        this.refreshTimeout();
    }
    async detach(reason) {
        return new Promise((resolve, reject)=>{
            const { socket } = this;
            if (socket) {
                const { deferred, id, timeout } = this.getSocketData(socket);
                whileDebug((log)=>log(`[socket#${id}] detaching..`));
                this.socket = null;
                Timer.clearTimeout(timeout);
                const { readyState } = socket;
                if (readyState !== WebSocket.CLOSED && readyState !== WebSocket.CLOSING) {
                    whileDebug((log)=>log(`[socket#${id}] closing socket with state ${readyState}`));
                    socket.close();
                }
                if (reason) {
                    deferred.promise.catch(reject);
                    deferred.reject(new Error(reason));
                }
            } else whileDebug((log)=>log('[socket#null] no socket to detach'));
            resolve();
        });
    }
    isSocketOpen() {
        whileDebug((log)=>{
            const id = this.socket ? this.getSocketData(this.socket).id : null;
            return log(`[socket#${id}] status: ${this.socket ? this.socket.readyState : 'no socket'}`);
        });
        return !!(this.socket && isSocketOpen(this.socket));
    }
    constructor(options){
        _define_property(this, "options", void 0);
        _define_property(this, "socket", void 0);
        _define_property(this, "socketCounter", void 0);
        _define_property(this, "sendCounter", void 0);
        _define_property(this, "perSocketMap", void 0);
        this.options = options;
        this.socket = null;
        this.socketCounter = 0;
        this.sendCounter = 0;
        this.perSocketMap = new WeakMap();
    }
};
export { SocketWrapper as default };
