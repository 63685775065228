import { IconName, IconSize } from '@leon-hub/icons';
import { AvatarSize } from 'web/src/components/Avatar/VAvatar/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function getButtonPropsForAvatar(props) {
    const size = props.size === AvatarSize.SIZE_68 ? IconSize.SIZE_28 : IconSize.SIZE_24;
    return {
        iconName: IconName.PERSON_FILL,
        iconSize: size,
        kind: ButtonKind.TRANSPARENT
    };
}
