import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getFormSchema, validationSchemaChangePassword, validationSchemaCustomerLimits, validationSchemaRestorePasswordByEmail, validationSchemaRestorePasswordByPhone, validationSchemaRestorePasswordSendLinkToEmail, validationSchemaRestorePasswordSendSmsToPhone } from '@leon-hub/api-sdk/src/sdk/sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useFormsStore = defineStore('forms', ()=>{
    const api = useGraphqlClient();
    const schemas = ref({});
    // eslint-disable-next-line max-len
    const restoreBySendSms = computed(()=>schemas.value[validationSchemaRestorePasswordSendSmsToPhone]);
    // eslint-disable-next-line max-len
    const restoreBySendLinkToEmail = computed(()=>schemas.value[validationSchemaRestorePasswordSendLinkToEmail]);
    // eslint-disable-next-line max-len
    const restoreByEmail = computed(()=>schemas.value[validationSchemaRestorePasswordByEmail]);
    // eslint-disable-next-line max-len
    const restoreByPhone = computed(()=>schemas.value[validationSchemaRestorePasswordByPhone]);
    // eslint-disable-next-line max-len
    const customerLimitsSchema = computed(()=>schemas.value[validationSchemaCustomerLimits]);
    // eslint-disable-next-line max-len
    const changePasswordSchema = computed(()=>schemas.value[validationSchemaChangePassword]);
    async function fetchSchema(key) {
        const result = await getFormSchema(api, (node)=>node.queries.system.schemaContent, {
            options: {
                schema: key
            }
        });
        if (result) schemas.value = {
            ...schemas.value,
            [key]: JSON.parse(result.content)
        };
    }
    return {
        restoreBySendSms,
        restoreBySendLinkToEmail,
        restoreByEmail,
        restoreByPhone,
        customerLimitsSchema,
        changePasswordSchema,
        fetchSchema
    };
});
export default useFormsStore;
