import { isValidJsonSchema } from '@leon-hub/guards';
function isCountriesData(uiSchema) {
    return isValidJsonSchema(uiSchema, {
        type: 'array',
        items: {
            type: 'object',
            properties: {
                code: {
                    type: 'string'
                },
                name: {
                    type: 'string'
                },
                phonePrefix: {
                    type: 'number'
                },
                popular: {
                    type: 'boolean'
                }
            },
            minItems: 1
        }
    });
}
export default isCountriesData;
