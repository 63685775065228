import { doFavoriteSportEventRemove, doFavoriteSportEventsAdd, doToggleCustomerLeague, getCustomerLeagues, getCustomerMatches } from '@leon-hub/api-sdk/src/sdk/sdk';
import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export function toggleFavoriteLeagueRequest(param, param1) {
    let { sportId, leagueId } = param, { silent } = param1;
    const apiClient = useGraphqlClient();
    return doToggleCustomerLeague(apiClient, (node)=>node.mutations.customer.leagues.toggleLeague, {
        options: {
            leagueKey: `${sportId}_${leagueId}`
        }
    }, {
        silent
    });
}
export function getFavoritesLeaguesIdsRequest(options, param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return getCustomerLeagues(apiClient, (node)=>node.queries.customer.leagues.getLeagues, {
        options
    }, {
        silent,
        priority: silent ? RequestOptionsPriority.LOW : void 0
    });
}
export function getFavoritesEventsIdsRequest(param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return getCustomerMatches(apiClient, (node)=>node.queries.customer.matches.listMatchesIds, {
        options: {}
    }, {
        silent,
        priority: silent ? RequestOptionsPriority.LOW : void 0
    });
}
export function addFavoriteEventsRequest(options, param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return doFavoriteSportEventsAdd(apiClient, (node)=>node.mutations.customer.matches.addMatches, {
        options
    }, {
        silent
    });
}
export function removeFavoriteEventRequest(options, param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return doFavoriteSportEventRemove(apiClient, (node)=>node.mutations.customer.matches.removeMatch, {
        options
    }, {
        silent
    });
}
