import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { usePromotionsTopStore } from 'web/src/modules/promotions/submodules/top/usePromotionsTopStore';
export function useHomePromotionsBlock() {
    const router = useRouter();
    const promotionsTopStore = usePromotionsTopStore();
    const rawPromotions = toRef(promotionsTopStore, 'promotions');
    const promotions = computed(()=>(rawPromotions.value ?? []).filter((el)=>!el.isShowStartDateCountdown));
    const allPromotionsRoute = {
        name: RouteName.PROMOTIONS
    };
    function getPromotionRoute(promotion) {
        return {
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: promotion.categoryId || '',
                actionUrl: promotion.actionUrl || ''
            }
        };
    }
    function openAllPromotions() {
        router.push(allPromotionsRoute);
    }
    function openPromotion(promotion) {
        router.push(getPromotionRoute(promotion));
    }
    return {
        promotions,
        allPromotionsRoute,
        openAllPromotions,
        openPromotion,
        getPromotionRoute
    };
}
