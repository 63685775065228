import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VSnackBar from 'web/src/components/SnackBar/VSnackBar/VSnackBar.vue';
import { useSnackbarHolder } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SnackbarHolder',
    props: {
        isTabbarHidden: {
            type: Boolean
        }
    },
    setup (__props) {
        const { hasSnackbars, snackbarProps, snackbarId, className, onAccept, onCancel } = useSnackbarHolder();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(hasSnackbars) ? _withDirectives((_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: "body",
                disabled: !_unref(hasSnackbars)
            }, [
                _unref(hasSnackbars) ? (_openBlock(), _createBlock(VSnackBar, _mergeProps({
                    key: 0
                }, _unref(snackbarProps), {
                    key: _unref(snackbarId),
                    "is-tab-bar-hidden": _ctx.isTabbarHidden,
                    class: _unref(className),
                    onClick: _unref(onAccept),
                    onClickClose: _unref(onCancel)
                }), null, 16, [
                    "is-tab-bar-hidden",
                    "class",
                    "onClick",
                    "onClickClose"
                ])) : _createCommentVNode("", true)
            ], 8, [
                "disabled"
            ])), [
                [
                    _directive_auto_id,
                    'SnackbarHolder'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
