import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import VLoadMoreObserver from 'web/src/components/LoadMoreObserver/VLoadMoreObserver/VLoadMoreObserver.vue';
import CustomerHistoryListItem from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItem/CustomerHistoryListItem.vue';
import CustomerHistoryList from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryList/CustomerHistoryList.vue';
import CustomerHistoryFilterTabs from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryFilterTabs/CustomerHistoryFilterTabs.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import CustomerHistoryFilterSelect from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryFilterSelect/CustomerHistoryFilterSelect.vue';
import CustomerHistoryListEmpty from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListEmpty/CustomerHistoryListEmpty.vue';
import { CashoutModal } from 'web/src/modules/cashout/views';
import { useCustomerHistoryListRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListRouteComponent',
    props: {
        isLazyLoadingEnabled: {
            type: Boolean,
            default: true
        },
        isHiddenTabs: {
            type: Boolean
        },
        isHiddenSelectFilter: {
            type: Boolean
        },
        filterParamsFromProps: {
            default: null
        }
    },
    setup (__props) {
        const props = __props;
        const { selectedHistoryFilter, isCashOutModal, isLeonShopEnabled, isInitialLoader, portalSelector, historyFilterOptions, historyItems, isLastPage, currency, isLazyLoader, currentCashOutItem, onCashOutConfirm, handleBetCashOut, handleHistoryItemClick, handleCancelWithdrawal, onLoadMore, onTabClick, closeCashOutModal, onFilterChange, beforeMount, beforeUnmount } = useCustomerHistoryListRouteComponent(props);
        onBeforeMount(beforeMount);
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['history-list-route-component'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['history-list-route-component__inner'])
                }, [
                    _ctx.isHiddenTabs ? _createCommentVNode("", true) : (_openBlock(), _createBlock(CustomerHistoryFilterTabs, {
                        key: 0,
                        "selected-history-filter": _unref(selectedHistoryFilter),
                        "is-leon-shop-enabled": _unref(isLeonShopEnabled),
                        onTabClick: _unref(onTabClick)
                    }, null, 8, [
                        "selected-history-filter",
                        "is-leon-shop-enabled",
                        "onTabClick"
                    ])),
                    _ctx.isHiddenSelectFilter ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['history-list-route-component__filter-select'])
                    }, [
                        _createVNode(CustomerHistoryFilterSelect, {
                            "history-filter-options": _unref(historyFilterOptions),
                            "selected-history-filter": _unref(selectedHistoryFilter),
                            onFilterChange: _unref(onFilterChange)
                        }, null, 8, [
                            "history-filter-options",
                            "selected-history-filter",
                            "onFilterChange"
                        ])
                    ], 2)),
                    _unref(isInitialLoader) ? _unref(isInitialLoader) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                        key: 3,
                        class: _normalizeClass(_ctx.$style['history-list-route-component__loader'])
                    }, null, 8, [
                        "class"
                    ])) : _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['history-list-route-component__container'])
                    }, [
                        _unref(historyItems) && !!_unref(historyItems).length ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createVNode(CustomerHistoryList, {
                                class: _normalizeClass(_ctx.$style['history-list-route-component__list']),
                                separate: ""
                            }, {
                                default: _withCtx(()=>[
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(historyItems), (item)=>(_openBlock(), _createBlock(CustomerHistoryListItem, {
                                                key: item.rootTxId,
                                                item: item,
                                                currency: _unref(currency),
                                                onItemClick: _unref(handleHistoryItemClick),
                                                onCashOut: _unref(handleBetCashOut),
                                                onCancelWithdrawal: _unref(handleCancelWithdrawal)
                                            }, null, 8, [
                                                "item",
                                                "currency",
                                                "onItemClick",
                                                "onCashOut",
                                                "onCancelWithdrawal"
                                            ]))), 128))
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ]),
                            _ctx.isLazyLoadingEnabled && !_unref(isLastPage) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _unref(isLazyLoader) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['history-list-route-component__loader'])
                                }, null, 8, [
                                    "class"
                                ])) : (_openBlock(), _createBlock(VLoadMoreObserver, {
                                    key: 1,
                                    onLoadMore: _unref(onLoadMore)
                                }, null, 8, [
                                    "onLoadMore"
                                ]))
                            ], 64)) : _createCommentVNode("", true)
                        ], 64)) : (_openBlock(), _createBlock(CustomerHistoryListEmpty, {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['history-list-route-component__list-empty']),
                            filter: _unref(selectedHistoryFilter),
                            "is-button-hidden": _ctx.isHiddenTabs
                        }, null, 8, [
                            "class",
                            "filter",
                            "is-button-hidden"
                        ]))
                    ], 2)),
                    _createVNode(ModalPortal, {
                        selector: _unref(portalSelector)
                    }, {
                        default: _withCtx(()=>[
                                _unref(isCashOutModal) ? (_openBlock(), _createBlock(_unref(CashoutModal), {
                                    key: 0,
                                    transaction: _unref(currentCashOutItem),
                                    onConfirm: _unref(onCashOutConfirm),
                                    onCancel: _unref(closeCashOutModal)
                                }, null, 8, [
                                    "transaction",
                                    "onConfirm",
                                    "onCancel"
                                ])) : _createCommentVNode("", true)
                            ]),
                        _: 1
                    }, 8, [
                        "selector"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListRouteComponent'
                ]
            ]);
        };
    }
});
