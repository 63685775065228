import { ref, computed } from 'vue';
import { applyLimitationsTo, getMaxDate, getMinDate } from 'web/src/components/Input/utils';
export function useVDatepicker(props, emit) {
    const { min, max, roundTo } = props;
    // controlled by plugin
    const isOpened = ref(false);
    const minDate = computed(()=>getMinDate(min.value));
    const maxDate = computed(()=>getMaxDate(max.value));
    function onChange(value) {
        const timestamp = applyLimitationsTo(value, minDate.value, maxDate.value, roundTo.value);
        emit('change', {
            timestamp
        });
    }
    function onClear() {
        isOpened.value = false;
        emit('change', {});
    }
    function setToday() {
        isOpened.value = false;
        onChange(Date.now());
    }
    return {
        isOpened,
        minDate,
        maxDate,
        onChange,
        onClear,
        setToday
    };
}
