import { SportFamily } from 'web/src/modules/sportline/enums';
export const SportBackgroundColorDark = {
    [SportFamily.ESport]: '#2A1E41',
    [SportFamily.Cricket]: '#41701F',
    [SportFamily.AustralianRules]: '#2D782D',
    [SportFamily.Rugby]: '#339955',
    [SportFamily.RugbyLeague]: '#339955',
    [SportFamily.RugbyUnion]: '#339955',
    [SportFamily.Baseball]: '#1C6427',
    [SportFamily.AmericanFootball]: '#46782D',
    [SportFamily.Volleyball]: '#BF6A40',
    [SportFamily.Tennis]: '#9B5231',
    [SportFamily.Soccer]: '#2D7837',
    [SportFamily.TableTennis]: '#083E6C',
    [SportFamily.Squash]: '#BF6A40',
    [SportFamily.Snooker]: '#2D8668',
    [SportFamily.WaterPolo]: '#337799',
    [SportFamily.Futsal]: '#3A86AC',
    [SportFamily.Floorball]: '#2D8686',
    [SportFamily.Bandy]: '#2D6886',
    [SportFamily.Darts]: '#BF4040',
    [SportFamily.Badminton]: '#2D8686',
    [SportFamily.Boxing]: '#7B3636',
    [SportFamily.MMA]: '#7B3636',
    [SportFamily.Motorsport]: '#AC3A3A',
    [SportFamily.WinterSports]: '#3A5FAC',
    [SportFamily.BeachVolleyball]: '#AC7C3A',
    [SportFamily.Handball]: '#3A86AC',
    [SportFamily.Basketball]: '#7E5F3A',
    [SportFamily.IceHockey]: '#2E4A65',
    [SportFamily.Baseball]: '#1C6427',
    [SportFamily.BeachSoccer]: '#AC7C3A',
    [SportFamily.Curling]: '#333399',
    [SportFamily.Golf]: '#4A862D',
    [SportFamily.Basketball3x3]: '#7E5F3A',
    [SportFamily.AlpineSkiing]: '#3E6274',
    [SportFamily.Biathlon]: '#175F82',
    [SportFamily.CrossCountrySkiing]: '#213268',
    [SportFamily.SkiJumping]: '#364E7D',
    // [<string>SportFamily.Bowling]: '#B87E2E', // Bowling
    [SportFamily.Cycling]: '#433AAC',
    [SportFamily.Equestrian]: '#884A2A',
    [SportFamily.FieldHockey]: '#279B75',
    [SportFamily.FormulaE]: '#AC3A3A',
    [SportFamily.GaelicFootball]: '#2D782D',
    [SportFamily.GaelicHurling]: '#1C6427',
    // [<string>SportFamily.Lacrosse]: '#4A862D', // Lacrosse
    [SportFamily.Pesapallo]: '#8F513D',
    [SportFamily.Sailing]: '#3A86AC',
    [SportFamily.Bowls]: '#4A862D'
};
