import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { computed, toRef, useSlots } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { BreadcrumbType } from 'web/src/components/Breadcrumbs/VBreadcrumb/enums';
import VLinkPrevented from 'web/src/components/Link/VLinkPrevented/VLinkPrevented.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCrumb',
    props: {
        title: {},
        to: {},
        isPrevented: {
            type: Boolean
        },
        isOpen: {
            type: Boolean
        },
        iconName: {},
        canExpand: {
            type: Boolean
        },
        type: {}
    },
    emits: [
        "toggle"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const to = toRef(props, 'to', null);
        const isPrevented = toRef(props, 'isPrevented', false);
        const iconName = toRef(props, 'iconName', null);
        const tag = computed(()=>{
            if (!to.value) return 'div';
            return isPrevented.value ? VLinkPrevented : VRouterLink;
        });
        const properties = computed(()=>to.value ? {
                to: to.value
            } : {});
        const hasIcon = computed(()=>!!iconName.value || !!slots.icon);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(tag.value), _mergeProps(properties.value, {
                title: _ctx.title,
                class: {
                    [_ctx.$style['crumb']]: true,
                    [_ctx.$style['crumb--menu']]: _ctx.type === _unref(BreadcrumbType).MENU,
                    [_ctx.$style['crumb--open']]: _ctx.isOpen,
                    [_ctx.$style['crumb--can-interact']]: _ctx.canExpand || to.value,
                    [_ctx.$style['crumb--with-indicator']]: _ctx.canExpand,
                    [_ctx.$style['crumb--with-icon']]: hasIcon.value
                },
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('toggle'))
            }), {
                default: _withCtx(()=>[
                        hasIcon.value ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['crumb__icon'])
                        }, [
                            iconName.value ? (_openBlock(), _createBlock(_unref(VIcon), {
                                key: 0,
                                name: iconName.value,
                                size: _unref(IconSize).SIZE_16
                            }, null, 8, [
                                "name",
                                "size"
                            ])) : _renderSlot(_ctx.$slots, "icon", {
                                key: 1
                            })
                        ], 2)) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['crumb__title'])
                        }, _toDisplayString(_ctx.title), 3),
                        _ctx.canExpand ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['crumb__indicator'])
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: _ctx.isOpen ? _unref(IconName).EXPAND_UP : _unref(IconName).EXPAND_DOWN,
                                size: _unref(IconSize).SIZE_16
                            }, null, 8, [
                                "name",
                                "size"
                            ])
                        ], 2)) : _createCommentVNode("", true)
                    ]),
                _: 3
            }, 16, [
                "title",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'VCrumb'
                ]
            ]);
        };
    }
});
