import { toRef, computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import { resolveLeaguePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useSportlineBreadcrumbsLeagueItem(props) {
    const defaultLeague = toRef(props.league);
    const { $translate } = useI18n();
    const sportlineBreadcrumbsStore = useSportlineBreadcrumbsStore();
    const leagueElement = toRef(sportlineBreadcrumbsStore, 'leagueElement');
    const relatedLeagues = toRef(sportlineBreadcrumbsStore, 'relatedLeagues');
    const league = computed(()=>defaultLeague.value ?? leagueElement.value?.league ?? null);
    const isShown = computed(()=>!!league.value || relatedLeagues.value.length > 0);
    const title = computed(()=>league.value?.name || $translate('JSP_SPORT_NAV_DEFAULT_LEAGUE').value);
    const pageLocation = computed(()=>league.value ? resolveLeaguePageLink(league.value.navigationParameters) : void 0);
    return {
        isShown,
        title,
        leagueElement,
        relatedLeagues,
        pageLocation
    };
}
