import { buildLeagueNavigationParameters } from 'web/src/modules/sportline/utils/rest/navigation-parameters';
export default function parseLeagueResponse(options) {
    const { sportResponse, leagueResponse } = options;
    const id = String(leagueResponse.id);
    const navigationParameters = buildLeagueNavigationParameters(options);
    const league = {
        id,
        key: `${sportResponse.id}_${leagueResponse.id}`,
        name: leagueResponse.name,
        top: leagueResponse.top ?? false,
        topOrder: leagueResponse.topOrder,
        weight: leagueResponse.weight,
        representation: {
            logo: leagueResponse.logoUrl,
            logoSource: leagueResponse.logoSource,
            background: leagueResponse.background?.src
        },
        navigationParameters,
        hasZeroMargin: options.canUseZeroMargin && (leagueResponse.hasZeroMarginEvents ?? false)
    };
    return {
        league
    };
}
