import { InputEventType } from 'web/src/components/Input/enums';
import { checkboxEventToDataEvent } from 'web/src/components/Form/utils';
const useFormCheckboxEvents = (emit, name)=>{
    const emitInput = (event)=>{
        emit(InputEventType.INPUT, checkboxEventToDataEvent(event, name));
    };
    const emitChange = (event)=>{
        emit(InputEventType.CHANGE, checkboxEventToDataEvent(event, name));
    };
    const emitFocus = (event)=>{
        emit(InputEventType.FOCUS, checkboxEventToDataEvent(event, name));
    };
    const emitBlur = (event)=>{
        emit(InputEventType.BLUR, checkboxEventToDataEvent(event, name));
    };
    return {
        emitChange,
        emitInput,
        emitFocus,
        emitBlur
    };
};
export default useFormCheckboxEvents;
