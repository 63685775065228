import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
const MINUTE = 60000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const YEAR = 365 * DAY;
export function useCardExpirationError(fieldName, value) {
    const { $translate } = useI18n();
    const cardExpirationErrors = computed(()=>{
        const minSymbols = 5;
        // MM/DD
        let message = '';
        if (value.value.length === minSymbols) {
            const LAST_DAY_OF_PREV_MONTH = 0;
            const MAX_YEARS_FOR_EXPIRATION_DATE = 11;
            const monthFromValue = Number(value.value.slice(0, 2));
            const yearFromValue = Number(`20${value.value.slice(-2)}`);
            const currentDateTime = Date.now();
            const cardExpirationDate = new Date(yearFromValue, monthFromValue, LAST_DAY_OF_PREV_MONTH).getTime();
            const isCorrectCardExpirationDate = cardExpirationDate - currentDateTime >= 0;
            const isCorrectMonth = monthFromValue <= 12 && monthFromValue >= 0;
            const isCorrectYear = (cardExpirationDate - currentDateTime) / YEAR <= MAX_YEARS_FOR_EXPIRATION_DATE;
            if (!(isCorrectMonth && isCorrectYear && isCorrectCardExpirationDate)) message = $translate('WEB2_INCORRECT_DATE').value;
        }
        return message ? {
            [fieldName]: [
                {
                    value: value.value,
                    message
                }
            ]
        } : {};
    });
    return {
        cardExpirationErrors
    };
}
