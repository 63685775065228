import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { isNullOrUndefined, isObject } from '@leon-hub/guards';
import { Json } from '@leon-hub/utils';
import { ComponentStatus } from './types';
const theMaximumRecursionDepth = 6;
const stringJoinerSeparator = '~~~';
let AbstractFingerprintComponent = class AbstractFingerprintComponent {
    result(value) {
        let result = '';
        if (Array.isArray(value)) result = value.flat(theMaximumRecursionDepth).join(stringJoinerSeparator);
        else if ('string' == typeof value && value.length > 0) result = value;
        else if (isNullOrUndefined(value)) {
            if (isObject(value)) {
                const stringifiedResult = Json.stringify(value, {
                    circular: true,
                    circularPlaceholder: '[Circular]',
                    defaultValue: ComponentStatus.NoData
                });
                result = isNullOrUndefined(stringifiedResult) ? ComponentStatus.NoData : stringifiedResult;
            } else result = ComponentStatus.NoData;
        } else result = String(value);
        return Promise.resolve({
            key: this.key,
            value: result
        });
    }
    constructor(key){
        _define_property(this, "key", void 0);
        this.key = key;
    }
};
export { AbstractFingerprintComponent as default };
