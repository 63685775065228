import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: "progress"
};
const _hoisted_2 = {
    key: "progress"
};
const _hoisted_3 = {
    key: "progress-direct"
};
const _hoisted_4 = {
    key: "progress-inactive"
};
const _hoisted_5 = {
    key: 0
};
import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import { vTimer } from 'web/src/modules/sportline/directives';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeadlineLiveTimeInfo',
    props: {
        sportlineEvent: {}
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const settingsStore = useSportlineSettingsStore();
        const isTimeReversedIndicatorAvailable = toRef(settingsStore, 'isTimeReversedIndicatorAvailable');
        const { matchProgress } = useLiveMatchProgress({
            sportEvent: sportlineEvent
        });
        const { gameScoreProgress, additionalTime, isStaticLiveProgress, isTimeReversed, staticDirectTimeProgressLabel, staticReversedTimeProgressLabel, mainTimeDirectiveDirection, additionalTimeDirectiveDirection, mainTimeTotalSeconds, mainTimeProgress, mainTimeProgressShortFormat, additionalTimeTotalSeconds, additionalTimeProgress } = useLiveStatus(matchProgress);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _unref(gameScoreProgress) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(gameScoreProgress)), 1)) : _unref(isStaticLiveProgress) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    isTimeReversedIndicatorAvailable.value && _unref(isTimeReversed) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _unref(staticReversedTimeProgressLabel) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(staticReversedTimeProgressLabel)), 1)) : _createCommentVNode("", true)
                    ], 64)) : _unref(staticDirectTimeProgressLabel) ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(staticDirectTimeProgressLabel)), 1)) : _createCommentVNode("", true)
                ], 64)) : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _unref(additionalTime) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _createTextVNode(_toDisplayString(_unref(mainTimeProgressShortFormat)) + " +", 1),
                        _withDirectives((_openBlock(), _createElementBlock("span", null, [
                            _createTextVNode(_toDisplayString(_unref(additionalTimeProgress)), 1)
                        ])), [
                            [
                                _unref(vTimer),
                                _unref(additionalTimeTotalSeconds),
                                _unref(additionalTimeDirectiveDirection)
                            ]
                        ])
                    ], 64)) : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _createTextVNode(_toDisplayString(_unref(mainTimeProgress)), 1)
                    ])), [
                        [
                            _unref(vTimer),
                            _unref(mainTimeTotalSeconds),
                            _unref(mainTimeDirectiveDirection)
                        ]
                    ])
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'HeadlineLiveTimeInfo'
                ]
            ]);
        };
    }
});
