import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = [
    "onClick"
];
import { ref, computed } from 'vue';
import { sleep } from '@leon-hub/utils';
import { useSwiperNavigationPoints } from './useSwiperNavigationPoints';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SwiperNavigationPoints',
    props: {
        activeSlideIndex: {},
        countSlides: {},
        type: {
            default: 'default'
        },
        isSingleSlideHidden: {
            type: Boolean
        },
        isFilledPreviousPoint: {
            type: Boolean
        },
        maxSlidePointsCount: {},
        isSmoothSlideDisabled: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { slidesCount, slideToSlide, isPointSelected } = useSwiperNavigationPoints(props);
        const isSmoothSlideAvailable = ref(false);
        const isSmoothSlideAvailableFinal = computed(()=>!props.isSmoothSlideDisabled && isSmoothSlideAvailable.value);
        async function onMounted() {
            await sleep(100);
            isSmoothSlideAvailable.value = true;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return !_ctx.isSingleSlideHidden || _unref(slidesCount) > 1 ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['swiper-navigation-points']]: true,
                    [_ctx.$style[`swiper-navigation-points--${_ctx.type}`]]: true
                }),
                onVnodeMounted: onMounted
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(slidesCount), (index)=>(_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass({
                            [_ctx.$style['swiper-navigation-points__item']]: true,
                            [_ctx.$style[`swiper-navigation-points__item--${_ctx.type}`]]: true,
                            [_ctx.$style[`swiper-navigation-points__item--${_ctx.type}-transition`]]: isSmoothSlideAvailableFinal.value,
                            [_ctx.$style[`swiper-navigation-points__item--${_ctx.type}-selected`]]: _unref(isPointSelected)(index)
                        }),
                        onClick: ($event)=>_unref(slideToSlide)(index - 1, {
                                smooth: true
                            })
                    }, null, 10, _hoisted_1))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'SwiperNavigationPoints'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
