import { defineStore } from 'pinia';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useExtendedEventBackgroundRequestsStoreComposable, useExtendedEventIdsStoreComposable, useExtendedEventSettings } from './composables';
import { createFetchEventDetailsMethod, createEventDetailsRefsMap } from './utils';
/**
 * Open markets in the events list
 */ export const useExtendedEventStore = defineStore('sportline-extended-event-store', ()=>{
    const { showDialog } = useDialogs();
    const apiService = useSportlineApiService();
    const { parseSportlineSettings } = useExtendedEventSettings();
    const { addEventId: addEventIdToOpened, removeEventId: removeEventIdFromOpened, createIsEventInListRef: createEventIsOpenedRef, getIsEventInList: getEventIsOpened, createListRef: createOpenedEventsListRef } = useExtendedEventIdsStoreComposable({
        maxInType: 1
    });
    const { addEventId: addEventIdToBackgroundRequests, removeEventId: removeEventIdFromBackgroundRequests, fullEventsIds: backgroundRequestsEventsIds } = useExtendedEventIdsStoreComposable();
    const { setEventDetailsData, createEventDetailsRef } = createEventDetailsRefsMap();
    const fetchSportlineEventDetails = createFetchEventDetailsMethod(apiService, {
        parseSportlineSettings
    }, {
        showDialog
    });
    async function loadSportlineEventDetails(id) {
        let silent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        const sportlineEventDetails = await fetchSportlineEventDetails({
            id,
            silent
        });
        if (!sportlineEventDetails) return;
        setEventDetailsData(id, sportlineEventDetails);
    }
    async function backgroundRequest() {
        const requests = backgroundRequestsEventsIds.value.map((id)=>loadSportlineEventDetails(id, true));
        await Promise.allSettled(requests);
    }
    useExtendedEventBackgroundRequestsStoreComposable({
        eventsIds: backgroundRequestsEventsIds
    }, backgroundRequest);
    return {
        createEventIsOpenedRef,
        getEventIsOpened,
        createOpenedEventsListRef,
        addEventIdToOpened,
        removeEventIdFromOpened,
        addEventIdToBackgroundRequests,
        removeEventIdFromBackgroundRequests,
        createEventDetailsRef,
        loadSportlineEventDetails
    };
});
