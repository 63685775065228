import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
export function useExtendedEventSettings() {
    const settingsStore = useSportlineSettingsStore();
    const extendedEventDetailsMarketLimit = toRef(settingsStore, 'listMarketsDropDownLimit');
    const isMarketGroupsTabsEnabled = toRef(settingsStore, 'isMarketGroupsTabsEnabled');
    const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');
    return {
        extendedEventDetailsMarketLimit,
        isMarketGroupsTabsEnabled,
        parseSportlineSettings
    };
}
