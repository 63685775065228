import { toRef, inject, computed } from 'vue';
import { ProvideSelectedColumnKey } from '../constants';
export function useInjectSelectedColumn(props) {
    // do not inject settings for blocks without sport info
    const basisKey = toRef(props.basisKey);
    const columnMap = toRef(inject(ProvideSelectedColumnKey, {}));
    const providedSportColumnSettings = computed(()=>{
        if (!basisKey.value) return null;
        return columnMap.value[basisKey.value] ?? null;
    });
    const sportColumnSettings = computed(()=>({
            selectedColumnId: providedSportColumnSettings.value?.selectedColumnId ?? null,
            selectedColumnSize: providedSportColumnSettings.value?.selectedColumnSize ?? null,
            selectedColumnSign: providedSportColumnSettings.value?.selectedColumnSign ?? null,
            columns: providedSportColumnSettings.value?.columns ?? []
        }));
    const selectedColumnId = computed(()=>sportColumnSettings.value.selectedColumnId);
    const selectedColumnSize = computed(()=>sportColumnSettings.value.selectedColumnSize);
    const selectedColumnSign = computed(()=>sportColumnSettings.value.selectedColumnSign);
    const columns = computed(()=>sportColumnSettings.value.columns);
    return {
        sportColumnSettings,
        selectedColumnId,
        selectedColumnSize,
        selectedColumnSign,
        columns
    };
}
