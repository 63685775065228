import { logger } from '@leon-hub/logging';
import { assertUnknownGetEventsChangesResponse } from 'web/src/modules/sportline/asserts/rest';
import { filterCorruptedEventsInChangesResponse, logCorruptedEventsIds } from 'web/src/modules/sportline/utils/response';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
export default async function getEventsChanges(input) {
    const apiClient = useSportlineApi();
    const response = await apiClient.getChanges(input);
    try {
        assertUnknownGetEventsChangesResponse(response);
        return filterCorruptedEventsInChangesResponse(response, logCorruptedEventsIds);
    } catch (error) {
        logger.error('apiClient getEventsChanges response corrupted', error);
        // always return response for user
        return {
            totalCount: 0,
            data: []
        };
    }
}
