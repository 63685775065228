import { IconName, IconSize } from '@leon-hub/icons';
export default function getPropsForIcon(props) {
    const defaults = {
        name: IconName.INFO_OUTLINE,
        size: IconSize.SIZE_24
    };
    if (props.icon) return {
        ...defaults,
        name: props.icon
    };
    return defaults;
}
