import useTimeBus from 'web/src/modules/core/composables/time-bus/useTimeBus';
/**
 * Composable to create directive
 */ export function useLiveProgressTimerController(props) {
    const { updateEvery, updated, isBindingChanged } = props;
    const { now, start, stop, subscribe, unsubscribe, getSubscriptionsCount } = useTimeBus(updateEvery ?? 1000);
    const subscriptionMap = new WeakMap();
    function hasSubscriptions() {
        return getSubscriptionsCount() > 0;
    }
    function remove(callback) {
        unsubscribe(callback);
        const isLastSubscription = !hasSubscriptions();
        if (isLastSubscription) stop();
    }
    function add(callback) {
        const isFirstSubscription = !hasSubscriptions();
        subscribe(callback);
        if (isFirstSubscription) start();
        return ()=>{
            remove(callback);
        };
    }
    function setSubscription(element, binding) {
        let unsubscribeForElement = subscriptionMap.get(element);
        unsubscribeForElement?.();
        const createdAt = now.value;
        unsubscribeForElement = add((updatedAt)=>updated(element, binding, updatedAt - createdAt));
        subscriptionMap.set(element, unsubscribeForElement);
    }
    return {
        getSubscriptionsCount,
        hasSubscriptions,
        beforeElementMounted (element, binding) {
            updated(element, binding, 0);
            setSubscription(element, binding);
        },
        elementUpdated (element, binding) {
            if (!isBindingChanged(binding)) return;
            updated(element, binding, 0);
            setSubscription(element, binding);
        },
        elementUnmounted (element) {
            const unsubscribeForElement = subscriptionMap.get(element);
            unsubscribeForElement?.();
            subscriptionMap.delete(element);
        }
    };
}
