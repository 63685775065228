import { computed } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { isAddToCustomerLeaguesParametersEquals } from '../utils';
import { useFavoriteLeagueData } from './useFavoriteLeagueData';
import { useIsFavoriteLeague } from './useIsFavoriteLeague';
export function useLeagueFavoriteIndicator(props) {
    const { sportId, leagueId } = props;
    const { changeFavoriteLeagueState, pendingToFavoriteLeaguesList, isOnlyAddToPendingFavoriteLeagues } = useFavoriteLeagueData();
    const isFavorite = useIsFavoriteLeague(leagueId);
    const router = useRouter();
    async function toggleLeagueFavoriteState() {
        // Use variables instead of .value because of typechecking problem
        const sId = sportId.value;
        const lId = leagueId.value;
        if (!sId || !lId) return;
        const options = {
            sportId: sId,
            leagueId: lId
        };
        if (!isOnlyAddToPendingFavoriteLeagues.value) {
            // for logged-in customers just add to favorites
            await changeFavoriteLeagueState(options);
            return;
        }
        const pendingList = pendingToFavoriteLeaguesList.value;
        const isRemovingFromPending = pendingList.some((pending)=>isAddToCustomerLeaguesParametersEquals(pending, options));
        await changeFavoriteLeagueState(options);
        // show login only for fist added league
        if (0 === pendingList.length && !isRemovingFromPending) router.push({
            name: RouteName.LOGIN
        });
    }
    const hasFavoriteButton = computed(()=>!!sportId.value && !!leagueId.value);
    return {
        isFavorite,
        isOnlyPending: isOnlyAddToPendingFavoriteLeagues,
        hasFavoriteButton,
        toggleLeagueFavoriteState
    };
}
