/* eslint-disable no-param-reassign */ import { sleep } from '@leon-hub/utils';
export async function playExpandAnimation(content, param) {
    let { duration, maxHeight } = param;
    content.style.willChange = 'visibility, max-height';
    content.style.maxHeight = `${maxHeight}px`;
    content.style.visibility = 'visible';
    await sleep(duration);
    content.style.maxHeight = '';
    content.style.visibility = '';
    content.style.willChange = '';
}
