import { isString, isObject } from '@leon-hub/guards';
import { IconName, IconSize } from '@leon-hub/icons';
// eslint-disable-next-line import/prefer-default-export
export function convertNavigationToSidebarMenu(items) {
    let l1Mapper = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, l2Mapper = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, l3Mapper = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    const convertNavigationToSidebarMenuL3 = (itemsL3, parent, grandParent)=>itemsL3.map((itemL3)=>{
            const { caption: captionL3 } = itemL3;
            isString(captionL3), itemL3.id;
            return {
                active: void 0 !== l3Mapper.active && l3Mapper.active(itemL3, parent, grandParent),
                id: itemL3.id,
                key: `level3-spoiler-${grandParent.id}-${parent.id}-${itemL3.id}`,
                location: void 0 !== l3Mapper.location ? l3Mapper.location(itemL3, parent, grandParent) : null,
                externalUrl: itemL3.url,
                name: captionL3,
                suffix: itemL3.props?.isHot ? {
                    size: IconSize.SIZE_16,
                    name: IconName.FIRE
                } : void 0
            };
        });
    const convertNavigationToSidebarMenuL2 = (itemsL2, parent)=>itemsL2.map((itemL2)=>{
            const { caption: captionL2, children: childrenL2 = [] } = itemL2;
            isString(captionL2), itemL2.id;
            return {
                key: `level2-spoiler-${parent.id}-${itemL2.id}`,
                active: void 0 !== l2Mapper.active && l2Mapper.active(itemL2, parent),
                location: void 0 !== l2Mapper.location ? l2Mapper.location(itemL2, parent) : null,
                externalUrl: itemL2.url,
                name: captionL2,
                suffix: itemL2.props?.isHot ? {
                    size: IconSize.SIZE_16,
                    name: IconName.FIRE
                } : void 0,
                menu: convertNavigationToSidebarMenuL3(childrenL2, itemL2, parent)
            };
        });
    return items.map((item)=>{
        const { id, caption, children = [], props } = item;
        isString(caption);
        const menuL1 = {
            active: void 0 !== l1Mapper.active && l1Mapper.active(item),
            canOpen: void 0 !== l1Mapper.canOpen ? l1Mapper.canOpen() : !!children.length,
            id,
            key: `level1-spoiler-${id}`,
            location: void 0 !== l1Mapper.location ? l1Mapper.location(item) : null,
            externalUrl: item.url,
            name: caption,
            suffix: item.props?.isHot ? {
                size: IconSize.SIZE_16,
                name: IconName.FIRE
            } : void 0,
            menu: convertNavigationToSidebarMenuL2(children, item),
            props
        };
        if (item.icon) Object.assign(menuL1, {
            icon: {
                ...item.icon
            }
        });
        if (item.badge || l1Mapper.badge) menuL1.badge = void 0 !== l1Mapper.badge ? l1Mapper.badge(item) : item.badge;
        if (item.progress) menuL1.progress = void 0 !== l1Mapper.progress ? l1Mapper.progress(item) : item.progress;
        if (item.suffixText) menuL1.suffixText = void 0 !== l1Mapper.suffixText ? l1Mapper.suffixText(item) : item.suffixText;
        return menuL1;
    });
}
export function isValidLocation(location) {
    return !!location && (!isObject(location) || !!location.name);
}
