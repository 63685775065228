import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsExclusiveBadge',
    props: {
        isPreview: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['egs-exclusive-badge']]: true,
                    [_ctx.$style['egs-exclusive-badge--preview']]: _ctx.isPreview
                })
            }, [
                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_EXCLUSIVE_BADGE')), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'EgsExclusiveBadge'
                ]
            ]);
        };
    }
});
