import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { PasswordValidateTemplateItemStatus } from '../enums';
import getRulesFromNotPassedGroups from './utils/getRulesFromNotPassedGroups';
export default function useVPasswordValidator(props) {
    const { $translate } = useI18n();
    const rules = computed(()=>getRulesFromNotPassedGroups(props.groups, props.password));
    const isValidPassword = computed(()=>{
        for (const rule of rules.value)if (!rule.isValid) return false;
        return true;
    });
    const templates = computed(()=>{
        if (!isValidPassword.value) return rules.value.map((rule)=>({
                message: rule.message,
                // eslint-disable-next-line no-nested-ternary
                status: rule.isValid ? PasswordValidateTemplateItemStatus.Success : props.isFocused ? PasswordValidateTemplateItemStatus.Default : PasswordValidateTemplateItemStatus.Error
            }));
        if (props.error) return [
            {
                message: props.error,
                status: PasswordValidateTemplateItemStatus.Error,
                icon: false
            }
        ];
        return [
            {
                message: props.successMessage || $translate('WEB2_VALID_STRONG_PASSWORD').value,
                status: PasswordValidateTemplateItemStatus.Success
            }
        ];
    });
    return {
        isValidPassword,
        templates
    };
}
