export default function getParsedValue(value, props) {
    let parsedValue = '';
    if (value) {
        parsedValue = props.deposit ? value.replace(/\D|(\D+\.+$|\D+,+$)/g, '') : value.replace(/[^\d,.]|(\D+\.+$|\D+,+$)/g, '');
        parsedValue = parsedValue.replace(/^0{2,}/g, '0');
        if (!props.deposit) {
            const matched = parsedValue.match(/^\d+[,.]\d{0,2}/g);
            parsedValue = matched?.length ? matched[0] : parsedValue;
        }
    }
    return '0' === parsedValue ? '' : parsedValue;
}
