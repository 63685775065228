import { computed, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { VideoVerificationStatus } from '@leon-hub/websocket/src/sdk-ws';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useVideoVerificationAlert() {
    const frontNotificationsStore = useFrontNotificationsStore();
    const { $translate } = useI18n();
    const videoVerificationNotifications = toRef(frontNotificationsStore, 'videoVerificationNotifications');
    const snackbarProperties = computed(()=>{
        const notification = videoVerificationNotifications.value[0];
        let iconName = IconName.ATTENTION;
        let type = 'default';
        let buttonLabel = $translate('JSP_NEWS_MORE').value;
        const status = notification?.status;
        if (status === VideoVerificationStatus.PASSED) {
            iconName = IconName.CHECK_FILLED;
            type = 'success';
        } else if (status === VideoVerificationStatus.SCHEDULED || status === VideoVerificationStatus.INVITED || status === VideoVerificationStatus.ON_REVIEW || status === VideoVerificationStatus.PENDING || status === VideoVerificationStatus.NOT_PASSED) iconName = IconName.PERSON;
        if (status === VideoVerificationStatus.NONE || status === VideoVerificationStatus.UNKNOWN || status === VideoVerificationStatus.PENDING) buttonLabel = void 0;
        return {
            title: $translate('WEB2_ACCOUNT_VERIFICATION').value,
            text: notification?.message,
            buttonLabel,
            type,
            iconName,
            hasClose: true
        };
    });
    async function markVideoVerificationAlert() {
        await frontNotificationsStore.markNotification({
            id: videoVerificationNotifications.value[0].id
        });
        const unmarkedNotificationIds = [];
        if (videoVerificationNotifications.value.length > 0) for (const element of videoVerificationNotifications.value){
            frontNotificationsStore.removeFrontNotification(element.id);
            unmarkedNotificationIds.push(element.id);
        }
        if (unmarkedNotificationIds.length) for (const id of unmarkedNotificationIds)frontNotificationsStore.markNotification({
            id
        });
    }
    return {
        snackbarProperties,
        markVideoVerificationAlert
    };
}
