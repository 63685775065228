import { computed, toRef } from 'vue';
import { useRouterStore } from 'web/src/modules/core/store';
import { getLobbyTypeByRouteName } from './utils';
export default function useLobbyType() {
    const routerStore = useRouterStore();
    const currentRouteName = toRef(routerStore, 'currentRouteName');
    const nextRouteName = toRef(routerStore, 'nextRouteName');
    const lobbyType = computed(()=>getLobbyTypeByRouteName(nextRouteName.value || currentRouteName.value));
    return {
        lobbyType
    };
}
