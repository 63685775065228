import { isString, isBoolean } from '@leon-hub/guards';
export default function getBooleanOption(value) {
    if (isBoolean(value)) return value;
    if (isString(value)) switch(value){
        case '':
        case 'false':
        case '0':
            return false;
        default:
            return true;
    }
    return Boolean(value);
}
