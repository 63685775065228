import { ref } from 'vue';
import { getFreebetData } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export function useFreeBetData() {
    const gqlClient = useGraphqlClient();
    const freeBetActionId = ref('');
    const freeBetActionBannerUrl = ref('');
    const freeBetTermsPage = ref('');
    const freeBetBcode = ref('');
    async function fetchFreeBetData(freeBetActionUrl) {
        const response = await getFreebetData(gqlClient, (node)=>node.queries.registration.getAvailableForms, {
            options: {
                freeBetActionUrl
            }
        });
        freeBetActionId.value = response?.freeBetActionId ?? '';
        freeBetActionBannerUrl.value = response?.freeBetActionBannerUrl ?? '';
        freeBetTermsPage.value = response?.freeBetTermsPage ?? '';
        freeBetBcode.value = response?.freeBetBcode ?? '';
    }
    return {
        freeBetActionId,
        freeBetActionBannerUrl,
        freeBetTermsPage,
        freeBetBcode,
        fetchFreeBetData
    };
}
