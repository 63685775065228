import { ref } from 'vue';
export default function useDropdownSearchInput() {
    const dropdownSearchInput = ref();
    const searchQuery = ref('');
    const clearSearchQuery = ()=>{
        searchQuery.value = '';
    };
    const handleSearchInput = (event)=>{
        const { target } = event;
        searchQuery.value = target.value;
    };
    const focusOnDropdownInput = ()=>{
        dropdownSearchInput.value?.focus();
    };
    return {
        dropdownSearchInput,
        searchQuery,
        clearSearchQuery,
        handleSearchInput,
        focusOnDropdownInput
    };
}
