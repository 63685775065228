/* eslint-disable class-methods-use-this */ import DefaultPageSeoController from 'web/src/modules/seo/DefaultPageSeoController';
import { useLobbyType } from 'web/src/modules/lobby/composables';
let EgsPageSeoController = class EgsPageSeoController extends DefaultPageSeoController {
    getSeoConfig(router, from, to) {
        return new Promise((resolve)=>{
            const { lobbyType } = useLobbyType();
            const params = {
                ...to.params || {},
                lobbyType: lobbyType.value
            };
            resolve({
                ...to,
                params
            });
        });
    }
};
export { EgsPageSeoController as default };
