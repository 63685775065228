import { watch } from 'vue';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { useThemeColorStore } from 'web/src/modules/theme/store';
export function useCustomThemeColor(type, condition) {
    const { startThemeColorWatcher, stopThemeColorWatcher } = useThemeColorStore();
    if (condition) watch(condition, (newValue)=>{
        if (newValue) startThemeColorWatcher(type);
        else stopThemeColorWatcher();
    }, {
        immediate: true
    });
    else onComponentActivated(()=>{
        startThemeColorWatcher(type);
    });
    onComponentDeactivated(()=>{
        stopThemeColorWatcher();
    });
}
