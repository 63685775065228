import { mergeDefaults as _mergeDefaults, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { getVButtonDefaultProps } from 'web/src/components/Button/VButton/utils/getVButtonDefaultProps';
import { isSButtonProps, isVButtonProps } from 'web/src/components/Button/SButton/types';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'JumbotronFooterButton',
    props: /*@__PURE__*/ _mergeDefaults({
        rounded: {
            type: Boolean
        },
        kind: {},
        height: {},
        textColor: {},
        isUppercase: {
            type: Boolean
        },
        iconSize: {},
        hasAnimation: {
            type: Boolean
        },
        isAbsolutePosition: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        for: {},
        fullWidth: {
            type: Boolean
        },
        href: {},
        iconName: {},
        iconRight: {
            type: Boolean
        },
        isAutofocus: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        },
        isNoPaddings: {
            type: Boolean
        },
        label: {},
        role: {},
        tag: {},
        target: {},
        type: {},
        id: {},
        size: {},
        action: {}
    }, getVButtonDefaultProps()),
    emits: [
        "click",
        "keydown",
        "mousedown",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        return (_ctx, _cache)=>_unref(isVButtonProps)(props) ? (_openBlock(), _createBlock(VButton, _mergeProps({
                key: 1
            }, props, {
                class: {
                    [_ctx.$style['jumbotron-footer-button']]: true,
                    [_ctx.$style['jumbotron-footer-button--full-width']]: props.fullWidth
                },
                onClick: _cache[3] || (_cache[3] = ($event)=>emit('click', $event)),
                onKeydown: _cache[4] || (_cache[4] = ($event)=>emit('keydown', $event)),
                onMousedown: _cache[5] || (_cache[5] = ($event)=>emit('mousedown', $event))
            }), {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 16, [
                "class"
            ])) : _createCommentVNode("", true);
    }
});
