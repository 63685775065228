import { computed } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
export default function useVSubHeader(props) {
    const mainProperties = computed(()=>{
        const properties = {};
        if (props.href) properties.href = props.href;
        return properties;
    });
    const mainTag = computed(()=>props.href ? Tag.A : Tag.DIV);
    const prefixIconProperties = computed(()=>({
            name: props.prefixIcon,
            size: IconSize.SIZE_16
        }));
    const suffixIconProperties = computed(()=>({
            name: props.suffixIcon,
            size: IconSize.SIZE_16
        }));
    return {
        mainProperties,
        mainTag,
        prefixIconProperties,
        suffixIconProperties
    };
}
