import RouteName from '@leon-hub/routing-config-names';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { SearchTab } from 'web/src/modules/search/enums';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
const modalPreset = ModalDesktopPreset.SearchDesktopModal;
export class SearchModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addModalRoute({
            name: RouteName.SEARCH,
            path: '/search',
            component: ()=>import('web/src/modules/search/pages/SearchRoutePage/SearchRoutePage.vue'),
            title: '{{$t(\'WEB2_SEARCH_TITLE\')}}',
            modalPreset: modalPreset
        });
        "1";
        router.addModalRoute({
            name: CasinoRouteName.CASINO_SEARCH,
            path: '/casino/search',
            component: ()=>import('web/src/modules/search/pages/SearchRoutePage/SearchRoutePage.vue'),
            props: {
                defaultTabIndex: SearchTab.SLOTS
            },
            modalPreset: modalPreset,
            title: '{{$t(\'WEB2_SEARCH_TITLE\')}}'
        });
    }
}
