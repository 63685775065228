import { FormControlType } from 'web/src/components/Form/enums';
import FastSum from 'web/src/modules/payments/form-widgets/FastSum';
import CreditCardSelector from 'web/src/modules/payments/form-widgets/CreditCardSelector';
import PhoneDisabledInput from 'web/src/modules/payments/form-widgets/PhoneDisabledInput';
import WalletDisabledInput from 'web/src/modules/payments/form-widgets/WalletDisabledInput';
import WebmoneyDisabledInput from 'web/src/modules/payments/form-widgets/WebmoneyDisabledInput';
import FormCardCvv from 'web/src/modules/payments/form-widgets/FormCardCvv';
import FormCardExpirationDate from 'web/src/modules/payments/form-widgets/FormCardExpirationDate';
import FormCardNumber from 'web/src/modules/payments/form-widgets/FormCardNumber';
import FormDepositBonusSelector from 'web/src/modules/payments/form-widgets/DepositBonusSelector';
import FormCardNameInput from 'web/src/modules/payments/form-widgets/FormCardNameInput';
import FormCryptoPaymentData from './FormCryptoPaymentData';
const customPaymentsFormWidgets = {
    [FormControlType.FastSum]: FastSum,
    [FormControlType.CreditCardSelector]: CreditCardSelector,
    [FormControlType.PhoneDisabledInput]: PhoneDisabledInput,
    [FormControlType.WalletDisabledInput]: WalletDisabledInput,
    [FormControlType.WebmoneyDisabledInput]: WebmoneyDisabledInput,
    [FormControlType.CardCvv]: FormCardCvv,
    [FormControlType.CardExpirationDate]: FormCardExpirationDate,
    [FormControlType.CardNumber]: FormCardNumber,
    [FormControlType.DepositBonus]: FormDepositBonusSelector,
    [FormControlType.CardName]: FormCardNameInput
};
"1";
// not for leonru
customPaymentsFormWidgets[FormControlType.CryptoPaymentData] = FormCryptoPaymentData;
export default Object.freeze(customPaymentsFormWidgets);
