import { computed } from 'vue';
import { isOptionalBoolean, isOptionalString } from '@leon-hub/guards';
import isCheckboxProps from 'web/src/components/Checkbox/VCheckbox/guards/isCheckboxProps';
export default function useFormCheckbox(props, label) {
    const componentProps = computed(()=>{
        const { name, value, disabled, error, options, autofocus } = props;
        isOptionalBoolean(value);
        isOptionalString(error);
        isCheckboxProps(options);
        return {
            name,
            error,
            label,
            ...options,
            checked: value,
            disabled,
            autofocus
        };
    });
    return {
        componentProps
    };
}
