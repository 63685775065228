import { getLocationOrigin } from '@leon-hub/service-locator-env';
import isSameOrigin from './isSameOrigin';
export default ((url, router)=>{
    const sameOrigin = isSameOrigin(url);
    if (!sameOrigin) return null;
    const routerPaths = router?.options?.routes?.map((route)=>route.path) || [];
    const internalPath = url.replace(getLocationOrigin(), '').split('#')[0];
    if (routerPaths.includes(internalPath)) return internalPath;
    return null;
});
