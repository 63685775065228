import { LegacyIconName, EsportIconName } from '@leon-hub/icons';
import { CyberSportDiscipline } from 'web/src/modules/sportline/enums';
export const RegionFamilyToIconMappingDefault = LegacyIconName.GAMEPAD;
export const RegionFamilyToIconMapping = Object.freeze({
    [CyberSportDiscipline.CALL_OF_DUTY]: EsportIconName.ESPORT_CALL_OF_DUTY,
    [CyberSportDiscipline.CALL_OF_DUTY_MOBILE]: EsportIconName.ESPORT_CALL_OF_DUTY_MOBILE,
    [CyberSportDiscipline.CSGO]: EsportIconName.ESPORT_CSGO,
    [CyberSportDiscipline.DEADLOCK]: EsportIconName.ESPORT_DEADLOCK,
    [CyberSportDiscipline.DOTA]: EsportIconName.ESPORT_DOTA,
    [CyberSportDiscipline.FIFA]: EsportIconName.ESPORT_FIFA,
    [CyberSportDiscipline.HEARTH_STONE]: EsportIconName.ESPORT_HEARTH_STONE,
    [CyberSportDiscipline.HEROES_OF_THE_STORM]: EsportIconName.ESPORT_HEROES_OF_THE_STORM,
    [CyberSportDiscipline.KING_OF_GLORY]: EsportIconName.ESPORT_KING_OF_GLORY,
    [CyberSportDiscipline.LEAGUE_OF_LEGENDS]: EsportIconName.ESPORT_LEAGUE_OF_LEGENDS,
    [CyberSportDiscipline.OVERWATCH]: EsportIconName.ESPORT_OVERWATCH,
    [CyberSportDiscipline.STARCRAFT]: EsportIconName.ESPORT_STARCRAFT,
    [CyberSportDiscipline.STARCRAFT_2]: EsportIconName.ESPORT_STARCRAFT_2,
    [CyberSportDiscipline.VALORANT]: EsportIconName.ESPORT_VALORANT,
    [CyberSportDiscipline.WARCRAFT_3]: EsportIconName.ESPORT_WARCRAFT_3,
    [CyberSportDiscipline.WORLD_OF_TANKS]: EsportIconName.ESPORT_WORLD_OF_TANKS,
    [CyberSportDiscipline.RAINBOW_SIX]: EsportIconName.ESPORT_RAINBOW_SIX,
    [CyberSportDiscipline.ROCKET_LEAGUE]: EsportIconName.ESPORT_ROCKET_LEAGUE,
    [CyberSportDiscipline.LOL_WILD_RIFT]: EsportIconName.ESPORT_LOL_WILD_RIFT,
    [CyberSportDiscipline.MOBILE_LEGENDS_BANG_BANG]: EsportIconName.ESPORT_MOBILE_LEGENDS_BANG_BANG,
    [CyberSportDiscipline.CROSSFIRE]: EsportIconName.ESPORT_CROSSFIRE,
    [CyberSportDiscipline.BRAWL_STARS]: EsportIconName.ESPORT_BRAWL_STARS,
    [CyberSportDiscipline.ARENA_OF_VALOR]: EsportIconName.ESPORT_ARENA_OF_VALOR,
    [CyberSportDiscipline.HALO]: EsportIconName.ESPORT_HALO,
    [CyberSportDiscipline.AOE2]: EsportIconName.ESPORT_AGE_OF_EMPIRES_2,
    [CyberSportDiscipline.AOE4]: EsportIconName.ESPORT_AGE_OF_EMPIRES_4,
    [CyberSportDiscipline.FREE_FIRE]: EsportIconName.ESPORT_FREE_FIRE,
    [CyberSportDiscipline.APEX_LEGENDS]: EsportIconName.ESPORT_APEX,
    [CyberSportDiscipline.PUBG_MOBILE]: EsportIconName.ESPORT_PUBG_MOBILE,
    [CyberSportDiscipline.PUBG]: EsportIconName.ESPORT_PUBG,
    [CyberSportDiscipline.BOOST]: EsportIconName.ESPORTLINE_BOOST,
    [CyberSportDiscipline.FORTNITE]: EsportIconName.ESPORT_FORTNITE,
    [CyberSportDiscipline.STREET_FIGHTER]: EsportIconName.ESPORT_STREET_FIGHTER,
    [CyberSportDiscipline.TEKKEN]: EsportIconName.ESPORT_TEKKEN
});
