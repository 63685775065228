import { computed, ref, nextTick } from 'vue';
import { CustomInputEventType, InputEventType } from 'web/src/components/Input/enums';
export default function useFileSingleStateless(props, emit, inputRef) {
    const isHover = ref(false);
    const isFocus = ref(false);
    const resetIndex = ref(0);
    const isEmpty = computed(()=>!props.fileName?.length);
    const onMouseenter = ()=>{
        isHover.value = true;
    };
    const onMouseleave = ()=>{
        isHover.value = false;
    };
    const onFocus = (event)=>{
        isFocus.value = true;
        emit(InputEventType.FOCUS, event);
    };
    const onBlur = (event)=>{
        isFocus.value = false;
        emit(InputEventType.BLUR, event);
    };
    const onChange = (event)=>{
        emit(InputEventType.CHANGE, event);
    };
    const onClear = async ()=>{
        resetIndex.value += 1;
        await nextTick();
        emit(CustomInputEventType.CLEAR);
    };
    const onClick = async (event)=>{};
    const splittedFileName = computed(()=>{
        let name = '';
        let extension = '';
        if (!props.fileName) return {
            name,
            extension
        };
        const latsDot = props.fileName.lastIndexOf('.');
        if (latsDot > -1) {
            name = props.fileName.slice(0, latsDot);
            extension = props.fileName.slice(latsDot);
        } else name = props.fileName;
        return {
            name,
            extension
        };
    });
    const formattedFileName = computed(()=>splittedFileName.value.name);
    const fileExtension = computed(()=>splittedFileName.value.extension);
    return {
        isHover,
        isFocus,
        isEmpty,
        resetIndex,
        formattedFileName,
        fileExtension,
        onMouseenter,
        onMouseleave,
        onFocus,
        onBlur,
        onClear,
        onClick,
        onChange
    };
}
