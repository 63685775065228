import { logger } from '@leon-hub/logging';
import { isString, isObject, isUndefined } from '@leon-hub/guards';
import { FormControlType, FormErrorKeyword } from 'web/src/components/Form/enums';
import isLegalAge from 'web/src/utils/validation/isLegalAge';
import { getFileExtension } from 'web/src/components/Input/utils';
import isDateInputCommonProperties from 'web/src/components/Input/guards/isDateInputCommonProperties';
import isFileMultipleInputProps from 'web/src/components/Input/guards/isFileMultipleInputProps';
import isFileMultipleDragAndDropInputProperties from 'web/src/components/Input/guards/isFileMultipleDragAndDropInputProperties';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ export const isFileAllowed = (allowedTypes, file)=>{
    if (0 === Object.keys(allowedTypes).length) return true;
    const extension = getFileExtension(file);
    return '' !== extension && !!allowedTypes[extension];
};
export function mergeValidatorPatterns(defaultPattern, clientPattern) {
    if (!clientPattern) return defaultPattern;
    return {
        common: {
            ...defaultPattern.common,
            ...clientPattern.common
        },
        byWidget: {
            ...defaultPattern.byWidget,
            ...clientPattern.byWidget
        },
        byPattern: {
            ...clientPattern.byPattern
        }
    };
}
function getErrorsFromSchemaErrors(errors, errorMapper) {
    if (!errors) return new Map();
    return errors.map((element)=>errorMapper(element)).// first error is required keyword always
    sort((a, b)=>{
        if (a.keyword === FormErrorKeyword.Required && b.keyword !== FormErrorKeyword.Required) return -1;
        if (a.keyword !== FormErrorKeyword.Required && b.keyword === FormErrorKeyword.Required) return 1;
        return 0;
    }).reduce((result, error)=>{
        const { dataPath, message } = error;
        if (!result.has(dataPath)) result.set(dataPath, message);
        return result;
    }, new Map());
}
function getErrorState(field, errors, previousErrors) {
    const errorForField = errors.get(field);
    if (!previousErrors.size) return errorForField ? new Map([
        [
            field,
            errorForField
        ]
    ]) : new Map();
    const updatedErrorsMap = new Map(previousErrors);
    if (errorForField) updatedErrorsMap.set(field, errorForField);
    else updatedErrorsMap.delete(field);
    return updatedErrorsMap;
}
export function getSchemaErrors(errors, previousErrors, errorMapper, field) {
    const schemaErrors = getErrorsFromSchemaErrors(errors, errorMapper);
    if (field) {
        const pathFragments = field.split('/');
        const pathFragmentsLength = pathFragments.length;
        // in case of "field == files/1", we have to check value for 'files' as well
        const isNestedArrayField = 2 === pathFragmentsLength && !Number.isNaN(parseInt(pathFragments[1], 10));
        return getErrorState(field, schemaErrors, isNestedArrayField ? getErrorState(pathFragments[0], schemaErrors, previousErrors) : previousErrors);
    }
    return schemaErrors;
}
export const getTouchedFieldsErrors = (currentErrors, touched)=>{
    const result = {};
    for (const [errorKey, errorValue] of currentErrors)if (touched.has(errorKey)) {
        const [rootKey, subKey] = errorKey.split('/');
        if (subKey) {
            const currentRootValue = result[rootKey];
            result[rootKey] = {
                ...isObject(currentRootValue) ? currentRootValue : {},
                [subKey]: errorValue
            };
        } else result[rootKey] = errorValue;
    }
    return result;
};
export const isDateStingCorrect = (sourceString, convertedDate)=>{
    if (!convertedDate) return false;
    const numberDate = Number(convertedDate);
    const minimalAvailableDate = -2208908340000;
    // 2 jan 1900, LEONWEB-6286
    if (Number.isNaN(numberDate) || numberDate < minimalAvailableDate) return false;
    const stringFromDate = convertedDate.toISOString().split('T')[0];
    const isFullyValid = sourceString === stringFromDate;
    if (!isFullyValid) logger.error('useFormValidator: invalid date', {
        input: sourceString,
        dateFromInput: convertedDate.toISOString(),
        stringToCompareInput: stringFromDate
    });
    return isFullyValid;
};
export const getDateErrors = (formData, dateFields, errorPatterns)=>// eslint-disable-next-line sonarjs/cognitive-complexity
{
    const errors = new Map();
    for (const [key, field] of dateFields){
        const value = formData[key];
        if (value) {
            isString(value);
            isUndefined(field.options) || isDateInputCommonProperties(field.options);
            const isBirthday = !!field.options?.isBirthday;
            const { DateUnderMinYear, InvalidDate, IsNotAnAdultStart, IsNotAnAdultEnd } = errorPatterns.common ?? {};
            let date = null;
            if (10 === value.length) // input is filled
            date = new Date(value);
            const dateStringCorrect = isDateStingCorrect(value, date);
            if (!dateStringCorrect) {
                const message = isBirthday ? DateUnderMinYear : InvalidDate;
                errors.set(key, message || 'Invalid date');
            }
            if (dateStringCorrect && date && isBirthday) {
                const legalAge = field.options?.legalAge || 18;
                if (!isLegalAge(date, legalAge)) {
                    const errorText = `${IsNotAnAdultStart} ${legalAge} ${IsNotAnAdultEnd}`;
                    errors.set(key, errorText);
                }
            }
        }
    }
    return errors;
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getFileErrors(formData, multipleFileFields, errorPatterns) {
    const errors = new Map();
    for (const [key, field] of multipleFileFields){
        isUndefined(field.options) || isFileMultipleInputProps(field.options) || isFileMultipleDragAndDropInputProperties(field.options), field.widget;
        const fileTypes = field.options?.fileTypes ?? {};
        const files = formData[key];
        const fileErrors = [];
        if (files?.length > 0) {
            const { IsFileAlreadyExists, IsFileWrongFormat } = errorPatterns.common ?? {};
            const fileNames = [];
            for (const file of files){
                let error = '';
                if (isFileAllowed(fileTypes, file)) {
                    if (fileNames.includes(file.name)) error = `${IsFileAlreadyExists}`;
                } else error = `${IsFileWrongFormat}`;
                if (error && field.widget === FormControlType.FileDragAndDrop) error = `${error}: ${file.name}`;
                fileErrors.push(error);
                fileNames.push(file.name);
            }
        }
        if (fileErrors.some((item)=>!!item)) errors.set(key, fileErrors);
    }
    return errors;
}
export const getPatternErrors = (formData, patternRelatedFields, errorPatterns)=>{
    const errors = new Map();
    for (const [key, field] of patternRelatedFields)if (field.widget === FormControlType.CPFNumber) {
        const value = formData[key];
        if (value) {
            isString(value);
            if (!/^[\d]{3}[.][\d]{3}[.][\d]{3}[-][\d]{2}$/.test(`${value}`)) errors.set(key, errorPatterns?.common?.format || 'invalid code');
        }
    }
    return errors;
};
export const getEmailSymbolsErrors = (formData, forbiddenSymbolsFields, errorPatterns)=>// eslint-disable-next-line sonarjs/cognitive-complexity
{
    const errors = new Map();
    for (const [key, field] of forbiddenSymbolsFields){
        const value = formData[key];
        if (value) {
            isString(value);
            field.options && field.options;
            const forbiddenSymbols = field.options.forbiddenSymbols ?? [];
            const foundSymbols = [];
            for (const forbiddenSymbol of forbiddenSymbols)if (value.includes(forbiddenSymbol)) foundSymbols.push(`"${forbiddenSymbol}"`);
            if (foundSymbols.length) errors.set(key, `${errorPatterns?.common?.ForbiddenSymbol || 'Forbidden symbols:'} ${foundSymbols.join(', ')}`);
        }
    }
    return errors;
};
