import { computed } from 'vue';
// eslint-disable-next-line max-len
export function useAbstractBanner(props, emit) {
    const isGroupBannersDev = computed(()=>props.bannerGroup?.isNT);
    const banners = computed(()=>props.bannerGroup?.banners ?? []);
    const autoplayTimeout = computed(()=>props.bannerGroup?.autoplayDelay || 0);
    const linkTitle = computed(()=>props.bannerGroup?.name);
    function emitBannerItemClick(path, banner) {
        emit('banner-item-click', {
            path,
            banner
        });
    }
    return {
        isGroupBannersDev,
        banners,
        autoplayTimeout,
        linkTitle,
        emitBannerItemClick
    };
}
