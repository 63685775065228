import { computed, ref, shallowRef } from 'vue';
import { getAvailableForms, CustomerRegistrationType } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { isRegistrationForms } from 'web/src/modules/registration/guards';
export function useAvailableRegistrationForms() {
    const gqlClient = useGraphqlClient();
    const selectedRegistrationType = ref(null);
    const isFormLoaded = ref(false);
    const availableFormsResponse = shallowRef(null);
    async function fetchAvailableForms() {
        const response = await getAvailableForms(gqlClient, (node)=>node.queries.registration.getAvailableForms);
        if (null !== response) {
            const { forms } = response;
            isRegistrationForms(forms);
            forms.sort((a, b)=>a.priority - b.priority);
            availableFormsResponse.value = response;
        }
    }
    function setSelectedRegistrationType(type) {
        selectedRegistrationType.value = type;
    }
    const availableForms = computed(()=>availableFormsResponse.value?.forms ?? []);
    const selectedForm = computed(()=>{
        if (null === selectedRegistrationType.value) return availableForms.value[0] ?? null;
        return availableForms.value.find((form)=>form.registrationType === selectedRegistrationType.value) ?? null;
    });
    const registrationType = computed(()=>selectedForm.value?.registrationType ?? CustomerRegistrationType.FULL);
    async function fetchForms() {
        isFormLoaded.value = false;
        await fetchAvailableForms();
        if (!registrationType.value) throw new Error('Available registration form is not found');
        isFormLoaded.value = true;
    }
    const regBannerUrl = computed(()=>availableFormsResponse.value?.regBannerUrl ?? '');
    const isExcludedCountry = computed(()=>availableFormsResponse.value?.isExcludedCountry ?? false);
    return {
        isFormLoaded,
        selectedForm,
        regBannerUrl,
        registrationType,
        isExcludedCountry,
        fetchForms,
        availableForms,
        setSelectedRegistrationType
    };
}
