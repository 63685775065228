import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { CasinoRouteName } from '@leon-hub/routing-config-names';
import { getLobbyLocation } from 'web/src/modules/casino/submodules/lobby/composables/utils';
import { AllGamesGroupUrl, FavoriteGamesCategoryUrl } from 'web/src/modules/egs/constants';
import { isLiveEgsRoute } from 'web/src/modules/egs/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCasinoLobby } from './useCasinoLobby';
export function useCategoriesTabs() {
    const { $translate } = useI18n();
    const route = useRoute();
    const { isLoggedIn } = useIsLoggedIn();
    const { isLoadingCategories, groupUrl, categories, groupBlockType, loadCategories } = useCasinoLobby();
    const filteredGroupUrl = computed(()=>groupBlockType.value ? void 0 : groupUrl.value);
    const baseRouteName = computed(()=>isLiveEgsRoute(route) ? CasinoRouteName.CASINO_LIVE_LOBBY : CasinoRouteName.CASINO_LOBBY);
    const categoriesTabs = computed(()=>{
        const pageAllLocation = {
            name: baseRouteName.value,
            params: {}
        };
        // fake tab list for skeleton while loading
        if (isLoadingCategories.value) return Array.from({
            length: 10
        }, (_, index)=>({
                id: `id-${index}`,
                name: `name-${index}`,
                location: pageAllLocation,
                iconUrl: '',
                isActive: false
            }));
        if (filteredGroupUrl.value) pageAllLocation.params.groupUrl = filteredGroupUrl.value;
        const tabs = [
            {
                id: 'ALL',
                name: $translate('WEB2_ALL_GAMES').value,
                location: pageAllLocation,
                iconUrl: '',
                isActive: !route.params.categoryUrl
            }
        ];
        if (groupUrl.value) tabs.unshift({
            id: 'LOBBY',
            name: $translate('WEB2_LOBBY').value,
            location: {
                name: baseRouteName.value,
                params: {}
            },
            iconUrl: '',
            isActive: false
        });
        if (isLoggedIn.value) tabs.push({
            id: 'FAVORITES',
            name: $translate('WEB2_MY_GAMES').value,
            location: getLobbyLocation(baseRouteName.value, FavoriteGamesCategoryUrl, filteredGroupUrl.value || AllGamesGroupUrl),
            iconUrl: '',
            isActive: isActiveCategory(FavoriteGamesCategoryUrl)
        });
        return [
            ...tabs,
            ...categories.value.map((item)=>({
                    id: item.id,
                    name: item.name,
                    location: getLobbyLocation(baseRouteName.value, item.url, filteredGroupUrl.value || AllGamesGroupUrl),
                    iconUrl: item.iconUrl || '',
                    isActive: isActiveCategory(item.url)
                }))
        ];
    });
    function isActiveCategory(url) {
        return url === route.params.categoryUrl;
    }
    return {
        isLoadingCategories,
        categoriesTabs,
        loadCategories
    };
}
