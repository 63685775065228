function getPreviousSlide(active, last) {
    return 0 === active ? last : active - 1;
}
function getNextSlide(active, last) {
    return active === last ? 0 : active + 1;
}
function filterUnique(values) {
    return values.filter((value, index, list)=>list.indexOf(value) === index);
}
export const getReadySlides = (bannersCount, activeSlideIndex)=>{
    if (!bannersCount) return [];
    const lastIndex = bannersCount - 1;
    return filterUnique([
        getPreviousSlide(activeSlideIndex, lastIndex),
        activeSlideIndex,
        getNextSlide(activeSlideIndex, lastIndex)
    ]);
};
