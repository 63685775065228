import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { sleep } from '@leon-hub/utils';
let VirtualSportBetsMapper = class VirtualSportBetsMapper {
    async getSlipMappingByBet(bet) {
        const storage = this.getStorage();
        if (!storage) return;
        let slipMapping = storage.getSlipMapping(bet);
        if (slipMapping) return slipMapping;
        const silent = !!this.options.repeatOnError;
        slipMapping = await this.fetchVirtualSportSlipMapping(bet, {
            silent
        });
        if (!slipMapping && this.options.repeatOnError) {
            await sleep(this.options.repeatOnErrorTimeout || 1000);
            slipMapping = await this.fetchVirtualSportSlipMapping(bet, {
                silent: false
            });
        }
        return storage.setSlipMapping(bet, slipMapping);
    }
    constructor(getStorage, fetchVirtualSportSlipMapping, options = {}){
        _define_property(this, "getStorage", void 0);
        _define_property(this, "fetchVirtualSportSlipMapping", void 0);
        _define_property(this, "options", void 0);
        this.getStorage = getStorage;
        this.fetchVirtualSportSlipMapping = fetchVirtualSportSlipMapping;
        this.options = options;
    }
};
export { VirtualSportBetsMapper as default };
