import { useIntercomEvents } from 'web/src/modules/intercom/composables/index';
import useSupport from 'web/src/modules/support/composables/useSupport';
export default function useBaseIntercom() {
    function prefetch(from, next) {
        "1";
        {
            const { openIntercom } = useIntercomEvents();
            const { isChatMode, isIntercomAvailable } = useSupport();
            if (isChatMode.value && isIntercomAvailable.value) {
                openIntercom();
                next({
                    ...from,
                    replace: true
                });
                return false;
            }
        }
        return true;
    }
    return {
        prefetch
    };
}
