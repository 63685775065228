import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { IconDirection, IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config';
import { ChevronIcon } from '@components/chevron-icon';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import FavoriteLeagueIndicator from 'web/src/modules/sportline/submodules/favorites/views/FavoriteLeagueIndicator.vue';
import $commonStyle from 'web/src/components/List/styles/common.module.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguesListItem',
    props: {
        sportId: {},
        leagueElement: {},
        isRegionNameDisplayed: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        _useCssVars((_ctx)=>({
                "0dd22e58": bgColor.value
            }));
        const props = __props;
        const emit = __emit;
        const leagueElement = toRef(props, 'leagueElement');
        const isRegionNameDisplayed = toRef(props, 'isRegionNameDisplayed');
        const bgColor = computed(()=>isRegionNameDisplayed.value ? 'var(--Layer1)' : 'var(--Layer2)');
        const to = computed(()=>({
                name: RouteName.SPORT_LEAGUE,
                params: {
                    ...leagueElement.value.league.navigationParameters
                }
            }));
        const leagueId = computed(()=>leagueElement.value.league.id);
        const totalCounters = computed(()=>leagueElement.value.counters.total);
        const title = computed(()=>{
            const regionName = leagueElement.value.region?.name;
            const leagueName = leagueElement.value.league.name;
            return isRegionNameDisplayed.value && regionName ? `${regionName} - ${leagueName}` : leagueName;
        });
        function onClick(event) {
            emit('click', {
                event,
                leagueId: leagueId.value
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass([
                    _unref($commonStyle)['list-item'],
                    _ctx.$style['leagues-list-item']
                ])
            }, [
                _createVNode(VRouterLink, {
                    to: to.value,
                    class: _normalizeClass([
                        _unref($commonStyle)['list-item__inner'],
                        _ctx.$style['leagues-list-item__inner']
                    ]),
                    onClick: onClick
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _unref($commonStyle)['list-item__prefix'],
                                    _ctx.$style['leagues-list-item__prefix']
                                ])
                            }, [
                                _createVNode(FavoriteLeagueIndicator, {
                                    "sport-id": _ctx.sportId,
                                    "league-id": leagueId.value
                                }, null, 8, [
                                    "sport-id",
                                    "league-id"
                                ])
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _unref($commonStyle)['list-item__default'],
                                    _ctx.$style['leagues-list-item__default']
                                ])
                            }, [
                                _createElementVNode("span", null, _toDisplayString(title.value), 1)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _unref($commonStyle)['list-item__suffix']
                                ])
                            }, [
                                totalCounters.value > 0 ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass([
                                        _unref($commonStyle)['list-item__suffix-text']
                                    ])
                                }, _toDisplayString(totalCounters.value), 3)) : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _unref($commonStyle)['list-item__chevron']
                                    ])
                                }, [
                                    _createVNode(_unref(ChevronIcon), {
                                        direction: _unref(IconDirection).EAST,
                                        size: _unref(IconSize).SIZE_16
                                    }, null, 8, [
                                        "direction",
                                        "size"
                                    ])
                                ], 2)
                            ], 2)
                        ]),
                    _: 1
                }, 8, [
                    "to",
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaguesListItem'
                ]
            ]);
        };
    }
});
