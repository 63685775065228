import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import VFavoriteToggle from 'web/src/components/FavoriteToggle/VFavoriteToggle/VFavoriteToggle.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsFavorite',
    props: {
        isFavorite: {
            type: Boolean
        },
        hasBackground: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const inactiveIconProperties = {
            name: IconName.HEART_OUTLINE,
            size: IconSize.SIZE_24
        };
        const activeIconProperties = {
            name: IconName.HEART,
            size: IconSize.SIZE_24
        };
        function emitClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(VFavoriteToggle, {
                "active-icon": activeIconProperties,
                "inactive-icon": inactiveIconProperties,
                "is-favorite": _ctx.isFavorite,
                "has-background": _ctx.hasBackground,
                "is-button": "",
                class: _normalizeClass({
                    [_ctx.$style['egs-favorite--background']]: _ctx.hasBackground
                }),
                onClick: emitClick
            }, null, 8, [
                "is-favorite",
                "has-background",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'EgsFavorite'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'egs-favorite-button',
                        favorite: _ctx.isFavorite ? 'on' : 'off'
                    }
                ]
            ]);
        };
    }
});
