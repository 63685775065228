import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { SocialType } from '../enums';
const mappedSocialIcons = Object.freeze({
    facebook: IconName.FB_CIRCLE,
    instagram: IconName.INSTAGRAM_CIRCLE,
    ok: IconName.OK_CIRCLE,
    vk: IconName.VK_CIRCLE,
    rss: IconName.RSS_CIRCLE,
    telegram: IconName.TELEGRAM_CIRCLE
});
export default function useVSocial(props) {
    const getIcon = computed(()=>mappedSocialIcons[props.socialType || SocialType.FACEBOOK]);
    const iconProps = computed(()=>({
            name: getIcon.value,
            size: props.size
        }));
    return {
        iconProps
    };
}
