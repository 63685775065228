/* eslint-disable no-param-reassign */ /** Get random part from string format a|b|c */ function randomSelectOnePart(value) {
    const splitValue = value.split('|');
    const randomValue = Math.floor(Math.random() * splitValue.length);
    return splitValue[randomValue] || '';
}
const CmsUtilsPlugin = {
    install (app) {
        app.config.globalProperties.$cmsUtils = {
            selectOne: randomSelectOnePart,
            phoneCall (phoneNumber) {
                try {
                    window.location.href = `tel:${phoneNumber}`;
                } catch  {}
            },
            //
            toggleBlocksVisibility (selector) {
                try {
                    {
                        const elements = document.querySelectorAll(selector);
                        const hiddenClassName = 'hidden';
                        for (const element of elements)if (element.classList.contains(hiddenClassName)) element.classList.remove(hiddenClassName);
                        else element.classList.add(hiddenClassName);
                    }
                } catch  {}
            },
            //
            toggleSelectorClass (selector, toggleClass) {
                try {
                    {
                        const elements = document.querySelectorAll(selector);
                        const toggleClassName = toggleClass;
                        for (const element of elements)if (element.classList.contains(toggleClassName)) element.classList.remove(toggleClassName);
                        else element.classList.add(toggleClassName);
                    }
                } catch  {}
            }
        };
    }
};
//
export default CmsUtilsPlugin;
