import { computed } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
export function useVFlagIcon(props) {
    const { size, flagSize } = props;
    const finalFlagSize = computed(()=>{
        if (flagSize.value) return flagSize.value;
        switch(size.value){
            case IconSize.SIZE_20:
                return VCountryFlagSize.SIZE_10;
            case IconSize.SIZE_24:
                return VCountryFlagSize.SIZE_12;
            case IconSize.SIZE_28:
                return VCountryFlagSize.SIZE_16;
            // fallback to see unsupported IconSize property
            default:
                return VCountryFlagSize.SIZE_0;
        }
    });
    return {
        finalFlagSize
    };
}
