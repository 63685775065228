// eslint-disable-next-line max-len
export default function getLatestCasinoPromotionUrl(input) {
    const now = Date.now();
    const sortedPromotions = input.filter((promotion)=>promotion.endDateNumber && promotion.endDateNumber > now).sort((a, b)=>{
        if (!a.endDateNumber || !b.endDateNumber || a.endDateNumber === b.endDateNumber) return 0;
        if (a.endDateNumber < b.endDateNumber) return -1;
        return 1;
    });
    return sortedPromotions[0]?.actionUrl || null;
}
