const idToParamMapping = {
    marketId: 'market',
    eventId: 'event',
    leagueId: 'league',
    regionId: 'region',
    sportId: 'sport'
};
export function buildOpenInCatalogParameters(params) {
    const result = Object.keys(params).map((id)=>idToParamMapping[id] ? `${idToParamMapping[id]}=${params[id]}` : null).filter((value)=>!!value).join('&');
    return result ? `?${result}` : '';
}
