const EnvironmentPlugin = {
    install (app) {
        Object.defineProperty(app.config.globalProperties, 'process', {
            get () {
                return {
                    env: process.env
                };
            }
        });
    }
};
export default EnvironmentPlugin;
