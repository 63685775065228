export function getSlipCaption(sportEvent) {
    const result = {};
    if (!sportEvent) return result;
    if (sportEvent.isOutright) result.eventName = sportEvent.name;
    else result.competitors = [
        sportEvent.competitors[0]?.name || '',
        sportEvent.competitors[1]?.name || ''
    ];
    return result;
}
