import { computed } from 'vue';
import { PieTimerSize } from '../enums';
export default function useVPieTimer(props) {
    const timerWrapperClass = computed(()=>({
            [`timer__wrapper--size-${props.size}`]: props.size !== PieTimerSize.DEFAULT
        }));
    const circleClass = computed(()=>({
            timer__counter: true,
            'timer__counter--danger': props.percentage <= 20
        }));
    return {
        timerWrapperClass,
        circleClass
    };
}
