import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 1
};
import CasinoGroupsList from 'web/src/modules/casino/components/CasinoGroupsList/CasinoGroupsList.vue';
import SearchSubHeader from 'web/src/modules/search/components/SearchSubHeader/SearchSubHeader.vue';
import VLoadMoreObserver from 'web/src/components/LoadMoreObserver/VLoadMoreObserver/VLoadMoreObserver.vue';
import CasinoSearchItem from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/CasinoSearchItem.vue';
import { useCasinoSearchSection } from 'web/src/modules/search/submodules/casino/components/CasinoSearchSection/composables';
import LobbySectionHeader from 'web/src/modules/lobby/components/LobbySectionHeader/LobbySectionHeader.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSearchSection',
    props: {
        searchText: {
            default: ''
        },
        games: {
            default: ()=>[]
        },
        groups: {
            default: ()=>[]
        },
        isLoadMoreAvailable: {
            type: Boolean
        }
    },
    emits: [
        "item-click",
        "load-more"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { hasGroups, hasGames, emitItemClick, emitLoadMore, getItemProperties } = useCasinoSearchSection(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(_ctx.$style['casino-search-section'])
            }, [
                _unref(hasGroups) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_openBlock(), _createBlock(SearchSubHeader, {
                        key: 1,
                        text: _ctx.$t('WEB2_SEARCH_PROVIDERS')
                    }, null, 8, [
                        "text"
                    ])),
                    _createVNode(CasinoGroupsList, {
                        groups: _ctx.groups,
                        "is-search-modal": ""
                    }, null, 8, [
                        "groups"
                    ])
                ])) : _createCommentVNode("", true),
                _unref(hasGames) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(), _createBlock(SearchSubHeader, {
                        key: 1,
                        text: _ctx.$t('WEB2_SEARCH_GAMES')
                    }, null, 8, [
                        "text"
                    ])),
                    _createElementVNode("ul", {
                        class: _normalizeClass(_ctx.$style['casino-search-section__list'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game)=>(_openBlock(), _createBlock(CasinoSearchItem, _mergeProps({
                                ref_for: true
                            }, _unref(getItemProperties)(game), {
                                key: game.id,
                                class: _ctx.$style['casino-search-section__list-item'],
                                onClick: ($event)=>_unref(emitItemClick)(game)
                            }), null, 16, [
                                "class",
                                "onClick"
                            ]))), 128))
                    ], 2),
                    _ctx.isLoadMoreAvailable ? (_openBlock(), _createBlock(VLoadMoreObserver, {
                        key: 2,
                        onLoadMore: _unref(emitLoadMore)
                    }, null, 8, [
                        "onLoadMore"
                    ])) : _createCommentVNode("", true)
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoSearchSection'
                ]
            ]);
        };
    }
});
