import { toValue } from 'vue';
export function applyExpandedStatePayload(payload, expandedMap, defaultValueRef) {
    const expandKey = toValue(payload.expandKey);
    const value = toValue(payload.value);
    const defaultValue = toValue(defaultValueRef);
    const result = {
        ...expandedMap
    };
    if (value === defaultValue) delete result[expandKey];
    else result[expandKey] = value;
    return result;
}
export function getIsExpandedFromMap(expandedMap, expandedKey, isExpandedByDefault) {
    const expandedMapValue = toValue(expandedMap);
    const expandedKeyValue = toValue(expandedKey);
    const isExpandedByDefaultValue = toValue(isExpandedByDefault ?? true);
    return expandedKeyValue ? expandedMapValue[expandedKeyValue] ?? isExpandedByDefaultValue : isExpandedByDefaultValue;
}
