import { computed } from 'vue';
import { getSlipCaption } from 'web/src/modules/sportline/views/runner/utils';
export function useMarketsListMarket(props) {
    const { group, sportEvent } = props;
    const slipCaption = computed(()=>getSlipCaption(sportEvent.value));
    const sportlineEventId = computed(()=>sportEvent.value.id);
    const sportlineEventType = computed(()=>sportEvent.value.type);
    const isOutrightSportlineEvent = computed(()=>sportEvent.value.isOutright);
    // @TODO check this. It seems deprecated
    const isDynamicColumns = computed(()=>group.value.markets[0]?.columns === 1);
    // @TODO review this mapping
    const flatRows = computed(()=>group.value.markets.flatMap((market)=>{
            const { columns } = market.type;
            const rows = [];
            for (const runner of market.runners){
                rows[runner.row] = rows[runner.row] || Array.from({
                    length: columns
                }).fill(null);
                rows[runner.row][runner.column] = {
                    runner
                };
            }
            return rows.map((row)=>({
                    market,
                    runners: row.flatMap((r)=>r?.runner ?? null)
                }));
        }));
    return {
        slipCaption,
        sportlineEventId,
        sportlineEventType,
        isOutrightSportlineEvent,
        isDynamicColumns,
        flatRows
    };
}
