import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { getIconNameBySportName } from './utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportIcon',
    props: {
        sportName: {},
        size: {
            default: IconSize.SIZE_20
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const iconProperties = computed(()=>({
                name: getIconNameBySportName(props.sportName),
                size: props.size
            }));
        function onClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VIcon), _mergeProps(iconProperties.value, {
                onClick: onClick
            }), null, 16)), [
                [
                    _directive_auto_id,
                    'SportIcon'
                ]
            ]);
        };
    }
});
