import { computed } from 'vue';
import { useSportlineBackgroundColor } from 'web/src/modules/sportline/composables/representation';
export function useLeagueRepresentation(props) {
    const { sport, leagueRepresentationData } = props;
    const background = computed(()=>leagueRepresentationData.value?.background ?? sport.value?.representation.background ?? null);
    const backgroundImage = computed(()=>leagueRepresentationData.value?.backgroundImage ?? null);
    const { backgroundColor } = useSportlineBackgroundColor({
        background
    });
    return {
        backgroundColor,
        backgroundImage
    };
}
