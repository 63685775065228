import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { useSportlinePendingApi } from './useSportlinePendingApi';
export function useSportlineApiLoadRegionEvents(props) {
    const { tryRequestWithCTag, defaultRestApiFlags, isLiveOutrightsEnabled } = props;
    const { loadEvents: loadRegionEvents } = useSportlinePendingApi({
        tryRequestWithCTag,
        getEventsInput: (param)=>{
            let { regionId, silent } = param;
            return {
                betline: BetlineType.All,
                regionId,
                hideClosed: true,
                silent,
                flags: [
                    ...defaultRestApiFlags.value
                ],
                LMCE: isLiveOutrightsEnabled.value
            };
        },
        getPendingParams: (param)=>{
            let { vTag, regionId } = param;
            return {
                vTag,
                regionId,
                betlines: [
                    BetlineType.All
                ]
            };
        }
    });
    return {
        loadRegionEvents
    };
}
