import { useCmsStore } from 'web/src/modules/cms/store';
export default function useAccessTelegramChannelRoutePage() {
    async function created() {
        await useCmsStore().fetchCmsContentTranslation({
            key: 'WEB2_SUBSCRIBE_TELEGRAM_CHANNEL'
        });
    }
    return {
        created
    };
}
