import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { PinButtonEvent } from '../../enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PinButton',
    props: {
        title: {},
        value: {
            default: PinButtonEvent.VALUE_0
        },
        iconName: {},
        isFilled: {
            type: Boolean,
            default: true
        },
        isActive: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function onClick() {
            emit('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                type: "button",
                class: _normalizeClass({
                    [_ctx.$style['pin-button']]: true,
                    [_ctx.$style['pin-button--filled']]: _ctx.isFilled,
                    [_ctx.$style['pin-button--active']]: _ctx.isActive
                }),
                onClick: onClick
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'PinButton'
                ],
                [
                    _directive_data_test,
                    {
                        el: `pin-button-${_ctx.value.toLowerCase()}`
                    }
                ]
            ]);
        };
    }
});
