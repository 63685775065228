import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createBlock as _createBlock, Fragment as _Fragment, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id",
    "autofocus",
    "value",
    "name",
    "disabled",
    "placeholder",
    "readonly",
    "rows",
    "maxlength"
];
import { VIcon } from '@components/v-icon';
import InputButton from '../InputButton';
import getInputIconSize from '../../utils/getInputIconSize';
import useMultilineTextInput from './useMultilineTextInput';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import { useAutofocus } from '../../composables';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'MultilineTextInput',
    props: {
        maxLength: {
            default: 0
        },
        rowsMax: {
            default: 0
        },
        rowsMin: {
            default: 3
        },
        isHintHidden: {
            type: Boolean
        },
        suffixIconName: {},
        clickableIcon: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'MultilineTextInput'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {}
    },
    emits: [
        "icon-suffix-click",
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { focusable, focus } = useAutofocus(props);
        const { uniqueId, hintProps, hasError, rows, isEmpty, inputValue, isFocus, isHover, clickableSuffixIcon, onMouseOver, onMouseLeave, onFocus, onBlur, onChange, onInput, emitIconSuffixClick, setRowsOnMount } = useMultilineTextInput(props, emits, focusable);
        __expose({
            focus
        });
        const iconSize = getInputIconSize(props.isLarge);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), _mergeProps(_unref(hintProps), {
                "input-id": _unref(uniqueId),
                label: _ctx.label,
                "is-hint-hidden": _ctx.isHintHidden,
                disabled: _ctx.disabled,
                "is-focus": _unref(isFocus),
                "is-empty": _unref(isEmpty),
                "is-large": _ctx.isLarge,
                class: {
                    [_ctx.$style['multiline-text-input']]: true,
                    [_ctx.$style['multiline-text-input--error']]: _unref(hasError),
                    [_ctx.$style['multiline-text-input--focus']]: _unref(isFocus),
                    [_ctx.$style['multiline-text-input--empty']]: _unref(isEmpty),
                    [_ctx.$style['multiline-text-input--large']]: _ctx.isLarge
                },
                "clickable-suffix": _unref(clickableSuffixIcon)
            }), {
                input: _withCtx(()=>[
                        _createVNode(_unref(InputBorderWrapper), {
                            "is-focus": _unref(isFocus),
                            "is-disabled": _ctx.disabled,
                            "is-hover": _unref(isHover),
                            "has-error": _unref(hasError)
                        }, {
                            default: _withCtx(()=>[
                                    _ctx.readonly ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass({
                                            [_ctx.$style['multiline-text-input__input']]: true,
                                            [_ctx.$style['multiline-text-input__input--with-suffix']]: !!_ctx.suffixIconName
                                        })
                                    }, _toDisplayString(_unref(inputValue)), 3)) : (_openBlock(), _createElementBlock("textarea", {
                                        key: 1,
                                        id: _unref(uniqueId),
                                        ref_key: "focusable",
                                        ref: focusable,
                                        autofocus: process.env.VUE_APP_OS_IOS ? void 0 : _ctx.autofocus,
                                        value: _unref(inputValue),
                                        name: _ctx.name,
                                        disabled: _ctx.disabled,
                                        placeholder: _ctx.placeholder,
                                        readonly: _ctx.readonly,
                                        rows: _unref(rows),
                                        maxlength: _ctx.maxLength || void 0,
                                        class: _normalizeClass(_ctx.$style['multiline-text-input__input']),
                                        onInput: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onInput) && _unref(onInput)(...args);
                                        }, [
                                            "stop"
                                        ])),
                                        onChange: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onChange) && _unref(onChange)(...args);
                                        }, [
                                            "stop"
                                        ])),
                                        onFocus: _cache[2] || (_cache[2] = _withModifiers(//@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onFocus) && _unref(onFocus)(...args);
                                        }, [
                                            "stop"
                                        ])),
                                        onBlur: _cache[3] || (_cache[3] = _withModifiers(//@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onBlur) && _unref(onBlur)(...args);
                                        }, [
                                            "stop"
                                        ])),
                                        onMouseover: _cache[4] || (_cache[4] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onMouseOver) && _unref(onMouseOver)(...args);
                                        }),
                                        onMouseleave: _cache[5] || (_cache[5] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onMouseLeave) && _unref(onMouseLeave)(...args);
                                        }),
                                        onVnodeMounted: _cache[6] || (_cache[6] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(setRowsOnMount) && _unref(setRowsOnMount)(...args);
                                        })
                                    }, null, 42, _hoisted_1))
                                ]),
                            _: 1
                        }, 8, [
                            "is-focus",
                            "is-disabled",
                            "is-hover",
                            "has-error"
                        ])
                    ]),
                iconSuffix: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "iconSuffix", {
                            isFocus: _unref(isFocus),
                            isEmpty: _unref(isEmpty)
                        }, ()=>[
                                _ctx.suffixIconName ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _ctx.clickableIcon ? (_openBlock(), _createBlock(_unref(InputButton), {
                                        key: 0,
                                        "is-large": _ctx.isLarge,
                                        "icon-name": _ctx.suffixIconName,
                                        onClick: _unref(emitIconSuffixClick)
                                    }, null, 8, [
                                        "is-large",
                                        "icon-name",
                                        "onClick"
                                    ])) : (_openBlock(), _createBlock(_unref(VIcon), {
                                        key: 1,
                                        size: _unref(iconSize),
                                        name: _ctx.suffixIconName
                                    }, null, 8, [
                                        "size",
                                        "name"
                                    ]))
                                ], 64)) : _createCommentVNode("", true)
                            ])
                    ]),
                _: 3
            }, 16, [
                "input-id",
                "label",
                "is-hint-hidden",
                "disabled",
                "is-focus",
                "is-empty",
                "is-large",
                "class",
                "clickable-suffix"
            ])), [
                [
                    _directive_auto_id,
                    'MultilineTextInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'multiline-text-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
