import { isNumber } from '@leon-hub/guards';
export function compareAsc(a, b) {
    if (a === b) return 0;
    if (a < b) return -1;
    return 1;
}
export function compareDesc(a, b) {
    if (a === b) return 0;
    if (a < b) return 1;
    return -1;
}
export function compare(a, b, options) {
    if (!isNumber(a)) {
        if (!isNumber(b)) return 0;
        return options?.nullsFirst ? -1 : 1;
    }
    if (!isNumber(b)) {
        if (!isNumber(a)) return 0;
        return options?.nullsFirst ? 1 : -1;
    }
    if (a === b) return 0;
    if (a < b) return options?.desc ? 1 : -1;
    return options?.desc ? -1 : 1;
}
