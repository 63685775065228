import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { useSlots, h, computed } from 'vue';
import { Tag } from '@leon-hub/tags';
import { logger as loggerInstance } from '@leon-hub/logging';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TextSlotPlaceholder',
    props: {
        tag: {
            default: Tag.SPAN
        },
        text: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const placeholderRegexp = /({[^\s{}]+})/g;
        const logger = loggerInstance.createNamespace('TextSlotPlaceholder');
        function validateSlots(slots, text) {
            if (Object.keys(slots).length <= 0) return [
                text
            ];
            if (!text) return [
                text
            ];
            const splits = text.split(placeholderRegexp);
            const hasPlaceholders = splits.some((item)=>placeholderRegexp.test(item));
            if (!hasPlaceholders) return [
                text
            ];
            return splits.reduce((accumulator, item)=>{
                const isPlaceholder = placeholderRegexp.test(item);
                const slot = slots[item.slice(1, -1)];
                if (slot && isPlaceholder) accumulator.push(...slot());
                else accumulator.push(item);
                if (!slot && isPlaceholder) logger.warn(`Slot with name: "${item}" not exists`);
                return accumulator;
            }, []);
        }
        const slots = useSlots();
        const component = computed(()=>h(props.tag, validateSlots(slots, props.text)));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(component.value), null, null, 512)), [
                [
                    _directive_auto_id,
                    'TextSlotPlaceholder'
                ]
            ]);
        };
    }
});
