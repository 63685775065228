import { createGetEventFailedRequestsCounter, createGetEventRequestOnPageBehaviour, createGetEventRequestOnInitBehaviour } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter';
function createCallActionMethod(actions) {
    return (action)=>{
        actions[action]?.();
    };
}
/**
 * This manager creates behaviours and connects them with a Status counter.
 * The status counter gets the last fetch status and fire a Status event.
 * The behaviours get a Status event and produce a Subscription event.
 * The page will act based on the last Subscription event.
 */ export function useSportlineEventDetailsRequestStatusBehaviours() {
    const failedRequestCounter = createGetEventFailedRequestsCounter();
    function addOnPageBehaviour(id, actions) {
        const behavior = createGetEventRequestOnPageBehaviour(id, createCallActionMethod(actions));
        failedRequestCounter.subscribe(behavior);
    }
    function addOnInitBehaviour(id, actions) {
        const behavior = createGetEventRequestOnInitBehaviour(id, createCallActionMethod(actions));
        failedRequestCounter.subscribe(behavior);
    }
    function removeBehaviours(id) {
        failedRequestCounter.unsubscribe(id);
    }
    function removeBehavioursExcept(id) {
        failedRequestCounter.unsubscribeAllExcept(id);
    }
    function fireRequestStatus(id, status) {
        failedRequestCounter.fireStatus(id, status);
    }
    return {
        addOnPageBehaviour,
        addOnInitBehaviour,
        removeBehaviours,
        removeBehavioursExcept,
        fireRequestStatus
    };
}
