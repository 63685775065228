import { computed, ref, watch } from 'vue';
import { filterSportListRegions, isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import useCustomerFavoritesService from './useCustomerFavoritesService';
/**
 * Contains shared logic for pages with a favourite tab
 */ export function useSportlineFavoritesStoreComposable(props) {
    const { backgroundFavoritesId, prepareEventsList } = props;
    const { fullCustomerSportEventsList, initialRequests, setIsBackgroundIdsUpdateAvailable } = useCustomerFavoritesService();
    const favoriteSelectedSportFamily = ref(null);
    const favoriteSelectedRegionFamily = ref(null);
    /**
   * Filtered favorites events list from favorites events request and favorites leagues
   * Used for favorites regions filter
   */ const favoriteEventsFullList = computed(()=>prepareEventsList(fullCustomerSportEventsList.value ?? []));
    const favoritesAvailableSportFamilies = computed(()=>favoriteEventsFullList.value.flatMap((sportElement)=>sportElement.sport.representation.family));
    const favoritesAvailableSportFamiliesHash = computed(()=>favoritesAvailableSportFamilies.value.join(','));
    const favoritesAvailableRegionFamilies = computed(()=>favoriteEventsFullList.value.flatMap((sportElement)=>sportElement.regions.flatMap((regionElement)=>regionElement.region.regionFamily)));
    const favoritesAvailableRegionFamiliesHash = computed(()=>favoritesAvailableRegionFamilies.value.join(','));
    /**
   * Actual list for display at favorites tab
   */ const favoriteEventsListForDisplay = computed(()=>{
        let result = [
            ...favoriteEventsFullList.value
        ];
        if (favoriteSelectedSportFamily.value) result = result.filter((sportElement)=>isSportFamilyEquals(sportElement.sport, favoriteSelectedSportFamily.value));
        if (favoriteSelectedRegionFamily.value) result = filterSportListRegions(result, (regionElement)=>regionElement.region.regionFamily === favoriteSelectedRegionFamily.value);
        return result;
    });
    async function setBackgroundUpdateEnabled(value) {
        await setIsBackgroundIdsUpdateAvailable(backgroundFavoritesId, value);
    }
    function setFavoriteSelectedRegionFamily(regionFamily) {
        favoriteSelectedRegionFamily.value = regionFamily;
    }
    function setFavoriteSelectedSportFamily(sportFamily) {
        favoriteSelectedSportFamily.value = sportFamily;
    }
    function selectFirstAvailableFavoriteSportFamily() {
        setFavoriteSelectedSportFamily(favoritesAvailableSportFamilies.value[0] ?? null);
    }
    /**
   * @TODO check logic, maybe it must be placed in a Page composable to prevent calculations after leave page
   */ function onInit() {
        watch(favoritesAvailableSportFamiliesHash, ()=>{
            const sportFamilies = favoritesAvailableSportFamilies.value;
            const selectedSportFilter = favoriteSelectedSportFamily.value;
            if (selectedSportFilter && !sportFamilies.includes(selectedSportFilter)) setFavoriteSelectedSportFamily(null);
        }, {
            immediate: true
        });
        watch(favoritesAvailableRegionFamiliesHash, ()=>{
            const regionFamilies = favoritesAvailableRegionFamilies.value;
            const selectedRegionFilter = favoriteSelectedRegionFamily.value;
            if (selectedRegionFilter && !regionFamilies.includes(selectedRegionFilter)) setFavoriteSelectedRegionFamily(null);
        }, {
            immediate: true
        });
    }
    return {
        favoriteSelectedRegionFamily,
        favoriteSelectedSportFamily,
        favoriteEventsFullList,
        favoriteEventsListForDisplay,
        onInit,
        initialRequests,
        setBackgroundUpdateEnabled,
        setFavoriteSelectedSportFamily,
        setFavoriteSelectedRegionFamily,
        selectFirstAvailableFavoriteSportFamily
    };
}
