import { Currency } from '../enums';
export const defaultCurrency = 'USD';
export const defaultCurrencyLocale = 'en';
const dollarSign = '$';
const kroneSign = 'kr';
const bonusCurrencySign = 'L';
export const currencySymbols = Object.freeze({
    [Currency.EUR]: '€',
    [Currency.RUB]: '₽',
    [Currency.USD]: dollarSign,
    [Currency.KZT]: '₸',
    [Currency.UAH]: '₴',
    [Currency.PLN]: 'zł',
    [Currency.GBP]: '£',
    [Currency.SEK]: kroneSign,
    [Currency.NOK]: kroneSign,
    [Currency.DKK]: kroneSign,
    [Currency.BYN]: 'руб',
    [Currency.TRY]: '₺',
    [Currency.BRL]: 'R$',
    [Currency.ARS]: dollarSign,
    [Currency.MXN]: dollarSign,
    [Currency.CLP]: dollarSign,
    [Currency.UYU]: dollarSign,
    [Currency.COP]: dollarSign,
    [Currency.HKD]: dollarSign,
    [Currency.PEN]: 'S/',
    [Currency.CZK]: 'Kč',
    [Currency.BSF]: 'B$',
    [Currency.CNY]: '¥',
    [Currency.JPY]: '¥',
    [Currency.TWD]: 'NT$',
    [Currency.INR]: '₹',
    [Currency.BTC]: '₿',
    [Currency.LTC]: 'Ł',
    [Currency.ETH]: 'Ξ',
    [Currency.AZN]: '₼',
    [Currency.UZS]: 'UZS',
    [Currency.CAD]: dollarSign,
    [Currency.TJS]: 'SM',
    [Currency.KGS]: 'с',
    [Currency.TMT]: 'T',
    [Currency.MDL]: 'L',
    [Currency.AUD]: 'A$',
    [Currency.NZD]: 'NZ$',
    [Currency.VND]: 'đ',
    [Currency.THB]: '฿',
    [Currency.TZS]: 'TZS',
    // bonus currency
    [Currency.KHQ]: bonusCurrencySign,
    L: bonusCurrencySign,
    [Currency.LCC]: bonusCurrencySign,
    [Currency.USDT]: 'USDT',
    [Currency.USDC]: 'USDC'
});
