import { defineStore } from 'pinia';
import { useDebugIFrameUrl } from 'web/src/modules/sportline/submodules/debug/store/composables';
const LS_IFRAME_URL_KEY = 'SPORTLINE_WIDGETS_IFRAME_URL';
export const useSportlineWidgetsDebugStore = defineStore('sportline-widgets-debug', ()=>{
    const { iframeUrl, saveIFrameUrl, setIFrameUrl, loadIFrameUrl } = useDebugIFrameUrl({
        localStorageKey: LS_IFRAME_URL_KEY
    });
    loadIFrameUrl();
    return {
        iframeUrl,
        setIFrameUrl,
        saveIFrameUrl
    };
});
