import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import LobbyEgsGroupItem from 'web/src/modules/lobby/components/LobbyEgsGroupItem/LobbyEgsGroupItem.vue';
import { useCasinoGroupsList } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGroupsList',
    props: {
        groups: {},
        selectedId: {},
        isSearchModal: {
            type: Boolean
        }
    },
    emits: [
        "selected-loaded",
        "click-group"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { onClick, onSelectedLoaded } = useCasinoGroupsList(emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['groups-list']]: true,
                    [_ctx.$style['groups-list--search']]: !!_ctx.isSearchModal
                })
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group)=>_withDirectives((_openBlock(), _createBlock(LobbyEgsGroupItem, {
                        key: group.id,
                        item: group,
                        "is-active": group.id === _ctx.selectedId,
                        class: _normalizeClass(_ctx.$style['groups-list__item']),
                        onClick: ($event)=>_unref(onClick)(group),
                        onSelectedLoaded: _unref(onSelectedLoaded)
                    }, null, 8, [
                        "item",
                        "is-active",
                        "class",
                        "onClick",
                        "onSelectedLoaded"
                    ])), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'groups-list-item',
                                id: group.id
                            }
                        ]
                    ])), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGroupsList'
                ]
            ]);
        };
    }
});
