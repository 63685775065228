import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, ref, toRef } from 'vue';
import { useProvideMarketColumn, useExpandableSportlineBlock } from 'web/src/modules/sportline/composables/list';
import { useSportlineElementMarketColumns, useSportlineElementMarketTypes } from 'web/src/modules/sportline/composables/markets';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import SportlineEventBlock from 'web/src/modules/sportline/views/EventBlock/SportlineEventBlock.vue';
import LeagueHeadline from 'web/src/modules/sportline/views/headline/LeagueHeadline.vue';
import SportHeadline from 'web/src/modules/sportline/views/headline/SportHeadline.vue';
import { addCountersToCounters, addSportlineEventToCounters, createSportEventsCounters } from 'web/src/modules/sportline/utils';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineHomeSportElement',
    props: {
        sportElement: {},
        isShownLiveIndicator: {
            type: Boolean
        },
        isShownLeaguesHeadline: {
            type: Boolean
        },
        canShowLogos: {
            type: Boolean
        },
        isFilterActive: {
            type: Boolean
        },
        isMarketTypesSelectionEnabled: {
            type: Boolean
        },
        expandKey: {},
        isDefaultExpanded: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const sportElement = toRef(props, 'sportElement');
        const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);
        const expandKey = toRef(props, 'expandKey', null);
        const isDefaultExpanded = toRef(props, 'isDefaultExpanded', false);
        const isShownLeaguesHeadline = toRef(props, 'isShownLeaguesHeadline', false);
        const sport = computed(()=>sportElement.value.sport);
        const sportId = computed(()=>sport.value.id);
        const basisKey = createSportlineFragmentBasisKey({
            sportId
        });
        const { marketsColumns } = useSportlineElementMarketColumns({
            sportlineElement: sportElement
        });
        const { marketsTypes } = useSportlineElementMarketTypes({
            sportlineElement: sportElement
        });
        const content = ref();
        const list = computed(()=>sportElement.value.sportEvents.reduce((result, param)=>{
                let { sportEvent, league, region } = param;
                let currentElement;
                const previousElement = result[result.length - 1] ?? null;
                if (previousElement && league.key === previousElement.leagueElement.league.key) currentElement = previousElement;
                else {
                    const leagueElement = {
                        key: league.key,
                        league,
                        marketTypes: marketsTypes.value,
                        sportEvents: [],
                        outrightEvents: [],
                        counters: createSportEventsCounters()
                    };
                    currentElement = {
                        region,
                        leagueElement,
                        sportEvents: []
                    };
                    result.push(currentElement);
                }
                currentElement.sportEvents.push({
                    sportEvent
                });
                currentElement.leagueElement.counters = addSportlineEventToCounters(currentElement.leagueElement.counters, sportEvent);
                return result;
            }, []));
        const counters = computed(()=>list.value.reduce((result, currentValue)=>addCountersToCounters(result, currentValue.leagueElement.counters), createSportEventsCounters()));
        const { isExpanded, isExpandable, expandAnimationActive, expandAnimationDuration, toggleExpandState } = useExpandableSportlineBlock({
            content,
            expandKey,
            isDefaultExpanded
        });
        const { canSelectMarketType } = useProvideMarketColumn({
            basisKey,
            isMarketTypesSelectionEnabled,
            marketsColumns,
            marketsTypes
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createVNode(SportHeadline, {
                    "sport-id": sport.value.id,
                    "basis-key": _unref(basisKey),
                    "is-market-types-selection-enabled": _unref(canSelectMarketType),
                    "count-events": counters.value.total,
                    "expand-key": expandKey.value,
                    "is-expanded": _unref(expandAnimationActive) ? !_unref(isExpanded) : _unref(isExpanded),
                    onToggleExpandState: _unref(toggleExpandState)
                }, null, 8, [
                    "sport-id",
                    "basis-key",
                    "is-market-types-selection-enabled",
                    "count-events",
                    "expand-key",
                    "is-expanded",
                    "onToggleExpandState"
                ]),
                _createElementVNode("div", {
                    ref_key: "content",
                    ref: content,
                    class: _normalizeClass({
                        [_ctx.$style['sportline-events-list__container']]: true,
                        [_ctx.$style['expandable-element-content']]: true,
                        [_ctx.$style['expandable-element-content--hidden']]: _unref(isExpandable) && !_unref(isExpanded)
                    }),
                    style: _normalizeStyle({
                        'transition-duration': `${_unref(expandAnimationDuration)}ms`
                    })
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (element, index)=>(_openBlock(), _createElementBlock("div", {
                            key: `${element.leagueElement.league.key}_${index}`,
                            class: _normalizeClass(_ctx.$style['sportline-events-list'])
                        }, [
                            isShownLeaguesHeadline.value ? (_openBlock(), _createBlock(LeagueHeadline, {
                                key: element.leagueElement.key,
                                class: _normalizeClass(_ctx.$style['league-headline']),
                                sport: sport.value,
                                region: element.region,
                                "league-element": element.leagueElement,
                                "is-filter-active": _ctx.isFilterActive,
                                "basis-key": _unref(basisKey)
                            }, null, 8, [
                                "class",
                                "sport",
                                "region",
                                "league-element",
                                "is-filter-active",
                                "basis-key"
                            ])) : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.sportEvents, (sportEventElement)=>(_openBlock(), _createBlock(SportlineEventBlock, {
                                    key: sportEventElement.sportEvent.id,
                                    sport: sport.value,
                                    "sport-event-element": sportEventElement,
                                    "is-shown-live-indicator": _ctx.isShownLiveIndicator,
                                    "can-show-logos": _ctx.canShowLogos,
                                    "can-add-to-favorite": true,
                                    "can-add-outrights-to-favorite": true,
                                    "extended-block-type": _unref(ExtendedEventBlock).Top,
                                    "basis-key": _unref(basisKey)
                                }, null, 8, [
                                    "sport",
                                    "sport-event-element",
                                    "is-shown-live-indicator",
                                    "can-show-logos",
                                    "can-add-to-favorite",
                                    "can-add-outrights-to-favorite",
                                    "extended-block-type",
                                    "basis-key"
                                ]))), 128))
                        ], 2))), 128))
                ], 6)
            ])), [
                [
                    _directive_auto_id,
                    'SportlineHomeSportElement'
                ]
            ]);
        };
    }
});
