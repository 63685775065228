import { useRouter } from 'vue-router';
import { computed, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useRouterStore } from 'web/src/modules/core/store';
export default function useDefaultTopBarRouteComponent(props, emit) {
    const router = useRouter();
    const isBackButtonInTopBar = toRef(useRouterStore(), 'isBackButtonInTopBar');
    const prefixIconName = computed(()=>{
        if (props.hidePrefix) return;
        if (props.customPrefixIconName) return props.customPrefixIconName;
        if (isBackButtonInTopBar.value) return IconName.ARROW_LEFT;
    });
    const suffixIconName = computed(()=>props.hideSuffix ? void 0 : IconName.CROSS);
    function onPrefixClick() {
        if (props.onlyEmitEvents) emit('back');
        else router.back();
    }
    function close() {
        emit('close');
        if (!props.onlyEmitEvents) router.closeModal();
    }
    return {
        prefixIconName,
        suffixIconName,
        onPrefixClick,
        close
    };
}
