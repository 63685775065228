import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { BetCashoutMode } from 'web/src/modules/cashout/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { CashoutButton } from '../CashoutButton';
import useBetCashOutInline from './useBetCashOutInline';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetCashOutInline',
    props: {
        price: {
            default: 0
        },
        betPrice: {
            default: 0
        },
        currency: {
            default: 'USD'
        },
        oldPrice: {},
        isLoaded: {
            type: Boolean
        },
        mode: {
            default: BetCashoutMode.DEFAULT
        },
        errorMessage: {
            default: ''
        },
        betName: {}
    },
    emits: [
        "cash-out"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isError, emitCashOut } = useBetCashOutInline(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['cash-out-inline'])
            }, [
                _ctx.isLoaded ? _unref(isError) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(CashoutButton), {
                    key: 1,
                    "cashout-amount": _ctx.price,
                    "stake-amount": _ctx.betPrice,
                    onClick: _unref(emitCashOut)
                }, null, 8, [
                    "cashout-amount",
                    "stake-amount",
                    "onClick"
                ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetCashOutInline'
                ]
            ]);
        };
    }
});
