import { watch, nextTick, ref } from 'vue';
import { setInputCursorAfterValue } from 'web/src/components/Input/utils';
export default function useAutofocus(props) {
    const focusable = ref();
    async function focus() {
        let { forceIos, atTheEnd } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        if (!process.env.VUE_APP_OS_IOS || forceIos) {
            await nextTick();
            if (focusable.value) {
                if (atTheEnd && focusable.value instanceof HTMLInputElement) setInputCursorAfterValue(focusable.value);
                focusable.value.focus?.();
            }
        }
    }
    watch(()=>props.autofocus, (value)=>{
        if (value) focus();
    }, {
        immediate: true
    });
    return {
        focusable,
        focus
    };
}
