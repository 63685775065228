import { LobbyGameType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
export default function getLobbyRouteName(lobbyType) {
    let isLiveEgsRoute = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    switch(lobbyType){
        case LobbyGameType.LIVE:
            return CasinoRouteName.CASINO_LIVE_LOBBY;
        case LobbyGameType.SLOTS:
            return CasinoRouteName.CASINO_LOBBY;
        default:
            return isLiveEgsRoute ? CasinoRouteName.CASINO_LIVE_LOBBY : CasinoRouteName.CASINO_LOBBY;
    }
}
