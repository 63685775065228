import { computed } from 'vue';
import { getHasImageObject, getIconName } from 'web/src/modules/bonuses/components/BonusLoyaltyProgramCard/composables/utils';
export default function useBonusLoyaltyProgramCard(props, emit) {
    const iconName = computed(()=>getIconName(props));
    const hasImageObject = computed(()=>getHasImageObject(props));
    const onBonusLoyaltyProgramCardClick = ()=>{
        if (!props.isLocked) emit('click');
    };
    return {
        iconName,
        onBonusLoyaltyProgramCardClick,
        hasImageObject
    };
}
