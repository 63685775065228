import { onActivated, onBeforeUnmount, onDeactivated } from 'vue';
import debounce from 'lodash/debounce';
export function useDebounce(func, wait, options) {
    let debouncedFn = debounce(func, wait, options);
    const cancel = ()=>debouncedFn?.cancel();
    const flush = ()=>debouncedFn?.flush();
    function pause() {
        cancel();
        debouncedFn = void 0;
    }
    function resume() {
        // debounced func was cancelled during onDeactivated hook, so we need to debounce it once again.
        debouncedFn = debounce(func, wait, options);
    }
    onBeforeUnmount(pause);
    onDeactivated(pause);
    onActivated(resume);
    const wrapper = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        if (debouncedFn) return debouncedFn.apply(this, args);
    };
    wrapper.cancel = cancel;
    wrapper.flush = flush;
    return wrapper;
}
