import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { Timer } from '@leon-hub/utils';
import { isNumber } from '@leon-hub/guards';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { BetCashoutMode } from 'web/src/modules/cashout/enums';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';
import { TopLevelTabs } from '../../../enums';
import isPendingBetsArray from '../../../guards/isPendingBetsArray';
import { getPendingBetsFromApi, mapPendingBetToSlipListHistoryItem } from '../utils';
import useSlipViewSettingsStore from '../../slip-view-settings/store/useSlipViewSettingsStore';
const usePendingBetsStore = defineStore('pending-bets', ()=>{
    const siteConfigStore = useSiteConfigStore();
    const slipViewSettingsStore = useSlipViewSettingsStore();
    const activeTopTabId = toRef(slipViewSettingsStore, 'activeTopTabId');
    const formatMoney = useFormatMoney();
    const { $translate } = useI18n();
    const { isLoggedIn } = useIsLoggedIn();
    const apiClient = useGraphqlClient();
    const { currentOddsType, isOddsFormatSwitcherEnabled } = useOddsSettings();
    const slipBlock = toRef(siteConfigStore, 'slipBlock');
    const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');
    const pendingBetsUpdateInterval = computed(()=>sportLineBlock.value?.pendingBetsUpdateInterval || 600000);
    const myBetsPaginationCount = computed(()=>slipBlock.value?.pendingBetsPaginationCount || 15);
    const pendingBets = ref([]);
    const pendingBetsCount = ref(0);
    const isLoaded = ref(false);
    const isLoadingMore = ref(false);
    let pendingBetsUpdateIntervalId = 0;
    const setPendingBets = (value)=>{
        pendingBets.value = value;
    };
    const setPendingBetsCount = (count)=>{
        pendingBetsCount.value = count;
    };
    const setIsLoaded = (isNowLoaded)=>{
        isLoaded.value = isNowLoaded;
    };
    const setIsLoadingMore = (isLoading)=>{
        isLoadingMore.value = isLoading;
    };
    const isMyBetsViewActive = computed(()=>activeTopTabId.value === TopLevelTabs.MY_BETS);
    const myBets = computed(()=>{
        if (!pendingBets.value?.length) return [];
        const expressTitle = $translate('JAVA_ACCHITEM_COMBI').value;
        const systemTitle = $translate('JAVA_ACCHITEM_SYSTEM').value;
        return pendingBets.value.map((pendingBet)=>mapPendingBetToSlipListHistoryItem({
                pendingBet,
                systemTitle,
                expressTitle,
                formatMoney,
                isOddsFormatSwitcherEnabled: isOddsFormatSwitcherEnabled.value
            }));
    });
    const requestPendingBets = (payload)=>{
        const options = {
            count: payload?.count || myBetsPaginationCount.value,
            from: payload?.from || 0,
            splitEventName: true
        };
        return getPendingBetsFromApi(apiClient, options, payload?.silent);
    };
    const loadPendingBets = async function() {
        let isBackgroundUpdate = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (isLoggedIn.value) {
            let options = {
                silent: isBackgroundUpdate
            };
            if (!isBackgroundUpdate) Timer.clearInterval(pendingBetsUpdateIntervalId);
            if (isBackgroundUpdate && isMyBetsViewActive.value) options = {
                ...options,
                count: pendingBets.value.length
            };
            const response = await requestPendingBets(options);
            setIsLoaded(true);
            if (!response) return;
            const { bets, totalCount } = response;
            if (bets) {
                isPendingBetsArray(bets);
                setPendingBets(bets);
            }
            if (isNumber(totalCount)) setPendingBetsCount(totalCount);
            if (!isBackgroundUpdate && totalCount) // call itself with timing
            pendingBetsUpdateIntervalId = Timer.setInterval(()=>{
                loadPendingBets(true);
            }, pendingBetsUpdateInterval.value);
        }
    };
    const clearPendingBets = ()=>{
        setPendingBets([]);
        setPendingBetsCount(0);
        setIsLoaded(false);
        setIsLoadingMore(false);
    };
    const loadMorePendingBets = async ()=>{
        const loadedCount = pendingBets.value.length;
        if (loadedCount >= pendingBetsCount.value) return;
        setIsLoadingMore(true);
        const response = await requestPendingBets({
            from: loadedCount
        });
        setIsLoadingMore(false);
        if (!response) return;
        const { bets, totalCount } = response;
        if (bets) {
            isPendingBetsArray(bets);
            setPendingBets([
                ...pendingBets.value,
                ...bets
            ]);
        }
        if (isNumber(totalCount)) setPendingBetsCount(totalCount);
    };
    const updatePendingBetsOnCashout = (options)=>{
        if (!options?.betId || !pendingBets.value.length) return;
        const { result, betId } = options;
        let updatedList;
        if (result === BetCashoutMode.SUCCESS) {
            updatedList = pendingBets.value.filter((item)=>item.betId !== betId);
            setPendingBetsCount(pendingBetsCount.value - 1);
        } else {
            updatedList = [
                ...pendingBets.value
            ];
            const matchedIndex = updatedList.findIndex((item)=>item.betId === betId);
            if (matchedIndex > -1) updatedList[matchedIndex] = {
                ...updatedList[matchedIndex],
                isEnabledForCashout: false
            };
        }
        setPendingBets(updatedList);
    };
    /** former init */ loadPendingBets();
    watch(isLoggedIn, (newValue)=>{
        if (newValue) loadPendingBets();
        else clearPendingBets();
    });
    watch(currentOddsType, ()=>{
        // reload pending bets with current oddsType
        if (isLoaded.value && pendingBetsCount.value) {
            clearPendingBets();
            loadPendingBets();
        }
    });
    return {
        myBets,
        myBetsPaginationCount,
        isLoaded,
        pendingBetsCount,
        isLoadingMore,
        setIsLoaded,
        loadPendingBets,
        clearPendingBets,
        loadMorePendingBets,
        updatePendingBetsOnCashout
    };
});
export default usePendingBetsStore;
