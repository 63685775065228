import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import { computed, nextTick, ref, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { SlottIconName } from '@leon-hub/icons';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import VList from 'web/src/components/List/VList/VList.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import { useRulesStore } from 'web/src/modules/rules/store';
import { VEmpty } from 'web/src/components/Empty';
import { ListItemAccordionKind } from 'web/src/components/List/VListItemAccordion/enums';
import { SwitchKind } from 'web/src/components/Switch/VSwitch/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { useCmsContentToPrint } from 'web/src/modules/rules/composables';
import { useRootStore } from 'web/src/modules/core/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RulesContentList',
    setup (__props) {
        const navigation = toRef(useRulesStore(), 'navigation');
        const bus = useEventsBus();
        const route = useRoute();
        const router = useRouter();
        ref([]);
        const { getCmsContentToPrint } = useCmsContentToPrint();
        const { isAppMainContentLoaded } = storeToRefs(useRootStore());
        const openedRuleCategory = ref('');
        const ruleItem = computed(()=>findRuleItemById(navigation.value, openedRuleCategory.value));
        const ruleCategory = computed(()=>{
            if (navigation.value) return navigation.value.find((item)=>item.id === route.params?.levelOneId);
            return null;
        });
        // Needed for block with cms content
        const ruleCmsKey = computed(()=>{
            if (ruleItem.value && ruleItem.value?.props?.contentKey) return ruleItem.value?.props?.contentKey;
            return '';
        });
        // Needed for block with cms content
        const ruleTitle = computed(()=>{
            if (ruleItem.value?.caption) return ruleItem.value?.caption;
            return '';
        });
        watch(()=>route.params.levelOneId, async ()=>{
            const ruleId = ruleCategory.value?.children?.[0]?.children?.[0]?.id;
            if (ruleId) openedRuleCategory.value = ruleId;
        }, {
            immediate: true
        });
        // For Leon navigation
        watch(()=>route.params.levelThreeId, (newValue)=>{
            {
                const ruleId = findRuleItemById(navigation.value, String(newValue))?.id;
                if (ruleId) openedRuleCategory.value = ruleId;
            }
        }, {
            immediate: true
        });
        function findRuleItemById(nav, id) {
            let foundRuleItem = null;
            for (const item of nav){
                if (item.id === id) return item;
                if (item.children) {
                    foundRuleItem = findRuleItemById(item.children, id);
                    if (foundRuleItem) break;
                }
            }
            return foundRuleItem;
        }
        watch(isAppMainContentLoaded, async ()=>{
            if (isAppMainContentLoaded.value) {
                const { hash } = route;
                if (hash) {
                    const ruleId = hash.substring(1);
                    openedRuleCategory.value = ruleId;
                    const element = document.getElementById(ruleId);
                    if (element) {
                        await nextTick();
                        bus.emit(BusEvent.SCROLL_TO_ELEMENT_ID, {
                            id: element.id
                        });
                    }
                }
            }
        }, {
            flush: 'post'
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['rules-container'])
            }, [
                (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['rules-container__title'])
                    }, _toDisplayString(ruleTitle.value), 3),
                    _createVNode(VCmsContent, {
                        class: _normalizeClass(_ctx.$style['inner']),
                        "cms-key": ruleCmsKey.value,
                        "do-not-update-if-exists": ""
                    }, null, 8, [
                        "class",
                        "cms-key"
                    ])
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'RulesContentList'
                ]
            ]);
        };
    }
});
