import { toRef } from 'vue';
import { shouldReduceMoneyAmount, getCurrencySymbol } from '../utils';
import { useCurrencyStore } from '../store';
export default function useFormatMoney() {
    const currencyStore = useCurrencyStore();
    const currencyLocale = toRef(()=>currencyStore.currencyLocale);
    const currency = toRef(()=>currencyStore.currency);
    return function(amount) {
        let inputOptions = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        const options = {
            ...inputOptions
        };
        const defaultFractionDigits = Number.isInteger(amount) && 0 !== amount ? 0 : 2;
        const defaultCurrencyDisplayOption = 'code';
        let formattedValue;
        const defaultOptions = {
            locale: currencyLocale.value,
            style: 'currency',
            currency: currency.value,
            currencyDisplay: defaultCurrencyDisplayOption,
            minimumFractionDigits: defaultFractionDigits,
            maximumFractionDigits: defaultFractionDigits
        };
        const originalCurrency = options?.currency ?? defaultOptions.currency;
        let workingOptions = {
            ...options
        };
        if (options?.currency === 'L' || options.currency?.length !== 3) workingOptions = {
            ...workingOptions,
            currency: 'KHQ',
            locale: currencyLocale.value
        };
        const { locale, ...intlOptions } = workingOptions;
        const formatOptions = {
            ...defaultOptions,
            ...intlOptions
        };
        if (options.hideCurrency) formatOptions.style = 'decimal';
        const { currency: customCurrency } = formatOptions;
        if (options.reduceAmount && shouldReduceMoneyAmount(amount, customCurrency, options.reduceAmount)) {
            const roundedCurrency = Math.round(amount / 1000);
            formattedValue = roundedCurrency.toLocaleString(locale || currencyLocale.value, {
                style: 'decimal'
            });
            return `${formattedValue}K`;
        }
        formattedValue = amount.toLocaleString(locale || currencyLocale.value, formatOptions);
        if (formatOptions.currencyDisplay === defaultCurrencyDisplayOption && !options.hideCurrency) formattedValue = formattedValue.replace(customCurrency, getCurrencySymbol(originalCurrency));
        return formattedValue;
    };
}
