import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createSlots as _createSlots, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0
};
import { useRouter } from 'vue-router';
import { toRef } from 'vue';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { AllGamesGroupUrl, EgsJackpotsSyncStateTimerName } from 'web/src/modules/egs/constants';
import HomeHeading from 'web/src/modules/home/components/heading/HomeHeading.vue';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useEgsNavigation from 'web/src/modules/egs/submodules/navigation/composable/useEgsNavigation';
import { useCasinoTopStore } from 'web/src/modules/casino/submodules/top/useCasinoTopStore';
import EgsGameJackpotListItem from 'web/src/modules/egs/submodules/jackpots/components/EgsGameJackpotListItem/EgsGameJackpotListItem.vue';
import { useComponentSyncState } from 'web/src/modules/core/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoHomeJackpotsBlock',
    setup (__props) {
        const router = useRouter();
        const casinoTopStore = useCasinoTopStore();
        const analytics = useAnalytics();
        const navigation = useEgsNavigation(router);
        const hasJackpots = toRef(casinoTopStore, 'hasJackpots');
        const jackpots = toRef(casinoTopStore, 'jackpots');
        function getJackpotLocation(game) {
            return navigation.getEgsGameHref(game);
        }
        function navigateToJackpot(game) {
            analytics.push(AnalyticsEvent.Z_CLICK_JACKPOT_BANNER, {});
            navigation.navigateToGamePage(game);
        }
        const { timeout } = useComponentSyncState(casinoTopStore.updateJackpots, EgsJackpotsSyncStateTimerName, {
            condition: hasJackpots
        });
        return (_ctx, _cache)=>{
            const _directive_collect = _resolveDirective("collect");
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return hasJackpots.value && jackpots.value ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(HomeHeading, {
                    class: _normalizeClass(_ctx.$style['home-jackpots__heading']),
                    title: _ctx.$t('WEB2_HOME_HEADING_JACKPOTS'),
                    "button-text": _ctx.$t('WEB2_HOME_HEADING_JACKPOTS_BTN'),
                    to: {
                        name: _unref(CasinoRouteName).CASINO_LOBBY,
                        params: {
                            groupUrl: _unref(AllGamesGroupUrl),
                            categoryUrl: 'jackpots'
                        }
                    },
                    "is-dark": ""
                }, null, 8, [
                    "class",
                    "title",
                    "button-text",
                    "to"
                ]),
                _createVNode(_unref(VSwiper), {
                    "is-scroll-snap-enabled": "",
                    "wrapper-class": _ctx.$style['home-jackpots__swiper-wrapper']
                }, _createSlots({
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(jackpots.value, (jackpot)=>_withDirectives((_openBlock(), _createBlock(VSwiperSlide, {
                                    key: jackpot.id,
                                    onClick: ($event)=>navigateToJackpot(jackpot)
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(EgsGameJackpotListItem, {
                                                item: jackpot,
                                                href: getJackpotLocation(jackpot),
                                                "animation-duration": _unref(timeout)
                                            }, null, 8, [
                                                "item",
                                                "href",
                                                "animation-duration"
                                            ])
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "onClick"
                                ])), [
                                    [
                                        _directive_collect,
                                        {
                                            SLOTS: 'jackpotBanner'
                                        },
                                        "click-counter"
                                    ]
                                ])), 128))
                        ]),
                    _: 2
                }, [
                    {
                        name: "pagination-footer",
                        fn: _withCtx(()=>[
                                _createVNode(SwiperNavigationButtons)
                            ]),
                        key: "0"
                    }
                ]), 1032, [
                    "wrapper-class"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CasinoHomeJackpotsBlock'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'home-jackpots'
                    }
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
