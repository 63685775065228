import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName, { isBaseRouteName } from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags/src/Tag';
import { Events, TargetParamHistory, TargetParamNavigation, TargetParamWithdrawal } from '@leon-hub/yandex-metrika';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { isTag } from '@leon-hub/tags';
import { getNavigationItemRouteLocation } from '@leon-hub/navigation-config';
import getOptionalString from 'web/src/utils/getOptionalString';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import { useCustomerNotificationsUnreadCounter } from 'web/src/modules/customer-notifications/composables';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import { getEgsLoyaltyStore } from 'web/src/modules/core/components/AuthorizedAppController/composables/utils';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ // eslint-disable-next-line sonarjs/cognitive-complexity
export default function useProfileNavList() {
    const router = useRouter();
    const analytics = useAnalytics();
    const $formatMoney = useFormatMoney();
    const profileBonusesStore = useProfileBonusesStore();
    const bonusLoyaltyStore = useBonusLoyaltyStore();
    const egsLoyaltyStore = getEgsLoyaltyStore();
    const egsBonuses = toRef(profileBonusesStore, 'egsBonuses');
    const sportBonuses = toRef(profileBonusesStore, 'sportBonuses');
    const { unreadCounter } = useCustomerNotificationsUnreadCounter();
    const isParticipating = toRef(bonusLoyaltyStore, 'isParticipating');
    const amountNumber = toRef(bonusLoyaltyStore, 'amountNumber');
    const bonusesCount = computed(()=>sportBonuses.value.length + egsBonuses.value.length);
    function getIconSettings(item) {
        // TODO: temp hardcode
        switch(item.id){
            case 'profile-my-games':
                return 'my-games';
            case 'profile-cashback':
                return 'cashback';
            default:
                break;
        }
        return item.icon?.name;
    }
    function getBonusesValue(item) {
        if (item?.routeName === RouteName.LOYALTY_PROGRAM) return isParticipating.value ? $formatMoney(amountNumber.value || 0, {
            currency: 'L'
        }) : '';
        if (item?.routeName === RouteName.BONUSES) return getOptionalString(bonusesCount.value);
    }
    function checkHasNewNotifications(item) {
        const itemId = isBaseRouteName(item.id) ? item.id : void 0;
        return itemId === RouteName.CUSTOMER_NOTIFICATIONS && item?.suffix?.label !== '';
    }
    function getBadgeLabel(item) {
        if (item?.suffix && item?.suffix.component === 'VBadge') return item.suffix.label;
        if (item.icon?.name === IconName.BELL) return unreadCounter.value;
        if (item?.routeName === CasinoRouteName.CASINO_LOYALTY_PROGRAM) {
            const currentLevelData = toRef(egsLoyaltyStore, 'currentLevelData');
            return currentLevelData.value?.name;
        }
        return '';
    }
    function getCounterLabel(item) {
        const tag = isTag(item?.suffix?.component) ? item.suffix?.component : void 0;
        if (tag === Tag.SPAN) return item.suffix?.label ?? '';
        return '';
    }
    function setMetrika(target, param) {
        analytics.push(Events.CLICK_MAP, {
            clickCounter: {
                [target]: param
            }
        });
    }
    function onClick(item) {
        switch(item.routeName){
            case RouteName.WITHDRAWALS:
                setMetrika('withdrawal', TargetParamWithdrawal.USER_PROFILE);
                break;
            case RouteName.CUSTOMER_TRANSACTIONS_HISTORY:
                setMetrika('history', TargetParamHistory.USER_PROFILE);
                break;
            case RouteName.PROMOTIONS:
                setMetrika('promo', TargetParamNavigation.LINK_IN_PROFILE);
                break;
            default:
                break;
        }
        if (item.routeName) router.push(getNavigationItemRouteLocation(item, 'useProfileNavList'));
        else if (item.url) router.push({
            path: item.url
        });
    }
    return {
        getIconSettings,
        getBadgeLabel,
        checkHasNewNotifications,
        getCounterLabel,
        onClick,
        getBonusesValue
    };
}
