import { _ as _define_property } from "@swc/helpers/_/_define_property";
let RequestAnimationFrame = class RequestAnimationFrame {
    start() {
        if (0 !== this.animationId) return;
        const start = performance.now();
        const { options } = this;
        const animate = (time)=>{
            let timeFraction = (time - start) / options.duration;
            if (timeFraction < 0) timeFraction = 0;
            if (timeFraction > 1) timeFraction = 1;
            const progress = options.timing(timeFraction);
            options.draw(progress);
            if (timeFraction < 1) {
                this.animationId = requestAnimationFrame(animate);
                return;
            }
            // animation finished
            this.stop();
        };
        requestAnimationFrame(animate);
    }
    stop() {
        cancelAnimationFrame(this.animationId);
        this.animationId = 0;
    }
    constructor(options){
        _define_property(this, "options", void 0);
        _define_property(this, "animationId", 0);
        this.options = options;
    }
};
export { RequestAnimationFrame as default };
