import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getFAQCategories, getFAQItems } from '@leon-hub/api-sdk/src/sdk/sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useSupportFaqStore = defineStore('supportFaq', ()=>{
    const apiClient = useGraphqlClient();
    const faqCategories = ref([]);
    const faqs = ref([]);
    const searchText = ref('');
    const isYandexMetrikaAllowed = ref(false);
    async function fetchCategories(silent) {
        const response = await getFAQItems(apiClient, (node)=>node.queries.faq.getList, {
            options: {}
        }, {
            silent
        });
        const faqCategs = [];
        for (const item of response.items)for (const categoryName of item.categories)if (categoryName) {
            let faqCategory = faqCategs.find((category)=>category.name === categoryName);
            if (!faqCategory) {
                faqCategory = {
                    name: categoryName,
                    items: []
                };
                faqCategs.push(faqCategory);
            }
            faqCategory.items.push(item);
        }
        faqCategories.value = faqCategs;
    }
    async function fetchFaqCategories() {
        const response = await getFAQCategories(apiClient, (node)=>node.queries.faq.getCategories, {});
        faqs.value = response.categories;
    }
    function setSearchText(value) {
        searchText.value = value;
    }
    function setIsYandexMetrikaAllowed(value) {
        isYandexMetrikaAllowed.value = value;
    }
    return {
        faqCategories,
        faqs,
        searchText,
        isYandexMetrikaAllowed,
        fetchCategories,
        fetchFaqCategories,
        setSearchText,
        setIsYandexMetrikaAllowed
    };
});
export default useSupportFaqStore;
