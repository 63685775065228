import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
import { toRef } from 'vue';
import { VIcon } from '@components/v-icon';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useVFavoriteToggle } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VFavoriteToggle',
    props: {
        activeIcon: {},
        activeIconColor: {},
        inactiveIcon: {},
        isFavorite: {
            type: Boolean
        },
        hasBackground: {
            type: Boolean
        },
        isButton: {
            type: Boolean
        },
        isOnlyPending: {
            type: Boolean
        },
        hideColors: {
            type: Boolean
        },
        hasOpacity: {
            type: Boolean
        },
        textColor: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const isButton = toRef(props, 'isButton', false);
        const textColor = toRef(props, 'textColor');
        const { isToggled, onClick, onMouseLeave } = useVFavoriteToggle(emit);
        const format = (name, color)=>{
            if (!name) return '';
            return String(color ? `${name}-${color}` : name);
        };
        return (_ctx, _cache)=>isButton.value ? (_openBlock(), _createBlock(VButton, {
                key: 0,
                kind: _unref(ButtonKind).TRANSPARENT,
                "text-color": textColor.value,
                class: _normalizeClass({
                    [_ctx.$style['favorite-toggle']]: true,
                    [_ctx.$style['favorite-toggle--button']]: true,
                    [_ctx.$style['favorite-toggle--favorite']]: !!_ctx.isFavorite,
                    [_ctx.$style['favorite-toggle--pending']]: !!_ctx.isOnlyPending,
                    [_ctx.$style['favorite-toggle--background']]: !!_ctx.hasBackground,
                    [_ctx.$style['favorite-toggle--colored']]: !_ctx.hideColors,
                    [_ctx.$style['favorite-toggle--opacity']]: _ctx.hasOpacity && !_ctx.isFavorite,
                    [_ctx.$style['favorite-toggle--toggled']]: _unref(isToggled),
                    [_ctx.$style['favorite-toggle--no-default-color']]: !!textColor.value
                }),
                onClick: _unref(onClick),
                onMouseleave: _unref(onMouseLeave)
            }, {
                icon: _withCtx(()=>[
                        _createVNode(_unref(VIcon), _mergeProps(_ctx.inactiveIcon, {
                            class: _ctx.$style['favorite-toggle__inactive-icon']
                        }), null, 16, [
                            "class"
                        ]),
                        _createVNode(_unref(VIcon), _mergeProps(_ctx.activeIcon, {
                            class: [
                                _ctx.$style[`favorite-toggle__active-icon-name-${format(_ctx.activeIcon?.name, _ctx.activeIconColor)}`],
                                _ctx.$style['favorite-toggle__active-icon']
                            ]
                        }), null, 16, [
                            "class"
                        ])
                    ]),
                _: 1
            }, 8, [
                "kind",
                "text-color",
                "class",
                "onClick",
                "onMouseleave"
            ])) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass({
                    [_ctx.$style['favorite-toggle']]: true,
                    [_ctx.$style['favorite-toggle--favorite']]: !!_ctx.isFavorite,
                    [_ctx.$style['favorite-toggle--pending']]: !!_ctx.isOnlyPending,
                    [_ctx.$style['favorite-toggle--background']]: !!_ctx.hasBackground,
                    [_ctx.$style['favorite-toggle--colored']]: !_ctx.hideColors,
                    [_ctx.$style['favorite-toggle--opacity']]: _ctx.hasOpacity && !_ctx.isFavorite,
                    [_ctx.$style['favorite-toggle--toggled']]: _unref(isToggled),
                    [_ctx.$style['favorite-toggle--no-default-color']]: !!textColor.value
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onClick) && _unref(onClick)(...args);
                }),
                onMouseleave: _cache[1] || (_cache[1] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onMouseLeave) && _unref(onMouseLeave)(...args);
                })
            }, [
                _createVNode(_unref(VIcon), _mergeProps(_ctx.inactiveIcon, {
                    class: _ctx.$style['favorite-toggle__inactive-icon']
                }), null, 16, [
                    "class"
                ]),
                _createVNode(_unref(VIcon), _mergeProps(_ctx.activeIcon, {
                    class: [
                        _ctx.$style[`favorite-toggle__active-icon-name-${format(_ctx.activeIcon?.name, _ctx.activeIconColor)}`],
                        _ctx.$style['favorite-toggle__active-icon']
                    ]
                }), null, 16, [
                    "class"
                ])
            ], 34));
    }
});
