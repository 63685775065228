import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { PostMessageEvent } from '@leon-hub/postmessage-bus';
let ReCaptchaPostMessageEvent = class ReCaptchaPostMessageEvent extends PostMessageEvent {
};
_define_property(ReCaptchaPostMessageEvent, "verify", new PostMessageEvent('captcha-verify'));
_define_property(ReCaptchaPostMessageEvent, "expired", new PostMessageEvent('captcha-expired'));
_define_property(ReCaptchaPostMessageEvent, "error", new PostMessageEvent('captcha-error'));
_define_property(ReCaptchaPostMessageEvent, "reset", new PostMessageEvent('captcha-reset'));
_define_property(ReCaptchaPostMessageEvent, "execute", new PostMessageEvent('captcha-execute'));
_define_property(ReCaptchaPostMessageEvent, "wrapperState", new PostMessageEvent('captcha-wrapper-state'));
export { ReCaptchaPostMessageEvent as default };
