import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onActivated, onDeactivated } from 'vue';
import { VIcon } from '@components/v-icon';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import { ShowcaseNavigationItemType } from 'web/src/modules/home/submodules/showcase/enums';
import useShowcaseNavigationComposable from './composables/useShowcaseNavigationComposable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ShowcaseNavigationRouteComponent',
    props: {
        isCustomHomepage: {
            type: Boolean
        }
    },
    setup (__props) {
        const { items, handleNavigationItemClickAnalytics, resumeSyncState, pauseSyncState } = useShowcaseNavigationComposable();
        onActivated(resumeSyncState);
        onDeactivated(pauseSyncState);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("nav", {
                class: _normalizeClass({
                    [_ctx.$style['showcase-navigation']]: true,
                    [_ctx.$style['showcase-navigation--custom']]: _ctx.isCustomHomepage
                })
            }, [
                _createVNode(_unref(VSwiper), {
                    "is-scroll-snap-enabled": ""
                }, _createSlots({
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(items), (param)=>{
                                let { key, icon, location, className, name, isHighlighted, isLandingEuroRu, routerLinkThemeStyle } = param;
                                return _openBlock(), _createBlock(VSwiperSlide, {
                                    key: key,
                                    class: _normalizeClass({
                                        [_ctx.$style['showcase-navigation__slide']]: true,
                                        [_ctx.$style['showcase-navigation__slide--custom']]: _ctx.isCustomHomepage
                                    })
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(VRouterLink, {
                                                style: _normalizeStyle(routerLinkThemeStyle),
                                                class: _normalizeClass({
                                                    [_ctx.$style['showcase-navigation__item']]: true,
                                                    [_ctx.$style[`showcase-navigation__item--${className}`]]: !!className,
                                                    [_ctx.$style['showcase-navigation__item--custom']]: _ctx.isCustomHomepage,
                                                    [_ctx.$style['showcase-navigation__item--stripe-animated']]: isHighlighted,
                                                    [_ctx.$style['showcase-navigation__item--landing']]: isLandingEuroRu
                                                }),
                                                to: location,
                                                draggable: false,
                                                onClick: ($event)=>_unref(handleNavigationItemClickAnalytics)(className || '')
                                            }, {
                                                default: _withCtx(()=>[
                                                        icon && icon.is === _unref(ShowcaseNavigationItemType).Icon ? (_openBlock(), _createBlock(_unref(VIcon), {
                                                            key: 0,
                                                            name: icon.props.name,
                                                            size: icon.props.size,
                                                            class: _normalizeClass(_ctx.$style['showcase-navigation__item-icon']),
                                                            draggable: false
                                                        }, null, 8, [
                                                            "name",
                                                            "size",
                                                            "class"
                                                        ])) : icon && icon.is === _unref(ShowcaseNavigationItemType).Image ? (_openBlock(), _createBlock(VImage, {
                                                            key: 1,
                                                            src: icon.props.src,
                                                            alt: icon.props.alt,
                                                            class: _normalizeClass(_ctx.$style['showcase-navigation__item-icon']),
                                                            draggable: false,
                                                            style: _normalizeStyle(icon.props.style)
                                                        }, null, 8, [
                                                            "src",
                                                            "alt",
                                                            "class",
                                                            "style"
                                                        ])) : (_openBlock(), _createElementBlock("div", {
                                                            key: 2,
                                                            class: _normalizeClass(_ctx.$style['showcase-navigation__item-icon'])
                                                        }, null, 2)),
                                                        name ? (_openBlock(), _createElementBlock("span", {
                                                            key: 3,
                                                            class: _normalizeClass(_ctx.$style['showcase-navigation__item-name'])
                                                        }, _toDisplayString(name), 3)) : _createCommentVNode("", true)
                                                    ]),
                                                _: 2
                                            }, 1032, [
                                                "style",
                                                "class",
                                                "to",
                                                "onClick"
                                            ])
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "class"
                                ]);
                            }), 128))
                        ]),
                    _: 2
                }, [
                    {
                        name: "pagination-footer",
                        fn: _withCtx(()=>[
                                _createVNode(SwiperNavigationButtons)
                            ]),
                        key: "0"
                    }
                ]), 1024)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ShowcaseNavigationRouteComponent'
                ],
                [
                    _vShow,
                    _unref(items).length > 0
                ]
            ]);
        };
    }
});
