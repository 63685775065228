import { defineStore } from 'pinia';
import { computed, toRef, watch } from 'vue';
import { logger } from '@leon-hub/logging';
import { SlipTypeId } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useCashoutStore } from 'web/src/modules/cashout/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useBalance } from 'web/src/modules/customer/store/composables';
import useIsIdentifiedUser from 'web/src/modules/identification/composables/useIsIdentifiedUser';
import { useMaxPrize } from 'web/src/modules/slip/submodules/slip-root/composables/useMaxPrize';
import { getEventFromSlipEntryId, getSlipEntryId } from '../../../utils';
import { AddSlipEntriesError, BetSlipResultState, BetSlipWarningTypes, LeaveSlipResultChoice, TopLevelTabs, BetSlipMainButtonMode } from '../../../enums';
import { useSlipViewSettingsStore } from '../../slip-view-settings/store';
import { usePlaceBetStore } from '../../place-bet/store';
import { useSlipSelectedEntriesStore } from '../../slipSelectedEntries/store';
import { usePendingBetsStore } from '../../pending-bets/store';
import { useSlipInfoStore } from '../../slip-info/store';
import { useStakeInputStore } from '../../stake-input/store';
import { useFreebetStore } from '../../freebet/store';
import { useMultiSinglesStore } from '../../multi-singles/store';
import { getSummaryStatus, getMainButtonMode } from '../utils';
const useSlipRootStore = defineStore('slip-root-store', ()=>{
    const bus = useEventsBus();
    const { isLoggedIn } = useIsLoggedIn();
    const { balance } = useBalance();
    const { isIdentifiedUser } = useIsIdentifiedUser();
    const multiSinglesStore = useMultiSinglesStore();
    const { resetMultiSinglesFocusOnClose } = multiSinglesStore;
    const isMultiSinglesEnabled = toRef(multiSinglesStore, 'isMultiSinglesEnabled');
    const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');
    const freebetStore = useFreebetStore();
    const { clearFreeBet } = freebetStore;
    const freeBetSwitchChecked = toRef(freebetStore, 'freeBetSwitchChecked');
    const currentFreeBet = toRef(freebetStore, 'currentFreeBet');
    const selectedFreeBetId = toRef(freebetStore, 'selectedFreeBetId');
    const stakeInputStore = useStakeInputStore();
    const { setStakeInputIsFocused, fixStakeRange } = stakeInputStore;
    const cashoutStore = useCashoutStore();
    const { closeSlipOnCashout } = cashoutStore;
    toRef(cashoutStore, 'slipCashoutInProgress');
    const slipInfoStore = useSlipInfoStore();
    const { removeBetEvents, setClearBetListScheduled, setBetMode, addToSlip, removeBetFromList, removeBetClick, onAddingToSlipError, acceptSlipChanges, matchSlipType } = slipInfoStore;
    const clearBetListScheduled = toRef(slipInfoStore, 'clearBetListScheduled');
    const isPendingChanges = toRef(slipInfoStore, 'isPendingChanges');
    const betMode = toRef(slipInfoStore, 'betMode');
    const hasLimitError = toRef(slipInfoStore, 'hasLimitError');
    const maxSlipSize = toRef(slipInfoStore, 'maxSlipSize');
    const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');
    const isBetModeMatchesSlipTypes = toRef(slipInfoStore, 'isBetModeMatchesSlipTypes');
    const mustAcceptChanges = toRef(slipInfoStore, 'mustAcceptChanges');
    const multiSinglesTotalPrice = toRef(slipInfoStore, 'multiSinglesTotalPrice');
    const multiSinglesAvailableForBet = toRef(slipInfoStore, 'multiSinglesAvailableForBet');
    const isMultiSinglesMode = toRef(slipInfoStore, 'isMultiSinglesMode');
    const selectedStakeValue = toRef(slipInfoStore, 'selectedStakeValue');
    const maxStake = toRef(slipInfoStore, 'maxStake');
    const minStake = toRef(slipInfoStore, 'minStake');
    const marketsChanged = toRef(slipInfoStore, 'marketsChanged');
    const pricesChanged = toRef(slipInfoStore, 'pricesChanged');
    const totalOdds = toRef(slipInfoStore, 'totalOdds');
    const isSyncInProgress = toRef(slipInfoStore, 'isSyncInProgress');
    const placeBetStore = usePlaceBetStore();
    const { setResultState, resetSlipResultState, resetResultErrorState, setLeaveBetResultChoice, placeBet } = placeBetStore;
    const resultState = toRef(placeBetStore, 'resultState');
    const leaveBetResultChoice = toRef(placeBetStore, 'leaveBetResultChoice');
    const slipUnsettledSingles = toRef(placeBetStore, 'slipUnsettledSingles');
    const slipViewSettingsStore = useSlipViewSettingsStore();
    const { setIsFullyVisibleOnMobile, stopLeaveEnterAnimation, handleSlipVisibilityChange, selectTopTab, setSlipFullHeight, setSlipSettingsVisibility, setClearBetListWarnVisibility, hideSlipFromBookingCodeOverlay } = slipViewSettingsStore;
    const isHiddenOnMobile = toRef(slipViewSettingsStore, 'isHiddenOnMobile');
    const selectedEntriesIds = toRef(useSlipSelectedEntriesStore(), 'selectedEntriesIds');
    const pendingBetsCount = toRef(usePendingBetsStore(), 'pendingBetsCount');
    const { maxPrize } = useMaxPrize({
        isMultiSinglesMode,
        totalOdds,
        selectedStakeValue,
        currentFreeBet,
        multiSinglesAvailableForBet,
        sameStakeForSingles
    });
    // getters
    const isPendingState = computed(()=>resultState.value === BetSlipResultState.PENDING || resultState.value === BetSlipResultState.WAIT_FOR_RETRY || isPendingChanges.value);
    const notEnoughBalance = computed(()=>{
        if (!isLoggedIn.value || freeBetSwitchChecked.value) return false;
        const stake = isMultiSinglesMode.value ? multiSinglesTotalPrice.value : selectedStakeValue.value;
        return stake > balance.value;
    });
    const stakeAboveMaximum = computed(()=>!!maxStake.value && selectedStakeValue.value > maxStake.value);
    const stakeBelowMinimum = computed(()=>!!minStake.value && selectedStakeValue.value < minStake.value);
    const stakeIsOutOfRange = computed(()=>stakeAboveMaximum.value || stakeBelowMinimum.value);
    const summaryStatus = computed(()=>getSummaryStatus({
            resultState: resultState.value,
            stakeAboveMaximum: stakeAboveMaximum.value,
            notEnoughBalance: notEnoughBalance.value,
            stakeBelowMinimum: stakeBelowMinimum.value,
            marketsChanged: marketsChanged.value,
            pricesChanged: pricesChanged.value,
            hasLimitError: hasLimitError.value,
            isFreeBet: !!currentFreeBet.value
        }));
    const mainButtonMode = computed(()=>getMainButtonMode({
            resultState: resultState.value,
            isPendingChanges: isPendingChanges.value,
            isLoggedIn: isLoggedIn.value,
            notEnoughBalance: notEnoughBalance.value,
            mustAcceptChanges: mustAcceptChanges.value,
            stakeIsOutOfRange: stakeIsOutOfRange.value,
            isBetModeMatchesSlipTypes: isBetModeMatchesSlipTypes.value,
            identificationRequired: !isIdentifiedUser.value,
            isFreeBet: !!currentFreeBet.value
        }));
    const placeBetButtonDisabled = computed(()=>{
        const readyToPlaceBet = mainButtonMode.value === BetSlipMainButtonMode.READY_TO_PLACE_BET;
        const emptyFreeBet = freeBetSwitchChecked.value && !selectedFreeBetId.value;
        if (emptyFreeBet && readyToPlaceBet) return true;
        if (mainButtonMode.value === BetSlipMainButtonMode.UNABLE_TO_APPLY_FREEBET) return true;
        if (readyToPlaceBet && isMultiSinglesMode.value && (!multiSinglesAvailableForBet.value.length || !multiSinglesTotalPrice.value)) return true;
        return (readyToPlaceBet || mainButtonMode.value === BetSlipMainButtonMode.MUST_ACCEPT_CHANGES) && isSyncInProgress.value;
    });
    // actions
    const switchToMyBets = ()=>{
        if (resultState.value === BetSlipResultState.SUCCESS) removeBetEvents();
        setResultState(BetSlipResultState.INITIAL);
        if (isHiddenOnMobile.value) handleSlipVisibilityChange(true);
        selectTopTab(TopLevelTabs.MY_BETS);
    };
    const handleDisplayAnimationEnd = function() {
        let isVisible = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        stopLeaveEnterAnimation();
        setIsFullyVisibleOnMobile(isVisible);
        hideSlipFromBookingCodeOverlay();
        if (!isVisible) {
            if (resultState.value === BetSlipResultState.SUCCESS) resetSlipResultState();
            if (resultState.value === BetSlipResultState.ERROR) resetResultErrorState();
            if (clearBetListScheduled.value) {
                removeBetEvents({
                    leaveBetResultChoice: leaveBetResultChoice.value,
                    slipUnsettledSingles: slipUnsettledSingles.value
                });
                setLeaveBetResultChoice(LeaveSlipResultChoice.CLEAR_ALL);
                setClearBetListScheduled(false);
            }
            setSlipSettingsVisibility(false);
            setClearBetListWarnVisibility(false);
            setStakeInputIsFocused(false);
            resetMultiSinglesFocusOnClose();
        }
    };
    const handleSlipClose = ()=>{
        "1";
        handleDisplayAnimationEnd(false);
    };
    /** @see handleDisplayAnimationEnd */ const clearBetList = ()=>{
        logger.info('The betslip has been cleared');
        "1";
        removeBetEvents();
        setClearBetListWarnVisibility(false);
    };
    const handleRunnerClick = (item)=>{
        // close shared slip overlay if visible
        hideSlipFromBookingCodeOverlay();
        if (isPendingState.value || !item) return;
        if (resultState.value === BetSlipResultState.SUCCESS) resetSlipResultState();
        const id = getSlipEntryId(item);
        const isAlreadyInSlip = selectedEntriesIds.value.includes(id);
        if (isAlreadyInSlip) {
            removeBetClick(id);
            return;
        }
        const event = getEventFromSlipEntryId(id);
        const selectedEntryOfSameEvent = selectedEntriesIds.value.find((identifier)=>identifier.startsWith(`${event}`));
        if (selectedEntryOfSameEvent) {
            if (isMultiSinglesEnabled.value && betMode.value !== SlipTypeId.SINGLE) setBetMode(SlipTypeId.SINGLE);
            if (!isMultiSinglesEnabled.value) // replace selected runner
            removeBetFromList(selectedEntryOfSameEvent);
        }
        if (hasLimitError.value) {
            onAddingToSlipError({
                message: '',
                entriesError: AddSlipEntriesError.LIMIT
            });
            return;
        }
        if (maxSlipSize.value && slipEventsCount.value >= maxSlipSize.value) {
            bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, {
                type: BetSlipWarningTypes.SLIP_LIMIT_REACHED
            });
            return;
        }
        addToSlip(item);
    };
    const handleMainButtonClick = ()=>{
        if (!isBetModeMatchesSlipTypes.value) {
            handleSlipClose();
            return;
        }
        if (mustAcceptChanges.value) {
            acceptSlipChanges();
            return;
        }
        if (stakeIsOutOfRange.value) {
            fixStakeRange({
                maxStake: maxStake.value,
                minStake: minStake.value,
                stakeBelowMinimum: stakeBelowMinimum.value
            });
            return;
        }
        placeBet();
    };
    const openSlipContextRelatively = ()=>{
        if (!slipEventsCount.value && pendingBetsCount.value) selectTopTab(TopLevelTabs.MY_BETS);
        else matchSlipType();
        handleSlipVisibilityChange(true);
    };
    const handleUserLogout = ()=>{
        setStakeInputIsFocused(false);
        resetSlipResultState(false);
        selectTopTab(TopLevelTabs.SLIP);
        setSlipSettingsVisibility(false);
        clearFreeBet();
    };
    watch(isLoggedIn, (newValue)=>{
        if (!newValue) handleUserLogout();
    });
    bus.on(BusEvent.CLAIM_TO_SLIP_CLOSE, ()=>{
        handleSlipClose();
    });
    return {
        switchToMyBets,
        handleSlipClose,
        clearBetList,
        handleRunnerClick,
        handleMainButtonClick,
        handleDisplayAnimationEnd,
        openSlipContextRelatively,
        placeBetButtonDisabled,
        mainButtonMode,
        summaryStatus,
        maxPrize
    };
});
export default useSlipRootStore;
