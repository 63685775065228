import { Storage } from '@leon-hub/storage';
export class LexisNexisProfiledStorage extends Storage {
    async isProfiled() {
        const record = await this.get();
        if (record.exists) return void 0 !== record.value;
        return false;
    }
    async isProfiledSuccessfully() {
        const record = await this.get();
        if (record.exists) return true === record.value;
        return false;
    }
    setProfiledStatus(status) {
        return this.set(status);
    }
}
