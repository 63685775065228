import { _ as _define_property } from "@swc/helpers/_/_define_property";
export var DecodeSymbol;
var DecodeSymbol1;
(DecodeSymbol1 = DecodeSymbol || (DecodeSymbol = {}))["DEFAULT"] = "*", DecodeSymbol1["BULL"] = "•", DecodeSymbol1["ZERO"] = "0";
export class DecodeValue {
    toDecodedString() {
        return this.value.replace(this.regexp, (foundSymbols)=>{
            let symbolsCount = foundSymbols.length;
            let result = '';
            while(symbolsCount){
                result += this.symbol;
                symbolsCount -= 1;
            }
            return result;
        });
    }
    constructor(value, props){
        _define_property(this, "value", void 0);
        _define_property(this, "regex", `[亜哀挨愛曖悪握圧扱宛嵐安案暗以衣位囲医依委威為畏胃尉異移萎偉椅彙意違維慰遺緯
                            域育一壱逸茨芋引印因咽姻員院淫陰飲隠韻右宇羽雨唄鬱畝浦運雲永泳英映栄営詠影鋭衛易疫益液駅
                            悦越謁閲円延沿炎怨宴媛援園煙猿遠鉛塩]+`);
        _define_property(this, "regexp", new RegExp(this.regex, 'g'));
        _define_property(this, "symbol", "*");
        this.value = value;
        if (props?.symbol) this.symbol = props.symbol;
        if (props?.regex) this.regex = props.regex;
    }
}
