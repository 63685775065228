export var PromotionItemType;
(PromotionItemType || (PromotionItemType = {}))["PROMOTION"] = "PROMOTION";
export var PromotionItemIds;
(PromotionItemIds || (PromotionItemIds = {}))["BONUS_WALLET"] = "bonuswallet";
export var PromotionCustomerRatingMethodType;
var PromotionCustomerRatingMethodType1;
(PromotionCustomerRatingMethodType1 = PromotionCustomerRatingMethodType || (PromotionCustomerRatingMethodType = {}))["INCREASE"] = "increase", PromotionCustomerRatingMethodType1["DECREASE"] = "decrease";
export var PromotionsType;
var PromotionsType1;
(PromotionsType1 = PromotionsType || (PromotionsType = {}))["PROMO"] = "promo", PromotionsType1["TOURNAMENTS"] = "tournaments";
export var CategoriesTabs;
var CategoriesTabs1;
(CategoriesTabs1 = CategoriesTabs || (CategoriesTabs = {}))["ALL"] = "all", CategoriesTabs1["ARCHIVE"] = "archive";
