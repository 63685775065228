import noop from 'lodash/noop';
export default function doAfterScrollVisibilityCheck(element, options) {
    const defaultOptions = {
        callbackIfVisible: noop,
        callbackIfHidden: noop,
        callbackIfUnavailable: noop
    };
    const combinedOptions = {
        ...defaultOptions,
        ...options
    };
    let observer = null;
    if ('function' == typeof window.IntersectionObserver) {
        const disconnect = ()=>{
            observer?.disconnect();
            observer = null;
        };
        observer = new IntersectionObserver((entries)=>{
            const visible = entries[0]?.isIntersecting;
            if (visible) combinedOptions.callbackIfVisible?.();
            else combinedOptions.callbackIfHidden?.();
            disconnect();
        }, combinedOptions.observerOptions);
        observer.observe(element);
    } else combinedOptions.callbackIfUnavailable?.();
}
