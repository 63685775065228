import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ref, toRef, watch } from 'vue';
import { onComponentActivated, onComponentDeactivated, useControllableResizeObserver } from '@leon-hub/vue-utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResizeObserver',
    props: {
        disabled: {
            type: Boolean
        }
    },
    emits: [
        "stopped",
        "resize"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const isDisabled = toRef(()=>props.disabled);
        const element = ref();
        function emitResize() {
            const height = element.value?.offsetHeight;
            const width = element.value?.offsetWidth;
            emit('resize', {
                height,
                width
            });
        }
        const { start, stop, isEnabled } = useControllableResizeObserver(element, emitResize);
        function applyDisabledState() {
            if (isDisabled.value) stop();
            else start();
        }
        watch(isDisabled, applyDisabledState);
        watch(isEnabled, (value)=>{
            if (value) return;
            emit('stopped');
        });
        onComponentActivated(applyDisabledState);
        onComponentDeactivated(stop);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "element",
                ref: element
            }, [
                _renderSlot(_ctx.$slots, "default")
            ])), [
                [
                    _directive_auto_id,
                    'ResizeObserver'
                ]
            ]);
        };
    }
});
