import { ApiErrorCode } from '@leon-hub/api';
import { AppError } from '@leon-hub/app-errors';
export class ApplyEventRunnersOddsChangesError extends AppError {
    constructor(options){
        super({
            silent: true,
            ...options,
            code: ApiErrorCode.API_UNEXPECTED_ERROR,
            message: `Sportline: ${options?.message || 'Unexpected apply odds changes error'}`
        });
    }
}
