import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconSize, SportIconName } from '@leon-hub/icons';
import { bannerVersionV3 } from '@leon-hub/api-sdk';
import { VIcon } from '@components/v-icon';
import PrimaryMarket from 'web/src/modules/sportline/views/primary-market/PrimaryMarket.vue';
import { useSportlineEventDateTime } from 'web/src/modules/sportline/composables/sportline-event';
import InfoCountdown from 'web/src/modules/sportline/submodules/event-details/components/info-countdown/InfoCountdown.vue';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import HeadlineLiveTimeInfo from 'web/src/modules/sportline/submodules/event-details/views/headline-info/default/HeadlineLiveTimeInfo.vue';
import { useSportEventInfo } from '../composables/useSportEventInfo';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportEventInfo',
    props: {
        bannerId: {},
        groupId: {},
        isActive: {
            type: Boolean
        },
        bannerVersion: {}
    },
    setup (__props) {
        const props = __props;
        const { sportlineEvent, market } = useSportEventInfo(props);
        const { matchProgress } = useLiveMatchProgress({
            sportEvent: sportlineEvent
        });
        const { stage } = useLiveStatus(matchProgress);
        const { kickoff } = useSportlineEventDateTime({
            sportlineEvent
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(sportlineEvent) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['headline-banner-sport']]: true,
                    [_ctx.$style['headline-banner-sport--v3']]: props.bannerVersion === _unref(bannerVersionV3)
                })
            }, [
                _createCommentVNode("", true),
                props.bannerVersion === _unref(bannerVersionV3) ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['headline-banner-sport__header'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['headline-banner-sport__competitor-text'])
                    }, _toDisplayString(_unref(sportlineEvent).competitors[0]?.name) + " - " + _toDisplayString(_unref(sportlineEvent).competitors[1]?.name), 3)
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['headline-banner-sport__content']]: true,
                        [_ctx.$style['headline-banner-sport__content--v3']]: props.bannerVersion === _unref(bannerVersionV3)
                    })
                }, [
                    _unref(matchProgress) || _unref(kickoff) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['headline-banner-sport__details'])
                    }, [
                        _unref(matchProgress) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['headline-banner-sport__score'])
                            }, [
                                _createTextVNode(_toDisplayString(_unref(matchProgress).totalScore.host), 1),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['headline-banner-sport__divider'])
                                }, ":", 2),
                                _createTextVNode(_toDisplayString(_unref(matchProgress).totalScore.guest), 1)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['headline-banner-sport__stage'])
                            }, [
                                _createTextVNode(_toDisplayString(_unref(stage)) + " ", 1),
                                _createVNode(HeadlineLiveTimeInfo, {
                                    "sportline-event": _unref(sportlineEvent)
                                }, null, 8, [
                                    "sportline-event"
                                ])
                            ], 2)
                        ], 64)) : (_openBlock(), _createBlock(InfoCountdown, {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['headline-banner-sport__countdown']),
                            kickoff: _unref(kickoff),
                            "is-narrow": props.bannerVersion === _unref(bannerVersionV3)
                        }, null, 8, [
                            "class",
                            "kickoff",
                            "is-narrow"
                        ]))
                    ], 2)) : _createCommentVNode("", true),
                    props.bannerVersion !== _unref(bannerVersionV3) && _unref(sportlineEvent).hasZeroMargin ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass({
                            [_ctx.$style['headline-banner-sport__special']]: true,
                            [_ctx.$style['headline-banner-sport__special--offer']]: !!_unref(matchProgress),
                            [_ctx.$style['headline-banner-sport__special--highest-odds']]: !_unref(matchProgress)
                        })
                    }, [
                        _unref(matchProgress) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SPORT_BANNER_SPECIAL_OFFER')), 1)
                        ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: _unref(SportIconName).SPORTLINE_HIGHEST_ODDS,
                                size: _unref(IconSize).SIZE_12,
                                class: _normalizeClass(_ctx.$style['headline-banner-sport__special-icon'])
                            }, null, 8, [
                                "name",
                                "size",
                                "class"
                            ]),
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SPORT_BANNER_HIGHEST_ODDS')), 1)
                        ], 64))
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['headline-banner-sport__footer'])
                    }, [
                        props.bannerVersion !== _unref(bannerVersionV3) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass([
                                _ctx.$style['headline-banner-sport__competitor'],
                                _ctx.$style['headline-banner-sport__competitor--left']
                            ])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['headline-banner-sport__competitor-text'])
                            }, _toDisplayString(_unref(sportlineEvent).competitors[0]?.name), 3)
                        ], 2)) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['headline-banner-sport__market'])
                        }, [
                            _unref(market) ? (_openBlock(), _createBlock(PrimaryMarket, {
                                key: 0,
                                market: _unref(market),
                                "sport-event-details": _unref(sportlineEvent),
                                "double-gap": "",
                                "is-primary-market-v2": props.bannerVersion === _unref(bannerVersionV3)
                            }, null, 8, [
                                "market",
                                "sport-event-details",
                                "is-primary-market-v2"
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        props.bannerVersion !== _unref(bannerVersionV3) ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass([
                                _ctx.$style['headline-banner-sport__competitor'],
                                _ctx.$style['headline-banner-sport__competitor--right']
                            ])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['headline-banner-sport__competitor-text'])
                            }, _toDisplayString(_unref(sportlineEvent).competitors[1]?.name), 3)
                        ], 2)) : _createCommentVNode("", true)
                    ], 2),
                    props.bannerVersion === _unref(bannerVersionV3) && _unref(sportlineEvent).hasZeroMargin ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass({
                            [_ctx.$style['headline-banner-sport__special']]: true,
                            [_ctx.$style['headline-banner-sport__special--offer']]: !!_unref(matchProgress),
                            [_ctx.$style['headline-banner-sport__special--highest-odds']]: !_unref(matchProgress)
                        })
                    }, [
                        _unref(matchProgress) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SPORT_BANNER_SPECIAL_OFFER')), 1)
                        ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: _unref(SportIconName).SPORTLINE_HIGHEST_ODDS,
                                size: _unref(IconSize).SIZE_12,
                                class: _normalizeClass(_ctx.$style['headline-banner-sport__special-icon'])
                            }, null, 8, [
                                "name",
                                "size",
                                "class"
                            ]),
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SPORT_BANNER_HIGHEST_ODDS')), 1)
                        ], 64))
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportEventInfo'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
