import { BetlineSportEventStatus } from 'web/src/modules/sportline/enums/rest';
import { SportEventStatus } from 'web/src/modules/sportline/enums';
// @TODO check status logic, choose one realisation
export function createEventStatusBasedOnBetlineStatus(response) {
    if (response.status === BetlineSportEventStatus.OPEN) return SportEventStatus.Open;
    if (response.status === BetlineSportEventStatus.SUSPENDED) return SportEventStatus.Suspended;
    return SportEventStatus.Closed;
}
export function createEventStatusBasedOnOpenState(response) {
    return response.open ? SportEventStatus.Open : SportEventStatus.Suspended;
}
