import { computed, ref, toRef } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useParseSportlineSettingsRef, useIsZeroMarginEnabledRef } from 'web/src/modules/sportline/composables/settings';
import { useCountSportlineUpdateTimeout, useZeroMarginSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { BetlineReplaceResponse, SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
function defaultPrepareEventsList(fullList) {
    return fullList;
}
export default function useSportlineZeroMarginSportsComposable(props) {
    const { selectedSportFamily } = props;
    const prepareEventsList = props.prepareEventsList ?? defaultPrepareEventsList;
    const apiService = useSportlineApiService();
    const broadcastSelected = useBroadcastSelected();
    const sportsTreeStore = useSportsTreeStore();
    const { timeout: countUpdateTimeout } = useCountSportlineUpdateTimeout();
    const { timeout: zeroMarginUpdateTimeout } = useZeroMarginSportlineUpdateTimeout();
    const isZeroMarginEnabled = useIsZeroMarginEnabledRef();
    const parseSportlineSettings = useParseSportlineSettingsRef();
    const isCountersLoaded = toRef(sportsTreeStore, 'isCountersLoaded');
    const hasZeroMarginInTheTree = toRef(sportsTreeStore, 'hasZeroMargin');
    const rawEventsResponse = ref(null);
    const isZeroMarginEventsTabSelected = computed(()=>isSportFamilyEquals(selectedSportFamily.value, CustomFilter.ZeroMargin));
    const lastUpdate = new BackgroundUpdateStopwatch({
        zeroMarginEvents: async (param)=>{
            let { silent } = param;
            if (!isZeroMarginEnabled.value) return;
            if (!isZeroMarginEventsTabSelected.value) return;
            const response = await apiService.loadZeroMarginEvents({
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                vTag: (rawEventsResponse.value || void 0)?.vtag,
                silent
            });
            rawEventsResponse.value = response ? BetlineReplaceResponse.unknownResponseToSportEventsResponse(response) : response;
            broadcastSelected.updateDataInStorageByResponse({
                response
            });
            lastUpdate.update('zeroMarginEvents');
        }
    });
    const countersLastUpdate = new BackgroundUpdateStopwatch({
        zeroMarginCounters: async (param)=>{
            let { silent } = param;
            if (!isZeroMarginEnabled.value) return;
            if (isZeroMarginEventsTabSelected.value) return;
            await sportsTreeStore.reloadZeroMarginCounters({
                silent
            });
            countersLastUpdate.update('zeroMarginCounters');
        }
    });
    const rawZeroMarginEventsList = computed(()=>isZeroMarginEnabled.value && rawEventsResponse.value ? new SportlineFactory(rawEventsResponse.value, parseSportlineSettings.value).build() : null);
    const isZeroMarginEventsTabAvailable = computed(()=>hasZeroMarginInTheTree.value || !!rawZeroMarginEventsList.value && rawZeroMarginEventsList.value.length > 0);
    const isZeroMarginEventsListEmpty = computed(()=>!rawZeroMarginEventsList.value?.length);
    const zeroMarginEventsList = computed(()=>prepareEventsList(rawZeroMarginEventsList.value ?? []));
    /** current selected tab ready */ const isReady = computed(()=>{
        if (!isZeroMarginEnabled.value) return true;
        if (!isZeroMarginEventsTabSelected.value) return isCountersLoaded.value;
        return null !== rawEventsResponse.value;
    });
    const isListLoaded = computed(()=>{
        if (!isZeroMarginEnabled.value) return true;
        return null !== rawEventsResponse.value;
    });
    /** any zero margin data loaded (used to first loading page indication) */ const isLoaded = computed(()=>{
        if (!isZeroMarginEnabled.value) return true;
        return isCountersLoaded.value || isListLoaded.value;
    });
    const { initialRequests: initialListRequests, syncState: syncListState, syncBackgroundRequests: syncListBackgroundRequests, setBackgroundUpdateEnabled: setListBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: zeroMarginUpdateTimeout
    });
    const { initialRequests: initialCountersRequests, syncState: syncCountersState, syncBackgroundRequests: syncCountersBackgroundRequests, setBackgroundUpdateEnabled: setCountersBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate: countersLastUpdate,
        updateInterval: countUpdateTimeout
    });
    const { onInit: sportlineEventsChangesListenersOnInit } = useSportlineEventsChangesListeners({
        setResponse (response) {
            rawEventsResponse.value = response;
        },
        getResponse () {
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            return rawEventsResponse.value || null;
        }
    });
    async function initialRequests() {
        if (!isZeroMarginEnabled.value) return;
        // we can run requests sequentially because they are mutually exclusive
        await initialCountersRequests();
        await initialListRequests();
    }
    async function syncBackgroundRequests(options) {
        if (!isZeroMarginEnabled.value) return;
        // we can run requests sequentially because they are mutually exclusive
        await syncCountersBackgroundRequests(options);
        await syncListBackgroundRequests(options);
    }
    async function setBackgroundUpdateEnabled(value) {
        if (!isZeroMarginEnabled.value) return;
        // we can run requests sequentially because they are mutually exclusive
        await setCountersBackgroundUpdateEnabled(value);
        await setListBackgroundUpdateEnabled(value);
    }
    function onInit() {
        sportlineEventsChangesListenersOnInit();
        useSyncState(async (silent)=>{
            if (!isZeroMarginEnabled.value) return;
            await syncListState({
                silent
            });
        }, zeroMarginUpdateTimeout);
        useSyncState(async (silent)=>{
            if (!isZeroMarginEnabled.value) return;
            await syncCountersState({
                silent
            });
        }, countUpdateTimeout);
    }
    return {
        isReady,
        isLoaded,
        isCountersLoaded,
        isListLoaded,
        isZeroMarginEnabled,
        isZeroMarginEventsTabSelected,
        zeroMarginEventsList,
        rawZeroMarginEventsList,
        isZeroMarginEventsTabAvailable,
        isZeroMarginEventsListEmpty,
        onInit,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
}
