import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id",
    "name",
    "disabled",
    "accept"
];
const _hoisted_2 = [
    "for"
];
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import InputButton from 'web/src/components/Input/components/InputButton/InputButton.vue';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import { useAutofocus, useHintParentProps } from '../../composables';
import useFileSingleStateless from './useFileSingleStateless';
import { resolveAcceptValue } from '../../utils';
import getInputIconSize from '../../utils/getInputIconSize';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FileSingleInputStateless',
    props: {
        inputId: {
            default: ''
        },
        fileName: {
            default: ''
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: ''
        },
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {}
    },
    emits: [
        "clear",
        "focus",
        "blur",
        "change"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { focusable, focus } = useAutofocus(props);
        const { isHover, isFocus, isEmpty, resetIndex, formattedFileName, fileExtension, onMouseenter, onMouseleave, onFocus, onBlur, onClear, onClick, onChange } = useFileSingleStateless(props, emits, focusable);
        const { hintProperties, hasError } = useHintParentProps(props);
        const acceptValue = resolveAcceptValue();
        // ok not to be reactive
        const iconSize = getInputIconSize(props.isLarge);
        __expose({
            focus
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), _mergeProps(_unref(hintProperties), {
                "input-id": _ctx.inputId,
                label: _ctx.label,
                disabled: _ctx.disabled,
                "is-focus": _unref(isFocus),
                "is-empty": _unref(isEmpty),
                "is-large": _ctx.isLarge,
                "clickable-suffix": !_unref(isEmpty),
                class: {
                    [_ctx.$style['file-single-input']]: true,
                    [_ctx.$style['file-single-input--error']]: _unref(hasError),
                    [_ctx.$style['file-single-input--focus']]: _unref(isFocus),
                    [_ctx.$style['file-single-input--empty']]: _unref(isEmpty),
                    [_ctx.$style['file-single-input--large']]: _ctx.isLarge
                }
            }), {
                input: _withCtx(()=>[
                        _createVNode(_unref(InputBorderWrapper), {
                            "is-focus": _unref(isFocus),
                            "is-disabled": _ctx.disabled,
                            "is-hover": _unref(isHover),
                            "has-error": _unref(hasError),
                            class: _normalizeClass(_ctx.$style['file-single-input__input-border-wrapper'])
                        }, {
                            default: _withCtx(()=>[
                                    (_openBlock(), _createElementBlock("input", {
                                        id: _ctx.inputId,
                                        ref_key: "focusable",
                                        ref: focusable,
                                        key: _unref(resetIndex),
                                        type: "file",
                                        name: _ctx.name,
                                        disabled: _ctx.disabled,
                                        accept: _unref(acceptValue),
                                        class: _normalizeClass(_ctx.$style['file-single-input__input']),
                                        onFocus: _cache[0] || (_cache[0] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onFocus) && _unref(onFocus)(...args);
                                        }),
                                        onBlur: _cache[1] || (_cache[1] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onBlur) && _unref(onBlur)(...args);
                                        }),
                                        onChange: _cache[2] || (_cache[2] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onChange) && _unref(onChange)(...args);
                                        }),
                                        onInput: _cache[3] || (_cache[3] = _withModifiers(()=>{}, [
                                            "stop",
                                            "prevent"
                                        ]))
                                    }, null, 42, _hoisted_1)),
                                    _createElementVNode("label", {
                                        for: _ctx.inputId,
                                        class: _normalizeClass([
                                            _ctx.$style['file-single-input__file-label'],
                                            _ctx.$style['file-single-input__file-label--icon-suffix']
                                        ]),
                                        onMouseenter: _cache[4] || (_cache[4] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onMouseenter) && _unref(onMouseenter)(...args);
                                        }),
                                        onMouseleave: _cache[5] || (_cache[5] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onMouseleave) && _unref(onMouseleave)(...args);
                                        }),
                                        onClick: _cache[6] || (_cache[6] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onClick) && _unref(onClick)(...args);
                                        })
                                    }, [
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['file-single-input__file-name'])
                                        }, _toDisplayString(_unref(formattedFileName) || _ctx.placeholder), 3),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['file-single-input__file-extension'])
                                        }, _toDisplayString(_unref(fileExtension)), 3)
                                    ], 42, _hoisted_2)
                                ]),
                            _: 1
                        }, 8, [
                            "is-focus",
                            "is-disabled",
                            "is-hover",
                            "has-error",
                            "class"
                        ])
                    ]),
                iconSuffix: _withCtx(()=>[
                        _unref(isEmpty) ? (_openBlock(), _createBlock(_unref(VIcon), {
                            key: 0,
                            "is-large": _ctx.isLarge,
                            size: _unref(iconSize),
                            name: _unref(IconName).CLIP
                        }, null, 8, [
                            "is-large",
                            "size",
                            "name"
                        ])) : (_openBlock(), _createBlock(InputButton, {
                            key: 1,
                            "is-large": _ctx.isLarge,
                            "icon-name": _unref(IconName).BIN,
                            onClick: _unref(onClear)
                        }, null, 8, [
                            "is-large",
                            "icon-name",
                            "onClick"
                        ]))
                    ]),
                _: 1
            }, 16, [
                "input-id",
                "label",
                "disabled",
                "is-focus",
                "is-empty",
                "is-large",
                "clickable-suffix",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'FileSingleInputStateless'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'file-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
