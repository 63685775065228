import createNewMaskedValue from './createNewMaskedValue';
import formatValueToPattern from './formatValueToPattern';
import getOnlyDigitsFromString from './getOnlyDigitsFromString';
export default function handleMaskedValueChange(param) {
    let { target, selectionStart, selectionEnd, data, currentValue, maskOptions } = param;
    const { pattern } = maskOptions;
    const updatedValue = createNewMaskedValue({
        oldValue: currentValue,
        positionStart: selectionStart,
        positionEnd: selectionEnd,
        pattern,
        data
    });
    const patternLength = getOnlyDigitsFromString(pattern).length;
    const limitedUpdatedValue = updatedValue.value.slice(0, patternLength);
    const eventFormattedValue = formatValueToPattern(limitedUpdatedValue, maskOptions);
    const newPosition = updatedValue.position;
    /* eslint-disable no-param-reassign */ target.value = eventFormattedValue;
    target.selectionStart = newPosition;
    target.selectionEnd = newPosition;
    /* eslint-enable no-param-reassign */ if (currentValue && currentValue === eventFormattedValue) return null;
    return {
        nextValue: limitedUpdatedValue,
        isFilled: eventFormattedValue.length >= pattern.length
    };
}
