import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
const _hoisted_2 = [
    "title"
];
import { computed, toRef } from 'vue';
import { useSportlineEventInfo } from 'web/src/modules/sportline/views/EventBlock/composables';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { getActualScoreDetails } from 'web/src/modules/sportline/views/EventBlock/utils';
import DoubleEventLogo from 'web/src/modules/sportline/components/SportlineLogo/DoubleEventLogo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventCompetitorsMainInfo',
    props: {
        sportlineEvent: {},
        sport: {},
        canShowLogos: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const sport = toRef(props, 'sport');
        const canShowLogos = toRef(props, 'canShowLogos');
        const actualScoreDetails = computed(()=>// @TODO return the list ready to display
            getActualScoreDetails(sportlineEvent.value, sport.value));
        const actualScoreDetailsList = computed(()=>{
            const host = actualScoreDetails.value?.host ?? [];
            const guest = actualScoreDetails.value?.guest ?? [];
            return host.map((_, index)=>({
                    host: host[index],
                    guest: guest[index]
                }));
        });
        const { hostName, guestName } = useSportlineEventInfo({
            sportlineEvent
        });
        const { matchProgress } = useLiveMatchProgress({
            sportEvent: sportlineEvent
        });
        // @TODO group with actual score details into single composable
        const innings = computed(()=>matchProgress.value?.innings ?? {
                host: false,
                guest: false
            });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportline-event-main-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-event-main-info__left-column'])
                }, [
                    canShowLogos.value ? (_openBlock(), _createBlock(DoubleEventLogo, {
                        key: 0,
                        "sportline-event": sportlineEvent.value,
                        sport: sport.value,
                        "is-colorful": ""
                    }, null, 8, [
                        "sportline-event",
                        "sport"
                    ])) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sportline-event-competitors'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sportline-event-competitor'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['sportline-event-competitor__name']),
                                title: _unref(hostName)
                            }, _toDisplayString(_unref(hostName)), 11, _hoisted_1)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sportline-event-competitor'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['sportline-event-competitor__name']),
                                title: _unref(guestName)
                            }, _toDisplayString(_unref(guestName)), 11, _hoisted_2)
                        ], 2)
                    ], 2)
                ], 2),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actualScoreDetailsList.value, (score, index)=>(_openBlock(), _createElementBlock("div", {
                        key: `${index}_${score.host.value}_${score.guest.value}`,
                        class: _normalizeClass({
                            [_ctx.$style['sportline-event-main-info__column']]: true,
                            [_ctx.$style['sportline-event-score-column']]: true,
                            [_ctx.$style['sportline-event-score-column--host-goal']]: score.host.goal,
                            [_ctx.$style['sportline-event-score-column--guest-goal']]: score.guest.goal
                        })
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['sportline-event-score']]: true,
                                [_ctx.$style['sportline-event-score--innings']]: !index && innings.value.host && !score.host.goal,
                                [_ctx.$style['sportline-event-score--goal']]: !index && score.host.goal,
                                [_ctx.$style['sportline-event-score--highlighted']]: score.host.highlighted
                            })
                        }, [
                            score.host.goal ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SPORT_LINE_GOAL')), 1)
                            ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _createTextVNode(_toDisplayString(score.host.value), 1)
                            ], 64))
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['sportline-event-score']]: true,
                                [_ctx.$style['sportline-event-score--innings']]: !index && innings.value.guest && !score.guest.goal,
                                [_ctx.$style['sportline-event-score--goal']]: !index && score.guest.goal,
                                [_ctx.$style['sportline-event-score--highlighted']]: score.guest.highlighted
                            })
                        }, [
                            score.guest.goal ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SPORT_LINE_GOAL')), 1)
                            ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _createTextVNode(_toDisplayString(score.guest.value), 1)
                            ], 64))
                        ], 2)
                    ], 2))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventCompetitorsMainInfo'
                ]
            ]);
        };
    }
});
