import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ProgressHeight, ProgressKind } from '../enums';
export default function useVLinearProgress(props) {
    const iconProperties = {
        name: IconName.CHECK_FILLED,
        size: IconSize.SIZE_24
    };
    const percentage = computed(()=>Math.floor((props.value || 0) / (props.of || 100) * 100));
    const barStyleObject = computed(()=>({
            width: `${percentage.value}%`
        }));
    const classObject = computed(()=>{
        const baseClass = 'linear-progress';
        return {
            [baseClass]: true,
            [`${baseClass}--${props.kind}`]: props.kind !== ProgressKind.DEFAULT,
            [`${baseClass}--${props.height}`]: props.height !== ProgressHeight.DEFAULT,
            [`${baseClass}--no-shadow`]: !!props.noShadow
        };
    });
    return {
        iconProperties,
        percentage,
        barStyleObject,
        classObject
    };
}
