import { IconName } from '@leon-hub/icons';
import { DialogComponent } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
const tfaCodesPresetOptions = {
    modalComponent: DialogComponent.TfaCodesModal,
    codes: [],
    iconName: IconName.CHECK_OUTLINE,
    iconKind: JumbotronIconKind.SUCCESS,
    width: ModalWidth.SMALL,
    fullHeight: true,
    isFullHeightCentered: false
};
export default tfaCodesPresetOptions;
