import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "status__text"
};
import { VIcon } from '@components/v-icon';
import { useVStatus } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VStatus',
    props: {
        text: {},
        isSuccess: {
            type: Boolean
        },
        isLowerCase: {
            type: Boolean
        },
        isIcon: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { className, iconProp } = useVStatus(props);
        function onClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_unref(className)),
                onClick: onClick
            }, [
                _ctx.isIcon ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                    key: 0
                }, _unref(iconProp), {
                    class: "status__icon"
                }), null, 16)) : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VStatus'
                ]
            ]);
        };
    }
});
