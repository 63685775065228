import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';
import { VIcon } from '@components/v-icon';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ModalWindowHeaderPrefixAbstract',
    props: {
        prefixIconName: {}
    },
    emits: [
        "drag-start",
        "prefix-click",
        "suffix-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        computed(()=>props.prefixIconName);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _ctx.prefixIconName ? _withDirectives((_openBlock(), _createBlock(LButton, {
                key: 1,
                "icon-size": _unref(IconSize).SIZE_24,
                kind: _unref(ButtonKind).NAV,
                "icon-name": _ctx.prefixIconName,
                class: "modal-header__prefix-button",
                onClick: _cache[1] || (_cache[1] = ($event)=>emit('prefix-click'))
            }, null, 8, [
                "icon-size",
                "kind",
                "icon-name"
            ])), [
                [
                    _directive_data_test,
                    {
                        el: 'modal-prefix-button'
                    }
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
