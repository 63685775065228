import { reactive } from 'vue';
export function useSaveOpenRegionStateStoreComposable() {
    const openRegionsIds = reactive({});
    function isRegionOpen(id) {
        return openRegionsIds[id] ?? false;
    }
    function setOpenRegionState(id, state) {
        openRegionsIds[id] = state;
    }
    return {
        isRegionOpen,
        setOpenRegionState
    };
}
