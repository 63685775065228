import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "for"
];
const _hoisted_2 = [
    "id",
    "autofocus",
    "value",
    "name",
    "disabled",
    "placeholder",
    "readonly",
    "rows",
    "maxlength"
];
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import InputButton from '../InputButton';
import InputHint from '../InputHint';
import useMultilineTextInput from '../MultilineTextInput/useMultilineTextInput';
import InputBorderWrapper from '../InputBorderWrapper';
import { useAutofocus } from '../../composables';
import getInputIconSize from '../../utils/getInputIconSize';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'AutoheightTextInput',
    props: {
        maxLength: {},
        rowsMax: {},
        rowsMin: {},
        isHintHidden: {
            type: Boolean
        },
        suffixIconName: {},
        clickableIcon: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'MultilineTextInput'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {}
    },
    emits: [
        "icon-suffix-click",
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { focusable, focus } = useAutofocus(props);
        const { uniqueId, hintProps, hasError, rows, inputValue, isFocus, isHover, onMouseOver, onMouseLeave, onFocus, onBlur, onChange, onInput, emitIconSuffixClick, setRowsOnMount } = useMultilineTextInput(props, emits, focusable);
        __expose({
            focus
        });
        // ok not to be reactive
        const iconSize = getInputIconSize();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['autoheight-text-input'])
            }, [
                _createVNode(_unref(InputBorderWrapper), {
                    "is-focus": _unref(isFocus),
                    "is-disabled": _ctx.disabled,
                    "is-hover": _unref(isHover),
                    "has-error": _unref(hasError),
                    class: _normalizeClass(_ctx.$style['autoheight-text-input__wrapper'])
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("label", {
                                for: _unref(uniqueId),
                                class: _normalizeClass({
                                    [_ctx.$style['autoheight-text-input__label']]: true,
                                    [_ctx.$style['autoheight-text-input__icon-padding']]: !!_ctx.suffixIconName
                                })
                            }, _toDisplayString(_ctx.label), 11, _hoisted_1),
                            _ctx.readonly ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['autoheight-text-input__input'])
                            }, _toDisplayString(_unref(inputValue)), 3)) : (_openBlock(), _createElementBlock("textarea", {
                                key: 1,
                                id: _unref(uniqueId),
                                ref_key: "focusable",
                                ref: focusable,
                                autofocus: process.env.VUE_APP_OS_IOS ? void 0 : _ctx.autofocus,
                                value: _unref(inputValue),
                                name: _ctx.name,
                                disabled: _ctx.disabled,
                                placeholder: _ctx.placeholder,
                                readonly: _ctx.readonly,
                                rows: _unref(rows),
                                maxlength: _ctx.maxLength,
                                class: _normalizeClass({
                                    [_ctx.$style['autoheight-text-input__input']]: true,
                                    [_ctx.$style['autoheight-text-input__icon-padding']]: !!_ctx.suffixIconName
                                }),
                                onInput: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onInput) && _unref(onInput)(...args);
                                }, [
                                    "stop"
                                ])),
                                onChange: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onChange) && _unref(onChange)(...args);
                                }, [
                                    "stop"
                                ])),
                                onFocus: _cache[2] || (_cache[2] = _withModifiers(//@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onFocus) && _unref(onFocus)(...args);
                                }, [
                                    "stop"
                                ])),
                                onBlur: _cache[3] || (_cache[3] = _withModifiers(//@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onBlur) && _unref(onBlur)(...args);
                                }, [
                                    "stop"
                                ])),
                                onMouseover: _cache[4] || (_cache[4] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onMouseOver) && _unref(onMouseOver)(...args);
                                }),
                                onMouseleave: _cache[5] || (_cache[5] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onMouseLeave) && _unref(onMouseLeave)(...args);
                                }),
                                onVnodeMounted: _cache[6] || (_cache[6] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(setRowsOnMount) && _unref(setRowsOnMount)(...args);
                                })
                            }, null, 42, _hoisted_2)),
                            !!_ctx.suffixIconName || _ctx.disabled ? (_openBlock(), _createElementBlock("div", {
                                key: 2,
                                class: _normalizeClass({
                                    [_ctx.$style['autoheight-text-input__icon']]: true,
                                    [_ctx.$style['autoheight-text-input__icon--inert']]: !_ctx.clickableIcon || _ctx.disabled
                                })
                            }, [
                                _ctx.disabled ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _ctx.disabled ? (_openBlock(), _createBlock(_unref(VIcon), {
                                        key: 0,
                                        size: _unref(iconSize),
                                        name: _unref(IconName).LOCK,
                                        class: _normalizeClass(_ctx.$style['autoheight-text-input__lock'])
                                    }, null, 8, [
                                        "size",
                                        "name",
                                        "class"
                                    ])) : _createCommentVNode("", true)
                                ], 64)) : _renderSlot(_ctx.$slots, "iconSuffix", {
                                    key: 1
                                }, ()=>[
                                        _ctx.clickableIcon ? (_openBlock(), _createBlock(_unref(InputButton), {
                                            key: 0,
                                            "is-large": _ctx.isLarge,
                                            "icon-name": _ctx.suffixIconName,
                                            onClick: _unref(emitIconSuffixClick)
                                        }, null, 8, [
                                            "is-large",
                                            "icon-name",
                                            "onClick"
                                        ])) : (_openBlock(), _createBlock(_unref(VIcon), {
                                            key: 1,
                                            size: _unref(iconSize),
                                            name: _ctx.suffixIconName
                                        }, null, 8, [
                                            "size",
                                            "name"
                                        ]))
                                    ])
                            ], 2)) : _createCommentVNode("", true)
                        ]),
                    _: 3
                }, 8, [
                    "is-focus",
                    "is-disabled",
                    "is-hover",
                    "has-error",
                    "class"
                ]),
                _ctx.isHintHidden ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(InputHint), _normalizeProps(_mergeProps({
                    key: 0
                }, _unref(hintProps))), null, 16))
            ], 2)), [
                [
                    _directive_auto_id,
                    'AutoheightTextInput'
                ]
            ]);
        };
    }
});
