import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestorePasswordContentBlock',
    props: {
        restoreTitle: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { restoreTitle } = props;
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, {
                key: 1
            }, [
                _createElementVNode("p", {
                    class: _normalizeClass({
                        [_ctx.$style['restore__helper-text']]: true,
                        [_ctx.$style['restore__helper-text--block']]: true
                    })
                }, _toDisplayString(_unref(restoreTitle)), 3),
                _createElementVNode("p", {
                    class: _normalizeClass({
                        [_ctx.$style['restore__helper-text']]: true,
                        [_ctx.$style['restore__helper-text--resend']]: true
                    })
                }, _toDisplayString(_ctx.$t('RESTORE_PASSWORD_LETTER_RESEND')), 3),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['restore-text--link']),
                    onClick: _cache[1] || (_cache[1] = ($event)=>{
                        emits('click');
                    })
                }, _toDisplayString(_ctx.$t('WEB2_RESTORE_PASSWORD_RESEND_MAIL')) + ". ", 3)
            ], 64));
    }
});
