import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import FormPaymentsInput from 'web/src/components/FormPaymentsInput/components/FormPaymentsInput/FormPaymentsInput.vue';
import { FormPaymentsInputType } from 'web/src/components/FormPaymentsInput/components/FormPaymentsInput/enums';
import useFormPaymentsDisabledInput from '../composables/useFormPaymentsDisabledInput';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WebmoneyDisabledInput',
    props: {
        title: {},
        hintMessage: {},
        disabled: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        name: {},
        extraProperties: {},
        value: {
            type: [
                String,
                Boolean,
                Number,
                Array,
                Object
            ]
        },
        options: {},
        error: {}
    },
    setup (__props) {
        const props = __props;
        const { componentProps } = useFormPaymentsDisabledInput(props, FormPaymentsInputType.WEBMONEY);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(FormPaymentsInput, _normalizeProps(_guardReactiveProps(_unref(componentProps))), null, 16)), [
                [
                    _directive_auto_id,
                    'WebmoneyDisabledInput'
                ]
            ]);
        };
    }
});
