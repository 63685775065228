import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CustomerHistoryFilterType, HistoryFilter } from '@leon-hub/api-sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line max-len
export default function useCustomerHistoryFilterSelect(props, emit) {
    const { $translate } = useI18n();
    useRoute();
    const router = useRouter();
    const SELECT_NAME = 'dateSelect';
    // filter corner case
    const pendingBetOption = computed(()=>({
            dateRange: 365,
            dateType: CustomerHistoryFilterType.DAY,
            isDefault: false,
            order: 0.1,
            title: $translate('WEB2_PENDING_BETS').value
        }));
    const filterOptions = computed(()=>{
        let options = [
            ...props.historyFilterOptions
        ];
        if (props.selectedHistoryFilter === HistoryFilter.ALL_BETS) options = [
            pendingBetOption.value,
            ...options
        ];
        return options;
    });
    const filterSelectOptions = computed(()=>filterOptions.value.sort((first, second)=>first.order - second.order).map((option)=>({
                value: `${option.order}`,
                label: option.title
            })));
    const selectProperties = computed(()=>{
        const activeOrderFilter = router.getQuery('activeOrderFilter');
        return {
            name: SELECT_NAME,
            options: filterSelectOptions.value,
            selectedValue: `${activeOrderFilter}`
        };
    });
    function onFilterChange(event) {
        const option = filterOptions.value.find((value)=>value.order === Number(event.target.value));
        emit('filter-change', option);
    }
    return {
        filterSelectOptions,
        selectProperties,
        onFilterChange
    };
}
