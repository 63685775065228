import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export class I18nModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
    addRoutes(router) {
        "1";
        router.addModalRoute({
            name: RouteName.LANGUAGE,
            path: '/language',
            title: '{{$t(\'WEB2_LANGUAGE_SELECTION\')}}',
            component: ()=>import('web/src/modules/i18n/components/LanguageSelector/LanguageSelector.vue'),
            prefetch: ()=>import('./prefetch/LanguagePagePrefetch'),
            modalPreset: ModalDesktopPreset.SmallDesktopModal
        });
    }
}
