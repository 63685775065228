import { SportFamily, MarketTypeSign } from 'web/src/modules/sportline/enums';
const size2 = {
    size: 2,
    sign: MarketTypeSign.HomeAway
};
const size3 = {
    size: 3,
    sign: MarketTypeSign.HomeDrawAway
};
export const defaultSportsMarketColumns = Object.freeze({
    // 2 column
    // American Football
    // Aussie Rules
    // Field Hockey
    // eSports
    // Rugby League
    // Rugby Union
    [SportFamily.Tennis]: size2,
    [SportFamily.Badminton]: size2,
    [SportFamily.Curling]: size2,
    [SportFamily.Basketball]: size2,
    [SportFamily.Volleyball]: size2,
    [SportFamily.TableTennis]: size2,
    [SportFamily.Snooker]: size2,
    [SportFamily.BeachVolleyball]: size2,
    [SportFamily.Baseball]: size2,
    [SportFamily.Darts]: size2,
    [SportFamily.Squash]: size2,
    [SportFamily.Cricket]: size2,
    [SportFamily.Bowls]: size2,
    [SportFamily.ESport]: size2,
    [SportFamily.Rugby]: size2,
    [SportFamily.Golf]: size2,
    [SportFamily.Cycling]: size2,
    // 3 column
    // Rugby Union
    // Field Hockey
    // eSports
    [SportFamily.Soccer]: size3,
    [SportFamily.IceHockey]: size3,
    [SportFamily.Handball]: size3,
    [SportFamily.Floorball]: size3,
    [SportFamily.Futsal]: size3,
    [SportFamily.WaterPolo]: size3,
    [SportFamily.RugbyLeague]: size3,
    // ? column
    [SportFamily.AlpineSkiing]: size2,
    [SportFamily.Bandy]: size2,
    [SportFamily.BeachSoccer]: size2,
    [SportFamily.Biathlon]: size2,
    [SportFamily.Boxing]: size2,
    [SportFamily.Equestrian]: size2,
    [SportFamily.Motorsport]: size2,
    [SportFamily.Sailing]: size2,
    [SportFamily.SkiJumping]: size2,
    [SportFamily.AustralianRules]: size2,
    [SportFamily.Specials]: size2
});
