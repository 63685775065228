import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onBeforeUnmount } from 'vue';
import VSnackBar from 'web/src/components/SnackBar/VSnackBar/VSnackBar.vue';
import { useDowntimeAlert } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TheDowntimeAlert',
    setup (__props) {
        const { snackbarProperties, setIsShown, clearTimer } = useDowntimeAlert();
        onBeforeUnmount(clearTimer);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VSnackBar, _mergeProps(_unref(snackbarProperties), {
                onClickClose: _unref(setIsShown)
            }), null, 16, [
                "onClickClose"
            ])), [
                [
                    _directive_auto_id,
                    'TheDowntimeAlert'
                ]
            ]);
        };
    }
});
