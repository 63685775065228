import { ref, computed, toRef } from 'vue';
import { defineStore } from 'pinia';
import { isUndefined } from '@leon-hub/guards';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useParseSportlineSettingsRef, useIsSportDateTimeFiltersEnabledRef } from 'web/src/modules/sportline/composables/settings';
import { useCustomerFavoritesService, useSportlineFavoritesStoreComposable } from 'web/src/modules/sportline/submodules/favorites/composables';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import { useSportlinePageIdentifier } from 'web/src/modules/sportline/store/composables';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useSaveOpenRegionStateStoreComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import { useSportsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { SportsListAdapter } from 'web/src/modules/sportline/utils/rest';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { CustomFilter, SportlineType } from 'web/src/modules/sportline/enums';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { filterSportEventsListByFilter } from 'web/src/modules/sportline/submodules/sports/store/utils';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import useAwaitConditionQueue from 'web/src/utils/store/composables/useAwaitConditionQueue';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useSportlineSportsFilterComposable from './composables/useSportlineSportsFilterComposable';
import useSportlineZeroMarginSportsComposable from './composables/useSportlineZeroMarginSportsComposable';
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useSportlineSportsStore = defineStore('sportline-sports', ()=>{
    const apiService = useSportlineApiService();
    const sportsTreeStore = useSportsTreeStore();
    const rawFullSportsList = toRef(sportsTreeStore, 'fullSportsList');
    const isSportFiltersEnabled = useIsSportDateTimeFiltersEnabledRef();
    const parseSportlineSettings = useParseSportlineSettingsRef();
    const { timeout: sportsUpdateInterval } = useSportsSportlineUpdateTimeout();
    const { onInit: onGlobalSportlineEventsInit } = useGlobalSportlineEvents();
    const { isFavoritesIdsLoaded } = useCustomerFavoritesService();
    const { pageIdentifier, setPageIdentifier } = useSportlinePageIdentifier();
    const { selectedSportFamily, sportsListFilter, filterDateTimeInterval, absoluteFilterDateTimeInterval, allowedSportFamily, customFilters, resetFilterOnLeavingPage, setSelectedSportFamily, setSportsListFilter, saveFilters, loadFilters } = useSportlineSportsFilterComposable();
    const saveOpenRegionState = useSaveOpenRegionStateStoreComposable();
    function prepareEventsList(fullList) {
        return filterSportEventsListByFilter(fullList, {
            dateTimeRange: absoluteFilterDateTimeInterval.value,
            sportlineType: sportsListFilter.value.sportlineType
        });
    }
    const { isReady: isZeroMarginReady, isLoaded: isZeroMarginLoaded, isListLoaded: isZeroMarginListLoaded, isZeroMarginEnabled, isZeroMarginEventsTabSelected, zeroMarginEventsList, rawZeroMarginEventsList, isZeroMarginEventsTabAvailable, isZeroMarginEventsListEmpty, initialRequests: initialZeroMarginRequests, onInit: onZeroMarginInit, syncBackgroundRequests: syncZeroMarginBackgroundRequests, setBackgroundUpdateEnabled: setZeroMarginBackgroundUpdateEnabled } = useSportlineZeroMarginSportsComposable({
        selectedSportFamily,
        prepareEventsList
    });
    const { favoriteEventsFullList: filteredByDateTimeFavoriteEventsList, favoriteSelectedRegionFamily, favoriteSelectedSportFamily, /** Actual list for display at favorites tab */ favoriteEventsListForDisplay, onInit: onFavoritesInit, initialRequests: initialFavoritesRequests, setBackgroundUpdateEnabled: setFavoritesBackgroundUpdateEnabled, setFavoriteSelectedSportFamily, setFavoriteSelectedRegionFamily, selectFirstAvailableFavoriteSportFamily } = useSportlineFavoritesStoreComposable({
        backgroundFavoritesId: CustomerFavoritesIdsBackgroundUpdateKey.PREMATCH,
        prepareEventsList
    });
    /** @deprecated internal field */ const sportsListInternal = ref(null);
    const showLoadingIndicator = ref(false);
    const fullSportsList = computed(()=>rawFullSportsList.value ?? []);
    const actualSportsList = computed(()=>{
        const interval = filterDateTimeInterval.value;
        const { sportlineType } = sportsListFilter.value;
        const hasDateTimeFilter = !isUndefined(interval.from) && !isUndefined(interval.to);
        const hasActiveFilter = hasDateTimeFilter || !isUndefined(sportlineType);
        return hasActiveFilter ? sportsListInternal.value : rawFullSportsList.value;
    });
    /** Active main filter for content */ const actualListFilter = computed(()=>{
        let sportFamily;
        if (isSportFamilyEquals(selectedSportFamily.value, CustomFilter.Favorites)) sportFamily = CustomFilter.Favorites;
        else if (selectedSportFamily.value) sportFamily = selectedSportFamily.value;
        else {
            sportFamily = rawFullSportsList.value?.[0]?.sport.navigationParameters.urlName;
            if (!sportFamily) {
                const sportsList = actualSportsList.value;
                sportFamily = sportsList ? sportsList[0]?.sport.navigationParameters.urlName : void 0;
            }
        }
        return {
            sportFamily
        };
    });
    /** Active sport element for content */ const actualSportElement = computed(()=>{
        const sportsList = actualSportsList.value;
        if (!sportsList) return null;
        return sportsList.find((item)=>isSportFamilyEquals(item.sport, actualListFilter.value.sportFamily)) ?? null;
    });
    const activeListFilterSportElement = computed(()=>{
        const sportsList = rawFullSportsList.value;
        if (!sportsList) return;
        return selectedSportFamily.value && sportsList ? sportsList.find((item)=>isSportFamilyEquals(item.sport, selectedSportFamily.value)) : sportsList[0];
    });
    const isActualSportsListLoaded = computed(()=>null !== actualSportsList.value);
    const isLoaded = computed(()=>isActualSportsListLoaded.value && isFavoritesIdsLoaded.value && isZeroMarginLoaded.value);
    function setShowLoadingIndicator(state) {
        showLoadingIndicator.value = state;
    }
    async function reloadSportsList(silent) {
        const interval = filterDateTimeInterval.value;
        const { sportlineType } = sportsListFilter.value;
        const hasDateTimeFilter = !isUndefined(interval.from) && !isUndefined(interval.to);
        const hasActiveFilter = hasDateTimeFilter || !isUndefined(sportlineType);
        if (!hasActiveFilter) // will be used full sports list
        return;
        const result = await apiService.loadSportsTree({
            dateTime: hasDateTimeFilter ? interval : void 0,
            silent,
            betlines: sportlineType === SportlineType.Live ? [
                BetlineType.Live
            ] : [
                BetlineType.Live,
                BetlineType.Prematch
            ]
        });
        const list = result ? new SportsListAdapter(result, parseSportlineSettings.value).getSports() : [];
        sportsListInternal.value = list;
    }
    async function reloadListForFilter(filter, silent) {
        setShowLoadingIndicator(true);
        setSportsListFilter(filter);
        saveFilters();
        await reloadSportsList(silent);
        setShowLoadingIndicator(false);
    }
    async function reloadFullSportsList(silent) {
        await sportsTreeStore.reloadFullSportsList({
            silent,
            invalidateAfterInterval: sportsUpdateInterval.value
        });
    }
    const lastUpdate = new BackgroundUpdateStopwatch({
        sportsList: async (param)=>{
            let { silent } = param;
            await Promise.all([
                reloadSportsList(silent),
                reloadFullSportsList(silent)
            ]);
            lastUpdate.update('sportsList');
        }
    });
    const { awaitCondition: awaitStateIsLoaded } = useAwaitConditionQueue({
        source: 'Sportline sports page is loaded',
        value: isLoaded,
        condition: (value)=>value,
        // It should give some time for the render process in the prerender
        flush: process.env.VUE_APP_PRERENDER ? 'post' : 'sync'
    });
    const { initialRequests: initialListRequests, syncState: syncListState, syncBackgroundRequests: syncListBackgroundRequests, setBackgroundUpdateEnabled: setListBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: sportsUpdateInterval
    });
    function onListInit() {
        useSyncState(async (silent)=>{
            await syncListState({
                silent
            });
        }, sportsUpdateInterval);
    }
    async function initialRequests(param) {
        let { dateTimeFilter } = param;
        if (isSportFiltersEnabled.value) {
            if (dateTimeFilter) setSportsListFilter(dateTimeFilter);
            else loadFilters();
        }
        await Promise.all([
            initialListRequests(),
            initialZeroMarginRequests(),
            initialFavoritesRequests()
        ]);
    }
    function setBackgroundUpdateEnabled(value) {
        setListBackgroundUpdateEnabled(value);
        setZeroMarginBackgroundUpdateEnabled(value);
        setFavoritesBackgroundUpdateEnabled(value);
    }
    function syncBackgroundRequests() {
        syncListBackgroundRequests({
            silent: true
        });
        syncZeroMarginBackgroundRequests({
            silent: true
        });
    }
    // on init
    onGlobalSportlineEventsInit();
    onListInit();
    onFavoritesInit();
    onZeroMarginInit();
    return {
        ...saveOpenRegionState,
        pageIdentifier,
        setPageIdentifier,
        showLoadingIndicator,
        setShowLoadingIndicator,
        isLoaded,
        isFavoritesIdsLoaded,
        isZeroMarginLoaded,
        isActualSportsListLoaded,
        selectedSportFamily,
        fullSportsList,
        actualSportsList,
        sportsListFilter,
        actualListFilter,
        actualSportElement,
        activeListFilterSportElement,
        customFilters,
        allowedSportFamily,
        isZeroMarginReady,
        isZeroMarginListLoaded,
        isZeroMarginEnabled,
        isZeroMarginEventsTabSelected,
        zeroMarginEventsList,
        isZeroMarginEventsTabAvailable,
        isZeroMarginEventsListEmpty,
        favoriteSelectedRegionFamily,
        favoriteSelectedSportFamily,
        filteredByDateTimeFavoriteEventsList,
        favoriteEventsListForDisplay,
        awaitStateIsLoaded,
        reloadListForFilter,
        setSelectedSportFamily,
        resetFilterOnLeavingPage,
        setSportsListFilter,
        initialRequests,
        initialListRequests,
        initialZeroMarginRequests,
        initialFavoritesRequests,
        setBackgroundUpdateEnabled,
        syncBackgroundRequests,
        setFavoriteSelectedSportFamily,
        setFavoriteSelectedRegionFamily,
        selectFirstAvailableFavoriteSportFamily,
        // @TODO do not export
        rawZeroMarginEventsList
    };
});
