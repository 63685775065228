import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "alt",
    "src"
];
import { defineAsyncComponent } from 'vue';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useVFileInputPlaceholder } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VFileInputPlaceholder',
    props: {
        name: {},
        imageSrc: {},
        hasError: {
            type: Boolean
        },
        isImageFile: {
            type: Boolean
        }
    },
    emits: [
        "clear"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const ImagePreview = defineAsyncComponent(()=>import('web/src/components/ImagePreview/VImagePreview/VImagePreview'));
        const { hasPreviewImage, iconProperties, previewButtonProps, fileNameText, isImageFileLayout, onImagePreview, onImagePreviewHide } = useVFileInputPlaceholder(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['file-input-photo-placeholder']]: true,
                    [_ctx.$style['file-input-photo-placeholder__error']]: _ctx.hasError,
                    [_ctx.$style['file-input-photo-placeholder__hovered']]: _unref(isImageFileLayout)
                })
            }, [
                _unref(isImageFileLayout) ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['file-input-photo-placeholder__frame']),
                    alt: _ctx.name,
                    src: _ctx.imageSrc
                }, null, 10, _hoisted_1)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['file-input-photo-placeholder__controls']]: true,
                        [_ctx.$style['file-input-photo-placeholder__controls--obscured']]: _unref(isImageFileLayout)
                    })
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['file-input-photo-placeholder__icon-wrapper']]: true,
                            [_ctx.$style['file-input-photo-placeholder__icon-wrapper--error']]: _ctx.hasError
                        })
                    }, [
                        _createVNode(VButton, _mergeProps({
                            class: _ctx.$style['file-input-photo-placeholder__preview-button']
                        }, _unref(previewButtonProps), {
                            onClick: _unref(onImagePreview)
                        }), null, 16, [
                            "class",
                            "onClick"
                        ]),
                        _createVNode(_unref(VIcon), _mergeProps({
                            class: _ctx.$style['file-input-photo-placeholder__icon']
                        }, _unref(iconProperties)), null, 16, [
                            "class"
                        ])
                    ], 2),
                    _ctx.hasError ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['file-input-photo-placeholder__error-title'])
                        }, _toDisplayString(_ctx.$t('WEB2_FAILED_TO_LOAD_FILE')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['file-input-photo-placeholder__error-file-name'])
                        }, _toDisplayString(_unref(fileNameText)), 3)
                    ], 64)) : (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['file-input-photo-placeholder__file-name'])
                    }, _toDisplayString(_unref(fileNameText)), 3)),
                    _createElementVNode("button", {
                        type: "button",
                        class: _normalizeClass(_ctx.$style['file-input-photo-placeholder__clear']),
                        onClick: _cache[0] || (_cache[0] = ($event)=>emit('clear'))
                    }, _toDisplayString(_ctx.$t('WEB2_IMAGE_DELETE')), 3)
                ], 2),
                _unref(hasPreviewImage) ? (_openBlock(), _createBlock(_unref(ImagePreview), {
                    key: 1,
                    src: _ctx.imageSrc,
                    name: _ctx.name,
                    onClose: _unref(onImagePreviewHide)
                }, null, 8, [
                    "src",
                    "name",
                    "onClose"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VFileInputPlaceholder'
                ]
            ]);
        };
    }
});
