import { _ as _define_property } from "@swc/helpers/_/_define_property";
import throttle from 'lodash/throttle';
import { normalizeError } from '@leon-hub/errors';
import { SocketWrapper, SocketEmitter } from '@leon-hub/websocket';
export class ActivityReporter {
    start() {
        const { bus } = this.options.api;
        const onClick = throttle(()=>{
            this.emitter.emit('customer_activity', {
                type: 'click'
            }).// eslint-disable-next-line no-console
            catch((err)=>bus.emit('log:error', normalizeError(err)));
        }, this.options.activityThrottle, {
            trailing: false
        });
        const onKeyUp = throttle(()=>{
            this.emitter.emit('customer_activity', {
                type: 'keyup'
            }).// eslint-disable-next-line no-console
            catch((err)=>bus.emit('log:error', normalizeError(err)));
        }, this.options.activityThrottle, {
            trailing: false
        });
        document.addEventListener('click', onClick, true);
        document.addEventListener('keyup', onKeyUp, true);
        window.addEventListener('beforeunload', ()=>{
            this.socketWrapper.detach();
        });
    }
    constructor(options){
        _define_property(this, "options", void 0);
        _define_property(this, "socketWrapper", void 0);
        _define_property(this, "emitter", void 0);
        this.options = options;
        this.socketWrapper = new SocketWrapper({
            openTimeout: 20000,
            url: this.options.url
        });
        this.emitter = new SocketEmitter(this.socketWrapper);
    }
}
