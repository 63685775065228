import { normalizeError } from '@leon-hub/errors';
import { applyEventsStatusChanges, isSportEventResponseIncludesChanges } from 'web/src/modules/sportline/utils/rest/claimToUpdate';
import { ApplyEventStatusChangesError } from 'web/src/modules/sportline/errors/core';
export default function createApplyEventsStatusChanges(getResponse, setResponse) {
    return function applyStatusChanges(changes) {
        try {
            const rawResponse = getResponse();
            if (!rawResponse) return;
            const hasChanges = isSportEventResponseIncludesChanges(rawResponse, changes);
            if (!hasChanges) return;
            const responseWithChanges = applyEventsStatusChanges(rawResponse, changes);
            setResponse(responseWithChanges);
        } catch (rawError) {
            throw new ApplyEventStatusChangesError({
                originalError: normalizeError(rawError)
            });
        }
    };
}
