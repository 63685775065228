import { computed, onActivated, onBeforeUnmount, onDeactivated, onMounted, onUpdated, provide, reactive, ref } from 'vue';
import VS, { Direction } from 'vanilla-swipe';
import { Timer } from '@leon-hub/utils';
import { useWindowVisibilityChanged } from '@leon-hub/browser-composables';
import { PaginationType } from 'web/src/components/FadeCarousel/enums';
export default function useVFadeCarousel(props, emits) {
    const MIN_VELOCITY = 0.05;
    const IS_TOUCH_DEVICE = 'ontouchstart' in document.documentElement;
    const holder = ref(null);
    const isPointsPagination = computed(()=>props.pagination === PaginationType.POINTS);
    let dragStartInfo = null;
    let dragCurrentInfo = null;
    let autoPlayInterval = 0;
    let swipeListener = null;
    const carouselOptions = reactive({
        activeSlideIndex: 0,
        slidesCount: 0,
        isDragInProgress: false
    });
    provide('carouselOptions', carouselOptions);
    provide('moveToNextSlide', moveToNextSlide);
    provide('moveToPreviousSlide', moveToPreviousSlide);
    provide('moveToSlide', moveToSlide);
    function onActivate() {
        initSwipeListener();
        initAutoplay();
        initSlidesCount();
        emitMounted();
    }
    function onUpdate() {
        initSlidesCount();
    }
    function onDeactivate() {
        stopSwipeListener();
        stopAutoplay();
    }
    onMounted(()=>{
        carouselOptions.activeSlideIndex = props.initSlideIndex ? props.initSlideIndex : 0;
        onActivate();
    });
    onUpdated(()=>{
        onUpdate();
    });
    onActivated(()=>{
        onActivate();
    });
    onBeforeUnmount(()=>{
        onDeactivate();
    });
    onDeactivated(()=>{
        onDeactivate();
    });
    function onWindowVisibilityChanged(isVisible) {
        if (isVisible) initAutoplay();
        else stopAutoplay();
    }
    function initAutoplay() {
        stopAutoplay();
        if (props.autoplayTimeout && props.autoplayTimeout > 0) autoPlayInterval = Timer.setTimeout(()=>{
            if (!carouselOptions.isDragInProgress && !dragStartInfo) moveToNextSlide();
            initAutoplay();
        }, props.autoplayTimeout);
    }
    function initSlidesCount() {
        if (holder.value) carouselOptions.slidesCount = Array.from(holder.value.children).reduce((accumulator, item)=>{
            if (item.classList.contains('fade-carousel-slide')) return accumulator + 1;
            return accumulator;
        }, 0);
    }
    function stopAutoplay() {
        if (autoPlayInterval) {
            Timer.clearTimeout(autoPlayInterval);
            autoPlayInterval = 0;
        }
    }
    function moveToNextSlide() {
        if (carouselOptions.slidesCount <= carouselOptions.activeSlideIndex + 1) carouselOptions.activeSlideIndex = 0;
        else carouselOptions.activeSlideIndex += 1;
        emitSlideChanged();
    }
    function moveToPreviousSlide() {
        if (carouselOptions.activeSlideIndex <= 0) carouselOptions.activeSlideIndex = carouselOptions.slidesCount - 1;
        else carouselOptions.activeSlideIndex -= 1;
        emitSlideChanged();
    }
    function moveToSlide(index) {
        if (carouselOptions.activeSlideIndex !== index && carouselOptions.activeSlideIndex >= 0 && carouselOptions.activeSlideIndex < carouselOptions.slidesCount) {
            initAutoplay();
            carouselOptions.activeSlideIndex = index;
            emitSlideChanged();
        }
    }
    function onMouseEnter() {
        stopAutoplay();
    }
    function onMouseLeave() {
        initAutoplay();
    }
    function dragStart(event, data) {
        if (!carouselOptions.isDragInProgress) {
            dragInit(event, data);
            stopAutoplay();
        }
    }
    function initSwipeListener() {
        if (holder.value) {
            swipeListener?.destroy();
            swipeListener = new VS({
                element: holder.value,
                onSwiping: dragMove,
                onSwiped: dragEnd,
                onSwipeStart: dragStart,
                mouseTrackingEnabled: true,
                preventDefaultTouchmoveEvent: false,
                preventTrackingOnMouseleave: true,
                delta: 10
            });
            swipeListener.init();
        }
    }
    function stopSwipeListener() {
        if (swipeListener) {
            swipeListener.destroy();
            swipeListener = null;
        }
    }
    function dragInit(event, data) {
        dragStartInfo = data;
        dragCurrentInfo = dragStartInfo;
    }
    function dragMove(event, data) {
        if (dragStartInfo) {
            dragCurrentInfo = data;
            if (carouselOptions.isDragInProgress) {
                if (event.cancelable) event.preventDefault();
            } else {
                carouselOptions.isDragInProgress = true;
                dragInit(event, data);
            }
        }
    }
    function dragEnd() {
        dragStartInfo = null;
        if (carouselOptions.isDragInProgress) {
            dragChangeSlide();
            initAutoplay();
            if (IS_TOUCH_DEVICE) carouselOptions.isDragInProgress = false;
        }
    }
    function onClick() {
        carouselOptions.isDragInProgress = false;
    }
    function dragChangeSlide() {
        if (dragCurrentInfo) {
            const { velocity } = dragCurrentInfo;
            if (velocity > 0 && velocity > MIN_VELOCITY) {
                if (dragCurrentInfo.directionX === Direction.LEFT) moveToNextSlide();
                else if (dragCurrentInfo.directionX === Direction.RIGHT) moveToPreviousSlide();
                return true;
            }
        }
        return false;
    }
    useWindowVisibilityChanged(onWindowVisibilityChanged);
    function emitSlideChanged() {
        emits('slide-changed', carouselOptions.activeSlideIndex);
    }
    function emitMounted() {
        emits('mounted', carouselOptions.activeSlideIndex);
    }
    return {
        holder,
        onClick,
        onMouseEnter,
        onMouseLeave,
        carouselOptions,
        isPointsPagination,
        moveToNextSlide,
        moveToPreviousSlide,
        moveToSlide
    };
}
