import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import useSidebarComponent from 'web/src/modules/sportline/submodules/sidebar/composables/useSidebarComponent';
import { DateTimeFilterTabSet } from 'web/src/modules/sportline/enums';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import DateTimeFilter from 'web/src/modules/sportline/components/date-time-filter/DateTimeFilter.vue';
import TheSidebarMenu from 'web/src/components/SidebarMenu/TheSidebarMenu/TheSidebarMenu.vue';
import SidebarCaption from 'web/src/components/SidebarMenu/SidebarCaption/SidebarCaption.vue';
import SportEventSidebarTopLeagues from 'web/src/modules/sportline/submodules/sidebar/components/SportsSidebarTopLeagues.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportsSidebarRouteComponent',
    setup (__props) {
        const dateTimeFilterTabSet = DateTimeFilterTabSet.Default;
        const dateTimeTabsType = TabsType.SMALL;
        const { fullSportsList, fullFavoriteLeagues, isSportFiltersEnabled, maxTopLeaguesInTab, maxTopLeaguesTabsInSidebar, topLeaguesDefaultSportId, datetimeActiveKickoffPeriod, datetimeActiveSportlineType, datetimeCustomFrom, datetimeCustomTo, menu, onDateTimeFilterSelect, setDateTimeFilterCustomRange, setBackgroundUpdateEnabled, catalogClick, beforeSidebarNavigate } = useSidebarComponent();
        onComponentActivated(()=>{
            setBackgroundUpdateEnabled(true);
        });
        onComponentDeactivated(()=>{
            setBackgroundUpdateEnabled(false);
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(TheSidebarMenu, {
                menu: _unref(menu),
                onLevelClick: _unref(catalogClick),
                onBeforeNavigate: _unref(beforeSidebarNavigate)
            }, {
                top: _withCtx(()=>[
                        _createVNode(SportEventSidebarTopLeagues, {
                            "default-active-sport-id": _unref(topLeaguesDefaultSportId),
                            sports: _unref(fullSportsList),
                            "favorites-sports": _unref(fullFavoriteLeagues),
                            "max-in-list": _unref(maxTopLeaguesInTab),
                            "max-tabs-count": _unref(maxTopLeaguesTabsInSidebar),
                            onLevelClick: _unref(catalogClick)
                        }, null, 8, [
                            "default-active-sport-id",
                            "sports",
                            "favorites-sports",
                            "max-in-list",
                            "max-tabs-count",
                            "onLevelClick"
                        ]),
                        _createVNode(SidebarCaption, {
                            title: _ctx.$t('JSPBET_SPORT_TYPES')
                        }, null, 8, [
                            "title"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sports-sidebar__tabs'])
                        }, [
                            _unref(isSportFiltersEnabled) ? (_openBlock(), _createBlock(DateTimeFilter, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['sports-sidebar__date-time-filter']),
                                "active-kickoff-period": _unref(datetimeActiveKickoffPeriod),
                                "active-sportline-type": _unref(datetimeActiveSportlineType),
                                "selected-range-from": _unref(datetimeCustomFrom),
                                "selected-range-to": _unref(datetimeCustomTo),
                                "tab-set": _unref(dateTimeFilterTabSet),
                                "tabs-type": _unref(dateTimeTabsType),
                                onSelectFilter: _unref(onDateTimeFilterSelect),
                                onSelectRange: _unref(setDateTimeFilterCustomRange)
                            }, null, 8, [
                                "class",
                                "active-kickoff-period",
                                "active-sportline-type",
                                "selected-range-from",
                                "selected-range-to",
                                "tab-set",
                                "tabs-type",
                                "onSelectFilter",
                                "onSelectRange"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "menu",
                "onLevelClick",
                "onBeforeNavigate"
            ])), [
                [
                    _directive_auto_id,
                    'SportsSidebarRouteComponent'
                ]
            ]);
        };
    }
});
