import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot } from "vue";
import { ref, watch } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCover',
    props: {
        opened: {
            type: Boolean
        }
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const isOpen = ref(false);
        const isLoaded = ref(props.opened);
        watch(()=>props.opened, (to)=>{
            isOpen.value = to;
        }, {
            immediate: true
        });
        watch(isOpen, (to)=>{
            if (to) isLoaded.value = true;
            emit('change', to);
        }, {
            immediate: true
        });
        function open() {
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        function toggle() {
            isOpen.value = !isOpen.value;
        }
        return (_ctx, _cache)=>_renderSlot(_ctx.$slots, "default", {
                isOpen: isOpen.value,
                isLoaded: isLoaded.value,
                toggle: toggle,
                open: open,
                close: close
            });
    }
});
