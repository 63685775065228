import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListItemDetails',
    props: {
        hover: {
            type: Boolean
        },
        label: {},
        value: {},
        highlight: {
            type: Boolean
        },
        highlightRed: {
            type: Boolean
        },
        oddsBoost: {},
        winBoost: {},
        unBoostedOdds: {},
        unBoostedPrice: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function emitClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass({
                    [_ctx.$style['history-item']]: true,
                    [_ctx.$style['history-item--hover']]: _ctx.hover
                }),
                onClick: emitClick
            }, [
                _renderSlot(_ctx.$slots, "default", {}, ()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['history-item__left'])
                        }, [
                            _renderSlot(_ctx.$slots, "left", {}, ()=>[
                                    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
                                    _ctx.winBoost ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['history-item__percent'])
                                    }, _toDisplayString(_ctx.winBoost), 3)) : _createCommentVNode("", true)
                                ])
                        ], 2),
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['history-item__right']]: true,
                                [_ctx.$style['history-item__right--column']]: _ctx.unBoostedPrice
                            })
                        }, [
                            _renderSlot(_ctx.$slots, "right", {}, ()=>[
                                    _ctx.unBoostedPrice ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['history-item__back-price'])
                                    }, _toDisplayString(_ctx.unBoostedPrice), 3)) : _createCommentVNode("", true),
                                    _ctx.unBoostedOdds ? (_openBlock(), _createElementBlock("span", {
                                        key: 1,
                                        class: _normalizeClass(_ctx.$style['history-item__back-coefficient'])
                                    }, _toDisplayString(_ctx.unBoostedOdds), 3)) : _createCommentVNode("", true),
                                    _createElementVNode("span", {
                                        class: _normalizeClass({
                                            [_ctx.$style['history-item__positive']]: _ctx.highlight,
                                            [_ctx.$style['history-item__negative']]: _ctx.highlightRed
                                        })
                                    }, _toDisplayString(_ctx.value), 3),
                                    _ctx.oddsBoost ? (_openBlock(), _createElementBlock("span", {
                                        key: 2,
                                        class: _normalizeClass(_ctx.$style['history-item__percent'])
                                    }, _toDisplayString(_ctx.oddsBoost), 3)) : _createCommentVNode("", true),
                                    _renderSlot(_ctx.$slots, "icon")
                                ])
                        ], 2)
                    ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListItemDetails'
                ]
            ]);
        };
    }
});
