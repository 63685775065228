import { ref, toRef, computed } from 'vue';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useResetPersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useSportsListDateFilterStoreComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { defaultListFilter } from 'web/src/modules/sportline/constants';
import { isSportDateTimeFilterGuard } from 'web/src/modules/sportline/submodules/navigation/store/guards';
const localStorageCustomerDateTimeFilterKey = 'customerDateTimeFilter';
export default function useSportlineSportsFilterComposable() {
    const localStorageManager = useLocalStorageManager();
    const { isLoggedIn } = useIsLoggedIn();
    const sportlineSettingsStore = useSportlineSettingsStore();
    const allowedSportFamily = toRef(sportlineSettingsStore, 'allowedSportFamily');
    const isZeroMarginEnabled = toRef(sportlineSettingsStore, 'isZeroMarginEnabled');
    const { handlePersistLocationReset } = useResetPersistLocation();
    const { dateTimeFilter: sportsListFilter, dateTimeFilterInterval: filterDateTimeInterval, dateTimeFilterAbsoluteInterval: absoluteFilterDateTimeInterval, setDateTimeFilter: setSportsListFilter } = useSportsListDateFilterStoreComposable();
    const selectedSportFamily = ref(null);
    const customFilters = computed(()=>{
        const result = [];
        if (isLoggedIn.value) result.push(CustomFilter.Favorites);
        if (isZeroMarginEnabled.value) result.push(CustomFilter.ZeroMargin);
        return result;
    });
    function setSelectedSportFamily(id) {
        selectedSportFamily.value = id;
    }
    function resetFilterOnLeavingPage() {
        handlePersistLocationReset('sports');
        selectedSportFamily.value = null;
    }
    function saveFilters() {
        if (!isLoggedIn.value) return;
        localStorageManager.setItem(localStorageCustomerDateTimeFilterKey, JSON.stringify(sportsListFilter.value));
    }
    function loadFilters() {
        if (!isLoggedIn.value) return;
        const savedCustomerDateTimeFilter = localStorageManager.getItem(localStorageCustomerDateTimeFilterKey);
        let customerDateTimeFilter;
        try {
            customerDateTimeFilter = savedCustomerDateTimeFilter ? JSON.parse(savedCustomerDateTimeFilter) : void 0;
            if (!customerDateTimeFilter || !isSportDateTimeFilterGuard(customerDateTimeFilter)) customerDateTimeFilter = void 0;
        } catch  {
            customerDateTimeFilter = void 0;
        }
        if (!customerDateTimeFilter) return;
        // Check is some filter already active
        const isForcedFilter = sportsListFilter.value.activeKickoffPeriod !== defaultListFilter.activeKickoffPeriod;
        const activeKickoffPeriod = isForcedFilter ? sportsListFilter.value.activeKickoffPeriod : customerDateTimeFilter.activeKickoffPeriod;
        setSportsListFilter({
            ...customerDateTimeFilter,
            activeKickoffPeriod
        });
    }
    return {
        selectedSportFamily,
        sportsListFilter,
        filterDateTimeInterval,
        absoluteFilterDateTimeInterval,
        allowedSportFamily,
        customFilters,
        setSelectedSportFamily,
        resetFilterOnLeavingPage,
        setSportsListFilter,
        saveFilters,
        loadFilters
    };
}
