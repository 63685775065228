import { computed, toRef } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { ShowcaseNavigationItemId } from 'web/src/modules/home/submodules/showcase/enums';
import { useNavigationItemsStore } from 'web/src/modules/core/store';
export default function useShowcaseNavigationZeroMarginComposable() {
    const settingsStore = useSportlineSettingsStore();
    const sportsTreeStore = useSportsTreeStore();
    const navigationStore = useNavigationItemsStore();
    const showcaseItems = toRef(navigationStore, 'showcaseItems');
    const hasZeroMargin = toRef(sportsTreeStore, 'hasZeroMargin');
    const zeroMarginUpdateInterval = toRef(sportsTreeStore, 'zeroMarginUpdateInterval');
    const isZeroMarginEnabled = toRef(settingsStore, 'isZeroMarginEnabled');
    const hasZeroMarginNavigationItem = computed(()=>showcaseItems.value.some((param)=>{
            let { id } = param;
            return(// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            id === ShowcaseNavigationItemId.ZERO_MARGIN || id === ShowcaseNavigationItemId.HIGHEST_ODDS);
        }));
    const { pauseSyncState, resumeSyncState } = useSyncState(async (silent)=>{
        if (hasZeroMarginNavigationItem.value) await sportsTreeStore.reloadZeroMarginCounters({
            silent
        });
    }, zeroMarginUpdateInterval, {
        suspendedByDefault: true
    });
    function isZeroMarginEnabledReducer() {
        return isZeroMarginEnabled.value && hasZeroMargin.value;
    }
    function addZeroMarginReducer(itemReducers) {
        const deprecatedReducers = itemReducers[ShowcaseNavigationItemId.ZERO_MARGIN] ?? [];
        const reducers = itemReducers[ShowcaseNavigationItemId.HIGHEST_ODDS] ?? [];
        return {
            ...itemReducers,
            [ShowcaseNavigationItemId.ZERO_MARGIN]: [
                ...deprecatedReducers,
                isZeroMarginEnabledReducer
            ],
            [ShowcaseNavigationItemId.HIGHEST_ODDS]: [
                ...reducers,
                isZeroMarginEnabledReducer
            ]
        };
    }
    return {
        hasZeroMarginNavigationItem,
        addZeroMarginReducer,
        resumeZeroMarginSyncState: resumeSyncState,
        pauseZeroMarginSyncState: pauseSyncState
    };
}
