import { toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { uiAccountTypeMain, uiAccountTypeSbc } from '@leon-hub/api-sdk';
import { useSlipShareStore } from '../store';
import { usePlaceBetStore } from '../../place-bet/store';
import { useFreebetStore } from '../../freebet/store';
export function useSlipShare() {
    const router = useRouter();
    const slipShareStore = useSlipShareStore();
    const isCurrentSlipShareEnabled = toRef(slipShareStore, 'isCurrentSlipShareEnabled');
    const isCustomerBetShareEnabled = toRef(slipShareStore, 'isCustomerBetShareEnabled');
    const lastBetId = toRef(usePlaceBetStore(), 'lastBetId');
    const currentFreeBet = toRef(useFreebetStore(), 'currentFreeBet');
    const { shareBetById, shareCurrentSlipData, showSharedModal, showAmountSwitcher } = slipShareStore;
    const goToShareRoute = ()=>{
        router.push({
            name: RouteName.SLIP_SHARE
        });
    };
    const showSlipShareDialog = ()=>{
        shareCurrentSlipData();
        showSharedModal();
        goToShareRoute();
    };
    const showBetShareDialog = (payload)=>{
        shareBetById(payload);
        showSharedModal();
        goToShareRoute();
    };
    const shareLastBet = ()=>{
        if (lastBetId.value) {
            showAmountSwitcher();
            showBetShareDialog({
                betId: lastBetId.value,
                accountType: currentFreeBet.value ? uiAccountTypeSbc : uiAccountTypeMain,
                showAmount: true
            });
        }
    };
    return {
        isCurrentSlipShareEnabled,
        isCustomerBetShareEnabled,
        showSlipShareDialog,
        showBetShareDialog,
        shareLastBet,
        showAmountSwitcher
    };
}
