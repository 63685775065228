/* eslint-disable no-param-reassign */ import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';
import { useQueryManager } from 'web/src/modules/core/composables';
import VueMetaParametersPlugin from 'web/src/plugins/VueMetaParametersPlugin';
import { dataTest } from 'web/src/utils/directives/dataTest';
import RouteNamePlugin from 'web/src/plugins/RouteNamePlugin';
import { ThemePlugin } from 'web/src/modules/theme/plugins';
import UnreactiveDataPlugin from 'web/src/plugins/UnreactiveDataPlugin';
import CmsUtilsPlugin from 'web/src/plugins/CmsUtilsPlugin';
import { EventsBusPlugin } from 'web/src/modules/core/plugins';
import EnvironmentPlugin from 'web/src/plugins/EnvironmentPlugin/EnvironmentPlugin';
import { AnalyticsPlugin } from 'web/src/modules/analytics/plugins';
import { I18nPlugin } from 'web/src/modules/i18n/plugins';
import { ModalsPlugin, CopyToClipboardPlugin } from 'web/src/modules/dialogs/plugins';
import { createStoreThemeGetter } from 'web/src/modules/theme/utils';
function applyBootstrapUrlConfiguration(app) {
    const queryManager = useQueryManager();
    // Enable vue dev-tools performance tab.
    app.config.performance = Boolean(queryManager.getParameter('vue-performance', true));
}
export function configureVueAppEssentials(app) {
    app.config.compilerOptions.whitespace = 'condense';
    app.config.compilerOptions.isCustomElement = (tag)=>[
            'noindex'
        ].includes(tag);
    // Setup pinia store first
    const pinia = createPinia();
    app.use(pinia);
    app.use(EnvironmentPlugin);
    app.use(VueMetaParametersPlugin);
    applyBootstrapUrlConfiguration(app);
    app.directive('data-test', dataTest);
    app.use(createHead());
    app.use(I18nPlugin);
    app.use(ModalsPlugin);
    app.use(RouteNamePlugin);
    app.use(UnreactiveDataPlugin);
    app.use(CopyToClipboardPlugin);
    app.use(CmsUtilsPlugin);
    app.use(EventsBusPlugin);
    app.use(ThemePlugin, createStoreThemeGetter());
    app.use(AnalyticsPlugin);
    return app;
}
