import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useTheme } from 'web/src/modules/theme/composables';
import { useThemeStore, useThemeColorStore } from 'web/src/modules/theme/store';
export default function useMetaTheme() {
    const { isLight } = useTheme();
    const router = useRouter();
    const isSkeletonColor = computed(()=>!!router.currentRoute.value.meta.hasTopBar);
    computed(()=>router.currentRoute.value.meta.topBarType);
    const { setCustomStatusbarColor } = useThemeStore();
    toRef(useThemeColorStore(), 'customThemeColor');
    const skeletonColor = computed(()=>isLight.value ? "rgba(241, 242, 244, 1)" : "rgba(11, 13, 14, 1)");
    const headerColor = computed(()=>isLight.value ? "rgba(34, 38, 42, 1)" : "rgba(34, 38, 42, 1)");
    const modalHeaderColor = computed(()=>{});
    const themeColor = computed(()=>{
        if (modalHeaderColor.value) return modalHeaderColor.value;
        return isSkeletonColor.value ? skeletonColor.value : headerColor.value;
    });
    return {
        themeColor
    };
}
