import { toRef, ref, computed } from 'vue';
import { isUndefined } from '@leon-hub/guards';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { SportsListAdapter } from 'web/src/modules/sportline/utils/rest';
import { useSportsListDateFilterStoreComposable } from './useSportsListDateFilter';
export function useSportsListStoreComposable(props) {
    const { updateInterval } = props;
    const settingsStore = useSportlineSettingsStore();
    const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');
    const apiService = useSportlineApiService();
    const sportsTreeStore = useSportsTreeStore();
    const { dateTimeFilter: sportsListFilter, dateTimeFilterInterval: filterDateTimeInterval, setDateTimeFilter: applySportsListFilter } = useSportsListDateFilterStoreComposable();
    const rawFullSportsList = toRef(sportsTreeStore, 'fullSportsList');
    const sportsListInternal = ref(null);
    const fullSportsList = computed(()=>rawFullSportsList.value ?? []);
    const actualSportsList = computed(()=>{
        const interval = filterDateTimeInterval.value;
        const { sportlineType } = sportsListFilter.value;
        const hasDateTimeFilter = !isUndefined(interval.from) && !isUndefined(interval.to);
        const hasActiveFilter = hasDateTimeFilter || !isUndefined(sportlineType);
        return hasActiveFilter ? sportsListInternal.value : rawFullSportsList.value;
    });
    const isReady = computed(()=>null !== actualSportsList.value);
    /**
   * @TODO remove extra request for same filters
   * Reload sports list with active filters for bottom block
   */ async function reloadSportsForNavigationElements(silent) {
        const interval = filterDateTimeInterval.value;
        const { sportlineType } = sportsListFilter.value;
        const hasDateTimeFilter = !isUndefined(interval.from) && !isUndefined(interval.to);
        const hasActiveFilter = hasDateTimeFilter || !isUndefined(sportlineType);
        // will be used full sports list
        if (!hasActiveFilter) return;
        const result = await apiService.loadSportsTree({
            dateTime: hasDateTimeFilter ? interval : void 0,
            silent,
            betlines: sportlineType === SportlineType.Live ? [
                BetlineType.Live
            ] : [
                BetlineType.Live,
                BetlineType.Prematch
            ]
        });
        const list = result ? new SportsListAdapter(result, parseSportlineSettings.value).getSports() : [];
        sportsListInternal.value = list ?? null;
    }
    /**
   * Reload sports list without filters for top block
   */ async function reloadFullSportsList(silent) {
        await sportsTreeStore.reloadFullSportsList({
            silent,
            invalidateAfterInterval: updateInterval.value
        });
    }
    /**
   * Reload both full and filtered lists
   */ async function reloadAllSportsLists(silent) {
        await Promise.all([
            reloadSportsForNavigationElements(silent),
            reloadFullSportsList(silent)
        ]);
    }
    async function setSportsListFilter(value) {
        applySportsListFilter(value);
        await reloadSportsForNavigationElements();
    }
    return {
        sportsListFilter,
        fullSportsList,
        actualSportsList,
        isReady,
        reloadAllSportsLists,
        setSportsListFilter
    };
}
