import { computed } from 'vue';
import { BetCashoutMode } from 'web/src/modules/cashout/enums';
export default function useBetCashOutInline(props, emit) {
    const isError = computed(()=>props.mode === BetCashoutMode.ERROR);
    const emitCashOut = ()=>{
        emit('cash-out');
    };
    return {
        isError,
        emitCashOut
    };
}
