import { isObject, isOptionalString, isUndefined, isOptionalBoolean } from '@leon-hub/guards';
import { FormControlType } from 'web/src/components/Form/enums';
import isFormUiSchemaFieldCommon from './isFormUiSchemaFieldCommon';
import isPartialFormPhoneValue from './isPartialFormPhoneValue';
export function isFormUiSchemaField(value) {
    if (!isFormUiSchemaFieldCommon(value)) return false;
    // eslint-disable-next-line sonarjs/no-collapsible-if
    switch(value.widget){
        case FormControlType.FileSingle:
        case FormControlType.FileDragAndDrop:
        case FormControlType.FileMultiple:
        case FormControlType.CreditCardSelector:
        case FormControlType.PhotoInput:
        case FormControlType.DepositBonus:
            return true;
        case FormControlType.Select:
        case FormControlType.SelectWithSearch:
            return isOptionalString(value.default) && isObject(value) && (isUndefined(value.labels) || isObject(value.labels));
        case FormControlType.Phone:
        case FormControlType.PhoneInput:
        case FormControlType.PhoneCountriesSelectorInput:
            return isUndefined(value.default) || isPartialFormPhoneValue(value.default);
        case FormControlType.Checkbox:
        case FormControlType.Switch:
        case FormControlType.TermsAcceptionCheckbox:
            return isOptionalBoolean(value.default);
        default:
            return isOptionalString(value.default);
    }
}
export function isFormUiSchemaFieldBonusCode(value) {
    return isFormUiSchemaField(value) && value.widget === FormControlType.BonusCode;
}
