import { computed, inject, ref, watch } from 'vue';
export default function useVFadeCarouselPointsPagination(props) {
    const carouselOptions = inject('carouselOptions', {
        activeSlideIndex: 0,
        slidesCount: 0,
        isDragInProgress: false
    });
    const moveToNextSlide = inject('moveToNextSlide');
    const moveToPreviousSlide = inject('moveToPreviousSlide');
    const moveToSlide = inject('moveToSlide');
    const paginationWrapper = ref();
    const isLimitedViewEnabled = computed(()=>carouselOptions.slidesCount > 3 && !!props?.isLimitedView && true);
    const scrollLeftActive = computed(()=>{
        if (carouselOptions.activeSlideIndex < 3 || !isLimitedViewEnabled.value) return 0;
        return (carouselOptions.activeSlideIndex - 2) * 20;
    });
    watch(scrollLeftActive, (value)=>{
        if (isLimitedViewEnabled.value) paginationWrapper.value?.scrollTo({
            left: value,
            behavior: 'smooth'
        });
    });
    return {
        carouselOptions,
        moveToNextSlide,
        moveToPreviousSlide,
        moveToSlide,
        paginationWrapper,
        isLimitedViewEnabled
    };
}
