import { computed } from 'vue';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { FastMoneyMaxPosition } from '../enums';
const useFastMoneyChoice = (props, emit)=>{
    const formatMoney = useFormatMoney();
    const selectValue = (value)=>{
        if (value >= (props.minAvailableValue || 0) && (!props.maxAvailableValue || value <= props.maxAvailableValue)) {
            emit('select-value', value);
            emit('click-value', value);
        }
    };
    const maxItem = computed(()=>{
        if (!props.maxEnabled || !props.maxValue) return null;
        return {
            isActive: props.maxValue === props.currentValue,
            label: props.maxLabel || '',
            value: props.maxValue
        };
    });
    const computedItems = computed(()=>{
        const mappedItems = [];
        const minValue = props.minAvailableValue ?? 0;
        const itemsFromProps = props.items ?? [];
        for (const item of itemsFromProps){
            const isAvailable = item.value >= minValue && (!props.maxAvailableValue || item.value <= props.maxAvailableValue);
            if (!props.hideUnavailableOptions || isAvailable) mappedItems.push({
                ...item,
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                isDisabled: props.disabled || !isAvailable,
                isActive: item.value === props.currentValue
            });
        }
        if (!maxItem.value) return mappedItems;
        return props.maxPosition === FastMoneyMaxPosition.START ? [
            maxItem.value,
            ...mappedItems
        ] : [
            ...mappedItems,
            maxItem.value
        ];
    });
    const formattedBalance = computed(()=>formatMoney(props.balance ?? 0, {
            currency: props.currency
        }));
    return {
        selectValue,
        computedItems,
        formattedBalance
    };
};
export default useFastMoneyChoice;
