import { toRef } from 'vue';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { useCountryStore } from 'web/src/modules/country/store';
import sanitize from 'web/src/utils/content/sanitize';
import useAddressApi from '../../../services/api/useAddressApi';
export default function useAddressSearch() {
    const countryStore = useCountryStore();
    const defaultCountryCode = toRef(countryStore, 'countryCode');
    const { autocompleteAddress } = useAddressApi();
    const findAddressByQuery = async (param)=>{
        let { address, countryCode, types } = param;
        const safeAddress = sanitize(address);
        if (!safeAddress) return [];
        try {
            const typesString = types?.length ? types.join(',') : void 0;
            const response = await autocompleteAddress({
                countryCode: countryCode || defaultCountryCode.value.toLowerCase(),
                address: safeAddress,
                types: typesString
            });
            if (response?.predictions?.length) return response.predictions;
            return [];
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(error);
            return [];
        }
    };
    return {
        findAddressByQuery
    };
}
