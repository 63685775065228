export var VirtualSportFramedWidgetProperties;
var VirtualSportFramedWidgetProperties1;
(VirtualSportFramedWidgetProperties1 = VirtualSportFramedWidgetProperties || (VirtualSportFramedWidgetProperties = {}))["IsDesktop"] = "d", VirtualSportFramedWidgetProperties1["Url"] = "url", VirtualSportFramedWidgetProperties1["Sport"] = "sport", VirtualSportFramedWidgetProperties1["ClientId"] = "clientId", VirtualSportFramedWidgetProperties1["Lang"] = "lang", VirtualSportFramedWidgetProperties1["Style"] = "style", VirtualSportFramedWidgetProperties1["Theme"] = "theme";
export var FramedVirtualSportWidgetSport;
var FramedVirtualSportWidgetSport1;
(FramedVirtualSportWidgetSport1 = FramedVirtualSportWidgetSport || (FramedVirtualSportWidgetSport = {}))["FootballLeague"] = "vflm", FramedVirtualSportWidgetSport1["Basketball"] = "vbl", FramedVirtualSportWidgetSport1["FootballEurocup"] = "vfc", FramedVirtualSportWidgetSport1["FootballChampionsCup"] = "vfcc", FramedVirtualSportWidgetSport1["FootballNationsCup"] = "vfnc", FramedVirtualSportWidgetSport1["TennisInplay"] = "vti", FramedVirtualSportWidgetSport1["FootballWorldCup"] = "vfwc", FramedVirtualSportWidgetSport1["FootballBundesliga"] = "vfb", FramedVirtualSportWidgetSport1["Cricket"] = "vci";
export var VirtualSportWidgetInstanceStatus;
var VirtualSportWidgetInstanceStatus1;
(VirtualSportWidgetInstanceStatus1 = VirtualSportWidgetInstanceStatus || (VirtualSportWidgetInstanceStatus = {}))["Initializing"] = "INITIALIZING", VirtualSportWidgetInstanceStatus1["Initialized"] = "INITIALIZED", VirtualSportWidgetInstanceStatus1["Destroyed"] = "DESTROYED";
export var VirtualSportWidgetBetsChangedContext;
var VirtualSportWidgetBetsChangedContext1;
(VirtualSportWidgetBetsChangedContext1 = VirtualSportWidgetBetsChangedContext || (VirtualSportWidgetBetsChangedContext = {}))["Restored"] = "selectionsRestored", VirtualSportWidgetBetsChangedContext1["Clicked"] = "selectionsClicked", VirtualSportWidgetBetsChangedContext1["ConfirmedViaAPI"] = "selectionsConfirmedViaAPI", VirtualSportWidgetBetsChangedContext1["DismissedViaAPI"] = "selectionsDismissedViaAPI";
