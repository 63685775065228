import { ref, computed, watch } from 'vue';
import { CountryCode } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { useInputCore, useMaskedInput } from '../../composables';
import { handleUnmaskedPhoneInput, handlePhonePaste } from '../../composables/utils';
import { InputEventType, PhoneSubfields, CustomInputEventType } from '../../enums';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ export default function usePhoneInput(props, emit) {
    const { uniqueId, inputValue, isEmpty, isHover, onMouseOver, onMouseLeave, setInputValue } = useInputCore(props, emit, true);
    const selectedPrefix = ref(props.prefix ?? '');
    const selectedCountryCode = ref(props.countryCode ?? '');
    watch(()=>props.countryCode, (value)=>{
        selectedCountryCode.value = value ?? '';
    });
    watch(()=>props.prefix, (value)=>{
        selectedPrefix.value = value ?? '';
    });
    const isFocus = ref(false);
    const eventToEmit = computed(()=>({
            target: {
                value: inputValue.value,
                name: `${props.name}/${PhoneSubfields.SUFFIX}`
            }
        }));
    const onFocus = ()=>{
        isFocus.value = true;
        emit(InputEventType.FOCUS, eventToEmit.value);
    };
    const onBlur = ()=>{
        isFocus.value = false;
        emit(InputEventType.BLUR, eventToEmit.value);
    };
    const emitChange = ()=>{
        emit(InputEventType.CHANGE, eventToEmit.value);
    };
    const emitInput = ()=>{
        emit(InputEventType.INPUT, eventToEmit.value);
    };
    const clearValue = ()=>{
        setInputValue('');
        emitChange();
    };
    const maskOptions = computed(()=>{
        if (props.disabled) return;
        const placeholder = 'X';
        if (props.mask?.pattern) return {
            pattern: props.mask.pattern,
            placeholder: props.mask.placeholder ?? placeholder
        };
        const ruMaskOptions = {
            placeholder,
            pattern: '(999) 999-99-99'
        };
        // eslint-disable-next-line sonarjs/no-small-switch
        switch(selectedCountryCode.value){
            case CountryCode.RU:
                return ruMaskOptions;
            default:
                return;
        }
    });
    const hasError = computed(()=>!!(props.error ?? props.errorRight));
    const showClearButton = computed(()=>isFocus.value && !isEmpty.value);
    const currentPlaceholder = computed(()=>{
        switch(selectedCountryCode.value){
            case CountryCode.AU:
            case CountryCode.NZ:
                return '4XXXXXXXX';
            default:
                return props.placeholder ?? '';
        }
    });
    const { isMaskedPlaceholderHidden, formattedValue, maskedValue, getValueCutToPattern, getMaskedInputResult, getMaskedKeydownResult } = useMaskedInput({
        inputValue,
        isFocus,
        maskOptions
    });
    const showMask = computed(()=>{
        if (props.disablePlaceholder && props.disabled) return false;
        return !!maskOptions.value;
    });
    const displayValue = computed(()=>{
        if (props.disabled && props.disablePlaceholder) return props.disablePlaceholder;
        return formattedValue.value;
    });
    const isPrefixModalShown = ref(false);
    const toggleOpenPrefixModal = ()=>{
        isPrefixModalShown.value = !isPrefixModalShown.value;
    };
    const onPrefixClick = ()=>{
        if (props.disabled && !isPrefixModalShown.value) return;
        toggleOpenPrefixModal();
    };
    const cutValueToPattern = ()=>{
        const nextValue = getValueCutToPattern();
        if (nextValue !== inputValue.value) {
            setInputValue(nextValue);
            emitInput();
        }
    };
    const onCountrySelect = (country)=>{
        "1";
        {
            const { prefix, code } = country;
            selectedCountryCode.value = code;
            selectedPrefix.value = prefix;
            emit(InputEventType.CHANGE, {
                target: {
                    value: prefix,
                    name: `${props.name}/${PhoneSubfields.PREFIX}`
                }
            });
            emit(InputEventType.CHANGE, {
                target: {
                    value: code,
                    name: `${props.name}/${PhoneSubfields.COUNTRY_CODE}`
                }
            });
            cutValueToPattern();
            isPrefixModalShown.value = false;
        }
    };
    const emitFilled = ()=>{
        emit(CustomInputEventType.FILLED);
    };
    const onRegularInput = (event)=>{
        const nextValue = handleUnmaskedPhoneInput(event, inputValue.value);
        if (nextValue !== inputValue.value) {
            setInputValue(nextValue);
            emitInput();
            if (props.emitFilled && props.maxSymbols && nextValue.length >= props.maxSymbols) emitFilled();
        }
    };
    const onMaskedInput = (event)=>{
        const result = getMaskedInputResult(event);
        if (result) {
            const { nextValue, isFilled } = result;
            setInputValue(nextValue);
            emitInput();
            if (isFilled) emitFilled();
        }
    };
    const onInput = (event)=>{
        if (maskOptions.value) onMaskedInput(event);
        else onRegularInput(event);
    };
    const onPaste = (event)=>{
        const result = handlePhonePaste({
            event,
            currentValue: maskedValue.value,
            maskOptions: maskOptions.value
        });
        if (result) {
            const { nextValue, isFilled } = result;
            setInputValue(nextValue);
            emitInput();
            if (isFilled) emitFilled();
        }
    };
    const onKeyDown = (event)=>{
        if (maskOptions.value) {
            const result = getMaskedKeydownResult(event);
            if (result && result.nextValue !== maskedValue.value) {
                setInputValue(result.nextValue);
                emitInput();
            }
        }
    };
    return {
        uniqueId,
        isEmpty,
        isFocus,
        isHover,
        selectedCountryCode,
        selectedPrefix,
        maskedValue,
        showMask,
        formattedValue: displayValue,
        toggleOpenPrefixModal,
        onPrefixClick,
        isPrefixModalShown,
        isMaskedPlaceholderHidden,
        showClearButton,
        currentPlaceholder,
        onCountrySelect,
        hasError,
        onInput,
        onChange: emitChange,
        onPaste,
        onKeyDown,
        onFocus,
        onBlur,
        onClear: clearValue,
        onMouseOver,
        onMouseLeave
    };
}
