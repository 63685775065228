import { CustomInputEventType, InputEventType } from 'web/src/components/Input/enums';
// LEONWEB-9747
export default function useFormEmits(emit, param) {
    let { handleInput, handleFocus, handleBlur, handleSubmit, getCurrentOutput } = param;
    function onChange(event) {
        const data = handleInput(event);
        emit(InputEventType.CHANGE, data);
    }
    function onInput(event) {
        const data = handleInput(event);
        emit(InputEventType.INPUT, data);
    }
    function onFocus(event) {
        const data = handleFocus(event);
        emit(InputEventType.FOCUS, data);
    }
    function onBlur(event) {
        const data = handleBlur(event);
        emit(InputEventType.BLUR, data);
    }
    function onSubmit(event) {
        if (event) event.preventDefault();
        const data = handleSubmit();
        emit(InputEventType.SUBMIT, data);
    }
    function onFilled() {
        emit(CustomInputEventType.FILLED);
    }
    function onIconPrefixClick(field) {
        emit(CustomInputEventType.ICON_PREFIX_CLICK, field);
    }
    function onIconSuffixClick(field) {
        emit(CustomInputEventType.ICON_SUFFIX_CLICK, field);
    }
    // LEONWEB-9747
    function onDisabledButtonClick() {
        emit('disabled-button-click', getCurrentOutput());
    }
    return {
        onChange,
        onInput,
        onFocus,
        onBlur,
        onSubmit,
        onFilled,
        onIconPrefixClick,
        onIconSuffixClick,
        onDisabledButtonClick
    };
}
