import { _ as _define_property } from "@swc/helpers/_/_define_property";
import random from 'lodash/random';
import round from 'lodash/round';
function generateTimeInterval(min, max, duration) {
    let sum = 0;
    const intervals = [
        0
    ];
    while(sum + max < duration){
        sum += random(min, max);
        intervals.push(round(sum / duration, 2));
    }
    return intervals;
}
let RandomIntervals = class RandomIntervals {
    query(value) {
        for(let index = this.intervals.length - 1; index >= 0; index -= 1)if (value >= this.intervals[index]) return this.intervals[index];
        return 1;
    }
    constructor(min, max, summ){
        _define_property(this, "intervals", []);
        this.intervals = generateTimeInterval(min, max, summ);
    }
};
export { RandomIntervals as default };
