import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href",
    "aria-label",
    "target"
];
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { SocialType } from './enums';
import { useVSocial } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSocial',
    props: {
        socialType: {
            default: SocialType.FACEBOOK
        },
        url: {
            default: ''
        },
        target: {
            default: ''
        },
        size: {
            default: IconSize.SIZE_24
        },
        title: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { iconProps } = useVSocial(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: _ctx.url,
                "aria-label": _ctx.title || void 0,
                target: _ctx.target,
                rel: "noopener"
            }, [
                _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconProps))), null, 16)
            ], 8, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VSocial'
                ]
            ]);
        };
    }
});
