import convertCommonClassesToCssModule from '../../../utils/convertCommonClassesToCssModule';
export default function getClassObject(props, havePrefix, haveSuffix) {
    return convertCommonClassesToCssModule({
        'list-item__inner': true,
        'list-item__inner--with-prefix': havePrefix,
        'list-item__inner--with-suffix': haveSuffix,
        'list-item__inner--colored': haveSuffix && !!props.iconColored,
        'list-item__inner--text-centered': !!props.isTitleCentered && !havePrefix,
        'list-item__inner--expand': !!props.haveExpand,
        'list-item__inner--nowrap': !!props.noWrap,
        'list-item__inner--disabled': !!props.haveSwitcher && !!props.isSwitchDisabled
    });
}
