import { ref, computed } from 'vue';
/** Method for external request for background sync state (like prefetch to kept alive page) */ export default function useBackgroundRequestsLifeCycle(props) {
    const isBackgroundUpdateEnabled = ref(false);
    async function syncBackgroundRequests(param, payloads) {
        let { silent, force, groups } = param;
        const syncs = [];
        for (const tread of props.lastUpdate.syncActionsNames){
            const payload = payloads?.[tread] ?? {};
            const group = groups?.[tread];
            if (force) {
                if (group) syncs.push(props.lastUpdate.forceCallGroupSyncAction(tread, group, {
                    silent,
                    ...payload
                }));
                else syncs.push(props.lastUpdate.forceCallSyncAction(tread, {
                    silent,
                    ...payload
                }));
                continue;
            }
            if (group) // eslint-disable-next-line max-len
            syncs.push(props.lastUpdate.callGroupSyncAction(tread, group, props.updateInterval.value, {
                silent,
                ...payload
            }));
            else syncs.push(props.lastUpdate.callSyncAction(tread, props.updateInterval.value, {
                silent,
                ...payload
            }));
        }
        const results = await Promise.allSettled(syncs);
        for (const result of results)if ('rejected' === result.status) // manual reject promises to throw errors
        Promise.reject(result.reason);
    }
    async function setBackgroundUpdateEnabled(value, payloads) {
        isBackgroundUpdateEnabled.value = value;
        if (value) await syncBackgroundRequests({
            silent: true
        }, payloads);
        props.onEnableChanged?.(value);
    }
    async function syncState(options, payloads) {
        if (isBackgroundUpdateEnabled.value) await syncBackgroundRequests({
            ...options,
            force: true
        }, payloads);
    }
    async function initialRequests(payloads) {
        await syncBackgroundRequests({
            silent: true,
            force: true
        }, payloads);
        isBackgroundUpdateEnabled.value = true;
    }
    return {
        // export only getter for ref
        isBackgroundUpdateEnabled: computed(()=>isBackgroundUpdateEnabled.value),
        initialRequests,
        syncState,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
}
