import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk/src/sdk/sdk';
import { getSportEventElementsListCounters, reduceListWithSportEventsCounters } from '../getCounters';
import { getFiltersMap, splitSportEventsListByFilters } from './splitSportEventsListByFilters';
const otherEventsFilter = {
    activeKickoffPeriod: SportFilterKickoffPeriod.ALL
};
function splitLeagueElementByFilters(leagueElement, filters) {
    const baseLeagueKey = leagueElement.key;
    const filtersMap = getFiltersMap(baseLeagueKey, filters);
    const sportEventsMap = splitSportEventsListByFilters(leagueElement.sportEvents, baseLeagueKey, filters);
    const outrightEventsMap = splitSportEventsListByFilters(leagueElement.outrightEvents, baseLeagueKey, filters);
    const filtersKeys = Object.keys(filtersMap);
    const leaguesByFilters = filtersKeys.reduce((result, key)=>{
        const sportEvents = sportEventsMap[key] ?? [];
        const outrightEvents = outrightEventsMap[key] ?? [];
        if (!sportEvents.length && !outrightEvents.length) return result;
        result.push({
            ...leagueElement,
            key,
            sportEvents,
            outrightEvents,
            counters: getSportEventElementsListCounters([
                ...sportEvents,
                ...outrightEvents
            ]),
            usedFilter: filtersMap[key]
        });
        return result;
    }, []);
    if (!filtersKeys.includes(baseLeagueKey)) {
        const key = baseLeagueKey;
        const sportEvents = sportEventsMap[key] ?? [];
        const outrightEvents = outrightEventsMap[key] ?? [];
        leaguesByFilters.push({
            ...leagueElement,
            key,
            sportEvents,
            outrightEvents,
            counters: getSportEventElementsListCounters([
                ...sportEvents,
                ...outrightEvents
            ]),
            usedFilter: leaguesByFilters.length > 0 ? otherEventsFilter : void 0
        });
    }
    return leaguesByFilters;
}
function splitRegionElementLeaguesByFilters(regionElement, filters) {
    const leagues = regionElement.leagues.flatMap((league)=>splitLeagueElementByFilters(league, filters));
    return {
        ...regionElement,
        leagues,
        counters: reduceListWithSportEventsCounters(leagues)
    };
}
export function splitSportElementLeaguesByDateFilter(sportElement, filters) {
    if (!sportElement) return null;
    const regions = sportElement.regions.map((regionElement)=>splitRegionElementLeaguesByFilters(regionElement, filters));
    return {
        ...sportElement,
        regions,
        counters: reduceListWithSportEventsCounters(regions)
    };
}
