import { defineStore } from 'pinia';
import { readonly, ref } from 'vue';
export const useRegistrationComplete = defineStore('registration-complete', ()=>{
    const isRegistrationComplete = ref(false);
    return {
        value: readonly(isRegistrationComplete),
        set (value) {
            isRegistrationComplete.value = value;
        }
    };
});
