import { toRef, computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { OpenIn } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';
import RouteName from '@leon-hub/routing-config';
import { isNullOrUndefined } from '@leon-hub/guards';
import { useWindowVisibilityChanged } from '@leon-hub/browser-composables';
import { getContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import PaymentsPostMessageEvent from 'web/src/modules/payments/utils/PaymentsPostMessageEvent';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function usePaymentsIframe(props) {
    const router = useRouter();
    const FORM_NAME = 'paymentsRedirectForm';
    const iframeName = 'payments-depositok';
    const postMessageBus = ref(null);
    const color = '#000';
    const cssLoader = `
    .lds-dual-ring {
      margin: 30px auto 0;
      width: 30px;
      height: 30px;
    }
    .lds-dual-ring:after {
      opacity: 0.5;
      content: " ";
      display: block;
      width: 24px;
      height: 24px;
      margin: 8px;
      border-radius: 50%;
      border: 3px solid ${color};
      border-color: ${color} transparent ${color} transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
    const openInIframe = toRef(()=>props.openIn === OpenIn.IFRAME);
    const form = computed(()=>{
        const element = document.createElement('form');
        element.setAttribute('accept-charset', props.redirectCharset ?? '');
        element.setAttribute('action', props.redirectUrl ?? '');
        element.setAttribute('method', props.redirectMethod ?? 'POST');
        element.setAttribute('name', FORM_NAME);
        if (props.redirectFormParams) {
            const keys = Object.keys(props.redirectFormParams);
            for (const key of keys){
                const input = document.createElement('input');
                const value = props.redirectFormParams ? props.redirectFormParams[key] : '';
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', `${key}`);
                input.setAttribute('value', `${value}`);
                element.append(input);
            }
        }
        return element;
    });
    const showIframe = computed(()=>!!(openInIframe.value && !props.redirectFormParams && props.redirectUrl));
    const windowOpenParams = toRef(()=>{
        if (props.openIn === OpenIn.NEW_WINDOW) return 'width=800px,height=600px,toolbar=no,titlebar=no,status=no,menubar=no';
        return '';
    });
    const windowOpenTarget = toRef(()=>{
        if (props.openIn === OpenIn.NEW_WINDOW && !process.env.VUE_APP_BROWSER_SAFARI) return '_blank';
        return '_self';
    });
    const redirectHtmlDocument = computed(()=>`<style>${cssLoader}</style>
            <div id="redirectForm">
              <div class="lds-dual-ring"></div>
              ${form.value.outerHTML}
            </div>`);
    const canRedirect = computed(()=>!!(props.redirectUrl && null === props.redirectFormParams));
    function processRedirectForm() {
        try {
            const newWindow = window.open('', windowOpenTarget.value, windowOpenParams.value);
            if (newWindow) {
                newWindow.document.body.innerHTML = redirectHtmlDocument.value;
                setTimeout(()=>{
                    // @ts-ignore
                    newWindow.document.forms[FORM_NAME].submit();
                }, 100);
            }
            doRedirect();
        } catch (error) {
            logger.error(`Can't open a new window for redirect form: ${error}`);
        }
    }
    function redirectDepositsOrWithdrawal() {
        if (props.isDeposit) router.push({
            name: RouteName.DEPOSITS
        });
        else router.push({
            name: RouteName.WITHDRAWALS
        });
    }
    function doRedirect() {
        if (props.openIn !== OpenIn.NEW_WINDOW) redirectDepositsOrWithdrawal();
        else router.closeModal();
    }
    function createPostMessage() {
        const contentWindow = getContentWindowByIFrameName(iframeName);
        isNullOrUndefined(contentWindow);
        postMessageBus.value = new PostMessageBus({
            target: contentWindow,
            targetOrigin: '*',
            initiator: 'PaymentsIframe'
        });
    }
    function iframeMounted() {
        createPostMessage();
    }
    function listenPostMessage() {
        if (postMessageBus.value) // If the close button was clicked in iframe
        postMessageBus.value.on(PaymentsPostMessageEvent.depositok, (payload)=>{
            if (payload.status) router.closeModal();
        });
    }
    function handleVisibilityChange() {
        document.hidden && (props.openIn, OpenIn.NEW_WINDOW);
    }
    function mounted() {
        // Opens content in Iframe
        if (!openInIframe.value && props.redirectFormParams) processRedirectForm();
        else if (!openInIframe.value && !props.redirectFormParams) {
            if (canRedirect.value) try {
                window.open(props.redirectUrl || '', windowOpenTarget.value, windowOpenParams.value);
                if (props.openIn === OpenIn.NEW_WINDOW) doRedirect();
            } catch (error) {
                logger.error(`Can't open in a new window: ${error}`);
            }
            else // Creates a form with params and then sends it
            processRedirectForm();
        }
    }
    useWindowVisibilityChanged(handleVisibilityChange);
    function beforeUnmount() {
        postMessageBus.value?.dispose();
    }
    watch(postMessageBus, (newValue)=>{
        if (newValue && openInIframe.value && !props.redirectFormParams) listenPostMessage();
    }, {
        immediate: true
    });
    return {
        showIframe,
        iframeName,
        iframeMounted,
        mounted,
        beforeUnmount
    };
}
