import { computed } from 'vue';
import { filterSportsListEvents } from 'web/src/modules/sportline/utils';
export function useRelatedEventsTable(props) {
    const { sportElement } = props;
    const outrightEvents = computed(()=>filterSportsListEvents(sportElement.value ? [
            sportElement.value
        ] : [], (item)=>item.sportEvent.isOutright));
    const doShowOutrightEvents = computed(()=>outrightEvents.value.length > 0);
    return {
        outrightEvents,
        doShowOutrightEvents
    };
}
