export const fastTrackMessageEvents = Object.freeze([
    'shoutout',
    'message',
    'inbox'
]);
export const fastTrackMessageDisplayType = Object.freeze([
    'push',
    'silent',
    ''
]);
