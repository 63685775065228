import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
import { onMounted, ref } from 'vue';
import usePaymentsInput from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/composables/usePaymentsInput';
import { PaymentInput } from 'web/src/components/Input';
import { Currency } from 'web/src/modules/money/enums';
import { PAYMENTS_DEFAULT_INPUT_VALUE } from 'web/src/modules/payments/constants';
import AmountTextInput from 'web/src/components/Input/components/AmountTextInput/AmountTextInput.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsInput',
    props: {
        deposit: {
            type: Boolean
        },
        minAmount: {
            default: 0
        },
        maxAmount: {
            default: 0
        },
        currency: {
            default: Currency.RUB
        },
        balance: {},
        commission: {},
        error: {
            type: Boolean
        },
        inputValue: {
            default: PAYMENTS_DEFAULT_INPUT_VALUE
        },
        bonus: {
            type: Boolean
        },
        allowedIncrement: {
            default: 0
        },
        allowInputSuggestedAmountsOnly: {
            type: Boolean
        },
        isCurrentValueFromSuggestedAmounts: {
            type: Boolean,
            default: true
        },
        suggestedAmounts: {},
        customTextedError: {},
        defaultInitInputValue: {
            default: 0
        },
        name: {
            default: 'PaymentsInput'
        },
        autofocus: {
            type: Boolean
        },
        isCryptoWithdrawal: {
            type: Boolean
        },
        placeholder: {},
        hintRight: {},
        disabled: {
            type: Boolean
        }
    },
    emits: [
        "input",
        "focus",
        "change",
        "blur"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onFocus, onChange, onInput, onBlur, inputLabel, hintRightMessage, errorLeftMessage, errorRightMessage, placeholderText, formatMoneyOptions, currentValue, formattedBalance, paymentsInputMounted } = usePaymentsInput(props, emit);
        onMounted(paymentsInputMounted);
        const input = ref();
        async function focus(options) {
            await input.value?.focus(options);
        }
        __expose({
            focus
        });
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                (_openBlock(), _createBlock(_unref(PaymentInput), {
                    key: 1,
                    autofocus: _ctx.autofocus,
                    name: _ctx.name,
                    value: _unref(currentValue),
                    error: _unref(errorLeftMessage),
                    "error-right": _unref(errorRightMessage),
                    "maximum-fraction-digits": _ctx.deposit ? 0 : 2,
                    "is-label-hidden": false,
                    "is-hint-hidden": false,
                    label: _unref(inputLabel),
                    "hint-right": _unref(hintRightMessage),
                    placeholder: _unref(placeholderText),
                    "format-money-options": _unref(formatMoneyOptions),
                    disabled: _ctx.disabled,
                    onBlur: _unref(onBlur),
                    onChange: _cache[2] || (_cache[2] = ($event)=>_unref(onChange)($event.target.value)),
                    onInput: _cache[3] || (_cache[3] = ($event)=>_unref(onInput)($event.target.value)),
                    onFocus: _unref(onFocus)
                }, null, 8, [
                    "autofocus",
                    "name",
                    "value",
                    "error",
                    "error-right",
                    "maximum-fraction-digits",
                    "is-label-hidden",
                    "is-hint-hidden",
                    "label",
                    "hint-right",
                    "placeholder",
                    "format-money-options",
                    "disabled",
                    "onBlur",
                    "onFocus"
                ])),
                _createCommentVNode("", true)
            ], 64));
    }
});
