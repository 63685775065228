import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "push-info"
};
const _hoisted_2 = {
    class: "push-info-bonus"
};
const _hoisted_3 = {
    class: "push-info-bonus__item"
};
const _hoisted_4 = {
    class: "push-info-bonus__item"
};
const _hoisted_5 = {
    class: "push-info-bonus__item"
};
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WebPushInfo',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_unref(VIcon), {
                            class: "push-info-bonus__item-icon",
                            name: _unref(IconName).CHECK
                        }, null, 8, [
                            "name"
                        ]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_DESC1')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_unref(VIcon), {
                            class: "push-info-bonus__item-icon",
                            name: _unref(IconName).CHECK
                        }, null, 8, [
                            "name"
                        ]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_DESC2')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createVNode(_unref(VIcon), {
                            class: "push-info-bonus__item-icon",
                            name: _unref(IconName).CHECK
                        }, null, 8, [
                            "name"
                        ]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_DESC3')), 1)
                    ])
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_INFO')), 1)
            ])), [
                [
                    _directive_auto_id,
                    'WebPushInfo'
                ]
            ]);
        };
    }
});
