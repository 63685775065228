import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';
import { CustomFilter } from 'web/src/modules/sportline/enums';
export function useSportlineSegmentFilter(props) {
    const { totalCount, allGroup, streamGroup, favoriteGroup, emitChangeFilter, resolveOptionLocation } = props;
    const { $translate } = useI18n();
    const allFilterItem = computed(()=>{
        const group = allGroup.value;
        if (!group) return null;
        const to = resolveOptionLocation(group);
        const icon = {
            type: SportlineNavigationItemType.Text,
            text: String(totalCount.value || 0)
        };
        return {
            key: group.key,
            icon,
            title: $translate('WEB2_SPORTS_QUICKFILTERS_ALL_MATHES').value,
            isActive: group.isActive,
            customFilter: CustomFilter.AllEvents,
            to,
            onClick: ()=>emitChangeFilter({
                    group,
                    to
                }),
            dataTest: CustomFilter.AllEvents,
            transitionId: group.transitionId
        };
    });
    const favoriteFilterItem = computed(()=>{
        const group = favoriteGroup.value;
        if (!group) return null;
        const to = resolveOptionLocation(group);
        const icon = {
            type: SportlineNavigationItemType.Icon,
            props: {
                name: IconName.STAR_ALT
            }
        };
        return {
            key: group.key,
            icon,
            title: $translate('WEB2_SPORT_EVENTS_FILTER_FAVORITE').value,
            isActive: group.isActive,
            customFilter: CustomFilter.Favorites,
            to,
            onClick: ()=>emitChangeFilter({
                    group,
                    to
                }),
            dataTest: CustomFilter.Favorites,
            transitionId: group.transitionId
        };
    });
    const streamFilterItem = computed(()=>{
        const group = streamGroup.value;
        if (!group) return null;
        const to = resolveOptionLocation(group);
        const icon = {
            type: SportlineNavigationItemType.Icon,
            props: {
                name: IconName.STREAM
            }
        };
        return {
            key: group.key,
            icon,
            title: $translate('WEB2_SPORT_EVENTS_FILTER_STREAM').value,
            isActive: group.isActive,
            customFilter: CustomFilter.Stream,
            to,
            onClick: ()=>emitChangeFilter({
                    group,
                    to
                }),
            dataTest: CustomFilter.Stream,
            transitionId: group.transitionId
        };
    });
    return {
        allFilterItem,
        favoriteFilterItem,
        streamFilterItem
    };
}
