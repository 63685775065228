import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import BalanceCard from 'web/src/modules/profile/components/BalanceCard/BalanceCard.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import BalanceValue from 'web/src/modules/profile/components/BalanceValue/BalanceValue.vue';
import { useMainBalance } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainBalance',
    props: {
        mode: {
            default: BalanceMode.Single
        }
    },
    emits: [
        "open-deposit"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { buttonProperties, formattedBalance, balanceLabel, handleClick } = useMainBalance(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(BalanceCard, {
                mode: _ctx.mode,
                class: _normalizeClass(_ctx.$style['main-balance']),
                onClick: _unref(handleClick)
            }, {
                default: _withCtx(()=>[
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createVNode(VButton, _normalizeProps(_guardReactiveProps(_unref(buttonProperties))), null, 16),
                            _createVNode(BalanceValue, {
                                value: _unref(formattedBalance),
                                caption: _ctx.$t('WEB2_MAIN_BALANCE'),
                                "balance-label": _unref(balanceLabel)
                            }, null, 8, [
                                "value",
                                "caption",
                                "balance-label"
                            ])
                        ], 64))
                    ]),
                _: 1
            }, 8, [
                "mode",
                "class",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'MainBalance'
                ]
            ]);
        };
    }
});
