import { computed, ref } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCurrency } from 'web/src/modules/money/composables';
import usePaymentsMaxValueFastChoice from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoice';
export default function usePaymentsFastSumInput(props, emit) {
    const { currency } = useCurrency();
    const analytics = useAnalytics();
    const { maxValueFastChoice } = usePaymentsMaxValueFastChoice(props);
    const selectedChoice = ref('0');
    const loading = ref(true);
    const fastChoiceValue = computed(()=>parseFloat(selectedChoice.value));
    function onFastSumInputMounted() {
        loading.value = false;
    }
    function sendPredefinedSumDepositAnalytics(value) {
        if (props.isDeposit) analytics.push(AnalyticsEvent.Z_PREDEFINED_SUM_DEPOSIT, {
            deposits: {
                predefinedSum: {
                    [currency.value]: maxValueFastChoice.value === value ? 'MAX' : value
                }
            }
        });
    }
    function handleInput(value) {
        selectedChoice.value = value;
        emit('input', {
            target: {
                name: props.name || '',
                value: `${value}`
            }
        });
    }
    return {
        fastChoiceValue,
        onFastSumInputMounted,
        loading,
        handleInput,
        sendPredefinedSumDepositAnalytics
    };
}
