import { useRouter } from 'vue-router';
import { toRef } from 'vue';
import { QueryParameterName } from '@leon-hub/query-manager';
import { getQueryParams } from '@leon-hub/service-locator-env';
import { HelpButtonMode } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import useRootStore from 'web/src/modules/core/store/useRootStore';
import { useIntercomEvents } from 'web/src/modules/intercom/composables';
export default function useTechnicalErrorRoutePage() {
    const router = useRouter();
    const { $translate } = useI18n();
    const { openIntercomMenu } = useIntercomEvents();
    const supportBlock = toRef(useSiteConfigStore(), 'supportBlock');
    const rootStore = useRootStore();
    const initUrl = toRef(rootStore, 'initUrl');
    const isAppMainContentLoaded = toRef(rootStore, 'isAppMainContentLoaded');
    const helpEmail = $translate('WEB2_HELP_EMAIL');
    function refresh() {
        if (isAppMainContentLoaded.value) router.back();
        else {
            const url = router.getPrevHistoryUrl() || '/';
            const runtimePlatform = getQueryParams()[QueryParameterName.PLATFORM];
            if (runtimePlatform) window.location.href = initUrl.value;
            else window.location.href = url;
        }
    }
    function onSupportClick() {
        if (supportBlock.value?.helpButtonMode === HelpButtonMode.EXT_CHAT) openIntercomMenu();
        else window.location.href = `mailto:${helpEmail.value}`;
    }
    return {
        refresh,
        onSupportClick
    };
}
