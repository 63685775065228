import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export class AccessOptionsModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        toRef(()=>useSiteConfigStore().isAccessOptionsEnabled);
        "1";
        router.addAnonymousProfileRoute({
            name: RouteName.ACCESS_OPTIONS,
            path: '/access-options',
            component: ()=>import('web/src/modules/access-options/pages/AccessOptionsListRoutePage/AccessOptionsListRoutePage.vue'),
            title: '{{$t(\'WEB2_ACCESS_TITLE\')}}',
            topBarProps: {
                hidePrefix: true
            },
            isNoMinHeightLimit: true
        });
        if (!process.env.VUE_APP_OS_IOS && !process.env.VUE_APP_OS_MACOS) router.addAnonymousProfileRoute({
            name: RouteName.ACCESS_OPTIONS_ANDROID_APP,
            path: '/access-options-android-app',
            component: ()=>import('web/src/modules/access-options/pages/AccessAndroidAppRoutePage/AccessAndroidAppRoutePage.vue'),
            title: '{{$t(\'WEB2_ACCESS_ANDROID_APP\')}}',
            isNoMinHeightLimit: true
        });
        router.addAnonymousProfileRoute({
            name: RouteName.ACCESS_OPTIONS_TELEGRAM_CHANNEL,
            path: '/access-options-telegram-channel',
            component: ()=>import('web/src/modules/access-options/pages/AccessTelegramChannelRoutePage/AccessTelegramChannelRoutePage.vue'),
            title: '{{$t(\'WEB2_TELEGRAM_CHANNEL\')}}',
            isNoMinHeightLimit: true
        });
        router.addAnonymousProfileRoute({
            name: RouteName.ACCESS_OPTIONS_ADD_BOOKMARK,
            path: '/access-options-add-bookmark',
            component: ()=>import('web/src/modules/access-options/pages/AccessAddBookmarkRoutePage/AccessAddBookmarkRoutePage.vue'),
            title: '{{$t(\'WEB2_ACCESS_BOOKMARK\')}}',
            isNoMinHeightLimit: true
        });
        router.addAnonymousProfileRoute({
            name: RouteName.ACCESS_OPTIONS_CALLBACK,
            path: '/access-options-callback',
            component: ()=>import('web/src/modules/access-options/pages/AccessCallbackRoutePage/AccessCallbackRoutePage.vue'),
            title: '{{$t(\'WEB2_ACCESS_CALLBACK\')}}',
            isNoMinHeightLimit: true
        });
        router.addProfileRoute({
            name: RouteName.ACCESS_OPTIONS_PERSONAL_LINK,
            path: '/access-options-personal-link',
            component: ()=>import('web/src/modules/access-options/pages/AccessPersonalLinkRoutePage/AccessPersonalLinkRoutePage.vue'),
            title: '{{$t(\'WEB2_ACCESS_PERSONAL_LINK\')}}',
            isNoMinHeightLimit: true
        });
    }
}
