import { HintBlockKind } from 'web/src/components/HintBlock/VHintBlock/enums';
const baseClass = 'hint-block';
export default function getBaseClassNames(props, hasTitleSlot) {
    const classNames = [
        baseClass
    ];
    if (props.isCentered) classNames.push(`${baseClass}--centered`);
    if (hasTitleSlot) classNames.push(`${baseClass}--titled`);
    if (!props.hasIcon) classNames.push(`${baseClass}--no-icon`);
    if (props.kind !== HintBlockKind.DEFAULT) classNames.push(`${baseClass}--kind-${props.kind}`);
    return classNames;
}
