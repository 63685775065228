import { ref, watch, computed } from 'vue';
import { defineStore } from 'pinia';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useIsStreamsEnabledRef } from 'web/src/modules/sportline/composables/settings';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useStreamEventsIdsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { getCustomerStreamsRequest } from 'web/src/modules/sportline/utils/api';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { createMatchStreamAccessRef } from '../utils/createMatchStreamAccessRef';
export const useCustomerStreamEventsStore = defineStore('sportline-customer-stream-events', ()=>{
    const isStreamsEnabled = useIsStreamsEnabledRef();
    const { timeout: streamEventsIdsInterval } = useStreamEventsIdsSportlineUpdateTimeout();
    const { isLoggedIn } = useIsLoggedIn();
    const rawCustomerMatchStreamEvents = ref([]);
    const isBackgroundIdsUpdateAvailableSet = ref({});
    const isSyncIdsAvailable = computed(()=>Object.values(isBackgroundIdsUpdateAvailableSet.value).some((enable)=>enable));
    const matchStreamAccess = createMatchStreamAccessRef(isStreamsEnabled, rawCustomerMatchStreamEvents);
    async function getCustomerMatchStreamEvents() {
        if (!isStreamsEnabled.value) {
            rawCustomerMatchStreamEvents.value = [];
            return;
        }
        const response = await getCustomerStreamsRequest({
            silent: true
        });
        rawCustomerMatchStreamEvents.value = response?.customerStreams ? [
            ...response.customerStreams
        ] : [];
    }
    const lastUpdate = new BackgroundUpdateStopwatch({
        streamEventsIds: async ()=>{
            await getCustomerMatchStreamEvents();
            lastUpdate.update('streamEventsIds');
        }
    });
    async function initialRequests() {
        await lastUpdate.forceCallSyncAction('streamEventsIds', {
            silent: true
        });
    }
    async function syncState(param) {
        let { silent } = param;
        if (!isSyncIdsAvailable.value) return;
        await lastUpdate.callSyncAction('streamEventsIds', streamEventsIdsInterval.value, {
            silent
        });
    }
    function setIsBackgroundIdsUpdateAvailable(value) {
        isBackgroundIdsUpdateAvailableSet.value = {
            ...isBackgroundIdsUpdateAvailableSet.value,
            ...value
        };
        if (value) syncState({
            silent: true
        });
    }
    // init store
    watch(isLoggedIn, (value)=>{
        if (value) {
            initialRequests();
            return;
        }
        // clear store on logout
        rawCustomerMatchStreamEvents.value = [];
    }, {
        immediate: true
    });
    useSyncState((silent)=>syncState({
            silent
        }), streamEventsIdsInterval, {
        condition: isSyncIdsAvailable
    });
    return {
        initialRequests,
        setIsBackgroundIdsUpdateAvailable,
        matchStreamAccess
    };
});
