import loadImage from 'web/src/utils/loadImage';
const K_TEST_IMAGES = Object.freeze({
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    // eslint-disable-next-line max-len
    alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    // eslint-disable-next-line max-len
    animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
});
/**
 * [documentation]{@link https://developers.google.com/speed/webp/faq}
 */ export function checkWebpFeature(feature) {
    return loadImage('', `data:image/webp;base64,${K_TEST_IMAGES[feature]}`).then((param)=>{
        let { width, height } = param;
        return width > 0 && height > 0;
    }).catch(()=>false);
}
