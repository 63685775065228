import { defineStore } from 'pinia';
import { ref } from 'vue';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useOpenInCatalog } from 'web/src/modules/sportline/submodules/catalog';
/** @deprecated @TODO split and use composable instead of EventsBus */ export const useSportlineGlobalEventsStore = defineStore('sportline-global-events', ()=>{
    const eventsBus = useEventsBus();
    const { openInCatalogIfEnabled } = useOpenInCatalog();
    let isSportlineEventsInited = false;
    const broadcastEventInfoSubscriptions = ref([]);
    function internalInitOpenCatalogClick() {
        eventsBus.on(BusEvent.CATALOG_CLICK, (param)=>{
            let { event, payload } = param;
            openInCatalogIfEnabled(event, payload);
        });
    }
    function internalInitBroadcastSlipChanges() {
        eventsBus.on(BusEvent.SLIP_SELECTION_CHANGE, (param)=>{
            let { selected } = param;
            broadcastEventInfoSubscriptions.value = selected.map((key)=>({
                    key
                }));
        });
    }
    function initSportlineEvents() {
        if (isSportlineEventsInited) return;
        internalInitOpenCatalogClick();
        internalInitBroadcastSlipChanges();
        isSportlineEventsInited = true;
    }
    return {
        initSportlineEvents,
        broadcastEventInfoSubscriptions
    };
});
