import { FormControlType } from '../../../../enums';
export default function getFormWidgetSchemaProperties() {
    let fieldSchema = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, fieldUiSchema = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, formData = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const widgetsWithRelatedFields = [
        FormControlType.AutocompleteAddress,
        FormControlType.CurrencySelect
    ];
    return {
        enum: fieldSchema.enum,
        maxLength: fieldSchema.maxLength,
        minLength: fieldSchema.minLength,
        pattern: 'pattern' in fieldSchema ? fieldSchema.pattern : void 0,
        labels: 'labels' in fieldUiSchema ? fieldUiSchema.labels : void 0,
        ...fieldUiSchema.widget && widgetsWithRelatedFields.includes(fieldUiSchema.widget) ? {
            formData
        } : {}
    };
}
