import { toRef } from 'vue';
import { useSportlinePersistFilterStore } from '../store';
export function useSportlinePersistLocation() {
    const sportlinePersistFilterStore = useSportlinePersistFilterStore();
    const persistLocation = toRef(sportlinePersistFilterStore, 'persistLocation');
    const { setPersistLocation } = sportlinePersistFilterStore;
    return {
        persistLocation,
        setPersistLocation
    };
}
