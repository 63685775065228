import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { ResponseMappings } from 'web/src/modules/sportline/utils/rest/pre-build';
export class BetlineReplaceResponse {
    /**
   * Update only events from one sport in full list
   */ replaceSportByResponse(incomingResponse, sportFamily) {
        if (!incomingResponse) return this.current ? Object.freeze(this.current) : null;
        const response = BetlineReplaceResponse.unknownResponseToSportEventsResponse(incomingResponse);
        if (!this.current) return response;
        const filteredEvents = this.filterEventsBySport(sportFamily);
        filteredEvents.otherEvents.splice(filteredEvents.startIndex || 0, 0, ...response.events);
        return Object.freeze({
            enabled: response.enabled,
            vtag: response.vtag,
            events: filteredEvents.otherEvents,
            totalCount: filteredEvents.otherEvents.length
        });
    }
    /**
   * Update only events from one region in full list
   */ replaceRegionByResponse(incomingResponse, regionId) {
        if (!incomingResponse) return this.current ? Object.freeze(this.current) : null;
        const response = BetlineReplaceResponse.unknownResponseToSportEventsResponse(incomingResponse);
        if (!this.current) return response;
        const filteredEvents = this.filterEventsByRegion(regionId);
        filteredEvents.otherEvents.splice(filteredEvents.startIndex || 0, 0, ...response.events);
        return Object.freeze({
            enabled: response.enabled,
            vtag: response.vtag,
            events: filteredEvents.otherEvents,
            totalCount: filteredEvents.otherEvents.length
        });
    }
    filterEventsBySport(sportFamily) {
        let startIndex = null;
        const responseMappings = new ResponseMappings();
        const sportEvents = [];
        const otherEvents = (this.current?.events || []).filter((event, index)=>{
            const leagueResponse = responseMappings.getLeagueResponseOrSetNew(event.league);
            const sportResponse = responseMappings.getSportResponseOrSetNew(leagueResponse.sport);
            const isSportFamily = isSportFamilyEquals(sportResponse.family, sportFamily);
            if (isSportFamily) {
                sportEvents.push(event);
                if (null === startIndex) startIndex = index;
                return false;
            }
            return true;
        });
        return {
            sportEvents,
            otherEvents,
            startIndex
        };
    }
    filterEventsByRegion(regionId) {
        let startIndex = null;
        const responseMappings = new ResponseMappings();
        const regionEvents = [];
        const otherEvents = (this.current?.events || []).filter((event, index)=>{
            const leagueResponse = responseMappings.getLeagueResponseOrSetNew(event.league);
            const regionResponse = responseMappings.getRegionResponseOrSetNew(leagueResponse.region);
            const isRegionEvent = regionResponse.id === regionId;
            if (isRegionEvent) {
                regionEvents.push(event);
                if (null === startIndex) startIndex = index;
                return false;
            }
            return true;
        });
        return {
            regionEvents,
            otherEvents,
            startIndex
        };
    }
    /** @deprecated use normalizeEventsChangesResponseToDefaultResponse instead */ static unknownResponseToSportEventsResponse(response) {
        return 'events' in response ? Object.freeze({
            ...response
        }) : Object.freeze({
            enabled: response.enabled,
            events: response.data,
            vtag: response.vtag,
            totalCount: response.data.length
        });
    }
    constructor(current){
        _define_property(this, "current", void 0);
        this.current = current;
    }
}
