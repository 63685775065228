import { computed, ref } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
export default function useVLogoLoader(props) {
    const isVisible = ref(false);
    const { isLight } = useTheme();
    function visibilityChanged(value) {
        isVisible.value = value;
    }
    const iconPath = computed(()=>{
        let name = 'mascot';
        if (!props?.isAnimationDisabled && isVisible.value && !process.env.STORYBOOK_CHROMATIC) name += '-animated';
        if (isLight.value || props.isFixedLightLogo) name += '--light';
        if (isLight.value && props.isGradient) name += '-gradient';
        // eslint-disable-next-line global-require,import/no-dynamic-require
        return require(`web/src/assets/mascot/li/default/${name}.svg`);
    });
    return {
        iconPath,
        visibilityChanged
    };
}
