import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1
};
import { IconName } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VPreloader } from 'web/src/components/Preloader';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { useAccessPersonalLinkRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccessPersonalLinkRoutePage',
    setup (__props) {
        const { created, bookmarkButtonProperties, personalLink, isLoading } = useAccessPersonalLinkRoutePage();
        created();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['access-option-content'])
            }, [
                _unref(isLoading) ? (_openBlock(), _createBlock(_unref(VPreloader), {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['access-option-content__loader'])
                }, null, 8, [
                    "class"
                ])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(VCmsContent, {
                        class: _normalizeClass(_ctx.$style['access-option-content__inner']),
                        "cms-key": "WEB2_ACCESS_PERSONAL_LINK_FIRST_DESCR",
                        silent: ""
                    }, null, 8, [
                        "class"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['access-option-content__personal-link']),
                        onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$copyToClipboard({
                                data: _unref(personalLink) || '',
                                notificationText: _ctx.$t('WEB2_LINK_HAS_BEEN_COPIED'),
                                isProfileModalTooltip: true
                            }))
                    }, [
                        _createTextVNode(_toDisplayString(_unref(personalLink)) + " ", 1),
                        (_openBlock(), _createBlock(LButton, {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['access-option-content__personal-link-copy']),
                            "icon-name": _unref(IconName).FILES,
                            kind: _unref(ButtonKind).PRIMARY,
                            height: _unref(ButtonHeight).LARGE
                        }, null, 8, [
                            "class",
                            "icon-name",
                            "kind",
                            "height"
                        ]))
                    ], 2),
                    _createVNode(VCmsContent, {
                        class: _normalizeClass(_ctx.$style['access-option-content__inner']),
                        "cms-key": "WEB2_ACCESS_PERSONAL_LINK_SECONDARY_DESCR",
                        silent: ""
                    }, null, 8, [
                        "class"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['access-option-content__personal-link-bookmark'])
                    }, [
                        (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 1
                        }, _unref(bookmarkButtonProperties), {
                            href: _unref(personalLink),
                            label: _ctx.$t('WEB2_BRAND_NAME_FULL')
                        }), null, 16, [
                            "href",
                            "label"
                        ]))
                    ], 2)
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'AccessPersonalLinkRoutePage'
                ]
            ]);
        };
    }
});
