import { computed } from 'vue';
export function useOptimisticFavoritesLeagues(props) {
    const { preparedToAdd, preparedToRemove, pendingToFavoriteLeaguesList, customerLeaguesIds } = props;
    const optimisticCustomerLeaguesIds = computed(()=>{
        const pendingIds = pendingToFavoriteLeaguesList.value.map((pending)=>pending.leagueId);
        return Array.from(new Set([
            ...customerLeaguesIds.value ?? [],
            ...preparedToAdd.value,
            ...pendingIds
        ])).filter((id)=>!preparedToRemove.value.includes(id));
    });
    return {
        optimisticCustomerLeaguesIds
    };
}
