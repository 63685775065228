import { computed } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useBannerTexts(banner) {
    const formatMoney = useFormatMoney();
    const analytics = useAnalytics();
    function applyMoneyPlaceholder(string, money) {
        if (!money || !string.includes('{money}')) return string;
        const moneyPlaceholderValue = formatMoney(money.value, {
            currency: money.currency
        });
        return string.replace(/{money}/g, moneyPlaceholderValue);
    }
    const headline = computed(()=>banner.value?.headline ? applyMoneyPlaceholder(banner.value.headline, banner.value.money) : void 0);
    const secondaryText = computed(()=>banner.value?.secondaryText ? applyMoneyPlaceholder(banner.value.secondaryText, banner.value.money) : void 0);
    const buttonText = computed(()=>banner.value?.buttonText ? applyMoneyPlaceholder(banner.value.buttonText, banner.value.money) : void 0);
    function onClick() {
        if (banner.value && banner.value.url === `/${String(RouteName.VIP)}`) analytics.push(AnalyticsEvent.Z_CLICK_TO_OPEN_VIP, {
            vip_program: {
                click_to_open: 'mini_banner'
            }
        });
    }
    return {
        headline,
        secondaryText,
        buttonText,
        onClick
    };
}
