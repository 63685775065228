import { MatchProgressTimeDirection } from 'web/src/modules/sportline/enums';
import { LiveStatusTimeDirection } from 'web/src/modules/sportline/enums/rest';
import LiveStatusParseStrategy from './LiveStatusParseStrategy';
import parseScore from './parseScore';
import getTimeCorrection from './getTimeCorrection';
let FullProgressLiveStatusParseStrategy = class FullProgressLiveStatusParseStrategy extends LiveStatusParseStrategy {
    getMainTime() {
        const { fullProgress, createAt } = this.liveStatus;
        if (!fullProgress) return super.getMainTime();
        const { time } = fullProgress;
        if (time?.seconds === void 0) return super.getMainTime();
        if (time?.minutes === void 0) return;
        const correction = getTimeCorrection(createAt, this.getTimeDirection());
        return {
            minutes: time.minutes + correction.minutes,
            seconds: time.seconds + correction.seconds
        };
    }
    getAdditionalTime() {
        const { fullProgress, createAt } = this.liveStatus;
        if (!fullProgress) return super.getAdditionalTime();
        const { time, additionalTime } = fullProgress;
        if (time?.seconds === void 0) return super.getAdditionalTime();
        if (additionalTime?.minutes === void 0 && additionalTime?.seconds === void 0) return;
        const correction = getTimeCorrection(createAt, this.getTimeDirection());
        return {
            minutes: (additionalTime.minutes || 0) + correction.minutes,
            seconds: (additionalTime.seconds || 0) + correction.seconds
        };
    }
    getTimeDirection() {
        const { fullProgress } = this.liveStatus;
        if (!fullProgress) return super.getTimeDirection();
        if (fullProgress.timeDirection === LiveStatusTimeDirection.Direct) return MatchProgressTimeDirection.Direct;
        if (fullProgress.timeDirection === LiveStatusTimeDirection.Reverse) return MatchProgressTimeDirection.Reversed;
        return MatchProgressTimeDirection.None;
    }
    getCurrentSetScore() {
        const { fullProgress } = this.liveStatus;
        if (!fullProgress) return super.getCurrentSetScore();
        const parsedScore = parseScore(fullProgress.score);
        const [host, guest] = parsedScore?.parts || [];
        return host && guest ? {
            host,
            guest,
            delimiter: parsedScore?.delimiter
        } : void 0;
    }
};
export { FullProgressLiveStatusParseStrategy as default };
