import { computed, watch } from 'vue';
import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import { DateTimeFilterTabId, SportlineType } from 'web/src/modules/sportline/enums';
import { findDefaultKickoffPeriodByTabId } from 'web/src/modules/sportline/components/date-time-filter/utils';
export function useDateTimeFilterWatchProps(props) {
    const { activeKickoffPeriod, activeSportlineType, preparedForActivateTab, setActiveTab } = props;
    const activePropsKey = computed(()=>`${activeKickoffPeriod.value}_${activeSportlineType.value}`);
    watch(activePropsKey, ()=>{
        switch(true){
            case activeSportlineType.value === SportlineType.Live:
                setActiveTab(DateTimeFilterTabId.LIVE, preparedForActivateTab.value);
                return;
            case activeKickoffPeriod.value === SportFilterKickoffPeriod.CUSTOM:
                setActiveTab(DateTimeFilterTabId.DATE_RANGE, preparedForActivateTab.value);
                return;
            default:
                {
                    const tabId = findDefaultKickoffPeriodByTabId(activeKickoffPeriod.value);
                    setActiveTab(tabId ?? DateTimeFilterTabId.ALL, preparedForActivateTab.value);
                }
        }
    }, {
        immediate: true
    });
}
