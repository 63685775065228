import { isValidJsonSchema } from '@leon-hub/guards';
function isArrayWithStringsAndNullsOptional(value) {
    if (void 0 !== value) return isValidJsonSchema(value, {
        type: 'array',
        items: {
            anyOf: [
                {
                    type: 'string'
                },
                {
                    type: 'null'
                }
            ]
        }
    });
    return true;
}
function getErrorArrayFields(options, errorValue) {
    const passedErrorValue = options?.error || errorValue;
    if (!passedErrorValue) return {};
    const isRightHint = !!options?.isHintRight;
    const computedErrorValue = 'string' == typeof passedErrorValue ? [
        passedErrorValue
    ] : passedErrorValue;
    isArrayWithStringsAndNullsOptional(computedErrorValue);
    return {
        [isRightHint ? 'errorRight' : 'error']: computedErrorValue
    };
}
export default getErrorArrayFields;
