import { computed, ref, watch } from 'vue';
import DateTime from 'web/src/utils/DateTime';
import createUniqueId from 'web/src/utils/createUniqueId';
import { DateInputType, InputEventType } from 'web/src/components/Input/enums';
import { applyLimitationsTo, getMaxDate, getMinDate, getTargetValue } from '../../utils';
export default function useNativeDateInput(props, emit) {
    const uniqueId = computed(()=>createUniqueId(props.name || ''));
    const systemInputValue = ref('');
    const systemInputMin = ref('');
    const systemInputMax = ref('');
    const displayingValue = ref('');
    const minDate = computed(()=>getMinDate(props.min));
    const maxDate = computed(()=>getMaxDate(props.max));
    const setSystemInputValue = (value)=>{
        if (!value) {
            systemInputValue.value = '';
            displayingValue.value = '';
            return;
        }
        const timestamp = applyLimitationsTo(value, minDate.value, maxDate.value, props.roundTo);
        switch(props.type){
            case DateInputType.Date:
                systemInputValue.value = DateTime.withTimeStamp(timestamp).toInputDate();
                displayingValue.value = DateTime.withTimeStamp(timestamp).toFullDate();
                break;
            case DateInputType.DateTime:
                systemInputValue.value = DateTime.withTimeStamp(timestamp).toInputDateTimeLocal();
                displayingValue.value = DateTime.withTimeStamp(timestamp).toDateTime();
                break;
            default:
                throw new Error(`Unknown DateInput type "${props.type}"`);
        }
    };
    const setSystemInputMin = (value)=>{
        if (!value) {
            systemInputMin.value = '';
            return;
        }
        switch(props.type){
            case DateInputType.Date:
                systemInputMin.value = DateTime.withTimeStamp(value).toInputDate();
                break;
            case DateInputType.DateTime:
                systemInputMin.value = DateTime.withTimeStamp(value).toInputDateTimeLocal();
                break;
            default:
                throw new Error(`Unknown DateInput type "${props.type}"`);
        }
        setSystemInputValue(applyLimitationsTo(props.value || 0, minDate.value, maxDate.value, props.roundTo));
    };
    const setSystemInputMax = (value)=>{
        if (!value) {
            systemInputMax.value = '';
            return;
        }
        switch(props.type){
            case DateInputType.Date:
                systemInputMax.value = DateTime.withTimeStamp(value).toInputDate();
                break;
            case DateInputType.DateTime:
                systemInputMax.value = DateTime.withTimeStamp(value).toInputDateTimeLocal();
                break;
            default:
                throw new Error(`Unknown DateInput type "${props.type}"`);
        }
        setSystemInputValue(applyLimitationsTo(props.value || 0, minDate.value, maxDate.value, props.roundTo));
    };
    watch(()=>props.value, (to)=>{
        setSystemInputValue(to);
    }, {
        immediate: true
    });
    watch(()=>props.min, ()=>{
        setSystemInputMin(minDate.value);
    }, {
        immediate: true
    });
    watch(()=>props.max, ()=>{
        setSystemInputMax(maxDate.value);
    }, {
        immediate: true
    });
    watch(()=>props.type, ()=>{
        setSystemInputValue(props.value);
        setSystemInputMin(minDate.value);
        setSystemInputMax(maxDate.value);
    });
    const onChange = (event)=>{
        const value = getTargetValue(event);
        if (value) {
            const timestamp = applyLimitationsTo(DateTime.toTimestamp(value), minDate.value, maxDate.value, props.roundTo);
            setSystemInputValue(timestamp);
            emit(InputEventType.CHANGE, {
                ...event,
                timestamp
            });
        } else // clear button
        emit(InputEventType.CHANGE, {
            ...event
        });
    };
    const onInput = (event)=>{
        emit(InputEventType.INPUT, event);
    };
    const onBlur = (event)=>{
        emit(InputEventType.BLUR, event);
    };
    const onFocus = (event)=>{
        emit(InputEventType.FOCUS, event);
    };
    return {
        uniqueId,
        systemInputMax,
        systemInputMin,
        systemInputValue,
        displayingValue,
        onBlur,
        onInput,
        onFocus,
        onChange
    };
}
