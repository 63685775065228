import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export function useSportlineAnalytics() {
    const analytics = useAnalytics();
    return {
        /** Sports zero margin tab click */ zeroMarginTabClick () {
            analytics.push(AnalyticsEvent.CLICK_MAP, {
                clickCounter: {
                    margin_0: 'shortcuts'
                }
            });
        },
        /** Sidebar zero margin tab click */ zeroMarginSidebarTabClick () {
            analytics.push(AnalyticsEvent.CLICK_MAP, {
                clickCounter: {
                    margin_0: 'button'
                }
            });
        },
        searchClick () {
            analytics.push(AnalyticsEvent.CLICK_MAP, {
                clickCounter: {
                    search: 'headerSearch'
                }
            });
        }
    };
}
