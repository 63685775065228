import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCashoutButton } from '../composables/useCashoutButton';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CashoutButton',
    props: {
        cashoutAmount: {},
        stakeAmount: {}
    },
    emits: [
        "click"
    ],
    setup (__props) {
        const props = __props;
        const { formattedPriceCashout, iconArrowCashout, isShowIconArrow, isHover, onPointerIn, onPointerOut } = useCashoutButton(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(LButton, {
                class: _normalizeClass({
                    [_ctx.$style['cashout-button']]: true,
                    [_ctx.$style['cashout-button--hover']]: _unref(isHover)
                }),
                kind: _unref(isHover) ? _unref(ButtonKind).PRIMARY : _unref(ButtonKind).PRIMARY_OPACITY,
                height: _unref(ButtonHeight).LARGE,
                "is-uppercase": false,
                "full-width": "",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>_ctx.$emit('click'), [
                    "prevent",
                    "stop"
                ])),
                onMouseenter: _unref(onPointerIn),
                onMouseleave: _unref(onPointerOut),
                onTouchstart: _unref(onPointerIn),
                onTouchend: _unref(onPointerOut),
                onTouchcancel: _unref(onPointerOut)
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['cashout-button__label'])
                        }, _toDisplayString(_ctx.$t('WEB2_CASHOUT_DETAILS_BUTTON_LABEL')), 3),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['cashout-button__suffix'])
                        }, [
                            _unref(isShowIconArrow) ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['cashout-button__icon'])
                            }, [
                                _createVNode(_unref(VIcon), {
                                    size: _unref(IconSize).SIZE_16,
                                    name: _unref(iconArrowCashout)
                                }, null, 8, [
                                    "size",
                                    "name"
                                ])
                            ], 2)) : _createCommentVNode("", true),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['cashout-button__amount'])
                            }, _toDisplayString(_unref(formattedPriceCashout)), 3)
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "class",
                "kind",
                "height",
                "onMouseenter",
                "onMouseleave",
                "onTouchstart",
                "onTouchend",
                "onTouchcancel"
            ])), [
                [
                    _directive_auto_id,
                    'CashoutButton'
                ]
            ]);
        };
    }
});
