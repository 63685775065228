import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { useIsFavoriteEvent } from './useIsFavoriteEvent';
import useCustomerFavoritesService from './useCustomerFavoritesService';
export function useFavoriteSportEventData(props) {
    const { sportlineEventId, sportlineEventType } = props;
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const { toggleCustomerSportEvent } = useCustomerFavoritesService();
    const settingsStore = useSportlineSettingsStore();
    const isPrematchFavoriteEnabled = toRef(()=>settingsStore.isPrematchFavoriteEnabled);
    const isFavorite = useIsFavoriteEvent(sportlineEventId);
    const canFavorite = computed(()=>!!sportlineEventType.value && (sportlineEventType.value === SportlineType.Live || isPrematchFavoriteEnabled.value));
    async function emitChangeFavoriteState() {
        if (!sportlineEventId.value) return;
        await toggleCustomerSportEvent({
            id: sportlineEventId.value,
            state: !isFavorite.value
        });
        if (!isLoggedIn.value) router.push({
            name: RouteName.LOGIN
        });
    }
    return {
        isFavorite,
        canFavorite,
        emitChangeFavoriteState
    };
}
