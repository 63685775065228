import { computed, onBeforeUnmount, onMounted, ref, toRef } from 'vue';
import { useAffiliateStore } from 'web/src/modules/affiliates/store';
import { isValidVSelectOption } from 'web/src/components/Select/guards/isValidVSelectOptions';
export default function useOperationHistory() {
    const affiliateStore = useAffiliateStore();
    const operationsHistory = toRef(affiliateStore, 'operationsHistory');
    const dateRangeFilterOptions = toRef(affiliateStore, 'dateRangeFilterOptions');
    const selectedOperationsFilter = toRef(affiliateStore, 'selectedOperationsFilter');
    const isReady = computed(()=>null !== operationsHistory.value);
    const isDataLoading = ref(true);
    const isResetFilter = ref(true);
    const operations = ref([]);
    const selectProperties = computed(()=>({
            name: 'dateSelect',
            options: dateRangeFilterOptions.value,
            selectedValue: selectedOperationsFilter.value.value,
            noBorder: true
        }));
    onMounted(fetchOperationsHistory);
    onBeforeUnmount(()=>{
        if (isResetFilter.value) affiliateStore.setSelectedOperationsFilter(void 0);
    });
    async function onFilter(event) {
        const toSelected = dateRangeFilterOptions.value.find((selectOption)=>selectOption.value === event.target.value);
        isValidVSelectOption(toSelected);
        affiliateStore.setSelectedOperationsFilter(toSelected);
        await fetchOperationsHistory();
    }
    async function fetchOperationsHistory() {
        isDataLoading.value = true;
        await affiliateStore.getOperationsHistory({
            page: 0,
            range: selectedOperationsFilter.value.value
        });
        operations.value = operationsHistory.value?.operations ?? [];
        isDataLoading.value = false;
    }
    function setSelectedOperation(value) {
        affiliateStore.setSelectedOperation(value);
    }
    function setResetFilter(state) {
        isResetFilter.value = state;
    }
    return {
        operations,
        isDataLoading,
        selectProperties,
        isReady,
        onFilter,
        setSelectedOperation,
        setResetFilter
    };
}
