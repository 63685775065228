import { isNumber, isString, isUndefined, isValidInterface } from '@leon-hub/guards';
import { LiveStatusTimeDirection } from 'web/src/modules/sportline/enums/rest';
export function isLiveStatusTimeDirection(value) {
    return isString(value) && Object.values(LiveStatusTimeDirection).includes(value);
}
export function isLiveStatusTime(value) {
    return isValidInterface(value, {
        minutes: [
            isUndefined,
            isNumber
        ],
        seconds: [
            isUndefined,
            isNumber
        ]
    });
}
export function isLiveStatusProgress(value) {
    return isValidInterface(value, {
        score: [
            isUndefined,
            isString
        ],
        time: [
            isUndefined,
            isLiveStatusTime
        ],
        additionalTime: [
            isUndefined,
            isLiveStatusTime
        ],
        timeDirection: [
            isUndefined,
            isLiveStatusTimeDirection
        ]
    });
}
export function isLiveStatusIncidentStatistics(value) {
    return isValidInterface(value, {
        corners: [
            isUndefined,
            isNumber
        ],
        redCards: [
            isUndefined,
            isNumber
        ],
        yellowCards: [
            isUndefined,
            isNumber
        ],
        yellowRedCards: [
            isUndefined,
            isNumber
        ],
        currentSuspensionCount: [
            isUndefined,
            isNumber
        ]
    });
}
export function isLiveStatusResponse(value) {
    return isValidInterface(value, {
        stage: isString,
        progress: [
            isUndefined,
            isString
        ],
        score: isString,
        setScores: [
            isUndefined,
            isString
        ],
        penaltyScore: [
            isUndefined,
            isString
        ],
        fullProgress: [
            isUndefined,
            isLiveStatusProgress
        ],
        createAt: [
            isUndefined,
            isNumber
        ],
        homeStatistics: [
            isUndefined,
            isLiveStatusIncidentStatistics
        ],
        awayStatistics: [
            isUndefined,
            isLiveStatusIncidentStatistics
        ]
    });
}
