import { computed, ref, watch } from 'vue';
import createUniqueId from 'web/src/utils/createUniqueId';
import { InputEventType } from 'web/src/components/Input/enums';
export default function useFileSingleInput(props, emit) {
    const uniqueId = computed(()=>createUniqueId(props.name || 'input'));
    const inputFileName = ref(props.fileName || '');
    const file = ref(null);
    const eventToEmit = computed(()=>({
            target: {
                files: file.value,
                name: props.name || ''
            }
        }));
    const onFocus = ()=>{
        emit(InputEventType.FOCUS, eventToEmit.value);
    };
    const onBlur = ()=>{
        emit(InputEventType.BLUR, eventToEmit.value);
    };
    const emitChange = ()=>{
        emit(InputEventType.CHANGE, eventToEmit.value);
    };
    const onClear = ()=>{
        inputFileName.value = '';
        file.value = null;
        emitChange();
    };
    const onChange = (param)=>{
        let { target } = param;
        HTMLInputElement;
        const files = target.files || [];
        if (files.length) {
            const newFile = files[0] || {};
            if (newFile.name !== inputFileName.value) {
                file.value = target.files;
                inputFileName.value = newFile.name;
                emitChange();
            }
        }
    };
    const onFileNameChange = function() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        inputFileName.value = value || '';
    };
    watch(()=>props.fileName, onFileNameChange);
    return {
        uniqueId,
        inputFileName,
        onFocus,
        onBlur,
        onClear,
        onChange
    };
}
