import { filterRegionsListEvents } from './filterRegionElementEvents';
import { reduceListWithSportEventsCounters } from '../getCounters';
export default function filterSportsListEvents(sportElements, filter) {
    return sportElements.map((sportElement)=>{
        const regions = filterRegionsListEvents(sportElement.regions, filter);
        return {
            ...sportElement,
            regions,
            counters: reduceListWithSportEventsCounters(regions)
        };
    }).filter((sportElement)=>sportElement.regions.length > 0);
}
