import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Teleport as _Teleport, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount } from 'vue';
import { useVCoreDropdown } from './composables';
import { DropdownPreferredDirection } from './enums/DropdownPreferredDirection';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCoreDropdown',
    props: {
        appearZonePositionX: {},
        appearZonePositionY: {},
        appearZoneOffsetY: {},
        appearZoneMaxWidth: {},
        appearZoneMinWidth: {},
        matchParentWidth: {
            type: Boolean
        },
        heightLimit: {},
        ignoreActiveElementClick: {
            type: Boolean
        },
        ignoreScroll: {
            type: Boolean
        },
        isModal: {
            type: Boolean
        },
        isBehindModals: {
            type: Boolean
        },
        verticalOffset: {},
        dropDirection: {
            default: DropdownPreferredDirection.AUTO
        }
    },
    emits: [
        "close",
        "update-position"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { dropdownContainer, style, onAppear, onDisappear, onContentRedraw, stylesIsReady, maxHeight, dropToTop } = useVCoreDropdown(props, emit);
        onBeforeUnmount(onDisappear);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_Teleport, {
                to: "body"
            }, [
                _createElementVNode("div", {
                    ref_key: "dropdownContainer",
                    ref: dropdownContainer,
                    style: _normalizeStyle(_ctx.isModal ? void 0 : _unref(style)),
                    class: _normalizeClass({
                        [_ctx.$style['dropdown']]: true,
                        [_ctx.$style['dropdown--hidden']]: !_unref(stylesIsReady),
                        [_ctx.$style['dropdown--modal']]: _ctx.isModal,
                        [_ctx.$style['dropdown--behind-modals']]: _ctx.isBehindModals
                    })
                }, [
                    _renderSlot(_ctx.$slots, "default", {
                        maxHeight: _unref(maxHeight),
                        directionTop: _unref(dropToTop),
                        mounted: _unref(onAppear),
                        onContentRedraw: _unref(onContentRedraw)
                    })
                ], 6)
            ])), [
                [
                    _directive_auto_id,
                    'VCoreDropdown'
                ]
            ]);
        };
    }
});
