import { watch } from 'vue';
/**
 * Util for await condition become true
 */ export default function useAwaitConditionQueue(props) {
    const { value, condition, source, flush = 'sync' } = props;
    let queue = [];
    let savedOldValue;
    watch(value, (newValue, oldValue)=>{
        if (condition(newValue, oldValue)) {
            for (const method of queue)method(newValue);
            queue = [];
        }
        savedOldValue = newValue;
    }, {
        immediate: true,
        flush
    });
    function removeFromQueue(callback) {
        queue = queue.filter((element)=>element !== callback);
    }
    function awaitCondition(callback, callbackRejected) {
        let rejectAfter = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 10000;
        return new Promise((resolve, reject)=>{
            let timeout = null;
            const currentValue = value.value;
            const resolveCondition = (resolvedValue)=>{
                if (timeout) {
                    window.clearTimeout(timeout);
                    timeout = null;
                }
                callback?.(resolvedValue);
                resolve(resolvedValue);
            };
            if (condition(currentValue, savedOldValue)) {
                resolveCondition(currentValue);
                return;
            }
            // if state is not ready await for change
            queue.push(resolveCondition);
            if (void 0 === rejectAfter) return;
            window.setTimeout(()=>{
                removeFromQueue(resolveCondition);
                callbackRejected?.();
                const sourceInfo = source ? ` in ${source}` : '';
                reject(new Error([
                    'Await condition failed by timeout',
                    sourceInfo,
                    '.'
                ].join('')));
            }, rejectAfter);
        });
    }
    return {
        awaitCondition,
        getQueueLength () {
            // Used for specs
            return queue.length;
        }
    };
}
