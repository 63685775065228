import { ref } from 'vue';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
export function usePaymentSystemRequestTime() {
    const paymentConfig = usePaymentsConfig();
    const lastPaymentSystemRequestTime = ref(null);
    function setLastPaymentSystemRequestTime() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : Date.now();
        lastPaymentSystemRequestTime.value = value;
    }
    function isReadyToRequest() {
        return !lastPaymentSystemRequestTime.value || Date.now() - lastPaymentSystemRequestTime.value > paymentConfig.paymentsListsReloadTimeout.value;
    }
    return {
        lastPaymentSystemRequestTime,
        isReadyToRequest,
        setLastPaymentSystemRequestTime
    };
}
