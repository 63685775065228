import { storeToRefs } from 'pinia';
import { isObject, isString } from '@leon-hub/guards';
import { useCountryStore } from 'web/src/modules/country/store';
import { isPartialFormPhoneValue } from 'web/src/components/Form/guards';
const matchPhoneNumberAndCountryCode = require('web/src/utils/phoneNumberUtils/matchPhoneNumberAndCountryCode').default;
export default function useFormDefaultPhoneValue() {
    const { currentCustomerCountryData, countriesData } = storeToRefs(useCountryStore());
    const normalizePhoneInput = function() {
        let input = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        if ('number' == typeof input) return String(input);
        return input.replace(/^\+/, '').replace(/\s+/g, '').replace(/\(|\)/g, '').replace(/-/g, '');
    };
    const getPrefix = (countryCode)=>{
        const matchedPrefix = countriesData.value.find((param)=>{
            let { code } = param;
            return code.toLowerCase() === countryCode.toLowerCase();
        })?.phonePrefix;
        return matchedPrefix ? `${matchedPrefix}` : null;
    };
    const isValidFormPhoneValue = (value)=>{
        const countryCodeIso2Length = 2;
        return isObject(value) && isString(value.prefix) && isString(value.suffix) && isString(value.countryCode) && value.countryCode.length === countryCodeIso2Length;
    };
    const parsePhone = (source)=>{
        const normalizedSource = normalizePhoneInput(source);
        const matchedPrefixes = countriesData.value.filter((param)=>{
            let { phonePrefix } = param;
            return normalizedSource.startsWith(`${phonePrefix}`);
        });
        if (!matchedPrefixes.length) throw new Error(`unable to parse phone number: ${normalizedSource}`);
        const prefix = matchedPrefixes[0].phonePrefix.toString();
        const suffix = normalizedSource.slice(prefix.length);
        return {
            prefix,
            suffix,
            countryCode: 1 === matchedPrefixes.length ? matchedPrefixes[0].code : matchPhoneNumberAndCountryCode({
                phoneNumber: suffix,
                countries: countriesData.value,
                countryCode: currentCustomerCountryData.value.code,
                prefix
            })
        };
    };
    const parsePhoneNumberByUIField = (param)=>{
        let { defaultValue } = param;
        let defaultCountryCode;
        defaultCountryCode = currentCustomerCountryData.value.code;
        const defaultPrefix = getPrefix(defaultCountryCode);
        if (isPartialFormPhoneValue(defaultValue)) {
            const phoneNumber = parsePhone(`${defaultValue.prefix ?? defaultPrefix}${defaultValue.suffix ?? ''}`);
            if (isValidFormPhoneValue(phoneNumber)) return phoneNumber;
        }
        if (defaultValue && isString(defaultValue)) {
            const phoneNumber = parsePhone(defaultValue);
            if (isValidFormPhoneValue(phoneNumber)) return phoneNumber;
        }
        return {
            prefix: defaultPrefix ?? '',
            countryCode: defaultCountryCode,
            suffix: ''
        };
    };
    const getDefaultPhoneValueForForm = function() {
        let payload = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        let defaultPrefix;
        defaultPrefix = currentCustomerCountryData.value.phonePrefix.toString();
        const prefix = payload.prefix ?? defaultPrefix;
        const matchedCountryCode = matchPhoneNumberAndCountryCode({
            prefix,
            phoneNumber: payload.suffix,
            countryCode: currentCustomerCountryData.value.code,
            countries: countriesData.value
        });
        return {
            prefix,
            countryCode: matchedCountryCode,
            suffix: payload.suffix || ''
        };
    };
    return {
        getDefaultPhoneValueForForm,
        parsePhone,
        parsePhoneNumberByUIField
    };
}
