import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id"
];
import { ref } from 'vue';
import OverlayType from 'web/src/components/Modal/VOverlay/enums/OverlayType';
import VOverlay from 'web/src/components/Modal/VOverlay/VOverlay.vue';
import { ModalWidth, ModalHeight } from 'web/src/components/Modal/enums';
import { useDefaultModal } from './composables';
import ModalWindow from '../ModalWindow/ModalWindow.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DefaultModal',
    props: {
        image: {},
        alertIconSize: {},
        icon: {},
        iconKind: {},
        iconSize: {},
        header: {},
        content: {},
        topBarText: {},
        buttons: {},
        isCloseAllowed: {
            type: Boolean,
            default: true
        },
        isOverlayCloseAllowed: {
            type: Boolean,
            default: true
        },
        isCloseAsBack: {
            type: Boolean
        },
        isAlert: {
            type: Boolean
        },
        isOverlayVisible: {
            type: Boolean,
            default: true
        },
        isTopBarHidden: {
            type: Boolean
        },
        isScrollBarEnabled: {
            type: Boolean
        },
        width: {
            default: ModalWidth.DEFAULT
        },
        height: {
            default: ModalHeight.DEFAULT
        },
        isCentered: {
            type: Boolean,
            default: true
        },
        isFullHeight: {
            type: Boolean
        },
        hasMinHeight: {
            type: Boolean
        },
        isFullHeightCentered: {
            type: Boolean,
            default: true
        },
        innerModalContainerId: {},
        hasInnerModal: {
            type: Boolean
        },
        isInnerModal: {
            type: Boolean
        },
        hasInnerModalFullHeight: {
            type: Boolean
        },
        modalKey: {},
        useScrollListener: {
            type: Boolean
        },
        isProfileLayout: {
            type: Boolean
        },
        hasNoScroll: {
            type: Boolean
        },
        customBackground: {},
        isFullScreen: {
            type: Boolean
        },
        overlayType: {
            default: OverlayType.BLUR_L1
        },
        isSmallWidthContent: {
            type: Boolean
        },
        dataTestId: {},
        isPaddingTopBig: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click",
        "scroll-reached-bottom"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const modalWindow = ref();
        const { outerModalContainerId, containerId, modalWindowProps, onOverlayClick, onClose } = useDefaultModal(props, emit);
        function emitButtonClick(value) {
            emit('button-click', value);
        }
        function emitScrollReachedBottom() {
            emit('scroll-reached-bottom');
        }
        function scrollTop() {
            modalWindow.value?.scrollTop();
        }
        function scrollToElement(element) {
            modalWindow.value?.scrollToElement(element);
        }
        function scrollUpdate() {
            modalWindow.value?.scrollUpdate();
        }
        __expose({
            scrollTop,
            scrollToElement,
            scrollUpdate
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                id: _unref(containerId),
                class: _normalizeClass({
                    [_ctx.$style['modal']]: true,
                    [_ctx.$style['modal--alert']]: props.isAlert,
                    [_ctx.$style['modal--centered']]: props.isCentered,
                    [_ctx.$style['modal--min-height']]: props.hasMinHeight,
                    [_ctx.$style['modal--has-inner']]: props.hasInnerModal && !props.hasInnerModalFullHeight,
                    [_ctx.$style[`modal--width-${props.width}`]]: true,
                    [_ctx.$style[`modal--height-${props.height}`]]: true,
                    [_ctx.$style['modal--full-height']]: props.isFullHeight,
                    [_ctx.$style['modal--profile']]: props.isProfileLayout,
                    [_ctx.$style['modal--full-screen']]: props.isFullScreen,
                    [_ctx.$style['modal--has-custom-bg']]: !!props.customBackground,
                    [_ctx.$style['modal--is-padding-top-big']]: props.isPaddingTopBig
                })
            }, [
                _ctx.isInnerModal ? (_openBlock(), _createBlock(_Teleport, {
                    key: 0,
                    to: `#${_unref(outerModalContainerId)}`,
                    disabled: !_ctx.isInnerModal
                }, [
                    _ctx.isInnerModal ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['modal__inner-overlay']),
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onOverlayClick) && _unref(onOverlayClick)(...args);
                        })
                    }, null, 2)) : _createCommentVNode("", true)
                ], 8, [
                    "to",
                    "disabled"
                ])) : _createCommentVNode("", true),
                _ctx.isOverlayVisible ? (_openBlock(), _createBlock(VOverlay, {
                    key: 1,
                    type: _ctx.overlayType,
                    class: _normalizeClass(_ctx.$style['modal__overlay']),
                    onClick: _unref(onOverlayClick)
                }, null, 8, [
                    "type",
                    "class",
                    "onClick"
                ])) : _createCommentVNode("", true),
                _createVNode(ModalWindow, _mergeProps(_unref(modalWindowProps), {
                    id: _ctx.innerModalContainerId,
                    ref_key: "modalWindow",
                    ref: modalWindow,
                    class: _ctx.$style['modal__window'],
                    style: {
                        background: _ctx.customBackground
                    },
                    onClose: _unref(onClose),
                    onButtonClick: emitButtonClick,
                    onScrollReachedBottom: emitScrollReachedBottom
                }), _createSlots({
                    _: 2
                }, [
                    _ctx.$slots.default ? {
                        name: "default",
                        fn: _withCtx(()=>[
                                _renderSlot(_ctx.$slots, "default")
                            ]),
                        key: "0"
                    } : void 0,
                    _ctx.$slots.header ? {
                        name: "header",
                        fn: _withCtx(()=>[
                                _renderSlot(_ctx.$slots, "header")
                            ]),
                        key: "1"
                    } : void 0,
                    _ctx.$slots['inner-content'] ? {
                        name: "inner-content",
                        fn: _withCtx(()=>[
                                _renderSlot(_ctx.$slots, "inner-content")
                            ]),
                        key: "2"
                    } : _ctx.$slots.icon ? {
                        name: "icon",
                        fn: _withCtx(()=>[
                                _renderSlot(_ctx.$slots, "icon")
                            ]),
                        key: "3"
                    } : void 0,
                    _ctx.$slots.content ? {
                        name: "content",
                        fn: _withCtx(()=>[
                                _renderSlot(_ctx.$slots, "content")
                            ]),
                        key: "4"
                    } : void 0,
                    _ctx.$slots.footer ? {
                        name: "footer",
                        fn: _withCtx(()=>[
                                _renderSlot(_ctx.$slots, "footer")
                            ]),
                        key: "5"
                    } : void 0
                ]), 1040, [
                    "id",
                    "class",
                    "style",
                    "onClose"
                ])
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'DefaultModal'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'modal',
                        type: _ctx.iconKind || 'unknown',
                        modalId: `modal-${_ctx.dataTestId}` || ''
                    }
                ]
            ]);
        };
    }
});
