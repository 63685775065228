import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue";
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config-names';
import { ButtonKind } from '@leon-hub/module-buttons';
import { VLoader } from '@components/loader';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import { VForm } from 'web/src/components/Form';
import useLoginPage from 'web/src/modules/login/pages/LoginRoutePage/composables/useLoginPage';
import { useDisableCookiesGuard } from 'web/src/modules/dialogs/composables/useDisableCookiesGuard';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import { SwitcherKind } from 'web/src/components/Switcher/VSwitcher/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LoginTermsFlow from './components/LoginTermsFlow.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LoginRoutePage',
    props: {
        isFlowLogin: {
            type: Boolean
        }
    },
    setup (__props) {
        useDisableCookiesGuard();
        const { loginForm, tabsProperties, onTabClick, isSubmitPending, isFormReady, schema, uiSchema, onChange, onInput, onSignIn, incorrectLoginMessage, incorrectHintBlockProperties, submitButtonProperties, onClickSingInButton, onRegistrationButtonClick, isCaptchaEnabled, loginType, // for form key, temp solution before LEONWEB-11751
        isShowTabsLogin } = useLoginPage();
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['login'])
            }, [
                _unref(isShowTabsLogin) ? (_openBlock(), _createBlock(VTabs, _mergeProps({
                    key: 1,
                    class: _ctx.$style['login__tabs']
                }, _unref(tabsProperties), {
                    "full-width": "",
                    "with-gap": "",
                    onTabClick: _unref(onTabClick)
                }), null, 16, [
                    "class",
                    "onTabClick"
                ])) : _createCommentVNode("", true),
                _unref(isFormReady) ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['login__content'])
                }, [
                    _createVNode(_unref(VForm), {
                        ref_key: "loginForm",
                        ref: loginForm,
                        class: _normalizeClass(_ctx.$style['login__form']),
                        schema: _unref(schema),
                        "ui-schema": _unref(uiSchema),
                        "is-pending": _unref(isSubmitPending),
                        autocomplete: "off",
                        onChange: _unref(onChange),
                        onSubmit: _unref(onSignIn),
                        onInput: _unref(onInput)
                    }, null, 8, [
                        "class",
                        "schema",
                        "ui-schema",
                        "is-pending",
                        "onChange",
                        "onSubmit",
                        "onInput"
                    ]),
                    _unref(incorrectLoginMessage) ? (_openBlock(), _createBlock(VHintBlock, _mergeProps({
                        key: 0
                    }, _unref(incorrectHintBlockProperties), {
                        class: _ctx.$style['login__form-hint']
                    }), null, 16, [
                        "class"
                    ])) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['login__buttons-block'])
                    }, [
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _withDirectives(_createVNode(VButton, _mergeProps(_unref(submitButtonProperties), {
                                class: _ctx.$style['login__button'],
                                "full-width": "",
                                onClick: _unref(onClickSingInButton)
                            }), null, 16, [
                                "class",
                                "onClick"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'modal-button'
                                    }
                                ]
                            ]),
                            _withDirectives(_createVNode(VButton, {
                                class: _normalizeClass(_ctx.$style['login__button']),
                                "full-width": "",
                                kind: _ctx.isFlowLogin ? _unref(ButtonKind).BASE : _unref(ButtonKind).TRANSPARENT,
                                label: _ctx.$t('JSPNAV_FOOT_REGISTER'),
                                onClick: _unref(onRegistrationButtonClick)
                            }, null, 8, [
                                "class",
                                "kind",
                                "label",
                                "onClick"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'modal-button'
                                    }
                                ]
                            ])
                        ], 64))
                    ], 2),
                    _ctx.isFlowLogin ? (_openBlock(), _createBlock(LoginTermsFlow, {
                        key: 1,
                        onRegistration: _unref(onRegistrationButtonClick)
                    }, null, 8, [
                        "onRegistration"
                    ])) : _unref(isCaptchaEnabled) ? (_openBlock(), _createBlock(VReCaptchaBadge, {
                        key: 2,
                        lang: _ctx.$lang
                    }, null, 8, [
                        "lang"
                    ])) : _createCommentVNode("", true)
                ], 2)) : (_openBlock(), _createBlock(_unref(VLoader), {
                    key: 3
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LoginRoutePage'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'login-page'
                    }
                ]
            ]);
        };
    }
});
