import { ref } from 'vue';
import { Timer } from '@leon-hub/utils';
import { useCmsStore } from 'web/src/modules/cms/store';
export default function useCmsContentToPrint() {
    const cmsContentStore = useCmsStore();
    function setupPrintWindow(categoryCaption, contentToPrint) {
        const win = window.open('', '_blank', 'width=800,height=600');
        if (!win) return;
        win.document.write(`<html lang=""><head><title>${categoryCaption}</title>`);
        win.document.write('<style>body { font-family: Poppins, sans-serif;}</style>');
        win.document.write('</head><body>');
        win.document.write(`<h3>${categoryCaption}</h3><br>`);
        contentToPrint(win);
        win.document.write('</body></html>');
        win.document.close();
        win.focus();
        win.print();
        "1";
        Timer.setTimeout(()=>{
            win.close();
        }, 1000);
    }
    function printNavigationItems(win, items) {
        for (const item of items){
            win.document.write(`<b>${item.caption}</b><br>`);
            const content = cmsContentStore.getCmsContent(ref(false), ref(String(item.props?.contentKey))).value;
            win.document.write(`${content || ''}<br><br>`);
            if (item.children && item.children.length > 0) printNavigationItems(win, item.children);
        }
    }
    function getCmsContentToPrint(categoryCaption, ruleItems) {
        setupPrintWindow(categoryCaption, (win)=>printNavigationItems(win, ruleItems));
    }
    function getBtrKeyToPrint(categoryCaption, btrKey) {
        setupPrintWindow(categoryCaption, (win)=>{
            const content = cmsContentStore.getCmsContent(ref(false), ref(String(btrKey))).value;
            win.document.write(`<div>${content}</div><br>`);
        });
    }
    return {
        getCmsContentToPrint,
        getBtrKeyToPrint
    };
}
