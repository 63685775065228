import { getSportFamilyBackground } from 'web/src/modules/sportline/utils';
export function parseBetlineLeagueCountryDataResponse(response) {
    if (!response) return null;
    const { logo, name, iso } = response;
    if (!name || !iso) return null;
    return {
        logo: logo || null,
        name,
        iso
    };
}
export function parseBetlineLeagueBackgroundData(response) {
    if (!response) return null;
    const { src } = response;
    if (!src?.src) return null;
    return {
        ...src
    };
}
function isBetlineLeagueDataEmpty(data) {
    const { id, ...fieldsDefineEmptiness } = data;
    return Object.values(fieldsDefineEmptiness).every((field)=>!field);
}
export function parseBetlineLeagueDataResponse(response) {
    if (!response) return null;
    const { id, country, currentSeasonStart, currentSeasonEnd, currentSeasonYear } = response;
    const betlineLeagueData = {
        id: id ? String(id) : null,
        country: parseBetlineLeagueCountryDataResponse(country),
        currentSeasonStart: currentSeasonStart || null,
        currentSeasonEnd: currentSeasonEnd || null,
        currentSeasonYear: currentSeasonYear || null
    };
    return isBetlineLeagueDataEmpty(betlineLeagueData) ? null : betlineLeagueData;
}
export function parseBetlineLeagueRepresentationDataResponse(response) {
    if (!response) return null;
    const { logoUrl, family, background: backgroundImageSrc } = response;
    return {
        icon: family?.toLowerCase() || null,
        logoUrl: logoUrl || null,
        backgroundImage: parseBetlineLeagueBackgroundData(backgroundImageSrc),
        background: getSportFamilyBackground(family)
    };
}
