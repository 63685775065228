import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useCasinoSearchTabsSwitcher } from 'web/src/modules/search/submodules/casino/components/CasinoSearchTabsSwitcher/composables';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSearchTabsSwitcher',
    props: {
        activeTab: {}
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { switcherOptions, emitChange } = useCasinoSearchTabsSwitcher(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VSwitcher, {
                options: _unref(switcherOptions),
                "active-id": _ctx.activeTab,
                onChange: _unref(emitChange)
            }, null, 8, [
                "options",
                "active-id",
                "onChange"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoSearchTabsSwitcher'
                ]
            ]);
        };
    }
});
