import { isArray } from '@leon-hub/guards';
export function mapRouteParamsIntoSpintaxRequestOptions(rawRouteParams) {
    if (!rawRouteParams) return [];
    return Object.keys(rawRouteParams).map((key)=>{
        const rawValue = rawRouteParams[key];
        const value = isArray(rawValue) ? rawValue.join('&') : rawValue;
        return {
            key: `:${key.toLowerCase()}`,
            value
        };
    });
}
export function getSpintaxSeoMetaDataRequestOptionsForRoute(route) {
    const routeName = route?.name;
    if (!routeName) return null;
    const routeParams = mapRouteParamsIntoSpintaxRequestOptions(route?.params);
    return {
        routeName,
        routeParams
    };
}
