import { ref } from 'vue';
import { usePendingToFavoriteStoreComposable } from 'web/src/modules/sportline/submodules/favorites/store/composables';
import { isAddToCustomerLeaguesParametersEquals } from 'web/src/modules/sportline/submodules/favorites/utils';
export function usePendingToFavoriteLeaguesStoreComposable(props) {
    const { customerLeaguesIds } = props;
    const { preparedToAdd, preparedToRemove, setPrepareToAddState, setPreparedState: setPreparedStateInternal } = usePendingToFavoriteStoreComposable();
    /**
   * Pending list is a list of ids which will be added after login
   */ const pendingToFavoriteLeaguesList = ref([]);
    function togglePendingToFavoriteLeaguesList(parameters) {
        const pendingList = pendingToFavoriteLeaguesList.value;
        const alreadyPending = pendingList.some((pending)=>isAddToCustomerLeaguesParametersEquals(pending, parameters));
        if (alreadyPending) {
            pendingToFavoriteLeaguesList.value = [
                ...pendingList.filter((pending)=>!isAddToCustomerLeaguesParametersEquals(pending, parameters))
            ];
            return;
        }
        pendingToFavoriteLeaguesList.value = [
            ...pendingList,
            parameters
        ];
    }
    function setupPendingLeagues() {
        const currentList = customerLeaguesIds.value ?? [];
        const pendingList = pendingToFavoriteLeaguesList.value.filter((param)=>{
            let { leagueId } = param;
            return !currentList.includes(leagueId);
        });
        for (const pending of pendingList)setPrepareToAddState({
            id: pending.leagueId,
            state: true
        });
        return pendingList;
    }
    function clearPendingLeagues() {
        for (const pending of pendingToFavoriteLeaguesList.value)setPrepareToAddState({
            id: pending.leagueId,
            state: false
        });
        pendingToFavoriteLeaguesList.value = [];
    }
    function addLeagueIdToTogglePrepared(id) {
        const isFavorite = (customerLeaguesIds.value ?? []).includes(id);
        setPreparedStateInternal(isFavorite, {
            id,
            state: true
        });
        return ()=>{
            setPreparedStateInternal(isFavorite, {
                id,
                state: false
            });
        };
    }
    return {
        preparedToAdd,
        preparedToRemove,
        pendingToFavoriteLeaguesList,
        addLeagueIdToTogglePrepared,
        togglePendingToFavoriteLeaguesList,
        setupPendingLeagues,
        clearPendingLeagues
    };
}
