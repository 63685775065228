/* eslint-disable @brettz9/prefer-for-of */ import { isNullOrUndefined } from '@leon-hub/guards';
import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let PluginsComponent = class PluginsComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        const navigatorPlugins = window.navigator.plugins;
        if (isNullOrUndefined(navigatorPlugins)) return this.result({
            key: this.key,
            value: ComponentStatus.NotAvailable
        });
        const plugins = [];
        for(let index = 0, l = navigatorPlugins.length; index < l; index += 1)if (navigatorPlugins[index]) plugins.push(navigatorPlugins[index]);
        const value = plugins.map((plugin)=>{
            const mimeTypes = [];
            for(let index = 0, l = plugin.length; index < l; index += 1)if (plugin[index] instanceof MimeType) mimeTypes.push([
                plugin[index].type,
                plugin[index].suffixes,
                plugin[index].description
            ]);
            return [
                plugin.name,
                plugin.description,
                mimeTypes
            ];
        });
        return this.result(value.length ? value : ComponentStatus.NoData);
    }
    constructor(){
        super(ComponentKey.Plugins);
    }
};
// Component source: https://github.com/fingerprintjs/fingerprintjs2/blob/master/fingerprint2.js#L539
export { PluginsComponent as default };
