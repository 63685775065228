import { doChangeLanguage } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function changeLanguage(languageTag) {
    const apiClient = useGraphqlClient();
    return doChangeLanguage(apiClient, (node)=>node.mutations.system.changeLanguage, {
        options: {
            languageTag
        }
    });
}
