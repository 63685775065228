import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
export default function useCasinoSearchSection(props, emits) {
    const router = useRouter();
    const { getEgsGameHref } = useEgsNavigation(router);
    const hasGames = computed(()=>props.games.length > 0);
    const hasGroups = computed(()=>props.groups.length > 0);
    function getItemProperties(game) {
        return {
            name: game.name,
            group: game.group.name,
            categories: game.categories.map((category)=>category.name),
            href: getEgsGameHref(game),
            searchText: props.searchText,
            image: game.images.squareImage ?? game.images.preview ?? void 0,
            ntBadge: game.ntBadge || void 0
        };
    }
    function emitItemClick(game) {
        emits('item-click', game);
    }
    function emitLoadMore() {
        emits('load-more');
    }
    return {
        hasGroups,
        hasGames,
        emitItemClick,
        emitLoadMore,
        getItemProperties
    };
}
