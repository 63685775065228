import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useRouter } from 'vue-router';
import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import VLinkPrevented from 'web/src/components/Link/VLinkPrevented/VLinkPrevented.vue';
import useRootStore from 'web/src/modules/core/store/useRootStore';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ErrorHeaderBarSimpleRouteComponent',
    setup (__props) {
        const router = useRouter();
        const isAppMainContentLoaded = toRef(useRootStore(), 'isAppMainContentLoaded');
        function goHome() {
            if (isAppMainContentLoaded.value) router.push({
                name: RouteName.HOME
            });
            else window.location.href = '/';
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['header-bar-simple'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['header-bar-simple__container'])
                }, [
                    _createVNode(VLinkPrevented, {
                        class: _normalizeClass(_ctx.$style['header-bar-simple__link']),
                        to: {
                            name: _unref(RouteName).HOME
                        },
                        onClick: goHome
                    }, {
                        default: _withCtx(()=>[
                                _createVNode(VBrand, {
                                    class: _normalizeClass(_ctx.$style['header-bar-simple__logo']),
                                    alt: _ctx.$t('WEB2_BRAND_NAME_SHORT')
                                }, null, 8, [
                                    "class",
                                    "alt"
                                ])
                            ]),
                        _: 1
                    }, 8, [
                        "class",
                        "to"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ErrorHeaderBarSimpleRouteComponent'
                ]
            ]);
        };
    }
});
