import { defineStore } from 'pinia';
import { ref } from 'vue';
import { fetchInlineChats } from './utils';
const useInlineChatsStore = defineStore('inline-chats', ()=>{
    // State
    const inlineChats = ref([]);
    // Actions
    async function syncChats() {
        inlineChats.value = await fetchInlineChats();
    }
    // On init
    syncChats();
    return {
        inlineChats,
        syncChats
    };
});
export default useInlineChatsStore;
