import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { Timer } from '@leon-hub/utils';
import { getButtonProperties, getTimerString } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/composables/utils';
import getPageDescription from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/composables/utils/getPageDescription';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import getTimerText from './utils/getTimerText';
export default function useVSmsNumberCheck(props, emit) {
    let showButtonInterval = 0;
    const { smsResendCodeTimer } = storeToRefs(useSiteConfigStore());
    const countDown = ref(smsResendCodeTimer.value);
    const formKey = ref(0);
    const showButton = ref(true);
    const form = ref();
    const buttonProperties = getButtonProperties(props);
    const timerText = getTimerText(props);
    const pageDescription = getPageDescription(props);
    const timer = computed(()=>getTimerString(countDown.value));
    function onComponentCreated() {
        // TODO: redo this logic in order use VTimer
        if (props.showButtonTime) {
            countDown.value = props.showButtonTime;
            startInterval();
        } else emitShowHintWithEmailRedirect(true);
    }
    function onComponentMounted() {}
    function onComponentBeforeUnmount() {
        if (showButtonInterval) {
            Timer.clearInterval(showButtonInterval);
            showButtonInterval = 0;
        }
        emitSetRetrySmsCodeTime();
    }
    function onSubmit(data) {
        if (data && !data.errors) emitSubmit(data);
    }
    function onBlur(data) {
        emit('blur', data);
    }
    function startCountDown() {
        if (countDown.value) countDown.value -= 1;
        if (// eslint-disable-next-line max-len
        countDown.value === (props.showButtonTime || smsResendCodeTimer.value) - (props.hintTimeout || smsResendCodeTimer.value)) emitShowHintWithEmailRedirect(true);
        if (0 === countDown.value) {
            emitShowHintWithEmailRedirect(true);
            countDown.value = props.showButtonTime || smsResendCodeTimer.value;
            if (showButtonInterval) {
                Timer.clearInterval(showButtonInterval);
                showButtonInterval = 0;
            }
            toggleShowButton();
        }
    }
    function startInterval() {
        showButtonInterval = Timer.setInterval(()=>startCountDown(), 1000);
        toggleShowButton();
        emitShowHintWithEmailRedirect(false);
        formKey.value += 1;
    }
    function onClick() {
        emitRetryButtonClick();
        if (!props.isTimer) startInterval();
    }
    function toggleShowButton() {
        showButton.value = !showButton.value;
    }
    function emitShowHintWithEmailRedirect(value) {
        if (value) emit('show-hint-with-email-redirect', true);
        return value;
    }
    function emitSetRetrySmsCodeTime() {
        emit('set-retry-sms-code-time');
        return showButton.value ? 0 : countDown.value;
    }
    function emitRetryButtonClick() {
        emit('retry-button-сlick');
    }
    function emitSubmit(data) {
        emit('submit', data);
        return data;
    }
    function emitInput(payload) {
        emit('input', payload);
        return payload;
    }
    return {
        form,
        formKey,
        timer,
        showButton,
        timerText,
        buttonProperties,
        pageDescription,
        onSubmit,
        emitInput,
        onClick,
        onComponentCreated,
        onComponentMounted,
        onComponentBeforeUnmount,
        onBlur
    };
}
