import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class SupportModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addAnonymousProfileRoute({
            name: RouteName.CALLBACK,
            path: '/callback',
            prefetch: ()=>import('./prefetch/SupportCallbackPagePrefetch'),
            component: ()=>import('./submodules/callback/pages/SupportCallbackRoutePage/SupportCallbackRoutePage.vue'),
            title: '{{$t(\'WEB2_CALL_CUSTOMER_SUPPORT\')}}'
        });
        router.addAnonymousProfileRoute({
            name: RouteName.FEEDBACK,
            path: '/feedback',
            prefetch: ()=>import('web/src/modules/intercom/prefetch/IntercomPrefetch'),
            component: ()=>import('web/src/modules/support/submodules/feedback/pages/SupportFeedbackRoutePage/SupportFeedbackRoutePage.vue'),
            title: '{{$t(\'WEB2_ACCESS_CALLBACK\')}}'
        });
        router.addAnonymousProfileRoute({
            name: RouteName.SUPPORT,
            path: '/support',
            prefetch: ()=>import('web/src/modules/support/submodules/options/prefetch/SupportOptionsPrefetch'),
            component: ()=>import('web/src/modules/support/submodules/options/pages/SupportOptionsRoutePage/SupportOptionsListRoutePage.vue'),
            title: '{{$t(\'WEB2_JSP_SUPPORT_SERVICE\')}}',
            isTabBarVisible: true
        });
        router.addAnonymousProfileRoute({
            name: RouteName.FAQ,
            path: '/faq',
            prefetch: ()=>import('./prefetch/SupportFaqPagePrefetch'),
            // eslint-disable-next-line max-len
            component: ()=>import('web/src/modules/support/submodules/faq/pages/SupportFaqRoutePage/SupportFaqRoutePage.vue'),
            title: '{{$t(\'JSP_FAQ_SUPPORT\')}}'
        });
        router.addAnonymousProfileRoute({
            name: RouteName.FAQ_CATEGORY,
            path: '/faq/:category',
            component: ()=>import('web/src/modules/support/submodules/faq/pages/SupportFaqCategoryRoutePage/SupportFaqCategoryRoutePage.vue'),
            topBar: ()=>import('web/src/modules/support/submodules/faq/layouts/SupportFaqCategoryTopBarRouteComponent.vue')
        });
        router.addAnonymousProfileRoute({
            name: RouteName.FAQ_CATEGORY_ITEM,
            path: '/faq/:category/:item',
            component: ()=>import(// eslint-disable-next-line max-len
                'web/src/modules/support/submodules/faq/pages/SupportFaqCategoryItemRoutePage/SupportFaqCategoryItemRoutePage.vue'),
            topBar: ()=>import('web/src/modules/support/submodules/faq/layouts/SupportFaqCategoryTopBarRouteComponent.vue')
        });
        router.addAnonymousProfileRoute({
            name: RouteName.FEEDBACK_RATING,
            path: '/feedback-rating',
            // eslint-disable-next-line max-len
            component: ()=>import('web/src/modules/support/submodules/feedback-legacy/pages/SupportFeedbackLegacyRoutePage/SupportFeedbackLegacyRoutePage.vue'),
            title: '{{$t(\'WEB2_YOUR_FEEDBACK\')}}',
            topBarProps: {
                hidePrefix: true
            }
        });
        router.addAnonymousProfileRoute({
            name: RouteName.FEEDBACK_LEGACY,
            path: '/feedback-legacy',
            // eslint-disable-next-line max-len
            component: ()=>import('web/src/modules/support/submodules/feedback-legacy/pages/SupportFeedbackLegacyRoutePage/SupportFeedbackLegacyRoutePage.vue'),
            title: '{{$t(\'WEB2_YOUR_FEEDBACK\')}}'
        });
    }
}
