import { computed, toRef } from 'vue';
import { useModuleTimeout, useSyncState } from 'web/src/modules/core/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useLeaguePlayoffStoreComposable, useLeagueStandingsStoreComposable, useLeagueTopPlayersStoreComposable, useLeagueMatchesStatisticStoreComposable } from 'web/src/modules/sportline/submodules/league-statistic/store/composables';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
export function useLeagueStatisticStoreComposable(props) {
    const { expectedId } = props;
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isEnabled = toRef(sportlineSettingsStore, 'isLeagueStatisticEnabled');
    const { timeout: updateInterval } = useModuleTimeout('sportline-league-statistic', toRef(sportlineSettingsStore, 'sportLinePrematchUpdateInterval'));
    const { isReady: isStandingsReady, isLoading: isStandingsLoading, isEmpty: isStandingsEmpty, leagueStandings, fetchLeagueStandings, clear: clearLeagueStandings } = useLeagueStandingsStoreComposable({
        expectedId,
        isEnabled
    });
    const { isReady: isMatchesStatisticReady, isLoading: isMatchesStatisticLoading, isEmpty: isMatchesStatisticEmpty, leagueMatchesStatistic, leagueMatchesResults, fetchLeagueMatchesStatistic, clear: clearLeagueMatchesStatistic } = useLeagueMatchesStatisticStoreComposable({
        expectedId,
        isEnabled
    });
    const { isReady: isPlayoffReady, isLoading: isPlayoffLoading, isEmpty: isPlayoffEmpty, leaguePlayoff, fetchLeagueLayoff, clear: clearLeagueLayoff } = useLeaguePlayoffStoreComposable({
        expectedId,
        isEnabled
    });
    const { isReady: isTopPlayersReady, isLoading: isTopPlayersLoading, isEmpty: isTopPlayersEmpty, topPlayers: leagueTopPlayers, fetchLeagueTopPlayers, clear: clearLeagueTopPlayers } = useLeagueTopPlayersStoreComposable({
        expectedId,
        isEnabled
    });
    const isReady = computed(()=>isStandingsReady.value && isMatchesStatisticReady.value && isPlayoffReady.value && isTopPlayersReady.value);
    const isLoading = computed(()=>isStandingsLoading.value || isMatchesStatisticLoading.value || isPlayoffLoading.value || isTopPlayersLoading.value);
    const isEmpty = computed(()=>isStandingsEmpty.value && isMatchesStatisticEmpty.value && isPlayoffEmpty.value && isTopPlayersEmpty.value);
    const lastUpdate = new BackgroundUpdateStopwatch({
        leagueStandings: async (param)=>{
            let { silent } = param;
            await fetchLeagueStandings({
                silent
            });
        },
        leagueMatchesStatistic: async (param)=>{
            let { silent } = param;
            await fetchLeagueMatchesStatistic({
                silent
            });
        },
        leaguePlayoff: async (param)=>{
            let { silent } = param;
            await fetchLeagueLayoff({
                silent
            });
        },
        topPlayers: async (param)=>{
            let { silent } = param;
            await fetchLeagueTopPlayers({
                silent
            });
        }
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval
    });
    function onInit() {
        useSyncState(async (silent)=>{
            await syncState({
                silent
            });
        }, updateInterval);
    }
    function clear() {
        clearLeagueStandings();
        clearLeagueMatchesStatistic();
        clearLeagueLayoff();
        clearLeagueTopPlayers();
    }
    return {
        isReady,
        isLoading,
        isEmpty,
        leagueStandings,
        leagueMatchesStatistic,
        leagueMatchesResults,
        leaguePlayoff,
        leagueTopPlayers,
        onInit,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled,
        clear
    };
}
