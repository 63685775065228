import { bindIsArrayOf, isValidInterface } from '@leon-hub/guards';
import { isCoreSportResponseFieldGuards } from './isCoreSportResponse';
import { isGetSportsRegionResponse } from './isGetSportsRegionResponse';
export const isGetSportsSportResponseFieldGuards = {
    ...isCoreSportResponseFieldGuards,
    regions: bindIsArrayOf(isGetSportsRegionResponse)
};
export function isGetSportsSportResponse(value) {
    return isValidInterface(value, isGetSportsSportResponseFieldGuards);
}
