import { computed, reactive } from 'vue';
import { Tag } from '@leon-hub/tags';
export default function useVAccordion(props) {
    const accordionSharedState = reactive({
        openItem: null,
        panels: false,
        indicators: true,
        independentItems: false,
        controlled: false,
        parentTag: Tag.SECTION,
        hasIcon: false
    });
    const listTags = new Set([
        Tag.OL,
        Tag.UL
    ]);
    const isList = computed(()=>!!(props.tag && listTags.has(props.tag)));
    function beforeComponentMount() {
        const { indicators } = props;
        accordionSharedState.panels = props.panels;
        accordionSharedState.indicators = indicators;
        accordionSharedState.independentItems = !!props.independentItems;
        accordionSharedState.controlled = !!props.controlled;
        accordionSharedState.parentTag = props.tag ?? Tag.SECTION;
    }
    return {
        isList,
        accordionSharedState,
        beforeComponentMount
    };
}
