import { computed, ref, watch } from 'vue';
import { validate } from 'jsonschema';
import { FormControlType } from '../../../enums';
import createErrorMapper from './utils/createErrorMapper';
import { mergeValidatorPatterns, getSchemaErrors, getDateErrors, getFileErrors, getPatternErrors, getEmailSymbolsErrors, getTouchedFieldsErrors } from './utils/formValidatorUtils';
export default function useFormValidator(param) {
    let { uiSchema, errorPatterns, schema, validationDisabled, touched } = param;
    const errorMapper = computed(()=>createErrorMapper(uiSchema.value, mergeValidatorPatterns(errorPatterns, uiSchema.value.validatorErrorPatterns)));
    let hiddenFields;
    let dateFields;
    let multipleFileFields;
    let patternRelatedFields;
    let forbiddenSymbolsFields;
    const sortFields = ()=>{
        dateFields = new Map();
        multipleFileFields = new Map();
        patternRelatedFields = new Map();
        forbiddenSymbolsFields = new Map();
        hiddenFields = new Set();
        // sorting fields
        const allFields = uiSchema.value.fields ?? {};
        for (const fieldName of Object.keys(allFields)){
            const currentField = allFields[fieldName];
            if (currentField.hidden) hiddenFields.add(fieldName);
            if (currentField.options && 'forbiddenSymbols' in currentField.options && currentField.options.forbiddenSymbols?.length) forbiddenSymbolsFields.set(fieldName, currentField);
            if (currentField.widget === FormControlType.Date) dateFields.set(fieldName, currentField);
            if (currentField.widget === FormControlType.FileMultiple || currentField.widget === FormControlType.FileDragAndDrop) multipleFileFields.set(fieldName, currentField);
            if (currentField.widget === FormControlType.CPFNumber) patternRelatedFields.set(fieldName, currentField);
        }
    };
    sortFields();
    watch(()=>uiSchema.value, sortFields, {
        deep: true
    });
    watch(()=>schema.value, (newValue, oldValue)=>{
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) resetErrors();
    }, {
        immediate: false,
        deep: true
    });
    const allValidationErrors = ref(new Map());
    const doValidation = (formData, field)=>{
        const schemaValidationResult = validate(formData, schema.value);
        const schemaErrors = getSchemaErrors(schemaValidationResult.errors, allValidationErrors.value, errorMapper.value, field);
        const dateErrors = getDateErrors(formData, dateFields, errorPatterns);
        const filesErrors = getFileErrors(formData, multipleFileFields, errorPatterns);
        const patternErrors = getPatternErrors(formData, patternRelatedFields, errorPatterns);
        const emailSymbolsErrors = getEmailSymbolsErrors(formData, forbiddenSymbolsFields, errorPatterns);
        /** do not validate hidden fields */ const allVisibleErrors = [
            ...dateErrors,
            ...filesErrors,
            ...patternErrors,
            ...emailSymbolsErrors,
            ...schemaErrors
        ].filter((param)=>{
            let [key] = param;
            return !hiddenFields.has(`${key}`);
        });
        allValidationErrors.value = new Map(allVisibleErrors);
    };
    const getCurrentErrors = computed(()=>validationDisabled ? {} : getTouchedFieldsErrors(allValidationErrors.value, touched.value));
    function resetErrors() {
        allValidationErrors.value = new Map();
    }
    const haveAnyValidationErrors = computed(()=>{
        if (validationDisabled) return false;
        return allValidationErrors.value.size > 0;
    });
    return {
        validate: doValidation,
        schemaErrors: getCurrentErrors,
        haveAnyValidationErrors,
        resetErrors
    };
}
