import { defineStore } from 'pinia';
import { ref, computed, watch, toRef } from 'vue';
import { doWithdrawalCancel as doWithdrawalCancelApi, getCustomerHistoryItem, getCustomerHistoryList, HistoryFilter } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';
import { useCashoutStore } from 'web/src/modules/cashout/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { replacePlaceholders } from 'web/src/utils/replacePlaceholders';
import { DEFAULT_ORDER_FILTER } from 'web/src/modules/profile/submodules/customer-history/store/constants';
import getCustomerFilterId from 'web/src/modules/profile/submodules/customer-history/utils';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useUserStore } from 'web/src/modules/user/store';
const useCustomerHistoryStore = defineStore('customer-history', ()=>{
    const apiClient = useGraphqlClient();
    const cashoutStore = useCashoutStore();
    const isLoggedIn = toRef(useAuthStore(), 'isLoggedIn');
    const userStore = useUserStore();
    const selectedCustomerHistoryItem = ref(null);
    const customerHistory = ref({});
    const filters = ref({});
    const selectedFilterId = ref('');
    const orderFilter = ref({
        ...DEFAULT_ORDER_FILTER
    });
    const isLeonShopHistoryExists = ref(false);
    // Getters:
    const isInitialLoader = computed(()=>{
        if (!selectedFilterId.value) return true;
        return filters.value[selectedFilterId.value].isLoading && 0 === filters.value[selectedFilterId.value].entries.length;
    });
    const selectedHistoryItem = computed(()=>{
        if (!selectedCustomerHistoryItem.value) return null;
        const id = selectedCustomerHistoryItem.value.rootTxId;
        return customerHistory.value[id] || null;
    });
    const isLazyLoader = computed(()=>{
        if (!filters.value[selectedFilterId.value]) return false;
        const { page, isLoading } = filters.value[selectedFilterId.value];
        return isLoading && page > 0;
    });
    const isLastPage = computed(()=>!!filters.value[selectedFilterId.value]?.isLastPage);
    const selectedHistoryFilter = computed(()=>{
        if (!selectedFilterId.value) return HistoryFilter.ALL;
        const { filter } = filters.value[selectedFilterId.value];
        if (filter === HistoryFilter.PENDING_BETS) return HistoryFilter.ALL_BETS;
        return filter;
    });
    const currentFilterState = computed(()=>filters.value[selectedFilterId.value]);
    const historyItems = computed(()=>{
        if (filters.value[selectedFilterId.value]?.entries?.length) return filters.value[selectedFilterId.value].entries?.map((item)=>customerHistory.value[item]) || [];
        return [];
    });
    // Mutations:
    function requestCustomerHistory(payload) {
        const { page, filter } = payload;
        const filterId = getCustomerFilterId(payload);
        const isFilterAdded = filterId in filters.value;
        const entries = isFilterAdded ? filters.value[filterId].entries : [];
        if (isFilterAdded) filters.value[filterId] = {
            ...filters.value[filterId],
            page,
            isLoading: true
        };
        else filters.value[filterId] = {
            page,
            filter,
            isLoading: true,
            isLastPage: false,
            entries: [
                ...entries
            ]
        };
        const previousSelectedFilterId = selectedFilterId.value;
        if (previousSelectedFilterId && previousSelectedFilterId !== filterId) {
            const filtersPreviousSelected = filters.value[previousSelectedFilterId];
            const firstPageEntries = filtersPreviousSelected.entries.slice(0, DEFAULT_ORDER_FILTER.rows);
            const isLastPageValue = !(filtersPreviousSelected.page > 0 && filtersPreviousSelected.isLastPage);
            filters.value[previousSelectedFilterId] = {
                ...filters.value[previousSelectedFilterId],
                isLastPage: isLastPageValue,
                page: 0,
                entries: [
                    ...firstPageEntries
                ]
            };
        }
        selectedFilterId.value = filterId;
    }
    function updateCustomerHistory(payload) {
        try {
            const { data, filter } = payload;
            const filterId = getCustomerFilterId(filter);
            const entries = [];
            const isFirstPage = 0 === filter.page;
            const isFilterEntriesLoaded = !!filters.value[filterId].entries.length;
            const commonState = {
                page: filter.page,
                filter: filter.filter,
                isLoading: false,
                isLastPage: filter.rows > data.length
            };
            for (const item of data){
                const itemId = item.rootTxId;
                customerHistory.value[itemId] = item;
                entries.push(itemId);
            }
            if (isFirstPage && isFilterEntriesLoaded) {
                const isEntriesChanged = entries.length && filters.value[filterId].entries[0] !== entries[0];
                if (isEntriesChanged) filters.value[filterId] = {
                    ...commonState,
                    entries: [
                        ...entries
                    ]
                };
            } else if (isFirstPage && !isFilterEntriesLoaded) filters.value[filterId] = {
                ...commonState,
                entries: [
                    ...entries
                ]
            };
            else if (!isFirstPage) filters.value[filterId] = {
                ...commonState,
                entries: [
                    ...filters.value[filterId].entries,
                    ...entries
                ]
            };
        } catch (rawError) {
            const payloadString = JSON.stringify(payload);
            logger.error(`[updateCustomerHistory] mutation payload: ${payloadString}`, rawError);
        }
    }
    function onDestroyCustomerHistory() {
        orderFilter.value = {
            ...DEFAULT_ORDER_FILTER
        };
    }
    function setOrderFilter(payload) {
        orderFilter.value = payload;
    }
    function updateListItem(payload) {
        customerHistory.value[payload.rootTxId] = {
            ...customerHistory.value[payload.objectId],
            ...payload
        };
    }
    function updatePendingBetsListItem(payload) {
        const filterId = selectedFilterId.value;
        if (filterId.includes(HistoryFilter.PENDING_BETS)) {
            const itemId = payload.rootTxId;
            const { entries } = filters.value[filterId];
            const newEntries = entries.filter((item)=>item !== itemId);
            filters.value[filterId] = {
                ...filters.value[filterId],
                entries: [
                    ...newEntries
                ]
            };
        }
    }
    function setSelectedCustomerHistoryItem(payload) {
        selectedCustomerHistoryItem.value = payload;
    }
    function clearHistoryState() {
        customerHistory.value = {};
        filters.value = {};
        selectedFilterId.value = '';
    }
    function setLeonShopExists(value) {
        isLeonShopHistoryExists.value = value;
    }
    // Actions:
    async function syncState(silent) {
        if (selectedCustomerHistoryItem.value) {
            await getAccountTransaction({
                id: selectedCustomerHistoryItem.value.objectId,
                group: selectedCustomerHistoryItem.value.objectType,
                ...selectedCustomerHistoryItem.value.accountType ? {
                    accountType: selectedCustomerHistoryItem.value.accountType
                } : {},
                silent
            });
            const historyId = selectedCustomerHistoryItem.value.rootTxId;
            const item = customerHistory.value[historyId];
            if ('cashoutAmount' in item) cashoutStore.applyUpdate({
                amount: Number(item.cashoutAmount)
            });
        }
    }
    async function checkIfLeonShopHistoryExists() {
        const { items } = await getCustomerHistoryList(apiClient, (node)=>node.queries.account.getHistory, {
            options: {
                ...orderFilter.value,
                page: 0,
                filter: HistoryFilter.LEONSHOP,
                splitEventName: true
            }
        });
        items?.length ? setLeonShopExists(true) : setLeonShopExists(false);
    }
    // renamed updateCustomerHistory -> updateCustomerHistoryAction
    async function updateCustomerHistoryAction(options) {
        let data = [];
        setOrderFilter(options);
        requestCustomerHistory(options);
        const formatMoney = useFormatMoney();
        const { items } = await getCustomerHistoryList(apiClient, (node)=>node.queries.account.getHistory, {
            options: {
                ...options,
                splitEventName: true
            }
        });
        if (items?.length) data = items.map((item)=>{
            if (item?.placeholders?.length) return {
                ...item,
                txTypeName: replacePlaceholders({
                    text: item.txTypeName,
                    placeholders: item.placeholders,
                    formatMoney
                })
            };
            return item;
        });
        updateCustomerHistory({
            filter: options,
            data
        });
    }
    async function updateHistoryListItem(options) {
        const { item } = await getCustomerHistoryItem(apiClient, (node)=>node.queries.account.getHistoryItemV2, {
            options: {
                ...options,
                splitEventName: true
            }
        });
        if (item) {
            updateListItem(item);
            updatePendingBetsListItem(item);
        }
    }
    async function getAccountTransaction(payload) {
        const { silent, ...options } = payload;
        const { item } = await getCustomerHistoryItem(apiClient, (node)=>node.queries.account.getHistoryItemV2, {
            options: {
                ...options,
                splitEventName: true
            }
        }, {
            silent
        });
        setSelectedCustomerHistoryItem(item);
        updateListItem(item);
    }
    async function doWithdrawalCancel(payload) {
        await doWithdrawalCancelApi(apiClient, (node)=>node.mutations.finance.withdrawal.cancel, {
            options: payload
        });
        userStore.loadUserBonusData();
    }
    async function updateProfileHistoryList() {
        await updateCustomerHistoryAction({
            ...DEFAULT_ORDER_FILTER,
            rows: 5
        });
    }
    watch(isLoggedIn, (newValue)=>{
        if (!newValue) clearHistoryState();
    });
    useSyncState(syncState, 'customer-history');
    return {
        selectedCustomerHistoryItem,
        customerHistory,
        filters,
        selectedFilterId,
        orderFilter,
        isLeonShopHistoryExists,
        // getters:
        isInitialLoader,
        selectedHistoryItem,
        isLazyLoader,
        isLastPage,
        selectedHistoryFilter,
        currentFilterState,
        historyItems,
        // mutations:
        updateCustomerHistory,
        onDestroyCustomerHistory,
        setSelectedCustomerHistoryItem,
        // actions:
        doWithdrawalCancel,
        updateCustomerHistoryAction,
        updateHistoryListItem,
        getAccountTransaction,
        updateProfileHistoryList,
        checkIfLeonShopHistoryExists
    };
});
export default useCustomerHistoryStore;
