import { ref, toRef } from 'vue';
import ResendSmsStopwatch from 'web/src/utils/phone-validation/ResendSmsStopwatch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export function useCountdownTimerManager() {
    const siteConfigStore = useSiteConfigStore();
    const retrySmsCodeTimer = toRef(siteConfigStore, 'smsResendCodeTimer');
    const resendSmsTimeOut = ref(0);
    const resendSmsStopwatch = new ResendSmsStopwatch((time)=>{
        setResendSmsTimeOut(time);
    }, 'registrationSmsTimeOut');
    function setResendSmsTimeOut(timeOut) {
        resendSmsTimeOut.value = timeOut;
    }
    function resumeResendSmsTimeout() {
        const savedResendTime = resendSmsStopwatch.getCurrentTimeFromLocalStorage();
        if (savedResendTime) resendSmsStopwatch.run(savedResendTime);
    }
    function restartResendSmsTimeout() {
        resendSmsStopwatch.clear();
        resendSmsStopwatch.run(retrySmsCodeTimer.value);
    }
    function pauseResendSmsTimeout() {
        resendSmsStopwatch.pause();
    }
    function isTimerRestarted() {
        const savedResendTime = resendSmsStopwatch.getCurrentTimeFromLocalStorage();
        if (savedResendTime) {
            resendSmsStopwatch.run(savedResendTime);
            return false;
        }
        resendSmsStopwatch.run(retrySmsCodeTimer.value);
        return true;
    }
    return {
        resendSmsTimeOut,
        resumeResendSmsTimeout,
        restartResendSmsTimeout,
        pauseResendSmsTimeout,
        isTimerRestarted
    };
}
