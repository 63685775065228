import { computed } from 'vue';
import { isObject, isOptionalString } from '@leon-hub/guards';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
export default function useFastSum(props) {
    const { isPaymentsFastSumEnabled, isPaymentsFastSumMaxEnabled } = usePaymentsConfig();
    const componentProps = computed(()=>{
        const { name, title, hintMessage, error, options, autofocus } = props;
        isOptionalString(error);
        isObject(options);
        return {
            ...options,
            name,
            error,
            hint: hintMessage,
            label: title,
            autofocus,
            showSuggestedAmount: isPaymentsFastSumEnabled.value,
            isFastSumMaxEnabled: isPaymentsFastSumMaxEnabled.value
        };
    });
    return {
        componentProps
    };
}
