import { ref, computed, watch } from 'vue';
import { isArray, isUndefined } from '@leon-hub/guards';
import { FormFieldTouchedStrategy } from '../../../enums';
import { getFormDataObject, getTouchedStrategyMap, updateFormDataDefaultValues, isEmptyDefaultValue, getInitialFormDataMap, getAllFieldNames, getTouchedStrategy } from './utils/formDataUtils';
import getObjectTypeFields from './utils/getObjectTypeFields';
function useFormData(param) {
    let { schema, uiSchema } = param;
    const touched = ref(new Set());
    const initialFormData = getInitialFormDataMap(schema.value, uiSchema.value);
    const formDataMap = ref(new Map(initialFormData));
    const defaultFormData = ref(new Map(initialFormData));
    const formDataObject = computed(()=>getFormDataObject(formDataMap.value, schema.value));
    const touchedStrategyMap = computed(()=>getTouchedStrategyMap(uiSchema.value));
    const objectTypeFields = computed(()=>getObjectTypeFields(schema.value));
    const setValue = (name, value)=>{
        formDataMap.value.set(name, value);
    };
    const deleteValue = (name)=>{
        formDataMap.value.delete(name);
    };
    const setTouched = (name)=>{
        touched.value.add(name);
    };
    const setTouchedRelatedToStrategy = (name, touchedStrategy)=>{
        if (getTouchedStrategy(name, touchedStrategyMap.value) === touchedStrategy) setTouched(name);
    };
    const removeTouched = (name)=>{
        touched.value.delete(name);
    };
    const isChanged = (dataPath)=>{
        const value = formDataMap.value.get(dataPath);
        const defaultValue = defaultFormData.value.get(dataPath);
        if (value instanceof File && defaultValue instanceof File) return value.name !== defaultValue.name;
        if (value && defaultValue) return value !== defaultValue;
        // value: string | boolean | number | null | undefined;
        return Boolean(value) !== Boolean(defaultValue);
    };
    const resetFormData = ()=>{
        formDataMap.value = new Map(initialFormData);
        touched.value.clear();
    };
    const allFieldNames = computed(()=>getAllFieldNames(schema.value));
    const getSnapshot = ()=>({
            formData: new Map(formDataMap.value),
            touched: new Set(touched.value)
        });
    const restoreFormDataFromSnapShot = (snapshot)=>{
        const filteredFormData = new Map();
        const filteredTouched = new Set();
        for (const key of allFieldNames.value){
            const hasValue = snapshot.formData.has(key);
            const isTouched = snapshot.touched.has(key);
            if (hasValue) {
                const value = snapshot.formData.get(key);
                if (!isUndefined(value)) filteredFormData.set(key, value);
            }
            if (isTouched) filteredTouched.add(key);
        }
        formDataMap.value = filteredFormData;
        touched.value = filteredTouched;
    };
    // partial changeStateOnChange - without output and validation
    const handleFieldInput = (formEvent)=>{
        const { name, value, fieldOriginalName } = formEvent;
        const required = isArray(schema.value.required) ? schema.value.required : [];
        const currentValueIsEmptyDefaultValue = isEmptyDefaultValue({
            name,
            value,
            uiSchema: uiSchema.value
        });
        if (value || void 0 !== value && !required.includes(name)) setValue(name, value);
        else deleteValue(name);
        if (currentValueIsEmptyDefaultValue) {
            removeTouched(name);
            deleteValue(name);
        } else {
            if (fieldOriginalName) setTouched(fieldOriginalName);
            setTouchedRelatedToStrategy(name, FormFieldTouchedStrategy.Change);
        }
    };
    // partial changeStateOnBlur - without output and validation
    const handleFieldBlur = (formEvent)=>{
        const { name } = formEvent;
        if (isChanged(name)) setTouchedRelatedToStrategy(name, FormFieldTouchedStrategy.Blur);
    };
    const setTouchedOnSubmit = ()=>{
        touched.value = new Set([
            ...allFieldNames.value
        ]);
    };
    const refreshFormData = ()=>{
        const updatedDefaults = getInitialFormDataMap(schema.value, uiSchema.value);
        formDataMap.value = updateFormDataDefaultValues({
            initialFormData: defaultFormData.value,
            updatedDefaults,
            fieldNames: allFieldNames.value,
            currentFormData: formDataMap.value,
            objectTypeFields: objectTypeFields.value
        });
        defaultFormData.value = getInitialFormDataMap(schema.value, uiSchema.value);
    };
    watch(()=>uiSchema.value.fields, ()=>{
        refreshFormData();
    }, {
        deep: true
    });
    return {
        formData: formDataObject,
        formDataMap,
        touched,
        handleFieldInput,
        handleFieldBlur,
        getSnapshot,
        restoreFormDataFromSnapShot,
        setTouchedOnSubmit,
        resetFormData,
        refreshFormData
    };
}
export default useFormData;
