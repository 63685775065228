import { ref } from 'vue';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
export default function getRewardTitle(reward, $t, locale) {
    const rewardKeys = ref({
        count: reward.count.toString() || ''
    });
    switch(reward.rewardType){
        case 'FREESPIN':
            return 0 === reward.count ? '0' : LanguageMicroSettings.plural([
                $t('WEB2_BONUSES_COMPLEX_FREESPIN_ONE_TITLE', rewardKeys).value,
                $t('WEB2_BONUSES_COMPLEX_FREESPIN_TWO_TITLE', rewardKeys).value,
                $t('WEB2_BONUSES_COMPLEX_FREESPIN_MANY_TITLE', rewardKeys).value
            ], reward.count || 0, locale);
        case 'FREEBET':
            return 0 === reward.count ? '0' : LanguageMicroSettings.plural([
                $t('WEB2_BONUSES_COMPLEX_FREEBET_ONE_TITLE', rewardKeys).value,
                $t('WEB2_BONUSES_COMPLEX_FREEBET_TWO_TITLE', rewardKeys).value,
                $t('WEB2_BONUSES_COMPLEX_FREEBET_MANY_TITLE', rewardKeys).value
            ], reward.count || 0, locale);
        default:
            return '';
    }
}
