import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { Timer } from '@leon-hub/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import ObjectFitOption from 'web/src/components/Image/VImage/enums/ObjectFitOption';
import { DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
// eslint-disable-next-line max-len
export default function usePaymentBonusItem(props, emit) {
    const { $translate } = useI18n();
    const router = useRouter();
    const animatedMask = ref(null);
    const isExpanded = ref(false);
    const isStatic = ref(false);
    const isImageLoaded = ref(false);
    const isDetailsAvailable = computed(()=>!!props.bonusItem?.actionUrl && !!props.bonusItem?.campaignId);
    const bonusImage = computed(()=>({
            src: props.bonusItem?.image?.src ?? props.bonusItem?.imgUrl ?? '',
            x1: props.bonusItem?.image?.x1,
            x2: props.bonusItem?.image?.x2,
            x3: props.bonusItem?.image?.x3,
            x1webp: props.bonusItem?.image?.x1webp,
            x2webp: props.bonusItem?.image?.x2webp,
            x3webp: props.bonusItem?.image?.x3webp,
            alt: props.bonusItem.campaignName,
            objectFit: ObjectFitOption.COVER,
            showLoader: true,
            isLazy: true
        }));
    const description = computed(()=>props.bonusItem?.description ?? `<span>${$translate('WEB2_BONUS_DESCRIPTION').value}</span>`);
    function onToggleExpand() {
        isExpanded.value = !isExpanded.value;
    }
    function emitSelectComplete() {
        emit('select-complete');
    }
    function emitSelectedBonus() {
        emit('select', props.isSelected ? DEPOSIT_BONUS_NONE_SELECTED : props.bonusItem?.campaignId);
    }
    function onMount() {
        if (animatedMask.value && !props.isSelected) animatedMask.value.addEventListener('animationend', ()=>{
            Timer.setTimeout(()=>{
                emitSelectComplete();
            }, 200);
        });
        if (animatedMask.value && props.isSelected) isStatic.value = true;
    }
    function onDetailClick() {
        router.push({
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: props.bonusItem?.categoryId || '',
                actionUrl: props.bonusItem?.actionUrl || ''
            }
        });
    }
    function chooseButtonHandle() {
        if (props.isSelected) emitSelectComplete();
        else emitSelectedBonus();
    }
    function setImageLoaded() {
        isImageLoaded.value = true;
    }
    return {
        animatedMask,
        isExpanded,
        isStatic,
        isImageLoaded,
        isDetailsAvailable,
        bonusImage,
        description,
        emitSelectComplete,
        emitSelectedBonus,
        onMount,
        onToggleExpand,
        onDetailClick,
        chooseButtonHandle,
        setImageLoaded
    };
}
