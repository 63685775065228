var SportlineRouteName;
var SportlineRouteName1;
(SportlineRouteName1 = SportlineRouteName || (SportlineRouteName = {}))["SPORTLINE_LIVE_EVENTS"] = "sportline-live-events", SportlineRouteName1["SPORTLINE_PREMATCH_EVENTS"] = "sportline-prematch-events", SportlineRouteName1["SPORTLINE_PREMATCH_TODAY"] = "sportline-prematch-today", SportlineRouteName1["SPORTLINE_TOP_EVENTS"] = "sportline-top-events", SportlineRouteName1["SPORTLINE_TOP_LEAGUE"] = "sportline-top-league", SportlineRouteName1["SPORTLINE_LIVE_LEAGUE"] = "sportline-live-league", SportlineRouteName1["SPORTLINE_VIRTUAL_SPORT"] = "sportline-virtual-sport", SportlineRouteName1["SPORTLINE_CYBERSPORT"] = "sportline-cybersport", SportlineRouteName1["SPORT_REGION"] = "sport-region", SportlineRouteName1["SPORT_LEAGUE"] = "sport-league", SportlineRouteName1["SPORT_EVENT_DETAILS"] = "sport-event-details", SportlineRouteName1["SPORTLINE_SELECT_ODDS"] = "sportline-select-odds", SportlineRouteName1["SLIP_SHARE"] = "share-slip", SportlineRouteName1["GET_SHARED_SLIP"] = "betslip", SportlineRouteName1["SLIP_WIN_BOOST_INFO"] = "slip-win-boost-info", SportlineRouteName1["QUICK_BETS"] = "quick-bets", SportlineRouteName1["QUICK_BETS_DETAILS"] = "quick-bets-details";
// single league page in the top section
// single league page in the live section
// Quick bets V2
export default SportlineRouteName;
const sportlineRouteNames = Object.values(SportlineRouteName);
export function isSportlineRouteName(value) {
    return sportlineRouteNames.includes(value);
}
