import { isOptionalHintLinkOrNull } from 'web/src/components/Input/guards/isHintLink';
function getHintLinkFields() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const { hintLink, hintLinkRight } = options;
    isOptionalHintLinkOrNull(hintLink), JSON.stringify(hintLink);
    isOptionalHintLinkOrNull(hintLinkRight), JSON.stringify(hintLinkRight);
    return {
        hintLink,
        hintLinkRight
    };
}
export default getHintLinkFields;
