import { defineComponent as _defineComponent } from 'vue';
import { mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal/LazyDefaultModal';
import PaymentBonuses from 'web/src/modules/payments/components/PaymentBonuses/PaymentBonuses.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
import SPaymentBonuses from 'web/src/modules/payments/components/PaymentBonuses/components/SPaymentBonuses/SPaymentBonuses.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DepositBonusSelectorModal',
    props: {
        selectedOptionId: {
            default: DEPOSIT_BONUS_NONE_SELECTED
        },
        openedBonusDetails: {
            default: null
        },
        bonusList: {}
    },
    emits: [
        "back",
        "close",
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { $translate } = useI18n();
        const headerProperties = computed(()=>({
                title: $translate('WEB2_BONUSES_AVAILABLE').value,
                prefixIconName: IconName.ARROW_LEFT,
                suffixIconName: IconName.CROSS
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(LazyDefaultModal), {
                ref: "modal",
                "is-full-height": "",
                "is-full-height-centered": false,
                "is-overlay-close-allowed": false,
                "is-inner-modal": true,
                "is-profile-layout": true,
                class: "bonus-selector-modal",
                "data-test-id": "deposit-bonus-selector",
                onClose: _cache[4] || (_cache[4] = ($event)=>emit('back'))
            }, {
                header: _withCtx(()=>[
                        _createVNode(ModalWindowHeader, _mergeProps(headerProperties.value, {
                            onPrefixClick: _cache[0] || (_cache[0] = ($event)=>emit('back')),
                            onSuffixClick: _cache[1] || (_cache[1] = ($event)=>emit('close'))
                        }), null, 16)
                    ]),
                "inner-content": _withCtx(()=>[
                        (_openBlock(), _createBlock(PaymentBonuses, {
                            key: 1,
                            "bonus-list": _ctx.bonusList,
                            "is-modal": "",
                            onChange: _cache[3] || (_cache[3] = ($event)=>emit('change', $event))
                        }, null, 8, [
                            "bonus-list"
                        ]))
                    ]),
                _: 1
            }, 8, [
                "is-inner-modal",
                "is-profile-layout"
            ])), [
                [
                    _directive_auto_id,
                    'DepositBonusSelectorModal'
                ]
            ]);
        };
    }
});
