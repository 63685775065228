import { DisplayType } from '@leon-hub/api-sdk';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
// eslint-disable-next-line max-len
export default function createCustomerMessageFromFrontNotification(notification) {
    return {
        id: notification.id,
        key: `${notification.id}-${CustomerNotificationMessageSource.ALCR}`,
        title: notification.title,
        message: notification.message,
        createdAt: Date.parse(notification.timestamp),
        isRead: notification.isRead,
        isModal: notification.displayType !== DisplayType.SILENT,
        source: CustomerNotificationMessageSource.ALCR,
        image: notification.imageUrl ?? void 0,
        button: notification.ctaButtonText && notification.ctaButtonLink ? {
            text: notification.ctaButtonText,
            link: notification.ctaButtonLink
        } : void 0
    };
}
