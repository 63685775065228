import { computed } from 'vue';
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
export function useSwiperNavigationPoints(props) {
    const { swiperState, slideToSlide } = useSwiperNavigation();
    const stateActiveSlideIndex = computed(()=>swiperState?.activeSlideIndex.value);
    const slidesCount = computed(()=>{
        const count = swiperState?.slidesCounter.value ?? props.countSlides ?? 0;
        if (!props.maxSlidePointsCount) return count;
        return Math.min(props.maxSlidePointsCount, count);
    });
    const finalActiveSlideIndex = computed(()=>props.activeSlideIndex ?? stateActiveSlideIndex.value ?? -1);
    function isPointSelected(index) {
        const nativeIndex = index - 1;
        if (props.isFilledPreviousPoint) return finalActiveSlideIndex.value >= nativeIndex;
        if (props.maxSlidePointsCount && finalActiveSlideIndex.value >= props.maxSlidePointsCount) return nativeIndex >= props.maxSlidePointsCount - 1;
        return finalActiveSlideIndex.value === nativeIndex;
    }
    return {
        finalActiveSlideIndex,
        slidesCount,
        slideToSlide,
        isPointSelected
    };
}
