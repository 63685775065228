import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeProps as _normalizeProps, withCtx as _withCtx, mergeProps as _mergeProps, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "for"
];
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import InputHint from '../InputHint';
import { dataTestLabel as vDataTestLabel } from '../../directives';
import { useHintParentProps } from '../../composables';
import getInputIconSize from '../../utils/getInputIconSize';
export default /*@__PURE__*/ _defineComponent({
    __name: 'InputWrapper',
    props: {
        inputId: {
            default: ''
        },
        label: {
            default: ''
        },
        disabled: {
            type: Boolean
        },
        isHintHidden: {
            type: Boolean
        },
        isFocus: {
            type: Boolean
        },
        isEmpty: {
            type: Boolean
        },
        isLabelHidden: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        clickablePrefix: {
            type: Boolean
        },
        clickableSuffix: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {}
    },
    setup (__props) {
        const props = __props;
        const { hintProperties } = useHintParentProps(props);
        // ok not to be reactive
        const iconSize = getInputIconSize(props.isLarge);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['input-wrapper']]: true,
                    [_ctx.$style['input-wrapper--empty']]: _ctx.isEmpty,
                    [_ctx.$style['input-wrapper--error']]: !!_ctx.error || !!_ctx.errorRight,
                    [_ctx.$style['input-wrapper--focus']]: _ctx.isFocus,
                    [_ctx.$style['input-wrapper--disabled']]: _ctx.disabled,
                    [_ctx.$style['input-wrapper--large']]: _ctx.isLarge
                })
            }, [
                _renderSlot(_ctx.$slots, "label", {}, ()=>[
                        _ctx.inputId && !_ctx.isLabelHidden ? _withDirectives((_openBlock(), _createElementBlock("label", {
                            key: 0,
                            for: _ctx.inputId,
                            class: _normalizeClass(_ctx.$style['input-wrapper__label']),
                            onMousedown: _cache[0] || (_cache[0] = _withModifiers(()=>{}, [
                                "prevent"
                            ]))
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.label), 1)
                        ], 42, _hoisted_1)), [
                            [
                                _unref(vDataTestLabel)
                            ]
                        ]) : _createCommentVNode("", true)
                    ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['input-wrapper__field-container'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['input-wrapper__icon']]: true,
                            [_ctx.$style['input-wrapper__icon--prefix']]: true,
                            [_ctx.$style['input-wrapper__icon--inert']]: !_ctx.clickablePrefix
                        })
                    }, [
                        _renderSlot(_ctx.$slots, "iconPrefix")
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['input-wrapper__field'])
                    }, [
                        _renderSlot(_ctx.$slots, "input")
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['input-wrapper__icon']]: true,
                            [_ctx.$style['input-wrapper__icon--suffix']]: true,
                            [_ctx.$style['input-wrapper__icon--inert']]: !_ctx.clickableSuffix
                        })
                    }, [
                        _ctx.disabled ? (_openBlock(), _createBlock(_unref(VIcon), {
                            key: 0,
                            size: _unref(iconSize),
                            name: _unref(IconName).LOCK,
                            class: _normalizeClass(_ctx.$style['input-wrapper__lock'])
                        }, null, 8, [
                            "size",
                            "name",
                            "class"
                        ])) : _renderSlot(_ctx.$slots, "iconSuffix", {
                            key: 1
                        })
                    ], 2)
                ], 2),
                _ctx.isHintHidden ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(InputHint), _normalizeProps(_mergeProps({
                    key: 0
                }, _unref(hintProperties))), {
                    hintLeft: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "hintLeft")
                        ]),
                    hintRight: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "hintRight")
                        ]),
                    default: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "hint")
                        ]),
                    _: 3
                }, 16)),
                _renderSlot(_ctx.$slots, "dropdown")
            ], 2)), [
                [
                    _directive_auto_id,
                    'InputWrapper'
                ]
            ]);
        };
    }
});
