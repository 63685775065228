import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "payments-content"
};
import { onBeforeMount, provide, reactive } from 'vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsContent',
    props: {
        htmlContentProperties: {},
        htmlContent: {}
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const cmsData = reactive({
            payId: ''
        });
        provide('cmsData', cmsData);
        onBeforeMount(()=>{
            if (props.htmlContentProperties) {
                for (const { key, value } of props.htmlContentProperties)if (key) cmsData[key] = value;
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VDynamicContent, {
                    content: _ctx.htmlContent,
                    onClose: _cache[0] || (_cache[0] = ($event)=>emit('close'))
                }, null, 8, [
                    "content"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsContent'
                ]
            ]);
        };
    }
});
