import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useServiceUnavailableError() {
    const { $translate } = useI18n();
    const errorProps = computed(()=>({
            title: $translate('WEB2_ERROR_503_TITLE_V2').value,
            text: `
      ${$translate('WEB2_ERROR_503_TEXT1_V2').value}
      ${$translate('WEB2_ERROR_503_TEXT2_V2').value}
      ${$translate('WEB2_ERROR_503_TEXT3_V2').value}
    `.trim(),
            iconName: IconName.GEARS
        }));
    return {
        errorProps
    };
}
