import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "for"
];
const _hoisted_2 = [
    "id",
    "value"
];
const _hoisted_3 = [
    "src"
];
import { IconName } from '@leon-hub/icons';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import { InputButton } from 'web/src/components/Input/components';
import VTooltip from 'web/src/modules/dialogs/components/VTooltip/VTooltip.vue';
import { TabId } from '../enums/TabId';
import { useCryptoPaymentData } from '../composables/useCryptoPaymentData';
// todo: probably should use helper below for qr src
// import getImageQr from '../../PaymentsCrypto/composables/utils/getImageQr';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CryptoPaymentData',
    props: {
        name: {
            default: 'payment-data'
        },
        fields: {
            default: ()=>[]
        },
        qrCodeSrc: {},
        expires: {}
    },
    setup (__props) {
        const props = __props;
        const { selectTab, activeTab, switcherOptions, showExpires, timeLeft, uniqueId, timerIsEnded, isTooltipShown, handleCopyToClipboard } = useCryptoPaymentData(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("fieldset", {
                class: _normalizeClass(_ctx.$style['crypto-payments-data'])
            }, [
                _ctx.qrCodeSrc ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['crypto-payments-data__switch'])
                }, [
                    _createVNode(VSwitcher, {
                        "active-id": _unref(activeTab),
                        options: _unref(switcherOptions),
                        onChange: _unref(selectTab)
                    }, null, 8, [
                        "active-id",
                        "options",
                        "onChange"
                    ])
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['crypto-payments-data__content'])
                }, [
                    _unref(timerIsEnded) ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['crypto-payments-data__hint-ended'])
                    }, _toDisplayString(_ctx.$t('WEB2_PAYMENTS_EXPIRES_TIMER_END')), 3)) : (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _createElementVNode("p", {
                            class: _normalizeClass(_ctx.$style['crypto-payments-data__hint'])
                        }, _toDisplayString(_unref(activeTab) === _unref(TabId).Details ? _ctx.$t('WEB2_PAYMENTS_PASTE_DATA_DESCRIPTION') : _ctx.$t('WEB2_PAYMENTS_SCAN_QR_DESCRIPTION')), 3),
                        _unref(showExpires) ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['crypto-payments-data__expires'])
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_PAYMENTS_EXPIRES')) + ": ", 1),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['crypto-payments-data__timer'])
                            }, _toDisplayString(_unref(timeLeft)), 3)
                        ], 2)) : _createCommentVNode("", true)
                    ], 64)),
                    _unref(activeTab) === _unref(TabId).Details ? (_openBlock(), _createElementBlock("ul", {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['crypto-payments-data__list'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (item, index)=>(_openBlock(), _createElementBlock("li", {
                                key: index,
                                class: _normalizeClass(_ctx.$style['crypto-payments-data__row'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['crypto-payments-data__item']]: true,
                                        [_ctx.$style['crypto-payments-data__item--with-button']]: item.isCopyEnabled
                                    })
                                }, [
                                    _createElementVNode("label", {
                                        class: _normalizeClass(_ctx.$style['crypto-payments-data__label']),
                                        for: `${_unref(uniqueId)}${index}`
                                    }, _toDisplayString(item.label), 11, _hoisted_1),
                                    _createElementVNode("input", {
                                        id: `${_unref(uniqueId)}${index}`,
                                        type: "text",
                                        class: _normalizeClass(_ctx.$style['crypto-payments-data__value']),
                                        readonly: "",
                                        value: item.value
                                    }, null, 10, _hoisted_2)
                                ], 2),
                                item.isCopyEnabled && item.value ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['crypto-payments-data__button'])
                                }, [
                                    _createVNode(_unref(InputButton), {
                                        title: _ctx.$t('WEB2_COPY'),
                                        "icon-name": _unref(IconName).FILES,
                                        onClick: ($event)=>_unref(handleCopyToClipboard)(item.value)
                                    }, null, 8, [
                                        "title",
                                        "icon-name",
                                        "onClick"
                                    ])
                                ], 2)) : _createCommentVNode("", true)
                            ], 2))), 128))
                    ], 2)) : _createCommentVNode("", true),
                    _unref(activeTab) === _unref(TabId).QrCode ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: _normalizeClass(_ctx.$style['crypto-payments-data__qr-frame'])
                    }, [
                        _createElementVNode("img", {
                            src: _ctx.qrCodeSrc,
                            alt: "qr code",
                            class: _normalizeClass(_ctx.$style['crypto-payments-data__qr'])
                        }, null, 10, _hoisted_3)
                    ], 2)) : _createCommentVNode("", true),
                    _unref(isTooltipShown) ? (_openBlock(), _createBlock(VTooltip, {
                        key: 4,
                        class: _normalizeClass(_ctx.$style['crypto-payments-data__tooltip']),
                        text: _ctx.$t('WEB2_DEFAULT_COPY_NOTIFICATION')
                    }, null, 8, [
                        "class",
                        "text"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CryptoPaymentData'
                ]
            ]);
        };
    }
});
