import { defineStore } from 'pinia';
import { ref } from 'vue';
import { doConfirmBonus, getBonusLoyalty } from '@leon-hub/api-sdk';
import { useWebSockets } from 'web/src/modules/core/composables';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
const useBonusLoyaltyStore = defineStore('bonus-loyalty-store', ()=>{
    const apiClient = useGraphqlClient();
    const { isLoggedIn } = useIsLoggedIn();
    const { subscribeAuthorized } = useWebSockets();
    const { isLoyaltyUpdateEnabled } = useWebSocketsConfig();
    // State
    const amount = ref('');
    const amountNumber = ref(0);
    const progress = ref(0);
    const progressPercentString = ref('');
    const remainingAmount = ref('');
    const remainingAmountNumber = ref(0);
    const bonusLoaded = ref(false);
    const isParticipating = ref(false);
    const bonusPollingInterval = ref(20000);
    let isRequestSent = false;
    const hideLeonShopOnboardingPage = ref(false);
    const setBonusLoaded = (isLoaded)=>{
        bonusLoaded.value = isLoaded;
        "1";
        isParticipating.value = true;
    };
    const setBonusLoyalty = (data)=>{
        const { amount: dataAmount, amountNumber: dataAmountNumber, progress: dataProgress, progressPercentString: dataProgressPercentString, remainingAmount: dataRemainingAmount, remainingAmountNumber: dataRemainingAmountNumber, isParticipating: dataIsParticipating } = data.loyalty;
        amount.value = dataAmount || '';
        amountNumber.value = dataAmountNumber || 0;
        progress.value = dataProgress || 0;
        progressPercentString.value = dataProgressPercentString || '';
        remainingAmount.value = dataRemainingAmount || '';
        remainingAmountNumber.value = dataRemainingAmountNumber || 0;
        isParticipating.value = dataIsParticipating;
    };
    const setIsParticipating = (value)=>{
        isParticipating.value = value;
    };
    const setHideLeonShopOnBoardingPageState = (value)=>{
        hideLeonShopOnboardingPage.value = value;
    };
    const fetchBonusLoyalty = async (alwaysRequest)=>{
        const doRequest = alwaysRequest || !isRequestSent;
        if (doRequest && isLoggedIn.value) {
            const result = await getBonusLoyalty(apiClient, (node)=>node.queries.bonuses.getBonusLoyalty);
            setBonusLoyalty(result);
            if (!isRequestSent) isRequestSent = true;
            setHideLeonShopOnBoardingPageState(result.hideOnBoardingOnLeonShopPage);
        }
        setBonusLoaded(true);
    };
    const updateBonusLoyalty = (data)=>{
        amountNumber.value = data.newProperties.amountNumber || 0;
        remainingAmountNumber.value = data.newProperties.remainingAmountNumber || 0;
        progress.value = data.newProperties.progress || 0;
        progressPercentString.value = data.newProperties.progressPercentString || '';
    };
    const confirmBonus = async ()=>{
        const response = await doConfirmBonus(apiClient, (node)=>node.mutations.bonuses.confirm);
        if (response.confirmed) await Promise.all([
            fetchBonusLoyalty()
        ]);
    };
    const subscribeOnBonusBalanceChange = ()=>{
        subscribeAuthorized({
            method: 'onBonusBalanceChange',
            onMessage: (data)=>{
                updateBonusLoyalty({
                    newProperties: {
                        amountNumber: data.onBonusBalanceChange.availableBalance,
                        remainingAmountNumber: data.onBonusBalanceChange.remainingAmountNumber,
                        progress: data.onBonusBalanceChange.progress,
                        progressPercentString: data.onBonusBalanceChange.progressPercentString
                    }
                });
            },
            isEnabled: isLoyaltyUpdateEnabled,
            polling: {
                timeout: bonusPollingInterval,
                callback: ()=>fetchBonusLoyalty(true),
                callOnLogin: true
            }
        });
    };
    subscribeOnBonusBalanceChange();
    return {
        confirmBonus,
        fetchBonusLoyalty,
        setIsParticipating,
        setHideLeonShopOnBoardingPageState,
        amount,
        amountNumber,
        progress,
        progressPercentString,
        remainingAmount,
        remainingAmountNumber,
        bonusLoaded,
        isParticipating,
        bonusPollingInterval,
        hideLeonShopOnboardingPage
    };
});
export default useBonusLoyaltyStore;
