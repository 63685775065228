import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import { useQuickBetsLobbyPage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'QuickBetsLobbyRoutePage',
    setup (__props) {
        const { isLoadingLobby, lobbyType, groupId, categoryId, skeletons } = useQuickBetsLobbyPage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['fast-games-lobby']]: true,
                    [_ctx.$style['component-wrapper']]: true,
                    [_ctx.$style['content-min-max']]: "1"
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['fast-games-lobby__content-wrapper'])
                }, [
                    _unref(isLoadingLobby) ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass([
                            _ctx.$style['fast-games-lobby__title'],
                            _ctx.$style['fast-games-lobby__title--skeleton']
                        ])
                    }, null, 2)) : (_openBlock(), _createElementBlock("h1", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['fast-games-lobby__title'])
                    }, _toDisplayString(_ctx.$t('WEB2_FASTGAMES_LOBBY_TTL')), 3))
                ], 2),
                _createVNode(_unref(LobbyBlock), {
                    "lobby-type": _unref(lobbyType),
                    "group-id": _unref(groupId),
                    "category-id": _unref(categoryId),
                    skeletons: _unref(skeletons),
                    "prevent-items-fetch": "",
                    class: _normalizeClass(_ctx.$style['fast-games-lobby__lobby'])
                }, null, 8, [
                    "lobby-type",
                    "group-id",
                    "category-id",
                    "skeletons",
                    "class"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['fast-games-lobby__content-wrapper'])
                }, [
                    _createVNode(VHintBlock, {
                        label: _ctx.$t('WEB2_FASTGAMES_LOBBY_HINT'),
                        class: _normalizeClass(_ctx.$style['fast-games-lobby__hint'])
                    }, null, 8, [
                        "label",
                        "class"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['fast-games-lobby__desc'])
                    }, _toDisplayString(_ctx.$t('WEB2_FASTGAMES_LOBBY_FOOTER_DESC')), 3)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'QuickBetsLobbyRoutePage'
                ]
            ]);
        };
    }
});
