import getBaseHeaders from '@leon-hub/api/src/client/getBaseHeaders';
import resolveApi3Url from '@leon-hub/api/src/helpers/resolveApi3Url';
import ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';
let CreditCardTokenizationApiClient = class CreditCardTokenizationApiClient extends ApplicationRestClient {
    constructor(){
        super({
            baseUrl: resolveApi3Url(),
            method: 'POST',
            origin: '',
            headers: {
                ...getBaseHeaders(),
                'Content-Type': 'application/json;charset=UTF-8'
            }
        });
    }
};
export { CreditCardTokenizationApiClient as default };
