import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { useMixedHeadlineEventsList } from 'web/src/modules/sportline/submodules/top/composables';
import SportlineHomeSportElement from 'web/src/modules/home/submodules/sportline/views/SportlineHomeSportElement.vue';
import { useHomeSportlineElementSeoMeta } from './composables';
const expandFirstCount = 5;
// @see LEONWEB-2140
export default /*@__PURE__*/ _defineComponent({
    __name: 'MixedSportlineEventsBlock',
    setup (__props) {
        const { isTopEventsLogosEnabled, isMarketTypesSelectionEnabled } = useSportlineTopEventsSettings();
        const { totalCount, headlineElements } = useMixedHeadlineEventsList();
        useHomeSportlineElementSeoMeta({
            sportElements: headlineElements
        });
        const isExpandAllByDefault = !!process.env.VUE_APP_PRERENDER;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(totalCount) > 0 ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['sport-events-block'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(headlineElements), (headlineElement, index)=>(_openBlock(), _createBlock(SportlineHomeSportElement, {
                        key: headlineElement.key,
                        "sport-element": headlineElement,
                        "is-market-types-selection-enabled": _unref(isMarketTypesSelectionEnabled),
                        "expand-key": `home-${headlineElement.sport.representation.family}`,
                        "is-default-expanded": isExpandAllByDefault || index < expandFirstCount,
                        "can-show-logos": _unref(isTopEventsLogosEnabled),
                        "is-shown-live-indicator": "",
                        "is-shown-leagues-headline": ""
                    }, null, 8, [
                        "sport-element",
                        "is-market-types-selection-enabled",
                        "expand-key",
                        "is-default-expanded",
                        "can-show-logos"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'MixedSportlineEventsBlock'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
