import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let PlatformComponent = class PlatformComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result([
            window.navigator.platform ? window.navigator.platform : ComponentStatus.NotAvailable
        ]);
    }
    constructor(){
        super(ComponentKey.Platform);
    }
};
export { PlatformComponent as default };
