import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export class PushNotificationsModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addModalRoute({
            name: RouteName.PUSH_NOTIFICATIONS_BETS_ONBOARDING,
            path: '/push-notifications-bets-onboarding',
            modalPreset: ModalDesktopPreset.SmallDesktopModal,
            access: CustomerRouteAccessRole.AUTHORIZED,
            // eslint-disable-next-line max-len
            component: ()=>import('web/src/modules/push-notifications/pages/PushNotificationsBetsOnboardingRoutePage/PushNotificationsBetsOnboardingRoutePage.vue')
        });
    }
}
