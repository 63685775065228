import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeProps as _normalizeProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 3
};
import { inject, watch, useSlots, onMounted } from 'vue';
import { Tag } from '@leon-hub/tags';
import { VIcon } from '@components/v-icon';
import { AccordionItemType } from 'web/src/components/Accordion/types';
import { useVAccordionItem } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VAccordionItem',
    props: {
        id: {},
        open: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        tag: {
            default: Tag.DIV
        },
        icon: {},
        title: {
            default: 'No title provided'
        },
        indicator: {
            type: [
                Boolean,
                Object,
                Array
            ],
            default: true
        },
        inline: {
            type: Boolean
        },
        itemType: {
            default: AccordionItemType.BASIC
        },
        isCenteredTitle: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "toggle",
        "open"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const accordionSharedState = inject('accordionSharedState', {});
        const { noIconProperties, isOpen, hasHeaderSlot, hasSuffixSlot, hasPrefixSlot, hasComputedIcon, hasEmptyIcon, hasPrefix, rootTag, headerTag, contentTag, computedIconProperties, computedIndicatorProperties, independentlyOpenToggle } = useVAccordionItem(props, slots, accordionSharedState);
        function emitToggle() {
            emit('toggle', props.id);
        }
        function emitClose() {
            emit('close', props.id);
        }
        function emitOpen() {
            emit('open', props.id);
        }
        function toggle() {
            let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
            if (accordionSharedState.controlled) {
                if (!silent) {
                    emitToggle();
                    if (props.open) emitClose();
                    else emitOpen();
                }
                return;
            }
            independentlyOpenToggle();
            if (!accordionSharedState.independentItems) accordionSharedState.openItem = isOpen.value ? null : props.id;
        }
        onMounted(()=>{
            if (props.open) toggle(true);
        });
        watch(()=>isOpen.value, (newValue)=>{
            if (!accordionSharedState.controlled) {
                emitToggle();
                if (newValue) emitOpen();
                else emitClose();
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(rootTag)), {
                class: _normalizeClass({
                    [_ctx.$style['accordion-item']]: true,
                    [_ctx.$style['accordion-item--large']]: _ctx.isLarge,
                    [_ctx.$style['accordion-item--open']]: _unref(isOpen),
                    [_ctx.$style['accordion-item--panel']]: !!_unref(accordionSharedState).panels,
                    [_ctx.$style['accordion-item--no-background']]: !!_ctx.noBackground,
                    // Disable default styling when panels or header slot used, or when header has inline layout
                    [_ctx.$style['accordion-item--default']]: !_unref(hasHeaderSlot) && !_unref(accordionSharedState).panels && !props.inline,
                    [_ctx.$style['accordion-item--how-to']]: _ctx.itemType === _unref(AccordionItemType).HOW_TO
                })
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "header", {
                            isOpen: _unref(isOpen),
                            toggle: toggle
                        }, ()=>[
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(headerTag)), {
                                    class: _normalizeClass({
                                        [_ctx.$style['accordion-item__header']]: true,
                                        [_ctx.$style['accordion-item__header--open']]: _unref(isOpen),
                                        [_ctx.$style['accordion-item__header--clickable']]: !_unref(accordionSharedState).controlled,
                                        [_ctx.$style['accordion-item__header--inline']]: _ctx.inline,
                                        [_ctx.$style['accordion-item__header--center']]: !!_ctx.isCenteredTitle
                                    }),
                                    onClick: _cache[0] || (_cache[0] = ($event)=>toggle(false))
                                }, {
                                    default: _withCtx(()=>[
                                            _unref(hasPrefix) ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                class: _normalizeClass(_ctx.$style['accordion-item__prefix'])
                                            }, [
                                                _unref(hasComputedIcon) && _unref(computedIconProperties) ? (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                                                    key: 0
                                                }, _unref(computedIconProperties))), null, 16)) : _unref(hasEmptyIcon) ? (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                                                    key: 1
                                                }, _unref(noIconProperties))), null, 16)) : _unref(hasPrefixSlot) ? _renderSlot(_ctx.$slots, "prefix", {
                                                    key: 2,
                                                    isOpen: _unref(isOpen),
                                                    toggle: toggle
                                                }) : (_openBlock(), _createElementBlock("div", _hoisted_1, " Something went wrong "))
                                            ], 2)) : _createCommentVNode("", true),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['accordion-item__title'])
                                            }, [
                                                _renderSlot(_ctx.$slots, "title", {
                                                    isOpen: _unref(isOpen),
                                                    toggle: toggle
                                                }, ()=>[
                                                        _createTextVNode(_toDisplayString(_ctx.title), 1)
                                                    ])
                                            ], 2),
                                            _unref(hasSuffixSlot) ? (_openBlock(), _createElementBlock("div", {
                                                key: 1,
                                                class: _normalizeClass(_ctx.$style['accordion-item__suffix'])
                                            }, [
                                                _renderSlot(_ctx.$slots, "suffix", {
                                                    isOpen: _unref(isOpen),
                                                    toggle: toggle
                                                })
                                            ], 2)) : _createCommentVNode("", true),
                                            _unref(computedIndicatorProperties) ? (_openBlock(), _createElementBlock("div", {
                                                key: 2,
                                                class: _normalizeClass(_ctx.$style['accordion-item__indicator'])
                                            }, [
                                                _createVNode(_unref(VIcon), _mergeProps({
                                                    class: _ctx.$style['accordion-item__indicator-icon']
                                                }, _unref(computedIndicatorProperties)), null, 16, [
                                                    "class"
                                                ])
                                            ], 2)) : _createCommentVNode("", true)
                                        ]),
                                    _: 3
                                }, 8, [
                                    "class"
                                ]))
                            ]),
                        _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(contentTag)), {
                            class: _normalizeClass(_ctx.$style['accordion-item__content'])
                        }, {
                            default: _withCtx(()=>[
                                    _renderSlot(_ctx.$slots, "default", {
                                        isOpen: _unref(isOpen),
                                        toggle: toggle
                                    }, ()=>[
                                            _createTextVNode(" No content provided for " + _toDisplayString(_ctx.id), 1)
                                        ])
                                ]),
                            _: 3
                        }, 8, [
                            "class"
                        ])), [
                            [
                                _vShow,
                                _unref(isOpen)
                            ]
                        ])
                    ]),
                _: 3
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'VAccordionItem'
                ]
            ]);
        };
    }
});
