import { doWithdrawSportBonus } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function doWithdrawSportBonusApi(campaignId) {
    const apiClient = useGraphqlClient();
    return doWithdrawSportBonus(apiClient, (node)=>node.mutations.bonuses.withdrawSportBonus, {
        options: {
            campaignId
        }
    });
}
