import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, CasinoIconName } from '@leon-hub/icons';
import CasinoLoyaltyProgramIcon from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCasinoLoyaltyProgramCurrentLevelCard } from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCurrentLevelCard/composables';
import BonusLoyaltyProgramBalance from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramCurrentLevelCard',
    props: {
        initLevel: {
            type: Boolean
        },
        currentValue: {
            default: 0
        },
        total: {
            default: 0
        },
        imageSrc: {
            default: ''
        },
        levelTitle: {
            default: ''
        },
        nextLevelTitle: {
            default: ''
        },
        multiplier: {
            default: '0'
        },
        image: {
            default: null
        },
        backgroundColor: {
            default: ''
        }
    },
    emits: [
        "show-description"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { currentBalance, remainingPoints, nextLevel, multiplierLabel, onClick, onGiftClick, clickOnBalance } = useCasinoLoyaltyProgramCurrentLevelCard(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-loyalty-program-current-level-card']]: true,
                    [_ctx.$style['casino-loyalty-program-current-level-card--init-level']]: _ctx.initLevel
                }),
                style: _normalizeStyle({
                    background: _ctx.backgroundColor
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onClick) && _unref(onClick)(...args);
                })
            }, [
                _ctx.initLevel ? _createCommentVNode("", true) : (_openBlock(), _createBlock(VButton, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-current-level-card__button']),
                    kind: _unref(ButtonKind).PRIMARY,
                    height: _unref(ButtonHeight).SMALL,
                    "icon-name": _unref(IconName).PROMOS,
                    rounded: "",
                    "is-absolute-position": "",
                    onClick: _withModifiers(_unref(onGiftClick), [
                        "stop"
                    ])
                }, null, 8, [
                    "class",
                    "kind",
                    "height",
                    "icon-name",
                    "onClick"
                ])),
                _createVNode(CasinoLoyaltyProgramIcon, {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-current-level-card__icon']),
                    "image-src": _ctx.imageSrc,
                    size: _unref(CasinoLoyaltyProgramIconSizes).SIZE_132,
                    "is-inactive": _ctx.initLevel,
                    "gradient-bg": "",
                    image: _ctx.image
                }, null, 8, [
                    "class",
                    "image-src",
                    "size",
                    "is-inactive",
                    "image"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-current-level-card__title'])
                }, _toDisplayString(_ctx.levelTitle), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-current-level-card__points-info'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['casino-loyalty-program-current-level-card__current'])
                    }, _toDisplayString(_unref(currentBalance)), 3),
                    _cache[1] || (_cache[1] = _createTextVNode(" / ")),
                    _createElementVNode("span", null, _toDisplayString(_unref(remainingPoints)) + " " + _toDisplayString(_unref(nextLevel)), 1)
                ], 2),
                _createVNode(BonusLoyaltyProgramBalance, {
                    "icon-name": _unref(CasinoIconName).CASINO_ROULETTE,
                    text: `${_unref(multiplierLabel)} ${_ctx.multiplier}`,
                    onClick: _unref(clickOnBalance)
                }, null, 8, [
                    "icon-name",
                    "text",
                    "onClick"
                ])
            ], 6)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramCurrentLevelCard'
                ]
            ]);
        };
    }
});
