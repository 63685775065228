import { BetlineType } from 'web/src/modules/sportline/enums/rest';
export function isBetlineType(argument) {
    return [
        BetlineType.All,
        BetlineType.Live,
        BetlineType.Prematch,
        BetlineType.Outright,
        BetlineType.Planed,
        BetlineType.Virtual
    ].includes(argument);
}
