import { HomePageType, ProgramRequirementType } from '@leon-hub/api-sdk/src/sdk/sdk';
import RouteName from '@leon-hub/routing-config-names';
export default function getRequirementsRoute(type, homePageType) {
    switch(type){
        case ProgramRequirementType.REGISTRATION:
            return RouteName.REGISTRATION;
        case ProgramRequirementType.DEPOSIT:
            return RouteName.DEPOSITS;
        case ProgramRequirementType.BET:
            return homePageType && [
                HomePageType.SLOTS,
                HomePageType.LIVE_GAMES
            ].includes(homePageType) ? RouteName.HOME : RouteName.SPORTLINE_TOP_EVENTS;
        case ProgramRequirementType.IDENTIFICATION:
            return RouteName.IDENTIFICATION;
        default:
            return RouteName.HOME;
    }
}
