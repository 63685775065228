export default function searchSelectOptions(input) {
    let searchValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '';
    // return input;
    const lowerSearch = searchValue.toLowerCase();
    const regexp = new RegExp(`(${lowerSearch})`, 'gmi');
    const searchMath = (param)=>{
        let { label } = param;
        return label.toLowerCase().search(regexp);
    };
    return input.filter((param)=>{
        let { label } = param;
        return label.toLowerCase().includes(lowerSearch);
    }).sort((a, b)=>{
        if (searchMath(a) < searchMath(b)) return -1;
        if (searchMath(a) > searchMath(b)) return 1;
        return 0;
    });
}
