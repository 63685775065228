import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = [
    "href"
];
import { useVReCaptchaBadge } from 'web/src/modules/captcha/components/VReCaptchaBadge/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VReCaptchaBadge',
    props: {
        termsLinkTemplate: {
            default: 'https://policies.google.com/terms?hl={lang}'
        },
        privacyLinkTemplate: {
            default: 'https://policies.google.com/privacy?hl={lang}'
        },
        lang: {}
    },
    setup (__props) {
        const props = __props;
        const { privacyLink, termsLink } = useVReCaptchaBadge(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['captcha-badge'])
            }, [
                _createTextVNode(_toDisplayString(_ctx.$t('JSP_PROTECTED_RECAPTCHA')), 1),
                _createElementVNode("a", {
                    target: "_blank",
                    href: _unref(privacyLink),
                    class: _normalizeClass(_ctx.$style['captcha-badge__link'])
                }, _toDisplayString(_ctx.$t('JSP_CONDITIONS')), 11, _hoisted_1),
                _createTextVNode(_toDisplayString(_ctx.$t('JSP_AND')), 1),
                _createElementVNode("a", {
                    target: "_blank",
                    href: _unref(termsLink),
                    class: _normalizeClass(_ctx.$style['captcha-badge__link'])
                }, _toDisplayString(_ctx.$t('JSP_RULES')), 11, _hoisted_2),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VReCaptchaBadge'
                ]
            ]);
        };
    }
});
