import { isObject, isNull, isUndefined, isString, isBoolean, isNumber } from '@leon-hub/guards';
import { knownBooleanProps, knownNumberProps, knownStringArrayProps } from '../constants/knownFields';
import getStringArrayOption from './getStringArrayOption';
import getBooleanOption from './getBooleanOption';
import getNumberOption from './getNumberOption';
function getMappedValue(key, value) {
    if (isNull(value) || isUndefined(value)) return null;
    if (isObject(value)) return value;
    if (knownBooleanProps.includes(key)) return getBooleanOption(value);
    if (knownNumberProps.includes(key)) return getNumberOption(value);
    if (knownStringArrayProps.includes(key)) return getStringArrayOption(value);
    // no handling for array, but its unexpected to be here
    // pass value without transformation
    isString(value) || isBoolean(value) || isNumber(value);
    return value;
}
export default function mapFieldRawOptions(rawOptions) {
    const result = {};
    const keys = Object.keys(rawOptions);
    for (const key of keys){
        const value = rawOptions[key];
        const mappedValue = getMappedValue(key, value);
        if (null !== mappedValue) result[key] = mappedValue;
    }
    return result;
}
