import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import VList from 'web/src/components/List/VList/VList.vue';
import { ListType } from 'web/src/components/List/enums';
import LeaguesListItem from './LeaguesListItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguesList',
    props: {
        sport: {},
        leagues: {},
        isTop: {
            type: Boolean
        },
        dateTimeFilter: {}
    },
    setup (__props) {
        const props = __props;
        const currentFilter = toRef(props, 'dateTimeFilter');
        const bus = useEventsBus();
        const { setSportlineNavigationBetweenPagesFilter } = useSportlineNavigationStore();
        function onSidebarLinkClick(param) {
            let { event, leagueId } = param;
            setSportlineNavigationBetweenPagesFilter(currentFilter.value);
            bus.emit(BusEvent.CATALOG_CLICK, {
                event,
                payload: {
                    leagueId
                }
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['leagues-list']]: true,
                    [_ctx.$style['leagues-list--top']]: _ctx.isTop
                })
            }, [
                _ctx.isTop ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['leagues-list__title'])
                }, _toDisplayString(_ctx.$t('WEB2_TOP_LEAGUES_CAPITALIZE')), 3)) : _createCommentVNode("", true),
                _createVNode(VList, {
                    class: _normalizeClass({
                        [_ctx.$style['leagues-list__list']]: true,
                        [_ctx.$style['leagues-list__list--top']]: _ctx.isTop
                    }),
                    "list-type": _unref(ListType).GROUP
                }, {
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leagues, (leagueElement)=>(_openBlock(), _createBlock(LeaguesListItem, {
                                    key: `league-${leagueElement.league.id}`,
                                    "sport-id": _ctx.sport.id,
                                    "league-element": leagueElement,
                                    "is-region-name-displayed": _ctx.isTop,
                                    onClick: onSidebarLinkClick
                                }, null, 8, [
                                    "sport-id",
                                    "league-element",
                                    "is-region-name-displayed"
                                ]))), 128))
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "list-type"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaguesList'
                ]
            ]);
        };
    }
});
