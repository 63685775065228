import { ShowcaseNavigationItemType } from 'web/src/modules/home/submodules/showcase/enums';
export default function useShowcaseNavigationIconItemsComposable() {
    function createIconNavigationItem(icon) {
        const props = {
            name: icon.name,
            size: icon.size
        };
        return {
            is: ShowcaseNavigationItemType.Icon,
            props
        };
    }
    return {
        createIconNavigationItem
    };
}
