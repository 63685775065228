import { toRef, computed } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import { SportlineType, SportWidgetType } from 'web/src/modules/sportline/enums';
export function useSportEventWidgetConfig(props) {
    const { sportEventDetails } = props;
    const sportlineSettingsStore = useSportlineSettingsStore();
    const sportlineWidgetsStore = useSportlineWidgetsStore();
    const betgeniusConfigFinal = toRef(sportlineWidgetsStore, 'betgeniusConfigFinal');
    const lsportsConfigFinal = toRef(sportlineWidgetsStore, 'lsportsConfigFinal');
    const { isActiveLSportWidgetSport } = sportlineWidgetsStore;
    const isLiveStreamEnabled = toRef(sportlineSettingsStore, 'isLiveStreamEnabled');
    const isSportradarLiveMatchTrackerEnabled = toRef(sportlineSettingsStore, 'isSportradarLiveMatchTrackerEnabled');
    const isBetGeniusWidgetEnabled = toRef(sportlineSettingsStore, 'isBetGeniusWidgetEnabled');
    const isLSportsWidgetEnabled = toRef(sportlineSettingsStore, 'isLSportsWidgetEnabled');
    const isLiveStreamWidgetFloatingEnabled = toRef(sportlineSettingsStore, 'isLiveStreamWidgetFloatingEnabled');
    const isSportRadarWidgetFloatingEnabled = toRef(sportlineSettingsStore, 'isSportRadarWidgetFloatingEnabled');
    const sportEventDetailsLiveWidget = computed(()=>sportEventDetails.value?.type === SportlineType.Live ? sportEventDetails.value?.liveWidget ?? null : null);
    /** @deprecated use each setting directly */ const sportEventWidgetConfig = computed(()=>({
            liveStreamEnabled: isLiveStreamEnabled.value,
            sportradarLiveMatchTrackerEnabled: isSportradarLiveMatchTrackerEnabled.value,
            betGeniusWidgetEnabled: isBetGeniusWidgetEnabled.value,
            lsportsWidgetEnabled: isLSportsWidgetEnabled.value,
            liveStreamWidgetFloatingEnabled: isLiveStreamWidgetFloatingEnabled.value,
            sportRadarWidgetFloatingEnabled: isSportRadarWidgetFloatingEnabled.value
        }));
    const isBetgeniusWidgetAvailable = computed(()=>isBetGeniusWidgetEnabled.value && sportEventDetailsLiveWidget.value?.type === SportWidgetType.BET_GENIUS && !!betgeniusConfigFinal.value?.active);
    const isLSportsWidgetAvailable = computed(()=>{
        if (!sportEventDetails.value || !lsportsConfigFinal.value?.active || !isLSportsWidgetEnabled.value || sportEventDetailsLiveWidget.value?.type !== SportWidgetType.LSPORTS) return false;
        return isActiveLSportWidgetSport(// @TODO check is this really sportFamily from representation or must be from navigationParameters
        sportEventDetails.value.sport.representation.family).value;
    });
    const isSportradarLiveMatchTrackerAvailable = computed(()=>{
        if (sportEventDetailsLiveWidget.value?.type !== SportWidgetType.SPORTRADAR) return false;
        return isSportradarLiveMatchTrackerEnabled.value;
    });
    const liveWidgetType = computed(()=>{
        if (!sportEventDetailsLiveWidget.value) return;
        if (isBetgeniusWidgetAvailable.value) return SportWidgetType.BET_GENIUS;
        if (isLSportsWidgetAvailable.value) return SportWidgetType.LSPORTS;
        if (isSportradarLiveMatchTrackerAvailable.value) return SportWidgetType.SPORTRADAR;
    });
    return {
        sportEventWidgetConfig,
        liveWidgetType
    };
}
