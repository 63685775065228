import { computed, ref } from 'vue';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useVLoadMoreObserver(props, emit) {
    const { $translate } = useI18n();
    const collision = ref();
    const buttonText = computed(()=>props.fallbackButtonText || $translate('WEB2_LOAD_MORE').value);
    const showFallbackButton = 'undefined' == typeof IntersectionObserver;
    useIntersectionObserver(collision, (isIntersecting)=>{
        if (isIntersecting) emit('load-more');
    }, {
        threshold: 1
    });
    return {
        collision,
        buttonText,
        showFallbackButton
    };
}
