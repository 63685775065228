import { getImageOrIcon } from 'web/src/modules/icons';
export function getJumbotronProps(param) {
    let { alertIconSize, alertIcon, image, iconKind, iconSize, icon } = param;
    const props = getImageOrIcon({
        icon,
        alertIcon,
        image
    });
    // alert icon is active when there is no image (overrides icon).
    const isAlertIconActive = alertIcon && !image && props.image;
    return {
        ...props,
        icon: props.iconName,
        alertIconSize: isAlertIconActive ? alertIconSize : void 0,
        iconKind: props.iconName ? iconKind : void 0,
        iconSize: props.iconName ? iconSize : void 0
    };
}
