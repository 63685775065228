import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    class: "language-switcher-list-item__content"
};
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { useIsGlobeIcon } from 'web/src/modules/i18n/components/LanguageSelector/composables';
import VCountryFlag from 'web/src/components/CountryFlag/VCountryFlag/VCountryFlag.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LanguageSwitcherListItem',
    props: {
        name: {
            default: 'RU'
        },
        title: {
            default: 'Россия'
        }
    },
    emits: [
        "open-language"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { isGlobeIcon } = useIsGlobeIcon();
        const iconProperties = {
            name: IconName.GLOBE,
            size: IconSize.SIZE_20
        };
        const pointingProperties = {
            name: IconName.EXPAND_DOWN,
            size: IconSize.SIZE_16
        };
        return (_ctx, _cache)=>(_openBlock(), _createBlock(VListItem, {
                key: 1,
                title: _ctx.title,
                class: "language-switcher-list-item",
                onClick: _cache[1] || (_cache[1] = ($event)=>emit('open-language'))
            }, {
                prefix: _withCtx(()=>[
                        _ctx.name && _unref(isGlobeIcon)(_ctx.name.toLowerCase()) ? (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                            key: 0
                        }, iconProperties)), null, 16)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            (_openBlock(), _createBlock(VCountryFlag, {
                                key: 1,
                                name: _ctx.name,
                                size: _unref(VCountryFlagSize).SIZE_16,
                                rounded: ""
                            }, null, 8, [
                                "name",
                                "size"
                            ]))
                        ], 64))
                    ]),
                inner: _withCtx(()=>[
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
                            _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(pointingProperties)), null, 16)
                        ])
                    ]),
                _: 1
            }, 8, [
                "title"
            ]));
    }
});
