import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusLoyaltyShopDetailsTopBar',
    props: {
        title: {}
    },
    setup (__props) {
        const analytics = useAnalytics();
        const handleBackTitle = ()=>{
            analytics.push(AnalyticsEvent.CLICK_MAP, {
                clickCounter: {
                    leonshop: 'back_shopItemPage'
                }
            });
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _ctx.title,
                onBack: handleBackTitle
            }, null, 8, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'BonusLoyaltyShopDetailsTopBar'
                ]
            ]);
        };
    }
});
