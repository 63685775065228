import { FormFocusType } from 'web/src/components/Form/enums';
function getDeprecatedFormFocusOnMountPreset(uiSchema) {
    const preset = uiSchema?.focus;
    if (preset && preset.type === FormFocusType.OnMount) return preset.field;
    return null;
}
function getActualAutofocusField(uiSchema) {
    let result;
    if (uiSchema.fields) {
        const keys = Object.keys(uiSchema.fields);
        for (const key of keys){
            const field = uiSchema.fields[key];
            if (field && field.options && 'autofocus' in field.options && field.options.autofocus) return key;
        }
    }
    return result;
}
export default function getDefaultAutofocus(uiSchema) {
    const oldSettings = getDeprecatedFormFocusOnMountPreset(uiSchema);
    if (oldSettings) return oldSettings;
    return getActualAutofocusField(uiSchema);
}
