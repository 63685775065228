const allowedDelimiters = [
    ':',
    '.',
    '/'
];
export default function parseScore(score) {
    if (!score) return;
    const delimiter = allowedDelimiters.find((d)=>new RegExp(`^[^\\${d}]+\\${d}[^\\${d}]+$`).test(score));
    if (!delimiter) return;
    const [part1, part2] = score.trim().split(delimiter);
    return {
        parts: [
            part1?.trim() || '',
            part2?.trim() || ''
        ],
        delimiter
    };
}
