import { toRef, computed } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useModuleTimeout } from 'web/src/modules/core/store/composables';
import { useSportSegmentsSettings } from 'web/src/modules/sportline/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { UpdateSportlineTimeoutName } from '../enums';
/** @see mobile.sportline.update.interval */ export function useDefaultSportlineUpdateTimeout() {
    return toRef(useSportlineSettingsStore(), 'sportLineUpdateInterval');
}
/** @see web2.sportline.sidebar.sports.update.interval */ export function useSidebarSportsUpdateTimeout() {
    const defaultTimeout = useDefaultSportlineUpdateTimeout();
    const settingTimeout = toRef(useSportlineSettingsStore(), 'sportLineSidebarSportsUpdateInterval');
    const finalTimeout = computed(()=>settingTimeout.value ?? defaultTimeout.value);
    return useModuleTimeout(UpdateSportlineTimeoutName.SidebarSports, finalTimeout);
}
/** @see web2.sportline.live.events.update.interval */ export function useAllLiveLivePageUpdateInterval() {
    const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_LIVE_EVENTS);
    return useModuleTimeout(UpdateSportlineTimeoutName.AllLiveTab, toRef(()=>sportSegmentsSettings.value.liveUpdateInterval));
}
/** @see web2.sportline.live.events.update.interval */ export function useAllLiveCybersportPageUpdateInterval() {
    const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_CYBERSPORT);
    return useModuleTimeout(UpdateSportlineTimeoutName.AllLiveTab, toRef(()=>sportSegmentsSettings.value.liveUpdateInterval));
}
/** @see web2.sportline.filter.activated.sport.update.interval */ export function useSelectedLiveLivePageUpdateInterval() {
    const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_LIVE_EVENTS);
    return useModuleTimeout(UpdateSportlineTimeoutName.SelectedLiveTab, toRef(()=>sportSegmentsSettings.value.liveActiveEntityUpdateInterval));
}
/** @see web2.sportline.filter.activated.sport.update.interval */ export function useSelectedCybersportLivePageUpdateInterval() {
    const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_CYBERSPORT);
    return useModuleTimeout(UpdateSportlineTimeoutName.SelectedLiveTab, toRef(()=>sportSegmentsSettings.value.liveActiveEntityUpdateInterval));
}
/** @see web2.sportline.prematch.events.update.interval */ export function useComingLiveLivePageUpdateInterval() {
    const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_LIVE_EVENTS);
    return useModuleTimeout(UpdateSportlineTimeoutName.ComingLive, toRef(()=>sportSegmentsSettings.value.comingUpdateInterval));
}
/** @see web2.sportline.prematch.events.update.interval */ export function useComingLiveCybersportPageUpdateInterval() {
    const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_CYBERSPORT);
    return useModuleTimeout(UpdateSportlineTimeoutName.ComingLive, toRef(()=>sportSegmentsSettings.value.comingUpdateInterval));
}
export function useSportlineUpdateTimeout(param) {
    let { name } = param;
    return useModuleTimeout(name, useDefaultSportlineUpdateTimeout());
}
export function useCountSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.Count
    });
}
export function useZeroMarginSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.ZeroMargin
    });
}
export function useTopSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.Top
    });
}
export function useTopZeroMarginSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.TopZeroMargin
    });
}
export function useTopLeaguesSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.TopLeagues
    });
}
export function useEventSubscriptionSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.EventSubscription
    });
}
export function useStreamEventsIdsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.StreamEventsIds
    });
}
export function useSportsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.Sports
    });
}
export function useNavigationSportsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.NavigationSports
    });
}
export function useExtendedEventSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.ExtendedEvent
    });
}
export function useFavoriteLeaguesSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.FavoriteLeagues
    });
}
export function useFavoriteEventsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.FavoriteEvents
    });
}
export function useRegionEventsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.RegionEvents
    });
}
export function useLeagueEventsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.LeagueEvents
    });
}
export function useEventDetailsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.EventDetails
    });
}
export function useRelatedEventsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.EventRelatedEvents
    });
}
export function useCybersportRegionsSportlineUpdateTimeout() {
    return useSportlineUpdateTimeout({
        name: UpdateSportlineTimeoutName.CybersportRegions
    });
}
