/* eslint-disable import/no-named-as-default-member */ import { _ as _define_property } from "@swc/helpers/_/_define_property";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { logger } from '@leon-hub/logging';
import { localStorageManager } from '@leon-hub/local-storage';
import { isNumber, isObject, isString } from '@leon-hub/guards';
import { getLocationHost, isHttps } from '@leon-hub/service-locator-env';
import { Json } from '@leon-hub/utils';
const FJS_STORAGE_KEY = 'fjsVisitorId';
function isFingerprintJsConfig(value) {
    return isObject(value) && isString(value.apiKey) && isString(value.endpoint) && isNumber(value.visitorIdTtl);
}
function isStoredFingerprintJs(value) {
    return isObject(value) && isString(value.visitorId) && isNumber(value.timestamp);
}
let DeviceFingerprintJsService = class DeviceFingerprintJsService {
    init(config) {
        this.config = config;
    }
    async generateFingerprint() {
        if (!isFingerprintJsConfig(this.config)) {
            logger.error(`FingerpintJS config is invalid: config=${Json.stringify(this.config)}`);
            return null;
        }
        if (!this.instance) {
            const endpoint = this.config.endpoint.endsWith('*') ? `${isHttps() ? 'https:' : 'http:'}//${this.config.endpoint.slice(0, -1)}${getLocationHost()}` : this.config.endpoint;
            const fpPromise = FingerprintJS.load({
                apiKey: this.config.apiKey,
                endpoint
            });
            const agent = await fpPromise;
            this.instance = await agent.get();
        }
        localStorageManager.setItem(FJS_STORAGE_KEY, Json.stringify({
            visitorId: this.instance.visitorId,
            timestamp: Date.now()
        }) || '{}');
        return this.instance.visitorId;
    }
    getFingerprintFromStorage() {
        if (!isFingerprintJsConfig(this.config)) {
            logger.error(`FingerpintJS config is invalid: config=${Json.stringify(this.config)}`);
            return null;
        }
        const storedFjs = Json.parse(localStorageManager.getItem(FJS_STORAGE_KEY) || '{}', {
            defaultValue: {}
        });
        if (isStoredFingerprintJs(storedFjs)) {
            if (this.config.visitorIdTtl > 0 && (Date.now() - storedFjs.timestamp) / 1000 / 60 < this.config.visitorIdTtl) return storedFjs.visitorId;
            localStorageManager.removeItem(FJS_STORAGE_KEY);
        }
        return null;
    }
    constructor(){
        _define_property(this, "instance", null);
        _define_property(this, "config", null);
    }
};
export { DeviceFingerprintJsService as default };
