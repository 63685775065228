export function getMonthShortNames($t) {
    return [
        $t('WEB2_MONTH_JANUARY_SHORT'),
        $t('WEB2_MONTH_FEBRUARY_SHORT'),
        $t('WEB2_MONTH_MARCH_SHORT'),
        $t('WEB2_MONTH_APRIL_SHORT'),
        $t('WEB2_MONTH_MAY_SHORT'),
        $t('WEB2_MONTH_JUNE_SHORT'),
        $t('WEB2_MONTH_JULY_SHORT'),
        $t('WEB2_MONTH_AUGUST_SHORT'),
        $t('WEB2_MONTH_SEPTEMBER_SHORT'),
        $t('WEB2_MONTH_OCTOBER_SHORT'),
        $t('WEB2_MONTH_NOVEMBER_SHORT'),
        $t('WEB2_MONTH_DECEMBER_SHORT')
    ];
}
