import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getBootstrapTranslations, isBootstrapTranslationKey, getInitLocale } from '@leon-hub/bootstrap-translations';
import { Timer } from '@leon-hub/utils';
import { Locale, localeStorageKey } from '@leon-hub/locale';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { isDebugEnabled } from '@leon-hub/debug';
import { primaryKeys } from 'web/src/modules/i18n/store/primaryKeys';
import useSyncState from 'web/src/modules/core/store/composables/useSyncState';
import { fetchTranslations, doChangeLanguage, setLanguageHeader } from './utils';
import { EMPTY_PLACEHOLDER } from '../consts';
function processParameters(translate, parameters) {
    return Object.keys(parameters).reduce((accumulator, item)=>accumulator.replace(new RegExp(`{${item}}`, 'g'), parameters[item]), translate);
}
const useI18nStore = defineStore('i18n', ()=>{
    const localStorageManager = useLocalStorageManager();
    const translations = {};
    const locale = ref(localStorageManager.getItem(localeStorageKey) || getInitLocale() || Locale.RU_RU);
    const currentUrlPrefix = ref('');
    const replacedUrlPrefix = ref('');
    const lang = computed(()=>locale.value.split('_')[0]);
    async function fetchKeys(keys) {
        let silent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        updateKeKeys(await fetchTranslations(keys, silent));
    }
    function updateKeKeys(items) {
        if (items.length > 0) for (const translation of items)if (translations[translation.key]) translations[translation.key].value = translation.value;
        else translations[translation.key] = ref(translation.value);
    }
    function loadPrimaryKeys() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        return fetchKeys(primaryKeys, silent);
    }
    let pendingTranslations = [];
    let pendingFetchTimeoutId = 0;
    function clearPendingFetchTimeout() {
        if (pendingFetchTimeoutId) {
            Timer.clearTimeout(pendingFetchTimeoutId);
            pendingFetchTimeoutId = 0;
        }
    }
    function addPendingKey(key) {
        if (primaryKeys.includes(key)) return;
        pendingTranslations.push(key);
        clearPendingFetchTimeout();
        pendingFetchTimeoutId = Timer.setTimeout(()=>{
            const keys = [
                ...pendingTranslations
            ];
            fetchKeys(keys, true).then(()=>{
                pendingTranslations = pendingTranslations.filter((item)=>!keys.includes(item));
            });
        }, 40);
    }
    function $t(key, parameters) {
        if (!translations[key]) {
            const emptyPlaceholder = isDebugEnabled() ? key : EMPTY_PLACEHOLDER;
            const defaultTranslationValue = isBootstrapTranslationKey(key) ? getBootstrapTranslations()[key] || emptyPlaceholder : emptyPlaceholder;
            translations[key] = ref(defaultTranslationValue);
            addPendingKey(key);
        }
        if (!parameters) return translations[key];
        return computed(()=>processParameters(translations[key].value, parameters.value));
    }
    function getTranslationFunction() {
        return (key, parameters)=>$t(key, parameters ? ref(parameters) : void 0).value;
    }
    function setLocale(value) {
        locale.value = value;
        setLanguageHeader(value);
        localStorageManager.setItem(localeStorageKey, value);
    }
    async function changeLanguage(languageTag) {
        const result = await doChangeLanguage(languageTag);
        if (result.currentLanguageTag) {
            setLocale(result.currentLanguageTag);
            currentUrlPrefix.value = result.urlLocale || '';
        }
    }
    function setUrlPrefixes(param) {
        let { currentPrefix, replacedPrefix } = param;
        currentUrlPrefix.value = currentPrefix;
        replacedUrlPrefix.value = replacedPrefix;
    }
    useSyncState((silent)=>loadPrimaryKeys(silent), 'i18n');
    return {
        locale,
        lang,
        currentUrlPrefix,
        replacedUrlPrefix,
        updateKeKeys,
        loadPrimaryKeys,
        $t,
        getTranslationFunction,
        setLocale,
        setUrlPrefixes,
        changeLanguage
    };
});
export default useI18nStore;
