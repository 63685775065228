const chunkSize = 5;
export default async function promisesInBatch(messages, callback) {
    const messagesCopy = [
        ...messages
    ];
    while(messagesCopy.length > 0){
        const batch = messagesCopy.splice(0, chunkSize);
        // eslint-disable-next-line no-await-in-loop
        await Promise.allSettled(batch.map((message)=>callback(message)));
    }
}
