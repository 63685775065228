import { watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { isString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config-names';
import { useSportlineRedirectInfoStore } from 'web/src/modules/sportline/submodules/redirects/store';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import { useSportlineTreeElementForPageIdentifier } from './useSportlineTreeElementForPageIdentifier';
export function useRedirectBySportsTree(props) {
    const redirectCode = props.code || 301;
    const canShowRedirectedInfoTargets = props.canShowRedirectedInfoTargets || {};
    const allowedTargets = props.allowedTargets || {};
    const { redirectWasHandled } = props;
    const router = props.router || useRouter();
    const sportlineRedirectInfoStore = useSportlineRedirectInfoStore();
    let unwatch = null;
    let timeout = null;
    const pageIdentifier = ref({
        sportFamily: ''
    });
    const { isLoaded, sportElement, regionElement, leagueElement, initialRequests } = useSportlineTreeElementForPageIdentifier({
        pageIdentifier
    });
    function makeRedirectToLeaguePage() {
        if (leagueElement.value && leagueElement.value.counters.total > 0 && regionElement.value && regionElement.value.leagues.length > 0 && sportElement.value && sportElement.value.leaguesCount > 0) return router.pushRedirect({
            name: RouteName.SPORT_LEAGUE,
            params: {
                sportFamily: sportElement.value.sport.navigationParameters.urlName,
                regionUrlName: regionElement.value.region.navigationParameters.urlName,
                urlName: leagueElement.value.league.navigationParameters.urlName,
                id: leagueElement.value.league.id
            }
        }, redirectCode);
    }
    function makeRedirectToRegionPage() {
        if (regionElement.value && regionElement.value.leagues.length > 0 && sportElement.value && sportElement.value.leaguesCount > 0) return router.pushRedirect({
            name: RouteName.SPORT_REGION,
            params: {
                sportFamily: sportElement.value.sport.navigationParameters.urlName,
                urlName: regionElement.value.region.navigationParameters.urlName,
                id: regionElement.value.region.id
            }
        }, redirectCode);
    }
    function makeRedirectToSportPage() {
        if (sportElement.value && sportElement.value.leaguesCount > 0) return router.pushRedirect({
            name: RouteName.SPORTLINE_PREMATCH_EVENTS,
            params: {
                family: sportElement.value.sport.navigationParameters.urlName
            }
        }, redirectCode);
    }
    function makeRedirectToHomePage() {
        return router.pushRedirect({
            name: RouteName.HOME
        }, redirectCode);
    }
    function stopWatchingLoadedState() {
        unwatch?.();
        unwatch = null;
    }
    function clearTimeout() {
        if (timeout) {
            window.clearTimeout(timeout);
            timeout = null;
        }
    }
    function checkCanShowRedirectedInfo(from, key) {
        if (canShowRedirectedInfoTargets[key] && isString(from)) sportlineRedirectInfoStore.showRedirectWasHandledInfo({
            from
        });
    }
    function redirect() {
        stopWatchingLoadedState();
        clearTimeout();
        redirectWasHandled?.(true);
        const from = router.currentRoute.value.name;
        if (allowedTargets.league) {
            const redirectToPage = makeRedirectToLeaguePage();
            if (redirectToPage) {
                redirectToPage.then(()=>checkCanShowRedirectedInfo(from, 'league'));
                return;
            }
        }
        if (allowedTargets.region) {
            const redirectToPage = makeRedirectToRegionPage();
            if (redirectToPage) {
                redirectToPage.then(()=>checkCanShowRedirectedInfo(from, 'region'));
                return;
            }
        }
        if (allowedTargets.sport) {
            const redirectToPage = makeRedirectToSportPage();
            if (redirectToPage) {
                redirectToPage.then(()=>checkCanShowRedirectedInfo(from, 'sport'));
                return;
            }
        }
        makeRedirectToHomePage().then(()=>checkCanShowRedirectedInfo(from, 'home'));
    }
    function make() {
        pageIdentifier.value = getSportlinePageNavigationParameters(router.currentRoute.value);
        unwatch = watch(isLoaded, (value)=>{
            if (value) redirect();
        }, {
            immediate: true
        });
        initialRequests();
        timeout = window.setTimeout(()=>{
            redirect();
        }, 10000);
    }
    return {
        make,
        redirect,
        clearTimeout
    };
}
