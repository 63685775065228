import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
import { VScrollbar } from '@components/v-scrollbar';
import { useModalContentWrapper } from 'web/src/components/Modal/ModalContentWrapper/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ModalContentWrapper',
    props: {
        isScrollBarEnabled: {
            type: Boolean
        },
        modalKey: {
            default: 'modal'
        },
        useScrollListener: {
            type: Boolean
        },
        isInnerModal: {
            type: Boolean
        }
    },
    emits: [
        "scroll-reached-bottom"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { content, scrollbar } = useModalContentWrapper(props);
        function scrollTop() {
            if (props.isScrollBarEnabled) scrollbar.value?.scrollTop();
            else content.value?.scrollIntoView(true);
        }
        function scrollUpdate() {
            scrollbar.value?.scrollUpdate();
        }
        function scrollToElement(element) {
            if (props.isScrollBarEnabled) scrollbar.value?.scrollToElement(element);
            else if (element) element.scrollIntoView();
        }
        __expose({
            scrollTop,
            scrollUpdate,
            scrollToElement
        });
        return (_ctx, _cache)=>_ctx.isScrollBarEnabled ? (_openBlock(), _createBlock(_unref(VScrollbar), {
                ref_key: "scrollbar",
                ref: scrollbar,
                key: _ctx.modalKey,
                "test-el": _ctx.isInnerModal ? 'inner-modal' : 'modal',
                "flex-fill": "",
                "use-scroll-listener": _ctx.useScrollListener,
                onReachedBottom: _cache[0] || (_cache[0] = ($event)=>emit('scroll-reached-bottom'))
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 8, [
                "test-el",
                "use-scroll-listener"
            ])) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                ref_key: "content",
                ref: content
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 512));
    }
});
