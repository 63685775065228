import { computed, onMounted, ref } from 'vue';
import { getBaseAttributesForButton, getIconComponentForButton } from 'web/src/components/Button/VButton/composables/utils';
import hasSlot from 'web/src/utils/vue/hasSlot';
export default function useBaseButton(props, slots) {
    const isAnimated = ref(false);
    const mainTag = ref();
    const iconComponent = computed(()=>getIconComponentForButton(props));
    const hasDefaultSlot = computed(()=>hasSlot(slots, 'default'));
    const hasIconSlot = computed(()=>hasSlot(slots, 'icon'));
    const isIconOnly = computed(()=>!props.label && !hasDefaultSlot.value && (!!props.iconName || hasIconSlot.value));
    const isIconRight = computed(()=>!!props.iconName && !!props.iconRight);
    const computedAttributes = computed(()=>getBaseAttributesForButton(props));
    const focusOnElement = ()=>{
        if (mainTag.value && 'focus' in mainTag.value) mainTag.value.focus();
    };
    onMounted(()=>{
        if (props.isAutofocus) focusOnElement();
    });
    function onTouchStart() {
        isAnimated.value = true;
    }
    function onTouchEnd() {
        isAnimated.value = false;
    }
    return {
        mainTag,
        isAnimated,
        iconComponent,
        isIconOnly,
        isIconRight,
        computedAttributes,
        onTouchStart,
        onTouchEnd,
        focusOnElement
    };
}
