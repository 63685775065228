import { InputEventType } from 'web/src/components/Input/enums';
import { inputEventToFormDataEvent } from 'web/src/components/Form/utils';
const useBaseFormWidgetEvents = (emit)=>{
    const emitChange = (event)=>{
        emit(InputEventType.CHANGE, inputEventToFormDataEvent(event));
    };
    const emitInput = (event)=>{
        emit(InputEventType.INPUT, inputEventToFormDataEvent(event));
    };
    const emitFocus = (event)=>{
        emit(InputEventType.FOCUS, inputEventToFormDataEvent(event));
    };
    const emitBlur = (event)=>{
        emit(InputEventType.BLUR, inputEventToFormDataEvent(event));
    };
    return {
        emitChange,
        emitInput,
        emitFocus,
        emitBlur
    };
};
export default useBaseFormWidgetEvents;
