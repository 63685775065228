import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { usePromotionDetailsDescription } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionDetailsDescription',
    props: {
        description: {}
    },
    setup (__props) {
        const props = __props;
        const { parsedDescription, openSharedInfo } = usePromotionDetailsDescription(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VDynamicContent, {
                class: "promotion-description__content",
                content: _unref(parsedDescription),
                onOpenInfo: _unref(openSharedInfo)
            }, null, 8, [
                "content",
                "onOpenInfo"
            ])), [
                [
                    _directive_auto_id,
                    'PromotionDetailsDescription'
                ]
            ]);
        };
    }
});
