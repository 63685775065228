import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, computed } from 'vue';
import { ProgressKind, ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { filterMainPeriods } from 'web/src/modules/sportline/utils/timeline';
import { getMatchPercentageByTimes } from './utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineLiveEventProgress',
    props: {
        sportlineEvent: {},
        progressKind: {}
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const progressKind = toRef(props, 'progressKind', ProgressKind.WHITE);
        const periods = computed(()=>filterMainPeriods(getMatchPercentageByTimes({
                sportlineEvent: sportlineEvent.value
            })));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportline-live-event-progress'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(periods.value, (period)=>(_openBlock(), _createBlock(VLinearProgress, {
                        key: `${period.type}_${period.number}`,
                        height: _unref(ProgressHeight).TINY_EXTRA,
                        kind: progressKind.value,
                        value: period.percentage,
                        class: _normalizeClass([
                            _ctx.$style['sportline-live-event-progress__bar']
                        ])
                    }, null, 8, [
                        "height",
                        "kind",
                        "value",
                        "class"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineLiveEventProgress'
                ]
            ]);
        };
    }
});
