import { computed, onMounted, ref, toRef } from 'vue';
import { useWindowResize } from '@leon-hub/browser-composables';
import getTestDataAttrs from '../../utils/getTestDataAttrs';
export default function useVScrollbarPhone(props, emit) {
    const testAttrs = computed(()=>getTestDataAttrs('scrollbar', props));
    const element = ref(null);
    let offsetXDelta = 0;
    onMounted(()=>{
        const el = element.value;
        if (el && (props.useScrollListener || props.handleResizeChange)) el.addEventListener('scroll', ()=>{
            offsetXDelta = el.scrollHeight - el.scrollTop - el.offsetHeight;
            if (el.scrollHeight <= Math.ceil(el.scrollTop) + el.offsetHeight) emit('reached-bottom');
        });
    });
    function scrollTop() {
        scrollTo(0);
    }
    function scrollDown() {
        scrollTo(element.value?.scrollHeight || 0);
    }
    function scrollRight() {
        const el = element.value;
        if (el) el.scrollTo({
            top: 0,
            left: el.scrollWidth
        });
    }
    function scrollTo(top) {
        const el = element.value;
        if (el) el.scrollTo({
            top,
            left: 0
        });
    }
    function scrollToElement(el, options) {
        if (el) el.scrollIntoView({
            block: options?.onlyIfNeeded ? 'nearest' : 'start'
        });
    }
    const handleResizeChange = toRef(props, 'handleResizeChange');
    useWindowResize((param)=>{
        let { deltaY } = param;
        if (element.value && 0 !== deltaY) element.value.scrollTo({
            top: element.value.scrollHeight - element.value.offsetHeight - offsetXDelta
        });
    }, {
        condition: handleResizeChange
    });
    return {
        testAttrs,
        element,
        scrollTo,
        scrollTop,
        scrollDown,
        scrollRight,
        scrollToElement
    };
}
