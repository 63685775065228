import { defineStore } from 'pinia';
import { watch } from 'vue';
import { GraphQLWebSocketService } from '@leon-hub/websocket';
import useIsLoggedIn from 'web/src/modules/auth/composables/useIsLoggedIn';
import useWebSocketsConfig from 'web/src/modules/core/composables/site-config/useWebSocketsConfig';
const useWebsocketSubscriptionsStore = defineStore('websocket-subscriptions', ()=>{
    const webSocketService = new GraphQLWebSocketService();
    const { isLoggedIn } = useIsLoggedIn();
    const { isEnabled } = useWebSocketsConfig();
    let subscriptions = [];
    function isSubscriptionAllowed(subscription) {
        return subscription.isAllowed({
            isLoggedIn: isLoggedIn.value
        });
    }
    function subscribeSubscription(subscription) {
        if (!webSocketService.isSubscribed(subscription)) webSocketService.subscribe(subscription);
    }
    function unsubscribeSubscription(subscription) {
        if (webSocketService.isSubscribed(subscription)) webSocketService.unsubscribe(subscription);
    }
    function subscribeWs(subscription) {
        if (isSubscriptionAllowed(subscription)) subscribeSubscription(subscription);
        else unsubscribeSubscription(subscription);
    }
    function subscribe(input) {
        subscriptions.push(input);
        subscribeWs(input);
    }
    function unsubscribe(input) {
        unsubscribeSubscription(input);
        subscriptions = subscriptions.filter((subscription)=>subscription.method !== input.method);
    }
    function init() {
        watch(isLoggedIn, (newValue)=>{
            for (const subscription of subscriptions){
                subscribeWs(subscription);
                if (subscription.isPollingMustBeCalledOnLogin() && newValue) subscription.callPollingRequest();
            }
        }, {
            immediate: true
        });
        watch(isEnabled, (newValue)=>{
            webSocketService.setEnabled(newValue);
        }, {
            immediate: true
        });
    }
    init();
    return {
        subscribe,
        unsubscribe
    };
});
export default useWebsocketSubscriptionsStore;
