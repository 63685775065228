import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
/**
 * Normalize types for a date time filter for sports pages from the saved value
 * Used to navigate to a filtered page from the sidebar
 */ export default function normalizeSportlineNavigationBetweenPagesFilter(filters) {
    const { period, from, to } = filters || {};
    let parsedFrom;
    let parsedTo;
    try {
        parsedFrom = from ? parseInt(String(from), 10) : void 0;
        parsedTo = to ? parseInt(String(to), 10) : void 0;
    } catch (rawError) {
        const error = normalizeError(rawError);
        logger.error(error);
    }
    return period ? {
        activeKickoffPeriod: period,
        customDateFrom: parsedFrom,
        customDateTo: parsedTo
    } : void 0;
}
