import { computed } from 'vue';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
import { CustomInputEventType } from 'web/src/components/Input/enums';
import { useCountryStore } from 'web/src/modules/country/store';
import matchPhoneNumberAndCountryCode from 'web/src/utils/phoneNumberUtils/matchPhoneNumberAndCountryCode';
export default function useInternationalPhoneInput(props, emit) {
    const { emitChange, emitInput, emitFocus, emitBlur } = useBaseInputEvents(emit);
    const countryStore = useCountryStore();
    const onFilled = ()=>{
        emit(CustomInputEventType.FILLED);
    };
    const computedPrefixesData = computed(()=>{
        if (props.prefixes?.length) return props.prefixes;
        return countryStore.countriesData;
    });
    const computedCountryCode = computed(()=>matchPhoneNumberAndCountryCode({
            phoneNumber: props.value,
            prefix: props.prefix ?? '',
            countries: computedPrefixesData.value,
            countryCode: props.countryCode || countryStore.countryCode
        }));
    return {
        onChange: emitChange,
        onInput: emitInput,
        onFocus: emitFocus,
        onBlur: emitBlur,
        onFilled,
        computedPrefixesData,
        computedCountryCode
    };
}
