import { SportlineType, DateTimeFilterTabId, SportFilterKickoffPeriod } from 'web/src/modules/sportline/enums';
import { defaultMapTabIdToKickoffPeriod, defaultTabIds } from 'web/src/modules/sportline/components/date-time-filter/constants';
export function findDefaultKickoffPeriodByTabId(kickoffPeriod) {
    return defaultTabIds.find((id)=>defaultMapTabIdToKickoffPeriod[id] === kickoffPeriod);
}
export function getFilterSelectFilterPayload(id, options) {
    switch(true){
        case id === DateTimeFilterTabId.DATE_RANGE:
            return {
                activeKickoffPeriod: SportFilterKickoffPeriod.CUSTOM,
                customDateFrom: options.selectedRangeFrom ?? options.lastSelectedCustomRange.from,
                customDateTo: options.selectedRangeTo ?? options.lastSelectedCustomRange.to
            };
        case id === DateTimeFilterTabId.LIVE:
            return {
                activeKickoffPeriod: SportFilterKickoffPeriod.ALL,
                sportlineType: SportlineType.Live
            };
        default:
            return {
                activeKickoffPeriod: defaultMapTabIdToKickoffPeriod[id] ?? SportFilterKickoffPeriod.ALL
            };
    }
}
