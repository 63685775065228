import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let HardwareConcurrencyComponent = class HardwareConcurrencyComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result(window.navigator.hardwareConcurrency ? [
            window.navigator.hardwareConcurrency
        ] : ComponentStatus.NotAvailable);
    }
    constructor(){
        super(ComponentKey.HardwareConcurrency);
    }
};
export { HardwareConcurrencyComponent as default };
