export function getMultipleSelectionText(options, selectedValues) {
    if (!selectedValues.size) return '';
    const matchedOptionsMap = {};
    for (const item of options)if (selectedValues.has(item.value)) matchedOptionsMap[item.value] = item.label;
    const sortedSelectedLabels = [];
    for (const value of selectedValues){
        const matchedLabel = matchedOptionsMap[value];
        if (matchedLabel) sortedSelectedLabels.push(matchedLabel);
    }
    return sortedSelectedLabels.join(', ');
}
