import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { IconName } from '@leon-hub/icons';
import { VScrollbar } from '@components/v-scrollbar';
import BonusLoyaltyProgramBalance from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { useBonusLoyaltyShopDetailsRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusLoyaltyShopDetailsRoutePage',
    props: {
        itemId: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { isLoading, onClick, isDisabled, buttonText, tagButton, currentOfferDes, currentOfferPrice, currentOfferTitle, currentOfferImageSrc, isCurrentOfferImage, currentOfferImage } = useBonusLoyaltyShopDetailsRoutePage(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(currentOfferTitle) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['loyalty-shop-item'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['loyalty-shop-item--wrapper'])
                }, [
                    _unref(isCurrentOfferImage) && _unref(currentOfferImage) ? (_openBlock(), _createBlock(VImage, _mergeProps({
                        key: 0,
                        class: _ctx.$style['loyalty-shop-item__img']
                    }, _unref(currentOfferImage)), null, 16, [
                        "class"
                    ])) : !_unref(isCurrentOfferImage) && _unref(currentOfferImageSrc) ? (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['loyalty-shop-item__img']),
                        alt: "",
                        src: _unref(currentOfferImageSrc)
                    }, null, 10, _hoisted_1)) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['loyalty-shop-item__title'])
                }, _toDisplayString(_unref(currentOfferTitle)), 3),
                _createVNode(BonusLoyaltyProgramBalance, {
                    class: _normalizeClass(_ctx.$style['loyalty-shop-item__balance']),
                    text: String(_unref(currentOfferPrice)),
                    "has-no-hover": "",
                    "is-secondary": "",
                    "icon-name": _unref(IconName).COIN_L
                }, null, 8, [
                    "class",
                    "text",
                    "icon-name"
                ]),
                (_openBlock(), _createBlock(_unref(VScrollbar), {
                    key: 0,
                    "flex-fill": ""
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("p", {
                                class: _normalizeClass(_ctx.$style['loyalty-shop-item__des'])
                            }, _toDisplayString(_unref(currentOfferDes)), 3)
                        ]),
                    _: 1
                })),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['loyalty-shop-item__button-wrapper'])
                }, [
                    _createVNode(VButton, {
                        "full-width": "",
                        tag: _unref(tagButton),
                        disabled: _unref(isDisabled),
                        "is-loading": _unref(isLoading),
                        onClick: _unref(onClick)
                    }, {
                        default: _withCtx(()=>[
                                _createTextVNode(_toDisplayString(_unref(buttonText)), 1)
                            ]),
                        _: 1
                    }, 8, [
                        "tag",
                        "disabled",
                        "is-loading",
                        "onClick"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusLoyaltyShopDetailsRoutePage'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
