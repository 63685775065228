import { getRewards } from '@leon-hub/api-sdk';
import { RequestHeader } from '@leon-hub/api-sdk/src/sdk/sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default async function getRewardsData(referralCode) {
    const apiClient = useGraphqlClient();
    const response = await getRewards(apiClient, (node)=>node.queries.refProgram.getRewards, {
        options: {
            referralCode
        }
    }, {
        headers: {
            [RequestHeader.X_REFTAG]: referralCode
        },
        silent: false
    });
    return response || {};
}
