import { computed, ref } from 'vue';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { useLobbyStore } from 'web/src/modules/lobby/store';
export default function useActiveGameData() {
    const { setVisitedGame } = useLobbyStore();
    const { updateGames, getGameByUrl } = useEgsGamesStore();
    const startGameData = ref();
    const isStartGameLoading = ref(false);
    const activeGroupUrl = ref('');
    const activeGameUrl = ref('');
    const activeGame = computed(()=>getGameByUrl(activeGroupUrl.value, activeGameUrl.value).value);
    const { getGameByUrl: getGameByUrlApi, startGame: startGameApi, getGameById } = useEgsApi();
    async function reloadGame(groupUrl, gameUrl) {
        const game = await getGameByUrlApi({
            gameUrl,
            groupUrl
        });
        updateGames([
            game
        ]);
    }
    async function setActiveUrl(groupUrl, gameUrl) {
        activeGroupUrl.value = groupUrl;
        activeGameUrl.value = gameUrl;
        if (!activeGame.value) await reloadGame(groupUrl, gameUrl);
    }
    function resetActiveGame() {
        activeGameUrl.value = '';
        activeGroupUrl.value = '';
    }
    async function startGame(options) {
        let isVpn = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        try {
            isStartGameLoading.value = true;
            const data = await startGameApi(options, isVpn);
            if (options.id === activeGame.value?.id) {
                startGameData.value = data;
                setVisitedGame(activeGame.value);
            }
        } finally{
            isStartGameLoading.value = false;
        }
    }
    async function reloadActiveGame() {
        if (activeGame.value) await reloadGame(activeGame.value.group.url, activeGame.value.url);
    }
    function clearStartGameData() {
        startGameData.value = void 0;
    }
    async function loadGameById(id) {
        const game = await getGameById(id);
        if (game) {
            updateGames([
                game
            ]);
            await setActiveUrl(game.group.url, game.url);
        }
    }
    return {
        activeGame,
        startGameData,
        isStartGameLoading,
        reloadActiveGame,
        startGame,
        getGameById,
        setActiveUrl,
        resetActiveGame,
        clearStartGameData,
        loadGameById
    };
}
