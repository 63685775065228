import { Json } from '@leon-hub/utils';
import { getNavigationItemIconLocation } from '@leon-hub/navigation-config/src/services/getNavigationItemIconLocation';
import { useTheme } from 'web/src/modules/theme/composables';
import { ShowcaseNavigationItemType } from 'web/src/modules/home/submodules/showcase/enums';
import useRootStore from 'web/src/modules/core/store/useRootStore';
export default function useShowcaseNavigationImageItemsComposable() {
    const { theme } = useTheme();
    const { imageCdnUrl } = useRootStore();
    function createImageNavigationItem(iconCdn, iconStyle) {
        const iconStyleParsed = iconStyle ? Json.parse(iconStyle, {
            defaultValue: {}
        }) : {};
        const iconThemeStyle = iconStyleParsed[theme.value.toLowerCase()] ?? {};
        return {
            is: ShowcaseNavigationItemType.Image,
            props: {
                src: getNavigationItemIconLocation(iconCdn.src, imageCdnUrl, theme.value),
                alt: '',
                style: {
                    width: iconCdn.width ?? 'auto',
                    height: iconCdn.height ?? '24px',
                    ...iconThemeStyle
                }
            }
        };
    }
    return {
        createImageNavigationItem
    };
}
