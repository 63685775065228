export function onWindowVisibilityChanged(callback) {
    function resizeCallback() {
        callback('visible' === document.visibilityState);
    }
    function addVisibilityChangeEventListener() {
        window.document.addEventListener('visibilitychange', resizeCallback);
    }
    function removeVisibilityChangeEventListener() {
        window.document.removeEventListener('visibilitychange', resizeCallback);
    }
    return {
        addVisibilityChangeEventListener,
        removeVisibilityChangeEventListener
    };
}
