import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = [
    "id",
    "name",
    "autofocus",
    "value",
    "autocomplete",
    "disabled",
    "type",
    "placeholder",
    "accept",
    "inputmode",
    "list",
    "maxlength",
    "readonly"
];
import { useNativeInputEvents, useAdditionalInputEvents, useAutofocus } from '../../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TextFlatInput',
    props: {
        hasError: {
            type: Boolean
        },
        type: {
            default: 'text'
        },
        inputId: {},
        hasVanillaMask: {
            type: Boolean
        },
        accept: {
            default: ''
        },
        isFromModal: {
            type: Boolean
        },
        isYmDisableKeys: {
            type: Boolean
        },
        inputMode: {},
        list: {
            default: ''
        },
        maxLength: {},
        hasIconPrefix: {
            type: Boolean
        },
        hasIconSuffix: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        readonly: {
            type: Boolean
        },
        noPadding: {
            type: Boolean
        },
        isPaymentField: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        name: {
            default: ''
        },
        value: {},
        autofocus: {
            type: Boolean
        },
        autocomplete: {},
        placeholder: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur",
        "mousedown",
        "keydown",
        "mouseover",
        "mouseleave",
        "paste"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { emitChange, emitInput, emitBlur, emitFocus } = useNativeInputEvents(emits);
        const { emitMousedown, emitMouseleave, emitPaste, emitKeydown, emitMouseover } = useAdditionalInputEvents(emits);
        const { focusable, focus } = useAutofocus(props);
        __expose({
            focus
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("input", {
                id: _ctx.inputId,
                ref_key: "focusable",
                ref: focusable,
                name: _ctx.name,
                class: _normalizeClass({
                    [_ctx.$style['text-flat-input']]: true,
                    [_ctx.$style['text-flat-input--error']]: _ctx.hasError,
                    [_ctx.$style['text-flat-input--vanilla-mask']]: _ctx.hasVanillaMask,
                    [_ctx.$style['text-flat-input--empty']]: '' === _ctx.value,
                    [_ctx.$style['text-flat-input--modal']]: _ctx.isFromModal,
                    [_ctx.$style['text-flat-input--icon-prefix']]: _ctx.hasIconPrefix,
                    [_ctx.$style['text-flat-input--icon-suffix']]: _ctx.hasIconSuffix,
                    [_ctx.$style['text-flat-input--large']]: _ctx.isLarge,
                    [_ctx.$style['text-flat-input--no-padding']]: _ctx.noPadding,
                    [_ctx.$style['text-flat-input--payment']]: _ctx.isPaymentField,
                    'ym-disable-keys': _ctx.isYmDisableKeys
                }),
                autofocus: process.env.VUE_APP_OS_IOS ? void 0 : _ctx.autofocus,
                value: _ctx.value,
                autocomplete: _ctx.autocomplete,
                disabled: _ctx.disabled,
                type: _ctx.type,
                placeholder: _ctx.placeholder,
                accept: _ctx.accept,
                inputmode: _ctx.inputMode,
                list: _ctx.list,
                maxlength: _ctx.maxLength,
                readonly: _ctx.readonly,
                onInput: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitInput) && _unref(emitInput)(...args);
                }, [
                    "stop"
                ])),
                onChange: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitChange) && _unref(emitChange)(...args);
                }, [
                    "stop"
                ])),
                onFocus: _cache[2] || (_cache[2] = _withModifiers(//@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitFocus) && _unref(emitFocus)(...args);
                }, [
                    "stop"
                ])),
                onBlur: _cache[3] || (_cache[3] = _withModifiers(//@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitBlur) && _unref(emitBlur)(...args);
                }, [
                    "stop"
                ])),
                onMousedown: _cache[4] || (_cache[4] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitMousedown) && _unref(emitMousedown)(...args);
                }),
                onKeydown: _cache[5] || (_cache[5] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitKeydown) && _unref(emitKeydown)(...args);
                }),
                onMouseover: _cache[6] || (_cache[6] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitMouseover) && _unref(emitMouseover)(...args);
                }),
                onMouseleave: _cache[7] || (_cache[7] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitMouseleave) && _unref(emitMouseleave)(...args);
                }),
                onPaste: _cache[8] || (_cache[8] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitPaste) && _unref(emitPaste)(...args);
                })
            }, null, 42, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'TextFlatInput'
                ]
            ]);
        };
    }
});
