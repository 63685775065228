import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
import { useSlots } from 'vue';
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { HintBlockKind } from './enums';
import { useVHintBlock } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VHintBlock',
    props: {
        hasIcon: {
            type: Boolean,
            default: true
        },
        icon: {
            default: IconName.ATTENTION_OUTLINE
        },
        label: {
            default: ''
        },
        labelCancel: {
            default: ''
        },
        labelAccept: {
            default: ''
        },
        kind: {
            default: HintBlockKind.DEFAULT
        },
        isCentered: {
            type: Boolean
        },
        isWideFooter: {
            type: Boolean,
            default: false
        },
        iconDataTest: {}
    },
    emits: [
        "click",
        "on-cancel",
        "on-accept"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const { iconProperties, baseClassNames } = useVHintBlock(props, slots);
        function emitClick(event) {
            emit('click', event);
        }
        function emitOnCancel() {
            emit('on-cancel');
        }
        function emitOnAccept() {
            emit('on-accept');
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_unref(baseClassNames).map((name)=>_ctx.$style[name])),
                onClick: emitClick
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['hint-block__wrapper'])
                }, [
                    _ctx.hasIcon ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['hint-block__icon'])
                    }, [
                        _ctx.$slots.icon ? _renderSlot(_ctx.$slots, "icon", {
                            key: 1
                        }) : _withDirectives((_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                            key: 0
                        }, _unref(iconProperties))), null, 16)), [
                            [
                                _directive_data_test,
                                _ctx.iconDataTest
                            ]
                        ])
                    ], 2)) : _createCommentVNode("", true),
                    _ctx.label || _ctx.$slots.label ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['hint-block__label'])
                    }, [
                        _renderSlot(_ctx.$slots, "label", {}, ()=>[
                                _createTextVNode(_toDisplayString(_ctx.label), 1)
                            ])
                    ], 2)) : (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass({
                            [_ctx.$style['hint-block__content']]: true,
                            [_ctx.$style['hint-block__content--indent']]: _ctx.labelCancel || _ctx.labelAccept
                        })
                    }, [
                        _ctx.$slots.title ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['hint-block__title'])
                        }, [
                            _renderSlot(_ctx.$slots, "title")
                        ], 2)) : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "default")
                    ], 2)),
                    _ctx.labelCancel || _ctx.labelAccept ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: _normalizeClass(_ctx.$style['hint-block__action'])
                    }, [
                        _ctx.labelCancel ? (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 0
                        }, {
                            height: _unref(ButtonHeight).TINY,
                            kind: _unref(ButtonKind).TRANSPARENT
                        }, {
                            label: _ctx.labelCancel,
                            class: _ctx.$style['hint-block__action-button'],
                            onClick: emitOnCancel
                        }), null, 16, [
                            "label",
                            "class"
                        ])) : _createCommentVNode("", true),
                        _ctx.labelAccept ? (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 1
                        }, {
                            height: _unref(ButtonHeight).TINY,
                            kind: _unref(ButtonKind).TRANSPARENT
                        }, {
                            label: _ctx.labelAccept,
                            class: [
                                _ctx.$style['hint-block__action-button'],
                                _ctx.$style['hint-block__action-button--green']
                            ],
                            onClick: emitOnAccept
                        }), null, 16, [
                            "label",
                            "class"
                        ])) : _createCommentVNode("", true)
                    ], 2)) : _createCommentVNode("", true)
                ], 2),
                _ctx.$slots.footer ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['hint-block__footer']]: true,
                        [_ctx.$style['hint-block__footer--width-wide']]: _ctx.$props.isWideFooter,
                        [_ctx.$style['hint-block__footer--width-normal']]: !_ctx.$props.isWideFooter
                    })
                }, [
                    _renderSlot(_ctx.$slots, "footer")
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VHintBlock'
                ]
            ]);
        };
    }
});
