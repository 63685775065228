import { ref, watch, computed, nextTick, onActivated } from 'vue';
import { FilterIdentificator } from 'web/src/modules/sportline/enums';
export function useSportlineFilter(props) {
    const { items } = props;
    const swiper = ref();
    const selectedIndex = computed(()=>{
        const index = items.value.findIndex((item)=>item.isActive);
        return index > -1 ? index : 0;
    });
    const itemsWithIdentifier = computed(()=>items.value.map((item)=>{
            const dataTest = item.customFilter ? FilterIdentificator.CUSTOM : FilterIdentificator.SPORT;
            return {
                ...item,
                dataTest
            };
        }));
    async function slideToActive() {
        let smooth = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        await nextTick();
        swiper.value?.slideToSlide(selectedIndex.value, {
            isCentered: true,
            smooth,
            speed: 300
        });
    }
    watch(selectedIndex, ()=>slideToActive(true));
    onActivated(()=>slideToActive(false));
    return {
        swiper,
        selectedIndex,
        itemsWithIdentifier,
        slideToActive
    };
}
