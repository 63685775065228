import { logger } from '@leon-hub/logging';
import { isArray } from '@leon-hub/guards';
import { isMetaPropertyWithName, isScriptMetaProperty, isTitleMetaProperty } from '@leon-hub/routing-config/src/types/MetaInfo';
export function getMetaTitleFromMetaInfo(metaInfoConfig) {
    const config = metaInfoConfig?.title || [];
    const [title] = isArray(config) ? config : [
        config
    ];
    return isTitleMetaProperty(title) ? title.content : title;
}
export function getMetaTagsFromMetaInfo(metaInfoConfig) {
    return (metaInfoConfig?.meta ?? []).filter((value)=>{
        if (isMetaPropertyWithName(value)) return true;
        logger.warn('Unexpected meta property value', value);
        return false;
    });
}
export function getMetaScriptsFromMetaInfo(metaInfoConfig) {
    return (metaInfoConfig?.script ?? []).filter((value)=>{
        if (isScriptMetaProperty(value)) return !value.template;
        logger.warn('Unexpected meta script property value', value);
        return false;
    });
}
export function getMetaScriptsTemplatesFromMetaInfo(metaInfoConfig) {
    return (metaInfoConfig?.script ?? []).filter((value)=>{
        if (isScriptMetaProperty(value)) return !!value.template;
        logger.warn('Unexpected meta script property value', value);
        return false;
    });
}
