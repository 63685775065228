/**
 * On first page loading:
 * - if we've got event data then it shows the page
 * - if we've got a MISSING status then it redirects up by the sport's tree
 * - if we've got an error (like 500) then it counts up to 3 and shows the connection error snackbar
 *
 * While viewing a page:
 * - if we've got event data then it shows the page
 * - if we've got a MISSING status then it counts up to 3. After the first status event will be suspended.
 *   Then we close it and show a suspended event page (@see LEONWEB-13493).
 * - If we've got an error (like 500) then we ignore it (like the lost connection)
 */ import { GetEventSubscriptionStatus } from './enums';
export function createGetEventFailedRequestsCounter() {
    let subscribers = [];
    const lastStatusMap = {};
    function getSubscribersIds() {
        return subscribers.map((subscriber)=>subscriber.id);
    }
    function subscribe(subscriber) {
        subscribers.push(subscriber);
    }
    function updateLastStatusCount(id, status) {
        let lastStatus = lastStatusMap[id];
        if (!lastStatus || lastStatus.status !== status) {
            lastStatus = {
                status,
                count: 0
            };
            lastStatusMap[id] = lastStatus;
        }
        lastStatus.count += 1;
        return lastStatus;
    }
    function fireStatus(id, status) {
        const counter = updateLastStatusCount(id, status);
        for (const subscriber of subscribers){
            if (subscriber.id === id) subscriber.onStatus(counter.status, counter.count);
        }
    }
    function fireStatusAllExcept(id, status) {
        for (const subscriber of subscribers){
            if (subscriber.id === id) continue;
            const counter = updateLastStatusCount(subscriber.id, status);
            subscriber.onStatus(counter.status, counter.count);
        }
    }
    function unsubscribe(id) {
        fireStatus(id, GetEventSubscriptionStatus.Unsubscribe);
        subscribers = subscribers.filter((subscriber)=>subscriber.id !== id);
    }
    function unsubscribeAllExcept(id) {
        fireStatusAllExcept(id, GetEventSubscriptionStatus.Unsubscribe);
        subscribers = subscribers.filter((subscriber)=>subscriber.id === id);
    }
    return {
        getSubscribersIds,
        subscribe,
        unsubscribe,
        unsubscribeAllExcept,
        fireStatus
    };
}
