import { getReferral, RequestOptionsPriority } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default async function getReferralData() {
    const apiClient = useGraphqlClient();
    return getReferral(apiClient, (node)=>node.queries.refProgram.getReferral, {
        options: {}
    }, {
        silent: true,
        priority: RequestOptionsPriority.LOW
    });
}
