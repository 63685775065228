import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useCTagController } from './composables/useCTagController';
import useSportlineApiLoadCounters from './composables/useSportlineApiLoadCounters';
import useSportlineApiLoadLeagueEvents from './composables/useSportlineApiLoadLeagueEvents';
import { useSportlineApiLoadEventDetails } from './composables/useSportlineApiLoadEventDetails';
import { useSportlineApiLoadRegionEvents } from './composables/useSportlineApiLoadRegionEvents';
import { useSportlineApiLoadFavoritesLeaguesEvents } from './composables/useSportlineApiLoadFavoritesLeaguesEvents';
import { useSportlineApiLoadFavoritesEvents } from './composables/useSportlineApiLoadFavoritesEvents';
import { useSportlineApiLoadSportsTree } from './composables/useSportlineApiLoadSportsTree';
import { useSportlineApiLoadRelatedEvents } from './composables/useSportlineApiLoadRelatedEvents';
import { useSportlineApiLoadComingEvents } from './composables/useSportlineApiLoadComingEvents';
import { useSportlineApiLoadLiveEvents } from './composables/useSportlineApiLoadLiveEvents';
import { useSportlineApiLoadZeroMarginEvents } from './composables/useSportlineApiLoadZeroMarginEvents';
import { useSportlineApiLoadSport } from './composables/useSportlineApiLoadSport';
import { useSportlineApiLoadRegion } from './composables/useSportlineApiLoadRegion';
import { useSportlineApiLoadLeague } from './composables/useSportlineApiLoadLeague';
export default function createSportlineApiService() {
    const { tryRequestWithCTag } = useCTagController();
    const settingsStore = useSportlineSettingsStore();
    const defaultRestApiFlags = toRef(()=>settingsStore.defaultRestApiFlags);
    const sportsRestApiFlags = toRef(()=>settingsStore.sportsRestApiFlags);
    const isZeroMarginEnabled = toRef(()=>settingsStore.isZeroMarginEnabled);
    const isLiveOutrightsEnabled = toRef(()=>settingsStore.isLiveOutrightsEnabled);
    const isExtraLiveEnabled = toRef(()=>settingsStore.isExtraLiveEnabled);
    const options = {
        tryRequestWithCTag,
        defaultRestApiFlags,
        isZeroMarginEnabled,
        isLiveOutrightsEnabled,
        isExtraLiveEnabled
    };
    const treeOptions = {
        tryRequestWithCTag,
        sportsRestApiFlags,
        isLiveOutrightsEnabled,
        isExtraLiveEnabled
    };
    const loadSportsTree = useSportlineApiLoadSportsTree(treeOptions);
    const loadEventDetails = useSportlineApiLoadEventDetails(options);
    const loadRelatedEvents = useSportlineApiLoadRelatedEvents(options);
    const loadComingEvents = useSportlineApiLoadComingEvents(options);
    const loadLiveEvents = useSportlineApiLoadLiveEvents(options);
    const loadZeroMarginEvents = useSportlineApiLoadZeroMarginEvents(options);
    const loadCounters = useSportlineApiLoadCounters(options);
    const loadLeagueEvents = useSportlineApiLoadLeagueEvents(options);
    const loadRegionEvents = useSportlineApiLoadRegionEvents(options);
    const loadFavoriteLeaguesEvents = useSportlineApiLoadFavoritesLeaguesEvents(options);
    const loadFavoriteEvents = useSportlineApiLoadFavoritesEvents(options);
    const loadSport = useSportlineApiLoadSport(treeOptions);
    const loadRegion = useSportlineApiLoadRegion(treeOptions);
    const loadLeague = useSportlineApiLoadLeague(treeOptions);
    return {
        ...loadSportsTree,
        ...loadRelatedEvents,
        ...loadLiveEvents,
        ...loadComingEvents,
        ...loadZeroMarginEvents,
        ...loadCounters,
        ...loadLeagueEvents,
        ...loadRegionEvents,
        ...loadEventDetails,
        ...loadFavoriteLeaguesEvents,
        ...loadFavoriteEvents,
        ...loadSport,
        ...loadRegion,
        ...loadLeague
    };
}
