import { Tag } from '@leon-hub/tags';
export const validAccordionTags = [
    Tag.DIV,
    Tag.SECTION,
    Tag.OL,
    Tag.UL,
    Tag.DL
];
export const validAccordionItemTags = [
    Tag.DIV,
    Tag.ARTICLE,
    Tag.LI
];
export var AccordionItemType;
var AccordionItemType1;
(AccordionItemType1 = AccordionItemType || (AccordionItemType = {}))["BASIC"] = "basic", AccordionItemType1["HOW_TO"] = "how-to";
