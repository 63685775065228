import { mergeDefaults as _mergeDefaults, defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import { Tag } from '@leon-hub/tags';
import { getVButtonDefaultProps } from './utils/getVButtonDefaultProps';
import { useButtonEmits } from './composables';
import SButton from '../SButton/SButton.vue';
import LButton from '../LButton/LButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VButton',
    props: /*@__PURE__*/ _mergeDefaults({
        rounded: {
            type: Boolean
        },
        kind: {},
        height: {},
        textColor: {},
        isUppercase: {
            type: Boolean
        },
        iconSize: {},
        hasAnimation: {
            type: Boolean
        },
        isAbsolutePosition: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        for: {},
        fullWidth: {
            type: Boolean
        },
        href: {},
        iconName: {},
        iconRight: {
            type: Boolean
        },
        isAutofocus: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        },
        isNoPaddings: {
            type: Boolean
        },
        label: {},
        role: {},
        tag: {},
        target: {},
        type: {},
        id: {}
    }, getVButtonDefaultProps()),
    emits: [
        "click",
        "keydown",
        "mousedown",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { click, keydown, mousedown, focus, blur } = useButtonEmits(emits);
        return (_ctx, _cache)=>(_openBlock(), _createBlock(LButton, _mergeProps({
                key: 1
            }, props, {
                onClick: _unref(click),
                onKeydown: _unref(keydown),
                onMousedown: _unref(mousedown),
                onFocus: _unref(focus),
                onBlur: _unref(blur)
            }), _createSlots({
                _: 2
            }, [
                _ctx.$slots.default ? {
                    name: "default",
                    fn: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                    key: "0"
                } : void 0,
                _ctx.$slots.icon ? {
                    name: "icon",
                    fn: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "icon")
                        ]),
                    key: "1"
                } : void 0
            ]), 1040, [
                "onClick",
                "onKeydown",
                "onMousedown",
                "onFocus",
                "onBlur"
            ]));
    }
});
