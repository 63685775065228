const createTestAttribute = (attributes)=>{
    if (!attributes?.el) return {};
    return Object.keys(attributes).reduce((result, key)=>{
        const name = 'el' === key ? 'data-test-el' : `data-test-attr-${key}`;
        // eslint-disable-next-line no-param-reassign
        result[name] = attributes[key];
        return result;
    }, {});
};
export const setElementAttributes = (element, values)=>{
    const attributes = createTestAttribute(values);
    for (const key of Object.keys(attributes))try {
        element.setAttribute(key, attributes[key]);
    } catch  {}
};
export const dataTest = {
    beforeMount: (element, binding)=>setElementAttributes(element, binding.value),
    updated: (element, binding)=>setElementAttributes(element, binding.value),
    beforeUnmount: (element, binding)=>setElementAttributes(element, binding.value)
};
