import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
import { useBaseTabsButton } from '../BaseTabsButton/composables';
import { TabsType } from '../VTabs/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LTabsButton',
    props: {
        version: {
            default: 'default'
        },
        isBordered: {
            type: Boolean
        },
        type: {
            default: TabsType.NORMAL
        },
        flexible: {
            type: Boolean
        },
        active: {
            type: Boolean
        },
        single: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        isNavigation: {
            type: Boolean
        },
        href: {},
        id: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const element = ref();
        const { isDisabled, tag, htmlTypeAttr, role } = useBaseTabsButton(props);
        function emitClick() {
            emit('click', props.id);
        }
        function click() {
            if (props.disabled) return;
            emitClick();
        }
        function getOffsetLeft() {
            return element.value?.offsetLeft || 0;
        }
        __expose({
            getOffsetLeft
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(tag)), {
                ref_key: "element",
                ref: element,
                disabled: _unref(isDisabled),
                role: _unref(role),
                class: _normalizeClass({
                    [_ctx.$style['tabs-button']]: true,
                    [_ctx.$style[`tabs-button-type--${_ctx.type}`]]: _ctx.type !== _unref(TabsType).NORMAL,
                    [_ctx.$style['tabs-button--flexible']]: _ctx.flexible,
                    [_ctx.$style['tabs-button--active']]: _ctx.active,
                    [_ctx.$style['tabs-button--is-bordered']]: _ctx.isBordered,
                    [_ctx.$style['tabs-button--semi-rounded']]: void 0,
                    [_ctx.$style['tabs-button--single']]: _ctx.single
                }),
                href: _ctx.href,
                type: _unref(htmlTypeAttr),
                onClick: _withModifiers(click, [
                    "prevent"
                ])
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 8, [
                "disabled",
                "role",
                "class",
                "href",
                "type"
            ])), [
                [
                    _directive_auto_id,
                    'TabsButton'
                ]
            ]);
        };
    }
});
