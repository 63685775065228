import { ref, computed } from 'vue';
import { useInjectIsExpandedSportlineBlock, useExpandedSportBlockLocalStorageRef } from 'web/src/modules/sportline/composables/expandable-block';
import { playExpandAnimation } from 'web/src/modules/sportline/utils/dom';
export { localStorageKey } from 'web/src/modules/sportline/composables/expandable-block';
const expandAnimationDuration = 250;
export function useExpandableSportlineBlock(props) {
    const { content, expandKey, isDefaultExpanded } = props;
    const isExpandedInLocalStorage = useExpandedSportBlockLocalStorageRef(expandKey, isDefaultExpanded);
    const { isExpanded, setExpandedState } = useInjectIsExpandedSportlineBlock({
        expandKey,
        isDefaultExpanded
    });
    const expandAnimationActive = ref(false);
    const isExpandable = computed(()=>null !== expandKey.value);
    const canToggleState = computed(()=>isExpandable.value && !!expandKey.value && !expandAnimationActive.value);
    async function toggleExpandState(state) {
        const currentContent = content.value;
        if (!canToggleState.value || !currentContent) return;
        const maxHeight = Math.min(currentContent.scrollHeight, document.body.offsetHeight);
        const duration = state.value ? expandAnimationDuration : 1;
        isExpandedInLocalStorage.value = state.value;
        expandAnimationActive.value = true;
        await playExpandAnimation(currentContent, {
            duration,
            maxHeight
        });
        setExpandedState(state.value);
        expandAnimationActive.value = false;
    }
    // init
    setExpandedState(isExpandedInLocalStorage.value);
    return {
        isExpanded,
        isExpandable,
        expandAnimationActive,
        expandAnimationDuration,
        toggleExpandState
    };
}
