import { bindIsArrayOf, isValidInterface } from '@leon-hub/guards';
import { isCoreRegionResponseFieldGuards } from './isCoreRegionResponse';
import { isGetSportsLeagueResponse } from './isGetSportsLeagueResponse';
export const isGetSportsRegionResponseFieldGuards = {
    ...isCoreRegionResponseFieldGuards,
    leagues: bindIsArrayOf(isGetSportsLeagueResponse)
};
export function isGetSportsRegionResponse(value) {
    return isValidInterface(value, isGetSportsRegionResponseFieldGuards);
}
