export var ResponsibleGamblingOptionType;
var ResponsibleGamblingOptionType1;
(ResponsibleGamblingOptionType1 = ResponsibleGamblingOptionType || (ResponsibleGamblingOptionType = {}))["TEST"] = "test", ResponsibleGamblingOptionType1["SUPPORT"] = "support", ResponsibleGamblingOptionType1["LIMITS"] = "limits", ResponsibleGamblingOptionType1["INFO"] = "info";
export var ResponsibleGamblingQuizResultsKind;
var ResponsibleGamblingQuizResultsKind1;
(ResponsibleGamblingQuizResultsKind1 = ResponsibleGamblingQuizResultsKind || (ResponsibleGamblingQuizResultsKind = {}))["DEFAULT"] = "default", ResponsibleGamblingQuizResultsKind1["ACCEPTABLE"] = "acceptable", ResponsibleGamblingQuizResultsKind1["MODERATELY"] = "moderately", ResponsibleGamblingQuizResultsKind1["CRITICAL"] = "critical";
export var StepName;
var StepName1;
(StepName1 = StepName || (StepName = {}))["INFO"] = "info", StepName1["EDIT"] = "edit";
export var LimitName;
var LimitName1;
(LimitName1 = LimitName || (LimitName = {}))["DAILY"] = "DAILY", LimitName1["WEEKLY"] = "WEEKLY", LimitName1["MONTHLY"] = "MONTHLY";
