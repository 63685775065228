import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
import { SliceEventsResponseError } from 'web/src/modules/sportline/errors/rest';
import { ResponseMappings } from 'web/src/modules/sportline/utils/rest/pre-build';
// eslint-disable-next-line unicorn/no-static-only-class
export class SportEventsResponseChangeUtils {
    /**
   * Used for restore duplicates in response
   * @param {GetSportEventsResponse} response
   * @return {GetSportEventsResponse}
   */ static normalizeDuplicatesResponse(response) {
        if (!response) return null;
        const responseMappings = new ResponseMappings();
        const events = response.events.reduce((result, event)=>{
            try {
                const leagueResponse = responseMappings.getLeagueResponseOrSetNew(event.league);
                const regionResponse = responseMappings.getRegionResponseOrSetNew(leagueResponse.region);
                const sportResponse = responseMappings.getSportResponseOrSetNew(leagueResponse.sport);
                const normalizedEvent = {
                    ...event,
                    league: {
                        ...leagueResponse,
                        region: regionResponse,
                        sport: sportResponse
                    }
                };
                return [
                    ...result,
                    normalizedEvent
                ];
            } catch (rawError) {
                const error = normalizeError(rawError);
                logger.error(error);
                return result;
            }
        }, []);
        return {
            ...response,
            totalCount: events.length,
            events
        };
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    static sliceEvents(response, options) {
        const countsIn = {
            sport: {},
            region: {}
        };
        const limits = {
            sports: options.sports || null,
            eventsInSport: options.eventsInSport || null,
            regions: options.regions || null,
            eventsInRegion: options.eventsInRegion || null
        };
        const responseMappings = new ResponseMappings();
        const eventsErrors = [];
        const events = response.events.filter((event)=>{
            try {
                const leagueResponse = responseMappings.getLeagueResponseOrSetNew(event.league);
                const regionResponse = responseMappings.getRegionResponseOrSetNew(leagueResponse.region);
                const sportResponse = responseMappings.getSportResponseOrSetNew(leagueResponse.sport);
                const currentSportsCount = Object.keys(countsIn.sport).length;
                const isNewSport = !(sportResponse.id in countsIn.sport);
                if (isNewSport && null != limits.sports && currentSportsCount >= limits.sports) return false;
                const currentEventsInSportCount = countsIn.sport[sportResponse.id] || 0;
                if (null !== limits.eventsInSport && currentEventsInSportCount >= limits.eventsInSport) return false;
                const currentRegionsCount = Object.keys(countsIn.region).length;
                const isNewRegion = !(regionResponse.id in countsIn.region);
                if (isNewRegion && null !== limits.regions && currentRegionsCount >= limits.regions) return false;
                const currentEventsInRegionCount = countsIn.region[regionResponse.id] || 0;
                if (null !== limits.eventsInRegion && currentEventsInRegionCount >= limits.eventsInRegion) return false;
                countsIn.sport[sportResponse.id] = currentEventsInSportCount + 1;
                countsIn.region[regionResponse.id] = currentEventsInRegionCount + 1;
                return true;
            } catch (rawError) {
                const error = normalizeError(rawError);
                eventsErrors.push({
                    event,
                    originalError: error
                });
                return false;
            }
        });
        if (eventsErrors.length > 0) // throw error cause to empty events block
        logger.error(new SliceEventsResponseError({
            payload: {
                eventsErrors,
                limits
            },
            silent: true
        }));
        return {
            ...response,
            totalCount: events.length,
            events
        };
    }
    static excludeResponses(response, exclude) {
        if (!response) return null;
        if (!exclude) return response;
        const excludedEventsIds = exclude.events.map((event)=>event.id);
        const events = response.events.filter((event)=>!excludedEventsIds.includes(event.id));
        return {
            ...response,
            totalCount: events.length,
            events
        };
    }
    static concatResponses(responses) {
        return responses.reduce((result, response)=>response ? {
                enabled: result.enabled && response.enabled,
                vtag: response.vtag || result.vtag,
                events: [
                    ...result.events,
                    ...response.events
                ],
                totalCount: result.totalCount + response.totalCount
            } : result, {
            enabled: true,
            vtag: '',
            events: [],
            totalCount: 0
        });
    }
    static isSomeIdsInList(response, param) {
        let { eventsIds, leaguesIds } = param;
        return !!response?.events.some((eventResponse)=>(eventsIds || []).includes(String(eventResponse.id)) || (leaguesIds || []).includes(String(eventResponse.league.id)));
    }
}
