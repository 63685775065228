import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useHintParentProps } from '../../composables';
import TextInput from '../TextInput';
import { InputMode, TextInputTypes } from '../../enums';
import useDateMaskInput from './useDateMaskInput';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'DateMaskInput',
    props: {
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'date-input'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        autocomplete: {},
        placeholder: {},
        prefixIconName: {},
        suffixIconName: {},
        emitFilled: {
            type: Boolean
        }
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { inputValue, mask, maxSymbols, onInput, onChange, onFocus, onBlur } = useDateMaskInput(props, emits);
        const { hintProperties } = useHintParentProps(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(TextInput), _mergeProps(_unref(hintProperties), {
                autofocus: _ctx.autofocus,
                name: _ctx.name,
                label: _ctx.label,
                value: _unref(inputValue),
                disabled: _ctx.disabled,
                autocomplete: _ctx.autocomplete,
                placeholder: _ctx.placeholder,
                "max-symbols": _unref(maxSymbols),
                "prefix-icon-name": _ctx.prefixIconName,
                "suffix-icon-name": _ctx.suffixIconName,
                mask: _unref(mask),
                type: _unref(TextInputTypes).TEL,
                "input-mode": _unref(InputMode).NUMERIC,
                "is-large": _ctx.isLarge,
                onInput: _unref(onInput),
                onChange: _unref(onChange),
                onBlur: _unref(onBlur),
                onFocus: _unref(onFocus)
            }), null, 16, [
                "autofocus",
                "name",
                "label",
                "value",
                "disabled",
                "autocomplete",
                "placeholder",
                "max-symbols",
                "prefix-icon-name",
                "suffix-icon-name",
                "mask",
                "type",
                "input-mode",
                "is-large",
                "onInput",
                "onChange",
                "onBlur",
                "onFocus"
            ])), [
                [
                    _directive_auto_id,
                    'DateMaskInput'
                ]
            ]);
        };
    }
});
