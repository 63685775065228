import { isValidInterface } from '@leon-hub/guards';
import { isCoreLeagueResponseFieldGuards } from './isCoreLeagueResponse';
export const isGetSportsLeagueResponseFieldGuards = {
    ...isCoreLeagueResponseFieldGuards,
    sport: (value)=>!value,
    region: (value)=>!value
};
export function isGetSportsLeagueResponse(value) {
    return isValidInterface(value, isGetSportsLeagueResponseFieldGuards);
}
