import { isNumber } from '@leon-hub/guards';
import handleMaskedValueChange from './handleMaskedValueChange';
import getOnlyDigitsFromString from './getOnlyDigitsFromString';
export default function handlePhonePaste(param) {
    let { event, currentValue, maskOptions } = param;
    event.preventDefault();
    const { target, clipboardData } = event;
    HTMLInputElement;
    const { selectionStart, selectionEnd } = target;
    isNumber(selectionStart);
    isNumber(selectionEnd);
    const data = clipboardData?.getData('text') ?? '';
    const safeSymbolsFromBuffer = getOnlyDigitsFromString(data);
    if (!maskOptions) {
        const valueBefore = currentValue.slice(0, selectionStart);
        const valueAfter = currentValue.slice(selectionEnd);
        return {
            nextValue: [
                valueBefore,
                safeSymbolsFromBuffer,
                valueAfter
            ].join(''),
            isFilled: false
        };
    }
    return handleMaskedValueChange({
        target,
        selectionStart,
        selectionEnd,
        data,
        currentValue,
        maskOptions
    });
}
