import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class OnboardingModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        const routeOptions = {
            name: RouteName.ONBOARDING,
            path: '/onboarding/:id',
            component: ()=>import('web/src/modules/onboarding/pages/OnboardingRoutePage.vue'),
            prefetch: ()=>import('web/src/modules/onboarding/prefetch/OnboardingPagePrefetch'),
            title: '',
            topBar: ()=>import('web/src/modules/onboarding/topbars/OnboardingTopbar/OnboardingTopbar.vue')
        };
        const { addProfileRoute, addAnonymousProfileRoute, addModalRoute } = router;
        addProfileRoute(routeOptions);
    }
}
