import { computed, watch } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { MarketCustomTabs } from 'web/src/modules/sportline/views/markets-list/enums';
export default function useMarketsListTabs(props, emit) {
    const { $translate } = useI18n();
    const basicTabsList = computed(()=>props.hasAllTab?.value ? [
            {
                id: MarketCustomTabs.All,
                label: $translate('WEB_ALL_MARKETS').value
            }
        ] : []);
    const statisticTabs = computed(()=>props.hasStatistic?.value ? [
            {
                id: MarketCustomTabs.Statistic,
                icon: {
                    name: IconName.STATISTIC,
                    size: IconSize.SIZE_18
                }
            }
        ] : []);
    const tabs = computed(()=>[
            ...statisticTabs.value,
            ...basicTabsList.value,
            ...props.groups.value.map((item)=>({
                    id: item.id,
                    label: item.name
                }))
        ]);
    const activeTabIndex = computed(()=>tabs.value.findIndex((tab)=>tab.id === props.activeTabId.value));
    function slideToActive() {
        let smooth = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        props.tabsRef.value?.slideToSlide(activeTabIndex.value, {
            smooth,
            speed: 300,
            isCentered: true
        });
    }
    function onTabClick(value) {
        emit('tab-click', value);
    }
    watch(props.activeTabId, ()=>{
        slideToActive(true);
    });
    return {
        activeTabIndex,
        tabs,
        slideToActive,
        onTabClick
    };
}
