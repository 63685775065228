import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { CustomerFieldType, InvalidPhoneExceptionCode, getCustomerProfileFields, updateCustomerProfileFields } from '@leon-hub/api-sdk/src/sdk/sdk';
import { isObject } from '@leon-hub/guards';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import GqlApiError from '@leon-hub/api/src/client/graphql/errors/GqlApiError';
import { ApiErrorCode } from '@leon-hub/api';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import { mapFormData } from 'web/src/components/Form/utils';
import CustomerFormError from 'web/src/utils/forms/CustomerFormError';
import updateFieldErrorByFormError from 'web/src/utils/forms/updateFieldErrorByFormError';
import extractCustomerFormErrors from 'web/src/utils/forms/extractCustomerFormErrors';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useUserStore } from 'web/src/modules/user/store';
import { JsonSchemaAdapter, getUiFieldRawOptions } from 'web/src/utils/jsonSchemaAdapter';
import { FormControlType } from 'web/src/components/Form/enums';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { isProfileForm, isUpdateIncompleteProfileFieldsResponse } from 'web/src/modules/profile/types';
const useSimpleRegistrantProfileStore = defineStore('simple-registrant-profile', ()=>{
    const api = useGraphqlClient();
    const { $translate } = useI18n();
    const userStore = useUserStore();
    const customerDataStore = useCustomerDataStore();
    const { parsePhoneNumberByUIField } = useFormDefaultFormPhoneValue();
    const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
    const emailSubscription = toRef(customerDataStore, 'emailSubscription');
    const country = toRef(customerDataStore, 'country');
    const form = ref(null);
    const disabledFields = ref([]);
    function setProfileForm(value) {
        form.value = value;
        if (null === value) disabledFields.value = [];
    }
    function updateUiFields(fields) {
        if (form.value?.uiFormSchema.fields.length) for (const field of fields){
            const targetIndex = form.value.uiFormSchema.fields.findIndex((item)=>item.id === field.id);
            if (!(targetIndex < 0)) {
                if (form.value?.uiFormSchema.fields) setProfileForm({
                    ...form.value,
                    uiFormSchema: {
                        ...form.value.uiFormSchema,
                        fields: [
                            ...form.value.uiFormSchema.fields.slice(0, targetIndex),
                            field,
                            ...form.value.uiFormSchema.fields.slice(targetIndex + 1)
                        ]
                    }
                });
            }
        }
    }
    function disableUiFields(listOfFields) {
        disabledFields.value = [
            ...new Set([
                ...disabledFields.value,
                ...listOfFields
            ])
        ];
        const updatedFields = (form.value?.uiFormSchema.fields ?? []).filter((field)=>listOfFields.includes(field.id)).map((field)=>({
                ...field,
                disabled: true
            }));
        updateUiFields(updatedFields);
    }
    function hiddenUiField(fieldId) {
        const updatedFields = (form.value?.uiFormSchema.fields ?? []).filter((field)=>field.id === fieldId).map((field)=>({
                ...field,
                hidden: true
            }));
        updateUiFields(updatedFields);
    }
    async function getProfileFields() {
        const profileForm = await getCustomerProfileFields(api, (node)=>node.queries.registration.getCustomerProfileFields);
        isProfileForm(profileForm);
        setProfileForm(profileForm);
        hiddenUiField(CustomerFieldType.SUBSCRIBE);
        disableUiFields(profileForm.uiFormSchema.fields.filter((field)=>field.disabled).map((field)=>field.id));
    }
    async function updateCustomerData(param) {
        let { formData } = param;
        isObject(form.value);
        let updateResponse = null;
        const filteredFormData = Object.keys(formData).filter((key)=>!disabledFields.value.includes(key)).reduce((object, key)=>({
                ...object,
                [key]: formData[key]
            }), {});
        try {
            updateResponse = await updateCustomerProfileFields(api, (node)=>node.mutations.registration.updateCustomerProfileFields, {
                options: {
                    schemaId: form.value.schemaId,
                    formParams: mapFormData({
                        ...filteredFormData,
                        [CustomerFieldType.SUBSCRIBE]: !!emailSubscription.value
                    })
                }
            });
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError && // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            error.extensions.errorCode === InvalidPhoneExceptionCode.INVALID_PHONE) throw new CustomerFormError(updateFieldErrorByFormError(formData, CustomerFieldType.PHONE_INPUT, form.value.uiFormSchema, extractCustomerFormErrors(form.value.uiFormSchema, formData), error.message));
        }
        isUpdateIncompleteProfileFieldsResponse(updateResponse);
        const { registrationForm } = updateResponse;
        isProfileForm(registrationForm);
        if (registrationForm.formError) throw new GqlApiError({
            code: ApiErrorCode.API_UNEXPECTED_ERROR,
            message: registrationForm.formError
        });
        await userStore.loadUserData(true);
        if (registrationForm.formError || registrationForm.hasFieldsErrors) throw new CustomerFormError(updateFieldErrorByFormError(formData, CustomerFieldType.PHONE_INPUT, registrationForm.uiFormSchema, extractCustomerFormErrors(registrationForm.uiFormSchema, formData)));
        if (customerDataStore.customerData?.isRegistrationFinish) {
            setProfileForm(registrationForm);
            hiddenUiField(CustomerFieldType.SUBSCRIBE);
            disableUiFields([]);
        } else {
            updateUiFields(registrationForm.uiFormSchema.fields.filter((field)=>field.disabled));
            const currentSetFields = form.value.uiFormSchema.order;
            const newSetFields = registrationForm.uiFormSchema.order;
            disableUiFields(currentSetFields.filter((x)=>!newSetFields.includes(x)));
        }
    }
    const profileSchema = computed(()=>{
        if (form.value) {
            const jsonSchema = JSON.parse(form.value.formValidationSchema);
            const jsonSchemaAdapter = new JsonSchemaAdapter(form.value.uiFormSchema, jsonSchema).addPropertiesByWidget(FormControlType.AutocompleteAddress, (field)=>({
                    default: String(field.defaultValue ?? ''),
                    options: {
                        ...field.options ? getUiFieldRawOptions(field.options) : {},
                        countryCode: country.value
                    }
                })).addPropertiesById(CustomerFieldType.PHONE_INPUT, (field)=>{
                const defaultValue = parsePhoneNumberByUIField(field);
                return {
                    default: defaultValue,
                    options: {
                        disablePlaceholder: defaultValue.suffix
                    }
                };
            });
            return {
                schema: jsonSchemaAdapter.getFormSchema(),
                uiSchema: {
                    ...jsonSchemaAdapter.getFormUiSchema(),
                    validatorErrorPatterns: {
                        byWidget: phoneErrorPatterns.value
                    },
                    submitButton: {
                        label: $translate('JSPACC_SETT_SAVE').value
                    }
                }
            };
        }
        return null;
    });
    const isFormLoaded = computed(()=>null !== profileSchema.value);
    return {
        setProfileForm,
        getProfileFields,
        updateCustomerData,
        profileSchema,
        isFormLoaded,
        form,
        disabledFields
    };
});
export default useSimpleRegistrantProfileStore;
