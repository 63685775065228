import { useCmsStore } from 'web/src/modules/cms/store';
export default function useAccessCallbackRoutePage() {
    async function created() {
        await useCmsStore().fetchCmsContentTranslation({
            key: 'WEB2_ACCESS_CALLBACK_OPTION'
        });
    }
    return {
        created
    };
}
