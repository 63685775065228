import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { isArray } from '@leon-hub/guards';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useRouterStore } from 'web/src/modules/core/store';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';
import replaceMetaParametersPlaceholders from 'web/src/modules/seo/utils/replaceMetaParametersPlaceholders';
import { getMetaScriptsTemplatesFromMetaInfo, getMetaScriptsFromMetaInfo, getMetaTagsFromMetaInfo, getMetaTitleFromMetaInfo } from 'web/src/modules/seo/utils/extractMetaInfo';
export function useSeoMetaTitleProperty(props) {
    const { spintaxMetaInfo, metaInfoConfig, metaParameters } = props;
    const { $translate } = useI18n();
    const defaultTitle = $translate('WEB2_SEO_DEFAULT_TITLE');
    return computed(()=>{
        const spintaxMetaTitle = getMetaTitleFromMetaInfo(spintaxMetaInfo.value);
        if (spintaxMetaTitle) return spintaxMetaTitle;
        const metaInfoConfigTitle = getMetaTitleFromMetaInfo(metaInfoConfig.value);
        return replaceMetaParametersPlaceholders(metaInfoConfigTitle || '', metaParameters.value) || defaultTitle.value;
    });
}
export function useSeoMetaTagsProperty(props) {
    const { spintaxMetaInfo, metaInfoConfig, metaParameters } = props;
    return computed(()=>{
        const spintaxMetaTags = getMetaTagsFromMetaInfo(spintaxMetaInfo.value);
        const metaInfoMetaTags = getMetaTagsFromMetaInfo(metaInfoConfig.value);
        const result = {};
        for (const value of spintaxMetaTags)if (value.content) result[value.name] = {
            name: value.name,
            content: value.content
        };
        for (const value of metaInfoMetaTags){
            // skip exist property
            if (!result[value.name]) result[value.name] = {
                name: value.name,
                content: replaceMetaParametersPlaceholders(value.content, metaParameters.value) || ''
            };
        }
        return Object.values(result);
    });
}
export function useSeoMetaScriptsProperty(props) {
    const { metaInfoConfig, metaParameters } = props;
    return computed(()=>{
        const metaInfoMetaScripts = getMetaScriptsFromMetaInfo(metaInfoConfig.value);
        const metaInfoMetaScriptsTemplates = getMetaScriptsTemplatesFromMetaInfo(metaInfoConfig.value);
        const tags = metaInfoMetaScripts.map((value)=>({
                type: value.type,
                innerHTML: replaceMetaParametersPlaceholders(value.content, metaParameters.value) || ''
            }));
        const templates = metaInfoMetaScriptsTemplates.reduce((result, value)=>{
            const dataSet = metaParameters.value[value.template];
            if (dataSet && isArray(dataSet)) return [
                ...result,
                ...dataSet.map((data)=>({
                        type: value.type,
                        innerHTML: replaceMetaParametersPlaceholders(value.content, data) || ''
                    }))
            ];
            return result;
        }, []);
        return [
            ...tags,
            ...templates
        ];
    });
}
/**
 * Computed App properties
 */ export default function useSeoMetaProperties(options) {
    const routerStore = useRouterStore();
    const seoMetaStorage = useSeoMetaStorage();
    const seoConfigs = toRef(routerStore, 'seoConfigs');
    const metaParameters = toRef(seoMetaStorage, 'metaParameters');
    const spintaxMetaData = toRef(seoMetaStorage, 'spintaxMetaData');
    const spintaxMetaInfo = toRef(seoMetaStorage, 'spintaxMetaInfo');
    // vue-router use inject logic, so outside the app, it is empty
    const router = options?.router ?? useRouter();
    const metaInfoConfig = computed(()=>{
        const route = router.currentRoute.value;
        return route ? seoConfigs.value[String(route.name)]?.metaInfo : void 0;
    });
    const metaTitle = useSeoMetaTitleProperty({
        spintaxMetaInfo,
        metaInfoConfig,
        metaParameters
    });
    const metaTags = useSeoMetaTagsProperty({
        spintaxMetaInfo,
        metaInfoConfig,
        metaParameters
    });
    const metaScripts = useSeoMetaScriptsProperty({
        metaInfoConfig,
        metaParameters
    });
    return {
        metaParameters,
        metaTitle,
        metaTags,
        metaScripts,
        spintaxMetaData,
        spintaxMetaInfo
    };
}
