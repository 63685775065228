import { isNumber, isString, bindIsArrayOf, isValidInterface } from '@leon-hub/guards';
export const isCoreSportMarketColumnResponseFieldGuards = {
    name: isString,
    id: isNumber,
    altMarketTypeIds: bindIsArrayOf(isNumber),
    weight: isNumber
};
export function isCoreSportMarketColumnResponse(value) {
    return isValidInterface(value, isCoreSportMarketColumnResponseFieldGuards);
}
