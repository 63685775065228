import { computed } from 'vue';
import { InputEventType } from 'web/src/components/Input/enums';
export default function useDropdownEvents(props, emit, closeCallback, clearSearchQueryCallback) {
    const inputEvent = computed(()=>({
            target: {
                name: props.name || '',
                value: props.selectedValue || ''
            }
        }));
    const onChange = (value)=>{
        clearSearchQueryCallback();
        closeCallback();
        if (value === props.selectedValue) return;
        emit(InputEventType.CHANGE, {
            target: {
                value,
                name: props.name || ''
            }
        });
    };
    const onFocus = ()=>{
        emit(InputEventType.FOCUS, inputEvent.value);
    };
    const onBlur = ()=>{
        emit(InputEventType.BLUR, inputEvent.value);
    };
    return {
        onFocus,
        onChange,
        onBlur
    };
}
