import { isObject, isString } from '@leon-hub/guards';
/**
 * Something from siteconfig
 */ export function isMetaPropertyWithName(value) {
    if (isCoreMetaProperty(value)) return 'string' == typeof value.name;
    return false;
}
export function isScriptMetaProperty(value) {
    return isCoreMetaProperty(value) && isString(value.type);
}
function isCoreMetaProperty(value) {
    return isObject(value) && Object.values(value).every((val)=>void 0 === val || 'string' == typeof val);
}
export function isTitleMetaProperty(value) {
    return isCoreMetaProperty(value);
}
