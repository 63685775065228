import { isNull, isArray, isString, isNumber, isUndefined, isValidInterface } from '@leon-hub/guards';
function isUnknownGetEventsResponse(value) {
    return isValidInterface(value, {
        totalCount: isNumber,
        vtag: [
            isUndefined,
            isString
        ],
        events: isArray
    }, {
        silent: true
    });
}
function isUnknownGetEventsChangesResponse(value) {
    return isValidInterface(value, {
        totalCount: isNumber,
        vtag: [
            isUndefined,
            isString
        ],
        data: isArray
    }, {
        silent: true
    });
}
/** Asserts types before filter events */ export function assertUnknownGetEventsResponse(value) {
    if (!isUnknownGetEventsResponse(value)) throw new Error('UnknownGetEventsResponse assertion failed');
}
/** Asserts types before filter changes */ export function assertUnknownGetEventsChangesResponse(value) {
    if (!isUnknownGetEventsChangesResponse(value)) throw new Error('UnknownGetEventsChangesResponse assertion failed');
}
export function assertUnknownGetHeadlineEventsResponse(value) {
    if (!isValidInterface(value, {
        total: [
            isUndefined,
            isNull,
            isNumber
        ],
        liveTotal: [
            isUndefined,
            isNull,
            isNumber
        ],
        prematchTotal: [
            isUndefined,
            isNull,
            isNumber
        ],
        events: [
            isUndefined,
            isNull,
            isUnknownGetEventsResponse
        ],
        live: [
            isUndefined,
            isNull,
            isUnknownGetEventsResponse
        ],
        prematch: [
            isUndefined,
            isNull,
            isUnknownGetEventsResponse
        ]
    }, {
        silent: true
    })) throw new Error('UnknownGetHeadlineEventsResponse assertion failed');
}
export function assertUnknownGetHeadlineChangesEventsResponse(value) {
    if (!isValidInterface(value, {
        total: [
            isUndefined,
            isNull,
            isNumber
        ],
        liveTotal: [
            isUndefined,
            isNull,
            isNumber
        ],
        prematchTotal: [
            isUndefined,
            isNull,
            isNumber
        ],
        events: [
            isUndefined,
            isNull,
            isUnknownGetEventsChangesResponse
        ],
        live: [
            isUndefined,
            isNull,
            isUnknownGetEventsChangesResponse
        ],
        prematch: [
            isUndefined,
            isNull,
            isUnknownGetEventsChangesResponse
        ]
    }, {
        silent: true
    })) throw new Error('UnknownGetHeadlineChangesEventsResponse assertion failed');
}
