import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { computed, ref } from 'vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import PaymentsDepositsOtherSuccessModal from 'web/src/modules/payments/components/PaymentsDepositsOtherSuccessModal/PaymentsDepositsOtherSuccessModal.vue';
import PaymentsWithdrawalCancelSuccessModal from 'web/src/modules/payments/components/PaymentsWithdrawalCancelSuccessModal/PaymentsWithdrawalCancelSuccessModal.vue';
import PaymentsWithdrawalCancelModal from 'web/src/modules/payments/components/PaymentsWithdrawalCancelModal/PaymentsWithdrawalCancelModal.vue';
import { PaymentsModalType } from 'web/src/modules/payments/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsModalsController',
    props: {
        modalText: {
            default: ''
        },
        secondaryText: {
            default: ''
        },
        modalType: {},
        isInProfile: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "confirm-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const isModalShown = ref(true);
        const modalSelector = computed(()=>props.isInProfile ? ModalSelector.DESKTOP_INNER_MODAL : ModalSelector.BODY);
        const modalComponentName = computed(()=>{
            switch(props.modalType){
                case PaymentsModalType.DEPOSITS_OTHER_SUCCESS_MODAL:
                    return PaymentsDepositsOtherSuccessModal;
                case PaymentsModalType.WITHDRAWAL_CANCEL_SUCCESS_MODAL:
                    return PaymentsWithdrawalCancelSuccessModal;
                case PaymentsModalType.WITHDRAWAL_CANCEL_MODAL:
                    return PaymentsWithdrawalCancelModal;
                default:
                    break;
            }
            return '';
        });
        function onModalClose() {
            isModalShown.value = false;
            emit('close');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.modalType ? _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                key: 0,
                selector: modalSelector.value
            }, {
                default: _withCtx(()=>[
                        isModalShown.value ? (_openBlock(), _createBlock(_resolveDynamicComponent(modalComponentName.value), {
                            key: 0,
                            text: _ctx.modalText,
                            "secondary-text": _ctx.secondaryText,
                            "is-in-profile": _ctx.isInProfile,
                            onClose: onModalClose,
                            onConfirmClick: _cache[0] || (_cache[0] = ($event)=>emit('confirm-click'))
                        }, null, 40, [
                            "text",
                            "secondary-text",
                            "is-in-profile"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "selector"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsModalsController'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
