import DOMPurify from 'dompurify';
export default function sanitize(content, config) {
    const args = config ? [
        content,
        config
    ] : [
        content
    ];
    return Reflect.apply(DOMPurify.sanitize, DOMPurify, args);
}
