import { computed, ref } from 'vue';
import { useBaseButton } from 'web/src/components/Button/VButton/composables';
export default function useLandingButton(props, slots) {
    const { computedAttributes: baseComputedAttributes } = useBaseButton(props, slots);
    const mainTag = ref();
    const computedAttributes = computed(()=>{
        const attributes = {
            ...baseComputedAttributes.value
        };
        if (props.dataAttributeName && props.dataAttributeValue) attributes[props.dataAttributeName] = props.dataAttributeValue;
        return attributes;
    });
    return {
        mainTag,
        computedAttributes
    };
}
