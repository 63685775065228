import { ref, onBeforeMount, computed } from 'vue';
import { useRouter } from 'vue-router';
import { CustomerConfig } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { useUserStore } from 'web/src/modules/user/store';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useCasinoGameBonusReminderModal(props, emits) {
    const { setAdditionalPropsUser } = useUserStore();
    const formatMoney = useFormatMoney();
    const router = useRouter();
    const isDontShowAgain = ref(false);
    const isVisible = ref(false);
    const maxBetLimitFormatted = computed(()=>formatMoney(props.maxBetLimit?.amount || 0, {
            currency: props.maxBetLimit?.currency
        }));
    onBeforeMount(()=>{
        isVisible.value = !props.hideLimitModalOnGamePage;
    });
    function onChangeConsent(event) {
        const { checked } = event.target;
        isDontShowAgain.value = checked;
    }
    async function onCloseReminderModal() {
        if (isDontShowAgain.value) await setAdditionalPropsUser({
            value: {
                value: isDontShowAgain.value
            },
            config: CustomerConfig.HIDE_LIMIT_MODAL_ON_GAME_PAGE
        });
        isVisible.value = false;
        emits('close');
    }
    function onBonusLinkClick() {
        isVisible.value = false;
        router.push({
            name: RouteName.BONUSES
        });
    }
    return {
        isDontShowAgain,
        isVisible,
        maxBetLimitFormatted,
        onChangeConsent,
        onCloseReminderModal,
        onBonusLinkClick
    };
}
