import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';
import { webSocketDocuments } from '../sdk-ws';
import isSubscriptionAllowed from '../helpers/isSubscriptionAllowed';
let WebSocketSubscription = class WebSocketSubscription {
    setPollingTimeout(timeout) {
        if (this.polling) {
            this.polling.timeout = timeout;
            if (this.pollingInterval) this.startPollingRequestInternal();
        }
    }
    setIsWsEnabled(isWsEnabled) {
        this.isWsEnabled = isWsEnabled;
    }
    isAllowed(param) {
        let { isLoggedIn } = param;
        return isSubscriptionAllowed(isLoggedIn, this.access);
    }
    isWebSocketsEnabled() {
        return !!this.isWsEnabled;
    }
    isPollingMustBeCalledOnLogin() {
        return !!this.polling?.callOnLogin;
    }
    getWsSubscriptionInput() {
        return {
            id: this.method,
            query: this.query,
            variables: this.variables,
            onMessage: this.onMessage,
            onError: (error)=>{
                logger.error(`[WebSocket] Failed to subscribe to event: ${this.method}. Fallback to polling.`, error);
                this.startPollingRequest();
            }
        };
    }
    startPollingRequest() {
        if (this.pollingInterval) return;
        this.startPollingRequestInternal();
    }
    startPollingRequestInternal() {
        if (this.polling) {
            this.stopPollingRequest();
            this.pollingInterval = Timer.setTimeout(()=>{
                this.polling;
                Promise.resolve(this.polling.callback()).then(()=>this.startPollingRequestInternal());
            }, this.polling.timeout);
        }
    }
    callPollingRequest() {
        if (this.polling) {
            this.polling.callback();
            if (this.pollingInterval) this.startPollingRequestInternal();
        }
    }
    stopPollingRequest() {
        if (this.pollingInterval) {
            Timer.clearTimeout(this.pollingInterval);
            this.pollingInterval = 0;
        }
    }
    constructor({ method, onMessage, variables, polling, access, isWsEnabled }){
        _define_property(this, "method", void 0);
        _define_property(this, "query", void 0);
        _define_property(this, "variables", void 0);
        _define_property(this, "onMessage", void 0);
        _define_property(this, "polling", void 0);
        _define_property(this, "isWsEnabled", void 0);
        _define_property(this, "access", void 0);
        _define_property(this, "pollingInterval", 0);
        this.method = method;
        this.query = webSocketDocuments[method];
        this.variables = variables;
        this.onMessage = onMessage;
        this.polling = polling;
        this.access = access;
        this.isWsEnabled = isWsEnabled;
    }
};
export { WebSocketSubscription as default };
