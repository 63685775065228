import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import CasinoLoyaltyProgramIcon from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import CasinoLoyaltyProgramLevel from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramLevel/CasinoLoyaltyProgramLevel.vue';
import { CasinoLoyaltyProgramCardSizes, CasinoLoyaltyProgramIconSizes, CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';
import { useCasinoLoyaltyProgramCard } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramCard',
    props: {
        title: {},
        imageSrc: {},
        currentValue: {
            default: 0
        },
        total: {
            default: 0
        },
        cardSize: {
            default: CasinoLoyaltyProgramCardSizes.SIZE_124
        },
        levelStatus: {
            default: CasinoLoyaltyProgramLevelStatus.SUCCESS
        },
        image: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isInactive, clickCard } = useCasinoLoyaltyProgramCard(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-loyalty-program-card']]: true,
                    [_ctx.$style[`casino-loyalty-program-card--${_ctx.cardSize}`]]: _ctx.cardSize !== _unref(CasinoLoyaltyProgramCardSizes).SIZE_124
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(clickCard) && _unref(clickCard)(...args);
                })
            }, [
                _createVNode(CasinoLoyaltyProgramIcon, {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-card__icon']),
                    "image-src": _ctx.imageSrc,
                    image: _ctx.image,
                    size: _unref(CasinoLoyaltyProgramIconSizes).SIZE_92,
                    "is-inactive": _unref(isInactive)
                }, null, 8, [
                    "class",
                    "image-src",
                    "image",
                    "size",
                    "is-inactive"
                ]),
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-card__title'])
                }, _toDisplayString(_ctx.title), 3),
                _createVNode(CasinoLoyaltyProgramLevel, {
                    status: _ctx.levelStatus
                }, null, 8, [
                    "status"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramCard'
                ]
            ]);
        };
    }
});
