import { IconName, IconSize } from '@leon-hub/icons';
export default function useReferralProgramPopupReward() {
    const iconProps = {
        size: IconSize.SIZE_40,
        name: IconName.PROMOS
    };
    return {
        iconProps
    };
}
