import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VListItemExtended from 'web/src/components/List/VListItemExtended/VListItemExtended.vue';
import ListItemExtendedKind from 'web/src/components/List/VListItemExtended/enums/ListItemExtendedKind';
import { usePaymentsListItem } from 'web/src/modules/payments/components/PaymentsList/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsListItem',
    props: {
        paymentSystem: {},
        enrollmentTimeInHint: {
            type: Boolean
        },
        isWithdrawals: {
            type: Boolean
        },
        groupIcon: {},
        groupTitle: {},
        groupDescription: {},
        hideUnavailableMessage: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { logoLocation, hintMessage } = usePaymentsListItem(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VListItemExtended, {
                class: _normalizeClass(_ctx.$style['payments-list-item']),
                "is-disabled": _ctx.paymentSystem.disabled ?? false,
                "logo-name": _ctx.paymentSystem.logoName,
                "logo-src": _unref(logoLocation),
                title: _ctx.paymentSystem.name,
                "sub-title-first": _unref(hintMessage),
                "test-key": _ctx.paymentSystem.id,
                kind: _unref(ListItemExtendedKind).LOGO,
                "hide-unavailable-message": _ctx.hideUnavailableMessage,
                onClick: _cache[0] || (_cache[0] = ($event)=>emits('click'))
            }, null, 8, [
                "class",
                "is-disabled",
                "logo-name",
                "logo-src",
                "title",
                "sub-title-first",
                "test-key",
                "kind",
                "hide-unavailable-message"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentsListItem'
                ]
            ]);
        };
    }
});
