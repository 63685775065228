import { isArray } from '@leon-hub/guards';
import { isFormDataValue, isPartialFormPhoneValue } from 'web/src/components/Form/guards';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fillSubmittedData = (formToFill, submittedData, availableToFillFields)=>{
    if (null === submittedData || !formToFill?.fields || !availableToFillFields) return;
    const { fields } = formToFill;
    for (const fieldType of availableToFillFields)if (fieldType in fields && fieldType in submittedData) {
        const submittedValue = submittedData[fieldType];
        const uiSchemaField = fields[fieldType];
        if ('default' in uiSchemaField && isFormDataValue(submittedValue) && !isArray(submittedValue)) {
            uiSchemaField.default = submittedValue;
            if (isPartialFormPhoneValue(submittedValue)) {
                fields.prefix = {
                    default: submittedValue.prefix,
                    ...submittedValue
                };
                fields.suffix = {
                    default: submittedValue.suffix,
                    ...submittedValue
                };
            }
        }
    }
};
export default fillSubmittedData;
