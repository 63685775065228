import { defineStore } from 'pinia';
import { readonly, ref } from 'vue';
export const useRegistrationGeneratedPassword = defineStore('registration-generated-password', ()=>{
    const generatedPassword = ref('');
    return {
        value: readonly(generatedPassword),
        set (password) {
            generatedPassword.value = password;
        }
    };
});
