import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import { computed, ref, toRef } from 'vue';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { useIsEventExtended } from 'web/src/modules/sportline/submodules/extended-event/composbles';
import { useExpandableSportlineEventBlock } from 'web/src/modules/sportline/views/EventBlock/composables';
import { useSportlineEventLink } from 'web/src/modules/sportline/composables/list';
import SportlineEventFavoriteToggle from 'web/src/modules/sportline/views/FavoriteToggle/SportlineEventFavoriteToggle.vue';
import SportEventListItemExtendedMarkets from 'web/src/modules/sportline/views/EventBlock/SportEventListItemExtendedMarkets.vue';
import ResizeObserver from 'web/src/components/ResizeObserver/ResizeObserver.vue';
import SportlineEventChampionshipMainInfo from './SportlineEventChampionshipMainInfo.vue';
import SportlineEventCompetitorsMainInfo from './SportlineEventCompetitorsMainInfo.vue';
import SportlineEventLiveMetaInfo from './SportlineEventLiveMetaInfo.vue';
import SportlineEventPrematchMetaInfo from './SportlineEventPrematchMetaInfo.vue';
import SportlineEventMainMarket from './SportlineEventMainMarket.vue';
import SportlineOutrightEventMainMarket from './SportlineOutrightEventMainMarket.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventBlock',
    props: {
        sportEventElement: {},
        basisKey: {},
        isExtendedEventViewDisabled: {
            type: Boolean
        },
        extendedBlockType: {},
        sport: {},
        upcomingLabelType: {},
        isShownLiveIndicator: {
            type: Boolean
        },
        canShowLogos: {
            type: Boolean
        },
        activeEventId: {},
        canAddToFavorite: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "resize"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const container = ref();
        const extendElement = ref();
        const sportlineEventElement = toRef(props, 'sportEventElement');
        const activeEventId = toRef(props, 'activeEventId', null);
        const isExtendedEventViewDisabled = toRef(props, 'isExtendedEventViewDisabled', false);
        const canShowLogos = toRef(props, 'canShowLogos', false);
        const canAddToFavorite = toRef(props, 'canAddToFavorite', false);
        const isShownLiveIndicator = toRef(props, 'isShownLiveIndicator', false);
        const extendedBlockType = toRef(props, 'extendedBlockType');
        const upcomingLabelType = toRef(props, 'upcomingLabelType');
        const sport = toRef(props, 'sport');
        const basisKey = toRef(props, 'basisKey');
        const sportEvent = computed(()=>sportlineEventElement.value.sportEvent);
        const sportlineEventId = computed(()=>sportEvent.value.id);
        const { sportlineType, isLive, isPrematch, isOutright } = useSportlineEventType({
            sportEvent
        });
        const { isEventExtended } = useIsEventExtended({
            sportlineEventId,
            blockType: extendedBlockType
        });
        const { isExtendedSportEventAllowed, beforeExtendedOpen, afterExtendedOpen, afterExtendedClose } = useExpandableSportlineEventBlock({
            container,
            isExtendedEventViewDisabled
        });
        const { onCatalogClick } = useSportlineCatalogClick({
            eventId: sportlineEventId
        });
        const { href, onLinkClick } = useSportlineEventLink({
            sportEvent,
            activeEventId
        }, {
            onClick () {
                emit('click');
            }
        });
        function onExtendElement() {
            extendElement.value?.open();
        }
        function onCollapseElement() {
            extendElement.value?.close();
        }
        // data-test is used for LEONWEB-6045
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(ResizeObserver, {
                class: _normalizeClass(_ctx.$style['sportline-event-block']),
                disabled: !_unref(isEventExtended),
                onResize: _cache[3] || (_cache[3] = ($event)=>emit('resize', {
                        ...$event,
                        sportlineEventId: sportlineEventId.value
                    }))
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            ref_key: "container",
                            ref: container,
                            class: _normalizeClass(_ctx.$style['sportline-event-block-container'])
                        }, [
                            canAddToFavorite.value ? (_openBlock(), _createBlock(SportlineEventFavoriteToggle, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['sportline-event-block-container__favorite']),
                                "sportline-event-id": sportlineEventId.value,
                                "sportline-event-type": _unref(sportlineType),
                                "is-colored": ""
                            }, null, 8, [
                                "class",
                                "sportline-event-id",
                                "sportline-event-type"
                            ])) : _createCommentVNode("", true),
                            _createElementVNode("a", {
                                href: _unref(href),
                                class: _normalizeClass([
                                    _ctx.$style['sportline-event-info'],
                                    _ctx.$style['sportline-event-block-container__info']
                                ]),
                                onClick: _cache[0] || (_cache[0] = ($event)=>{
                                    _unref(onCatalogClick)($event);
                                    _unref(onLinkClick)($event);
                                })
                            }, [
                                _unref(isOutright) ? (_openBlock(), _createBlock(SportlineEventChampionshipMainInfo, {
                                    key: 0,
                                    "sportline-event": sportEvent.value
                                }, null, 8, [
                                    "sportline-event"
                                ])) : (_openBlock(), _createBlock(SportlineEventCompetitorsMainInfo, {
                                    key: 1,
                                    "sportline-event": sportEvent.value,
                                    sport: sport.value,
                                    "can-show-logos": canShowLogos.value
                                }, null, 8, [
                                    "sportline-event",
                                    "sport",
                                    "can-show-logos"
                                ])),
                                _unref(isLive) ? (_openBlock(), _createBlock(SportlineEventLiveMetaInfo, {
                                    key: 2,
                                    "sportline-event": sportEvent.value,
                                    "do-show-live-indicator": isShownLiveIndicator.value || _unref(isOutright)
                                }, null, 8, [
                                    "sportline-event",
                                    "do-show-live-indicator"
                                ])) : (_openBlock(), _createBlock(SportlineEventPrematchMetaInfo, {
                                    key: 3,
                                    "sportline-event": sportEvent.value,
                                    "upcoming-label-type": upcomingLabelType.value
                                }, null, 8, [
                                    "sportline-event",
                                    "upcoming-label-type"
                                ]))
                            ], 10, _hoisted_1),
                            _unref(isOutright) ? (_openBlock(), _createBlock(SportlineOutrightEventMainMarket, {
                                key: 1,
                                "sportline-event": sportEvent.value,
                                sport: sport.value,
                                "active-event-id": activeEventId.value,
                                "basis-key": basisKey.value,
                                onClick: _cache[1] || (_cache[1] = ($event)=>emit('click'))
                            }, null, 8, [
                                "sportline-event",
                                "sport",
                                "active-event-id",
                                "basis-key"
                            ])) : (_openBlock(), _createBlock(SportlineEventMainMarket, {
                                key: 2,
                                "sportline-event": sportEvent.value,
                                sport: sport.value,
                                "active-event-id": activeEventId.value,
                                "is-extended": _unref(isEventExtended),
                                "is-extended-sport-event-allowed": _unref(isExtendedSportEventAllowed),
                                "basis-key": basisKey.value,
                                onClick: _cache[2] || (_cache[2] = ($event)=>emit('click')),
                                onExtendElement: onExtendElement,
                                onCollapseElement: onCollapseElement
                            }, null, 8, [
                                "sportline-event",
                                "sport",
                                "active-event-id",
                                "is-extended",
                                "is-extended-sport-event-allowed",
                                "basis-key"
                            ]))
                        ], 2),
                        _unref(isExtendedSportEventAllowed) ? (_openBlock(), _createBlock(SportEventListItemExtendedMarkets, {
                            key: 0,
                            ref_key: "extendElement",
                            ref: extendElement,
                            "sport-event-id": sportlineEventId.value,
                            "extended-block-type": extendedBlockType.value,
                            onBeforeOpen: _unref(beforeExtendedOpen),
                            onAfterOpen: _unref(afterExtendedOpen),
                            onAfterClose: _unref(afterExtendedClose)
                        }, null, 8, [
                            "sport-event-id",
                            "extended-block-type",
                            "onBeforeOpen",
                            "onAfterOpen",
                            "onAfterClose"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "class",
                "disabled"
            ])), [
                [
                    _directive_auto_id,
                    'SportlineEventBlock'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'sportline-event-block',
                        live: _unref(isLive),
                        prematch: _unref(isPrematch),
                        outright: _unref(isOutright)
                    }
                ]
            ]);
        };
    }
});
