import getBaseHeaders from '@leon-hub/api/src/client/getBaseHeaders';
import ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';
function getCasHeaders() {
    const baseHeaders = getBaseHeaders();
    return Object.keys(baseHeaders).reduce((accumulator, headerName)=>{
        if ([
            'X-APP-PLATFORM',
            'X-APP-LAYOUT',
            'X-APP-OS',
            'X-APP-STORE'
        ].includes(headerName)) accumulator[headerName] = baseHeaders[headerName] || '';
        return accumulator;
    }, {});
}
let CasRestApiClient = class CasRestApiClient extends ApplicationRestClient {
    constructor(){
        super({
            baseUrl: '',
            method: 'GET',
            headers: getCasHeaders(),
            origin: ''
        });
    }
};
export { CasRestApiClient as default };
