import { defineStore } from 'pinia';
import { computed } from 'vue';
import { NavigationGroup } from '@leon-hub/navigation-config';
import { useFilterNavigationItems } from 'web/src/modules/core/store/composables';
// @TODO remove Readonly from computed
const useNavigationItemsStore = defineStore('navigation-items', ()=>{
    const { getNavigationItems } = useFilterNavigationItems();
    const topNavigationItems = computed(()=>[
            ...getNavigationItems(NavigationGroup.TOP_NAVIGATION)
        ]);
    const tabBarItems = computed(()=>getNavigationItems(NavigationGroup.BOTTOM_NAVIGATION));
    const tabBarItemsShort = computed(()=>getNavigationItems(NavigationGroup.BOTTOM_NAVIGATION_SHORT));
    const sidebarLeftTabNavigation = computed(()=>getNavigationItems(NavigationGroup.SIDEBAR_LEFT_TAB_NAVIGATION));
    const sidebarLeftNavigation = computed(()=>getNavigationItems(NavigationGroup.SIDEBAR_LEFT_NAVIGATION));
    const promotionCategoriesNavigationItems = computed(()=>getNavigationItems(NavigationGroup.PROMOTION_CATEGORIES_NAVIGATION));
    const bootstrapNavigationItems = computed(()=>getNavigationItems(NavigationGroup.BOOTSTRAP_NAVIGATION));
    const genericInfoNavigationItems = computed(()=>getNavigationItems(NavigationGroup.GENERIC_INFO_NAVIGATION));
    const affiliateNavigation = computed(()=>getNavigationItems(NavigationGroup.AFFILIATE_PROGRAM_NAVIGATION));
    const error404NavigationItems = computed(()=>getNavigationItems(NavigationGroup.ERROR_404_NAVIGATION));
    const affiliateFooterNavigationItem = computed(()=>getNavigationItems(NavigationGroup.AFFILIATE_PROGRAM_FOOTER_NAVIGATION)[0]);
    const telegramBotNavigationItem = computed(()=>getNavigationItems(NavigationGroup.TELEGRAM_BOT_NAVIGATION)[0]);
    const showcaseItems = computed(()=>getNavigationItems(NavigationGroup.TOP_SHOWCASE_NAVIGATION));
    const responsibleGamblingItems = computed(()=>getNavigationItems(NavigationGroup.RESPONSIBLE_GAMBLING_NAVIGATION));
    const sidebarLeftTopItems = computed(()=>getNavigationItems(NavigationGroup.SIDEBAR_LEFT_TOP_NAVIGATION));
    const sidebarLeftMiddleItems = computed(()=>getNavigationItems(NavigationGroup.SIDEBAR_LEFT_MIDDLE_NAVIGATION));
    const devtoolsItems = computed(()=>getNavigationItems(NavigationGroup.DEVTOOLS_NAVIGATION));
    return {
        topNavigationItems,
        tabBarItems,
        tabBarItemsShort,
        sidebarLeftTabNavigation,
        promotionCategoriesNavigationItems,
        bootstrapNavigationItems,
        genericInfoNavigationItems,
        affiliateNavigation,
        error404NavigationItems,
        affiliateFooterNavigationItem,
        telegramBotNavigationItem,
        showcaseItems,
        responsibleGamblingItems,
        sidebarLeftNavigation,
        sidebarLeftTopItems,
        sidebarLeftMiddleItems,
        devtoolsItems
    };
});
export default useNavigationItemsStore;
