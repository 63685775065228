import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { CurrencyInput } from 'web/src/components/Input/components';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
import useSmartCurrencyInput from './useSmartCurrencyInput';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SmartCurrencyInput',
    props: {
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {},
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {},
        autocomplete: {},
        maximumFractionDigits: {
            default: 2
        }
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { currencyRightSign, currencyLeftSign } = useSmartCurrencyInput();
        const { emitFocus, emitBlur, emitChange, emitInput } = useBaseInputEvents(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(CurrencyInput), {
                autofocus: _ctx.autofocus,
                name: _ctx.name,
                disabled: _ctx.disabled,
                label: _ctx.label,
                value: _ctx.value,
                placeholder: _ctx.placeholder,
                autocomplete: _ctx.autocomplete,
                "maximum-fraction-digits": _ctx.maximumFractionDigits,
                hint: _ctx.hint,
                "hint-right": _ctx.hintRight,
                "hint-link": _ctx.hintLink,
                "hint-link-right": _ctx.hintLinkRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                currency: _unref(currencyLeftSign),
                "currency-right": _unref(currencyRightSign),
                onInput: _unref(emitInput),
                onChange: _unref(emitChange),
                onFocus: _unref(emitFocus),
                onBlur: _unref(emitBlur)
            }, null, 8, [
                "autofocus",
                "name",
                "disabled",
                "label",
                "value",
                "placeholder",
                "autocomplete",
                "maximum-fraction-digits",
                "hint",
                "hint-right",
                "hint-link",
                "hint-link-right",
                "error",
                "error-right",
                "currency",
                "currency-right",
                "onInput",
                "onChange",
                "onFocus",
                "onBlur"
            ])), [
                [
                    _directive_auto_id,
                    'SmartCurrencyInput'
                ]
            ]);
        };
    }
});
