import { computed } from 'vue';
export function useSportlineEventCompetitors(props) {
    const { sportlineEvent } = props;
    const host = computed(()=>sportlineEvent.value?.competitors[0] ?? null);
    const guest = computed(()=>sportlineEvent.value?.competitors[1] ?? null);
    return {
        host,
        guest
    };
}
