import { isArray } from '@leon-hub/guards';
import getUiFieldRawOptions from './getUiFieldRawOptions';
import isStringEntryArray from './isStringEntryArray';
import decodeDefaultValue from './decodeDefaultValue';
/** should return base for FormUiSchemaFieldCommon */ export default function mapCommonFieldProperties(uiField, mappingKeys) {
    const result = {};
    for (const key of Object.keys(mappingKeys)){
        const uiFieldKey = mappingKeys[key];
        if (uiFieldKey) {
            const value = uiField[uiFieldKey];
            const valueIsArray = isArray(value);
            if (valueIsArray) {
                if (value.length) {
                    isStringEntryArray(value);
                    result[key] = getUiFieldRawOptions(value);
                }
            } else result[key] = value;
        }
    }
    return uiField.encrypted ? decodeDefaultValue(result) : result;
}
