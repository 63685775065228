import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { defaultFileTypes } from '../../utils/defaultFileTypes';
import FileSingleInputStateless from '../FileSingleInputStateless';
import { useMultipleFileInput } from '../../composables';
import useErrorsArray from './useErrorsArray';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FileMultipleInput',
    props: {
        error: {
            default: ()=>[]
        },
        errorRight: {
            default: ()=>[]
        },
        max: {},
        fileTypes: {
            default: ()=>defaultFileTypes
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'fileMultiple'
        },
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {}
    },
    emits: [
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { uniqueId, filesRecords, removeByIndex, onChange, onReplace, onBlur, onFocus, addMoreAvailable } = useMultipleFileInput(props, emits);
        const { anySingleError, anySingleErrorRight } = useErrorsArray(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("fieldset", {
                class: _normalizeClass(_ctx.$style['file-multiple-input'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filesRecords), (value, index)=>(_openBlock(), _createBlock(_unref(FileSingleInputStateless), {
                        key: index,
                        "input-id": `${_unref(uniqueId)}-${index}`,
                        name: value.name,
                        "file-name": value.name,
                        error: _ctx.error?.[index],
                        "error-right": _ctx.errorRight?.[index],
                        hint: _ctx.hint,
                        "hint-right": _ctx.hintRight,
                        "hint-link": _ctx.hintLink,
                        "hint-link-right": _ctx.hintLinkRight,
                        "is-large": _ctx.isLarge,
                        onChange: ($event)=>_unref(onReplace)($event, index),
                        onClear: ($event)=>_unref(removeByIndex)(index),
                        onFocus: ($event)=>_unref(onFocus)(index),
                        onBlur: ($event)=>_unref(onBlur)(index)
                    }, null, 8, [
                        "input-id",
                        "name",
                        "file-name",
                        "error",
                        "error-right",
                        "hint",
                        "hint-right",
                        "hint-link",
                        "hint-link-right",
                        "is-large",
                        "onChange",
                        "onClear",
                        "onFocus",
                        "onBlur"
                    ]))), 128)),
                _unref(addMoreAvailable) ? (_openBlock(), _createBlock(_unref(FileSingleInputStateless), {
                    key: 0,
                    autofocus: _ctx.autofocus,
                    "input-id": _unref(uniqueId),
                    placeholder: _ctx.placeholder,
                    error: _unref(filesRecords).length ? '' : _unref(anySingleError),
                    "error-right": _unref(filesRecords).length ? '' : _unref(anySingleErrorRight),
                    label: _ctx.label,
                    hint: _ctx.hint,
                    name: _ctx.name,
                    "is-large": _ctx.isLarge,
                    onChange: _unref(onChange),
                    onBlur: _cache[0] || (_cache[0] = ($event)=>_unref(onBlur)(_unref(filesRecords).length)),
                    onFocus: _cache[1] || (_cache[1] = ($event)=>_unref(onFocus)(_unref(filesRecords).length))
                }, null, 8, [
                    "autofocus",
                    "input-id",
                    "placeholder",
                    "error",
                    "error-right",
                    "label",
                    "hint",
                    "name",
                    "is-large",
                    "onChange"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'FileMultipleInput'
                ]
            ]);
        };
    }
});
