import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
import SwiperNavigationButtonsBase from 'web/src/components/Swiper/Navigation/SwiperNavigationButtonsBase/SwiperNavigationButtonsBase.vue';
import getNavigationProps from './utils/getNavigationProps';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SwiperNavigationButtons',
    props: {
        isRounded: {
            type: Boolean
        },
        isPreviousAllowed: {
            type: Boolean
        },
        isNextAllowed: {
            type: Boolean
        },
        buttonKind: {}
    },
    setup (__props) {
        const props = __props;
        const { swiperState, slideToPreviousSlide, slideToNextSlide } = useSwiperNavigation();
        const navigationProperties = computed(()=>getNavigationProps(props, swiperState));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SwiperNavigationButtonsBase, _mergeProps(navigationProperties.value, {
                onClickPrevious: _unref(slideToPreviousSlide),
                onClickNext: _unref(slideToNextSlide)
            }), null, 16, [
                "onClickPrevious",
                "onClickNext"
            ])), [
                [
                    _directive_auto_id,
                    'SwiperNavigationButtons'
                ]
            ]);
        };
    }
});
