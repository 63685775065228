import { defineComponent as _defineComponent } from 'vue';
import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useHead } from '@unhead/vue';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
import { assert, isOptionalString } from '@leon-hub/guards';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RedirectSeoRouteComponent',
    setup (__props) {
        const router = useRouter();
        const code = ref('301');
        const location = ref('/');
        onBeforeMount(()=>{
            const { redirectLocation, redirectCode } = window.history.state;
            isOptionalString(redirectLocation);
            isOptionalString(redirectCode);
            location.value = redirectLocation || '/';
            if (redirectCode && [
                '301',
                '302'
            ].includes(redirectCode)) code.value = redirectCode;
            if (!process.env.VUE_APP_PRERENDER) router.push(location.value);
        });
        const meta = computed(()=>({
                meta: [
                    {
                        name: 'prerender-status-code',
                        content: code.value
                    },
                    {
                        name: 'prerender-header',
                        content: `Location: ${getLocationOrigin()}${location.value}`
                    }
                ]
            }));
        useHead(meta);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
                _createTextVNode("Redirecting...")
            ]))), [
                [
                    _directive_auto_id,
                    'RedirectSeoRouteComponent'
                ]
            ]);
        };
    }
});
