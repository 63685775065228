import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useConnectionError } from './composables';
import BaseErrorContent from '../BaseErrorContent/BaseErrorContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ConnectionError',
    setup (__props) {
        const { errorProps } = useConnectionError();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(BaseErrorContent, _normalizeProps(_guardReactiveProps(_unref(errorProps))), null, 16)), [
                [
                    _directive_auto_id,
                    'ConnectionError'
                ]
            ]);
        };
    }
});
