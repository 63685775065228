import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots } from "vue";
import { toRef } from 'vue';
import { VIcon } from '@components/v-icon';
import { useFavoriteRegionFilterSwiper } from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/composables';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { getRegionCountryCode, getCyberSportIconName } from 'web/src/modules/sportline/utils/theme';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineFavoriteRegionsFilter',
    props: {
        activeTabIndex: {},
        favoriteRegionsTabs: {},
        selectedRegionFamily: {}
    },
    setup (__props) {
        const props = __props;
        const activeTabIndex = toRef(props, 'activeTabIndex');
        const { swiper, slideToActive } = useFavoriteRegionFilterSwiper({
            selectedIndex: activeTabIndex
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.favoriteRegionsTabs.length > 0 ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['favorite-regions'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['favorite-regions-title'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['favorite-regions-title__label'])
                    }, _toDisplayString(_ctx.$t('WEB2_FAVORITE_COUNTRIES')), 3)
                ], 2),
                _createVNode(_unref(VSwiper), {
                    ref_key: "swiper",
                    ref: swiper,
                    "is-scroll-snap-enabled": "",
                    class: _normalizeClass(_ctx.$style['favorite-regions-list']),
                    onVnodeMounted: _cache[0] || (_cache[0] = ($event)=>_unref(slideToActive)(false))
                }, _createSlots({
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.favoriteRegionsTabs, (item, index)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                    key: item.key,
                                    class: _normalizeClass(_ctx.$style['favorite-regions-list__slide']),
                                    onClick: ($event)=>item.onClick(item, index)
                                }, {
                                    default: _withCtx(()=>[
                                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                                class: _normalizeClass({
                                                    [_ctx.$style['favorite-regions-list__item']]: true,
                                                    [_ctx.$style['favorite-regions-list__item--active']]: item.isActive
                                                })
                                            }, [
                                                item.region ? (_openBlock(), _createElementBlock(_Fragment, {
                                                    key: 0
                                                }, [
                                                    item.isCyberSport ? (_openBlock(), _createBlock(_unref(VIcon), {
                                                        key: 0,
                                                        name: _unref(getCyberSportIconName)(item.region.representation),
                                                        class: _normalizeClass(_ctx.$style['favorite-regions-list__icon'])
                                                    }, null, 8, [
                                                        "name",
                                                        "class"
                                                    ])) : (_openBlock(), _createBlock(_unref(VCountryFlag), {
                                                        key: 1,
                                                        code: _unref(getRegionCountryCode)(item.region),
                                                        class: _normalizeClass(_ctx.$style['favorite-regions-list__flag'])
                                                    }, null, 8, [
                                                        "code",
                                                        "class"
                                                    ]))
                                                ], 64)) : _createCommentVNode("", true),
                                                _createTextVNode(" " + _toDisplayString(item.title), 1)
                                            ], 2)), [
                                                [
                                                    _directive_data_test,
                                                    {
                                                        el: 'favorite-regions-filter',
                                                        active: item.isActive
                                                    }
                                                ]
                                            ])
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "class",
                                    "onClick"
                                ]))), 128))
                        ]),
                    _: 2
                }, [
                    {
                        name: "pagination-footer",
                        fn: _withCtx(()=>[
                                _createVNode(SwiperNavigationButtons)
                            ]),
                        key: "0"
                    }
                ]), 1032, [
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineFavoriteRegionsFilter'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
