import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useParseSportlineSettingsRef, useIsZeroMarginEnabledRef } from 'web/src/modules/sportline/composables/settings';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useTopZeroMarginSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
export const useSportlineTopZeroMarginStore = defineStore('sportline-top-zero-margin', ()=>{
    const apiService = useSportlineApiService();
    const broadcastSelected = useBroadcastSelected();
    const isZeroMarginEnabled = useIsZeroMarginEnabledRef();
    const parseSportlineSettings = useParseSportlineSettingsRef();
    const { timeout: updateInterval } = useTopZeroMarginSportlineUpdateTimeout();
    // @TODO get the limit from the site config
    const topZeroMarginLoadLimit = 5;
    const rawEventsResponse = ref(null);
    const vTag = computed(()=>rawEventsResponse.value ? rawEventsResponse.value.vtag : void 0);
    const rawZeroMarginEventsList = computed(()=>isZeroMarginEnabled.value && rawEventsResponse.value ? new SportlineFactory(rawEventsResponse.value, parseSportlineSettings.value).build() : null);
    const zeroMarginEventsList = computed(()=>rawZeroMarginEventsList.value ?? []);
    const isReady = computed(()=>{
        if (!isZeroMarginEnabled.value) return true;
        return null !== rawEventsResponse.value;
    });
    const lastUpdate = new BackgroundUpdateStopwatch({
        zeroMarginEvents: async (param)=>{
            let { silent } = param;
            if (!isZeroMarginEnabled.value) return;
            const response = await apiService.loadZeroMarginEvents({
                vTag: vTag.value,
                limit: topZeroMarginLoadLimit,
                silent
            });
            rawEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
            broadcastSelected.updateDataInStorageByResponse({
                response
            });
            lastUpdate.update('zeroMarginEvents');
        }
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval
    });
    useSyncState((silent)=>syncState({
            silent
        }), updateInterval);
    return {
        isReady,
        zeroMarginEventsList,
        rawZeroMarginEventsList,
        async initialRequests () {
            if (!isZeroMarginEnabled.value) return;
            await initialRequests();
        },
        async setBackgroundUpdateEnabled (value) {
            if (!isZeroMarginEnabled.value) return;
            await setBackgroundUpdateEnabled(value);
        },
        async syncBackgroundRequests () {
            await syncBackgroundRequests({
                silent: true
            });
        }
    };
});
