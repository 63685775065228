import { matchSportFamily } from './matchSportFamily';
export function buildSportlineEventNavigationParameters(options) {
    const { sportFamilyMapping, sportEventResponse, leagueResponse, regionResponse, sportResponse } = options;
    return {
        id: String(sportEventResponse.id),
        urlName: sportEventResponse.url,
        leagueUrlName: leagueResponse.url,
        regionUrlName: regionResponse.url,
        sportFamily: matchSportFamily(sportResponse, sportFamilyMapping)
    };
}
