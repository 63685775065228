import { computed, nextTick, ref, watch } from 'vue';
import { useIntersectionObserver, useLifecycleResizeObserver } from '@leon-hub/vue-utils';
import { useDebounce } from '@leon-hub/debounce';
import { useWindowResize } from '@leon-hub/browser-composables';
import { nextAnimationFrame } from '@leon-hub/html-utils';
export default function useVSwitcher(props) {
    const grid = ref();
    const innerActiveIndex = ref(0);
    const activeSlideTranslate = ref(0);
    const activeSlideWidth = ref(0);
    const positionCalculated = ref(false);
    const isTransitionAvailable = ref(false);
    function setActiveIndex(activeId) {
        if (!activeId) return;
        const index = props?.options?.findIndex((item)=>activeId === item.id) || -1;
        innerActiveIndex.value = index > -1 ? index : 0;
    }
    const selectionStyle = computed(()=>({
            transform: `translateX(${activeSlideTranslate.value}px)`,
            transition: isTransitionAvailable.value ? 'transform 0.2s ease' : void 0,
            width: activeSlideWidth.value ? `${activeSlideWidth.value}px` : void 0
        }));
    watch(()=>props.activeId, (newValue)=>{
        setActiveIndex(newValue);
        calculateActiveOffsetWidth();
    });
    watch(()=>props.options, async ()=>{
        await nextTick();
        calculateActiveOffsetWidth();
    }, {
        deep: true
    });
    const handleResize = useDebounce((param)=>{
        let { deltaX } = param;
        if (Math.abs(deltaX) > 0) calculateActiveOffsetWidth();
    }, 100);
    function calculateActiveOffsetWidth() {
        const slides = grid.value?.children;
        if (slides?.[innerActiveIndex.value]) {
            const activeSlideElement = slides[innerActiveIndex.value];
            if (activeSlideElement && activeSlideElement instanceof HTMLElement) {
                activeSlideTranslate.value = activeSlideElement.offsetLeft;
                activeSlideWidth.value = activeSlideElement.offsetWidth;
                positionCalculated.value = true;
            }
        } else {
            activeSlideTranslate.value = 0;
            activeSlideWidth.value = 0;
        }
    }
    async function beforeMount() {
        setActiveIndex(props.activeId);
        calculateActiveOffsetWidth();
        await nextAnimationFrame();
        calculateActiveOffsetWidth();
    }
    useLifecycleResizeObserver(grid, calculateActiveOffsetWidth);
    useWindowResize(handleResize);
    useIntersectionObserver(grid, async (isIntersecting)=>{
        calculateActiveOffsetWidth();
        if (isIntersecting) {
            await nextAnimationFrame();
            isTransitionAvailable.value = true;
        }
    }, void 0, true);
    return {
        grid,
        innerActiveIndex,
        positionCalculated,
        beforeMount,
        calculateActiveOffsetWidth,
        selectionStyle
    };
}
