import { AbstractError, ErrorMessageUtils, normalizeError } from '@leon-hub/errors/src/internal';
import { AppError } from '@leon-hub/app-errors';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useErrorsConverter() {
    const { $translate } = useI18n();
    const defaultMessage = $translate('WEB2_TECHNICAL_ERROR');
    function convertToBaseError(error, message) {
        if (error instanceof AbstractError) {
            if (ErrorMessageUtils.isBtrKey(error.message)) // eslint-disable-next-line no-param-reassign
            error.message = $translate(ErrorMessageUtils.normalizeBtrKey(error.message)).value;
            return error;
        }
        return new AppError({
            message: message || defaultMessage.value,
            originalError: normalizeError(error, message)
        });
    }
    return {
        convertToBaseError
    };
}
