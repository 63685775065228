import { getSportEventElementsListCounters, reduceListWithSportEventsCounters } from '../getCounters';
export function filterRegionElementEvents(regionElement, filter) {
    const leagues = regionElement.leagues.map((leagueElement)=>{
        const sportEvents = leagueElement.sportEvents.filter((sportEvent)=>filter(sportEvent));
        const outrightEvents = leagueElement.outrightEvents.filter((sportEvent)=>filter(sportEvent));
        return {
            ...leagueElement,
            sportEvents,
            outrightEvents,
            counters: getSportEventElementsListCounters([
                ...sportEvents,
                ...outrightEvents
            ])
        };
    }).filter((leagueElement)=>leagueElement.sportEvents.length > 0 || leagueElement.outrightEvents.length > 0);
    return {
        ...regionElement,
        leagues,
        counters: reduceListWithSportEventsCounters(leagues)
    };
}
export function filterRegionsListEvents(regionElements, filter) {
    return regionElements.map((regionElement)=>filterRegionElementEvents(regionElement, filter)).filter((regionElement)=>regionElement.leagues.length > 0);
}
export function filterEmptyRegionElements(regionElements) {
    return regionElements.filter((regionElement)=>regionElement.leagues.some((leagueElement)=>leagueElement.sportEvents.length > 0 || leagueElement.outrightEvents.length > 0));
}
