import { computed, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { useSeparateHeadlineEventsLists } from 'web/src/modules/sportline/submodules/top/composables';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { SportlineHomeBlockPriority } from 'web/src/modules/home/submodules/sportline/enums';
import { resolveLivePageLink, resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useSportlineEventsBlock(props) {
    const { priority } = props;
    const { $translate } = useI18n();
    const { isLoggedIn } = useIsLoggedIn();
    const favoritesEventsStore = useFavoritesEventsStore();
    const favoritesIdsList = toRef(favoritesEventsStore, 'favoritesIdsList');
    const { totalLiveCount, totalPrematchCount, liveHeadlineElements, prematchHeadlineElements } = useSeparateHeadlineEventsLists();
    const hasLiveSportEvents = computed(()=>liveHeadlineElements.value.length > 0);
    const hasPrematchSportEvents = computed(()=>prematchHeadlineElements.value.length > 0);
    const isLiveBlock = computed(()=>priority.value === SportlineHomeBlockPriority.Live && hasLiveSportEvents.value);
    const isPrematchBlock = computed(()=>{
        const isPrematchBlockWithLive = priority.value === SportlineHomeBlockPriority.Prematch && hasLiveSportEvents.value && hasPrematchSportEvents.value;
        const isLiveBlockWithoutLive = priority.value === SportlineHomeBlockPriority.Live && !hasLiveSportEvents.value;
        return isPrematchBlockWithLive || isLiveBlockWithoutLive;
    });
    const totalCount = computed(()=>{
        if (isLiveBlock.value) return totalLiveCount.value;
        if (isPrematchBlock.value) return totalPrematchCount.value;
        return 0;
    });
    const title = computed(()=>{
        if (isLiveBlock.value) return $translate('WEB2_LIVE_SPORTLINE').value;
        if (isPrematchBlock.value) return $translate('WEB2_PREMATCH_SPORTLINE').value;
        return null;
    });
    const buttonTitle = computed(()=>{
        if (isLiveBlock.value) return `${$translate('WEB2_HOME_ALL_LIVE_BUTTON').value} (${totalCount.value})`;
        if (isPrematchBlock.value) return `${$translate('WEB2_HOME_ALL_PREMATCH_BUTTON').value} (${totalCount.value})`;
        return null;
    });
    const sportElements = computed(()=>{
        if (isLiveBlock.value) return liveHeadlineElements.value;
        if (isPrematchBlock.value) return prematchHeadlineElements.value;
        return [];
    });
    const secondaryButton = computed(()=>{
        if (!isLiveBlock.value) return null;
        if (!isLoggedIn.value) return null;
        const count = favoritesIdsList.value?.length;
        if (!count) return null;
        return {
            text: `(${count})`,
            to: resolveLivePageLink({
                urlName: CustomFilter.Favorites
            }),
            icon: {
                name: IconName.STAR_ALT
            }
        };
    });
    const pageLocation = computed(()=>{
        if (isLiveBlock.value) return resolveLivePageLink({});
        if (isPrematchBlock.value) return resolveSportsPageLink({});
        return null;
    });
    return {
        isLiveBlock,
        isPrematchBlock,
        hasLiveSportEvents,
        hasPrematchSportEvents,
        totalCount,
        title,
        buttonTitle,
        sportElements,
        secondaryButton,
        pageLocation
    };
}
