import { computed, toRef } from 'vue';
export function useSportlineEventMarketsPriority(props) {
    const { sportEvent } = props;
    const isPrimaryMarketFiltrationDisabled = toRef(props.isPrimaryMarketFiltrationDisabled ?? false);
    const markets = computed(()=>sportEvent.value?.markets ?? []);
    const primaryMarketIndex = computed(()=>{
        if (isPrimaryMarketFiltrationDisabled.value) return null;
        const index = markets.value.findIndex((market)=>market.isPrimary);
        return index > -1 ? index : null;
    });
    const primaryMarket = computed(()=>{
        const index = primaryMarketIndex.value;
        if (null === index) return null;
        return markets.value[index] || null;
    });
    const secondaryMarkets = computed(()=>{
        const index = primaryMarketIndex.value;
        if (null === index) return markets.value;
        const result = [
            ...markets.value
        ];
        result.splice(index, 1);
        return result;
    });
    return {
        markets,
        primaryMarketIndex,
        primaryMarket,
        secondaryMarkets
    };
}
