import convertCommonClassesToCssModule from '../../../utils/convertCommonClassesToCssModule';
import { ListItemAccordionKind } from '../../enums';
export default function getClassObject(props, isOpen) {
    return convertCommonClassesToCssModule({
        'list-item-accordion': true,
        'list-item-accordion--open': isOpen,
        'list-item-accordion--have-switcher': !!props.haveSwitcher,
        [`list-item-accordion--kind-${props.type}`]: props.type !== ListItemAccordionKind.DEFAULT,
        'list-item-accordion--done': props.type === ListItemAccordionKind.PROMO && !!props.isDone,
        'list-item-accordion--no-background': !!props.noBackground,
        'list-item-accordion--big-margin': !!props.isBigMargin
    });
}
