import { computed, ref, useSlots } from 'vue';
import { Tag } from '@leon-hub/tags';
import { IconName, IconSize } from '@leon-hub/icons';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';
import getClassObject from './utils/getClassObject';
export default function useVListItemInner(props) {
    const listItemInnerRef = ref();
    const slots = useSlots();
    const havePrefix = computed(()=>!!props.iconName || hasSlot(slots, 'prefix'));
    const haveSuffix = computed(()=>!!props.badgeLabel || !!props.counter || !!props.suffixText || props.haveExpand || // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        props.haveSwitcher || // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        !!props.iconSuffixName || (props.progressValue || 0) > 0 || !!props.buttonLabel);
    function scrollIntoView() {
        listItemInnerRef.value?.scrollIntoView({
            behavior: 'smooth'
        });
    }
    return {
        tag: computed(()=>props.href ? Tag.A : Tag.DIV),
        badgeProperties: computed(()=>({
                label: props.badgeLabel,
                kind: BadgeKind.DEFAULT
            })),
        switchProperties: computed(()=>({
                name: '',
                checked: props.isSwitchChecked,
                disabled: props.isSwitchDisabled,
                kind: props.switchKind
            })),
        computedIconProperties: computed(()=>({
                size: IconSize.SIZE_20,
                name: props.iconName
            })),
        progressProperties: computed(()=>({
                circleSize: CircleSize.MINI,
                percentage: props.progressValue,
                iconSize: IconSize.SIZE_20
            })),
        haveSuffix,
        havePrefix,
        classObject: computed(()=>getClassObject(props, havePrefix.value, haveSuffix.value)),
        iconSuffixProperties: computed(()=>({
                name: props.haveExpand || (props?.progressValue || 0) > 0 ? IconName.EXPAND_RIGHT : props.iconSuffixName,
                size: IconSize.SIZE_16
            })),
        buttonProperties: computed(()=>({
                label: props.buttonLabel,
                kind: ButtonKind.CANCEL,
                height: ButtonHeight.TINY_EXTRA
            })),
        listItemInnerRef,
        scrollIntoView
    };
}
