import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "card"
};
const _hoisted_2 = {
    class: "card-title"
};
const _hoisted_3 = {
    key: 0,
    class: "card-content"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCard',
    props: {
        title: {
            default: ''
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
                _ctx.$slots.default ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "default")
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'VCard'
                ]
            ]);
        };
    }
});
