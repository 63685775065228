import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import { useRouter } from 'vue-router';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import routerLinkClickGuard from 'web/src/components/Link/utils/routerLinkClickGuard';
import { useVRouterLink } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VRouterLink',
    props: {
        to: {},
        replace: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        saveScrollPosition: {
            type: Boolean
        },
        isHiddenDecoration: {
            type: Boolean
        }
    },
    emits: [
        "before-navigate",
        "click",
        "mouseenter",
        "mouseleave"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { href, target } = useVRouterLink(props);
        const router = useRouter();
        const bus = useEventsBus();
        function clickHandler(mouseEvent) {
            if (!props.disabled) {
                if (routerLinkClickGuard(mouseEvent)) {
                    emitBeforeNavigate(mouseEvent, target.value);
                    if (props.replace) router.replace(target.value);
                    else if (router.currentRoute.value.fullPath === target.value.fullPath) bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {
                        smooth: true
                    });
                    else router.push(target.value, {
                        saveScrollPosition: props.saveScrollPosition
                    });
                }
                emitClick(mouseEvent);
            }
        }
        function emitBeforeNavigate(originalEvent, to) {
            emit('before-navigate', {
                originalEvent,
                to
            });
        }
        function emitClick(event) {
            emit('click', event);
        }
        function emitMouseEnter(event) {
            emit('mouseenter', event);
        }
        function emitMouseLeave(event) {
            emit('mouseleave', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: _unref(href),
                class: _normalizeClass({
                    [_ctx.$style['router-link--decoration-hidden']]: _ctx.isHiddenDecoration
                }),
                onClick: clickHandler,
                onMouseenter: emitMouseEnter,
                onMouseleave: emitMouseLeave
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 42, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VRouterLink'
                ]
            ]);
        };
    }
});
