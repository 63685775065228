import { computed, toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
export function useSportlineExpandableEventsListItem(props, emit) {
    const { eventId, isExtended, hasPrimaryMarket, isMoreRunnersExist, isExtendedSportEventAllowed } = props;
    const settingsStore = useSportlineSettingsStore();
    const isListMarketsDropDownEnabled = toRef(settingsStore, 'isListMarketsDropDownEnabled');
    const canShowMoreBlock = computed(()=>true);
    const isMoreRunnersAllowed = computed(()=>!!eventId.value && isMoreRunnersExist.value && canShowMoreBlock.value);
    const isExpandButtonAvailable = computed(()=>isMoreRunnersAllowed.value && !isExtended.value);
    const isCollapseButtonAvailable = computed(()=>isExtended.value);
    const isLockedMoreAvailable = computed(()=>!isMoreRunnersAllowed.value && !isExtended.value && canShowMoreBlock.value);
    function onMoreRunnersClick(mouseEvent) {
        if (isExtendedSportEventAllowed.value && isListMarketsDropDownEnabled.value) emit.extendElementOpen();
        else emit.onLinkClick(mouseEvent, false);
    }
    function onLessRunnersClick() {
        if (isExtendedSportEventAllowed.value) emit.extendElementClose();
    }
    return {
        isMoreRunnersAllowed,
        isExpandButtonAvailable,
        isCollapseButtonAvailable,
        isLockedMoreAvailable,
        onMoreRunnersClick,
        onLessRunnersClick
    };
}
