export function isGetHeadlineMatchesChangesResponseCheck(value) {
    if ('events' in value && !!value.events) return 'data' in value.events;
    if ('live' in value && !!value.live && 'prematch' in value && !!value.prematch) return 'data' in value.live && 'data' in value.prematch;
    throw new Error('Unexpected headline response');
}
export function isGetMergedHeadlineMatchesResponseCheck(value) {
    const eventsKey = 'events';
    return eventsKey in value && !!value[eventsKey];
}
export function isGetMergedHeadlineMatchesChangesResponseCheck(value) {
    const eventsKey = 'events';
    return eventsKey in value && !!value[eventsKey];
}
