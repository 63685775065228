import { ref } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { getBetlineLeagueStandings } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';
export default function useSportlineLeagueStandingsStatisticsStoreComposable(props) {
    const betlineLeagueStandings = ref(null);
    const setBetlineLeagueStandings = function(statistic) {
        betlineLeagueStandings.value = !!statistic && statistic.standings ? [
            ...statistic.standings
        ] : null;
    };
    const lastUpdate = new BackgroundUpdateStopwatch({
        standingsStatistic: async (param)=>{
            let { silent } = param;
            if (!props.isSportEventStatisticEnabled.value) return;
            try {
                const eventId = props.expectedStatisticId.value;
                if (!eventId) return;
                setBetlineLeagueStandings(await getBetlineLeagueStandings({
                    eventId
                }, {
                    silent
                }));
            } catch (error) {
                if (!isStatisticNotFoundError(error)) throw error;
                // we tried but got empty statistic for this ID
                setBetlineLeagueStandings(null);
            } finally{
                lastUpdate.update('standingsStatistic');
            }
        }
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: props.sportlineEventStatisticUpdateInterval
    });
    function isSyncStateWillBeSkipped() {
        if (!props.isSportEventStatisticEnabled.value) return true;
        // standing displayed only for live events
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const loadedSportEvent = props.currentLoadedMatchIdentifier.value || null;
        if (loadedSportEvent?.betline !== BetlineType.Live) return true;
        // sync standings only if we have one
        return !betlineLeagueStandings.value;
    }
    function onInit() {
        useSyncState(async (silent)=>{
            if (isSyncStateWillBeSkipped()) return;
            await syncState({
                silent
            });
        }, props.sportlineEventStatisticUpdateInterval);
    }
    return {
        initialRequests,
        onInit,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled,
        betlineLeagueStandings,
        setBetlineLeagueStandings,
        isSyncStateWillBeSkipped
    };
}
