import { ref, watch } from 'vue';
export default function useBonusCodeInput(props) {
    const isBonusCodeVisible = ref(!!props.value?.length || !!props.autofocus);
    const makeVisible = (relatedProp)=>{
        if (relatedProp) isBonusCodeVisible.value = true;
    };
    watch(()=>props.value, makeVisible);
    watch(()=>props.autofocus, makeVisible);
    return {
        isBonusCodeVisible,
        makeVisible
    };
}
