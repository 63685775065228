import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "viewBox"
];
const _hoisted_2 = [
    "cx",
    "cy",
    "r"
];
const _hoisted_3 = [
    "cx",
    "cy",
    "r"
];
import { VSpinnerSize, VSpinnerColor } from '../../enums';
import { useVSpinner } from '../../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LSpinner',
    props: {
        size: {
            default: VSpinnerSize.DEFAULT
        },
        color: {
            default: VSpinnerColor.DEFAULT
        },
        customSize: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { viewBox, circleSize } = useVSpinner(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass({
                    [_ctx.$style['spinner']]: true,
                    [_ctx.$style[`spinner--size_${_ctx.size}`]]: _ctx.size !== _unref(VSpinnerSize).DEFAULT,
                    [_ctx.$style[`spinner--color-${props.color}`]]: _ctx.color !== _unref(VSpinnerColor).DEFAULT,
                    [_ctx.$style['spinner--custom-size']]: _ctx.customSize
                }),
                viewBox: _unref(viewBox)
            }, [
                _createElementVNode("g", {
                    class: _normalizeClass(_ctx.$style['spinner__group'])
                }, [
                    _createElementVNode("circle", {
                        cx: _unref(circleSize),
                        cy: _unref(circleSize),
                        r: _unref(circleSize),
                        class: _normalizeClass({
                            [_ctx.$style['spinner__circle']]: true,
                            [_ctx.$style['spinner__circle--bg']]: true
                        })
                    }, null, 10, _hoisted_2),
                    _createElementVNode("circle", {
                        cx: _unref(circleSize),
                        cy: _unref(circleSize),
                        r: _unref(circleSize),
                        class: _normalizeClass({
                            [_ctx.$style['spinner__circle']]: true,
                            [_ctx.$style['spinner__circle--path']]: true
                        })
                    }, null, 10, _hoisted_3)
                ], 2)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'Spinner'
                ]
            ]);
        };
    }
});
