import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useActiveGameData } from 'web/src/modules/egs/store/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
const useQuickBetsStore = defineStore('quick-bets', ()=>{
    const { isLoggedIn } = useIsLoggedIn();
    const gamesStore = useEgsGamesStore();
    const { activeGame, startGameData, isStartGameLoading, startGame: startGameAction, clearStartGameData, resetActiveGame, reloadActiveGame, setActiveUrl, getGameById: getGameByIdApi } = useActiveGameData();
    let isDemo;
    isDemo = computed(()=>!isLoggedIn.value);
    async function startGame() {
        if (activeGame.value) await startGameAction({
            id: activeGame.value.id,
            isDemo: isDemo.value
        });
    }
    async function getGameById(id) {
        return gamesStore.getGameById(id) || getGameByIdApi(id);
    }
    return {
        isDemo,
        activeGame,
        startGameData,
        isStartGameLoading,
        startGame,
        clearStartGameData,
        resetActiveGame,
        reloadActiveGame,
        setActiveUrl,
        getGameById
    };
});
export default useQuickBetsStore;
