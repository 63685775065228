import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { useLobbyType } from 'web/src/modules/lobby/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
const useEgsCategoriesStore = defineStore('egs-categories', ()=>{
    const { lobbyType: currentLobbyType } = useLobbyType();
    const { getCategories } = useEgsApi();
    const { isLoggedIn } = useIsLoggedIn();
    const categoriesByType = {};
    const categoriesByGroupCache = {};
    const isLoading = ref(false);
    function createKey(type, groupId) {
        return `${type}-${groupId || ''}`;
    }
    function checkCategoriesExists(type, groupId) {
        const key = createKey(type, groupId);
        if (!categoriesByType[key]) {
            categoriesByType[key] = ref([]);
            return false;
        }
        return true;
    }
    function getCategoriesByGroupIdAndType(lobbyType, groupId) {
        const innerType = lobbyType || currentLobbyType.value;
        checkCategoriesExists(innerType, groupId);
        const key = createKey(innerType, groupId);
        if (!categoriesByGroupCache[key]) categoriesByGroupCache[key] = computed(()=>categoriesByType[key].value);
        return categoriesByGroupCache[key];
    }
    function getCurrentGroupsCategories(groupId) {
        return computed(()=>getCategoriesByGroupIdAndType(currentLobbyType.value, groupId).value);
    }
    function setCategories(type, groupId, categories) {
        checkCategoriesExists(type, groupId);
        const key = createKey(type, groupId);
        categoriesByType[key].value = categories;
    }
    async function loadCategories(groupId, lobbyType) {
        let silent = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
        const innerGroupId = groupId || '';
        const type = lobbyType ?? currentLobbyType.value;
        if (!silent) isLoading.value = true;
        try {
            const categories = await getCategories({
                type,
                group: groupId
            }, silent);
            setCategories(type, innerGroupId, categories);
        } finally{
            isLoading.value = false;
        }
    }
    watch(isLoggedIn, ()=>{
        for (const category of Object.values(categoriesByGroupCache))category.value = [];
        for (const category of Object.values(categoriesByType))category.value = [];
    });
    return {
        isLoading,
        loadCategories,
        getCurrentGroupsCategories,
        getCategoriesByGroupIdAndType
    };
});
export default useEgsCategoriesStore;
