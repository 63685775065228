export default function getFavoriteLeaguesFromSportList(sportList, customerLeaguesIds) {
    return sportList.map((sportElement)=>{
        const leaguesList = (sportElement.regions || []).flatMap((regionElement)=>{
            const leagues = (regionElement.leagues || []).filter((leagueElement)=>customerLeaguesIds.includes(leagueElement.league.id));
            return leagues.map((leagueElement)=>({
                    league: leagueElement.league,
                    region: regionElement.region,
                    counters: leagueElement.counters
                }));
        });
        return {
            sport: sportElement.sport,
            leaguesCount: sportElement.leaguesCount,
            leagues: leaguesList || []
        };
    }).filter((sportElement)=>sportElement.leagues.length > 0);
}
