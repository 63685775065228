import { computed, reactive } from 'vue';
export function createEventDetailsRefsMap() {
    // @TODO clear cache or get from the Global storage
    const extendedEventsMap = reactive({});
    return {
        setEventDetailsData (id, value) {
            extendedEventsMap[id] = value;
        },
        createEventDetailsRef (id) {
            return computed(()=>{
                const { value } = id;
                if (!value) return null;
                return extendedEventsMap[value] ?? null;
            });
        }
    };
}
