import { nextTick, onMounted, ref } from 'vue';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
export default function useVAnimationStarter(props) {
    const animationContainer = ref();
    const isAnimated = ref(false);
    let intersectionObserver;
    let previousY = 0;
    let previousRatio = 0;
    function handleIntersect(entries) {
        const target = entries[0];
        if (!target) return;
        const { isIntersecting } = target;
        const topBound = target.boundingClientRect.top;
        const currentY = target.boundingClientRect.y;
        const currentRatio = target.intersectionRatio;
        isAnimated.value = !(topBound > 0) || isIntersecting;
        if (currentY > previousY && currentRatio < previousRatio && !isIntersecting) isAnimated.value = true;
        previousY = currentY;
        previousRatio = currentRatio;
    }
    function observe() {
        if (!animationContainer.value) return;
        try {
            const options = {
                rootMargin: props.rootMargin,
                threshold: '' === props.rootMargin ? props.threshold : 0
            };
            intersectionObserver = new IntersectionObserver(handleIntersect, options);
            intersectionObserver.observe(animationContainer.value);
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(error);
        }
    }
    onMounted(()=>{
        nextTick(()=>{
            requestAnimationFrame(()=>{
                setTimeout(()=>{
                    observe();
                }, 1000);
            });
        });
    });
    return {
        animationContainer,
        isAnimated,
        observe
    };
}
