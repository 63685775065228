import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode, resolveDirective as _resolveDirective, createSlots as _createSlots } from "vue";
import { ref, toRefs, computed, defineAsyncComponent } from 'vue';
import { VIcon } from '@components/v-icon';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import VTabsButton from 'web/src/components/Tabs/VTabsButton/VTabsButton.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import { unitTestElement } from '../constants';
import useMarketsTabs from './composables/useMarketsTabs';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketsTabs',
    props: {
        hasStatistic: {
            type: Boolean
        },
        hasAllTab: {
            type: Boolean
        },
        groups: {},
        activeTabId: {}
    },
    emits: [
        "tab-click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const SwiperNavigationButtons = // eslint-disable-next-line max-len
        defineAsyncComponent(()=>import('web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue'));
        const tabsRef = ref();
        const { hasStatistic, hasAllTab, groups, activeTabId } = toRefs(props);
        const { tabs, slideToActive, onTabClick } = useMarketsTabs({
            hasStatistic,
            hasAllTab,
            groups,
            activeTabId,
            tabsRef
        }, emit);
        const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
        const isButtonLikeTabs = computed(()=>void 0);
        __expose({
            slideToActive
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VSwiper), {
                ref_key: "tabsRef",
                ref: tabsRef,
                class: _normalizeClass(_ctx.$style['markets-tabs'])
            }, _createSlots({
                default: _withCtx(()=>[
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabs), (item)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                key: item.id,
                                class: _normalizeClass(_ctx.$style['markets-tabs__slide'])
                            }, {
                                default: _withCtx(()=>[
                                        _withDirectives((_openBlock(), _createBlock(VTabsButton, {
                                            id: item.id,
                                            class: _normalizeClass(_ctx.$style['markets-tabs__button']),
                                            active: item.id === _unref(activeTabId),
                                            "is-bordered": !isButtonLikeTabs.value,
                                            version: 'default',
                                            onClick: ($event)=>_unref(onTabClick)(item.id)
                                        }, {
                                            default: _withCtx(()=>[
                                                    item.icon ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                                                        key: 0,
                                                        ref_for: true
                                                    }, item.icon), null, 16)) : (_openBlock(), _createElementBlock(_Fragment, {
                                                        key: 1
                                                    }, [
                                                        _createTextVNode(_toDisplayString(item.label), 1)
                                                    ], 64))
                                                ]),
                                            _: 2
                                        }, 1032, [
                                            "id",
                                            "class",
                                            "active",
                                            "is-bordered",
                                            "version",
                                            "onClick"
                                        ])), [
                                            [
                                                _unref(vDataTestUnit),
                                                {
                                                    el: _unref(unitTestElement).tabButton,
                                                    id: item.id
                                                }
                                            ]
                                        ])
                                    ]),
                                _: 2
                            }, 1032, [
                                "class"
                            ]))), 128))
                    ]),
                _: 2
            }, [
                {
                    name: "pagination-footer",
                    fn: _withCtx(()=>[
                            _createVNode(_unref(SwiperNavigationButtons), {
                                "button-kind": "base"
                            })
                        ]),
                    key: "0"
                }
            ]), 1032, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'MarketsTabs'
                ]
            ]);
        };
    }
});
