import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick"
];
import { useVFadeCarouselPointsPagination } from 'web/src/components/FadeCarousel/VFadeCarouselPointsPagination/composables';
import { PaginationLocation } from 'web/src/components/FadeCarousel/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VFadeCarouselPointsPagination',
    props: {
        isLimitedView: {
            type: Boolean,
            default: true
        },
        paginationLocation: {
            default: PaginationLocation.CENTER
        }
    },
    setup (__props) {
        const props = __props;
        const { carouselOptions, moveToNextSlide, moveToPreviousSlide, moveToSlide, paginationWrapper, isLimitedViewEnabled } = useVFadeCarouselPointsPagination(props);
        return (_ctx, _cache)=>{
            const _directive_collect = _resolveDirective("collect");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "paginationWrapper",
                ref: paginationWrapper,
                class: _normalizeClass({
                    [_ctx.$style['pagination-wrapper']]: true,
                    [_ctx.$style['pagination-wrapper-limited']]: _unref(isLimitedViewEnabled),
                    [_ctx.$style['pagination-wrapper-right']]: props.paginationLocation === _unref(PaginationLocation).RIGHT,
                    [_ctx.$style['pagination-wrapper-center']]: props.paginationLocation === _unref(PaginationLocation).CENTER
                })
            }, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass({
                        [_ctx.$style['pagination']]: true,
                        [_ctx.$style['pagination--full']]: !_unref(isLimitedViewEnabled)
                    }),
                    style: _normalizeStyle({
                        ..._unref(isLimitedViewEnabled) ? {
                            width: `${20 * _unref(carouselOptions).slidesCount}px`
                        } : {}
                    })
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(carouselOptions).slidesCount, (index)=>(_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: _normalizeClass({
                                [_ctx.$style['pagination__item']]: true,
                                [_ctx.$style['pagination__item--selected']]: index === _unref(carouselOptions).activeSlideIndex + 1
                            }),
                            onClick: _withModifiers(($event)=>_unref(moveToSlide)?.(index - 1), [
                                "stop"
                            ])
                        }, null, 10, _hoisted_1))), 128)),
                    //@ts-ignore
                    _createCommentVNode("", true),
                    //@ts-ignore
                    _createCommentVNode("", true)
                ], 6)), [
                    [
                        _directive_collect,
                        {
                            promo: 'misclick'
                        },
                        "click-counter"
                    ]
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VFadeCarouselPointsPagination'
                ]
            ]);
        };
    }
});
