import { ref, computed, toRef } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { updateSegmentEventsResponse } from 'web/src/modules/sportline/submodules/segment/store/utils';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
// @TODO don't export
export default function useSegmentStoreLiveComposable(props) {
    const { sportSegmentsSettings, allLiveTabUpdateInterval, selectedLiveTabUpdateInterval } = props;
    const apiService = useSportlineApiService();
    const broadcastSelected = useBroadcastSelected();
    const isUpdateOnlyActiveEntityOnChangeEnabled = toRef(()=>sportSegmentsSettings.value.isUpdateOnlyActiveEntityOnChangeEnabled);
    /** Update only one sport/region for active tab */ const isUpdateOnlyActiveEntityEnabled = toRef(()=>sportSegmentsSettings.value.isUpdateOnlyActiveEntityEnabled);
    const rawLiveEventsResponse = ref(null);
    const lastUpdate = new BackgroundUpdateStopwatch({
        segmentLiveEvents: async (param)=>{
            let { silent, regionId, sportFamily } = param;
            const response = await apiService.loadLiveEvents({
                vTag: rawLiveEventsResponse.value?.vtag,
                sportFamily: sportFamily || sportSegmentsSettings.value.sportFamily,
                doAddUpcoming: !!sportSegmentsSettings.value.isUpcomingEventsEnabled,
                regionId,
                silent
            });
            const group = sportFamily || regionId;
            if (group) {
                rawLiveEventsResponse.value = updateSegmentEventsResponse(rawLiveEventsResponse.value, response, {
                    sportFamily,
                    regionId
                });
                broadcastSelected.updateDataInStorageByResponse({
                    response
                });
                lastUpdate.updateGroup('segmentLiveEvents', group);
            } else {
                rawLiveEventsResponse.value = updateSegmentEventsResponse(rawLiveEventsResponse.value, response, {});
                broadcastSelected.updateDataInStorageByResponse({
                    response
                });
                lastUpdate.update('segmentLiveEvents');
                lastUpdate.updateGroup('segmentLiveEvents', props.filterOptionsIds.value);
            }
        }
    });
    const segmentSportFamily = computed(()=>props.sportSegmentsSettings.value.sportFamily);
    const liveListUpdateInterval = computed(()=>isUpdateOnlyActiveEntityEnabled.value ? selectedLiveTabUpdateInterval.value : allLiveTabUpdateInterval.value);
    const isReady = computed(()=>null !== rawLiveEventsResponse.value);
    const isNeedToUpdateFullList = computed(()=>lastUpdate.isIntervalElapsed('segmentLiveEvents', allLiveTabUpdateInterval.value));
    const selectedEntityId = computed(()=>segmentSportFamily.value ? {
            regionId: props.activeRegionElement.value?.region.id
        } : {
            sportFamily: props.activeSportElement.value?.sport.representation.family
        });
    const willUpdateFullList = computed(()=>// update time elapsed
        isNeedToUpdateFullList.value || // disabled one entity updates
        !isUpdateOnlyActiveEntityEnabled.value || // don't have selected id
        !selectedEntityId.value.sportFamily && !selectedEntityId.value.regionId);
    const { initialRequests, syncState: internalSyncState, syncBackgroundRequests: internalSyncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: liveListUpdateInterval
    });
    const { onInit: sportlineEventsChangesListenersOnInit } = useSportlineEventsChangesListeners({
        setResponse (response) {
            rawLiveEventsResponse.value = response;
        },
        getResponse () {
            return rawLiveEventsResponse.value;
        }
    });
    function onInit() {
        sportlineEventsChangesListenersOnInit();
        useSyncState(async (silent)=>{
            if (!isUpdateOnlyActiveEntityOnChangeEnabled.value) return internalSyncState({
                silent
            });
            if (willUpdateFullList.value) return internalSyncState({
                silent
            });
            const group = selectedEntityId.value.sportFamily || selectedEntityId.value.regionId;
            return internalSyncState({
                silent,
                groups: {
                    segmentLiveEvents: group
                }
            }, {
                segmentLiveEvents: selectedEntityId.value
            });
        }, liveListUpdateInterval);
    }
    async function syncBackgroundRequests(options) {
        // check update full list
        if (!isUpdateOnlyActiveEntityOnChangeEnabled.value) return internalSyncBackgroundRequests(options);
        if (willUpdateFullList.value) return internalSyncBackgroundRequests(options);
        const group = selectedEntityId.value.sportFamily || selectedEntityId.value.regionId;
        return internalSyncBackgroundRequests({
            ...options,
            groups: {
                segmentLiveEvents: group
            }
        }, {
            segmentLiveEvents: selectedEntityId.value
        });
    }
    return {
        liveListUpdateInterval,
        isReady,
        rawLiveEventsResponse,
        initialRequests,
        onInit,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
}
