import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HomeHeading',
    props: {
        to: {},
        title: {
            default: ''
        },
        buttonText: {
            default: ''
        },
        secondaryButton: {},
        isDark: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const secondaryButton = toRef(props, 'secondaryButton', null);
        const router = useRouter();
        const buttonIconProperties = {
            size: IconSize.SIZE_16
        };
        const secondaryButtonIconProperties = computed(()=>({
                ...buttonIconProperties,
                ...secondaryButton.value?.icon ?? {}
            }));
        function secondaryButtonClick() {
            const target = secondaryButton.value?.to;
            if (!target) return;
            router.push(target);
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VRouterLink, {
                class: _normalizeClass(_ctx.$style['home-heading']),
                to: _ctx.to ?? {}
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['home-heading__title'])
                        }, _toDisplayString(_ctx.title), 3),
                        secondaryButton.value ? (_openBlock(), _createBlock(VButton, {
                            key: 0,
                            kind: _unref(ButtonKind).BASE,
                            height: _unref(ButtonHeight).MEDIUM,
                            class: _normalizeClass([
                                _ctx.$style['home-heading__button'],
                                _ctx.$style['home-heading__button--secondary']
                            ]),
                            label: secondaryButton.value.text,
                            icon: secondaryButtonIconProperties.value,
                            "is-uppercase": false,
                            onClick: _withModifiers(secondaryButtonClick, [
                                "prevent"
                            ])
                        }, {
                            default: _withCtx(()=>[
                                    _createVNode(_unref(VIcon), _mergeProps(secondaryButtonIconProperties.value, {
                                        class: _ctx.$style['home-heading__button-icon']
                                    }), null, 16, [
                                        "class"
                                    ]),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['home-heading__button-label'])
                                    }, _toDisplayString(secondaryButton.value.text), 3)
                                ]),
                            _: 1
                        }, 8, [
                            "kind",
                            "height",
                            "class",
                            "label",
                            "icon"
                        ])) : _createCommentVNode("", true),
                        _withDirectives(_createVNode(VButton, {
                            kind: _unref(ButtonKind).BASE,
                            height: _unref(ButtonHeight).MEDIUM,
                            class: _normalizeClass(_ctx.$style['home-heading__button']),
                            label: _ctx.buttonText ?? void 0,
                            "is-uppercase": false
                        }, null, 8, [
                            "kind",
                            "height",
                            "class",
                            "label"
                        ]), [
                            [
                                _directive_data_test,
                                {
                                    el: 'home-heading-button'
                                }
                            ]
                        ])
                    ]),
                _: 1
            }, 8, [
                "class",
                "to"
            ])), [
                [
                    _directive_auto_id,
                    'HomeHeading'
                ]
            ]);
        };
    }
});
