const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    title: 'TwoFactorAuthenticationSecret',
    required: [
        'code'
    ],
    properties: {
        code: {
            type: 'string',
            pattern: '^(\\d{6}|\\d{8})$'
        }
    }
};
export default schema;
