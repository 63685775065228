import { defineComponent as _defineComponent } from 'vue';
import { mergeProps as _mergeProps, createVNode as _createVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "casino-section-swiper-navigation"
};
import { IconName } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramSwiperNavigationButtons',
    props: {
        showPreviousButton: {
            type: Boolean,
            default: false
        },
        showNextButton: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        "click-previous",
        "click-next"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const previousButtonProperties = {
            kind: ButtonKind.BASE,
            height: ButtonHeight.MEDIUM,
            iconName: IconName.EXPAND_LEFT,
            disabled: props.showPreviousButton
        };
        const nextButtonProperties = {
            kind: ButtonKind.BASE,
            height: ButtonHeight.MEDIUM,
            iconName: IconName.EXPAND_RIGHT,
            disabled: props.showNextButton
        };
        function emitClickPrevious() {
            if (!props.showPreviousButton) return;
            emit('click-previous');
        }
        function emitClickNext() {
            if (!props.showNextButton) return;
            emit('click-next');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VButton, _mergeProps(previousButtonProperties, {
                    class: "casino-section-swiper-navigation__button",
                    onClick: emitClickPrevious
                }), null, 16),
                _createVNode(VButton, _mergeProps(nextButtonProperties, {
                    class: "casino-section-swiper-navigation__button",
                    onClick: emitClickNext
                }), null, 16)
            ])), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramSwiperNavigationButtons'
                ],
                [
                    _vShow,
                    _ctx.showPreviousButton || _ctx.showNextButton
                ]
            ]);
        };
    }
});
