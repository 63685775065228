import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import EgsGameIframe from 'web/src/modules/egs/components/EgsGameIframe/EgsGameIframe.vue';
import { VLogoLoader } from 'web/src/components/Loader';
import { useQuickBetsDetailsPage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'QuickBetsDetailsRoutePage',
    setup (__props) {
        const { showLoader, gameUrl, startCodeType, iframeElement, styles, mainContentRef } = useQuickBetsDetailsPage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "mainContentRef",
                ref: mainContentRef,
                class: _normalizeClass(_ctx.$style['fast-games-page'])
            }, [
                _unref(showLoader) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['fast-games-page__loader'])
                }, [
                    _createVNode(_unref(VLogoLoader), {
                        class: _normalizeClass(_ctx.$style['fast-games-page__loader-image'])
                    }, null, 8, [
                        "class"
                    ])
                ], 2)) : _createCommentVNode("", true),
                _unref(gameUrl) ? (_openBlock(), _createBlock(EgsGameIframe, {
                    key: 1,
                    ref_key: "iframeElement",
                    ref: iframeElement,
                    style: _normalizeStyle(_unref(styles)),
                    "game-url": _unref(gameUrl),
                    "start-code-type": _unref(startCodeType),
                    "flex-layout": "",
                    allow: "autoplay; fullscreen;",
                    class: _normalizeClass(_ctx.$style['fast-games-page__iframe'])
                }, null, 8, [
                    "style",
                    "game-url",
                    "start-code-type",
                    "class"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'QuickBetsDetailsRoutePage'
                ]
            ]);
        };
    }
});
