import { ref, computed } from 'vue';
import getPasswordInputIcon from './utils/getPasswordInputIcon';
export default function usePasswordInput() {
    const isTextVisible = ref(false);
    const toggleTextVisibility = ()=>{
        isTextVisible.value = !isTextVisible.value;
    };
    const iconName = computed(()=>getPasswordInputIcon(isTextVisible.value));
    return {
        isTextVisible,
        toggleTextVisibility,
        iconName
    };
}
