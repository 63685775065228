import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { MatchProgressTimeDirection } from 'web/src/modules/sportline/enums';
import { LiveStatusTimeDirection } from 'web/src/modules/sportline/enums/rest';
import parseScore from './parseScore';
let LiveStatusParseStrategy = class LiveStatusParseStrategy {
    getMainTime() {
        const { progress } = this.liveStatus;
        if (!progress || !/^[^']+'$/.test(progress)) return;
        const rawProgress = progress.replace('\'', '') || '';
        const [mainTime] = rawProgress.split('+');
        const result = mainTime ? parseInt(mainTime, 10) : 0;
        return {
            minutes: Number.isNaN(result) ? 0 : result,
            seconds: 0
        };
    }
    getAdditionalTime() {
        const { progress } = this.liveStatus;
        if (!progress || !/^[^']+'$/.test(progress)) return;
        const rawProgress = progress.replace('\'', '') || '';
        const [, additionalTime] = rawProgress.split('+');
        if (!additionalTime) return;
        const result = additionalTime ? parseInt(additionalTime, 10) : 0;
        return {
            minutes: Number.isNaN(result) ? 0 : result,
            seconds: 0
        };
    }
    getTimeDirection() {
        // Get direction info from fullProgress for LEONWEB-3935
        // This info may be empty for default strategy
        const { fullProgress } = this.liveStatus;
        if (!fullProgress) return MatchProgressTimeDirection.None;
        if (fullProgress.timeDirection === LiveStatusTimeDirection.Reverse) return MatchProgressTimeDirection.ReversedNone;
        return MatchProgressTimeDirection.None;
    }
    getCurrentSetScore() {
        const { progress } = this.liveStatus;
        const parsedScore = parseScore(progress);
        if (!parsedScore) return;
        const [host, guest] = parsedScore.parts;
        return host && guest ? {
            host,
            guest,
            delimiter: parsedScore.delimiter
        } : void 0;
    }
    getTotalScore() {
        const [host, guest] = parseScore(this.liveStatus.score)?.parts || [];
        return {
            host: host?.replace('*', '') || '',
            guest: guest?.replace('*', '') || ''
        };
    }
    getInnings() {
        const [host, guest] = parseScore(this.liveStatus.score)?.parts || [];
        return {
            host: !!host?.includes('*'),
            guest: !!guest?.includes('*')
        };
    }
    getTotalScoreDetails() {
        const setScores = (this.liveStatus.setScores || '').split(';').map((part)=>parseScore(part)?.parts || []);
        return {
            host: setScores.map((scores)=>scores[0]).filter((value)=>!!value),
            guest: setScores.map((scores)=>scores[1]).filter((value)=>!!value)
        };
    }
    getPenaltyScore() {
        const [host, guest] = parseScore(this.liveStatus.penaltyScore)?.parts || [];
        return host && guest ? {
            host: host.replace('*', ''),
            guest: guest.replace('*', '')
        } : void 0;
    }
    getStage() {
        return this.liveStatus.stage || '';
    }
    constructor(liveStatus){
        _define_property(this, "liveStatus", void 0);
        this.liveStatus = liveStatus;
    }
};
export { LiveStatusParseStrategy as default };
