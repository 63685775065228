import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ErrorContent',
    props: {
        errorType: {},
        errorNumber: {},
        errorTitle: {},
        errorMessage: {},
        hasSupport: {
            type: Boolean
        },
        errorIcon: {},
        image: {},
        alertIconSize: {},
        alertIcon: {}
    },
    setup (__props) {
        const router = useRouter();
        const isShow = ref(true);
        function onClickHelp() {
            router.push({
                name: RouteName.SUPPORT
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['error-page']]: true,
                    [_ctx.$style[`error-page--${_ctx.errorType}`]]: !!_ctx.errorType
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['error-page__holder']]: true,
                        [_ctx.$style['error-page__holder--plain']]: void 0,
                        [_ctx.$style['error-page__holder--slott']]: void 0
                    })
                }, [
                    _createVNode(_unref(VJumbotron), _mergeProps(_ctx.errorIcon, {
                        image: _ctx.image,
                        heading: _ctx.errorTitle,
                        text: _ctx.errorMessage,
                        "alert-icon-size": _ctx.alertIconSize,
                        "alert-icon": _ctx.alertIcon
                    }), _createSlots({
                        footer: _withCtx(()=>[
                                _renderSlot(_ctx.$slots, "footer")
                            ]),
                        default: _withCtx(()=>[
                                _ctx.$slots.description ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['error-page__description'])
                                }, [
                                    _renderSlot(_ctx.$slots, "description")
                                ], 2)) : _createCommentVNode("", true)
                            ]),
                        _: 2
                    }, [
                        _ctx.errorNumber ? {
                            name: "icon",
                            fn: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['error-page__error-number'])
                                    }, _toDisplayString(_ctx.errorNumber), 3)
                                ]),
                            key: "0"
                        } : void 0
                    ]), 1040, [
                        "image",
                        "heading",
                        "text",
                        "alert-icon-size",
                        "alert-icon"
                    ])
                ], 2),
                _ctx.hasSupport && isShow.value ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['error-page__holder'])
                }, [
                    (_openBlock(), _createBlock(_unref(VJumbotron), {
                        key: 1,
                        text: _ctx.$t('WEB2_ERROR_TEXT_SUPPORT')
                    }, {
                        footer: _withCtx(()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['error-page__footer'])
                                }, [
                                    _renderSlot(_ctx.$slots, "contacts-button", {}, ()=>[
                                            _createVNode(_unref(JumbotronFooterButton), {
                                                kind: _unref(ButtonKind).BASE,
                                                rounded: "",
                                                "is-uppercase": false,
                                                label: _ctx.$t('WEB2_ERROR_BTN_SUPPORT'),
                                                onClick: onClickHelp
                                            }, null, 8, [
                                                "kind",
                                                "label"
                                            ])
                                        ])
                                ], 2)
                            ]),
                        _: 3
                    }, 8, [
                        "text"
                    ]))
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ErrorContent'
                ]
            ]);
        };
    }
});
