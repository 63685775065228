import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';
import PaymentsModalsController from 'web/src/modules/payments/components/PaymnetsModalsController/PaymentsModalsController.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import { usePaymentsCancelWithdrawal } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCancelWithdrawal',
    props: {
        value: {
            default: 0
        }
    },
    setup (__props) {
        const props = __props;
        const { showModal, modalType, modalText, secondaryText, showCancelWithdrawalWindow, formattedValue, onCancelWithdrawalClick, onConfirmClick, hideModal } = usePaymentsCancelWithdrawal(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['payments-cancel-withdrawal'])
            }, [
                _unref(showCancelWithdrawalWindow) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    (_openBlock(), _createBlock(VHintBlock, {
                        key: 1,
                        icon: _unref(IconName).ATTENTION_OUTLINE,
                        "is-wide-footer": ""
                    }, {
                        label: _withCtx(()=>[
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('JSPACC_ACC_REQUESTED')) + " ", 1),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['payments-cancel-withdrawal__money'])
                                }, _toDisplayString(_unref(formattedValue)), 3)
                            ]),
                        footer: _withCtx(()=>[
                                _createVNode(VButton, {
                                    label: _ctx.$t('WEB2_CANCEL_WITHDRAWAL_AND_RECIEVE_TO_ACCOUNT'),
                                    kind: _unref(ButtonKind).CANCEL,
                                    "full-width": "",
                                    class: _normalizeClass(_ctx.$style['payments-cancel-withdrawal__button']),
                                    onClick: _unref(onCancelWithdrawalClick)
                                }, null, 8, [
                                    "label",
                                    "kind",
                                    "class",
                                    "onClick"
                                ])
                            ]),
                        _: 1
                    }, 8, [
                        "icon"
                    ]))
                ], 64)) : _createCommentVNode("", true),
                _unref(showModal) ? (_openBlock(), _createBlock(PaymentsModalsController, {
                    key: 1,
                    "modal-text": _unref(modalText),
                    "secondary-text": _unref(secondaryText),
                    "modal-type": _unref(modalType),
                    "is-in-profile": "",
                    onClose: _unref(hideModal),
                    onConfirmClick: _unref(onConfirmClick)
                }, null, 8, [
                    "modal-text",
                    "secondary-text",
                    "modal-type",
                    "onClose",
                    "onConfirmClick"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsCancelWithdrawal'
                ]
            ]);
        };
    }
});
