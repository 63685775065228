import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "onClick"
];
const _hoisted_2 = [
    "href"
];
const _hoisted_3 = [
    "onClick"
];
const _hoisted_4 = [
    "onClick"
];
const _hoisted_5 = [
    "href"
];
const _hoisted_6 = [
    "onClick"
];
const _hoisted_7 = [
    "onClick"
];
const _hoisted_8 = [
    "href"
];
const _hoisted_9 = [
    "onClick"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VCover from 'web/src/components/Cover/components/VCover.vue';
import { VSidebar } from 'web/src/components/Sidebar';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import { useTheSidebarMenu } from 'web/src/components/SidebarMenu/TheSidebarMenu/composables';
import { isValidLocation } from 'web/src/components/SidebarMenu/utils';
const defaultL1Class = 'menu-level-1-spoiler__header-icon';
const defaultL1InnerClass = 'menu-level-1-spoiler__header-inner';
const cashbackIcon = 'cashback';
const myGamesIcon = 'my-games';
// TODO: vue3 bug? lost data-v- attributes
export default /*@__PURE__*/ _defineComponent({
    __name: 'TheSidebarMenu',
    props: {
        menu: {},
        replaceRoutes: {
            type: Boolean
        },
        noMargins: {
            type: Boolean
        }
    },
    emits: [
        "before-navigate",
        "level-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const levelIndicatorProperties = [
            {
                size: IconSize.SIZE_16,
                name: IconName.EXPAND_DOWN
            },
            {
                size: IconSize.SIZE_16,
                name: IconName.EXPAND_UP
            }
        ];
        const options = {
            defaultL1Class,
            defaultL1InnerClass
        };
        const { emitMenuLevelClick, emitBeforeNavigateClick, doLevelClick, beforeLevelOneClick } = useTheSidebarMenu(props, emit, options);
        // TODO: temp hardcode
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VSidebar), {
                class: _normalizeClass({
                    [_ctx.$style['sidebar--menu']]: true
                })
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "top"),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (firstLevelElement)=>(_openBlock(), _createBlock(VCover, {
                                key: firstLevelElement.key,
                                opened: firstLevelElement.active
                            }, {
                                default: _withCtx((scopeLvl1)=>[
                                        _withDirectives((_openBlock(), _createElementBlock("div", {
                                            class: _normalizeClass({
                                                [_ctx.$style['menu-level-1-spoiler']]: true,
                                                [_ctx.$style['menu-level-1-spoiler--sidebar']]: true,
                                                [_ctx.$style['menu-level-1-spoiler--open']]: scopeLvl1.isOpen && firstLevelElement.canOpen,
                                                [_ctx.$style['menu-level-1-spoiler--space-bottom']]: firstLevelElement.props && !!firstLevelElement.props.addBottomPadding
                                            })
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass({
                                                    [_ctx.$style['menu-level-1-spoiler__header']]: true,
                                                    [_ctx.$style['menu-level-1-spoiler__header--open']]: scopeLvl1.isOpen
                                                })
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style[defaultL1InnerClass]),
                                                    onClick: ($event)=>_unref(emitMenuLevelClick)($event, {
                                                            level: 1,
                                                            id: firstLevelElement.id
                                                        })
                                                }, [
                                                    _renderSlot(_ctx.$slots, "icon", {}, ()=>[
                                                            firstLevelElement.icon ? (_openBlock(), _createElementBlock("div", {
                                                                key: 0,
                                                                class: _normalizeClass(_ctx.$style[defaultL1Class]),
                                                                style: _normalizeStyle(firstLevelElement.props && firstLevelElement.props.iconStyle ? firstLevelElement.props.iconStyle : {})
                                                            }, [
                                                                _createVNode(_unref(VIcon), {
                                                                    class: _normalizeClass(_ctx.$style['menu-level-1-spoiler__header-icon-svg']),
                                                                    name: firstLevelElement.icon.name
                                                                }, null, 8, [
                                                                    "class",
                                                                    "name"
                                                                ])
                                                            ], 6)) : 'profile-my-games' === firstLevelElement.id ? (_openBlock(), _createElementBlock("div", {
                                                                key: 1,
                                                                class: _normalizeClass(_ctx.$style[defaultL1Class]),
                                                                style: _normalizeStyle(firstLevelElement.props && firstLevelElement.props.iconStyle ? firstLevelElement.props.iconStyle : {})
                                                            }, [
                                                                _createVNode(_unref(VIcon), {
                                                                    class: _normalizeClass(_ctx.$style['menu-level-1-spoiler__header-icon-svg']),
                                                                    name: myGamesIcon
                                                                }, null, 8, [
                                                                    "class"
                                                                ])
                                                            ], 6)) : 'profile-cashback' === firstLevelElement.id ? (_openBlock(), _createElementBlock("div", {
                                                                key: 2,
                                                                class: _normalizeClass(_ctx.$style[defaultL1Class]),
                                                                style: _normalizeStyle(firstLevelElement.props && firstLevelElement.props.iconStyle ? firstLevelElement.props.iconStyle : {})
                                                            }, [
                                                                _createVNode(_unref(VIcon), {
                                                                    class: _normalizeClass(_ctx.$style['menu-level-1-spoiler__header-icon-svg']),
                                                                    name: cashbackIcon
                                                                }, null, 8, [
                                                                    "class"
                                                                ])
                                                            ], 6)) : _createCommentVNode("", true)
                                                        ]),
                                                    firstLevelElement.externalUrl ? (_openBlock(), _createElementBlock("a", {
                                                        key: 0,
                                                        class: _normalizeClass({
                                                            [_ctx.$style['menu-level-1-spoiler__title']]: true,
                                                            [_ctx.$style['menu-level-1-spoiler__title--open']]: scopeLvl1.isOpen,
                                                            [_ctx.$style['menu-level-1-spoiler__title--has-icon']]: firstLevelElement.icon
                                                        }),
                                                        href: firstLevelElement.externalUrl,
                                                        target: "_blank"
                                                    }, _toDisplayString(firstLevelElement.name), 11, _hoisted_2)) : firstLevelElement.location && _unref(isValidLocation)(firstLevelElement.location) ? (_openBlock(), _createBlock(VRouterLink, {
                                                        key: 1,
                                                        replace: _ctx.replaceRoutes,
                                                        class: _normalizeClass({
                                                            [_ctx.$style['menu-level-1-spoiler__title']]: true,
                                                            [_ctx.$style['menu-level-1-spoiler__title--open']]: scopeLvl1.isOpen,
                                                            [_ctx.$style['menu-level-1-spoiler__title--has-icon']]: firstLevelElement.icon || 'profile-my-games' === firstLevelElement.id || 'profile-cashback' === firstLevelElement.id
                                                        }),
                                                        to: firstLevelElement.location,
                                                        onBeforeNavigate: ($event)=>_unref(emitBeforeNavigateClick)($event.originalEvent, $event.to, {
                                                                level: 1,
                                                                id: firstLevelElement.id
                                                            }),
                                                        onClick: ($event)=>_unref(beforeLevelOneClick)($event, scopeLvl1.open, firstLevelElement.location)
                                                    }, {
                                                        default: _withCtx(()=>[
                                                                _createTextVNode(_toDisplayString(firstLevelElement.name), 1)
                                                            ]),
                                                        _: 2
                                                    }, 1032, [
                                                        "replace",
                                                        "class",
                                                        "to",
                                                        "onBeforeNavigate",
                                                        "onClick"
                                                    ])) : (_openBlock(), _createElementBlock("div", {
                                                        key: 2,
                                                        class: _normalizeClass({
                                                            [_ctx.$style['menu-level-1-spoiler__title']]: true,
                                                            [_ctx.$style['menu-level-1-spoiler__title--open']]: scopeLvl1.isOpen,
                                                            [_ctx.$style['menu-level-1-spoiler__title--has-icon']]: firstLevelElement.icon
                                                        }),
                                                        onClick: ($event)=>scopeLvl1.isOpen ? ()=>{} : scopeLvl1.toggle
                                                    }, _toDisplayString(firstLevelElement.name), 11, _hoisted_3))
                                                ], 10, _hoisted_1),
                                                firstLevelElement.badge ? (_openBlock(), _createBlock(VBadge, {
                                                    key: 0,
                                                    class: _normalizeClass(_ctx.$style['menu-level-1-spoiler__badge']),
                                                    label: firstLevelElement.badge
                                                }, null, 8, [
                                                    "class",
                                                    "label"
                                                ])) : _createCommentVNode("", true),
                                                firstLevelElement.suffixText ? (_openBlock(), _createElementBlock("span", {
                                                    key: 1,
                                                    class: _normalizeClass(_ctx.$style['menu-level-1-spoiler__suffix-text'])
                                                }, _toDisplayString(firstLevelElement.suffixText), 3)) : _createCommentVNode("", true),
                                                firstLevelElement.canOpen ? (_openBlock(), _createElementBlock("span", {
                                                    key: 2,
                                                    class: _normalizeClass(_ctx.$style['menu-level-1-spoiler__indicator']),
                                                    onClick: scopeLvl1.toggle
                                                }, [
                                                    _createVNode(_unref(VIcon), _mergeProps({
                                                        ref_for: true
                                                    }, scopeLvl1.isOpen ? levelIndicatorProperties[1] : levelIndicatorProperties[0]), null, 16)
                                                ], 10, _hoisted_4)) : firstLevelElement.suffix ? (_openBlock(), _createElementBlock("span", {
                                                    key: 3,
                                                    class: _normalizeClass(_ctx.$style['menu-level-1-spoiler__suffix'])
                                                }, [
                                                    _createVNode(_unref(VIcon), _mergeProps({
                                                        ref_for: true
                                                    }, firstLevelElement.suffix), null, 16)
                                                ], 2)) : (_openBlock(), _createElementBlock("span", {
                                                    key: 4,
                                                    class: _normalizeClass([
                                                        _ctx.$style['menu-level-1-spoiler__indicator'],
                                                        _ctx.$style['menu-level-1-spoiler__indicator--empty']
                                                    ])
                                                }, null, 2))
                                            ], 2),
                                            firstLevelElement.canOpen && scopeLvl1.isLoaded ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                class: _normalizeClass(_ctx.$style['menu-level-1-spoiler__content'])
                                            }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(firstLevelElement.menu, (secondLevelElement)=>(_openBlock(), _createBlock(VCover, {
                                                        key: secondLevelElement.key,
                                                        opened: secondLevelElement.active
                                                    }, {
                                                        default: _withCtx((scopeLvl2)=>[
                                                                _createElementVNode("div", {
                                                                    class: _normalizeClass({
                                                                        [_ctx.$style['menu-level-2-spoiler']]: true,
                                                                        [_ctx.$style['menu-level-2-spoiler--open']]: scopeLvl2.isOpen
                                                                    })
                                                                }, [
                                                                    _createElementVNode("div", {
                                                                        class: _normalizeClass({
                                                                            [_ctx.$style['menu-level-2-spoiler__header']]: true,
                                                                            [_ctx.$style['menu-level-2-spoiler__header--open']]: scopeLvl2.isOpen
                                                                        })
                                                                    }, [
                                                                        secondLevelElement.externalUrl ? (_openBlock(), _createElementBlock("a", {
                                                                            key: 0,
                                                                            class: _normalizeClass({
                                                                                [_ctx.$style['menu-level-2-spoiler__title']]: true,
                                                                                [_ctx.$style['menu-level-2-spoiler__title--open']]: scopeLvl2.isOpen
                                                                            }),
                                                                            href: secondLevelElement.externalUrl,
                                                                            target: "_blank"
                                                                        }, _toDisplayString(secondLevelElement.name), 11, _hoisted_5)) : secondLevelElement.location ? (_openBlock(), _createBlock(VRouterLink, {
                                                                            key: 1,
                                                                            replace: _ctx.replaceRoutes,
                                                                            class: _normalizeClass({
                                                                                [_ctx.$style['menu-level-2-spoiler__title']]: true,
                                                                                [_ctx.$style['menu-level-2-spoiler__title--open']]: scopeLvl2.isOpen
                                                                            }),
                                                                            to: secondLevelElement.location,
                                                                            onBeforeNavigate: ($event)=>_unref(emitBeforeNavigateClick)($event.originalEvent, $event.to, {
                                                                                    level: 2,
                                                                                    id: secondLevelElement.id
                                                                                }),
                                                                            onClick: ($event)=>_unref(beforeLevelOneClick)($event, scopeLvl2.open, secondLevelElement.location, {
                                                                                    level: 2,
                                                                                    id: secondLevelElement.id
                                                                                })
                                                                        }, {
                                                                            default: _withCtx(()=>[
                                                                                    _createTextVNode(_toDisplayString(secondLevelElement.name), 1)
                                                                                ]),
                                                                            _: 2
                                                                        }, 1032, [
                                                                            "replace",
                                                                            "class",
                                                                            "to",
                                                                            "onBeforeNavigate",
                                                                            "onClick"
                                                                        ])) : (_openBlock(), _createElementBlock("div", {
                                                                            key: 2,
                                                                            class: _normalizeClass({
                                                                                [_ctx.$style['menu-level-2-spoiler__title']]: true,
                                                                                [_ctx.$style['menu-level-2-spoiler__title--open']]: scopeLvl2.isOpen
                                                                            }),
                                                                            onClick: ($event)=>_unref(doLevelClick)($event, scopeLvl2.toggle, {
                                                                                    level: 2,
                                                                                    id: secondLevelElement.id
                                                                                })
                                                                        }, _toDisplayString(secondLevelElement.name), 11, _hoisted_6)),
                                                                        secondLevelElement.menu.length ? (_openBlock(), _createElementBlock("span", {
                                                                            key: 3,
                                                                            class: _normalizeClass(_ctx.$style['menu-level-2-spoiler__indicator']),
                                                                            onClick: scopeLvl2.toggle
                                                                        }, [
                                                                            _createVNode(_unref(VIcon), _mergeProps({
                                                                                ref_for: true
                                                                            }, scopeLvl2.isOpen ? levelIndicatorProperties[1] : levelIndicatorProperties[0]), null, 16)
                                                                        ], 10, _hoisted_7)) : secondLevelElement.suffix ? (_openBlock(), _createElementBlock("span", {
                                                                            key: 4,
                                                                            class: _normalizeClass(_ctx.$style['menu-level-2-spoiler__suffix'])
                                                                        }, [
                                                                            _createVNode(_unref(VIcon), _mergeProps({
                                                                                ref_for: true
                                                                            }, secondLevelElement.suffix), null, 16)
                                                                        ], 2)) : _createCommentVNode("", true)
                                                                    ], 2),
                                                                    scopeLvl2.isLoaded && !!secondLevelElement.menu.length ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                                                        key: 0,
                                                                        class: _normalizeClass(_ctx.$style['menu-level-2-spoiler__content'])
                                                                    }, [
                                                                        _createElementVNode("ul", {
                                                                            class: _normalizeClass({
                                                                                [_ctx.$style['menu-level-3-spoiler']]: true,
                                                                                [_ctx.$style['menu-level-3-spoiler--open']]: scopeLvl2.isOpen
                                                                            })
                                                                        }, [
                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(secondLevelElement.menu, (thirdLevelElement)=>(_openBlock(), _createElementBlock("li", {
                                                                                    key: thirdLevelElement.id,
                                                                                    class: _normalizeClass(_ctx.$style['menu-level-3-item'])
                                                                                }, [
                                                                                    thirdLevelElement.externalUrl ? (_openBlock(), _createElementBlock("a", {
                                                                                        key: 0,
                                                                                        class: _normalizeClass({
                                                                                            [_ctx.$style['menu-level-3-item__link']]: true,
                                                                                            [_ctx.$style['menu-level-3-item--active']]: thirdLevelElement.active
                                                                                        }),
                                                                                        href: thirdLevelElement.externalUrl,
                                                                                        target: "_blank"
                                                                                    }, _toDisplayString(thirdLevelElement.name), 11, _hoisted_8)) : (_openBlock(), _createBlock(VRouterLink, {
                                                                                        key: 1,
                                                                                        replace: _ctx.replaceRoutes,
                                                                                        class: _normalizeClass({
                                                                                            [_ctx.$style['menu-level-3-item__link']]: true,
                                                                                            [_ctx.$style['menu-level-3-item--active']]: thirdLevelElement.active
                                                                                        }),
                                                                                        to: thirdLevelElement.location,
                                                                                        onBeforeNavigate: ($event)=>_unref(emitBeforeNavigateClick)($event.originalEvent, $event.to, {
                                                                                                level: 3,
                                                                                                id: thirdLevelElement.id
                                                                                            })
                                                                                    }, {
                                                                                        default: _withCtx(()=>[
                                                                                                _createElementVNode("span", {
                                                                                                    class: _normalizeClass({
                                                                                                        [_ctx.$style['menu-level-3-item__name']]: true,
                                                                                                        [_ctx.$style['menu-level-3-item__name--active']]: thirdLevelElement.active
                                                                                                    }),
                                                                                                    onClick: ($event)=>_unref(emitMenuLevelClick)($event, {
                                                                                                            level: 3,
                                                                                                            id: thirdLevelElement.id
                                                                                                        })
                                                                                                }, _toDisplayString(thirdLevelElement.name), 11, _hoisted_9)
                                                                                            ]),
                                                                                        _: 2
                                                                                    }, 1032, [
                                                                                        "replace",
                                                                                        "class",
                                                                                        "to",
                                                                                        "onBeforeNavigate"
                                                                                    ])),
                                                                                    thirdLevelElement.suffix ? (_openBlock(), _createElementBlock("span", {
                                                                                        key: 2,
                                                                                        class: _normalizeClass(_ctx.$style['menu-level-3-spoiler__suffix'])
                                                                                    }, [
                                                                                        _createVNode(_unref(VIcon), _mergeProps({
                                                                                            ref_for: true
                                                                                        }, thirdLevelElement.suffix), null, 16)
                                                                                    ], 2)) : _createCommentVNode("", true)
                                                                                ], 2))), 128))
                                                                        ], 2)
                                                                    ], 2)), [
                                                                        [
                                                                            _vShow,
                                                                            scopeLvl2.isOpen
                                                                        ]
                                                                    ]) : _createCommentVNode("", true)
                                                                ], 2)
                                                            ]),
                                                        _: 2
                                                    }, 1032, [
                                                        "opened"
                                                    ]))), 128))
                                            ], 2)), [
                                                [
                                                    _vShow,
                                                    scopeLvl1.isOpen
                                                ]
                                            ]) : _createCommentVNode("", true)
                                        ], 2)), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'sidebar-el-l1',
                                                    item: firstLevelElement.id
                                                }
                                            ]
                                        ])
                                    ]),
                                _: 2
                            }, 1032, [
                                "opened"
                            ]))), 128)),
                        _renderSlot(_ctx.$slots, "bottom")
                    ]),
                _: 3
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'TheSidebarMenu'
                ]
            ]);
        };
    }
});
