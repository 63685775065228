import { matchSportFamily } from './matchSportFamily';
export function buildLeagueNavigationParameters(options) {
    const { sportFamilyMapping, leagueResponse, regionResponse, sportResponse } = options;
    return {
        id: String(leagueResponse.id),
        urlName: leagueResponse.url,
        regionUrlName: regionResponse.url,
        sportFamily: matchSportFamily(sportResponse, sportFamilyMapping)
    };
}
