import { ref, nextTick } from 'vue';
import getDefaultAutofocus from './utils/getDefaultAutofocus';
export default function useFormAutofocus(uiSchema) {
    const focusedElementId = ref(getDefaultAutofocus(uiSchema.value));
    const focusField = (name)=>{
        const lastFocus = name ?? focusedElementId.value ?? Object.keys(uiSchema.value.fields ?? {})[0];
        if (lastFocus) {
            focusedElementId.value = void 0;
            nextTick().then(()=>{
                focusedElementId.value = lastFocus;
            });
        }
    };
    const defaultFocus = ()=>{
        focusField();
    };
    const focusByName = (name)=>{
        if (Object.keys(uiSchema.value.fields ?? {}).includes(name)) focusField(name);
    };
    const focusByIndex = (index)=>{
        const keys = Object.keys(uiSchema.value.fields ?? {});
        const matchedIndex = keys[index];
        if (matchedIndex) focusField(matchedIndex);
    };
    const focusStart = ()=>{
        if (focusedElementId.value) defaultFocus();
    };
    return {
        focusedElementId,
        focus: defaultFocus,
        focusByName,
        focusByIndex,
        focusStart
    };
}
