import { computed, ref, watch, onBeforeUnmount } from 'vue';
import { Timer } from '@leon-hub/utils';
import { InputEventType } from 'web/src/components/Input/enums';
import { generateSmsInputValue, setInputCursorAfterValue, setInputCursorPositionOnClick, getMatchedSmsInputSubfield } from '../../utils';
const useSmsCodeInput = (props, emit, inputId)=>{
    const inputValue = ref(generateSmsInputValue(props.value, props.size));
    const focusIndex = ref(-1);
    const hoverIndex = ref(-1);
    const setFocusIndex = function() {
        let index = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : -1;
        focusIndex.value = index;
    };
    const onMouseOver = function() {
        let index = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : -1;
        hoverIndex.value = index;
    };
    const onMouseLeave = ()=>{
        hoverIndex.value = -1;
    };
    const isFocusInside = ref(false);
    const regenerateInputValue = ()=>{
        inputValue.value = generateSmsInputValue(props.value, props.size);
    };
    watch(()=>props.value, ()=>{
        regenerateInputValue();
    });
    watch(()=>props.size, ()=>{
        regenerateInputValue();
    });
    const inputValueAsString = computed(()=>inputValue.value.map((item)=>item || ' ').join(''));
    const valueToEmit = computed(()=>({
            target: {
                value: inputValueAsString.value,
                name: props.name
            }
        }));
    const emitInput = ()=>{
        emit(InputEventType.INPUT, valueToEmit.value);
    };
    const emitChange = ()=>{
        emit(InputEventType.CHANGE, valueToEmit.value);
    };
    const emitFocus = ()=>{
        emit(InputEventType.FOCUS, valueToEmit.value);
    };
    const emitBlur = ()=>{
        emit(InputEventType.BLUR, valueToEmit.value);
    };
    const focusOnSubfield = function() {
        let index = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
        if (index < 0 || index > props.size - 1) return;
        const nextInput = getMatchedSmsInputSubfield(`${inputId}-${index}`);
        nextInput.focus();
        setFocusIndex(index);
        isFocusInside.value = true;
        setInputCursorAfterValue(nextInput);
    };
    const triggerExternalEvents = (currentElement, nextIndex)=>{
        emitInput();
        if (!currentElement.value) return;
        focusOnSubfield(nextIndex);
        if (currentElement.value.includes('')) return;
        emitChange();
        emitBlur();
        setFocusIndex(-1);
    };
    const setInputValueByIndex = (index, updatedValue)=>{
        const storedValue = inputValueAsString.value;
        const start = storedValue.slice(0, index);
        const end = storedValue.slice(index + (updatedValue.length || 1));
        const merged = `${start}${updatedValue || ' '}${end}`;
        inputValue.value = generateSmsInputValue(merged, props.size);
    };
    const replaceValueByIndex = (index, updatedValue)=>{
        setInputValueByIndex(index, updatedValue);
        const matchedInputElement = getMatchedSmsInputSubfield(`${inputId}-${index}`);
        matchedInputElement.value = updatedValue;
    };
    const handleInserting = (currentElement, value, index)=>{
        const oldValue = inputValue.value[index] || '';
        let newValue = value;
        if (value.length > 1) // remove oldValue, we don't know position of it
        newValue = value.replace(oldValue, '');
        // remove not a number
        newValue = newValue.replace(/\D+/g, '');
        if (!newValue) newValue = oldValue;
        replaceValueByIndex(index, newValue);
        triggerExternalEvents(currentElement, index + 1);
    };
    const onPaste = function(event) {
        let startIndex = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
        event.preventDefault();
        const { clipboardData } = event;
        if (!clipboardData) return;
        const data = clipboardData.getData('text');
        const rightBorder = Math.min(startIndex + data.length, props.size);
        for(let index = startIndex, stringIndex = 0; index < rightBorder; index += 1, stringIndex += 1){
            const input = getMatchedSmsInputSubfield(`${inputId}-${index}`);
            handleInserting(input, data[stringIndex], index);
        }
    };
    const handleDeleting = (index)=>{
        const currentValue = inputValue.value[index];
        if (currentValue) {
            replaceValueByIndex(index, '');
            emitInput();
            return;
        }
        // we placed at the first input
        if (0 === index) return;
        const leftIndex = index - 1;
        replaceValueByIndex(leftIndex, '');
        emitInput();
        focusOnSubfield(leftIndex);
    };
    const onKeyDown = (event, index)=>{
        const { key } = event;
        switch(key){
            case 'Backspace':
                event.preventDefault();
                handleDeleting(index);
                break;
            case 'ArrowLeft':
                event.preventDefault();
                focusOnSubfield(index - 1);
                break;
            case 'ArrowRight':
                event.preventDefault();
                focusOnSubfield(index + 1);
                break;
            default:
                break;
        }
    };
    const focusOnFirstSubfield = ()=>{
        focusOnSubfield(0);
    };
    let blurTimeoutId = 0;
    const clearBlurTimeout = ()=>{
        Timer.clearTimeout(blurTimeoutId);
    };
    onBeforeUnmount(clearBlurTimeout);
    const onFocus = (index)=>{
        if (!isFocusInside.value) emitFocus();
        clearBlurTimeout();
        // eslint-disable-next-line sonarjs/no-collapsible-if
        // if is mobile and value is empty
        isFocusInside.value = true;
        setFocusIndex(index);
    };
    const onBlur = ()=>{
        setFocusIndex(-1);
        // next tick is not a solution for this case
        blurTimeoutId = Timer.setTimeout(()=>{
            isFocusInside.value = false;
            emitBlur();
        }, 0);
    };
    const onInput = (event, index)=>{
        const { target } = event;
        HTMLInputElement;
        // we need it for android, since deleting/inserting value on android
        // generates: code: "", key: "Unidentified", keyCode: 229
        if ('deleteContentBackward' === event.inputType) {
            event.preventDefault();
            handleDeleting(index);
            return;
        }
        handleInserting(target, target.value, index);
    };
    const onMouseDown = (event, index)=>{
        const hasFocus = focusIndex.value === index;
        setInputCursorPositionOnClick(event, hasFocus);
    };
    return {
        inputValue,
        focusIndex,
        hoverIndex,
        onPaste,
        onFocus,
        onBlur,
        onKeyDown,
        onInput,
        onMouseDown,
        focusOnFirstSubfield,
        onMouseOver,
        onMouseLeave
    };
};
export default useSmsCodeInput;
