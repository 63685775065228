import { computed, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import useLoyaltyShopStore from 'web/src/modules/bonuses/store/useLoyaltyShopStore';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
let BonusLoyaltyProgramPagePrefetch = class BonusLoyaltyProgramPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const onboarding = useOnboarding();
        const siteConfigStore = useSiteConfigStore();
        const bonusLoyaltyStore = useBonusLoyaltyStore();
        const loyaltyShopStore = useLoyaltyShopStore();
        const offers = toRef(()=>loyaltyShopStore.offers);
        const isLeonShopEnabled = toRef(()=>siteConfigStore.isLeonShopEnabled);
        const hideLeonShopOnboardingPage = toRef(()=>bonusLoyaltyStore.hideLeonShopOnboardingPage);
        const hasOnboarding = computed(()=>!!onboarding.getOnboardingById(OnboardingIdsEnum.LEONSHOP));
        const isLeonShopEnabledWithOffers = computed(()=>isLeonShopEnabled.value && offers.value.length > 0);
        if (isLeonShopEnabledWithOffers.value && !hideLeonShopOnboardingPage.value && hasOnboarding.value) {
            bonusLoyaltyStore.setHideLeonShopOnBoardingPageState(true);
            next({
                name: RouteName.ONBOARDING,
                params: {
                    id: OnboardingIdsEnum.LEONSHOP
                }
            });
            return Promise.resolve();
        }
        next();
        return Promise.resolve();
    }
};
export { BonusLoyaltyProgramPagePrefetch as default };
