import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ObserveVisibility } from 'vue3-observe-visibility';
import { useVSightObserver } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSightObserver',
    setup (__props) {
        const { isVisible, observeVisibilityOptions } = useVSightObserver();
        const vObserveVisibility = ObserveVisibility;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _renderSlot(_ctx.$slots, "default", {
                    isVisible: _unref(isVisible)
                })
            ])), [
                [
                    _directive_auto_id,
                    'VSightObserver'
                ],
                [
                    _unref(vObserveVisibility),
                    _unref(observeVisibilityOptions)
                ]
            ]);
        };
    }
});
