import { isOptionalString } from '@leon-hub/guards';
function getHintFields(options, hintText) {
    const hint = options?.isHintRight ? void 0 : options?.hint || hintText;
    isOptionalString(hint);
    const hintRight = options?.isHintRight && hintText || options?.hintRight;
    isOptionalString(hintRight);
    return {
        hint,
        hintRight
    };
}
export default getHintFields;
