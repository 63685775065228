import RouteName from '@leon-hub/routing-config';
import { CustomerConfig } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
// @TODO: wait test
export default function getOnboardingConfig(id) {
    if (id === OnboardingIdsEnum.REFERRAL_PROGRAM) return {
        customerConfig: CustomerConfig.IS_REFERRAL_PROGRAM_ONBOARDING_SHOWN,
        infoLinkRouteName: RouteName.REFERRAL_PROGRAM_INFO,
        topBarTitleBTR: 'WEB2_REFFERAL_PROGRAM_INVITE'
    };
    if (id === OnboardingIdsEnum.BONUSES) return {
        customerConfig: CustomerConfig.HIDE_ON_BOARDING_PAGE,
        routeBack: RouteName.BONUSES
    };
    if (id === OnboardingIdsEnum.LEONSHOP) return {
        customerConfig: CustomerConfig.HIDE_ON_BOARDING_ON_LEON_SHOP_PAGE,
        routeBack: RouteName.LOYALTY_PROGRAM
    };
    if (id === OnboardingIdsEnum.EGS_LOYALTY_PROGRAM) return {
        customerConfig: CustomerConfig.HIDE_EGS_LOYALTY_ON_BOARDING_PAGE,
        routeBack: CasinoRouteName.CASINO_LOYALTY_PROGRAM
    };
    return {};
}
