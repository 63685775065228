import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export class LoginModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addModalRoute({
            name: RouteName.LOGIN,
            path: '/login',
            access: CustomerRouteAccessRole.ANONYMOUS,
            modalPreset: ModalDesktopPreset.SmallDesktopModal,
            title: '{{$t(\'THE_SITE_ENTRANCE\')}}',
            component: ()=>import('web/src/modules/login/pages/LoginRoutePage/LoginRoutePage.vue'),
            prefetch: ()=>import('./prefetch/LoginModulePrefetch')
        });
    }
} // eslint-disable-next-line max-len
