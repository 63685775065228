import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
import { usePromotionCountdown } from 'web/src/modules/promotions/components/PromotionCountdown/composable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionCountdown',
    props: {
        timestamp: {},
        isBeforeStart: {
            type: Boolean
        },
        hiddenTitle: {
            type: Boolean
        },
        isCashback: {
            type: Boolean
        },
        isAltTimeName: {
            type: Boolean
        },
        kind: {}
    },
    emits: [
        "send"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { counterTitle, timeName, timeNameAlternative, isHourShown, isMinutesShown, timeLeft, isShown } = usePromotionCountdown(props, emit);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isShown) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['promotion-countdown']]: true,
                    [_ctx.$style[`promotion-countdown--kind-${_ctx.kind}`]]: _ctx.kind
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['promotion-countdown__inner'])
                }, [
                    _ctx.hiddenTitle ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['promotion-countdown__title'])
                    }, _toDisplayString(_unref(counterTitle)), 3)),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['promotion-countdown__counters'])
                    }, [
                        _unref(timeLeft).days > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item'])
                            }, [
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item-value'])
                                }, [
                                    _createTextVNode(_toDisplayString(_unref(timeLeft).days), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-countdown__counters-item-value'
                                        }
                                    ]
                                ]),
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item-label'])
                                }, [
                                    _createTextVNode(_toDisplayString(_ctx.isAltTimeName ? _unref(timeNameAlternative) ? _unref(timeNameAlternative).days : '' : _unref(timeName) ? _unref(timeName).days : ''), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-countdown__counters-item-label'
                                        }
                                    ]
                                ])
                            ], 2),
                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                class: _normalizeClass(_ctx.$style['promotion-countdown__counters-separator'])
                            }, _cache[0] || (_cache[0] = [
                                _createTextVNode(" : ")
                            ]), 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'promotion-countdown__counters-separator'
                                    }
                                ]
                            ])
                        ], 64)) : _createCommentVNode("", true),
                        _unref(isHourShown) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item'])
                            }, [
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item-value'])
                                }, [
                                    _createTextVNode(_toDisplayString(_unref(timeLeft).hours < 10 ? `0${_unref(timeLeft).hours}` : _unref(timeLeft).hours), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-countdown__counters-item-value'
                                        }
                                    ]
                                ]),
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item-label'])
                                }, [
                                    _createTextVNode(_toDisplayString(_ctx.isAltTimeName ? _unref(timeNameAlternative) ? _unref(timeNameAlternative).hours : '' : _unref(timeName) ? _unref(timeName).hours : ''), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-countdown__counters-item-label'
                                        }
                                    ]
                                ])
                            ], 2),
                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                class: _normalizeClass(_ctx.$style['promotion-countdown__counters-separator'])
                            }, _cache[1] || (_cache[1] = [
                                _createTextVNode(" : ")
                            ]), 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'promotion-countdown__counters-separator'
                                    }
                                ]
                            ])
                        ], 64)) : _createCommentVNode("", true),
                        _unref(isMinutesShown) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 2
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item'])
                            }, [
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item-value'])
                                }, [
                                    _createTextVNode(_toDisplayString(_unref(timeLeft).minutes < 10 ? `0${_unref(timeLeft).minutes}` : _unref(timeLeft).minutes), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-countdown__counters-item-value'
                                        }
                                    ]
                                ]),
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item-label'])
                                }, [
                                    _createTextVNode(_toDisplayString(_ctx.isAltTimeName ? _unref(timeNameAlternative) ? _unref(timeNameAlternative).minutes : '' : _unref(timeName) ? _unref(timeName).minutes : ''), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'promotion-countdown__counters-item-label'
                                        }
                                    ]
                                ])
                            ], 2),
                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                class: _normalizeClass(_ctx.$style['promotion-countdown__counters-separator'])
                            }, _cache[2] || (_cache[2] = [
                                _createTextVNode(" : ")
                            ]), 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'promotion-countdown__counters-separator'
                                    }
                                ]
                            ])
                        ], 64)) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item'])
                        }, [
                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item-value'])
                            }, [
                                _createTextVNode(_toDisplayString(_unref(timeLeft).seconds < 10 ? `0${_unref(timeLeft).seconds}` : _unref(timeLeft).seconds), 1)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'promotion-countdown__counters-item-value'
                                    }
                                ]
                            ]),
                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                class: _normalizeClass(_ctx.$style['promotion-countdown__counters-item-label'])
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.isAltTimeName ? _unref(timeNameAlternative) ? _unref(timeNameAlternative).seconds : '' : _unref(timeName) ? _unref(timeName).seconds : ''), 1)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'promotion-countdown__counters-item-label'
                                    }
                                ]
                            ])
                        ], 2)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionCountdown'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
