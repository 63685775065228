import { isNumber } from '@leon-hub/guards';
import handleMaskedValueChange from './handleMaskedValueChange';
export default function handleMaskedKeydown(param) {
    let { event, currentValue, maskOptions } = param;
    const { target, key } = event;
    const isNumericKey = /^\d$/i.test(key);
    // assume that masked input value is number
    HTMLInputElement;
    let beforeSelectionStart = target.selectionStart;
    const beforeSelectionEnd = target.selectionEnd;
    isNumber(beforeSelectionStart);
    isNumber(beforeSelectionEnd);
    const isSelected = beforeSelectionStart !== beforeSelectionEnd;
    const isRemoving = 'Delete' === event.key || 'Backspace' === event.key;
    const isRemovingSelected = isRemoving && isSelected;
    const isReplacingSelected = !isRemoving && isSelected && isNumericKey;
    const data = isReplacingSelected ? event.key : null;
    if (isRemoving && !isSelected) beforeSelectionStart = 'Backspace' === event.key ? beforeSelectionStart - 1 : beforeSelectionStart;
    if (isRemovingSelected || isReplacingSelected || isRemoving) {
        event.preventDefault();
        return handleMaskedValueChange({
            target,
            selectionStart: beforeSelectionStart,
            selectionEnd: beforeSelectionEnd,
            data,
            currentValue,
            maskOptions
        });
    }
    return null;
}
