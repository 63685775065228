import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { ButtonKind } from '@leon-hub/module-buttons';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { StepName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import SwitcherKind from 'web/src/components/Switcher/VSwitcher/enums/SwitcherKind';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingInfoStep',
    props: {
        limit: {},
        switcherOptions: {},
        data: {},
        loading: {
            type: Boolean
        }
    },
    emits: [
        "tab-change",
        "step-change",
        "reset",
        "back"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const formatMoney = useFormatMoney();
        const limitValue = computed(()=>formatMoney(Number(props.data?.limit ?? 0)));
        const leftValue = computed(()=>formatMoney(Number(props.data?.left ?? 0)));
        const handleActiveTabChange = (value)=>{
            emits('tab-change', value);
        };
        const handleActiveStepChange = ()=>{
            emits('step-change', StepName.EDIT);
        };
        const handleReset = ()=>{
            emits('reset');
        };
        const handleBack = ()=>{
            emits('back');
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(_ctx.$style['rg-bet-limit-info'])
            }, [
                _createVNode(VSwitcher, {
                    kind: _unref(SwitcherKind).DEFAULT,
                    "active-id": _ctx.limit,
                    options: _ctx.switcherOptions,
                    "show-divider": false,
                    class: _normalizeClass(_ctx.$style['rg-bet-limit-info__switcher']),
                    onChange: handleActiveTabChange
                }, null, 8, [
                    "kind",
                    "active-id",
                    "options",
                    "class"
                ]),
                (_openBlock(), _createBlock(VHintBlock, {
                    key: 1,
                    "has-icon": false,
                    class: _normalizeClass(_ctx.$style['rg-bet-limit-info__hint'])
                }, {
                    title: _withCtx(()=>[
                            _createElementVNode("span", null, _toDisplayString(Number(_ctx.data.limit) ? _ctx.$t('WEB2_RG_LIMIT_TITLE_ENABLED') : _ctx.$t('WEB2_RG_LIMIT_TITLE_DISABLED')), 1)
                        ]),
                    default: _withCtx(()=>[
                            _createVNode(VDynamicContent, {
                                content: Number(_ctx.data.limit) ? _ctx.$t('WEB2_RG_LIMIT_DESCRIPTION_ENABLED') : _ctx.$t('WEB2_RG_LIMIT_DESCRIPTION_DISABLED')
                            }, null, 8, [
                                "content"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ])),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['rg-bet-limit-info__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['rg-bet-limit-info__info'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['rg-bet-limit-info__title'])
                        }, _toDisplayString(_ctx.$t('WEB2_RG_CURRENT_LIMIT')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['rg-bet-limit-info__count'])
                        }, _toDisplayString(limitValue.value), 3),
                        (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['rg-bet-limit-info__buttons-wrapper'])
                        }, [
                            Number(_ctx.data.limit) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['rg-bet-limit-info__buttons'])
                            }, [
                                _createVNode(VButton, {
                                    "full-width": "",
                                    disabled: _ctx.loading,
                                    onClick: handleActiveStepChange
                                }, {
                                    default: _withCtx(()=>[
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RG_BUTTON_CHANGE')), 1)
                                        ]),
                                    _: 1
                                }, 8, [
                                    "disabled"
                                ]),
                                _createVNode(VButton, {
                                    "full-width": "",
                                    kind: _unref(ButtonKind).TRANSPARENT,
                                    "is-loading": _ctx.loading,
                                    onClick: handleReset
                                }, {
                                    default: _withCtx(()=>[
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RG_BUTTON_REMOVE')), 1)
                                        ]),
                                    _: 1
                                }, 8, [
                                    "kind",
                                    "is-loading"
                                ])
                            ], 2)) : (_openBlock(), _createBlock(VButton, {
                                key: 1,
                                "full-width": "",
                                onClick: handleActiveStepChange
                            }, {
                                default: _withCtx(()=>[
                                        _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RG_BUTTON_SET')), 1)
                                    ]),
                                _: 1
                            }))
                        ], 2))
                    ], 2),
                    _createVNode(VCircularProgress, {
                        percentage: _ctx.data.percent,
                        "circle-size": _unref(CircleSize).SIZE_152
                    }, {
                        default: _withCtx(()=>[
                                Number(_ctx.data.limit) ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['rg-bet-limit-info__set'])
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['rg-bet-limit-info__set-label'])
                                    }, _toDisplayString(_ctx.$t('WEB2_RG_LEFT')), 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['rg-bet-limit-info__set-value'])
                                    }, _toDisplayString(leftValue.value), 3)
                                ], 2)) : (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['rg-bet-limit-info__not-set'])
                                }, _toDisplayString(leftValue.value), 3))
                            ]),
                        _: 1
                    }, 8, [
                        "percentage",
                        "circle-size"
                    ]),
                    _createCommentVNode("", true)
                ], 2),
                (_openBlock(), _createBlock(VButton, {
                    key: 3,
                    "full-width": "",
                    kind: _unref(ButtonKind).SECONDARY_DARK,
                    onClick: handleBack
                }, {
                    default: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RG_BACK_TO_GAME')), 1)
                        ]),
                    _: 1
                }, 8, [
                    "kind"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingInfoStep'
                ]
            ]);
        };
    }
});
