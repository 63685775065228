import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import SimpleBrandTopBarRouteComponent from 'web/src/modules/core/views/SimpleBrandTopBarRouteComponent/SimpleBrandTopBarRouteComponent.vue';
import { useRegistrationCompletionTopBarRouteComponent } from 'web/src/modules/registration-completion/views/RegistrationCompletionTopBarRouteComponent/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegistrationCompletionTopBarRouteComponent',
    setup (__props) {
        const { topBarTitle, isAsoTerms, showPrefix, onClose, onBack } = useRegistrationCompletionTopBarRouteComponent();
        return (_ctx, _cache)=>_unref(isAsoTerms) ? (_openBlock(), _createBlock(SimpleBrandTopBarRouteComponent, {
                key: 0,
                "hide-prefix": !_unref(showPrefix),
                "only-emit-events": "",
                onBack: _unref(onBack),
                onClose: _unref(onClose)
            }, null, 8, [
                "hide-prefix",
                "onBack",
                "onClose"
            ])) : (_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                key: 1,
                title: _unref(topBarTitle),
                "hide-prefix": !_unref(showPrefix),
                "only-emit-events": "",
                onBack: _unref(onBack),
                onClose: _unref(onClose)
            }, null, 8, [
                "title",
                "hide-prefix",
                "onBack",
                "onClose"
            ]));
    }
});
