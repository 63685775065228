import { computed, toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { useCurrency } from 'web/src/modules/money/composables';
export default function usePaymentsSuggestedAmounts(props) {
    const formattedCurrency = toRef(useCurrencyStore(), 'formattedCurrency');
    const { currency } = useCurrency();
    const suggestedAmountsFromConfig = toRef(useSiteConfigStore(), 'suggestedAmounts');
    const maxAmount = toRef(props, 'maxAmount');
    const minAmount = toRef(props, 'minAmount');
    const suggestedAmounts = toRef(props, 'suggestedAmounts');
    const allowedIncrement = toRef(props, 'allowedIncrement');
    const filteredDefaultSuggestedAmounts = computed(()=>{
        if (suggestedAmountsFromConfig.value && formattedCurrency.value && currency.value) {
            const suggestedAmountArray = suggestedAmountsFromConfig.value.filter((item)=>item.currency === currency.value);
            if (1 === suggestedAmountArray.length) {
                const amountList = suggestedAmountArray[0].depositAmounts;
                if (amountList?.length) return amountList.filter((item)=>item >= minAmount.value && (0 !== maxAmount.value ? item <= maxAmount.value : item));
            }
        }
        return null;
    });
    const filteredCurrentPaymentSystemSuggestedAmounts = computed(()=>{
        const amounts = suggestedAmounts?.value ?? [];
        const incr = allowedIncrement?.value ?? 0;
        if (amounts.length) return amounts.filter((amount)=>amount >= minAmount.value && amount <= maxAmount.value && (incr > 0 ? amount % incr === 0 : amount));
        return null;
    });
    return {
        filteredDefaultSuggestedAmounts,
        filteredCurrentPaymentSystemSuggestedAmounts
    };
}
