import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';
import { isAddressAutocompleteResult } from './guards';
export default function createAddressApi() {
    const client = useBaseApiClient();
    return {
        async autocompleteAddress (param) {
            let { address, countryCode, types } = param;
            return client.request({
                id: 'autocomplete/address',
                endpoint: '/autocomplete/address',
                query: {
                    address,
                    countryCode,
                    types
                },
                guard: isAddressAutocompleteResult,
                silent: true
            });
        }
    };
}
