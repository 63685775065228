import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { Timer } from '@leon-hub/utils';
import { localStorageManager } from '@leon-hub/local-storage';
let ResendSmsStopwatch = class ResendSmsStopwatch {
    saveCurrentTimeToLocalStorage() {
        const time = this.currentTickTime;
        if (time > 0) localStorageManager.setItem(this.localStorageTimeOutKey, `${Date.now() + 1000 * time}`);
        else localStorageManager.removeItem(this.localStorageTimeOutKey);
    }
    getCurrentTimeFromLocalStorage() {
        const expiredDate = Number(localStorageManager.getItem(this.localStorageTimeOutKey));
        return expiredDate ? Math.floor(Math.max(expiredDate - Date.now(), 0) / 1000) : 0;
    }
    async run(time) {
        if (this.currentTickTime > 0 && time > this.currentTickTime) return;
        await this.stop();
        await this.nextTick(time);
        this.saveCurrentTimeToLocalStorage();
    }
    async stop() {
        if (!this.timeOutId) return;
        Timer.clearTimeout(this.timeOutId);
        await this.syncCurrentTickTimeout(0);
    }
    async pause() {
        if (!this.currentTickTime) return;
        // save to local storage
        this.saveCurrentTimeToLocalStorage();
        // then drop current value
        await this.stop();
    }
    clear() {
        // drop timer to 0
        this.stop();
        // remove from local storage
        this.saveCurrentTimeToLocalStorage();
    }
    async syncCurrentTickTimeout(time) {
        this.currentTickTime = time;
        await this.syncStore(time);
    }
    async nextTick(time) {
        const actualTime = Math.max(time, 0);
        await this.syncCurrentTickTimeout(actualTime);
        if (actualTime <= 0) {
            this.stop();
            return;
        }
        if (this.timeOutId) Timer.clearTimeout(this.timeOutId);
        this.timeOutId = Timer.setTimeout(()=>{
            this.timeOutId = null;
            this.nextTick(actualTime - 1);
        }, 1000);
    }
    constructor(syncStore, localStorageTimeOutKey){
        _define_property(this, "syncStore", void 0);
        _define_property(this, "localStorageTimeOutKey", void 0);
        _define_property(this, "currentTickTime", void 0);
        _define_property(this, "timeOutId", void 0);
        this.syncStore = syncStore;
        this.localStorageTimeOutKey = localStorageTimeOutKey;
        this.currentTickTime = 0;
        this.timeOutId = null;
    }
};
export { ResendSmsStopwatch as default };
