import { getAvailableMeetingSlots } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
// eslint-disable-next-line max-len
export default function fetchAvailableMeetingSlots() {
    let toChangeTime = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    return getAvailableMeetingSlots(useGraphqlClient(), (node)=>node.queries.verification.getAvailableMeetingSlots, {
        options: {
            toChangeTime
        }
    });
}
