import { isArray, isArrayOfStrings, isNull, isNumber, isObject, isOptionalBoolean, isOptionalNumber, isOptionalString, isString } from '@leon-hub/guards';
export const isPendingBet = (value)=>{
    if (!isObject(value)) return false;
    const { betId, combiStatus, eventId, eventName, eventNameParts, isEnabledForCashout, itemsQuantity, odds, oddsType, outcomeSr, stake, acceptDate, freeBetId, transactionTypeId, isLive } = value;
    if (!(isString(eventName) || isArrayOfStrings(eventNameParts))) return false;
    return isNumber(betId) && isOptionalString(combiStatus) && isNumber(eventId) && (isOptionalBoolean(isEnabledForCashout) || isNull(isEnabledForCashout)) && isOptionalNumber(itemsQuantity) && isOptionalNumber(odds) && isOptionalString(oddsType) && isOptionalString(outcomeSr) && isNumber(stake) && isOptionalNumber(acceptDate) && (isNull(freeBetId) || isOptionalNumber(freeBetId)) && isOptionalNumber(transactionTypeId) && isOptionalBoolean(isLive);
};
const isPendingBetsArray = (value)=>{
    if (!isArray(value)) return false;
    return value.every((element)=>isPendingBet(element));
};
export default isPendingBetsArray;
