export function getOptionGroupsLimitedByCount(input, count) {
    let restCount = count;
    const result = [];
    for (const group of input){
        if (restCount <= 0) break;
        const groupItemsCount = group.options.length;
        if (groupItemsCount <= restCount) {
            result.push(group);
            restCount -= groupItemsCount;
        } else {
            const tempGroup = {
                caption: group.caption,
                options: group.options.slice(0, restCount)
            };
            restCount = 0;
            result.push(tempGroup);
        }
    }
    return result;
}
