import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportNoResults',
    props: {
        canResetFilter: {
            type: Boolean
        },
        sportlineIsEmpty: {
            type: Boolean
        }
    },
    emits: [
        "reset"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VJumbotron), {
                heading: _ctx.$t('WEB2_SPORTLINE_NO_EVENTS'),
                class: _normalizeClass(_ctx.$style['filter-no-results'])
            }, {
                footer: _withCtx(()=>[
                        _createElementVNode("div", null, [
                            _ctx.canResetFilter ? (_openBlock(), _createBlock(_unref(JumbotronFooterButton), {
                                key: 0,
                                kind: _unref(ButtonKind).BASE,
                                label: _ctx.$t('WEB2_RESET_FILTERS'),
                                "full-width": "",
                                onClick: _cache[0] || (_cache[0] = ($event)=>emit('reset'))
                            }, null, 8, [
                                "kind",
                                "label"
                            ])) : _createCommentVNode("", true)
                        ])
                    ]),
                default: _withCtx(()=>[
                        _ctx.sportlineIsEmpty ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SPORTLINE_NO_EVENTS_FILTER')) + " " + _toDisplayString(_ctx.$t('WEB2_OR')), 1)
                        ], 64))
                    ]),
                _: 1
            }, 8, [
                "heading",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'SportNoResults'
                ]
            ]);
        };
    }
});
