import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import VPieTimer from 'web/src/components/PieTimer/VPieTimer/VPieTimer.vue';
import { PieTimerSize } from 'web/src/components/PieTimer/VPieTimer/enums';
import { useBaseInputEvents } from '../../composables';
import { TextInputTypes } from '../../enums';
import TextInput from '../TextInput';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'PieTimerInput',
    props: {
        timerPercentage: {
            default: 0
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'pie-timer-input'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {},
        regexp: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { emitBlur, emitInput, emitFocus, emitChange } = useBaseInputEvents(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(_unref(TextInput), {
                autofocus: _ctx.autofocus,
                name: _ctx.name,
                label: _ctx.label,
                value: _ctx.value,
                disabled: _ctx.disabled,
                placeholder: _ctx.placeholder,
                hint: _ctx.hint,
                "hint-link": _ctx.hintLink,
                "hint-right": _ctx.hintRight,
                "hint-link-right": _ctx.hintLinkRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                type: _unref(TextInputTypes).TEL,
                regexp: _ctx.regexp,
                "is-large": _ctx.isLarge,
                autocomplete: "off",
                onBlur: _unref(emitBlur),
                onInput: _unref(emitInput),
                onFocus: _unref(emitFocus),
                onChange: _unref(emitChange)
            }, {
                iconSuffix: _withCtx(()=>[
                        _createVNode(VPieTimer, {
                            percentage: _ctx.timerPercentage,
                            size: _unref(PieTimerSize).SMALL
                        }, null, 8, [
                            "percentage",
                            "size"
                        ])
                    ]),
                _: 1
            }, 8, [
                "autofocus",
                "name",
                "label",
                "value",
                "disabled",
                "placeholder",
                "hint",
                "hint-link",
                "hint-right",
                "hint-link-right",
                "error",
                "error-right",
                "type",
                "regexp",
                "is-large",
                "onBlur",
                "onInput",
                "onFocus",
                "onChange"
            ])), [
                [
                    _directive_auto_id,
                    'PieTimerInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'pie-timer-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
