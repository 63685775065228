import { AbstractError } from '@leon-hub/errors';
import { SandboxApiErrorCode } from './SandboxApiErrorCode';
export class SandboxApiError extends AbstractError {
    constructor(options){
        super({
            ...options,
            code: new SandboxApiErrorCode('SANDBOX_API_ERROR_CODE')
        });
    }
}
