import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { addLeadingZero } from '@leon-hub/utils';
export var TimeFormats;
var TimeFormats1;
(TimeFormats1 = TimeFormats || (TimeFormats = {}))["hoursMinutes"] = "hh:mm", TimeFormats1["hoursMinutesSeconds"] = "hh:mm:ss", TimeFormats1["day"] = "dd", TimeFormats1["monthShortLocal"] = "MMM", TimeFormats1["dayMonth"] = "dd.MM", TimeFormats1["dayMonthShortYear"] = "dd.MM.yy", TimeFormats1["dayMonthYear"] = "dd.MM.yyyy", TimeFormats1["dayMonthYearHoursMinutes"] = "dd.MM.yyyy hh:mm", TimeFormats1["dayMonthHoursMinutes"] = "dd.MM hh:mm", TimeFormats1["minutesSeconds"] = "nn:ss", TimeFormats1["inputDate"] = "yyyy-MM-dd", TimeFormats1["inputDateTimeLocal"] = "yyyy-MM-ddThh:mm";
export var InputDateFormats;
var InputDateFormats1;
(InputDateFormats1 = InputDateFormats || (InputDateFormats = {}))["dayMonthYear"] = "dd.MM.yyyy", InputDateFormats1["inputDate"] = "yyyy-MM-dd";
let DateTime = class DateTime {
    static getDayMonthYearLocalized(locale, day, month, year) {
        switch(locale.toLowerCase()){
            case 'el_gr':
                return `${day}-${month}-${year}`;
            case 'de_de':
            case 'ru_ru':
            case 'ru_kz':
                return `${day}.${month}.${year}`;
            case 'en_ca':
            case 'en_nz':
                return `${year}-${month}-${day}`;
            case 'en_us':
            case 'en_in':
            case 'en_au':
            case 'en_se':
            case 'pt_pt':
            case 'br_br':
            case 'fr_fr':
            case 'fr_ca':
            case 'es_cl':
            case 'es_pe':
            case 'th_th':
            case 'vn_vn':
            case 'tr_tr':
            case 'it_it':
            case 'sw_tz':
            default:
                return `${day}/${month}/${year}`;
        }
    }
    static getPrettyDay(date) {
        const day = date.getDate();
        return day < 10 ? `0${day}` : `${day}`;
    }
    static getPrettyMonth(date) {
        const mm = date.getMonth() + 1;
        return addLeadingZero(mm, 2);
    }
    static getDay(date) {
        return `${DateTime.getPrettyDay(date)}`;
    }
    static getShortMonthLocalized(date, locale) {
        return `${date.toLocaleString(locale?.replace(/_/g, '-'), {
            month: 'short'
        })}`;
    }
    static getDayAndMonth(date) {
        return `${DateTime.getPrettyDay(date)}.${DateTime.getPrettyMonth(date)}`;
    }
    static getDayMonthYear(date, locale) {
        const dayMonth = DateTime.getDayAndMonth(date);
        const day = DateTime.getPrettyDay(date);
        const month = DateTime.getPrettyMonth(date);
        const year = date.getFullYear();
        return locale ? DateTime.getDayMonthYearLocalized(locale, day, month, year.toString()) : `${dayMonth}.${year}`;
    }
    static getDayMonthShortYear(date, locale) {
        const dayMonth = DateTime.getDayAndMonth(date);
        const day = DateTime.getPrettyDay(date);
        const month = DateTime.getPrettyMonth(date);
        const year = date.getFullYear();
        const shortYear = year.toString().slice(2, 4);
        return locale ? DateTime.getDayMonthYearLocalized(locale, day, month, shortYear.toString()) : `${dayMonth}.${shortYear}`;
    }
    static getDayMonthYearHoursMinutes(date) {
        const dayMonth = DateTime.getDayAndMonth(date);
        const year = date.getFullYear();
        const time = DateTime.getHoursAndMinutes(date);
        return `${dayMonth}.${year}\u00A0${time}`;
    }
    static getDayMonthHoursMinutes(date) {
        const dayMonth = DateTime.getDayAndMonth(date);
        const time = DateTime.getHoursAndMinutes(date);
        return `${dayMonth}\u00A0${time}`;
    }
    static getMinutesSeconds(date) {
        const mm = date.getMinutes();
        const ss = date.getSeconds();
        return `${addLeadingZero(mm, 2)}:${addLeadingZero(ss, 2)}`;
    }
    static getInputDateTimeLocal(date) {
        const day = DateTime.getPrettyDay(date);
        const month = DateTime.getPrettyMonth(date);
        const year = date.getFullYear();
        const time = DateTime.getHoursAndMinutes(date);
        return `${year}-${month}-${day}T${time}`;
    }
    static getInputDate(date) {
        const day = DateTime.getPrettyDay(date);
        const month = DateTime.getPrettyMonth(date);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }
    static getTimezoneOffset() {
        let timestamp = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
        const date = new Date(timestamp);
        return date.getTimezoneOffset();
    }
    static formatTimeStamp(timestamp, format, locale) {
        const date = new Date(timestamp);
        switch(format){
            case "hh:mm":
                return DateTime.getHoursAndMinutes(date);
            case "hh:mm:ss":
                return DateTime.getHoursMinutesSeconds(date);
            case "dd.MM":
                return DateTime.getDayAndMonth(date);
            case "dd.MM.yyyy":
                return DateTime.getDayMonthYear(date, locale);
            case "dd.MM.yy":
                return DateTime.getDayMonthShortYear(date, locale);
            case "dd.MM.yyyy hh:mm":
                return DateTime.getDayMonthYearHoursMinutes(date);
            case "dd.MM hh:mm":
                return DateTime.getDayMonthHoursMinutes(date);
            case "nn:ss":
                return DateTime.getMinutesSeconds(date);
            case "yyyy-MM-dd":
                return DateTime.getInputDate(date);
            case "yyyy-MM-ddThh:mm":
                return DateTime.getInputDateTimeLocal(date);
            case "dd":
                return DateTime.getDay(date);
            case "MMM":
                return DateTime.getShortMonthLocalized(date, locale);
            default:
                throw new Error(`Unexpected format: "${format}"`);
        }
    }
    static roundToDayStart(timestamp) {
        const date = 'number' == typeof timestamp || 'string' == typeof timestamp ? new Date(timestamp) : timestamp;
        date.setHours(0, 0, 0, 0);
        return date.getTime();
    }
    static roundToDayEnd(timestamp) {
        const date = 'number' == typeof timestamp || 'string' == typeof timestamp ? new Date(timestamp) : timestamp;
        date.setHours(23, 59, 59, 999);
        return date.getTime();
    }
    static withTimeStamp(timestamp) {
        return new DateTime(timestamp);
    }
    static toTimestamp(date) {
        const dateInstance = 'string' == typeof date || 'number' == typeof date ? new Date(date) : date;
        return Number(dateInstance);
    }
    static timestampSecondsToDate(timestampSeconds) {
        let toUTC = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        if (toUTC) {
            const unixStartDate = new Date(Date.UTC(1970, 0, 1));
            unixStartDate.setUTCSeconds(timestampSeconds);
            return unixStartDate;
        }
        const date = new Date(1970, 0, 1);
        date.setSeconds(timestampSeconds);
        return date;
    }
    static now() {
        return new DateTime(Date.now());
    }
    getTimestamp(date, format) {
        const { from, to } = this.dateNormalization[format];
        return new Date(date.replace(from, to)).getTime();
    }
    toISOString() {
        return new Date(this.timestamp).toISOString();
    }
    toTimestamp() {
        return this.timestamp;
    }
    toTime() {
        return DateTime.formatTimeStamp(this.timestamp, "hh:mm");
    }
    toDate() {
        return DateTime.formatTimeStamp(this.timestamp, "dd.MM");
    }
    toFullDate() {
        return DateTime.formatTimeStamp(this.timestamp, "dd.MM.yyyy");
    }
    toDateTime() {
        return DateTime.formatTimeStamp(this.timestamp, "dd.MM.yyyy hh:mm");
    }
    toShortDateTime() {
        return DateTime.formatTimeStamp(this.timestamp, "dd.MM hh:mm");
    }
    toInputDate() {
        return DateTime.formatTimeStamp(this.timestamp, "yyyy-MM-dd");
    }
    toInputDateTimeLocal() {
        return DateTime.formatTimeStamp(this.timestamp, "yyyy-MM-ddThh:mm");
    }
    constructor(time, format){
        _define_property(this, "dateNormalization", {
            ["dd.MM.yyyy"]: {
                from: /^(\d{2})\.(\d{2})\.(\d{4})$/,
                to: '$3-$2-$1'
            },
            ["yyyy-MM-dd"]: {
                from: /^/,
                to: ''
            }
        });
        _define_property(this, "timestamp", void 0);
        if ('number' == typeof time) this.timestamp = time;
        else if (format) this.timestamp = this.getTimestamp(time, format);
        else this.timestamp = parseInt(time, 10);
        if (Number.isNaN(this.timestamp)) throw new TypeError(`Unexpected timestamp: "${time}"`);
    }
};
_define_property(DateTime, "getHoursAndMinutes", (date)=>{
    const hh = date.getHours();
    const mm = date.getMinutes();
    return `${addLeadingZero(hh, 2)}:${addLeadingZero(mm, 2)}`;
});
_define_property(DateTime, "getHoursMinutesSeconds", (date)=>{
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    return `${addLeadingZero(hh, 2)}:${addLeadingZero(mm, 2)}:${addLeadingZero(ss, 2)}`;
});
export { DateTime as default };
