import { _ as _define_property } from "@swc/helpers/_/_define_property";
import requestIdleCallback from './requestIdleCallback';
let IdleTimeQueueManager = class IdleTimeQueueManager {
    static getInstance() {
        if (!IdleTimeQueueManager.instance) IdleTimeQueueManager.instance = new IdleTimeQueueManager();
        return IdleTimeQueueManager.instance;
    }
    runTaskQueue(deadline) {
        while((deadline.timeRemaining() > 0 || deadline.didTimeout) && this.taskList.length){
            const task = this.taskList.shift();
            this.currentTaskNumber += 1;
            task.handler(task.payload);
            this.totalTasksHaveBeenCompleted += 1;
            this.totalTasksToComplete -= 1;
        }
        if (this.taskList.length) this.taskHandle = requestIdleCallback(this.runTaskQueue.bind(this), {
            timeout: this.options.timeout
        });
        else this.taskHandle = 0;
    }
    enqueueTask(taskHandler, payload) {
        this.taskList.push({
            handler: taskHandler,
            payload
        });
        this.totalTasksToComplete += 1;
        if (!this.taskHandle) this.taskHandle = requestIdleCallback(this.runTaskQueue.bind(this), {
            timeout: this.options.timeout
        });
    }
    constructor(options = {
        timeout: 1000
    }){
        _define_property(this, "options", void 0);
        _define_property(this, "taskList", void 0);
        _define_property(this, "currentTaskNumber", void 0);
        _define_property(this, "totalTasksToComplete", void 0);
        _define_property(this, "totalTasksHaveBeenCompleted", void 0);
        _define_property(this, "taskHandle", void 0);
        this.options = options;
        this.taskList = [];
        this.currentTaskNumber = 0;
        this.totalTasksToComplete = 0;
        this.totalTasksHaveBeenCompleted = 0;
        this.taskHandle = void 0;
    }
};
_define_property(IdleTimeQueueManager, "instance", void 0);
export { IdleTimeQueueManager as default };
