import { computed } from 'vue';
import { useProvideSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
/**
 * @deprecated fallback for the old components
 */ export function useProvideMarketColumn(props) {
    const { isMarketTypesSelectionEnabled, marketsColumns, marketsTypes, basisKey } = props;
    const sportlineElements = computed(()=>[
            {
                marketsColumns: marketsColumns.value,
                marketsTypes: marketsTypes.value,
                key: basisKey.value
            }
        ]);
    useProvideSelectedColumn({
        sportlineElements
    });
    const canSelectMarketType = computed(()=>isMarketTypesSelectionEnabled.value && marketsColumns.value.length > 0);
    return {
        canSelectMarketType
    };
}
