import { defineStore } from 'pinia';
import { toRef, computed, ref, watch } from 'vue';
import { ensure, isTruthyString } from '@leon-hub/guards/src';
import { useRootStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useTheme } from 'web/src/modules/theme/composables';
import getCasApi from './utils/getCasApi';
import { useAuthStore } from '../../auth/store';
const useCasAuthStore = defineStore('cas-auth', ()=>{
    const api = getCasApi();
    const { theme } = useTheme();
    const rootStore = useRootStore();
    const i18nStore = useI18nStore();
    const ssoServerUrl = toRef(rootStore, 'ssoServerUrl');
    const isLoggedIn = toRef(useAuthStore(), 'isLoggedIn');
    const siteConfigStore = useSiteConfigStore();
    const isCasAuthEnabled = toRef(siteConfigStore, 'isCasAuthEnabled');
    const usernames = ref([]);
    const isCookiesOk = ref(false);
    const ticket = ref('');
    let apiRequestPromise = null;
    const isEnabled = computed(()=>isCasAuthEnabled.value && !!ssoServerUrl.value);
    const authUrl = computed(()=>`${ssoServerUrl.value || ensure(isTruthyString, process.env.VUE_APP_LEONCAS_ORIGIN, 'Expected VUE_APP_LEONCAS_ORIGIN to be defined')}/${i18nStore.locale.split('_')[0]}/?theme=${theme.value.toLowerCase()}`);
    let casUserLoginCache;
    // Reset cas cache on changed user auth state.
    watch(isLoggedIn, ()=>{
        casUserLoginCache = void 0;
    });
    async function getSavedLogins() {
        if (isEnabled.value && !process.env.VUE_APP_PRERENDER) {
            if (apiRequestPromise) return apiRequestPromise;
            if (casUserLoginCache) return Promise.resolve();
            apiRequestPromise = (async ()=>{
                try {
                    const result = await api.getSavedLogins({
                        endpoint: ssoServerUrl.value
                    });
                    usernames.value = result.usernames;
                    isCookiesOk.value = result.cookiesOk;
                    casUserLoginCache = {
                        ...result
                    };
                } catch  {} finally// eslint-disable-next-line no-console
                {
                    apiRequestPromise = null;
                }
            })();
            return apiRequestPromise;
        }
        return Promise.resolve();
    }
    async function loginCas(param) {
        let { username, password } = param;
        if (isEnabled.value) {
            const response = await api.loginCas({
                username,
                password,
                endpoint: ssoServerUrl.value
            });
            if (response) ticket.value = response.ticket;
        }
    }
    async function checkCookie() {
        if (isEnabled.value) {
            await api.checkCookie({
                endpoint: ssoServerUrl.value
            });
            isCookiesOk.value = true;
        }
    }
    function removeUser(username) {
        usernames.value = usernames.value.filter((item)=>item !== username);
    }
    async function deleteSavedLogins(param) {
        let { username } = param;
        if (isEnabled.value) try {
            const response = await api.deleteSavedLogins({
                username,
                endpoint: ssoServerUrl.value
            });
            if (response) removeUser(username);
        } catch  {}
    }
    // eslint-disable-next-line no-console
    return {
        usernames,
        isCookiesOk,
        authUrl,
        ticket,
        getSavedLogins,
        loginCas,
        checkCookie,
        deleteSavedLogins
    };
});
export default useCasAuthStore;
