import { lobbyTypeFastgames, lobbyTypeLiveCasino, lobbyTypeLiveGamePage, lobbyTypeSearch, lobbyTypeSlots, lobbyTypeSlotsGamePage } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import RouteName from '@leon-hub/routing-config-names';
export default function getLobbyTypeByRouteName(routeName) {
    switch(routeName){
        case CasinoRouteName.CASINO_LIVE_LOBBY:
        case CasinoRouteName.CASINO_LIVE_GROUPS:
            return lobbyTypeLiveCasino;
        case CasinoRouteName.CASINO_LIVE_GAME:
            return lobbyTypeLiveGamePage;
        case CasinoRouteName.CASINO_GAME:
            return lobbyTypeSlotsGamePage;
        case CasinoRouteName.CASINO_SEARCH:
            return lobbyTypeSearch;
        case RouteName.QUICK_BETS:
        case RouteName.QUICK_BETS_DETAILS:
            return lobbyTypeFastgames;
        case RouteName.HOME:
            return lobbyTypeSlots;
        default:
            return lobbyTypeSlots;
    }
}
