import { computed, onMounted, onUnmounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { BonusCampaignType } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import RouteName from '@leon-hub/routing-config-names';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
import { getButtonText, getIsDisabled, getOrderId, getTagButton } from 'web/src/modules/bonuses/pages/BonusLoyaltyShopDetailsRoutePage/composables/utils';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import useLoyaltyShopStore from 'web/src/modules/bonuses/store/useLoyaltyShopStore';
import useHomePageType from 'web/src/modules/core/store/composables/useHomePageType';
import getDialogProps from './utils/getDialogProps';
export default function useBonusLoyaltyShopDetailsRoutePage(props) {
    const router = useRouter();
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const { showDialog, closeDialog } = useDialogs();
    const { navigateToGamePageById } = useEgsNavigation(router);
    const analytics = useAnalytics();
    const loyaltyShopStore = useLoyaltyShopStore();
    const { routeNameToSportLine } = useHomePageType();
    const currentOffer = toRef(loyaltyShopStore, 'currentOffer');
    const orderCategory = toRef(loyaltyShopStore, 'orderCategory');
    const offers = toRef(loyaltyShopStore, 'offers');
    const freespinGameIds = toRef(loyaltyShopStore, 'freespinGameIds');
    const hasError = toRef(loyaltyShopStore, 'hasError');
    const bonusLoyaltyStore = useBonusLoyaltyStore();
    const amountNumber = toRef(bonusLoyaltyStore, 'amountNumber');
    const openedModalId = ref('');
    const isLoading = ref(true);
    const currentOfferDes = computed(()=>currentOffer.value?.description ?? '');
    const currentOfferPrice = computed(()=>currentOffer.value?.price ?? 0);
    const currentOfferTitle = computed(()=>currentOffer.value?.name ?? '');
    const currentOfferImageSrc = computed(()=>currentOffer.value?.imageUrl ?? '');
    const currentOfferImage = computed(()=>currentOffer.value?.image ?? null);
    const isCurrentOfferImage = computed(()=>!!currentOfferImage.value?.src);
    const orderId = computed(()=>getOrderId(props));
    const isDisabled = computed(()=>getIsDisabled(currentOfferPrice.value, amountNumber.value));
    const buttonText = computed(()=>getButtonText($translate, $formatMoney, isDisabled.value, currentOfferPrice.value));
    const tagButton = computed(()=>getTagButton(isDisabled.value));
    const closeOpenedModal = ()=>{
        if (openedModalId.value) closeDialog(openedModalId.value);
    };
    const pushAnalytic = (leonshop)=>{
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                leonshop
            }
        });
    };
    const onClick = async ()=>{
        if (isDisabled.value) {
            pushAnalytic('notEnoughLeons');
            return;
        }
        pushAnalytic('buy');
        await loyaltyShopStore.doCustomerOrder({
            orderId: orderId.value
        });
        const onDialogActionConfirm = ()=>{
            let name = RouteName.HOME;
            "1";
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            if (orderCategory.value === BonusCampaignType.SPORT) name = routeNameToSportLine.value;
            else if (freespinGameIds.value.length) {
                const gameId = freespinGameIds.value[0];
                navigateToGamePageById(gameId).catch(()=>{
                    router.push({
                        name: CasinoRouteName.CASINO_LOBBY
                    });
                });
            } else name = CasinoRouteName.CASINO_LOBBY;
            router.push({
                name
            }).then(closeOpenedModal);
            return false;
        };
        if (!hasError.value) {
            const { subscribe, id } = showDialog(getDialogProps($translate));
            openedModalId.value = id;
            subscribe({
                [DialogAction.CONFIRM]: onDialogActionConfirm,
                [DialogAction.MODAL_CLOSE]: ()=>{
                    pushAnalytic('close_successScreen');
                },
                [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                    router.back();
                    pushAnalytic('returnToShop_successScreen');
                }
            });
        }
    };
    onMounted(async ()=>{
        if (!offers.value.length) await loyaltyShopStore.fetchCustomerOffers();
        loyaltyShopStore.setSelectedItemId(orderId.value);
        if (currentOffer.value) await bonusLoyaltyStore.fetchBonusLoyalty();
        else await router.push({
            name: RouteName.LOYALTY_PROGRAM
        });
        isLoading.value = false;
    });
    onUnmounted(()=>{
        loyaltyShopStore.setSelectedItemId(null);
        closeOpenedModal();
    });
    return {
        isLoading,
        onClick,
        isDisabled,
        buttonText,
        tagButton,
        currentOfferDes,
        currentOfferPrice,
        currentOfferTitle,
        currentOfferImageSrc,
        isCurrentOfferImage,
        currentOfferImage
    };
}
