import { bannerVersionV3 } from '@leon-hub/api-sdk';
export default function getBannerClass(props, isVisible, hasContentBlock) {
    const classList = {
        'headline-bn': true,
        [`headline-bn--${props.bannerType}`]: !!props.bannerType,
        'headline-bn--hidden': !isVisible,
        'headline-bn--no-min-height': !!props.noMinHeight,
        'headline-bn--big-height': !!props.isBigHeight,
        'headline-bn--sport': !!props.hasEventDetails,
        'headline-bn--without-text': !hasContentBlock,
        'headline-bn--half-width': !!props.isHalfWidth,
        'headline-bn--v3': props.bannerVersion === bannerVersionV3
    };
    if (props.animation) classList[`headline-bn--animation-${props.animation.toLowerCase().replace(/_/g, '-')}`] = true;
    return classList;
}
