import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useWebPushModal } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WebPushModalRouteComponent',
    setup (__props) {
        const ModalComponent = require('../WebPushDesktopModal/WebPushDesktopModal.vue').default;
        const { modalProps, onClose, onButtonClick } = useWebPushModal();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(ModalComponent), _mergeProps(_unref(modalProps), {
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }), null, 16, [
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'WebPushModalRouteComponent'
                ]
            ]);
        };
    }
});
