import { normalizeDate } from './normalizeDate';
import { addLeadingZero } from '../../string/addLeadingZero';
export const replacePlaceholders = (param)=>{
    let { date, pattern, getMonth } = param;
    const d = normalizeDate(date);
    const dDate = d.getDate();
    const dMonth = d.getMonth();
    const dFullYear = String(d.getFullYear());
    return pattern.replace(/(^|[^d])(d{2})($|[^d])/g, `$1${String(dDate)}$3`).replace(/(^|[^D])(D{2})($|[^D])/g, `$1${addLeadingZero(dDate, 2)}$3`).replace(/(^|[^M])(M{4})($|[^M])/g, `$1${getMonth({
        id: dMonth,
        format: 'long'
    })}$3`).replace(/(^|[^M])(M{2})($|[^M])/g, `$1${getMonth({
        id: dMonth,
        format: 'short'
    })}$3`).replace(/(^|[^y])(y{4})($|[^y])/g, `$1${dFullYear}$3`).replace(/(^|[^y])(y{2})($|[^y])/g, `$1${dFullYear.slice(-2)}$3`);
};
