import { computed, toRef } from 'vue';
import { lobbyTypeFastgames } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useLobbyStore } from 'web/src/modules/lobby/store';
export default function useQuickBetsLobbyPage() {
    const lobbyStore = useLobbyStore();
    const isLoadingLobby = lobbyStore.getIsLoading(lobbyTypeFastgames);
    const siteConfigStore = useSiteConfigStore();
    const egsSkeletons = toRef(siteConfigStore, 'egsSkeletons');
    const skeletons = computed(()=>egsSkeletons.value?.fastGames ?? []);
    const lobbyType = toRef(lobbyStore, 'activeType');
    const groupId = toRef(lobbyStore, 'activeGroupId');
    const categoryId = toRef(lobbyStore, 'activeCategoryId');
    return {
        isLoadingLobby,
        skeletons,
        lobbyType,
        groupId,
        categoryId
    };
}
