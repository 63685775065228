import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
export default function useCasinoProviderSelect(props, emits) {
    const iconProperties = computed(()=>{
        const name = IconName.FILTER;
        if (props.inSidebar) return {
            size: IconSize.SIZE_20,
            name
        };
        return {
            size: IconSize.SIZE_24,
            name
        };
    });
    function emitClick(evt) {
        emits('click', evt);
    }
    return {
        iconProperties,
        emitClick
    };
}
