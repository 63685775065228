import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
export function useSportlineCatalogClick(props) {
    const { eventId, sportId, regionId, leagueId } = props;
    const bus = useEventsBus();
    function getFinalPayload() {
        const result = {};
        if (eventId?.value) result.eventId = eventId.value;
        if (sportId?.value) result.eventId = sportId.value;
        if (regionId?.value) result.eventId = regionId.value;
        if (leagueId?.value) result.eventId = leagueId.value;
        return result;
    }
    function onCatalogClick(event) {
        const payload = getFinalPayload();
        bus.emit(BusEvent.CATALOG_CLICK, {
            event,
            payload
        });
    }
    return {
        onCatalogClick
    };
}
