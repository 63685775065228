import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { VCoreDropdown } from 'web/src/components/CoreDropdown';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DropListSelectDropdown',
    props: {
        dropdownProps: {},
        isBehindModals: {
            type: Boolean
        },
        searchEnabled: {
            type: Boolean
        },
        dropDirection: {},
        isFooterShown: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "preselect",
        "return-focus",
        "update-position"
    ],
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.dropdownProps ? _withDirectives((_openBlock(), _createBlock(_unref(VCoreDropdown), _mergeProps({
                key: 0
            }, _ctx.dropdownProps, {
                "match-parent-width": "",
                "ignore-active-element-click": "",
                "vertical-offset": 10,
                "is-behind-modals": _ctx.isBehindModals,
                "drop-direction": _ctx.dropDirection,
                onClose: _cache[3] || (_cache[3] = ($event)=>_ctx.$emit('close')),
                onUpdatePosition: _cache[4] || (_cache[4] = ($event)=>_ctx.$emit('update-position'))
            }), {
                default: _withCtx((param)=>{
                    let { maxHeight, mounted, directionTop, onContentRedraw } = param;
                    return [
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['drop-list-menu']]: true,
                                [_ctx.$style['drop-list-menu--direction-top']]: directionTop
                            })
                        }, [
                            _createElementVNode("form", {
                                class: _normalizeClass(_ctx.$style['drop-list-menu__background']),
                                style: _normalizeStyle(maxHeight ? {
                                    'max-height': `${maxHeight}px`
                                } : {}),
                                autocomplete: "off",
                                onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event)=>_ctx.$emit('preselect'), [
                                    "stop",
                                    "prevent"
                                ])),
                                onClick: _cache[2] || (_cache[2] = _withModifiers(($event)=>_ctx.$emit('return-focus'), [
                                    "stop"
                                ]))
                            }, [
                                _ctx.searchEnabled ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['drop-list-menu__search'])
                                }, [
                                    _createElementVNode("div", {
                                        onClick: _cache[0] || (_cache[0] = _withModifiers(()=>{}, [
                                            "stop"
                                        ]))
                                    }, [
                                        _renderSlot(_ctx.$slots, "search")
                                    ])
                                ], 2)) : _createCommentVNode("", true),
                                _renderSlot(_ctx.$slots, "content", {
                                    mounted: mounted,
                                    onContentRedraw: onContentRedraw
                                }),
                                _ctx.isFooterShown ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['drop-list-menu__footer'])
                                }, [
                                    _renderSlot(_ctx.$slots, "footer")
                                ], 2)) : _createCommentVNode("", true)
                            ], 38)
                        ], 2)
                    ];
                }),
                _: 3
            }, 16, [
                "is-behind-modals",
                "drop-direction"
            ])), [
                [
                    _directive_auto_id,
                    'DropListSelectDropdown'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
