import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_2 = [
    "title"
];
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BalanceValue',
    props: {
        value: {},
        caption: {},
        balanceLabel: {
            default: ''
        },
        type: {
            default: 'balance'
        }
    },
    setup (__props) {
        const props = __props;
        computed(()=>props.value.replace(/[.,\s]/g, ''));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['balance-value-wrapper'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['balance-value']]: true,
                            [_ctx.$style[`balance-value--type__${_ctx.type}`]]: !!_ctx.type
                        }),
                        role: "note",
                        title: _ctx.balanceLabel,
                        tabindex: "0"
                    }, _toDisplayString(_ctx.value), 11, _hoisted_2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['balance-caption'])
                    }, _toDisplayString(_ctx.caption), 3)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'BalanceValue'
                ]
            ]);
        };
    }
});
