import { ref } from 'vue';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { useExtendedEventStore } from '../store';
export function useIsEventExtended(param) {
    let { sportlineEventId, blockType } = param;
    const { createEventIsOpenedRef } = useExtendedEventStore();
    const type = blockType ?? ref(ExtendedEventBlock.Default);
    const isEventExtended = createEventIsOpenedRef(sportlineEventId, type);
    return {
        isEventExtended
    };
}
