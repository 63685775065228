import { ref } from 'vue';
import { updatePreparedToActionList } from 'web/src/modules/sportline/submodules/favorites/utils';
export function usePendingToFavoriteStoreComposable() {
    const preparedToAdd = ref([]);
    const preparedToRemove = ref([]);
    function setPrepareToAddState(param) {
        let { id, state } = param;
        const currentList = preparedToAdd.value;
        const prepared = updatePreparedToActionList({
            id,
            state,
            currentList
        });
        if (prepared.isChanged) preparedToAdd.value = prepared.list;
    }
    function setPrepareToRemoveState(param) {
        let { id, state } = param;
        const currentList = preparedToRemove.value;
        const prepared = updatePreparedToActionList({
            id,
            state,
            currentList
        });
        if (prepared.isChanged) preparedToRemove.value = prepared.list;
    }
    function setPreparedState(isFavorite, param) {
        let { id, state } = param;
        if (isFavorite) setPrepareToRemoveState({
            id,
            state
        });
        else setPrepareToAddState({
            id,
            state
        });
    }
    return {
        preparedToAdd,
        preparedToRemove,
        setPrepareToAddState,
        setPrepareToRemoveState,
        setPreparedState
    };
}
