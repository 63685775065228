import { isObject, isUndefined, isValidInterface, isString } from '@leon-hub/guards';
import { LiveChatMessageType } from './enums';
export function isLiveChatBaseResponse(argument) {
    return isObject(argument) && 'string' == typeof argument.result && [
        'OK',
        'ERROR'
    ].includes(argument.result);
}
export function isLiveChatMessageType(argument) {
    return Object.values(LiveChatMessageType).includes(argument);
}
export function isLiveChatConversationItem(argument) {
    return isObject(argument) && 'string' == typeof argument.actionType && 'number' == typeof argument.date && isLiveChatMessageType(argument.messageType) && 'string' == typeof argument.message && 'string' == typeof argument.uid && Array.isArray(argument.files) && argument.files.every((file)=>isObject(file) && 'string' == typeof file.url && 'string' == typeof file.name);
}
export function isLiveChatGetData(argument) {
    return isObject(argument) && 'string' == typeof argument.question && 'number' == typeof argument.status && 'number' == typeof argument.started && Array.isArray(argument.conversation) && argument.conversation.every((element)=>isLiveChatConversationItem(element)) && 'boolean' == typeof argument.typing;
}
export function isLiveChatOpenData(argument) {
    return isObject(argument) && 'boolean' == typeof argument.chatActive && 'boolean' == typeof argument.supportActive && 'number' == typeof argument.chatStatus;
}
export function isLiveChatStartData(argument) {
    return isObject(argument) && ('boolean' == typeof argument.chatActive && 'string' == typeof argument.question || 'string' == typeof argument.errorStatus);
}
export function isLiveChatCheckData(argument) {
    return isObject(argument) && 'boolean' == typeof argument.active && 'number' == typeof argument.chatStatus;
}
export function isLiveChatErrorData(argument) {
    return isValidInterface(argument, {
        result: [
            (value)=>'ERROR' === value
        ],
        errorStatus: [
            isUndefined,
            isString
        ],
        errorMessage: [
            isUndefined,
            isString
        ]
    });
}
