import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { LiveChatStatus } from '../enums';
let DefaultLiveChatObject = class DefaultLiveChatObject {
    constructor(status){
        _define_property(this, "question", '');
        _define_property(this, "status", LiveChatStatus.ENDED);
        _define_property(this, "started", Date.now());
        _define_property(this, "conversation", []);
        _define_property(this, "typing", false);
        _define_property(this, "hasSurvey", false);
        _define_property(this, "isInputDisabled", false);
        _define_property(this, "isSurveyVisible", false);
        _define_property(this, "actions", []);
        _define_property(this, "surveyMessage", '');
        _define_property(this, "surveyRating", 0);
        if (status) this.status = status;
    }
};
export { DefaultLiveChatObject as default };
