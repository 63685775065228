function sportEventElementToFlatted(eventElement) {
    return {
        key: eventElement.sportEvent.id,
        eventElement
    };
}
function leagueElementToFlatted(leagueElement, options) {
    const regular = options?.doExcludeRegularEvents ? [] : leagueElement.sportEvents.map(sportEventElementToFlatted).map((sportEventElement)=>({
            ...sportEventElement,
            league: leagueElement.league
        }));
    const outright = options?.doExcludeOutrightEvents ? [] : leagueElement.outrightEvents.map(sportEventElementToFlatted).map((sportEventElement)=>({
            ...sportEventElement,
            league: leagueElement.league
        }));
    return [
        ...regular,
        ...outright
    ];
}
function regionElementToFlatted(regionElement, options) {
    return regionElement.leagues.flatMap((leagueElement)=>leagueElementToFlatted(leagueElement, options)).map((leagueElement)=>({
            ...leagueElement,
            region: regionElement.region
        }));
}
function sportElementToFlatted(sportElement, options) {
    return sportElement.regions.flatMap((regionElement)=>regionElementToFlatted(regionElement, options)).map((regionElement)=>({
            ...regionElement,
            sport: sportElement.sport
        }));
}
export function flatMapLeagueElementsList(sport, region, leagueElements, options) {
    return leagueElements.flatMap((leagueElement)=>leagueElementToFlatted(leagueElement, options)).map((leagueElement)=>({
            ...leagueElement,
            region,
            sport
        }));
}
export function flatMapRegionElementsList(sport, regionElements, options) {
    return regionElements.flatMap((regionElement)=>regionElementToFlatted(regionElement, options)).map((regionElement)=>({
            ...regionElement,
            sport
        }));
}
export function flatMapSportElementsList(sportElements, options) {
    return sportElements.flatMap((sportElement)=>sportElementToFlatted(sportElement, options));
}
