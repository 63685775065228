import { logger } from '@leon-hub/logging';
export function getNavigationItemRouteLocation(navigationItem, position) {
    const { routeName, props } = navigationItem;
    let routerParameters;
    if (props?.routeParams) try {
        routerParameters = JSON.parse(`${props.routeParams}`);
    } catch (error) {
        logger.error(`getNavigationItemRouteLocation at ${position}: wrong routeParams: ${props.routeParams}`, error);
    }
    return {
        name: routeName,
        params: routerParameters
    };
}
