import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, computed, ref } from 'vue';
import { useMarketColumns } from 'web/src/modules/sportline/composables/list';
import { useSportlineElementMarketColumns, useSportlineElementMarketTypes } from 'web/src/modules/sportline/composables/markets';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { useMarketRunners, useSlipCaption } from 'web/src/modules/sportline/views/runner/composables';
import { selectMarketTypeByColumn } from 'web/src/modules/sportline/utils/markets';
import PrimaryMarketRunner from 'web/src/modules/sportline/views/runner/PrimaryMarketRunner.vue';
// @TODO use SportlineEvent instead of SportEventDetails
export default /*@__PURE__*/ _defineComponent({
    __name: 'PrimaryMarket',
    props: {
        market: {},
        sportElement: {},
        sportEventDetails: {},
        doubleGap: {
            type: Boolean
        },
        isPrimaryMarketV2: {
            type: Boolean
        }
    },
    emits: [
        "click-runner"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const market = toRef(props, 'market');
        const sportElement = toRef(props, 'sportElement', null);
        const sportEvent = toRef(props, 'sportEventDetails');
        const sportEventId = computed(()=>sportEvent.value.id);
        const sport = computed(()=>sportElement.value?.sport ?? sportEvent.value.sport);
        const marketType = computed(()=>market.value.type);
        const markets = computed(()=>sportEvent.value.markets);
        const { marketsColumns } = useSportlineElementMarketColumns({
            sportlineElement: sportElement
        });
        const { marketsTypes } = useSportlineElementMarketTypes({
            sportlineElement: sportElement
        });
        const selectedColumnId = ref(null);
        const selectedMarketType = computed(()=>selectMarketTypeByColumn({
                columns: marketsColumns.value,
                allMarketsTypes: marketsTypes.value,
                selectedColumnId: selectedColumnId.value
            }));
        const selectedColumnSize = computed(()=>selectedMarketType.value?.columns ?? null);
        const { actualMarketType, actualMarketTypePriority, actualMarket } = useMarketColumns({
            sport,
            selectedColumnSize,
            selectedColumnId,
            columns: marketsColumns,
            markets
        });
        const { isOutright, sportlineType } = useSportlineEventType({
            sportEvent
        });
        const { runnersPlaces } = useMarketRunners({
            sport,
            market: actualMarket,
            marketType
        });
        const { slipCaption } = useSlipCaption({
            sportlineEvent: sportEvent
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['primary-market__wrapper'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['primary-market']]: true,
                        [_ctx.$style['primary-market--v2']]: _ctx.isPrimaryMarketV2
                    })
                }, [
                    _unref(actualMarketType) && _unref(actualMarketTypePriority) > 0 ? _renderSlot(_ctx.$slots, "top", {
                        key: 0,
                        marketName: _unref(actualMarketType).name
                    }) : _createCommentVNode("", true),
                    _ctx.isPrimaryMarketV2 ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['primary-market__market-name'])
                    }, _toDisplayString(market.value.name), 3)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['primary-market__runners']]: true,
                            [_ctx.$style['primary-market__runners--double-gap']]: _ctx.doubleGap
                        })
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(runnersPlaces), (runner, index)=>(_openBlock(), _createElementBlock("div", {
                                key: runner?.id ?? index,
                                class: _normalizeClass(_ctx.$style['primary-market__runner'])
                            }, [
                                _createVNode(PrimaryMarketRunner, {
                                    runner: runner,
                                    market: _unref(actualMarket),
                                    "slip-caption": _unref(slipCaption),
                                    "sport-event-id": sportEventId.value,
                                    "sportline-type": _unref(sportlineType),
                                    "is-outright-event": _unref(isOutright),
                                    "is-runner-v2": _ctx.isPrimaryMarketV2,
                                    onClickRunner: _cache[0] || (_cache[0] = ($event)=>emit('click-runner', $event))
                                }, null, 8, [
                                    "runner",
                                    "market",
                                    "slip-caption",
                                    "sport-event-id",
                                    "sportline-type",
                                    "is-outright-event",
                                    "is-runner-v2"
                                ])
                            ], 2))), 128))
                    ], 2),
                    _renderSlot(_ctx.$slots, "bottom", {
                        hasZeroMargin: !!_unref(actualMarket)?.hasZeroMargin
                    })
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PrimaryMarket'
                ]
            ]);
        };
    }
});
