import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import RouteName from '@leon-hub/routing-config-names';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useVReferralProgramStep(props) {
    const router = useRouter();
    const analytics = useAnalytics();
    const iconProps = computed(()=>({
            size: IconSize.SIZE_20,
            name: props.satisfied ? IconName.CHECK_FILLED : IconName.FAKE_CHECKBOX
        }));
    function handleClick() {
        if (props.to) {
            router.push({
                name: props.to
            });
            let steps = '';
            switch(props.to){
                case RouteName.DEPOSITS:
                    steps = 'deposit';
                    break;
                case RouteName.SPORTLINE_TOP_EVENTS:
                    steps = 'spin_bet';
                    break;
                default:
                    steps = 'verification';
                    break;
            }
            analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
                referFriendProgram: {
                    noAccessPage: {
                        steps
                    }
                }
            });
        }
    }
    return {
        iconProps,
        handleClick
    };
}
