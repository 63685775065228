import { CountryFlag } from 'web/src/components/CountryFlag/VCountryFlag/enums';
/**
 * These positions are used for flags.png
 * @TODO add "an" Netherlands Antilles flag to sprites list
 */ export const flagPosition = {
    [CountryFlag.AD]: [
        -1,
        -1
    ],
    [CountryFlag.AE]: [
        -35,
        -1
    ],
    [CountryFlag.AF]: [
        -69,
        -1
    ],
    [CountryFlag.AG]: [
        -103,
        -1
    ],
    [CountryFlag.AI]: [
        -137,
        -1
    ],
    [CountryFlag.AL]: [
        -171,
        -1
    ],
    [CountryFlag.AM]: [
        -205,
        -1
    ],
    an: [
        -239,
        -1
    ],
    [CountryFlag.AO]: [
        -273,
        -1
    ],
    [CountryFlag.AQ]: [
        -307,
        -1
    ],
    [CountryFlag.AR]: [
        -341,
        -1
    ],
    [CountryFlag.AS]: [
        -375,
        -1
    ],
    [CountryFlag.AT]: [
        -409,
        -1
    ],
    [CountryFlag.AU]: [
        -443,
        -1
    ],
    [CountryFlag.AW]: [
        -1,
        -27
    ],
    [CountryFlag.AX]: [
        -35,
        -27
    ],
    [CountryFlag.AZ]: [
        -69,
        -27
    ],
    [CountryFlag.BA]: [
        -103,
        -27
    ],
    [CountryFlag.BB]: [
        -137,
        -27
    ],
    [CountryFlag.BD]: [
        -171,
        -27
    ],
    [CountryFlag.BE]: [
        -205,
        -27
    ],
    [CountryFlag.BF]: [
        -239,
        -27
    ],
    [CountryFlag.BG]: [
        -273,
        -27
    ],
    [CountryFlag.BH]: [
        -307,
        -27
    ],
    [CountryFlag.BI]: [
        -341,
        -27
    ],
    [CountryFlag.BJ]: [
        -375,
        -27
    ],
    [CountryFlag.BL]: [
        -409,
        -27
    ],
    [CountryFlag.BM]: [
        -443,
        -27
    ],
    [CountryFlag.BN]: [
        -1,
        -53
    ],
    [CountryFlag.BO]: [
        -35,
        -53
    ],
    [CountryFlag.BQ]: [
        -69,
        -53
    ],
    [CountryFlag.BR]: [
        -103,
        -53
    ],
    [CountryFlag.BS]: [
        -137,
        -53
    ],
    [CountryFlag.BT]: [
        -171,
        -53
    ],
    [CountryFlag.BV]: [
        -205,
        -53
    ],
    [CountryFlag.BW]: [
        -239,
        -53
    ],
    [CountryFlag.BY]: [
        -273,
        -53
    ],
    [CountryFlag.BZ]: [
        -307,
        -53
    ],
    [CountryFlag.CA]: [
        -341,
        -53
    ],
    [CountryFlag.CC]: [
        -375,
        -53
    ],
    [CountryFlag.CD]: [
        -409,
        -53
    ],
    [CountryFlag.CF]: [
        -443,
        -53
    ],
    [CountryFlag.CG]: [
        -1,
        -79
    ],
    [CountryFlag.CH]: [
        -35,
        -79
    ],
    [CountryFlag.CI]: [
        -69,
        -79
    ],
    [CountryFlag.CK]: [
        -103,
        -79
    ],
    [CountryFlag.CL]: [
        -137,
        -79
    ],
    [CountryFlag.CM]: [
        -171,
        -79
    ],
    [CountryFlag.CN]: [
        -205,
        -79
    ],
    [CountryFlag.CO]: [
        -239,
        -79
    ],
    [CountryFlag.CR]: [
        -273,
        -79
    ],
    [CountryFlag.CU]: [
        -307,
        -79
    ],
    [CountryFlag.CV]: [
        -341,
        -79
    ],
    [CountryFlag.CW]: [
        -375,
        -79
    ],
    [CountryFlag.CX]: [
        -409,
        -79
    ],
    [CountryFlag.CY]: [
        -443,
        -79
    ],
    [CountryFlag.CZ]: [
        -1,
        -105
    ],
    [CountryFlag.DE]: [
        -35,
        -105
    ],
    [CountryFlag.DJ]: [
        -69,
        -105
    ],
    [CountryFlag.DK]: [
        -103,
        -105
    ],
    [CountryFlag.DM]: [
        -137,
        -105
    ],
    [CountryFlag.DO]: [
        -171,
        -105
    ],
    [CountryFlag.DZ]: [
        -205,
        -105
    ],
    [CountryFlag.EC]: [
        -239,
        -105
    ],
    [CountryFlag.EE]: [
        -273,
        -105
    ],
    [CountryFlag.EG]: [
        -307,
        -105
    ],
    [CountryFlag.EH]: [
        -341,
        -105
    ],
    [CountryFlag.ER]: [
        -375,
        -105
    ],
    [CountryFlag.ES]: [
        -409,
        -105
    ],
    [CountryFlag.ES_CT]: [
        -443,
        -105
    ],
    [CountryFlag.ET]: [
        -1,
        -131
    ],
    [CountryFlag.EU]: [
        -35,
        -131
    ],
    [CountryFlag.FI]: [
        -69,
        -131
    ],
    [CountryFlag.FJ]: [
        -103,
        -131
    ],
    [CountryFlag.FK]: [
        -137,
        -131
    ],
    [CountryFlag.FM]: [
        -171,
        -131
    ],
    [CountryFlag.FO]: [
        -205,
        -131
    ],
    [CountryFlag.FR]: [
        -239,
        -131
    ],
    [CountryFlag.GA]: [
        -273,
        -131
    ],
    [CountryFlag.GB]: [
        -307,
        -131
    ],
    [CountryFlag.GB_ENG]: [
        -341,
        -131
    ],
    [CountryFlag.GB_NIR]: [
        -375,
        -131
    ],
    [CountryFlag.GB_SCT]: [
        -409,
        -131
    ],
    [CountryFlag.GB_WLS]: [
        -443,
        -131
    ],
    [CountryFlag.GD]: [
        -1,
        -157
    ],
    [CountryFlag.GE]: [
        -35,
        -157
    ],
    [CountryFlag.GF]: [
        -69,
        -157
    ],
    [CountryFlag.GG]: [
        -103,
        -157
    ],
    [CountryFlag.GH]: [
        -137,
        -157
    ],
    [CountryFlag.GI]: [
        -171,
        -157
    ],
    [CountryFlag.GL]: [
        -205,
        -157
    ],
    [CountryFlag.GM]: [
        -239,
        -157
    ],
    [CountryFlag.GN]: [
        -273,
        -157
    ],
    [CountryFlag.GP]: [
        -307,
        -157
    ],
    [CountryFlag.GQ]: [
        -341,
        -157
    ],
    [CountryFlag.GR]: [
        -375,
        -157
    ],
    [CountryFlag.GS]: [
        -409,
        -157
    ],
    [CountryFlag.GT]: [
        -443,
        -157
    ],
    [CountryFlag.GU]: [
        -1,
        -183
    ],
    [CountryFlag.GW]: [
        -35,
        -183
    ],
    [CountryFlag.GY]: [
        -69,
        -183
    ],
    [CountryFlag.HK]: [
        -103,
        -183
    ],
    [CountryFlag.HM]: [
        -137,
        -183
    ],
    [CountryFlag.HN]: [
        -171,
        -183
    ],
    [CountryFlag.HR]: [
        -205,
        -183
    ],
    [CountryFlag.HT]: [
        -239,
        -183
    ],
    [CountryFlag.HU]: [
        -273,
        -183
    ],
    [CountryFlag.ID]: [
        -307,
        -183
    ],
    [CountryFlag.IE]: [
        -341,
        -183
    ],
    [CountryFlag.IL]: [
        -375,
        -183
    ],
    [CountryFlag.IM]: [
        -409,
        -183
    ],
    [CountryFlag.IN]: [
        -443,
        -183
    ],
    [CountryFlag.IO]: [
        -1,
        -209
    ],
    [CountryFlag.IQ]: [
        -35,
        -209
    ],
    [CountryFlag.IR]: [
        -69,
        -209
    ],
    [CountryFlag.IS]: [
        -103,
        -209
    ],
    [CountryFlag.IT]: [
        -137,
        -209
    ],
    [CountryFlag.JE]: [
        -171,
        -209
    ],
    [CountryFlag.JM]: [
        -205,
        -209
    ],
    [CountryFlag.JO]: [
        -239,
        -209
    ],
    [CountryFlag.JP]: [
        -273,
        -209
    ],
    [CountryFlag.KE]: [
        -307,
        -209
    ],
    [CountryFlag.KG]: [
        -341,
        -209
    ],
    [CountryFlag.KH]: [
        -375,
        -209
    ],
    [CountryFlag.KI]: [
        -409,
        -209
    ],
    [CountryFlag.KM]: [
        -443,
        -209
    ],
    [CountryFlag.KN]: [
        -1,
        -235
    ],
    [CountryFlag.KP]: [
        -35,
        -235
    ],
    [CountryFlag.KR]: [
        -69,
        -235
    ],
    [CountryFlag.KW]: [
        -103,
        -235
    ],
    [CountryFlag.KY]: [
        -137,
        -235
    ],
    [CountryFlag.KZ]: [
        -171,
        -235
    ],
    [CountryFlag.LA]: [
        -205,
        -235
    ],
    [CountryFlag.LB]: [
        -239,
        -235
    ],
    [CountryFlag.LC]: [
        -273,
        -235
    ],
    [CountryFlag.LI]: [
        -307,
        -235
    ],
    [CountryFlag.LK]: [
        -341,
        -235
    ],
    [CountryFlag.LR]: [
        -375,
        -235
    ],
    [CountryFlag.LS]: [
        -409,
        -235
    ],
    [CountryFlag.LT]: [
        -443,
        -235
    ],
    [CountryFlag.LU]: [
        -1,
        -261
    ],
    [CountryFlag.LV]: [
        -35,
        -261
    ],
    [CountryFlag.LY]: [
        -69,
        -261
    ],
    [CountryFlag.MA]: [
        -103,
        -261
    ],
    [CountryFlag.MC]: [
        -137,
        -261
    ],
    [CountryFlag.MD]: [
        -171,
        -261
    ],
    [CountryFlag.ME]: [
        -205,
        -261
    ],
    [CountryFlag.MF]: [
        -239,
        -261
    ],
    [CountryFlag.MG]: [
        -273,
        -261
    ],
    [CountryFlag.MH]: [
        -307,
        -261
    ],
    [CountryFlag.MK]: [
        -341,
        -261
    ],
    [CountryFlag.ML]: [
        -375,
        -261
    ],
    [CountryFlag.MM]: [
        -409,
        -261
    ],
    [CountryFlag.MN]: [
        -443,
        -261
    ],
    [CountryFlag.MO]: [
        -1,
        -287
    ],
    [CountryFlag.MP]: [
        -35,
        -287
    ],
    [CountryFlag.MQ]: [
        -69,
        -287
    ],
    [CountryFlag.MR]: [
        -103,
        -287
    ],
    [CountryFlag.MS]: [
        -137,
        -287
    ],
    [CountryFlag.MT]: [
        -171,
        -287
    ],
    [CountryFlag.MU]: [
        -205,
        -287
    ],
    [CountryFlag.MV]: [
        -239,
        -287
    ],
    [CountryFlag.MW]: [
        -273,
        -287
    ],
    [CountryFlag.MX]: [
        -307,
        -287
    ],
    [CountryFlag.MY]: [
        -341,
        -287
    ],
    [CountryFlag.MZ]: [
        -375,
        -287
    ],
    [CountryFlag.NA]: [
        -409,
        -287
    ],
    [CountryFlag.NC]: [
        -443,
        -287
    ],
    [CountryFlag.NE]: [
        -1,
        -313
    ],
    [CountryFlag.NF]: [
        -35,
        -313
    ],
    [CountryFlag.NG]: [
        -69,
        -313
    ],
    [CountryFlag.NI]: [
        -103,
        -313
    ],
    [CountryFlag.NL]: [
        -137,
        -313
    ],
    [CountryFlag.NO]: [
        -171,
        -313
    ],
    [CountryFlag.NP]: [
        -205,
        -313
    ],
    [CountryFlag.NR]: [
        -239,
        -313
    ],
    [CountryFlag.NU]: [
        -273,
        -313
    ],
    [CountryFlag.NZ]: [
        -307,
        -313
    ],
    [CountryFlag.OM]: [
        -341,
        -313
    ],
    [CountryFlag.PA]: [
        -375,
        -313
    ],
    [CountryFlag.PE]: [
        -409,
        -313
    ],
    [CountryFlag.PF]: [
        -443,
        -313
    ],
    [CountryFlag.PG]: [
        -1,
        -339
    ],
    [CountryFlag.PH]: [
        -35,
        -339
    ],
    [CountryFlag.PK]: [
        -69,
        -339
    ],
    [CountryFlag.PL]: [
        -103,
        -339
    ],
    [CountryFlag.PM]: [
        -137,
        -339
    ],
    [CountryFlag.PN]: [
        -171,
        -339
    ],
    [CountryFlag.PR]: [
        -205,
        -339
    ],
    [CountryFlag.PS]: [
        -239,
        -339
    ],
    [CountryFlag.PT]: [
        -273,
        -339
    ],
    [CountryFlag.PW]: [
        -307,
        -339
    ],
    [CountryFlag.PY]: [
        -341,
        -339
    ],
    [CountryFlag.QA]: [
        -375,
        -339
    ],
    [CountryFlag.RE]: [
        -409,
        -339
    ],
    [CountryFlag.RO]: [
        -443,
        -339
    ],
    [CountryFlag.RS]: [
        -1,
        -365
    ],
    [CountryFlag.RU]: [
        -35,
        -365
    ],
    [CountryFlag.RW]: [
        -69,
        -365
    ],
    [CountryFlag.SA]: [
        -103,
        -365
    ],
    [CountryFlag.SB]: [
        -137,
        -365
    ],
    [CountryFlag.SC]: [
        -171,
        -365
    ],
    [CountryFlag.SD]: [
        -205,
        -365
    ],
    [CountryFlag.SE]: [
        -239,
        -365
    ],
    [CountryFlag.SG]: [
        -273,
        -365
    ],
    [CountryFlag.SH]: [
        -307,
        -365
    ],
    [CountryFlag.SI]: [
        -341,
        -365
    ],
    [CountryFlag.SJ]: [
        -375,
        -365
    ],
    [CountryFlag.SK]: [
        -409,
        -365
    ],
    [CountryFlag.SL]: [
        -443,
        -365
    ],
    [CountryFlag.SM]: [
        -1,
        -391
    ],
    [CountryFlag.SN]: [
        -35,
        -391
    ],
    [CountryFlag.SO]: [
        -69,
        -391
    ],
    [CountryFlag.SR]: [
        -103,
        -391
    ],
    [CountryFlag.SS]: [
        -137,
        -391
    ],
    [CountryFlag.ST]: [
        -171,
        -391
    ],
    [CountryFlag.SV]: [
        -205,
        -391
    ],
    [CountryFlag.SX]: [
        -239,
        -391
    ],
    [CountryFlag.SY]: [
        -273,
        -391
    ],
    [CountryFlag.SZ]: [
        -307,
        -391
    ],
    [CountryFlag.TC]: [
        -341,
        -391
    ],
    [CountryFlag.TD]: [
        -375,
        -391
    ],
    [CountryFlag.TF]: [
        -409,
        -391
    ],
    [CountryFlag.TG]: [
        -443,
        -391
    ],
    [CountryFlag.TH]: [
        -1,
        -417
    ],
    [CountryFlag.TJ]: [
        -35,
        -417
    ],
    [CountryFlag.TK]: [
        -69,
        -417
    ],
    [CountryFlag.TL]: [
        -103,
        -417
    ],
    [CountryFlag.TM]: [
        -137,
        -417
    ],
    [CountryFlag.TN]: [
        -171,
        -417
    ],
    [CountryFlag.TO]: [
        -205,
        -417
    ],
    [CountryFlag.TR]: [
        -239,
        -417
    ],
    [CountryFlag.TT]: [
        -273,
        -417
    ],
    [CountryFlag.TV]: [
        -307,
        -417
    ],
    [CountryFlag.TW]: [
        -341,
        -417
    ],
    [CountryFlag.TZ]: [
        -375,
        -417
    ],
    [CountryFlag.UA]: [
        -409,
        -417
    ],
    [CountryFlag.UG]: [
        -443,
        -417
    ],
    [CountryFlag.UM]: [
        -1,
        -443
    ],
    [CountryFlag.UN]: [
        -35,
        -443
    ],
    [CountryFlag.US]: [
        -69,
        -443
    ],
    [CountryFlag.UY]: [
        -103,
        -443
    ],
    [CountryFlag.UZ]: [
        -137,
        -443
    ],
    [CountryFlag.VA]: [
        -171,
        -443
    ],
    [CountryFlag.VC]: [
        -205,
        -443
    ],
    [CountryFlag.VE]: [
        -239,
        -443
    ],
    [CountryFlag.VG]: [
        -273,
        -443
    ],
    [CountryFlag.VI]: [
        -307,
        -443
    ],
    [CountryFlag.VN]: [
        -341,
        -443
    ],
    [CountryFlag.VU]: [
        -375,
        -443
    ],
    [CountryFlag.WF]: [
        -409,
        -443
    ],
    [CountryFlag.WS]: [
        -443,
        -443
    ],
    [CountryFlag.YE]: [
        -477,
        -1
    ],
    [CountryFlag.YT]: [
        -477,
        -27
    ],
    [CountryFlag.ZA]: [
        -477,
        -53
    ],
    [CountryFlag.ZM]: [
        -477,
        -79
    ],
    [CountryFlag.ZW]: [
        -477,
        -105
    ]
};
