import { ref, computed } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
export function useFavoritesEventsStoreComposable() {
    const { isLoggedIn } = useIsLoggedIn();
    const favoritesIdsList = ref(null);
    const rawFavoriteEventsResponse = ref(null);
    const isFavoritesEventsListLoaded = computed(()=>!isLoggedIn.value || null !== rawFavoriteEventsResponse.value);
    function setRawFavoriteEventsResponse(response) {
        rawFavoriteEventsResponse.value = response ? normalizeEventsChangesResponseToDefaultResponse(response) : response;
    }
    function setFavoritesIdsList(ids) {
        favoritesIdsList.value = ids;
    }
    return {
        favoritesIdsList,
        rawFavoriteEventsResponse,
        isFavoritesEventsListLoaded,
        setFavoritesIdsList,
        setRawFavoriteEventsResponse
    };
}
