import { ref, computed } from 'vue';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { normalizeComingResponse } from 'web/src/modules/sportline/submodules/segment/store/utils';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { useSyncState } from 'web/src/modules/core/store/composables';
export default function useSegmentStoreComingComposable(props) {
    const { comingUpdateInterval } = props;
    const apiService = useSportlineApiService();
    const broadcastSelected = useBroadcastSelected();
    const rawComingEventsResponse = ref(null);
    const lastUpdate = new BackgroundUpdateStopwatch({
        segmentComingEvents: async (param)=>{
            let { silent } = param;
            const response = await apiService.loadComingEvents({
                filters: props.comingEventsKickoffPeriod.value,
                vTag: rawComingEventsResponse.value?.vtag,
                sportFamily: props.sportSegmentsSettings.value.sportFamily,
                silent
            });
            rawComingEventsResponse.value = normalizeComingResponse({
                response
            });
            broadcastSelected.updateDataInStorageByResponse({
                response
            });
            lastUpdate.update('segmentComingEvents');
        }
    });
    const totalCount = computed(()=>rawComingEventsResponse.value?.totalCount || 0);
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: comingUpdateInterval
    });
    const { onInit: sportlineEventsChangesListenersOnInit } = useSportlineEventsChangesListeners({
        setResponse (response) {
            rawComingEventsResponse.value = response;
        },
        getResponse () {
            return rawComingEventsResponse.value;
        }
    });
    function onInit() {
        sportlineEventsChangesListenersOnInit();
        useSyncState(async (silent)=>{
            await syncState({
                silent
            });
        }, comingUpdateInterval);
    }
    return {
        rawComingEventsResponse,
        totalCount,
        initialRequests,
        onInit,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
}
