/** @deprecated
 * @see @leonhub/html-utils */ let HtmlUtils = class HtmlUtils {
    /** @deprecated use vue-observe-visibility module instead
   * example src/components/Image/components/VLazyImage.vue
   * */ static inViewPort(element) {
        const bounding = element.getBoundingClientRect();
        return bounding.top >= 0 && bounding.left >= 0 && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    }
};
export { HtmlUtils as default };
