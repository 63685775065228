import { createDateLocalizer } from './createDateLocalizer';
import { getMediumDatePattern } from './getMediumDatePattern';
import { getShortDatePattern } from './getShortDatePattern';
import { getDefaultDatePattern } from './getDefaultDatePattern';
export const createDateLocalizers = (options)=>{
    const toFullLocaleDate = createDateLocalizer(options, getDefaultDatePattern);
    const toMediumLocaleDate = createDateLocalizer(options, getMediumDatePattern);
    const toShortLocaleDate = createDateLocalizer(options, getShortDatePattern);
    return {
        toFullLocaleDate,
        toMediumLocaleDate,
        toShortLocaleDate
    };
};
