import { ref, watch, onMounted, computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
export default function CasinoLoyaltyProgramSwiper(props, emit) {
    const { $translate } = useI18n();
    const swiper = ref();
    const currentMonthProgressText = $translate('WEB2_LOYALTY_PROGRAM_CURRENT_MONTH_PROGRESS', ref({
        currentMonth: useDateLocalizers().toMediumLocaleDate(new Date(), 'MMMM')
    }));
    const indexCurrentLevel = computed(()=>{
        const currentIndex = props.cards?.findIndex((item)=>!!item?.progress);
        return -1 !== currentIndex ? currentIndex : 0;
    });
    function emitClickCard(item) {
        emit('click-card', item.id);
    }
    function scrollToLevelWithProgress() {
        swiper.value?.slideToSlide(indexCurrentLevel.value);
    }
    watch(indexCurrentLevel, scrollToLevelWithProgress);
    onMounted(scrollToLevelWithProgress);
    return {
        swiper,
        currentMonthProgressText,
        indexCurrentLevel,
        emitClickCard
    };
}
