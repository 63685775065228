import { computed, ref } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
import getCardType from 'web/src/modules/payments/utils/getCardType';
import paymentsCreditCardLogoMapper from 'web/src/modules/payments/utils/paymentsCreditCardLogoMapper';
import getCardNumberLength from 'web/src/modules/payments/utils/getCardNumberLength';
export default function useCardNumberInput(props, emitInput) {
    const maskPatternMaxLength = ref(19);
    const logoName = ref(null);
    const cardType = ref(null);
    const { theme } = useTheme();
    const disabledCardNumberPattern = computed(()=>{
        const visibleChars = '9999';
        const hiddenChars = props.value ? props.value.slice(0, -4).replace(/[\d*]/g, '•') : '';
        return `${hiddenChars}${visibleChars}`.replace(/([\d•]{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
    });
    const enabledCardNumberPattern = computed(()=>{
        const maskChar = '9';
        return maskChar.repeat(maskPatternMaxLength.value).replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
    });
    const maskPattern = computed(()=>{
        if (props.disabled) return disabledCardNumberPattern.value;
        return enabledCardNumberPattern.value;
    });
    const maskOptions = computed(()=>({
            pattern: maskPattern.value,
            placeholder: ''
        }));
    function onInput(event) {
        const { value } = event.target;
        cardType.value = getCardType(value);
        if (cardType.value) {
            maskPatternMaxLength.value = getCardNumberLength(cardType.value).maxLength;
            logoName.value = paymentsCreditCardLogoMapper(cardType.value, theme.value);
        } else if (logoName.value) {
            logoName.value = null;
            maskPatternMaxLength.value = 19;
        }
        emitInput(event);
    }
    return {
        logoName,
        onInput,
        maskOptions,
        cardType
    };
}
