import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { computed } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useCasinoLoyaltyProgramLevel } from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramLevel/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramLevel',
    props: {
        status: {}
    },
    setup (__props) {
        const props = __props;
        const { label, iconName } = useCasinoLoyaltyProgramLevel(props);
        const iconProperties = computed(()=>({
                name: iconName.value,
                size: IconSize.SIZE_16
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(label) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['casino-loyalty-program-level']]: true,
                    [_ctx.$style[`casino-loyalty-program-level--${_ctx.status}`]]: true
                })
            }, [
                _createVNode(_unref(VIcon), _mergeProps({
                    class: _ctx.$style['casino-loyalty-program-level__icon']
                }, iconProperties.value), null, 16, [
                    "class"
                ]),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-level__label'])
                }, _toDisplayString(_unref(label)), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramLevel'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
