import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { HomePageType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
let CasinoSlotsPrefetchOld = class CasinoSlotsPrefetchOld extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const { homePageType } = useSiteConfigStore();
        let location = {
            name: CasinoRouteName.CASINO_LOBBY,
            params: to.params
        };
        if ((homePageType === HomePageType.LIVE_GAMES || homePageType === HomePageType.SLOTS) && !to.params.groupUrl && !to.params.categoryUrl) location = {
            name: RouteName.HOME
        };
        next(router.resolve301location(location));
        return Promise.resolve();
    }
};
export { CasinoSlotsPrefetchOld as default };
