import useInputCore from './useInputCore';
import { handleNumberPaste, handleNumberInput } from './utils';
export default function useNumberStringInput(props, emit, symbolLimitation) {
    const { uniqueId, isEmpty, isFocus, isHover, inputValue, showClearButton, clearValue, onMouseOver, onMouseLeave, onFocus, onBlur, setInputValue, emitInput, emitChange } = useInputCore(props, emit);
    const onPaste = (event)=>{
        event.preventDefault();
        const updatedValue = handleNumberPaste(event, inputValue.value, props.maximumFractionDigits, symbolLimitation);
        setInputValue(updatedValue);
        emitInput();
    };
    const onChange = (event)=>{
        setInputValue(handleNumberInput(event, inputValue.value, props.maximumFractionDigits, symbolLimitation));
        emitChange();
    };
    const onInput = (event)=>{
        setInputValue(handleNumberInput(event, inputValue.value, props.maximumFractionDigits, symbolLimitation));
        emitInput();
    };
    const onClear = ()=>{
        clearValue();
        emitChange();
    };
    return {
        uniqueId,
        isEmpty,
        isFocus,
        isHover,
        inputValue,
        showClearButton,
        onBlur,
        onFocus,
        onMouseOver,
        onMouseLeave,
        onChange,
        onInput,
        onClear,
        onPaste
    };
}
