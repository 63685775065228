import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { onWindowVisibilityChanged } from '@leon-hub/browser-composables';
function getChannelName(brandId, userId) {
    return `private-prisma-${brandId}-${userId}`;
}
async function loadPusher() {
    const { default: Pusher } = await import('pusher-js');
    return Pusher;
}
let PusherJsClient = class PusherJsClient {
    onVisibilityChanged(isVisible) {
        if (isVisible) {
            if (this.isSilentDisconnect && this.options) this.connect(this.options);
        } else if (this.pusher) this.disconnect(true);
    }
    async connect(options) {
        this.options = options;
        this.disconnect();
        const pusher = Reflect.construct(await loadPusher(), [
            options.pusherKey,
            {
                authEndpoint: `${options.fusionUrl}/external/pusher/${options.brandName}?authToken=${options.sessionId}`,
                cluster: options.pusherRegion
            }
        ]);
        pusher.connection.bind('connected', ()=>{});
        pusher.connection.bind('disconnected', ()=>{});
        const channel = pusher.subscribe(getChannelName(options.brandId, options.userId));
        channel.bind('pusher:subscription_error', ()=>{});
        channel.bind('pusher:subscription_succeeded', ()=>{});
        channel.bind('message', (data)=>{
            options.onNewPusherMessage(data);
        });
        channel.bind('shoutout', (data)=>{
            options.onNewPusherMessage(data);
        });
        channel.bind('inbox', (data)=>{
            options.onNewPusherMessage(data);
        });
        this.pusher = pusher;
        this.channel = channel;
    }
    disconnect() {
        let isSilent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        this.isSilentDisconnect = isSilent;
        if (this.channel) this.channel = void 0;
        if (this.pusher) {
            this.pusher.connection.unbind();
            this.pusher.connection.disconnect();
            this.pusher = void 0;
        }
    }
    constructor(){
        _define_property(this, "pusher", void 0);
        _define_property(this, "channel", void 0);
        _define_property(this, "isSilentDisconnect", false);
        _define_property(this, "options", null);
        window.addEventListener('beforeunload', ()=>{
            this.disconnect();
        });
        onWindowVisibilityChanged(this.onVisibilityChanged.bind(this)).addVisibilityChangeEventListener();
    }
};
export { PusherJsClient as default };
