import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { EgsAvailabilityStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getSeats, getSeatsText } from 'web/src/modules/egs/components/EgsGameInfoOverlay/composables/utils';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { getBetRange } from 'web/src/modules/lobby/components/LobbyEgsGamesItemImage/composables/utils';
export default function useEgsGameInfoOverlay(props) {
    const { $translate } = useI18n();
    const iconProperties = computed(()=>({
            name: IconName.PERSON_FILL,
            size: props.isTopPosition ? IconSize.SIZE_16 : IconSize.SIZE_12
        }));
    const status = computed(()=>props.availability?.status);
    const seats = computed(()=>getSeats(props, $translate));
    const seatsText = computed(()=>getSeatsText(status.value, seats.value, $translate));
    // eslint-disable-next-line max-len
    const isSeatsHidden = computed(()=>!props.availability || status.value === EgsAvailabilityStatus.AVAILABLE && !seats.value);
    const betRange = computed(()=>getBetRange(props.limits));
    const ntBadgeProperties = computed(()=>({
            kind: BadgeKind.SQUARE_ERROR,
            label: props.badge ?? ''
        }));
    return {
        isSeatsHidden,
        betRange,
        seatsText,
        iconProperties,
        status,
        ntBadgeProperties
    };
}
