import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useVAnimationStarter } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VAnimationStarter',
    props: {
        rootMargin: {
            default: ''
        },
        threshold: {
            default: 0
        }
    },
    setup (__props) {
        const props = __props;
        const { animationContainer, isAnimated } = useVAnimationStarter(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "animationContainer",
                ref: animationContainer,
                class: _normalizeClass([
                    "anim-starter",
                    {
                        'anim-starter--animated': _unref(isAnimated)
                    }
                ])
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'VAnimationStarter'
                ]
            ]);
        };
    }
});
