import { defineStore } from 'pinia';
import { nextTick, ref } from 'vue';
import { lobbyTypeSlotsGamePage, lobbyTypeLiveGamePage, lobbyTypeSlots, EgsTopWinnersGameType, LobbyItemType } from '@leon-hub/api-sdk';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { useLobbyType, useLobbySportlineEvents } from 'web/src/modules/lobby/composables';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
import { EgsGamesCategoryId } from 'web/src/modules/egs/enums';
import { getLobbyTypeByCategoryGamesType, isFavoriteLobbyItem, isHistoryLobbyItem, isJackpotWidget, isLobbyGamesItem, isLobbyTopWinnersItem, isSquareImagesWidget } from 'web/src/modules/lobby/utils';
import { useEgsHistoryGamesStore } from 'web/src/modules/egs/submodules/history-games';
import { createLobbyCacheKey, isLobbyAndGameOfSameType } from './utils';
const useLobbyStore = defineStore('lobby', ()=>{
    const { sportlineFlags, parseSportlineSettings, onGlobalSportlineEventsInit } = useLobbySportlineEvents();
    const gamesStore = useEgsGamesStore();
    const { lobbyType: currentLobbyType } = useLobbyType();
    const { getLobby, getGames, getTopWinners } = useEgsApi();
    const { updateHistoryGame } = useEgsHistoryGamesStore();
    const lobbyItemsByType = {};
    const isLoading = {};
    const activeType = ref(lobbyTypeSlots);
    const activeGroupId = ref();
    const activeCategoryId = ref();
    function checkLobbyItemsExists(type, groupId, categoryId) {
        const key = createLobbyCacheKey(type, groupId, categoryId);
        if (!lobbyItemsByType[key]) {
            lobbyItemsByType[key] = ref([]);
            return false;
        }
        return true;
    }
    function setLobbyItems(type, items, groupId, categoryId) {
        checkLobbyItemsExists(type, groupId, categoryId);
        const key = createLobbyCacheKey(type, groupId, categoryId);
        lobbyItemsByType[key].value = items;
        if (items.some((item)=>item.typeId === LobbyItemType.SPORTLINE_TOP_MATCHES)) onGlobalSportlineEventsInit();
    }
    function getLobbyItems(type, groupId, categoryId) {
        const innerType = type ?? currentLobbyType.value;
        checkLobbyItemsExists(innerType, groupId, categoryId);
        const key = createLobbyCacheKey(innerType, groupId, categoryId);
        return lobbyItemsByType[key];
    }
    function setIsLoading(value, type, groupId, categoryId) {
        const key = createLobbyCacheKey(type, groupId, categoryId);
        if (value && (type === lobbyTypeLiveGamePage || type === lobbyTypeSlotsGamePage)) {
            const lobbyItems = getLobbyItems(type, groupId, categoryId);
            if (lobbyItems.value.length) return;
        }
        if (isLoading[key]) isLoading[key].value = value;
        else isLoading[key] = ref(value);
    }
    function getIsLoading(type, groupId, categoryId) {
        const key = createLobbyCacheKey(type, groupId, categoryId);
        if (!isLoading[key]) isLoading[key] = ref(false);
        return isLoading[key];
    }
    async function loadLobbyItems(options) {
        const silent = getLobbyItems(options.type, options.group, options.category).value.length > 0;
        const type = options.type ?? currentLobbyType.value;
        setIsLoading(true, type, options.group, options.category);
        if (silent && options.category !== EgsGamesCategoryId.TOP_WINNERS_LIVE && options.category !== EgsGamesCategoryId.TOP_WINNERS_SLOTS) {
            await nextTick();
            await nextAnimationFrame();
            setIsLoading(false, type, options.group, options.category);
        }
        try {
            const getLobbyRequestOptions = {
                ...options,
                sportlineFlags: sportlineFlags.value.join(',') || void 0,
                type
            };
            const lobbyItems = await getLobby(getLobbyRequestOptions, {
                sportEventDetails: parseSportlineSettings.value
            }, silent);
            setLobbyItems(type, lobbyItems.items, options.group, options.category);
            gamesStore.updateGames(lobbyItems.games);
        } finally{
            setIsLoading(false, type, options.group, options.category);
        }
    }
    async function loadMoreLobbyItemGames(param) {
        let { lobbyItem, groupId, isAllTab, excludeGameIds } = param;
        if (!lobbyItem.isLoading && lobbyItem.next) {
            // eslint-disable-next-line no-param-reassign
            lobbyItem.isLoading = true;
            // eslint-disable-next-line no-param-reassign
            lobbyItem.loadingCounter = (lobbyItem.loadingCounter || 0) + 1;
            const data = await getGames({
                type: getLobbyTypeByCategoryGamesType(lobbyItem.category.type),
                group: groupId,
                category: lobbyItem.category.id,
                isAllTab,
                onlySquare: isSquareImagesWidget(lobbyItem.widgetId),
                isJackpot: isJackpotWidget(lobbyItem.widgetId),
                limit: lobbyItem.next?.limit,
                offset: lobbyItem.next?.offset,
                ...excludeGameIds && {
                    excludeGameIds
                }
            });
            // eslint-disable-next-line no-param-reassign
            lobbyItem.games = [
                ...lobbyItem.games,
                ...data.games
            ];
            // eslint-disable-next-line no-param-reassign
            lobbyItem.next = data.next;
            // eslint-disable-next-line no-param-reassign
            lobbyItem.isLoading = false;
            gamesStore.updateGames(data.games);
        }
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function loadLobbyItemTopWinners(param) {
        let { lobbyItem, filter, isReplace } = param;
        if (!lobbyItem.isLoading && (lobbyItem.next || filter)) {
            // eslint-disable-next-line no-param-reassign
            lobbyItem.isLoading = true;
            if (isReplace) {
                // eslint-disable-next-line no-param-reassign
                lobbyItem.showSkeleton = true;
                if (filter) // eslint-disable-next-line no-param-reassign
                lobbyItem.filter = filter;
            }
            const data = await getTopWinners({
                gameType: lobbyItem.gameType ?? EgsTopWinnersGameType.SLOTS,
                filter: filter ?? lobbyItem.filter,
                ...lobbyItem.next && {
                    limit: lobbyItem.next?.limit,
                    offset: isReplace ? 0 : lobbyItem.next?.offset
                }
            });
            // eslint-disable-next-line no-param-reassign
            lobbyItem.winners = isReplace ? data.winners : [
                ...lobbyItem.winners,
                ...data.winners
            ];
            // eslint-disable-next-line no-param-reassign
            lobbyItem.filter = filter ?? lobbyItem.filter;
            // eslint-disable-next-line no-param-reassign
            lobbyItem.next = data.next;
            // eslint-disable-next-line no-param-reassign
            lobbyItem.isLoading = false;
            // eslint-disable-next-line no-param-reassign
            lobbyItem.showSkeleton = false;
            gamesStore.updateGames(data.winners.map((winner)=>winner.egsGame));
        }
    }
    function setActiveLobby(type, groupId, categoryId) {
        activeType.value = type;
        activeGroupId.value = groupId;
        activeCategoryId.value = categoryId;
    }
    function isLobbyChanged(type, groupId, categoryId) {
        return !checkLobbyItemsExists(type, groupId, categoryId) || type !== activeType.value || groupId !== activeGroupId.value || categoryId !== activeCategoryId.value;
    }
    function setVisitedGame(game) {
        if (game) {
            for (const lobbyItems of Object.values(lobbyItemsByType))for (const lobbyItem of lobbyItems.value)if (isLobbyGamesItem(lobbyItem) && isHistoryLobbyItem(lobbyItem) && isLobbyAndGameOfSameType(game, lobbyItem)) lobbyItem.games = [
                game,
                ...lobbyItem.games.filter((item)=>item.id !== game.id)
            ];
            updateHistoryGame(game);
        }
    }
    function setFavoriteLobbyItem(lobbyItem, ids, value) {
        if (isLobbyGamesItem(lobbyItem) && isFavoriteLobbyItem(lobbyItem)) {
            const changeGames = [];
            const foundIds = [];
            for (const id of ids){
                const game = gamesStore.getGameById(id);
                if (game && isLobbyAndGameOfSameType(game, lobbyItem)) {
                    changeGames.push(game);
                    foundIds.push(game.id);
                }
            }
            const filteredGames = lobbyItem.games.filter((game)=>!foundIds.includes(game.id));
            if (value) // eslint-disable-next-line no-param-reassign
            lobbyItem.games = [
                ...changeGames,
                ...filteredGames
            ];
            else // eslint-disable-next-line no-param-reassign
            lobbyItem.games = filteredGames;
        }
    }
    function setLobbyItemsFavorite(lobbyItem, ids, value) {
        if (isLobbyGamesItem(lobbyItem)) {
            for (const game of lobbyItem.games)if (ids.includes(game.id)) game.isFavorite = value;
            setFavoriteLobbyItem(lobbyItem, ids, value);
        }
        if (isLobbyTopWinnersItem(lobbyItem)) {
            for (const winner of lobbyItem.winners)if (ids.includes(winner.egsGame.id)) winner.egsGame.isFavorite = value;
        }
    }
    function setFavorite(ids, value) {
        for (const lobbyItems of Object.values(lobbyItemsByType))for (const lobbyItem of lobbyItems.value)setLobbyItemsFavorite(lobbyItem, ids, value);
    }
    return {
        getIsLoading,
        loadLobbyItems,
        getLobbyItems,
        loadMoreLobbyItemGames,
        loadLobbyItemTopWinners,
        setActiveLobby,
        isLobbyChanged,
        activeType,
        activeGroupId,
        activeCategoryId,
        setFavorite,
        setVisitedGame
    };
});
export default useLobbyStore;
