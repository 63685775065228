import { getRegion } from 'web/src/modules/sportline/utils/api';
import useSportlineApiPending from './useSportlineApiPending';
export function useSportlineApiLoadRegion(props) {
    const { tryRequestWithCTag, sportsRestApiFlags, isLiveOutrightsEnabled } = props;
    const sportsApiPendings = useSportlineApiPending();
    function loadRegion(param) {
        let { id, silent } = param;
        const pendingFilter = {
            regionId: id,
            betlines: []
        };
        const apiPendingIndex = sportsApiPendings.getApiPendingIndexForFilter(pendingFilter);
        if (apiPendingIndex > -1) return sportsApiPendings.getApiPendingByIndex(apiPendingIndex).request;
        const request = new Promise((resolve, reject)=>{
            tryRequestWithCTag((ctag)=>getRegion({
                    ctag,
                    regionId: id,
                    silent,
                    flags: [
                        ...sportsRestApiFlags.value
                    ],
                    LMCE: isLiveOutrightsEnabled.value
                })).then((result)=>{
                sportsApiPendings.removeApiPendingForFilter(pendingFilter);
                resolve(result);
                return result;
            }).catch((error)=>{
                sportsApiPendings.removeApiPendingForFilter(pendingFilter);
                reject(error);
            });
        });
        const apiPending = {
            ...pendingFilter,
            request
        };
        sportsApiPendings.addApiPending(apiPending);
        return apiPending.request;
    }
    return {
        loadRegion
    };
}
