import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = [
    "disabled",
    "autofocus"
];
import { ref } from 'vue';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
import DropdownSelectIcon from './DropdownSelectIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DropdownWrapper',
    props: {
        autofocus: {
            type: Boolean
        },
        isDropdownShown: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        isEmpty: {
            type: Boolean
        },
        hasError: {
            type: Boolean
        },
        hasIconPrefix: {
            type: Boolean
        },
        isCountrySelector: {
            type: Boolean
        },
        isSearchActive: {
            type: Boolean
        },
        selectedValue: {
            default: ''
        },
        selectedLabel: {
            default: ''
        },
        placeholder: {
            default: ''
        },
        isLarge: {
            type: Boolean
        }
    },
    emits: [
        "focus",
        "blur",
        "click",
        "keydown"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const emit = __emit;
        const button = ref();
        const submit = ref();
        __expose({
            button,
            submit
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['dropdown-wrapper']]: true,
                    [_ctx.$style['dropdown-wrapper--open']]: _ctx.isDropdownShown
                })
            }, [
                _ctx.disabled ? _createCommentVNode("", true) : (_openBlock(), _createBlock(DropdownSelectIcon, {
                    key: 0,
                    "is-open": _ctx.isDropdownShown,
                    "is-large": _ctx.isLarge
                }, null, 8, [
                    "is-open",
                    "is-large"
                ])),
                _createElementVNode("button", {
                    ref_key: "button",
                    ref: button,
                    disabled: _ctx.disabled,
                    autofocus: _ctx.autofocus,
                    type: "button",
                    class: _normalizeClass({
                        [_ctx.$style['dropdown-wrapper__button']]: true,
                        [_ctx.$style['dropdown-wrapper__button--icon-suffix']]: true,
                        [_ctx.$style['dropdown-wrapper__button--icon-prefix']]: _ctx.hasIconPrefix || _ctx.isCountrySelector && _ctx.selectedValue,
                        [_ctx.$style['dropdown-wrapper__button--hidden']]: _ctx.isSearchActive,
                        [_ctx.$style['dropdown-wrapper__button--empty']]: _ctx.isEmpty && !_ctx.isSearchActive,
                        [_ctx.$style['dropdown-wrapper__button--error']]: _ctx.hasError,
                        [_ctx.$style['dropdown-wrapper__button--large']]: _ctx.isLarge
                    }),
                    onFocus: _cache[0] || (_cache[0] = ($event)=>emit('focus')),
                    onBlur: _cache[1] || (_cache[1] = ($event)=>emit('blur')),
                    onClick: _cache[2] || (_cache[2] = ($event)=>emit('click')),
                    onKeydown: _cache[3] || (_cache[3] = ($event)=>emit('keydown', $event))
                }, [
                    _withDirectives((_openBlock(), _createElementBlock("span", {
                        class: _normalizeClass(_ctx.$style['dropdown-wrapper__button-content'])
                    }, [
                        _ctx.isCountrySelector && _ctx.selectedValue ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            (_openBlock(), _createBlock(_unref(VCountryFlag), {
                                key: 1,
                                name: _ctx.selectedValue,
                                class: _normalizeClass(_ctx.$style['dropdown-wrapper__flag'])
                            }, null, 8, [
                                "name",
                                "class"
                            ]))
                        ], 64)) : _createCommentVNode("", true),
                        _createTextVNode(_toDisplayString(_ctx.selectedLabel || _ctx.placeholder), 1)
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'dropdown-select-selected-label',
                                value: _ctx.selectedValue
                            }
                        ]
                    ])
                ], 42, _hoisted_1),
                _renderSlot(_ctx.$slots, "search"),
                _createElementVNode("button", {
                    ref_key: "submit",
                    ref: submit,
                    type: "submit",
                    inert: "",
                    hidden: ""
                }, null, 512),
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'DropdownWrapper'
                ]
            ]);
        };
    }
});
