import { useRouter, useRoute } from 'vue-router';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { getDefaultLobbyLocationByRouteName } from 'web/src/modules/egs/utils';
export default function useLobbyEgsNoGames(props) {
    const router = useRouter();
    const route = useRoute();
    const noGamesButtonProperties = {
        rounded: true,
        isUppercase: false,
        kind: ButtonKind.BASE
    };
    function onClick() {
        router.push(props.routeName ? {
            name: props.routeName
        } : getDefaultLobbyLocationByRouteName(route));
    }
    return {
        noGamesButtonProperties,
        onClick
    };
}
