import { ref } from 'vue';
export function useSwiperManualActiveSlide() {
    const manualActiveSlide = ref(void 0);
    function manualChangeSlideActiveState(index) {
        let isActive = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
        if (isActive) manualActiveSlide.value = index;
        else if (manualActiveSlide.value === index) manualActiveSlide.value = void 0;
    }
    return {
        manualActiveSlide,
        manualChangeSlideActiveState
    };
}
