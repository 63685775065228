import { SportWidgetType } from 'web/src/modules/sportline/enums';
import { BetlineSportWidgetType } from 'web/src/modules/sportline/enums/rest';
import { findSportRepresentationSetting } from '../pre-build';
const sportWidgetTypesMapping = {
    [BetlineSportWidgetType.BETRADAR]: SportWidgetType.SPORTRADAR,
    [BetlineSportWidgetType.LSPORTS]: SportWidgetType.LSPORTS,
    [BetlineSportWidgetType.BET_GENIUS]: SportWidgetType.BET_GENIUS,
    [BetlineSportWidgetType.NONE]: SportWidgetType.NONE
};
function getWidgetTypeBySport(param, param1) {
    let { id, family, virtual } = param, { sportRepresentationSettings } = param1;
    if (virtual) {
        const item = findSportRepresentationSetting(sportRepresentationSettings, {
            id,
            family
        });
        return item?.defaultWidgetType ?? BetlineSportWidgetType.NONE;
    }
    return BetlineSportWidgetType.NONE;
}
function isEmptyWidgetType(widgetType) {
    return !widgetType || widgetType === BetlineSportWidgetType.NONE;
}
export function createSportWidget(param, options) {
    let { response, sportResponse } = param;
    const { widgetId, widgetType, widgetVirtual } = response;
    const { family } = sportResponse;
    const finalType = isEmptyWidgetType(widgetType) ? getWidgetTypeBySport(sportResponse, options) : widgetType;
    if (!widgetId || !finalType) return null;
    return sportWidgetTypesMapping[finalType] ? {
        id: widgetId,
        type: sportWidgetTypesMapping[finalType],
        sportFamily: family,
        isVirtual: Boolean(widgetVirtual)
    } : null;
}
