import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import debounce from 'lodash/debounce';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useMatchStreamAccess } from 'web/src/modules/sportline/submodules/streams';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import getNormalizedSearchQuery from 'web/src/utils/getNormalizedSearchQuery';
import { searchEvents } from 'web/src/modules/sportline/utils/api';
import { parseSportlineSearchResult } from './utils';
const useSearchSportlineStore = defineStore('search-sportline', ()=>{
    const analytics = useAnalytics();
    const searchMinCharsSize = toRef(()=>useSiteConfigStore().searchMinCharsSize);
    const matchStreamAccess = useMatchStreamAccess();
    const sportlineSettingsStore = useSportlineSettingsStore();
    const searchRestApiFlags = toRef(()=>sportlineSettingsStore.searchRestApiFlags);
    const parseSportlineSettings = toRef(()=>sportlineSettingsStore.parseSportlineSettings);
    const searchText = ref('');
    const searchType = ref();
    const liveEvents = ref();
    const prematchEvents = ref();
    const isSearchAvailable = computed(()=>searchText.value.length >= searchMinCharsSize.value);
    const isSearchInProgress = computed(()=>isSearchAvailable.value && (!liveEvents.value || !prematchEvents.value));
    const isNoSearchResults = computed(()=>(!liveEvents.value || 0 === liveEvents.value.length) && (!prematchEvents.value || 0 === prematchEvents.value.length));
    async function fetchSportEvents() {
        const response = await searchEvents({
            query: searchText.value,
            flags: [
                ...searchRestApiFlags.value
            ]
        });
        const result = parseSportlineSearchResult(response, parseSportlineSettings.value, matchStreamAccess.value);
        liveEvents.value = result.liveEvents;
        prematchEvents.value = result.prematchEvents;
    }
    const doSearch = debounce(()=>{
        fetchSportEvents();
    }, 500);
    function setSearchText(value) {
        searchText.value = getNormalizedSearchQuery(value);
        liveEvents.value = void 0;
        prematchEvents.value = void 0;
        if (!isSearchAvailable.value) return;
        doSearch();
    }
    function setSearchType(type) {
        searchType.value = type;
    }
    function sendMetrics(clicks) {
        const results = isSearchAvailable.value && !isNoSearchResults.value;
        "1";
        analytics.push(AnalyticsEvent.Z_SEARCH_SPORT, {
            searchDetails: {
                sport: {
                    [searchType.value ?? 'null']: {
                        [searchText.value]: {
                            [results ? 'true' : 'false']: clicks ? 'clicked' : 'nonClicked'
                        }
                    }
                }
            }
        });
    }
    return {
        searchText,
        liveEvents,
        prematchEvents,
        isSearchAvailable,
        isNoSearchResults,
        isSearchInProgress,
        setSearchText,
        sendMetrics,
        setSearchType
    };
});
export default useSearchSportlineStore;
