import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useParseSportlineSettingsRef, useIsMarketTypesSelectionEnabledRef } from 'web/src/modules/sportline/composables/settings';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineEventsChangesListeners, useSportlinePageIdentifier } from 'web/src/modules/sportline/store/composables';
import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import { useRegionEventsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { filterSportsListEvents } from 'web/src/modules/sportline/utils';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
import useAwaitConditionQueue from 'web/src/utils/store/composables/useAwaitConditionQueue';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { useGetSportlineEntityFactory } from 'web/src/modules/sportline/composables/factories';
export const useRegionPageStore = defineStore('sportline-region-page-store', ()=>{
    const { onInit: onGlobalSportlineEventsInit } = useGlobalSportlineEvents();
    const { timeout: regionEventsUpdateInterval } = useRegionEventsSportlineUpdateTimeout();
    const parseSportlineSettings = useParseSportlineSettingsRef();
    const isMarketTypesSelectionEnabled = useIsMarketTypesSelectionEnabledRef();
    const apiService = useSportlineApiService();
    const broadcastSelected = useBroadcastSelected();
    const customerFavoritesService = useCustomerFavoritesService();
    const { pageIdentifier, setPageIdentifier } = useSportlinePageIdentifier();
    const configForRegionLoading = ref({});
    const lastLoadedRegionId = ref(null);
    const isLoaded = ref(false);
    const isRegionDataLoaded = ref(false);
    const isLoadingIndicatorShown = ref(false);
    const rawEventsResponse = ref(null);
    const rawRegionResponse = ref(null);
    const currentRegionLoadingConfigId = computed(()=>configForRegionLoading.value.id ?? null);
    /** Is page ready to show (we have some data) */ const isReady = computed(()=>null !== rawEventsResponse.value || null !== rawRegionResponse.value);
    /** Get actual sports with events tree */ const sportElementsList = computed(()=>rawEventsResponse.value ? new SportlineFactory(rawEventsResponse.value, parseSportlineSettings.value).build() : null);
    const outrightEvents = computed(()=>filterSportsListEvents(sportElementsList.value ?? [], (item)=>item.sportEvent.isOutright));
    const { regionElement: regionData } = useGetSportlineEntityFactory({
        rawRegionResponse
    });
    const sportElement = computed(()=>sportElementsList.value?.[0] ?? null);
    const regionElement = computed(()=>sportElement.value?.regions[0] ?? regionData.value);
    const leagueElements = computed(()=>(regionElement.value?.leagues ?? []).filter((leagueElement)=>leagueElement.sportEvents.length > 0 || leagueElement.outrightEvents.length > 0));
    const containEvents = computed(()=>leagueElements.value.length > 0);
    const region = computed(()=>regionElement.value?.region ?? null);
    const sport = computed(()=>sportElement.value?.sport ?? null);
    function setConfigForRegionLoading(config) {
        configForRegionLoading.value = config;
    }
    function clearRegion() {
        setConfigForRegionLoading({});
        rawEventsResponse.value = null;
        isLoadingIndicatorShown.value = false;
    }
    function fetchRegion(param) {
        let { regionId, silent } = param;
        return apiService.loadRegion({
            id: regionId,
            silent
        });
    }
    const lastUpdate = new BackgroundUpdateStopwatch({
        regionEvents: async (param)=>{
            let { silent } = param;
            const regionId = currentRegionLoadingConfigId.value;
            const isUpdateRequest = regionId === lastLoadedRegionId.value;
            if (!regionId) return;
            isLoaded.value = false;
            if (!isUpdateRequest) isLoadingIndicatorShown.value = true;
            try {
                const response = await apiService.loadRegionEvents({
                    regionId,
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    vTag: isUpdateRequest ? (rawEventsResponse.value || null)?.vtag : void 0,
                    silent
                });
                // user went away from the page
                if (regionId !== currentRegionLoadingConfigId.value) return;
                rawEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
                broadcastSelected.updateDataInStorageByResponse({
                    response
                });
                lastUpdate.update('regionEvents');
                lastLoadedRegionId.value = regionId;
            } catch (error) {
                rawEventsResponse.value = false;
                lastLoadedRegionId.value = regionId;
                throw error;
            } finally{
                isLoaded.value = true;
                isLoadingIndicatorShown.value = false;
            }
        },
        regionData: async (param)=>{
            let { silent } = param;
            const regionId = currentRegionLoadingConfigId.value;
            try {
                if (!regionId) return;
                isRegionDataLoaded.value = false;
                const response = await fetchRegion({
                    regionId,
                    silent
                });
                if (!response) return;
                rawRegionResponse.value = response;
                lastUpdate.update('regionData');
            } catch (error) {
                rawRegionResponse.value = false;
                throw error;
            } finally{
                isRegionDataLoaded.value = true;
                lastLoadedRegionId.value = regionId;
            }
        }
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: regionEventsUpdateInterval,
        onEnableChanged (value) {
            customerFavoritesService.setIsBackgroundIdsUpdateAvailable(CustomerFavoritesIdsBackgroundUpdateKey.REGION, value);
        }
    });
    const { awaitCondition: awaitStateIsLoaded } = useAwaitConditionQueue({
        source: 'Sportline region page is ready',
        value: isLoaded,
        condition: (value)=>value
    });
    const { awaitCondition: awaitLeavePage } = useAwaitConditionQueue({
        source: 'Leave sportline region page',
        value: computed(()=>pageIdentifier.value.regionId),
        condition: (value, oldValue)=>!value || !!oldValue && value !== oldValue
    });
    const { onInit: onSportlineEventsChangesListenersInit } = useSportlineEventsChangesListeners({
        setResponse (response) {
            rawEventsResponse.value = response;
        },
        getResponse () {
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            return rawEventsResponse.value || null;
        }
    });
    function onInit() {
        useSyncState(async (silent)=>{
            await syncState({
                silent
            });
        }, regionEventsUpdateInterval);
    }
    // init
    onGlobalSportlineEventsInit();
    onSportlineEventsChangesListenersInit();
    onInit();
    return {
        setConfigForRegionLoading,
        pageIdentifier,
        setPageIdentifier,
        awaitStateIsLoaded,
        awaitLeavePage,
        clearRegion,
        isLoaded,
        isReady,
        isLoadingIndicatorShown,
        isMarketTypesSelectionEnabled,
        sportElementsList,
        outrightEvents,
        containEvents,
        region,
        sport,
        lastLoadedRegionId,
        currentRegionLoadingConfigId,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
});
