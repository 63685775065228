import { computed } from 'vue';
import { isVCaptchaProperties } from 'web/src/components/Input/guards/isVCaptchaProperties';
export default function useFormCaptcha(props) {
    const componentProps = computed(()=>{
        // original: https://gitlab.leoncorp.net/leonweb/leon-front/-/merge_requests/762/diffs
        const { options, error } = props;
        const captchaProperties = {
            ...options ?? {},
            error
        };
        isVCaptchaProperties(captchaProperties);
        return captchaProperties;
    });
    return {
        componentProps
    };
}
