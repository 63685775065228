import { SportFamily } from 'web/src/modules/sportline/enums';
export const SportFamilyEmoji = Object.freeze({
    [SportFamily.Soccer]: '⚽',
    [SportFamily.IceHockey]: '🏒',
    [SportFamily.Tennis]: '🎾',
    [SportFamily.Basketball]: '🏀',
    [SportFamily.Motorsport]: '🏎️',
    [SportFamily.AmericanFootball]: '🏈',
    [SportFamily.Badminton]: '🏸',
    [SportFamily.Baseball]: '⚾',
    [SportFamily.Boxing]: '🥊',
    [SportFamily.WaterPolo]: '🤽',
    [SportFamily.Volleyball]: '🏐',
    [SportFamily.Handball]: '🤾🏿',
    [SportFamily.AlpineSkiing]: '🎿',
    [SportFamily.Darts]: '🎯',
    [SportFamily.Cricket]: '🏏',
    [SportFamily.MMA]: '🥊',
    [SportFamily.TableTennis]: '🏓',
    [SportFamily.Rugby]: '🏉',
    [SportFamily.RugbyLeague]: '🏉',
    [SportFamily.Snooker]: '',
    [SportFamily.Floorball]: '',
    [SportFamily.Futsal]: '⚽',
    [SportFamily.ESport]: '🎮'
});
export const DefaultSportFamilyEmoji = '🏆';
