import { useBannersStore } from 'web/src/modules/banners/store';
export default function useInitialRequests() {
    const { loadBanners } = useBannersStore();
    function getInitRequests() {
        return [
            loadBanners()
        ];
    }
    return {
        getInitRequests
    };
}
