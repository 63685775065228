import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { HistoryFilter } from '@leon-hub/api-sdk';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VTabsButton from 'web/src/components/Tabs/VTabsButton/VTabsButton.vue';
import VSwiperNavigationButtons from 'web/src/modules/banners/components/VSwiperBannerNavigation/VSwiperBannerNavigationButtons.vue';
import STabsButton from 'web/src/components/Tabs/STabsButton/STabsButton.vue';
import { useCustomerHistoryFilterTabs } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryFilterTabs',
    props: {
        selectedHistoryFilter: {
            default: HistoryFilter.ALL
        },
        isLeonShopEnabled: {
            type: Boolean
        }
    },
    emits: [
        "tab-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { swiper, tabs, slideToActive } = useCustomerHistoryFilterTabs(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['history-filter-tabs__wrapper']]: void 0
                })
            }, [
                _createVNode(_unref(VSwiper), {
                    ref_key: "swiper",
                    ref: swiper,
                    class: _normalizeClass(_ctx.$style['history-filter-tabs']),
                    "wrapper-class": {
                        [_ctx.$style['history-filter-tabs__with-gap']]: true,
                        [_ctx.$style['history-filter-tabs__swiper']]: void 0
                    },
                    "is-scroll-snap-enabled": "",
                    onVnodeMounted: _unref(slideToActive)
                }, _createSlots({
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabs), (item)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                    key: item.id
                                }, {
                                    default: _withCtx(()=>[
                                            (_openBlock(), _createBlock(VTabsButton, {
                                                key: 1,
                                                id: item.id,
                                                active: item.id === _ctx.selectedHistoryFilter,
                                                "is-bordered": "",
                                                onClick: ($event)=>emit('tab-click', item.id)
                                            }, {
                                                default: _withCtx(()=>[
                                                        _createTextVNode(_toDisplayString(item.label), 1)
                                                    ]),
                                                _: 2
                                            }, 1032, [
                                                "id",
                                                "active",
                                                "onClick"
                                            ]))
                                        ]),
                                    _: 2
                                }, 1024))), 128))
                        ]),
                    _: 2
                }, [
                    {
                        name: "pagination-footer",
                        fn: _withCtx(()=>[
                                _createVNode(VSwiperNavigationButtons, {
                                    "button-kind": "base"
                                })
                            ]),
                        key: "0"
                    }
                ]), 1032, [
                    "class",
                    "wrapper-class",
                    "onVnodeMounted"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerHistoryFilterTabs'
                ]
            ]);
        };
    }
});
