import { isValidNumberInput, shouldReplaceZero } from '../../utils';
export default function handleNumberInput(event) {
    let currentValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '', fractionDigits = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0, symbolLimitation = arguments.length > 3 ? arguments[3] : void 0;
    const { target } = event;
    HTMLInputElement;
    let eventValue = target.value;
    const haveComma = eventValue.includes(',');
    if (haveComma) eventValue = eventValue.replaceAll(',', '.');
    if (shouldReplaceZero(currentValue, eventValue)) eventValue = eventValue.slice(1);
    const isInputValid = isValidNumberInput(eventValue, fractionDigits);
    if (!isInputValid || symbolLimitation && eventValue.length > symbolLimitation) target.value = currentValue;
    if (isInputValid) {
        const nextValue = eventValue.slice(0, symbolLimitation);
        if (haveComma) target.value = nextValue;
        return nextValue;
    }
    return currentValue;
}
