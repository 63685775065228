import { getCustomerBonuses } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
// eslint-disable-next-line max-len
export default function fetchCustomerBonuses(bonusCampaignType) {
    const apiClient = useGraphqlClient();
    return getCustomerBonuses(apiClient, (node)=>node.queries.bonuses.getCustomerBonuses, {
        options: {
            bonusCampaignType
        }
    });
}
