import { computed, nextTick, ref, toRef, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { Timer } from '@leon-hub/utils';
import { HelpButtonMode } from '@leon-hub/api-sdk/src/sdk/sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { getInlineChatByPathname } from 'web/src/modules/support/submodules/widget/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useInlineChatsStore } from 'web/src/modules/support/submodules/widget/store';
import { useRouterStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import OverlayType from 'web/src/components/Modal/VOverlay/enums/OverlayType';
import { useLiveChatStore } from 'web/src/modules/live-chat/store';
import { useSiteConfigStore } from 'web/src/modules/core/store/useSiteConfigStore';
import { getInlineChatHideTimeout } from './utils';
const overlayProps = {
    type: OverlayType.OPACITY_L0,
    isFixed: true
};
export default function useInlineChat() {
    const analytics = useAnalytics();
    const liveChatStore = useLiveChatStore();
    const { $translate } = useI18n();
    const siteConfigStore = useSiteConfigStore();
    const { isLoggedIn } = useIsLoggedIn();
    const routerStore = useRouterStore();
    const currentRoute = toRef(routerStore, 'currentRoute');
    const supportBlock = toRef(siteConfigStore, 'supportBlock');
    let inlineChatOpenTimeout = 0;
    let inlineChatCloseTimeout = 0;
    const isInlineChatAllowed = ref(false);
    const isWidgetOpened = ref(false);
    const isChatEnabled = toRef(liveChatStore, 'enabled');
    toRef(liveChatStore, 'isChatActive');
    const isChatOpened = toRef(liveChatStore, 'isOpened');
    const { inlineChats } = storeToRefs(useInlineChatsStore());
    const inlineChat = computed(()=>getInlineChatByPathname(inlineChats.value, currentRoute.value?.fullPath));
    const inlineChatText = computed(()=>inlineChat.value?.text || `${$translate('WEB2_SUPPORT_WIDGET_HELLO_TITLE').value} &#x1f44b;`);
    const inlineChatDescription = computed(()=>inlineChat.value?.description || $translate('WEB2_SUPPORT_WIDGET_HELLO_DESCR').value);
    const defaultChatSubject = computed(()=>inlineChat.value?.subject || '');
    const isWidgetAllowedForPage = computed(()=>// eslint-disable-next-line max-len
        !isChatOpened.value && isChatEnabled.value && isInlineChatAllowed.value && supportBlock.value?.helpButtonMode === HelpButtonMode.HELP);
    const showOverlay = computed(()=>false);
    function clearInlineChatOpenTimeout() {
        if (inlineChatOpenTimeout) {
            Timer.clearTimeout(inlineChatOpenTimeout);
            inlineChatOpenTimeout = 0;
        }
    }
    function clearInlineChatCloseTimeout() {
        if (inlineChatCloseTimeout) {
            Timer.clearTimeout(inlineChatCloseTimeout);
            inlineChatCloseTimeout = 0;
        }
    }
    function sendInlineChatAnalytics() {
        if (isInlineChatAllowed.value) analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
            supportDetails: {
                inlineChat: {
                    clickInlineChat: true
                }
            }
        });
    }
    function hideWidget() {
        if (isWidgetOpened.value) isWidgetOpened.value = false;
    }
    watch(inlineChat, (newValue, oldValue)=>{
        if (!newValue && !oldValue || newValue?.id === oldValue?.id) return;
        clearInlineChatOpenTimeout();
        clearInlineChatCloseTimeout();
        isInlineChatAllowed.value = false;
        isWidgetOpened.value = false;
        if (newValue) inlineChatOpenTimeout = Timer.setTimeout(async ()=>{
            if (isChatOpened.value) return;
            isInlineChatAllowed.value = true;
            await nextTick();
            await nextAnimationFrame();
            isWidgetOpened.value = true;
            clearInlineChatCloseTimeout();
            const timeout = getInlineChatHideTimeout(newValue.hideCondition);
            if (timeout > 0) inlineChatCloseTimeout = Timer.setTimeout(hideWidget, timeout);
        }, newValue.timeout);
    }, {
        immediate: true,
        deep: true
    });
    watch(isChatOpened, (newValue)=>{
        if (newValue) hideWidget();
    });
    return {
        inlineChatText,
        isWidgetOpened,
        inlineChatDescription,
        defaultChatSubject,
        isWidgetAllowedForPage,
        showOverlay,
        overlayProps,
        hideWidget,
        sendInlineChatAnalytics
    };
}
