import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, withCtx as _withCtx } from "vue";
import { TextInputTypes } from 'web/src/components/Input/enums';
import PasswordValidator from 'web/src/components/PasswordValidator';
import InputButton from 'web/src/components/Input/components/InputButton/InputButton.vue';
import TextInput from '../TextInput';
import { useBaseInputEvents } from '../../composables';
import usePasswordInput from './usePasswordInput';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'PasswordInput',
    props: {
        groups: {},
        hideValidation: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'password'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {},
        autocomplete: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { toggleTextVisibility, isTextVisible, iconName } = usePasswordInput();
        const { emitChange, emitInput, emitFocus, emitBlur } = useBaseInputEvents(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(_unref(TextInput), {
                autofocus: _ctx.autofocus,
                value: _ctx.value,
                label: _ctx.label,
                name: _ctx.name,
                placeholder: _ctx.placeholder,
                disabled: _ctx.disabled,
                hint: _ctx.hint,
                "hint-right": _ctx.hintRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                "hint-link": _ctx.hintLink,
                "hint-link-right": _ctx.hintLinkRight,
                type: _unref(isTextVisible) ? _unref(TextInputTypes).TEXT : _unref(TextInputTypes).PASSWORD,
                autocomplete: _ctx.autocomplete,
                "is-large": _ctx.isLarge,
                "clickable-icon": "",
                onChange: _unref(emitChange),
                onInput: _unref(emitInput),
                onBlur: _unref(emitBlur),
                onFocus: _unref(emitFocus)
            }, {
                hint: _withCtx((param)=>{
                    let { isFocus, value: inputValue } = param;
                    return [
                        _ctx.hideValidation || !inputValue ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(PasswordValidator), {
                            key: 0,
                            groups: _ctx.groups,
                            password: inputValue,
                            "is-focused": isFocus,
                            error: _ctx.error
                        }, null, 8, [
                            "groups",
                            "password",
                            "is-focused",
                            "error"
                        ]))
                    ];
                }),
                iconSuffix: _withCtx((param)=>{
                    let { isFocus, isEmpty } = param;
                    return [
                        _withDirectives(_createVNode(InputButton, {
                            "icon-name": _unref(iconName),
                            onClick: _unref(toggleTextVisibility)
                        }, null, 8, [
                            "icon-name",
                            "onClick"
                        ]), [
                            [
                                _vShow,
                                isFocus || !isEmpty
                            ]
                        ])
                    ];
                }),
                _: 1
            }, 8, [
                "autofocus",
                "value",
                "label",
                "name",
                "placeholder",
                "disabled",
                "hint",
                "hint-right",
                "error",
                "error-right",
                "hint-link",
                "hint-link-right",
                "type",
                "autocomplete",
                "is-large",
                "onChange",
                "onInput",
                "onBlur",
                "onFocus"
            ])), [
                [
                    _directive_auto_id,
                    'PasswordInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'password-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
