import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticDisclaimer',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VHintBlock, {
                class: "match-statistic-disclaimer",
                "has-icon": false,
                label: _ctx.$t('WEB2_MATCH_STATISTIC_DISCLAIMER')
            }, null, 8, [
                "label"
            ])), [
                [
                    _directive_auto_id,
                    'StatisticDisclaimer'
                ]
            ]);
        };
    }
});
