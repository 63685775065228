import { defineComponent as _defineComponent } from 'vue';
import { mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SwiperNavigationButton from 'web/src/components/Swiper/Navigation/SwiperNavigationButton/SwiperNavigationButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SwiperNavigationButtonsBase',
    props: {
        isRounded: {
            type: Boolean
        },
        isPreviousAllowed: {
            type: Boolean
        },
        isNextAllowed: {
            type: Boolean
        },
        buttonKind: {}
    },
    emits: [
        "click-previous",
        "click-next"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const previousButtonProperties = {
            iconName: IconName.EXPAND_LEFT,
            iconSize: IconSize.SIZE_24
        };
        const nextButtonProperties = {
            iconName: IconName.EXPAND_RIGHT,
            iconSize: IconSize.SIZE_24
        };
        function emitClickPrevious() {
            emit('click-previous');
        }
        function emitClickNext() {
            emit('click-next');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['swiper-navigation-buttons'])
            }, [
                _ctx.isPreviousAllowed ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([
                        _ctx.$style['swiper-navigation-buttons__btn'],
                        _ctx.$style['swiper-navigation-buttons__btn--prev']
                    ])
                }, [
                    _ctx.buttonKind ? (_openBlock(), _createBlock(VButton, _mergeProps({
                        key: 0
                    }, previousButtonProperties, {
                        kind: _ctx.buttonKind,
                        onClick: emitClickPrevious
                    }), null, 16, [
                        "kind"
                    ])) : (_openBlock(), _createBlock(SwiperNavigationButton, {
                        key: 1,
                        "is-rounded": _ctx.isRounded,
                        onClick: emitClickPrevious
                    }, null, 8, [
                        "is-rounded"
                    ]))
                ], 2)) : _createCommentVNode("", true),
                _ctx.isNextAllowed ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([
                        _ctx.$style['swiper-navigation-buttons__btn'],
                        _ctx.$style['swiper-navigation-buttons__btn--next']
                    ])
                }, [
                    _ctx.buttonKind ? (_openBlock(), _createBlock(VButton, _mergeProps({
                        key: 0
                    }, nextButtonProperties, {
                        kind: _ctx.buttonKind,
                        onClick: emitClickNext
                    }), null, 16, [
                        "kind"
                    ])) : (_openBlock(), _createBlock(SwiperNavigationButton, {
                        key: 1,
                        "is-rounded": _ctx.isRounded,
                        "is-next": "",
                        onClick: emitClickNext
                    }, null, 8, [
                        "is-rounded"
                    ]))
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SwiperNavigationButtonsBase'
                ]
            ]);
        };
    }
});
