let Timer = class Timer {
    static setInterval(callback, timeout) {
        if (!process.env.VUE_APP_PRERENDER) return window.setInterval(callback, timeout);
        return 0;
    }
    static setTimeout(callback, timeout) {
        if (!process.env.VUE_APP_PRERENDER) return window.setTimeout(callback, timeout);
        return 0;
    }
    static clearTimeout(timeout) {
        if (!process.env.VUE_APP_PRERENDER) window.clearTimeout(timeout);
    }
    static clearInterval(timeout) {
        if (!process.env.VUE_APP_PRERENDER) window.clearInterval(timeout);
    }
};
export { Timer as default };
