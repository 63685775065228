import { computed } from 'vue';
import { Theme } from '@leon-hub/api-sdk';
import { useTheme } from 'web/src/modules/theme/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useVThemeSwitcher() {
    const themeSystem = useTheme();
    const { $translate } = useI18n();
    function isActive(theme) {
        if (themeSystem.isAuto.value) return theme === Theme.AUTO;
        return theme === themeSystem.theme.value;
    }
    const themeList = computed(()=>[
            Theme.DARK,
            Theme.LIGHT,
            Theme.AUTO
        ].map((theme)=>({
                theme,
                name: $translate(`WEB2_CORDOVA_THEME_${theme}`).value,
                isActive: isActive(theme)
            })));
    return {
        themeList
    };
}
