import { ref, onBeforeMount, onBeforeUnmount, getCurrentInstance } from 'vue';
import { isFunction } from '@leon-hub/guards';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';
import { isDifferentMetaParameters } from './SeoMetaParameters';
export const computedMetaParametersKey = 'metaParameters';
export default {
    install (app) {
        app.mixin({
            beforeCreate () {
                const { $options } = this;
                const { metaParameters } = $options;
                if (isFunction(metaParameters)) {
                    $options.computed = $options.computed || {};
                    $options.computed[computedMetaParametersKey] = function computeMetaParameters() {
                        return metaParameters.call(this);
                    };
                    onBeforeMount(()=>{
                        const uid = getCurrentInstance()?.uid;
                        if (!uid) throw new Error('Component not initialized');
                        const parameter = ref({});
                        useSeoMetaStorage().setMetaParameter(uid, parameter);
                        this.$watch('metaParameters', (newValue, oldValue)=>{
                            if (!isDifferentMetaParameters(newValue, oldValue)) return;
                            parameter.value = newValue ?? {};
                        }, {
                            immediate: true
                        });
                    });
                    onBeforeUnmount(()=>{
                        const uid = getCurrentInstance()?.uid;
                        if (!uid) throw new Error('Component not initialized');
                        useSeoMetaStorage().setMetaParameter(uid, null);
                    });
                }
            }
        });
    }
};
