import $styles from '../styles/common.module.scss';
export function convertCommonClassToCssModule(className) {
    let value = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
    const resultClass = $styles[className];
    // eslint-disable-next-line no-console
    return resultClass || className;
}
export default function convertCommonClassesToCssModule(classes) {
    return Object.keys(classes).reduce((acc, key)=>({
            ...acc,
            [convertCommonClassToCssModule(key, classes[key])]: classes[key]
        }), {});
}
