import { watch, onBeforeUnmount, ref } from 'vue';
import { Timer } from '@leon-hub/utils';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { InputEventType } from 'web/src/components/Input/enums';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useDropdownMenu(props, emit) {
    const buttonsRef = ref([]);
    const scrollbarRef = ref();
    const onClick = (value)=>{
        emit(InputEventType.CHANGE, value);
    };
    let lastHoverIndex = null;
    let isScrollingIntoView = false;
    let scrollTimer = 0;
    /** scroll to option */ const onPreselectedIndexChange = (index, oldIndex)=>{
        if (index < 0) return;
        Timer.clearTimeout(scrollTimer);
        const safeIndex = buttonsRef.value?.[index] ? index : 0;
        if (safeIndex === lastHoverIndex) // prevent scroll on hover
        return;
        lastHoverIndex = null;
        const button = buttonsRef.value?.[safeIndex];
        isScrollingIntoView = true;
        scrollTimer = Timer.setTimeout(async ()=>{
            scrollbarRef.value?.scrollToElement(button, {
                onlyIfNeeded: true,
                alignToTop: oldIndex > safeIndex
            });
            await nextAnimationFrame();
            isScrollingIntoView = false;
        }, 0);
    };
    const onAppear = ()=>{
        emit('mounted');
    };
    onBeforeUnmount(()=>Timer.clearTimeout(scrollTimer));
    const onOptionHover = (index)=>{
        if (!isScrollingIntoView) {
            lastHoverIndex = index;
            emit('option-hover', index);
        }
    };
    const setButtonsRef = (templateRef, index)=>{
        if (templateRef) {
            HTMLButtonElement;
            buttonsRef.value[index] = templateRef;
        }
    };
    watch(()=>props.options, (value, oldValue)=>{
        const itemsIsInOverflowSize = 20;
        if (value && value?.length > itemsIsInOverflowSize && oldValue && oldValue?.length > itemsIsInOverflowSize) /**
       * in this case height is limited by screen / scroll. Avoid extra styles recalculation
       * */ return;
        if (value?.length !== oldValue?.length) emit('list-size-updated');
    });
    watch(()=>props.preselectedListIndex, onPreselectedIndexChange);
    return {
        onClick,
        onAppear,
        onOptionHover,
        setButtonsRef,
        scrollbarRef
    };
}
