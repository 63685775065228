import { isString } from '@leon-hub/guards';
import { isValueList } from 'web/src/utils/jsonSchemaAdapter/utils/isStringEntryArray';
export default function getUiFieldRawOptions() {
    let fieldOptions = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
    const result = {};
    for (const item of fieldOptions){
        isString(item.key) && item.key.length;
        if (item.valueList?.length) {
            result[item.key] = item.valueList ?? [];
            isValueList(result[item.key]);
        } else {
            result[item.key] = item.value ?? '';
            isString(result[item.key]);
        }
    }
    return result;
}
