import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class DevToolsModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addDefaultRoute({
            name: RouteName.DEVTOOLS,
            path: '/dev-tools',
            component: ()=>import('web/src/modules/dev-tools/pages/DevtoolsRoutePage/DevtoolsRoutePage.vue')
        });
    }
}
