import { computed, ref, toRef } from 'vue';
import { Timer } from '@leon-hub/utils';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default function useVLoaderDelayed(props) {
    const appVloaderDelayMs = toRef(useSiteConfigStore(), 'appVloaderDelayMs');
    const loaderIsVisible = ref(false);
    let timerId = 0;
    const delayTime = computed(()=>props.delay || appVloaderDelayMs.value || 0);
    const onAppear = ()=>{
        timerId = Timer.setTimeout(()=>{
            loaderIsVisible.value = true;
        }, delayTime.value);
    };
    const onDisappear = ()=>{
        if (timerId) {
            Timer.clearTimeout(timerId);
            timerId = 0;
        }
    };
    return {
        delayTime,
        loaderIsVisible,
        onAppear,
        onDisappear
    };
}
