export default function getBannerWrapperStyles(props, backgroundHeight, isFrontImageAvailable, hasContentBlock) {
    const styles = {
        backgroundColor: props.backgroundColor
    };
    if (backgroundHeight > 0 && !isFrontImageAvailable) {
        if (hasContentBlock) styles.height = 'auto';
        else styles.height = `${backgroundHeight}px`;
    }
    return styles;
}
