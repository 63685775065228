import { LobbyGameType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { isString } from '@leon-hub/guards';
import { HistoryGamesCategoryUrl } from 'web/src/modules/egs/constants';
import { EgsGamesCategoryId } from 'web/src/modules/egs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import isFavoriteEgsRoute from './isFavoriteEgsRoute';
import { getLobbyCategoryUrlFromRoute } from './getLobbyCategoryUrlFromRoute';
export default function getCategoryByRoute(categories, route) {
    const { $translate } = useI18n();
    const url = getLobbyCategoryUrlFromRoute(route);
    if (isFavoriteEgsRoute(route)) {
        isString(url);
        return {
            id: EgsGamesCategoryId.MY_GAMES,
            type: LobbyGameType.SLOTS,
            url,
            iconUrl: '',
            nameEn: '',
            name: $translate('WEB2_MY_GAMES').value
        };
    }
    if (url === HistoryGamesCategoryUrl) return {
        id: EgsGamesCategoryId.HISTORY_GAMES,
        type: LobbyGameType.SLOTS,
        url,
        iconUrl: '',
        nameEn: '',
        name: $translate('WEB2_SIDEBAR_EGS_HISTORY_GAMES').value
    };
    return categories.find((category)=>category.url === url);
}
