import { isString } from '@leon-hub/guards';
import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import { isCasUserResponse, isLoginResponse } from './guards';
const encodeFormData = (data)=>Object.keys(data).reduce((parameters, key)=>{
        parameters.append(key, String(data[key]));
        return parameters;
    }, new URLSearchParams());
export default function createCasApi(client) {
    return {
        async loginCas (param) {
            let { username, password, endpoint } = param;
            return client.request({
                id: 'cas-api/login-cas',
                silent: true,
                priority: RequestOptionsPriority.LOW,
                method: 'POST',
                endpoint: `${endpoint}rest/auth/login/`,
                data: password ? encodeFormData({
                    username,
                    password
                }) : encodeFormData({
                    username,
                    'saved-password': true
                }),
                guard: isLoginResponse
            });
        },
        async getSavedLogins (data) {
            return client.request({
                id: 'cas-api/get-saved-logins',
                silent: true,
                priority: RequestOptionsPriority.LOW,
                endpoint: `${data.endpoint}rest/auth/saved-passwords/`,
                guard: isCasUserResponse
            });
        },
        async checkCookie (data) {
            return client.request({
                id: 'cas-api/check-cookie',
                silent: true,
                priority: RequestOptionsPriority.LOW,
                endpoint: `${data.endpoint}rest/auth/cookie-check`,
                guard: isString
            });
        },
        async deleteSavedLogins (param) {
            let { username, endpoint } = param;
            return client.request({
                id: 'cas-api/delete-saved-logins',
                silent: true,
                priority: RequestOptionsPriority.LOW,
                method: 'POST',
                endpoint: `${endpoint}rest/auth/saved-passwords/delete`,
                data: encodeFormData({
                    username
                }),
                guard: isCasUserResponse
            });
        }
    };
}
