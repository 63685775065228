import { computed } from 'vue';
import { TransactionGroup } from '@leon-hub/api-sdk';
import CustomerHistoryListItemBet from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemBet/CustomerHistoryListItemBet.vue';
import CustomerHistoryListItemPayment from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemPayment/CustomerHistoryListItemPayment.vue';
import CustomerHistoryListItemEgs from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemEgs/CustomerHistoryListItemEgs.vue';
export default function useCustomerHistoryListItem(props, emits) {
    const component = computed(()=>{
        switch(props.item.objectType){
            case TransactionGroup.BET:
                return CustomerHistoryListItemBet;
            case TransactionGroup.PAYMENT:
                return CustomerHistoryListItemPayment;
            default:
                return CustomerHistoryListItemEgs;
        }
    });
    function emitItemClick(value) {
        emits('item-click', value);
    }
    function emitCashOut(value) {
        emits('cash-out', value);
    }
    function emitCancelWithdrawal(value) {
        emits('cancel-withdrawal', value);
    }
    return {
        component,
        emitItemClick,
        emitCashOut,
        emitCancelWithdrawal
    };
}
