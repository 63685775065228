import { isBoolean, isNumber, isString, isUndefined, isValidInterface, isOptionalString, isNull, isArrayOf, isArrayOfStrings, isObjectValues } from '@leon-hub/guards';
import { fastTrackMessageDisplayType, fastTrackMessageEvents } from './types';
export function isFastTrackBaseEndpointsResponse(argument) {
    return isValidInterface(argument, {
        pusherKey: isString,
        fusionUrl: isString,
        pusherRegion: isString
    });
}
function isFastTrackUser(argument) {
    return isValidInterface(argument, {
        UserId: isNumber
    });
}
function isFastTrackMessageEvent(argument) {
    return fastTrackMessageEvents.includes(argument);
}
function isFastTrackMessageDisplayType(argument) {
    return fastTrackMessageDisplayType.includes(argument);
}
export function isFastTrackMessage(argument) {
    return isValidInterface(argument, {
        MessageId: isNumber,
        UserId: isNumber,
        Event: isFastTrackMessageEvent,
        Title: isString,
        Message: isString,
        PreviewText: isOptionalString,
        FooterText: isOptionalString,
        Data: [
            isUndefined,
            (value)=>isObjectValues([
                    isString
                ], value)
        ],
        CTAButtonLink: isOptionalString,
        CTAButtonText: isOptionalString,
        CTAButton2Link: isOptionalString,
        CTAButton2Text: isOptionalString,
        ImageUrl: isOptionalString,
        IsRead: isBoolean,
        DisplayType: isFastTrackMessageDisplayType,
        Date: isString,
        Expires: isOptionalString
    });
}
export function isFastTrackMarkMessageAsReadResponse(argument) {
    return isValidInterface(argument, {
        Data: [
            isUndefined,
            isBoolean,
            isNull
        ],
        Success: isBoolean,
        Errors: [
            isNull,
            isArrayOfStrings
        ]
    });
}
export function isFastTrackDeleteMessageResponse(argument) {
    return isValidInterface(argument, {
        Data: [
            isUndefined,
            isBoolean,
            isNull
        ],
        Success: isBoolean,
        Errors: [
            isNull,
            isArrayOfStrings
        ]
    });
}
function isFastTrackAuthentication(argument) {
    return isValidInterface(argument, {
        AuthToken: isString
    });
}
function isFastTrackDoLoginResponseData(argument) {
    return isValidInterface(argument, {
        User: isFastTrackUser,
        Authentication: isFastTrackAuthentication
    });
}
export function isFastTrackDoLoginResponse(argument) {
    return isValidInterface(argument, {
        Data: [
            isUndefined,
            isFastTrackDoLoginResponseData
        ],
        Success: isBoolean,
        Errors: [
            isNull,
            isArrayOfStrings
        ]
    });
}
export function isFastTrackMessagesResponse(argument) {
    return isValidInterface(argument, {
        Data: [
            isUndefined,
            (value)=>isArrayOf(isFastTrackMessage, value)
        ],
        Success: isBoolean,
        Errors: [
            isNull,
            isArrayOfStrings
        ]
    });
}
