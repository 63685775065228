import { isObject } from '@leon-hub/guards';
function isSeoMetaParametersConfig(value) {
    return isObject(value) && 'value' in value;
}
// @TODO custom compare for objects
function isEqualSeoMetaParameterValue(oldValue, newValue) {
    return oldValue === newValue;
}
/** @deprecated */ // eslint-disable-next-line import/prefer-default-export
export function isDifferentMetaParameters(source, target) {
    if (source === target) return false;
    if (!source || !target) return true;
    if (Object.keys(source).length !== Object.keys(target).length) return true;
    return Object.keys(source).some((key)=>{
        const sourceParameter = source[key];
        const targetParameter = target[key];
        const sourceValue = isSeoMetaParametersConfig(sourceParameter) ? sourceParameter.value : sourceParameter;
        const targetValue = isSeoMetaParametersConfig(targetParameter) ? targetParameter.value : targetParameter;
        return !isEqualSeoMetaParameterValue(sourceValue, targetValue);
    });
}
