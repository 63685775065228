import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, mergeProps as _mergeProps, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { nextTick, ref } from 'vue';
import { onComponentActivated } from '@leon-hub/vue-utils';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import { useLobbyEgsGroupItem } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyEgsGroupItem',
    props: {
        item: {},
        isBig: {
            type: Boolean
        },
        isActive: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "selected-loaded"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { src, ntBadgeProperties, href } = useLobbyEgsGroupItem(props);
        const wrapper = ref();
        onComponentActivated(async ()=>{
            if (props.isActive) {
                if (!wrapper.value) await nextTick();
                if (wrapper.value) emit('selected-loaded', wrapper.value);
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(href) ? 'a' : 'div'), {
                ref_key: "wrapper",
                ref: wrapper,
                class: _normalizeClass({
                    [_ctx.$style['lobby-egs-group-item']]: true,
                    [_ctx.$style['lobby-egs-group-item--big']]: _ctx.isBig,
                    [_ctx.$style['lobby-egs-group-item--active']]: _ctx.isActive
                }),
                style: _normalizeStyle({
                    background: _ctx.item?.color
                }),
                href: _unref(href),
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>emit('click', $event), [
                    "prevent"
                ]))
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['lobby-egs-group-item__wrapper'])
                        }, [
                            _ctx.item ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _unref(src) ? (_openBlock(), _createBlock(VImage, {
                                    key: _unref(src),
                                    alt: _ctx.item.name,
                                    src: _unref(src),
                                    "is-lazy": "",
                                    "object-fit": void 0,
                                    class: _normalizeClass(_ctx.$style['lobby-egs-group-item__logo'])
                                }, null, 8, [
                                    "alt",
                                    "src",
                                    "object-fit",
                                    "class"
                                ])) : (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['lobby-egs-group-item__text'])
                                }, _toDisplayString(_ctx.item.name), 3)),
                                _ctx.item.ntBadge ? (_openBlock(), _createBlock(VBadge, _mergeProps({
                                    key: 2
                                }, _unref(ntBadgeProperties), {
                                    class: _ctx.$style['lobby-egs-group-item__nt-badge']
                                }), null, 16, [
                                    "class"
                                ])) : _createCommentVNode("", true)
                            ], 64)) : (_openBlock(), _createBlock(VSkeletonImage, {
                                key: 1
                            }))
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "class",
                "style",
                "href"
            ])), [
                [
                    _directive_auto_id,
                    'LobbyEgsGroupItem'
                ]
            ]);
        };
    }
});
