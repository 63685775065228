import { PlaceholderType } from 'web/src/modules/bonuses/store/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line import/prefer-default-export
export function replacePlaceholders(param) {
    let { text = '', placeholders = [], formatMoney } = param;
    const { $translate } = useI18n();
    let returnValue = `${text}`;
    if (placeholders.length) {
        for (const { type, name, value, currency } of placeholders)if (type === PlaceholderType.MONEY) {
            const textReplace = formatMoney(Number(value), {
                currency: currency ?? ''
            });
            returnValue = returnValue.split(name).join(textReplace);
        } else if (type === PlaceholderType.COUNT) returnValue = returnValue.split(name).join(`${value} ${$translate('WEB2_BONUSES_FREESPIN_COUNT').value}`);
        return returnValue;
    }
    return text;
}
