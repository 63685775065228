import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { getSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';
export function useHomeSportlineElementSeoMeta(props) {
    const { sportElements } = props;
    const router = useRouter();
    const metaParameters = computed(()=>({
            sportEvents: {
                value: sportElements.value.flatMap((sportElement)=>sportElement.sportEvents.map((sportEventElement)=>getSportEventSeoMetaInfo({
                            sportEvent: sportEventElement.sportEvent,
                            sport: sportElement.sport,
                            region: sportEventElement.region,
                            league: sportEventElement.league,
                            vm: {
                                $router: router
                            }
                        }))),
                merge (oldValue, newValue) {
                    return [
                        ...oldValue,
                        ...newValue
                    ];
                }
            }
        }));
    useSeoMetaParameters(metaParameters);
}
