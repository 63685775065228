import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconName } from '@leon-hub/icons';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { useTwoFactorAuthenticationStore } from 'web/src/modules/two-factor-authentication/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TfaRoutePageTopBar',
    props: {
        title: {}
    },
    setup (__props) {
        const router = useRouter();
        const tfaStore = useTwoFactorAuthenticationStore();
        const currentStepTfa = toRef(tfaStore, 'currentStepTfa');
        function onClickBack() {
            currentStepTfa.value;
            router.back();
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, _mergeProps({}, {
                title: _ctx.title,
                onBack: onClickBack
            }), null, 16, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'TfaRoutePageTopBar'
                ]
            ]);
        };
    }
});
