import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { reduceListWithSportEventsCounters, addCountersToCounters, getSportEventElementsListCounters } from 'web/src/modules/sportline/utils';
import { assertFullCoreLeagueResponse, assertFullCoreRegionResponse, assertFullCoreSportResponse } from 'web/src/modules/sportline/utils/rest/assert';
import { extractBuildSportOptions } from 'web/src/modules/sportline/utils/rest/pre-build';
import { parseRegionResponse, parseSportResponse, parseLeagueResponse, createSport, createSportEvent, createEventStatusBasedOnBetlineStatus } from 'web/src/modules/sportline/utils/rest/build';
// @TODO share create elements with SportlineFactory
export function buildSportlineSportElement(response) {
    let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : extractBuildSportOptions();
    const leagueResponse = assertFullCoreLeagueResponse(response.league);
    const regionResponse = assertFullCoreRegionResponse(leagueResponse.region);
    const sportResponse = assertFullCoreSportResponse(leagueResponse.sport);
    const buildOptions = {
        ...options,
        sportResponse,
        regionResponse,
        leagueResponse
    };
    const rawSport = parseSportResponse(sportResponse, buildOptions);
    const rawRegion = parseRegionResponse({
        ...buildOptions,
        sportResponse,
        regionResponse
    });
    const rawLeague = parseLeagueResponse({
        ...buildOptions,
        sportResponse,
        regionResponse,
        leagueResponse
    });
    const sportlineEvent = createSportEvent(response, {
        ...buildOptions,
        sportResponse,
        regionResponse,
        leagueResponse,
        useFullProgress: rawSport.sport.representation.useFullProgress
    });
    const marketTypes = sportlineEvent.markets.map((param)=>{
        let { type } = param;
        return type;
    });
    // @TODO check status logic for details page
    const sportlineEventElement = {
        sportEvent: {
            ...sportlineEvent,
            status: createEventStatusBasedOnBetlineStatus(response)
        }
    };
    const sportEvents = sportlineEventElement.sportEvent.isOutright ? [] : [
        sportlineEventElement
    ];
    const outrightEvents = sportlineEventElement.sportEvent.isOutright ? [
        sportlineEventElement
    ] : [];
    const leagueElement = {
        key: `1_${rawLeague.league.id}`,
        league: rawLeague.league,
        marketTypes,
        sportEvents,
        outrightEvents,
        counters: addCountersToCounters(getSportEventElementsListCounters(sportEvents), getSportEventElementsListCounters(outrightEvents))
    };
    const regionElement = {
        key: `1_${rawRegion.region.id}`,
        region: rawRegion.region,
        marketTypes,
        leagues: [
            leagueElement
        ],
        counters: reduceListWithSportEventsCounters([
            leagueElement
        ])
    };
    return {
        key: rawSport.sport.id,
        sport: rawSport.sport,
        regions: [
            regionElement
        ],
        marketTypes,
        marketsColumns: rawSport.marketsColumns,
        counters: reduceListWithSportEventsCounters([
            regionElement
        ])
    };
}
/**
 * @TODO Split file
 */ export class DetailsAdapter {
    get sportEventDetails() {
        const { response, options } = this;
        const leagueResponse = assertFullCoreLeagueResponse(response.league);
        const regionResponse = assertFullCoreRegionResponse(leagueResponse.region);
        const sportResponse = assertFullCoreSportResponse(leagueResponse.sport);
        const buildOptions = {
            ...options,
            sportResponse,
            regionResponse,
            leagueResponse
        };
        const sport = createSport({
            ...options,
            sport: sportResponse
        });
        const { region } = parseRegionResponse(buildOptions);
        const { league } = parseLeagueResponse(buildOptions);
        const sportlineEvent = createSportEvent(response, {
            ...buildOptions,
            useFullProgress: sport.representation.useFullProgress
        });
        return {
            ...sportlineEvent,
            status: createEventStatusBasedOnBetlineStatus(response),
            sport,
            league,
            region
        };
    }
    constructor(response, options = extractBuildSportOptions()){
        _define_property(this, "response", void 0);
        _define_property(this, "options", void 0);
        this.response = response;
        this.options = options;
    }
}
