import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef } from 'vue';
import { useRulesStore } from 'web/src/modules/rules/store';
import RulesContentList from 'web/src/modules/rules/components/RulesContentList.vue';
import RulesContentDefault from 'web/src/modules/rules/components/RulesContentDefault.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RulesRouteComponent',
    setup (__props) {
        const navigation = toRef(useRulesStore(), 'navigation');
        return (_ctx, _cache)=>navigation.value.length ? (_openBlock(), _createBlock(RulesContentList, {
                key: 0
            })) : (_openBlock(), _createBlock(RulesContentDefault, {
                key: 1,
                "cms-key": 'SPORT_BETTING_GUIDELINES_DESKTOP_WEB2',
                title: _ctx.$t('SPORT_BETTING_RULES_TITLE_WEB2')
            }, null, 8, [
                "cms-key",
                "title"
            ]));
    }
});
