import { ref, computed } from 'vue';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { getBetlineLeaguePlayoff } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';
export function useLeaguePlayoffStoreComposable(props) {
    const { isEnabled, expectedId } = props;
    const { isLoading, isExpectedIdLoaded, setIsLoading, setLastLoadedId } = useExpectedIdLoading({
        expectedId
    });
    const leaguePlayoff = ref(null);
    const isReady = computed(()=>!isEnabled.value || isExpectedIdLoaded.value);
    const isEmpty = computed(()=>null === leaguePlayoff.value);
    function clear() {
        leaguePlayoff.value = null;
    }
    async function fetchLeagueLayoff(param) {
        let { silent } = param;
        if (!isEnabled.value) return;
        const leagueId = expectedId.value;
        if (!leagueId) return;
        setIsLoading(true);
        try {
            leaguePlayoff.value = await getBetlineLeaguePlayoff({
                leagueId
            }, {
                silent
            });
        } catch (error) {
            clear();
            if (!isStatisticNotFoundError(error)) throw error;
        } finally{
            setIsLoading(false);
            setLastLoadedId(leagueId);
        }
    }
    return {
        isReady,
        isLoading,
        isEmpty,
        leaguePlayoff,
        fetchLeagueLayoff,
        clear
    };
}
