import { reactive, toRef, toValue, provide, readonly, computed } from 'vue';
import { selectMarketTypeByColumn } from 'web/src/modules/sportline/utils/markets';
import { getMarketColumnsFromMarketColumnsInfo, getMarketTypesFromMarketColumnsInfo } from 'web/src/modules/sportline/composables/markets/utils';
import { ProvideSelectedColumnKey, ProvideSelectColumnMethodKey } from '../constants';
export function useProvideSelectedColumn(props) {
    const sportlineElements = toRef(props.sportlineElements);
    const selectedColumnIdMap = reactive({});
    const columnsMap = computed(()=>{
        const elements = sportlineElements.value;
        const result = {};
        for (const element of elements){
            const { key } = element;
            if (!!key) result[key] = getMarketColumnsFromMarketColumnsInfo(element);
        }
        return result;
    });
    const marketTypesMap = computed(()=>{
        const elements = sportlineElements.value;
        const result = {};
        for (const element of elements){
            const { key } = element;
            if (!!key) result[key] = getMarketTypesFromMarketColumnsInfo(element);
        }
        return result;
    });
    const selectedMarketTypes = computed(()=>{
        const elements = sportlineElements.value;
        const result = {};
        for (const element of elements){
            const { key } = element;
            if (!key) continue;
            const columns = columnsMap.value[key] ?? [];
            const allMarketsTypes = marketTypesMap.value[key] ?? [];
            const selectedColumnId = selectedColumnIdMap[key] ?? null;
            result[key] = selectMarketTypeByColumn({
                columns,
                allMarketsTypes,
                selectedColumnId
            });
        }
        return result;
    });
    const selectedColumnMap = computed(()=>{
        const result = {};
        const selectedTypesValue = selectedMarketTypes.value;
        for (const id of Object.keys(selectedTypesValue)){
            const selectedColumnId = selectedColumnIdMap[id] ?? null;
            const columns = columnsMap.value[id] ?? [];
            const selected = selectedTypesValue[id] ?? null;
            result[id] = {
                selectedColumnId,
                selectedColumnSize: selected?.columns ?? null,
                selectedColumnSign: selected?.sign ?? null,
                columns
            };
        }
        return result;
    });
    function selectColumn(param) {
        let { basisKey, columnId } = param;
        selectedColumnIdMap[toValue(basisKey)] = toValue(columnId);
    }
    provide(ProvideSelectedColumnKey, readonly(selectedColumnMap));
    provide(ProvideSelectColumnMethodKey, selectColumn);
    return {
        selectedMarketTypes,
        selectedColumnMap,
        selectColumn
    };
}
