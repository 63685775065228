import { Tag } from '@leon-hub/tags';
import { ButtonHeight, ButtonKind, ButtonType } from '../enums';
export function getVButtonDefaultProps() {
    return {
        tag: Tag.BUTTON,
        label: '',
        kind: ButtonKind.PRIMARY,
        type: ButtonType.BUTTON,
        role: 'button',
        height: ButtonHeight.LARGE,
        hasAnimation: true,
        isUppercase: true
    };
}
