import getCoreDropdownProps from './getCoreDropdownProps';
const getCoreDropdownPropsFromElement = (element, options)=>{
    const { x, y } = element.getBoundingClientRect();
    const { offsetHeight, offsetWidth } = element;
    return getCoreDropdownProps({
        x,
        y,
        height: offsetHeight,
        width: offsetWidth
    }, options);
};
export default getCoreDropdownPropsFromElement;
