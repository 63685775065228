import { isString } from '@leon-hub/guards';
export default function getOptionsRelatedToSiblingFields(optionName, fieldId, formData) {
    let options = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    if (fieldId && formData?.[fieldId]) {
        const relatedValue = formData[fieldId];
        if (relatedValue) {
            isString(relatedValue);
            return {
                ...options,
                [optionName]: relatedValue
            };
        }
    }
    return options;
}
