import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
export default function betPlaceErrorYMTrigger(param, service) {
    let { errorCode, errorMessage } = param;
    service.push(AnalyticsEvent.Z_BET_ERROR, {
        submitSlipData: {
            error: {
                message: errorMessage,
                code: errorCode
            }
        }
    });
}
