import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BreadcrumbsRelatedListItem',
    props: {
        isActive: {
            type: Boolean
        },
        title: {},
        to: {}
    },
    emits: [
        "link-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VListItem, {
                "is-active": _ctx.isActive,
                class: _normalizeClass([
                    _ctx.$style['breadcrumbs-related-list-item'],
                    _ctx.$style['breadcrumbs-related-list-item--borderless']
                ])
            }, {
                default: _withCtx(()=>[
                        _createVNode(VRouterLink, {
                            to: _ctx.to,
                            class: _normalizeClass(_ctx.$style['breadcrumbs-related-list-item__link']),
                            onClick: _cache[0] || (_cache[0] = ($event)=>emit('link-click'))
                        }, {
                            default: _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                                ]),
                            _: 1
                        }, 8, [
                            "to",
                            "class"
                        ])
                    ]),
                _: 1
            }, 8, [
                "is-active",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'BreadcrumbsRelatedListItem'
                ]
            ]);
        };
    }
});
