import { computed, ref, toRef } from 'vue';
import { isString } from '@leon-hub/guards';
import { Json, keys } from '@leon-hub/utils';
import { getNavigationItemRouteLocation } from '@leon-hub/navigation-config';
import { useTheme } from 'web/src/modules/theme/composables';
import { useNavigationItemsStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { ShowcaseNavigationItemName } from 'web/src/modules/home/submodules/showcase/enums';
import useShowcaseNavigationAnalyticsComposable from './useShowcaseNavigationAnalyticsComposable';
import useShowcaseNavigationImageItemsComposable from './useShowcaseNavigationImageItemsComposable';
import useShowcaseNavigationIconItemsComposable from './useShowcaseNavigationIconItemsComposable';
import useShowcaseNavigationZeroMarginComposable from './useShowcaseNavigationZeroMarginComposable';
import useShowcaseNavigationFavoriteEventsComposable from './useShowcaseNavigationFavoriteEventsComposable';
export default function useShowcaseNavigationComposable() {
    const siteConfigStore = useSiteConfigStore();
    const { theme } = useTheme();
    const isPromotionsHighlightLinkEnabled = toRef(siteConfigStore, 'isPromotionsHighlightLinkEnabled');
    const itemReducers = ref({});
    const { handleNavigationItemClickAnalytics } = useShowcaseNavigationAnalyticsComposable();
    const { createImageNavigationItem } = useShowcaseNavigationImageItemsComposable();
    const { createIconNavigationItem } = useShowcaseNavigationIconItemsComposable();
    const { addZeroMarginReducer, resumeZeroMarginSyncState, pauseZeroMarginSyncState } = useShowcaseNavigationZeroMarginComposable();
    const { addFavoriteEventsReducer } = useShowcaseNavigationFavoriteEventsComposable();
    const navigationStore = useNavigationItemsStore();
    const showcaseItems = toRef(navigationStore, 'showcaseItems');
    const items = computed(// eslint-disable-next-line sonarjs/cognitive-complexity
    ()=>showcaseItems.value.map((item)=>{
            const { id, caption, icon, iconCdn, props } = item;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            const itemReducersKey = keys(itemReducers.value).find((key)=>key === id);
            const reducers = itemReducersKey ? itemReducers.value[itemReducersKey] ?? [] : [];
            if (reducers.length > 0 && !reducers.every((reducer)=>reducer(id))) return null;
            let navIcon = null;
            if (icon) navIcon = createIconNavigationItem(icon);
            else if (iconCdn) navIcon = createImageNavigationItem(iconCdn, isString(props?.iconStyle) ? String(props.iconStyle) : void 0);
            const className = isString(props?.className) ? props?.className : void 0;
            const routerLinkStyle = isString(props?.routerLinkStyle) ? Json.parse(String(props.routerLinkStyle), {
                defaultValue: {}
            }) : {};
            const routerLinkThemeStyle = routerLinkStyle?.[theme.value.toLowerCase()] ?? {};
            return {
                key: `${id}-${theme.value}`,
                name: caption || '',
                className,
                icon: navIcon,
                location: getNavigationItemRouteLocation(item, 'showcase'),
                isHighlighted: className === ShowcaseNavigationItemName.ACTIONS && isPromotionsHighlightLinkEnabled.value,
                isLandingEuroRu: false,
                routerLinkThemeStyle
            };
        }).filter((item)=>!!item));
    function resumeSyncState() {
        resumeZeroMarginSyncState();
    }
    function pauseSyncState() {
        pauseZeroMarginSyncState();
    }
    // init reducers
    let compiledReducers = itemReducers.value;
    compiledReducers = addZeroMarginReducer(compiledReducers);
    compiledReducers = addFavoriteEventsReducer(compiledReducers);
    itemReducers.value = compiledReducers;
    return {
        items,
        handleNavigationItemClickAnalytics,
        resumeSyncState,
        pauseSyncState
    };
}
