import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { FormControlType, FormErrorKeyword } from 'web/src/components/Form/enums';
/**
 * provides similar error text for incorrect phone from LEONWEB-9138
 * */ export default function useFormPhoneErrorPatterns() {
    const { $translate } = useI18n();
    const phoneValidationWidgetError = computed(()=>({
            [FormErrorKeyword.Pattern]: $translate('WEB2_PHONE_INCORRECT').value
        }));
    const phoneErrorPatterns = computed(()=>({
            [FormControlType.Phone]: phoneValidationWidgetError.value,
            [FormControlType.PhoneInput]: phoneValidationWidgetError.value,
            [FormControlType.PhoneCountriesSelectorInput]: phoneValidationWidgetError.value
        }));
    return {
        phoneErrorPatterns
    };
}
