import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
export default function useSportlineSidebarSettingsComposable() {
    const settingsStore = useSportlineSettingsStore();
    const isSportFiltersEnabled = toRef(settingsStore, 'isSportFiltersEnabled');
    const maxTopLeaguesInTab = toRef(settingsStore, 'maxTopLeaguesInTab');
    const maxTopLeaguesTabsInSidebar = toRef(settingsStore, 'maxTopLeaguesTabsInSidebar');
    const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');
    const doUseRelativeTimeForSportline = toRef(settingsStore, 'doUseRelativeTimeForSportline');
    return {
        isSportFiltersEnabled,
        maxTopLeaguesInTab,
        maxTopLeaguesTabsInSidebar,
        parseSportlineSettings,
        doUseRelativeTimeForSportline
    };
}
