import { computed, toRef } from 'vue';
import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
export function useSportlineFavoritesTabList(props) {
    const { isActive, selectedRegionFamily } = props;
    const { isFavoritesLoaded: isLoaded, hasFavoritesOptimistic, setFavoriteEventsBackgroundUpdateEnabled } = useCustomerFavoritesService();
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isMarketTypesSelectionEnabled = toRef(sportlineSettingsStore, 'isMarketTypesSelectionEnabled');
    const isNeedRedirectFromTab = computed(()=>isActive.value && !hasFavoritesOptimistic.value && null === selectedRegionFamily.value);
    function onTabActivated() {
        setFavoriteEventsBackgroundUpdateEnabled(true);
    }
    function onTabDeactivated() {
        setFavoriteEventsBackgroundUpdateEnabled(false);
    }
    return {
        isLoaded,
        isNeedRedirectFromTab,
        isMarketTypesSelectionEnabled,
        onTabActivated,
        onTabDeactivated
    };
}
