import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { PinCodePlace, CustomerConfig, doCreatePinCode, doDeletePinCode, doVerifyPinCode, getPinCodeStatus, PinCodeState as PinCodeStateEnum, RemoteApiErrorExceptionCode, sendRestorePinLinkToPhone, sendRestorePinLinkToEmail, resetPinCodeEmail, resetPinCodePhone, PreviousRequestHasNotExpiredExceptionCode } from '@leon-hub/api-sdk';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { RegistrationApiErrorCode } from 'web/src/modules/registration/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useAuthStore } from 'web/src/modules/auth/store';
export const IS_PIN_CODE_MODAL_CREATE_AVAILABLE = 'IS_PIN_CODE_MODAL_CREATE_AVAILABLE';
export const PIN_CODE_DATA = 'PIN_CODE_DATA';
const usePinCodeStore = defineStore('pin-code', ()=>{
    const prevStep = ref(null);
    const step = ref(null);
    const loading = ref(false);
    const error = ref('');
    const status = ref(null);
    const pinCodeModalCounter = ref(0);
    const pinCodeRequestedAtLogin = ref(false);
    const bannedUntil = ref(0);
    const pinCodePlace = ref(null);
    const resetToken = ref('');
    const isPhoneReset = ref(false);
    const resetErrorMessage = ref('');
    const routeFrom = ref(null);
    const showCompleteRegistrationFields = ref(false);
    const wasPinCodeSetOnce = ref(false);
    const isRestoreByEmailSent = ref(false);
    const isRestoreByPhoneSent = ref(false);
    const code = ref('');
    const isStatusIgnored = ref(false);
    const customerDataStore = useCustomerDataStore();
    const apiClient = useGraphqlClient();
    const localStorageManager = useLocalStorageManager();
    const isLoggedIn = toRef(useAuthStore(), 'isLoggedIn');
    const { convertToBaseError } = useErrorsConverter();
    const { setAdditionalPropsUser, doPinCodeLogin } = useUserStore();
    const pinCodeRequestedPlaces = toRef(useSiteConfigStore(), 'pinCodeRequestedPlaces');
    const createPinCodeModalCounter = computed(()=>customerDataStore.createPinCodeModalCounter);
    const isPinCodeRequestedOnLogin = computed(()=>customerDataStore.isPinCodeRequestedOnLogin);
    const setStep = (value)=>{
        prevStep.value = step.value;
        step.value = value;
    };
    const setStepWithLS = (value)=>{
        setStep(value);
        const data = JSON.stringify({
            prevStep: prevStep.value,
            routeFrom: routeFrom.value
        });
        localStorageManager.setItem(PIN_CODE_DATA, data);
    };
    const setStepCountdown = ()=>{
        if (!isStatusIgnored.value) setStepWithLS('COUNTDOWN');
    };
    const setCountdown = (value)=>{
        const date = new Date(value);
        bannedUntil.value = date.getTime();
        setStepCountdown();
    };
    const setStatusIgnored = (value)=>{
        isStatusIgnored.value = value;
    };
    const setPinCodeModalCounter = (value)=>{
        pinCodeModalCounter.value = value;
    };
    const setPinCodeRequestedAtLogin = (value)=>{
        pinCodeRequestedAtLogin.value = value ?? isPinCodeRequestedOnLogin.value;
    };
    const setBannedUntil = (value)=>{
        bannedUntil.value = value;
    };
    const setShowCompleteRegistrationFields = (value)=>{
        showCompleteRegistrationFields.value = value;
    };
    const setCode = (value)=>{
        code.value = value;
    };
    const setResetErrorMessage = (value)=>{
        resetErrorMessage.value = value;
    };
    const setError = (value)=>{
        error.value = value;
    };
    const setPinCodePlace = (value)=>{
        pinCodePlace.value = value;
    };
    const setIsRestoreByEmailSent = (value)=>{
        isRestoreByEmailSent.value = value;
    };
    const setIsRestoreByPhoneSent = (value)=>{
        isRestoreByPhoneSent.value = value;
    };
    const setRouteFrom = (value)=>{
        routeFrom.value = value;
        const data = JSON.stringify({
            prevStep: prevStep.value,
            routeFrom: routeFrom.value
        });
        localStorageManager.setItem(PIN_CODE_DATA, data);
    };
    const handleError = (rawError)=>{
        const err = convertToBaseError(rawError);
        const errorCode = err.code.toString();
        const { extensions } = rawError;
        const availableErrorTypeForCountdown = [
            RegistrationApiErrorCode.TOO_MANY_ATTEMPTS,
            RemoteApiErrorExceptionCode.PIN_CODE_IS_BANNED
        ];
        if (availableErrorTypeForCountdown.includes(errorCode) && extensions.bannedUntil) setCountdown(extensions.bannedUntil);
        else error.value = err.message;
        loading.value = false;
    };
    const resetPinCodeToken = (param)=>{
        let { value, phoneReset = false } = param;
        resetToken.value = value;
        isPhoneReset.value = phoneReset;
    };
    const refreshPinCodeStatus = async ()=>{
        if (pinCodeRequestedPlaces.value?.length) try {
            const data = await getPinCodeStatus(apiClient, (node)=>node.queries.pinCode.getPinCodeStatus);
            status.value = data.status;
            wasPinCodeSetOnce.value = data.wasPinCodeSetOnce;
            if (data.status && data.status === PinCodeStateEnum.RESET && 'CREATE' !== step.value) setStepWithLS('MODAL_CREATE');
            if (data.bannedUntil) setCountdown(data.bannedUntil);
            else setBannedUntil(0);
            return data.status;
        } catch (rawError) {
            handleError(rawError);
            throw rawError;
        }
    };
    const createPinCode = async (value)=>{
        loading.value = true;
        try {
            await doCreatePinCode(apiClient, (node)=>node.mutations.pinCode.createPinCode, {
                options: {
                    code: value
                }
            });
            await refreshPinCodeStatus();
            loading.value = false;
        } catch (rawError) {
            handleError(rawError);
            throw rawError;
        }
    };
    const deletePinCode = async (value)=>{
        loading.value = true;
        try {
            await doDeletePinCode(apiClient, (node)=>node.mutations.pinCode.deletePinCode, {
                options: {
                    code: value
                }
            });
            await refreshPinCodeStatus();
            loading.value = false;
        } catch (rawError) {
            handleError(rawError);
            throw rawError;
        }
    };
    const verifyPinCode = async (value)=>{
        loading.value = true;
        try {
            await doVerifyPinCode(apiClient, (node)=>node.mutations.pinCode.verifyPinCode, {
                options: {
                    code: value,
                    place: pinCodePlace.value ?? PinCodePlace.UNKNOWN
                }
            });
            if (pinCodePlace.value === PinCodePlace.PIN_CODE_AT_LOGIN_SETTING) {
                await setAdditionalPropsUser({
                    value: {
                        value: !pinCodeRequestedAtLogin.value
                    },
                    config: CustomerConfig.IS_PIN_CODE_REQUESTED_ON_LOGIN
                });
                pinCodeRequestedAtLogin.value = !pinCodeRequestedAtLogin.value;
            }
            pinCodePlace.value = null;
            loading.value = false;
        } catch (rawError) {
            handleError(rawError);
            throw rawError;
        }
    };
    const loginPinCode = async (value)=>{
        loading.value = true;
        try {
            await doPinCodeLogin(value);
            await refreshPinCodeStatus();
            loading.value = false;
            setStepWithLS(null);
        } catch (rawError) {
            handleError(rawError);
            throw rawError;
        }
    };
    const handleSendRestorePinTokenPhone = async (method)=>{
        await sendRestorePinLinkToPhone(apiClient, (node)=>node.mutations.pinCode.sendResetTokenPhone, {
            options: {
                method
            }
        }).catch((originalError)=>{
            const err = convertToBaseError(originalError);
            if (!err.code.equals(PreviousRequestHasNotExpiredExceptionCode.PREVIOUS_REQUEST_HAS_NOT_EXPIRED)) throw originalError;
        });
    };
    const handleSendRestorePinLinkToEmail = async ()=>{
        await sendRestorePinLinkToEmail(apiClient, (node)=>node.mutations.pinCode.sendResetTokenEmail, {
            options: {}
        });
    };
    const resetPinCodeByEmail = async (token)=>{
        await resetPinCodeEmail(apiClient, (node)=>node.mutations.pinCode.resetPinCodeEmail, {
            options: {
                token
            }
        });
    };
    const resetPinCodeByPhone = async (token)=>{
        await resetPinCodePhone(apiClient, (node)=>node.mutations.pinCode.resetPinCodePhone, {
            options: {
                token
            }
        });
        await refreshPinCodeStatus();
    };
    const processInit = async ()=>{
        const pinCodeData = localStorageManager.getItem(PIN_CODE_DATA);
        if (pinCodeData) {
            const data = JSON.parse(pinCodeData);
            setStep(data.prevStep);
            routeFrom.value = data.routeFrom;
        }
        await refreshPinCodeStatus();
        pinCodeModalCounter.value = createPinCodeModalCounter.value;
        const isModalCreateAvailable = localStorageManager.getItem(IS_PIN_CODE_MODAL_CREATE_AVAILABLE);
        if (!isModalCreateAvailable) localStorageManager.setItem(IS_PIN_CODE_MODAL_CREATE_AVAILABLE, '1');
        setPinCodeRequestedAtLogin();
    };
    function init() {
        if (pinCodeRequestedPlaces.value?.length) {
            processInit();
            watch(isLoggedIn, (newValue)=>{
                if (newValue) processInit();
                else {
                    setStepWithLS(null);
                    setRouteFrom(null);
                    status.value = null;
                    pinCodeRequestedAtLogin.value = false;
                    showCompleteRegistrationFields.value = false;
                    processInit();
                }
            });
        }
    }
    init();
    return {
        pinCodeRequestedAtLogin,
        pinCodePlace,
        isPhoneReset,
        resetErrorMessage,
        wasPinCodeSetOnce,
        isRestoreByEmailSent,
        isRestoreByPhoneSent,
        refreshPinCodeStatus,
        deletePinCode,
        verifyPinCode,
        loginPinCode,
        step,
        prevStep,
        showCompleteRegistrationFields,
        pinCodeModalCounter,
        routeFrom,
        loading,
        status,
        error,
        bannedUntil,
        code,
        resetToken,
        isStatusIgnored,
        setStep,
        setStepWithLS,
        setRouteFrom,
        setPinCodeModalCounter,
        setBannedUntil,
        setShowCompleteRegistrationFields,
        createPinCode,
        resetPinCodeToken,
        setCode,
        resetPinCodeByEmail,
        resetPinCodeByPhone,
        setResetErrorMessage,
        setPinCodeRequestedAtLogin,
        setPinCodePlace,
        setError,
        setIsRestoreByEmailSent,
        setIsRestoreByPhoneSent,
        handleSendRestorePinTokenPhone,
        handleSendRestorePinLinkToEmail,
        handleError,
        setStatusIgnored,
        setStepCountdown
    };
});
export default usePinCodeStore;
