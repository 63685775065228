import { extractMarketStatus } from 'web/src/modules/sportline/utils/rest/pre-build';
import { createRunner } from './createRunner';
import createMarketType from './createMarketType';
export function createMarket(marketResponse, properties, options) {
    const { eventId, eventStatus } = properties;
    const marketId = String(marketResponse.id);
    const marketStatus = extractMarketStatus(eventStatus, marketResponse);
    const { handicap } = marketResponse;
    const createRunnerProperties = {
        eventId,
        marketId,
        handicap,
        eventStatus,
        marketStatus
    };
    const runners = marketResponse.runners.map((runnerResponse)=>createRunner(runnerResponse, createRunnerProperties));
    return {
        eventId,
        id: marketId,
        name: marketResponse.name,
        type: createMarketType(marketResponse),
        isPrimary: marketResponse.primary,
        status: marketStatus,
        runners,
        columns: marketResponse.cols,
        handicap,
        hasZeroMargin: options.canUseZeroMargin && (marketResponse.hasZeroMargin ?? false)
    };
}
export function createMarkets(markets, properties, options) {
    return (markets || []).map((marketResponse)=>createMarket(marketResponse, properties, options));
}
