import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef } from 'vue';
import SidebarMenuKnowHowRouteComponent from 'web/src/modules/sidebar/views/SidebarMenuKnowHowRouteComponent/SidebarMenuKnowHowRouteComponent.vue';
import { useRulesStore } from 'web/src/modules/rules/store';
import RulesSidebarList from 'web/src/modules/rules/components/RulesSidebarList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RulesSidebarRouteComponent',
    setup (__props) {
        const navigation = toRef(useRulesStore(), 'navigation');
        return (_ctx, _cache)=>navigation.value.length ? (_openBlock(), _createBlock(RulesSidebarList, {
                key: 0
            })) : (_openBlock(), _createBlock(SidebarMenuKnowHowRouteComponent, {
                key: 1
            }));
    }
});
