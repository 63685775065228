import { ComponentKey, ComponentStatus } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let LanguageComponent = class LanguageComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result([
            window.navigator.language || ComponentStatus.NotAvailable
        ]);
    }
    constructor(){
        super(ComponentKey.Language);
    }
};
export { LanguageComponent as default };
