import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { getCmsContentSpintaxBatchTranslations, getCmsContentTranslations } from '@leon-hub/api-sdk/src/sdk/sdk';
import { RequestGroupScore24 } from '@leon-hub/api';
import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import { useI18nStore } from 'web/src/modules/i18n/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useCmsStore = defineStore('cms', ()=>{
    const api = useGraphqlClient();
    const i18nStore = useI18nStore();
    const locale = toRef(i18nStore, 'locale');
    const cmsData = {};
    const cmsSpintaxData = {};
    function checkCmsData(lang, key) {
        if (!cmsData[lang]) cmsData[lang] = {};
        if (!cmsData[lang][key]) cmsData[lang][key] = ref(void 0);
    }
    function checkSpintaxData(lang) {
        if (!cmsSpintaxData[lang]) cmsSpintaxData[lang] = ref('');
    }
    async function fetchCmsContentTranslation(param) {
        let { key, silent } = param;
        const response = await getCmsContentTranslations(api, (node)=>node.queries.system.cms.content, {
            options: {
                key
            }
        }, {
            silent,
            priority: silent ? RequestOptionsPriority.LOW : void 0
        });
        if (response) for (const translation of response.translations){
            checkCmsData(translation.lang, key);
            cmsData[translation.lang][key].value = translation.html || '';
        }
    }
    async function fetchCmsContentSpintaxBatchTranslations(param) {
        let { keys, cmsParams, silent } = param;
        try {
            const response = await getCmsContentSpintaxBatchTranslations(api, (node)=>node.queries.system.cms.getSpintaxBatch, {
                options: {
                    contentKeys: [
                        ...keys
                    ],
                    ...cmsParams
                }
            }, {
                silent,
                priority: silent ? RequestOptionsPriority.LOW : void 0,
                group: RequestGroupScore24
            });
            if (response) for (const translation of response.translations){
                checkSpintaxData(translation.lang);
                cmsSpintaxData[translation.lang].value = translation.html || '';
            }
        } catch  {}
    }
    // empty
    function hasCmsItem(key) {
        return cmsData[locale.value]?.[key]?.value !== void 0;
    }
    function getCmsContent(isSpintaxEnabled, key) {
        return computed(()=>{
            if (isSpintaxEnabled.value) {
                checkSpintaxData(locale.value);
                return cmsSpintaxData[locale.value].value;
            }
            if (!key.value) return;
            checkCmsData(locale.value, key.value);
            return cmsData[locale.value][key.value].value;
        });
    }
    function resetCmsSpintaxData() {
        for (const item of Object.values(cmsSpintaxData))item.value = '';
    }
    return {
        fetchCmsContentTranslation,
        fetchCmsContentSpintaxBatchTranslations,
        hasCmsItem,
        resetCmsSpintaxData,
        getCmsContent
    };
});
export default useCmsStore;
