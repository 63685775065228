import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, unref as _unref } from "vue";
import { onBeforeUnmount, onMounted } from 'vue';
import { useVCountdown } from 'web/src/components/Countdown/VCountdown/composable';
import { CountdownType } from 'web/src/components/Countdown/VCountdown/enum';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCountdown',
    props: {
        expirationDate: {},
        title: {},
        isShortNames: {
            type: Boolean
        },
        countdownType: {
            default: CountdownType.DAY_HOURS_MINUTES_SECONDS
        }
    },
    emits: [
        "countdown-end",
        "countdown-change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { updateNow, clearNow, clearTimer, timeLabel } = useVCountdown(props, emit);
        onMounted(()=>{
            updateNow(true);
        });
        onBeforeUnmount(()=>{
            clearNow();
            clearTimer();
        });
        return (_ctx, _cache)=>_toDisplayString(_ctx.title) + " " + _toDisplayString(_unref(timeLabel));
    }
});
