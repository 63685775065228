import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'DefaultFadeTransition',
    props: {
        isTransitionDisabled: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>_ctx.isTransitionDisabled ? _renderSlot(_ctx.$slots, "default", {
                key: 1
            }) : (_openBlock(), _createBlock(_Transition, {
                key: 0,
                name: "fade",
                "enter-active-class": _ctx.$style['fade-enter-active'],
                "enter-from-class": _ctx.$style['fade-enter-from'],
                "leave-active-class": _ctx.$style['fade-leave-active'],
                "leave-to-class": _ctx.$style['fade-leave-to']
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 8, [
                "enter-active-class",
                "enter-from-class",
                "leave-active-class",
                "leave-to-class"
            ]));
    }
});
