import RouteName from '@leon-hub/routing-config-names';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { LobbyGameType } from '@leon-hub/api-sdk';
export default function getLobbyLocationByGroup(group) {
    let routeName;
    switch(group.type){
        case LobbyGameType.LIVE:
            routeName = CasinoRouteName.CASINO_LIVE_LOBBY;
            break;
        case LobbyGameType.BETGAMES:
            return {
                name: CasinoRouteName.CASINO_BETGAMES
            };
        case LobbyGameType.FASTGAMES:
            return {
                name: RouteName.QUICK_BETS
            };
        case LobbyGameType.SLOTS:
        default:
            routeName = CasinoRouteName.CASINO_LOBBY;
            break;
    }
    return {
        name: routeName,
        params: {
            groupUrl: group.url
        }
    };
}
