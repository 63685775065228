import { toRef, computed } from 'vue';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
export function useRegionLogoProps(props) {
    const rawCode = toRef(props, 'countryCode');
    const sportSegmentId = toRef(props, 'sportSegmentId');
    const countryCode = computed(()=>rawCode.value ?? null);
    const isCyberSport = computed(()=>sportSegmentId.value === SportSegmentId.CyberSport);
    return {
        countryCode,
        sportSegmentId,
        isCyberSport
    };
}
