import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { CustomerRegistrationType, PhoneCodeMethod, RegistrationStateExceptionCode } from '@leon-hub/api-sdk';
import { isEnumOfType } from '@leon-hub/guards';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import RouteName from '@leon-hub/routing-config-names';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
import { useRegistrationStage } from 'web/src/modules/registration/store/composables';
import { useRegistrationForms } from 'web/src/modules/registration/store/composables/useRegistrationForms';
import { SimpleRegistrationState } from 'web/src/modules/registration/submodules/simplified-registration/types';
import { useRegistrationErrors } from 'web/src/modules/registration/store/composables/useRegistrationErrors';
import { useFreeBetData } from 'web/src/modules/registration/store/composables/useFreeBetData';
import { RegistrationFormStep, RegistrationStage, RegistrationTypeMetrika } from 'web/src/modules/registration/enums';
import { useCountdownTimerManager } from 'web/src/modules/registration/composables/useCountdownTimerManager';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useGoToRouteName } from 'web/src/modules/registration/store/composables/useGoToRouteName';
export const useSimplifiedRegistrationStore = defineStore('simplified-registration', ()=>{
    const registrationStage = useRegistrationStage();
    const regForms = useRegistrationForms();
    const regErros = useRegistrationErrors();
    const freeBetData = useFreeBetData();
    const routeGo = useGoToRouteName();
    const countdownTimer = useCountdownTimerManager();
    const errorConverter = useErrorsConverter();
    const router = useRouter();
    const isRegistrationComplete = useRegistrationComplete();
    const customerData = useCustomerDataStore();
    toRef(customerData, 'login');
    toRef(customerData, 'country');
    const regState = ref(SimpleRegistrationState.ANONYM);
    const phoneCheckType = ref(PhoneCodeMethod.SMS);
    const analytics = useAnalytics();
    async function onLogoutReset() {
        regErros.setRegistrationError('');
        regState.value = SimpleRegistrationState.ANONYM;
        regForms.setSubmittedPhone(false);
        registrationStage.resetStages();
        isRegistrationComplete.set(false);
        regForms.setSelectedRegistrationType(null);
        await regForms.resetRegistrationState();
    }
    function setCurrentRegistrationType(registrationType) {
        regForms.setSelectedRegistrationType(registrationType);
        regForms.setCurrentForm(regForms.selectedForm.value);
        regForms.resetCaptchaToken();
    }
    const registrationStep = computed(()=>{
        if (isEnumOfType(RegistrationFormStep)(regForms.registrationStep.value)) return regForms.registrationStep.value;
        return RegistrationFormStep.Initial;
    });
    function setPhoneCheckType(type) {
        phoneCheckType.value = type;
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function onSubmitHandler(data, options) {
        try {
            await regForms.onSubmitHandler(data, options);
            if (registrationStep.value === RegistrationFormStep.Sms) setPhoneCheckType(PhoneCodeMethod.SMS);
            else if (registrationStep.value === RegistrationFormStep.CallPasswordCode) setPhoneCheckType(PhoneCodeMethod.CALL_PASSWORD_CODE);
            if (registrationStep.value !== RegistrationFormStep.Initial && !regForms.hasCustomErrors.value) registrationStage.goToStage({
                stage: RegistrationStage.PhoneCheck
            });
            if (isRegistrationComplete.value) {
                if (regForms.generatedPassword.value || 'SUCCESS' !== regForms.submitResult.value) {
                    if (regForms.generatedPassword.value) registrationStage.goToStage({
                        stage: RegistrationStage.Success
                    });
                } else if (regForms.registrationType.value === CustomerRegistrationType.SIMPLE_EMAIL) regState.value = SimpleRegistrationState.EMAIL_REGISTRATION_DONE;
                else regState.value = SimpleRegistrationState.AUTHORIZED;
            }
        } catch (rawError) {
            const error = errorConverter.convertToBaseError(rawError);
            if (error.code.equals(RegistrationStateExceptionCode.REGISTRATION_STATE_ERROR)) {
                await regForms.resetRegistrationState();
                registrationStage.goToStage({
                    stage: RegistrationStage.Main,
                    clearHistory: true
                });
            }
            if (handleDoRegistrationError({
                error
            })) return;
            throw rawError;
        }
    }
    function handleDoRegistrationError(param) {
        let { error } = param;
        const { message } = error;
        regErros.setRegistrationError(message);
        regErros.setRegistrationErrorCode(`${error.code}`);
        return regErros.handleDuplicateError(error, ()=>{
            regForms.resetRegistrationState();
            "1";
            router.replace({
                name: RouteName.LOGIN
            });
        }, ()=>{
            router.push({
                name: RouteName.SUPPORT
            });
        }, ()=>{
            regForms.resetRegistrationState();
            // LEONWEB-11962 do not push from registration to registration (to avoid extra pages in history)
            router.replace({
                name: RouteName.REGISTRATION
            });
            regErros.setRegistrationError('');
            regErros.setRegistrationErrorCode('');
            regState.value = SimpleRegistrationState.FORM_READY;
        });
    }
    function setMetrikaPhoneCheck(action) {
        analytics.push(AnalyticsEvent.Z_PHONE_CHECK, {
            registration: {
                [RegistrationTypeMetrika.BY_PHONE]: {
                    phoneCheck: {
                        [action]: true
                    }
                }
            }
        });
    }
    function setMetrikaRegistrationSuccess(param) {
        let { registrationType } = param;
    }
    return {
        ...registrationStage,
        ...regForms,
        ...regErros,
        ...freeBetData,
        ...countdownTimer,
        ...routeGo,
        registrationStep,
        onSubmitHandler,
        phoneCheckType,
        setCurrentRegistrationType,
        regState,
        onLogoutReset,
        setMetrikaPhoneCheck,
        setMetrikaRegistrationSuccess
    };
});
