import { defineStore } from 'pinia';
import { ref, computed, toRef, watch } from 'vue';
import { safeParseInt } from '@leon-hub/utils';
import { useSportlinePageIdentifier } from 'web/src/modules/sportline/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import useSportlineMatchStatisticsStoreComposable from './composables/useSportlineMatchStatisticsStoreComposable';
import useSportlinePostMatchStatisticsStoreComposable from './composables/useSportlinePostMatchStatisticsStoreComposable';
import useSportlineLeagueStandingsStatisticsStoreComposable from './composables/useSportlineLeagueStandingsStatisticsStoreComposable';
export const useSportlineEventStatisticStore = defineStore('sportline-event-statistic', ()=>{
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isSportEventStatisticEnabled = toRef(sportlineSettingsStore, 'isSportEventStatisticEnabled');
    const { pageIdentifier, setPageIdentifier } = useSportlinePageIdentifier();
    const currentLoadedMatchIdentifier = ref(null);
    const isBackgroundRequestPriorityIncreased = ref(false);
    // remove false from the value
    const internalIdentifier = computed(()=>currentLoadedMatchIdentifier.value ? currentLoadedMatchIdentifier.value : null);
    const matchId = computed(()=>internalIdentifier.value?.id?.toString());
    const matchBetline = computed(()=>internalIdentifier.value?.betline);
    const matchStatus = computed(()=>internalIdentifier.value?.status);
    const setIsBackgroundRequestPriorityIncreased = function(value) {
        isBackgroundRequestPriorityIncreased.value = value;
    };
    const sportlineEventStatisticUpdateInterval = computed(()=>isBackgroundRequestPriorityIncreased.value ? 20000 : 60000);
    const expectedStatisticId = computed(()=>{
        const eventId = pageIdentifier.value.statisticId || pageIdentifier.value.sportEventId;
        return void 0 !== eventId ? safeParseInt(eventId) : null;
    });
    const { betlineMatchStatistics, hasMatchStatistic, setBetlineMatchStatistics, fetchH2hMatchStatistics } = useSportlineMatchStatisticsStoreComposable({
        expectedStatisticId
    });
    const { betlinePostMatchStatistics, postMatchStatistics, hasPostMatchStatistics, isPostMatchStatisticLoaded, confrontationBlocks, setBetlinePostMatchStatistics, initialRequests: initialPostMatchRequests, onInit: onPostMatchInit, syncBackgroundRequests: syncPostMatchBackgroundRequests, setBackgroundUpdateEnabled: setPostMatchBackgroundUpdateEnabled } = useSportlinePostMatchStatisticsStoreComposable({
        isSportEventStatisticEnabled,
        expectedStatisticId,
        currentLoadedMatchIdentifier,
        sportlineEventStatisticUpdateInterval,
        isBackgroundRequestPriorityIncreased,
        setIsBackgroundRequestPriorityIncreased
    });
    const { betlineLeagueStandings, setBetlineLeagueStandings, initialRequests: initialLeagueStandingsRequests, onInit: onLeagueStandingsInit, syncBackgroundRequests: syncLeagueStandingsBackgroundRequests, setBackgroundUpdateEnabled: setLeagueStandingsBackgroundUpdateEnabled } = useSportlineLeagueStandingsStatisticsStoreComposable({
        isSportEventStatisticEnabled,
        expectedStatisticId,
        currentLoadedMatchIdentifier,
        sportlineEventStatisticUpdateInterval
    });
    const statisticSportFamily = computed(()=>// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        (postMatchStatistics.value || null)?.family?.toLowerCase() ?? betlineMatchStatistics.value?.family?.toLowerCase() ?? null);
    function setCurrentLoadedMatchIdentifier(response) {
        currentLoadedMatchIdentifier.value = response ? {
            id: response.id,
            status: response.status,
            betline: response.betline
        } : response;
    }
    function clearStatistic() {
        setCurrentLoadedMatchIdentifier(null);
        setBetlinePostMatchStatistics(null);
        setBetlineLeagueStandings(null);
        setBetlineMatchStatistics(null);
    }
    function syncBackgroundRequests() {
        syncPostMatchBackgroundRequests({
            silent: true
        });
        syncLeagueStandingsBackgroundRequests({
            silent: true
        });
    }
    function setBackgroundUpdateEnabled(value) {
        setPostMatchBackgroundUpdateEnabled(value);
        setLeagueStandingsBackgroundUpdateEnabled(value);
    }
    async function initialRequests() {
        if (!isSportEventStatisticEnabled.value) return;
        // just try to load all statistic because we have only id and don't know status yet
        await Promise.allSettled([
            initialPostMatchRequests(),
            fetchH2hMatchStatistics({
                silent: true
            }),
            initialLeagueStandingsRequests()
        ]);
    }
    function startLoadingPostMatchStatistics() {
        setIsBackgroundRequestPriorityIncreased(true);
        initialPostMatchRequests();
    }
    // on init
    onPostMatchInit();
    onLeagueStandingsInit();
    watch([
        matchId,
        matchBetline,
        matchStatus
    ], (param, param1)=>{
        let [toId, toBetline, toStatus] = param, [fromId, fromBetline, fromStatus] = param1;
        if (toId !== fromId) return;
        if (fromBetline === toBetline && fromStatus === toStatus) return;
        syncBackgroundRequests();
    });
    return {
        isSportEventStatisticEnabled,
        pageIdentifier,
        currentLoadedMatchIdentifier,
        expectedStatisticId,
        hasMatchStatistic,
        hasPostMatchStatistics,
        isPostMatchStatisticLoaded,
        sportlineEventStatisticUpdateInterval,
        betlinePostMatchStatistics,
        postMatchStatistics,
        betlineMatchStatistics,
        betlineLeagueStandings,
        confrontationBlocks,
        statisticSportFamily,
        setPageIdentifier,
        setCurrentLoadedMatchIdentifier,
        setBetlinePostMatchStatistics,
        setBetlineLeagueStandings,
        clearStatistic,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled,
        setIsBackgroundRequestPriorityIncreased,
        startLoadingPostMatchStatistics
    };
});
