import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "timer"
};
const _hoisted_2 = {
    key: 0,
    class: "timer__label"
};
const _hoisted_3 = {
    class: "timer__time-left"
};
import { useVTimer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VTimer',
    props: {
        text: {
            default: ''
        },
        seconds: {
            default: 0
        }
    },
    setup (__props) {
        const props = __props;
        const { timeLeft } = useVTimer(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _ctx.text ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1)) : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(timeLeft)), 1)
            ])), [
                [
                    _directive_auto_id,
                    'VTimer'
                ]
            ]);
        };
    }
});
