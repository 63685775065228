import { computed, onBeforeMount, onBeforeUnmount, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { G2SVRequiredExceptionCode, LoginType, PinCodeRequiredExceptionCode } from '@leon-hub/api-sdk';
import { isObject, isString } from '@leon-hub/guards';
import { localStorageManager } from '@leon-hub/local-storage';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { CasAuthPostMessageEvent, PostMessageBus } from '@leon-hub/postmessage-bus';
import { logger } from '@leon-hub/logging';
import GqlApiCaptchaRequiredError from '@leon-hub/api/src/client/graphql/errors/GqlApiCaptchaRequiredError';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import useLoginStore from 'web/src/modules/login/store/useLoginStore';
import { useI18n } from 'web/src/modules/i18n/composables';
import { isFormPhoneValue } from 'web/src/components/Form/guards';
import useSiteConfigStore from 'web/src/modules/core/store/useSiteConfigStore';
import { LoginFieldName } from 'web/src/modules/login/store/enums';
import useCasAuthStore from 'web/src/modules/cas/store/useCasAuthStore';
import { getLoginType } from 'web/src/modules/user/store/utils';
import { LOCAL_STORAGE_LOGIN_TAB_KEY, LoginApiErrorCode } from 'web/src/modules/login/pages/LoginRoutePage/types';
import useCaptchaResolver from 'web/src/modules/captcha/composables/useCaptchaResolver';
import { AnalyticsModalType, DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useDeviceFingerprints } from 'web/src/modules/identity/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useUserStore from 'web/src/modules/user/store/useUserStore';
import HintBlockKind from 'web/src/components/HintBlock/VHintBlock/enums/HintBlockKind';
import ButtonType from 'web/src/components/Button/VButton/enums/ButtonType';
import ButtonKind from 'web/src/components/Button/VButton/enums/ButtonKind';
import useRouterStore from 'web/src/modules/core/store/useRouterStore';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useErrorHandler } from 'web/src/modules/core/composables/errors';
const defaultFakePassword = '*******';
function getPhoneString(phone) {
    const { prefix, suffix } = phone;
    return `+${prefix ?? ''}${suffix ?? ''}`;
}
function checkPhoneMatch(phoneString, phone) {
    return phoneString === getPhoneString(phone);
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useLoginPage() {
    let windowRef = null;
    let postMessageBus = null;
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const router = useRouter();
    const analytics = useAnalytics();
    const { getDeviceFingerprints } = useDeviceFingerprints();
    const errorConverter = useErrorsConverter();
    const { setCaptchaToken, resolveCaptcha } = useCaptchaResolver();
    const { handleError } = useErrorHandler(router);
    const userStore = useUserStore();
    const userLoginStore = useLoginStore();
    const siteConfigStore = useSiteConfigStore();
    const casAuthStore = useCasAuthStore();
    const routerStore = useRouterStore();
    const pinCodeModule = usePinCodeStore();
    const loginType = toRef(userLoginStore, 'loginType');
    const phoneNumber = toRef(userLoginStore, 'phoneNumber');
    const isCasAuthEnabled = toRef(siteConfigStore, 'isCasAuthEnabled');
    const loginTypes = toRef(siteConfigStore, 'loginTypes');
    const usernames = toRef(casAuthStore, 'usernames');
    const isCookiesOk = toRef(casAuthStore, 'isCookiesOk');
    const defaultFieldLogin = toRef(userLoginStore, 'defaultFieldLogin');
    const submitButtonTitle = ref('');
    const loginForm = ref();
    const incorrectLoginMessage = ref(null);
    const isAutoLoginFilled = ref(false);
    const isFormReady = ref(false);
    const isSubmitPending = ref(false);
    function getLabelByLoginType(value) {
        switch(value){
            case LoginType.EMAIL:
                return $translate('WEB2_LOGIN_EMAIL').value;
            case LoginType.PHONE:
                return $translate('WEB2_LOGIN_PHONE').value;
            case LoginType.ACCOUNT:
                return $translate('WEB2_LOGIN_ACCOUNT').value;
            default:
                return '';
        }
    }
    const tabsProperties = computed(()=>({
            items: loginTypes.value?.map((param)=>{
                let { type } = param;
                return {
                    id: type,
                    label: getLabelByLoginType(type)
                };
            }) ?? [],
            activeId: loginType.value,
            type: TabsType.UPPERCASE
        }));
    const casUsername = computed(()=>usernames.value[0] || '');
    const fakePassword = computed(()=>casUsername.value && loginType.value === getLoginType(casUsername.value) ? defaultFakePassword : '');
    function saveFormData() {
        // need to save state of form when switch tabs
        const formOutput = loginForm.value?.getCurrentOutput();
        const login = formOutput?.formData?.login || '';
        if (isFormPhoneValue(login) || isString(login)) userLoginStore.setUiSchemaLoginDefault({
            activeTab: loginType.value,
            value: login
        });
    }
    const incorrectHintBlockProperties = computed(()=>({
            kind: HintBlockKind.ERROR,
            label: incorrectLoginMessage.value || void 0
        }));
    const submitButtonProperties = computed(()=>({
            type: ButtonType.SUBMIT,
            kind: ButtonKind.PRIMARY,
            isLoading: isSubmitPending.value,
            label: submitButtonTitle.value
        }));
    const isShowTabsLogin = computed(()=>tabsProperties.value.items?.length > 1);
    function onTabClick(clickedTab) {
        if (loginType.value !== clickedTab) {
            incorrectLoginMessage.value = null;
            saveFormData();
            userLoginStore.setLoginType(clickedTab);
            if (isCasAuthEnabled.value && casUsername.value && loginType.value === getLoginType(casUsername.value)) {
                userLoginStore.prefillLoginForm(loginType.value, casUsername.value, fakePassword.value);
                isAutoLoginFilled.value = Boolean(fakePassword.value);
            } else // eslint-disable-next-line sonarjs/no-collapsible-if
            if (!defaultFieldLogin.value[clickedTab]) onClear();
        }
    }
    function restoreSubmitButtonInitialState() {
        submitButtonTitle.value = $translate('JS_BONUSPROMO_LOGIN').value;
        isSubmitPending.value = false;
    }
    onBeforeUnmount(()=>{
        postMessageBus?.dispose();
        userLoginStore.resetUiSchemaLoginDefault();
        userLoginStore.setUiSchemaDefault({
            field: LoginFieldName.PASSWORD,
            value: ''
        });
        if (phoneNumber.value) userLoginStore.setPhoneNumber(null);
    });
    useBusSafeSubscribe(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, restoreSubmitButtonInitialState);
    useBusSafeSubscribe(BusEvent.CAPTCHA_SERVICE_ERROR, (data)=>{
        restoreSubmitButtonInitialState();
        handleError(data.error);
    });
    onBeforeMount(async ()=>{
        submitButtonTitle.value = $translate('JS_BONUSPROMO_LOGIN').value;
        const savedLoginType = localStorageManager.getItem(LOCAL_STORAGE_LOGIN_TAB_KEY);
        userLoginStore.setLoginType(loginTypes.value?.find((param)=>{
            let { type } = param;
            return type === savedLoginType;
        })?.type ?? loginTypes.value?.find((param)=>{
            let { isDefault } = param;
            return isDefault;
        })?.type ?? tabsProperties.value.items[0].id);
        if (isCasAuthEnabled.value && !casUsername.value) await casAuthStore.getSavedLogins();
        if (casUsername.value) {
            const casLoginType = getLoginType(casUsername.value);
            const isCasLoginTypeEnabled = loginTypes.value?.some((param)=>{
                let { type } = param;
                return type === casLoginType;
            });
            if (isCasLoginTypeEnabled) {
                userLoginStore.setLoginType(getLoginType(casUsername.value));
                userLoginStore.prefillLoginForm(getLoginType(casUsername.value), casUsername.value, fakePassword.value);
                isAutoLoginFilled.value = Boolean(fakePassword.value);
            }
        }
        isFormReady.value = true;
    });
    function checkCookiesHandler() {
        let checkboxValue = false;
        casAuthStore.getSavedLogins().then(()=>{
            if (isCookiesOk.value && postMessageBus) {
                postMessageBus.emit(CasAuthPostMessageEvent.cookieIsOk);
                checkboxValue = true;
            } else windowRef?.location.replace(`${casAuthStore.authUrl}&cookieDisabled=true`);
            userLoginStore.setUiSchemaDefault({
                field: LoginFieldName.CHECKBOX,
                value: checkboxValue
            });
        }).catch(()=>logger.warn('cannot fetch saved logins'));
    }
    async function saveCasLogin(loginOptions) {
        const casLoginInput = {
            username: loginOptions.username
        };
        if (!isAutoLoginFilled.value) casLoginInput.password = loginOptions.password;
        await casAuthStore.loginCas(casLoginInput);
    }
    function showPasswordSavingWarning() {
        const { subscribe, promise } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_MODALTITLE_ATTENTION').value,
                confirmMessage: $translate('JS_PASSWORD_SAVING_IS_DISABLED').value,
                buttons: [
                    {
                        label: $translate('JS_ENABLE').value
                    }
                ],
                analyticsModalType: AnalyticsModalType.WARNING,
                dataTestId: 'show-password-saving-warning'
            }
        });
        let confirm = false;
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                confirm = true;
            }
        });
        promise.then(()=>{
            if (confirm) {
                windowRef = window.open(casAuthStore.authUrl, 'CasAuth', 'menubar=no,location=no,resizable=yes,status=no');
                isObject(windowRef);
                postMessageBus = new PostMessageBus({
                    target: windowRef,
                    targetOrigin: '*',
                    skipSourceCheck: true,
                    initiator: 'LoginPage'
                });
                postMessageBus.on(CasAuthPostMessageEvent.checkCookie, checkCookiesHandler);
                postMessageBus.on(CasAuthPostMessageEvent.reloadPage, ()=>window.location.reload());
            } else userLoginStore.setUiSchemaDefault({
                field: LoginFieldName.CHECKBOX,
                value: false
            });
            return casAuthStore.authUrl;
        }).catch(()=>logger.warn('cannot close showPasswordSavingWarning'));
    }
    async function recheckCookies() {
        try {
            await casAuthStore.checkCookie();
        } catch (error) {
            logger.error('Can not setup casAuth', error);
        }
    }
    async function handleCaptcha(data, forcedCaptchaType) {
        const captchaType = forcedCaptchaType ?? userLoginStore.captchaType;
        // eslint-disable-next-line no-param-reassign
        data.formData[LoginFieldName.CAPTCHA_TOKEN] = await resolveCaptcha(captchaType);
        // eslint-disable-next-line no-param-reassign
        data.formData[LoginFieldName.CAPTCHA_TYPE] = captchaType;
        userLoginStore.setUiSchemaDefault({
            field: LoginFieldName.CAPTCHA_TYPE,
            value: captchaType
        });
    }
    function handleLoginError(error, loginOptions) {
        const isGoingToReset = !!pinCodeModule.resetToken;
        if (error.code.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)) {
            if (isGoingToReset) pinCodeModule.setStepWithLS('RESET_AFTER_LOGIN');
            else {
                userStore.setPinCodeData({
                    pinCode: '',
                    captchaAnswer: loginOptions.captchaAnswer,
                    captchaType: loginOptions.captchaType,
                    fingerprint: loginOptions.fingerprint,
                    fpjsVisitorId: loginOptions.fpjsVisitorId,
                    cordovaDeviceUUID: loginOptions.cordovaDeviceUUID,
                    appGUID: loginOptions.appGUID,
                    loginType: loginOptions.loginType
                });
                pinCodeModule.setStepWithLS('LOGIN');
            }
            router.replace({
                name: RouteName.PIN_CODE
            });
        } else if (error.code.equals(G2SVRequiredExceptionCode.G2SV_REQUIRED)) router.push({
            name: RouteName.TWO_FACTOR_AUTHENTICATION_LOGIN
        });
        else if (error.code.equals(LoginApiErrorCode.LOGIN_BY_EMAIL_FAILED) || error.code.equals(LoginApiErrorCode.LOGIN_BY_ACCOUNT_FAILED) || error.code.equals(LoginApiErrorCode.LOGIN_BY_PHONE_FAILED)) {
            incorrectLoginMessage.value = error.message;
            isAutoLoginFilled.value = false;
            saveFormData();
            userLoginStore.setUiSchemaDefault({
                field: LoginFieldName.PASSWORD,
                value: ''
            });
        } else showLoginErrorModal(error);
    }
    function showLoginErrorModal(error) {
        let title;
        title = error.code.equals(LoginApiErrorCode.FAILURE_INVALID_TOKEN) || error.code.equals(LoginApiErrorCode.FAILURE_TOKEN_AUTH_DISABLED) ? $translate('JAVA_SSO_INVALID_TOKEN_ERROR_TITLE').value : $translate('WEB2_MODALTITLE_ATTENTION').value;
        showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                title,
                confirmMessage: error.message,
                dataTestId: 'login-error'
            }
        }).promise.then(()=>{
            isAutoLoginFilled.value = false;
            saveFormData();
            userLoginStore.setUiSchemaDefault({
                field: LoginFieldName.PASSWORD,
                value: ''
            });
        }).catch(()=>logger.warn('cannot show showLoginErrorModal'));
    }
    async function onChange(data) {
        const login = data.formData[LoginFieldName.LOGIN];
        if (login) {
            if ('string' != typeof login) {
                userLoginStore.setUiSchemaLoginDefault({
                    activeTab: LoginType.PHONE,
                    value: login
                });
                const isPrefixChanged = Boolean(phoneNumber.value) && phoneNumber.value?.prefix !== login.prefix;
                userLoginStore.setPhoneNumber({
                    prefix: login.prefix,
                    suffix: login.suffix
                });
                if (data.field === LoginFieldName.LOGIN_PREFIX && isAutoLoginFilled.value && isPrefixChanged) {
                    userLoginStore.setUiSchemaDefault({
                        field: LoginFieldName.PASSWORD,
                        value: ''
                    });
                    isAutoLoginFilled.value = false;
                }
            }
            const errorsInInputs = data.errors?.has(LoginFieldName.LOGIN) ?? data.errors?.has(LoginFieldName.PASSWORD);
            if (data.field === LoginFieldName.LOGIN && !errorsInInputs && isCookiesOk.value && isAutoLoginFilled.value) {
                userLoginStore.setUiSchemaLoginDefault({
                    activeTab: loginType.value,
                    value: login
                });
                userLoginStore.setUiSchemaDefault({
                    field: LoginFieldName.PASSWORD,
                    value: ''
                });
                isAutoLoginFilled.value = false;
            }
            if (data.field === LoginFieldName.LOGIN && !errorsInInputs && 'string' == typeof login && isCookiesOk.value && !isAutoLoginFilled.value && usernames.value.includes(login) && isCasAuthEnabled.value) {
                userLoginStore.setUiSchemaLoginDefault({
                    activeTab: loginType.value,
                    value: login
                });
                userLoginStore.setUiSchemaDefault({
                    field: LoginFieldName.PASSWORD,
                    value: fakePassword.value
                });
                isAutoLoginFilled.value = true;
            }
            if (data.field === LoginFieldName.PASSWORD && !errorsInInputs && isCookiesOk.value && isAutoLoginFilled.value) isAutoLoginFilled.value = false;
            if (data.field !== LoginFieldName.CHECKBOX && !isCookiesOk.value) return false;
            if (data.field === LoginFieldName.CHECKBOX && data.formData.checkbox) {
                await casAuthStore.getSavedLogins();
                if (!isCookiesOk.value) {
                    "1";
                    await recheckCookies();
                    showPasswordSavingWarning();
                }
            }
        } else onClear();
        return true;
    }
    function onClear() {
        userLoginStore.setUiSchemaLoginDefault({
            activeTab: loginType.value,
            value: loginType.value === LoginType.PHONE ? {} : ''
        });
        userLoginStore.setUiSchemaDefault({
            field: LoginFieldName.PASSWORD,
            value: ''
        });
        isAutoLoginFilled.value = false;
    }
    function onInput(payload) {
        const { login, password } = payload.formData;
        if (isFormPhoneValue(login) && payload.field === LoginFieldName.LOGIN_SUFFIX && isAutoLoginFilled.value) {
            userLoginStore.setUiSchemaDefault({
                field: LoginFieldName.PASSWORD,
                value: ''
            });
            isAutoLoginFilled.value = false;
        }
        if (isString(login) && payload.field === LoginFieldName.LOGIN) {
            const isStartsWithSpace = /^\s/.test(login);
            const isEndsWithSpace = /\s$/.test(login);
            userLoginStore.setUiSchemaLoginDefault({
                activeTab: loginType.value,
                value: isStartsWithSpace || isEndsWithSpace ? login.trim() : login
            });
        }
        if (isString(password) && payload.field === LoginFieldName.PASSWORD) userLoginStore.setUiSchemaDefault({
            field: LoginFieldName.PASSWORD,
            value: password
        });
        const captchaToken = payload.formData[LoginFieldName.CAPTCHA_TOKEN];
        if (captchaToken) setCaptchaToken(captchaToken);
    }
    async function logIn(loginOptions, save) {
        if ((save || isAutoLoginFilled.value) && isCookiesOk.value) {
            const { password, ...options } = loginOptions;
            try {
                await saveCasLogin(loginOptions);
            } catch  {
                await userStore.auth(loginOptions);
                return;
            }
            await userStore.authCas({
                ...options,
                ssoLoginTicket: casAuthStore.ticket,
                savePassword: null
            });
        } else await userStore.auth(loginOptions);
    }
    async function onSignIn(data) {
        const { errors } = data;
        // TODO: JSON Scheme dynamic validation
        const hasErrors = errors && !(1 === errors.size && errors.get('captchaAnswer'));
        if (hasErrors) return;
        if (isCasAuthEnabled.value && !isCookiesOk.value) await recheckCookies();
        let { password } = data.formData;
        // eslint-disable-next-line sonarjs/no-collapsible-if
        isSubmitPending.value = true;
        const { deviceFingerprint, deviceFingerprintJs } = await getDeviceFingerprints();
        const { login, checkbox = false, captchaAnswer = null, captchaType = null, fingerprint = deviceFingerprint } = data.formData;
        let currentUsername;
        if ('string' != typeof login) {
            currentUsername = getPhoneString(login);
            if (casUsername.value && getLoginType(casUsername.value) === LoginType.PHONE && currentUsername !== casUsername.value && checkPhoneMatch(casUsername.value, login)) currentUsername = casUsername.value;
        } else currentUsername = `${login}`;
        submitButtonTitle.value = $translate('JSPACC_LOGIN_ENTER').value;
        const loginOptions = {
            username: currentUsername,
            password,
            captchaType,
            captchaAnswer,
            fingerprint,
            fpjsVisitorId: deviceFingerprintJs,
            loginType: loginType.value
        };
        try {
            await logIn(loginOptions, checkbox);
            isSubmitPending.value = false;
        } catch (rawError) {
            if (rawError instanceof GqlApiCaptchaRequiredError) {
                await handleCaptcha(data, rawError.extensions.captchaType);
                await onSignIn(data);
                return;
            }
            const error = errorConverter.convertToBaseError(rawError);
            const errorCode = error.code;
            if (checkbox && isCookiesOk.value && errorCode.equals(G2SVRequiredExceptionCode.G2SV_REQUIRED || errorCode.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED))) await saveCasLogin(loginOptions);
            if (checkbox && isCookiesOk.value && !errorCode.equals(G2SVRequiredExceptionCode.G2SV_REQUIRED) && !errorCode.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)) await casAuthStore.deleteSavedLogins({
                username: currentUsername
            });
            handleLoginError(error, loginOptions);
            analytics.push(AnalyticsEvent.Z_LOGGED_IN, {
                login: {
                    error: {
                        message: error.message,
                        code: `${errorCode}`
                    }
                }
            });
            restoreSubmitButtonInitialState();
        }
        if (!checkbox && isCookiesOk.value) await casAuthStore.deleteSavedLogins({
            username: currentUsername
        });
    }
    function onClickSingInButton() {
        loginForm.value?.submit();
    }
    function sendMetricOnRegistrationClick() {
        const actionName = 'pageLoginRegistration';
        const payload = {
            registration: actionName
        };
        const attemptedRoute = routerStore.getLoginRedirectRoute();
        if (attemptedRoute) {
            const attemptedRouteEntryName = router.resolve(attemptedRoute)?.name;
            if (attemptedRouteEntryName) {
                payload.registration = {
                    [actionName]: attemptedRouteEntryName
                };
                analytics.clickMap(payload);
            }
        }
    }
    function onRegistrationButtonClick() {
        sendMetricOnRegistrationClick();
        router.push({
            name: RouteName.REGISTRATION
        });
    }
    return {
        loginForm,
        tabsProperties,
        onTabClick,
        isSubmitPending,
        isFormReady,
        schema: toRef(userLoginStore, 'schema'),
        uiSchema: toRef(userLoginStore, 'uiSchema'),
        isCaptchaEnabled: toRef(userLoginStore, 'isCaptchaEnabled'),
        onChange,
        onInput,
        onSignIn,
        incorrectLoginMessage,
        incorrectHintBlockProperties,
        submitButtonProperties,
        onClickSingInButton,
        onRegistrationButtonClick,
        loginType,
        isShowTabsLogin
    };
}
