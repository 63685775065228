import { computed } from 'vue';
import { isOptionalString, isNumber } from '@leon-hub/guards';
import { getOptionsRelatedToSiblingFields, getWrapperHintProperties } from '../../../utils';
export default function useFormCurrencySelect(props) {
    const componentProps = computed(()=>{
        const { name, value, options, title, error, disabled, hintMessage, extraProperties, autofocus } = props;
        isOptionalString(value);
        const computedOptions = options ?? {};
        const { relatedCountryFieldId, ...restOptions } = computedOptions;
        let combinedOptions = {
            ...restOptions
        };
        combinedOptions = getOptionsRelatedToSiblingFields('countryCode', relatedCountryFieldId, extraProperties?.formData, combinedOptions);
        return {
            name,
            selectedValue: value,
            label: title,
            disabled,
            ...combinedOptions,
            loaderDelay: options?.loaderDelay && !isNumber(options.loaderDelay) ? parseInt(options.loaderDelay, 10) : void 0,
            ...getWrapperHintProperties({
                options,
                error,
                hintMessage
            }),
            autofocus
        };
    });
    return {
        componentProps
    };
}
