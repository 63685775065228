import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, mergeProps as _mergeProps } from "vue";
import { inject, useSlots } from 'vue';
import { VIcon } from '@components/v-icon';
import { FormProvidable } from 'web/src/components/Form/enums';
import InputButton from '../InputButton/InputButton.vue';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import useTextInput from './useTextInput';
import { TextInputTypes } from '../../enums';
import { useFocusableElement } from '../../composables';
import getInputIconSize from '../../utils/getInputIconSize';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'TextInput',
    props: {
        type: {
            default: TextInputTypes.TEXT
        },
        mask: {},
        list: {
            default: ''
        },
        isYmDisableKeys: {
            type: Boolean
        },
        clickableIcon: {
            type: Boolean
        },
        isHintHidden: {
            type: Boolean
        },
        isLabelHidden: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'text-input'
        },
        value: {
            default: ''
        },
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        autocomplete: {},
        placeholder: {},
        prefixIconName: {},
        suffixIconName: {},
        maxSymbols: {},
        regexp: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur",
        "clear",
        "icon-prefix-click",
        "icon-suffix-click",
        "filled",
        "keydown"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const slots = useSlots();
        const customFormContext = inject(FormProvidable.FormContext, {
            isFromModal: false
        });
        const { uniqueId, hintProperties, hasError, isFocus, isEmpty, isHover, hasIconSuffix, inputType, inputMode, isMaskedPlaceholderHidden, showClearButton, inputValue, maskedValue, formattedValue, inputMaxLength, clickablePrefixIcon, clickableSuffixIcon, onMouseOver, onMouseLeave, onFocus, onBlur, onClear, emitIconPrefixClick, emitIconSuffixClick, onKeyDown, onPaste, onInput, onChange } = useTextInput(props, emits, slots);
        const { focus, focusable } = useFocusableElement();
        // ok not to be reactive
        const iconSize = getInputIconSize(props.isLarge);
        __expose({
            focus
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), _mergeProps(_unref(hintProperties), {
                "input-id": _unref(uniqueId),
                label: _ctx.label,
                disabled: _ctx.disabled,
                "is-focus": _unref(isFocus),
                "is-empty": _unref(isEmpty),
                "is-hint-hidden": _ctx.isHintHidden,
                "is-label-hidden": _ctx.isLabelHidden,
                "is-large": _ctx.isLarge,
                "clickable-prefix": _unref(clickablePrefixIcon),
                "clickable-suffix": _unref(clickableSuffixIcon)
            }), {
                iconPrefix: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "iconPrefix", {}, ()=>[
                                _ctx.prefixIconName ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _ctx.clickableIcon ? (_openBlock(), _createBlock(InputButton, {
                                        key: 0,
                                        "is-large": _ctx.isLarge,
                                        "icon-name": _ctx.prefixIconName,
                                        onClick: _unref(emitIconPrefixClick)
                                    }, null, 8, [
                                        "is-large",
                                        "icon-name",
                                        "onClick"
                                    ])) : (_openBlock(), _createBlock(_unref(VIcon), {
                                        key: 1,
                                        size: _unref(iconSize),
                                        name: _ctx.prefixIconName
                                    }, null, 8, [
                                        "size",
                                        "name"
                                    ]))
                                ], 64)) : _createCommentVNode("", true)
                            ])
                    ]),
                input: _withCtx(()=>[
                        _createVNode(_unref(InputBorderWrapper), {
                            "is-focus": _unref(isFocus),
                            "is-disabled": _ctx.disabled,
                            "is-hover": _unref(isHover),
                            "has-error": _unref(hasError),
                            "is-from-modal": _unref(customFormContext).isFromModal
                        }, {
                            default: _withCtx(()=>[
                                    _createVNode(_unref(TextFlatInput), {
                                        ref_key: "focusable",
                                        ref: focusable,
                                        autofocus: _ctx.autofocus,
                                        name: _ctx.name,
                                        "input-id": _unref(uniqueId),
                                        type: _unref(inputType),
                                        "input-mode": _unref(inputMode),
                                        value: _unref(formattedValue),
                                        "has-error": _unref(hasError),
                                        disabled: _ctx.disabled,
                                        "has-vanilla-mask": !!_ctx.mask,
                                        autocomplete: _ctx.autocomplete,
                                        placeholder: _ctx.placeholder,
                                        "is-from-modal": _unref(customFormContext).isFromModal,
                                        "is-ym-disable-keys": _ctx.isYmDisableKeys,
                                        list: _ctx.list,
                                        "max-length": _unref(inputMaxLength),
                                        "has-icon-prefix": !!_ctx.prefixIconName || !!_ctx.$slots.iconPrefix,
                                        "has-icon-suffix": _unref(hasIconSuffix) || !!_ctx.$slots.iconSuffix,
                                        readonly: _ctx.readonly,
                                        "is-large": _ctx.isLarge,
                                        onMouseover: _unref(onMouseOver),
                                        onMouseleave: _unref(onMouseLeave),
                                        onFocus: _unref(onFocus),
                                        onBlur: _unref(onBlur),
                                        onKeydown: _unref(onKeyDown),
                                        onChange: _unref(onChange),
                                        onInput: _unref(onInput),
                                        onPaste: _unref(onPaste)
                                    }, null, 8, [
                                        "autofocus",
                                        "name",
                                        "input-id",
                                        "type",
                                        "input-mode",
                                        "value",
                                        "has-error",
                                        "disabled",
                                        "has-vanilla-mask",
                                        "autocomplete",
                                        "placeholder",
                                        "is-from-modal",
                                        "is-ym-disable-keys",
                                        "list",
                                        "max-length",
                                        "has-icon-prefix",
                                        "has-icon-suffix",
                                        "readonly",
                                        "is-large",
                                        "onMouseover",
                                        "onMouseleave",
                                        "onFocus",
                                        "onBlur",
                                        "onKeydown",
                                        "onChange",
                                        "onInput",
                                        "onPaste"
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "is-focus",
                            "is-disabled",
                            "is-hover",
                            "has-error",
                            "is-from-modal"
                        ]),
                        _ctx.mask ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass({
                                [_ctx.$style['text-input__masked-placeholder']]: true,
                                [_ctx.$style['text-input__masked-placeholder--hidden']]: _unref(isMaskedPlaceholderHidden),
                                [_ctx.$style['text-input__masked-placeholder--icon-suffix']]: _unref(hasIconSuffix),
                                [_ctx.$style['text-input__masked-placeholder--icon-prefix']]: !!_ctx.prefixIconName,
                                [_ctx.$style['text-input__masked-placeholder--large']]: _ctx.isLarge
                            })
                        }, _toDisplayString(_unref(maskedValue)), 3)) : _createCommentVNode("", true)
                    ]),
                iconSuffix: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "iconSuffix", {
                            isFocus: _unref(isFocus),
                            isEmpty: _unref(isEmpty)
                        }, ()=>[
                                _withDirectives(_createVNode(InputButton, {
                                    "is-large": _ctx.isLarge,
                                    title: _ctx.$t('WEB2_CLEAR'),
                                    onClick: _unref(onClear)
                                }, null, 8, [
                                    "is-large",
                                    "title",
                                    "onClick"
                                ]), [
                                    [
                                        _vShow,
                                        _unref(showClearButton)
                                    ],
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'clear-icon-input-button',
                                            visible: !_unref(showClearButton)
                                        }
                                    ]
                                ]),
                                !_ctx.suffixIconName || _unref(showClearButton) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _ctx.clickableIcon ? (_openBlock(), _createBlock(InputButton, {
                                        key: 0,
                                        "is-large": _ctx.isLarge,
                                        "icon-name": _ctx.suffixIconName,
                                        onClick: _unref(emitIconSuffixClick)
                                    }, null, 8, [
                                        "is-large",
                                        "icon-name",
                                        "onClick"
                                    ])) : (_openBlock(), _createBlock(_unref(VIcon), {
                                        key: 1,
                                        size: _unref(iconSize),
                                        name: _ctx.suffixIconName
                                    }, null, 8, [
                                        "size",
                                        "name"
                                    ]))
                                ], 64))
                            ])
                    ]),
                hint: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "hint", {
                            isFocus: _unref(isFocus),
                            value: _unref(inputValue)
                        })
                    ]),
                dropdown: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "dropdown", {
                            isFocus: _unref(isFocus),
                            value: _unref(inputValue)
                        })
                    ]),
                _: 3
            }, 16, [
                "input-id",
                "label",
                "disabled",
                "is-focus",
                "is-empty",
                "is-hint-hidden",
                "is-label-hidden",
                "is-large",
                "clickable-prefix",
                "clickable-suffix"
            ])), [
                [
                    _directive_auto_id,
                    'TextInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'text-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
