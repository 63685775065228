import { computed } from 'vue';
import { isOptionalString, isString } from '@leon-hub/guards';
import { safeParseInt } from '@leon-hub/utils';
import isSmsCodeInputProps from 'web/src/components/Input/guards/isSmsCodeInputProps';
import getErrorFields from '../../utils/getErrorFields';
import getHintLinkFields from '../../utils/getHintLinkFields';
export default function useFormSmsCode(props) {
    const componentProps = computed(()=>{
        const { name, hintMessage, value, options, error, disabled, autofocus } = props;
        if (options?.size) options.size = isString(options?.size) ? safeParseInt(options.size) ?? 6 : options.size;
        isOptionalString(value);
        isSmsCodeInputProps(options);
        return {
            name,
            value,
            disabled,
            ...options,
            ...getErrorFields(options, error),
            ...getHintLinkFields(options),
            hintRight: hintMessage,
            autofocus
        };
    });
    return {
        componentProps
    };
}
