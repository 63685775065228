import { isNumber, isString, isBoolean, isUndefined, bindIsArrayOf, isValidInterface } from '@leon-hub/guards';
import { isSportEventMatchPhase } from '../core';
import { isBetlineType } from './isBetlineType';
import { isCoreLeagueResponse } from './isCoreLeagueResponse';
import { isLiveStatusResponse } from './isLiveStatusResponse';
import { isCoreMarketResponse } from './isCoreMarketResponse';
import { isBetlineSportEventStatus } from './isBetlineSportEventStatus';
import { isBetlineSportWidgetType } from './isBetlineSportWidgetType';
import { isCoreLeagueReferenceResponse } from './isCoreLeagueReferenceResponse';
import { isCoreCompetitorResponse } from './isCoreCompetitorResponse';
const isCoreSportEventResponseFieldGuards = {
    id: isNumber,
    name: isString,
    nameDefault: isString,
    competitors: bindIsArrayOf(isCoreCompetitorResponse),
    kickoff: isNumber,
    lastUpdated: isNumber,
    league: [
        isCoreLeagueReferenceResponse,
        isCoreLeagueResponse
    ],
    betline: isBetlineType,
    url: isString,
    open: [
        isUndefined,
        isBoolean
    ],
    status: [
        isUndefined,
        isBetlineSportEventStatus
    ],
    matchPhase: [
        isUndefined,
        isSportEventMatchPhase
    ],
    marketsCount: [
        isUndefined,
        isNumber
    ],
    runnersCount: [
        isUndefined,
        isNumber
    ],
    liveStatus: [
        isUndefined,
        isLiveStatusResponse
    ],
    markets: [
        isUndefined,
        bindIsArrayOf(isCoreMarketResponse)
    ],
    widgetId: [
        isUndefined,
        isString
    ],
    widgetType: [
        isUndefined,
        isBetlineSportWidgetType
    ],
    widgetVirtual: [
        isUndefined,
        isBoolean
    ],
    hasMarketWithZeroMargin: [
        isUndefined,
        isBoolean
    ]
};
export function isCoreSportEventResponse(value) {
    let silent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    return isValidInterface(value, isCoreSportEventResponseFieldGuards, {
        silent
    });
}
