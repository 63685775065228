import { Locale, isLocale } from '@leon-hub/locale';
let LanguageMicroSettings = class LanguageMicroSettings {
    static plural(words, number, locale) {
        if (isLocale(locale, Locale.RU_RU) || locale.includes('ru')) return getRussianPluralForm(words, number);
        if (isLocale(locale, Locale.EN_US) || isLocale(locale, Locale.EN_IN) || isLocale(locale, Locale.EN_GB)) return getEnglishPluralForm(words, number);
        return getDefaultPluralForm(words, number);
    }
};
export { LanguageMicroSettings as default };
function getRussianPluralForm(words, number) {
    let n = Math.abs(number) % 100;
    if (n >= 5 && n <= 20) return words[2];
    n %= 10;
    if (1 === n) return words[0];
    if (n >= 2 && n <= 4) return words[1];
    return words[2];
}
function getEnglishPluralForm(words, number) {
    if (4 === words.length) {
        let n = Math.abs(number) % 100;
        if (n >= 11 && n <= 13) return words[3];
        n = number % 10;
        if (1 === n) return words[0];
        if (2 === n) return words[1];
        if (3 === n) return words[2];
        return words[3];
    }
    return 1 === number ? words[0] : words[1];
}
function getDefaultPluralForm(words, number) {
    if (1 === number) return words[0];
    return words[1];
}
