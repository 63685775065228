import { isString, isUndefined, isValidInterface } from '@leon-hub/guards';
export default function isSportEventsPageIdentifier(value) {
    return isValidInterface(value, {
        sportFamily: [
            isString
        ],
        regionId: [
            isUndefined,
            isString
        ],
        regionUrlName: [
            isUndefined,
            isString
        ],
        leagueId: [
            isUndefined,
            isString
        ],
        leagueUrlName: [
            isUndefined,
            isString
        ],
        sportEventId: [
            isUndefined,
            isString
        ],
        sportEventUrlName: [
            isUndefined,
            isString
        ]
    });
}
