import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VReferralProgramStep from 'web/src/modules/referral-program/components/ReferralProgramStep/VReferralProgramStep/VReferralProgramStep.vue';
import SReferralProgramStep from 'web/src/modules/referral-program/components/ReferralProgramStep/SReferralProgramStep/SReferralProgramStep.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramPopupListSteps',
    props: {
        title: {},
        satisfied: {
            type: Boolean
        },
        list: {},
        green: {
            type: Boolean
        },
        isRounded: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['referral-program-list-steps']]: true,
                    [_ctx.$style['referral-program-list-steps--green']]: _ctx.green
                })
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createElementVNode("p", {
                        class: _normalizeClass(_ctx.$style['referral-program-list-steps__title'])
                    }, _toDisplayString(_ctx.title), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-list-steps__list'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item)=>(_openBlock(), _createBlock(VReferralProgramStep, {
                                key: item.label,
                                class: _normalizeClass(_ctx.$style['referral-program-list-steps__item']),
                                satisfied: _ctx.satisfied,
                                "is-rounded": _ctx.isRounded,
                                label: item.label,
                                to: item.to,
                                green: _ctx.green
                            }, null, 8, [
                                "class",
                                "satisfied",
                                "is-rounded",
                                "label",
                                "to",
                                "green"
                            ]))), 128))
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramPopupListSteps'
                ]
            ]);
        };
    }
});
