import StopIteration from 'web/src/modules/errors/errors/StopIteration';
let HighLighter = class HighLighter {
    static highlight(inputString, searchText) {
        let className = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 'highlighted-text', exploded = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
        if (!searchText) return inputString;
        const html = document.createElement('div');
        html.innerHTML = inputString;
        HighLighter.highlightInDom(html, searchText, className, exploded);
        return html.innerHTML;
    }
    static highlightInDom(element, searchText) {
        let className = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 'highlighted-text', exploded = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
        if (element.hasChildNodes()) for (const node of element.childNodes)if (node.nodeType === Node.TEXT_NODE) {
            const mark = document.createElement('span');
            mark.innerHTML = node.nodeValue ? // eslint-disable-next-line max-len
            node.nodeValue.replace(new RegExp(`(${HighLighter.getSearchRegexp(searchText, exploded)})`, 'igm'), `<span class="${className}">$1</span>`) : '';
            if (mark.innerHTML !== node.nodeValue) node.replaceWith(mark);
        } else HighLighter.highlightInDom(node, searchText, className);
    }
    static isHighlighted(inputString, searchText) {
        let exploded = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
        if (!searchText) return true;
        const html = document.createElement('div');
        html.innerHTML = inputString;
        return HighLighter.isHighlighedInDom(html, searchText, exploded);
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    static isHighlighedInDom(element, searchText) {
        let exploded = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
        let highlighted = false;
        if (element.hasChildNodes()) try {
            for (const node of element.childNodes){
                if (node.nodeType === Node.TEXT_NODE) highlighted = !!node.nodeValue && new RegExp(`(${HighLighter.getSearchRegexp(searchText, exploded)})`, 'igm').test(node.nodeValue);
                else highlighted = HighLighter.isHighlighedInDom(node, searchText);
                if (highlighted) throw new StopIteration();
            }
        } catch  {}
        //
        return highlighted;
    }
    static getSearchRegexp(searchText) {
        let exploded = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        const escapedSearchText = searchText.replace(/[$()*+./?[\\\]^{|}-]/g, '\\$&');
        return exploded ? escapedSearchText.split(' ').join('|') : escapedSearchText;
    }
};
export { HighLighter as default };
