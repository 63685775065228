import { ref, computed } from 'vue';
import { getBetlineMatchEventStatistics } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';
import { isBetlineSportEventMatchStatisticsEmpty } from 'web/src/modules/sportline/submodules/event-statistic/utils';
export default function useSportlineMatchStatisticsStoreComposable(props) {
    const betlineMatchStatistics = ref(null);
    const hasMatchStatistic = computed(()=>{
        const statisticId = betlineMatchStatistics.value?.id;
        return !!statisticId && statisticId === String(props.expectedStatisticId.value);
    });
    function setBetlineMatchStatistics(statistic) {
        betlineMatchStatistics.value = isBetlineSportEventMatchStatisticsEmpty(statistic) ? null : statistic;
    }
    async function fetchH2hMatchStatistics(param) {
        let { silent } = param;
        try {
            const eventId = props.expectedStatisticId.value;
            if (!eventId) return;
            setBetlineMatchStatistics(await getBetlineMatchEventStatistics({
                eventId
            }, {
                silent
            }));
        } catch (error) {
            if (!isStatisticNotFoundError(error)) throw error;
            // we tried but got empty statistic for this ID
            setBetlineMatchStatistics(null);
        }
    }
    return {
        betlineMatchStatistics,
        hasMatchStatistic,
        setBetlineMatchStatistics,
        fetchH2hMatchStatistics
    };
}
