import { nextTick, ref, watch } from 'vue';
import { onComponentActivated, useIntersectionObserver } from '@leon-hub/vue-utils';
export default function useSwiperToActiveScroll(activeIndex, swiperRef) {
    const swiper = swiperRef ?? ref();
    watch(activeIndex, ()=>{
        slideToActive(true);
    });
    function scrollToSlide() {
        let index = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0, smooth = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        if (index < 0) return;
        swiper.value?.slideToSlide(index, {
            isCentered: true,
            smooth,
            speed: 300
        });
    }
    async function slideToActive() {
        let smooth = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        await nextTick();
        scrollToSlide(activeIndex.value, smooth);
    }
    onComponentActivated(slideToActive);
    useIntersectionObserver(swiper, ()=>{
        slideToActive();
    }, void 0, true);
    return {
        swiper,
        slideToActive,
        scrollToSlide
    };
}
