import { computed } from 'vue';
import getIconName from 'web/src/components/Swiper/Navigation/SwiperNavigationButton/composables/utils/getIconName';
import { getClassName, getIconSize } from 'web/src/components/Swiper/Navigation/SwiperNavigationButton/composables/utils';
export default function useSwiperNavigationButton(props) {
    const iconName = computed(()=>getIconName(props));
    const iconSize = computed(()=>getIconSize(props));
    const className = computed(()=>getClassName(props));
    return {
        iconName,
        iconSize,
        className
    };
}
