import { defineStore } from 'pinia';
import { ref } from 'vue';
export const useKeepAliveStore = defineStore('keep-alive', ()=>{
    const keepAliveComponents = ref([]);
    function addKeepAliveComponent(componentName) {
        if (!keepAliveComponents.value.includes(componentName)) keepAliveComponents.value.push(componentName);
    }
    function removeKeepAliveComponent(componentName) {
        keepAliveComponents.value = keepAliveComponents.value.filter((c)=>c !== componentName);
    }
    return {
        keepAliveComponents,
        addKeepAliveComponent,
        removeKeepAliveComponent
    };
});
