import { isNumber, isBoolean, isUndefined, bindIsArrayOf, isValidInterface } from '@leon-hub/guards';
import { isCoreSportMarketGroup } from './isCoreSportMarketGroup';
import { isCoreSportMarketColumnResponse } from './isCoreSportMarketColumnResponse';
import { isMinimalWorkingSportResponseFieldGuards } from './isMinimalWorkingSportResponse';
export const isCoreSportResponseFieldGuards = {
    id: isMinimalWorkingSportResponseFieldGuards.id,
    name: isMinimalWorkingSportResponseFieldGuards.name,
    weight: isNumber,
    family: isMinimalWorkingSportResponseFieldGuards.family,
    mainMarkets: [
        isUndefined,
        bindIsArrayOf(isCoreSportMarketColumnResponse)
    ],
    marketGroups: [
        isUndefined,
        bindIsArrayOf(isCoreSportMarketGroup)
    ],
    virtual: [
        isUndefined,
        isBoolean
    ]
};
export function isCoreSportResponse(value) {
    return isValidInterface(value, isCoreSportResponseFieldGuards);
}
