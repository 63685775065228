import { initializeApp } from 'firebase/app';
import { getMessaging, getToken as getTokenFirebase, isSupported as isFirebaseSupported, onMessage } from 'firebase/messaging';
let isInitialized = false;
let vapidKey = '';
let messaging = null;
let swRegistration;
export default function useFirebaseMessaging() {
    async function isSupported() {
        if (!window || !navigator || !navigator.serviceWorker || !window.Notification || 'denied' === window.Notification.permission) return false;
        try {
            return await isFirebaseSupported();
        } catch  {
            return false;
        }
    }
    async function init(settings) {
        if (isInitialized || !settings.apiKey || !settings.projectId || !settings.senderId || !settings.appId || !settings.measurementId || !settings.vapidKey) return;
        if (!await isSupported()) return;
        try {
            const app = initializeApp({
                apiKey: settings.apiKey,
                authDomain: `${settings.projectId}.firebaseapp.com`,
                databaseURL: `https://${settings.projectId}.firebaseio.com`,
                projectId: settings.projectId,
                storageBucket: `${settings.projectId}.appspot.com`,
                messagingSenderId: settings.senderId,
                appId: settings.appId,
                measurementId: settings.measurementId
            });
            vapidKey = settings.vapidKey;
            swRegistration = await navigator.serviceWorker.register(// eslint-disable-next-line max-len
            `/scripts/firebase/sw.firebase.js?apiKey=${settings.apiKey}&projectId=${settings.projectId}&senderId=${settings.senderId}&appId=${settings.appId}&measurementId=${settings.measurementId}`);
            messaging = getMessaging(app);
            onMessage(messaging, (payload)=>{
                if (payload.notification) swRegistration?.showNotification(payload.notification.title || '', {
                    ...payload.notification,
                    data: payload.data,
                    icon: payload.data?.icon
                });
            });
            isInitialized = true;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('[Firebase] Unable to initialize app', error);
        }
    }
    function checkInitialized() {
        if (!isInitialized) throw new Error('Please initialize Firebase api first');
    }
    async function getToken() {
        checkInitialized();
        await Notification.requestPermission();
        return getTokenFirebase(messaging, {
            vapidKey,
            serviceWorkerRegistration: swRegistration
        });
    }
    return {
        init,
        isSupported,
        getToken
    };
}
