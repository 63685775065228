import { computed } from 'vue';
import isFileSingleInputProps from 'web/src/components/Input/guards/isFileSingleInputProps';
import getWrapperHintProperties from '../../utils/getWrapperHintProperties';
export default function useFormFileSingleInput(props) {
    const componentProps = computed(()=>{
        const { name, options, error, title, hintMessage, disabled, autofocus } = props;
        const computedOptions = options ?? {};
        isFileSingleInputProps(computedOptions);
        return {
            label: title,
            disabled,
            ...options ? computedOptions : {},
            ...getWrapperHintProperties({
                options: computedOptions,
                error,
                hintMessage
            }),
            autofocus,
            name
        };
    });
    return {
        componentProps
    };
}
