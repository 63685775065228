import { computed } from 'vue';
export function useOptimisticFavoritesEvents(props) {
    const { preparedToAdd, preparedToRemove, pendingToFavoritesList, favoritesIdsList } = props;
    const optimisticFavoritesSportEventsIdsList = computed(()=>{
        const toRemove = preparedToRemove.value;
        return [
            ...favoritesIdsList.value || [],
            ...preparedToAdd.value,
            ...pendingToFavoritesList.value
        ].filter((id)=>!toRemove.includes(id));
    });
    return {
        optimisticFavoritesSportEventsIdsList
    };
}
