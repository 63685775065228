import { getCounters } from 'web/src/modules/sportline/utils/api';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
export default function useSportlineApiLoadCounters(props) {
    const { tryRequestWithCTag, isZeroMarginEnabled, defaultRestApiFlags, isLiveOutrightsEnabled } = props;
    let loadCountersPending = null;
    function loadCounters(options) {
        if (loadCountersPending) return loadCountersPending;
        const { silent } = options;
        loadCountersPending = new Promise((resolve, reject)=>{
            tryRequestWithCTag((ctag)=>getCounters({
                    betline: BetlineType.All,
                    ctag,
                    silent,
                    hideClosed: true,
                    zeroMarginMarkets: isZeroMarginEnabled.value,
                    flags: [
                        ...defaultRestApiFlags.value
                    ],
                    LMCE: isLiveOutrightsEnabled.value
                })).finally(()=>{
                loadCountersPending = null;
            }).then(resolve).catch(reject);
        });
        return loadCountersPending;
    }
    return {
        loadCounters
    };
}
