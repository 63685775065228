function extend(to, from) {
    for (const key of Object.keys(from))// eslint-disable-next-line no-param-reassign
    to[key] = from[key];
    return to;
}
export default function defaultMergeStrategy(parentVal, childVal) {
    if (!parentVal) return childVal;
    const ret = Object.create(null);
    extend(ret, parentVal);
    if (childVal) extend(ret, childVal);
    return ret;
}
