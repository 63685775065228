/* eslint-disable no-underscore-dangle */ import { isNumber, isObject } from '@leon-hub/guards';
import { ApiIpBlockedError, ApiServiceUnavailableError, GqlApiAccessDeniedError, GqlApiCustomerHistoryLimitExceededError, GqlApiPreviousRequestHasNotExpired } from '@leon-hub/api';
import { AbstractError, LogLevel } from '@leon-hub/errors';
import { AppIgnoredError } from '@leon-hub/app-errors';
var NavigationFailureType;
var NavigationFailureType1;
(NavigationFailureType1 = NavigationFailureType || (NavigationFailureType = {}))[NavigationFailureType1["redirected"] = 2] = "redirected", NavigationFailureType1[NavigationFailureType1["aborted"] = 4] = "aborted", NavigationFailureType1[NavigationFailureType1["cancelled"] = 8] = "cancelled", NavigationFailureType1[NavigationFailureType1["duplicated"] = 16] = "duplicated";
export function isCommonWhitelistedError(error) {
    return !!(isObject(error) && (error instanceof AbstractError && (error.logLevel === LogLevel.DEBUG || error.silent || error instanceof ApiServiceUnavailableError || error instanceof GqlApiAccessDeniedError || error instanceof GqlApiPreviousRequestHasNotExpired || error instanceof GqlApiCustomerHistoryLimitExceededError || error instanceof ApiIpBlockedError || error instanceof AppIgnoredError) || error instanceof Error && (error.message.includes('This browser doesn\'t support the API\'s required to use the Firebase') || error.message.includes('[webpack-dev-server]') || !!(error.stack && error.stack.includes('googletagmanager'))) || error._isRouter && isNumber(error.type) && [
        2,
        16,
        8
    ].includes(error.type) || error._isWhiteListed));
}
