/* eslint-disable class-methods-use-this */ import { AbstractPageSeoController } from 'web/src/modules/core/services/router/seo';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';
let DefaultPageSeoController = class DefaultPageSeoController extends AbstractPageSeoController {
    getSeoConfig(router, from, to) {
        return Promise.resolve(to);
    }
    loadSeoMetaDataFor(route) {
        useSeoMetaStorage().updateSpintaxMetaData(route);
    }
};
export { DefaultPageSeoController as default };
