import { ref, watch, computed, nextTick } from 'vue';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { groupLeagueStandings, getPromotionsFromTournament, filterGroupsInTournamentByTeams, filterGroupsInTournamentByFirstAndLastPosition } from 'web/src/modules/sportline/submodules/core-statistic/utils';
export default function useStatisticLeagueStandings(props) {
    const bus = useEventsBus();
    const { leagueStandings, teams, alwaysExpanded } = props;
    const standings = ref();
    const isExpanded = ref(!!process.env.VUE_APP_PRERENDER);
    const isDisplayFullTable = computed(()=>alwaysExpanded.value || isExpanded.value);
    const teamsIds = computed(()=>{
        if (!teams.value || teams.value.length < 2) return new Set([]);
        const hostId = teams.value[0].id;
        const guestId = teams.value[1].id;
        return new Set([
            hostId,
            guestId
        ].filter((id)=>!!id));
    });
    const tournament = computed(()=>{
        const tournamentRaw = groupLeagueStandings(leagueStandings.value);
        if (isDisplayFullTable.value) return tournamentRaw;
        if (teamsIds.value.size < 2) return filterGroupsInTournamentByFirstAndLastPosition(tournamentRaw);
        return filterGroupsInTournamentByTeams(tournamentRaw, teamsIds.value);
    });
    const promotions = computed(()=>{
        const promotionsMap = getPromotionsFromTournament(tournament.value);
        return [
            ...promotionsMap.values()
        ];
    });
    if (!process.env.VUE_APP_PRERENDER) {
        watch(alwaysExpanded, (newValue)=>{
            isExpanded.value = newValue;
        }, {
            immediate: true
        });
        watch(isExpanded, async (newValue)=>{
            if (newValue) return;
            await nextTick();
            const top = standings.value?.offsetTop;
            const headerHeight = 60;
            // beside value
            if (void 0 === top) return;
            bus.emit(BusEvent.LAYOUT_CONTENT_SET_SCROLL, {
                scrollTop: top - headerHeight,
                smooth: true
            });
        });
    }
    return {
        isExpanded,
        isDisplayFullTable,
        promotions,
        teamsIds,
        tournament
    };
}
