const baseClass = 'button__icon';
export default function getIconClassNamesForButton(props, hasDefaultSlot) {
    const classNames = [
        baseClass
    ];
    if (props.isLoading) classNames.push(`${baseClass}--loader`);
    if ((props.label || hasDefaultSlot) && !props.iconRight) classNames.push(`${baseClass}--left`);
    if (props.iconRight) classNames.push(`${baseClass}--right`);
    return classNames;
}
