import { computed, nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DEPOSIT_BONUS_DEFAULT_VALUE } from 'web/src/modules/payments/constants';
export default function useDepositBonusSelector(props, emit) {
    const bonusStore = useBonusStore();
    const { $translate } = useI18n();
    const router = useRouter();
    const openedBonusDetails = ref(null);
    const currentSelectedBonus = computed(()=>props.depositBonusList.find((item)=>item.campaignId === props.selectedBonus));
    const bonusTitle = computed(()=>{
        if (currentSelectedBonus.value) return currentSelectedBonus.value.campaignName || '';
        if (props.isNoneSelected) return $translate('WEB2_WITHOUT_BONUS').value;
        return $translate('WEB2_FORM_BONUS_SELECT').value;
    });
    const selectorListItemProperties = computed(()=>({
            title: bonusTitle.value,
            iconName: props.isNoneSelected ? IconName.PROMOS_NO : IconName.PROMOS,
            iconSuffixName: IconName.TRIANGLE_DOWN,
            suffixText: `(${props.depositBonusList.length})`,
            noWrap: true
        }));
    const isPaymentBonuses = computed(()=>!!props.isChoosingBonusBeforeDeposit && props.depositBonusList.length > 0);
    const isDetailsAvailable = computed(()=>!!currentSelectedBonus.value?.actionUrl && !!currentSelectedBonus.value?.campaignId);
    function openListModal() {
        bonusStore.setChoosingBonusBeforeDeposit(true);
    }
    function openDetailsModal() {
        openedBonusDetails.value = props.depositBonusList.find((item)=>item.campaignId === props.selectedBonus) ?? null;
        if (openedBonusDetails.value) router.push({
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: openedBonusDetails.value.categoryId || '',
                actionUrl: openedBonusDetails.value.actionUrl || ''
            }
        });
    }
    function onCloseModal() {
        bonusStore.setChoosingBonusBeforeDeposit(false);
    }
    function onCloseAllModal() {
        router.closeModal();
    }
    function emitChange(value) {
        emit('change', value);
    }
    async function setSelectedOptionId() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : DEPOSIT_BONUS_DEFAULT_VALUE;
        if (props.selectedBonus === value) {
            bonusStore.setBonusSelection(value);
            return;
        }
        await nextTick();
        bonusStore.setBonusSelection(value);
        emitChange(String(props.selectedBonus));
    }
    useBusSafeSubscribe(BusEvent.DEPOSIT_BONUS_WIDGET_OPEN_LIST, openListModal);
    onMounted(()=>{
        const campaignId = router.getQuery('campaignId');
        if (campaignId) {
            const bonusId = props.depositBonusList.find((item)=>Number(item.campaignId) === Number(campaignId));
            setSelectedOptionId(bonusId?.campaignId);
        }
    });
    return {
        selectorListItemProperties,
        isPaymentBonuses,
        isDetailsAvailable,
        openedBonusDetails,
        openListModal,
        openDetailsModal,
        onCloseModal,
        onCloseAllModal,
        emitChange
    };
}
