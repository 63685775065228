import { defineComponent as _defineComponent } from 'vue';
import { mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import LBadge from 'web/src/components/Badge/LBadge/LBadge.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VBadge',
    props: {
        label: {},
        kind: {},
        position: {},
        isActive: {
            type: Boolean
        },
        iconName: {},
        isCounter: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        return (_ctx, _cache)=>(_openBlock(), _createBlock(LBadge, _mergeProps({
                key: 1
            }, props, {
                onClick: _cache[1] || (_cache[1] = ($event)=>emit('click', $event))
            }), null, 16));
    }
});
