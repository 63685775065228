import { provide, ref } from 'vue';
import { accordionSharedState } from '../types';
export default function useVList(props) {
    const openItem = ref(null);
    const updateOpenItem = (value)=>{
        openItem.value = value;
    };
    provide(accordionSharedState, {
        openItem,
        independentItems: props.independentItems ?? false,
        updateOpenItem
    });
}
