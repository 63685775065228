export default function useVSearchInput(emit) {
    const onInput = (event)=>{
        emit('input', event.target.value);
    };
    const onClear = ()=>{
        emit('clear', true);
    };
    return {
        onInput,
        onClear
    };
}
