import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
import { toRef, computed } from 'vue';
import HighestOddsBadge from 'web/src/modules/sportline/submodules/highest-odds/components/HighestOddsBadge.vue';
import { createShownMarketNameRef } from '../utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainMarketContainer',
    props: {
        actualMarket: {
            default: null
        },
        actualMarketType: {
            default: null
        },
        actualMarketTypePriority: {
            default: 0
        },
        runnersPlacesCount: {}
    },
    setup (__props) {
        const props = __props;
        const actualMarket = toRef(()=>props.actualMarket);
        const actualMarketType = toRef(()=>props.actualMarketType);
        const actualMarketTypePriority = toRef(()=>props.actualMarketTypePriority);
        const runnersPlacesCount = toRef(()=>props.runnersPlacesCount);
        const shownMarketName = createShownMarketNameRef({
            actualMarket,
            actualMarketType,
            actualMarketTypePriority
        });
        const isHighestOdds = computed(()=>!!actualMarket.value?.hasZeroMargin);
        const hasExtraLines = computed(()=>!!shownMarketName.value || isHighestOdds.value);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['main-market-container']]: true,
                    [_ctx.$style['main-market-container--with-top-line']]: _unref(shownMarketName),
                    [_ctx.$style['main-market-container--with-bottom-line']]: isHighestOdds.value
                })
            }, [
                _unref(shownMarketName) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['market-name-line']]: true,
                        [_ctx.$style['fit-to-columns']]: true,
                        [_ctx.$style[`fit-to-columns--${runnersPlacesCount.value}`]]: !!runnersPlacesCount.value
                    })
                }, [
                    _createTextVNode(_toDisplayString(_unref(shownMarketName)), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'main-market-name'
                        }
                    ]
                ]) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['main-market-container__main-content']]: true,
                        [_ctx.$style['main-market-container__main-content--short']]: hasExtraLines.value
                    })
                }, [
                    _renderSlot(_ctx.$slots, "default")
                ], 2),
                isHighestOdds.value ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass({
                        [_ctx.$style['highest-odds-line']]: true,
                        [_ctx.$style['fit-to-columns']]: true,
                        [_ctx.$style[`fit-to-columns--extra-${runnersPlacesCount.value}`]]: !!runnersPlacesCount.value
                    })
                }, [
                    _createVNode(HighestOddsBadge, {
                        "highest-odds-icon-color": "gradient",
                        "badge-background": "transparent",
                        "do-hide-text-for-small-size": ""
                    })
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'main-market-highest-odds'
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'MainMarketContainer'
                ]
            ]);
        };
    }
});
