import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "value",
    "readonly"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import useStakeInput from './useStakeInput';
import useStakeInputLabel from './useStakeInputLabel';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StakeInput',
    props: {
        isFocused: {
            type: Boolean
        },
        haveError: {
            type: Boolean
        },
        value: {
            default: '0'
        },
        currencySymbol: {
            default: '$'
        },
        maximumFractionDigits: {
            default: 2
        },
        betMode: {}
    },
    emits: [
        "input",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { onFocus, onBlur, onClear, onPaste, onInput, onEnterKeydown, inputElement, isClearButtonVisible, isCurrencySymbolVisible, inputValue } = useStakeInput(props, emits);
        const { widthMeter, isLabelHidden } = useStakeInputLabel(inputValue, props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['stake-input']]: true,
                    [_ctx.$style['stake-input--focused']]: _ctx.isFocused,
                    [_ctx.$style['stake-input--error']]: _ctx.haveError
                }),
                onClick: _cache[6] || (_cache[6] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onFocus) && _unref(onFocus)(...args);
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['stake-input__inner'])
                }, [
                    _withDirectives(_createElementVNode("label", {
                        class: _normalizeClass(_ctx.$style['stake-input__label'])
                    }, _toDisplayString(_ctx.$t('JSPBET_PLBET_STAKE_SHORT')), 3), [
                        [
                            _vShow,
                            !_unref(isLabelHidden)
                        ]
                    ]),
                    _createElementVNode("input", {
                        ref_key: "inputElement",
                        ref: inputElement,
                        value: _unref(inputValue),
                        type: "tel",
                        readonly: false,
                        class: _normalizeClass(_ctx.$style['stake-input__value']),
                        onInput: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onInput) && _unref(onInput)(...args);
                        }),
                        onFocus: _cache[1] || (_cache[1] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onFocus) && _unref(onFocus)(...args);
                        }),
                        onBlur: _cache[2] || (_cache[2] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onBlur) && _unref(onBlur)(...args);
                        }),
                        onPaste: _cache[3] || (_cache[3] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onPaste) && _unref(onPaste)(...args);
                        }),
                        onKeydown: _cache[4] || (_cache[4] = _withKeys(//@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onEnterKeydown) && _unref(onEnterKeydown)(...args);
                        }, [
                            "enter"
                        ]))
                    }, null, 42, _hoisted_1),
                    _createElementVNode("div", {
                        ref_key: "widthMeter",
                        ref: widthMeter,
                        "aria-hidden": "true",
                        class: _normalizeClass(_ctx.$style['stake-input__hidden'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['stake-input__hidden-label'])
                        }, _toDisplayString(_ctx.$t('JSPBET_PLBET_STAKE_SHORT')), 3),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['stake-input__hidden-value'])
                        }, _toDisplayString(_unref(inputValue)), 3)
                    ], 2)
                ], 2),
                _createCommentVNode("", true),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['stake-input__currency'])
                }, [
                    _withDirectives(_createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['stake-input__currency-symbol'])
                    }, _toDisplayString(_ctx.currencySymbol), 3), [
                        [
                            _vShow,
                            _unref(isCurrencySymbolVisible)
                        ]
                    ]),
                    _withDirectives(_createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['stake-input__clear'])
                    }, [
                        _createElementVNode("button", {
                            class: _normalizeClass(_ctx.$style['stake-input__clear-button']),
                            type: "button",
                            tabIndex: "-1",
                            onMousedown: _cache[5] || (_cache[5] = _withModifiers(//@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(onClear) && _unref(onClear)(...args);
                            }, [
                                "prevent"
                            ]))
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: _unref(IconName).CANCEL,
                                size: _unref(IconSize).SIZE_16
                            }, null, 8, [
                                "name",
                                "size"
                            ])
                        ], 34)
                    ], 2), [
                        [
                            _vShow,
                            _unref(isClearButtonVisible)
                        ]
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'StakeInput'
                ]
            ]);
        };
    }
});
