import { toRef, ref } from 'vue';
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';
export default function useBalanceUser(props, emit) {
    const paymentStore = usePaymentsStore();
    const isSuccessModal = ref(false);
    const balanceSnapshot = ref('');
    const isSingleMode = toRef(()=>props.mode === BalanceMode.Single);
    const isMultipleMode = toRef(()=>props.mode === BalanceMode.Multiple);
    function emitClose() {
        emit('close');
    }
    function handleCloseSuccessModal() {
        isSuccessModal.value = false;
        emitClose();
    }
    function onWithdrawBonus(balance) {
        isSuccessModal.value = true;
        balanceSnapshot.value = balance;
    }
    function beforeMount() {
        paymentStore.loadWithdrawalStats();
    }
    return {
        isSuccessModal,
        balanceSnapshot,
        isSingleMode,
        isMultipleMode,
        emitClose,
        handleCloseSuccessModal,
        onWithdrawBonus,
        beforeMount
    };
}
