import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
export function useWindowEvent(event, callback) {
    let hasActiveListener = false;
    function addEventListener() {
        if (!hasActiveListener) {
            window.addEventListener(event, callback);
            hasActiveListener = true;
        }
    }
    function removeEventListener() {
        if (hasActiveListener) {
            window.removeEventListener(event, callback);
            hasActiveListener = false;
        }
    }
    onComponentActivated(addEventListener);
    onComponentDeactivated(removeEventListener);
}
