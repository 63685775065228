import { computed, ref } from 'vue';
import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { useLeagueEventsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { isEmptyFilter, filterSportsListEvents, splitSportElementLeaguesByDateFilter } from 'web/src/modules/sportline/utils';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
export function useLeagueEventsStoreComposable(props) {
    const { expectedId, configForLeagueLoading } = props;
    const apiService = useSportlineApiService();
    const broadcastSelected = useBroadcastSelected();
    const { timeout: leagueEventsUpdateInterval } = useLeagueEventsSportlineUpdateTimeout();
    const parseSportlineSettings = useParseSportlineSettingsRef();
    const { isLoading, lastLoadedId, isExpectedIdLoaded, setIsLoading, setLastLoadedId } = useExpectedIdLoading({
        expectedId
    });
    const rawEventsResponse = ref(null);
    const liveFilter = {
        activeKickoffPeriod: SportFilterKickoffPeriod.ALL,
        sportlineType: SportlineType.Live
    };
    const isReady = computed(()=>null !== rawEventsResponse.value && isExpectedIdLoaded.value);
    const sportElement = computed(()=>{
        if (!rawEventsResponse.value) return null;
        const { filter } = configForLeagueLoading.value;
        return splitSportElementLeaguesByDateFilter(new SportlineFactory(rawEventsResponse.value, parseSportlineSettings.value).build()[0] || null, isEmptyFilter(filter) ? [
            liveFilter
        ] : [
            liveFilter,
            {
                ...filter
            }
        ]);
    });
    const regionElement = computed(()=>sportElement.value?.regions[0] ?? null);
    const leagueElement = computed(()=>regionElement.value?.leagues[0] ?? null);
    const hasEvents = computed(()=>(leagueElement.value?.sportEvents?.length || 0) > 0);
    const outrightEvents = computed(()=>{
        // @TODO add type guard
        const sportElementValue = sportElement.value;
        return filterSportsListEvents(sportElementValue ? [
            sportElementValue
        ] : [], (item)=>item.sportEvent.isOutright);
    });
    const hasOutrightEvents = computed(()=>outrightEvents.value.length > 0);
    const isEmpty = computed(()=>!hasEvents.value && !hasOutrightEvents.value);
    const lastUpdate = new BackgroundUpdateStopwatch({
        leagueEvents: async (param)=>{
            let { silent } = param;
            const leagueId = expectedId.value;
            const isUpdateRequest = leagueId === lastLoadedId.value;
            if (!leagueId) return;
            setIsLoading(true);
            try {
                const response = await apiService.loadLeagueEvents({
                    leagueId: String(leagueId),
                    // here could be "false" value in rawEventsResponse, nullish-coalescing is not working here
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    vTag: isUpdateRequest ? (rawEventsResponse.value || null)?.vtag : void 0,
                    silent
                });
                // user went away from the page
                if (leagueId !== expectedId.value) return;
                rawEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
                broadcastSelected.updateDataInStorageByResponse({
                    response
                });
                lastUpdate.update('leagueEvents');
            } catch (error) {
                // set error value for first load
                rawEventsResponse.value = false;
                throw error;
            } finally{
                setIsLoading(false);
                setLastLoadedId(leagueId);
            }
        }
    });
    const { initialRequests, syncState, syncBackgroundRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: leagueEventsUpdateInterval
    });
    function onInit() {
        useSyncState(async (silent)=>{
            await syncState({
                silent
            });
        }, leagueEventsUpdateInterval);
    }
    function clearEventsResponse() {
        rawEventsResponse.value = null;
        lastLoadedId.value = null;
    }
    return {
        isReady,
        isEmpty,
        isLoading,
        rawEventsResponse,
        sportElement,
        regionElement,
        leagueElement,
        outrightEvents,
        hasEvents,
        hasOutrightEvents,
        clearEventsResponse,
        onInit,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
}
