import { createMarketColumns } from './createMarketColumns';
import createSport from './createSport';
export function parseSportResponse(response, buildSportOptions) {
    const sport = createSport({
        ...buildSportOptions,
        sport: response
    });
    const marketsColumns = createMarketColumns({
        ...buildSportOptions,
        sport: response
    });
    return {
        sport,
        marketsColumns,
        leagues: {},
        outrightEvents: []
    };
}
