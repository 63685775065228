import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useResetPersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { CustomFilter } from 'web/src/modules/sportline/enums';
function isGroupCanBeShown(group) {
    return group.isActive && (!group.isEmpty || !group.isEmptyComing);
}
function doNotRedirectFromFilter(filter) {
    return filter === CustomFilter.AllEvents || filter === CustomFilter.None;
}
export function useSegmentPageResetFiltersHooks(props) {
    const { isReady, selectedFilter, groupsForDisplay, persistentLocationKey, getDefaultPageLocation, setBackgroundUpdateEnabled } = props;
    const router = useRouter();
    const { handlePersistLocationReset } = useResetPersistLocation();
    let unwatchRedirectFromEmptyTab = null;
    // ref to filter element
    const listTransition = ref(null);
    // @TODO merge logic with SportEventsAbstractTab.needRedirectFromTab
    const doRedirectFromTab = computed(()=>{
        if (!isReady.value) return false;
        if (doNotRedirectFromFilter(selectedFilter.value)) return false;
        return !groupsForDisplay.value.find(isGroupCanBeShown);
    });
    function onChangeFilter(payload) {
        // stop navigation for active transition
        if (listTransition.value?.isActive()) return;
        const to = payload?.to || getDefaultPageLocation();
        listTransition.value?.enable();
        if (to) router.replace(to);
    }
    function onActivatedFiltersHook() {
        setBackgroundUpdateEnabled(true);
        unwatchRedirectFromEmptyTab = unwatchRedirectFromEmptyTab ?? watch(doRedirectFromTab, (doRedirect)=>{
            if (!doRedirect) return;
            onChangeFilter(null);
        }, {
            immediate: true
        });
    }
    function onDeactivatedFiltersHook() {
        unwatchRedirectFromEmptyTab?.();
        unwatchRedirectFromEmptyTab = null;
        setBackgroundUpdateEnabled(false);
        handlePersistLocationReset(persistentLocationKey);
    }
    return {
        listTransition,
        doRedirectFromTab,
        onChangeFilter,
        onActivatedFiltersHook,
        onDeactivatedFiltersHook
    };
}
