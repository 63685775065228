import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { BannerCategory, BannerLocation } from 'web/src/modules/banners/enums';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import { useMainBannerSectionRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainBannerSectionRouteComponent',
    props: {
        location: {
            default: BannerLocation.TOP
        },
        customLocationId: {
            default: ''
        },
        isStatic: {
            type: Boolean
        },
        isStoryBanner: {
            type: Boolean
        },
        isBigHeight: {
            type: Boolean
        },
        aspectRatio: {
            default: 1.75
        },
        bannerType: {
            default: BannerType.MAIN_BANNER
        },
        bannerCategory: {
            default: BannerCategory.DEFAULT
        }
    },
    setup (__props) {
        const props = __props;
        const { showBannerSection, bannerGroupComponent, currentGroup, isLoggedIn, disableClickAreas, onRouterLinkClick } = useMainBannerSectionRouteComponent(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _unref(showBannerSection) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['banner-section']]: true,
                    [_ctx.$style['banner-section__big-height']]: _ctx.isBigHeight
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style[_ctx.isStatic ? 'banner-section__column' : 'banner-section__wrapper'])
                }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(bannerGroupComponent)), {
                        "banner-group": _unref(currentGroup),
                        "is-logged-in": _unref(isLoggedIn),
                        "banner-type": _ctx.bannerType,
                        "aspect-ratio": _ctx.aspectRatio,
                        "banner-category": _ctx.bannerCategory,
                        "is-big-height": _ctx.isBigHeight,
                        onBannerItemClick: _unref(onRouterLinkClick)
                    }, null, 40, [
                        "banner-group",
                        "is-logged-in",
                        "banner-type",
                        "aspect-ratio",
                        "banner-category",
                        "is-big-height",
                        "onBannerItemClick"
                    ]))
                ], 2),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(disableClickAreas), (click)=>(_openBlock(), _createElementBlock("div", {
                        key: click,
                        class: _normalizeClass({
                            [_ctx.$style['banner-section__disable-click']]: true,
                            [_ctx.$style[`banner-section__disable-click--${click}`]]: true
                        })
                    }, null, 2))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'MainBannerSectionRouteComponent'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'banner-section'
                    }
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
