import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { ParseSportEventError } from 'web/src/modules/sportline/errors/rest';
import { parseSportResponse, parseRegionResponse, parseLeagueResponse, createSportEvent } from 'web/src/modules/sportline/utils/rest/build';
import { AbstractLineFactory } from 'web/src/modules/sportline/utils/rest';
/**
 * Used to prepare top events sportline (home page)
 * @TODO merge with SportlineFactory
 */ export class HeadlineFactory extends AbstractLineFactory {
    processSportResponse(param) {
        let { sportResponse } = param;
        const sportId = String(sportResponse.id);
        let sportInfo = this.result.get(sportId);
        if (!sportInfo) {
            const rawSport = parseSportResponse(sportResponse, this.buildSportOptions);
            sportInfo = {
                sport: rawSport,
                sportEvents: []
            };
            this.result.set(sportId, sportInfo);
        }
        return sportInfo;
    }
    processRegionResponse(param) {
        let { sportResponse, regionResponse } = param;
        const regionId = String(regionResponse.id);
        let regionInfo = this.regions.get(regionId);
        if (!regionInfo) {
            const rawRegion = parseRegionResponse({
                sportResponse,
                regionResponse,
                ...this.buildSportOptions
            });
            regionInfo = {
                region: rawRegion,
                leagues: new Map([])
            };
            this.regions.set(regionId, regionInfo);
        }
        return regionInfo;
    }
    processLeagueResponse(param) {
        let { sportResponse, regionResponse, leagueResponse } = param;
        const regionInfo = this.processRegionResponse({
            sportResponse,
            regionResponse
        });
        const leagueId = String(leagueResponse.id);
        let leagueInfo = regionInfo.leagues.get(leagueId);
        if (!leagueInfo) {
            const rawLeague = parseLeagueResponse({
                sportResponse,
                regionResponse,
                leagueResponse,
                ...this.buildSportOptions
            });
            leagueInfo = {
                league: rawLeague
            };
            regionInfo.leagues.set(leagueId, leagueInfo);
        }
        return leagueInfo;
    }
    // eslint-disable-next-line rulesdir/class-method-use-this-regex
    processEventResponse(param) {
        let { sportResponse, regionResponse, leagueResponse, eventResponse } = param;
        if (eventResponse.betline === BetlineType.Outright) throw new ParseSportEventError({
            message: 'Outright in headline is not allowed',
            response: eventResponse,
            buildOptions: this.buildSportOptions
        });
        const sportInfo = this.processSportResponse({
            sportResponse
        });
        const regionInfo = this.processRegionResponse({
            sportResponse,
            regionResponse
        });
        const leagueInfo = this.processLeagueResponse({
            sportResponse,
            regionResponse,
            leagueResponse
        });
        const sportEvent = createSportEvent(eventResponse, {
            sportResponse,
            regionResponse,
            leagueResponse,
            ...this.buildSportOptions,
            useFullProgress: sportInfo.sport.sport.representation.useFullProgress
        });
        sportInfo.sportEvents.push({
            sportEvent,
            region: regionInfo.region,
            league: leagueInfo.league
        });
    }
    build() {
        return [
            ...this.parseResponse().values()
        ].map((sportInfo)=>{
            const sportMarketTypes = new Map([]);
            const sportEvents = sportInfo.sportEvents.map((sportEventInfo)=>{
                for (const market of sportEventInfo.sportEvent.markets)sportMarketTypes.set(market.type.id, market.type);
                return {
                    league: sportEventInfo.league.league,
                    region: sportEventInfo.region.region,
                    sportEvent: sportEventInfo.sportEvent
                };
            });
            return {
                key: sportInfo.sport.sport.id,
                sport: sportInfo.sport.sport,
                marketTypes: [
                    ...sportMarketTypes.values()
                ],
                marketsColumns: sportInfo.sport.marketsColumns,
                sportEvents
            };
        });
    }
    constructor(...args){
        super(...args);
        _define_property(this, "result", new Map([]));
        _define_property(this, "regions", new Map([]));
    }
}
