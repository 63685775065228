import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getLeaderBoard as getLeaderBoardApi } from '@leon-hub/api-sdk';
import { logger as loggerInstance } from '@leon-hub/logging';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { getLeaderBoardOptions } from './utils';
const logger = loggerInstance.createNamespace('LeaderBoardActions');
const leaderBoardPageSizeDefaultPageSize = 6;
const DEFAULT = {
    nextPage: 1,
    // backend pagination starts from 1
    customerPosition: null,
    isLoading: true
};
const useLeaderBoardStore = defineStore('leader-board', ()=>{
    const apiClient = useGraphqlClient();
    const scgStore = useSiteConfigStore();
    const leaderBoardListMap = ref({});
    const leaderBoardOptionsMapRecord = ref({});
    function setLeaderBoardOptions(payload) {
        const { keys: { actionUrl, id }, options } = payload;
        const boards = leaderBoardOptionsMapRecord.value[actionUrl];
        if (!boards) leaderBoardOptionsMapRecord.value[actionUrl] = {};
        leaderBoardOptionsMapRecord.value[actionUrl][id] = options;
    }
    function updateLeaderBoardOptions(payload) {
        const { keys: { actionUrl, id }, options } = payload;
        const oldOptions = leaderBoardOptionsMapRecord.value[actionUrl]?.[id];
        if (oldOptions) leaderBoardOptionsMapRecord.value[actionUrl][id] = {
            ...oldOptions,
            ...options
        };
    }
    function setLeaderBoardTournamentList(payload) {
        const { list, id, overwrite } = payload;
        const oldList = leaderBoardListMap.value[id];
        if (oldList && !overwrite) leaderBoardListMap.value[id] = [
            ...oldList,
            ...list
        ];
        else leaderBoardListMap.value[id] = list;
    }
    function resetLeaderBoard() {
        leaderBoardListMap.value = {};
        leaderBoardOptionsMapRecord.value = {};
    }
    const pageSize = computed(()=>scgStore.promotionsBlock?.leaderboardPageSize || leaderBoardPageSizeDefaultPageSize);
    async function getLeaderBoard(payload) {
        const { actionUrl, id, isUpdateRequest } = payload;
        if (!isUpdateRequest) {
            const currentOptions = !!getLeaderBoardOptions(actionUrl, id, leaderBoardOptionsMapRecord.value);
            if (currentOptions) updateLeaderBoardOptions({
                keys: payload,
                options: {
                    isLoading: true
                }
            });
            else setLeaderBoardOptions({
                keys: payload,
                options: DEFAULT
            });
        }
        await leaderBoardRequest(payload);
        updateLeaderBoardOptions({
            keys: payload,
            options: {
                isLoading: false
            }
        });
    }
    async function leaderBoardRequest(payload) {
        const { actionUrl, id, isUpdateRequest, isUpdateAfterLogin } = payload;
        const currentOptions = getLeaderBoardOptions(actionUrl, id, leaderBoardOptionsMapRecord.value);
        /**
     * NB! Backend logic.
     * If there is tournamentId in request then it will be prioritized as identifier.
     * Otherwise identifier is actionUrl.
     */ const requestOptions = {
            tournamentId: actionUrl === id ? void 0 : Number(id),
            page: currentOptions && !isUpdateRequest ? currentOptions.nextPage : 1,
            pageSize: payload.pageSize || pageSize.value,
            actionUrl
        };
        if (currentOptions?.nextPage === 0) return;
        try {
            const { winners, nextPage, customerPosition } = await getLeaderBoardApi(apiClient, (node)=>node.queries.promotions.getLeaderBoard, {
                options: requestOptions
            });
            if (!isUpdateRequest) updateLeaderBoardOptions({
                keys: payload,
                options: {
                    customerPosition: customerPosition ?? null,
                    nextPage: nextPage || 0,
                    isLoading: false
                }
            });
            setLeaderBoardTournamentList({
                id,
                list: winners ? [
                    ...winners
                ] : [],
                overwrite: isUpdateRequest ?? isUpdateAfterLogin
            });
        } catch (error) {
            logger.error('leaderBoardRequest error', error);
        }
    }
    return {
        leaderBoardListMap,
        leaderBoardOptionsMapRecord,
        setLeaderBoardOptions,
        updateLeaderBoardOptions,
        setLeaderBoardTournamentList,
        resetLeaderBoard,
        getLeaderBoard,
        leaderBoardRequest
    };
});
export default useLeaderBoardStore;
