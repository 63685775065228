import { isNumber, isValidInterface } from '@leon-hub/guards';
import { addLeadingZero } from '@leon-hub/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DateRangeDefaultDay } from 'web/src/modules/affiliates/store/enums';
export function getLastMonths(fromDate) {
    let monthsCount = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 3;
    const result = [];
    let count = monthsCount;
    let isPreviousYear = false;
    let currentMonth = fromDate.getMonth() - monthsCount;
    while(count > 0){
        if (currentMonth < 0) {
            isPreviousYear = true;
            currentMonth += 12;
        }
        if (currentMonth >= 12) {
            isPreviousYear = false;
            currentMonth -= 12;
        }
        const date = new Date();
        date.setMonth(currentMonth);
        if (isPreviousYear) date.setFullYear(fromDate.getFullYear() - 1);
        result.push(date);
        currentMonth += 1;
        count -= 1;
    }
    return result;
}
export const dateRangeFilterOptionsList = ()=>{
    const { $translate } = useI18n();
    const defaultDateRange = [
        {
            label: $translate('WEB2_DATE_RANGE_FILTER_FORTODAY').value,
            value: DateRangeDefaultDay.OneDay
        },
        {
            label: $translate('WEB2_DATE_RANGE_FILTER_LAST3DAYS').value,
            value: DateRangeDefaultDay.ThreeDays
        },
        {
            label: $translate('WEB2_DATE_RANGE_FILTER_LAST7DAYS').value,
            value: DateRangeDefaultDay.SevenDays
        },
        {
            label: $translate('WEB2_DATE_RANGE_FILTER_LAST15DAYS').value,
            value: DateRangeDefaultDay.FifteenDays
        },
        {
            label: $translate('WEB2_DATE_RANGE_FILTER_LAST30DAYS').value,
            value: DateRangeDefaultDay.ThirtyDays
        }
    ];
    const lastMonths = getLastMonths(new Date(), 3).reverse().map((date)=>({
            label: `${$translate('WEB2_DATE_RANGE_FILTER_PERMONTH').value} ${date.getMonth() + 1}/${date.getFullYear()}`,
            value: `m${addLeadingZero(date.getMonth() + 1, 2)}/${date.getFullYear()}`
        }));
    return [
        ...defaultDateRange,
        ...lastMonths
    ];
};
export const isAffiliateStats = (value)=>isValidInterface(value, {
        balance: isNumber,
        pendingBalance: isNumber,
        percent: isNumber,
        affiliatedCustomersCount: isNumber,
        profitForPeriod: isNumber,
        profit: isNumber
    });
