import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { InputWrapper, InputBorderWrapper } from 'web/src/components/Input';
import LayoutRelatedSelect from './LayoutRelatedSelect';
import { useSingleSelect } from '../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SingleSelect',
    props: {
        autofocus: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        dropdownMinWidth: {
            default: 0
        },
        hidden: {
            type: Boolean
        },
        label: {},
        placeholder: {},
        isCountrySelector: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        name: {
            default: 'SingleSelect'
        },
        selectedValue: {
            default: ''
        },
        options: {
            default: ()=>[]
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { mouseover, mouseleave, onFocus, onBlur, onChange, borderWrapperProps, wrapperInputProps, selectProperties } = useSingleSelect(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), _normalizeProps(_guardReactiveProps(_unref(wrapperInputProps))), {
                iconPrefix: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "iconPrefix")
                    ]),
                input: _withCtx(()=>[
                        _createVNode(_unref(InputBorderWrapper), _normalizeProps(_guardReactiveProps(_unref(borderWrapperProps))), {
                            default: _withCtx(()=>[
                                    _createVNode(_unref(LayoutRelatedSelect), _mergeProps(_unref(selectProperties), {
                                        onChange: _unref(onChange),
                                        onFocus: _unref(onFocus),
                                        onBlur: _unref(onBlur),
                                        onMouseover: _unref(mouseover),
                                        onMouseleave: _unref(mouseleave)
                                    }), null, 16, [
                                        "onChange",
                                        "onFocus",
                                        "onBlur",
                                        "onMouseover",
                                        "onMouseleave"
                                    ])
                                ]),
                            _: 1
                        }, 16)
                    ]),
                _: 3
            }, 16)), [
                [
                    _directive_auto_id,
                    'SingleSelect'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'single-select',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
