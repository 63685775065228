import { ref, computed } from 'vue';
import { useIsDebugEnabled } from 'web/src/modules/sportline/submodules/debug/store/composables/useIsDebugEnabled';
import { useSaveToLocalStorage } from './useSaveToLocalStorage';
export function useDebugIFrameUrl(props) {
    const { localStorageKey } = props;
    const { isDebugEnabled } = useIsDebugEnabled();
    const { saveValueToLocalStorage, getValueFromLocalStorage } = useSaveToLocalStorage();
    const iframeUrlInternal = ref(null);
    const iframeUrl = computed(()=>isDebugEnabled.value ? iframeUrlInternal.value : null);
    return {
        iframeUrl,
        setIFrameUrl (value) {
            iframeUrlInternal.value = value ?? null;
        },
        saveIFrameUrl () {
            saveValueToLocalStorage(localStorageKey, iframeUrlInternal.value);
        },
        loadIFrameUrl () {
            if (!isDebugEnabled.value) return;
            const rawIFrameUrl = getValueFromLocalStorage(localStorageKey);
            if (rawIFrameUrl) iframeUrlInternal.value = rawIFrameUrl;
        }
    };
}
