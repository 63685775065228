import { ref, nextTick } from 'vue';
export function useFavoriteRegionFilterSwiper(param) {
    let { selectedIndex } = param;
    const swiper = ref();
    return {
        swiper,
        async slideToActive () {
            let smooth = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
            await nextTick();
            swiper.value?.slideToSlide(selectedIndex.value, {
                isCentered: true,
                smooth,
                speed: 300
            });
        }
    };
}
