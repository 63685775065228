import { computed, ref, watch } from 'vue';
import { useExtendedEventStore } from 'web/src/modules/sportline/submodules/extended-event/store';
import { useExtendedEventSettings } from 'web/src/modules/sportline/submodules/extended-event/store/composables';
import { useIsEventExtended } from 'web/src/modules/sportline/submodules/extended-event/composbles';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
export function useExtendedSportEventInfo(props, emits) {
    const { sportlineEventId, extendedBlockType } = props;
    const { extendedEventDetailsMarketLimit: extendedSportEventDetailsMarketLimit, isMarketGroupsTabsEnabled } = useExtendedEventSettings();
    const extendedEventStore = useExtendedEventStore();
    const { createEventDetailsRef, loadSportlineEventDetails, addEventIdToOpened, removeEventIdFromOpened, addEventIdToBackgroundRequests, removeEventIdFromBackgroundRequests } = extendedEventStore;
    const blockType = extendedBlockType ?? ref(ExtendedEventBlock.Default);
    const extendedSportEvent = createEventDetailsRef(sportlineEventId, blockType);
    const { isEventExtended } = useIsEventExtended({
        sportlineEventId,
        blockType
    });
    let stopWatchEventIsClosedHandle;
    const representationSportFamily = computed(()=>extendedSportEvent.value?.sport.representation.family ?? null);
    function stopWatchEventIsClosed() {
        stopWatchEventIsClosedHandle?.();
        stopWatchEventIsClosedHandle = void 0;
    }
    function closeExtendedSportlineEvent() {
        stopWatchEventIsClosed();
        removeEventIdFromBackgroundRequests(sportlineEventId.value, blockType.value);
        removeEventIdFromOpened(sportlineEventId.value, blockType.value);
        emits.afterClose();
    }
    function onIsEventExtendedValueChanged(value) {
        if (value) return;
        stopWatchEventIsClosed();
        closeExtendedSportlineEvent();
    }
    /**
   * Watch the extended state to stop background requests if user extends too many events
   */ function startWatchEventIsClosed() {
        stopWatchEventIsClosedHandle = watch(isEventExtended, onIsEventExtendedValueChanged);
    }
    async function loadExtendedSportEvent() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        await loadSportlineEventDetails(sportlineEventId.value, silent);
        const event = extendedSportEvent.value;
        if (!event && !silent) closeExtendedSportlineEvent();
    }
    /** Open the block after button clicked */ async function openExtendedSportlineEvent() {
        if (isEventExtended.value) return;
        addEventIdToOpened(sportlineEventId.value, blockType.value);
        await loadExtendedSportEvent();
        if (!extendedSportEvent.value) closeExtendedSportlineEvent();
        // start background requests for this id after the first loading
        addEventIdToBackgroundRequests(sportlineEventId.value, blockType.value);
        startWatchEventIsClosed();
    }
    function resumeBackgroundUpdated() {
        if (!isEventExtended.value) return;
        addEventIdToBackgroundRequests(sportlineEventId.value, blockType.value);
    }
    function pauseBackgroundUpdated() {
        removeEventIdFromBackgroundRequests(sportlineEventId.value, blockType.value);
        // @TODO check. Seems it is just used to stop scrolling
        emits.afterClose();
    }
    return {
        openExtendedSportlineEvent,
        closeExtendedSportlineEvent,
        pauseBackgroundUpdated,
        resumeBackgroundUpdated,
        isEventExtended,
        extendedSportEvent,
        representationSportFamily,
        extendedSportEventDetailsMarketLimit,
        isMarketGroupsTabsEnabled
    };
}
