import { InlineChatHideCondition } from '@leon-hub/api-sdk';
export default function getInlineChatHideTimeout(condition) {
    switch(condition){
        case InlineChatHideCondition.SECONDS_3:
            return 3000;
        case InlineChatHideCondition.SECONDS_5:
            return 5000;
        case InlineChatHideCondition.SECONDS_10:
            return 10000;
        default:
            break;
    }
    return 0;
}
