import { ref, computed } from 'vue';
export function useExpectedIdLoading(props) {
    const { expectedId } = props;
    const isLoading = ref(false);
    const lastLoadedId = ref(null);
    const isExpectedIdLoaded = computed(()=>!!lastLoadedId.value && expectedId.value === lastLoadedId.value);
    return {
        isLoading,
        lastLoadedId,
        isExpectedIdLoaded,
        setIsLoading (value) {
            isLoading.value = value;
        },
        setLastLoadedId (value) {
            lastLoadedId.value = value;
        }
    };
}
