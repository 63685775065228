import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCheckboxIcon',
    props: {
        checked: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        isError: {
            type: Boolean
        },
        isHover: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("span", {
                class: _normalizeClass({
                    [_ctx.$style['checkbox-icon']]: true,
                    [_ctx.$style['checkbox-icon--disabled']]: _ctx.disabled,
                    [_ctx.$style['checkbox-icon--checked']]: _ctx.checked,
                    [_ctx.$style['checkbox-icon--hover']]: _ctx.isHover,
                    [_ctx.$style['checkbox-icon--error']]: _ctx.isError
                })
            }, [
                _renderSlot(_ctx.$slots, "default"),
                _createVNode(_unref(VIcon), {
                    class: _normalizeClass(_ctx.$style['checkbox-icon__check']),
                    name: _unref(IconName).CHECK_ROUNDED,
                    size: _unref(IconSize).SIZE_20
                }, null, 8, [
                    "class",
                    "name",
                    "size"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VCheckboxIcon'
                ]
            ]);
        };
    }
});
