import memoize from 'lodash/memoize';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { reloadPageWithCurrentLang } from './utils';
import { useI18nStore } from '../store';
/**
 * Composable i18n service.
 */ const useI18n = memoize(()=>{
    let languageSelectorPlace = '';
    function isLanguageChangeable(languageTag) {
        if (!languageTag) return false;
        const userLanguageTag = languageTag.replace('-', '_');
        const i18nStore = useI18nStore();
        return i18nStore.locale !== userLanguageTag;
    }
    async function setLanguageTag(param) {
        let { reload, textMetrics, languageTag } = param;
        if (!isLanguageChangeable(languageTag)) return;
        const i18nStore = useI18nStore();
        const prevUrlPrefix = i18nStore.currentUrlPrefix;
        await i18nStore.changeLanguage(languageTag);
        if (textMetrics) {
            const analytics = useAnalytics();
            analytics.clickMap({
                language: {
                    [languageSelectorPlace]: textMetrics.toLowerCase()
                }
            });
        }
        if (reload) reloadPageWithCurrentLang(prevUrlPrefix, i18nStore.currentUrlPrefix);
    }
    return {
        get $translate () {
            return useI18nStore().$t;
        },
        setLanguageTag,
        setLanguageSelectorPlace (value) {
            languageSelectorPlace = value;
        },
        isLanguageChangeable
    };
});
export default useI18n;
