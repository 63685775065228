import { FramedVirtualSportWidgetSport } from './enums';
// manual map for { VirtualSportTournament } from '@leon-hub/api-sdk/src/sdk/sdk';
// used for reduce framed app chunk size
const FramedVirtualSportTournament = {
    FOOTBALL_LEAGUE_MODE: 'FOOTBALL_LEAGUE_MODE',
    BASKETBALL: 'BASKETBALL',
    FOOTBALL_EUROCUP: 'FOOTBALL_EUROCUP',
    FOOTBALL_CHAMPIONS_CUP: 'FOOTBALL_CHAMPIONS_CUP',
    FOOTBALL_NATIONS_CUP: 'FOOTBALL_NATIONS_CUP',
    TENNIS_INPLAY: 'TENNIS_INPLAY',
    FOOTBALL_WORLD_CUP: 'FOOTBALL_WORLD_CUP',
    FOOTBALL_BUNDESLIGA: 'FOOTBALL_BUNDESLIGA',
    CRICKET: 'CRICKET'
};
export const framedVirtualSportIdBySport = {
    [FramedVirtualSportWidgetSport.FootballLeague]: FramedVirtualSportTournament.FOOTBALL_LEAGUE_MODE,
    [FramedVirtualSportWidgetSport.Basketball]: FramedVirtualSportTournament.BASKETBALL,
    [FramedVirtualSportWidgetSport.FootballEurocup]: FramedVirtualSportTournament.FOOTBALL_EUROCUP,
    [FramedVirtualSportWidgetSport.FootballChampionsCup]: FramedVirtualSportTournament.FOOTBALL_CHAMPIONS_CUP,
    [FramedVirtualSportWidgetSport.FootballNationsCup]: FramedVirtualSportTournament.FOOTBALL_NATIONS_CUP,
    [FramedVirtualSportWidgetSport.TennisInplay]: FramedVirtualSportTournament.TENNIS_INPLAY,
    [FramedVirtualSportWidgetSport.FootballWorldCup]: FramedVirtualSportTournament.FOOTBALL_WORLD_CUP,
    [FramedVirtualSportWidgetSport.FootballBundesliga]: FramedVirtualSportTournament.FOOTBALL_BUNDESLIGA,
    [FramedVirtualSportWidgetSport.Cricket]: FramedVirtualSportTournament.CRICKET
};
export const framedVirtualSportBySportId = Object.fromEntries(Object.keys(framedVirtualSportIdBySport).map((key)=>[
        framedVirtualSportIdBySport[key],
        key
    ]));
