import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import MainBannerItem from 'web/src/modules/banners/components/MainBannerItem/MainBannerItem.vue';
import { useAbstractBanner } from 'web/src/modules/banners/composables/useAbstractBanner';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StaticBanner',
    props: {
        bannerGroup: {},
        isLoggedIn: {
            type: Boolean
        },
        bannerType: {
            default: BannerType.MAIN_BANNER
        },
        aspectRatio: {
            default: 1.75
        },
        isBigHeight: {
            type: Boolean
        }
    },
    emits: [
        "banner-item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { banners, isGroupBannersDev, emitBannerItemClick } = useAbstractBanner(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(banners), (banner, index)=>(_openBlock(), _createBlock(MainBannerItem, {
                        key: index,
                        banner: banner,
                        "group-id": _ctx.bannerGroup?.id,
                        "aspect-ratio": _ctx.aspectRatio,
                        "is-group-banners-dev": _unref(isGroupBannersDev),
                        "banner-type": _ctx.bannerType,
                        onClick: ($event)=>_unref(emitBannerItemClick)($event, banner)
                    }, null, 8, [
                        "banner",
                        "group-id",
                        "aspect-ratio",
                        "is-group-banners-dev",
                        "banner-type",
                        "onClick"
                    ]))), 128))
            ])), [
                [
                    _directive_auto_id,
                    'StaticBanner'
                ]
            ]);
        };
    }
});
