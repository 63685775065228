export function getOpenStateKeyForGroup(group) {
    return group.id;
}
export function getGroupIdForMarket(market) {
    const id = market?.type?.id ?? '';
    const name = market?.type?.name ?? '';
    return [
        id,
        name
    ].join(' - ');
}
export function createEmptyMarketGroupForMarket(market) {
    return {
        id: getGroupIdForMarket(market),
        title: market?.name ?? '',
        displayTitle: false,
        markets: [],
        isOpened: false,
        hasZeroMargin: false
    };
}
