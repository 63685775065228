import { computed } from 'vue';
import { isOptionalBoolean } from '@leon-hub/guards';
export default function useFormSwitch(props) {
    const componentProps = computed(()=>{
        const { name, disabled, value, title, hintMessage, autofocus } = props;
        isOptionalBoolean(value);
        return {
            name,
            checked: value,
            label: title,
            disabled,
            hint: hintMessage,
            autofocus
        };
    });
    return {
        componentProps
    };
}
