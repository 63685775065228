import { delay } from '@leon-hub/utils';
import { LEXIS_NEXIS_PROFILE_TIMEOUT } from 'web/src/modules/lexis-nexis-integration/constants';
import { createLexisNexisApi } from './createLexisNexisApi';
export const runLexisNexisApiProfile = async (options, param)=>{
    let { log } = param;
    const { lexGuardOrgId, lexGuardAPIUrl, lexGuardProfilingSubdomain, sessionId, timeout = LEXIS_NEXIS_PROFILE_TIMEOUT } = options;
    log('calling sdk: %O', options);
    const { api, stop } = await createLexisNexisApi(lexGuardAPIUrl);
    try {
        const timeoutTime = Date.now() + timeout;
        // TODO: fix comlink invalid typings of consuming api.
        // @ts-ignore
        api.threatmetrix.profile(lexGuardProfilingSubdomain, lexGuardOrgId, sessionId);
        // eslint-disable-next-line no-await-in-loop
        while(!await api.isProfiled){
            log('waiting to complete');
            if (Date.now() > timeoutTime) throw new Error('Lexis profile timeout');
            // eslint-disable-next-line no-await-in-loop
            await delay(100);
        }
    } finally{
        stop();
    }
};
