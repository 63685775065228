import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "language-selector"
};
const _hoisted_2 = [
    "onClick"
];
const _hoisted_3 = {
    class: "language-selector__item-text"
};
const _hoisted_4 = {
    key: 2,
    class: "language-selector__icon-container"
};
const _hoisted_5 = [
    "value",
    "checked"
];
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useIsGlobeIcon, useLanguageSelector } from 'web/src/modules/i18n/components/LanguageSelector/composables';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LanguageSelector',
    setup (__props) {
        const { availableLanguages, iconGlobeProps, iconCheckProps, onChange } = useLanguageSelector();
        const { isGlobeIcon } = useIsGlobeIcon();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("form", null, [
                _createElementVNode("ul", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(availableLanguages), (language)=>(_openBlock(), _createElementBlock("li", {
                            key: language.code,
                            class: _normalizeClass([
                                "language-selector__item",
                                {
                                    'language-selector__item-active': language.selected
                                }
                            ]),
                            onClick: ($event)=>_unref(onChange)(language)
                        }, [
                            _unref(isGlobeIcon)(language.value) ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                                key: 0,
                                ref_for: true
                            }, _unref(iconGlobeProps)), null, 16)) : (_openBlock(), _createBlock(VFlagIcon, {
                                key: 1,
                                "country-code": language.flagCode,
                                size: _unref(IconSize).SIZE_20,
                                "flag-size": _unref(VCountryFlagSize).SIZE_16
                            }, null, 8, [
                                "country-code",
                                "size",
                                "flag-size"
                            ])),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(language.text), 1),
                            language.selected ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                                _createVNode(_unref(VIcon), _mergeProps({
                                    class: "language-selector__icon-selected",
                                    ref_for: true
                                }, _unref(iconCheckProps)), null, 16)
                            ])) : _createCommentVNode("", true),
                            _createElementVNode("input", {
                                name: "language-selector",
                                type: "radio",
                                value: language.code,
                                checked: language.selected,
                                hidden: ""
                            }, null, 8, _hoisted_5)
                        ], 10, _hoisted_2))), 128))
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LanguageSelector'
                ]
            ]);
        };
    }
});
