import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { Tag } from '@leon-hub/tags';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import { useDefaultTopBarRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DefaultTopBarRouteComponent',
    props: {
        title: {},
        hidePrefix: {
            type: Boolean
        },
        hideSuffix: {
            type: Boolean
        },
        customPrefixIconName: {},
        badge: {},
        badgeKind: {},
        isAvatar: {
            type: Boolean
        },
        onlyEmitEvents: {
            type: Boolean
        },
        type: {},
        isBadgeCounter: {
            type: Boolean
        }
    },
    emits: [
        "back",
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { prefixIconName, suffixIconName, onPrefixClick, close } = useDefaultTopBarRouteComponent(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalWindowHeader, {
                title: _ctx.title,
                "title-tag": _unref(Tag).H1,
                badge: _ctx.badge,
                "badge-kind": _ctx.badgeKind,
                "prefix-icon-name": _unref(prefixIconName),
                "suffix-icon-name": _unref(suffixIconName),
                "is-avatar": _ctx.isAvatar,
                "is-badge-counter": _ctx.isBadgeCounter,
                type: _ctx.type,
                onPrefixClick: _unref(onPrefixClick),
                onSuffixClick: _unref(close)
            }, {
                prefix: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "prefix")
                    ]),
                suffix: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "suffix", {
                            goBackOrClose: _unref(onPrefixClick)
                        })
                    ]),
                title: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "title")
                    ]),
                _: 3
            }, 8, [
                "title",
                "title-tag",
                "badge",
                "badge-kind",
                "prefix-icon-name",
                "suffix-icon-name",
                "is-avatar",
                "is-badge-counter",
                "type",
                "onPrefixClick",
                "onSuffixClick"
            ])), [
                [
                    _directive_auto_id,
                    'DefaultTopBarRouteComponent'
                ]
            ]);
        };
    }
});
