import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineExtendedMarketsSkeleton',
    setup (__props) {
        // empty
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-extended-skeleton'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (index)=>_createElementVNode("div", {
                        key: index,
                        class: _normalizeClass(_ctx.$style['sport-event-extended-skeleton__item'])
                    }, null, 2)), 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineExtendedMarketsSkeleton'
                ]
            ]);
        };
    }
});
