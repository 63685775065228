export default function getSubmitPaymentPayload(param) {
    let { paymentSystemId, schemaId, formParams, action } = param;
    return {
        payload: {
            ts: Date.now(),
            paymentSystemId,
            schemaId,
            formParams
        },
        action
    };
}
