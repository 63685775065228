import { computed } from 'vue';
import { TransactionGroup } from '@leon-hub/api-sdk';
import { defaultDateTimeFormat } from 'web/src/modules/profile/submodules/customer-history/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';
// eslint-disable-next-line max-len
export default function useCustomerHistoryListItemEgs(props) {
    const $formatMoney = useFormatMoney();
    const id = computed(()=>`(ID: ${props.item.objectId})`);
    const date = computed(()=>defaultDateTimeFormat(props.item.date));
    const typeKind = computed(()=>props.item.txTypeKind || '');
    const isCreditPositive = computed(()=>props.item.credit > 0);
    const eventDescription = computed(()=>props.item.txTypeName);
    const debit = computed(()=>{
        if (props.item.objectType === TransactionGroup.LEONSHOP) return props.item.statusName;
        return $formatMoney(props.item.debit || 0, {
            currency: props.currency
        });
    });
    const credit = computed(()=>{
        const baseString = `${$formatMoney(props.item.credit, {
            currency: props.currency
        })}`;
        if (props.item.objectType === TransactionGroup.LEONSHOP) return baseString;
        return `+${baseString}`;
    });
    return {
        id,
        date,
        typeKind,
        isCreditPositive,
        eventDescription,
        debit,
        credit
    };
}
