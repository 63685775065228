import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { getSports } from 'web/src/modules/sportline/utils/api';
import useSportlineApiPending from './useSportlineApiPending';
export function useSportlineApiLoadSportsTree(props) {
    const { tryRequestWithCTag, sportsRestApiFlags, isLiveOutrightsEnabled, isExtraLiveEnabled } = props;
    const sportsApiPendings = useSportlineApiPending();
    function loadSportsTree(param) {
        let { dateTime, silent, betlines } = param;
        const filter = dateTime ?? {
            from: void 0,
            to: void 0
        };
        const { from, to } = filter;
        const pendingFilter = {
            filter,
            betlines: betlines ?? []
        };
        const apiPendingIndex = sportsApiPendings.getApiPendingIndexForFilter(pendingFilter);
        if (apiPendingIndex > -1) return sportsApiPendings.getApiPendingByIndex(apiPendingIndex).request;
        const request = new Promise((resolve, reject)=>{
            tryRequestWithCTag((ctag)=>getSports({
                    ctag,
                    from,
                    to,
                    silent,
                    flags: [
                        ...sportsRestApiFlags.value
                    ],
                    LMCE: isLiveOutrightsEnabled.value,
                    betlines
                })).then((result)=>{
                sportsApiPendings.removeApiPendingForFilter(pendingFilter);
                resolve(result);
                return result;
            }).catch((error)=>{
                sportsApiPendings.removeApiPendingForFilter(pendingFilter);
                reject(error);
            });
        });
        const apiPending = {
            ...pendingFilter,
            request
        };
        sportsApiPendings.addApiPending(apiPending);
        return apiPending.request;
    }
    function loadFullSportsTree(param) {
        let { silent } = param;
        return loadSportsTree({
            silent
        });
    }
    function loadLiveFiltersSportsTree(param) {
        let { silent, dateTime } = param;
        if (isExtraLiveEnabled.value) {
            const betlines = [
                BetlineType.Live,
                BetlineType.Prematch,
                BetlineType.Outright,
                BetlineType.Virtual
            ];
            return loadSportsTree({
                silent,
                dateTime,
                betlines
            });
        }
        return loadSportsTree({
            silent,
            dateTime
        });
    }
    return {
        loadSportsTree,
        loadFullSportsTree,
        loadLiveFiltersSportsTree
    };
}
