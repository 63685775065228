import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { AbstractError } from '@leon-hub/errors';
import ChunkLoadErrorCode from './ChunkLoadErrorCode';
const chunkLoadErrorCode = new ChunkLoadErrorCode();
let ChunkLoadError = class ChunkLoadError extends AbstractError {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getSerializable() {
        return {
            ...super.getSerializable(),
            chunkPath: this.chunkPath
        };
    }
    constructor(options){
        super({
            ...options,
            code: chunkLoadErrorCode
        });
        _define_property(this, "chunkPath", void 0);
        this.chunkPath = options.chunkPath;
    }
};
export { ChunkLoadError as default };
