import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
export function useParseSportlineSettingsRef() {
    return toRef(()=>useSportlineSettingsStore().parseSportlineSettings);
}
export function useIsZeroMarginEnabledRef() {
    return toRef(()=>useSportlineSettingsStore().isZeroMarginEnabled);
}
export function useIsStreamsEnabledRef() {
    return toRef(()=>useSportlineSettingsStore().isLiveStreamEnabled);
}
export function useIsSportDateTimeFiltersEnabledRef() {
    return toRef(()=>useSportlineSettingsStore().isSportFiltersEnabled);
}
export function useIsMarketTypesSelectionEnabledRef() {
    return toRef(()=>useSportlineSettingsStore().isMarketTypesSelectionEnabled);
}
