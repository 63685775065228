import DateTime from 'web/src/utils/DateTime';
// eslint-disable-next-line max-len
export default function mapTimesArrayByDay(availableMeetingSlots) {
    const map = new Map();
    if (!availableMeetingSlots) return map;
    for (const item of availableMeetingSlots){
        const eventDay = DateTime.withTimeStamp(item.startTime).toInputDate();
        const value = map.get(eventDay) ?? [];
        value.push({
            ...item,
            start: DateTime.withTimeStamp(item.startTime).toTime(),
            end: DateTime.withTimeStamp(item.endTime).toTime()
        });
        map.set(eventDay, value);
    }
    return map;
}
