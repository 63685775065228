import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { PieTimerSize } from './enums';
import { useVPieTimer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VPieTimer',
    props: {
        percentage: {
            default: 0
        },
        size: {
            default: PieTimerSize.DEFAULT
        }
    },
    setup (__props) {
        const props = __props;
        const { timerWrapperClass, circleClass } = useVPieTimer(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass([
                    _unref(timerWrapperClass),
                    "timer__wrapper"
                ]),
                viewBox: "0 0 20 20"
            }, [
                _createElementVNode("circle", {
                    class: _normalizeClass(_unref(circleClass)),
                    style: _normalizeStyle({
                        'stroke-dasharray': 31.4 * _ctx.percentage / 100 + ' 31.4'
                    }),
                    r: "5",
                    cx: "10",
                    cy: "10",
                    transform: "rotate(-90) translate(-20)"
                }, null, 6)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VPieTimer'
                ]
            ]);
        };
    }
});
