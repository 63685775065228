import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import TheSidebarMenu from 'web/src/components/SidebarMenu/TheSidebarMenu/TheSidebarMenu.vue';
import PartnersMenuComponent from 'web/src/modules/sidebar/views/PartnersMenuComponent/PartnersMenuComponent.vue';
import { useSidebarMenuKnowHowRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SidebarMenuKnowHowRouteComponent',
    setup (__props) {
        const { menuBootstrap, menuGeneric } = useSidebarMenuKnowHowRouteComponent();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createVNode(TheSidebarMenu, {
                    menu: _unref(menuBootstrap)
                }, null, 8, [
                    "menu"
                ]),
                _createVNode(TheSidebarMenu, {
                    menu: _unref(menuGeneric)
                }, null, 8, [
                    "menu"
                ]),
                _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'SidebarMenuKnowHowRouteComponent'
                ]
            ]);
        };
    }
});
