import { Deferred, delay } from '@leon-hub/utils';
import { isTelegramInAppBrowser } from './isTelegramInAppBrowser';
import { getFrameWindow } from './getFrameWindow';
// eslint-disable-next-line sonarjs/cognitive-complexity
async function getListeningFrameWindow(iframe) {
    if (await isTelegramInAppBrowser()) await delay(1000);
    let { contentWindow } = iframe;
    if (!contentWindow) {
        let resolved = false;
        let interval;
        contentWindow = await Promise.race([
            new Promise((resolve)=>{
                interval = setInterval(()=>{
                    if (!resolved && iframe.contentWindow) resolve(iframe.contentWindow);
                }, 100);
            }),
            new Promise((resolve)=>{
                iframe.addEventListener('load', ()=>{
                    if (!resolved) resolve(getFrameWindow(iframe));
                });
            })
        ]).finally(()=>{
            resolved = true;
            if (interval) clearInterval(interval);
        });
    }
    const { addEventListener, removeEventListener } = contentWindow;
    await new Promise((resolve)=>{
        function onMessage(param) {
            let { data } = param;
            if ('listening' === data) {
                removeEventListener('message', onMessage);
                resolve();
            }
        }
        addEventListener('message', onMessage);
    });
    return contentWindow;
}
// eslint-disable-next-line
export async function connectWindow(iframe, options) {
    const deferred = new Deferred();
    const contentWindow = await getListeningFrameWindow(iframe);
    const { addEventListener, removeEventListener } = contentWindow;
    addEventListener('message', function onMessage(param) {
        let { data } = param;
        if ('connected' === data) {
            removeEventListener('message', onMessage);
            deferred.resolve();
        }
    });
    contentWindow.postMessage('connect', '*');
    return deferred.promise;
}
