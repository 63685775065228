import { toRef, computed } from 'vue';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useSportSegmentsSettings } from 'web/src/modules/sportline/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { KickoffPeriodUtils } from 'web/src/modules/sportline/utils';
import useSegmentStoreFilterComposable from './useSegmentStoreFilterComposable';
import useSegmentStoreLiveComposable from './useSegmentStoreLiveComposable';
import useSegmentStoreComingComposable from './useSegmentStoreComingComposable';
import useSegmentStoreFavoritesComposable from './useSegmentStoreFavoritesComposable';
import { useSegmentStoreResponseMapping } from './useSegmentStoreResponseMapping';
// @TODO remove export raw data after remove vuex store deps
export default function useSegmentStoreComposable(props) {
    const { sportSegmentsSettingsId, allLiveTabUpdateInterval, selectedLiveTabUpdateInterval, comingUpdateInterval } = props;
    const settingsStore = useSportlineSettingsStore();
    const { onInit: globalSportlineEventsOnInit } = useGlobalSportlineEvents();
    const sportSegmentsSettings = useSportSegmentsSettings(sportSegmentsSettingsId);
    const isMarketTypesSelectionEnabled = toRef(settingsStore, 'isMarketTypesSelectionEnabled');
    const isFilterTransitionFadeEffectEnabled = computed(()=>settingsStore.isFilterTransitionFadeEffectEnabled);
    const filterTransitionDuration = computed(()=>settingsStore.filterTransitionDuration);
    const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');
    const allowedSportFamily = toRef(settingsStore, 'allowedSportFamily');
    const doUseRelativeTimeForSportline = toRef(settingsStore, 'doUseRelativeTimeForSportline');
    const isPageEnabled = computed(()=>sportSegmentsSettings.value.isPageEnabled);
    const comingEventsKickoffPeriod = computed(()=>{
        const comingEventsKickoffFilter = sportSegmentsSettings.value.comingKickoffFilter;
        return doUseRelativeTimeForSportline.value ? KickoffPeriodUtils.optionalRelativeDateTimeRangeInMinutes(comingEventsKickoffFilter) : KickoffPeriodUtils.dateTimeRangeFromKickoffPeriod(comingEventsKickoffFilter);
    });
    const { isReady: filterIsReady, selectedFilter, fullSportsList, rawFullSportsListResponse, filterOptionsIds, activeSportElement, activeRegionElement, setSelectedFilter, initialRequests: filtersInitialRequests, onInit: filtersOnInit, syncBackgroundRequests: filtersSyncBackgroundRequests, setBackgroundUpdateEnabled: filtersSetBackgroundUpdateEnabled } = useSegmentStoreFilterComposable({
        allLiveTabUpdateInterval,
        selectedLiveTabUpdateInterval,
        parseSportlineSettings,
        sportSegmentsSettings,
        comingEventsKickoffPeriod
    });
    const { isReady: liveIsReady, rawLiveEventsResponse, onInit: liveOnInit, initialRequests: liveInitialRequests, syncBackgroundRequests: liveSyncBackgroundRequests, setBackgroundUpdateEnabled: liveSetBackgroundUpdateEnabled } = useSegmentStoreLiveComposable({
        allLiveTabUpdateInterval,
        selectedLiveTabUpdateInterval,
        sportSegmentsSettings,
        filterOptionsIds,
        activeSportElement,
        activeRegionElement
    });
    const { rawComingEventsResponse, onInit: comingOnInit, initialRequests: comingInitialRequests, syncBackgroundRequests: comingSyncBackgroundRequests, setBackgroundUpdateEnabled: comingSetBackgroundUpdateEnabled } = useSegmentStoreComingComposable({
        comingUpdateInterval,
        parseSportlineSettings,
        sportSegmentsSettings,
        doUseRelativeTimeForSportline,
        comingEventsKickoffPeriod
    });
    const { comingResponseWithoutLive, fullEventsResponse, liveEventsSportsMap, comingEventsSportsMap, compiledComingSports, liveDisciplinesMap, comingDisciplinesMap, compiledComingDisciplinesList, totalCount } = useSegmentStoreResponseMapping({
        rawLiveEventsResponse,
        rawComingEventsResponse,
        parseSportlineSettings,
        sportSegmentsSettings
    });
    const isReady = computed(()=>liveIsReady.value && filterIsReady.value);
    const { hasFavoritesOptimistic, favoriteSelectedRegionFamily, favoriteEventsFullList, favoriteEventsListForDisplay, customerOptimisticFavoriteSportEventsIds, customerOptimisticFavoriteLeaguesIds, onInit: onFavoriteInit, initialRequests: initialFavoriteRequests, setFavoriteSelectedRegionFamily, setBackgroundUpdateEnabled: setFavoritesBackgroundUpdateEnabled } = useSegmentStoreFavoritesComposable({
        filterEventsListBySegmentId: props.filterFavoritesEventsListBySegmentId,
        backgroundFavoritesId: props.backgroundFavoritesId,
        filterEventsListBySportlineType: props.filterFavoritesEventsListBySportlineType,
        eventsResponseForOptimisticCheck: computed(()=>{
            if (props.filterFavoritesEventsListBySportlineType === SportlineType.Live) return rawLiveEventsResponse.value;
            if (props.filterFavoritesEventsListBySportlineType === SportlineType.Prematch) return comingResponseWithoutLive.value;
            return fullEventsResponse.value;
        })
    });
    async function initialRequests() {
        const coreRequests = Promise.allSettled([
            liveInitialRequests(),
            comingInitialRequests(),
            filtersInitialRequests()
        ]);
        // do not await favorites
        initialFavoriteRequests();
        await coreRequests;
    }
    function syncBackgroundRequests() {
        liveSyncBackgroundRequests({
            silent: true
        });
        comingSyncBackgroundRequests({
            silent: true
        });
        filtersSyncBackgroundRequests({
            silent: true
        });
    }
    function setBackgroundUpdateEnabled(value) {
        liveSetBackgroundUpdateEnabled(value);
        comingSetBackgroundUpdateEnabled(value);
        filtersSetBackgroundUpdateEnabled(value);
        setFavoritesBackgroundUpdateEnabled(value);
    }
    // on init
    globalSportlineEventsOnInit();
    liveOnInit();
    comingOnInit();
    filtersOnInit();
    onFavoriteInit();
    return {
        isPageEnabled,
        isReady,
        filterIsReady,
        totalCount,
        isMarketTypesSelectionEnabled,
        isFilterTransitionFadeEffectEnabled,
        filterTransitionDuration,
        activeSportElement,
        activeRegionElement,
        selectedFilter,
        allowedSportFamily,
        rawLiveEventsResponse,
        rawComingEventsResponse,
        rawFullSportsListResponse,
        fullEventsResponse,
        fullSportsList,
        comingResponseWithoutLive,
        parseSportlineSettings,
        sportSegmentsSettings,
        hasFavoritesOptimistic,
        favoriteSelectedRegionFamily,
        favoriteEventsFullList,
        favoriteEventsListForDisplay,
        customerOptimisticFavoriteSportEventsIds,
        customerOptimisticFavoriteLeaguesIds,
        liveEventsSportsMap,
        comingEventsSportsMap,
        compiledComingSports,
        liveDisciplinesMap,
        comingDisciplinesMap,
        compiledComingDisciplinesList,
        setSelectedFilter,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled,
        setFavoriteSelectedRegionFamily
    };
}
