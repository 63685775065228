import { computed, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useDowntimeAlertStore } from 'web/src/modules/downtime/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
export default function useDowntimeAlert() {
    const downtimeStore = useDowntimeAlertStore();
    const { $translate } = useI18n();
    const downtimeStart = toRef(downtimeStore, 'downtimeStart');
    const downtimeFinish = toRef(downtimeStore, 'downtimeFinish');
    const { setIsShown, clearTimer } = downtimeStore;
    const formattedStart = computed(()=>downtimeStart.value ? DateTime.formatTimeStamp(downtimeStart.value, TimeFormats.dayMonthYearHoursMinutes) : '');
    const formattedFinish = computed(()=>downtimeFinish.value ? DateTime.formatTimeStamp(downtimeFinish.value, TimeFormats.dayMonthYearHoursMinutes) : '');
    const textParameters = computed(()=>({
            START: formattedStart.value,
            END: formattedFinish.value
        }));
    const alertText = $translate('WEB2_START_ALERT_UPDATE', textParameters);
    const snackbarProperties = computed(()=>({
            type: 'warning',
            title: $translate('WEB2_MODALTITLE_ATTENTION').value,
            text: alertText.value,
            iconName: IconName.WARNING,
            hasClose: true
        }));
    return {
        snackbarProperties,
        setIsShown,
        clearTimer
    };
}
