import { ref, computed } from 'vue';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { getBetlineLeagueStatistics } from 'web/src/modules/sportline/utils/api';
import { isLeagueMatchesStatisticEmpty, isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';
export function useLeagueMatchesStatisticStoreComposable(props) {
    const { isEnabled, expectedId } = props;
    const { isLoading, isExpectedIdLoaded, setIsLoading, setLastLoadedId } = useExpectedIdLoading({
        expectedId
    });
    const leagueMatchesStatistic = ref(null);
    const leagueMatchesResults = ref(null);
    const isReady = computed(()=>!isEnabled.value || isExpectedIdLoaded.value);
    const isEmpty = computed(()=>null === leagueMatchesStatistic.value && null === leagueMatchesResults.value);
    function clear() {
        leagueMatchesStatistic.value = null;
        leagueMatchesResults.value = null;
    }
    async function fetchLeagueMatchesStatistic(param) {
        let { silent } = param;
        if (!isEnabled.value) return;
        const leagueId = expectedId.value;
        if (!leagueId) return;
        setIsLoading(true);
        try {
            const response = await getBetlineLeagueStatistics({
                leagueId
            }, {
                silent
            });
            const { average, sum, overallGoals, overallResults } = response || {};
            const statistic = {
                average,
                sum
            };
            const results = {
                overallGoals,
                overallResults
            };
            leagueMatchesStatistic.value = isLeagueMatchesStatisticEmpty(statistic) ? null : statistic;
            leagueMatchesResults.value = isLeagueMatchesStatisticEmpty(results) ? null : results;
        } catch (error) {
            clear();
            if (!isStatisticNotFoundError(error)) throw error;
        } finally{
            setIsLoading(false);
            setLastLoadedId(leagueId);
        }
    }
    return {
        isLoading,
        isReady,
        isEmpty,
        leagueMatchesStatistic,
        leagueMatchesResults,
        fetchLeagueMatchesStatistic,
        clear
    };
}
