import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "sidebar-caption"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'SidebarCaption',
    props: {
        title: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
            ])), [
                [
                    _directive_auto_id,
                    'SidebarCaption'
                ]
            ]);
        };
    }
});
