export const defaultMappingKeys = {
    default: 'defaultValue',
    disabled: 'disabled',
    title: 'title',
    hidden: 'hidden',
    widget: 'widget',
    labels: 'labels',
    options: 'options',
    hintMessage: 'hintMessage'
};
