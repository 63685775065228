import { computed, toRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useDesktopModalStore } from 'web/src/modules/core/store';
import { usePendingBetsStore } from 'web/src/modules/slip/submodules/pending-bets/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCustomerHistoryDetailsScoreBoard } from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsScoreBoard/composables';
import { useCashoutStore } from '../store';
import { BetCashoutMode } from '../enums';
export default function useCashoutModal(emit, props) {
    const cashoutStore = useCashoutStore();
    const { updatePendingBetsOnCashout } = usePendingBetsStore();
    const desktopModalStore = useDesktopModalStore();
    const { $translate } = useI18n();
    const { betKind, competitors, isSingle, isExpress } = useCustomerHistoryDetailsScoreBoard({
        transaction: props.transaction
    });
    const { coreComponentProps, currentBetId, mode } = storeToRefs(cashoutStore);
    const hasDesktopModal = toRef(desktopModalStore, 'hasDesktopModal');
    const isProfileLayout = toRef(desktopModalStore, 'isProfileLayout');
    const betPrice = computed(()=>mode.value === BetCashoutMode.SUCCESS ? 0 : Number(props.transaction?.debit));
    const betName = computed(()=>{
        if (!isSingle.value) return `${isExpress.value ? $translate('WEB2_TRANSACTION_HISTORY_EXPRESS_BET').value : $translate('WEB2_TRANSACTION_HISTORY_SYSTEM_BET').value} ${betKind.value}`;
        return competitors.value;
    });
    const resolveCashout = ()=>{
        updatePendingBetsOnCashout({
            betId: currentBetId.value,
            result: mode.value
        });
    };
    const emitCancel = ()=>{
        emit('cancel');
    };
    const emitConfirm = ()=>{
        emit('confirm');
    };
    const mainButtonClick = ()=>{
        if (mode.value === BetCashoutMode.SUCCESS) {
            resolveCashout();
            emitConfirm();
        } else cashoutStore.doCashout();
    };
    const cancelClick = ()=>{
        if (mode.value === BetCashoutMode.ERROR) resolveCashout();
        emitCancel();
    };
    return {
        coreComponentProps,
        hasDesktopModal,
        isProfileLayout,
        betPrice,
        betName,
        mainButtonClick,
        cancelClick,
        closeModal: emitCancel
    };
}
