import { ref, watch, computed } from 'vue';
import { defineStore } from 'pinia';
import mergeSeoMetaParameters from 'web/src/modules/seo/utils/mergeSeoMetaParameters';
import { useSpintaxSeoMetaData } from './composables/useSpintaxSeoMetaData';
const useSeoMetaStorage = defineStore('seo-meta-storage', ()=>{
    const { metaData: spintaxMetaData, metaInfo: spintaxMetaInfo, updateActualMetaData: updateSpintaxMetaData } = useSpintaxSeoMetaData();
    const refsToComponentsData = ref({});
    const stopWatch = {};
    const metaParameters = computed(()=>{
        let result = {};
        for (const key of Object.keys(refsToComponentsData.value))result = mergeSeoMetaParameters(result, refsToComponentsData.value[key] || {}, ()=>{});
        return result;
    });
    /**
   * @TODO check components data after destroy component. Seems it couldn't clear because unref is not trigger watch effect
   */ function setMetaParameter(uid, config) {
        const UID = String(uid);
        if (!config) {
            refsToComponentsData.value[UID] = {};
            stopWatch[UID]?.();
            delete stopWatch[UID];
            delete refsToComponentsData.value[UID];
            return;
        }
        if (stopWatch[UID]) stopWatch[UID]();
        stopWatch[UID] = watch(config, (newValue)=>{
            refsToComponentsData.value = {
                ...refsToComponentsData.value,
                [UID]: newValue
            };
        }, {
            immediate: true,
            flush: 'sync'
        });
    }
    return {
        spintaxMetaData,
        spintaxMetaInfo,
        metaParameters,
        setMetaParameter,
        updateSpintaxMetaData
    };
});
export default useSeoMetaStorage;
