import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
export function prepareNavigationBetweenPagesSportsFilter(filter) {
    // @TODO check selected sportline type
    const { activeKickoffPeriod, customDateFrom, customDateTo } = filter;
    return activeKickoffPeriod === SportFilterKickoffPeriod.CUSTOM ? {
        activeKickoffPeriod,
        customDateFrom,
        customDateTo
    } : {
        activeKickoffPeriod
    };
}
