import { isArray, isObject, isString, isUndefined } from '@leon-hub/guards';
import { isFile } from '@leon-hub/utils';
import { isJsonSchema } from '@leon-hub/form-utils';
import { logger } from '@leon-hub/logging';
import mapToObject from 'web/src/utils/map/mapToObject';
import { FormFieldTouchedStrategy } from 'web/src/components/Form/enums';
import { mergeArray, mergeObject, mergeString } from 'web/src/components/Form/utils/merge';
import { isPartialFormPhoneValue } from 'web/src/components/Form/guards';
import { isFormTypeArray, isFormTypeObject } from './formSchemaUtils';
export const mergeFormDataMap = (dataMap, dataPath, value)=>{
    const pathFragments = dataPath.split('/');
    const pathFragmentsLength = pathFragments.length;
    if (pathFragmentsLength > 2) logger.error('Form: error in margeFormDataMap, unexpected path length', {
        dataPath
    });
    if (1 === pathFragments.length) return mergeString(pathFragments, dataMap, value);
    if (Number.isNaN(parseInt(pathFragments[pathFragmentsLength - 1], 10))) return mergeObject(pathFragments, dataMap, value);
    return mergeArray(pathFragments, dataMap, value);
};
/** provides default values for object/array type */ export function getDefaultEmptyObject(schema) {
    const { properties = {} } = schema;
    return Object.keys(properties).reduce((result, key)=>{
        const schemaField = properties[key];
        if (!isJsonSchema(schemaField)) return result;
        if (isFormTypeObject(schemaField) && 'properties' in schemaField) result.set(key, {});
        else if (isFormTypeArray(schemaField)) result.set(key, []);
        return result;
    }, new Map());
}
export const getUiSchemaFieldDefaultValue = (uiSchema)=>{
    if (uiSchema && 'default' in uiSchema) return uiSchema.default;
};
export const getDefaultEnumValue = (property)=>{
    const schemaEnum = property.enum;
    if (isArray(schemaEnum) && schemaEnum.length) {
        const value = schemaEnum[0];
        isString(value);
        return value;
    }
    return null;
};
export const isEmptyDefaultValue = (param)=>{
    let { value, name, uiSchema } = param;
    if (isUndefined(value)) return false;
    const field = uiSchema?.fields?.[name];
    if (!field) return false;
    const defaultValue = getUiSchemaFieldDefaultValue(field);
    return '' === value && '' === defaultValue;
};
// for getInitialFormDataMap
export const addObjectValueToDataMap = (target, fieldName, objectValue)=>{
    isPartialFormPhoneValue(objectValue);
    // only phone is currently existing object value
    const keys = Object.keys(objectValue);
    for (const key of keys){
        const value = objectValue[key];
        if (value) {
            // not empty string
            isString(value);
            target.set(`${fieldName}/${key}`, value);
        }
    }
    return target;
};
const getDefaultPrimitiveValue = function(schemaProperty, uiSchemaProperty) {
    let isRequired = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    const defaultFromUiSchema = getUiSchemaFieldDefaultValue(uiSchemaProperty);
    if (defaultFromUiSchema) return defaultFromUiSchema;
    /**
   * there is no check for widget type, coz it can be select-like widgets. Another fields do not have enum list values, so its safe enough
   * */ // LEONWEB-6703
    const isUnlabeledSelect = uiSchemaProperty && !uiSchemaProperty?.title;
    // LEONWEB-13642
    const requiredSingleValueSelect = isRequired && schemaProperty.enum && schemaProperty.enum?.length === 1;
    if (isUnlabeledSelect || requiredSingleValueSelect) return getDefaultEnumValue(schemaProperty);
};
const getDefaultEnumForObject = (properties)=>{
    const keys = Object.keys(properties);
    const result = {};
    for (const key of keys){
        isObject(properties);
        const childSchema = properties[key];
        if (childSchema) {
            const relatedEnum = getDefaultEnumValue(childSchema);
            if (relatedEnum) result[key] = relatedEnum;
        }
    }
    return result;
};
const getDefaultValue = function(schemaProperty, uiSchemaProperty) {
    let isRequired = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    if (isFormTypeObject(schemaProperty)) {
        const defaultUiValue = getUiSchemaFieldDefaultValue(uiSchemaProperty);
        const defaultEnumValue = isObject(schemaProperty.properties) ? getDefaultEnumForObject(schemaProperty.properties) : {};
        const combinedValue = {
            ...defaultEnumValue,
            ...isObject(defaultUiValue) ? defaultUiValue : {}
        };
        if (Object.keys(combinedValue).length) return combinedValue;
        return isObject(schemaProperty.properties) ? {} : void 0;
    }
    return getDefaultPrimitiveValue(schemaProperty, uiSchemaProperty, isRequired);
};
// for getInitialFormDataMap
export const addArrayValueToDataMap = (target, fieldName, arrayValue)=>{
    arrayValue.forEach((value, index)=>{
        target.set(`${fieldName}/${index}`, value);
    });
    return target;
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export const getInitialFormDataMap = (schema, uiSchema)=>{
    const { properties } = schema;
    isObject(properties);
    const { fields } = uiSchema;
    if (!fields) return new Map();
    const propertyKeys = new Set(Object.keys(properties));
    const requiredFields = isArray(schema.required) ? schema.required : [];
    let result = new Map();
    for (const key of propertyKeys){
        const currentProperty = properties[key] || {};
        const currentField = fields[key];
        const isRequired = requiredFields.includes(key);
        if (currentField) {
            const defaultValue = getDefaultValue(currentProperty, currentField, isRequired);
            if (isArray(defaultValue)) {
                defaultValue.every(isString || isFile);
                result = addArrayValueToDataMap(result, key, defaultValue);
            } else if (isObject(defaultValue)) result = addObjectValueToDataMap(result, key, defaultValue);
            else if (defaultValue) result.set(key, defaultValue);
        }
    }
    return result;
};
// also knows as getFormData in original
export function getFormDataObject(formDataMap, schema) {
    const keys = Object.keys(Object.fromEntries(formDataMap));
    const newFormData = keys.reduce((result, key)=>{
        // eslint-disable-next-line no-param-reassign
        result = mergeFormDataMap(result, key, formDataMap.get(key));
        return result;
    }, new Map());
    const mergedMap = new Map([
        ...Array.from(getDefaultEmptyObject(schema)),
        ...Array.from(newFormData)
    ]);
    return mapToObject(mergedMap);
}
export function getDefaultTouchedStrategy() {
    return FormFieldTouchedStrategy.Blur;
}
export const getAllFieldNames = (schema)=>{
    const rootProperties = schema.properties || {};
    const result = new Set();
    const propertiesList = Object.keys(rootProperties);
    for (const propertyName of propertiesList){
        const relatedProperties = rootProperties[propertyName];
        const childProperties = relatedProperties.properties;
        if (isFormTypeObject(relatedProperties) && childProperties) {
            const childKeys = Object.keys(childProperties);
            for (const childKey of childKeys)result.add(`${propertyName}/${childKey}`);
        } else result.add(propertyName);
    }
    return result;
};
export const getTouchedStrategyMap = (uiSchema)=>{
    const { fields = {} } = uiSchema;
    const result = new Map();
    const keys = Object.keys(fields);
    for (const key of keys)result.set(key, fields[key].touchedStrategy ?? getDefaultTouchedStrategy());
    return result;
};
export const getTouchedStrategy = (dataPath, touchedStrategyMap)=>{
    const rootField = dataPath.split('/')[0];
    return touchedStrategyMap.get(rootField) ?? getDefaultTouchedStrategy();
};
// copy from CustomFormInternal
// eslint-disable-next-line sonarjs/cognitive-complexity
export function updateFormDataDefaultValues(payload) {
    const { fieldNames, updatedDefaults, currentFormData, initialFormData, objectTypeFields } = payload;
    const updatedData = new Map();
    const setUpdatedData = (field)=>{
        const currentValue = currentFormData.get(field);
        const initialValue = initialFormData.get(field);
        const updatedDefaultValue = updatedDefaults.get(field);
        // can't relay on touched here
        const wasEdited = currentFormData.has(field) && currentValue !== initialValue;
        const valueToSet = wasEdited ? currentValue : updatedDefaultValue;
        if (isUndefined(valueToSet)) updatedData.delete(field);
        else updatedData.set(field, valueToSet);
    };
    for (const field of fieldNames)if (currentFormData.has(field) || updatedDefaults.has(field)) setUpdatedData(field);
    // File fields
    for (const field of currentFormData.keys())if (field.includes('/') && objectTypeFields.includes(field)) {
        const fieldName = field.split('/')[0];
        if (fieldNames.has(fieldName)) setUpdatedData(field);
    }
    return updatedData;
}
