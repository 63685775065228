import { toValue } from 'vue';
import { useSportlinePersistFilterStore } from '../store';
import { useResetPersistLocationSettings } from '../store/composables';
/** Force reset saved location */ /** Do reset location only for web2.sportline.family.filter.reset.status */ export function useResetPersistLocation() {
    const { isResetLocationEnabled } = useResetPersistLocationSettings();
    const sportlinePersistFilterStore = useSportlinePersistFilterStore();
    const { resetPersistLocation } = sportlinePersistFilterStore;
    function forcePersistLocationReset(key) {
        resetPersistLocation(toValue(key));
    }
    function handlePersistLocationReset(key) {
        if (!isResetLocationEnabled.value) return;
        resetPersistLocation(toValue(key));
    }
    return {
        forcePersistLocationReset,
        handlePersistLocationReset
    };
}
