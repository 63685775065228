import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VList from 'web/src/components/List/VList/VList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccessOptionsList',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['access-options-list'])
            }, [
                _createVNode(VList, null, {
                    default: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                    _: 3
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'AccessOptionsList'
                ]
            ]);
        };
    }
});
