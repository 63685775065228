import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export function useMoreCaptionLabel(props) {
    const { totalCount, primaryCount } = props;
    const { $translate } = useI18n();
    const baseCaptionLabel = $translate('WEB2_SPORT_EXT_BETS_COUNT');
    const secondaryCount = computed(()=>totalCount.value - (primaryCount?.value ?? 0));
    const moreCaption = computed(()=>{
        if (secondaryCount.value <= 0) return '';
        if (primaryCount?.value) return `+${secondaryCount.value}`;
        return `${baseCaptionLabel.value} +${totalCount.value}`;
    });
    return {
        moreCaption
    };
}
