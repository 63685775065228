import { computed } from 'vue';
import { useCustomerFavoritesService, useSportlineFavoritesStoreComposable } from 'web/src/modules/sportline/submodules/favorites/composables';
import { filterSportsListEvents } from 'web/src/modules/sportline/utils';
import { SportEventsResponseChangeUtils } from 'web/src/modules/sportline/utils/rest';
export default function useSegmentStoreFavoritesComposable(props) {
    const { backgroundFavoritesId, filterEventsListBySegmentId, filterEventsListBySportlineType } = props;
    const { customerOptimisticFavoriteSportEventsIds, customerOptimisticFavoriteLeaguesIds } = useCustomerFavoritesService();
    const { favoriteEventsFullList, favoriteSelectedRegionFamily, favoriteEventsListForDisplay, onInit, initialRequests, setBackgroundUpdateEnabled, setFavoriteSelectedRegionFamily } = useSportlineFavoritesStoreComposable({
        backgroundFavoritesId,
        prepareEventsList (fullList) {
            if (void 0 !== filterEventsListBySegmentId) return fullList.filter((element)=>element.sport.segment.id === filterEventsListBySegmentId);
            if (void 0 !== filterEventsListBySportlineType) return filterSportsListEvents(fullList, (item)=>item.sportEvent.type === filterEventsListBySportlineType);
            return fullList;
        }
    });
    const hasFavoritesOptimistic = computed(()=>{
        const eventsIds = customerOptimisticFavoriteSportEventsIds.value;
        const leaguesIds = customerOptimisticFavoriteLeaguesIds.value;
        return SportEventsResponseChangeUtils.isSomeIdsInList(props.eventsResponseForOptimisticCheck.value, {
            eventsIds,
            leaguesIds
        });
    });
    return {
        hasFavoritesOptimistic,
        favoriteSelectedRegionFamily,
        favoriteEventsFullList,
        favoriteEventsListForDisplay,
        customerOptimisticFavoriteSportEventsIds,
        customerOptimisticFavoriteLeaguesIds,
        onInit,
        initialRequests,
        setFavoriteSelectedRegionFamily,
        setBackgroundUpdateEnabled
    };
}
