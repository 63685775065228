import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VSpinner, VSpinnerSize, VSpinnerColor } from '@components/spinner';
import { VLoaderMargin } from './enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLoader',
    props: {
        size: {
            default: VSpinnerSize.DEFAULT
        },
        color: {
            default: VSpinnerColor.DEFAULT
        },
        title: {
            default: ''
        },
        hint: {
            default: ''
        },
        margin: {
            default: VLoaderMargin.NONE
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['loader']]: true,
                    [_ctx.$style['loader--with-hint']]: !!_ctx.hint,
                    [_ctx.$style[`loader--margin-${_ctx.margin}`]]: _ctx.margin !== _unref(VLoaderMargin).NONE
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['loader__spinner']]: true,
                        [_ctx.$style[`loader__spinner--size-${_ctx.size}`]]: _ctx.size !== _unref(VSpinnerSize).DEFAULT
                    })
                }, [
                    _createVNode(_unref(VSpinner), {
                        size: _ctx.size,
                        color: _ctx.color,
                        class: _normalizeClass(_ctx.$style['loader__svg'])
                    }, null, 8, [
                        "size",
                        "color",
                        "class"
                    ])
                ], 2),
                _ctx.title ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    "data-test-id": "title",
                    class: _normalizeClass(_ctx.$style['loader__title'])
                }, _toDisplayString(_ctx.title), 3)) : _createCommentVNode("", true),
                _ctx.hint ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    "data-test-id": "hint",
                    class: _normalizeClass({
                        [_ctx.$style['loader__hint']]: true,
                        [_ctx.$style['loader__hint--grey']]: !!_ctx.title
                    })
                }, _toDisplayString(_ctx.hint), 3)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VLoader'
                ]
            ]);
        };
    }
});
