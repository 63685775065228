import { computed, onBeforeMount, onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { useSearchSportlineStore } from 'web/src/modules/search/submodules/sportline/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { SearchType } from 'web/src/modules/search/submodules/sportline/enums';
export default function useSearchSportlineContent(emits) {
    let isEventClicked = false;
    let isInputTouched = false;
    const { $translate } = useI18n();
    const searchSportlineStore = useSearchSportlineStore();
    const siteConfigStore = useSiteConfigStore();
    const router = useRouter();
    const liveEventsRef = toRef(searchSportlineStore, 'liveEvents');
    const prematchEventsRef = toRef(searchSportlineStore, 'prematchEvents');
    const isSearchInProgressRef = toRef(searchSportlineStore, 'isSearchInProgress');
    const searchTextRef = toRef(searchSportlineStore, 'searchText');
    const isNoSearchResultsRef = toRef(searchSportlineStore, 'isNoSearchResults');
    const isSearchAvailableRef = toRef(searchSportlineStore, 'isSearchAvailable');
    const popularSearchQueriesRef = toRef(siteConfigStore, 'popularSearchQueries');
    const liveEvents = computed(()=>liveEventsRef.value ?? []);
    const prematchEvents = computed(()=>prematchEventsRef.value ?? []);
    const isLoading = computed(()=>isSearchInProgressRef.value);
    const searchText = computed(()=>searchTextRef.value);
    const isNoSearchResults = computed(()=>isNoSearchResultsRef.value);
    const searchTags = computed(()=>popularSearchQueriesRef.value);
    const isNoResultsBlockVisible = computed(()=>!isLoading.value && (!isSearchAvailableRef.value || isNoSearchResults.value));
    const isTagsVisible = computed(()=>isNoResultsBlockVisible.value && searchTags.value.length > 0);
    const hasSearchResults = computed(()=>!isLoading.value && !isNoSearchResults.value);
    const noResultsText = computed(()=>isSearchAvailableRef.value ? $translate('JAVA_SEARCH_NORESULTS').value : $translate('WEB2_SEARCH_BY').value);
    onMounted(()=>emitSearchText(searchText.value));
    onBeforeMount(()=>{
        if (!isEventClicked) {
            if (isInputTouched) searchSportlineStore.sendMetrics(false);
            searchSportlineStore.setSearchText('');
        }
    });
    function onTagClick(value) {
        onInput(value, SearchType.POPULAR);
        emitFocus();
    }
    function onEventClick(event) {
        isEventClicked = true;
        emitItemClick();
        searchSportlineStore.sendMetrics(true);
        router.push({
            name: RouteName.SPORT_EVENT_DETAILS,
            params: {
                ...event.navigationParameters
            }
        });
    }
    function onInput(value) {
        let searchType = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : SearchType.MANUAL;
        isInputTouched = true;
        emitSearchText(value);
        searchSportlineStore.setSearchType(searchType);
        searchSportlineStore.setSearchText(value);
    }
    function onInputClear() {
        isInputTouched = false;
        searchSportlineStore.sendMetrics(false);
        onInput('');
    }
    function emitFocus() {
        emits('focus');
    }
    function emitSearchText(value) {
        emits('search-text', value);
    }
    function emitItemClick() {
        emits('item-click');
    }
    return {
        isNoResultsBlockVisible,
        noResultsText,
        isTagsVisible,
        searchTags,
        onTagClick,
        isLoading,
        hasSearchResults,
        liveEvents,
        prematchEvents,
        searchText,
        onEventClick,
        onInputClear,
        onInput
    };
}
