import { computed } from 'vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { getButtonPropsForAvatar, getClassNamesForAvatar } from './utils';
export default function useVAvatar(props) {
    const buttonProp = computed(()=>getButtonPropsForAvatar(props));
    const baseClasses = computed(()=>getClassNamesForAvatar(props));
    const buttonTransparent = {
        kind: ButtonKind.TRANSPARENT
    };
    return {
        buttonProp,
        baseClasses,
        buttonTransparent
    };
}
