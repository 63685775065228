import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useConnectionError() {
    const { $translate } = useI18n();
    const errorProps = computed(()=>({
            title: $translate('WEB2_CONNECTION_ERROR_CAPTION').value,
            text: $translate('WEB2_CONNECTION_ERROR_DESCRIPTION').value,
            iconName: IconName.CONNECTION_LOST,
            isEmailAsLabel: true
        }));
    return {
        errorProps
    };
}
