import { computed } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useCasinoLoyaltyProgramCard(props, emits) {
    const analytics = useAnalytics();
    const isInactive = computed(()=>props.levelStatus !== CasinoLoyaltyProgramLevelStatus.SUCCESS);
    function clickCard() {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                loyaltyProgram: {
                    carouselLevelCard: props.title
                }
            }
        });
        emits('click');
    }
    return {
        isInactive,
        clickCard
    };
}
