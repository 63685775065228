import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import isCountriesData from './guards/isCountriesData';
const useCountryStore = defineStore('country', ()=>{
    // State
    const countriesData = ref([
        {
            code: "MT",
            name: '',
            phonePrefix: Number("356"),
            popular: false
        }
    ]);
    const countryCode = ref("MT");
    const geoIpCountryCode = ref("MT");
    // Getters
    const computedCountryCode = computed(()=>{
        const countryData = countriesData.value.find((country)=>country.code === countryCode.value);
        if (void 0 === countryData) return countriesData.value[0].code;
        return countryCode.value;
    });
    const currentCustomerCountryData = computed(()=>{
        const countryData = countriesData.value.find((country)=>country.code === countryCode.value);
        if (void 0 === countryData) return countriesData.value[0];
        return countryData;
    });
    // Actions/Mutations
    const setInitialCountryModuleData = (initialData)=>{
        isCountriesData(initialData.countriesData);
        initialData.countryCode.length;
        countriesData.value = [
            ...initialData.countriesData
        ];
        countryCode.value = initialData.countryCode;
        geoIpCountryCode.value = initialData.countryCode;
    };
    const setCountryCode = (code)=>{
        countryCode.value = code;
    };
    return {
        // State
        countriesData,
        geoIpCountryCode,
        // Getters
        countryCode: computedCountryCode,
        currentCustomerCountryData,
        // Actions
        setInitialCountryModuleData,
        setCountryCode
    };
});
export default useCountryStore;
