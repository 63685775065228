import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue";
import { toRef, computed, useCssModule } from 'vue';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import { vTimer } from 'web/src/modules/sportline/directives';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventGameProgress',
    props: {
        matchProgress: {},
        isTimeReversedIndicatorAvailable: {
            type: Boolean
        },
        fontSize: {}
    },
    setup (__props) {
        const props = __props;
        const matchProgress = toRef(props, 'matchProgress');
        const isTimeReversedIndicatorAvailable = toRef(props, 'isTimeReversedIndicatorAvailable');
        const fontSize = toRef(props, 'fontSize', 12);
        const cssModule = useCssModule();
        const { gameScoreProgress, additionalTime, isStaticLiveProgress, isTimeReversed, staticDirectTimeProgressLabel, staticReversedTimeProgressLabel, mainTimeDirectiveDirection, additionalTimeDirectiveDirection, mainTimeTotalSeconds, mainTimeProgress, mainTimeProgressShortFormat, additionalTimeTotalSeconds, additionalTimeProgress } = useLiveStatus(matchProgress);
        const gameProgressClasses = computed(()=>({
                [cssModule['sportline-event-game-progress']]: true,
                [cssModule['sportline-event-game-progress--10']]: 10 === fontSize.value,
                [cssModule['sportline-event-game-progress--12']]: 12 === fontSize.value
            }));
        const timeProgressClasses = computed(()=>({
                [cssModule['sportline-event-time-progress']]: true,
                [cssModule['sportline-event-time-progress--10']]: 10 === fontSize.value,
                [cssModule['sportline-event-time-progress--12']]: 12 === fontSize.value
            }));
        return (_ctx, _cache)=>_unref(gameScoreProgress) ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(gameProgressClasses.value)
            }, _toDisplayString(_unref(gameScoreProgress)), 3)) : _unref(isStaticLiveProgress) ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
            }, [
                isTimeReversedIndicatorAvailable.value && _unref(isTimeReversed) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _unref(staticReversedTimeProgressLabel) ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(timeProgressClasses.value)
                    }, _toDisplayString(_unref(staticReversedTimeProgressLabel)), 3)) : _createCommentVNode("", true)
                ], 64)) : _unref(staticDirectTimeProgressLabel) ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(timeProgressClasses.value)
                }, _toDisplayString(_unref(staticDirectTimeProgressLabel)), 3)) : _createCommentVNode("", true)
            ], 64)) : (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass(_ctx.$style['sportline-event-time-progress__holder'])
            }, [
                _unref(additionalTime) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(timeProgressClasses.value)
                    }, _toDisplayString(_unref(mainTimeProgressShortFormat)), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(timeProgressClasses.value)
                    }, [
                        _cache[0] || (_cache[0] = _createTextVNode("+")),
                        _withDirectives((_openBlock(), _createElementBlock("span", null, [
                            _createTextVNode(_toDisplayString(_unref(additionalTimeProgress)), 1)
                        ])), [
                            [
                                _unref(vTimer),
                                _unref(additionalTimeTotalSeconds),
                                _unref(additionalTimeDirectiveDirection)
                            ]
                        ])
                    ], 2)
                ], 64)) : _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(timeProgressClasses.value)
                }, [
                    _createTextVNode(_toDisplayString(_unref(mainTimeProgress)), 1)
                ], 2)), [
                    [
                        _unref(vTimer),
                        _unref(mainTimeTotalSeconds),
                        _unref(mainTimeDirectiveDirection)
                    ]
                ])
            ], 2));
    }
});
