import { customRef } from 'vue';
import { useLocalStorageManager } from '@leon-hub/local-storage';
export const localStorageKey = 'sport-element-expand-state';
export function useExpandedSportsLocalStorageRef() {
    const localStorageManager = useLocalStorageManager();
    function getStateFromStorage() {
        const storedState = localStorageManager.getItem(localStorageKey);
        return storedState ? JSON.parse(storedState) : {};
    }
    let stateInStorage = getStateFromStorage();
    return customRef((track, trigger)=>({
            get () {
                track();
                return stateInStorage;
            },
            set (newValue) {
                stateInStorage = newValue;
                localStorageManager.setItem(localStorageKey, JSON.stringify(stateInStorage));
                trigger();
            }
        }));
}
export function useExpandedSportBlockLocalStorageRef(keyRef, defaultValueRef) {
    const stateInStorage = useExpandedSportsLocalStorageRef();
    return customRef((track, trigger)=>({
            get () {
                track();
                const key = keyRef.value;
                const defaultValue = defaultValueRef.value;
                return key ? stateInStorage.value[key] ?? defaultValue : defaultValue;
            },
            set (newValue) {
                const key = keyRef.value;
                if (!key) return;
                const defaultValue = defaultValueRef.value;
                const currentState = stateInStorage.value;
                if (defaultValue === newValue) delete currentState[key];
                else currentState[key] = newValue;
                stateInStorage.value = currentState;
                trigger();
            }
        }));
}
