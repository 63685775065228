import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import NotFoundMessage from 'web/src/modules/affiliates/components/NotFoundMessage/NotFoundMessage.vue';
import { NativeSelect } from 'web/src/components/Select';
import AffiliateCustomerCard from 'web/src/modules/affiliates/components/AffiliateCustomerCard/AffiliateCustomerCard.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useOperationsHistoryMobile } from 'web/src/modules/affiliates/submodules/operations-history/pages/OperationsHistoryMobile/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'OperationsHistoryMobile',
    setup (__props) {
        const { operations, isDataLoading, selectProperties, isReady, onFilter, mapDateTime, mapTypeId, mapBalance, operationClick } = useOperationsHistoryMobile();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isReady) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['affiliate-history__wrapper'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-history__head-line'])
                }, [
                    _createVNode(_unref(NativeSelect), _mergeProps({
                        class: _ctx.$style['affiliate-history__select']
                    }, _unref(selectProperties), {
                        onChange: _unref(onFilter)
                    }), null, 16, [
                        "class",
                        "onChange"
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-history__list'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operations), (operation, index)=>(_openBlock(), _createBlock(AffiliateCustomerCard, {
                            key: index,
                            onClick: ($event)=>_unref(operationClick)(operation)
                        }, {
                            "top-left": _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_AFFILIATE_BALANCE')), 1)
                                ]),
                            "bottom-left": _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_unref(mapTypeId)(operation)), 1)
                                ]),
                            "top-right": _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_unref(mapDateTime)(operation)), 1)
                                ]),
                            "bottom-right": _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_unref(mapBalance)(operation)), 1)
                                ]),
                            _: 2
                        }, 1032, [
                            "onClick"
                        ]))), 128))
                ], 2),
                _unref(isDataLoading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['affiliate-history__loader'])
                }, null, 8, [
                    "class"
                ])) : 0 === _unref(operations).length ? (_openBlock(), _createBlock(NotFoundMessage, {
                    key: 1,
                    text: _ctx.$t('WEB2_AFFILIATE_OPERATIONS_NOT_FOUND')
                }, null, 8, [
                    "text"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'OperationsHistoryMobile'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
