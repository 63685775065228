import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { promiseTimeout } from '@leon-hub/utils';
import { DEFAULT_TIMEOUT } from './constants';
export const createAsyncEffect = (param)=>{
    let { scope, effects } = param;
    let isStopped = false;
    return async (onCleanup)=>{
        onCleanup(()=>{
            isStopped = true;
        });
        for (const runSubEffect of effects){
            // Skip sub-effects if the main effect is stopped.
            if (isStopped) return;
            try {
                const result = scope.run(()=>runSubEffect(onCleanup));
                if (result instanceof Promise) {
                    const promise = Number.isFinite(DEFAULT_TIMEOUT) ? promiseTimeout({
                        promise: result,
                        timeout: DEFAULT_TIMEOUT
                    }) : result;
                    // Await each sub-effect in series to avoid race conditions.
                    // eslint-disable-next-line no-await-in-loop
                    await promise;
                }
            } catch (err) {
                if (isStopped) return;
                const normalizedError = normalizeError(err);
                normalizedError.message = `Unable to run sub effect (${runSubEffect.name ?? '<unnamed>'}): ${normalizedError.message}`;
                // eslint-disable-next-line no-console
                console.error(normalizedError.message, runSubEffect);
                logger.error(normalizedError);
                break;
            }
        }
    };
};
const cachedWrappers = new WeakSet();
function isNormalizedEffect(callback) {
    return cachedWrappers.has(callback);
}
export function normalizeEffect(callback) {
    if (isNormalizedEffect(callback)) return callback;
    const wrappedEffect = function() {
        for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
            rest[_key] = arguments[_key];
        }
        return Promise.resolve(callback(...rest));
    };
    cachedWrappers.add(wrappedEffect);
    return wrappedEffect;
}
