import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SearchSubHeader from 'web/src/modules/search/components/SearchSubHeader/SearchSubHeader.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SearchTags',
    props: {
        tags: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const buttonProperties = {
            kind: ButtonKind.BASE,
            rounded: true,
            isUppercase: false
        };
        function emitClick(value) {
            emits('click', value);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['search-tags'])
            }, [
                _createVNode(SearchSubHeader, {
                    text: _ctx.$t('WEB2_SEARCH_TAGS_TITLE')
                }, null, 8, [
                    "text"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['search-tags__buttons'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag)=>(_openBlock(), _createBlock(VButton, _mergeProps({
                            ref_for: true
                        }, buttonProperties, {
                            key: tag,
                            label: tag,
                            onClick: ($event)=>emitClick(tag)
                        }), null, 16, [
                            "label",
                            "onClick"
                        ]))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SearchTags'
                ]
            ]);
        };
    }
});
