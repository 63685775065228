import { computed } from 'vue';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useSportsListStoreComposable, useSaveOpenRegionStateStoreComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import { useCybersportRegionsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
export function useCybersportAllRegionsStoreComposable(props) {
    const { sportElement } = props;
    const { timeout: cybersportRegionsUpdateInterval } = useCybersportRegionsSportlineUpdateTimeout();
    const { sportsListFilter: sportsTreeDateTimeFilter, actualSportsList: sportsTree, isReady, reloadAllSportsLists, setSportsListFilter: setSportsTreeDateTimeFilter } = useSportsListStoreComposable({
        updateInterval: cybersportRegionsUpdateInterval
    });
    const saveOpenRegionState = useSaveOpenRegionStateStoreComposable();
    const sportsTreeElement = computed(()=>sportsTree.value?.find((treeElement)=>treeElement.sport.id === sportElement.value?.sport.id) ?? null);
    const lastUpdate = new BackgroundUpdateStopwatch({
        sportsTree: async (param)=>{
            let { silent } = param;
            await reloadAllSportsLists(silent);
            lastUpdate.update('sportsTree');
        }
    });
    async function reloadSportsTreeForFilter(filter) {
        await setSportsTreeDateTimeFilter(filter);
    }
    const { syncState, initialRequests, setBackgroundUpdateEnabled } = useBackgroundRequestsLifeCycle({
        lastUpdate,
        updateInterval: cybersportRegionsUpdateInterval
    });
    const { resumeSyncState } = useSyncState((silent)=>syncState({
            silent
        }), cybersportRegionsUpdateInterval, {
        suspendedByDefault: true
    });
    return {
        ...saveOpenRegionState,
        isReady,
        sportsTreeElement,
        sportsTreeDateTimeFilter,
        setSportsTreeDateTimeFilter,
        setBackgroundUpdateEnabled,
        reloadSportsTreeForFilter,
        initialRequests () {
            initialRequests();
            resumeSyncState();
            return Promise.resolve();
        }
    };
}
