import { BetType } from '@leon-hub/api-sdk';
import DateTime from 'web/src/utils/DateTime';
export default function getCustomerFilterId(options) {
    return `${options.filter}_${options.dateRange}_${options.dateType}_${options.rows}`;
}
export function currentScore(score) {
    if (score) {
        const scoreSplit = score.split(':');
        return {
            home: scoreSplit[0],
            away: scoreSplit[1]
        };
    }
    return {
        home: '',
        away: ''
    };
}
export function formatInnings() {
    let score = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '', placeholder = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '*', replaceString = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : '\u2022';
    if (score && score.includes(placeholder)) return score.replace(placeholder, replaceString);
    return score;
}
export function defaultDateTimeFormat(timeStamp) {
    if (timeStamp) return DateTime.withTimeStamp(timeStamp).toDateTime();
    return '';
}
export function numberToDouble(coefficient) {
    if (coefficient) return `${Number(coefficient).toFixed(2)}`;
    return '';
}
export function getBetEventDescription(betType, $t) {
    let betEventParts = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    switch(betType){
        case BetType.EXPRESS:
            return [
                $t('WEB2_TRANSACTION_HISTORY_EXPRESS_BET').value
            ];
        case BetType.SYSTEM:
            return [
                $t('WEB2_TRANSACTION_HISTORY_SYSTEM_BET').value
            ];
        default:
            return betEventParts;
    }
}
