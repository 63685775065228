import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "component-wrapper"
};
import { onBeforeMount } from 'vue';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { useCasinoHome } from 'web/src/modules/home/submodules/casino/useCasinoHome';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { SportlineHomeBlockPriority } from 'web/src/modules/home/submodules/sportline/enums';
import { CasinoHomeBlockPriority } from 'web/src/modules/home/submodules/casino/enums';
import ShowcaseNavigationRouteComponent from 'web/src/modules/home/submodules/showcase/ShowcaseNavigationRouteComponent.vue';
import SportlineEventsBlock from 'web/src/modules/home/submodules/sportline/views/sportline-element/SportlineEventsBlock.vue';
import MixedSportlineEventsBlock from 'web/src/modules/home/submodules/sportline/views/sportline-element/MixedSportlineEventsBlock.vue';
import CasinoHomePromoBlock from 'web/src/modules/home/submodules/casino/components/CasinoHomePromoBlock/CasinoHomePromoBlock.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoHomeRoutePage',
    setup (__props) {
        const { isTopEventsEnabled } = useSportlineTopEventsSettings();
        const { setBackgroundUpdateEnabled } = useCasinoHome();
        const favoritesEventsStore = useFavoritesEventsStore();
        onBeforeMount(()=>{
            favoritesEventsStore.initialRequests();
        });
        onComponentActivated(()=>{
            setBackgroundUpdateEnabled(true);
        });
        onComponentDeactivated(()=>{
            setBackgroundUpdateEnabled(false);
        });
        useKeepAlive();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(ShowcaseNavigationRouteComponent),
                _unref(isTopEventsEnabled) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createVNode(MixedSportlineEventsBlock),
                    _createVNode(CasinoHomePromoBlock, {
                        priority: _unref(CasinoHomeBlockPriority).Jackpots
                    }, null, 8, [
                        "priority"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['home-block-delimiter'])
                    }, null, 2),
                    _createVNode(CasinoHomePromoBlock, {
                        priority: _unref(CasinoHomeBlockPriority).Promotions
                    }, null, 8, [
                        "priority"
                    ])
                ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(SportlineEventsBlock, {
                        priority: _unref(SportlineHomeBlockPriority).Live
                    }, null, 8, [
                        "priority"
                    ]),
                    _createVNode(CasinoHomePromoBlock, {
                        priority: _unref(CasinoHomeBlockPriority).Jackpots
                    }, null, 8, [
                        "priority"
                    ]),
                    _createVNode(SportlineEventsBlock, {
                        priority: _unref(SportlineHomeBlockPriority).Prematch
                    }, null, 8, [
                        "priority"
                    ]),
                    _createVNode(CasinoHomePromoBlock, {
                        priority: _unref(CasinoHomeBlockPriority).Promotions
                    }, null, 8, [
                        "priority"
                    ])
                ], 64))
            ])), [
                [
                    _directive_auto_id,
                    'CasinoHomeRoutePage'
                ]
            ]);
        };
    }
});
