import { computed, toRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useHead } from '@unhead/vue';
import { getUserAgent } from '@leon-hub/service-locator-env';
import { DeviceMeta } from '@leon-hub/environment-client';
import useSeoMetaProperties from 'web/src/modules/seo/composables/useSeoMetaProperties';
import { useTheme } from 'web/src/modules/theme/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useAppClassList } from 'web/src/modules/core/utils';
import { useHtmlClassList, useLayoutInfo } from 'web/src/modules/core/composables';
import useRootStore from 'web/src/modules/core/store/useRootStore';
import useAppFullscreenStore from 'web/src/modules/core/store/useAppFullscreenStore';
import useMetaTheme from 'web/src/modules/seo/components/useMetaTheme';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default function useMainAppMetaInfo() {
    const { locale } = storeToRefs(useI18nStore());
    const rootStore = useRootStore();
    const isAppMainContentLoaded = toRef(rootStore, 'isAppMainContentLoaded');
    const isContentSelectionDisabled = toRef(rootStore, 'isContentSelectionDisabled');
    const isAppInFullscreenMode = toRef(useAppFullscreenStore(), 'isAppInFullscreenMode');
    const { theme } = useTheme();
    const { metaTitle, metaTags, metaScripts } = useSeoMetaProperties();
    const { isLanding, isFullWidth } = useLayoutInfo();
    const { themeColor } = useMetaTheme();
    const fontFamily = toRef(useSiteConfigStore(), 'fontFamily');
    const classList = computed(()=>{
        // TODO: manage these all classes via store
        const result = useAppClassList(theme.value, isAppInFullscreenMode.value, fontFamily.value);
        if (DeviceMeta.isTablet(getUserAgent())) result.push('app__layout--tablet');
        if (isAppMainContentLoaded.value) result.push('app__content-ready');
        if (isContentSelectionDisabled.value) result.push('user-select-none');
        if (isFullWidth.value) result.push('app__content--full-width');
        if (isLanding.value) result.push('app__content--landing');
        return result;
    });
    useHtmlClassList(classList);
    const metaSource = computed(()=>({
            title: metaTitle?.value || '',
            htmlAttrs: {
                lang: locale.value.split('_')[0].toLowerCase()
            },
            meta: [
                {
                    charset: 'utf-8'
                },
                {
                    name: 'format-detection',
                    content: 'telephone=no'
                },
                {
                    name: 'theme-color',
                    content: themeColor.value
                },
                {
                    name: 'viewport',
                    content: "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
                },
                ...metaTags?.value || []
            ],
            script: Array.from(metaScripts?.value || [])
        }));
    useHead(metaSource);
}
