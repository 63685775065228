import { useRouter } from 'vue-router';
import { computed } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export function useRegistrationCompletionTopBarRouteComponent() {
    const { $translate } = useI18n();
    const router = useRouter();
    const customerAction = computed(()=>router.getParam('customerAction'));
    const topBarTitle = computed(()=>customerAction.value === CustomerCompletionAction.ForbiddenCountry ? $translate('WEB2_REGISTRATION_COMPLETION_COUNTRY_TITLE').value : $translate('WEB2_REGISTRATION_COMPLETION_COMMON_TITLE').value);
    const isAsoTerms = computed(()=>customerAction.value === CustomerCompletionAction.AsoTerms);
    const showPrefix = computed(()=>{
        switch(customerAction.value){
            case CustomerCompletionAction.AsoTerms:
                return false;
            case CustomerCompletionAction.ForbiddenCountry:
                return false;
            default:
                return true;
        }
    });
    function onClose() {
        router.closeModal();
    }
    function onBack() {
        "1";
        if (customerAction.value === CustomerCompletionAction.Deposit) router.push({
            name: RouteName.DEPOSITS
        });
        if (customerAction.value === CustomerCompletionAction.Withdrawal) router.push({
            name: RouteName.WITHDRAWALS
        });
    }
    return {
        topBarTitle,
        isAsoTerms,
        showPrefix,
        onClose,
        onBack
    };
}
