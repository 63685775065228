import { ref, toRef } from 'vue';
import { Tag } from '@leon-hub/tags';
import { useCmsStore } from 'web/src/modules/cms/store';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function useAccessPersonalLinkRoutePage() {
    const cmsStore = useCmsStore();
    const customerStore = useCustomerDataStore();
    const personalLink = toRef(customerStore, 'personalLink');
    const bookmarkButtonProperties = {
        kind: ButtonKind.YELLOW,
        height: ButtonHeight.SMALL,
        tag: Tag.A
    };
    const isLoading = ref(true);
    async function created() {
        await Promise.all([
            cmsStore.fetchCmsContentTranslation({
                key: 'WEB2_ACCESS_PERSONAL_LINK_FIRST_DESCR'
            }),
            cmsStore.fetchCmsContentTranslation({
                key: 'WEB2_ACCESS_PERSONAL_LINK_SECONDARY_DESCR'
            })
        ]);
        isLoading.value = false;
    }
    return {
        created,
        bookmarkButtonProperties,
        personalLink,
        isLoading
    };
}
