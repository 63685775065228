import { computed } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useCustomerFavoritesService from './useCustomerFavoritesService';
export function useFavoriteLeagueData() {
    const { pendingToFavoriteLeaguesList, changeFavoriteLeagueState } = useCustomerFavoritesService();
    const { isLoggedIn } = useIsLoggedIn();
    return {
        isOnlyAddToPendingFavoriteLeagues: computed(()=>!isLoggedIn.value),
        pendingToFavoriteLeaguesList,
        changeFavoriteLeagueState
    };
}
