import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import { IconDirection, IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ChevronIcon',
    props: {
        size: {
            default: IconSize.SIZE_20
        },
        direction: {
            default: IconDirection.EAST
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        /**
 * @deprecated use VIcon instead
 * @TODO delete
 */ const props = __props;
        const emit = __emit;
        const iconProperties = computed(()=>({
                name: IconName.CHEVRON,
                direction: props.direction,
                size: props.size
            }));
        function onClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VIcon), _mergeProps(iconProperties.value, {
                onClick: onClick
            }), null, 16)), [
                [
                    _directive_auto_id,
                    'ChevronIcon'
                ]
            ]);
        };
    }
});
