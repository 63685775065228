/* eslint-disable arrow-body-style */ // Any value validator, used to describe field and pass any value.
import { isBoolean, isArray, isUndefined, isObject, isString, isOptionalArray, isNumber, isArrayOfStrings } from '@leon-hub/guards';
function isUnknown(argument) {
    return true;
}
const isJsonSchemaOrBoolean = (argument)=>// Recursive call must be suppressed.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    !!(isBoolean(argument) || isJsonSchema(argument));
const isJsonSchemaOrBooleanArray = (argument)=>{
    if (!isArray(argument)) return false;
    // Recursive call must be suppressed.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return argument.every((element)=>isJsonSchemaOrBoolean(element));
};
const isJsonSchemaItems = (argument)=>!!(isJsonSchemaOrBoolean(argument) || isJsonSchemaOrBooleanArray(argument));
const isOptionalJsonSchemaItems = (argument)=>!!(isUndefined(argument) || isJsonSchemaItems(argument));
const isOptionalJsonSchemaOrBoolean = (argument)=>!!(isUndefined(argument) || isJsonSchemaOrBoolean(argument));
const isJsonSchemaDependencies = (argument)=>{
    if (isObject(argument)) {
        const items = Object.values(argument);
        // Recursive call must be suppressed.
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return items.every((item)=>isBoolean(item) || isArrayOfStrings(item) || isJsonSchema(item));
    }
    if (isArray(argument)) return argument.every((element)=>isString(element));
    return false;
};
export const isJsonSchemaOrBooleanObject = (argument)=>{
    if (!isObject(argument)) return false;
    // Recursive call must be suppressed.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return Object.values(argument).every((element)=>isJsonSchemaOrBoolean(element));
};
const baseJsonSchemaValidationRules = ()=>({
        $comment: [
            isString,
            isUndefined
        ],
        $ref: [
            isString,
            isUndefined
        ],
        $id: [
            isString,
            isUndefined
        ],
        $schema: [
            isString,
            isUndefined
        ],
        additionalItems: [
            isUndefined,
            isJsonSchemaOrBoolean
        ],
        additionalProperties: [
            isUndefined,
            isJsonSchemaOrBoolean
        ],
        allOf: [
            isUndefined,
            isJsonSchemaOrBooleanArray
        ],
        anyOf: [
            isUndefined,
            isJsonSchemaOrBooleanArray
        ],
        const: [
            isUnknown
        ],
        contains: [
            isUndefined,
            isJsonSchemaOrBoolean
        ],
        contentEncoding: [
            isString,
            isUndefined
        ],
        contentMediaType: [
            isString,
            isUndefined
        ],
        default: [
            isUnknown
        ],
        definitions: [
            isUndefined,
            isJsonSchemaOrBooleanObject
        ],
        dependencies: [
            isUndefined,
            isJsonSchemaDependencies
        ],
        description: [
            isString,
            isUndefined
        ],
        enum: [
            isOptionalArray
        ],
        examples: [
            isArray,
            isUndefined
        ],
        exclusiveMaximum: [
            isNumber,
            isUndefined
        ],
        exclusiveMinimum: [
            isNumber,
            isUndefined
        ],
        format: [
            isString,
            isUndefined
        ],
        items: [
            isOptionalJsonSchemaItems
        ],
        maximum: [
            isNumber,
            isUndefined
        ],
        maxItems: [
            isNumber,
            isUndefined
        ],
        maxLength: [
            isNumber,
            isUndefined
        ],
        maxProperties: [
            isNumber,
            isUndefined
        ],
        minimum: [
            isNumber,
            isUndefined
        ],
        minLength: [
            isNumber,
            isUndefined
        ],
        minItems: [
            isNumber,
            isUndefined
        ],
        minProperties: [
            isNumber,
            isUndefined
        ],
        multipleOf: [
            isNumber,
            isUndefined
        ],
        not: [
            isUndefined,
            isJsonSchemaOrBoolean
        ],
        oneOf: [
            isUndefined,
            isJsonSchemaOrBooleanArray
        ],
        pattern: [
            isString,
            isUndefined
        ],
        patternProperties: [
            isUndefined,
            isJsonSchemaOrBooleanObject
        ],
        properties: [
            isUndefined,
            isJsonSchemaOrBooleanObject
        ],
        propertyNames: [
            isUndefined,
            isJsonSchemaOrBooleanObject
        ],
        readOnly: [
            isBoolean,
            isUndefined
        ],
        required: [
            isArrayOfStrings,
            isUndefined
        ],
        then: [
            isOptionalJsonSchemaOrBoolean
        ],
        title: [
            isString,
            isUndefined
        ],
        type: [
            isString,
            isArrayOfStrings,
            isUndefined
        ],
        uniqueItems: [
            isBoolean,
            isUndefined
        ],
        writeOnly: [
            isBoolean,
            isUndefined
        ],
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        else: [
            isUndefined,
            isBoolean,
            isJsonSchema
        ],
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if: [
            isUndefined,
            isBoolean,
            isJsonSchema
        ]
    });
const isJsonSchema = (schema)=>{
    if (!isObject(schema)) return false;
    if (0 === Object.keys(schema).length) return true;
    const rules = {
        ...baseJsonSchemaValidationRules()
    };
    const argumentKeys = Object.keys(schema);
    const rulesKeys = Object.keys(rules);
    const unknownKeys = [
        ...rulesKeys,
        ...argumentKeys
    ].filter((key)=>!rulesKeys.includes(key));
    if (unknownKeys.length > 0) {
        // eslint-disable-next-line no-console
        console.warn('Unexpected JsonSchema fields:', ...unknownKeys);
        return false;
    }
    return rulesKeys.every((key)=>{
        const validators = rules[key];
        const result = validators.some((validator)=>validator(schema[key]));
        if (!result) // eslint-disable-next-line no-console
        console.warn('Invalid JsonSchema field:', key, schema[key]);
        return result;
    });
};
const isFormSchema = (schema)=>{
    if (!isObject(schema)) return false;
    const rules = {
        ...baseJsonSchemaValidationRules(),
        properties: [
            isJsonSchemaOrBooleanObject
        ]
    };
    const argumentKeys = Object.keys(schema);
    const rulesKeys = Object.keys(rules);
    const unknownKeys = [
        ...rulesKeys,
        ...argumentKeys
    ].filter((key)=>!rulesKeys.includes(key));
    unknownKeys.length, JSON.stringify(unknownKeys);
    return rulesKeys.every((key)=>{
        const validators = rules[key];
        const result = validators.some((validator)=>validator(schema[key]));
        if (!result) // eslint-disable-next-line no-console
        console.warn('Invalid FormSchemasJSON field:', key, schema[key]);
        return result;
    });
};
export { isJsonSchema, isFormSchema };
