import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsGameItemBottomBadge',
    props: {
        text: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['egs-game-item-bottom-badge'])
            }, [
                _renderSlot(_ctx.$slots, "default", {}, ()=>[
                        _createTextVNode(_toDisplayString(_ctx.text), 1)
                    ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'EgsGameItemBottomBadge'
                ]
            ]);
        };
    }
});
