import { ref, computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useFormatMoney } from 'web/src/modules/money/composables';
export function useCashoutButton(props) {
    const formatMoney = useFormatMoney();
    const formattedPriceCashout = computed(()=>formatMoney(props.cashoutAmount ?? 0));
    const iconArrowCashout = computed(()=>Number(props?.cashoutAmount) > Number(props?.stakeAmount) ? IconName.ARROW_UPWARD : IconName.ARROW_DOWNWARD);
    const isShowIconArrow = computed(()=>Number(props?.cashoutAmount) !== Number(props?.stakeAmount));
    const isHover = ref(false);
    const onPointerIn = ()=>{
        isHover.value = true;
    };
    const onPointerOut = ()=>{
        isHover.value = false;
    };
    return {
        formattedPriceCashout,
        iconArrowCashout,
        isShowIconArrow,
        isHover,
        onPointerIn,
        onPointerOut
    };
}
