import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import getCardType from 'web/src/modules/payments/utils/getCardType';
import { PAYMENTS_UNDEFINED_CREDIT_CARD } from 'web/src/modules/payments/constants';
import getCardNumberLength from '../utils/getCardNumberLength';
export function useCardNumberError(fieldNames, value) {
    const { $translate } = useI18n();
    const cardType = computed(()=>getCardType(value.value));
    const minLength = computed(()=>getCardNumberLength(cardType.value).minLength);
    const maxLength = computed(()=>getCardNumberLength(cardType.value).maxLength);
    const minLengthErrorProps = computed(()=>({
            LIMIT: `${minLength.value}`
        }));
    const maxLengthErrorProps = computed(()=>({
            LIMIT: `${maxLength.value}`
        }));
    const minLengthError = $translate('WEB2_MINIMUM_SYMBOL_LIMIT', minLengthErrorProps);
    const maxLengthError = $translate('WEB2_MAXIMUM_SYMBOL_LIMIT', maxLengthErrorProps);
    const cardNumberErrors = computed(()=>{
        let errorText = '';
        if (value.value === PAYMENTS_UNDEFINED_CREDIT_CARD) errorText = '';
        else if (value.value && value.value.length < minLength.value) errorText = minLengthError.value;
        else if (value.value && value.value.length > maxLength.value) errorText = maxLengthError.value;
        return errorText ? fieldNames.reduce((acc, fieldName)=>({
                ...acc,
                [fieldName]: [
                    {
                        value: value.value,
                        message: errorText
                    }
                ]
            }), {}) : {};
    });
    return {
        cardNumberErrors
    };
}
