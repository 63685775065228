import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onMousedown",
    "onMouseenter"
];
import { TextInput } from 'web/src/components/Input';
import { TextInputTypes } from 'web/src/components/Input/enums';
import { useAutocompleteAddress } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AutocompleteAddress',
    props: {
        countryCode: {},
        isCityType: {
            type: Boolean
        },
        selectedCity: {},
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'address'
        },
        value: {},
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {},
        autocomplete: {},
        regexp: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { inputValue, predictions, onInput, onChange, onClear, onBlur, onFocus, showPredictions, selectPrediction, preselectedIndex, onKeyDown, setPreselectedIndex } = useAutocompleteAddress(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("fieldset", {
                class: _normalizeClass(_ctx.$style['address'])
            }, [
                _createVNode(_unref(TextInput), {
                    autofocus: _ctx.autofocus,
                    value: _unref(inputValue),
                    label: _ctx.label,
                    name: _ctx.name,
                    placeholder: _ctx.placeholder,
                    disabled: _ctx.disabled,
                    hint: _ctx.hint,
                    "hint-right": _ctx.hintRight,
                    error: _ctx.error,
                    "error-right": _ctx.errorRight,
                    "hint-link": _ctx.hintLink,
                    "hint-link-right": _ctx.hintLinkRight,
                    type: _unref(TextInputTypes).TEXT,
                    autocomplete: _ctx.autocomplete,
                    regexp: _ctx.regexp,
                    onKeydown: _unref(onKeyDown),
                    onInput: _unref(onInput),
                    onChange: _unref(onChange),
                    onClear: _unref(onClear),
                    onFocus: _unref(onFocus),
                    onBlur: _unref(onBlur)
                }, _createSlots({
                    _: 2
                }, [
                    _unref(showPredictions) ? {
                        name: "dropdown",
                        fn: _withCtx(()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['address__predictions'])
                                }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(predictions), (prediction, index)=>(_openBlock(), _createElementBlock("button", {
                                            key: prediction.place_id,
                                            class: _normalizeClass({
                                                [_ctx.$style['address__predictions-item']]: true,
                                                [_ctx.$style['address__predictions-item--preselected']]: _unref(preselectedIndex) === index
                                            }),
                                            type: "button",
                                            onMousedown: ($event)=>_unref(selectPrediction)(prediction),
                                            onMouseenter: ($event)=>_unref(setPreselectedIndex)(index)
                                        }, _toDisplayString(prediction.description), 43, _hoisted_1))), 128))
                                ], 2)
                            ]),
                        key: "0"
                    } : void 0
                ]), 1032, [
                    "autofocus",
                    "value",
                    "label",
                    "name",
                    "placeholder",
                    "disabled",
                    "hint",
                    "hint-right",
                    "error",
                    "error-right",
                    "hint-link",
                    "hint-link-right",
                    "type",
                    "autocomplete",
                    "regexp",
                    "onKeydown",
                    "onInput",
                    "onChange",
                    "onClear",
                    "onFocus",
                    "onBlur"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'AutocompleteAddress'
                ]
            ]);
        };
    }
});
