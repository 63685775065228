import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { JumbotronFooterButton, JumbotronIconKind, VJumbotron } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { BetCashoutMode } from 'web/src/modules/cashout/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';
import useBetCashOut from './useBetCashOut';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetCashOut',
    props: {
        price: {
            default: 0
        },
        betPrice: {},
        currency: {
            default: 'USD'
        },
        oldPrice: {},
        isLoaded: {
            type: Boolean
        },
        mode: {
            default: BetCashoutMode.DEFAULT
        },
        errorMessage: {
            default: ''
        },
        betName: {
            default: ''
        }
    },
    emits: [
        "main-button-click",
        "cancel"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isPending, isCompleted, isError, iconName, mainText, formattedPriceValue, formattedBetPriceValue, primaryButtonLabel, showCancel, showValue, isGreenPrice, emitMainButtonClick, emitCancel } = useBetCashOut(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bet-cash-out'])
            }, [
                _ctx.isLoaded ? (_openBlock(), _createBlock(_unref(VJumbotron), {
                    key: 1,
                    icon: _unref(iconName),
                    text: _unref(mainText),
                    "icon-kind": _unref(isCompleted) ? _unref(JumbotronIconKind).SUCCESS : void 0,
                    class: _normalizeClass(_ctx.$style['bet-cash-out__jumbotron'])
                }, {
                    footer: _withCtx(()=>[
                            _unref(isError) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(JumbotronFooterButton), {
                                key: 0,
                                label: _unref(primaryButtonLabel),
                                kind: _unref(ButtonKind).PRIMARY,
                                "is-loading": _unref(isPending),
                                "full-width": "",
                                onClick: _unref(emitMainButtonClick)
                            }, null, 8, [
                                "label",
                                "kind",
                                "is-loading",
                                "onClick"
                            ])),
                            _unref(showCancel) ? (_openBlock(), _createBlock(_unref(JumbotronFooterButton), {
                                key: 1,
                                label: _ctx.$t('JSPACC_SETT_CANCEL'),
                                kind: _unref(ButtonKind).TRANSPARENT,
                                disabled: _unref(isPending),
                                "full-width": "",
                                class: _normalizeClass(_ctx.$style['bet-cash-out__cancel-button']),
                                onClick: _unref(emitCancel)
                            }, null, 8, [
                                "label",
                                "kind",
                                "disabled",
                                "class",
                                "onClick"
                            ])) : _createCommentVNode("", true)
                        ]),
                    default: _withCtx(()=>[
                            _unref(isPending) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['bet-cash-out__pending'])
                            }, null, 2)) : _createCommentVNode("", true),
                            _unref(isError) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                props.betName ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['bet-cash-out__row'])
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['bet-cash-out__name'])
                                    }, _toDisplayString(props.betName), 3)
                                ], 2)) : _createCommentVNode("", true),
                                _unref(showValue) ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['bet-cash-out__row'])
                                }, [
                                    _createElementVNode("span", null, _toDisplayString(_unref(formattedPriceValue)), 1)
                                ], 2)) : props.betPrice ? (_openBlock(), _createElementBlock("div", {
                                    key: 2,
                                    class: _normalizeClass(_ctx.$style['bet-cash-out__row'])
                                }, [
                                    _unref(isCompleted) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 0
                                    }, [
                                        _createElementVNode("span", null, _toDisplayString(_unref(formattedBetPriceValue)), 1),
                                        _createVNode(_unref(VIcon), {
                                            class: _normalizeClass(_ctx.$style['bet-cash-out__price-arrow']),
                                            name: _unref(IconName).ARROW,
                                            size: _unref(IconSize).SIZE_16
                                        }, null, 8, [
                                            "class",
                                            "name",
                                            "size"
                                        ])
                                    ], 64)),
                                    _createElementVNode("span", {
                                        class: _normalizeClass({
                                            [_ctx.$style['bet-cash-out__green']]: _unref(isGreenPrice)
                                        })
                                    }, _toDisplayString(_unref(formattedPriceValue)), 3)
                                ], 2)) : _createCommentVNode("", true)
                            ], 64))
                        ]),
                    _: 1
                }, 8, [
                    "icon",
                    "text",
                    "icon-kind",
                    "class"
                ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetCashOut'
                ]
            ]);
        };
    }
});
