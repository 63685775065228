import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective } from "vue";
import { toRef, onBeforeMount } from 'vue';
import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { DateTimeFilterTabSet, FocusedInput } from 'web/src/modules/sportline/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { DateInputCustomDate, DateInputRoundDateTo } from 'web/src/components/Input/enums';
import { DateInput } from 'web/src/components/Input';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDatepicker from 'web/src/components/Datepicker/VDatepicker/VDatepicker.vue';
import { useDateTimeFilter, useDateTimeFilterTabsSet } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DateTimeFilter',
    props: {
        activeKickoffPeriod: {},
        activeSportlineType: {},
        tabSet: {},
        selectedRangeFrom: {},
        selectedRangeTo: {},
        focused: {},
        tabsType: {},
        doRemovePaddings: {
            type: Boolean
        }
    },
    emits: [
        "select-range",
        "select-filter"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const activeKickoffPeriod = toRef(props, 'activeKickoffPeriod', SportFilterKickoffPeriod.ALL);
        const activeSportlineType = toRef(props, 'activeSportlineType');
        const tabSet = toRef(props, 'tabSet', DateTimeFilterTabSet.Full);
        const focused = toRef(props, 'focused', FocusedInput.NONE);
        const tabsType = toRef(props, 'tabsType', TabsType.NORMAL);
        const selectedRangeFrom = toRef(props, 'selectedRangeFrom');
        const selectedRangeTo = toRef(props, 'selectedRangeTo');
        const { tabsRef, showSelectCustomRangeForm, focusedLocal, preparedForActivateTab, slideToActive, setFocused, onActivateTabClick, hideSelectCustomRange, rangeFromChanged, rangeToChanged } = useDateTimeFilter({
            activeKickoffPeriod,
            activeSportlineType,
            selectedRangeFrom,
            selectedRangeTo
        }, emit);
        const { tabs } = useDateTimeFilterTabsSet({
            tabSet
        });
        __expose({
            slideToActive
        });
        onBeforeMount(()=>{
            setFocused(focused.value);
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['date-time-filter'])
            }, [
                _withDirectives(_createVNode(VTabs, {
                    ref_key: "tabsRef",
                    ref: tabsRef,
                    items: _unref(tabs),
                    "active-id": _unref(preparedForActivateTab),
                    type: tabsType.value,
                    "time-filter": "",
                    "is-bordered": "",
                    "swiper-wrapper-class": _ctx.doRemovePaddings ? [] : void 0,
                    onTabClick: _unref(onActivateTabClick)
                }, null, 8, [
                    "items",
                    "active-id",
                    "type",
                    "swiper-wrapper-class",
                    "onTabClick"
                ]), [
                    [
                        _vShow,
                        !_unref(showSelectCustomRangeForm)
                    ]
                ]),
                _withDirectives(_createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['custom-range-select'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['custom-range-select__prefix'])
                    }, [
                        _createVNode(_unref(VIcon), {
                            name: _unref(IconName).DATE_RANGE,
                            size: _unref(IconSize).SIZE_16
                        }, null, 8, [
                            "name",
                            "size"
                        ])
                    ], 2),
                    process.env.VUE_APP_BROWSER_SAFARI ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['custom-range-select__content'])
                    }, [
                        _createVNode(VDatepicker, {
                            value: selectedRangeFrom.value,
                            min: _unref(DateInputCustomDate).Now,
                            "round-to": _unref(DateInputRoundDateTo).DayStart,
                            clearable: "",
                            onChange: _unref(rangeFromChanged)
                        }, null, 8, [
                            "value",
                            "min",
                            "round-to",
                            "onChange"
                        ]),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['custom-range-select__divider'])
                        }, "-", 2),
                        _createVNode(VDatepicker, {
                            value: selectedRangeTo.value,
                            min: _unref(DateInputCustomDate).Now,
                            "round-to": _unref(DateInputRoundDateTo).DayEnd,
                            clearable: "",
                            onChange: _unref(rangeToChanged)
                        }, null, 8, [
                            "value",
                            "min",
                            "round-to",
                            "onChange"
                        ])
                    ], 2)) : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['custom-range-select__content'])
                    }, [
                        _createVNode(_unref(DateInput), {
                            class: _normalizeClass([
                                "date-picker--from",
                                [
                                    _ctx.$style['custom-range-select__input']
                                ]
                            ]),
                            value: selectedRangeFrom.value,
                            min: _unref(DateInputCustomDate).Now,
                            "round-to": _unref(DateInputRoundDateTo).DayStart,
                            focused: _unref(focusedLocal),
                            onChange: _unref(rangeFromChanged),
                            onFocus: _cache[0] || (_cache[0] = ($event)=>_unref(setFocused)(_unref(FocusedInput).FROM)),
                            onBlur: _cache[1] || (_cache[1] = ($event)=>_unref(setFocused)(_unref(FocusedInput).NONE))
                        }, null, 8, [
                            "class",
                            "value",
                            "min",
                            "round-to",
                            "focused",
                            "onChange"
                        ]),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['custom-range-select__divider'])
                        }, "-", 2),
                        _createVNode(_unref(DateInput), {
                            class: _normalizeClass([
                                "date-picker--to",
                                [
                                    _ctx.$style['custom-range-select__input']
                                ]
                            ]),
                            value: selectedRangeTo.value,
                            min: _unref(DateInputCustomDate).Now,
                            "round-to": _unref(DateInputRoundDateTo).DayEnd,
                            focused: _unref(focusedLocal),
                            onChange: _unref(rangeToChanged),
                            onFocus: _cache[2] || (_cache[2] = ($event)=>_unref(setFocused)(_unref(FocusedInput).TO)),
                            onBlur: _cache[3] || (_cache[3] = ($event)=>_unref(setFocused)(_unref(FocusedInput).NONE))
                        }, null, 8, [
                            "class",
                            "value",
                            "min",
                            "round-to",
                            "focused",
                            "onChange"
                        ])
                    ], 2)),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['custom-range-select__suffix'])
                    }, [
                        _createVNode(VButton, {
                            kind: _unref(ButtonKind).TRANSPARENT,
                            "icon-name": _unref(IconName).CANCEL,
                            "icon-size": _unref(IconSize).SIZE_16,
                            class: _normalizeClass(_ctx.$style['custom-range-select__cancel']),
                            onClick: _unref(hideSelectCustomRange)
                        }, null, 8, [
                            "kind",
                            "icon-name",
                            "icon-size",
                            "class",
                            "onClick"
                        ])
                    ], 2)
                ], 2), [
                    [
                        _vShow,
                        _unref(showSelectCustomRangeForm)
                    ]
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'DateTimeFilter'
                ]
            ]);
        };
    }
});
