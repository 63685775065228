export default function extractBuildSportOptions(options) {
    if (!options) return {
        sportSegmentMapping: null,
        sportFamilyMapping: null,
        sportRepresentationSettings: null,
        canUseZeroMargin: false
    };
    return {
        sportSegmentMapping: options.sportSegmentMapping ?? null,
        sportFamilyMapping: options.sportFamilyMapping ?? null,
        sportRepresentationSettings: options.sportRepresentationSettings ?? null,
        canUseZeroMargin: options.canUseZeroMargin ?? false
    };
}
