import { getEvents, getEventsChanges } from 'web/src/modules/sportline/utils/api';
import useSportlineApiPending from './useSportlineApiPending';
export function useSportlinePendingApi(props) {
    const { tryRequestWithCTag, getEventsInput, getPendingParams } = props;
    const pendingController = useSportlineApiPending();
    // just fetch data
    async function fetchEvents(options) {
        let result = null;
        const { vTag, silent } = options;
        const input = getEventsInput(options);
        if (vTag) result = await tryRequestWithCTag((ctag)=>getEventsChanges({
                ...input,
                ctag,
                vtag: vTag,
                silent
            }));
        if (!vTag || result?.vtag === 'invalid') result = await tryRequestWithCTag((ctag)=>getEvents({
                ...input,
                ctag
            }));
        return result;
    }
    function loadEvents(options) {
        const pendingFilter = getPendingParams(options);
        const apiPendingIndex = pendingController.getApiPendingIndexForFilter(pendingFilter);
        if (apiPendingIndex > -1) return pendingController.getApiPendingByIndex(apiPendingIndex).request;
        const request = new Promise((resolve, reject)=>{
            fetchEvents(options).then((result)=>{
                pendingController.removeApiPendingForFilter(pendingFilter);
                resolve(result);
                return result;
            }).catch((error)=>{
                pendingController.removeApiPendingForFilter(pendingFilter);
                reject(error);
            });
        });
        const apiPending = {
            ...pendingFilter,
            request
        };
        pendingController.addApiPending(apiPending);
        return apiPending.request;
    }
    return {
        loadEvents
    };
}
