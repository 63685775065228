import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import DefaultMarketGroup from './components/default/MarketGroup.vue';
import BetSwipeMarketGroup from './components/bet-swipe/MarketGroup.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketGroup',
    props: {
        group: {},
        sportlineEvent: {}
    },
    emits: [
        "market-group-toggle"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
        return (_ctx, _cache)=>(_openBlock(), _createBlock(DefaultMarketGroup, {
                key: 1,
                group: _ctx.group,
                "sportline-event": _ctx.sportlineEvent,
                onMarketGroupToggle: _cache[1] || (_cache[1] = ($event)=>emit('market-group-toggle', $event))
            }, null, 8, [
                "group",
                "sportline-event"
            ]));
    }
});
