import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { EgsAvailabilityStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
import { VIcon } from '@components/v-icon';
import { useEgsGameInfoOverlay } from 'web/src/modules/egs/components/EgsGameInfoOverlay/composables';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsGameInfoOverlay',
    props: {
        availability: {},
        limits: {},
        isTopPosition: {
            type: Boolean
        },
        isExclusive: {
            type: Boolean
        },
        badge: {}
    },
    setup (__props) {
        const props = __props;
        const { isSeatsHidden, seatsText, betRange, iconProperties, status, ntBadgeProperties } = useEgsGameInfoOverlay(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['egs-game-info-overlay']]: true,
                    [_ctx.$style['egs-game-info-overlay--top']]: _ctx.isTopPosition
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['egs-game-info-overlay__badges'])
                }, [
                    _ctx.badge ? (_openBlock(), _createBlock(VBadge, _mergeProps({
                        key: 0
                    }, _unref(ntBadgeProperties), {
                        class: _ctx.$style['egs-game-info-overlay__nt-badge']
                    }), null, 16, [
                        "class"
                    ])) : _createCommentVNode("", true),
                    _ctx.isExclusive ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['egs-game-info-overlay__item'])
                    }, _toDisplayString(_ctx.$t('WEB2_EXCLUSIVE_BADGE')), 3)) : _createCommentVNode("", true),
                    _unref(isSeatsHidden) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass({
                            [_ctx.$style['egs-game-info-overlay__seats']]: true,
                            [_ctx.$style['egs-game-info-overlay__item']]: true,
                            [_ctx.$style['egs-game-info-overlay__seats--busy']]: _unref(status) === _unref(EgsAvailabilityStatus).NOT_AVAILABLE,
                            [_ctx.$style['egs-game-info-overlay__seats--behind']]: _unref(status) === _unref(EgsAvailabilityStatus).BET_BEHIND
                        })
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['egs-game-info-overlay__seats-indicator'])
                        }, null, 2),
                        _createTextVNode(" " + _toDisplayString(_unref(seatsText)) + " ", 1),
                        _createVNode(_unref(VIcon), _mergeProps(_unref(iconProperties), {
                            class: _ctx.$style['egs-game-info-overlay__seats-icon']
                        }), null, 16, [
                            "class"
                        ])
                    ], 2)),
                    _unref(betRange) ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: _normalizeClass(_ctx.$style['egs-game-info-overlay__item'])
                    }, _toDisplayString(_unref(betRange)), 3)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'EgsGameInfoOverlay'
                ]
            ]);
        };
    }
});
