import RouteName from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useRootStore } from 'web/src/modules/core/store';
let LanguagePagePrefetch = class LanguagePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        if (!useRootStore().isLanguageSwitcherAvailable) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return Promise.resolve();
        }
        next();
        return Promise.resolve();
    }
};
export { LanguagePagePrefetch as default };
