import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "balance-swiper-navigation"
};
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useBalanceSwiperNavigation } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BalanceSwiperNavigation',
    setup (__props) {
        const { slideToPreviousSlide, slideToNextSlide, isShowButtons, previousButtonProperties, nextButtonProperties } = useBalanceSwiperNavigation();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VButton, _mergeProps(_unref(previousButtonProperties), {
                    class: "balance-swiper-navigation__button",
                    onClick: _unref(slideToPreviousSlide)
                }), null, 16, [
                    "onClick"
                ]),
                _createVNode(VButton, _mergeProps(_unref(nextButtonProperties), {
                    class: "balance-swiper-navigation__button",
                    onClick: _unref(slideToNextSlide)
                }), null, 16, [
                    "onClick"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'BalanceSwiperNavigation'
                ],
                [
                    _vShow,
                    _unref(isShowButtons)
                ]
            ]);
        };
    }
});
