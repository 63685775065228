import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { getRelatedEvents } from 'web/src/modules/sportline/utils/api';
export function useSportlineApiLoadRelatedEvents(props) {
    const { tryRequestWithCTag, defaultRestApiFlags, isLiveOutrightsEnabled } = props;
    async function loadRelatedEvents(options) {
        const { eventId, vTag, silent } = options;
        let result;
        if (vTag) result = await tryRequestWithCTag((ctag)=>getRelatedEvents({
                eventId,
                betline: BetlineType.All,
                ctag,
                vtag: vTag,
                hideClosed: true,
                silent,
                flags: [
                    ...defaultRestApiFlags.value
                ],
                LMCE: isLiveOutrightsEnabled.value
            }));
        if (!result || 'invalid' === result.vtag) result = await tryRequestWithCTag((ctag)=>getRelatedEvents({
                eventId,
                betline: BetlineType.All,
                ctag,
                hideClosed: true,
                silent,
                flags: [
                    ...defaultRestApiFlags.value
                ],
                LMCE: isLiveOutrightsEnabled.value
            }));
        // @see getRelatedEvents (always return value)
        return result ?? {
            totalCount: 0,
            events: []
        };
    }
    return {
        loadRelatedEvents
    };
}
