import formatOdd from 'web/src/utils/formatOdd';
import isMatchedChange from './isMatchedChange';
export default function applyRunnerOddsChange(param, changes) {
    let { eventId, marketId, runner } = param;
    const actualChange = changes.find((change)=>isMatchedChange(change, {
            eventId,
            marketId,
            runnerId: runner.id
        }));
    if (!actualChange) return runner;
    return {
        ...runner,
        price: actualChange.odds,
        priceStr: formatOdd(actualChange.odds)
    };
}
