import { ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { FeedbackApiErrorCode } from 'web/src/modules/core/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import useUserFeedbackResponse from 'web/src/modules/feedback/composables/useUserFeedbackResponse';
export default function useSupportFeedbackLegacyRoutePage(emit) {
    const siteConfigStore = useSiteConfigStore();
    const customerDataStore = useCustomerDataStore();
    const { showDialog, closeDialog } = useDialogs();
    const { $translate } = useI18n();
    useRouter();
    const { sendUserResponse } = useUserFeedbackResponse();
    const feedbackFormMaxChars = toRef(siteConfigStore, 'feedbackFormMaxChars');
    const haveValidEmailValue = toRef(customerDataStore, 'haveValidEmailValue');
    const emailValue = toRef(customerDataStore, 'email');
    const successModalId = ref('');
    const submitting = ref(false);
    const isClearTextarea = ref(false);
    const isApiErrorEmailMessage = ref('');
    const savedErrorDuplicateEmail = ref('');
    async function submit(data) {
        submitting.value = true;
        isApiErrorEmailMessage.value = '';
        savedErrorDuplicateEmail.value = '';
        try {
            await handleSubmit(data);
            isClearTextarea.value = true;
            await handleSuccessResponse();
        } catch (error) {
            handleErrorResponse(error, data.email);
        }
        submitting.value = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    async function handleSubmit(data) {
        try {
            const payload = {
                body: data.comment
            };
            const userEmail = haveValidEmailValue.value ? emailValue.value : '';
            payload.email = userEmail || data.email;
            await sendUserResponse(payload);
            return true;
        } catch (error) {
            return error;
        }
    }
    async function handleSuccessResponse() {
        "1";
        {
            const { subscribe, id } = showDialog({
                presetName: PresetName.ALERT_SUCCESS,
                options: {
                    confirmMessage: $translate('WEB2_THANK_YOU_FOR_FEEDBACK').value,
                    title: $translate('WEB2_FEEDBACK_SENT').value,
                    width: ModalWidth.MEDIUM,
                    fullHeight: true,
                    dataTestId: 'feedback-sent',
                    isCloseAllowed: true
                }
            });
            successModalId.value = id;
            subscribe({
                [DialogAction.MODAL_CLOSE]: ()=>{
                    isClearTextarea.value = false;
                }
            });
        }
    }
    function handleErrorResponse(error, email) {
        if (error instanceof GqlApiBatchedSubRequestError && // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        error.extensions.errorCode === FeedbackApiErrorCode.EMAIL_ALREADY_EXISTS) {
            isApiErrorEmailMessage.value = error.message;
            savedErrorDuplicateEmail.value = email;
            submitting.value = false;
            return;
        }
        const { subscribe, id } = showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                title: $translate('JS_CAPTION_ATTENTION').value,
                confirmMessage: $translate('WEB2_USER_FEEDBACK_IS_NOT_SENT').value,
                width: ModalWidth.MEDIUM,
                dataTestId: 'feedback-is-not-sent'
            }
        });
        subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                closeDialog(id);
            }
        });
    }
    function closeSuccessDialog() {
        if (successModalId.value) {
            closeDialog(successModalId.value);
            isClearTextarea.value = false;
        }
    }
    return {
        feedbackFormMaxChars,
        haveValidEmailValue,
        submitting,
        isClearTextarea,
        isApiErrorEmailMessage,
        savedErrorDuplicateEmail,
        submit,
        closeSuccessDialog
    };
}
