import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "viewBox"
];
const _hoisted_2 = [
    "id",
    "width",
    "height"
];
const _hoisted_3 = [
    "values"
];
const _hoisted_4 = [
    "stroke-width"
];
const _hoisted_5 = [
    "stroke-width",
    "stroke-dasharray",
    "filter"
];
import { onMounted } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { CircleBackground, CircleColor, CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import { useVCircularProgress } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCircularProgress',
    props: {
        percentage: {
            default: 0
        },
        iconName: {},
        iconSize: {
            default: IconSize.SIZE_24
        },
        circleSize: {
            default: CircleSize.DEFAULT
        },
        circleBackground: {
            default: CircleBackground.DEFAULT
        },
        circleColor: {
            default: CircleColor.DEFAULT
        },
        isSimpleProgress: {
            type: Boolean
        },
        inLoyaltyProgram: {
            type: Boolean
        },
        isIconBackground: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { progress, checkIcon, matchedSizeValue, circleStrokeWidth, circlePositionProps, viewBox, safePercentage, relativeSafePercentage, strokeDasharray, filterId, filterShadowColorMatrix, onCircularProgressMounted } = useVCircularProgress(props);
        onMounted(onCircularProgressMounted);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['circular-progress']]: true,
                    [_ctx.$style[`circular-progress--size-${_ctx.circleSize}`]]: _ctx.circleSize,
                    [_ctx.$style["circular-progress--background-colored"]]: _ctx.circleBackground === _unref(CircleBackground).COLORED,
                    [_ctx.$style["circular-progress--in-loyalty-program"]]: _ctx.inLoyaltyProgram
                })
            }, [
                (_openBlock(), _createElementBlock("svg", {
                    class: _normalizeClass(_ctx.$style['circular-progress__svg']),
                    viewBox: _unref(viewBox),
                    xmlns: "http://www.w3.org/2000/svg",
                    style: {
                        overflow: "inherit"
                    }
                }, [
                    (_openBlock(), _createElementBlock("filter", {
                        key: 0,
                        id: `inset-shadow-${_unref(filterId)}`,
                        filterUnits: "userSpaceOnUse",
                        "color-interpolation-filters": "sRGB",
                        width: _unref(matchedSizeValue) + 20,
                        height: _unref(matchedSizeValue) + 20,
                        x: -10,
                        y: -10
                    }, [
                        _cache[0] || (_cache[0] = _createElementVNode("feFlood", {
                            "flood-opacity": "0",
                            result: "BackgroundImageFix"
                        }, null, -1)),
                        _cache[1] || (_cache[1] = _createElementVNode("feColorMatrix", {
                            in: "SourceAlpha",
                            type: "matrix",
                            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                            result: "hardAlpha"
                        }, null, -1)),
                        _cache[2] || (_cache[2] = _createElementVNode("feOffset", null, null, -1)),
                        _cache[3] || (_cache[3] = _createElementVNode("feGaussianBlur", {
                            stdDeviation: "2"
                        }, null, -1)),
                        _createElementVNode("feColorMatrix", {
                            type: "matrix",
                            values: _unref(filterShadowColorMatrix)
                        }, null, 8, _hoisted_3),
                        _cache[4] || (_cache[4] = _createElementVNode("feBlend", {
                            mode: "normal",
                            in2: "BackgroundImageFix",
                            result: "effect1_dropShadow"
                        }, null, -1)),
                        _cache[5] || (_cache[5] = _createElementVNode("feBlend", {
                            mode: "normal",
                            in: "SourceGraphic",
                            in2: "effect1_dropShadow",
                            result: "shape"
                        }, null, -1))
                    ], 8, _hoisted_2)),
                    _createElementVNode("circle", _mergeProps(_unref(circlePositionProps), {
                        class: {
                            [_ctx.$style['circular-progress__background']]: true,
                            [_ctx.$style['circular-progress__background--colored']]: _ctx.circleBackground === _unref(CircleBackground).COLORED,
                            [_ctx.$style['circular-progress__background--simple']]: _ctx.isSimpleProgress,
                            [_ctx.$style['circular-progress__background--white']]: _ctx.circleColor === _unref(CircleColor).WHITE
                        },
                        "stroke-width": _unref(circleStrokeWidth),
                        fill: "none"
                    }), null, 16, _hoisted_4),
                    _withDirectives(_createElementVNode("circle", _mergeProps(_unref(circlePositionProps), {
                        ref_key: "progress",
                        ref: progress,
                        class: {
                            [_ctx.$style['circular-progress__progress']]: true,
                            [_ctx.$style[`circular-progress__progress--${_ctx.circleColor}`]]: _ctx.circleColor !== _unref(CircleColor).DEFAULT
                        },
                        "stroke-width": _unref(circleStrokeWidth),
                        "stroke-dasharray": _unref(strokeDasharray),
                        "stroke-linecap": "round",
                        "stroke-dashoffset": "0",
                        fill: "none",
                        filter: `url(#inset-shadow-${_unref(filterId)})`
                    }), null, 16, _hoisted_5), [
                        [
                            _vShow,
                            _unref(relativeSafePercentage) > 0
                        ]
                    ])
                ], 10, _hoisted_1)),
                _ctx.isSimpleProgress ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['circular-progress__content']]: true,
                        [_ctx.$style['circular-progress__content--filled']]: _ctx.isIconBackground
                    }),
                    "data-test-id": "content"
                }, [
                    _renderSlot(_ctx.$slots, "default", {}, ()=>[
                            _ctx.iconName ? (_openBlock(), _createBlock(_unref(VIcon), {
                                key: 0,
                                name: _ctx.iconName,
                                size: _ctx.iconSize,
                                class: _normalizeClass(_ctx.$style['circular-progress__icon'])
                            }, null, 8, [
                                "name",
                                "size",
                                "class"
                            ])) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                100 === _unref(safePercentage) ? (_openBlock(), _createBlock(_unref(VIcon), {
                                    key: 0,
                                    name: _unref(checkIcon),
                                    size: _ctx.iconSize,
                                    class: _normalizeClass(_ctx.$style['circular-progress__icon-check'])
                                }, null, 8, [
                                    "name",
                                    "size",
                                    "class"
                                ])) : (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 1
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['circular-progress__label'])
                                    }, _toDisplayString(_unref(safePercentage)), 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass({
                                            [_ctx.$style['circular-progress__label']]: true,
                                            [_ctx.$style['circular-progress__label--percent']]: true
                                        })
                                    }, " %", 2)
                                ], 64))
                            ], 64))
                        ])
                ], 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VCircularProgress'
                ]
            ]);
        };
    }
});
