import { computed } from 'vue';
import { IconDirection, IconSize, isPointingIcon, useIcons } from '@leon-hub/icons';
export default function useVIcon(props) {
    const { icons } = useIcons();
    const iconPath = computed(()=>props.name && icons.get(props.name.toLowerCase()));
    const hasDirection = computed(()=>props.direction !== IconDirection.EAST && isPointingIcon(props.name));
    const hasSize = computed(()=>!!props.size && props.size !== IconSize.SIZE_20 && props.size !== IconSize.DEFAULT);
    const isLoading = computed(()=>0 === icons.size);
    return {
        iconPath,
        hasSize,
        hasDirection,
        isLoading
    };
}
