import { computed } from 'vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';
import { getIconPropsForButton, isButtonDisabled, getIconClassNamesForButton } from './utils';
function hasIcon(props) {
    return !!props.iconName;
}
export default function useVButton(props, slots, isAnimated) {
    const iconProps = computed(()=>getIconPropsForButton(props));
    const isDisabledState = computed(()=>isButtonDisabled(props));
    const iconClassNames = computed(()=>getIconClassNamesForButton(props, hasSlot(slots, 'default')));
    const isAnimation = computed(()=>!!props.hasAnimation);
    const isSpecialHeight = computed(()=>!!props.height && props.height !== ButtonHeight.LARGE);
    const isSpecialKind = computed(()=>!!props.kind && props.kind !== ButtonKind.PRIMARY);
    const isIconLeft = computed(()=>(!!props.label || hasSlot(slots, 'default')) && hasIcon(props) && !props.iconRight);
    return {
        isDisabledState,
        iconProps,
        iconClassNames,
        isAnimation,
        isSpecialHeight,
        isSpecialKind,
        isIconLeft
    };
}
