import { toRef } from 'vue';
import { useLexisNexisIntegrationStore } from '../store';
import { log } from './log';
import { watchCustomEffect } from './watchCustomEffect';
import { createActivationEffect } from './createActivationEffect';
export const runApiProfile = ()=>{
    log('starting profiling..');
    const store = useLexisNexisIntegrationStore();
    const isEnabled = toRef(()=>store.isEnabled);
    let controls;
    // TODO: create controls when needed with watchCustomEffect helper when needed and provided with watchCustomEffect options
    const getControls = ()=>({
            stop: ()=>controls.stop(),
            continueEffect: function() {
                for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
                    rest[_key] = arguments[_key];
                }
                return controls.continueEffect(...rest);
            },
            addEffect: function() {
                for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
                    rest[_key] = arguments[_key];
                }
                return controls.addEffect(...rest);
            }
        });
    controls = watchCustomEffect(createActivationEffect(getControls), {
        detached: true,
        condition: isEnabled,
        id: 'activation'
    });
};
