import { computed, ref, watch } from 'vue';
import { getSelectedOption, getDefaultSelectedValue, getDefaultMultipleSelectedValues, getMultipleSelectionText } from '../utils';
export function useDropListSelected(props, allFlatOptions) {
    // selected
    const singleSelectedValue = ref(getDefaultSelectedValue(props));
    const multipleSelectedValuesSet = ref(getDefaultMultipleSelectedValues(props));
    const multipleSelectedValues = computed(()=>Array.from(multipleSelectedValuesSet.value));
    const selectedId = computed(()=>{
        if (props.isMultiselectMode) return null;
        return singleSelectedValue.value ?? null;
    });
    const selectedOption = computed(()=>getSelectedOption(props, singleSelectedValue.value, allFlatOptions.value));
    const selectedText = computed(()=>{
        const textFromSelection = props.isMultiselectMode ? getMultipleSelectionText(allFlatOptions.value, multipleSelectedValuesSet.value) : selectedOption.value?.label;
        return textFromSelection || props.placeholder || '';
    });
    const selectedSecondaryText = computed(()=>selectedOption.value?.rightText ?? '');
    const selectedSecondaryBadge = computed(()=>selectedOption.value?.badge ?? '');
    const setSingleSelected = (value)=>{
        singleSelectedValue.value = value;
    };
    const setMultipleSelected = (value)=>{
        multipleSelectedValuesSet.value = value;
    };
    const toggleMultipleSelected = (value)=>{
        if (multipleSelectedValuesSet.value.has(value)) multipleSelectedValuesSet.value.delete(value);
        else multipleSelectedValuesSet.value.add(value);
    };
    const isSelected = (value)=>{
        if (props.isMultiselectMode) return multipleSelectedValuesSet.value.has(value);
        return value === selectedId.value;
    };
    watch(()=>props.selected, ()=>{
        setSingleSelected(getDefaultSelectedValue(props));
        setMultipleSelected(getDefaultMultipleSelectedValues(props));
    });
    return {
        selectedId,
        selectedText,
        selectedSecondaryText,
        selectedSecondaryBadge,
        selectedOption,
        multipleSelectedValues,
        setSingleSelected,
        toggleMultipleSelected,
        isSelected
    };
}
