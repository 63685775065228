import { ComponentKey } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let ScreenResolutionComponent = class ScreenResolutionComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        return this.result([
            window.screen.width,
            window.screen.height
        ]);
    }
    constructor(){
        super(ComponentKey.ScreenResolution);
    }
};
export { ScreenResolutionComponent as default };
