import { computed, toRef } from 'vue';
import { useNavigationItemsStore } from 'web/src/modules/core/store';
import { convertNavigationToSidebarMenu } from 'web/src/components/SidebarMenu/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
export function useSidebarMenuKnowHowRouteComponent() {
    const { $translate } = useI18n();
    const navigationItemsStore = useNavigationItemsStore();
    const bootstrapNavigationItems = toRef(navigationItemsStore, 'bootstrapNavigationItems');
    const genericInfoNavigationItems = toRef(navigationItemsStore, 'genericInfoNavigationItems');
    const menuBootstrap = computed(()=>convertNavigationToSidebarMenu([
            {
                id: '1',
                caption: $translate('WEB2_WHERE_TO_BEGIN').value,
                children: bootstrapNavigationItems.value
            }
        ], {
            active: ()=>true
        }, {
            location: (navItem)=>({
                    name: navItem.routeName
                }),
            active: (navItem)=>!!navItem?.isActive
        }));
    const menuGeneric = computed(()=>convertNavigationToSidebarMenu([
            {
                id: '2',
                caption: $translate('WEB2_INFORMATION').value,
                children: genericInfoNavigationItems.value
            }
        ], {
            active: ()=>true
        }, {
            location: (navItem)=>({
                    name: navItem.routeName
                }),
            active: (navItem)=>!!navItem?.isActive
        }));
    return {
        menuBootstrap,
        menuGeneric
    };
}
