import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "component-wrapper"
};
import { onBeforeMount, onActivated, onMounted, onDeactivated, onUnmounted } from 'vue';
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { useSportlineHome } from 'web/src/modules/home/submodules/sportline/useSportlineHome';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { SportlineHomeBlockPriority } from 'web/src/modules/home/submodules/sportline/enums';
import ShowcaseNavigationRouteComponent from 'web/src/modules/home/submodules/showcase/ShowcaseNavigationRouteComponent.vue';
import HomePromotionsBlock from 'web/src/modules/home/submodules/promotions/views/HomePromotionsBlock.vue';
import SportlineEventsBlock from 'web/src/modules/home/submodules/sportline/views/sportline-element/SportlineEventsBlock.vue';
import MixedSportlineEventsBlock from 'web/src/modules/home/submodules/sportline/views/sportline-element/MixedSportlineEventsBlock.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HomeRoutePage',
    setup (__props) {
        const { isTopEventsEnabled } = useSportlineTopEventsSettings();
        const { setBackgroundUpdateEnabled } = useSportlineHome();
        const favoritesEventsStore = useFavoritesEventsStore();
        onBeforeMount(()=>{
            favoritesEventsStore.initialRequests();
        });
        onActivated(()=>{
            setBackgroundUpdateEnabled(true);
        });
        onMounted(()=>{
            setBackgroundUpdateEnabled(true);
        });
        onDeactivated(()=>{
            setBackgroundUpdateEnabled(false);
        });
        onUnmounted(()=>{
            setBackgroundUpdateEnabled(false);
        });
        useKeepAlive();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(ShowcaseNavigationRouteComponent),
                _unref(isTopEventsEnabled) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createVNode(MixedSportlineEventsBlock),
                    _createVNode(HomePromotionsBlock)
                ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(SportlineEventsBlock, {
                        priority: _unref(SportlineHomeBlockPriority).Live
                    }, null, 8, [
                        "priority"
                    ]),
                    _createVNode(HomePromotionsBlock),
                    _createVNode(SportlineEventsBlock, {
                        priority: _unref(SportlineHomeBlockPriority).Prematch
                    }, null, 8, [
                        "priority"
                    ])
                ], 64))
            ])), [
                [
                    _directive_auto_id,
                    'HomeRoutePage'
                ]
            ]);
        };
    }
});
