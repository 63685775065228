import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { packListStateActive, packStateCalculated, doButtonMutation, doCalculatePack, doCollectPackReward, getAllPacks, getLeaderBoard as getLeaderBoardApi, getPacks, getPromotionDetails, packStateNotAvailable } from '@leon-hub/api-sdk';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { logger } from '@leon-hub/logging';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { usePromotionDetailsCore } from 'web/src/modules/promotions/submodules/details/composables';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import useIdentificationNeededModal from 'web/src/modules/dialogs/composables/useIdentificationNeededModal';
const useAdventStore = defineStore('advent', ()=>{
    const apiClient = useGraphqlClient();
    const siteConfigStore = useSiteConfigStore();
    const { isLoggedIn } = useIsLoggedIn();
    const promotionsStore = usePromotionsStore();
    const { loadPromotionDetails, currentPromotion } = usePromotionDetailsCore();
    const errorConverter = useErrorsConverter();
    const { showIdentificationNeededModal } = useIdentificationNeededModal();
    const promotions = ref([]);
    const promotionUpdatedData = ref();
    const festivalDate = ref(0);
    const allPacks = ref([]);
    const pack = ref(null);
    const isPacksLoaded = ref(false);
    const isFestivalUnavailable = ref(false);
    const festivalCampaignId = toRef(()=>siteConfigStore.festivalCampaignId);
    const festivalActionUrl = toRef(()=>siteConfigStore.festivalActionUrl);
    const festivalTournamentActionUrls = toRef(()=>siteConfigStore.festivalTournamentActionUrls);
    // getters
    const rewards = computed(()=>pack.value?.rewards);
    const activePack = computed(()=>allPacks.value?.find((item)=>item.state === packListStateActive));
    const isBeforeStart = computed(()=>{
        if (!!currentPromotion.value?.isShowStartDateCountdown && !!currentPromotion.value?.isShowEndDateCountdown) return false;
        return !!currentPromotion.value?.isShowStartDateCountdown;
    });
    const counterTimestamp = computed(()=>{
        if (isBeforeStart.value) return currentPromotion.value?.startDateCountdown || 0;
        return currentPromotion.value?.endDateNumber || 0;
    });
    // actions
    function setFestivalUnavailability(value) {
        isFestivalUnavailable.value = value;
    }
    function checkFestivalUnavailability() {
        if (!festivalCampaignId.value || 0 === festivalCampaignId.value) setFestivalUnavailability(true);
    }
    async function adventCalendarLeaderBoardRequest(payload) {
        const { actionUrl } = payload;
        const requestOptions = {
            page: 1,
            pageSize: 3,
            actionUrl
        };
        try {
            return await getLeaderBoardApi(apiClient, (node)=>node.queries.promotions.getLeaderBoard, {
                options: requestOptions
            });
        } catch (error) {
            logger.error('Something goes wrong with leaderBoardRequest', error);
            return null;
        }
    }
    async function loadPromotions() {
        await promotionsStore.getPromotionsByCategoryId();
        promotions.value = Object.values(promotionsStore.promotions).filter((action)=>festivalTournamentActionUrls.value.includes(action.actionUrl || ''));
    }
    async function updatePromotionDetails(actionUrl) {
        if (actionUrl) try {
            const filterOptions = {
                actionUrl,
                pageSize: 6,
                ts: 0
            };
            const { promotion: data } = await getPromotionDetails(apiClient, (node)=>node.queries.promotions.getPromotion, {
                options: filterOptions
            });
            promotionUpdatedData.value = data;
        } catch (error) {
            logger.error('Something goes wrong with update promotion details', error);
        }
        return null;
    }
    async function adventCalendarDoButtonMutationPromotion(payload) {
        try {
            const options = {
                ...payload,
                ts: 0
            };
            const { actionButton } = await doButtonMutation(apiClient, (node)=>node.mutations.promotions.updatePromotion, {
                options
            });
            if (actionButton) await loadPromotions();
        } catch (rawError) {
            const error = errorConverter.convertToBaseError(rawError);
            const errorCode = error.code;
            if (errorCode.equals('IDENTIFICATION_NEEDED')) showIdentificationNeededModal();
        }
    }
    async function fetchAllPacks() {
        try {
            if (festivalCampaignId.value) {
                const response = await getAllPacks(apiClient, (node)=>node.queries.bonusGame.getAllPacks, {
                    options: {
                        campaignId: festivalCampaignId.value
                    }
                }, {
                    silent: true
                });
                const { packs } = response;
                allPacks.value = packs;
                isPacksLoaded.value = true;
            }
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) logger.error('Something goes wrong with fetch all packs', error);
        }
    }
    async function fetchPack() {
        try {
            if (festivalCampaignId.value) {
                const response = await getPacks(apiClient, (node)=>node.queries.bonusGame.getPacks, {
                    options: {
                        campaignId: festivalCampaignId.value
                    }
                }, {
                    silent: true
                });
                pack.value = {
                    ...response
                };
                if (pack.value.state === packStateNotAvailable) isFestivalUnavailable.value = true;
            }
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) logger.error('Something goes wrong with fetch pack', error);
        }
    }
    async function calculatePack() {
        try {
            if (festivalCampaignId.value) {
                const response = await doCalculatePack(apiClient, (node)=>node.mutations.bonusGame.calculatePack, {
                    options: {
                        campaignId: festivalCampaignId.value
                    }
                });
                if (response.state === packStateCalculated) await fetchPack();
            }
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) logger.error('Something goes wrong with calculate pack', error);
        }
    }
    async function collectPackReward(rewardId) {
        try {
            if (festivalCampaignId.value) {
                const response = await doCollectPackReward(apiClient, (node)=>node.mutations.bonusGame.collectPackReward, {
                    options: {
                        campaignId: festivalCampaignId.value,
                        rewardId
                    }
                });
                if (response.state === packStateCalculated) await Promise.all([
                    fetchPack(),
                    fetchAllPacks()
                ]);
            }
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) logger.error('Something goes wrong with collect reward', error);
        }
    }
    async function setFestivalDate() {
        if (festivalActionUrl.value) try {
            await loadPromotionDetails(festivalActionUrl.value);
            festivalDate.value = counterTimestamp.value;
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) logger.error('Something goes wrong with load promotion details', error);
            setFestivalUnavailability(true);
        }
    }
    async function init() {
        if (isLoggedIn.value) {
            await Promise.all([
                fetchAllPacks(),
                fetchPack(),
                loadPromotions()
            ]);
            checkFestivalUnavailability();
        }
        await setFestivalDate();
    }
    init();
    watch(isLoggedIn, async ()=>{
        if (isLoggedIn.value) {
            await Promise.all([
                fetchAllPacks(),
                fetchPack(),
                loadPromotions()
            ]);
            checkFestivalUnavailability();
        } else {
            allPacks.value = [];
            setFestivalUnavailability(false);
        }
    });
    watch(festivalCampaignId, async ()=>{
        if (isLoggedIn.value) {
            await Promise.all([
                fetchAllPacks(),
                fetchPack()
            ]);
            checkFestivalUnavailability();
        } else setFestivalUnavailability(false);
    });
    watch(festivalActionUrl, async ()=>{
        await setFestivalDate();
    });
    return {
        activePack,
        allPacks,
        festivalDate,
        isBeforeStart,
        isFestivalUnavailable,
        isLoggedIn,
        isPacksLoaded,
        promotionUpdatedData,
        promotions,
        rewards,
        adventCalendarDoButtonMutationPromotion,
        adventCalendarLeaderBoardRequest,
        calculatePack,
        collectPackReward,
        fetchAllPacks,
        fetchPack,
        loadPromotions,
        updatePromotionDetails
    };
});
export default useAdventStore;
