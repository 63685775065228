import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import { computed, toRef } from 'vue';
import { IconDirection, IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useRunnersSettings, useMainMarketSettings } from 'web/src/modules/sportline/composables/settings';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { useMarketColumns, useSportlineEventLink } from 'web/src/modules/sportline/composables/list';
import { useInjectSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
import { useMarketRunners, useSlipCaption } from 'web/src/modules/sportline/views/runner/composables';
import InListRunner from 'web/src/modules/sportline/views/runner/InListRunner.vue';
import { useMoreCaption, useSportlineExpandableEventsListItem } from './composables';
import MainMarketContainer from './components/MainMarketContainer.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventMainMarket',
    props: {
        sportlineEvent: {},
        sport: {},
        activeEventId: {},
        isExtended: {
            type: Boolean
        },
        isExtendedSportEventAllowed: {
            type: Boolean
        },
        basisKey: {}
    },
    emits: [
        "click",
        "extend-element",
        "collapse-element"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sportEvent = toRef(props, 'sportlineEvent');
        const sport = toRef(props, 'sport');
        const basisKey = toRef(props, 'basisKey');
        const sportEventId = computed(()=>sportEvent.value.id);
        const activeEventId = toRef(props, 'activeEventId', null);
        const markets = computed(()=>sportEvent.value.markets);
        const { isUsedMoreRunnersCount } = useRunnersSettings();
        const { doUseFullAlternativeMarketsList } = useMainMarketSettings();
        const { isOutright, sportlineType } = useSportlineEventType({
            sportEvent
        });
        const { href, onLinkClick } = useSportlineEventLink({
            sportEvent,
            activeEventId
        }, {
            onClick () {
                emit('click');
            }
        });
        const { selectedColumnId, selectedColumnSize, columns } = useInjectSelectedColumn({
            basisKey
        });
        const { marketColumnsCount, actualMarketType, actualMarketTypePriority, actualMarket } = useMarketColumns({
            sport,
            selectedColumnSize,
            selectedColumnId,
            columns,
            markets,
            doUseFullAlternativeMarketsList
        });
        const { runnersPlaces } = useMarketRunners({
            sport,
            market: actualMarket,
            marketType: actualMarketType
        });
        const { slipCaption } = useSlipCaption({
            sportlineEvent: sportEvent
        });
        const { hasPrimaryMarket, hasMore: isMoreRunnersExist, moreCaption } = useMoreCaption({
            sportEvent,
            actualMarket,
            isUsedMoreRunnersCount
        });
        const { isExpandButtonAvailable, isCollapseButtonAvailable, isLockedMoreAvailable, onMoreRunnersClick, onLessRunnersClick } = useSportlineExpandableEventsListItem({
            eventId: sportEventId,
            isMoreRunnersExist,
            hasPrimaryMarket,
            isExtended: toRef(props, 'isExtended', false),
            isExtendedSportEventAllowed: toRef(props, 'isExtendedSportEventAllowed', false)
        }, {
            onLinkClick,
            extendElementOpen () {
                emit('extend-element');
            },
            extendElementClose () {
                emit('collapse-element');
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(MainMarketContainer, {
                "actual-market": _unref(actualMarket),
                "actual-market-type": _unref(actualMarketType),
                "actual-market-type-priority": _unref(actualMarketTypePriority),
                "runners-places-count": _unref(runnersPlaces).length
            }, {
                default: _withCtx(()=>[
                        _unref(hasPrimaryMarket) ? (_openBlock(true), _createElementBlock(_Fragment, {
                            key: 0
                        }, _renderList(_unref(runnersPlaces), (runner, index)=>(_openBlock(), _createBlock(InListRunner, {
                                key: runner?.id ?? index,
                                runner: runner,
                                market: _unref(actualMarket),
                                "slip-caption": _unref(slipCaption),
                                "sport-event-id": sportEventId.value,
                                "sportline-type": _unref(sportlineType),
                                "is-outright-event": _unref(isOutright)
                            }, null, 8, [
                                "runner",
                                "market",
                                "slip-caption",
                                "sport-event-id",
                                "sportline-type",
                                "is-outright-event"
                            ]))), 128)) : _createCommentVNode("", true),
                        _unref(isExpandButtonAvailable) ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: _unref(href),
                            class: _normalizeClass({
                                [_ctx.$style['sportline-event-more-indicator']]: true,
                                [_ctx.$style['sportline-event-more-indicator--clickable']]: !_unref(hasPrimaryMarket),
                                [_ctx.$style['sportline-event-without-market-indicator']]: !_unref(hasPrimaryMarket),
                                [_ctx.$style['sportline-event-without-market-indicator--2-columns']]: !_unref(hasPrimaryMarket) && 2 === _unref(marketColumnsCount),
                                [_ctx.$style['sportline-event-without-market-indicator--3-columns']]: !_unref(hasPrimaryMarket) && 3 === _unref(marketColumnsCount)
                            }),
                            onClick: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(onMoreRunnersClick) && _unref(onMoreRunnersClick)(...args);
                            }, [
                                "prevent",
                                "stop"
                            ]))
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['sportline-event-more-indicator__more-caption'])
                            }, _toDisplayString(_unref(moreCaption)), 3),
                            _createVNode(_unref(VIcon), {
                                name: _unref(IconName).CHEVRON,
                                size: _unref(IconSize).SIZE_16,
                                direction: _unref(hasPrimaryMarket) ? _unref(IconDirection).SOUTH : _unref(IconDirection).RIGHT,
                                class: _normalizeClass(_ctx.$style['sportline-event-more-indicator__chevron'])
                            }, null, 8, [
                                "name",
                                "size",
                                "direction",
                                "class"
                            ])
                        ], 10, _hoisted_1)) : _unref(isCollapseButtonAvailable) ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: _normalizeClass({
                                [_ctx.$style['sportline-event-more-indicator']]: true,
                                [_ctx.$style['sportline-event-without-market-indicator']]: !_unref(hasPrimaryMarket),
                                [_ctx.$style['sportline-event-without-market-indicator__2-columns']]: !_unref(hasPrimaryMarket) && 2 === _unref(marketColumnsCount),
                                [_ctx.$style['sportline-event-without-market-indicator__3-columns']]: !_unref(hasPrimaryMarket) && 3 === _unref(marketColumnsCount),
                                [_ctx.$style['close-extended-event-button__holder']]: true
                            }),
                            onClick: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(onLessRunnersClick) && _unref(onLessRunnersClick)(...args);
                            }, [
                                "prevent",
                                "stop"
                            ]))
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['close-extended-event-button'])
                            }, [
                                _createVNode(_unref(VIcon), {
                                    name: _unref(IconName).CROSS,
                                    size: _unref(IconSize).SIZE_16
                                }, null, 8, [
                                    "name",
                                    "size"
                                ])
                            ], 2)
                        ], 2)) : _unref(isLockedMoreAvailable) ? (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            class: _normalizeClass({
                                [_ctx.$style['sportline-event-more-indicator']]: true,
                                [_ctx.$style['sportline-event-more-indicator--extended']]: _ctx.isExtended,
                                [_ctx.$style['sportline-event-without-market-indicator']]: !_unref(hasPrimaryMarket),
                                [_ctx.$style['sportline-event-without-market-indicator--2-columns']]: !_unref(hasPrimaryMarket) && 2 === _unref(marketColumnsCount),
                                [_ctx.$style['sportline-event-without-market-indicator--3-columns']]: !_unref(hasPrimaryMarket) && 3 === _unref(marketColumnsCount)
                            })
                        }, [
                            _unref(hasPrimaryMarket) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VIcon), {
                                key: 0,
                                name: _unref(IconName).LOCK,
                                size: _unref(IconSize).SIZE_24,
                                class: _normalizeClass(_ctx.$style['sportline-event-more-indicator__lock-icon'])
                            }, null, 8, [
                                "name",
                                "size",
                                "class"
                            ]))
                        ], 2)) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "actual-market",
                "actual-market-type",
                "actual-market-type-priority",
                "runners-places-count"
            ])), [
                [
                    _directive_auto_id,
                    'SportlineEventMainMarket'
                ]
            ]);
        };
    }
});
