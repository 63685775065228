import { toRef, watch } from 'vue';
import { useLexisNexisIntegrationStore } from '../store';
import { getLexisNexisSessionId as getSessionId } from '../api';
import { runApiProfile } from '../utils';
export const useLexisNexisIntegration = ()=>{
    // eslint-disable-next-line no-console
    const store = useLexisNexisIntegrationStore();
    return {
        isEnabled: toRef(()=>store.isEnabled),
        whenProfiledDuringRuntime: ()=>new Promise((resolve)=>{
                const stop = watch(()=>store.isProfiled, (isProfiled)=>{
                    if (isProfiled) {
                        stop();
                        resolve();
                    }
                });
            }),
        runApiProfile,
        async getSessionId () {
            store.customerLogin;
            return getSessionId({
                login: store.customerLogin
            });
        }
    };
};
