import { computed, toRef } from 'vue';
import { useFormatMoney } from '../../composables';
import { useCurrencyStore } from '../../store';
export default function useSmartCurrencyInput() {
    const formatMoney = useFormatMoney();
    const formattedCurrency = toRef(useCurrencyStore(), 'formattedCurrency');
    const isCurrencyOnRightSide = computed(()=>{
        const formattedValue = formatMoney(8);
        const index = formattedValue.indexOf('8');
        return 0 === index;
    });
    const currencyRightSign = computed(()=>isCurrencyOnRightSide.value ? formattedCurrency.value : '');
    const currencyLeftSign = computed(()=>isCurrencyOnRightSide.value ? '' : formattedCurrency.value);
    return {
        currencyLeftSign,
        currencyRightSign
    };
}
