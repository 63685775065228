import ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';
let FastTrackApiClient = class FastTrackApiClient extends ApplicationRestClient {
    constructor(){
        super({
            baseUrl: '',
            method: 'POST',
            credentials: 'same-origin',
            origin: ''
        });
    }
};
export { FastTrackApiClient as default };
