import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { CasinoLoyaltyProgramIconSizes, CasinoLoyaltyProgramProgressSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import CasinoLoyaltyProgramIcon from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramProgress',
    props: {
        isHideProgress: {
            type: Boolean
        },
        isHideBackground: {
            type: Boolean
        },
        progress: {},
        imageSrc: {},
        imageSrcNextLevel: {},
        isInactive: {
            type: Boolean
        },
        showImageNextLevel: {
            type: Boolean
        },
        size: {
            default: CasinoLoyaltyProgramProgressSizes.SIZE_42
        },
        hasShadow: {
            type: Boolean
        },
        image: {},
        circleColor: {},
        iconSizes: {
            default: CasinoLoyaltyProgramIconSizes.DEFAULT
        },
        isGradient: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const circleSize = computed(()=>{
            switch(props.size){
                case CasinoLoyaltyProgramProgressSizes.SIZE_92:
                    return CircleSize.SIZE_92;
                case CasinoLoyaltyProgramProgressSizes.SIZE_94:
                    return CircleSize.SIZE_94;
                case CasinoLoyaltyProgramProgressSizes.SIZE_112:
                    return CircleSize.SIZE_112;
                default:
                    return CircleSize.SIZE_42;
            }
        });
        function emitClick() {
            emit('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-loyalty-program-progress']]: true,
                    [_ctx.$style[`casino-loyalty-program-progress--${_ctx.size}`]]: _ctx.size !== _unref(CasinoLoyaltyProgramProgressSizes).SIZE_42,
                    [_ctx.$style['casino-loyalty-program-progress--has-shadow']]: !!_ctx.hasShadow,
                    [_ctx.$style['casino-loyalty-program-progress--has-no-image']]: !_ctx.imageSrc,
                    [_ctx.$style['casino-loyalty-program-progress--hide-background']]: _ctx.isHideProgress || _ctx.isHideBackground
                }),
                onClick: emitClick
            }, [
                _ctx.isHideProgress ? _createCommentVNode("", true) : (_openBlock(), _createBlock(VCircularProgress, {
                    key: 0,
                    class: "casino-loyalty-program-progress__progress",
                    percentage: _ctx.progress,
                    "circle-size": circleSize.value,
                    "circle-color": _ctx.circleColor,
                    "is-simple-progress": "",
                    "in-loyalty-program": ""
                }, null, 8, [
                    "percentage",
                    "circle-size",
                    "circle-color"
                ])),
                _createVNode(CasinoLoyaltyProgramIcon, {
                    "image-src": _ctx.showImageNextLevel && _ctx.imageSrcNextLevel ? _ctx.imageSrcNextLevel : _ctx.imageSrc,
                    "is-inactive": _ctx.isInactive,
                    size: _ctx.iconSizes,
                    image: _ctx.image,
                    "gradient-bg": _ctx.isGradient,
                    class: "casino-loyalty-program-progress__logo"
                }, null, 8, [
                    "image-src",
                    "is-inactive",
                    "size",
                    "image",
                    "gradient-bg"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramProgress'
                ]
            ]);
        };
    }
});
