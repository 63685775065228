import { logger } from '@leon-hub/logging';
import { isObject } from '@leon-hub/guards';
import { isCoreSportEventResponse } from 'web/src/modules/sportline/guards/rest';
export function logCorruptedEventsIds(events) {
    let message = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 'Event response error occurred and filtered';
    const ids = events.map((event)=>isObject(event) && 'id' in event ? event.id : null);
    logger.error(message, {
        ids
    });
}
export function filterCorruptedEvents(events, corruptedEventsHandler) {
    const corruptedList = [];
    const result = events.filter((event)=>{
        if (isCoreSportEventResponse(event, true)) return true;
        corruptedList.push(event);
        return false;
    });
    if (corruptedList.length > 0) corruptedEventsHandler?.(corruptedList);
    return result;
}
export function filterCorruptedEventsInResponse(response, corruptedEventsHandler) {
    const events = filterCorruptedEvents(response.events, corruptedEventsHandler);
    return {
        ...response,
        totalCount: events.length,
        events
    };
}
export function filterCorruptedEventsInChangesResponse(response, corruptedEventsHandler) {
    const data = filterCorruptedEvents(response.data, corruptedEventsHandler);
    return {
        ...response,
        totalCount: data.length,
        data
    };
}
