const extractCustomerFormErrors = (uiFormSchema, formData)=>uiFormSchema.fields.filter((field)=>null != field.error).reduce((accumulator, field)=>{
        const customFormCustomError = {
            message: field.error || 'Error',
            value: String(formData[field.id] || field.defaultValue)
        };
        return {
            ...accumulator,
            [field.id]: [
                customFormCustomError
            ]
        };
    }, {});
export default extractCustomerFormErrors;
