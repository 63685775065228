import { ref, nextTick, onBeforeUnmount, watch } from 'vue';
import { Timer } from '@leon-hub/utils';
import { useWindowResize } from '@leon-hub/browser-composables';
import { getCoreDropdownPropsFromElement } from '../utils';
import { useExternalScrollWatcher } from './useExternalScrollWatcher';
export default function useDropdownButton(props, options) {
    const isDropdownShown = ref(false);
    const dropdownButton = ref();
    const dropdownProps = ref(null);
    const setDropdownButton = (element)=>{
        dropdownButton.value = element;
    };
    const focusOnDropdownButton = ()=>{
        dropdownButton.value?.focus();
    };
    const updateDropdownProps = ()=>{
        if (!dropdownButton.value) return;
        dropdownProps.value = getCoreDropdownPropsFromElement(dropdownButton.value, {
            matchParentWidth: true
        });
    };
    const closeDropdown = async ()=>{
        isDropdownShown.value = false;
        await nextTick();
        focusOnDropdownButton();
    };
    const openDropdown = ()=>{
        if (isDropdownShown.value) return;
        updateDropdownProps();
        isDropdownShown.value = true;
    };
    let updatePositionTimer = 0;
    const clearUpdatePositionTimer = ()=>{
        Timer.clearTimeout(updatePositionTimer);
    };
    onBeforeUnmount(clearUpdatePositionTimer);
    const updateContainerPositionProps = ()=>{
        clearUpdatePositionTimer();
        if (!isDropdownShown.value) return;
        updatePositionTimer = Timer.setTimeout(()=>{
            updateDropdownProps();
        }, 40);
    };
    if (!options?.ignoreScroll) useExternalScrollWatcher(updateContainerPositionProps, updateContainerPositionProps);
    if (!options?.ignoreResize) useWindowResize(updateContainerPositionProps);
    watch(()=>props.autofocus, (value)=>{
        if (value) nextTick().then(()=>{
            focusOnDropdownButton();
        });
    }, {
        immediate: true
    });
    return {
        isDropdownShown,
        closeDropdown,
        openDropdown,
        focusOnDropdownButton,
        setDropdownButton,
        dropdownProps,
        dropdownButton
    };
}
