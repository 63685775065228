import RouteName from '@leon-hub/routing-config-names';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
export default function useLoadingError(router) {
    const { showDialog } = useDialogs();
    function showLoadingError(value, closeCallback) {
        const callback = closeCallback ?? (()=>{
            router.push({
                name: RouteName.HOME
            });
        });
        showDialog({
            presetName: PresetName.ALERT_ERROR,
            options: {
                confirmMessage: value.message,
                dataTestId: 'loading-error'
            }
        }).subscribe({
            [DialogAction.MODAL_CLOSE]: callback
        });
    }
    return {
        showLoadingError
    };
}
