import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 2,
    class: "component-wrapper"
};
import { computed, inject, watch } from 'vue';
import { useRoute } from 'vue-router';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { ModalProvidable } from 'web/src/modules/dialogs/enums';
import { setHashWithoutRouter } from 'web/src/utils/url/hash';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useBaseCmsContent } from 'web/src/modules/cms/components/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCmsContent',
    props: {
        cmsKey: {},
        cmsData: {},
        spintaxCms: {
            type: Boolean
        },
        seoCms: {
            type: Boolean
        },
        silent: {
            type: Boolean
        },
        doNotUpdateIfExists: {
            type: Boolean,
            default: true
        },
        noPadding: {
            type: Boolean
        },
        loader: {
            type: Boolean
        },
        stopHashAutoScroll: {
            type: Boolean
        }
    },
    emits: [
        "loaded",
        "click-link"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        /* eslint-disable vue/no-duplicate-attr-inheritance */ const props = __props;
        const emit = __emit;
        const route = useRoute();
        const bus = useEventsBus();
        const currentCmsKey = computed(()=>props.cmsKey || props.cmsData?.keys[0] || '');
        function goToHash() {
            if (props.stopHashAutoScroll) return;
            const { hash } = route;
            if (hash) {
                const modalContext = inject(ModalProvidable.modalContext, {
                    insideModal: false
                });
                bus.emit(BusEvent.SCROLL_TO_ELEMENT_ID, {
                    id: hash.slice(1),
                    inModal: modalContext.insideModal
                });
                if (modalContext.insideModal) setHashWithoutRouter('');
            }
        }
        const { content, isLoaded, className, loadData } = useBaseCmsContent(props, {
            currentCmsKey,
            onLoadCallback: ()=>{
                goToHash();
                emit('loaded');
            }
        });
        watch(()=>props.cmsKey, ()=>{
            loadData();
        });
        return (_ctx, _cache)=>_ctx.loader && !_unref(isLoaded) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                key: 0,
                class: "component-wrapper"
            })) : _unref(content) ? (_openBlock(), _createBlock(VDynamicContent, _mergeProps({
                key: 1
            }, _ctx.$attrs, {
                "is-cms-content": "",
                content: _unref(content),
                class: _unref(className),
                onClickLink: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('click-link'))
            }), null, 16, [
                "content",
                "class"
            ])) : (_openBlock(), _createElementBlock("div", _hoisted_1));
    }
});
