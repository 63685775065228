import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import OverlayType from './enums/OverlayType';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VOverlay',
    props: {
        type: {
            default: OverlayType.BLUR_L0
        },
        isFixed: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['overlay']]: true,
                    [_ctx.$style['overlay--fixed']]: props.isFixed,
                    [_ctx.$style[`overlay--type-${props.type}`]]: props.type
                }),
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('click'))
            }, null, 2)), [
                [
                    _directive_auto_id,
                    'VOverlay'
                ]
            ]);
        };
    }
});
