import { isNumber } from '@leon-hub/guards';
import handleMaskedValueChange from './handleMaskedValueChange';
export default function handleMaskedValuePaste(param) {
    let { event, currentValue, maskOptions } = param;
    event.preventDefault();
    const { target, clipboardData } = event;
    HTMLInputElement;
    const { selectionStart, selectionEnd } = target;
    isNumber(selectionStart) && isNumber(selectionEnd);
    const data = clipboardData?.getData('text') ?? '';
    return handleMaskedValueChange({
        target,
        selectionStart,
        selectionEnd,
        data,
        currentValue,
        maskOptions
    });
}
