import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = {
    key: 0
};
import { computed, toRef } from 'vue';
import { IconDirection, IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useMarketColumns, useSportlineEventLink } from 'web/src/modules/sportline/composables/list';
import { useInjectSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
import { useMainMarketSettings } from 'web/src/modules/sportline/composables/settings';
import { useMarketRunners, useSlipCaption } from 'web/src/modules/sportline/views/runner/composables';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import InListRunner from 'web/src/modules/sportline/views/runner/InListRunner.vue';
import MainMarketContainer from './components/MainMarketContainer.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineOutrightEventMainMarket',
    props: {
        sportlineEvent: {},
        sport: {},
        activeEventId: {},
        basisKey: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const sport = toRef(props, 'sport');
        const basisKey = toRef(props, 'basisKey');
        const activeEventId = toRef(props, 'activeEventId', null);
        const sportlineEventId = computed(()=>sportlineEvent.value.id);
        const markets = computed(()=>sportlineEvent.value.markets);
        const counter = computed(()=>sportlineEvent.value.moreCount.runners ?? 0);
        const { doUseFullAlternativeMarketsList } = useMainMarketSettings();
        const { isOutright, sportlineType } = useSportlineEventType({
            sportEvent: sportlineEvent
        });
        const { href, onLinkClick } = useSportlineEventLink({
            sportEvent: sportlineEvent,
            activeEventId
        }, {
            onClick () {
                emit('click');
            }
        });
        const { selectedColumnId, selectedColumnSize, columns } = useInjectSelectedColumn({
            basisKey
        });
        const { actualMarketType, actualMarket, actualMarketTypePriority } = useMarketColumns({
            sport,
            selectedColumnSize,
            selectedColumnId,
            columns,
            markets,
            doUseFullAlternativeMarketsList
        });
        const { runnersPlaces } = useMarketRunners({
            sport,
            market: actualMarket,
            marketType: actualMarketType
        });
        const { slipCaption } = useSlipCaption({
            sportlineEvent
        });
        const { onCatalogClick } = useSportlineCatalogClick({
            eventId: sportlineEventId
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(MainMarketContainer, {
                "actual-market": _unref(actualMarket),
                "actual-market-type": _unref(actualMarketType),
                "actual-market-type-priority": _unref(actualMarketTypePriority),
                "runners-places-count": _unref(runnersPlaces).length
            }, {
                default: _withCtx(()=>[
                        _unref(actualMarket) ? (_openBlock(true), _createElementBlock(_Fragment, {
                            key: 0
                        }, _renderList(_unref(runnersPlaces), (runner, index)=>(_openBlock(), _createBlock(InListRunner, {
                                key: runner?.id ?? index,
                                runner: runner,
                                market: _unref(actualMarket),
                                "slip-caption": _unref(slipCaption),
                                "sport-event-id": sportlineEventId.value,
                                "sportline-type": _unref(sportlineType),
                                "is-outright-event": _unref(isOutright),
                                "do-display-name": ""
                            }, null, 8, [
                                "runner",
                                "market",
                                "slip-caption",
                                "sport-event-id",
                                "sportline-type",
                                "is-outright-event"
                            ]))), 128)) : _createCommentVNode("", true),
                        (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: _unref(href),
                            class: _normalizeClass(_ctx.$style['more-indicator']),
                            onClick: _cache[0] || (_cache[0] = ($event)=>{
                                _unref(onCatalogClick)($event);
                                _unref(onLinkClick)($event);
                            })
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['sportline-event-markets-counter'])
                            }, [
                                counter.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(counter.value), 1)) : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['sportline-event-markets-counter__chevron'])
                                }, [
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(IconName).CHEVRON,
                                        direction: _unref(IconDirection).EAST,
                                        size: _unref(IconSize).SIZE_16
                                    }, null, 8, [
                                        "name",
                                        "direction",
                                        "size"
                                    ])
                                ], 2)
                            ], 2)
                        ], 10, _hoisted_1))
                    ]),
                _: 1
            }, 8, [
                "actual-market",
                "actual-market-type",
                "actual-market-type-priority",
                "runners-places-count"
            ])), [
                [
                    _directive_auto_id,
                    'SportlineOutrightEventMainMarket'
                ]
            ]);
        };
    }
});
