import { ref } from 'vue';
export function useSportlinePageIdentifier() {
    const pageIdentifier = ref({
        sportFamily: ''
    });
    function setPageIdentifier(id) {
        pageIdentifier.value = id;
    }
    return {
        pageIdentifier,
        setPageIdentifier
    };
}
