import { ref, computed } from 'vue';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { getBetlineLeagueStandings } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';
export function useLeagueStandingsStoreComposable(props) {
    const { isEnabled, expectedId } = props;
    const { isLoading, isExpectedIdLoaded, setIsLoading, setLastLoadedId } = useExpectedIdLoading({
        expectedId
    });
    const leagueStandings = ref(null);
    const isReady = computed(()=>!isEnabled.value || isExpectedIdLoaded.value);
    const isEmpty = computed(()=>0 === (leagueStandings.value?.length || 0));
    function clear() {
        leagueStandings.value = null;
    }
    async function fetchLeagueStandings(param) {
        let { silent } = param;
        if (!isEnabled.value) return;
        const leagueId = expectedId.value;
        if (!leagueId) return;
        setIsLoading(true);
        try {
            const result = await getBetlineLeagueStandings({
                leagueId
            }, {
                silent
            });
            leagueStandings.value = result?.standings ? [
                ...result.standings
            ] : null;
        } catch (error) {
            clear();
            if (!isStatisticNotFoundError(error)) throw error;
        } finally{
            setIsLoading(false);
            setLastLoadedId(leagueId);
        }
    }
    return {
        isLoading,
        isReady,
        isEmpty,
        leagueStandings,
        fetchLeagueStandings,
        clear
    };
}
