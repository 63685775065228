import { watch, computed, ref } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { InputEventType } from 'web/src/components/Input/enums';
import { formatInputDateToInputString, formatInputStringToInputDate } from './utils/formatDateMaskValue';
export default function useDateMaskInput(props, emit) {
    const { $translate } = useI18n();
    const inputValue = ref(formatInputDateToInputString(props.value || ''));
    const setInputValue = (value)=>{
        inputValue.value = value;
    };
    const syncValueProp = (value)=>{
        setInputValue(formatInputDateToInputString(value));
    };
    watch(()=>props.value, (value)=>{
        syncValueProp(value || '');
    });
    const pattern = '99.99.9999';
    const maxSymbols = pattern.length;
    const mask = computed(()=>{
        const placeholder = $translate('WEB2_DATE_INPUT_FORMAT').value;
        return {
            pattern,
            // if there is no translation set 'DD.MM.YYYY'
            placeholder: 'WEB2_DATE_INPUT_FORMAT' === placeholder ? 'DD.MM.YYYY' : placeholder
        };
    });
    const eventToEmit = computed(()=>({
            target: {
                value: formatInputStringToInputDate(inputValue.value),
                name: props.name || ''
            }
        }));
    const onInput = (param)=>{
        let { target } = param;
        setInputValue(target.value);
        emit(InputEventType.INPUT, eventToEmit.value);
    };
    const onChange = (param)=>{
        let { target } = param;
        setInputValue(target.value);
        emit(InputEventType.CHANGE, eventToEmit.value);
    };
    const onFocus = ()=>{
        emit(InputEventType.FOCUS, eventToEmit.value);
    };
    const onBlur = ()=>{
        emit(InputEventType.BLUR, eventToEmit.value);
    };
    return {
        inputValue,
        mask,
        maxSymbols,
        onInput,
        onChange,
        onFocus,
        onBlur
    };
}
