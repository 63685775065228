import { computed } from 'vue';
const defaultAnimationProps = {
    duration: 15000,
    timeRange: {
        min: 900,
        max: 900
    },
    stepRange: {
        min: 0.01,
        max: 0.1
    },
    isInfinite: true
};
export default function useEgsJackpotAnimatedAmount(props) {
    const amountAnimatorProps = computed(()=>({
            ...defaultAnimationProps,
            duration: props.jackpotAnimationDuration || defaultAnimationProps.duration,
            amount: props.value
        }));
    return {
        amountAnimatorProps
    };
}
