import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { doActivateAffiliateProgram, doCreateBonusCode, getAffiliatedCustomers, getBonusCodes, getGeneralStats } from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { dateRangeFilterOptionsList } from 'web/src/modules/affiliates/store/utils';
import { DateRangeDefaultDay } from 'web/src/modules/affiliates/store/enums';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useUserStore } from 'web/src/modules/user/store';
const useAffiliateStore = defineStore('affiliate', ()=>{
    const api = useGraphqlClient();
    const userStore = useUserStore();
    /** state */ const affiliatedCustomers = ref();
    const lastRegisteredAffiliatedCustomers = ref();
    const isAffiliatedCustomersLoading = ref(false);
    const stats = ref();
    const operationsHistory = ref();
    const profileOperations = ref([]);
    const selectedOperation = ref();
    const selectedOperationsFilterRef = ref();
    const bonusCodesLoaded = ref(false);
    const bonusCodes = ref([]);
    const maxBonusCodesCount = ref(100);
    const addBonusCodeError = ref('');
    const lastAddedBonusCode = ref('');
    const codeAddedSuccessfully = ref(false);
    /** getters */ const bonusCodesCount = computed(()=>bonusCodes.value.length);
    const dateRangeFilterOptions = computed(()=>dateRangeFilterOptionsList());
    const defaultDateRangeFilterOption = computed(()=>{
        const daysRange = DateRangeDefaultDay.ThirtyDays;
        const defaultRangeFilterOption = dateRangeFilterOptions.value.find((os)=>os.value === daysRange);
        return defaultRangeFilterOption;
    });
    const selectedOperationsFilter = computed(()=>selectedOperationsFilterRef.value ?? defaultDateRangeFilterOption.value);
    function setAffiliatedCustomers(payload) {
        affiliatedCustomers.value = payload;
    }
    function setLastRegisteredAffiliatedCustomers(payload) {
        lastRegisteredAffiliatedCustomers.value = payload.slice(0, 5);
    }
    function setAffiliateCustomersLoader(isLoading) {
        isAffiliatedCustomersLoading.value = isLoading;
    }
    function setStats(statsValue) {
        stats.value = statsValue;
    }
    function setOperationsHistory(operationsHistoryValue) {
        operationsHistory.value = operationsHistoryValue;
    }
    function setProfileOperations(operations) {
        profileOperations.value = operations;
    }
    function setSelectedOperation(operationToSelected) {
        selectedOperation.value = operationToSelected;
    }
    function setSelectedOperationsFilter(filter) {
        selectedOperationsFilterRef.value = filter;
    }
    function setBonusCodeLoaded(isLoaded) {
        bonusCodesLoaded.value = isLoaded;
    }
    function setBonusCodes() {
        let bonusCodesValue = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
        bonusCodes.value = bonusCodesValue;
    }
    function setMaxBonusCodesCount(count) {
        maxBonusCodesCount.value = count;
    }
    function setAddBonusCodeError() {
        let errorText = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        addBonusCodeError.value = errorText;
    }
    function setLastAddedBonusCode(code) {
        lastAddedBonusCode.value = code;
    }
    function setCodeAddedSuccessfully(isAdded) {
        codeAddedSuccessfully.value = isAdded;
    }
    async function fetchGeneralStats(param) {
        let { page = 0, range = '1' } = param;
        const queryVariables = {
            options: {
                page,
                range
            }
        };
        return getGeneralStats(api, (node)=>node.queries.affiliate.getGeneralStats, queryVariables);
    }
    async function getProfileGeneralStats(param) {
        let { page = 0, range = '1' } = param;
        const result = await fetchGeneralStats({
            page,
            range
        });
        setStats(result.stats);
        setProfileOperations(result.operations);
    }
    async function getOperationsHistory(param) {
        let { page = 0, range = '1' } = param;
        const result = await fetchGeneralStats({
            page,
            range
        });
        setOperationsHistory({
            operations: result.operations,
            totalCount: result.totalCount
        });
    }
    async function doActivation(payload) {
        const response = await doActivateAffiliateProgram(api, (node)=>node.mutations.affiliate.activateAffiliateProgram, {
            options: {
                ...payload
            }
        });
        if ('OK' === response.result) await userStore.loadCustomerData(true);
    }
    function requestBonusCodes() {
        return getBonusCodes(api, (node)=>node.queries.affiliate.getBonusCodes, {});
    }
    async function getBonusCodesHandler() {
        const response = await requestBonusCodes();
        setBonusCodeLoaded(true);
        if (response) {
            setBonusCodes(response.bonusCodes);
            setMaxBonusCodesCount(response.maxCount);
        }
    }
    async function createBonusCode(bonusCode) {
        setAddBonusCodeError('');
        setLastAddedBonusCode(bonusCode);
        try {
            const result = await doCreateBonusCode(api, (node)=>node.mutations.affiliate.createBonusCode, {
                options: {
                    bonusCode
                }
            });
            setCodeAddedSuccessfully(true);
            return !!result;
        } catch (rawError) {
            const error = normalizeError(rawError);
            setAddBonusCodeError(error.message);
            return false;
        }
    }
    function clearSelectedOperation() {
        setSelectedOperation(void 0);
    }
    async function fetchAffiliateCustomers(options) {
        try {
            setAffiliateCustomersLoader(true);
            const data = await getAffiliatedCustomers(api, (node)=>node.queries.affiliate.getAffiliatedCustomers, {
                options
            });
            setAffiliatedCustomers(data.affiliatedCustomers);
            if (!lastRegisteredAffiliatedCustomers.value?.length) setLastRegisteredAffiliatedCustomers(data.affiliatedCustomers);
            setAffiliateCustomersLoader(false);
        } catch (error) {
            setAffiliateCustomersLoader(false);
            logger.error('fetchAffiliateCustomers', error);
        }
    }
    return {
        stats,
        bonusCodes,
        bonusCodesCount,
        maxBonusCodesCount,
        bonusCodesLoaded,
        isAffiliatedCustomersLoading,
        affiliatedCustomers,
        lastRegisteredAffiliatedCustomers,
        selectedOperation,
        operationsHistory,
        dateRangeFilterOptions,
        selectedOperationsFilter,
        lastAddedBonusCode,
        getProfileGeneralStats,
        addBonusCodeError,
        getBonusCodesHandler,
        fetchAffiliateCustomers,
        clearSelectedOperation,
        getOperationsHistory,
        setSelectedOperationsFilter,
        setSelectedOperation,
        doActivation,
        createBonusCode
    };
});
export default useAffiliateStore;
