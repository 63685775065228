import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { useAccessAddBookmarkRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccessAddBookmarkRoutePage',
    setup (__props) {
        const { created } = useAccessAddBookmarkRoutePage();
        created();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createVNode(VCmsContent, {
                    class: _normalizeClass(_ctx.$style['access-option-content']),
                    "cms-key": "WEB2_ACCESS_BOOKMARK",
                    silent: ""
                }, null, 8, [
                    "class"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'AccessAddBookmarkRoutePage'
                ]
            ]);
        };
    }
});
