import { ref, watch, computed } from 'vue';
import { unrefElement } from '../refs';
import { onComponentDeactivated, onComponentActivated } from '../lifecycle';
export function useControllableResizeObserver(element, callback, options) {
    if ('undefined' == typeof ResizeObserver) return {
        isEnabled: ref(false),
        start: ()=>{},
        stop: ()=>{}
    };
    let observer;
    let stopWatch;
    const isEnabled = ref(false);
    const target = computed(()=>unrefElement(element));
    function disconnectObserver() {
        if (observer) {
            observer.disconnect();
            observer = void 0;
        }
    }
    function connectObserver() {
        if (!target.value) return;
        observer = new ResizeObserver((entries)=>{
            callback(entries);
        });
        observer.observe(target.value, options);
    }
    function start() {
        stopWatch = stopWatch ?? watch(target, (newValue)=>{
            if (newValue) connectObserver();
            else disconnectObserver();
        }, {
            immediate: true
        });
        isEnabled.value = true;
    }
    function stop() {
        disconnectObserver();
        stopWatch?.();
        stopWatch = void 0;
        isEnabled.value = false;
    }
    return {
        isEnabled,
        start,
        stop
    };
}
export function useLifecycleResizeObserver(element, callback, options) {
    const { start, stop } = useControllableResizeObserver(element, callback, options);
    onComponentActivated(start);
    onComponentDeactivated(stop);
}
