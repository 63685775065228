import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { getLocationOrigin } from '@leon-hub/service-locator-env';
export var CameraAccessMode;
var CameraAccessMode1;
(CameraAccessMode1 = CameraAccessMode || (CameraAccessMode = {}))[CameraAccessMode1["ASK"] = 0] = "ASK", CameraAccessMode1[CameraAccessMode1["ALLOWED"] = 1] = "ALLOWED", CameraAccessMode1[CameraAccessMode1["DENIED"] = 2] = "DENIED";
let MediaUtils = class MediaUtils {
    static async hasCamera() {
        if (void 0 === navigator.mediaDevices?.enumerateDevices) return false;
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.some((device)=>'videoinput' === device.kind);
    }
    static isSupportedByBrowser() {
        return !!navigator.mediaDevices && 'undefined' != typeof MediaRecorder && !!MediaRecorder;
    }
    static async isSupported() {
        if (!MediaUtils.isSupportedByBrowser()) return false;
        return MediaUtils.hasCamera();
    }
    async getStream() {
        if (this.stream) return this.stream;
        this.stream = await navigator.mediaDevices.getUserMedia({
            video: true
        });
        return this.stream;
    }
    stopStream() {
        if (this.stream) {
            for (const track of this.stream.getTracks())track.stop();
            this.stream = void 0;
        }
    }
    static async playSound(name) {
        try {
            const audio = new Audio(`${getLocationOrigin()}/sounds/${name}`);
            if (audio) await audio.play();
        } catch  {}
    }
    constructor(){
        _define_property(this, "stream", void 0);
    }
} // empty
;
export { MediaUtils as default };
