import { getBalance as getBalanceSdk } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function useCustomerFinanceApi() {
    const apiClient = useGraphqlClient();
    function getBalance(silent) {
        return getBalanceSdk(apiClient, (node)=>node.queries.finance.getBalance, void 0, {
            silent
        });
    }
    return {
        getBalance
    };
}
