import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeMount, provide } from 'vue';
import { Tag } from '@leon-hub/tags';
import { useVAccordion } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VAccordion',
    props: {
        tag: {
            default: Tag.SECTION
        },
        panels: {
            type: Boolean
        },
        indicators: {
            type: [
                Boolean,
                Object,
                Array
            ],
            default: true
        },
        independentItems: {
            type: Boolean
        },
        controlled: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { isList, accordionSharedState, beforeComponentMount } = useVAccordion(props);
        onBeforeMount(beforeComponentMount);
        provide('accordionSharedState', accordionSharedState);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
                class: _normalizeClass({
                    accordion: true,
                    'accordion--list': _unref(isList)
                })
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'VAccordion'
                ]
            ]);
        };
    }
});
