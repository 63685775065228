import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';
import { isResolveVirtualIdsResponse } from './guards';
export default function createVirtualSportApi() {
    const client = useBaseApiClient();
    return {
        async resolveVirtualIds (input) {
            return client.request({
                id: 'betline/resolve-virtual-ids',
                endpoint: '/betline/resolve-virtual-ids',
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                query: {
                    marketId: encodeURIComponent(input.marketId),
                    matchId: encodeURIComponent(input.matchId),
                    odds: void 0 !== input.odds ? encodeURIComponent(input.odds) : void 0,
                    outcomeId: encodeURIComponent(input.outcomeId),
                    // @TODO marketSpecifier send undefined after api fix
                    marketSpecifier: void 0 !== input.marketSpecifier ? encodeURIComponent(input.marketSpecifier) : '',
                    tournament: void 0 !== input.sport ? encodeURIComponent(input.sport) : void 0
                },
                guard: isResolveVirtualIdsResponse
            });
        }
    };
}
