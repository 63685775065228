import { isOptionalString } from '@leon-hub/guards';
function getErrorFields(options, errorText) {
    const error = options?.isHintRight ? void 0 : options?.error || errorText;
    isOptionalString(error);
    const errorRight = options?.isHintRight && errorText || options?.errorRight;
    isOptionalString(errorRight);
    return {
        error,
        errorRight
    };
}
export default getErrorFields;
