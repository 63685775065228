function isNotEmpty(value) {
    return !!value;
}
export function convertBetlineToSportlineMatchStatisticsPlayer(response) {
    if (!response) return null;
    const { shirtNumber, playerName, playerShortName } = response;
    return {
        shirtNumber,
        playerName,
        playerShortName
    };
}
export function convertBetlineToSportlineMatchStatisticsCard(response) {
    if (!response) return null;
    const { team, time, color, playerDto } = response;
    return {
        team,
        time,
        color,
        playerDto: convertBetlineToSportlineMatchStatisticsPlayer(playerDto)
    };
}
export function convertBetlineToSportlinePostMatchStatistics(response) {
    if (false === response) return false;
    if (!response) return null;
    const { id, matchDate, family, cards, substitutions, teams, resultScore, goals, scores, periods } = response;
    return {
        id,
        matchDate,
        family,
        cards: cards?.map(convertBetlineToSportlineMatchStatisticsCard).filter(isNotEmpty) ?? [],
        substitutions,
        teams,
        resultScore,
        goals,
        scores,
        periods
    };
}
