import { isBoolean, isNumber, isUndefined, isValidInterface } from '@leon-hub/guards';
import { isCoreSportResponse } from './isCoreSportResponse';
import { isCoreSportReferenceResponse } from './isCoreSportReferenceResponse';
import { isMinimalWorkingLeagueResponseFieldGuards } from './isMinimalWorkingLeagueResponse';
export const isCoreLeagueResponseFieldGuards = {
    id: isMinimalWorkingLeagueResponseFieldGuards.id,
    region: isMinimalWorkingLeagueResponseFieldGuards.region,
    name: isMinimalWorkingLeagueResponseFieldGuards.name,
    nameDefault: isMinimalWorkingLeagueResponseFieldGuards.nameDefault,
    sport: [
        isCoreSportReferenceResponse,
        isCoreSportResponse
    ],
    prematch: [
        isNumber,
        isUndefined
    ],
    inplay: [
        isNumber,
        isUndefined
    ],
    outright: [
        isNumber,
        isUndefined
    ],
    top: [
        isBoolean,
        isUndefined
    ],
    topOrder: [
        isNumber,
        isUndefined
    ],
    weight: [
        isNumber,
        isUndefined
    ],
    url: isMinimalWorkingLeagueResponseFieldGuards.url,
    hasZeroMarginEvents: [
        isBoolean,
        isUndefined
    ]
};
export function isCoreLeagueResponse(value) {
    return isValidInterface(value, isCoreLeagueResponseFieldGuards);
}
