import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
const affiliateTitle = '{{$t(\'WEB2_AFFILIATE_PROGRAM\')}}';
export class AffiliatesModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addProfileRoute({
            name: RouteName.AFFILIATE_PROFILE,
            path: '/affiliate-profile',
            component: ()=>import('web/src/modules/affiliates/pages/AffiliateProfileRoutePage/AffiliateProfileRoutePage.vue'),
            title: affiliateTitle,
            topBarProps: {
                hidePrefix: true
            }
        });
        router.addProfileRoute({
            name: RouteName.AFFILIATE_LINK_HELP,
            path: '/how-to-use-affiliate-link',
            component: ()=>import('web/src/modules/affiliates/pages/AffiliateLinkHelpRoutePage/AffiliateLinkHelpRoutePage.vue'),
            title: '{{$t(\'WEB2_AFFILIATE_LINK_HELP_CAPTION\')}}'
        });
        router.addProfileRoute({
            name: RouteName.AFFILIATE_BONUS_CODES_HELP,
            path: '/affiliate-bonus-codes/how-to-use-bonus-codes',
            component: ()=>import('web/src/modules/affiliates/pages/AffiliateBonusCodesHelpRoutePage/AffiliateBonusCodesHelpRoutePage.vue'),
            title: '{{$t(\'WEB2_BONUS_CODES_HELP_CAPTION\')}}'
        });
        router.addProfileRoute({
            name: RouteName.AFFILIATE_BONUS_CODES,
            path: '/affiliate-bonus-codes',
            component: ()=>import('web/src/modules/affiliates/pages/AffiliateBonusCodesRoutePage/AffiliateBonusCodesRoutePage.vue'),
            title: '{{$t(\'WEB2_BONUS_CODES_MANAGEMENT\')}}'
        });
        router.addProfileRoute({
            name: RouteName.AFFILIATE_REGISTRATIONS,
            path: '/affiliate-registrations',
            component: ()=>import('web/src/modules/affiliates/pages/AffiliateRegistrationsRouteComponent/AffiliateRegistrationsRouteComponent.vue'),
            title: '{{$t(\'WEB2_AFFILIATE_REG_HISTORY\')}}'
        });
        router.addProfileRoute({
            name: RouteName.AFFILIATE_HISTORY_OPERATIONS,
            path: '/affiliate-history-operations',
            component: ()=>import('web/src/modules/affiliates/submodules/operations-history/pages/OperationsHistoryMobile/OperationsHistoryMobile.vue'),
            title: '{{$t(\'WEB2_AFFILIATE_OPERATIONS_TITLE\')}}',
            topBar: ()=>import('web/src/modules/affiliates/pages/AffiliateOperationsTopBarRouteComponent/AffiliateOperationsTopBarRouteComponent.vue')
        });
        router.addProfileRoute({
            name: RouteName.AFFILIATE_HISTORY_OPERATIONS_DETAILS,
            path: '/affiliate-history-operations/:operationId',
            component: ()=>import('web/src/modules/affiliates/submodules/operations-history/pages/OperationHistoryDetailsRoutePage/OperationHistoryDetailsRoutePage.vue'),
            title: '{{$t(\'WEB2_AFFILIATE_HISTORY_DETAILS_TITLE\')}}',
            // eslint-disable-next-line max-len
            topBar: ()=>import('web/src/modules/profile/submodules/customer-history/layouts/CustomerHistoryTopBarRouteComponent/CustomerHistoryTopBarRouteComponent.vue')
        });
        router.addModalRoute({
            name: RouteName.AFFILIATE_PROFILE_MIGRATED,
            path: '/affiliate-profile-migrated',
            access: CustomerRouteAccessRole.AUTHORIZED,
            modalPreset: ModalDesktopPreset.SmallDesktopModal,
            component: ()=>import('web/src/modules/affiliates/pages/AffiliateProfileMigratedRoutePage/AffiliateProfileMigratedRoutePage.vue')
        });
        "1";
        router.addDefaultRoute({
            name: RouteName.AFFILIATE_PROGRAM,
            path: '/affiliate-program',
            component: ()=>import('web/src/modules/affiliates/pages/AffiliateProgramRoutePage/AffiliateProgramRoutePage.vue'),
            leftSideBar: ()=>import('web/src/modules/sidebar/views/SidebarMenuKnowHowRouteComponent/SidebarMenuKnowHowRouteComponent.vue'),
            title: affiliateTitle
        });
    }
}
