import { isString, isObject } from '@leon-hub/guards';
import { FormControlType } from 'web/src/components/Form/enums';
import { isPartialFormPhoneValue, isFormWidget } from 'web/src/components/Form/guards';
function modifyFieldPropertyTitleToLabel(properties) {
    const { title } = properties;
    isString(title);
    let options = properties.options ?? {};
    isObject(options);
    if (title) options = {
        label: title,
        ...options
    };
    return {
        ...properties,
        ...Object.keys(options).length ? {
            options
        } : {}
    };
}
function modifyFieldPropertiesForSelect(properties) {
    const shouldReplaceDefaultValue = properties.default && '-' === properties.default;
    return shouldReplaceDefaultValue ? {
        ...properties,
        default: ''
    } : properties;
}
function modifyFieldPropertiesForPhone(properties) {
    const defaultValue = properties.default ?? {
        prefix: '',
        suffix: ''
    };
    isPartialFormPhoneValue(defaultValue);
    return {
        ...properties,
        default: defaultValue
    };
}
/** modify properties by widget type */ export default function modifyFieldPropertiesByWidget(properties) {
    const { widget } = properties;
    isFormWidget(widget);
    switch(widget){
        case FormControlType.Date:
        case FormControlType.DropListSelect:
            return modifyFieldPropertyTitleToLabel(properties);
        case FormControlType.Select:
        case FormControlType.SelectWithSearch:
            return modifyFieldPropertiesForSelect(properties);
        case FormControlType.Phone:
        case FormControlType.PhoneInput:
        case FormControlType.PhoneCountriesSelectorInput:
            return modifyFieldPropertiesForPhone(properties);
        default:
            return properties;
    }
}
