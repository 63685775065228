import { computed, ref, toRef } from 'vue';
import { Timer } from '@leon-hub/utils';
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import { FormControlType } from 'web/src/components/Form/enums';
import { RestorePasswordFieldName } from 'web/src/modules/restore-password/enums';
import { useFormsStore } from 'web/src/modules/forms/store';
import { CaptchaRequesterStrategy } from 'web/src/modules/captcha/store/enums';
import { getJumbotronProps, JumbotronIconKind } from 'web/src/components/Jumbotron';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
export default function useRestorePasswordByEmailInfo(props, emits) {
    const { $translate } = useI18n();
    const restorePasswordStore = useRestorePasswordStore();
    const restoreBySendLinkToEmail = toRef(useFormsStore(), 'restoreBySendLinkToEmail');
    const captchaSettings = toRef(restorePasswordStore, 'captchaSettings');
    const resendForm = ref();
    const tooltipLabel = ref(null);
    const iconProperties = {
        ...getJumbotronProps({
            alertIcon: AlertIconName.Email,
            iconSize: IconSize.SIZE_80,
            icon: IconName.CHECK_OUTLINE
        }),
        iconKind: JumbotronIconKind.SUCCESS
    };
    const currentEmail = computed(()=>restorePasswordStore.currentEmail || '');
    // reuse send link to mail form
    // eslint-disable-next-line rulesdir/class-method-use-this-regex
    const formSchema = computed(()=>restoreBySendLinkToEmail.value ?? {
            properties: {}
        });
    // we need full uiSchema to create captcha flow elements in form
    const uiSchema = computed(()=>({
            submitButton: null,
            fields: {
                // we will use an email from the store but fill the field for a form validation
                [RestorePasswordFieldName.EMAIL]: {
                    hidden: true,
                    widget: FormControlType.Email,
                    default: currentEmail.value
                },
                [RestorePasswordFieldName.CAPTCHA_TOKEN]: {
                    hidden: true,
                    widget: FormControlType.Captcha,
                    options: {
                        captchaRequesterStrategy: CaptchaRequesterStrategy.RESTORE_PASSWORD,
                        captchaEnabled: captchaSettings.value.isEnabledToShow,
                        reCaptchaTheme: captchaSettings.value.theme,
                        isEnforced: captchaSettings.value.isEnforced
                    }
                }
            }
        }));
    function displaySuccessTooltip() {
        if (null !== tooltipLabel.value) return;
        tooltipLabel.value = $translate('WEB2_REQUEST_SUBMITTED').value;
        Timer.setTimeout(()=>{
            tooltipLabel.value = null;
        }, 1500);
    }
    function resendEmail() {
        if (props.isPin) emitSuccess();
        else resendForm.value?.submit();
    }
    function emitSubmit(formData) {
        emits('form-submit', {
            formOutput: formData,
            onSuccess: displaySuccessTooltip
        });
    }
    function emitInput(payload) {
        emits('form-input', payload);
    }
    function emitSuccess() {
        emits('success', {
            onSuccess: displaySuccessTooltip
        });
    }
    return {
        iconProperties,
        resendForm,
        tooltipLabel,
        currentEmail,
        formSchema,
        uiSchema,
        resendEmail,
        displaySuccessTooltip,
        emitSubmit,
        emitInput,
        emitSuccess
    };
}
