import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import useEgsBasePrefetch from 'web/src/modules/egs/composables/useEgsBasePrefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
let QuickBetsLobbyPrefetch = class QuickBetsLobbyPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const siteConfigStore = useSiteConfigStore();
        if (!siteConfigStore.fastGamesBlock?.isEnabled) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        const input = await useEgsBasePrefetch().prefetch(to, next);
        if (!input) return;
        next();
    }
};
export { QuickBetsLobbyPrefetch as default };
