import { toNumber, toPattern } from 'vanilla-masker';
function reverseInputDate(value) {
    return value.split('-').reverse().join('-');
}
export function formatInputStringToInputDate(value) {
    return value ? reverseInputDate(toPattern(value, '99-99-9999')) : value;
}
export function formatInputDateToInputString(value) {
    return value ? toNumber(reverseInputDate(value)) : value;
}
