import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue";
import { inject, ref } from 'vue';
import { ScrollbarProvidableContext } from '@components/v-scrollbar';
import VSearchInput from 'web/src/components/SearchInput/VSearchInput/VSearchInput.vue';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { useSearchRoutePage } from 'web/src/modules/search/pages/SearchRoutePage/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SearchRoutePage',
    props: {
        defaultTabIndex: {},
        shouldReplaceItemRoutes: {
            type: Boolean
        }
    },
    emits: [
        "item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const SearchTabsSwitcher = // eslint-disable-next-line max-len
        require('web/src/modules/search/submodules/casino/components/CasinoSearchTabsSwitcher/CasinoSearchTabsSwitcher.vue').default;
        const customScrollbarContext = inject(ScrollbarProvidableContext.ScrollbarContext, ref({
            isReady: true,
            scrollTop: 0
        }));
        const { autofocus, input, result, searchInputProperties, onInput, onClear, onFocus, selectedTabIndex, onActiveTabChanged, resultsComponent, resultsComponentProps, setSearchText, emitItemClick } = useSearchRoutePage(props, emits);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['search'])
            }, [
                _withDirectives(_createVNode(VSearchInput, _mergeProps(_unref(searchInputProperties), {
                    ref_key: "input",
                    ref: input,
                    autofocus: _unref(customScrollbarContext).isReady && _unref(autofocus),
                    "is-large": false,
                    class: _ctx.$style['search__input'],
                    onInput: _unref(onInput),
                    onClear: _unref(onClear)
                }), null, 16, [
                    "autofocus",
                    "is-large",
                    "class",
                    "onInput",
                    "onClear"
                ]), [
                    [
                        _directive_data_test,
                        {
                            el: 'search-input'
                        }
                    ]
                ]),
                (_openBlock(), _createBlock(_unref(SearchTabsSwitcher), {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['search__switcher']),
                    "active-tab": _unref(selectedTabIndex),
                    onChange: _unref(onActiveTabChanged)
                }, null, 8, [
                    "class",
                    "active-tab",
                    "onChange"
                ])),
                _unref(resultsComponent) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(resultsComponent)), _mergeProps({
                    key: 1
                }, _unref(resultsComponentProps), {
                    ref_key: "result",
                    ref: result,
                    onFocus: _unref(onFocus),
                    onSearchText: _unref(setSearchText),
                    onItemClick: _unref(emitItemClick)
                }), null, 16, [
                    "onFocus",
                    "onSearchText",
                    "onItemClick"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SearchRoutePage'
                ]
            ]);
        };
    }
});
