import { isArray } from '@leon-hub/guards';
// eslint-disable-next-line sonarjs/cognitive-complexity
export function extractTopLeaguesByCondition(listElement, condition, options) {
    if (!listElement) return [];
    const maxCount = options?.maxCount;
    const keepLeagueGuard = condition ?? (()=>true);
    const listElements = isArray(listElement) ? listElement : [
        listElement
    ];
    return listElements.flatMap((sportElement)=>{
        const result = [];
        const { sport } = sportElement;
        if (!sport) return result;
        for (const regionElement of sportElement?.regions ?? [])for (const leagueElement of regionElement?.leagues ?? []){
            if (!!keepLeagueGuard(leagueElement, regionElement, sportElement)) {
                result.push({
                    league: leagueElement.league,
                    region: regionElement.region,
                    sport,
                    counters: leagueElement.counters
                });
                if (maxCount && result.length >= maxCount) break;
            }
        }
        return result;
    });
}
