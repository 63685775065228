import { toNumber } from 'vanilla-masker';
import { computed, watch } from 'vue';
import { isString } from '@leon-hub/guards';
import { InputMode, TextInputTypes, CustomInputEventType } from '../../enums';
import { useInputCore, useHintParentProps, useMaskedInput } from '../../composables';
import { handleMaskedValuePaste, handleRegularInput, handleRegexpLimitedInput, handleRegexpLimitedPaste } from '../../composables/utils';
export default function useTextInput(props, emit, slots) {
    const { uniqueId, isEmpty, isFocus, isHover, inputValue, showClearButton, clearValue, onMouseOver, onMouseLeave, onFocus, onBlur, setInputValue, emitInput, emitChange } = useInputCore(props, emit, false);
    const { hintProperties, hasError } = useHintParentProps(props);
    const hasIconSuffix = computed(()=>!!props.suffixIconName || showClearButton.value);
    const inputType = computed(()=>{
        if (props.type === TextInputTypes.NUMBER) return TextInputTypes.TEL;
        return props.type ?? TextInputTypes.TEXT;
    });
    const inputMode = computed(()=>{
        switch(props.type){
            case TextInputTypes.TEL:
                return InputMode.TEL;
            case TextInputTypes.NUMBER:
                return InputMode.NUMERIC;
            case TextInputTypes.EMAIL:
                return InputMode.EMAIL;
            default:
                return InputMode.TEXT;
        }
    });
    const emitIconSuffixClick = ()=>{
        emit(CustomInputEventType.ICON_SUFFIX_CLICK);
    };
    const emitIconPrefixClick = ()=>{
        emit(CustomInputEventType.ICON_PREFIX_CLICK);
    };
    const emitFilled = ()=>{
        emit(CustomInputEventType.FILLED);
    };
    const onClear = ()=>{
        clearValue();
        emit(CustomInputEventType.CLEAR);
    };
    const maskOptions = computed(()=>{
        if (!props.mask) return;
        const defaultOptions = {
            placeholder: 'X'
        };
        return isString(props.mask) ? {
            ...defaultOptions,
            pattern: props.mask
        } : {
            ...defaultOptions,
            ...props.mask
        };
    });
    const { isMaskedPlaceholderHidden, formattedValue, maskedValue, getValueCutToPattern, getMaskedInputResult, getMaskedKeydownResult } = useMaskedInput({
        inputValue,
        isFocus,
        maskOptions
    });
    watch(()=>props.mask, ()=>{
        const nextValue = getValueCutToPattern();
        if (nextValue !== inputValue.value) {
            setInputValue(nextValue);
            emitInput();
        }
    });
    const inputMaxLength = computed(()=>{
        if (!props.maxSymbols && !maskOptions.value) return;
        if (props.maxSymbols && maskOptions.value) return Math.max(props.maxSymbols, maskOptions.value?.pattern.length);
        return props.maxSymbols ?? maskOptions.value?.pattern.length;
    });
    const onPropValueChange = (value)=>{
        if (inputValue.value !== value) setInputValue(maskOptions.value ? toNumber(value) : value);
    };
    watch(()=>props.value, (value)=>onPropValueChange(value ?? ''));
    const onKeyDown = (event)=>{
        emit('keydown', event);
        if (!maskOptions.value) return;
        const result = getMaskedKeydownResult(event);
        if (result && result.nextValue !== maskedValue.value) {
            setInputValue(result.nextValue);
            emitInput();
        }
    };
    const limitingRegexp = computed(()=>{
        if (props.regexp) return new RegExp(props.regexp, 'm');
        return null;
    });
    const onRegexpPaste = (event)=>{
        if (!limitingRegexp.value) return;
        const currentValue = inputValue.value;
        const nextValue = handleRegexpLimitedPaste({
            event,
            regexp: limitingRegexp.value,
            currentValue
        });
        setInputValue(nextValue);
        emitInput();
        if (nextValue !== currentValue && props.maxSymbols && nextValue.length >= props.maxSymbols) emitFilled();
    };
    const onMaskedPaste = (event)=>{
        if (!maskOptions.value) return;
        const result = handleMaskedValuePaste({
            event,
            maskOptions: maskOptions.value,
            currentValue: inputValue.value
        });
        if (result) {
            const { nextValue, isFilled } = result;
            setInputValue(nextValue);
            emitInput();
            if (isFilled) emitFilled();
        }
    };
    const onPaste = (event)=>{
        if (maskOptions.value) {
            onMaskedPaste(event);
            return;
        }
        if (limitingRegexp.value) onRegexpPaste(event);
    };
    // no custom handling by default
    const onRegularInput = (event)=>{
        let nextValue;
        nextValue = limitingRegexp.value ? handleRegexpLimitedInput({
            event,
            regexp: limitingRegexp.value,
            currentValue: inputValue.value
        }) : handleRegularInput(event);
        setInputValue(nextValue);
        emitInput();
        if (props.maxSymbols && inputValue.value.length >= props.maxSymbols) emitFilled();
    };
    const onMaskedInput = (event)=>{
        const result = getMaskedInputResult(event);
        if (result) {
            const { nextValue, isFilled } = result;
            setInputValue(nextValue);
            emitInput();
            if (isFilled) emitFilled();
        }
    };
    const onInput = (event)=>{
        if (maskOptions.value) onMaskedInput(event);
        else onRegularInput(event);
    };
    const clickablePrefixIcon = computed(()=>!!((props.prefixIconName ?? slots?.iconPrefix) && props.clickableIcon));
    const clickableSuffixIcon = computed(()=>showClearButton.value || !!((props.suffixIconName ?? slots?.iconSuffix) && props.clickableIcon));
    return {
        uniqueId,
        isEmpty,
        isFocus,
        isHover,
        inputValue,
        hintProperties,
        hasError,
        hasIconSuffix,
        inputType,
        inputMode,
        isMaskedPlaceholderHidden,
        showClearButton,
        formattedValue,
        maskedValue,
        inputMaxLength,
        clickablePrefixIcon,
        clickableSuffixIcon,
        emitIconSuffixClick,
        emitIconPrefixClick,
        onClear,
        onBlur,
        onFocus,
        onMouseOver,
        onMouseLeave,
        onKeyDown,
        onPaste,
        onChange: emitChange,
        onInput
    };
}
