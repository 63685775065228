import { computed, nextTick, onMounted, onUpdated } from 'vue';
export default function useLeaderBoardParticipant(props, emit) {
    const score = computed(()=>(props.participant?.score || 0).toFixed(2).toString());
    onMounted(async ()=>{
        await nextTick();
        emit('mounted');
    });
    onUpdated(()=>{
        emit('mounted');
    });
    return {
        score
    };
}
