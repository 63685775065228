import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { onWindowVisibilityChanged } from './onWindowVisibilityChanged';
export function useWindowVisibilityChanged(callback) {
    let hasActiveListener = false;
    const { addVisibilityChangeEventListener, removeVisibilityChangeEventListener } = onWindowVisibilityChanged(callback);
    function addEventListener() {
        if (!hasActiveListener) {
            addVisibilityChangeEventListener();
            hasActiveListener = true;
        }
    }
    function removeEventListener() {
        if (hasActiveListener) {
            removeVisibilityChangeEventListener();
            hasActiveListener = false;
        }
    }
    onComponentActivated(addEventListener);
    onComponentDeactivated(removeEventListener);
}
