import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventChampionshipMainInfo',
    props: {
        sportlineEvent: {}
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const championshipName = computed(()=>sportlineEvent.value.championshipName ?? '');
        const outrightName = computed(()=>sportlineEvent.value.outrightName ?? '');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportline-event-main-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-event-main-info__left-column'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sportline-event-championship'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sportline-event-championship__name'])
                        }, _toDisplayString(championshipName.value), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sportline-event-championship__market'])
                        }, _toDisplayString(outrightName.value), 3)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventChampionshipMainInfo'
                ]
            ]);
        };
    }
});
