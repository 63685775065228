import { computed } from 'vue';
export default function useVTabs(props) {
    const containerRole = computed(()=>props.isNavigation ? 'navigation' : 'tablist');
    const isIconTabs = computed(()=>{
        if (props.items?.length) {
            const tab = props.items[0];
            return tab && 'icon' in tab && !!tab.icon;
        }
        return false;
    });
    const activeTabIndex = computed(()=>{
        const { items, activeId } = props;
        return items.findIndex((item)=>item.id === activeId);
    });
    const itemsList = computed(()=>props?.items?.map((item)=>{
            const { isNavigation } = props;
            return {
                ...item,
                active: item.id === props.activeId,
                isNavigation
            };
        }) ?? []);
    return {
        activeTabIndex,
        containerRole,
        isIconTabs,
        itemsList
    };
}
