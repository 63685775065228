import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id",
    "name",
    "accept"
];
import { Tag } from '@leon-hub/tags';
import { IconName } from '@leon-hub/icons';
import FileInputPlaceholder from 'web/src/components/PhotoPlaceholder/VFileInputPlaceholder/VFileInputPlaceholder.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import InputHint from 'web/src/components/Input/components/InputHint';
import { defaultFileTypes } from '../../utils/defaultFileTypes';
import { useHintParentProps } from '../../composables';
import useFileMultipleDragAndDropInput from './useFileMultipleDragAndDropInput';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FileDragAndDropInput',
    props: {
        name: {
            default: 'fileDragAndDrop'
        },
        max: {},
        formErrors: {
            default: ()=>[]
        },
        fileTypes: {
            default: ()=>defaultFileTypes
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {}
    },
    emits: [
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { hintProperties } = useHintParentProps(props);
        const { errorList, placeholderPropsList, uniqueId, acceptedFileTypes, filesRecords, isInDropZone, addMoreAvailable, onLabelKeydown, removeByIndex, onChange, onBlur, onDragenter, onDragleave, onDragover, onDrop } = useFileMultipleDragAndDropInput(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['drag-and-drop-input']]: true,
                        [_ctx.$style['drag-and-drop-input--drag-over']]: _unref(isInDropZone),
                        [_ctx.$style['drag-and-drop-input--empty']]: 0 === _unref(filesRecords).length,
                        [_ctx.$style['drag-and-drop-input--error']]: !!_ctx.error || !!_ctx.errorRight || _unref(errorList)?.length > 0
                    }),
                    onDragenter: _cache[5] || (_cache[5] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onDragenter) && _unref(onDragenter)(...args);
                    }),
                    onDrop: _cache[6] || (_cache[6] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onDrop) && _unref(onDrop)(...args);
                    })
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(placeholderPropsList), (placeholderProps, index)=>(_openBlock(), _createBlock(FileInputPlaceholder, _mergeProps({
                            key: index,
                            class: [
                                _ctx.$style['drag-and-drop-input__item'],
                                _ctx.$style['drag-and-drop-input__item--placeholder']
                            ],
                            ref_for: true
                        }, placeholderProps, {
                            onClear: ($event)=>_unref(removeByIndex)(index)
                        }), null, 16, [
                            "class",
                            "onClear"
                        ]))), 128)),
                    _unref(addMoreAvailable) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass([
                            _ctx.$style['drag-and-drop-input__item'],
                            _ctx.$style['drag-and-drop-input__item--controls']
                        ])
                    }, [
                        _createVNode(VButton, {
                            class: _normalizeClass(_ctx.$style['drag-and-drop-input__add-button']),
                            kind: _unref(ButtonKind).BASE,
                            height: _unref(ButtonHeight).LARGE,
                            "icon-name": _unref(IconName).PLUS_SMALL,
                            tag: _unref(Tag).LABEL,
                            for: _unref(uniqueId),
                            rounded: ""
                        }, null, 8, [
                            "class",
                            "kind",
                            "height",
                            "icon-name",
                            "tag",
                            "for"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['drag-and-drop-input__text-primary'])
                        }, _toDisplayString(_ctx.$t('WEB2_ADD_FILE_INPUT_TEXT_PRIMARY')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['drag-and-drop-input__text-secondary'])
                        }, _toDisplayString(_ctx.$t('WEB2_ADD_FILE_INPUT_TEXT_SECONDARY')), 3),
                        _createVNode(VButton, {
                            class: _normalizeClass(_ctx.$style['drag-and-drop-input__add-button']),
                            label: _ctx.$t('WEB2_ADD_FILE_INPUT_BUTTON'),
                            kind: _unref(ButtonKind).PRIMARY,
                            tag: _unref(Tag).LABEL,
                            for: _unref(uniqueId),
                            "full-width": "",
                            tabindex: "0",
                            onKeydown: _withKeys(_unref(onLabelKeydown), [
                                "enter",
                                "space"
                            ])
                        }, null, 8, [
                            "class",
                            "label",
                            "kind",
                            "tag",
                            "for",
                            "onKeydown"
                        ]),
                        _createElementVNode("input", {
                            id: _unref(uniqueId),
                            name: _ctx.name,
                            class: _normalizeClass(_ctx.$style['drag-and-drop-input__input']),
                            type: "file",
                            multiple: "",
                            accept: _unref(acceptedFileTypes),
                            onChange: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(onChange) && _unref(onChange)(...args);
                            }, [
                                "stop"
                            ])),
                            onInput: _cache[1] || (_cache[1] = _withModifiers(()=>{}, [
                                "stop"
                            ])),
                            onBlur: _cache[2] || (_cache[2] = ($event)=>_unref(onBlur)(_unref(filesRecords).length))
                        }, null, 42, _hoisted_1)
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['drag-and-drop-input__drag-zone']),
                        onDragleave: _cache[3] || (_cache[3] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onDragleave) && _unref(onDragleave)(...args);
                        }),
                        onDragover: _cache[4] || (_cache[4] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onDragover) && _unref(onDragover)(...args);
                        })
                    }, null, 34)
                ], 34),
                _createVNode(_unref(InputHint), _normalizeProps(_guardReactiveProps(_unref(hintProperties))), {
                    hintLeft: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "hintLeft")
                        ]),
                    hintRight: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "hintRight")
                        ]),
                    errorLeft: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(errorList), (message, index)=>(_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: _normalizeClass(_ctx.$style['drag-and-drop-input__error'])
                                }, _toDisplayString(message), 3))), 128))
                        ]),
                    default: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "hint")
                        ]),
                    _: 3
                }, 16)
            ])), [
                [
                    _directive_auto_id,
                    'FileDragAndDropInput'
                ]
            ]);
        };
    }
});
