import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'VDottedLoader',
    props: {
        isPrimary: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("span", {
                class: _normalizeClass({
                    [_ctx.$style['dotted-loader']]: true,
                    [_ctx.$style['dotted-loader--primary']]: _ctx.isPrimary
                })
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['dotted-loader__dot'])
                }, null, 2),
                _createElementVNode("span", {
                    class: _normalizeClass([
                        _ctx.$style['dotted-loader__dot'],
                        _ctx.$style['dotted-loader__dot--second']
                    ])
                }, null, 2),
                _createElementVNode("span", {
                    class: _normalizeClass([
                        _ctx.$style['dotted-loader__dot'],
                        _ctx.$style['dotted-loader__dot--third']
                    ])
                }, null, 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VDottedLoader'
                ]
            ]);
        };
    }
});
