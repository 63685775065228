import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VAnimatedTrack from '../VAnimatedTrack/VAnimatedTrack.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLinearLoader',
    props: {
        hint: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['linear-loader']]: true,
                    [_ctx.$style['linear-loader--with-hint']]: !!props.hint
                })
            }, [
                _ctx.hint ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['linear-loader__hint'])
                }, _toDisplayString(_ctx.hint), 3)) : _createCommentVNode("", true),
                _createVNode(VAnimatedTrack)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VLinearLoader'
                ]
            ]);
        };
    }
});
