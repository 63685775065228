import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getWithdrawalStats } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { PaymentsStatus } from 'web/src/modules/payments/enums';
const usePaymentsStore = defineStore('payments', ()=>{
    const gqlClient = useGraphqlClient();
    const mobileRedirectUrl = ref('/');
    const uiFormSchema = ref(null);
    const withdrawalStatsData = ref(null);
    const lastDepositSystemsRequest = ref(null);
    const loadingError = ref(null);
    const paymentError = ref(null);
    const paymentsProcedureStatus = ref({
        action: null,
        status: null
    });
    function setPaymentsProcedureStatus(status) {
        paymentsProcedureStatus.value = {
            ...paymentsProcedureStatus.value,
            ...status
        };
    }
    function setLoadingError(value) {
        loadingError.value = value;
    }
    const mobileRedirectUrlFormatted = computed(()=>{
        const excludedUrls = [
            RouteName.IDENTIFICATION
        ];
        return excludedUrls.filter((url)=>mobileRedirectUrl.value.includes(url)).length ? '/' : mobileRedirectUrl.value;
    });
    function setPreviousPageUrl(value) {
        mobileRedirectUrl.value = value;
    }
    function errorHandler(error) {
        paymentError.value = error;
        if (paymentsProcedureStatus.value.action) setPaymentsProcedureStatus({
            status: PaymentsStatus.ERROR
        });
    }
    async function loadWithdrawalStats() {
        withdrawalStatsData.value = await getWithdrawalStats(gqlClient, (node)=>node.queries.finance.getWithdrawalStats, {
            options: {}
        }, {
            silent: true
        });
    }
    return {
        setLoadingError,
        errorHandler,
        setPaymentsProcedureStatus,
        mobileRedirectUrl,
        mobileRedirectUrlFormatted,
        uiFormSchema,
        lastDepositSystemsRequest,
        loadingError,
        withdrawalStatsData,
        loadWithdrawalStats,
        setPreviousPageUrl
    };
});
export default usePaymentsStore;
