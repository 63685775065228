import { computed } from 'vue';
import { VSpinnerSize } from '../enums';
export function useVSpinner(props) {
    const spinnerSize = computed(()=>props.size === VSpinnerSize.LARGE ? 52 : 24);
    const viewBox = computed(()=>`0 0 ${2 * spinnerSize.value} ${2 * spinnerSize.value}`);
    const circleSize = computed(()=>`${spinnerSize.value}px`);
    return {
        viewBox,
        circleSize
    };
}
