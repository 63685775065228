import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "disabled",
    "onClick"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { FastMoneyMaxPosition } from '../enums';
import { useFastMoneyChoice } from '../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FastMoneyChoice',
    props: {
        items: {
            default: ()=>[]
        },
        currentValue: {},
        showEditButton: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        maxEnabled: {
            type: Boolean
        },
        maxValue: {
            default: 0
        },
        maxAvailableValue: {},
        minAvailableValue: {
            default: 0
        },
        maxLabel: {
            default: 'MAX'
        },
        maxPosition: {
            default: FastMoneyMaxPosition.START
        },
        dense: {
            type: Boolean
        },
        isBordered: {
            type: Boolean
        },
        balance: {
            default: 0
        },
        currency: {},
        showBalance: {
            type: Boolean
        },
        hideUnavailableOptions: {
            type: Boolean
        }
    },
    emits: [
        "select-value",
        "click-value",
        "edit-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { selectValue, computedItems, formattedBalance } = useFastMoneyChoice(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['fast-money-choice'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['fast-money-choice__swiper'])
                }, [
                    _createCommentVNode("", true),
                    _createVNode(_unref(VSwiper), null, {
                        default: _withCtx(()=>[
                                _ctx.showEditButton ? (_openBlock(), _createBlock(VSwiperSlide, {
                                    key: 0,
                                    class: _normalizeClass({
                                        [_ctx.$style['fast-money-choice__slide']]: true,
                                        [_ctx.$style['fast-money-choice__slide--spaced']]: _ctx.isBordered,
                                        [_ctx.$style['fast-money-choice__slide--not-editable']]: !_ctx.isBordered && !_ctx.showEditButton
                                    })
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(VButton, {
                                                class: _normalizeClass(_ctx.$style['fast-money-choice__button-edit']),
                                                "icon-name": _unref(IconName).PENCIL,
                                                kind: _unref(ButtonKind).TRANSPARENT,
                                                onClick: _cache[0] || (_cache[0] = ($event)=>emit('edit-click'))
                                            }, null, 8, [
                                                "class",
                                                "icon-name",
                                                "kind"
                                            ])
                                        ]),
                                    _: 1
                                }, 8, [
                                    "class"
                                ])) : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedItems), (param, index)=>{
                                    let { isActive, value, label, isDisabled } = param;
                                    return _openBlock(), _createBlock(VSwiperSlide, {
                                        key: index,
                                        class: _normalizeClass({
                                            [_ctx.$style['fast-money-choice__slide']]: true,
                                            [_ctx.$style['fast-money-choice__slide--spaced']]: _ctx.isBordered,
                                            [_ctx.$style['fast-money-choice__slide--not-editable']]: !_ctx.isBordered && !_ctx.showEditButton
                                        })
                                    }, {
                                        default: _withCtx(()=>[
                                                _createElementVNode("button", {
                                                    class: _normalizeClass({
                                                        [_ctx.$style['fast-money-choice__button--active']]: isActive,
                                                        [_ctx.$style['fast-money-choice__button--disabled']]: isDisabled,
                                                        [_ctx.$style['fast-money-choice__button']]: true,
                                                        [_ctx.$style['fast-money-choice__button--bordered']]: _ctx.isBordered,
                                                        [_ctx.$style['fast-money-choice__button--active-bordered']]: _ctx.isBordered && isActive
                                                    }),
                                                    disabled: isDisabled,
                                                    type: "button",
                                                    onClick: ($event)=>_unref(selectValue)(value)
                                                }, _toDisplayString(label), 11, _hoisted_1)
                                            ]),
                                        _: 2
                                    }, 1032, [
                                        "class"
                                    ]);
                                }), 128))
                            ]),
                        _: 1
                    })
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'FastMoneyChoice'
                ]
            ]);
        };
    }
});
