import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SwiperNavigationButtonsBase from 'web/src/components/Swiper/Navigation/SwiperNavigationButtonsBase/SwiperNavigationButtonsBase.vue';
import { useVSwiperBannerNavigationButtons } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSwiperBannerNavigationButtons',
    setup (__props) {
        const { navigationProperties, onNextClick, onPreviousClick } = useVSwiperBannerNavigationButtons();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SwiperNavigationButtonsBase, _mergeProps(_unref(navigationProperties), {
                onClickPrevious: _unref(onPreviousClick),
                onClickNext: _unref(onNextClick)
            }), null, 16, [
                "onClickPrevious",
                "onClickNext"
            ])), [
                [
                    _directive_auto_id,
                    'VSwiperBannerNavigationButtons'
                ]
            ]);
        };
    }
});
