import { isObject } from '@leon-hub/guards';
import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk/src/sdk/sdk';
import { isSportFilterKickoffPeriod, isSportlineType } from 'web/src/modules/sportline/guards';
function isSportDateTimeFilter(value) {
    return isObject(value) && ('sportlineType' in value || 'activeKickoffPeriod' in value);
}
function isDateTimeRange(value) {
    return isObject(value) && 'to' in value && 'from' in value;
}
export function getSportDateTimeFilterByValue(value) {
    if (isSportlineType(value)) return {
        activeKickoffPeriod: SportFilterKickoffPeriod.ALL,
        sportlineType: value
    };
    if (isSportFilterKickoffPeriod(value)) return {
        activeKickoffPeriod: value
    };
    if (isDateTimeRange(value)) return {
        activeKickoffPeriod: SportFilterKickoffPeriod.CUSTOM,
        customDateFrom: value.from,
        customDateTo: value.to
    };
    if (isSportDateTimeFilter(value)) return value.sportlineType ? {
        activeKickoffPeriod: SportFilterKickoffPeriod.ALL,
        sportlineType: value.sportlineType
    } : {
        ...value
    };
    return null;
}
