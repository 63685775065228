import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import EgsJackpotAmount from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAmount/EgsJackpotAmount.vue';
import VSightAmountAnimator from 'web/src/components/AmountAnimator/VSightAmountAnimator/VSightAmountAnimator.vue';
import { useEgsJackpotAnimatedAmount } from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAnimatedAmount/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EgsJackpotAnimatedAmount',
    props: {
        currency: {},
        value: {},
        hasBackground: {
            type: Boolean
        },
        jackpotAnimationDuration: {},
        isPlainText: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { amountAnimatorProps } = useEgsJackpotAnimatedAmount(props);
        const formatMoney = useFormatMoney();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VSightAmountAnimator, _normalizeProps(_guardReactiveProps(_unref(amountAnimatorProps))), {
                default: _withCtx((param)=>{
                    let { animatedValue } = param;
                    return [
                        _ctx.isPlainText ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createTextVNode(_toDisplayString(_unref(formatMoney)(animatedValue, {
                                currency: _ctx.currency,
                                reduceAmount: 1000000000
                            })), 1)
                        ], 64)) : (_openBlock(), _createBlock(EgsJackpotAmount, {
                            key: 1,
                            currency: _ctx.currency,
                            value: animatedValue,
                            "has-background": _ctx.hasBackground
                        }, null, 8, [
                            "currency",
                            "value",
                            "has-background"
                        ]))
                    ];
                }),
                _: 1
            }, 16)), [
                [
                    _directive_auto_id,
                    'EgsJackpotAnimatedAmount'
                ]
            ]);
        };
    }
});
