import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { disableCustomerMode, enableCustomerMode, getAuthenticatorUriQRCode, resetDisposableKeys, getAuthenticatorUri as getAuthenticatorUriApi } from '@leon-hub/api-sdk/src/sdk/sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useUserStore } from 'web/src/modules/user/store';
const useTwoFactorAuthenticationStore = defineStore('two-factor-authentication', ()=>{
    /** state */ const secret = ref('');
    const reservedKeys = ref([]);
    const g2svLink = ref('');
    const qrCode = ref('');
    const currentStepTfa = ref(1);
    const { loadCustomerData } = useUserStore();
    const api = useGraphqlClient();
    /** getters */ const stringifyReservedKeys = computed(()=>reservedKeys.value.map((code)=>code.toString().replace(/(\d{4})/g, '$1 ').trim()));
    /** mutations */ const setSecret = (value)=>{
        secret.value = value;
    };
    const setReservedKeys = (value)=>{
        reservedKeys.value = value;
    };
    const setLink = (value)=>{
        g2svLink.value = value;
    };
    const setQRCode = (value)=>{
        qrCode.value = value;
    };
    const updateAppData = async ()=>{
        await loadCustomerData();
    };
    const enableTFA = async (payload)=>{
        const response = await enableCustomerMode(api, (node)=>node.mutations.g2sv.enableCustomerMode, {
            options: payload
        });
        const { disposableKeys } = response;
        if (response) setReservedKeys([
            ...disposableKeys
        ]);
    };
    const disableTFA = async (value)=>{
        const response = await disableCustomerMode(api, (node)=>node.mutations.g2sv.disableCustomerMode, {
            options: {
                code: value
            }
        });
        const { result } = response;
        if (result) await updateAppData();
    };
    const regenerateTFA = async (value)=>{
        const response = await resetDisposableKeys(api, (node)=>node.mutations.g2sv.resetDisposableKeys, {
            options: {
                code: value
            }
        });
        const { disposableKeys } = response;
        if (response) setReservedKeys([
            ...disposableKeys
        ]);
    };
    const getAuthenticatorUri = async ()=>{
        setQRCode('');
        const response = await getAuthenticatorUriApi(api, (node)=>node.queries.g2sv.getAuthenticatorUri);
        const { authenticatorUri, secret: secretResponse } = response;
        setLink(authenticatorUri);
        setSecret(secretResponse);
    };
    const getQRCode = async (options)=>{
        const response = await getAuthenticatorUriQRCode(api, (node)=>node.queries.g2sv.getQRCode, {
            options
        });
        const { qrCode: qrCodeResponse } = response;
        setQRCode(qrCodeResponse);
    };
    const resetReservedKeys = ()=>{
        setReservedKeys([]);
    };
    function setCurrentStepTfaPlus() {
        currentStepTfa.value += 1;
    }
    function setCurrentStepTfaMinus() {
        currentStepTfa.value -= 1;
    }
    function setCurrentStepTfaInit() {
        currentStepTfa.value = 1;
    }
    return {
        secret,
        reservedKeys,
        g2svLink,
        qrCode,
        stringifyReservedKeys,
        currentStepTfa,
        setSecret,
        setReservedKeys,
        setLink,
        setQRCode,
        enableTFA,
        disableTFA,
        regenerateTFA,
        getAuthenticatorUri,
        getQRCode,
        resetReservedKeys,
        updateAppData,
        setCurrentStepTfaPlus,
        setCurrentStepTfaMinus,
        setCurrentStepTfaInit
    };
});
export default useTwoFactorAuthenticationStore;
