import { computed, ref } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { PaymentsModalType } from 'web/src/modules/payments/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import { useI18n } from 'web/src/modules/i18n/composables';
// eslint-disable-next-line max-len
export default function usePaymentsCancelWithdrawal(props) {
    const { $translate } = useI18n();
    const analytics = useAnalytics();
    const formatMoney = useFormatMoney();
    const withdrawalStore = useWithdrawalStore();
    const showModal = ref(false);
    const modalType = ref(null);
    const modalText = ref('');
    const secondaryText = ref('');
    const showCancelWithdrawalWindow = ref(true);
    const formattedValue = computed(()=>`${formatMoney(props.value || 0)}`);
    function onCancelWithdrawalClick() {
        analytics.clickMap({
            withdrawalCancel: 'true'
        });
        showModal.value = true;
        modalType.value = PaymentsModalType.WITHDRAWAL_CANCEL_MODAL;
        modalText.value = $translate('WEB2_RETURN_TO_ACCOUNT').value;
        secondaryText.value = formattedValue.value;
    }
    function showSuccessModal(amount) {
        showModal.value = true;
        modalType.value = PaymentsModalType.WITHDRAWAL_CANCEL_SUCCESS_MODAL;
        modalText.value = $translate('WEB2_ADDED_TO_ACCOUNT').value;
        secondaryText.value = formatMoney(amount);
    }
    function hideModal() {
        showModal.value = false;
        modalType.value = null;
        modalText.value = '';
        secondaryText.value = '';
    }
    function onConfirmClick() {
        analytics.clickMap({
            withdrawalCancelRequest: 'true'
        });
        hideModal();
        withdrawalStore.cancelRequestedWithdrawals().then((response)=>{
            if (response.totalCount) {
                analytics.push(AnalyticsEvent.Z_WITHDRAWAL_CANCEL_DEPOSIT_FORM, {
                    withdrawals: {
                        withdrawalCancelDepositForm: true
                    }
                });
                showSuccessModal(response.totalAmount);
                showCancelWithdrawalWindow.value = false;
            }
        });
    }
    return {
        showModal,
        modalType,
        modalText,
        secondaryText,
        showCancelWithdrawalWindow,
        formattedValue,
        onCancelWithdrawalClick,
        onConfirmClick,
        hideModal
    };
}
