import { toRef, computed } from 'vue';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import { SportlineType, SportSegmentId } from 'web/src/modules/sportline/enums';
import { resolveLivePageLink, resolveCybersportPageLink, resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useSportlineBreadcrumbsSportItem(props) {
    const { resolveLocationSportSegmentId, resolveLocationSportlineType } = props;
    const defaultSport = toRef(props.sport);
    const sportlineBreadcrumbsStore = useSportlineBreadcrumbsStore();
    const sportTreeElement = toRef(sportlineBreadcrumbsStore, 'sportElement');
    const sport = computed(()=>defaultSport.value ?? sportTreeElement.value?.sport ?? null);
    const isShown = computed(()=>!!sport.value);
    const title = computed(()=>sport.value?.name ?? '');
    const sportRepresentationFamily = computed(()=>sport.value?.representation.family);
    const canNavigateTo = computed(()=>!sport.value?.segment.isVirtual);
    const pageLocation = computed(()=>{
        if (resolveLocationSportSegmentId?.value === SportSegmentId.CyberSport) return resolveCybersportPageLink({});
        if (!sport.value) return;
        if (resolveLocationSportlineType?.value === SportlineType.Live) return resolveLivePageLink(sport.value?.navigationParameters || {});
        return resolveSportsPageLink(sport.value?.navigationParameters || {});
    });
    return {
        canNavigateTo,
        isShown,
        title,
        sportTreeElement,
        pageLocation,
        sportRepresentationFamily
    };
}
