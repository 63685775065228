import { toRef, computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import useCashbackTimeCountdown from 'web/src/modules/promotions/components/PromotionCountdown/composable/useCashbackTimeCountdown';
export default function usePromotionCountdown(props, emit) {
    const { $translate } = useI18n();
    const targetTime = computed(()=>props.timestamp);
    const { timeName, timeNameAlternative, isHourShown, isMinutesShown, timeLeft, isShown } = useCashbackTimeCountdown(targetTime, toRef(props, 'isCashback'), ()=>emit('send'));
    const counterTitle = computed(()=>{
        if (props.isBeforeStart) return props.isCashback ? $translate('WEB2_CASHBACK_BEFORE_START').value : $translate('WEB2_PROMOTION_BEFORE_START').value;
        return props.isCashback ? $translate('WEB2_CASHBACK_BEFORE_END').value : $translate('WEB2_PROMOTION_BEFORE_END').value;
    });
    return {
        counterTitle,
        timeName,
        timeNameAlternative,
        isHourShown,
        isMinutesShown,
        timeLeft,
        isShown
    };
}
