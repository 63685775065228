import { IconName } from '@leon-hub/icons';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
export default function getDialogProps($t) {
    return {
        presetName: PresetName.CONFIRM,
        options: {
            confirmMessage: $t('WEB2_LEONSHOP_MSG').value,
            title: $t('WEB2_SHOP_CONFIRM_TITLE').value,
            fullHeight: true,
            iconName: IconName.CHECK_OUTLINE,
            iconKind: JumbotronIconKind.SUCCESS,
            buttons: [
                {
                    label: $t('WEB2_BONUSES_PLAY_BUTTON').value
                },
                {
                    label: $t('WEB2_BACK_TO_SHOP').value,
                    action: DialogAction.ADDITIONAL_BUTTON
                }
            ]
        }
    };
}
