import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { VIcon } from '@components/v-icon';
import HighLighter from 'web/src/utils/HighLighter';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { VCheckboxIcon } from 'web/src/components/Checkbox';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import LBadge from 'web/src/components/Badge/LBadge/LBadge.vue';
import { buttonIconSize } from '../constants/staticProps';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DropListOption',
    props: {
        isPreselected: {
            type: Boolean
        },
        isActive: {
            type: Boolean
        },
        searchQuery: {
            default: ''
        },
        isMultiselectMode: {
            type: Boolean
        },
        disableHoverFilter: {
            type: Boolean
        },
        withBackground: {
            type: Boolean
        },
        label: {
            default: ''
        },
        value: {
            default: ''
        },
        rightText: {},
        imageSrc: {},
        icon: {},
        badge: {}
    },
    emits: [
        "click"
    ],
    setup (__props) {
        const props = __props;
        const highlightedLabel = computed(()=>HighLighter.highlight(props.label || '', props.searchQuery || '', 'highlighted-text', true));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("button", {
                type: "button",
                class: _normalizeClass({
                    [_ctx.$style['drop-list-option']]: true,
                    [_ctx.$style['drop-list-option--multiselect']]: _ctx.isMultiselectMode,
                    [_ctx.$style['drop-list-option--preselected']]: _ctx.isPreselected,
                    [_ctx.$style['drop-list-option--active']]: _ctx.isActive,
                    [_ctx.$style['drop-list-option--filter-enabled']]: !_ctx.disableHoverFilter,
                    [_ctx.$style['drop-list-option--background']]: _ctx.withBackground
                }),
                onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('click'))
            }, [
                _ctx.isMultiselectMode ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['drop-list-option__checkbox'])
                }, [
                    _createVNode(_unref(VCheckboxIcon), {
                        checked: _ctx.isActive,
                        "is-hover": _ctx.isPreselected
                    }, null, 8, [
                        "checked",
                        "is-hover"
                    ])
                ], 2)) : _createCommentVNode("", true),
                _ctx.icon || _ctx.imageSrc ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['drop-list-option__icon'])
                }, [
                    _ctx.imageSrc ? (_openBlock(), _createBlock(VImage, {
                        key: 0,
                        src: _ctx.imageSrc,
                        class: _normalizeClass(_ctx.$style['drop-list-option__img'])
                    }, null, 8, [
                        "src",
                        "class"
                    ])) : _ctx.icon ? (_openBlock(), _createBlock(_unref(VIcon), {
                        key: 1,
                        name: _ctx.icon,
                        size: _unref(buttonIconSize)
                    }, null, 8, [
                        "name",
                        "size"
                    ])) : _createCommentVNode("", true)
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['drop-list-option__label'])
                }, [
                    _createVNode(VDynamicContent, {
                        content: highlightedLabel.value
                    }, null, 8, [
                        "content"
                    ])
                ], 2),
                _ctx.rightText || _ctx.badge ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['drop-list-option__right'])
                }, [
                    _ctx.rightText ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.rightText), 1)
                    ], 64)) : _createCommentVNode("", true),
                    _ctx.badge ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        (_openBlock(), _createBlock(LBadge, {
                            key: 1,
                            label: _ctx.badge,
                            kind: _unref(BadgeKind).SQUARE_ERROR
                        }, null, 8, [
                            "label",
                            "kind"
                        ]))
                    ], 64)) : _createCommentVNode("", true)
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'DropListOption'
                ]
            ]);
        };
    }
});
