import { SportlineType } from 'web/src/modules/sportline/enums';
export function isLiveSportlineEvent(sportEvent) {
    return sportEvent?.type === SportlineType.Live;
}
export function isAnyPrematchSportlineEvent(sportEvent) {
    return sportEvent?.type === SportlineType.Prematch;
}
export function isPrematchSportlineEvent(sportEvent) {
    return sportEvent?.type === SportlineType.Prematch && !sportEvent?.isOutright;
}
export function isOutrightSportlineEvent(sportEvent) {
    return !!sportEvent?.isOutright;
}
