import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "form__terms"
};
const _hoisted_2 = {
    class: "form__terms-guest"
};
import { useRouter } from 'vue-router';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LoginTermsFlow',
    setup (__props) {
        const { back } = useRouter();
        const buttonTextProperties = {
            kind: ButtonKind.TRANSPARENT,
            fullWidth: true
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, [
                    _createVNode(VButton, _mergeProps(buttonTextProperties, {
                        label: _ctx.$t('WEB2_LOGIN_GUEST'),
                        class: "form__terms-guest-btn",
                        onClick: _cache[0] || (_cache[0] = ($event)=>_unref(back)())
                    }), null, 16, [
                        "label"
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LoginTermsFlow'
                ]
            ]);
        };
    }
});
