import { isObject } from '@leon-hub/guards';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { SportlineRestErrorCode } from 'web/src/modules/sportline/errors/rest';
export * from './PostMatchStatistics';
export function isStatisticNotFoundError(error_) {
    const error = useErrorsConverter().convertToBaseError(error_);
    return error.code.equals(SportlineRestErrorCode.MISSING_EVENT_STATISTIC);
}
export function isLeagueMatchesStatisticEmpty(value) {
    if (!value) return true;
    return Object.keys(value).every((key)=>{
        const data = value[key];
        return !isObject(data) || !Object.keys(data).length;
    });
}
export function isTopPlayersEmpty(value) {
    if (!value) return true;
    return 0 === value.scorers.length && 0 === value.assists.length;
}
