import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { inject } from 'vue';
import SwiperNavigationButtonsBase from 'web/src/components/Swiper/Navigation/SwiperNavigationButtonsBase/SwiperNavigationButtonsBase.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VFadeCarouselNavigationButtons',
    setup (__props) {
        const moveToNextSlide = inject('moveToNextSlide');
        const moveToPreviousSlide = inject('moveToPreviousSlide');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SwiperNavigationButtonsBase, {
                "is-rounded": "",
                "is-next-allowed": "",
                "is-previous-allowed": "",
                onClickPrevious: _unref(moveToPreviousSlide),
                onClickNext: _unref(moveToNextSlide)
            }, null, 8, [
                "onClickPrevious",
                "onClickNext"
            ])), [
                [
                    _directive_auto_id,
                    'VFadeCarouselNavigationButtons'
                ]
            ]);
        };
    }
});
