import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import KickoffPeriodUtils from './KickoffPeriodUtils';
export default function getFilterDateTimeInterval(param) {
    let { activeKickoffPeriod, sportsListFilter, doUseRelativeTimeForSportline } = param;
    const defaultInterval = {
        from: void 0,
        to: void 0
    };
    if (activeKickoffPeriod === SportFilterKickoffPeriod.CUSTOM) {
        const { customDateFrom, customDateTo } = sportsListFilter;
        return customDateFrom && customDateTo ? {
            from: customDateFrom,
            to: customDateTo
        } : defaultInterval;
    }
    return doUseRelativeTimeForSportline ? KickoffPeriodUtils.optionalRelativeDateTimeRangeInMinutes(sportsListFilter.activeKickoffPeriod) : KickoffPeriodUtils.dateTimeRangeFromKickoffPeriod(sportsListFilter.activeKickoffPeriod);
}
