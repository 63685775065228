import { computed, toRef } from 'vue';
import { useCasinoTopStore } from 'web/src/modules/casino/submodules/top/useCasinoTopStore';
import { useSportlineTopStore } from 'web/src/modules/sportline/submodules/top/store';
import { usePromotionsTopStore } from 'web/src/modules/promotions/submodules/top/usePromotionsTopStore';
export function useCasinoHome() {
    const sportlineTopStore = useSportlineTopStore();
    const isSportlineTopReady = toRef(sportlineTopStore, 'isReady');
    const promotionsTopStore = usePromotionsTopStore();
    const isPromotionsTopReady = toRef(promotionsTopStore, 'isReady');
    const casinoTopStore = useCasinoTopStore();
    const isCasinoTopReady = toRef(casinoTopStore, 'isReady');
    const isReady = computed(()=>isSportlineTopReady.value && isPromotionsTopReady.value && isCasinoTopReady.value);
    async function initialRequests() {
        await Promise.allSettled([
            sportlineTopStore.initialRequests(),
            promotionsTopStore.initialRequests(),
            casinoTopStore.updateJackpots()
        ]);
    }
    async function syncBackgroundRequests() {
        await Promise.allSettled([
            sportlineTopStore.syncBackgroundRequests({
                silent: true
            }),
            promotionsTopStore.syncBackgroundRequests()
        ]);
    }
    async function setBackgroundUpdateEnabled(value) {
        await Promise.allSettled([
            sportlineTopStore.setBackgroundUpdateEnabled(value)
        ]);
    }
    return {
        isReady,
        initialRequests,
        syncBackgroundRequests,
        setBackgroundUpdateEnabled
    };
}
