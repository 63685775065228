import { computed, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { isCasinoLobbyRouteName } from 'web/src/modules/casino/utils';
export function useCustomerFinalBalance() {
    let sumOnlyOnEgsPages = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    const route = useRoute();
    const balance = toRef(useCustomerFinanceStore(), 'balance');
    const siteConfigStore = useSiteConfigStore();
    const homePageType = toRef(siteConfigStore, 'homePageType');
    const isSumOfBonusAndBalanceEnabled = toRef(siteConfigStore, 'isSumOfBonusAndBalanceEnabled');
    const bonusCbc = toRef(useBonusStore(), 'bonusCbc');
    const bonusAmount = computed(()=>bonusCbc.value?.walletBalanceNumber ?? 0);
    const isEGSLobbyPage = computed(()=>isCasinoLobbyRouteName(route.name, homePageType.value));
    const isSumOfBalancesEnabled = computed(()=>isSumOfBonusAndBalanceEnabled.value && bonusAmount.value > 0 && (!sumOnlyOnEgsPages || isEGSLobbyPage.value));
    const totalBalance = computed(()=>{
        if (!isSumOfBalancesEnabled.value) return balance.value;
        return balance.value + bonusAmount.value;
    });
    return {
        isSumOfBalancesEnabled,
        totalBalance,
        balance,
        bonusAmount
    };
}
