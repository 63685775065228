import { getCustomerMatchStreamEvents, getCustomerStreams, getMatchStreamUrl as fetchMatchStreamUrl, RequestOptionsPriority } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export function getCustomerStreamEventsRequest(param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return getCustomerMatchStreamEvents(apiClient, (node)=>node.queries.matchStream.getCustomerEvents, {}, {
        silent,
        priority: silent ? RequestOptionsPriority.LOW : void 0
    });
}
export function getCustomerStreamsRequest(param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return getCustomerStreams(apiClient, (node)=>node.queries.matchStream.getCustomerStreams, {}, {
        silent,
        priority: silent ? RequestOptionsPriority.LOW : void 0
    });
}
export function getMatchStreamUrl(options, param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return fetchMatchStreamUrl(apiClient, (node)=>node.queries.matchStream.getMatchStreamUrl, {
        options
    }, {
        silent,
        priority: silent ? RequestOptionsPriority.LOW : void 0
    });
}
