import { computed, toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { checkMatchStreamAccess } from '../utils';
import { createIsPreviewOnlyRef } from './createIsPreviewOnlyRef';
import { useMatchStreamAccess } from './useMatchStreamAccess';
export function useStreamUrlAvailable(props) {
    const sportlineEventId = toRef(props.sportlineEventId);
    const sportlineType = toRef(props.sportlineType);
    const isStreamsEnabled = toRef(()=>useSportlineSettingsStore().isLiveStreamEnabled);
    const matchStreamAccess = useMatchStreamAccess();
    const isStreamAvailable = computed(()=>{
        if (!isStreamsEnabled.value) return false;
        if (!sportlineEventId.value) return false;
        return checkMatchStreamAccess(matchStreamAccess.value, {
            id: sportlineEventId.value,
            type: sportlineType.value ?? SportlineType.Live
        });
    });
    const isPreviewOnly = createIsPreviewOnlyRef(sportlineEventId);
    return {
        isStreamAvailable,
        isPreviewOnly
    };
}
