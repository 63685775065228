import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { getCyberSportIconName } from 'web/src/modules/sportline/utils/theme';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
import { useRegionLogoProps } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegionLogo',
    props: {
        countryCode: {},
        sportSegmentId: {},
        size: {},
        flagSize: {}
    },
    setup (__props) {
        const props = __props;
        const { isCyberSport, countryCode } = useRegionLogoProps(props);
        return (_ctx, _cache)=>_unref(isCyberSport) ? (_openBlock(), _createBlock(_unref(VIcon), {
                key: 0,
                name: _unref(getCyberSportIconName)({
                    icon: _unref(countryCode)
                })
            }, null, 8, [
                "name"
            ])) : (_openBlock(), _createBlock(VFlagIcon, {
                key: 1,
                size: _unref(IconSize).SIZE_20,
                "flag-size": _unref(VCountryFlagSize).SIZE_16,
                "country-code": _unref(countryCode)
            }, null, 8, [
                "size",
                "flag-size",
                "country-code"
            ]));
    }
});
