import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import fetchOnboarding from 'web/src/modules/onboarding/utils/fetchOnboarding';
const useOnboardingStore = defineStore('onboarding', ()=>{
    const onboardingItems = ref({});
    const onboardingLength = computed(()=>Object.keys(onboardingItems).length);
    const swiper = ref();
    const isLastSlide = ref(false);
    async function getOnboarding(param) {
        let { onboardingId, withSlides = false } = param;
        const { items } = await fetchOnboarding({
            onboardingId,
            withSlides
        });
        for (const item of items)if (!onboardingItems.value[item.id] || !onboardingItems.value[item.id].slides.length) onboardingItems.value[item.id] = item;
    }
    return {
        onboardingLength,
        getOnboarding,
        onboardingItems,
        swiper,
        isLastSlide
    };
});
export default useOnboardingStore;
