import { computed, ref, watch } from 'vue';
import { CountryFlag } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { flagPosition, countryToFlagMapping } from 'web/src/components/CountryFlag/VCountryFlag/constants';
export function useVCountryFlag(props) {
    const { countryCode } = props;
    const imageUrl = ref(null);
    const flagName = computed(()=>{
        if (!countryCode.value) return CountryFlag.DEFAULT;
        return countryToFlagMapping[countryCode.value] ?? CountryFlag.DEFAULT;
    });
    /** @TODO check logic "0px 0px" points to a flag, maybe it must be null for this case */ const position = computed(()=>{
        const c = flagPosition[flagName.value];
        return c ? `${c[0] / 2}px ${c[1] / 2}px` : '0px 0px';
    });
    function checkImageUrl() {
        try {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            imageUrl.value = require(`web/src/assets/flags/${flagName.value}.svg`);
        } catch  {
            imageUrl.value = null;
        }
    }
    watch(flagName, checkImageUrl, {
        immediate: true
    });
    return {
        position,
        imageUrl
    };
}
