import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "customer-verification-card-timer"
};
const _hoisted_2 = {
    class: "customer-verification-card-timer__date"
};
import { ref } from 'vue';
import VCountdown from 'web/src/components/Countdown/VCountdown/VCountdown.vue';
import { CountdownType } from 'web/src/components/Countdown/VCountdown/enum';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerVerificationCardTimer',
    props: {
        expirationDate: {}
    },
    emits: [
        "timer-end"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const timeLabel = ref();
        function setTimeLabel(value) {
            timeLabel.value = value;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, [
                    _ctx.expirationDate ? (_openBlock(), _createBlock(VCountdown, {
                        key: 0,
                        "expiration-date": _ctx.expirationDate,
                        "countdown-date-format": _unref(CountdownType).HOURS_MINUTES,
                        title: _ctx.$t('WEB2_VV_CLOSE_TIME'),
                        onCountdownEnd: _cache[0] || (_cache[0] = ($event)=>{
                            emit('timer-end');
                        }),
                        onCountdownChange: setTimeLabel
                    }, null, 8, [
                        "expiration-date",
                        "countdown-date-format",
                        "title"
                    ])) : _createCommentVNode("", true)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CustomerVerificationCardTimer'
                ]
            ]);
        };
    }
});
