import { computed } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
export default function useSearchListSection(props, emits) {
    const router = useRouter();
    const eventsCounter = computed(()=>props.list.length);
    const captionText = computed(()=>`${props.caption} (${eventsCounter.value})`);
    function getItemProperties(event) {
        const { id, ...uiProps } = event;
        return {
            ...uiProps,
            href: getHref(event),
            searchText: props.searchText
        };
    }
    function getHref(item) {
        return router.resolve({
            name: RouteName.SPORT_EVENT_DETAILS,
            params: {
                ...item.navigationParameters
            }
        }).href;
    }
    function emitItemClick(event) {
        emits('item-click', event);
    }
    return {
        captionText,
        getItemProperties,
        emitItemClick
    };
}
