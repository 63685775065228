import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { useCasinoProviderSelect } from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoProviderSelect',
    props: {
        title: {
            default: ''
        },
        hasSelectedGroup: {
            type: Boolean
        },
        inSidebar: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { iconProperties, emitClick } = useCasinoProviderSelect(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-provider-select']]: true,
                    [_ctx.$style['casino-provider-select--in-sidebar']]: _ctx.inSidebar
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(emitClick) && _unref(emitClick)(...args);
                })
            }, [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['casino-provider-select__button']]: true,
                        [_ctx.$style['casino-provider-select__button--selected']]: _ctx.hasSelectedGroup
                    })
                }, [
                    _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconProperties))), null, 16)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoProviderSelect'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'casino-button-provider-select'
                    }
                ]
            ]);
        };
    }
});
