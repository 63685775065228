import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "src"
];
import { IconName } from '@leon-hub/icons';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import WebPushInfo from '../WebPushInfo/WebPushInfo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WebPushDesktopModal',
    props: {
        title: {},
        buttons: {}
    },
    emits: [
        "button-click",
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function onButtonClick(index) {
            emit('button-click', index);
        }
        function onClose() {
            emit('close');
        }
        const closeIcon = IconName.CROSS;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['push-notifications'])
            }, [
                _createVNode(ModalWindowHeader, {
                    title: _ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_HEADLINE'),
                    "suffix-icon-name": _unref(closeIcon),
                    type: "tertiary",
                    onSuffixClick: onClose
                }, null, 8, [
                    "title",
                    "suffix-icon-name"
                ]),
                _createVNode(_unref(VJumbotron), {
                    class: _normalizeClass(_ctx.$style['push-notifications__jubmotron']),
                    heading: _ctx.title
                }, {
                    icon: _withCtx(()=>[
                            _createElementVNode("img", {
                                src: require('web/src/assets/images/push-notifications.svg'),
                                alt: ""
                            }, null, 8, _hoisted_1)
                        ]),
                    footer: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index)=>_withDirectives((_openBlock(), _createBlock(_unref(JumbotronFooterButton), _mergeProps({
                                    ref_for: true
                                }, button, {
                                    key: index,
                                    onClick: ($event)=>onButtonClick(index)
                                }), null, 16, [
                                    "onClick"
                                ])), [
                                    [
                                        _unref(vDataTestUnit),
                                        {
                                            el: 'push-modal-btn',
                                            index: index
                                        }
                                    ]
                                ])), 128))
                        ]),
                    default: _withCtx(()=>[
                            _createVNode(WebPushInfo)
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "heading"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'WebPushDesktopModal'
                ]
            ]);
        };
    }
});
