import { ref } from 'vue';
import { ModalWidth } from '@leon-hub/module-modal';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import RegistrationApiErrorCode from '../../enums/RegistrationApiErrorCode';
export function useRegistrationErrors() {
    const registrationError = ref('');
    const registrationErrorCode = ref('');
    const { showDialog } = useDialogs();
    const { $translate } = useI18n();
    function setRegistrationError() {
        let error = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        registrationError.value = error;
    }
    function setRegistrationErrorCode() {
        let code = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        registrationErrorCode.value = code;
    }
    function handleDuplicateError(error, onConfirm, onAdditionalButton, onClose) {
        if (error.code.equals(RegistrationApiErrorCode.DUPLICATE_EMAIL) || error.code.equals(RegistrationApiErrorCode.DUPLICATE_PHONE_NUMBER)) {
            const { subscribe } = showDialog({
                presetName: PresetName.CONFIRM,
                options: {
                    title: $translate('WEB2_REGISTRATION_ALREADY_HAVE_SCORE_TITLE').value,
                    analyticsModalType: void 0,
                    confirmMessage: error.message,
                    width: ModalWidth.SMALL,
                    isOverlayCloseAllowed: false,
                    buttons: [
                        {
                            label: $translate('JSPACC_LOGIN_ENTER').value
                        },
                        {
                            action: DialogAction.ADDITIONAL_BUTTON,
                            label: $translate('WEB2_SUPPORT').value
                        }
                    ]
                }
            });
            subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    onConfirm();
                },
                [DialogAction.ADDITIONAL_BUTTON]: ()=>{
                    onAdditionalButton();
                },
                [DialogAction.MODAL_CLOSE]: ()=>{
                    onClose();
                }
            });
            return true;
        }
        return false;
    }
    return {
        registrationError,
        registrationErrorCode,
        setRegistrationError,
        setRegistrationErrorCode,
        handleDuplicateError
    };
}
