import { ref } from 'vue';
import { usePendingToFavoriteStoreComposable } from 'web/src/modules/sportline/submodules/favorites/store/composables';
export function usePendingToFavoriteEventsStoreComposable() {
    const { preparedToAdd, preparedToRemove, setPrepareToAddState, setPrepareToRemoveState, setPreparedState } = usePendingToFavoriteStoreComposable();
    const pendingToFavoritesList = ref([]);
    function addPendingToFavoritesList(eventId) {
        pendingToFavoritesList.value = [
            ...pendingToFavoritesList.value,
            eventId
        ];
    }
    function clearPendingToFavoritesList() {
        pendingToFavoritesList.value = [];
    }
    return {
        pendingToFavoritesList,
        preparedToAdd,
        preparedToRemove,
        setPrepareToAddState,
        setPrepareToRemoveState,
        setPreparedState,
        addPendingToFavoritesList,
        clearPendingToFavoritesList
    };
}
