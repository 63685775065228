import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "data-test-key"
];
const _hoisted_2 = [
    "data-test-key"
];
const _hoisted_3 = [
    "data-test-key"
];
const _hoisted_4 = {
    key: 0
};
const _hoisted_5 = [
    "data-test-key"
];
const _hoisted_6 = [
    "data-test-key"
];
const _hoisted_7 = [
    "data-test-key"
];
import { ButtonKind, ButtonHeight } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import CustomerHistoryListItemLayout from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemLayout/CustomerHistoryListItemLayout.vue';
import { TestKeysEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { useCustomerHistoryListItemPayment } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListItemPayment',
    props: {
        item: {}
    },
    emits: [
        "cancel-withdrawal",
        "item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { dateProperties, isStatus, isMoneyPositive, money, isWithdrawCancellable, cancelWithdrawal } = useCustomerHistoryListItemPayment(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(CustomerHistoryListItemLayout, {
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('item-click', _ctx.item))
            }, {
                metaLeft: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['payment-list-item__type-name']),
                            "data-test-key": _unref(TestKeysEnum).TYPE_NAME
                        }, _toDisplayString(_ctx.item.txTypeKind), 11, _hoisted_1)
                    ]),
                metaRight: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['payment-list-item__date']),
                            "data-test-key": _unref(TestKeysEnum).DATE
                        }, _toDisplayString(_unref(dateProperties)), 11, _hoisted_2)
                    ]),
                descriptionLeft: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['payment-list-item__type-description'])
                        }, [
                            _createElementVNode("span", {
                                "data-test-key": _unref(TestKeysEnum).TYPE_DESCRIPTION
                            }, _toDisplayString(_ctx.item.txTypeName), 9, _hoisted_3)
                        ], 2)
                    ]),
                descriptionRight: _withCtx(()=>[
                        _unref(isStatus) ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['payment-list-item__status']),
                                "data-test-key": _unref(TestKeysEnum).STATUS
                            }, _toDisplayString(_ctx.item.statusName), 11, _hoisted_5)
                        ])) : _createCommentVNode("", true)
                    ]),
                footerLeft: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['payment-list-item__id']),
                            "data-test-key": _unref(TestKeysEnum).ID
                        }, _toDisplayString(`(ID: ${_ctx.item.objectId})`), 11, _hoisted_6)
                    ]),
                footerRight: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['payment-list-item__withdrawal-block'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass({
                                    [_ctx.$style['payment-list-item__money']]: true,
                                    [_ctx.$style['payment-list-item__money--positive']]: _unref(isMoneyPositive)
                                }),
                                "data-test-key": _unref(TestKeysEnum).MONEY
                            }, _toDisplayString(_unref(money)), 11, _hoisted_7),
                            _unref(isWithdrawCancellable) ? (_openBlock(), _createBlock(VButton, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['payment-list-item__withdrawal-button']),
                                kind: _unref(ButtonKind).CANCEL,
                                height: _unref(ButtonHeight).TINY_EXTRA,
                                label: _ctx.$t('WEB2_CUSTOMER_HISTORY_CANCEL'),
                                "data-test-key": _unref(TestKeysEnum).WITHDRAW_CANCELLABLE,
                                onClick: _withModifiers(_unref(cancelWithdrawal), [
                                    "stop"
                                ])
                            }, null, 8, [
                                "class",
                                "kind",
                                "height",
                                "label",
                                "data-test-key",
                                "onClick"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListItemPayment'
                ]
            ]);
        };
    }
});
