import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { isString, isNull } from '@leon-hub/guards';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';
import { useVFlagIcon } from './useVFlagIcon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VFlagIcon',
    props: {
        size: {},
        countryCode: {},
        flagSize: {},
        rounded: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const size = toRef(props, 'size', IconSize.SIZE_20);
        const countryCode = toRef(props, 'countryCode', null);
        const flagSize = toRef(props, 'flagSize');
        const { finalFlagSize } = useVFlagIcon({
            size,
            flagSize
        });
        function isCountryCode(value) {
            return isNull(value) || isString(value);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("span", {
                class: _normalizeClass({
                    [_ctx.$style['flag-icon']]: true,
                    [_ctx.$style[`flag-icon--${size.value}`]]: true
                })
            }, [
                _renderSlot(_ctx.$slots, "flag", {
                    flagSize: flagSize.value
                }, ()=>[
                        isCountryCode(countryCode.value) ? (_openBlock(), _createBlock(_unref(VCountryFlag), {
                            key: 0,
                            code: countryCode.value,
                            size: _unref(finalFlagSize),
                            rounded: _ctx.rounded
                        }, null, 8, [
                            "code",
                            "size",
                            "rounded"
                        ])) : _createCommentVNode("", true)
                    ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VFlagIcon'
                ]
            ]);
        };
    }
});
