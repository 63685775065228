import getOnlyDigitsFromString from './getOnlyDigitsFromString';
export default function handleUnmaskedPhoneInput(event, currentValue, maxSymbols) {
    const { target } = event;
    HTMLInputElement;
    const eventValue = target.value;
    const valueIsValid = getOnlyDigitsFromString(eventValue) === eventValue;
    if (!valueIsValid || maxSymbols && eventValue.length > maxSymbols) {
        target.value = currentValue;
        return currentValue;
    }
    return eventValue;
}
