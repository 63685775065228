import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { logger } from '@leon-hub/logging';
import { ParseSportEventError } from 'web/src/modules/sportline/errors/rest';
import { ResponseMappings, extractBuildSportOptions } from 'web/src/modules/sportline/utils/rest/pre-build';
export class AbstractLineFactory {
    /**
   * Fill up response mappings and try to build events
   */ parseResponse() {
        for (const eventResponse of this.response.events || [])try {
            const leagueResponse = this.responseMappings.getLeagueResponseOrSetNew(eventResponse.league);
            const regionResponse = this.responseMappings.getRegionResponseOrSetNew(leagueResponse.region);
            const sportResponse = this.responseMappings.getSportResponseOrSetNew(leagueResponse.sport);
            this.processEventResponse({
                sportResponse,
                regionResponse,
                leagueResponse,
                eventResponse
            });
        } catch (error) {
            if (error instanceof ParseSportEventError) this.errors.push(error);
            else throw error;
        }
        if (this.errors.length > 0) logger.error('Sportline: parse sportline error occurred', {
            eventsIds: this.errors.map((error)=>error.response?.id).filter((id)=>!!id),
            messages: [
                ...new Set(this.errors.map((error)=>error.message))
            ],
            buildOptions: this.buildSportOptions
        });
        return this.result;
    }
    constructor(response, options, factoryOptions){
        _define_property(this, "response", void 0);
        _define_property(this, "factoryOptions", void 0);
        /**
   * Storage for responses. Used to find response by reference in response (like { id: string })
   */ _define_property(this, "responseMappings", void 0);
        /**
   * Accumulator for parse errors
   */ _define_property(this, "errors", void 0);
        _define_property(this, "buildSportOptions", void 0);
        this.response = response;
        this.factoryOptions = factoryOptions;
        this.responseMappings = new ResponseMappings();
        this.errors = [];
        this.buildSportOptions = extractBuildSportOptions(options);
    }
} /**
   * Process event responses
   * Fill up result
   * @abstract
   */  /**
   * Main build line method
   * @abstract
   */ 
