import { ref } from 'vue';
import { isFocusable } from 'web/src/utils/forms/focus';
const useFocusableElement = ()=>{
    const element = ref();
    const focusOnElement = ()=>{
        if (!process.env.VUE_APP_OS_IOS && isFocusable(element.value)) element.value.focus();
    };
    return {
        focus: focusOnElement,
        focusable: element
    };
};
export default useFocusableElement;
