import { toRef, ref, watch } from 'vue';
import { logger } from '@leon-hub/logging';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useFavoriteEventsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { CustomerLiveMatchesApiErrorCode } from 'web/src/modules/sportline/submodules/favorites/enums';
import { addFavoriteEventsRequest, getFavoritesEventsIdsRequest } from 'web/src/modules/sportline/utils/api';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
export function useFavoriteEventsBackgroundUpdatesStoreComposable(props) {
    const { favoritesIdsList, pendingToFavoritesList, rawFavoriteEventsResponse, setFavoritesIdsList, clearPendingToFavoritesList, setRawFavoriteEventsResponse } = props;
    const { isLoggedIn } = useIsLoggedIn();
    const customerDataStore = useCustomerDataStore();
    const customerLogin = toRef(customerDataStore, 'login');
    const { timeout: favoriteEventsUpdateInterval } = useFavoriteEventsSportlineUpdateTimeout();
    const broadcastSelected = useBroadcastSelected();
    const apiService = useSportlineApiService();
    const { convertToBaseError } = useErrorsConverter();
    const isEventsBackgroundUpdateAvailable = ref(false);
    const isBackgroundIdsUpdateAvailable = ref({});
    async function fetchFavoriteEvents(param) {
        let { silent } = param;
        if (!isLoggedIn.value) return;
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const vTag = (rawFavoriteEventsResponse.value || null)?.vtag;
        const isUpdateRequest = !!vTag;
        const result = await apiService.loadFavoriteEvents({
            login: `${customerLogin.value}-${Date.now()}`,
            vTag: isUpdateRequest ? vTag : void 0,
            silent
        });
        setRawFavoriteEventsResponse(result);
        broadcastSelected.updateDataInStorageByResponse({
            response: result
        });
    }
    async function fetchFavoriteEventsIdsList(silent) {
        if (!isLoggedIn.value) {
            setFavoritesIdsList([]);
            return;
        }
        const response = await getFavoritesEventsIdsRequest({
            silent
        });
        // eslint-disable-next-line unicorn/prefer-native-coercion-functions
        const favorites = (response.ids || []).map((eventId)=>String(eventId)).sort();
        setFavoritesIdsList(favorites);
    }
    async function synchronizeFavorites(silent) {
        if (!isLoggedIn.value) {
            setFavoritesIdsList([]);
            return;
        }
        const pendingIds = pendingToFavoritesList.value.map((id)=>Number.parseInt(id, 10));
        if (pendingIds.length) try {
            await addFavoriteEventsRequest({
                eventIds: pendingIds
            }, {
                silent
            });
            await fetchFavoriteEventsIdsList(silent);
        } catch (rawError) {
            const error = convertToBaseError(rawError);
            if (error.code.equals(CustomerLiveMatchesApiErrorCode.LIMIT_EXCEEDED)) logger.warn('doCustomerLiveMatchesAdd limit exceeded', error);
            else logger.error(error);
        } finally{
            clearPendingToFavoritesList();
        }
        else await fetchFavoriteEventsIdsList(silent);
    }
    const lastUpdate = new BackgroundUpdateStopwatch({
        favoriteEvents: async (param)=>{
            let { silent } = param;
            await fetchFavoriteEvents({
                silent
            });
            lastUpdate.update('favoriteEvents');
        },
        favoriteEventsIds: async (param)=>{
            let { silent } = param;
            await synchronizeFavorites(silent);
            lastUpdate.update('favoriteEventsIds');
        }
    });
    async function synchronizeEventsIds(param) {
        let { force, silent } = param;
        await (force ? lastUpdate.forceCallSyncAction('favoriteEventsIds', {
            silent
        }) : lastUpdate.callSyncAction('favoriteEventsIds', favoriteEventsUpdateInterval.value, {
            silent
        }));
    }
    async function synchronizeEvents(param) {
        let { force, silent } = param;
        await (force ? lastUpdate.forceCallSyncAction('favoriteEvents', {
            silent
        }) : lastUpdate.callSyncAction('favoriteEvents', favoriteEventsUpdateInterval.value, {
            silent
        }));
    }
    function setIsEventsBackgroundUpdateAvailable(value) {
        if (value === isEventsBackgroundUpdateAvailable.value) return;
        isEventsBackgroundUpdateAvailable.value = value;
        if (value) synchronizeEvents({
            silent: true
        });
    }
    function setIsBackgroundIdsUpdateAvailable(value) {
        isBackgroundIdsUpdateAvailable.value = {
            ...isBackgroundIdsUpdateAvailable.value,
            ...value
        };
        if (value) synchronizeEventsIds({
            silent: true
        });
    }
    async function syncState(silent) {
        const isSyncIdsAvailable = Object.values(isBackgroundIdsUpdateAvailable.value).some((enable)=>enable);
        const isSyncListAvailable = isEventsBackgroundUpdateAvailable.value;
        // sync state
        await Promise.allSettled([
            isSyncIdsAvailable ? synchronizeEventsIds({
                silent
            }) : Promise.resolve(),
            isSyncListAvailable ? synchronizeEvents({
                silent
            }) : Promise.resolve()
        ]);
    }
    useSyncState(syncState, favoriteEventsUpdateInterval);
    watch(favoritesIdsList, async (value, oldValue)=>{
        if (JSON.stringify(value || []) === JSON.stringify(oldValue)) return;
        if (isEventsBackgroundUpdateAvailable.value) await synchronizeEvents({
            force: true,
            silent: true
        });
        else lastUpdate.invalidate('favoriteEvents');
    });
    return {
        setIsEventsBackgroundUpdateAvailable,
        setIsBackgroundIdsUpdateAvailable,
        fetchFavoriteEventsIdsList
    };
}
