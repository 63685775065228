/* eslint-disable no-tabs */ /*
  vi_VN - Vietnam
    Language	vi
    Country	VN

  en_VI - US Virgin Islands
    Language	en
    Country	VI
*/ var FlagValue;
var FlagValue1;
(FlagValue1 = FlagValue || (FlagValue = {}))["GREECE"] = "el", FlagValue1["KOREAN"] = "ko", FlagValue1["TECH"] = "ln", FlagValue1["UZBEK"] = "uc", FlagValue1["UKRAINIAN"] = "uk", FlagValue1["VIETNAMESE"] = "vi";
export default FlagValue;
