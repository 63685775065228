import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import InGroupRunner from 'web/src/modules/sportline/views/runner/InGroupRunner.vue';
import { useMarketsListMarket } from '../composables/useMarketsListMarket';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketsListMarket',
    props: {
        group: {},
        sportlineEvent: {},
        isShown: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const group = toRef(props, 'group');
        const sportEvent = toRef(props, 'sportlineEvent');
        const { slipCaption, sportlineEventId, sportlineEventType, isDynamicColumns, flatRows } = useMarketsListMarket({
            group,
            sportEvent
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-details-item'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['sport-event-details-item__dynamic-columns']]: _unref(isDynamicColumns)
                    })
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(flatRows), (row, rowIndex)=>(_openBlock(), _createElementBlock("div", {
                            key: `${row.market.id}-${rowIndex}`,
                            class: _normalizeClass({
                                [_ctx.$style['sport-event-details-item__grid']]: true,
                                [_ctx.$style['sport-event-details-item__grid--column-' + row.runners.length]]: true,
                                [_ctx.$style['sport-event-details-item__static-column']]: !_unref(isDynamicColumns),
                                [_ctx.$style['sport-event-details-item__dynamic-column']]: _unref(isDynamicColumns)
                            })
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.runners, (runner, index)=>(_openBlock(), _createElementBlock("div", {
                                    key: runner ? `${runner.marketId}_${runner.id}` : `i${index}`,
                                    class: _normalizeClass(_ctx.$style['sport-event-details-item__runner-holder'])
                                }, [
                                    _createVNode(InGroupRunner, {
                                        class: _normalizeClass(_ctx.$style['sport-event-details-item__runner']),
                                        runner: runner,
                                        market: row.market,
                                        "slip-caption": _unref(slipCaption),
                                        "sport-event-id": _unref(sportlineEventId),
                                        "sportline-type": _unref(sportlineEventType)
                                    }, null, 8, [
                                        "class",
                                        "runner",
                                        "market",
                                        "slip-caption",
                                        "sport-event-id",
                                        "sportline-type"
                                    ])
                                ], 2))), 128))
                        ], 2))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'MarketsListMarket'
                ]
            ]);
        };
    }
});
