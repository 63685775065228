import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
export function useSportlineBreadcrumbsMetaParameters(props) {
    const { sportElement, sportPageLocation, regionElement, regionPageLocation, leagueElement, leaguePageLocation } = props;
    const router = useRouter();
    const metaParameters = computed(()=>({
            sport: sportElement.value?.sport.name,
            sportEmoji: sportElement.value?.sport?.representation.emoji,
            sportUrl: sportPageLocation.value ? router.getHref(sportPageLocation.value).absolute : void 0,
            region: regionElement.value?.region.name,
            regionUrl: regionPageLocation.value ? router.getHref(regionPageLocation.value).absolute : void 0,
            league: leagueElement.value?.league.name,
            leagueUrl: leaguePageLocation.value ? router.getHref(leaguePageLocation.value).absolute : void 0
        }));
    useSeoMetaParameters(metaParameters);
}
