import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "id",
    "disabled",
    "name",
    "tabindex",
    "type",
    "value",
    "min",
    "max"
];
const _hoisted_2 = [
    "for"
];
import DateTime from 'web/src/utils/DateTime';
import { DateInputRoundDateTo, DateInputType } from '../../enums';
import useNativeDateInput from './useNativeDateInput';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NativeDateInput',
    props: {
        min: {},
        max: {},
        roundTo: {
            default: DateInputRoundDateTo.None
        },
        value: {
            default: ()=>DateTime.now().toTimestamp()
        },
        disabled: {
            type: Boolean
        },
        name: {
            default: 'date'
        },
        type: {
            default: DateInputType.Date
        },
        tabIndex: {
            default: '-1'
        },
        focused: {
            default: ''
        }
    },
    emits: [
        "change",
        "focus",
        "blur",
        "input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { uniqueId, systemInputMax, systemInputMin, systemInputValue, displayingValue, onBlur, onInput, onFocus, onChange } = useNativeDateInput(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['date-picker']]: true,
                    [_ctx.$style[`date-picker--focused-${_ctx.focused}`]]: _ctx.focused
                })
            }, [
                (_openBlock(), _createElementBlock("input", {
                    id: _unref(uniqueId),
                    key: _ctx.name,
                    disabled: _ctx.disabled,
                    name: _ctx.name,
                    tabindex: _ctx.tabIndex,
                    type: _ctx.type,
                    value: _unref(systemInputValue),
                    min: _unref(systemInputMin),
                    max: _unref(systemInputMax),
                    class: _normalizeClass(_ctx.$style['date-picker__input']),
                    placeholder: " ",
                    onChange: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onChange) && _unref(onChange)(...args);
                    }),
                    onInput: _cache[1] || (_cache[1] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onInput) && _unref(onInput)(...args);
                    }),
                    onFocus: _cache[2] || (_cache[2] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onFocus) && _unref(onFocus)(...args);
                    }),
                    onBlur: _cache[3] || (_cache[3] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onBlur) && _unref(onBlur)(...args);
                    })
                }, null, 42, _hoisted_1)),
                _createElementVNode("label", {
                    class: _normalizeClass(_ctx.$style['date-picker__label']),
                    for: _unref(uniqueId)
                }, _toDisplayString(_unref(displayingValue)), 11, _hoisted_2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'NativeDateInput'
                ]
            ]);
        };
    }
});
