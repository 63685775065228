import { ref } from 'vue';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
export default function useChatBetId() {
    const betId = ref('');
    useBusSafeSubscribe(BusEvent.OPEN_CHAT, (param)=>{
        let { betId: busBetId } = param;
        betId.value = busBetId || '';
    });
    return betId;
}
