import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "skeleton-image"
};
import { IconSize } from '@leon-hub/icons';
import { SSpinner } from '@components/spinner';
import { VLogoLoader } from 'web/src/components/Loader';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSkeletonImage',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createBlock(_unref(VLogoLoader), {
                    key: 1,
                    class: "skeleton-image__logo",
                    "is-small": ""
                }))
            ])), [
                [
                    _directive_auto_id,
                    'VSkeletonImage'
                ]
            ]);
        };
    }
});
