import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import VReCaptcha from 'web/src/modules/captcha/components/VReCaptcha/VReCaptcha.vue';
import { useVCaptcha } from 'web/src/modules/captcha/components/VCaptcha/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCaptcha',
    props: {
        captchaRequesterStrategy: {},
        captchaEnabled: {
            type: Boolean
        },
        siteKey: {},
        captchaType: {},
        reCaptchaTheme: {},
        error: {},
        autoExecute: {
            type: Boolean
        },
        useIframeForCaptcha: {
            type: Boolean
        },
        captchaIframeUrl: {},
        apiUrl: {}
    },
    emits: [
        "verify",
        "expired",
        "wrapper-state",
        "error",
        "challenge-is-closed",
        "challenge-is-opened"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { vCaptchaElement, iframeElement, iframeWidgetSrc, expanded, isDefaultStrategy, iframeName, additionalQueryStringParameters, iframeMounted, listenPostMessage, emitVerify, emitExpired, emitError, emitChallengeClosed, emitChallengeOpened, mapCaptchaType } = useVCaptcha(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['captcha']]: true,
                    [_ctx.$style['captcha--expanded']]: _unref(expanded),
                    [_ctx.$style['captcha--modal']]: _unref(isDefaultStrategy)
                })
            }, [
                _ctx.useIframeForCaptcha ? (_openBlock(), _createBlock(VIframe, {
                    key: 0,
                    ref_key: "iframeElement",
                    ref: iframeElement,
                    src: _unref(iframeWidgetSrc),
                    "auto-height": "",
                    name: _unref(iframeName),
                    "additional-query-string-parameters": _unref(additionalQueryStringParameters),
                    onVnodeMounted: _unref(iframeMounted),
                    onIframeApplicationReady: _unref(listenPostMessage)
                }, null, 8, [
                    "src",
                    "name",
                    "additional-query-string-parameters",
                    "onVnodeMounted",
                    "onIframeApplicationReady"
                ])) : (_openBlock(), _createBlock(VReCaptcha, {
                    key: 1,
                    ref_key: "vCaptchaElement",
                    ref: vCaptchaElement,
                    type: _unref(mapCaptchaType)(_ctx.captchaType),
                    theme: _ctx.reCaptchaTheme,
                    language: _ctx.$lang,
                    "site-key": _ctx.siteKey,
                    "auto-execute": _ctx.autoExecute,
                    "api-url": _ctx.apiUrl,
                    onVerify: _unref(emitVerify),
                    onExpired: _unref(emitExpired),
                    onError: _unref(emitError),
                    onChallengeIsClosed: _unref(emitChallengeClosed),
                    onChallengeIsOpened: _unref(emitChallengeOpened)
                }, null, 8, [
                    "type",
                    "theme",
                    "language",
                    "site-key",
                    "auto-execute",
                    "api-url",
                    "onVerify",
                    "onExpired",
                    "onError",
                    "onChallengeIsClosed",
                    "onChallengeIsOpened"
                ])),
                _ctx.error ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['captcha__hint'])
                }, _toDisplayString(_ctx.error), 3)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VCaptcha'
                ],
                [
                    _vShow,
                    _ctx.captchaEnabled
                ]
            ]);
        };
    }
});
