import { isValidJsonSchema } from '@leon-hub/guards';
export const PromotionPropertiesValidationSchema = {
    properties: {
        code: {
            type: [
                'string',
                'null'
            ]
        },
        actionUrl: {
            type: [
                'string',
                'null'
            ]
        },
        name: {
            type: [
                'string',
                'null'
            ]
        },
        description: {
            type: [
                'string',
                'null'
            ]
        },
        shortDescription: {
            type: [
                'string',
                'null'
            ]
        },
        bannerUrl: {
            type: [
                'string',
                'null'
            ]
        },
        isWide: {
            type: [
                'boolean',
                'null'
            ]
        },
        startDate: {
            type: [
                'string',
                'null'
            ]
        },
        endDate: {
            type: [
                'string',
                'null'
            ]
        },
        actionId: {
            type: [
                'number',
                'null'
            ]
        },
        nativeScriptDescriptionKey: {
            type: [
                'string',
                'null'
            ]
        },
        winnersList: {
            type: [
                'array',
                'null'
            ]
        },
        leaderBoardSize: {
            type: [
                'number',
                'null'
            ]
        },
        isArchived: {
            type: [
                'boolean',
                'null'
            ]
        },
        isNew: {
            type: [
                'boolean',
                'null'
            ]
        },
        startDateNumber: {
            type: [
                'number',
                'null'
            ]
        },
        endDateNumber: {
            type: [
                'number',
                'null'
            ]
        },
        socialLinks: {
            type: [
                'array',
                'null'
            ]
        },
        isNT: {
            type: [
                'boolean'
            ]
        }
    }
};
export function isPromotion(argument) {
    return isValidJsonSchema(argument, PromotionPropertiesValidationSchema);
}
