// eslint-disable-next-line import/prefer-default-export
export function normalizeEventsChangesResponseToDefaultResponse(response) {
    if (!response) return null;
    return 'events' in response ? response : {
        enabled: response.enabled,
        events: response.data,
        vtag: response.vtag,
        totalCount: response.data.length
    };
}
