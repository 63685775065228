/* eslint-disable @typescript-eslint/no-redeclare */ export const GetEventSubscriptionStatus = {
    None: 'None',
    OK: 'OK',
    Missing: 'Missing',
    Error: 'Error',
    Unsubscribe: 'Unsubscribe'
};
export const GetEventSubscriptionAction = {
    Finish: 'Finish',
    AwaitNext: 'AwaitNext',
    Redirect: 'Redirect',
    ShowError: 'ShowError',
    Unsubscribe: 'Unsubscribe'
};
