import { isObject } from '@leon-hub/guards';
import { isFormUiSchemaField } from 'web/src/components/Form/guards';
import { FormControlType } from 'web/src/components/Form/enums';
import mapFieldRawOptions from './mapFieldRawOptions';
import modifyFieldPropertiesByWidget from './modifyFieldPropertiesByWidget';
/** function to merge all passed properties and map it into single field of formUiSchema[fields] */ export default function getUiSchemaField(commonProperties, customProperties, customOptions) {
    const rawFieldCommon = {
        ...commonProperties,
        ...customProperties
    };
    const { options, ...rawFieldCommonBase } = rawFieldCommon;
    const defaultOptions = isObject(options) ? {
        ...options
    } : {};
    const mappedFieldCommon = mapFieldRawOptions(rawFieldCommonBase);
    const mappedOptions = mapFieldRawOptions({
        ...defaultOptions,
        ...customOptions
    });
    const optionsIsNotEmpty = !!Object.keys(mappedOptions).length;
    const result = modifyFieldPropertiesByWidget({
        ...mappedFieldCommon,
        ...optionsIsNotEmpty ? {
            options: mappedOptions
        } : {}
    });
    // TODO: Temporary solution. Default comes as an empty string
    if (result.widget === FormControlType.AsoTermsCheckbox) return result;
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    isFormUiSchemaField(result), result.widget;
    return result;
}
