import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "data-value",
    "onClick",
    "onMouseenter"
];
import { VScrollbar } from '@components/v-scrollbar';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
import { useDropdownMenu } from '../composables';
import getFreeBetAmount from '../utils/getFreeBetAmount';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DropdownMenu',
    props: {
        maxHeight: {},
        forcedLightTheme: {
            type: Boolean
        },
        isFreebet: {
            type: Boolean
        },
        directionTop: {
            type: Boolean
        },
        searchQuery: {},
        preselectedListIndex: {
            default: -1
        },
        isCountrySelector: {
            type: Boolean
        },
        name: {
            default: 'DropdownMenu'
        },
        selectedValue: {
            default: ''
        },
        options: {
            default: ()=>[]
        }
    },
    emits: [
        "change",
        "mounted",
        "clear-search-query",
        "list-size-updated",
        "option-hover",
        "reached-bottom"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { onAppear, onClick, onOptionHover, setButtonsRef, scrollbarRef } = useDropdownMenu(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['drop-menu']]: true,
                    [_ctx.$style['drop-menu--light']]: _ctx.forcedLightTheme,
                    [_ctx.$style['drop-menu--direction-top']]: _ctx.directionTop
                })
            }, [
                _createVNode(_unref(VScrollbar), {
                    ref_key: "scrollbarRef",
                    ref: scrollbarRef,
                    "test-el": "dropdown-select",
                    style: _normalizeStyle(_ctx.maxHeight ? {
                        'max-height': `${_ctx.maxHeight}px`
                    } : {}),
                    "use-scroll-listener": "",
                    onReachedBottom: _cache[4] || (_cache[4] = ($event)=>emits('reached-bottom'))
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("ul", {
                                class: _normalizeClass(_ctx.$style['drop-menu__list']),
                                onVnodeMounted: _cache[3] || (_cache[3] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onAppear) && _unref(onAppear)(...args);
                                })
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index)=>(_openBlock(), _createElementBlock("li", {
                                        key: index,
                                        class: _normalizeClass(_ctx.$style['drop-menu__item'])
                                    }, [
                                        _createElementVNode("button", {
                                            ref_for: true,
                                            ref: (el)=>_unref(setButtonsRef)(el, index),
                                            type: "button",
                                            class: _normalizeClass({
                                                [_ctx.$style['drop-menu-button']]: true,
                                                [_ctx.$style['drop-menu-button--spaced']]: props.isFreebet,
                                                [_ctx.$style['drop-menu-button--light']]: props.forcedLightTheme,
                                                [_ctx.$style['drop-menu-button--selected']]: _ctx.preselectedListIndex === index
                                            }),
                                            "data-value": option.value,
                                            onFocus: _cache[0] || (_cache[0] = _withModifiers(()=>{}, [
                                                "prevent",
                                                "stop"
                                            ])),
                                            onClick: ($event)=>_unref(onClick)(option.value),
                                            onMouseenter: ($event)=>_unref(onOptionHover)(index)
                                        }, [
                                            props.isFreebet ? (_openBlock(), _createElementBlock(_Fragment, {
                                                key: 0
                                            }, [
                                                _createElementVNode("span", {
                                                    class: _normalizeClass(_ctx.$style['drop-menu-button__left'])
                                                }, _toDisplayString(option.label), 3),
                                                _createElementVNode("span", {
                                                    class: _normalizeClass(_ctx.$style['drop-menu-button__right'])
                                                }, _toDisplayString(_unref(getFreeBetAmount)(option, _ctx.isFreebet)), 3)
                                            ], 64)) : _ctx.isCountrySelector ? (_openBlock(), _createElementBlock(_Fragment, {
                                                key: 1
                                            }, [
                                                (_openBlock(), _createElementBlock(_Fragment, {
                                                    key: 1
                                                }, [
                                                    _createVNode(_unref(VCountryFlag), {
                                                        name: option.value,
                                                        class: _normalizeClass(_ctx.$style['drop-menu-button__flag'])
                                                    }, null, 8, [
                                                        "name",
                                                        "class"
                                                    ]),
                                                    _createTextVNode(_toDisplayString(option.label), 1)
                                                ], 64))
                                            ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                                                key: 2
                                            }, [
                                                _createTextVNode(_toDisplayString(option.label), 1)
                                            ], 64))
                                        ], 42, _hoisted_1)
                                    ], 2))), 128)),
                                _ctx.searchQuery && !_ctx.options?.length ? (_openBlock(), _createElementBlock("li", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['drop-menu__item'])
                                }, [
                                    _createElementVNode("button", {
                                        type: "button",
                                        class: _normalizeClass([
                                            _ctx.$style['drop-menu-button'],
                                            _ctx.$style['drop-menu-button--empty']
                                        ]),
                                        onFocus: _cache[1] || (_cache[1] = _withModifiers(()=>{}, [
                                            "prevent",
                                            "stop"
                                        ])),
                                        onClick: _cache[2] || (_cache[2] = ($event)=>emits('clear-search-query'))
                                    }, _toDisplayString(_ctx.$t('WEB2_NOTHING_FOUND')), 35)
                                ], 2)) : _createCommentVNode("", true)
                            ], 2)
                        ]),
                    _: 1
                }, 8, [
                    "style"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'DropdownMenu'
                ]
            ]);
        };
    }
});
