import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import { isCoreSportEventResponse, isGetCountersResponse, isGetCTagResponse, isGetLeagueResponse, isGetRegionResponse, isGetSportResponse, isGetSportsResponse } from 'web/src/modules/sportline/guards/rest';
import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';
function isResponseUnknownGuard(value) {
    // LEONWEB-10281 it used to skip rest api guards, and we'll filter the response later
    return true;
}
function serializeFlags(flags) {
    return flags?.length ? flags.join(',') : void 0;
}
function getLiveOutrightOptions(param) {
    let { LMCE } = param;
    return LMCE ? {
        LMCE
    } : {};
}
export default function createSportlineApi() {
    const client = useBaseApiClient();
    return {
        async getCTag (input) {
            return client.request({
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                id: 'sport/get-ctag',
                endpoint: '/betline/ctag',
                query: {},
                guard: isGetCTagResponse
            });
        },
        async getEvent (input) {
            return client.request({
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                id: 'sport/get-event',
                endpoint: `/betline/event/${input.betline}`,
                query: {
                    ctag: input.ctag,
                    eventId: input.eventId,
                    flags: serializeFlags(input.flags),
                    ...getLiveOutrightOptions(input)
                },
                guard: isCoreSportEventResponse
            });
        },
        async getEvents (input) {
            return client.request({
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                id: 'sport/get-events',
                endpoint: `/betline/events/${input.betline}`,
                query: {
                    ctag: input.ctag,
                    curLang: input.curLang,
                    from: input.from,
                    to: input.to,
                    region_id: input.regionId,
                    league_id: input.leagueId,
                    sport_id: input.sportId,
                    family: input.sportFamily,
                    limit: input.limit,
                    hideClosed: input.hideClosed,
                    flags: serializeFlags(input.flags),
                    favorite: input.favorite,
                    favoriteLeagues: input.favoriteLeagues,
                    login: input.login,
                    zeroMarginMarkets: input.zeroMarginMarkets,
                    order: input.order,
                    ...getLiveOutrightOptions(input)
                },
                guard: isResponseUnknownGuard
            });
        },
        async getChanges (input) {
            return client.request({
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                id: 'sport/get-changes',
                endpoint: `/betline/changes/${input.betline}`,
                query: {
                    ctag: input.ctag,
                    vtag: input.vtag,
                    curLang: input.curLang,
                    from: input.from,
                    to: input.to,
                    region_id: input.regionId,
                    league_id: input.leagueId,
                    sport_id: input.sportId,
                    family: input.sportFamily,
                    limit: input.limit,
                    hideClosed: input.hideClosed,
                    flags: serializeFlags(input.flags),
                    favorite: input.favorite,
                    favoriteLeagues: input.favoriteLeagues,
                    login: input.login,
                    zeroMarginMarkets: input.zeroMarginMarkets,
                    order: input.order,
                    ...getLiveOutrightOptions(input)
                },
                guard: isResponseUnknownGuard
            });
        },
        async getCounters (input) {
            return client.request({
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                id: 'sport/get-counters',
                endpoint: `/betline/count/${input.betline}`,
                query: {
                    ctag: input.ctag,
                    curLang: input.curLang,
                    from: input.from,
                    to: input.to,
                    region_id: input.regionId,
                    league_id: input.leagueId,
                    sport_id: input.sportId,
                    family: input.sportFamily,
                    limit: input.limit,
                    hideClosed: input.hideClosed,
                    flags: serializeFlags(input.flags),
                    favorite: input.favorite,
                    favoriteLeagues: input.favoriteLeagues,
                    login: input.login,
                    zeroMarginMarkets: input.zeroMarginMarkets,
                    order: input.order,
                    ...getLiveOutrightOptions(input)
                },
                guard: isGetCountersResponse
            });
        },
        async getSports (input) {
            return client.request({
                id: 'sport/get-sports',
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                endpoint: '/betline/sports',
                silent: input.silent,
                query: {
                    ctag: input.ctag,
                    curLang: input.curLang,
                    from: input.from,
                    to: input.to,
                    flags: serializeFlags(input.flags),
                    betlines: serializeFlags(input.betlines),
                    ...getLiveOutrightOptions(input)
                },
                guard: isGetSportsResponse
            });
        },
        async getRelatedEvents (input) {
            return client.request({
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                id: 'sport/get-related-events',
                endpoint: `/betline/related/${input.betline}`,
                query: {
                    eventId: input.eventId,
                    ctag: input.ctag,
                    vtag: input.vtag,
                    hideClosed: input.hideClosed,
                    flags: serializeFlags(input.flags),
                    ...getLiveOutrightOptions(input)
                },
                guard: isResponseUnknownGuard
            });
        },
        async searchEvents (input) {
            return client.request({
                id: 'sport/search',
                endpoint: '/betline/search',
                query: {
                    q: input.query,
                    curLang: input.curLang,
                    flags: serializeFlags(input.flags)
                },
                silent: true,
                guard: isResponseUnknownGuard
            });
        },
        async headlineMatches (input) {
            return client.request({
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                id: 'sport/betline-headline-matches',
                endpoint: '/betline/headline-matches',
                query: {
                    ctag: input.ctag,
                    hideClosed: input.hideClosed,
                    curLang: input.curLang,
                    flags: serializeFlags(input.flags),
                    merged: input.merged,
                    ...getLiveOutrightOptions(input)
                },
                guard: isResponseUnknownGuard
            });
        },
        async headlineMatchesChanges (input) {
            return client.request({
                id: 'sport/betline-headline-matches-changes',
                endpoint: '/betline/headline-matches/changes',
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                query: {
                    ctag: input.ctag,
                    inplayHeadlineVtag: input.inplayHeadlineVtag,
                    prematchHeadlineVtag: input.prematchHeadlineVtag,
                    allVtag: input.allVtag,
                    hideClosed: input.hideClosed,
                    curLang: input.curLang,
                    flags: input.flagsString ?? serializeFlags(input.flags),
                    ...getLiveOutrightOptions(input)
                },
                guard: isResponseUnknownGuard
            });
        },
        async getSport (input) {
            return client.request({
                id: 'sport/get-sport',
                endpoint: `/betline/sport/${input.sportId}`,
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                query: {
                    ctag: input.ctag,
                    curLang: input.curLang,
                    flags: serializeFlags(input.flags),
                    ...getLiveOutrightOptions(input)
                },
                guard: isGetSportResponse
            });
        },
        async getRegion (input) {
            return client.request({
                id: 'sport/get-region',
                endpoint: `/betline/region/${input.regionId}`,
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                query: {
                    ctag: input.ctag,
                    curLang: input.curLang,
                    flags: serializeFlags(input.flags),
                    ...getLiveOutrightOptions(input)
                },
                guard: isGetRegionResponse
            });
        },
        async getLeague (input) {
            return client.request({
                id: 'sport/get-league',
                endpoint: `/betline/league/${input.leagueId}`,
                silent: input.silent,
                priority: input.silent ? RequestOptionsPriority.LOW : void 0,
                query: {
                    ctag: input.ctag,
                    curLang: input.curLang,
                    flags: serializeFlags(input.flags),
                    ...getLiveOutrightOptions(input)
                },
                guard: isGetLeagueResponse
            });
        }
    };
}
