import { Timer } from '@leon-hub/utils';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import HtmlUtils from 'web/src/utils/HtmlUtils';
export function useScrollElementIntoView(props) {
    const bus = useEventsBus();
    let checkViewPortInterval = null;
    function clearCheckViewPortInterval() {
        if (!checkViewPortInterval) return;
        Timer.clearInterval(checkViewPortInterval);
        checkViewPortInterval = null;
    }
    function scrollContainerIntoView() {
        const containerElement = props.container?.value;
        // @TODO remake isViewPort to vue-observe-visibility
        if (!!containerElement && !HtmlUtils.inViewPort(containerElement)) bus.emit(BusEvent.LAYOUT_CONTENT_SET_SCROLL, {
            scrollTop: containerElement.offsetTop,
            smooth: false
        });
    }
    function startScrollIntoView() {
        if (checkViewPortInterval) return;
        checkViewPortInterval = Timer.setInterval(scrollContainerIntoView, 10);
    }
    function scrollIntoViewAndStop() {
        scrollContainerIntoView();
        clearCheckViewPortInterval();
    }
    function stopScrollIntoView() {
        clearCheckViewPortInterval();
    }
    return {
        startScrollIntoView,
        scrollIntoViewAndStop,
        stopScrollIntoView
    };
}
