import { sandboxApi } from '@leon-hub/script-sandbox';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
import { getInitSentryOptions } from 'web/src/modules/core/apps/main/utils/getInitSentryOptions';
import { LEXIS_NEXIS_SANDBOX_READY_TIMEOUT, LEXIS_NEXIS_UTILS_SCRIPT_URL_PATH } from '../constants';
export const createLexisNexisApi = (scriptUrl)=>{
    const origin = getLocationOrigin();
    return sandboxApi({
        id: 'lexis-nexis-api',
        strict: false,
        timeout: LEXIS_NEXIS_SANDBOX_READY_TIMEOUT,
        scripts: [
            {
                src: [
                    origin,
                    LEXIS_NEXIS_UTILS_SCRIPT_URL_PATH
                ].join('/'),
                inline: true
            },
            {
                src: scriptUrl,
                inline: true
            }
        ],
        origin,
        sentry: getInitSentryOptions()
    });
};
