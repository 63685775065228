import { doAchievementMarkAsViewed } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function doAchievementMarkAsViewedRequest(id) {
    const apiClient = useGraphqlClient();
    return doAchievementMarkAsViewed(apiClient, (node)=>node.mutations.achievements.markAsViewed, {
        options: {
            id
        }
    });
}
