import { computed } from 'vue';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
export default function nameDayWithDate(inputDate, $t) {
    const today = DateTime.now().toInputDate();
    const oneDay = 86400000;
    const tomorrow = DateTime.withTimeStamp(DateTime.now().toTimestamp() + oneDay).toInputDate();
    const dayWeek = new Date(inputDate).getDay();
    const keyParams = computed(()=>({
            date: DateTime.formatTimeStamp(DateTime.roundToDayStart(inputDate), TimeFormats.dayMonth)
        }));
    if (inputDate === today) return $t('WEB2_VERIFICATION_DAY_TODAY', keyParams).value;
    if (inputDate === tomorrow) return $t('WEB2_VERIFICATION_DAY_TOMORROW', keyParams).value;
    switch(dayWeek){
        case 0:
            return $t('WEB2_VERIFICATION_DAY_SUNDAY', keyParams).value;
        case 1:
            return $t('WEB2_VERIFICATION_DAY_MONDAY', keyParams).value;
        case 2:
            return $t('WEB2_VERIFICATION_DAY_TUESDAY', keyParams).value;
        case 3:
            return $t('WEB2_VERIFICATION_DAY_WEDNESDAY', keyParams).value;
        case 4:
            return $t('WEB2_VERIFICATION_DAY_THURSDAY', keyParams).value;
        case 5:
            return $t('WEB2_VERIFICATION_DAY_FRIDAY', keyParams).value;
        case 6:
            return $t('WEB2_VERIFICATION_DAY_SATURDAY', keyParams).value;
        default:
            return keyParams.value.date;
    }
}
