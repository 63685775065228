import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SSpinner',
    props: {
        kind: {
            default: 'primary'
        },
        size: {
            default: IconSize.SIZE_20
        }
    },
    setup (__props) {
        const element = ref();
        const isAnimated = ref(false);
        useIntersectionObserver(element, (isVisible)=>{
            isAnimated.value = isVisible;
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("svg", {
                ref_key: "element",
                ref: element,
                viewBox: "0 0 240 240",
                xmlns: "http://www.w3.org/2000/svg",
                class: _normalizeClass({
                    [_ctx.$style['sspinner']]: true,
                    [_ctx.$style[`sspinner--${_ctx.kind}`]]: _ctx.kind,
                    [_ctx.$style[`sspinner--${_ctx.size}`]]: _ctx.size,
                    [_ctx.$style["sspinner--animated"]]: isAnimated.value
                })
            }, [
                _createElementVNode("circle", {
                    class: _normalizeClass([
                        _ctx.$style['sspinner__circle'],
                        _ctx.$style['sspinner__circle--small'],
                        _ctx.$style['sspinner__circle--circle1']
                    ])
                }, null, 2),
                _createElementVNode("circle", {
                    class: _normalizeClass([
                        _ctx.$style['sspinner__circle'],
                        _ctx.$style['sspinner__circle--small'],
                        _ctx.$style['sspinner__circle--circle2']
                    ])
                }, null, 2),
                _createElementVNode("circle", {
                    class: _normalizeClass([
                        _ctx.$style['sspinner__circle'],
                        _ctx.$style['sspinner__circle--small'],
                        _ctx.$style['sspinner__circle--circle3']
                    ])
                }, null, 2),
                _createElementVNode("circle", {
                    class: _normalizeClass([
                        _ctx.$style['sspinner__circle'],
                        _ctx.$style['sspinner__circle--big'],
                        _ctx.$style['sspinner__circle--circle4']
                    ])
                }, null, 2),
                _createElementVNode("circle", {
                    class: _normalizeClass([
                        _ctx.$style['sspinner__circle'],
                        _ctx.$style['sspinner__circle--big'],
                        _ctx.$style['sspinner__circle--circle5']
                    ])
                }, null, 2),
                _createElementVNode("circle", {
                    class: _normalizeClass([
                        _ctx.$style['sspinner__circle'],
                        _ctx.$style['sspinner__circle--big'],
                        _ctx.$style['sspinner__circle--circle6']
                    ])
                }, null, 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SSpinner'
                ]
            ]);
        };
    }
});
