import { computed, ref, inject, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { accordionSharedState, isListAccordionSharedStateProps } from 'web/src/components/List/VList/types';
import getClassObject from './utils/getClassObject';
import getAccordionIconName from './utils/getAccordionIconName';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useVListItemAccordion(props, emit) {
    const listItemRef = ref();
    const $accordionSharedState = inject(accordionSharedState);
    isListAccordionSharedStateProps($accordionSharedState);
    const isIndependentlyOpen = ref(false);
    const isOpen = toRef(()=>{
        if (props.isDynamicOpen) return !!props.open;
        if ($accordionSharedState.independentItems) return isIndependentlyOpen.value;
        if ($accordionSharedState.openItem.value) return $accordionSharedState.openItem.value === props.id;
        return !!props.id && !!props.open;
    });
    function toggle() {
        let force = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (props.haveSwitcher && props.isSwitchDisabled && !force) emit('click-disabled', true);
        if (force || !props.haveSwitcher || props.haveSwitcher && !props.isSwitchDisabled) {
            if (!force) emit('click', true);
            isIndependentlyOpen.value = !isIndependentlyOpen.value;
            if (!$accordionSharedState?.independentItems) $accordionSharedState?.updateOpenItem(isOpen.value ? null : props.id);
        }
    }
    function scrollIntoView() {
        listItemRef.value?.scrollIntoView();
    }
    const iconOpen = IconName.EXPAND_UP;
    const iconClose = IconName.EXPAND_DOWN;
    return {
        isOpen,
        classObject: computed(()=>getClassObject(props, isOpen.value)),
        iconSuffixProperties: toRef(()=>isOpen.value ? iconOpen : iconClose),
        iconNameAccordion: toRef(()=>getAccordionIconName(props)),
        $accordionSharedState,
        isIndependentlyOpen,
        toggle,
        listItemRef,
        scrollIntoView
    };
}
