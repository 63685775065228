import { defineComponent as _defineComponent } from 'vue';
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionsTermsModalPage',
    props: {
        cmsKey: {},
        cmsData: {},
        spintaxCms: {
            type: Boolean,
            default: false
        },
        seoCms: {
            type: Boolean
        },
        silent: {
            type: Boolean
        },
        doNotUpdateIfExists: {
            type: Boolean
        },
        noPadding: {
            type: Boolean
        },
        loader: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['promotions-terms-padding'])
            }, [
                _createVNode(VCmsContent, _normalizeProps(_guardReactiveProps(props)), null, 16)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionsTermsModalPage'
                ]
            ]);
        };
    }
});
