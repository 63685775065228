import { ref, computed } from 'vue';
import { Timer } from '@leon-hub/utils';
export default function useTimeBus() {
    let updateEvery = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 1000;
    const now = ref(Date.now());
    let subscriptions = [];
    const timeout = ref();
    const isRunning = computed(()=>void 0 !== timeout.value);
    function forceUpdateNow() {
        now.value = Date.now();
    }
    function fireSubscriptions() {
        const dateNow = now.value;
        for (const subscription of subscriptions)subscription(dateNow);
    }
    function unsubscribe(subscription) {
        subscriptions = subscriptions.filter((item)=>item !== subscription);
    }
    function subscribe(subscription) {
        unsubscribe(subscription);
        subscriptions.push(subscription);
    }
    function stop() {
        if (!timeout.value) return;
        Timer.clearTimeout(timeout.value);
        timeout.value = void 0;
    }
    function start() {
        if (updateEvery <= 0) throw new Error('Unexpected update interval');
        stop();
        timeout.value = Timer.setTimeout(()=>{
            forceUpdateNow();
            fireSubscriptions();
            start();
        }, updateEvery);
    }
    function getSubscriptionsCount() {
        return subscriptions.length;
    }
    return {
        now,
        isRunning,
        start,
        stop,
        subscribe,
        unsubscribe,
        forceUpdateNow,
        getSubscriptionsCount
    };
}
