import { isNumber } from '@leon-hub/guards';
import { SportlineType } from 'web/src/modules/sportline/enums';
export function isSportlineType(value) {
    return isNumber(value) && (value === SportlineType.Live || value === SportlineType.Prematch);
}
export function isLiveSportlineType(value) {
    return isNumber(value) && value === SportlineType.Live;
}
export function isPrematchSportlineType(value) {
    return isNumber(value) && value === SportlineType.Prematch;
}
