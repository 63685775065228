export default function useMarketsListToolbar(props, emit) {
    function slideToActiveTab() {
        props.tabsRef.value?.slideToActive();
    }
    function onMarketTabClick(value) {
        emit('market-tab-click', value);
    }
    function onChangeStatisticSelected(value) {
        emit('change-statistic-selected', value ?? !props.isStatisticSelected?.value);
    }
    return {
        slideToActiveTab,
        onMarketTabClick,
        onChangeStatisticSelected
    };
}
