// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { _ as _define_property } from "@swc/helpers/_/_define_property";
let PostMessageEvent = class PostMessageEvent {
    toString() {
        return this.key;
    }
    constructor(key){
        _define_property(this, "key", void 0);
        this.key = key;
    }
};
export { PostMessageEvent as default };
