import { isString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config';
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getSportlinePageNavigationParameters(route) {
    const routeName = route.name;
    const parameters = route.params;
    switch(routeName){
        case RouteName.SPORTLINE_PREMATCH_TODAY:
        case RouteName.SPORTLINE_PREMATCH_EVENTS:
            {
                const { family } = parameters;
                return {
                    sportFamily: isString(family) ? family : ''
                };
            }
        case RouteName.SPORTLINE_LIVE_EVENTS:
            {
                const { family: urlName } = parameters;
                return {
                    sportFamily: isString(urlName) ? urlName : ''
                };
            }
        case RouteName.SPORTLINE_CYBERSPORT:
            {
                const { region: urlName } = parameters;
                return {
                    regionUrlName: isString(urlName) ? urlName : ''
                };
            }
        case RouteName.SPORT_REGION:
            {
                const { sportFamily, urlName, id } = parameters;
                return {
                    sportFamily: isString(sportFamily) ? sportFamily : '',
                    regionUrlName: isString(urlName) ? urlName : '',
                    regionId: isString(id) ? id : ''
                };
            }
        case RouteName.SPORTLINE_TOP_LEAGUE:
        case RouteName.SPORTLINE_LIVE_LEAGUE:
        case RouteName.SPORT_LEAGUE:
            {
                const { sportFamily, regionUrlName, urlName, id } = parameters;
                return {
                    sportFamily: isString(sportFamily) ? sportFamily : '',
                    regionUrlName: isString(regionUrlName) ? regionUrlName : '',
                    leagueUrlName: isString(urlName) ? urlName : '',
                    leagueId: isString(id) ? id : ''
                };
            }
        case RouteName.SPORT_EVENT_DETAILS:
            {
                const { leagueUrlName, regionUrlName, sportFamily, urlName, id } = parameters;
                return {
                    sportFamily: isString(sportFamily) ? sportFamily : '',
                    regionUrlName: isString(regionUrlName) ? regionUrlName : '',
                    leagueUrlName: isString(leagueUrlName) ? leagueUrlName : '',
                    sportEventUrlName: isString(urlName) ? urlName : '',
                    sportEventId: isString(id) ? id : ''
                };
            }
        default:
            return {
                sportFamily: ''
            };
    }
}
