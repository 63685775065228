import { computed } from 'vue';
import { getButtonProps } from 'web/src/components/Informer/VInformer/composables/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
export default function useVInformer(props) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const options = {};
    const badgeKind = BadgeKind.DEFAULT;
    const cancelButtonProperties = computed(()=>getButtonProps($translate('WEB2_CUSTOMER_HISTORY_CANCEL').value));
    const formattedValue = computed(()=>{
        if (props.currency) options.currency = props.currency;
        return `${$formatMoney(props.value, options)}`;
    });
    return {
        cancelButtonProperties,
        formattedValue,
        badgeKind
    };
}
