export default function getCasinoLobbyRoutePage(param) {
    let { name, path, meta, seoController, access } = param;
    return {
        name,
        path,
        meta: {
            ...meta,
            isEgsLobbyPage: true
        },
        access,
        prefetch: ()=>import('../prefetch/CasinoLobbyPagePrefetch'),
        component: ()=>import('../pages/CasinoLobbyRoutePage/CasinoLobbyRoutePage.vue'),
        leftSideBar: ()=>import('../views/CasinoSidebarRouteComponent/CasinoSidebarRouteComponent.vue'),
        isRightSidebarHidden: true,
        isLeftSidebarToggleHidden: true,
        navigation: // eslint-disable-next-line max-len
        void 0,
        seoController,
        // eslint-disable-next-line max-len
        topAuthorizedSlot: ()=>import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue')
    };
}
