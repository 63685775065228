import { BetlineReplaceResponse, SportEventsResponseChangeUtils } from 'web/src/modules/sportline/utils/rest';
/**
 * Replace one segment (sport, region or all) by new response
 */ export function updateSegmentEventsResponse(oldResponse, newResponse, options) {
    const { regionId, sportFamily } = options;
    let updated;
    updated = sportFamily ? new BetlineReplaceResponse(oldResponse).replaceSportByResponse(newResponse ?? null, sportFamily) : regionId ? new BetlineReplaceResponse(oldResponse).replaceRegionByResponse(newResponse ?? null, Number(regionId)) : newResponse ? BetlineReplaceResponse.unknownResponseToSportEventsResponse(newResponse) : null;
    const normalized = SportEventsResponseChangeUtils.normalizeDuplicatesResponse(updated);
    return normalized ? Object.freeze(normalized) : null;
}
