import { ApiErrorCode } from '@leon-hub/api';
import { AppError } from '@leon-hub/app-errors';
export class SliceEventsResponseError extends AppError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_UNEXPECTED_ERROR,
            message: `Sportline: ${options?.message || 'Unexpected slice response error'}`
        });
    }
}
