import { computed } from 'vue';
import useFormData from './useFormData';
import useExternalErrors from './useExternalErrors';
import useFormValidator from './useFormValidator';
import useFormFields from './useFormFields';
import useIsFormReady from './useIsFormReady';
import useFocusOnError from './useFocusOnError';
import getErrorsMapForOutput from './utils/getErrorsMapForOutput';
export default function useForm(param) {
    let { schema, uiSchema, customErrors, validationDisabled, errorPatterns, isPending, focusOnError, focusByName } = param;
    const { formData, formDataMap, touched, resetFormData, getSnapshot, restoreFormDataFromSnapShot, setTouchedOnSubmit, handleFieldInput, handleFieldBlur, refreshFormData } = useFormData({
        schema,
        uiSchema
    });
    const { externalErrors } = useExternalErrors({
        customErrors,
        uiSchema,
        validationDisabled,
        formDataMap,
        schema
    });
    const { validate, schemaErrors, resetErrors, haveAnyValidationErrors } = useFormValidator({
        schema,
        uiSchema,
        validationDisabled,
        errorPatterns,
        touched
    });
    const createFormOutput = function() {
        let field = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        return {
            formData: formData.value,
            errors: getErrorsMapForOutput(schemaErrors.value),
            customErrors: getErrorsMapForOutput(externalErrors.value),
            field
        };
    };
    const getCurrentOutput = ()=>createFormOutput();
    const hasAnyErrors = computed(()=>{
        if (validationDisabled) return false;
        return !!(haveAnyValidationErrors.value || Object.keys(externalErrors.value || {}).length);
    });
    const { isFormReady } = useIsFormReady({
        validationDisabled,
        uiSchema,
        schema,
        hasAnyErrors,
        formDataMap
    });
    const restoreFromSnapShot = function(snapshot) {
        let showErrorsAtRestore = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        restoreFormDataFromSnapShot(snapshot);
        if (showErrorsAtRestore) validate(formData.value);
    };
    const showValidationErrors = ()=>{
        setTouchedOnSubmit();
        validate(formData.value);
    };
    const reset = ()=>{
        resetFormData();
        resetErrors();
    };
    const { fields, buttonProps, captchaField } = useFormFields({
        schema,
        uiSchema,
        isFormReady,
        isPending,
        externalErrors,
        schemaErrors,
        formData
    });
    const { scrollToFirstError, setLinesRef, resetFocusOnError } = useFocusOnError(fields, externalErrors, focusOnError, focusByName);
    const handleFocus = (data)=>createFormOutput(data.name);
    const handleBlur = (data)=>{
        resetFocusOnError();
        handleFieldBlur(data);
        validate(formData.value, data.name);
        return createFormOutput(data.name);
    };
    const handleInput = (data)=>{
        handleFieldInput(data);
        validate(formData.value, data.name);
        return createFormOutput(data.name);
    };
    const handleSubmit = ()=>{
        setTouchedOnSubmit();
        validate(formData.value);
        scrollToFirstError();
        return createFormOutput();
    };
    return {
        reset,
        getSnapshot,
        restoreFromSnapShot,
        getCurrentOutput,
        handleFocus,
        handleBlur,
        handleInput,
        handleSubmit,
        refreshFormData,
        showValidationErrors,
        setLinesRef,
        fields,
        captchaField,
        buttonProps
    };
}
