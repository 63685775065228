import { toRef, computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ListItemExtendedKind } from 'web/src/components/List/VListItemExtended/enums';
import getClassObject from './utils/getClassObject';
export default function useVListItemExtended(props) {
    const isLogoDisabled = toRef(()=>!!props.isDisabled && props.kind === ListItemExtendedKind.LOGO);
    const hasSubTitleContent = toRef(()=>!!props.subTitleFirst || !!props.subTitleSecond || isLogoDisabled.value);
    const haveSuffix = toRef(()=>!!props.haveExpand && !props.isDisabled);
    return {
        classObject: computed(()=>getClassObject(props)),
        computedIconProperties: toRef(()=>({
                size: props.kind === ListItemExtendedKind.ICON_WITH_BACKGROUND ? IconSize.SIZE_32 : IconSize.SIZE_24,
                name: props.kind === ListItemExtendedKind.NOTIFICATION ? IconName.NOTIFICATION_DOT : props.iconName
            })),
        hasIcon: toRef(()=>props.kind === ListItemExtendedKind.ICON || props.kind === ListItemExtendedKind.ICON_WITH_BACKGROUND || props.kind === ListItemExtendedKind.NOTIFICATION),
        hasLogo: toRef(()=>props.kind === ListItemExtendedKind.LOGO),
        hasCheckbox: toRef(()=>props.kind === ListItemExtendedKind.CHECKBOX),
        isLogoDisabled,
        hasSubTitleContent,
        haveSuffix
    };
}
