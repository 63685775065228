import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import FileSingleInputStateless from '../FileSingleInputStateless';
import { useHintParentProps } from '../../composables';
import useFileSingleInput from './useFileSingleInput';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FileSingleInput',
    props: {
        fileName: {},
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'file-single'
        },
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        placeholder: {}
    },
    emits: [
        "focus",
        "blur",
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { uniqueId, inputFileName, onFocus, onBlur, onClear, onChange } = useFileSingleInput(props, emits);
        const { hintProperties } = useHintParentProps(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(FileSingleInputStateless), _mergeProps({
                autofocus: _ctx.autofocus,
                "input-id": _unref(uniqueId)
            }, _unref(hintProperties), {
                name: _ctx.name,
                "file-name": _unref(inputFileName),
                label: _ctx.label,
                disabled: _ctx.disabled,
                placeholder: _ctx.placeholder,
                "is-large": _ctx.isLarge,
                onFocus: _unref(onFocus),
                onBlur: _unref(onBlur),
                onChange: _unref(onChange),
                onClear: _unref(onClear)
            }), null, 16, [
                "autofocus",
                "input-id",
                "name",
                "file-name",
                "label",
                "disabled",
                "placeholder",
                "is-large",
                "onFocus",
                "onBlur",
                "onChange",
                "onClear"
            ])), [
                [
                    _directive_auto_id,
                    'FileSingleInput'
                ]
            ]);
        };
    }
});
