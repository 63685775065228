import { doScheduleMeeting } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function scheduleMeeting(param) {
    let { meetingSlotId, rescheduledMeetingSlotId } = param;
    const apiClient = useGraphqlClient();
    return doScheduleMeeting(apiClient, (node)=>node.mutations.verification.scheduleMeeting, {
        options: {
            meetingSlotId,
            ...rescheduledMeetingSlotId ? {
                rescheduledMeetingSlotId
            } : {}
        }
    });
}
