import { toRef } from 'vue';
import { useThemeStore } from '../store';
export default function useTheme() {
    const themeStore = useThemeStore();
    const isLight = toRef(themeStore, 'isLight');
    const isDark = toRef(themeStore, 'isDark');
    const isAuto = toRef(themeStore, 'isAuto');
    const theme = toRef(themeStore, 'theme');
    return {
        isLight,
        isDark,
        theme,
        isAuto
    };
}
