import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import PromotionDetailsDescription from 'web/src/modules/promotions/components/PromotionDetailsDescription/PromotionDetailsDescription.vue';
import VStatus from 'web/src/components/Status/VStatus/VStatus.vue';
import PromotionRate from 'web/src/modules/promotions/components/PromotionRate/PromotionRate.vue';
import { usePromotionDetailsHeader } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionDetailsHeader',
    props: {
        promotion: {}
    },
    setup (__props) {
        const props = __props;
        const { periodProperties } = usePromotionDetailsHeader(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['promotion-details-title']]: true,
                    [_ctx.$style['promotion-details-title--archived']]: _ctx.promotion.isArchived
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['promotion-details-title__wrapper'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['promotion-details-title__wrapper-top'])
                    }, [
                        _ctx.promotion.startDateNumber && _ctx.promotion.endDateNumber ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass({
                                [_ctx.$style['promotion-details-title__period']]: true,
                                [_ctx.$style['promotion-details-title__period--archived']]: _ctx.promotion.isArchived
                            })
                        }, [
                            _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(_unref(periodProperties)), 1)
                        ], 2)) : _createCommentVNode("", true),
                        _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['promotion-details-title__wrapper-middle'])
                    }, [
                        _createElementVNode("h1", {
                            class: _normalizeClass({
                                [_ctx.$style['promotions-shared__title']]: true,
                                [_ctx.$style['promotions-shared__title--big']]: true,
                                [_ctx.$style['promotion-details-title__name']]: true
                            })
                        }, _toDisplayString(_ctx.promotion.name), 3),
                        _ctx.promotion.description ? (_openBlock(), _createBlock(PromotionDetailsDescription, {
                            key: 0,
                            description: _ctx.promotion.description
                        }, null, 8, [
                            "description"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _ctx.promotion.isArchived ? (_openBlock(), _createBlock(VStatus, {
                        key: 0,
                        text: _ctx.$t('JSP_BONUSPROMO_ARCHIVED'),
                        class: _normalizeClass(_ctx.$style['promotion-details-title__status'])
                    }, null, 8, [
                        "text",
                        "class"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionDetailsHeader'
                ]
            ]);
        };
    }
});
