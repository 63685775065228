import { ComponentKey } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';
let TouchSupportComponent = class TouchSupportComponent extends AbstractFingerprintComponent {
    getComponentResult() {
        let maxTouchPoints = 0;
        let touchEvent;
        if (void 0 !== navigator.maxTouchPoints) maxTouchPoints = navigator.maxTouchPoints;
        else if (void 0 !== navigator.msMaxTouchPoints) maxTouchPoints = navigator.msMaxTouchPoints;
        try {
            document.createEvent('TouchEvent');
            touchEvent = true;
        } catch  {
            touchEvent = false;
        }
        const touchStart = 'ontouchstart' in window;
        return this.result([
            maxTouchPoints,
            touchEvent,
            touchStart
        ]);
    }
    constructor(){
        super(ComponentKey.TouchSupport);
    }
};
// Component source: https://github.com/fingerprintjs/fingerprintjs2/blob/master/fingerprint2.js#L886
export { TouchSupportComponent as default };
