import { doAddLoginDevice } from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { keys } from '@leon-hub/utils';
import { DeviceFingerprintType } from 'web/src/modules/identity/enums';
import { useIdentityStore } from 'web/src/modules/identity/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useLexisNexisIntegration } from 'web/src/modules/lexis-nexis-integration';
import { useShieldIntegration } from 'web/src/modules/shield-integration';
async function getFingerPrintId() {
    const { getDeviceFingerprint, getFingerPrintFromStorage } = useIdentityStore();
    try {
        if (!await getFingerPrintFromStorage(DeviceFingerprintType.DEVICE_FINGERPRINT_JS)) return await getDeviceFingerprint(DeviceFingerprintType.DEVICE_FINGERPRINT_JS) || void 0;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Unable to get device fingerprint', err);
    }
}
const getOptionalLexisNexisSessionId = async ()=>{
    let result;
    "1";
    {
        const { whenProfiledDuringRuntime, getSessionId, isEnabled } = useLexisNexisIntegration();
        if (isEnabled.value) {
            await whenProfiledDuringRuntime();
            result = await getSessionId();
        }
    }
    return result;
};
async function getShieldSessionId() {
    "1";
    {
        const { load, sessionId } = useShieldIntegration();
        await load();
        return sessionId.value;
    }
}
function getValues(record) {
    return keys(record).reduce((acc, id)=>{
        const settled = record[id];
        if ('rejected' === settled.status) {
            logger.error(`Unable to get ${id}`, normalizeError(settled.reason));
            return acc;
        }
        return {
            ...acc,
            [id]: settled.value
        };
    }, {});
}
async function getOptions(param) {
    let { skipLexis } = param;
    const [fpjsVisitorId, helmetDeviceId, lexGuardSessionId] = await Promise.allSettled([
        getFingerPrintId(),
        getShieldSessionId(),
        skipLexis ? void 0 : getOptionalLexisNexisSessionId()
    ]);
    return getValues({
        fpjsVisitorId,
        helmetDeviceId,
        lexGuardSessionId
    });
}
/**
 * Save different device/user identification data.
 */ const addLoginDevice = async function() {
    let { skipLexis } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const { fpjsVisitorId, helmetDeviceId, lexGuardSessionId } = await getOptions({
        skipLexis
    });
    if (fpjsVisitorId || helmetDeviceId || lexGuardSessionId) await doAddLoginDevice(useGraphqlClient(), (node)=>node.mutations.customer.addLoginDevice, {
        options: {
            fpjsVisitorId,
            helmetDeviceId,
            lexGuardSessionId
        }
    }, {
        silent: true
    });
};
export default addLoginDevice;
