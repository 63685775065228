import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
export const useThemeColorStore = defineStore('theme-color', ()=>{
    const customThemeColor = ref();
    let watcher;
    function startThemeColorWatcher(type) {
        if (watcher) throw new Error('Custom theme color change handler already registered!');
        watcher = watch(type, (newValue)=>{
            customThemeColor.value = newValue;
        }, {
            immediate: true
        });
    }
    function stopThemeColorWatcher() {
        if (watcher) {
            watcher();
            watcher = void 0;
        }
        customThemeColor.value = void 0;
    }
    return {
        customThemeColor,
        startThemeColorWatcher,
        stopThemeColorWatcher
    };
});
