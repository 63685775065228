import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getBanners } from '@leon-hub/api-sdk';
import { useSyncState } from 'web/src/modules/core/store/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useBannersStore = defineStore('banners', ()=>{
    const api = useGraphqlClient();
    const bannerGroups = ref([]);
    const setBannerGroups = (value)=>{
        bannerGroups.value = value;
    };
    async function loadBanners() {
        const result = await getBanners(api, (node)=>node.queries.banners.getBanners, void 0, {
            silent: true
        });
        setBannerGroups(result.bannerGroups);
    }
    useSyncState(loadBanners, 'banners');
    return {
        bannerGroups,
        loadBanners,
        setBannerGroups
    };
});
export default useBannersStore;
