import { ref, computed } from 'vue';
import { useCanShowSegmentGroup } from 'web/src/modules/sportline/submodules/segment/composables/useCanShowSegmentGroup';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { SegmentPageTabType } from 'web/src/modules/sportline/submodules/segment/enums';
export function useSegmentPageFilters(props) {
    const activeTransitionId = ref(null);
    const { isFilterTransitionFadeEffectEnabled, filterTransitionDuration, totalCount, hasStreamEvents, hasFavoritesOptimistic, selectedFilter, mainGroups } = props;
    const { canShowLiveForGroup, canShowPrematchForGroup } = useCanShowSegmentGroup({
        activeTransitionId,
        selectedFilter
    });
    const allGroupForFilter = computed(()=>({
            transitionId: CustomFilter.AllEvents,
            allowedForTransitionIds: [
                CustomFilter.AllEvents
            ],
            isActive: selectedFilter.value === CustomFilter.AllEvents,
            isEmpty: 0 === totalCount.value,
            isEmptyComing: false,
            key: CustomFilter.AllEvents,
            type: SegmentPageTabType.Default
        }));
    const mainGroupsForFilter = computed(()=>[
            ...mainGroups.value
        ]);
    const favoriteGroupForFilter = computed(()=>hasFavoritesOptimistic.value ? {
            transitionId: CustomFilter.Favorites,
            allowedForTransitionIds: [
                CustomFilter.Favorites
            ],
            isActive: selectedFilter.value === CustomFilter.Favorites,
            isEmpty: false,
            isEmptyComing: false,
            key: CustomFilter.Favorites,
            type: SegmentPageTabType.Default
        } : null);
    const streamEventsGroupForFilter = computed(()=>hasStreamEvents.value ? {
            transitionId: CustomFilter.Stream,
            allowedForTransitionIds: [
                CustomFilter.Stream
            ],
            isActive: selectedFilter.value === CustomFilter.Stream,
            isEmpty: false,
            isEmptyComing: false,
            key: CustomFilter.Stream,
            type: SegmentPageTabType.Default
        } : null);
    function setActiveTransitionId(id) {
        activeTransitionId.value = id;
    }
    return {
        allGroupForFilter,
        mainGroupsForFilter,
        favoriteGroupForFilter,
        streamEventsGroupForFilter,
        isFilterTransitionFadeEffectEnabled,
        filterTransitionDuration,
        activeTransitionId,
        setActiveTransitionId,
        canShowLiveForGroup,
        canShowPrematchForGroup
    };
}
