import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef, computed } from 'vue';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { SportBackgroundColorDark } from 'web/src/modules/sportline/constants';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PowerPlayBadge',
    props: {
        count: {}
    },
    setup (__props) {
        const props = __props;
        const count = toRef(props, 'count');
        const label = computed(()=>count.value > 1 ? `PP${count.value}` : 'PP');
        const textColor = SportBackgroundColorDark[SportFamily.IceHockey];
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("span", {
                class: _normalizeClass(_ctx.$style['power-play-badge'])
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['power-play-badge__label']),
                    style: _normalizeStyle({
                        color: _unref(textColor)
                    })
                }, _toDisplayString(label.value), 7)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PowerPlayBadge'
                ]
            ]);
        };
    }
});
