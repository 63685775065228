import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import vPopperHint from 'web/src/components/Popper/directives/popperHint';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticLeagueStandingsPromotion',
    props: {
        promotion: {}
    },
    setup (__props) {
        const promotionIndicatorClasses = {
            // eslint-disable-next-line max-len
            'standings-team-promotion--continental-league-playoff': new Set([
                '2',
                '45',
                '29',
                '44',
                '19',
                '15',
                '40',
                '10',
                '42',
                '39'
            ]),
            'standings-team-promotion--continental-league': new Set([
                '1',
                '16',
                '25',
                '9',
                '14',
                '20',
                '35'
            ]),
            'standings-team-promotion--second-continental-league': new Set([
                '18',
                '36',
                '26',
                '17',
                '37',
                '3',
                '11',
                '33',
                '38'
            ]),
            'standings-team-promotion--second-continental-league-playoff': new Set([
                '27',
                '4',
                '24'
            ]),
            'standings-team-promotion--relegation-play-off': new Set([
                '8',
                '23',
                '30'
            ]),
            'standings-team-promotion--relegation-direct': new Set([
                '7',
                '31'
            ]),
            'standings-team-promotion--promotion-direct': new Set([
                '5'
            ]),
            'standings-team-promotion--promotion-playoff': new Set([
                '6'
            ]),
            'standings-team-promotion--next-round-play-off': new Set([
                '28'
            ])
        };
        function getPromotionIndicatorClasses(promotion) {
            const promotionId = promotion.id;
            if (!promotionId) return {};
            const modifier = Object.keys(promotionIndicatorClasses).find((key)=>promotionIndicatorClasses[key].has(promotionId));
            return {
                [modifier || 'standings-team-promotion--filled']: true
            };
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "standings-team-promotion",
                    getPromotionIndicatorClasses(_ctx.promotion)
                ])
            }, null, 2)), [
                [
                    _directive_auto_id,
                    'StatisticLeagueStandingsPromotion'
                ],
                [
                    _unref(vPopperHint),
                    _ctx.promotion.name
                ]
            ]);
        };
    }
});
