import { BetlineOrder, BetlineType } from 'web/src/modules/sportline/enums/rest';
import { useSportlinePendingApi } from './useSportlinePendingApi';
export function useSportlineApiLoadFavoritesLeaguesEvents(props) {
    const { tryRequestWithCTag, defaultRestApiFlags, isLiveOutrightsEnabled } = props;
    const { loadEvents: loadFavoriteLeaguesEvents } = useSportlinePendingApi({
        tryRequestWithCTag,
        getEventsInput: (param)=>{
            let { login, silent } = param;
            return {
                betline: BetlineType.All,
                favoriteLeagues: true,
                login,
                silent,
                hideClosed: true,
                flags: [
                    ...defaultRestApiFlags.value
                ],
                order: BetlineOrder.SportThenKickoff,
                LMCE: isLiveOutrightsEnabled.value
            };
        },
        getPendingParams: (param)=>{
            let { vTag, login } = param;
            return {
                vTag,
                login,
                betlines: [
                    BetlineType.All
                ]
            };
        }
    });
    return {
        loadFavoriteLeaguesEvents
    };
}
