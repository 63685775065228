import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { doChangeCustomerNickname, doChangePassword, doLogin as apiDoLogin, doLoginCas, doLoginFingerprint, doLoginG2sv, doLoginPinCode, doMarkDeviceUntrusted, doModifyTimeZone, doUpdateNotificationConsents, getConsents, getUserData, getUserProfileBonus, logout as apiLogout, RequestOptionsPriority, resendVerificationEmail as apiResendVerificationEmail, setAdditionalPropsUser as apiSetAdditionalPropsUser, verifyEmail } from '@leon-hub/api-sdk';
import { isNumber, isObject, isString } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { roundToDivisible } from '@leon-hub/utils';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { getDeferredDeviceIDStorage } from 'web/src/modules/auth/utils/getDeferredDeviceIDStorage';
import { useSyncState } from 'web/src/modules/core/store/composables';
import { startLoginDeviceRoutineEventType, useAppEmitter } from 'web/src/modules/emitter';
import { useI18n } from 'web/src/modules/i18n/composables';
import DeviceCustomerLoginStorage from 'web/src/modules/identity/utils/deviceCustomerLoginStorage';
import ServerDate from 'web/src/utils/ServerDate';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useCustomerDataStore, useCustomerFinanceStore } from 'web/src/modules/customer/store';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { captchaChallengeWasShownKey } from 'web/src/modules/captcha/store/constants';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { LOCAL_STORAGE_LOGIN_TAB_KEY } from 'web/src/modules/login/pages/LoginRoutePage/types';
import { localStorageCustomerTimezoneOffsetKey } from './constants';
import { LoginMethod } from './enums';
import analyticsLogLogin from './utils/analyticsLogLogin';
export const useUserStore = defineStore('user', ()=>{
    const api = useGraphqlClient();
    const bus = useEventsBus();
    const localStorage = useLocalStorageManager();
    const authStore = useAuthStore();
    const { setLoggedIn } = useAuthStore();
    const isLoggedIn = toRef(authStore, 'isLoggedIn');
    const { convertToBaseError } = useErrorsConverter();
    const customerDataStore = useCustomerDataStore();
    const { setCustomerData, setupCustomerData, updateCustomerData, loadCustomerPhone } = useCustomerDataStore();
    const { setBalance } = useCustomerFinanceStore();
    const { setCurrency } = useCurrencyStore();
    const isEmailConfirmed = toRef(customerDataStore, 'isEmailConfirmed');
    const userLanguageTag = toRef(customerDataStore, 'userLanguageTag');
    const customerData = toRef(customerDataStore, 'customerData');
    // state
    const profileData = ref(null);
    const pinCodeData = ref(null);
    const consents = ref({});
    const confirmationLinkWasSent = ref(false);
    const tooManyConfirmationLinkRequests = ref(false);
    const loginOptionsCAS = ref(null);
    // getters
    const isBonusTermsAccepted = computed(()=>profileData.value?.bonusTermsAccepted ?? false);
    const inGameValue = computed(()=>profileData.value?.waitingBetsAmount || 0);
    const safeUserConsents = computed(()=>{
        if (isEmailConfirmed.value) return consents.value;
        // force to confirm email
        return {
            ...consents.value,
            email: false
        };
    });
    // actions / mutations
    const loadUserBonusData = async (silent)=>{
        const response = await getUserProfileBonus(api, (node)=>node.queries.customer.getProfileData, {
            options: {}
        }, {
            ...isObject(silent) ? silent : {
                silent
            }
        });
        if (response?.profileData) profileData.value = response.profileData;
    };
    const getUserDataQuery = (silent)=>Promise.all([
            loadCustomerData(silent),
            loadUserBonusData(silent)
        ]);
    const loadUserData = async (silent)=>{
        await getUserDataQuery(silent);
    };
    const doLogin = async (options)=>{
        const { loginMethod } = options;
        let loginMutation;
        /* eslint-disable @typescript-eslint/no-duplicate-type-constituents */ /* eslint-enable @typescript-eslint/no-duplicate-type-constituents */ api.clearQueue();
        const requestOptions = {
            priority: RequestOptionsPriority.HIGH,
            timeout: 60000
        };
        switch(options.loginMethod){
            case LoginMethod.DEFAULT:
                loginMutation = apiDoLogin(api, (node)=>node.mutations.auth.login, {
                    options: options.payload
                }, requestOptions);
                break;
            case LoginMethod.CAS:
                loginOptionsCAS.value = options;
                loginMutation = doLoginCas(api, (node)=>node.mutations.auth.loginSso, {
                    options: options.payload
                }, requestOptions);
                break;
            case LoginMethod.G2SV:
                loginMutation = doLoginG2sv(api, (node)=>node.mutations.auth.loginG2sv, {
                    options: options.payload
                }, requestOptions);
                break;
            case LoginMethod.FINGERPRINT:
                loginMutation = doLoginFingerprint(api, (node)=>node.mutations.auth.loginFingerprint, {
                    options: options.payload
                }, requestOptions);
                break;
            default:
                throw new Error(`Unknown loginMethod=${loginMethod}`);
        }
        // user data queries will be batched together with login mutation and sent as one gql batch
        await Promise.all([
            loginMutation,
            getUserDataQuery(requestOptions)
        ]);
        if (options.payload.loginType) localStorage.setItem(LOCAL_STORAGE_LOGIN_TAB_KEY, options.payload.loginType);
        if (userLanguageTag.value) await useI18n().setLanguageTag({
            languageTag: userLanguageTag.value,
            reload: true
        });
        setLoggedIn(true);
        analyticsLogLogin(options, customerData.value);
    };
    const auth = async (payload)=>{
        await doLogin({
            loginMethod: LoginMethod.DEFAULT,
            payload
        });
        window.sessionStorage.removeItem(captchaChallengeWasShownKey);
        DeviceCustomerLoginStorage.setLogin(payload.username);
    };
    const authCas = async (payload)=>{
        await doLogin({
            loginMethod: LoginMethod.CAS,
            payload
        });
        window.sessionStorage.removeItem(captchaChallengeWasShownKey);
        DeviceCustomerLoginStorage.setLogin(payload.username);
    };
    const authG2sv = async (payload)=>{
        await doLogin({
            loginMethod: LoginMethod.G2SV,
            payload
        });
    };
    const authFingerprint = async (payload)=>{
        await doLogin({
            loginMethod: LoginMethod.FINGERPRINT,
            payload
        });
    };
    const setPinCodeData = (data)=>{
        pinCodeData.value = data;
    };
    const doPinCodeLogin = async (pinCode)=>{
        await doLoginPinCode(api, (node)=>node.mutations.auth.loginPinCode, {
            options: {
                ...pinCodeData.value,
                pinCode
            }
        });
        setLoggedIn(true);
        pinCodeData.value = null;
        await loadUserData(true);
        if (loginOptionsCAS.value) {
            analyticsLogLogin(loginOptionsCAS.value, customerData.value);
            loginOptionsCAS.value = null;
        }
    };
    const logout = async ()=>{
        api.clearQueue();
        setLoggedIn(false);
        await apiLogout(api, (node)=>node.mutations.auth.logout);
    };
    const resetUserState = ()=>{
        setLoggedIn(false);
        setCustomerData(null);
        profileData.value = null;
    };
    const signOut = async ()=>{
        await logout();
        resetUserState();
    };
    const changePassword = async (param)=>{
        let { oldPassword, newPassword } = param;
        await doChangePassword(api, (node)=>node.mutations.customer.changePassword, {
            options: {
                oldPassword,
                newPassword
            }
        });
    };
    const getNotificationConsents = async (silent)=>{
        const response = await getConsents(api, (node)=>node.queries.customer.notifications.getConsents, {
            options: {}
        }, {
            silent
        });
        if (response?.consents) consents.value = response.consents;
    };
    const updateNotificationConsents = async (options)=>{
        const { ts, ...consentsSettings } = options;
        consents.value = consentsSettings;
        await doUpdateNotificationConsents(api, (node)=>node.mutations.customer.updateNotificationConsents, {
            options
        });
    };
    const enablePushConsent = async ()=>{
        const response = await getConsents(api, (node)=>node.queries.customer.notifications.getConsents, {
            options: {}
        });
        await updateNotificationConsents({
            ...response.consents,
            pushNotification: true
        });
    };
    const confirmEmailByToken = async (token)=>verifyEmail(api, (node)=>node.mutations.customer.verifyEmail, {
            options: {
                token
            }
        });
    const setTooManyConfirmationLinkRequests = (tooMany)=>{
        tooManyConfirmationLinkRequests.value = tooMany;
    };
    const resendVerificationEmail = async ()=>{
        try {
            await apiResendVerificationEmail(api, (node)=>node.mutations.customer.resendVerificationEmail, {
                options: {}
            });
        } catch (error) {
            const formattedError = convertToBaseError(error);
            if (formattedError.code.equals('EMAIL_VERIFICATION_SENT_TOO_OFTEN')) {
                setTooManyConfirmationLinkRequests(true);
                return;
            }
            throw error;
        }
    };
    const checkCustomerTimezoneOffset = async ()=>{
        if (!isLoggedIn.value) {
            localStorage.removeItem(localStorageCustomerTimezoneOffsetKey);
            return;
        }
        const savedOffset = localStorage.getItem(localStorageCustomerTimezoneOffsetKey);
        const currentOffset = ServerDate.getCustomerTimezoneOffset();
        if (savedOffset !== String(currentOffset)) {
            let timeZone = 60 * currentOffset;
            // LEONWEB-8244 log incorrect value (possible incorrect getTimezoneOffset value)
            if (timeZone % 30 !== 0) {
                logger.error('Customer timezone is not divisible by 30', {
                    timeZone,
                    timezoneOffset: currentOffset,
                    oldTimezoneOffset: savedOffset,
                    serverTimeShift: ServerDate.getTimeShift(),
                    getTimezoneOffset: new Date().getTimezoneOffset()
                });
                timeZone = roundToDivisible(timeZone, 30);
            }
            await doModifyTimeZone(api, (node)=>node.mutations.customer.modifyTimeZone, {
                options: {
                    timeZone
                }
            }, {
                silent: true
            });
            localStorage.setItem(localStorageCustomerTimezoneOffsetKey, String(currentOffset));
        }
    };
    const markDeviceUntrusted = async (options)=>{
        await doMarkDeviceUntrusted(api, (node)=>node.mutations.customer.markDeviceUntrusted, {
            options
        });
        if (isLoggedIn.value) await logout();
    };
    const setAdditionalPropsUser = async (options)=>{
        try {
            await apiSetAdditionalPropsUser(api, (node)=>node.mutations.customer.config.setConfig, {
                options
            });
        } catch (error) {
            logger.error('UserActions setAdditionalPropsUser Error:', error);
        }
        customerDataStore.setCustomerConfigCache(options.config, options.value);
    };
    const updateNickname = async (options)=>{
        await doChangeCustomerNickname(api, (node)=>node.mutations.customer.changeNickname, {
            options
        });
    };
    const setConfirmationLinkWasSent = (wasSent)=>{
        confirmationLinkWasSent.value = wasSent;
    };
    // former init
    useSyncState(async (silent)=>{
        if (isLoggedIn.value) await loadUserData(silent);
    }, 'user', {
        disableLoginLogoutHandlers: true
    });
    const initBusEvents = ()=>{
        bus.on(BusEvent.NEW_BET_HAS_BEEN_PLACED, ()=>{
            loadUserBonusData(true);
        });
    };
    async function loadCustomerData(silent) {
        const options = {
            ...isObject(silent) ? silent : {
                silent
            }
        };
        const response = await getUserData(api, (node)=>node.queries.customer.getUserData, {
            options: {}
        }, options);
        response.customerData;
        setupCustomerData(response.customerData);
        const { balance, currency } = response.customerData;
        if (isNumber(balance) && isString(currency)) {
            setBalance(balance, 1);
            setCurrency(currency);
        }
    }
    async function updateUserData(payload) {
        await updateCustomerData(payload);
        await loadCustomerData();
    }
    async function updateCustomerPhone(param) {
        let { silent } = param;
        if (!customerData.value) {
            await loadCustomerData(silent);
            return;
        }
        await loadCustomerPhone({
            silent
        });
    }
    function loginWatchCallback(newIsLoggedIn) {
        if (newIsLoggedIn) {
            checkCustomerTimezoneOffset();
            {
                const isRefreshRequired = useI18n().isLanguageChangeable(userLanguageTag.value);
                if (isRefreshRequired) {
                    const { login } = customerDataStore;
                    getDeferredDeviceIDStorage(login).set(true);
                } else useAppEmitter().emit(startLoginDeviceRoutineEventType);
            }
        } else {
            setCustomerData(null);
            profileData.value = null;
            checkCustomerTimezoneOffset();
        }
    }
    function init() {
        initBusEvents();
        watch(isLoggedIn, loginWatchCallback);
    }
    init();
    return {
        // state/getters
        isBonusTermsAccepted,
        inGameValue,
        consents: safeUserConsents,
        confirmationLinkWasSent,
        tooManyConfirmationLinkRequests,
        profileData,
        // actions
        auth,
        authCas,
        authG2sv,
        authFingerprint,
        doPinCodeLogin,
        setPinCodeData,
        logout,
        signOut,
        changePassword,
        getNotificationConsents,
        updateNotificationConsents,
        enablePushConsent,
        confirmEmailByToken,
        resendVerificationEmail,
        markDeviceUntrusted,
        setAdditionalPropsUser,
        updateNickname,
        loadUserData,
        setConfirmationLinkWasSent,
        setTooManyConfirmationLinkRequests,
        loadCustomerData,
        updateUserData,
        updateCustomerPhone,
        loadUserBonusData
    };
});
export default useUserStore;
