import { RequestGroupScore24 } from '@leon-hub/api';
import { getLeagueData as getBetlineLeagueDataByLeagueId } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function getBetlineLeagueData(options, param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return getBetlineLeagueDataByLeagueId(apiClient, (node)=>node.queries.betLine.getLeagueData, {
        options
    }, {
        silent,
        group: RequestGroupScore24
    });
}
