import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
import { AlertIconName } from '@leon-hub/icons';
import VRadio from 'web/src/components/Radio/VRadio/VRadio.vue';
import { getImageOrIcon } from 'web/src/modules/icons';
import { usePushNotificationsBetsOnboardingRoutePage } from 'web/src/modules/push-notifications/pages/PushNotificationsBetsOnboardingRoutePage/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import PushNotificationsOnboardingImage from 'web/src/modules/cordova/components/PushNotificationsOnboardingImage/PushNotificationsOnboardingImage.vue';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PushNotificationsBetsOnboardingRoutePage',
    setup (__props) {
        const { isSaved, setImageLoaded, isImageLoaded, pushInputs, onPushSettingChanged, onSubmit } = usePushNotificationsBetsOnboardingRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['push-notifications-bets-onboarding'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(PushNotificationsOnboardingImage, {
                        class: _normalizeClass(_ctx.$style['push-notifications-bets-onboarding__img']),
                        onLoad: _unref(setImageLoaded),
                        onError: _unref(setImageLoaded)
                    }, null, 8, [
                        "class",
                        "onLoad",
                        "onError"
                    ]),
                    _withDirectives(_createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['push-notifications-bets-onboarding__content'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['push-notifications-bets-onboarding__title'])
                        }, _toDisplayString(_ctx.$t('WEB2_ONBOARDING_PUSH_TITLE')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['push-notifications-bets-onboarding__desc'])
                        }, _toDisplayString(_ctx.$t('WEB2_ONBOARDING_PUSH_TEXT')), 3),
                        _createElementVNode("form", {
                            class: _normalizeClass(_ctx.$style['push-notifications-bets-onboarding__form-block'])
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pushInputs), (input)=>(_openBlock(), _createBlock(VRadio, {
                                    key: input.id,
                                    value: input.id,
                                    label: input.name,
                                    checked: input.isEnabled,
                                    name: "push-settings",
                                    onChange: ($event)=>_unref(onPushSettingChanged)($event, input.id)
                                }, null, 8, [
                                    "value",
                                    "label",
                                    "checked",
                                    "onChange"
                                ]))), 128))
                        ], 2),
                        _createVNode(LButton, {
                            kind: _unref(ButtonKind).PRIMARY,
                            "full-width": "",
                            label: _ctx.$t('WEB2_ONBOARDING_SWIPER_BUTTON'),
                            class: _normalizeClass(_ctx.$style['push-notifications-onboarding__btn--primary']),
                            onClick: _unref(onSubmit)
                        }, null, 8, [
                            "kind",
                            "label",
                            "class",
                            "onClick"
                        ])
                    ], 2), [
                        [
                            _vShow,
                            _unref(isImageLoaded)
                        ]
                    ])
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PushNotificationsBetsOnboardingRoutePage'
                ],
                [
                    _vShow,
                    !_unref(isSaved)
                ]
            ]);
        };
    }
});
