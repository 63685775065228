import { computed } from 'vue';
import { isNumber } from '@leon-hub/guards';
import { numberToDouble } from 'web/src/modules/profile/submodules/customer-history/utils';
export default function useBetPrice(historyItem) {
    const betPrice = computed(()=>{
        isNumber(historyItem.value.betClientPrice);
        return historyItem.value.betClientPriceStr ?? numberToDouble(historyItem.value.betClientPrice);
    });
    return {
        betPrice
    };
}
