import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
export default function useTheLiveChatWidget(props) {
    const showCounter = computed(()=>props.newMessagesCounter > 0);
    const buttonProps = {
        iconName: IconName.CHAT,
        iconSize: IconSize.SIZE_24,
        kind: ButtonKind.PRIMARY,
        rounded: true
    };
    const badgeProps = computed(()=>({
            label: props.newMessagesCounter > 0 ? String(props.newMessagesCounter) : '0',
            kind: BadgeKind.CHAT
        }));
    return {
        showCounter,
        buttonProps,
        badgeProps
    };
}
