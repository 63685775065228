import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, ref } from 'vue';
import { TabsType } from '../VTabs/enums';
import STabsButton from '../STabsButton/STabsButton.vue';
import LTabsButton from '../LTabsButton/LTabsButton.vue';
import { useVTabsButton } from './useVTabsButton';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VTabsButton',
    props: {
        version: {},
        isBordered: {
            type: Boolean
        },
        sTabButtonKind: {},
        type: {
            default: TabsType.NORMAL
        },
        flexible: {
            type: Boolean
        },
        active: {
            type: Boolean
        },
        single: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        isNavigation: {
            type: Boolean
        },
        href: {},
        id: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const stab = ref(null);
        const ltab = ref(null);
        const tab = computed(()=>ltab.value ?? stab.value);
        const { remapProps } = useVTabsButton();
        function getOffsetLeft() {
            return tab.value?.getOffsetLeft() || 0;
        }
        __expose({
            getOffsetLeft
        });
        return (_ctx, _cache)=>(_openBlock(), _createBlock(LTabsButton, _mergeProps({
                key: 1
            }, _unref(remapProps)(props), {
                ref_key: "ltab",
                ref: ltab,
                onClick: _cache[1] || (_cache[1] = ($event)=>emit('click', $event))
            }), _createSlots({
                _: 2
            }, [
                _ctx.$slots.default ? {
                    name: "default",
                    fn: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                    key: "0"
                } : void 0
            ]), 1040));
    }
});
