import { doSendWeb2Feedback, FeedbackChannel } from '@leon-hub/api-sdk/src/sdk/sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function useUserFeedbackResponse() {
    const apiClient = useGraphqlClient();
    async function sendUserResponse(param) {
        let { body, channel = FeedbackChannel.DEFAULT, actionId, lbType, rating, email } = param;
        await doSendWeb2Feedback(apiClient, (node)=>node.mutations.feedback.sendWeb2Feedback, {
            options: {
                body,
                channel,
                actionId,
                lbType,
                rating,
                email
            }
        });
    }
    return {
        sendUserResponse
    };
}
