import { computed, ref } from 'vue';
export function usePaymentsCategories() {
    const paymentSystems = ref({});
    const data = ref([]);
    function setCategories(categories) {
        data.value = categories;
    }
    const paymentCategories = computed(()=>{
        const fetchedCategories = [];
        for (const [index, category] of data.value.entries())if (category.name) {
            const cat = {
                id: index,
                name: category.name,
                isFolded: !!('isFolded' in category && category.isFolded),
                // TODO: It's better to add the property isFolded in WithdrawalCategoriesDocument as well
                payments: []
            };
            for (const system of category.paymentSystems)if (system.id) {
                paymentSystems.value[system.id] = system;
                cat.payments.push(system.id);
            }
            fetchedCategories.push(cat);
        }
        return fetchedCategories;
    });
    // eslint-disable-next-line max-len
    const categories = computed(()=>paymentCategories.value.map((category)=>({
                id: category.id,
                name: category.name,
                isFolded: category.isFolded,
                paymentSystems: category.payments.map((payment)=>paymentSystems.value[payment])
            })));
    return {
        paymentSystems,
        categories,
        setCategories
    };
}
