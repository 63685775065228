import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useIntercomEvents } from 'web/src/modules/intercom/composables';
import useSupport from 'web/src/modules/support/composables/useSupport';
export default function useBaseErrorContent(props) {
    const { $translate } = useI18n();
    const { openIntercomMenu } = useIntercomEvents();
    const { isIntercomAvailable, isChatMode } = useSupport();
    const mainJumbotronProps = computed(()=>({
            icon: props.iconName,
            iconSize: IconSize.SIZE_80,
            heading: props.title,
            text: props.text
        }));
    const mainButtonProps = computed(()=>({
            kind: ButtonKind.PRIMARY,
            label: $translate('WEB2_ERROR_503_BTN_UPDATE_V2').value,
            fullWidth: true
        }));
    const email = computed(()=>{
        const companyEmail = void 0;
        return companyEmail || $translate('WEB2_HELP_EMAIL').value;
    });
    const supportJumbotronProps = computed(()=>({
            text: $translate('WEB2_ERROR_TEXT_SUPPORT').value
        }));
    const supportButtonProps = computed(()=>({
            kind: ButtonKind.BASE,
            label: props.isEmailAsLabel ? email.value : $translate('WEB2_ERROR_BTN_SUPPORT').value,
            isUppercase: false,
            rounded: true
        }));
    function onMainClick() {
        window.location.reload();
    }
    function onSupportClick() {
        if (isIntercomAvailable.value && isChatMode.value) openIntercomMenu();
        else window.location.href = `mailto:${email.value}`;
    }
    useHead({
        meta: [
            {
                name: 'prerender-status-code',
                content: '503'
            }
        ]
    });
    return {
        mainJumbotronProps,
        mainButtonProps,
        supportJumbotronProps,
        supportButtonProps,
        onMainClick,
        onSupportClick
    };
}
