import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "placeholder",
    "value"
];
const _hoisted_2 = [
    "title"
];
import { VCoreDropdown } from 'web/src/components/CoreDropdown';
import DropdownMenu from './DropdownMenu.vue';
import { useDropdownSelect } from '../composables';
import DropdownWrapper from './DropdownWrapper.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DropdownSelect',
    props: {
        dropdownMinWidth: {
            default: 0
        },
        hideSelectedOption: {
            type: Boolean,
            default: true
        },
        selectedLabel: {
            default: ''
        },
        enableSearch: {
            type: Boolean
        },
        isCountrySelector: {
            type: Boolean
        },
        itemsPerPage: {
            default: 20
        },
        ignoreScroll: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        hasError: {
            type: Boolean
        },
        hasIconPrefix: {
            type: Boolean
        },
        isEmpty: {
            type: Boolean
        },
        placeholder: {},
        name: {
            default: ''
        },
        selectedValue: {
            default: ''
        },
        options: {
            default: ()=>[]
        }
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur",
        "close",
        "open"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isDropdownShown, onFocus, onChange, onBlur, onButtonBlur, close, toggleOpen, focusOnDropdownButton, dropdownProps, dropdownSearchInput, optionsToRender, handleKeyDown, handleSearchInput, isSearchActive, searchQuery, clearSearchQuery, preselectedListIndex, setPreselectedIndex, renderMoreItems, setRefs } = useDropdownSelect(props, emits);
        __expose({
            focus: focusOnDropdownButton
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DropdownWrapper, {
                ref: _unref(setRefs),
                disabled: _ctx.disabled,
                "has-icon-prefix": _ctx.hasIconPrefix,
                "is-country-selector": _ctx.isCountrySelector,
                "selected-label": _ctx.selectedLabel,
                "selected-value": _ctx.selectedValue,
                placeholder: _ctx.placeholder,
                "is-empty": _ctx.isEmpty,
                "has-error": _ctx.hasError,
                "is-search-active": _unref(isSearchActive),
                "is-large": _ctx.isLarge,
                "is-dropdown-shown": _unref(isDropdownShown),
                onFocus: _unref(onFocus),
                onBlur: _unref(onButtonBlur),
                onClick: _unref(toggleOpen),
                onKeydown: _unref(handleKeyDown)
            }, {
                search: _withCtx(()=>[
                        _unref(isSearchActive) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createElementVNode("input", {
                                ref_key: "dropdownSearchInput",
                                ref: dropdownSearchInput,
                                type: "text",
                                autocomplete: "off",
                                inputmode: "text",
                                class: _normalizeClass({
                                    [_ctx.$style['dropdown-select__input']]: true,
                                    [_ctx.$style['dropdown-select__input--icon-prefix']]: _ctx.hasIconPrefix,
                                    [_ctx.$style['dropdown-select__input--large']]: _ctx.isLarge
                                }),
                                placeholder: _ctx.placeholder,
                                value: _unref(searchQuery),
                                onBlur: _cache[0] || (_cache[0] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onBlur) && _unref(onBlur)(...args);
                                }),
                                onInput: _cache[1] || (_cache[1] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(handleSearchInput) && _unref(handleSearchInput)(...args);
                                }),
                                onKeydown: _cache[2] || (_cache[2] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(handleKeyDown) && _unref(handleKeyDown)(...args);
                                })
                            }, null, 42, _hoisted_1),
                            _createElementVNode("button", {
                                type: "button",
                                title: _ctx.$t('WEB2_CLOSE'),
                                class: _normalizeClass(_ctx.$style['dropdown-select__close']),
                                onClick: _cache[3] || (_cache[3] = _withModifiers(//@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(close) && _unref(close)(...args);
                                }, [
                                    "stop"
                                ]))
                            }, null, 10, _hoisted_2)
                        ], 64)) : _createCommentVNode("", true)
                    ]),
                default: _withCtx(()=>[
                        _unref(isDropdownShown) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _unref(dropdownProps) ? (_openBlock(), _createBlock(_unref(VCoreDropdown), _mergeProps({
                                key: 0
                            }, _unref(dropdownProps), {
                                "match-parent-width": "",
                                "ignore-active-element-click": "",
                                "height-limit": 296,
                                onClose: _unref(close)
                            }), {
                                default: _withCtx((param)=>{
                                    let { maxHeight, directionTop, mounted, onContentRedraw } = param;
                                    return [
                                        _createVNode(DropdownMenu, {
                                            ref: "dropdownMenu",
                                            name: _ctx.name,
                                            "max-height": maxHeight,
                                            "direction-top": directionTop,
                                            "selected-value": _ctx.selectedValue,
                                            options: _unref(optionsToRender),
                                            "search-query": _unref(searchQuery),
                                            "preselected-list-index": _unref(preselectedListIndex),
                                            "is-country-selector": _ctx.isCountrySelector,
                                            onChange: _unref(onChange),
                                            onMounted: mounted,
                                            onClearSearchQuery: _unref(clearSearchQuery),
                                            onListSizeUpdated: onContentRedraw,
                                            onOptionHover: _unref(setPreselectedIndex),
                                            onReachedBottom: _unref(renderMoreItems)
                                        }, null, 8, [
                                            "name",
                                            "max-height",
                                            "direction-top",
                                            "selected-value",
                                            "options",
                                            "search-query",
                                            "preselected-list-index",
                                            "is-country-selector",
                                            "onChange",
                                            "onMounted",
                                            "onClearSearchQuery",
                                            "onListSizeUpdated",
                                            "onOptionHover",
                                            "onReachedBottom"
                                        ])
                                    ];
                                }),
                                _: 1
                            }, 16, [
                                "onClose"
                            ])) : _createCommentVNode("", true)
                        ], 64)) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "disabled",
                "has-icon-prefix",
                "is-country-selector",
                "selected-label",
                "selected-value",
                "placeholder",
                "is-empty",
                "has-error",
                "is-search-active",
                "is-large",
                "is-dropdown-shown",
                "onFocus",
                "onBlur",
                "onClick",
                "onKeydown"
            ])), [
                [
                    _directive_auto_id,
                    'DropdownSelect'
                ]
            ]);
        };
    }
});
