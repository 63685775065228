import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import createUniqueId from 'web/src/utils/createUniqueId';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import useHintParentProps from '../../composables/useHintParentProps';
import { InputMode } from '../../enums';
import useSmsCodeInput from './useSmsCodeInput';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'SmsCodeInput',
    props: {
        autofocus: {
            type: Boolean
        },
        name: {
            default: 'sms-code-input'
        },
        value: {
            default: ''
        },
        size: {
            default: 6
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const uniqueId = createUniqueId(props.name);
        const { hintProperties, hasError } = useHintParentProps(props);
        const { inputValue: smsCodeValue, onPaste, onFocus, focusOnFirstSubfield, onBlur, onKeyDown, onInput, onMouseDown, focusIndex, hoverIndex, onMouseOver, onMouseLeave } = useSmsCodeInput(props, emit, uniqueId);
        __expose({
            focus: focusOnFirstSubfield
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("fieldset", {
                ref: "fieldset",
                class: _normalizeClass(_ctx.$style['sms-code-input'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sms-code-input__wrapper'])
                }, [
                    _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), _mergeProps(_unref(hintProperties), {
                        class: _ctx.$style['sms-code-input__raw-wrapper']
                    }), {
                        input: _withCtx(()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['sms-code-input__inner'])
                                }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(smsCodeValue), (symbolFromValue, index)=>(_openBlock(), _createBlock(_unref(InputBorderWrapper), {
                                            key: index,
                                            class: _normalizeClass(_ctx.$style['sms-code-input__border']),
                                            "is-focus": _unref(focusIndex) === index,
                                            "is-hover": _unref(hoverIndex) === index,
                                            "has-error": _unref(hasError)
                                        }, {
                                            default: _withCtx(()=>[
                                                    _createVNode(_unref(TextFlatInput), {
                                                        autofocus: 0 === index && _ctx.autofocus,
                                                        "input-id": `${_unref(uniqueId)}-${index}`,
                                                        value: symbolFromValue,
                                                        name: `${_ctx.name}-${index}`,
                                                        "data-index": index + 1,
                                                        "has-error": _unref(hasError),
                                                        autocomplete: 'off',
                                                        placeholder: _unref(focusIndex) === index ? '' : '—',
                                                        type: "tel",
                                                        "no-padding": "",
                                                        "input-mode": _unref(InputMode).NUMERIC,
                                                        class: _normalizeClass(_ctx.$style['sms-code-input__number']),
                                                        onInput: ($event)=>_unref(onInput)($event, index),
                                                        onPaste: ($event)=>_unref(onPaste)($event, index),
                                                        onKeydown: ($event)=>_unref(onKeyDown)($event, index),
                                                        onMousedown: ($event)=>_unref(onMouseDown)($event, index),
                                                        onBlur: _unref(onBlur),
                                                        onFocus: ($event)=>_unref(onFocus)(index),
                                                        onMouseleave: _unref(onMouseLeave),
                                                        onMouseover: ($event)=>_unref(onMouseOver)(index)
                                                    }, null, 8, [
                                                        "autofocus",
                                                        "input-id",
                                                        "value",
                                                        "name",
                                                        "data-index",
                                                        "has-error",
                                                        "autocomplete",
                                                        "placeholder",
                                                        "input-mode",
                                                        "class",
                                                        "onInput",
                                                        "onPaste",
                                                        "onKeydown",
                                                        "onMousedown",
                                                        "onBlur",
                                                        "onFocus",
                                                        "onMouseleave",
                                                        "onMouseover"
                                                    ])
                                                ]),
                                            _: 2
                                        }, 1032, [
                                            "class",
                                            "is-focus",
                                            "is-hover",
                                            "has-error"
                                        ]))), 128))
                                ], 2)
                            ]),
                        _: 1
                    }, 16, [
                        "class"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'sms-code-input',
                                name: _ctx.name
                            }
                        ]
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SmsCodeInput'
                ]
            ]);
        };
    }
});
