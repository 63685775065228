import { toRef } from 'vue';
import { logger } from '@leon-hub/logging';
import RouteName from '@leon-hub/routing-config';
import { loadLobbyBlock } from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
import { useEgsCategoriesStore } from 'web/src/modules/egs/submodules/categories/store';
import { getCategoryByRoute, getGroupByUrl, isFavoriteEgsRoute, getLobbyCategoryUrlFromRoute } from 'web/src/modules/egs/utils';
import { AllGamesGroupUrl, HistoryGamesCategoryUrl } from 'web/src/modules/egs/constants';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { EgsGamesCategoryId } from 'web/src/modules/egs/enums';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import useLobbyType from 'web/src/modules/lobby/composables/useLobbyType';
export default function useEgsBasePrefetch() {
    const groupsStore = useEgsGroupsStore();
    const categoriesStore = useEgsCategoriesStore();
    const currentGroups = toRef(groupsStore, 'currentGroups');
    const { loadGroups } = groupsStore;
    const { loadCategories, getCurrentGroupsCategories } = categoriesStore;
    function isHistory(to) {
        return to.params.categoryUrl === HistoryGamesCategoryUrl;
    }
    async function fetchGroups(to) {
        if (0 === currentGroups.value.length) {
            if (to.params.groupUrl) await loadGroups();
            else loadGroups().then();
        }
        return to.params.groupUrl ? getGroupByUrl(currentGroups.value, String(to.params.groupUrl)) : void 0;
    }
    async function fetchCategories(to, groupId) {
        const currentCategories = getCurrentGroupsCategories(groupId);
        const categoryUrl = getLobbyCategoryUrlFromRoute(to);
        if (0 === currentCategories.value.length) {
            if (categoryUrl) await loadCategories(groupId);
            else loadCategories(groupId).then();
        } else loadCategories(groupId, void 0, true).then();
        if (isFavoriteEgsRoute(to)) return EgsGamesCategoryId.MY_GAMES;
        if (isHistory(to)) return EgsGamesCategoryId.HISTORY_GAMES;
        const category = categoryUrl ? getCategoryByRoute(currentCategories.value, to) : void 0;
        return category?.id;
    }
    async function prefetchComponents() {
        await loadLobbyBlock().catch(logger.error);
    }
    async function prefetch(to, next) {
        const group = await fetchGroups(to);
        const groupId = group?.blockType ? void 0 : group?.id;
        if (to.params.groupUrl && to.params.groupUrl !== AllGamesGroupUrl && void 0 === groupId && !group?.blockType) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return false;
        }
        const { isLoggedIn } = useIsLoggedIn();
        if ((isFavoriteEgsRoute(to) || isHistory(to)) && !isLoggedIn.value) {
            next({
                name: String(to.name)
            });
            return false;
        }
        const categoryId = await fetchCategories(to, groupId);
        if (to.params.categoryUrl && void 0 === categoryId && !group?.blockType) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return false;
        }
        const lobbyStore = useLobbyStore();
        const { lobbyType } = useLobbyType();
        if (lobbyStore.isLobbyChanged(lobbyType.value, groupId, categoryId)) {
            lobbyStore.loadLobbyItems({
                type: lobbyType.value,
                group: groupId,
                category: categoryId
            });
            lobbyStore.setActiveLobby(lobbyType.value, groupId, categoryId);
        }
        await prefetchComponents();
        return {
            groupId,
            categoryId
        };
    }
    return {
        prefetch
    };
}
