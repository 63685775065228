import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "onboarding-wrapper"
};
const _hoisted_2 = {
    class: "onboarding__navigation"
};
const _hoisted_3 = {
    key: 1,
    class: "onboarding__navigation-description"
};
import { onMounted, onBeforeUnmount } from 'vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VTurnDeviceNotification from 'web/src/components/TurnDeviceNotification/VTurnDeviceNotification/VTurnDeviceNotification.vue';
import SwiperNavigationPoints from 'web/src/components/Swiper/Navigation/SwiperNavigationPoints/SwiperNavigationPoints.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import { VLoaderDelayed } from 'web/src/components/Loader';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'OnboardingRoutePage',
    setup (__props) {
        const { swiper, onScroll, isContentLoaded, onboardingSlides, handleClickSwiperButton, runBeforeUnmount, runOnMounted, buttonLabel, isReferralProgram, handleReferralProgramClick, buttonProperties, isLastSlide } = useOnboarding();
        onMounted(runOnMounted);
        onBeforeUnmount(runBeforeUnmount);
        return (_ctx, _cache)=>{
            _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _unref(isContentLoaded) ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([
                        "onboarding",
                        {
                            'onboarding--referral-program': _unref(isReferralProgram)
                        }
                    ])
                }, [
                    _createVNode(_unref(VSwiper), {
                        ref_key: "swiper",
                        ref: swiper,
                        "is-scroll-snap-enabled": "",
                        "is-block": "",
                        "is-full-height": "",
                        "use-modern-snap-api": false,
                        "slide-visibility-rate": 0.5,
                        onScroll: _unref(onScroll)
                    }, _createSlots({
                        "pagination-header": _withCtx(()=>[
                                _createCommentVNode("", true)
                            ]),
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(onboardingSlides), (slide, index)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                        key: index,
                                        "is-full-width": ""
                                    }, {
                                        default: _withCtx(()=>[
                                                _createVNode(VDynamicContent, {
                                                    class: _normalizeClass([
                                                        "cms__content",
                                                        {
                                                            'cms__content--referral-program': _unref(isReferralProgram)
                                                        }
                                                    ]),
                                                    content: slide.content
                                                }, null, 8, [
                                                    "class",
                                                    "content"
                                                ])
                                            ]),
                                        _: 2
                                    }, 1024))), 128))
                            ]),
                        _: 2
                    }, [
                        {
                            name: "pagination-footer",
                            fn: _withCtx(()=>[
                                    _createElementVNode("div", _hoisted_2, [
                                        _createVNode(SwiperNavigationPoints, {
                                            class: "onboarding__navigation-points"
                                        }),
                                        (_openBlock(), _createBlock(VButton, _mergeProps({
                                            key: 0
                                        }, _unref(buttonProperties), {
                                            class: "onboarding__navigation-button",
                                            label: _unref(buttonLabel),
                                            onClick: _unref(handleClickSwiperButton)
                                        }), null, 16, [
                                            "label",
                                            "onClick"
                                        ])),
                                        _unref(isReferralProgram) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_REFERRAL_PROGRAM_AGREEMENT_PART1')) + " ", 1),
                                            _createElementVNode("span", {
                                                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                                function() {
                                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                        args[_key] = arguments[_key];
                                                    }
                                                    return _unref(handleReferralProgramClick) && _unref(handleReferralProgramClick)(...args);
                                                })
                                            }, _toDisplayString(_ctx.$t('WEB2_REFERRAL_PROGRAM_AGREEMENT_PART2')), 1)
                                        ])) : _createCommentVNode("", true)
                                    ])
                                ]),
                            key: "0"
                        }
                    ]), 1032, [
                        "use-modern-snap-api",
                        "onScroll"
                    ]),
                    //@ts-ignore
                    _createCommentVNode("", true),
                    (_unref(isContentLoaded), _createCommentVNode("", true))
                ], 2)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ])), [
                [
                    _directive_auto_id,
                    'OnboardingRoutePage'
                ]
            ]);
        };
    }
});
