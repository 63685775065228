import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "payments-sum__fast-container"
};
const _hoisted_2 = {
    key: 1,
    class: "payments-sum__available-for-withdrawal"
};
import { onMounted, ref } from 'vue';
import { VFastMoneyChoice, FastMoneyMaxPosition } from 'web/src/components/FastMoneyChoice';
import usePaymentsFastChoices from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsFastChoices';
import usePaymentsMaxValueFastChoice from // eslint-disable-next-line max-len
'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoice';
import { VLoaderDelayed } from 'web/src/components/Loader';
import PaymentsInput from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/PaymentsInput.vue';
import { useCurrency } from 'web/src/modules/money/composables';
import { useBalance } from 'web/src/modules/customer/store/composables';
import usePaymentsFastSumInput from './composables/usePaymentsFastSumInput';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsFastSumInput',
    props: {
        error: {
            default: ''
        },
        label: {},
        name: {
            default: ''
        },
        hint: {},
        minAmount: {},
        maxAmount: {},
        suggestedAmounts: {},
        allowedIncrement: {},
        allowInputSuggestedAmountsOnly: {
            type: Boolean
        },
        defaultInputValue: {},
        isDeposit: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        availableForWithdraw: {},
        amount: {},
        isCryptoWithdrawal: {
            type: Boolean
        },
        isCurrentValueFromSuggestedAmounts: {
            type: Boolean
        },
        showSuggestedAmount: {
            type: Boolean,
            default: true
        },
        isFastSumMaxEnabled: {
            type: Boolean
        }
    },
    emits: [
        "input",
        "focus",
        "change",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onFastSumInputMounted, fastChoiceValue, loading, handleInput, sendPredefinedSumDepositAnalytics } = usePaymentsFastSumInput(props, emit);
        const { fastChoices } = usePaymentsFastChoices(props);
        const { maxValueFastChoice } = usePaymentsMaxValueFastChoice(props);
        const { balance } = useBalance();
        const { currency } = useCurrency();
        onMounted(onFastSumInputMounted);
        const paymentsInput = ref();
        function focusInputElement() {
            paymentsInput.value?.focus({
                forceIos: true,
                atTheEnd: true
            });
        }
        return (_ctx, _cache)=>_unref(loading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                key: 0
            })) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass({
                    'payments-sum': true,
                    'payments-sum--small': true,
                    'payments-sum--large': void 0
                })
            }, [
                _createElementVNode("div", {
                    class: "payments-sum__input-container",
                    onClick: focusInputElement
                }, [
                    _createVNode(PaymentsInput, {
                        ref_key: "paymentsInput",
                        ref: paymentsInput,
                        autofocus: _ctx.autofocus,
                        name: _ctx.name,
                        currency: _unref(currency),
                        balance: _unref(balance),
                        "min-amount": _ctx.minAmount,
                        "max-amount": _ctx.maxAmount,
                        "input-value": `${_ctx.amount || _ctx.defaultInputValue}`,
                        error: !!_ctx.error,
                        deposit: _ctx.isDeposit ?? false,
                        bonus: false,
                        "default-init-input-value": _ctx.defaultInputValue,
                        "suggested-amounts": _ctx.suggestedAmounts ?? [],
                        "allow-input-suggested-amounts-only": _ctx.allowInputSuggestedAmountsOnly ?? false,
                        "allowed-increment": _ctx.allowedIncrement,
                        "is-crypto-withdrawal": _ctx.isCryptoWithdrawal,
                        "is-current-value-from-suggested-amounts": _ctx.isCurrentValueFromSuggestedAmounts,
                        onFocus: _cache[0] || (_cache[0] = ($event)=>emit('focus', $event)),
                        onChange: _cache[1] || (_cache[1] = ($event)=>emit('change', $event)),
                        onBlur: _cache[2] || (_cache[2] = ($event)=>emit('blur', $event)),
                        onInput: _cache[3] || (_cache[3] = ($event)=>_unref(handleInput)($event.target.value))
                    }, null, 8, [
                        "autofocus",
                        "name",
                        "currency",
                        "balance",
                        "min-amount",
                        "max-amount",
                        "input-value",
                        "error",
                        "deposit",
                        "default-init-input-value",
                        "suggested-amounts",
                        "allow-input-suggested-amounts-only",
                        "allowed-increment",
                        "is-crypto-withdrawal",
                        "is-current-value-from-suggested-amounts"
                    ])
                ]),
                _ctx.showSuggestedAmount ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _ctx.isDeposit || _unref(balance) > 0 ? (_openBlock(), _createBlock(_unref(VFastMoneyChoice), {
                        key: 0,
                        class: "payments-sum__fast",
                        items: _unref(fastChoices) || void 0,
                        balance: _unref(balance),
                        currency: _unref(currency),
                        "show-balance": !_ctx.isDeposit && !_ctx.isCryptoWithdrawal,
                        "current-value": _unref(fastChoiceValue),
                        "max-value": _unref(maxValueFastChoice),
                        "max-available-value": _unref(maxValueFastChoice),
                        "min-available-value": _ctx.minAmount,
                        "max-enabled": !_ctx.allowInputSuggestedAmountsOnly && _ctx.isFastSumMaxEnabled,
                        "max-position": _unref(FastMoneyMaxPosition).END,
                        dense: "",
                        "is-bordered": "",
                        "is-deposit": _ctx.isDeposit,
                        "hide-unavailable-options": "",
                        onSelectValue: _unref(handleInput),
                        onClickValue: _unref(sendPredefinedSumDepositAnalytics)
                    }, null, 8, [
                        "items",
                        "balance",
                        "currency",
                        "show-balance",
                        "current-value",
                        "max-value",
                        "max-available-value",
                        "min-available-value",
                        "max-enabled",
                        "max-position",
                        "is-deposit",
                        "onSelectValue",
                        "onClickValue"
                    ])) : _createCommentVNode("", true)
                ])) : _createCommentVNode("", true),
                _ctx.availableForWithdraw && !_ctx.isCryptoWithdrawal ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_WITHDRAWAL_RESTRICTIONS_AVAILABLE')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.availableForWithdraw), 1)
                ])) : _createCommentVNode("", true)
            ], 2));
    }
});
