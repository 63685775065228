import { getPromotionsByEgsGame } from '@leon-hub/api-sdk/src/sdk/sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import getLatestCasinoPromotionUrl from './getLatestCasinoPromotionUrl';
export default async function getPromoDetailsUrlForGame(game) {
    const { id, providerId } = game;
    const result = await getPromotionsByEgsGame(useGraphqlClient(), (node)=>node.queries.promotions.getPromotionsByEgsGame, {
        options: {
            gameId: id,
            providerId
        }
    }, {
        silent: true
    });
    if (result?.promotions && result.promotions.length) return getLatestCasinoPromotionUrl([
        ...result.promotions
    ]);
    return null;
}
