import { ref } from 'vue';
import getRewardTitle from 'web/src/modules/referral-program/utils/referralProgramPopup/getRewardTitle';
export default function getPlayerReward(rewards, $t, formatMoney, locale, currency) {
    if (rewards) {
        let options = {};
        if (currency) options = {
            currency
        };
        return rewards.map((reward)=>{
            const depositKeys = ref({
                count: String(formatMoney(Number(reward.depositAmount) || 0, options))
            });
            return reward.rewardAmount.referralReward.map((rew)=>({
                    deposit: $t('WEB2_REFFERAL_PROGRAM_DEPOSIT_FROM', depositKeys).value,
                    reward: getRewardTitle(rew, $t, locale)
                }));
        }).flat();
    }
    return [];
}
