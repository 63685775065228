import { once } from 'lodash';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
import { isTelegramInAppBrowser } from './isTelegramInAppBrowser';
import { getFrameId } from './getFrameId';
import { printScript } from './printScript';
const fetchSandboxHtml = once(async ()=>(await fetch("/sandbox.html")).text());
export const configureFrame = async (iframe, options)=>{
    const id = getFrameId(options.id);
    iframe.setAttribute('id', id);
    iframe.setAttribute('title', `Sandboxed api iframe: ${options.id}`);
    iframe.setAttribute('width', '1');
    iframe.setAttribute('height', '1');
    iframe.setAttribute('style', 'opacity: 0; position: absolute; left: -1px');
    if (options.strict) // Minimal sandbox permissions.
    iframe.setAttribute('sandbox', 'allow-scripts');
    // eslint-disable-next-line no-param-reassign
    iframe.style.opacity = '0';
    const origin = options.origin ?? getLocationOrigin();
    const [sandboxContent, ...scripts] = await Promise.all([
        fetchSandboxHtml(),
        ...options.scripts.map((script)=>printScript({
                script
            }))
    ]);
    const html = sandboxContent.replace(/(<head[^>]*?>)/, [
        '$1',
        `<base href="${origin}"/>`,
        options.strict ? `<meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'self' 'unsafe-eval' ${origin}; style-src 'self' ${origin}">` : '',
        options.sentry ? `<script>sentryOptions = ${JSON.stringify(options.sentry)}</script>` : ''
    ].join('')).replace('</body>', [
        ...scripts,
        '</body>'
    ].join(''));
    if ((process.env.VUE_APP_OS_IOS || process.env.VUE_APP_OS_MACOS) && !await isTelegramInAppBrowser()) {
        const blob = new Blob([
            html
        ], {
            type: 'text/html'
        });
        const url = URL.createObjectURL(blob);
        iframe.setAttribute('src', url);
        iframe.addEventListener('load', ()=>{
            URL.revokeObjectURL(url);
        });
    } else // eslint-disable-next-line no-param-reassign
    iframe.srcdoc = html;
    return iframe;
};
