import { computed } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { ButtonKind, ButtonHeight } from 'web/src/components/Button/VButton/enums';
function getButtonHeight(props) {
    if (props.isBigIcon) return ButtonHeight.LARGE;
    if (props.isBalanceHidden) return ButtonHeight.SMALL;
    return ButtonHeight.TINY;
}
export default function baseButtonProps(props) {
    const heightButton = computed(()=>getButtonHeight(props));
    return {
        height: heightButton.value,
        rounded: true,
        iconSize: IconSize.SIZE_16,
        kind: ButtonKind.PRIMARY_DARK_VERSION
    };
}
