import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { useVList } from 'web/src/components/List/VList/composables';
import { ListType } from 'web/src/components/List/enums';
import getClassObject from 'web/src/components/List/VList/utils/getClassObject';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VList',
    props: {
        listType: {
            default: ListType.SEPARATE
        },
        independentItems: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        useVList(props);
        const classObject = computed(()=>getClassObject(props));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("ul", {
                class: _normalizeClass(classObject.value)
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'VList'
                ]
            ]);
        };
    }
});
