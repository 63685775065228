import { computed, onMounted, onUnmounted } from 'vue';
const outerModalContainerId = 'outerModalContainer';
export default function useDefaultModal(props, emits) {
    const containerId = computed(()=>props.isInnerModal ? void 0 : outerModalContainerId);
    const modalWindowProps = computed(()=>({
            image: props.image,
            alertIconSize: props.alertIconSize,
            icon: props.icon,
            iconSize: props.iconSize,
            header: props.header,
            content: props.content,
            topBarText: props.topBarText,
            buttons: props.buttons,
            isCloseAllowed: props.isCloseAllowed,
            isAlert: props.isAlert,
            isScrollBarEnabled: props.isScrollBarEnabled,
            isFullHeight: props.isFullHeight,
            isFullHeightCentered: props.isFullHeightCentered,
            isCloseAsBack: props.isCloseAsBack,
            isTopBarHidden: props.isTopBarHidden,
            iconKind: props.iconKind,
            hasInnerModal: props.hasInnerModal,
            isInnerModal: props.isInnerModal,
            modalKey: props.modalKey,
            useScrollListener: props.useScrollListener,
            hasNoScroll: props.hasNoScroll,
            isFullScreen: props.isFullScreen,
            isProfileLayout: props.isProfileLayout,
            isSmallWidthContent: props.isSmallWidthContent
        }));
    const classNames = computed(()=>({
            modal: true,
            'modal--alert': !!props.isAlert,
            'modal--centered': !!props.isCentered,
            'modal--min-height': !!props.hasMinHeight,
            'modal--has-inner': !!props.hasInnerModal && !props.hasInnerModalFullHeight,
            [`modal--width-${props.width}`]: true,
            [`modal--height-${props.height}`]: true,
            'modal--full-height': !!props.isFullHeight,
            'modal--profile': !!props.isProfileLayout,
            'modal--full-screen': !!props.isFullScreen,
            'modal--has-custom-bg': !!props.customBackground
        }));
    function onClose() {
        if (props.isCloseAllowed) emits('close');
    }
    function onOverlayClick() {
        if (props.isOverlayCloseAllowed) onClose();
    }
    function handleKeydown(event) {
        const { key } = event;
        if ('Escape' === key || 'Esc' === key) onClose();
    }
    onMounted(()=>{
        "1";
        window.addEventListener('keydown', handleKeydown);
    });
    onUnmounted(()=>{
        "1";
        window.removeEventListener('keydown', handleKeydown);
    });
    return {
        outerModalContainerId,
        containerId,
        modalWindowProps,
        classNames,
        onOverlayClick,
        onClose
    };
}
