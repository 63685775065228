import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
const _hoisted_2 = [
    "xlink:href"
];
import { normalizeError } from '@leon-hub/errors';
import { IconDirection, IconName, IconSize, loadIcons } from '@leon-hub/icons';
import { useVIcon } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VIcon',
    props: {
        title: {
            default: ''
        },
        name: {
            default: IconName.CROSS
        },
        size: {
            default: IconSize.SIZE_20
        },
        direction: {
            default: IconDirection.EAST
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { hasSize, iconPath, hasDirection, isLoading } = useVIcon(props);
        function onClick(event) {
            emit('click', event);
        }
        if (!isLoading.value) // eslint-disable-next-line no-console
        loadIcons().catch((err)=>console.error(normalizeError(err)));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass({
                    [_ctx.$style.icon]: true,
                    [_ctx.$style[`icon--direction-${_ctx.direction}`]]: _unref(hasDirection),
                    [_ctx.$style[`icon--size-${_ctx.size}`]]: _unref(hasSize)
                }),
                "aria-hidden": "true",
                role: "presentation",
                title: _ctx.title,
                onClick: onClick
            }, [
                _createElementVNode("use", {
                    "xlink:href": _unref(iconPath)
                }, null, 8, _hoisted_2)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VIcon'
                ]
            ]);
        };
    }
});
