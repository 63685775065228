import { customRef } from 'vue';
import { Timer } from '@leon-hub/utils';
export default function useDebouncedRef(value) {
    let delay = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 200;
    let timeout = 0;
    return customRef((track, trigger)=>({
            get () {
                track();
                return value;
            },
            set (newValue) {
                Timer.clearTimeout(timeout);
                timeout = Timer.setTimeout(()=>{
                    // eslint-disable-next-line no-param-reassign
                    value = newValue;
                    trigger();
                }, delay);
            }
        }));
}
