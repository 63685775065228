import { computed } from 'vue';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { setInputCursorPositionOnClick } from '../../utils';
import { useNumberStringInput } from '../../composables';
export default function usePaymentInput(props, emits) {
    const formatMoney = useFormatMoney();
    const paymentInputMaxLength = 12;
    const { uniqueId, isFocus, isEmpty, isHover, showClearButton, inputValue, onBlur, onChange, onInput, onFocus, onClear, onPaste, onMouseOver, onMouseLeave } = useNumberStringInput(props, emits, paymentInputMaxLength);
    const displayedValue = computed(()=>{
        if (isFocus.value) return inputValue.value;
        return inputValue.value ? formatMoney(parseFloat(inputValue.value), props.formatMoneyOptions) : '';
    });
    const setFocusPosition = (event)=>{
        if (isFocus.value) return;
        setInputCursorPositionOnClick(event, isFocus.value);
    };
    return {
        uniqueId,
        isFocus,
        isEmpty,
        isHover,
        showClearButton,
        onBlur,
        onChange,
        onInput,
        onFocus,
        onClear,
        onPaste,
        onMouseOver,
        onMouseLeave,
        displayedValue,
        setFocusPosition,
        inputMaxLength: paymentInputMaxLength
    };
}
