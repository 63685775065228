import { defineComponent, h, useCssModule } from 'vue';
export default defineComponent({
    name: 'InputBorderWrapper',
    props: {
        isFocus: {
            type: Boolean
        },
        isDisabled: {
            type: Boolean
        },
        isHover: {
            type: Boolean
        },
        hasError: {
            type: Boolean
        },
        isFromModal: {
            type: Boolean
        }
    },
    setup (props, param) {
        let { slots } = param;
        const $style = useCssModule();
        const rootClass = 'input-border-wrapper';
        return ()=>{
            const cssClasses = [];
            if ($style) {
                cssClasses.push($style[rootClass]);
                if (props.isFocus) cssClasses.push($style[`${rootClass}--focus`]);
                if (props.isDisabled) cssClasses.push($style[`${rootClass}--disabled`]);
                if (props.isHover) cssClasses.push($style[`${rootClass}--hover`]);
                if (props.hasError) cssClasses.push($style[`${rootClass}--error`]);
                if (props.isFromModal) cssClasses.push($style[`${rootClass}--modal`]);
            }
            return h('div', {
                class: cssClasses
            }, slots.default?.());
        };
    }
});
