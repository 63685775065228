export default function nativeSelectItemToVInputInputEvent(param) {
    let { target } = param, name = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '';
    HTMLSelectElement;
    const { value } = target;
    return {
        target: {
            value,
            name
        }
    };
}
