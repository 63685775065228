import { reactive, watchEffect, effectScope, getCurrentScope, toRef } from 'vue';
import { normalizeError } from '@leon-hub/errors';
import { Deferred, getResolvedDeferred, voidify } from '@leon-hub/utils';
import { getFunctionId } from 'web/src/modules/lexis-nexis-integration/utils/getFunctionId';
import { createAsyncEffect, normalizeEffect } from './helpers';
import { log as logger } from '../log';
import { rejection } from './constants';
export function watchCustomEffect(effectCallback) {
    let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const effects = reactive(new Set([
        effectCallback
    ]));
    const scope = effectScope(!!options.detached);
    const condition = options.condition ?? toRef(true);
    const log = logger.extend(`effect:${options.id ?? getFunctionId(effectCallback)}`);
    log.enabled = true;
    let subScope;
    let subScopeStopHandler;
    let runningEffect;
    function resetRunningEffect() {
        runningEffect = getResolvedDeferred(void 0);
    }
    resetRunningEffect();
    const stopSubWatchEffect = ()=>{
        if (subScopeStopHandler) {
            log('teardown sub scope..');
            subScopeStopHandler();
            subScopeStopHandler = void 0;
        }
    };
    const composedEffect = (onCleanup)=>{
        const isEnabled = condition.value;
        log(`conditional effect called (condition=${isEnabled})`);
        if (isEnabled) {
            if (!subScopeStopHandler) {
                log('creating sub-scope');
                subScope = effectScope();
                const asyncEffect = createAsyncEffect({
                    scope: subScope,
                    effects,
                    onCleanup
                });
                log('running effect on sub-scope');
                subScopeStopHandler = ()=>{};
                // allows inner sub-effects to detect live state.
                subScopeStopHandler = subScope.run(()=>watchEffect(voidify(asyncEffect), options));
            }
        } else stopSubWatchEffect();
    };
    /**
   * Extend main effect with sub effects.
   */ const addEffect = (asyncEffectCallback)=>{
        log('add');
        const currentScope = getCurrentScope();
        const noActiveScope = void 0 === currentScope;
        effects.add(asyncEffectCallback);
    };
    /**
   * Run async code with effect scope.
   */ const continueEffect = async (debugLog, callback)=>{
        debugLog('init');
        const deferred = new Deferred();
        const runner = ()=>run({
                log: debugLog,
                callback,
                deferred,
                scope
            });
        if (getCurrentScope() === subScope) return runner();
        try {
            while(!runningEffect.finished){
                log('waiting');
                const activePromise = runningEffect.promise;
                // eslint-disable-next-line no-await-in-loop
                await activePromise;
            }
            log('resume');
        } catch  {
            throw new Error('Unable to continue failed effect');
        }
        return runner();
    };
    const controls = {
        stop: ()=>{
            log('stop effect');
            stopSubWatchEffect();
            scope.stop();
            runningEffect.reject(rejection);
        },
        addEffect,
        continueEffect: function() {
            for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
                rest[_key] = arguments[_key];
            }
            const [callback] = rest;
            const prefix = `continue-effect:${getFunctionId(callback)}`;
            const debugLog = log.extend(prefix);
            debugLog.enabled = true;
            if (!subScopeStopHandler) {
                debugLog('cancel');
                return {
                    dead: true,
                    value: void 0
                };
            }
            return continueEffect(debugLog, ...rest);
        }
    };
    log('creating..');
    scope.run(()=>{
        watchEffect(composedEffect, options);
    });
    return controls;
}
async function run(param) {
    let { log, scope, callback, deferred } = param;
    try {
        log('before run');
        const result = scope.run(normalizeEffect(callback));
        const value = result instanceof Promise ? await result : result;
        const dead = !result;
        return {
            dead,
            value
        };
    } catch (err) {
        const normalizedError = normalizeError(err);
        deferred.reject(rejection);
        throw normalizedError;
    } finally{
        log('after run');
        deferred.resolve();
    }
}
