import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, withModifiers as _withModifiers, mergeProps as _mergeProps, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 2,
    class: "headline-bn__content"
};
import { onActivated, onMounted, ref } from 'vue';
import { ObserveVisibility } from 'vue3-observe-visibility';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import { VEmpty } from 'web/src/components/Empty';
import { useVHeadlineBanner } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VHeadlineBanner',
    props: {
        headline: {},
        secondaryText: {},
        buttonText: {},
        mainImage: {},
        backgroundImage: {},
        backgroundColor: {},
        url: {},
        bannerId: {},
        groupId: {},
        isInternalLink: {
            type: Boolean
        },
        linkTitle: {},
        aspectRatio: {},
        animation: {},
        isRedirectDisabled: {
            type: Boolean
        },
        forceHideImage: {
            type: Boolean
        },
        isDivContainer: {
            type: Boolean
        },
        isNT: {
            type: Boolean
        },
        bannerType: {},
        isActive: {
            type: Boolean
        },
        textColor: {},
        isTextShadowHidden: {
            type: Boolean
        },
        imageHeight: {},
        fixHeightImg: {
            type: Boolean
        },
        noMinHeight: {
            type: Boolean
        },
        isVisibleImage: {
            type: Boolean
        },
        isBigHeight: {
            type: Boolean
        },
        hasEventDetails: {
            type: Boolean
        },
        isHalfWidth: {
            type: Boolean
        },
        bannerVersion: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const SportEventInfo = require('web/src/modules/banners/components/SportEventInfo').default;
        // eslint-disable-next-line vue/define-macros-order
        const props = __props;
        const emits = __emit;
        const vObserveVisibility = ObserveVisibility;
        const isBannerFgLoaded = ref(false);
        const isBannerBgLoaded = ref(false);
        const { backgroundImage, mainImageRef, wrapper, wrapperComponent, wrapperProperties, wrapperClass, wrapperStyles, isBackgroundImageAvailable, backgroundImageSource, bgImageWidth, hasContentBlock, headlineComponent, isHeadlineAvailable, textStyles, isSecondaryTextAvailable, imagePosition, linkProperties, isFrontImageAvailable, isButtonAvailable, badgeKind, imageFit, imageStyle, onWrapperClick, visibilityChanged, onClick, onComponentMounted, onComponentActivated } = useVHeadlineBanner(props, emits);
        onMounted(onComponentMounted);
        onActivated(onComponentActivated);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_collect = _resolveDirective("collect");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(wrapperComponent)), _mergeProps(_unref(wrapperProperties), {
                ref_key: "wrapper",
                ref: wrapper,
                class: _unref(wrapperClass),
                style: _unref(wrapperStyles),
                onClick: _withModifiers(_unref(onWrapperClick), [
                    "prevent"
                ])
            }), {
                default: _withCtx(()=>[
                        _ctx.isNT ? (_openBlock(), _createBlock(VBadge, {
                            key: 0,
                            class: "headline-bn__badge headline-bn__badge--nt headline-bn__badge--dev-ip",
                            kind: _unref(badgeKind),
                            label: "DevIP"
                        }, null, 8, [
                            "kind"
                        ])) : _createCommentVNode("", true),
                        _unref(isBackgroundImageAvailable) ? _withDirectives((_openBlock(), _createBlock(VImage, {
                            key: 1,
                            ref_key: "backgroundImage",
                            ref: backgroundImage,
                            src: _unref(backgroundImageSource).src,
                            x1: _unref(backgroundImageSource).x1,
                            x2: _unref(backgroundImageSource).x2,
                            x3: _unref(backgroundImageSource).x3,
                            x1webp: _unref(backgroundImageSource).x1webp,
                            x2webp: _unref(backgroundImageSource).x2webp,
                            x3webp: _unref(backgroundImageSource).x3webp,
                            "force-hide-image": _ctx.forceHideImage,
                            style: _normalizeStyle({
                                width: _unref(bgImageWidth)
                            }),
                            "object-fit": _unref(ObjectFitOption).COVER,
                            "is-lazy": !process.env.VUE_APP_PRERENDER && !_ctx.isVisibleImage,
                            "is-relative": void 0,
                            class: _normalizeClass({
                                'headline-bn__bg': true,
                                'headline-bn__bg--loaded': isBannerBgLoaded.value,
                                'headline-bn__bg--active': _ctx.isActive
                            }),
                            onImageLoaded: _cache[0] || (_cache[0] = ($event)=>isBannerBgLoaded.value = true)
                        }, null, 8, [
                            "src",
                            "x1",
                            "x2",
                            "x3",
                            "x1webp",
                            "x2webp",
                            "x3webp",
                            "force-hide-image",
                            "style",
                            "object-fit",
                            "is-lazy",
                            "is-relative",
                            "class"
                        ])), [
                            [
                                _directive_data_test,
                                {
                                    el: 'banner-image-background'
                                }
                            ]
                        ]) : _createCommentVNode("", true),
                        _unref(hasContentBlock) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _unref(isHeadlineAvailable) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(headlineComponent)), {
                                key: 0,
                                style: _normalizeStyle(_unref(textStyles)),
                                class: _normalizeClass([
                                    "headline-bn__heading",
                                    {
                                        'headline-bn__text--no-shadow': _ctx.isTextShadowHidden
                                    }
                                ])
                            }, {
                                default: _withCtx(()=>[
                                        _createTextVNode(_toDisplayString(_ctx.headline), 1)
                                    ]),
                                _: 1
                            }, 8, [
                                "style",
                                "class"
                            ])) : _createCommentVNode("", true),
                            _unref(isSecondaryTextAvailable) ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                style: _normalizeStyle(_unref(textStyles)),
                                class: _normalizeClass([
                                    "headline-bn__text",
                                    {
                                        'headline-bn__text--no-shadow': _ctx.isTextShadowHidden
                                    }
                                ])
                            }, _toDisplayString(_ctx.secondaryText), 7)) : _createCommentVNode("", true)
                        ])) : _createCommentVNode("", true),
                        _unref(isButtonAvailable) ? _withDirectives((_openBlock(), _createElementBlock("a", _mergeProps({
                            key: 3
                        }, _unref(linkProperties), {
                            class: "headline-bn__button",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(onClick) && _unref(onClick)(...args);
                            }, [
                                "stop",
                                "prevent"
                            ]))
                        }), [
                            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                        ], 16)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'banner-button'
                                }
                            ]
                        ]) : _createCommentVNode("", true),
                        _unref(isFrontImageAvailable) ? _withDirectives((_openBlock(), _createBlock(VImage, {
                            key: 4,
                            ref_key: "mainImageRef",
                            ref: mainImageRef,
                            src: _ctx.mainImage.src,
                            x1: _ctx.mainImage.x1,
                            x2: _ctx.mainImage.x2,
                            x3: _ctx.mainImage.x3,
                            x1webp: _ctx.mainImage.x1webp,
                            x2webp: _ctx.mainImage.x2webp,
                            x3webp: _ctx.mainImage.x3webp,
                            "force-hide-image": _ctx.forceHideImage,
                            "object-fit": _unref(imageFit),
                            "object-position": _unref(imagePosition),
                            "is-lazy": !process.env.VUE_APP_PRERENDER && !_ctx.isVisibleImage,
                            "is-relative": void 0,
                            class: _normalizeClass({
                                'headline-bn__img': true,
                                'headline-bn__img--loaded': isBannerFgLoaded.value,
                                'headline-bn__img--active': _ctx.isActive
                            }),
                            style: _normalizeStyle(_unref(imageStyle)),
                            onImageLoaded: _cache[2] || (_cache[2] = ($event)=>isBannerFgLoaded.value = true)
                        }, null, 8, [
                            "src",
                            "x1",
                            "x2",
                            "x3",
                            "x1webp",
                            "x2webp",
                            "x3webp",
                            "force-hide-image",
                            "object-fit",
                            "object-position",
                            "is-lazy",
                            "is-relative",
                            "class",
                            "style"
                        ])), [
                            [
                                _directive_data_test,
                                {
                                    el: 'banner-image-main'
                                }
                            ]
                        ]) : _createCommentVNode("", true),
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 5
                        }, [
                            _ctx.hasEventDetails && _ctx.bannerId && _ctx.groupId ? (_openBlock(), _createBlock(_unref(SportEventInfo), {
                                key: 0,
                                "banner-id": _ctx.bannerId,
                                "group-id": _ctx.groupId,
                                "is-active": _ctx.isActive,
                                "banner-version": _ctx.bannerVersion
                            }, null, 8, [
                                "banner-id",
                                "group-id",
                                "is-active",
                                "banner-version"
                            ])) : _createCommentVNode("", true)
                        ], 64))
                    ]),
                _: 1
            }, 16, [
                "class",
                "style",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'VHeadlineBanner'
                ],
                [
                    _directive_collect,
                    {
                        promo: 'misclick'
                    },
                    "click-counter"
                ],
                [
                    _unref(vObserveVisibility),
                    {
                        callback: _unref(visibilityChanged)
                    }
                ]
            ]);
        };
    }
});
