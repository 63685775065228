import { _ as _define_property } from "@swc/helpers/_/_define_property";
export class ScriptElement {
    addScript() {
        return new Promise((resolve, reject)=>{
            const { name } = this.options;
            const source = 'src' in this.options ? this.options.src : void 0;
            const content = 'content' in this.options ? this.options.content : void 0;
            if (!source && !name) {
                reject(new Error('Empty script selector'));
                return;
            }
            const querySelector = name ? `script[n="${name}"]` : `script[src="${source}"]`;
            const existedScript = document.querySelector(querySelector);
            if (existedScript) {
                this.script = existedScript;
                if (existedScript.dataset.loaded) resolve();
                else existedScript.addEventListener('load', ()=>resolve());
                return;
            }
            const js = document.createElement('script');
            js.type = 'text/javascript';
            js.async = !!this.options.async;
            js.defer = !!this.options.defer;
            if (name) js.setAttribute('n', name);
            if (source) {
                js.src = source;
                js.addEventListener('load', ()=>{
                    js.dataset.loaded = 'loaded';
                    return resolve();
                });
                js.addEventListener('error', ()=>reject(new Error(`Can't load script ${js.src}`)));
                this.script = js;
                this.appendedToTarget = this.options.appendTarget || 'head';
                document[this.appendedToTarget].append(js);
                return;
            }
            if (content) {
                js.innerHTML = content;
                js.dataset.loaded = 'loaded';
                this.script = js;
                this.appendedToTarget = this.options.appendTarget || 'body';
                document[this.appendedToTarget].append(js);
                resolve();
                return;
            }
            reject(new Error('Insert script error'));
        });
    }
    removeScript() {
        if (this.script && this.appendedToTarget) document[this.appendedToTarget].removeChild(this.script);
    }
    constructor(options){
        _define_property(this, "options", void 0);
        _define_property(this, "script", void 0);
        _define_property(this, "appendedToTarget", void 0);
        this.options = options;
    }
}
