import { getAvailability, RequestOptionsPriority } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default async function getAvailabilityData() {
    const apiClient = useGraphqlClient();
    return getAvailability(apiClient, (node)=>node.queries.refProgram.getAvailability, {
        options: {}
    }, {
        silent: true,
        priority: RequestOptionsPriority.LOW
    });
}
