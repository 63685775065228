import { SportlineType } from 'web/src/modules/sportline/enums';
export function checkMatchStreamAccess(access, event) {
    return access.some((param)=>{
        let { sportlineTypes, eventId } = param;
        return eventId === event.id && sportlineTypes.includes(event.type);
    });
}
export function checkMatchStreamIndicator(access, event) {
    return access.some((param)=>{
        let { eventId, isUpcoming } = param;
        return eventId === event.id && (event.type === SportlineType.Live || event.type === SportlineType.Prematch && isUpcoming);
    });
}
