import { computed } from 'vue';
import { getCurrencySymbol, shouldReduceMoneyAmount } from 'web/src/modules/money/utils';
export default function useEgsJackpotAmount(props) {
    const currencySymbol = computed(()=>getCurrencySymbol(props.currency));
    const priceItems = computed(()=>{
        if (shouldReduceMoneyAmount(props.value, props.currency, 100000000)) return `${Math.ceil(props.value / 1000).toFixed()}K`.split('');
        return props.value.toFixed(2).split('');
    });
    return {
        currencySymbol,
        priceItems
    };
}
