import { computed } from 'vue';
import { filterEmptyRegionElements } from 'web/src/modules/sportline/utils';
export function useSportlineSportRegionElements(props) {
    const { sportElement } = props;
    const sport = computed(()=>sportElement.value.sport);
    const sportId = computed(()=>sport.value.id);
    const regionElements = computed(()=>filterEmptyRegionElements(sportElement.value.regions));
    return {
        sport,
        sportId,
        regionElements
    };
}
