import { getLeaguePlayoffByLeagueId as getBetlineLeaguePlayoffByLeagueId } from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function getBetlineLeaguePlayoff(options, param) {
    let { silent } = param;
    const apiClient = useGraphqlClient();
    return getBetlineLeaguePlayoffByLeagueId(apiClient, (node)=>node.queries.betLine.getLeaguePlayoffByLeagueId, {
        options
    }, {
        silent,
        group: RequestGroupScore24
    });
}
