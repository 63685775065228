import RouteName from '@leon-hub/routing-config';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import isLiveEgsRoute from './isLiveEgsRoute';
export default function getDefaultLobbyLocationByRouteName(route) {
    if (isLiveEgsRoute(route)) return {
        name: CasinoRouteName.CASINO_LIVE_LOBBY
    };
    if (route.name === CasinoRouteName.CASINO_LOBBY) return {
        name: CasinoRouteName.CASINO_LOBBY
    };
    if (route.name === RouteName.QUICK_BETS) return {
        name: RouteName.QUICK_BETS
    };
    return {
        name: RouteName.HOME
    };
}
