import template from 'lodash/template';
import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
import hasMetaParametersPlaceholders from './hasMetaParametersPlaceholders';
/**
 * Replace parameters placeholders in meta content
 * Returns unchanged content if no one placeholder found
 * Returns undefined if has no one parameter provided
 *
 * @param {string} content
 * @param {SeoMetaParameters} parameters
 *
 * @return {string | undefined}
 */ export default function replaceMetaParametersPlaceholders(content, parameters) {
    if (!hasMetaParametersPlaceholders(content)) return content ?? '';
    if (!parameters || 0 === Object.keys(parameters).length) return '';
    // Loading parameters state | '\u200E' - for empty url
    try {
        return template(content, {
            variable: 'data'
        })(parameters);
    } catch (error) {
        try {
            logger.error('Meta template compilation failed', {
                content,
                parameters
            }, normalizeError(error));
        } catch (e) {
            logger.error('Meta template compilation log error', e);
        }
    }
    return '';
}
