import getBaseHeaders from '@leon-hub/api/src/client/getBaseHeaders';
import ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';
let LiveChatRestApiClient = class LiveChatRestApiClient extends ApplicationRestClient {
    constructor(){
        super({
            baseUrl: '',
            method: 'POST',
            credentials: 'same-origin',
            origin: '',
            headers: {
                ...getBaseHeaders(),
                Authorization: 'Bearer gCKBkmpxSV2Tisgk6N9Z',
                'X-App-Chat-Version': '3'
            }
        });
    }
};
export { LiveChatRestApiClient as default };
