import { normalizeError } from '@leon-hub/errors';
import { DetailsAdapter } from 'web/src/modules/sportline/utils/rest';
import { PresetName } from 'web/src/modules/dialogs/enums';
export function createFetchEventDetailsMethod(apiService, param, param1) {
    let { parseSportlineSettings } = param, { showDialog } = param1;
    return async function getSportEventDetails(param) {
        let { id, silent } = param;
        try {
            const rawSportEvent = await apiService.getEventDetails({
                id,
                silent
            });
            if (!rawSportEvent) return null;
            return new DetailsAdapter(rawSportEvent, parseSportlineSettings.value).sportEventDetails;
        } catch (rawError) {
            showDialog({
                id: 'extended-sport-event-details-error',
                presetName: PresetName.ALERT_ERROR,
                options: {
                    confirmMessage: normalizeError(rawError).message,
                    dataTestId: 'sport-event-details-error'
                }
            });
            return null;
        }
    };
}
