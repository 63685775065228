import { computed } from 'vue';
import { useMoreCaptionCount, useMoreCaptionLabel } from 'web/src/modules/sportline/composables/sportline-event';
export function useMoreCaption(props) {
    const { sportEvent, actualMarket, isUsedMoreRunnersCount } = props;
    const { totalCount: moreCountTotal } = useMoreCaptionCount({
        sportlineEvent: sportEvent,
        isUsedMoreRunnersCount
    });
    const hasPrimaryMarket = computed(()=>!!actualMarket.value);
    const moreCountPrimary = computed(()=>{
        if (isUsedMoreRunnersCount.value) return actualMarket.value?.runners.length || 0;
        return hasPrimaryMarket.value ? 1 : 0;
    });
    const total = computed(()=>moreCountTotal.value - moreCountPrimary.value);
    const hasMore = computed(()=>total.value > 0);
    const { moreCaption } = useMoreCaptionLabel({
        totalCount: moreCountTotal,
        primaryCount: moreCountPrimary
    });
    return {
        hasPrimaryMarket,
        hasMore,
        moreCaption
    };
}
