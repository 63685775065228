import { ref, watch } from 'vue';
import { getLocationHref } from '@leon-hub/service-locator-env';
import { getImageFromImageSource, isWebpSupported } from 'web/src/components/Image/utils';
import normalizeImagePath from 'web/src/utils/normalizeImagePath';
export default function useRecalculateImageSource(props) {
    const { imageSource } = props;
    let isSupportWebp;
    const normalizedSrc = ref(null);
    async function normalizeSrc() {
        if (void 0 === isSupportWebp) isSupportWebp = await isWebpSupported;
        normalizedSrc.value = imageSource.value ? normalizeImagePath(getLocationHref(), getImageFromImageSource(imageSource.value, isSupportWebp)) : null;
    }
    watch(imageSource, normalizeSrc);
    return {
        normalizedSrc,
        normalizeSrc
    };
}
