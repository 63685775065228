import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk/src/sdk/sdk';
import { isNull, isString, isNumber, isUndefined, isValidInterface, bindIsEnumValueType } from '@leon-hub/guards';
export const isSportDateTimeFilterFieldGuards = {
    activeKickoffPeriod: bindIsEnumValueType(SportFilterKickoffPeriod),
    customDateFrom: [
        isUndefined,
        isNull,
        isNumber
    ],
    customDateTo: [
        isUndefined,
        isNull,
        isNumber
    ],
    sportlineType: [
        isUndefined,
        isString,
        isNumber
    ]
};
export default function isSportDateTimeFilterGuard(value) {
    return isValidInterface(value, isSportDateTimeFilterFieldGuards);
}
