import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { SwitchKind } from 'web/src/components/Switch/VSwitch/enums';
import VListItemInner from '../VListItemInner/VListItemInner.vue';
import { ListItemSuffixAlign } from './enums';
import { useVListItem } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VListItem',
    props: {
        iconName: {
            default: void 0
        },
        countryCode: {
            default: ''
        },
        isNew: {
            type: Boolean
        },
        title: {
            default: ''
        },
        isTitleCentered: {
            type: Boolean
        },
        badgeLabel: {
            default: ''
        },
        counter: {
            default: ''
        },
        haveExpand: {
            type: Boolean
        },
        haveSwitcher: {
            type: Boolean
        },
        switchKind: {
            default: SwitchKind.DEFAULT
        },
        isSwitchChecked: {
            type: Boolean
        },
        isSwitchDisabled: {
            type: Boolean
        },
        suffixAlign: {
            default: ListItemSuffixAlign.CENTER
        },
        noHover: {
            type: Boolean
        },
        iconSuffixName: {
            default: void 0
        },
        isActive: {
            type: Boolean
        },
        href: {
            default: ''
        },
        progressValue: {
            default: 0
        },
        noWrap: {
            type: Boolean
        },
        haveProgress: {
            type: Boolean
        },
        iconCustomStyles: {
            default: ''
        },
        suffixText: {},
        isBigMargin: {
            type: Boolean
        },
        iconColored: {
            type: Boolean
        },
        buttonLabel: {
            default: ''
        }
    },
    emits: [
        "click",
        "change-switcher",
        "click-switcher",
        "click-button"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { classObject } = useVListItem(props);
        function onClick(event) {
            emit('click', event);
        }
        const emitChangeSwitcher = (value)=>{
            emit('change-switcher', value);
        };
        const emitClickButton = ()=>{
            emit('click-button', true);
        };
        const emitClickSwitcher = (event)=>{
            emit('click-switcher', event);
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(_unref(classObject))
            }, [
                _renderSlot(_ctx.$slots, "default", {}, ()=>[
                        _createVNode(VListItemInner, {
                            title: _ctx.title,
                            "badge-label": _ctx.badgeLabel,
                            "suffix-text": _ctx.suffixText,
                            counter: _ctx.counter,
                            "have-expand": _ctx.haveExpand,
                            "have-switcher": _ctx.haveSwitcher,
                            "suffix-align": _ctx.suffixAlign,
                            "is-new": _ctx.isNew,
                            "icon-name": _ctx.iconName,
                            "country-code": _ctx.countryCode,
                            "is-switch-disabled": _ctx.isSwitchDisabled,
                            "switch-kind": _ctx.switchKind,
                            "is-switch-checked": _ctx.isSwitchChecked,
                            "is-title-centered": _ctx.isTitleCentered,
                            "icon-suffix-name": _ctx.iconSuffixName,
                            href: _ctx.href,
                            "progress-value": _ctx.progressValue,
                            "no-wrap": _ctx.noWrap,
                            "have-progress": _ctx.haveProgress,
                            "icon-colored": _ctx.iconColored,
                            "icon-custom-styles": _ctx.iconCustomStyles,
                            "button-label": _ctx.buttonLabel,
                            onClick: onClick,
                            onChangeSwitcher: emitChangeSwitcher,
                            onClickButton: emitClickButton,
                            onClickSwitcher: emitClickSwitcher
                        }, _createSlots({
                            _: 2
                        }, [
                            _ctx.$slots.prefix ? {
                                name: "prefix",
                                fn: _withCtx(()=>[
                                        _renderSlot(_ctx.$slots, "prefix")
                                    ]),
                                key: "0"
                            } : void 0,
                            _ctx.$slots.inner ? {
                                name: "inner",
                                fn: _withCtx(()=>[
                                        _renderSlot(_ctx.$slots, "inner")
                                    ]),
                                key: "1"
                            } : void 0
                        ]), 1032, [
                            "title",
                            "badge-label",
                            "suffix-text",
                            "counter",
                            "have-expand",
                            "have-switcher",
                            "suffix-align",
                            "is-new",
                            "icon-name",
                            "country-code",
                            "is-switch-disabled",
                            "switch-kind",
                            "is-switch-checked",
                            "is-title-centered",
                            "icon-suffix-name",
                            "href",
                            "progress-value",
                            "no-wrap",
                            "have-progress",
                            "icon-colored",
                            "icon-custom-styles",
                            "button-label"
                        ])
                    ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VListItem'
                ]
            ]);
        };
    }
});
