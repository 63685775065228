export var ProfileButtonsTab;
var ProfileButtonsTab1;
(ProfileButtonsTab1 = ProfileButtonsTab || (ProfileButtonsTab = {}))["SPORTS"] = "0", ProfileButtonsTab1["EGS"] = "1";
export var ProfileBonusesActiveTab;
var ProfileBonusesActiveTab1;
(ProfileBonusesActiveTab1 = ProfileBonusesActiveTab || (ProfileBonusesActiveTab = {}))["SPORT"] = "sport", ProfileBonusesActiveTab1["GAME"] = "game";
export var ButtonActionType;
var ButtonActionType1;
(ButtonActionType1 = ButtonActionType || (ButtonActionType = {}))["PLAY"] = "PLAY", ButtonActionType1["REFUSE"] = "REFUSE", ButtonActionType1["DEPOSIT"] = "DEPOSIT", ButtonActionType1["IDENTIFICATION"] = "IDENTIFICATION", ButtonActionType1["COLLECT"] = "COLLECT", ButtonActionType1["CONFIRMATION"] = "CONFIRMATION", ButtonActionType1["BONUS_CODE_CONFIRMATION"] = "BONUS_CODE_CONFIRMATION", ButtonActionType1["DEPOSIT_BONUS_CODE"] = "DEPOSIT_BONUS_CODE", ButtonActionType1["COMPLEX"] = "COMPLEX";
export var InfoStatusType;
var InfoStatusType1;
(InfoStatusType1 = InfoStatusType || (InfoStatusType = {}))["COMPLETE_ACTIVE_COMPAIGN"] = "completeActiveCampaign", InfoStatusType1["FREEBET_IN_PLAY"] = "freebetInPlay";
export var ProfileBonusesComplexType;
var ProfileBonusesComplexType1;
(ProfileBonusesComplexType1 = ProfileBonusesComplexType || (ProfileBonusesComplexType = {}))["DEFAULT"] = "DEFAULT", ProfileBonusesComplexType1["FREEBET"] = "FREEBET", ProfileBonusesComplexType1["FREESPIN"] = "FREESPIN";
