import isDropdownWrapperRef from '../guards/isDropdownWrapperRef';
/**
 * This is replacement for
 * (exposed) => { exposed && (dropdownButton = exposed.button, hiddenSubmit = exposed.submit) }
 * used directly in template.
 * So its looks weird, yes
 * */ export default function setDropdownSelectRefs(wrapperRef, setButton, setSubmit) {
    if (wrapperRef) {
        isDropdownWrapperRef(wrapperRef);
        const { button, submit } = wrapperRef;
        if (button) setButton(button);
        if (submit) setSubmit(submit);
    }
}
