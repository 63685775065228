import { computed, inject, ref, watch } from 'vue';
export default function useVFadeCarouselSlide(props) {
    const isSlideShown = ref(false);
    const carouselOptions = inject('carouselOptions');
    // eslint-disable-next-line max-len
    const isSlideVisible = computed(()=>null !== props.currentSlideIndex && props.currentSlideIndex === carouselOptions?.activeSlideIndex);
    watch(isSlideVisible, ()=>{
        isSlideShown.value = true;
    }, {
        immediate: true
    });
    return {
        isSlideShown,
        isSlideVisible
    };
}
