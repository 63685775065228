import { computed } from 'vue';
import useSportlineBackgroundColor from 'web/src/modules/sportline/composables/representation/useSportlineBackgroundColor';
export function useSportBackgroundColor(props) {
    const background = computed(()=>props.sport.value?.representation.background ?? null);
    const { backgroundColor } = useSportlineBackgroundColor({
        background
    });
    return {
        backgroundColor
    };
}
