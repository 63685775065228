import isInputHintProps from 'web/src/components/Input/guards/isInputHintProps';
import getErrorFields from './getErrorFields';
import getHintFields from './getHintFields';
import getHintLinkFields from './getHintLinkFields';
export default function getWrapperHintProperties(param) {
    let { options, error, hintMessage } = param;
    if (!options) return {};
    isInputHintProps(options);
    return {
        ...getErrorFields(options, error),
        ...getHintFields(options, hintMessage),
        ...getHintLinkFields(options)
    };
}
