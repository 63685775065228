import { computed, toRef } from 'vue';
import { CustomerVerificationStatus, CustomerVerificationType, VideoVerificationStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function useVerification() {
    const siteConfigStore = useSiteConfigStore();
    const customerDataStore = useCustomerDataStore();
    const isVideoVerificationFeatureEnabled = toRef(siteConfigStore, 'isVideoVerificationFeatureEnabled');
    const latestRequestedVerificationType = toRef(customerDataStore, 'latestRequestedVerificationType');
    const videoVerificationStatus = toRef(customerDataStore, 'videoVerificationStatus');
    const verificationStatus = toRef(customerDataStore, 'verificationStatus');
    const isEkycRequestable = toRef(()=>customerDataStore.isEkycRequestable);
    const isAdditionalVerificationNeeded = toRef(customerDataStore, 'isAdditionalVerificationNeeded');
    const showVerification = toRef(customerDataStore, 'showVerification');
    const isVideoVerificationLatest = computed(// eslint-disable-next-line max-len
    ()=>latestRequestedVerificationType.value === CustomerVerificationType.VIDEO_VERIFICATION_SERVICE);
    const isVerificationStatusActive = computed(()=>!!verificationStatus.value && [
            CustomerVerificationStatus.STARTED,
            CustomerVerificationStatus.IN_PROCESS,
            CustomerVerificationStatus.RETRY,
            CustomerVerificationStatus.FAILED
        ].includes(verificationStatus.value));
    const isAccountVerificationRequired = computed(()=>isAdditionalVerificationNeeded.value || isVerificationStatusActive.value);
    const isVideoVerificationEnabled = computed(()=>isVideoVerificationFeatureEnabled.value && videoVerificationStatus.value !== VideoVerificationStatus.NONE && videoVerificationStatus.value !== VideoVerificationStatus.UNKNOWN && videoVerificationStatus.value !== VideoVerificationStatus.PENDING && isVideoVerificationLatest.value);
    const isVerificationNavigationItemEnabled = computed(()=>isAccountVerificationRequired.value || showVerification.value || isVideoVerificationEnabled.value);
    const isShowHintVideoVerificationProfile = computed(()=>isVideoVerificationEnabled.value && videoVerificationStatus.value !== VideoVerificationStatus.PASSED);
    return {
        isEkycRequestable,
        isVideoVerificationEnabled,
        isVerificationNavigationItemEnabled,
        isAccountVerificationRequired,
        isVerificationStatusActive,
        isShowHintVideoVerificationProfile
    };
}
