import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useOperationHistory } from 'web/src/modules/affiliates/submodules/operations-history/composables';
import { mapOperationBalance, mapOperationDateTime, mapOperationTypeId } from 'web/src/modules/affiliates/submodules/operations-history/utils';
export default function useOperationsHistoryMobile() {
    const router = useRouter();
    const { $translate } = useI18n();
    const { operations, isDataLoading, selectProperties, isReady, onFilter, setSelectedOperation, setResetFilter } = useOperationHistory();
    async function operationClick(operation) {
        setResetFilter(false);
        setSelectedOperation(operation);
        await router.push({
            name: RouteName.AFFILIATE_HISTORY_OPERATIONS_DETAILS,
            params: {
                operationId: 'operation'
            }
        });
    }
    function mapDateTime(operation) {
        return mapOperationDateTime(operation.date);
    }
    function mapTypeId(operation) {
        return mapOperationTypeId($translate, operation.typeId);
    }
    function mapBalance(operation) {
        return mapOperationBalance(operation.balance);
    }
    return {
        operations,
        isDataLoading,
        selectProperties,
        isReady,
        onFilter,
        mapDateTime,
        mapTypeId,
        mapBalance,
        operationClick
    };
}
