import { toRef, inject, computed } from 'vue';
import { ProvideExpandedBlockMapKey, ProvideSetExpandedBlockStateKey } from '../constants';
import { applyExpandedStatePayload, getIsExpandedFromMap } from '../utils';
export function useInjectIsExpandedSportlineBlock(props) {
    const expandKey = toRef(props.expandKey);
    const isDefaultExpanded = toRef(props.isDefaultExpanded ?? true);
    const expandedMap = toRef(inject(ProvideExpandedBlockMapKey, {}));
    const injectedSetExpandedState = inject(ProvideSetExpandedBlockStateKey, (payload)=>{
        expandedMap.value = applyExpandedStatePayload(payload, expandedMap.value);
    });
    const isExpanded = computed(()=>getIsExpandedFromMap(expandedMap, expandKey, isDefaultExpanded));
    function setExpandedState(value) {
        if (!expandKey.value) return;
        injectedSetExpandedState({
            expandKey: expandKey.value,
            value: !!value
        });
    }
    return {
        isExpanded,
        setExpandedState
    };
}
