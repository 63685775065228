import { toRef } from 'vue';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import useOnboardingStore from 'web/src/modules/onboarding/store/useOnboardingStore';
let OnboardingPagePrefetch = class OnboardingPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const onboardingId = String(to.params.id || '');
        const onboardingStore = useOnboardingStore();
        const onboardingItems = toRef(onboardingStore, 'onboardingItems');
        await onboardingStore.getOnboarding({
            onboardingId,
            withSlides: true
        });
        onboardingItems.value[onboardingId];
        // eslint-disable-next-line no-console
        next();
        return Promise.resolve();
    }
};
export { OnboardingPagePrefetch as default };
