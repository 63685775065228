import { ref, computed } from 'vue';
import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import { useSportlineEventId, useSportlineEventTypeRef } from 'web/src/modules/sportline/composables/sportline-event';
import useSportlineCacheStorage from 'web/src/modules/sportline/store/useSportlineCacheStorage';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { useStreamUrlStoreComposable } from 'web/src/modules/sportline/submodules/streams/store/composables';
import { getSportEventDetailsFromPreviewData } from 'web/src/modules/sportline/submodules/event-details/utils';
import { GetEventSubscriptionStatus } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
import { DetailsAdapter, buildSportlineSportElement } from 'web/src/modules/sportline/utils/rest';
export function useSportlineEventDetailsDataStoreComposable(props, events) {
    const { pageIdentifier, fireRequestStatus } = props;
    const { getSportEventCache, getLeagueCacheByKey, getRegionCacheByKey, getSportCacheByKey, getMarketCache } = useSportlineCacheStorage();
    const parseSportlineSettings = useParseSportlineSettingsRef();
    const apiService = useSportlineApiService();
    const broadcastSelected = useBroadcastSelected();
    const rawSportEventDetails = ref(null);
    const isShowingLoadingIndicator = ref(false);
    const sportEventDetailsLoadedId = computed(()=>rawSportEventDetails.value ? String(rawSportEventDetails.value.id) : null);
    /** Return is load data for target id completed (have data or missing event) */ const isLoaded = computed(()=>{
        if (null === rawSportEventDetails.value) return false;
        // load missing event
        if (false === rawSportEventDetails.value) return true;
        const targetId = pageIdentifier.value.sportEventId;
        return !!targetId && !!sportEventDetailsLoadedId.value && targetId === sportEventDetailsLoadedId.value;
    });
    const sportEventDetailsPreview = computed(()=>{
        const targetId = pageIdentifier.value.sportEventId;
        if (!targetId) return null;
        const sportEventCache = getSportEventCache(targetId);
        if (!sportEventCache.value) return null;
        const leagueCache = getLeagueCacheByKey(sportEventCache.value.leagueKey);
        const regionCache = getRegionCacheByKey(sportEventCache.value.regionKey);
        const sportCache = getSportCacheByKey(sportEventCache.value.sportKey);
        if (!leagueCache?.value || !regionCache?.value || !sportCache?.value) return null;
        const marketsCache = getMarketCache(targetId);
        const { markets, marketGroups, moreCount } = marketsCache?.value?.markets ?? {};
        return {
            sport: sportCache.value.sport,
            region: regionCache.value.region,
            league: leagueCache.value.league,
            sportEvent: {
                ...sportEventCache.value.sportEvent,
                markets: markets ?? [],
                marketGroups: marketGroups ?? [],
                moreCount: moreCount ?? {}
            }
        };
    });
    const sportEventDetails = computed(()=>{
        const loadedId = rawSportEventDetails.value ? String(rawSportEventDetails.value.id) : null;
        const targetId = pageIdentifier.value.sportEventId;
        if (null === rawSportEventDetails.value || loadedId !== targetId) // return preview for unloaded event or mismatched id
        return getSportEventDetailsFromPreviewData(sportEventDetailsPreview.value, {
            actualSportEventId: targetId
        });
        if (false === rawSportEventDetails.value) return null;
        return new DetailsAdapter(rawSportEventDetails.value, parseSportlineSettings.value).sportEventDetails;
    });
    const sportEventDetailsId = useSportlineEventId(sportEventDetails);
    const sportlineType = useSportlineEventTypeRef(sportEventDetails);
    const streamInfo = useStreamUrlStoreComposable({
        sportlineEventId: sportEventDetailsId,
        sportlineType
    });
    const sportElement = computed(()=>rawSportEventDetails.value ? buildSportlineSportElement(rawSportEventDetails.value, parseSportlineSettings.value) : null);
    const sport = computed(()=>sportEventDetails.value?.sport ?? null);
    const region = computed(()=>sportEventDetails.value?.region ?? null);
    const league = computed(()=>sportEventDetails.value?.league ?? null);
    function setRawSportEventDetails(value) {
        rawSportEventDetails.value = value;
        events.sportEventDetailsUpdated(value);
    }
    function setDetailsStatus(status) {
        const details = rawSportEventDetails.value;
        if (!details) return;
        setRawSportEventDetails({
            ...details,
            status
        });
    }
    function clearDetailsMarketsList() {
        const details = rawSportEventDetails.value;
        if (!details) return;
        setRawSportEventDetails({
            ...details,
            markets: [],
            marketsCount: 0
        });
    }
    async function fetchDetails(param) {
        let { silent } = param;
        const eventId = pageIdentifier.value.sportEventId;
        if (!eventId) return;
        if (!isLoaded.value) {
            isShowingLoadingIndicator.value = true;
            streamInfo.clearStreamInfo();
        }
        let result;
        try {
            result = await apiService.getEventDetails({
                id: eventId,
                silent
            });
        } catch (error) {
            fireRequestStatus(eventId, GetEventSubscriptionStatus.Error);
            throw error;
        }
        if (!result) {
            fireRequestStatus(eventId, GetEventSubscriptionStatus.Missing);
            // MISSING event
            isShowingLoadingIndicator.value = false;
            broadcastSelected.removeEventsDataInStorage([
                eventId
            ]);
            return;
        }
        const resultId = String(result.id);
        fireRequestStatus(resultId, GetEventSubscriptionStatus.OK);
        // old data
        if (eventId !== resultId) return;
        isShowingLoadingIndicator.value = false;
        setRawSportEventDetails(result);
        broadcastSelected.updateDataInStorageByResponse({
            response: {
                events: [
                    result
                ],
                totalCount: 1
            }
        });
    }
    const { onInit: onSportlineEventsChangesListenersInit } = useSportlineEventsChangesListeners({
        setResponse (response) {
            rawSportEventDetails.value = response;
        },
        getResponse () {
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            return rawSportEventDetails.value || null;
        }
    });
    function onInit() {
        onSportlineEventsChangesListenersInit();
    }
    return {
        isLoaded,
        isShowingLoadingIndicator,
        rawSportEventDetails,
        sportEventDetailsPreview,
        sportEventDetails,
        sportEventDetailsId,
        sportEventDetailsLoadedId,
        sportElement,
        sport,
        region,
        league,
        setDetailsStatus,
        clearDetailsMarketsList,
        setRawSportEventDetails,
        fetchDetails,
        onInit,
        streamInfo
    };
}
