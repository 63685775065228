import { computed } from 'vue';
const useHintParentProps = (props)=>{
    const hintProperties = computed(()=>({
            error: props.error,
            errorRight: props.errorRight,
            hint: props.hint,
            hintLink: props.hintLink,
            hintRight: props.hintRight,
            hintLinkRight: props.hintLinkRight
        }));
    const hasError = computed(()=>!!(props.error || props.errorRight));
    return {
        hintProperties,
        hasError
    };
};
export default useHintParentProps;
