export var TfaAppLink;
(TfaAppLink || (TfaAppLink = {}))["GOOGLE_AUTHENTICATOR"] = "otpauth://totp";
export const DownloadBadgeData = {
    Android: {
        redirectUrl: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
        imageSrc: 'https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png',
        imageAlt: 'Get it on Google Play'
    },
    Ios: {
        redirectUrl: 'https://apps.apple.com/ru/app/google-authenticator/id388497605',
        imageSrc: 'https://linkmaker.itunes.apple.com/ru-ru/badge-lrg.svg?releaseDate=2010-09-20&kind=iossoftware&bubble=ios_apps',
        imageAlt: 'Get it on App Store'
    }
};
export var BadgeImageUrl;
var BadgeImageUrl1;
(BadgeImageUrl1 = BadgeImageUrl || (BadgeImageUrl = {}))["ANDROID"] = "google-play-badge-", BadgeImageUrl1["IOS"] = "app-store-", BadgeImageUrl1["IOS_LIGHT"] = "app-store-light-";
