import { InputEventType } from 'web/src/components/Input/enums';
import nativeSelectItemToVInputInputEvent from '../utils/nativeSelectItemToVInputInputEvent';
export default function useNativeSelectEvents(props, emit) {
    const emitChange = (event)=>{
        emit(InputEventType.CHANGE, nativeSelectItemToVInputInputEvent(event, props.name));
    };
    const emitFocus = (event)=>{
        emit(InputEventType.FOCUS, nativeSelectItemToVInputInputEvent(event, props.name));
    };
    const emitBlur = (event)=>{
        emit(InputEventType.BLUR, nativeSelectItemToVInputInputEvent(event, props.name));
    };
    return {
        emitChange,
        emitFocus,
        emitBlur
    };
}
