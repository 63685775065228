import { ref } from 'vue';
export function useMarketsLayoutTypeStoreComposable() {
    const defaultLayoutType = ref(null);
    function setDefaultLayoutType(layoutType) {
        defaultLayoutType.value = layoutType ?? null;
    }
    return {
        defaultLayoutType,
        setDefaultLayoutType
    };
}
