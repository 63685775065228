export function useButtonEmits(emit) {
    const click = (event)=>{
        emit('click', event);
    };
    const keydown = (event)=>{
        emit('keydown', event);
    };
    const mousedown = (event)=>{
        emit('mousedown', event);
    };
    const focus = (event)=>{
        emit('focus', event);
    };
    const blur = (event)=>{
        emit('blur', event);
    };
    return {
        click,
        keydown,
        mousedown,
        focus,
        blur
    };
}
