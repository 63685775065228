import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useIntercomEvents, useWebIntercom } from 'web/src/modules/intercom/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { IntercomWidgetChevron, IntercomWidgetPadding } from 'web/src/modules/intercom/enums';
import { fetchIntercomApiData, getPadding } from 'web/src/modules/intercom/store/utils';
import { useRouterStore } from 'web/src/modules/core/store';
import { useI18nLocale } from 'web/src/modules/i18n/composables';
import { IntercomShowSpace } from 'web/src/modules/intercom/enums/IntercomShowSpace';
import useSupport from 'web/src/modules/support/composables/useSupport';
const useIntercomStore = defineStore('intercom', ()=>{
    const currentRoute = toRef(useRouterStore(), 'currentRoute');
    const companyId = "LI-DEFAULT";
    // State
    const userHash = ref();
    const intercomApiBase = ref();
    const intercomAppId = ref('');
    const { isLoggedIn } = useIsLoggedIn();
    const isIntercomStarted = ref(false);
    const customerStore = useCustomerDataStore();
    const userId = toRef(customerStore, 'login');
    const { locale } = useI18nLocale();
    const horizontalPadding = getPadding(IntercomWidgetPadding.HORIZONTAL);
    const verticalPadding = getPadding(IntercomWidgetPadding.VERTICAL);
    const intercomSettings = computed(()=>({
            api_base: intercomApiBase.value ? intercomApiBase.value : void 0,
            app_id: intercomAppId.value ? intercomAppId.value : void 0,
            user_id: userHash.value ? userId.value : void 0,
            user_hash: userHash.value ? userHash.value : void 0,
            alignment: IntercomWidgetChevron.RIGHT,
            horizontal_padding: horizontalPadding,
            vertical_padding: verticalPadding,
            company_id: companyId,
            language_override: locale.value
        }));
    const { openIntercomMenu, hideIntercomWidget, showIntercomWidget, resetIntercom, bootIntercomUser, showSpace, onUpdate } = useIntercomEvents();
    const bus = useEventsBus();
    const { isChatMode, isHelpMode, isIntercomAvailable, isIntercomOptionsListFlow } = useSupport();
    bus.on(BusEvent.OPEN_CHAT, ()=>{
        openIntercom();
        openIntercomMenu();
    });
    async function syncIntercomApiData() {
        if (isChatMode.value || isHelpMode.value && isIntercomAvailable.value) {
            const data = await fetchIntercomApiData();
            intercomApiBase.value = data.apiUrl;
            intercomAppId.value = data.appId;
            userHash.value = data.userHash;
        }
    }
    // Main initialize function
    async function initIntercom() {
        await syncIntercomApiData();
        if (!intercomAppId.value) return;
        // It's necessary to hide the widget on a mobile device and sunny desktop
        if (!isIntercomStarted.value && isIntercomOptionsListFlow.value) hideIntercomWidget();
        isIntercomStarted.value = true;
        await useWebIntercom().createScript(intercomSettings.value);
    }
    function registerIntercomUserForCordova() {}
    function openIntercom() {
        // For phone
        // For desktop
        if (isIntercomStarted.value) {
            if (isIntercomOptionsListFlow.value) showSpace(IntercomShowSpace.MESSAGES);
            else openIntercomMenu();
        }
    }
    // For cordova
    watch(isIntercomStarted, (newValue)=>{
        if (newValue) {
            if (isIntercomOptionsListFlow.value) hideIntercomWidget();
        }
    });
    watch(isLoggedIn, async (newValue)=>{
        if (isHelpMode.value && !isIntercomOptionsListFlow.value) return;
        if (newValue) await syncIntercomApiData();
        else {
            userHash.value = '';
            resetIntercom();
        }
        registerIntercomUserForCordova();
    });
    watch(intercomSettings, (newValue)=>{
        bootIntercomUser(newValue);
        if (newValue && isIntercomOptionsListFlow.value) hideIntercomWidget();
    }, {
        deep: true
    });
    watch(intercomAppId, (newValue)=>{
        if (newValue) {
            intercomAppId.value = newValue;
            bootIntercomUser(intercomSettings.value);
        }
    }, {
        immediate: true
    });
    watch(()=>currentRoute.value?.fullPath, (newPath)=>{
        onUpdate({
            last_request_at: Math.floor(Date.now() / 1000),
            location: newPath
        });
    }, {
        immediate: true
    });
    return {
        initIntercom,
        openIntercom,
        userHash,
        isIntercomStarted
    };
});
export default useIntercomStore;
