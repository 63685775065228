import { computed } from 'vue';
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { HeadlineFactory } from 'web/src/modules/sportline/submodules/top/utils';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';
export function useMixedHeadlineListsStore(props) {
    const { rawResponse } = props;
    const { parseSportlineSettings } = useSportlineTopEventsSettings();
    const isReady = computed(()=>null !== rawResponse.value);
    const totalCount = computed(()=>rawResponse.value?.total ?? 0);
    /** Mixed list of elements */ const headlineElements = computed(()=>{
        const response = rawResponse.value?.events ?? null;
        if (!response) return [];
        return new HeadlineFactory(response, parseSportlineSettings.value).build() || [];
    });
    const sportlineElements = computed(()=>{
        const response = rawResponse.value?.events ?? null;
        if (!response) return [];
        return new SportlineFactory(response, parseSportlineSettings.value).build() || [];
    });
    return {
        isReady,
        totalCount,
        headlineElements,
        sportlineElements
    };
}
