import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useSportlineEventId, useSportlineEventTypeRef } from 'web/src/modules/sportline/composables/sportline-event';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import { useStreamAvailableIndicator } from 'web/src/modules/sportline/submodules/streams/composables';
import SportlineEventGameProgress from 'web/src/modules/sportline/components/game-progress/SportlineEventGameProgress.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventLiveMetaInfo',
    props: {
        doShowLiveIndicator: {
            type: Boolean
        },
        sportlineEvent: {}
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(()=>props.sportlineEvent);
        const doShowLiveIndicator = toRef(()=>props.doShowLiveIndicator);
        const sportlineEventId = useSportlineEventId(sportlineEvent);
        const sportlineType = useSportlineEventTypeRef(sportlineEvent);
        const settingsStore = useSportlineSettingsStore();
        const isTimeReversedIndicatorAvailable = toRef(()=>settingsStore.isTimeReversedIndicatorAvailable);
        const { matchProgress } = useLiveMatchProgress({
            sportEvent: sportlineEvent
        });
        const { stage, scoreDetailsLine } = useLiveStatus(matchProgress);
        const { isStreamIndicatorAvailable } = useStreamAvailableIndicator({
            sportEventId: sportlineEventId,
            sportlineType
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportline-event-meta-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-event-meta-info__left-column'])
                }, [
                    doShowLiveIndicator.value ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['sport-event-badge'])
                    }, "Live", 2)) : _createCommentVNode("", true),
                    _createVNode(SportlineEventGameProgress, {
                        "match-progress": _unref(matchProgress),
                        "is-time-reversed-indicator-available": isTimeReversedIndicatorAvailable.value
                    }, null, 8, [
                        "match-progress",
                        "is-time-reversed-indicator-available"
                    ]),
                    _unref(stage) ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['sportline-event-live-stage'])
                    }, _toDisplayString(_unref(stage)), 3)) : _createCommentVNode("", true),
                    _unref(scoreDetailsLine) ? (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['sportline-event-live-score-details'])
                    }, "(" + _toDisplayString(_unref(scoreDetailsLine)) + ")", 3)) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-event-meta-info__right-column'])
                }, [
                    _unref(isStreamIndicatorAvailable) ? (_openBlock(), _createBlock(_unref(VIcon), {
                        key: 0,
                        name: _unref(IconName).STREAM,
                        size: _unref(IconSize).SIZE_12,
                        class: _normalizeClass(_ctx.$style['sportline-event-stream'])
                    }, null, 8, [
                        "name",
                        "size",
                        "class"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventLiveMetaInfo'
                ]
            ]);
        };
    }
});
