import { computed } from 'vue';
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { HeadlineFactory } from 'web/src/modules/sportline/submodules/top/utils';
export function useSeparateHeadlineListsStore(props) {
    const { rawResponse } = props;
    const { parseSportlineSettings } = useSportlineTopEventsSettings();
    const isReady = computed(()=>null !== rawResponse.value);
    const totalLiveCount = computed(()=>rawResponse.value?.liveTotal ?? 0);
    const totalPrematchCount = computed(()=>rawResponse.value?.prematchTotal ?? 0);
    const liveHeadlineElements = computed(()=>{
        const response = rawResponse.value?.live ?? null;
        if (!response) return [];
        return new HeadlineFactory(response, parseSportlineSettings.value).build();
    });
    const prematchHeadlineElements = computed(()=>{
        const response = rawResponse.value?.prematch ?? null;
        if (!response) return [];
        return new HeadlineFactory(response, parseSportlineSettings.value).build();
    });
    return {
        isReady,
        totalLiveCount,
        totalPrematchCount,
        liveHeadlineElements,
        prematchHeadlineElements
    };
}
