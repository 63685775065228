import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { useBonusLoyaltyProgramBalance } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusLoyaltyProgramBalance',
    props: {
        isInactive: {
            type: Boolean
        },
        isSecondary: {
            type: Boolean
        },
        isSmall: {
            type: Boolean
        },
        iconName: {},
        text: {},
        hasNoHover: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { handleClick, iconProps } = useBonusLoyaltyProgramBalance(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['bonus-loyalty-program-balance']]: true,
                    [_ctx.$style['bonus-loyalty-program-balance--has-icon']]: !!_ctx.iconName,
                    [_ctx.$style['bonus-loyalty-program-balance--inactive']]: _ctx.isInactive,
                    [_ctx.$style['bonus-loyalty-program-balance--secondary']]: _ctx.isSecondary,
                    [_ctx.$style['bonus-loyalty-program-balance--is-small']]: _ctx.isSmall,
                    [_ctx.$style['bonus-loyalty-program-balance--no-hover']]: _ctx.hasNoHover
                }),
                onClick: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(handleClick) && _unref(handleClick)(...args);
                }, [
                    "stop"
                ]))
            }, [
                _ctx.iconName ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                    key: 0,
                    class: [
                        _ctx.$style['bonus-loyalty-program-balance__icon']
                    ]
                }, _unref(iconProps)), null, 16, [
                    "class"
                ])) : _createCommentVNode("", true),
                _createElementVNode("span", {
                    class: _normalizeClass([
                        _ctx.$style['bonus-loyalty-program-balance__text']
                    ])
                }, _toDisplayString(_ctx.text), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusLoyaltyProgramBalance'
                ]
            ]);
        };
    }
});
