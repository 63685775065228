import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal';
import { BetCashOut } from 'web/src/components/BetCashOut/components';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useCashoutModal } from '../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CashoutModal',
    props: {
        transaction: {}
    },
    emits: [
        "confirm",
        "cancel"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { coreComponentProps, hasDesktopModal, isProfileLayout, betPrice, betName, mainButtonClick, cancelClick, closeModal } = useCashoutModal(emits, props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(LazyDefaultModal), {
                "is-alert": !_unref(hasDesktopModal) && true,
                "is-inner-modal": _unref(hasDesktopModal) && true,
                "is-centered": !!_unref(hasDesktopModal),
                width: _unref(ModalWidth).SMALL,
                "is-profile-layout": _unref(isProfileLayout),
                "data-test-id": "cashout-modal",
                onClose: _unref(closeModal)
            }, {
                "inner-content": _withCtx(()=>[
                        _createVNode(_unref(BetCashOut), _mergeProps(_unref(coreComponentProps), {
                            "bet-name": _unref(betName),
                            "bet-price": _unref(betPrice),
                            onMainButtonClick: _unref(mainButtonClick),
                            onCancel: _unref(cancelClick)
                        }), null, 16, [
                            "bet-name",
                            "bet-price",
                            "onMainButtonClick",
                            "onCancel"
                        ])
                    ]),
                _: 1
            }, 8, [
                "is-alert",
                "is-inner-modal",
                "is-centered",
                "width",
                "is-profile-layout",
                "onClose"
            ])), [
                [
                    _directive_auto_id,
                    'CashoutModal'
                ]
            ]);
        };
    }
});
