import { ButtonKind, ButtonType } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function getButtonProperties(props) {
    const { $translate } = useI18n();
    return {
        label: props.isNewTel ? $translate('WEB2_CALL_BACK').value : $translate('WEB2_RESEND_CODE').value,
        kind: ButtonKind.BASE,
        type: ButtonType.BUTTON,
        fullWidth: true
    };
}
