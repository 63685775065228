import { InputEventType } from 'web/src/components/Input/enums';
import { inputExpirationDateToFormDataEvent } from 'web/src/components/Form/utils';
const useExpirationDateInputEvents = (emit)=>{
    const emitChange = (event)=>{
        emit(InputEventType.CHANGE, inputExpirationDateToFormDataEvent(event));
    };
    const emitInput = (event)=>{
        emit(InputEventType.INPUT, inputExpirationDateToFormDataEvent(event));
    };
    const emitFocus = (event)=>{
        emit(InputEventType.FOCUS, inputExpirationDateToFormDataEvent(event));
    };
    const emitBlur = (event)=>{
        emit(InputEventType.BLUR, inputExpirationDateToFormDataEvent(event));
    };
    return {
        emitChange,
        emitInput,
        emitFocus,
        emitBlur
    };
};
export default useExpirationDateInputEvents;
