import { computed, toRef } from 'vue';
import { useCustomerNotificationsStore } from 'web/src/modules/customer-notifications/store';
import getOptionalString from 'web/src/utils/getOptionalString';
export default function useCustomerNotificationsUnreadCounter() {
    const unreadTotalCounter = toRef(useCustomerNotificationsStore(), 'unreadTotalCounter');
    const unreadCounter = computed(()=>getOptionalString(unreadTotalCounter.value));
    return {
        unreadCounter
    };
}
