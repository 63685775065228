import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { AffiliatePartnerStatus, doSavePriceChangePolicy, doUpdateCustomerData, getUserDataPhone, PriceChangePolicy } from '@leon-hub/api-sdk';
import { CustomerConfig } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { DecodeValue } from 'web/src/utils/DecodeValue';
import { emailPattern } from 'web/src/components/Form/constants';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useCountryStore } from 'web/src/modules/country/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import isValidCustomerBetType from 'web/src/modules/customer/utils/isValidCustomerBetType';
import phoneStringToPhoneInput from '../utils/phoneStringToPhoneInput';
import useCustomerBetTypeStore from './useCustomerBetTypeStore';
const useCustomerDataStore = defineStore('customer-data', ()=>{
    const api = useGraphqlClient();
    const authStore = useAuthStore();
    const { setLoggedIn } = authStore;
    const isLoggedIn = toRef(authStore, 'isLoggedIn');
    const customerConfigCache = ref(null);
    const { setBetType } = useCustomerBetTypeStore();
    const localStorageManager = useLocalStorageManager();
    /** state */ const customerData = ref(null);
    const forcedVerificationType = ref(null);
    const isShowTurnOnTfaStep = ref(!customerData.value?.g2svCustomerStatus);
    const customerOddTypeConfig = ref();
    /** getters */ const isLoaded = computed(()=>null !== customerData.value);
    const isAsoTermsConfirmationNeeded = computed(()=>customerData.value?.isAsoTermsConfirmationNeeded ?? false);
    const email = computed(()=>customerData.value?.email || '');
    const emailSubscription = computed(()=>customerData.value?.emailSubscription || 0);
    const login = computed(()=>customerData.value?.customerLogin || '');
    const phone = computed(()=>phoneStringToPhoneInput(customerData.value?.phone || ''));
    const phoneDecodedString = computed(()=>{
        let phoneString = customerData.value?.phone === 'N/A' ? '' : phone.value.suffix;
        if (!!phoneString && !phoneString.startsWith('+')) phoneString = `+${phone.value.suffix}`;
        return new DecodeValue(phoneString || '').toDecodedString();
    });
    const phoneDecodedStringFull = computed(()=>{
        let phoneString = '';
        if (customerData.value?.phone !== 'N/A') {
            const prefix = phone.value.prefix.startsWith('+') ? phone.value.prefix.slice(1) : phone.value.prefix;
            const suffix = phone.value.suffix.startsWith('+') ? phone.value.suffix.slice(1) : phone.value.suffix;
            phoneString = `+${prefix}${suffix}`;
        }
        return new DecodeValue(phoneString).toDecodedString();
    });
    const phoneDecoded = computed(()=>phoneStringToPhoneInput(new DecodeValue(customerData.value?.phone || '').toDecodedString()));
    const fullName = computed(()=>customerData.value?.fullName || '');
    const verificationType = computed(()=>customerData.value?.verificationType ?? null);
    const isCustomerVerificationAsDefaultAvailable = computed(()=>!!customerData.value?.isCustomerVerificationAsDefaultAvailable);
    const verificationStatus = computed(()=>customerData.value?.verificationStatus);
    const isAdditionalVerificationNeeded = computed(()=>!!customerData.value?.additionalVerificationNeeded);
    const isVerificationPurseCheck = computed(()=>customerData.value?.verificationLevel === 'PURSE_CHECK');
    const isCountryConfirmed = computed(()=>customerData.value?.isCountryConfirmed ?? false);
    const city = computed(()=>customerData.value?.city ?? '');
    const address = computed(()=>customerData.value?.address ?? '');
    const country = computed(()=>customerData.value?.country ?? '');
    const countryName = computed(()=>customerData.value?.countryName ?? '');
    const vipStatus = computed(()=>customerData.value?.vipStatus ?? 0);
    const customerBetType = computed(()=>customerData.value?.customerBetType);
    const shortComments = computed(()=>customerData.value?.shortComments ?? []);
    const priceChangePolicy = computed(()=>{
        const value = customerData.value?.priceChangePolicy;
        if (!value || value === PriceChangePolicy.UNDEFINED) return PriceChangePolicy.ALLOW_CHANGES;
        return value;
    });
    const historyFilterOptions = computed(()=>{
        const options = customerData.value?.historyFilterOptions;
        return options ? [
            ...options
        ] : [];
    });
    const defaultHistoryFilterOption = computed(()=>historyFilterOptions.value.find((item)=>item.isDefault) ?? null);
    const g2svFeatureStatus = computed(()=>customerData.value?.g2svFeatureStatus ?? false);
    const g2svCustomerStatus = computed(()=>customerData.value?.g2svCustomerStatus ?? false);
    const lastDepositedSystemId = computed(()=>customerData.value?.lastDepositedSystemId || null);
    const affiliatePartnerStatus = computed(()=>customerData.value?.affiliatePartnerStatus ?? AffiliatePartnerStatus.NO);
    const isExAllowedCountry = computed(()=>customerData.value?.exAllowedCountry ?? false);
    const isForbiddenCountry = computed(()=>customerData.value?.forbiddenCountry ?? false);
    const isRegistrationFinish = computed(()=>customerData.value?.isRegistrationFinish ?? false);
    const isSimpleRegistrant = computed(()=>customerData.value?.isSimpleRegistrant ?? false);
    const personalLink = computed(()=>{
        const personalDomainLink = customerData.value?.personalDomainData?.name;
        if (void 0 !== personalDomainLink) {
            if (!personalDomainLink.startsWith('http')) return `https://${personalDomainLink}`;
            return personalDomainLink;
        }
        return '';
    });
    const isEmailConfirmed = computed(()=>customerData.value?.isEmailConfirmed ?? false);
    /** not an 'N/A' string value */ const haveValidEmailValue = computed(()=>new RegExp(emailPattern).test(email.value));
    const isAppsFlyerEnabled = computed(()=>customerData.value?.isAppsFlyerEnabled ?? false);
    const isAppsFlyerDepositQualified = computed(()=>customerData.value?.isAppsFlyerDepositQualified ?? false);
    const isHiddenBalance = computed(()=>customerData.value?.isHiddenBalance ?? false);
    const nickname = computed(()=>customerData.value?.nickname || '');
    const userLanguageTag = computed(()=>customerData.value?.userLanguageTag || '');
    const createPinCodeModalCounter = computed(()=>customerData.value?.createPinCodeModalCounter || 0);
    const isPinCodeRequestedOnLogin = computed(()=>customerData.value?.isPinCodeRequestedOnLogin ?? false);
    const showSlipOnFirstAdded = computed(()=>{
        if (!isLoggedIn.value || !customerData.value) return true;
        return customerData.value.showSlipOnFirstAdded;
    });
    const useStandardBet = computed(()=>customerData.value?.useStandardBet ?? false);
    const standardBetAmount = computed(()=>customerData.value?.standardBetAmount || null);
    const isEkycRequestable = toRef(()=>customerData.value?.isEkycRequestable ?? false);
    const sameStakeForSingleByDefault = computed(()=>customerData.value?.sameStakeForSingleByDefault ?? false);
    const fastBets = computed(()=>customerData.value?.fastBets ?? null);
    const videoVerificationStatus = computed(()=>customerData.value?.videoVerificationStatus ?? null);
    const videoVerificationStatusExpiration = computed(()=>{
        if (customerData.value?.videoVerificationStatusExpiration) return customerData.value.videoVerificationStatusExpiration;
        return null;
    });
    const isVideoVerificationOnboardingShown = computed(()=>customerData.value?.isVideoVerificationOnboardingShown ?? false);
    const latestRequestedVerificationType = computed(()=>customerData.value?.latestRequestedVerificationType ?? '');
    const showVerification = computed(()=>!!customerData.value?.showVerification);
    // eslint-disable-next-line max-len
    const isReferralProgramNotificationClicked = computed(()=>customerData.value?.customerConfig.isReferralProgramNotificationClicked.value ?? false);
    const isResponsibleGamblingOnboardingShown = computed(()=>customerData.value?.customerConfig.isResponsibleGamblingOnboardingShown.value ?? false);
    const isAchievementsMainOnboardingShown = computed(()=>customerData.value?.customerConfig.isAchievementsMainOnboardingShown.value);
    const isPrivateProfile = computed(()=>customerData.value?.customerConfig.isPrivateProfile.value);
    const isHiddenNickname = computed(()=>customerData.value?.customerConfig.isHiddenNickname.value);
    const responsibleGamingLimits = computed(()=>customerData.value?.responsibleGamingLimits ?? []);
    /** mutations */ const setIsShowTurnOnTfaStep = (value)=>{
        isShowTurnOnTfaStep.value = value;
    };
    const setCustomerData = (data)=>{
        customerData.value = data;
    };
    function setCustomerConfig(config) {
        customerConfigCache.value = Object.keys(config).reduce((acc, key)=>{
            const item = config[key];
            acc[item.key] = item.value;
            return acc;
        }, {});
    }
    function setCustomerOddTypeConfig(isOddsTypeClassic, isOddsTypeAmerican) {
        if (isOddsTypeAmerican.value) {
            customerOddTypeConfig.value = isOddsTypeAmerican.key;
            localStorageManager.setItem('odd-type', CustomerConfig.IS_ODDS_TYPE_AMERICAN);
        } else {
            customerOddTypeConfig.value = isOddsTypeClassic.key;
            localStorageManager.setItem('odd-type', CustomerConfig.IS_ODDS_TYPE_CLASSIC);
        }
    }
    const updateLocalCustomerData = (data)=>{
        if (!isLoggedIn.value || !customerData.value) return;
        customerData.value = {
            ...customerData.value,
            ...data
        };
    };
    /** actions */ const setupCustomerData = (response)=>{
        const code = response.phoneCountryIsoCode || response.country || '';
        useCountryStore().setCountryCode(code);
        setCustomerData(response);
        setCustomerConfig(response.customerConfig);
        const { isOddsTypeClassic, isOddsTypeAmerican } = response.customerConfig;
        setCustomerOddTypeConfig(isOddsTypeClassic, isOddsTypeAmerican);
        setLoggedIn(true);
    };
    const updateCustomerData = async (payload)=>{
        // TODO: discus with leonapi team about required fields (c) 11.05.2020
        const defaults = {
            address: address.value,
            city: city.value,
            phone: phone.value,
            subscribe: String(emailSubscription.value),
            nickname: nickname.value
        };
        await doUpdateCustomerData(api, (node)=>node.mutations.customer.updateUserData, {
            options: {
                ...defaults,
                ...payload
            }
        });
    };
    /**
   * Reload only user's phone data or full data if we did not get it yet
   */ const loadCustomerPhone = async (param)=>{
        let { silent } = param;
        const response = await getUserDataPhone(api, (node)=>node.queries.customer.getUserData, {
            options: {}
        }, {
            silent
        });
        if (response.customerData) updateLocalCustomerData(response.customerData);
    };
    const savePriceChangePolicy = async (newValue)=>{
        const oldValue = priceChangePolicy.value;
        const safeValue = newValue === PriceChangePolicy.UNDEFINED ? PriceChangePolicy.DENY_CHANGES : newValue;
        try {
            updateLocalCustomerData({
                priceChangePolicy: safeValue
            });
            await doSavePriceChangePolicy(api, (node)=>node.mutations.customer.savePriceChangePolicy, {
                options: safeValue
            });
        } catch  {
            updateLocalCustomerData({
                priceChangePolicy: oldValue
            });
        }
    };
    const setCustomerConfigCache = (key, value)=>{
        if (!customerConfigCache.value) return;
        customerConfigCache.value[key] = value;
    };
    const setForcedVerificationType = (value)=>{
        forcedVerificationType.value = value;
    };
    const setResponsibleGamingLimits = (type, isSet)=>{
        const limit = responsibleGamingLimits.value.find((item)=>item.type === type);
        if (limit) limit.isSet = isSet;
    };
    watch(customerBetType, (newValue)=>{
        if (isValidCustomerBetType(newValue)) setBetType(newValue);
    }, {
        immediate: true
    });
    return {
        customerOddTypeConfig,
        forcedVerificationType,
        isLoaded,
        customerData,
        email,
        emailSubscription,
        login,
        phone,
        phoneDecodedString,
        phoneDecoded,
        fullName,
        verificationType,
        verificationStatus,
        isVerificationPurseCheck,
        isCountryConfirmed,
        city,
        address,
        country,
        countryName,
        vipStatus,
        priceChangePolicy,
        historyFilterOptions,
        defaultHistoryFilterOption,
        g2svFeatureStatus,
        g2svCustomerStatus,
        lastDepositedSystemId,
        affiliatePartnerStatus,
        isExAllowedCountry,
        isForbiddenCountry,
        isRegistrationFinish,
        isSimpleRegistrant,
        personalLink,
        customerBetType,
        isEmailConfirmed,
        haveValidEmailValue,
        isAppsFlyerEnabled,
        isAppsFlyerDepositQualified,
        isHiddenBalance,
        nickname,
        userLanguageTag,
        shortComments,
        createPinCodeModalCounter,
        isPinCodeRequestedOnLogin,
        showSlipOnFirstAdded,
        useStandardBet,
        standardBetAmount,
        sameStakeForSingleByDefault,
        fastBets,
        videoVerificationStatus,
        videoVerificationStatusExpiration,
        isVideoVerificationOnboardingShown,
        latestRequestedVerificationType,
        isAdditionalVerificationNeeded,
        isAsoTermsConfirmationNeeded,
        isReferralProgramNotificationClicked,
        isResponsibleGamblingOnboardingShown,
        isAchievementsMainOnboardingShown,
        isCustomerVerificationAsDefaultAvailable,
        showVerification,
        customerConfig: customerConfigCache,
        phoneDecodedStringFull,
        isShowTurnOnTfaStep,
        setCustomerData,
        setForcedVerificationType,
        updateLocalCustomerData,
        setupCustomerData,
        updateCustomerData,
        loadCustomerPhone,
        savePriceChangePolicy,
        setCustomerConfigCache,
        setIsShowTurnOnTfaStep,
        isEkycRequestable,
        isPrivateProfile,
        isHiddenNickname,
        responsibleGamingLimits,
        setResponsibleGamingLimits
    };
});
export default useCustomerDataStore;
