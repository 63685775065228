import { getIntercomUserHash, RequestOptionsPriority } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function fetchIntercomApiData() {
    const apiClient = useGraphqlClient();
    return getIntercomUserHash(apiClient, (node)=>node.queries.faq.getIntercomConfig, {
        options: {}
    }, {
        silent: true,
        priority: RequestOptionsPriority.LOW
    });
}
