export function getMonthFullNames($t) {
    return [
        $t('WEB2_MONTH_JANUARY_GENITIVE'),
        $t('WEB2_MONTH_FEBRUARY_GENITIVE'),
        $t('WEB2_MONTH_MARCH_GENITIVE'),
        $t('WEB2_MONTH_APRIL_GENITIVE'),
        $t('WEB2_MONTH_MAY_GENITIVE'),
        $t('WEB2_MONTH_JUNE_GENITIVE'),
        $t('WEB2_MONTH_JULY_GENITIVE'),
        $t('WEB2_MONTH_AUGUST_GENITIVE'),
        $t('WEB2_MONTH_SEPTEMBER_GENITIVE'),
        $t('WEB2_MONTH_OCTOBER_GENITIVE'),
        $t('WEB2_MONTH_NOVEMBER_GENITIVE'),
        $t('WEB2_MONTH_DECEMBER_GENITIVE')
    ];
}
