import { computed, ref, useSlots, watch } from 'vue';
import { CustomInputEventType } from 'web/src/components/Input/enums';
import { useHintParentProps, useInputCore } from '../../composables';
import { getStyleNumberValue } from '../../utils';
import { getMinMaxRow } from './utils';
export default function useMultilineTextInput(props, emits, textAreaRef) {
    const slots = useSlots();
    const { uniqueId, isEmpty, isFocus, isHover, inputValue, clearValue, onMouseOver, onMouseLeave, onFocus, onBlur, emitChange, emitInput, setInputValue } = useInputCore(props, emits);
    const { hintProperties, hasError } = useHintParentProps(props);
    const hintProps = computed(()=>({
            ...hintProperties.value,
            hintRight: props.maxLength ? `${(inputValue.value || '').length}/${props.maxLength}` : props.hintRight
        }));
    const rows = ref(props.rowsMin || 1);
    const watchRowsMin = ()=>{
        if (props.rowsMin) rows.value = Math.max(props.rowsMin, rows.value);
    };
    const watchRowsMax = ()=>{
        if (props.rowsMax) rows.value = getMinMaxRow(rows.value, props.rowsMax, props.rowsMin || 0);
    };
    watch(()=>props.rowsMin, watchRowsMin);
    watch(()=>props.rowsMax, watchRowsMax);
    const calculateRows = (target, value)=>{
        const { rowsMax, rowsMin } = props;
        const computedStyle = window.getComputedStyle(target);
        const padding = getStyleNumberValue(computedStyle, 'paddingBottom') + getStyleNumberValue(computedStyle, 'paddingTop');
        const lineHeight = getStyleNumberValue(computedStyle, 'lineHeight');
        const computedRows = value ? Math.floor((target.scrollHeight - padding) / lineHeight) : 1;
        if (rowsMax || rowsMin) rows.value = getMinMaxRow(computedRows, rowsMax || 0, rowsMin || 0);
        else rows.value = computedRows;
    };
    const setRowsOnMount = ()=>{
        if (textAreaRef?.value) {
            textAreaRef.value, HTMLTextAreaElement;
            calculateRows(textAreaRef.value, textAreaRef.value.value);
        }
    };
    const handleUserInput = (event, emitFunction)=>{
        const { target } = event;
        HTMLTextAreaElement;
        const { value } = target;
        if (value === inputValue.value) return;
        calculateRows(target, value);
        setInputValue(value);
        emitFunction();
    };
    const handleChange = (event)=>handleUserInput(event, emitChange);
    const handleInput = (event)=>handleUserInput(event, emitInput);
    const emitIconSuffixClick = ()=>{
        emits(CustomInputEventType.ICON_SUFFIX_CLICK);
    };
    const clickableSuffixIcon = computed(()=>!!((props.suffixIconName ?? slots?.iconSuffix) && props.clickableIcon));
    return {
        uniqueId,
        hintProps,
        hasError,
        rows,
        isEmpty,
        inputValue,
        isFocus,
        isHover,
        clearValue,
        onMouseOver,
        onMouseLeave,
        onFocus,
        onBlur,
        onChange: handleChange,
        onInput: handleInput,
        emitIconSuffixClick,
        clickableSuffixIcon,
        setRowsOnMount
    };
}
