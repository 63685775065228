import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
export class FortuneWheelModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        "1";
        router.addDefaultRoute({
            name: RouteName.FORTUNE_WHEEL,
            path: '/fortune-wheel',
            access: CustomerRouteAccessRole.AUTHORIZED,
            component: ()=>import('web/src/modules/fortune-wheel/pages/FortuneWheelRoutePage/FortuneWheelRoutePage.vue'),
            isFooterHidden: true
        });
    }
}
