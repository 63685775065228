export function updateSingleBannerInGroups(param) {
    let { groupId, bannerGroups, updatedBanner } = param;
    const { bannerId } = updatedBanner;
    const matchedGroupIndex = bannerGroups.findIndex((group)=>group.id === groupId);
    if (matchedGroupIndex < 0) return bannerGroups;
    const matchedBannersGroup = [
        ...bannerGroups[matchedGroupIndex].banners
    ];
    const matchedBannersIndex = matchedBannersGroup.findIndex((banner)=>banner.bannerId === bannerId);
    if (matchedBannersIndex < 0) return bannerGroups;
    matchedBannersGroup[matchedBannersIndex] = updatedBanner;
    const result = [
        ...bannerGroups
    ];
    result[matchedGroupIndex].banners = matchedBannersGroup;
    return result;
}
