import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "credit-card-radio-selector-item__item"
};
const _hoisted_2 = {
    class: "credit-card-radio-selector-item__card-select"
};
const _hoisted_3 = {
    class: "credit-card-radio-selector-item__card"
};
const _hoisted_4 = {
    class: "credit-card-radio-selector-item__name"
};
import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VRadio from 'web/src/components/Radio/VRadio/VRadio.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import paymentsCreditCardLogoMapper from 'web/src/modules/payments/utils/paymentsCreditCardLogoMapper';
import VLogo from 'web/src/components/Logo/VLogo/VLogo.vue';
import { PaymentsCardTypes } from 'web/src/modules/payments/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCreditCardRadioSelectorItem',
    props: {
        name: {},
        label: {},
        pan: {
            default: ''
        },
        token: {},
        type: {},
        isSelected: {
            type: Boolean
        },
        isDeletable: {
            type: Boolean
        }
    },
    emits: [
        "delete",
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const binProperties = {
            iconName: IconName.BIN,
            iconSize: IconSize.SIZE_20,
            kind: ButtonKind.TRANSPARENT
        };
        const iconProperties = {
            name: IconName.CREDIT_CARD
        };
        const isPaymentsCardType = computed(()=>!!props.type && props.type in PaymentsCardTypes);
        const vLogoName = computed(()=>paymentsCreditCardLogoMapper(props.type ?? null));
        function emitDelete() {
            emit('delete', props.token);
        }
        function emitChange() {
            emit('change', props.token);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "credit-card-radio-selector-item__holder",
                    {
                        'credit-card-radio-selector-item__holder--selected': _ctx.isSelected
                    }
                ]),
                onClick: emitChange
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(VRadio, {
                            "input-id": _ctx.token,
                            checked: _ctx.isSelected,
                            value: _ctx.token,
                            name: _ctx.name,
                            class: "credit-card-radio-selector-item__radio",
                            onChange: emitChange
                        }, null, 8, [
                            "input-id",
                            "checked",
                            "value",
                            "name"
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                            isPaymentsCardType.value ? (_openBlock(), _createBlock(VLogo, {
                                key: 0,
                                name: vLogoName.value
                            }, null, 8, [
                                "name"
                            ])) : (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                                key: 1
                            }, iconProperties, {
                                class: "credit-card-radio-selector-item__logo"
                            }), null, 16))
                        ]),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1)
                    ]),
                    _ctx.type && _ctx.isDeletable && _ctx.isSelected ? (_openBlock(), _createBlock(VButton, _mergeProps({
                        key: 0,
                        class: "credit-card-radio-selector-item__delete-button"
                    }, binProperties, {
                        onClick: emitDelete
                    }), null, 16)) : _createCommentVNode("", true)
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsCreditCardRadioSelectorItem'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'credit-card-radio-selector-item'
                    }
                ]
            ]);
        };
    }
});
