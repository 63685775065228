import { _ as _define_property } from "@swc/helpers/_/_define_property";
let EgsRouterParametersHandler = class EgsRouterParametersHandler {
    static setSelectedGameMethod(method) {
        EgsRouterParametersHandler.selectedGameMethod = method;
    }
    static getSelectedGameMethod() {
        return EgsRouterParametersHandler.selectedGameMethod;
    }
    static setSelectedGroup(method) {
        EgsRouterParametersHandler.selectedGroup = method;
    }
    static getSelectedGroup() {
        return EgsRouterParametersHandler.selectedGroup;
    }
};
_define_property(EgsRouterParametersHandler, "selectedGameMethod", void 0);
_define_property(EgsRouterParametersHandler, "selectedGroup", void 0);
export { EgsRouterParametersHandler as default };
