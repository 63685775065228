import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import FooterCuracao from 'web/src/modules/core/views/FooterLicense/FooterCuracao.vue';
import { useFooterCopyright } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterCopyright',
    props: {
        isLanding: {
            type: Boolean
        }
    },
    setup (__props) {
        const { copyright1, copyright2 } = useFooterCopyright();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['footer-copyright']]: true,
                    [_ctx.$style['footer-copyright--is-landing']]: _ctx.isLanding
                })
            }, [
                (_openBlock(), _createBlock(VDynamicContent, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['footer-copyright__year']),
                    content: _unref(copyright1)
                }, null, 8, [
                    "class",
                    "content"
                ])),
                (_openBlock(), _createBlock(VDynamicContent, {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['footer-copyright__text']),
                    content: _unref(copyright2)
                }, null, 8, [
                    "class",
                    "content"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'FooterCopyright'
                ]
            ]);
        };
    }
});
