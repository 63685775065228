import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, resolveDirective as _resolveDirective, createBlock as _createBlock } from "vue";
const _hoisted_1 = [
    "for"
];
import { dataTestLabel as vDataTestLabel } from '../../directives';
import { InputMode, TextInputTypes } from '../../enums';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import usePaymentInput from './usePaymentInput';
import InputButton from '../InputButton/InputButton.vue';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'PaymentInput',
    props: {
        formatMoneyOptions: {},
        isLabelHidden: {
            type: Boolean
        },
        isHintHidden: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        label: {},
        name: {
            default: 'number-input'
        },
        value: {
            default: ''
        },
        readonly: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        },
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {},
        autocomplete: {},
        placeholder: {},
        maximumFractionDigits: {}
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { uniqueId, isFocus, isEmpty, isHover, showClearButton, onBlur, onChange, onInput, onFocus, onClear, onPaste, onMouseOver, onMouseLeave, inputMaxLength, setFocusPosition, displayedValue } = usePaymentInput(props, emits);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(InputWrapper), {
                class: _normalizeClass(_ctx.$style['payment-input']),
                "input-id": _unref(uniqueId),
                label: _ctx.label,
                "is-label-hidden": _ctx.isLabelHidden,
                "is-hint-hidden": _ctx.isHintHidden,
                disabled: _ctx.disabled,
                "is-focus": _unref(isFocus),
                "is-empty": _unref(isEmpty),
                hint: _ctx.hint,
                "hint-right": _ctx.hintRight,
                "hint-link": _ctx.hintLink,
                "hint-link-right": _ctx.hintLinkRight,
                error: _ctx.error,
                "error-right": _ctx.errorRight,
                "is-large": _ctx.isLarge,
                "clickable-suffix": _unref(showClearButton)
            }, {
                label: _withCtx(()=>[
                        _unref(uniqueId) && !_ctx.isLabelHidden ? _withDirectives((_openBlock(), _createElementBlock("label", {
                            key: 0,
                            for: _unref(uniqueId),
                            class: _normalizeClass(_ctx.$style['payment-input__label']),
                            onMousedown: _cache[0] || (_cache[0] = _withModifiers(()=>{}, [
                                "prevent"
                            ]))
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.label), 1)
                        ], 42, _hoisted_1)), [
                            [
                                _unref(vDataTestLabel)
                            ]
                        ]) : _createCommentVNode("", true)
                    ]),
                input: _withCtx(()=>[
                        _createVNode(_unref(InputBorderWrapper), {
                            "is-focus": _unref(isFocus),
                            "is-disabled": _ctx.disabled,
                            "is-hover": _unref(isHover),
                            "has-error": !!(_ctx.error || _ctx.errorRight)
                        }, {
                            default: _withCtx(()=>[
                                    _createVNode(_unref(TextFlatInput), {
                                        autofocus: _ctx.autofocus,
                                        name: _ctx.name,
                                        "input-id": _unref(uniqueId),
                                        type: _unref(TextInputTypes).TEL,
                                        "input-mode": _unref(InputMode).DECIMAL,
                                        value: _unref(displayedValue),
                                        "has-error": !!(_ctx.error || _ctx.errorRight),
                                        disabled: _ctx.disabled,
                                        "has-icon-suffix": _unref(showClearButton),
                                        "max-length": _unref(inputMaxLength),
                                        "is-large": _ctx.isLarge,
                                        "is-payment-field": "",
                                        onInput: _unref(onInput),
                                        onChange: _unref(onChange),
                                        onFocus: _unref(onFocus),
                                        onBlur: _unref(onBlur),
                                        onPaste: _unref(onPaste),
                                        onMousedown: _unref(setFocusPosition),
                                        onMouseover: _unref(onMouseOver),
                                        onMouseleave: _unref(onMouseLeave)
                                    }, null, 8, [
                                        "autofocus",
                                        "name",
                                        "input-id",
                                        "type",
                                        "input-mode",
                                        "value",
                                        "has-error",
                                        "disabled",
                                        "has-icon-suffix",
                                        "max-length",
                                        "is-large",
                                        "onInput",
                                        "onChange",
                                        "onFocus",
                                        "onBlur",
                                        "onPaste",
                                        "onMousedown",
                                        "onMouseover",
                                        "onMouseleave"
                                    ]),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['payment-input__placeholder'])
                                    }, _toDisplayString(_ctx.placeholder), 3)
                                ]),
                            _: 1
                        }, 8, [
                            "is-focus",
                            "is-disabled",
                            "is-hover",
                            "has-error"
                        ])
                    ]),
                iconSuffix: _withCtx(()=>[
                        _withDirectives(_createVNode(InputButton, {
                            title: _ctx.$t('WEB2_CLEAR'),
                            onClick: _unref(onClear)
                        }, null, 8, [
                            "title",
                            "onClick"
                        ]), [
                            [
                                _vShow,
                                _unref(showClearButton)
                            ],
                            [
                                _directive_data_test,
                                {
                                    el: 'clear-icon-input-button',
                                    visible: !_unref(showClearButton)
                                }
                            ]
                        ])
                    ]),
                _: 1
            }, 8, [
                "class",
                "input-id",
                "label",
                "is-label-hidden",
                "is-hint-hidden",
                "disabled",
                "is-focus",
                "is-empty",
                "hint",
                "hint-right",
                "hint-link",
                "hint-link-right",
                "error",
                "error-right",
                "is-large",
                "clickable-suffix"
            ])), [
                [
                    _directive_auto_id,
                    'PaymentInput'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'payment-input',
                        name: _ctx.name
                    }
                ]
            ]);
        };
    }
});
