import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "data-test-key"
];
const _hoisted_2 = [
    "data-test-key"
];
const _hoisted_3 = [
    "data-test-key"
];
const _hoisted_4 = [
    "data-test-key"
];
const _hoisted_5 = [
    "data-test-key"
];
const _hoisted_6 = [
    "data-test-key"
];
import CustomerHistoryListItemLayout from // eslint-disable-next-line max-len
'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemLayout/CustomerHistoryListItemLayout.vue';
import { TestKeysEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { defaultCurrency } from 'web/src/modules/money/consts';
import { useCustomerHistoryListItemEgs } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListItemEgs',
    props: {
        item: {},
        currency: {
            default: defaultCurrency
        }
    },
    emits: [
        "item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { id, date, typeKind, isCreditPositive, eventDescription, debit, credit } = useCustomerHistoryListItemEgs(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(CustomerHistoryListItemLayout, {
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('item-click', _ctx.item))
            }, {
                metaLeft: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['egs-list-item__type-kind']),
                            "data-test-key": _unref(TestKeysEnum).TYPE_KIND
                        }, _toDisplayString(_unref(typeKind)), 11, _hoisted_1)
                    ]),
                metaRight: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['egs-list-item__date']),
                            "data-test-key": _unref(TestKeysEnum).DATE
                        }, _toDisplayString(_unref(date)), 11, _hoisted_2)
                    ]),
                descriptionLeft: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['egs-list-item__event-description']),
                            "data-test-key": _unref(TestKeysEnum).EVENT_DESCRIPTION
                        }, _toDisplayString(_unref(eventDescription)), 11, _hoisted_3)
                    ]),
                descriptionRight: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['egs-list-item__debit']),
                            "data-test-key": _unref(TestKeysEnum).DEBIT
                        }, _toDisplayString(_unref(debit)), 11, _hoisted_4)
                    ]),
                footerLeft: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['egs-list-item__id']),
                            "data-test-key": _unref(TestKeysEnum).ID
                        }, _toDisplayString(_unref(id)), 11, _hoisted_5)
                    ]),
                footerRight: _withCtx(()=>[
                        _unref(isCreditPositive) ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass({
                                [_ctx.$style['egs-list-item__credit']]: true,
                                [_ctx.$style['egs-list-item__credit--positive']]: true
                            }),
                            "data-test-key": _unref(TestKeysEnum).CREDIT
                        }, _toDisplayString(_unref(credit)), 11, _hoisted_6)) : _createCommentVNode("", true)
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListItemEgs'
                ]
            ]);
        };
    }
});
