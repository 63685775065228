import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
export const useSportlineRedirectInfoStore = defineStore('sportline-redirect-info', ()=>{
    const router = useRouter();
    const redirectedFromRoute = ref(null);
    let unwatchRoute;
    function clearRedirectWasHandledInfo() {
        redirectedFromRoute.value = null;
        unwatchRoute?.();
        unwatchRoute = void 0;
    }
    function showRedirectWasHandledInfo(param) {
        let { from } = param;
        redirectedFromRoute.value = from;
        unwatchRoute = watch(router.currentRoute, clearRedirectWasHandledInfo);
    }
    return {
        redirectedFromRoute,
        showRedirectWasHandledInfo,
        clearRedirectWasHandledInfo
    };
});
