import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function getShowMoreButtonProperties(props, $t) {
    return {
        fullWidth: true,
        disabled: props.endOfList,
        label: props.isLoading ? $t('JS_LOADING_M').value : $t('SLOTS_V2_SHOW_MORE_DESKTOP').value,
        isLoading: props.isLoading,
        kind: ButtonKind.BASE
    };
}
