import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSidebar',
    setup (__props) {
        const classObject = {
            sidebar: true
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(classObject)
            }, [
                _renderSlot(_ctx.$slots, "default")
            ])), [
                [
                    _directive_auto_id,
                    'VSidebar'
                ]
            ]);
        };
    }
});
