import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import createApplyEventsStatusChanges from 'web/src/modules/sportline/utils/apply-changes/applyEventsStatusChanges';
import createApplyRunnersOddsChanges from 'web/src/modules/sportline/utils/apply-changes/createApplyRunnersOddsChanges';
export function useSportlineEventsChangesListeners(props) {
    const bus = useEventsBus();
    const applyStatusChanges = createApplyEventsStatusChanges(props.getResponse, props.setResponse);
    const applyOddsChanges = createApplyRunnersOddsChanges(props.getResponse, props.setResponse);
    function onInit() {
        bus.on(BusEvent.SPORT_EVENTS_STATUS_CHANGED, (data)=>{
            if ('line' === data.source) return;
            applyStatusChanges(data.events);
        });
        bus.on(BusEvent.SPORT_EVENTS_RUNNERS_CHANGED, (data)=>{
            if ('line' === data.source) return;
            applyOddsChanges(data.events);
        });
    }
    return {
        onInit
    };
}
