import { isNumber, isString, isBoolean, isUndefined, bindIsArrayOf, isValidInterface } from '@leon-hub/guards';
import { isBetlineSelectionTag } from './isBetlineSelectionTag';
export const isCoreMarketRunnerResponseFieldGuards = {
    id: isNumber,
    name: isString,
    open: isBoolean,
    r: isNumber,
    c: isNumber,
    tags: [
        isUndefined,
        bindIsArrayOf(isBetlineSelectionTag)
    ],
    price: isNumber,
    handicap: [
        isString,
        isUndefined
    ]
};
export function isCoreMarketRunnerResponse(value) {
    return isValidInterface(value, isCoreMarketRunnerResponseFieldGuards);
}
