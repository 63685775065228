export const buttonKinds = [
    'primary',
    'secondary',
    'secondary-outline',
    'tertiary-primary',
    'tertiary-secondary',
    'quaternary-primary',
    'quaternary-secondary',
    'quinary-primary',
    'quinary-secondary'
];
export const buttonSizes = [
    'xsmall',
    // 32px
    'small',
    // 36px
    'medium',
    // 44px
    'large',
    // 52px
    'xlarge'
];
// 56px
// Label
export function isSButtonProps(argument) {
    return false;
}
export function isVButtonProps(argument) {
    return !isSButtonProps(argument);
}
