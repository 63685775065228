import { ref, watch, nextTick } from 'vue';
import { Timer } from '@leon-hub/utils';
import { Keys } from 'web/src/components/Select/constants/keyboardEvents';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ // eslint-disable-next-line sonarjs/cognitive-complexity
export default function useDropdownKeydownAndFocusIndex(param) {
    let { openDropdown, closeDropdown, selectedValue, isDropdownShown, options, disableKeySearch, onOptionSelect, searchQuery, setPaginationToReachIndex } = param;
    const hiddenSubmit = ref();
    const setHiddenSubmit = (element)=>{
        hiddenSubmit.value = element;
    };
    const getSelectedIndex = ()=>selectedValue.value ? (options.value ?? []).findIndex((option)=>option.value === selectedValue.value) : -1;
    const preselectedListIndex = ref(getSelectedIndex());
    const setPreselectedIndex = (index)=>{
        preselectedListIndex.value = index;
    };
    const confirmPreselect = ()=>{
        const selectedOption = options.value?.[preselectedListIndex.value];
        if (selectedOption) onOptionSelect(selectedOption.value);
    };
    const handleSpace = ()=>{
        if (isDropdownShown.value) confirmPreselect();
        else openDropdown();
    };
    let searchTimer = 0;
    let keySearchQuery = '';
    const resetSearch = ()=>{
        keySearchQuery = '';
    };
    const onKeySearch = async (event)=>{
        Timer.clearTimeout(searchTimer);
        const currentValue = keySearchQuery;
        keySearchQuery = event.code === Keys.Backspace ? currentValue.slice(0, currentValue.length - 1) : `${currentValue}${event.key}`.toLowerCase();
        const match = (options.value ?? [])?.findIndex((param)=>{
            let { label } = param;
            return label.toLowerCase().startsWith(keySearchQuery);
        });
        if (match > -1) {
            setPaginationToReachIndex(match);
            await nextTick();
            setPreselectedIndex(match);
            searchTimer = Timer.setTimeout(resetSearch, 1000);
        } else resetSearch();
    };
    const handleEnter = ()=>{
        if (isDropdownShown.value) confirmPreselect();
        else if (selectedValue.value && hiddenSubmit.value) // submit form LEONWEB-5979
        hiddenSubmit.value?.click();
        else openDropdown();
    };
    const onArrowPress = async (isDown)=>{
        if (!isDropdownShown.value) {
            openDropdown();
            return;
        }
        let nextIndex;
        const lastIndex = (options.value ?? []).length - 1;
        if (isDown) {
            nextIndex = preselectedListIndex.value >= lastIndex ? 0 : preselectedListIndex.value + 1;
            setPaginationToReachIndex(nextIndex + 2);
        } else {
            nextIndex = preselectedListIndex.value <= 0 ? lastIndex : preselectedListIndex.value - 1;
            setPaginationToReachIndex(nextIndex);
        }
        await nextTick();
        setPreselectedIndex(nextIndex);
    };
    const handleKeyDown = (event)=>{
        const keysToHandle = [
            Keys.ArrowUp,
            Keys.ArrowDown,
            Keys.Space,
            Keys.Enter,
            Keys.Escape
        ];
        if (!disableKeySearch.value && (1 === event.key.length || event.code === Keys.Backspace) || keysToHandle.includes(event.code) || event.code === Keys.Tab && isDropdownShown.value) {
            event.stopPropagation();
            event.preventDefault();
            switch(event.code){
                case Keys.Escape:
                    closeDropdown();
                    break;
                case Keys.Space:
                    handleSpace();
                    break;
                case Keys.Enter:
                    handleEnter();
                    break;
                case Keys.ArrowDown:
                case Keys.ArrowUp:
                    onArrowPress(event.code === Keys.ArrowDown);
                    break;
                default:
                    onKeySearch(event);
                    break;
            }
        }
    };
    const focusOnFirstResult = ()=>{
        if (searchQuery.value && options.value?.length) setPreselectedIndex(0);
    };
    watch(()=>searchQuery.value, focusOnFirstResult);
    watch(()=>isDropdownShown.value, ()=>{
        if (!isDropdownShown.value) nextTick().then(()=>setPreselectedIndex(getSelectedIndex()));
    });
    return {
        hiddenSubmit,
        handleKeyDown,
        preselectedListIndex,
        setPreselectedIndex,
        setHiddenSubmit
    };
}
