import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, isRef as _isRef, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/locale/ru';
import { TimeFormats } from 'web/src/utils/DateTime';
import { useVDatepicker, useVDatepickerDisabledDates } from './composables';
const __default__ = {
    inheritAttrs: false
};
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    __name: 'VDatepicker',
    props: {
        value: {},
        clearable: {
            type: Boolean
        },
        showCalendarIcon: {
            type: Boolean
        },
        doDisablePastDates: {
            type: Boolean
        },
        roundTo: {},
        min: {},
        max: {}
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const min = toRef(props, 'min');
        const max = toRef(props, 'max');
        const roundTo = toRef(props, 'roundTo');
        const clearable = toRef(props, 'clearable', false);
        const doDisablePastDates = toRef(props, 'doDisablePastDates', false);
        const format = TimeFormats.dayMonthYear.toUpperCase();
        const { isOpened, onChange, onClear, setToday } = useVDatepicker({
            min,
            max,
            roundTo
        }, emit);
        const { checkDisabledDate } = useVDatepickerDisabledDates({
            doDisablePastDates
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(DatePicker), {
                open: _unref(isOpened),
                "onUpdate:open": _cache[2] || (_cache[2] = ($event)=>_isRef(isOpened) ? isOpened.value = $event : null),
                type: "date",
                "value-type": "timestamp",
                format: _unref(format),
                value: _ctx.value,
                "disabled-date": _unref(checkDisabledDate),
                clearable: false,
                class: _normalizeClass({
                    'mx-datepicker__calendar-icon--hidden': !_ctx.showCalendarIcon
                }),
                onChange: _unref(onChange)
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "footer", {}, ()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['date-picker__footer'])
                                }, [
                                    clearable.value ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        type: "button",
                                        class: "mx-btn mx-btn-text",
                                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(onClear) && _unref(onClear)(...args);
                                        })
                                    }, _toDisplayString(_ctx.$t('WEB2_DATE_PICKER_CLEAR')), 1)) : _createCommentVNode("", true),
                                    _createElementVNode("button", {
                                        type: "button",
                                        class: "mx-btn mx-btn-text",
                                        onClick: _cache[1] || (_cache[1] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(setToday) && _unref(setToday)(...args);
                                        })
                                    }, _toDisplayString(_ctx.$t('WEB2_DATE_PICKER_TODAY')), 1)
                                ], 2)
                            ])
                    ]),
                _: 3
            }, 8, [
                "open",
                "format",
                "value",
                "disabled-date",
                "class",
                "onChange"
            ])), [
                [
                    _directive_auto_id,
                    'VDatepicker'
                ]
            ]);
        };
    }
});
