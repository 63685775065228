import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { IconSize } from '@leon-hub/icons';
import { SportIcon } from '@components/sport-icon';
import { BreadcrumbType } from 'web/src/components/Breadcrumbs/VBreadcrumb/enums';
import VBreadcrumb from 'web/src/components/Breadcrumbs/VBreadcrumb/VBreadcrumb.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportBreadcrumbsItem',
    props: {
        title: {},
        position: {},
        sportFamily: {},
        to: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VBreadcrumb, {
                type: _unref(BreadcrumbType).MENU,
                title: _ctx.title,
                position: _ctx.position,
                to: _ctx.to,
                controlled: ""
            }, _createSlots({
                _: 2
            }, [
                _ctx.sportFamily ? {
                    name: "icon",
                    fn: _withCtx(()=>[
                            _createVNode(_unref(SportIcon), {
                                size: _unref(IconSize).SIZE_16,
                                "sport-name": _ctx.sportFamily
                            }, null, 8, [
                                "size",
                                "sport-name"
                            ])
                        ]),
                    key: "0"
                } : void 0
            ]), 1032, [
                "type",
                "title",
                "position",
                "to"
            ])), [
                [
                    _directive_auto_id,
                    'SportBreadcrumbsItem'
                ]
            ]);
        };
    }
});
