import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onMounted, onBeforeUnmount } from 'vue';
import useVerifyEmailRoutePage from '../../composables/useVerifyEmailRoutePage';
import ConfirmEmail from '../../components/ConfirmEmail';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VerifyEmailRouteComponent',
    setup (__props) {
        const { email, isLoggedIn, confirmEmailStage, isPending, onAppear, onDisappear, onConfirmClick, goToSupport, sendAgain, onClose } = useVerifyEmailRoutePage();
        onMounted(onAppear);
        onBeforeUnmount(onDisappear);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['verify-email-page'])
            }, [
                _createVNode(_unref(ConfirmEmail), {
                    stage: _unref(confirmEmailStage),
                    email: _unref(email),
                    "is-pending": _unref(isPending),
                    "is-logged-in": _unref(isLoggedIn),
                    onConfirm: _unref(onConfirmClick),
                    onSendAgain: _unref(sendAgain),
                    onGetSupport: _unref(goToSupport),
                    onClose: _unref(onClose)
                }, null, 8, [
                    "stage",
                    "email",
                    "is-pending",
                    "is-logged-in",
                    "onConfirm",
                    "onSendAgain",
                    "onGetSupport",
                    "onClose"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VerifyEmailRouteComponent'
                ]
            ]);
        };
    }
});
